<template>
    <Card :title="$translate('ssc.title2')"
          title-bg="blue-grey-3"
          header-toolbar
          hide-actions>
        <ElementTree :items="departments"
                     :level="0"
                     :parent="0"
                     :active-element="selectedDepartmentId"
                     expand-element>
            <template v-slot:name="node">
                <q-item-label class="cursor-pointer col flex self-stretch items-center"
                              :title="node.node.name"
                              @click="router.visit($route('ssc.show',node.node))">
                    <span>{{ node.node.name }}</span>
                </q-item-label>
            </template>
            <template v-slot:actions="node">
                <q-btn size="sm" icon="menu" :label="$translate('ssc.actions.title')" flat
                       v-if="_.filter(node.node.can,(v) => v === true).length>0">
                    <q-menu>
                        <q-list dense separator>
                            <q-item v-if="node.node.can.view" clickable
                                    @click="router.get($route('ssc.show',node.node.id))">
                                <q-item-section side>
                                    <q-icon name="o_info" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ $translate('ssc.actions.show') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="node.node.can.add" clickable
                                    @click="openAddDepartment(node.node.id)">
                                <q-item-section side>
                                    <q-icon name="add" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ $translate('ssc.actions.add') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="node.node.can.edit" clickable
                                    @click="openEditDepartment(node.node)">
                                <q-item-section side>
                                    <q-icon name="o_edit" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ $translate('ssc.actions.edit') }}</q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item v-if="node.node.can.move" clickable
                                    @click="openMoveDepartment(node.node)">
                                <q-item-section side>
                                    <q-icon name="sym_o_jump_to_element" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ $translate('ssc.actions.move') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="node.node.can.staff.view"
                                    clickable
                                    @click="router.visit($route('ssc.staff',node.node))">
                                <q-item-section side>
                                    <q-icon name="o_person" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ $translate('ssc.actions.staff') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="node.node.can.documents.view"
                                    clickable
                                    @click="router.visit($route('ssc.documents',node.node))">
                                <q-item-section side>
                                    <q-icon name="o_file_present" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ $translate('ssc.actions.documents') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="node.node.can.address.view"
                                    clickable
                                    @click="router.visit($route('ssc.address',node.node))">
                                <q-item-section side>
                                    <q-icon name="o_location_on" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ $translate('ssc.actions.address') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="node.node.can.settings.view"
                                    clickable
                                    @click="router.visit($route('ssc.settings',node.node))">
                                <q-item-section side>
                                    <q-icon name="o_settings" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ $translate('ssc.actions.settings') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-btn>
            </template>
        </ElementTree>
    </Card>
    <q-dialog v-model="department_form_add" no-esc-dismiss no-backdrop-dismiss>
        <Card :title="$translate('ssc.title-add')"
              in-modal show-close-button header-toolbar no-card-section>
            <Form v-model="department"/>
            <template v-slot:actions>
                <q-btn color="positive" icon="add" :label="$translate('buttons.add')"
                       @click="addDepartment"/>
                <q-btn color="grey-7" icon="close" :label="$translate('buttons.close')"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="department_form_edit" no-esc-dismiss no-backdrop-dismiss>
        <Card :title="$translate('ssc.title-edit')"
              in-modal show-close-button header-toolbar no-card-section>
            <Form v-model="department"/>
            <template v-slot:actions>
                <q-btn color="positive" icon="o_save" :label="$translate('buttons.save')"
                       @click="saveDepartment"/>
                <q-btn color="grey-7" icon="close" :label="$translate('buttons.close')"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="department_form_move" no-esc-dismiss no-backdrop-dismiss>
        <Card :title="$translate('ssc.title-move',{ssc: movedDepartment.name})" show-close-button in-modal>
            <q-select :options="departments" v-model="moveForm.new_id"
                      option-label="name" option-value="id"
                      emit-value map-options
                      :error="moveForm.errors.hasOwnProperty('new_id')"
                      :error-message="moveForm.errors['new_id']"></q-select>
            <template v-slot:actions>
                <q-btn color="positive" icon="add" :label="$translate('buttons.move')"
                       @click="moveDepartment()"/>
                <q-btn color="grey-7" icon="close" :label="$translate('buttons.close')"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, onBeforeMount, onMounted, ref} from "vue";
import _ from "lodash";
import ElementTree from "@/Components/ElementTree.vue";
import Form from "@/Components/SSC/Form.vue";
import {router, useForm} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    departments: {
        type: Array
    }
})

const department = useForm({
    id: null,
    parent_id: null,
    name_i18n: {
        ru: null,
        en: null
    },
    name_short_i18n: {
        ru: null,
        en: null
    },
    description_i18n: {
        ru: null,
        en: null
    },
    display: false,
    level: 0,
})

const selectedDepartmentId = ref(0)

const department_form_add = ref(false)
const department_form_edit = ref(false)
const parent_id = ref(0)

const openAddDepartment = (id) => {
    department.parent_id = id
    department_form_add.value = true
}
const addDepartment = () => {
    department
        .post(route('admin.ssc.store'), {
            onSuccess: () => {
                department_form_add.value = false
            }
        })
}
const openEditDepartment = (depart) => {
    _.forEach(depart, (v, k) => {
        _.set(department, k, v)
    })
    department_form_edit.value = true
}
const saveDepartment = () => {
    department
        .post(route('admin.ssc.update', department.id), {
            onSuccess: () => {
                department_form_edit.value = false
            }
        })
}
const department_form_move = ref(false)
const movedDepartment = ref(null)
const openMoveDepartment = (department) => {
    movedDepartment.value = department;
    department_form_move.value = true
}
const moveForm = useForm({
    new_id: null
})
const moveDepartment = () => {
    moveForm.post(route('admin.ssc.move', {ssc: movedDepartment.value.id}), {
        onSuccess: () => {
            department_form_move.value = false
            router.reload()
        }
    })
}
</script>