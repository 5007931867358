<template>
    <div class="row q-mb-md q-gutter-x-md">
        <div class="col">
            <q-item-label caption>
                {{ $translate('delo.deloNumber-label') }}*:
            </q-item-label>
            <q-input v-model="deloNumber"
                     :hint="$translate('delo.deloNumber-helper')"
                     :error="project.errors.hasOwnProperty('sourceData.sed_num')"
                     :error-message="_.get(project.errors,'sourceData.sed_num')">
            </q-input>
        </div>
        <div class="col">
            <q-item-label caption>
                {{ $translate('delo.deloDate-label') }}*:
            </q-item-label>
            <q-input v-model="deloDate"
                     :hint="$translate('delo.deloDate-helper') + ' ' +moment().format($translate('common.date.js'))"
                     :error="project.errors.hasOwnProperty('sourceData.sed_date')"
                     :error-message="_.get(project.errors,'sourceData.sed_date')">
            </q-input>
        </div>
        <div class="col-auto flex items-center">
            <q-btn icon="playlist_add_check"
                   :label="$translate('delo.check-document')"
                   @click="checkExistence"
                   :loading="checkingDelo"
                   flat
                   color="secondary"
            >
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
        </div>
    </div>

    <div v-if="projectData" class="bg-grey-1 q-pa-sm q-mb-md">

        <q-field label="Номер РК" stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width">
                    {{ _.get(projectData, 'FREE_NUM') }}
                </div>
            </template>
        </q-field>
        <q-field label="Дата РК" stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width">
                    {{ moment(_.get(projectData, 'DOC_DATE')).format($translate('common.date.js')) }}
                </div>
            </template>
        </q-field>
        <q-field label="Наименование" stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width">
                    {{ _.get(projectData, 'ANNOTAT') }}
                </div>
            </template>
        </q-field>
        <q-field label="Примечание" stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width">
                    {{ _.get(projectData, 'NOTE') }}
                </div>
            </template>
        </q-field>
        <q-field label="Категория" stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width">
                    {{ _.get(projectData, 'CLASSIF_NAME') }}
                </div>
            </template>
        </q-field>
    </div>
    <Alert variant="info" v-if="validateInfo" :message="validateInfo"/>
    <Alert variant='danger' v-if="validateError" :message="validateError"/>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {usePage} from "@inertiajs/vue3";
import axios from "axios";
import _ from "lodash";

import moment from "moment";
import {Alert} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const project = defineModel({type: Object})

const locale = usePage().props.locale;

const projectData = ref(null)
const options = ref([])

const deloNumber = ref(null)
const deloDate = ref(null)
const checkingDelo = ref(false)

const validateInfo = ref(null)
const validateError = ref(null)

const checkExistence = () => {
    validateError.value = null
    checkingDelo.value = true
    axios.post('/datasource.json', {
        m: 'validateProject',
        source: 'delo',
        data: {
            sed_num: deloNumber.value,
            sed_date: deloDate.value,
        }
    }).then(async (response) => {
        if (response.data.results) {
            if (response.data.results.hasOwnProperty('error')) {
                validateError.value = response.data.results.error
            } else {
                loadData()
            }
        } else {
            validateError.value = $translate('delo.document-not-found')
        }
    }).finally(() => {
        checkingDelo.value = false
    })
}
const loadData = () => {
    return axios.get('/delo.json', {
        params: {
            m: 'getData',
            sed_num: deloNumber.value,
            sed_date: deloDate.value,
        }
    }).then((response) => {
        projectData.value = response.data.results
        _.set(project.value, 'sourceData', {sed_num: deloNumber.value, sed_date: deloDate.value});
        _.set(project.value, 'sourceDetail', projectData.value);
        _.set(project.value, 'name', _.get(projectData.value, 'ANNOTAT'));
        _.set(project.value, 'description', _.get(projectData.value, 'NOTE'));
        _.set(project.value, 'targets', null);
        _.set(project.value, 'keywords', [_.get(projectData.value, 'CLASSIF_NAME')]);
        _.set(project.value, 'additional', {});
        _.set(project.value, 'date_start', null);
        _.set(project.value, 'date_end', null);
        _.set(project.value, 'members', []);
        _.set(project.value, 'ssc', []);
    })
}

</script>
