<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions
          no-card-section>

        <FieldsRendering :fields="_.get(fields,'fields')"
                         v-model="publication.print_fields"
                         :errors="publication.errors"
                         error-prefix="print_fields."
        />
    </Card>
</template>

<script setup>
/**************************************************
 *
 *  Результаты исследований - Журнал (печатное издание)
 *
 ***************************************************/
import {getCurrentInstance} from "vue";
import _ from "lodash";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object}
})
const publication = defineModel('publication', {type: Object})
const errors = defineModel('errors', {type: Object})
</script>