<template>
    <q-card-section style="max-height: 80vh" class="scroll">
        <div class="q-pb-sm">
            <div>{{ $translate('ssc.fields.name') }}:</div>
            <template v-for="(lang_name,locale) in $translate('fields.locale')">
                <q-input dense v-model="form.name_i18n[locale]" :label="lang_name"
                         :error-message="form.errors['name_i18n.'+locale]"
                         :error="form.errors.hasOwnProperty('name_i18n.'+locale)"
                         counter maxlength="512"
                />
            </template>
        </div>

        <div class="q-pb-sm">
            <div>{{ $translate('ssc.fields.name_short') }}:</div>
            <template v-for="(lang_name,locale) in $translate('fields.locale')">
                <q-input dense v-model="form.name_short_i18n[locale]" :label="lang_name"
                         :error-message="form.errors['name_short_i18n.'+locale]"
                         :error="form.errors.hasOwnProperty('name_short_i18n.'+locale)"
                         counter maxlength="100"
                />
            </template>
        </div>

        <div class="q-pb-sm">
            <div>{{ $translate('ssc.fields.description') }}:</div>
            <template v-for="(lang_name,locale) in $translate('fields.locale')">
                <q-input type="textarea" rows="3" dense v-model="form.description_i18n[locale]"
                         :label="lang_name" counter
                         :error-message="form.errors['description_i18n.'+locale]"
                         :error="form.errors.hasOwnProperty('description_i18n.'+locale)"/>
            </template>
        </div>
        <div class="q-pb-sm">
            <div>{{ $translate('ssc.fields.research') }}:</div>
            <q-checkbox v-model="form.display"
                        :label="$translate('ssc.fields.display')"
                        size="sm"/>

            <q-option-group v-model="form.level"
                            v-if="form.display"
                            size="xs"
                            :options="[
        {value:1, label:$translate('ssc.fields.level_1')},
        {value:2, label:$translate('ssc.fields.level_2')},
        {value:3, label:$translate('ssc.fields.level_3')}
       ]"/>
        </div>
    </q-card-section>
</template>

<script setup>
import {getCurrentInstance} from "vue";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const form = defineModel({
    type: Object,
    default() {
        return {
            id: null,
            parent_id: null,
            name_i18n: {
                ru: null,
                en: null
            },
            name_short_i18n: {
                ru: null,
                en: null
            },
            description_i18n: {
                ru: null,
                en: null
            },
            display: false,
            level: 0,
        }
    }
})

</script>