<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions no-card-section>

        <q-card-section v-if="_.get(fields,'fields.name.show')">
            <q-input v-model="publication.name"
                     :label="_.get(fields,'fields.name.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('name')"
                     :error-message="_.get(errors,'name')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.description.show')">
            <q-input v-model="publication.description"
                     :label="_.get(fields,'fields.description.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('description')"
                     :error-message="_.get(errors,'description')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.date.show')">
            <q-field :label="_.get(fields,'fields.date.label')"
                     style="width: 100%; max-width: 300px"
                     stack-label
                     :error="errors.hasOwnProperty('date')"
                     :error-message="_.get(errors,'date')">
                <template v-slot:control>
                    <div class="full-width text-center">
                        {{ publication.date }}
                    </div>
                </template>
                <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                            <q-date v-model="publication.date"
                                    today-btn
                                    minimal
                                    first-day-of-week="1">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup
                                           :label="$translate('buttons.select')"
                                           color="primary" flat/>
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-field>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.events.show')">
            <q-field v-model="publication.events"
                     :label="_.get(fields,'fields.events.label')"
                     stack-label
                     :error="errors.hasOwnProperty('event_id')"
                     :error-message="_.get(errors,'event_id')">
                <template v-slot:control>
                    <div class="items-center">
                        {{ _.get(publication, 'event.name') }}
                    </div>
                </template>
                <template v-slot:append>
                    <q-btn :label="$translate('buttons.select')"
                           icon="sym_o_trackpad_input"
                           dense size="sm"
                           @click="openDialogEvents"/>
                    <q-dialog v-model="dialogEvents">
                        <EventsDialog @onSelect="setEvents"/>
                    </q-dialog>
                </template>
            </q-field>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.contragent.show')">
            <q-field :label="_.get(fields,'fields.contragent.label')"
                     stack-label
                     :error="errors.hasOwnProperty('contragent_id')"
                     :error-message="_.get(errors,'contragent_id')">
                <template v-slot:control>
                    <div class="items-center">
                        {{ _.get(publication, 'contragent.name') }}
                    </div>
                </template>
                <template v-slot:append>
                    <q-btn :label="$translate('buttons.select')"
                           icon="sym_o_trackpad_input"
                           dense size="sm"
                           @click="openDialogContragent"/>
                </template>
            </q-field>
            <q-dialog v-model="dialogContragent">
                <ContragentDialog @onSelect="setContragent"/>
            </q-dialog>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.state.show')">
            <q-select v-model="publication.state_id"
                      :label="_.get(fields,'fields.state.label')"
                      :options="optionsState"
                      options-dense
                      emit-value
                      map-options
                      stack-label
                      :error="errors.hasOwnProperty('state_id')"
                      :error-message="_.get(errors,'state_id')"/>
        </q-card-section>

        <FieldsRendering :fields="_.get(fields,'common_fields')"
                         v-model="publication.common_fields"
                         :errors="errors"
                         error-prefix="common_fields."/>
    </Card>
</template>

<script setup>
/**************************************************
 *
 *  Награды - основная информация
 *
 ***************************************************/
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";
import {Events as EventsDialog, Contragent as ContragentDialog} from "@/Components/ReportMaterialForm/Dialog";
import ClassificationDB from "@/plugins/ClassificationDB";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object}
})
const publication = defineModel('publication', {type: Object})
const errors = defineModel('errors', {type: Object})

const dialogEvents = ref(false)

const openDialogEvents = () => {
    dialogEvents.value = true
}
const setEvents = (data) => {
    publication.value.event = data.events
    publication.value.event_id = data.events.id
    dialogEvents.value = false
}

const optionsState = ref([])
const loadState = () => {
    ClassificationDB
        .getSimpleList('awardstate')
        .then((r) => {
            optionsState.value = r
        })
}

const dialogContragent = ref(false)

const openDialogContragent = () => {
    dialogContragent.value = true
}
const setContragent = (data) => {
    publication.value.contragent = data.contragent
    publication.value.contragent_id = data.contragent.id
    dialogContragent.value = false
}

onMounted(() => {
    loadState()
})
</script>