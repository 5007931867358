<template>
    <q-tabs v-model="tab" align="left" dense no-caps>
        <q-tab name="main" :label="$translate('module-srvbus.base-settings.name')"/>
        <q-tab name="sap" :label="$translate('module-sap.spbu.name')"/>
        <q-tab name="students" label="StudentCard" disable/>
    </q-tabs>
    <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="main">
            <Alert variant="info" :message="$translate('module-srvbus.description')"/>
            <SettingsTable :data="config.main"/>
        </q-tab-panel>
        <q-tab-panel name="sap">
            <Alert variant="info" :message="$translate('module-sap.spbu.description')"/>
            <SettingsTable :data="config.sap"/>
        </q-tab-panel>
        <q-tab-panel name="students"></q-tab-panel>
    </q-tab-panels>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const tab = ref('main');

const config = {
    main: [
        {
            name: 'SRVBUS_ENABLED',
            label: $translate('module-srvbus.settings.SRVBUS_ENABLED'),
            value: props.settings.module.SRVBUS_ENABLED || false,
            block: 'module',
            type: 'checkbox',
        },
        {
            name: 'SRVBUS_HOST',
            label: $translate('module-srvbus.settings.SRVBUS_HOST'),
            value: props.settings.module.SRVBUS_HOST,
            block: 'module',
            type: 'text',
        },
        {
            name: 'SRVBUS_DATABASE',
            label: $translate('module-srvbus.settings.SRVBUS_DATABASE'),
            value: props.settings.module.SRVBUS_DATABASE,
            block: 'module',
            type: 'text',
        },
        {
            name: 'SRVBUS_USERNAME',
            label: $translate('module-srvbus.settings.SRVBUS_USERNAME'),
            value: props.settings.module.SRVBUS_USERNAME,
            block: 'module',
            type: 'text',
        },
        {
            name: 'SRVBUS_PASSWORD',
            label: $translate('module-srvbus.settings.SRVBUS_PASSWORD'),
            value: props.settings.module.SRVBUS_PASSWORD,
            block: 'module',
            type: 'password',
        },
        {
            name: 'SRVBUS_ENCRYPT',
            label: $translate('module-srvbus.settings.SRVBUS_ENCRYPT'),
            value: !_.isEmpty(props.settings.module.SRVBUS_ENCRYPT) ? props.settings.module.SRVBUS_ENCRYPT : 'no',
            description: '',
            block: 'module',
            type: 'select',
            multiple: false,
            options: ['yes', 'no', 'strict']
        },
    ],
    sap: [
        {
            name: 'SAP_ROOT_ID',
            label: $translate('module-sap.settings.SAP_ROOT_ID'),
            value: !_.isEmpty(props.settings.module.SAP_ROOT_ID) ? props.settings.module.SAP_ROOT_ID : '0',
            description: $translate('module-sap.settings.SAP_ROOT_ID_description'),
            block: 'module',
            type: 'text',
        },
    ]
};
</script>
