<template>
    <Card :title="$translate('users.manage.stats-logon.title')"
          title-bg="cyan-14"
          title-text="white"
          no-card-section
          hide-actions
    >
        <template v-if="show">
            <div class="row justify-between q-my-sm">
                <q-skeleton width="65%" class="col-6"/>
                <q-skeleton width="25%" class="col-6"/>
            </div>
            <div class="q-mt-sm">
                <q-skeleton width="100%" height="1rem"/>
            </div>
            <div class="row justify-between q-my-sm">
                <q-skeleton width="85%"/>
                <q-skeleton width="14%"/>
            </div>
            <div class="row justify-between q-my-sm">
                <q-skeleton width="35%"/>
                <q-skeleton width="55%"/>
            </div>
        </template>
        <template v-else>
            <q-list dense separator class="max-height-vh-70 overflow-y-auto">
                <q-item v-for="(item, i) in logs" :key="i">
                    <q-item-section>
                        <q-item-label>
                            <q-icon name="o_language"/>
                            {{ item.ip }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>
                            <q-icon name="o_schedule"/>
                            {{ moment(item.login_time).format($translate("common.datetime.js")) }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
        </template>
    </Card>
</template>

<script setup lang="ts">
import {Card} from "@/Components/Block";
import {getCurrentInstance, onMounted, ref, Ref} from "vue";
import {ComponentInternalInstance} from "@vue/runtime-core";
import moment from "moment";
import axios from "axios";

const { userId } = defineProps<{userId: number}>();

const app: ComponentInternalInstance | null = getCurrentInstance();
const $translate: Function = app!.appContext.config.globalProperties.$translate;
const $route: Function = app!.appContext.config.globalProperties.$route;

const show: Ref<boolean> = ref<boolean>(true);

interface ILogItem {
    ip: string,
    login_time: string
}

const logs: Ref<ILogItem[] | never[]> = ref<ILogItem[] | never[]>([]);

const getUserLastLogs = () => {
    show.value = true;
    axios.get($route("ajax.users", {m: "getUserCardLastLogs", user_id: userId}))
        .then((response) => {
            logs.value = response.data.results;
        })
        .catch(e => {
            console.error(e);
        })
        .finally(() => {
            show.value = false;
        });
};

onMounted(() => {
    getUserLastLogs();
});
</script>
