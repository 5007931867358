<template>

    <q-item-section>
        <q-select v-model="selectedField"
                  :label="$translate('report-template.form.fields.additional.field')"
                  :options="reportDataStore.research.template.fields"
                  option-value="name"
                  :option-label="(item)=>item.label?item.label:item.name"
                  @update:modelValue="(item) => addon.field = item.name"
                  dense
                  stack-label
                  options-dense/>
    </q-item-section>
    <q-item-section>
        <q-select v-model="addon.condition"
                  v-if="addon.field"
                  :options="optionsCondition"
                  options-dense
                  emit-value
                  map-options
                  dense
        />
    </q-item-section>
    <q-item-section v-if="selectedField">
        <q-select v-model="addon.value"
                  v-if="['file','file:multi'].includes(selectedField.type)"
                  :label="$translate('report-template.form.fields.additional.file_uploaded')"
                  :options="optionsValueField"
                  emit-value
                  map-options
                  dense
                  options-dense
        />
        <q-select v-model="addon.value"
                  v-else-if="['input:checkbox','input:radio','select','select:multi'].includes(selectedField.type)"
                  :options="_.get(selectedField.props,'options')"
                  option-label="text"
                  option-value="id"
                  :multiple="['input:checkbox','select:multi'].includes(selectedField.type)"
                  emit-value
                  map-options
                  dense
                  options-dense
        />
        <q-input v-model="addon.value"
                 v-else
                 v-if="addon.field"
                 :type="valueType"
                 dense
        />
    </q-item-section>
    <q-item-section>
        <q-input v-model="addon.time"
                 v-if="addon.field"
                 mask="#"
                 reverse-fill-mask
                 :label="$translate('report-template.form.fields.additional.time-change')"
                 :suffix="$translate('units.min')"
                 input-class="text-center"
                 stack-label
                 dense
        />
    </q-item-section>
    <q-item-section>
        <q-select v-model="addon.depend"
                  v-if="addon.field"
                  :options="optionsDepend"
                  options-dense
                  emit-value
                  map-options
                  dense
        />
    </q-item-section>

</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {useReportDataStore} from "@/store/reportDataStore";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const addon = defineModel({type: Object})
const reportDataStore = useReportDataStore()

const selectedField = ref(null)

const optionsCondition = computed(() => {
    if (selectedField.value) {
        return _.map($translate('fields.types.' + _.get(selectedField.value, 'type') + '.condition'), (v, k) => ({
            value: k,
            label: v
        }))
    }
    return []
})

const optionsDepend = computed(() => {
    return [
        {value: 1, label: $translate('report-template.form.fields.additional.depend_unit')},
        {value: 0, label: $translate('report-template.form.fields.additional.depend_total')},
    ]
})
const optionsValueField = computed(() => {
    return [
        {value: 1, label: $translate('buttons.yes')},
        {value: 0, label: $translate('buttons.no')},
    ]
})
/**
 *
 * @param type
 * @returns {string}
 */
const valueType = computed(() => {
    if (!selectedField.value) {
        return 'text';
    }
    switch (_.get(selectedField.value, 'type')) {
        case 'input:date':
            return 'date';
        case 'input:datetime':
            return 'datetime';
        case 'input:time':
            return 'time';
        default:
            return 'text';
    }
})
</script>