<template>
    <Card :title="$translate('research-report.title.consumables')" class="q-ma-sm">

        <div class="row q-gutter-x-lg">
            <div class="col-auto">
                <q-input mask="date" v-model="form.date" ref="date"
                         :prefix="$translate('research-report.fields.consumables.date')"
                         :hint="$translate('research-report.fields.consumables.date-placeholder')"
                         input-class="text-center"
                         :error="form.errors.hasOwnProperty('date')"
                         :error-message="_.get(form.errors,'date')">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer" @click="showDateDialog">
                            <q-popup-proxy v-model="dateDialog" cover no-parent-event
                                           transition-show="scale"
                                           transition-hide="scale">
                                <q-date v-model="form.date"
                                        :options="dateOptions"
                                        @update:model-value="loadConsumables"
                                        minimal>
                                    <div class="row items-center justify-end">
                                        <q-btn @click="hideDateDialog"
                                               :label="$translate('buttons.select')"
                                               color="primary" flat/>
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
            </div>
            <div class="col-8">
                <q-select v-model="selectedConsumable"
                          :disable="!form.date"
                          :label="$translate('research-report.fields.consumables.consumable')"
                          :hint="$translate('research-report.fields.consumables.consumable-placeholder')"
                          :options="consumableOptions"
                          :loading="filteringConsumable"
                          @filter="filterConsumable"
                          @update:model-value="onSelectConsumable"
                          use-input
                          input-debounce="1000"
                          clearable
                          clear-icon="clear"
                          option-label="name"
                          option-value="id"
                          options-dense>
                </q-select>
                <q-item v-if="selectedConsumable" dense>
                    <q-item-section>
                        <q-item-label caption class="text-light-blue-6">
                            {{ $translate('research-report.fields.consumables.balance_on') }}
                            {{ moment(_.get(_.first(selectedConsumable.state),'date')).format($translate('common.date.js')) }}
                            &mdash;
                            {{ _.get(_.first(selectedConsumable.state),'count') }}
                            {{ _.get(selectedConsumable,'unit.name')}}
                        </q-item-label>
                    </q-item-section>
                </q-item>

            </div>
            <div class="col-auto">
                <div class="flex q-gutter-x-xs">
                    <q-input v-model="form.count"
                             mask="#.##" reverse-fill-mask
                             :disable="!selectedConsumable"
                             :label="$translate('research-report.fields.consumables.count')" stack-label
                             :prefix="$translate('research-report.fields.consumables.count-placeholder')"

                             input-class="text-center"
                             :error="form.errors.hasOwnProperty('count')"
                             :error-message="_.get(form.errors,'count')"/>
                    <q-select v-model="form.transform_id"
                              :disable="!selectedConsumable"
                              :label="$translate('research-report.fields.consumables.units-placeholder')" stack-label

                              :options="_.get(selectedConsumable,'transform')"
                              option-value="id"
                              option-label="name"
                              emit-value map-options
                              class="w-5rem"
                              :error="form.errors.hasOwnProperty('transform_id')"
                              :error-message="_.get(form.errors,'transform_id')"/>
                </div>
            </div>
            <div class="col-20" v-if="_.size(equipmentList) > 0">
                <q-select v-model="form.equipment_id"
                          :label="$translate('research-report.fields.consumables.equipment')"
                          :hint="$translate('research-report.fields.consumables.equipment-placeholder')"
                          :options="equipmentList"
                          options-dense
                          clearable
                          clear-icon="clear"
                          emit-value map-options/>
            </div>
        </div>
        <q-input type="textarea" rows="2"
                 v-model="form.comment"
                 :label="$translate('research-report.fields.worktime.comment-placeholder')"
                 :error="form.errors.hasOwnProperty('comment')"
                 :error-message="_.get(form.errors,'comment')"/>

        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.add')"
                   @click="formSubmit"
                   color="positive"
                   dense/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {useReportDataStore} from '@/store/reportDataStore';
import axios from "axios";
import {useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    research: {type: Object, required: true}
})

const reportDataStore = useReportDataStore()

const form = useForm({
    date: null,
    count: null,
    transform_id: null,
    consumable_id: null,
    equipment_id: null,
    comment: null
})

const dateDialog = ref(false)

const showDateDialog = () => {
    dateDialog.value = true
}
const hideDateDialog = () => {
    dateDialog.value = false
}

/**
 * Проверка даты на доступность заполнения
 *
 * @param {Date} date
 * @returns {boolean}
 */
const dateOptions = (date) => {
    if (moment(date, 'YYYY/MM/DD').startOf('day').isAfter()) {
        return false
    }
    if (moment(date, 'YYYY/MM/DD').startOf('day').isBefore(moment(props.research.responsible_assign).startOf('day'))) {
        return false
    }
    if (moment(date, 'YYYY/MM/DD').startOf('day').isAfter(moment(props.research.project.date_end).startOf('day'))) {
        return false
    }
    return true
}

const selectedConsumable = ref(null)
const consumableList = ref(null)
const consumableOptions = ref([])
const filteringConsumable = ref(false)
const filterConsumable = (val, update, abort) => {
    filteringConsumable.value = true
    if (consumableList.value !== null) {
        if (val === null) {
            update(() => {
                consumableOptions.value = consumableList.value
            })
            filteringConsumable.value = false
            return
        }
        update(() => {
            const needle = val.toLowerCase()
            consumableOptions.value = _.filter(consumableList.value, (v => (v.name).toLowerCase().indexOf(needle) > -1))
        })
    }
    filteringConsumable.value = false
}
const loadConsumables = () => {
    filteringConsumable.value = true
    axios.get(route('ajax.consumables.ssc', {
            m: 'forReport',
            ssc_id: props.research.ssc_id,
            date: moment(form.date, 'YYYY/MM/DD').format('YYYY-MM-DD'),
            template_id: props.research.template_id,
        })
    )
        .then((response) => {
            consumableList.value = response.data.results
        })
        .finally(() => filteringConsumable.value = false)
}
const onSelectConsumable = () => {
    form.consumable_id = selectedConsumable.value.id
    form.count = null
    form.transform_id = null
    form.equipment_id = null;
}

const equipmentList = computed(() => {
    if (form.date) {
        let d = moment(form.date, 'YYYY/MM/DD').startOf('day')
        return _.map(
            _.filter(
                reportDataStore.equipmentList,
                (item) => moment(item.used_date).startOf('day').isSame(d)
            ), (item) => ({
                value: item.id,
                label: item.equipment.display_name + ' [' + item.used_time_display + ']'
            }));
    }
    return []
})

const formSubmit = () => {
    form.post(route('project.research.report.consumable.store', props.research),
        {
            onSuccess: () => {
                form.reset();
                reportDataStore.loadConsumables();
            }
        })
}
</script>