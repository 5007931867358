<template>
    <q-item>
        <q-item-section top>
            <q-item-label>{{ $translate('cadre-sicklist.label.period') }}</q-item-label>
            <div class="row">
                <q-field :label="$translate('cadre-sicklist.fields.date_start')"
                         tag="div" class="col"
                         borderless stack-label>
                    <template v-slot:control>
                        <div class="full-width items-center">
                            {{ moment(sicklist.date_start).format($translate('common.date.js')) }}
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('cadre-sicklist.fields.date_end')"
                         tag="div" class="col"
                         borderless stack-label>
                    <template v-slot:control>
                        <div class="full-width items-center" v-if="sicklist.date_end">
                            {{ moment(sicklist.date_end).format($translate('common.date.js')) }}
                        </div>
                    </template>
                </q-field>
            </div>
        </q-item-section>
        <q-item-section>
            <q-item-label class="text-center">
                {{ sicklist.status_text }}
            </q-item-label>
        </q-item-section>
        <q-item-section side class="w-3rem">
            <SicklistActions :sicklist="sicklist"/>
        </q-item-section>
    </q-item>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {CadreSicklist} from "@/entity";
import moment from "moment";
import SicklistActions from "@/Components/Cadre/Sicklist/SicklistActions.vue";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

interface Props {
    sicklist: CadreSicklist
}

const props = defineProps<Props>()

</script>