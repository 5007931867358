<template>
    <Card :title="$translate('module-auth.form.resetpassword.title')"
          header-toolbar>

        <Alert v-if="banner" variant="warning" :html-message="banner"/>

        <q-input v-model="form.email"
                 id="email"
                 :label="$translate('module-auth.form.resetpassword.placeholder')"
                 label-color="blue-grey"
                 :error-message="form.errors.email"
                 :error="form.errors.hasOwnProperty('email')">
            <template v-slot:prepend>
                <q-icon name="o_email" color="blue-grey"/>
            </template>
            <template v-slot:append>
                <q-btn color="primary"
                       @click="submit"
                       :loading="form.processing">
                    <template v-slot:loading>
                        <q-spinner-facebook/>
                    </template>
                    <div>{{ $translate('module-auth.form.resetpassword.submit') }}</div>
                </q-btn>
            </template>
        </q-input>


        <template v-slot:actions>
            <div class="flex justify-between align-center full-width q-px-sm">
                <q-btn color="secondary"
                       :href="$route('login')"
                       :label="$translate('buttons.login')"
                       icon-right="login"/>

                <q-btn href="https://docs.researchims.ru/common/login/" target="_blank" size="md"
                       color="deep-orange-9"  outline
                       icon="o_report_problem"
                       :label="$translate('module-auth.form.problem')"
                       no-caps/>
            </div>
        </template>
    </Card>
</template>

<script setup>
import Guest from "@/Layout/Guest.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {computed} from "vue";
import {Notify} from 'quasar'
import {Card, Alert} from "@/Components/Block";

defineOptions({layout: Guest})

const page = usePage()
const message = computed(() => page.props.message)
const banner = computed(() => page.props.banner)
const form = useForm({
    email: '',
})

const notifySuccess = () => {
    Notify.create({
        color: 'positive',
        message: message,
        position: "top",
        progress: true,
        icon: "task_alt"
    })
}

const submit = () => {
    form.post(route('password.email'), {
        onSuccess: () => notifySuccess()
    })
}
</script>