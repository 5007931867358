<template>
    <Card :title="title??$translate('Выбор события')"
          no-card-section in-modal show-close-button header-toolbar>
        <Alert variant="info">
            Воспользуйтесь поиском для выбора события.<br/>
            Если событие не найдено, воспользуйтесь добавлением нового
        </Alert>
        <q-tabs v-model="tab">
            <q-tab name="search"
                   :label="titleSearch??$translate('Поиск события')"/>
            <q-tab name="new"
                   :label="titleCreate??$translate('Добавление нового события')"/>
        </q-tabs>
        <q-tab-panels v-model="tab">
            <q-tab-panel name="search">
                <SearchEvents @onSelect="selectItem"/>
            </q-tab-panel>
            <q-tab-panel name="new">
                <div class="full-width text-center">
                    <q-btn
                        icon-right="o_send"
                        :label="$translate('Перейти к созданию события в новом окне')"
                        :href="$route('publication.add')"
                        target="_blank"/>
                </div>
            </q-tab-panel>
        </q-tab-panels>
        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   @click="setItem"
                   color="positive"
                   dense
            />
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {Card, Alert} from "@/Components/Block";
import {Events as SearchEvents} from "@/Components/Search";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    title: {
        type: String,
        default: null
    },
    titleSearch: {
        type: String,
        default: null
    },
    titleCreate: {
        type: String,
        default: null
    }
})

const emit = defineEmits(['onSelect'])

const tab = ref('search')

const item = ref(null)

const selectItem = (data) => {
    item.value = data
}
const setItem = () => {
    if (tab.value === 'search') {
        emit('onSelect', item.value)
    }
}
</script>