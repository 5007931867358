<template>
    <ProjectLayout :project="project" tab="report">
        <q-bar class="bg-grey-3">
            {{ $translate('project.menu.publication.list') }}
        </q-bar>
        <q-table ref="tableRef"
                 row-key="id"
                 :rows="items"
                 :columns="columns"
                 :filter="filter"
                 :loading="loading"
                 @request="onRequest"
                 v-model:pagination="pagination"
                 :rows-per-page-options="[0]"
                 wrap-cells
                 hide-pagination
                 flat
        >
            <template v-slot:top-row>
                <q-tr>
                    <q-td/>
                    <q-td colspan="100%">
                        <q-input v-model="filter"
                                 debounce="1000"
                                 :placeholder="$translate('search.element')"
                                 borderless dense
                                 clearable clear-icon="clear">
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:body-cell-category="props">
                <q-td :props="props">
                    {{ $translate('report-type.type.' + props.value) }}
                </q-td>
            </template>
            <template v-slot:body-cell-status="props">
                <q-td :props="props">
                    {{ props.value }}
                </q-td>
            </template>
            <template v-slot:body-cell="props">
                <q-td :props="props">
                    <div v-html="props.value"/>
                </q-td>
            </template>
            <template v-slot:body-cell-datetime="props">
                <q-td :props="props">
                    <div v-for="(date) in props.value">{{ date }}</div>
                </q-td>
            </template>
            <template v-slot:body-cell-imported="props">
                <q-td :props="props">
                    <q-icon v-if="props.value"
                            name="mdi-database-import-outline"
                            class="cursor-help"
                            color="purple-5"
                            size="sm"
                    >
                        <q-tooltip class="text-body2">
                            {{ $translate('publication.fields.imported') }}
                        </q-tooltip>
                    </q-icon>
                </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @on-success="onSuccess"
                    ></DataTableActions>
                </q-td>
            </template>
        </q-table>
    </ProjectLayout>
</template>

<script setup>
import DataTableActions from "@/Components/DataTableActions.vue";
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import ProjectLayout from "@/Components/Project/ProjectLayout.vue";
import axios from "axios";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    project: {type: Object, required: true},
    categorys: {type: Array}
})


const tableRef = ref();
const filter = ref('');
const loading = ref(false);
const items = ref([]);
const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'imported', align: 'left', label: '', field: 'imported', style: 'width: 2rem'},
    {name: 'category', align: 'left', label: $translate('publication.fields.category'), field: 'category',},
    {name: 'type', align: 'left', label: $translate('publication.fields.type'), field: 'report_type',},
    {name: 'name', align: 'left', label: $translate('publication.fields.name'), field: 'name'},
    // {name: 'author', align: 'left', label: $translate('publication.fields.author'), field: 'author'},
    {name: 'status', align: 'left', label: $translate('publication.fields.status'), field: 'status_text'},
    // {name: 'datetime', align: 'left', label: $translate('publication.fields.datetime'), field: 'datetime'},
];

const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10000,
    rowsNumber: 10000
});

const onRequest = (request) => {
    const {page, rowsPerPage, sortBy, descending} = request.pagination;
    const filter = request.filter;

    loading.value = true;

    let params = {
        m: 'publications',
        project: props.project.id,
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {}
        }
    };
    if (_.size(filter) > 0) {
        params.datatable['search'] = filter;
    }
    axios.get(route('ajax.project'), {
        params: params,
    })
            .then((response) => {
                items.value = response.data.results;
            })
            .finally(() => {
                loading.value = false;
            });
};

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};

onMounted(() => {
    onSuccess()
});
</script>