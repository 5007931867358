<template>
    <q-list separator>
        <template v-for="(row) in usageInResearch">
            <q-item>
                <q-item-section>
                    <q-item-label class="ellipsis">
                        <Link :href="$route('project.show', {project: _.get(row, 'project_number')})">
                            {{ _.get(row, 'project_name') }}
                        </Link>
                        <q-tooltip class="text-body2">{{ _.get(row, 'project_name') }}</q-tooltip>
                    </q-item-label>
                </q-item-section>
                <q-item-section side style="width: 150px">
                    <q-item-label class="text-black">
                        {{ helpers.formatNumber(_.get(row, 'count')) }}
                        {{ _.get(row, 'unit') }}
                    </q-item-label>
                </q-item-section>
            </q-item>
        </template>
    </q-list>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import axios from "axios";
import _ from "lodash";
import helpers from "@/helpers";
import {Link} from "@inertiajs/vue3";

const props = defineProps({
    consumableId: Number
});

const app = getCurrentInstance();
const $route = app.appContext.config.globalProperties.$route;

const usageInResearch = ref({});

const loading = ref(false);


onMounted(() => {
    loading.value = true;
    axios.get(route('ajax.consumables', {
        m: 'usageInResearch',
        consumable_id: props.consumableId
    })).then(response => {
        usageInResearch.value = response.data.results;
    }).finally(() => {
        loading.value = false;
    });
});
</script>
