<template>
    <section v-if="currentRole">
        <q-toolbar class="bg-grey-4 cursor-text">
            <q-toolbar-title>
                <div>{{ currentRole.display_name }}</div>
            </q-toolbar-title>

            <q-btn :icon="showDetail?'expand_less':'expand_more'"
                   size="sm" color="grey-6"
                   dense
                   @click="showDetail = !showDetail"/>

            <q-btn v-if="_.get(currentRole,'can.delete')"
                   icon="o_delete"
                   :label="$translate('buttons.delete')"
                   size="sm"
                   color="negative"
                   class="q-ml-sm"
                   dense
                   @click="deleteRole"/>
        </q-toolbar>
        <q-scroll-area :style="areaHeight">
            <q-card-section v-if="showDetail">
                <q-field :label="$translate('role.fields.display_name')" tag="div" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width no-outline cursor-text flex items-center no-wrap"
                             tabindex="0">
                            <q-icon name="edit" color="grey-5"
                                    :title="$translate('buttons.edit')"
                                    class="cursor-pointer">
                                <q-popup-edit v-model="currentRole.display_name_i18n"
                                              v-if="!currentRole.system"
                                              v-slot="scope"
                                              buttons auto-save
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchRoleName"
                                              :title="$translate('role.fields.display_name')">
                                    <template v-for="(lang_name,locale) in $translate('fields.locale')">
                                        <q-input v-model="scope.value[locale]"
                                                 style="min-width: 320px"
                                                 :label="lang_name"
                                                 dense counter/>
                                    </template>
                                </q-popup-edit>
                            </q-icon>
                            <div class="q-ml-xs">{{ currentRole.display_name }}</div>
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('role.fields.description')" tag="div" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width no-outline cursor-text flex items-center no-wrap"
                             tabindex="0">
                            <q-icon name="edit" color="grey-5"
                                    :title="$translate('buttons.edit')"
                                    class="cursor-pointer">
                                <q-popup-edit v-model="currentRole.description_i18n"
                                              v-slot="scope" buttons auto-save
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchRoleDescription">
                                    <div>{{ $translate('role.fields.description') }}</div>
                                    <template v-for="(lang_name,locale) in $translate('fields.locale')">
                                        <q-input type="textarea" rows="3"
                                                 style="min-width: 320px"
                                                 v-model="scope.value[locale]"
                                                 :label="lang_name"
                                                 dense counter/>
                                    </template>
                                </q-popup-edit>
                            </q-icon>
                            <div class="q-ml-xs">{{ currentRole.description }}</div>
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('role.fields.scope')" tag="div" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width no-outline cursor-text row items-center" tabindex="0">
                            <q-icon name="edit" color="grey-5"
                                    :title="$translate('buttons.edit')"
                                    class="cursor-pointer">
                                <q-popup-edit v-model="currentRole.scope"
                                              v-slot="scope" buttons auto-save
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchRoleScope">
                                    <div>{{ $translate('role.fields.scope') }}</div>
                                    <div class="text-wrap">
                                        <template v-for="(lang_name,locale) in $translate('role.scope')">
                                            <q-checkbox v-model="scope.value"
                                                        size="xs"
                                                        :val='locale'
                                                        :label="lang_name"/>
                                        </template>
                                    </div>
                                </q-popup-edit>
                            </q-icon>
                            <div class="q-ml-xs">
                                <template v-for="(scope) in currentRole.scope">
                                    <q-chip square dense>{{ $translate('role.scope.' + scope) }}</q-chip>
                                </template>
                            </div>
                        </div>
                    </template>
                </q-field>
            </q-card-section>
            <q-item-label header>
                {{ $translate('role.fields.permission') }}
            </q-item-label>
            <Alert v-if="currentRole.system"
                   :message="$translate('Для встроенной роли права не назначаются')"
                   variant="info"/>
            <q-splitter v-model="splitPermission" unit="px" v-if="!currentRole.system">
                <template v-slot:before>
                    <q-tabs vertical dense v-model="permissionTab">
                        <template v-for="(group,name) in permissions">
                            <q-tab :name="name" :label="$translate('permission.'+name+'.title')" no-caps/>
                        </template>
                    </q-tabs>
                </template>
                <template v-slot:after>
                    <q-tab-panels vertical dense v-model="permissionTab">
                        <template v-for="(group,name) in permissions">
                            <q-tab-panel :name="name" class="q-py-none">
                                <q-list dense>
                                    <template v-for="(perm,level) in group">
                                        <q-item>
                                            <q-item-section>
                                                <q-item-label header>
                                                    {{ $translate('permission.' + name + '.' + level + '-title') }}
                                                </q-item-label>
                                                <template v-for="(item) in perm">
                                                    <q-checkbox v-model="userPermission" :val="item.id" dense size="sm">
                                                        {{ $translate('permission.' + name + '.' + item.name) }}
                                                    </q-checkbox>
                                                </template>
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-list>
                            </q-tab-panel>
                        </template>
                    </q-tab-panels>
                </template>
            </q-splitter>
        </q-scroll-area>
        <q-separator/>
        <q-card-actions align="center">
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   color="positive"
                   @click="patchRolePermission"/>
        </q-card-actions>
    </section>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref, watch} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import {useQuasar} from "quasar";
import {Alert} from "@/Components/Block/";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar()

const props = defineProps({
    currentRole: {},
})
const emit = defineEmits(['onSuccess'])

const permissions = computed(() => usePage().props.permissions)
const areaHeight = computed(() => {
    return {
        height: 'calc(100vh - 240px)'
    }
})

const splitPermission = ref(200)
const permissionTab = ref(null)

const userPermission = ref({});
const showDetail = ref(false)

onMounted(() => {
    userPermission.value = _.map(_.get(props.currentRole, 'permissions'), (item) => (item.id))
    permissionTab.value = _.get(_.keys(permissions.value), 0);
})

watch(props, () => {
    userPermission.value = _.map(_.get(props.currentRole, 'permissions'), (item) => (item.id))
})

const patchRoleName = (value, initValue) => {
    router.patch(route('admin.roles.update', props.currentRole.id), {
        display_name_i18n: value
    }, {
        onSuccess: () => {
            emit('onSuccess')
        }
    })
}
const patchRoleDescription = (value, initValue) => {
    router.patch(route('admin.roles.update', props.currentRole.id), {
        description_i18n: value
    }, {
        onSuccess: () => {
            emit('onSuccess')
        }
    })
}
const patchRoleScope = (value, initValue) => {
    router.patch(route('admin.roles.update', props.currentRole.id), {
        scope: value
    }, {
        onSuccess: () => {
            emit('onSuccess')
        }
    })
}
const patchRolePermission = () => {
    router.patch(route('admin.roles.update', props.currentRole.id), {
        permission: userPermission.value
    }, {
        onSuccess: () => {
            emit('onSuccess')
        }
    })
}
const deleteRole = () => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: props.currentRole.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('admin.roles.destroy', props.currentRole), {
            onSuccess: () => {
                props.currentRole = null
                emit('onSuccess')
            }
        })
    })
}
</script>