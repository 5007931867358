<template>
    <Card :title="title" no-card-section hide-actions :hide-title="_.size(title)===0">
        <q-list separator>
            <template v-for="(author) in items">
                <q-item>
                    <q-item-section>
                        <q-item-label v-if="author.role">
                            <q-badge color="grey">
                                {{ _.get($translate('report-type.author-roles'), author.role) }}
                            </q-badge>
                        </q-item-label>
                        <template v-if="author.user_id">
                            <q-item-label>
                                {{ _.get(author, 'user.fulled_name') }}
                            </q-item-label>
                            <q-item-label caption v-if="author.external">
                                <span>внешняя персона</span>
                            </q-item-label>
                        </template>
                        <template v-if="author.company_id">
                            <q-item-label>
                                {{ _.get(author, 'company.name') }}
                            </q-item-label>
                            <q-item-label caption v-if="author.external">
                                <span>внешняя организация</span>
                            </q-item-label>
                        </template>
                        <template v-if="author.group_id">
                            <q-item-label>
                                {{ _.get(author, 'group.name') }}
                            </q-item-label>
                            <q-item-label caption>
                                <span>коллаборация авторов</span>
                            </q-item-label>
                        </template>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import _ from "lodash";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    title: {type: String},
    items: {type: Array}
})
</script>