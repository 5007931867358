<template>
    <Card :title="$translate('module-archive.project.card.students.title', {size: _.size(students)})"
          class="q-mb-md"
          no-card-section
          hide-actions
    >
        <template v-slot:default>
            <q-table ref="tableRef"
                     :columns="columns"
                     :rows="students"
                     :pagination="{rowsPerPage: 0}"
                     :rows-per-page-options="[0]"
                     hide-pagination
                     wrap-cells
                     bordered
                     flat
            >
                <template v-slot:body-cell-fio="props">
                    <q-td :props="props">
                        {{ props.row.fullname }}
                    </q-td>
                </template>
            </q-table>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";

const props = defineProps({
    students: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const tableRef = ref();
const filter = ref('');
const loading = ref(false);
const columns = [
    {name: 'fio', align: 'left', label: $translate('module-archive.project.card.students.fields.fio'), field: 'fio'},
    {name: 'group', align: 'left', label: $translate('module-archive.project.card.students.fields.group'), field: 'group'},
    {name: 'course', align: 'left', label: $translate('module-archive.project.card.students.fields.course'), field: 'course'},
    {name: 'ad', align: 'left', label: $translate('module-archive.project.card.students.fields.ad'), field: 'ad'},
    {name: 'group_l', align: 'center', label: $translate('module-archive.project.card.students.fields.group_l'), field: 'group_l'},
    {name: 'faculty', align: 'left', label: $translate('module-archive.project.card.students.fields.faculty'), field: 'faculty'},
];
</script>
