<template>
    <Card :title="$translate('module-archive.research.card.additional.title')" class="q-mb-md" hide-actions>
        <template v-slot:default>
            <template v-for="(field, key) in afmData" :key="key">
                <div v-if="helpers.filled(_.get(field, 'name')) && helpers.filled(_.get(field, 'value'))"
                     class="row q-py-xs"
                >
                    <div class="col-12">{{ handleDescription(_.get(field, 'name')) }}</div>
                    <div class="col q-ml-md">{{ _.get(field, 'value') }}</div>
                </div>
            </template>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";
import helpers from "@/helpers";

const props = defineProps({
    afmData: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const handleDescription = (value) => value.endsWith(':') ? value : value + ':';
</script>
