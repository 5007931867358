<template>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb q-mt-none">
            <template v-for="(text, i) in $translate('module-ethics.application.card.breadcrumbs.items')" :key="i">
                <li class="breadcrumb-item" :class="{'active': state === getIndex(i)}">
                    <template v-if="_.includes([4, 5], getIndex(i)) && state === getIndex(i)">
                        <span v-if="4 === getIndex(i)">
                            {{ text }}: {{ $translate('module-ethics.application.card.change-state.finally_approved') }}
                        </span>
                        <span v-if="5 === getIndex(i)">
                            {{ text }}: {{ $translate('module-ethics.application.card.change-state.finally_declined') }}
                        </span>
                    </template>
                    <template v-else>
                        <span>{{ text }}</span>
                    </template>
                </li>
            </template>
        </ol>
    </nav>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import _ from "lodash";

const props = defineProps({
    state: Number
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const getIndex = (key) => Number(_.last(_.split(key, '_')));
</script>
