<template>
    <SearchEdition @onSelect="selectEdition" label="Издание"/>
    <SearchPublisher @onSelect="selectPublisher" label="Издатель"/>
    <q-input v-model="seria.number" label="Том"/>
    <q-input v-model="seria.book" label="Номер"/>
    <q-select v-model="seria.isbn"
              multiple
              label="ISBN"
              :hint="$translate('введите значение и нажмите Enter')"
              use-chips
              use-input
              clearable clear-icon="clear"
              new-value-mode="add-unique"
              hide-dropdown-icon/>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import SearchEdition from "@/Components/Search/SearchEdition.vue";
import SearchPublisher from "@/Components/Search/SearchPublisher.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const seria = defineModel({
    type: Object,
    default() {
        return {
            edition_id: null,
            edition: null,
            publisher_id: null,
            publisher: null,
            number: null,
            book: null,
            isbn: []
        }
    }
})

const selectEdition = (data) => {
    _.set(seria.value, 'edition_id', data.id)
    _.set(seria.value, 'edition', data.edition)
}
const selectPublisher = (data) => {
    _.set(seria.value, 'publisher_id', data.id)
    _.set(seria.value, 'publisher', data.publisher)
}
</script>

<style scoped>

</style>