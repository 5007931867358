<template>
    <Card title-bg="deep-purple-2"
          :title="$translate('module-sensors.measurement-types.form.title.create')"
          actions-align="center"
          header-toolbar
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('module-sensors.measurement-types.form.actions.back')"
                   @click="router.get($route('services.sensors.measurement-types'))"
                   icon="o_chevron_left"
                   color="deep-purple-5"
                   dense
            />
        </template>
        <MeasurementTypeForm v-model="form"/>
        <template v-slot:actions>
            <q-btn :label="$translate('module-sensors.measurement-types.form.actions.add')"
                   @click="submitForm"
                   color="positive"
            />
        </template>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {Form as MeasurementTypeForm} from "@/Components/Sensors/MeasurementType";
import {getCurrentInstance} from "vue";
import {router, useForm} from "@inertiajs/vue3";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    name: '',
    data_type: ''
});

const submitForm = () => {
    form.post(route('services.sensors.measurement-types.store'), {
        onSuccess: () => router.visit(route('services.sensors.measurement-types'))
    });
};
</script>
