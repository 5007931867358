<template>
    <ResearchLayout :research="research" tab="about">
        <q-card-section>
            <div :class="{'row q-gutter-x-md':$q.screen.gt.sm,'column q-gutter-y-md':$q.screen.lt.md}">
                <div class="col">
                    <ResearchCardAbout :research="research"/>
                </div>
                <div class="col">
                    <ResearchClaim :research="research"/>

                    <Card :title="$translate('research.show.detail')" hide-actions>
                        <template v-slot:headerActions v-if="research.can.edit">
                            <q-btn icon="o_edit" :label="$translate('buttons.edit')"
                                   @click="researchAdditionalEditDialog = true"
                                   dense/>
                        </template>
                        <FieldsValues :fields="research.template.fields" :values="research.fields"/>
                        <q-dialog v-model="researchAdditionalEditDialog" no-backdrop-dismiss>
                            <Card :title="$translate('research.form.title.edit',{number: research.number})"
                                  in-modal show-close-button>
                                <FieldsRendering :fields="research.template.fields"
                                                 v-model="researchAdditionalEditForm.fields"
                                                 error-prefix="fields."
                                                 :errors="researchAdditionalEditForm.errors"/>
                                <template v-slot:actions>
                                    <q-btn icon="o_save" :label="$translate('buttons.save')"
                                           color="positive"
                                           @click="researchAdditionalEditFormSubmit"
                                    />
                                </template>
                            </Card>
                        </q-dialog>
                    </Card>
                </div>
            </div>
        </q-card-section>
    </ResearchLayout>

</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {useQuasar} from 'quasar';
import _ from "lodash";
import ResearchLayout from "@/Components/ProjectResearch/ResearchLayout.vue";
import ResearchCardAbout from "@/Components/ProjectResearch/Card/ResearchCardAbout.vue";
import FieldsValues from "@/Components/Fields/FieldsValues.vue";
import {Card} from "@/Components/Block";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";
import ResearchClaim from "@/Components/ProjectResearch/Card/ResearchClaim.vue";


const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $q = useQuasar();

const props = defineProps({
    research: {type: Object, required: true}
})

const researchAdditionalEditDialog = ref(false)

const researchAdditionalEditForm = useForm({
    fields: props.research.fields
})

const researchAdditionalUpdateData = (values) => {
    _.forEach(values, (v, k) => {
        _.set(researchAdditionalEditForm.fields, k, v)
    })
}
const researchAdditionalEditFormSubmit = () => {

    researchAdditionalEditForm.post(route('project.research.update', props.research), {
        onSuccess: () => {
            researchAdditionalEditDialog.value = false
            router.reload()
        }
    })
}

</script>