<template>
    <Card :title="$translate('consumables.title.transform')" hide-actions>
        <template v-slot:headerActions>
            <q-btn :label="$translate('buttons.add')" @click="addTransform" icon="o_add" dense/>
        </template>

        <div class="row items-center text-uppercase">
            <div class="col text-center">{{ $translate('consumables.title.transform-name') }}</div>
            <div class="col-7 text-center">{{ $translate('consumables.title.transform-input') }}</div>
            <div class="col-auto q-px-none">&nbsp;&nbsp;</div>
            <div class="col-6 text-center">{{ $translate('consumables.title.transform-base') }}</div>
            <div class="col-2">&nbsp;</div>
        </div>
        <div v-for="(transformItem, index) in transform" :key="index" class="row q-mt-sm q-pt-sm border-top">
            <div class="col">
                <q-item>
                    <q-item-section>
                        <q-input v-model="transformItem.name"
                                 placeholder="например: 1 ящик или баллон 40л 5 атм"
                                 :readonly="isUsed(transformItem,consumable)"
                                 outlined
                                 dense
                        />
                    </q-item-section>
                </q-item>
            </div>
            <div class="col-7">
                <q-item>
                    <q-item-section>
                        <q-input v-model.number="transformItem.from_value"
                                 :readonly="transformItem.id && transformItem.from_unit === transformItem.to_unit || isUsed(transformItem,consumable)"
                                 :placeholder="$translate('consumables.ssc.fields.count')"
                                 :suffix="unitCurrent(transformItem.from_unit) ? ( unitCurrent(transformItem.from_unit).label  + ' (' + unitCurrent(transformItem.from_unit).value + ')') : ''"
                                 type="number"
                                 step="0.01"
                                 min="0"
                                 input-class="text-center"
                                 outlined
                                 dense
                        />
                    </q-item-section>
                    <q-item-section side top>
                        <template
                            v-if="transformItem.id && transformItem.from_unit === transformItem.to_unit || isUsed(transformItem,consumable)">
                        </template>
                        <template v-else>
                            <q-select v-model="transformItem.from_unit" :options="units" outlined dense/>
                        </template>
                    </q-item-section>
                </q-item>
            </div>
            <div class="col-auto">
                <q-item>
                    <q-item-section>
                        <span class="q-mt-sm">=</span>
                    </q-item-section>
                </q-item>
            </div>
            <div class="col-6">
                <q-item>
                    <q-item-section>
                        <q-input v-model.number="transformItem.to_value"
                                 :readonly="transformItem.id && transformItem.from_unit === transformItem.to_unit || isUsed(transformItem,consumable)"
                                 :suffix="unitSelected ? (unitSelected.label + ' (' + unitSelected.value + ')') : ''"
                                 type="number"
                                 step="0.01"
                                 min="0"
                                 input-class="text-center"
                                 outlined
                                 dense

                        />
                    </q-item-section>
                </q-item>
            </div>
            <div class="col-1">
                <q-item>
                    <q-item-section>
                        <q-toggle v-if="isUsed(transformItem, consumable) && transformItem.from_unit !== transformItem.to_unit"
                                  v-model="transformItem.archive"
                                  :false-value="0"
                                  :true-value="1"
                        >
                            <q-tooltip class="text-body2">{{ $translate('buttons.archive') }}</q-tooltip>
                        </q-toggle>
                    </q-item-section>
                </q-item>
            </div>
            <div class="col-1">
                <q-item>
                    <q-item-section>
                        <q-btn v-if="transformItem.from_unit !== transformItem.to_unit && !isUsed(transformItem, consumable)"
                               @click="deleteTransform(index)"
                               text-color="negative"
                               icon="o_delete" flat
                        >
                            <q-tooltip class="text-body2">{{ $translate('buttons.delete') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                </q-item>
            </div>
        </div>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import Card from "@/Components/Block/Card.vue";

const props = defineProps({
    consumable: {
        type: Object
    },
    units: {
        type: Array
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const transform = ref([]);

const unitSelected = computed(() => props.consumable.units ?
    _.find(props.units, {'value': props.consumable.units}) :
    {}
);

const addTransform = () => {
    transform.value.push({
        id: null,
        name: '',
        from_unit: null,
        from_value: 1,
        loss: 0,
        to_value: 1,
        to_unit: props.consumable.units,
        archive: 0
    });
};
const deleteTransform = (index) => {
    _.remove(transform.value, (value, i) => i === index);
};

const isUsed = (transform, consumable) => transform.id ?
    (_.get(transform, 'usage_count') > 0 || _.get(consumable, 'movement_count') > 0) :
    false;

const unitCurrent = (unit) => _.find(props.units, {'value': unit});

onMounted(() => {
    transform.value = props.consumable.transform;
});
</script>
