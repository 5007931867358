<template>
    <Card :title="$translate('users.details.roles')" title-bg="cyan-14" title-text="white"
          in-modal no-card-section show-close-button>
        <q-scroll-area style="height: 70vh;">
            <q-card-section>
                <div class="row q-gutter-xs">
                    <template v-for="(role) in roles">
                        <q-checkbox v-model="selectedRoles" :val="role.id" class="col-11" dense>
                            {{ role.display_name }}
                        </q-checkbox>
                    </template>
                </div>
            </q-card-section>
        </q-scroll-area>

        <template v-slot:actions>
            <q-btn color="positive" icon="check" :label="$translate('buttons.add')" @click="submitForm"/>
            <q-btn color="dark" icon="close" :label="$translate('buttons.cancel')" v-close-popup/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {useForm, usePage} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    user: {
        type: Object,
    },
})

const emit = defineEmits(['onSuccess'])

const roles = computed(() => usePage().props.systemRoles)

const selectedRoles = ref([])

const form = useForm({
    roles: []
})

const submitForm = () => {
    form.transform((data) => ({
        ...data,
        roles: selectedRoles.value,
    }))
        .post(route('admin.users.add-role', props.user.id), {
            onSuccess: () => {
                emit('onSuccess')
            }
        })
}
</script>