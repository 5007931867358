<template>
    <Card title-bg="brown-3"
          :title="$translate('settings.title')"
          no-card-section
          header-toolbar
          hide-actions
    >
        <q-tabs align="left" no-caps v-model="tabs">
            <template v-for="(label,name) in $translate('settings.tab')">
                <q-tab :name="name" :label="label"/>
            </template>
        </q-tabs>
        <q-tab-panels v-model="tabs">
            <template v-for="(label, name) in $translate('settings.tab')">
                <q-tab-panel :name="name" class="q-py-md">
                    <component :is="tabComponent[_.upperFirst(name)]" :settings="settings" :data="data"/>
                </q-tab-panel>
            </template>
        </q-tab-panels>
    </Card>
</template>

<script setup>
import About from "@/Pages/Admin/Settings/Blocks/About.vue";
import {Card} from "@/Components/Block";
import Integration from "@/Pages/Admin/Settings/Blocks/Integration.vue";
import Modules from "@/Pages/Admin/Settings/Blocks/Modules.vue";
import System from "@/Pages/Admin/Settings/Blocks/System.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";

const props = defineProps({
    block: {
        type: String,
        default: null
    },
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const tabs = ref(props.block);

const tabComponent = {
    System,
    Integration,
    About,
    Modules
};
</script>
