<script setup>
import {getCurrentInstance, onMounted, ref} from 'vue'
import {router, useForm} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import {useQuasar} from "quasar";
import {RIDAnketa} from "@/entity";
import AnketaReferat from "@/Components/RID/Object/AnketaReferat.vue";
import AnketaCommerce from "@/Components/RID/Object/AnketaCommerce.vue";
import AnketaAuthors from "@/Components/RID/Object/AnketaAuthors.vue";
import AnketaDocuments from "@/Components/RID/Object/AnketaDocuments.vue";
import AnketaResult from "@/Components/RID/Object/AnketaResult.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();

const props = defineProps({
    anketa: {type: RIDAnketa, required: true}
})

const tabs = ref('referat')

const form = useForm({
    comment: null,
})
const declineDialog = ref(false)

const submitForm = (action) => {
    form.patch($route('services.rid.' + action, props.anketa), {
        onSuccess: () => {
            declineDialog.value = false
            router.reload()
        }
    })
}
</script>

<template>
    <Card title-bg="blue-grey-13" title-text="white"
          header-toolbar hide-actions>
        <template v-slot:title>
            <span class="q-mr-sm q-pr-sm border-right">{{ anketa.number }}</span>
            <span :title="anketa.name">{{ anketa.name }}</span>
        </template>

        <template v-slot:headerActions>
            <q-btn icon="mdi-chevron-left"
                   :label="$translate('buttons.back')"
                   @click="router.visit($route('services.rid.list'))"
                   color="white"
                   outline
                   dense
                   no-caps
            />
        </template>
        <div class="q-gutter-x-md q-mb-md">
            <q-btn v-if="anketa.can.edit"
                   icon="mdi-pencil-outline"
                   :label="$translate('buttons.edit')"
                   @click="router.visit($route('services.rid.edit',anketa))"
                   color="dark"
                   outline
                   dense
                   no-caps
            />
            <q-btn v-if="anketa.can.approve"
                   icon="mdi-check"
                   :label="$translate('buttons.to-approve')"
                   @click="submitForm('approve')"
                   color="dark"
                   outline
                   dense
                   no-caps
            />
            <q-btn v-if="anketa.can.accept"
                   icon="mdi-check"
                   :label="$translate('buttons.approve')"
                   @click="submitForm('accept')"
                   color="dark"
                   outline
                   dense
                   no-caps
            />
            <q-btn v-if="anketa.can.decline"
                   icon="mdi-cancel"
                   :label="$translate('buttons.decline')"
                   @click="declineDialog = true"
                   color="dark"
                   outline
                   dense
                   no-caps
            />
            <q-btn v-if="anketa.can.complete"
                   icon="mdi-check-underline"
                   :label="$translate('buttons.complete')"
                   @click="submitForm('complete')"
                   color="dark"
                   outline
                   dense
                   no-caps
            />
        </div>
        <div class="row q-gutter-x-md">
            <div class="col q-gutter-y-md">
                <Card :title="$translate('module-rid.fields.referat-title')" hide-actions>
                    <AnketaReferat :anketa="anketa"/>
                </Card>
                <Card :title="$translate('module-rid.fields.commerce-title')" hide-actions>
                    <AnketaCommerce :anketa="anketa"/>
                </Card>
            </div>
            <div class="col q-gutter-y-md">
                <Card :title="$translate('module-rid.fields.authors-title')" hide-actions no-card-section>
                    <AnketaAuthors :anketa="anketa"/>
                </Card>
                <Card :title="$translate('module-rid.fields.documents-title')" hide-actions no-card-section>
                    <AnketaDocuments :anketa="anketa"/>
                </Card>
                <Card :title="$translate('module-rid.fields.result-title')" hide-actions no-card-section>
                    <AnketaResult :anketa="anketa"/>
                </Card>
            </div>
        </div>
    </Card>
    <q-dialog v-model="declineDialog" no-backdrop-dismiss>
        <Card in-modal show-close-button :title="$translate('Укажите причину отклонения заявки')">
            <q-input v-model="form.comment"
                     type="textarea" rows="5"/>
            <template v-slot:actions>
                <q-btn icon="clear"
                       :label="$translate('buttons.decline')"
                       color="negative"
                       @click="submitForm('decline')"/>
                <q-btn :label="$translate('buttons.cancel')"
                       color="dark"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>
</template>