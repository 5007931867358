<template>
    <Card title-bg="orange-1"
          :title="$translate('module-syexc.proposal.edit.title', {
              'number': _.get(proposal, 'number'),
              'date': moment(_.get(proposal, 'created_at')).format($translate('common.datetime.js'))
          })"
          header-toolbar
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('buttons.back')"
                   @click="router.get($route('services.sys-exp-ctrl.proposal.list'))"
                   icon="o_chevron_left"
                   color="orange-4"
                   dense

            />
        </template>
        <template v-slot:default>
            <ProposalForm :form="form"></ProposalForm>
        </template>
        <template v-slot:actions>
            <q-btn :label="$translate('module-syexc.actions.proposal.save')" @click="submitForm" color="positive"/>
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import Card from "@/Components/Block/Card.vue";
import ProposalForm from "@/Components/Syexc/Proposal/Form/ProposalForm.vue";
import moment from "moment";

const props = defineProps({
    proposal: {
        type: Object,
        required: true
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    description: _.get(props.proposal, 'description'),
    oldFiles: _.get(props.proposal, 'files'),
    files: [],
    comment: _.get(props.proposal, 'comment'),
});

const submitForm = () => {
    form.post($route('services.sys-exp-ctrl.proposal.update', props.proposal), {
        onSuccess: () => router.visit($route('services.sys-exp-ctrl.proposal.list'))
    })

    // sendForm.value = true;
};
</script>
