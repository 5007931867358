<template>
    <q-btn icon="o_fact_check"
           :disable="!sscResearch.permission.validate"
           :title="$translate('валидация ОМ')"
           @click="dialog = true"
           :color="sscResearch.permission.validate?'secondary':null"
           dense no-caps flat/>
    <q-dialog v-model="dialog" no-backdrop-dismiss>
        <Card :title="$translate('Валидация отчетного материала')"
              in-modal show-close-button>
            <Alert variant="info" hide-icon>
                Для подтверждения соответствия отчетного
                материала тематике выполненного проекта, отметьте следующие пункты:
            </Alert>
            <Alert variant="danger" v-if="form.errors.hasOwnProperty('validation')">
                {{ _.get(form.errors, 'validation') }}
            </Alert>
            <q-list>
                <q-item v-if="validationRequirements.includes('file')">
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('Приложенный файл(ы) корректен и является искомым отчетным материалом') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn-toggle
                            v-model="form.validation['file']"
                            spread
                            toggle-color="secondary"
                            color="white"
                            text-color="black"
                            :options="optionsFile"
                        />
                    </q-item-section>
                </q-item>
                <q-item v-if="validationRequirements.includes('project')">
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('Отчетный материал соответствует тематике указанного проекта') }}
                            <b>#{{ project.number }}</b>
                        </q-item-label>
                        <q-item-label caption>{{ project.name }}</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn-toggle
                            v-model="form.validation['project']"
                            spread
                            toggle-color="secondary"
                            color="white"
                            text-color="black"
                            :options="optionsProject"
                        />
                    </q-item-section>
                </q-item>
                <q-item v-if="validationRequirements.includes('ssc')">
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('Отчетный материал имеет аффилиацию с подразделением ') }}
                            <b>{{ sscResearch.ssc.name }}</b>
                        </q-item-label>
                        <q-item-label>
                            <q-select v-model="form.pages"
                                      prefix="на странице(-ах)"
                                      hint="введите номер и нажмите Enter"
                                      multiple dense
                                      use-input use-chips
                                      new-value-mode="add-unique"
                                      hide-dropdown-icon
                                      @new-value="filterPages"
                                      :error="form.errors.hasOwnProperty('pages')"
                                      :error-message="_.get(form.errors,'pages')"/>
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn-toggle
                            v-model="form.validation['ssc']"
                            spread
                            toggle-color="secondary"
                            color="white"
                            text-color="black"
                            :options="optionsSsc"
                        />
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-input v-model="form.comment"
                                 :label="$translate('Комментарий:')"
                                 :hint="$translate('укажите комментарий при необходимости')"
                                 type="textarea"
                                 rows="3"
                                 :error="form.errors.hasOwnProperty('comment')"
                                 :error-message="_.get(form.errors,'comment')"
                        />
                    </q-item-section>
                </q-item>
            </q-list>

            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.send-solution')"
                       color="positive"
                       @click="approveForm"
                />
                <q-btn icon="o_cancel"
                       :label="$translate('buttons.decline')"
                       color="negative"
                       @click="declineForm"
                />
                <q-btn icon="o_close"
                       :label="$translate('buttons.close')"
                       color="dark"
                       @click="dialog = false"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Alert, Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const dialog = ref(false)

const props = defineProps({
    project: {type: Object},
    sscResearch: {type: Object},
    validationRequirements: {type: Array}
})

const form = useForm({
    ssc_id: props.sscResearch.ssc_id,
    validation: {},
    pages: null,
    comment: null,
    action: null
})
const optionsFile = [{label: $translate('buttons.yes'), value: '1'}, {label: $translate('buttons.no'), value: '0'}]
const optionsProject = [{label: $translate('buttons.yes'), value: '3'}, {label: $translate('buttons.no'), value: '0'}]
const optionsSsc = [{label: $translate('buttons.yes'), value: '6'}, {label: $translate('buttons.no'), value: '0'}]

const filterPages = (val, done) => {
    if (parseInt(val) == val) {
        done(val, 'add-unique')
    } else {
        done()
    }
}

const approveForm = () => {
    form.transform((data) => ({
        ...data,
        action: 'approve'
    }))
        .post(route('project.publications.validate', {
            project: props.project,
            projectReporting: props.sscResearch.validating.project_reporting_id
        }), {
            onSuccess: () => {
                dialog.value = false
            }
        })
}
const declineForm = () => {
    form.transform((data) => ({
        ...data,
        action: 'decline'
    }))
        .post(route('project.publications.validate', {
            project: props.project,
            projectReporting: props.sscResearch.validating.project_reporting_id
        }), {
            onSuccess: () => {
                dialog.value = false
            }
        })
}
</script>