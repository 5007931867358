<template>
    <q-select v-model="selected"
              :loading="loading"
              input-debounce="1000"
              :multiple="multiple"
              :options="options"
              options-dense
              :label="label?label:$translate('search.object-visibility.label')"
              @update:model-value="onSelect"
              use-input
              :use-chips="multiple"
              :error-message="error"
              :error="_.size(error)>0"
    >
        <template v-slot:option="option">
            <q-item v-bind="option.itemProps">
                <q-item-section>
                    <q-item-label v-html="option.opt.html"></q-item-label>
                </q-item-section>
            </q-item>
        </template>
        <q-tooltip>{{ $translate('search.object-visibility.hint') }}</q-tooltip>
    </q-select>
</template>

<script setup>
import {getCurrentInstance, ref, onMounted} from "vue";
import axios from "axios";
import _ from "lodash";

const props = defineProps({
    label: {
        type: String,
        default: null
    },
    multiple: {
        type: Boolean,
        required: false,
        default: false
    },
    filters: {
        type: Array,
        required: false,
        default: []
    },
    error: {
        type: String,
        default: null
    }
});

const emit = defineEmits(['selectItem']);

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const selected = ref(null);
const options = ref([]);
const loading = ref(false);

const onSelect = (value) => {
    emit('selectItem', value)
}

const loadItems = () => {
    loading.value = true;
    let params = {m: 'all'};
    axios.get(route('ajax.visibility', params))
            .then((response) => {
                options.value = response.data.results;
            })
            .finally(() => {
                loading.value = false;
            });
}

onMounted(()=>{
    loadItems()
})
// const searchItem = (search, update) => {
//     let params = {m: 'all', q: search,filters: {}};
//     if (search === '') {
//         update(() => {
//             options.value = [];
//         });
//     }
//     update(() => {
//         _.forEach(props.filters, (v, k) => {
//             params['filters'][k] = v;
//         });
//         loading.value = true;
//         axios.get(route('ajax.visibility', params))
//             .then((response) => {
//                 fetchedData.value = response.data.results;
//             })
//             .finally(() => {
//                 loading.value = false;
//             });
//     });
// };
</script>
