<template>
    <Card :title="$translate('datatable.settings.title')"
          in-modal show-close-button header-toolbar>

        <q-input v-model="form.name"
                 :label="$translate('datatable.settings.template-name')"
                 stack-label
                 :error="form.errors.hasOwnProperty('name')"
                 :error-message="_.get(form.errors,'name')"
        />

        <div class="flex no-wrap q-gutter-x-sm items-start q-my-md scroll-x">
            <template v-for="(field,index) in form.fields">
                <div class="column q-gutter-y-sm" style="min-width: 150px;width:20%">

                    <q-input v-model="field.name"
                             :label="$translate('datatable.editor.column-name',{num:(index+1)})"
                             input-class="text-center"
                             stack-label
                             dense
                             :error="form.errors.hasOwnProperty('fields.'+index+'.name')"
                             :error-message="_.get(form.errors,'fields.'+index+'.name')"
                    >
                        <template v-slot:append>
                            <q-btn icon="o_delete"
                                   :title="$translate('datatable.actions.delete-column')"
                                   @click="deleteColumn(index)"
                                   flat dense/>
                        </template>
                    </q-input>
                    <q-item-label caption>{{ $translate('datatable.editor.column-data') }}</q-item-label>
                    <div class="text-negative text-small" v-if="form.errors.hasOwnProperty('fields.'+index+'.data')">
                        {{ _.get(form.errors, 'fields.' + index + '.data') }}
                    </div>
                    <template v-for="(data,ind) in field.data">
                        <div class="row full-width">
                            <q-select v-model="field.data[ind]"
                                      :options="options"
                                      class="col"
                                      dense
                                      options-dense
                                      emit-value
                                      map-options
                                      :error="form.errors.hasOwnProperty('fields.'+index+'.data.'+ind)"
                                      :error-message="_.get(form.errors,'fields.'+index+'.data.'+ind)"
                                      @update:model-value="setColumnName(index,field.data[ind])"
                            >
                                <template v-slot:option="scope">
                                    <q-item v-bind="scope.itemProps">
                                        <q-item-section>
                                            <q-item-label caption>{{ scope.opt.group }}</q-item-label>
                                            <q-item-label>{{ scope.opt.label }}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </template>
                                <template v-slot:selected>
                                    <q-item-label>
                                        {{ field.data[ind].label }}
                                        {{ countSelected(field.data[ind]) }}
                                    </q-item-label>
                                </template>
                            </q-select>
                            <q-btn icon="o_delete"
                                   :title="$translate('datatable.actions.delete-data')"
                                   flat dense
                                   @click="()=>{field.data.splice(ind,1)}"/>
                        </div>
                    </template>
                    <q-btn icon="o_add"
                           :label="$translate('datatable.actions.add-data')"
                           color="info"
                           dense flat no-caps
                           @click="()=>{field.data.push('')}"/>
                </div>
                <q-separator vertical/>
            </template>
        </div>

        <div class="text-negative text-small" v-if="form.errors.hasOwnProperty('fields')">
            {{ _.get(form.errors, 'fields') }}
        </div>

        <q-btn icon="o_add"
               :label="$translate('datatable.actions.add-column')"
               @click="addColumn"
               color="primary"
               dense/>

        <Alert :message="$translate('datatable.info-1')" variant="info"/>
        <Alert :message="$translate('datatable.info-2')" variant="info"/>

        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   color="positive"
                   @click="submitForm"/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {useQuasar} from "quasar";
import {Alert, Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const $q = useQuasar()

const props = defineProps({
    template: {type: Object, default: null}
})

const emit = defineEmits(['onSuccess'])
/**
 * Список основных полей доступных для отображения
 *
 * @type {string[]}
 */
const customSettingsPrimaryFields = [
    'project',
    'state',
    'template',
    'assay',
    'ssc',
    'responsible',
    'author',
    'created',
]
/**
 * Список полей шаблонов доступных для отображения
 *
 */
const customSettingsTemplateFields = ref([])

/**
 * Форма шаблона
 * @type {InertiaForm<{name: null, fields: *[]}>}
 */
const form = useForm({
    name: null,
    fields: []
})

/**
 * Сохранение формы
 */
const submitForm = () => {
    form.post(
        (props.template ?
                route('datatable.settings.research.update', props.template)
                :
                route('datatable.settings.research.save')
        ),
        {
            onSuccess: () => {
                emit('onSuccess')
            }
        }
    )
}

/**
 * Список опций
 * @type {ComputedRef<[]>}
 */
const options = computed(() => {
    let _options = [];
    _.forEach(customSettingsPrimaryFields, (field) => {
        _options.push({
            value: {name: field, label: $translate('research.list.' + field)},
            group: $translate('datatable.settings.primary-fields'),
            label: $translate('research.list.' + field)
        })
    })
    _.forEach(customSettingsTemplateFields.value, (template) => {
        _.forEach(template.fields, (field) => {
            _options.push({
                value: {name: 'field_' + field.name, label: templateFieldLabel(field)},
                group: $translate('datatable.settings.template-fields') + ' ' + template.name,
                label: templateFieldLabel(field)
            })
        })
    })
    return _options;
})

const addColumn = () => {
    form.fields.push({
        name: null,
        data: []
    })
}
const deleteColumn = (index) => {
    form.fields.splice(index, 1)
}

const templateFieldLabel = (field) => {
    if (field.hasOwnProperty('label')) {
        return field.label[$q.lang.isoName];
    }
    return field.name
}

const loadTemplate = () => {
    axios
        .get(route('ajax.research.template', {
            m: 'fieldsToView'
        }))
        .then((response) => {
            customSettingsTemplateFields.value = response.data.results
        })
}
const countSelected = (selected) => {
    let _size = _.size(_.filter(options.value, {value: selected}))
    if (_size > 1) {
        return '(+' + (_size - 1) + ')'
    }
    return ''
}

/**
 * Выставление наименования колонки
 * - если оно было не заполнено
 * - если выбранное значение только одно
 *
 * @param column индекс колонки
 * @param value  выбранное значение
 */
const setColumnName = (column, value) => {
    if (!form.fields[column].name) {
        _.set(form.fields[column], 'name', value.label)
    }
    if (_.size(form.fields[column].data) === 1) {
        _.set(form.fields[column], 'name', value.label)
    }
}

onMounted(() => {
    loadTemplate()
    if (props.template) {
        form.name = _.get(props.template, 'name')
        form.fields = _.get(props.template, 'fields')
    }
})
</script>