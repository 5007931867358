<template>
    <Card :title="_.get(position, 'cadre_position.name')"   actions-align="left" no-card-section>
        <q-card-section>
            <q-field :label="$translate('cadre-department.fields.name')" stack-label>
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ position.cadre_department.name }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('cadre-staff.fields.cadre_position')" stack-label>
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ position.cadre_position.name }}
                    </div>
                </template>
            </q-field>
        </q-card-section>
        <q-separator/>
        <q-list>
            <template v-for="(dutyjourneys,group) in _.groupBy(position.dutyjourney,'type_text')">
                <q-item dense class="bg-grey-1">
                    <q-item-section>
                        <q-item-label>{{ group }}</q-item-label>
                    </q-item-section>
                </q-item>
                <template v-for="(dutyjourney) in dutyjourneys">
                    <StaffDutyjourneyItem :dutyjourney="dutyjourney"/>
                </template>
            </template>
        </q-list>

        <template v-slot:actions>
            <q-btn icon="o_add"
                   :label="$translate('cadre-dutyjourney.actions.add')"
                   @click="openAddDialog"
                   dense
            />
        </template>
    </Card>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('cadre-dutyjourney.title.new')"
              in-modal show-close-button header-toolbar>
            <ProfileDutyjourneyForm v-model="addForm"
                                    :position="position.cadre_position.name + ', ' + position.cadre_department.name"/>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       dense
                       @click="addFormSubmit"
                />
            </template>
        </Card>
    </q-dialog>
</template>
<script setup lang="ts">

import {Card} from "@/Components/Block";
import {getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {CadreStaff} from "@/entity/";
import _ from "lodash";
import {useQuasar} from "quasar";
import ProfileDutyjourneyForm from "@/Components/Cadre/Dutyjourney/ProfileDutyjourneyForm.vue";
import StaffDutyjourneyItem from "@/Components/SSC/Staff/StaffDutyjourneyItem.vue";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;
const $q = useQuasar()

interface Props {
    position: CadreStaff
}

const props = defineProps<Props>()

const addDialog = ref(false)

const openAddDialog = () => {
    addForm.reset()
    addDialog.value = true;
}

const addForm = useForm({
    action: null,
    dutyjourney: {
        cadre_staff_id: props.position.id,
        type: null,
        date_start: null,
        date_end: null,
        days:null,
        city:null,
        target:null,
        file:null,
        comment:null,
        status:null
    },
    comment: null
});

/**/
const addFormSubmit = () => {
    addForm
            .transform((data) => ({
                        ...data.dutyjourney,
                        comment: data.comment
                    })
            )
            .post($route('profile.dutyjourney.store'), {
                onSuccess: () => {
                    addForm.reset()
                    addDialog.value = false;
                }
            });
}
</script>