<template>
    <q-btn icon="o_assignment_ind"
           :label="research.responsible_id?$translate('research.list.responsible-reassign'):$translate('research.list.responsible-assign')"
           @click="openDialog"
           dense no-caps/>
    <q-dialog v-model="dialogResponsible"
              no-backdrop-dismiss>
        <Card :title="$translate('research.list.responsible-modal-title')"
              in-modal show-close-button>
            <q-select v-model="form.user_id"
                      :label="$translate('research.list.responsible-modal-selectuser')"
                      :options="options"
                      option-value="id" emit-value map-options
                      :loading="loading" clearable clear-icon="clear"
                      :error="form.errors.hasOwnProperty('user_id')"
                      :error-message="form.errors['user_id']"
            >
                <template v-slot:no-option="option">
                    <q-item>
                        <q-item-section class="text-negative">
                            {{ $translate('research.list.responsible-modal-emptymember') }}
                        </q-item-section>
                    </q-item>
                </template>
                <template v-slot:option="option">
                    <q-item v-bind="option.itemProps">
                        <q-item-section avatar>
                            <UserAvatar :avatar="option.opt.user.avatar"></UserAvatar>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ option.opt.user.fulled_name }}</q-item-label>
                            <template v-for="(title) in userTitle( option.opt.user)">
                                <span class="q-mr-xs text-italic">{{ title.value }}</span>
                            </template>
                        </q-item-section>
                        <q-item-section class="q-gutter-xs column items-start">
                            <div class="q-gutter-x-xs">
                                <q-chip dense v-if="option.opt.user.ext_login"
                                        :title="$translate('users.fields.extlogin')">
                                    <q-icon name="o_badge"/>
                                    <span class="q-ml-xs">{{ option.opt.user.ext_login }}</span>
                                </q-chip>
                                <q-chip dense>
                                    <q-icon name="o_email"/>
                                    <span class="q-ml-xs">{{ option.opt.user.email }}</span>
                                </q-chip>
                            </div>
                        </q-item-section>
                    </q-item>
                </template>
            </q-select>

            <q-input v-if="!research.responsible_id"
                     v-model="form.date" class="col"
                     mask="date" fill-mask :rules="['date']"
                     :prefix="$translate('research.list.responsible-modal-setdate')" stack-label
                     input-class="text-center"
                     :error="form.errors.hasOwnProperty('date')"
                     :error-message="form.errors['date']">
                <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                            <q-date v-model="form.date" :options="allowedPeriod"
                                    today-btn
                                    minimal
                                    first-day-of-week="1">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup :label="$translate('buttons.select')" color="primary" flat/>
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-input>

            <q-checkbox v-if="research.state < 30"
                        v-model="form.approve"
                        :label="$translate('research.list.responsible-modal-approve')"
                        size="sm"/>

            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="submitForm"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import axios from "axios";
import Card from "@/Components/Block/Card.vue";
import UserAvatar from "@/Components/UserAvatar.vue";
import _ from "lodash";
import moment from "moment";


const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    research: {type: Object, required: true}
})

const emit = defineEmits(['onSuccess'])

const dialogResponsible = ref(false)

const form = useForm({
    user_id: null,
    date: null,
    approve: false
})

const openDialog = () => {
    dialogResponsible.value = true
    loadMembers()
}
const options = ref([])
const loading = ref(false)
const loadMembers = () => {
    loading.value = true;
    axios.get(route('ajax.research', {
        m: 'responsible',
        project_research_id: props.research.id
    }))
        .then((response) => {
            options.value = response.data.results;
        })
        .finally(() => {
            loading.value = false;
        });
}

const submitForm = () => {
    form.patch(route('project.research.assign', props.research), {
        onSuccess: () => {
            dialogResponsible.value = false
            emit('onSuccess')
        }
    })
}

const allowedPeriod = (date) => {
    let d = moment(date).startOf('day')
    let p = moment(props.research.created_at).startOf('day')
    let n = moment().startOf('day')
    let pde = moment(props.research.project.date_end).startOf('day')
    let e = pde.isSameOrBefore(n) ? pde : n;

    return d.isSameOrAfter(p) && d.isSameOrBefore(e)
}
const userTitle = (user) => {
    return _.filter(user.additional, function (item) {
        return item.slug === 'title' || item.slug === 'position'
    })
}
</script>