<template>
    <div class="row justify-center q-gutter-sm">
        <div class="col">
            <q-field :label="$translate('module-sensors.sensors.card.fields.sensor_group')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        {{ _.get(sensor, 'sensor_group.name') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-sensors.sensors.card.fields.sensor_type')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        {{ _.get(sensor, 'sensor_type.name') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-sensors.sensors.card.fields.connection_data.id')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        {{ $translate(`module-sensors.connections.${_.get(sensor, 'connection_data.id')}.name`) }}
                    </div>
                </template>
            </q-field>
            <template v-for="(value, field) in _.get(sensor, 'connection_data')">
                <q-field v-if="hasConnectionField(field)"
                         :label="getConnectionFieldLabel(field)"
                         stack-label
                         borderless
                >
                    <template v-slot:control>
                        <div class="self-center full-width no-outline" tabindex="0">{{ value }}</div>
                    </template>
                </q-field>
            </template>
            <q-field :label="$translate('module-sensors.sensors.card.fields.sensor_ssc')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        {{ _.get(sensor, 'ssc') }}
                    </div>
                </template>
            </q-field>
        </div>
        <div class="col">
            <q-field :label="$translate('module-sensors.sensors.card.fields.state')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        {{ $translate(`module-sensors.sensors.card.fields.${_.get(sensor, 'enabled') ? 'enabled' : 'disabled'}`) }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-sensors.sensors.card.fields.period')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        {{ _.get(sensor, 'period') }}
                    </div>
                </template>
            </q-field>
            <q-field v-if="!_.isNull(_.get(sensor, 'timeout'))"
                     :label="$translate('module-sensors.sensors.card.fields.timeout')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        {{ _.get(sensor, 'timeout') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-sensors.sensors.card.fields.sensor_location.address')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        {{ _.get(sensor, 'sensor_location.address') }}
                    </div>
                </template>
            </q-field>
            <div class="row justify-center q-gutter-sm">
                <div class="col">
                    <q-field :label="$translate('module-sensors.sensors.card.fields.sensor_location.floor')"
                             stack-label
                             borderless
                    >
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">
                                {{ _.get($translate('module-sensors.floors'), _.get(sensor, 'sensor_location.floor')) }}
                            </div>
                        </template>
                    </q-field>
                </div>
                <div class="col">
                    <q-field :label="$translate('module-sensors.sensors.card.fields.sensor_location.room')"
                             stack-label
                             borderless
                    >
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">
                                {{ _.get(sensor, 'sensor_location.room') }}
                            </div>
                        </template>
                    </q-field>
                </div>
            </div>
            <q-field :label="$translate('module-sensors.sensors.card.fields.sensor_users')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        <q-chip v-for="(user, i) in _.get(sensor, 'users')"
                                :key="i"
                                :label="user.fullname"
                                class="cursor-help"
                        >
                            <q-tooltip class="text-body2">{{ user.title }}</q-tooltip>
                        </q-chip>
                    </div>
                </template>
            </q-field>
        </div>
    </div>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import _ from "lodash";

const props = defineProps({
    sensor: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const hasConnectionField = (field) => _.has(
    $translate(`module-sensors.connections.${_.get(props.sensor, 'connection_data.id')}.fields`),
    field
);
const getConnectionFieldLabel = (field) => $translate(
    `module-sensors.connections.${_.get(props.sensor, 'connection_data.id')}.fields.${field}`
);
</script>
