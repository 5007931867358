<template>
    <Card :title="_.get(position, 'cadre_position.name')" hide-actions>
        <q-field :label="$translate('cadre-department.fields.name')" stack-label>
            <template v-slot:control>
                <div class="items-center full-width">
                    {{ _.get(position, 'cadre_department.name') }}
                </div>
            </template>
        </q-field>
        <q-field :label="$translate('cadre-staff.fields.cadre_position')" stack-label>
            <template v-slot:control>
                <div class="items-center full-width">
                    {{ _.get(position, 'cadre_position.name') }}
                </div>
            </template>
        </q-field>
        <q-field :label="$translate('cadre-staff.fields.rate')" stack-label>
            <template v-slot:control>
                <div class="items-center full-width">
                    {{ _.get(position, 'rate_formatted') }}
                </div>
            </template>
        </q-field>
        <q-field :label="$translate('cadre-staff.fields.date_employment')" stack-label>
            <template v-slot:control>
                <div class="items-center full-width">
                    {{ _.get(position, 'date_employment') }}
                </div>
            </template>
        </q-field>
        <q-field :label="$translate('cadre-staff.fields.salary')" stack-label>
            <template v-slot:control>
                <div class="items-center full-width">
                    {{ _.get(position, 'salary_formatted') }}
                </div>
            </template>
        </q-field>
        <q-field :label="$translate('cadre-staff.label.worktime')" stack-label borderless>
            <template v-slot:control>
                <div class="items-center full-width flex">
                    <CadreWorktimeList :worktime="_.get(position, 'worktime')"/>
                    <div class="col-auto">
                        <q-btn icon="o_edit"
                               :title="$translate('cadre-staff.actions.worktime-edit')"
                               @click="worktimeDialog = true"
                               dense flat
                        />
                        <q-dialog v-model="worktimeDialog" no-backdrop-dismiss>
                            <Card :title="$translate('cadre-worktime.title.edit')"
                                  in-modal show-close-button>
                                <CadreWorktimeForm v-model="worktimeForm"/>
                                <template v-slot:actions>
                                    <q-btn icon="o_save"
                                           :label="$translate('buttons.save')"
                                           color="positive"
                                           @click="worktimeFormSubmit"
                                    />
                                </template>
                            </Card>
                        </q-dialog>
                    </div>
                </div>
            </template>
        </q-field>
    </Card>
</template>

<script setup lang="ts">
import {getCurrentInstance, ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {Card} from "@/Components/Block";
import {List as CadreWorktimeList, Form as CadreWorktimeForm} from "@/Components/Cadre/Worktime";
import {CadreStaff} from "@/entity";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

interface Props {
    position: CadreStaff
}

const props = defineProps<Props>()

const worktimeDialog = ref(false)

const worktimeForm = useForm({
    time_start: {
        0: _.get(_.find(props.position.worktime, (d) => d.day === 0), 'time_start'),
        1: _.get(_.find(props.position.worktime, (d) => d.day === 1), 'time_start'),
        2: _.get(_.find(props.position.worktime, (d) => d.day === 2), 'time_start'),
        3: _.get(_.find(props.position.worktime, (d) => d.day === 3), 'time_start'),
        4: _.get(_.find(props.position.worktime, (d) => d.day === 4), 'time_start'),
        5: _.get(_.find(props.position.worktime, (d) => d.day === 5), 'time_start'),
        6: _.get(_.find(props.position.worktime, (d) => d.day === 6), 'time_start'),
    },
    time_end: {
        0: _.get(_.find(props.position.worktime, (d) => d.day === 0), 'time_end'),
        1: _.get(_.find(props.position.worktime, (d) => d.day === 1), 'time_end'),
        2: _.get(_.find(props.position.worktime, (d) => d.day === 2), 'time_end'),
        3: _.get(_.find(props.position.worktime, (d) => d.day === 3), 'time_end'),
        4: _.get(_.find(props.position.worktime, (d) => d.day === 4), 'time_end'),
        5: _.get(_.find(props.position.worktime, (d) => d.day === 5), 'time_end'),
        6: _.get(_.find(props.position.worktime, (d) => d.day === 6), 'time_end'),
    },
})

const worktimeFormSubmit = () => {
    worktimeForm.post($route('cadre.worktime.update', {cadreStaff: props.position.id}),
            {
                onSuccess: () => {
                    worktimeDialog.value = false
                }
            })
}
</script>