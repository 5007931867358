<template>
    <Card v-if="research.claim" :title="$translate('research.show.claim')"
          class="q-mb-md"
          :hide-actions="!research.can.actions.claimResponse"
    >
        <template v-slot:headerActions v-if="research.can.actions.claimDelete">
            <q-btn icon="o_delete"
                   :label="$translate('buttons.delete')"
                   dense
                   flat
                   @click="deleteClaim()"
            />
        </template>
        <q-chat-message
            bg-color="white"
            text-color="negative"
            :name="research.claim.author.full_name"
            :text="[helpers.nl2br(research.claim.claim_text),claimFile(_.get(research.claim,'claim_file'))]"
            text-html
            :stamp="moment(research.claim.created_at).format($translate('common.datetime.js'))"
        />
        <q-chat-message v-if="research.claim.response_text"
                        bg-color="white"
                        text-color="primary"
                        sent
                        :name="research.ssc.director.full_name"
                        :text="[helpers.nl2br(research.claim.response_text),claimFile(_.get(research.claim,'response_file'))]"
                        text-html
                        :stamp="moment(research.claim.updated_at).format($translate('common.datetime.js'))"
        />
        <template v-slot:actions>
            <q-btn :label="$translate('research.list.claim-response-modal-title')"
                   icon="o_question_answer"
                   no-caps
                   color="positive"
                   @click="claimResponseDialog = true"/>
            <q-dialog v-model="claimResponseDialog" no-backdrop-dismiss>
                <Card
                    :title="$translate('research.list.claim-response-modal-title') + ' #' + research.number"
                    in-modal header-toolbar show-close-button>

                    <q-input v-model="claimResponse.response_text"
                             :label="$translate('research.list.claim-response-modal-text-placeholder')"
                             type="textarea" rows="3"
                             :error="claimResponse.errors.hasOwnProperty('response_text')"
                             :error-message="_.get(claimResponse.errors,'response_text')"
                    />
                    <q-file v-model="claimResponse.response_file"
                            :label="$translate('research.list.claim-response-modal-file-placeholder')"
                            accept=".jpg, .png, .gif, .pdf, .doc, .docx"
                            :error="claimResponse.errors.hasOwnProperty('response_file')"
                            :error-message="_.get(claimResponse.errors,'response_file')"
                    />
                    <div class="column q-my-sm">
                        <q-radio v-model="claimResponse.status" val="success"
                                 :label="$translate('project.research.actions.claim-success')"/>
                        <Alert variant="info" v-if="claimResponse.status==='success'"
                               :message="$translate('research.list.claim-response-modal-success')"/>
                        <q-radio v-model="claimResponse.status" val="fail"
                                 :label="$translate('project.research.actions.claim-fail')"/>
                        <Alert variant="info" v-if="claimResponse.status==='fail'"
                               :message="$translate('research.list.claim-response-modal-fail')"/>
                        <div class="text-negative text-small">
                            {{ _.get(claimResponse.errors, 'status') }}
                        </div>
                    </div>

                    <template v-slot:actions>
                        <div class="full-width flex justify-center">
                            <q-checkbox v-model="claimResponse.send"
                                        :label="$translate('research.list.claim-response-modal-send')"/>
                        </div>
                        <q-btn icon="o_send"
                               :label="$translate('project.research.actions.claim-response-send')"
                               color="positive"
                               @click="claimResponseSubmit"
                        />
                    </template>
                </Card>
            </q-dialog>
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {useQuasar} from "quasar";
import moment from "moment/moment";
import helpers from "@/helpers";
import {Card, Alert} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const $q = useQuasar()

const props = defineProps({
    research: {type: Object}
})

const claimFile = (file) => {
    if (file) {
        return "<a href='/storage/" + _.get(file, 'path') + "' target='_blank'>" + _.get(file, 'name') + "</a>";
    }
    return null
}
const claimResponseDialog = ref(false)

const claimResponse = useForm({
    response_text: null,
    response_file: null,
    status: null,
    send: false
})

const claimResponseSubmit = () => {
    claimResponse.post(route('project.research.claim', [props.research, props.research.claim]), {
        onSuccess: () => {
            claimResponseDialog.value = false;
            claimResponse.reset();
            router.reload();
        }
    })
}

const deleteClaim = () => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: 'претензии'}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('project.research.claim-delete', [props.research, props.research.claim]),
            {
                onSuccess: () => {
                    router.reload()
                }
            })
    })
}
</script>