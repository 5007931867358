<template>
    <Card :title="$translate('research-report.title.worktime')" class="q-ma-sm">

        <div class="row q-gutter-x-lg">
            <div class="col-auto">
                <q-input mask="date" v-model="form.date" ref="date"
                         :prefix="$translate('research-report.fields.equipment.date')"
                         :hint="$translate('research-report.fields.equipment.date-placeholder')"
                         input-class="text-center"
                         :error="form.errors.hasOwnProperty('date')"
                         :error-message="_.get(form.errors,'date')">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer" @click="showDateDialog">
                            <q-popup-proxy v-model="dateDialog" cover no-parent-event
                                           transition-show="scale"
                                           transition-hide="scale">
                                <q-date v-model="form.date"
                                        :options="dateOptions"
                                        minimal>
                                    <div class="row items-center justify-end">
                                        <q-btn @click="hideDateDialog"
                                               :label="$translate('buttons.select')"
                                               color="primary" flat/>
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
            </div>
            <div class="col-auto">
                <q-input v-model="form.time"
                         :prefix="$translate('research-report.fields.worktime.time')"
                         :hint="$translate('research-report.fields.worktime.time-placeholder')"
                         input-class="text-center"
                         :error="form.errors.hasOwnProperty('time')"
                         :error-message="_.get(form.errors,'time')"/>
            </div>
            <div class="col" v-if="_.size(equipmentList) > 0">
                <q-select v-model="form.equipment_id"
                          :label="$translate('research-report.fields.worktime.equipment')"
                          :hint="$translate('research-report.fields.worktime.equipment-placeholder')"
                          :options="equipmentList"
                          options-dense
                          clearable
                          clear-icon="clear"
                          emit-value map-options/>
            </div>
        </div>
        <q-input type="textarea" rows="2"
                 v-model="form.comment"
                 :label="$translate('research-report.fields.worktime.comment-placeholder')"
                 :error="form.errors.hasOwnProperty('comment')"
                 :error-message="_.get(form.errors,'comment')"/>

        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.add')"
                   @click="formSubmit"
                   color="positive"
                   dense/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {useReportDataStore} from '@/store/reportDataStore';
import axios from "axios";
import {useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    research: {type: Object, required: true}
})

const reportDataStore = useReportDataStore()

const form = useForm({
    date: null,
    time: null,
    equipment_id: null,
    comment: null
})

const dateDialog = ref(false)

const showDateDialog = () => {
    dateDialog.value = true
}
const hideDateDialog = () => {
    dateDialog.value = false
}

/**
 * Проверка даты на доступность заполнения
 *
 * @param {Date} date
 * @returns {boolean}
 */
const dateOptions = (date) => {
    if (moment(date, 'YYYY/MM/DD').startOf('day').isAfter()) {
        return false
    }
    if (moment(date, 'YYYY/MM/DD').startOf('day').isBefore(moment(props.research.responsible_assign).startOf('day'))) {
        return false
    }
    if (moment(date, 'YYYY/MM/DD').startOf('day').isAfter(moment(props.research.project.date_end).startOf('day'))) {
        return false
    }
    return true
}

const equipmentList = computed(() => {
    if (form.date) {
        let d = moment(form.date, 'YYYY/MM/DD').startOf('day')
        return _.map(
            _.filter(
                reportDataStore.equipmentList,
                (item) => moment(item.used_date).startOf('day').isSame(d)
            ), (item) => ({
                value: item.id,
                label: item.equipment.display_name + ' [' + item.used_time_display + ']'
            }));
    }
    return []
})

const formSubmit = () => {
    form.post(route('project.research.report.worktime.store', props.research),
        {
            onSuccess: () => {
                form.reset();
                reportDataStore.loadWorktime();
            }
        })
}
</script>