<template>
    <div v-if="data.module.science.enabled">
        <q-tabs v-model="tab" align="left" dense no-caps>
            <q-tab name="base" :label="$translate('module-science.settings.name')"/>
            <q-tab name="visibility" :label="$translate('module-science.visibility.name')"/>
        </q-tabs>
        <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="base">
                <Alert v-if="$translate('module-science.description')"
                       variant="info"
                       :message="$translate('module-science.description')"
                />
                <SettingsTable :data="config"/>
            </q-tab-panel>
            <q-tab-panel name="visibility">
                <VisibilitySettings :settings="data.module.science.visibilitySettings"/>
            </q-tab-panel>
        </q-tab-panels>
    </div>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import VisibilitySettings from "@/Components/Science/VisibilitySettings.vue";
import {getCurrentInstance, ref} from "vue";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const tab = ref('base');

const config = [
    {
        name: 'SCIENCE_ENABLED',
        label: $translate('module-science.settings.SCIENCE_ENABLED'),
        value: props.settings.module.SCIENCE_ENABLED,
        description: $translate('module-science.settings.SCIENCE_ENABLED_description'),
        block: 'module',
        type: 'checkbox',
    },
];
</script>
