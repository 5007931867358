<template>
    <q-table ref="dataTable"
             v-model:pagination="pagination"
             :rows="rows"
             :columns="columns"
             row-key="id"
             :filter="filter"
             :rows-per-page-options="rowsPerPageOptions"
             :loading="loading"
             @request="onRequest"
             wrap-cells flat>
        <template v-slot:top-row v-if="allowSearch">
            <q-tr>
                <q-td>
                    <slot name="search-info"/>
                </q-td>
                <q-td colspan="100%">
                    <q-input v-model="filter"
                             :placeholder="searchPlaceholder"
                             clearable
                             clear-icon="clear"
                             borderless
                             dense
                             debounce="1000">
                        <template v-slot:prepend>
                            <q-icon name="search"/>
                        </template>
                    </q-input>
                </q-td>
            </q-tr>
        </template>

        <template v-for="(_,slotName) in ($slots)"
                  v-slot:[slotName]="slotScope" :key="slotName">
            <slot :name="slotName" v-bind="slotScope"/>
        </template>
        <template v-slot:body-cell-actions="props">
            <q-td :props="props" class="no-wrap">
                <DataTableActions :actions="props.row.actions"
                                  :name="props.row.name"
                                  @onSuccess="onSuccess"
                />
            </q-td>
        </template>
        <template v-slot:pagination="scope">
            <q-pagination
                    v-model="pagination.page"
                    color="grey-8"
                    :max="_.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
                    input
                    @update:model-value="onSuccess"
                    input-class="border-bottom q-py-none q-my-auto"
                    input-style="height:70%!important"
            />
            <span class="q-table__bottom-item q-ml-md q-pl-xs">
                {{ $translate('pagination.total-rows') }}: {{ pagination.rowsNumber }}
            </span>
        </template>
    </q-table>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref, watch} from "vue";
import axios from "axios";
import _ from "lodash";
import {useQuasar} from "quasar";
import DataTableActions from "@/Components/DataTableActions.vue";
import {ComponentInternalInstance} from "@vue/runtime-core";

const app: ComponentInternalInstance | null = getCurrentInstance();
const $translate: Function = app!.appContext.config.globalProperties.$translate;

const $q = useQuasar();

const dataTable = ref()
const rows = ref([])
const filter = ref('')
const loading = ref(false)

interface Props {
    /**
     * Request uri
     */
    requestRoute: string,
    /**
     * Table columns
     */
    columns: [],
    /**
     * records per page
     */
    rowsPerPageOptions?: any,
    /**
     * Show search row
     */
    allowSearch?: boolean,
    /**
     * search  field placeholder
     */
    searchPlaceholder?: string,
    /**
     * Searchable string
     */
    search?: string | []
}

const props: any = withDefaults(defineProps<Props>(), {
    rowsPerPageOptions: [10, 50, 100],
    allowSearch: false,
    searchPlaceholder: 'поиск элемента'
})

const emit: any = defineEmits(['onSuccess'])

defineSlots<{
    [key: string]: unknown;
}>();

const pagination: any = ref({
    sortBy: null,
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
})

function onRequest(request: any) {
    const {page, rowsPerPage, sortBy, descending} = request.pagination
    console.log(request.pagination)
    const filter = request.filter

    loading.value = true

    let params = {
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {},
            search: filter
        }
    }

    if (sortBy) {
        _.set(params.datatable['sort'], sortBy, {'field': sortBy, 'direction': descending ? 'desc' : 'asc'});
    }

    axios.get(props.requestRoute, {params: params})
            .then((response) => {
                rows.value = response.data.results.data;

                pagination.value.page = response.data.results.current_page
                pagination.value.rowsPerPage = response.data.results.per_page
                pagination.value.sortBy = sortBy
                pagination.value.descending = descending
                pagination.value.rowsNumber = response.data.results.total

                window.location.hash = "#p" + response.data.results.current_page;
            })
            .finally(() => {
                loading.value = false
            })

}

const onSuccess = () => {
    emit('onSuccess');
    dataTable.value.requestServerInteraction();
};

watch(() => props.search, () => {
    filter.value = props.search;
    onSuccess();
});

onMounted(() => {
    let page = parseInt(window.location.hash.replace(/^#p/, ''));
    if (page) {
        pagination.value.page = page;
    }
    if (props.search) {
        filter.value = props.search;
    }
    onSuccess();
});
</script>

<style lang="scss">
.q-table th {
    font-weight: bold !important;
    white-space: nowrap;
}
</style>