<template>
    <q-btn icon="o_history" v-if="research.assay_received > 0"
           :title="$translate('research.show.assay-title')"
           size="sm"
           class="q-ml-md"
           @click="dialog = true"
           dense/>
    <q-dialog v-model="dialog"
              no-backdrop-dismiss>
        <Card :title="$translate('research.show.assay-title')"
              show-close-button hide-actions in-modal no-card-section>
            <q-list separator dense>
                <q-item>
                    <q-item-section side>
                        <q-item-label style="width:70px">
                            {{ $translate('research.show.history-assay-date') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('research.show.history-assay-count') }} /
                            {{ $translate('research.show.history-assay-comment') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('research.show.history-assay-user') }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <template v-for="(history) in research.assay_list">
                    <q-item>
                        <q-item-section side>
                            <q-item-label style="width:70px">
                                {{ moment(history.date).format($translate('common.date.js')) }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>
                                {{ history.assay }}
                            </q-item-label>
                            <q-item-label v-if="history.comment">
                                <i>{{ history.comment }}</i>
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <UserWithDetail :user="history.responsible"/>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import {useForm} from "@inertiajs/vue3";
import moment from "moment/moment";
import UserWithDetail from "@/Components/User/UserWithDetail.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    research: {type: Object, required: true}
})

const emit = defineEmits(['onSuccess'])

const dialog = ref(false)

const form = useForm({
    assay: (props.research.assay - props.research.assay_received),
    date: null,
    comment: null
})

const maxCount = computed(() => (props.research.assay - props.research.assay_received))

const allowedPeriod = (date) => {
    let d = moment(date).startOf('day')
    let p = moment(props.research.created_at).startOf('day')
    let n = moment().startOf('day')
    let pde = moment(props.research.project.date_end).startOf('day')
    let e = pde.isSameOrBefore(n) ? pde : n;

    return d.isSameOrAfter(p) && d.isSameOrBefore(e)
}

const submitForm = () => {
    form.put(route('project.research.assay.receive', props.research), {
        onSuccess: () => {
            dialog.value = false
            emit('onSuccess')
        }
    })
}
</script>