<template>
    <div>
        <q-list>
            <q-item v-for="(item, i) in list" :key="i">
                <q-item-section>
                    <q-select v-model="form[block][name][item.id]"
                              :label="item.name"
                              :options="fetchedData"
                              :multiple="multiple"
                              behavior="dialog"
                              :emit-value="_.every(fetchedData, _.isObject)"
                              use-chips
                    >
                        <template v-slot:selected>
                            <span v-if="!_.isEmpty(form[block][name][item.id])">
                                <span v-if="multiple === true">
                                    <q-chip v-for="(label, i) in getSelectedLabels(fetchedData, form[block][name][item.id])"
                                            :key="i"
                                            :label="label"
                                    />
                                </span>
                                <span v-else>
                                    <q-chip :label="getSelectedLabel(fetchedData, form[block][name][item.id])" />
                                </span>
                            </span>
                        </template>
                    </q-select>
                </q-item-section>
            </q-item>
        </q-list>
        <div class="q-pa-sm text-center">
            <q-btn color="positive" :label="$translate('buttons.save')" @click="submitForm" :loading="form.processing">
                <template v-slot:loading>
                    <q-spinner-facebook />
                </template>
            </q-btn>
        </div>
    </div>
</template>

<script setup>
import {getCurrentInstance, onBeforeMount, ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import axios from "axios";
import _ from "lodash";

const props = defineProps({
    url: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        default: '',
    },
    block: {
        type: String,
        default: '',
    },
    data: {
        type: Object,
        default: {},
    },
    list: {
        type: Array,
        default: []
    },
    multiple: {
        type: Boolean,
        default: true
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const name = ref(props.name);
const block = ref(props.block);
const data = ref(props.data);
const list = ref(props.list);

const fetchedData = ref([]);

let form = null;

onBeforeMount(() => {
    axios.get(props.url)
        .then((response) => {
            fetchedData.value = _.map(response.data.results, (item) => ({
                value: String(item.value),
                label: String(item.label)
            }));
        });
    let forma = {};
    if (!forma.hasOwnProperty(block.value)) {
        forma[block.value] = {};
    }
    if (!forma[block.value].hasOwnProperty(name.value)) {
        forma[block.value][name.value] = {};
    }
    _.forEach(list.value, (item) => {
        _.set(forma, `${block.value}.${name.value}.${item.id}`, _.get(data.value, item.id));
    });
    form = useForm(forma);
});

const getSelectedLabel = (options, value) => {
    if (_.isArray(value)) {
        value = _.head(value);
    }
    if (_.every(options, _.isObject)) {
        return _.get(
            _.find(options, {'value': value}),
            'label'
        );
    }
    return value;
};
const getSelectedLabels = (options, values) => _.map(values, value => getSelectedLabel(options, value));

const submitForm = () => {
    form.post(route('admin.settings.save'));
};
</script>
