<template>
    <q-item>
        <q-item-section>
            <q-item-label class="ellipsis-2-lines">
                {{ feedback.response }}
            </q-item-label>
            <q-item-label v-if="feedback.ssc" caption>
                <div class="self-center full-width flex no-wrap items-center">
                    <q-icon name="o_business" size="xs"/>
                    <span class="q-ml-xs">{{ feedback.ssc.name }}</span>
                </div>
            </q-item-label>
            <q-item-label v-if="feedback.comment" caption>
                <div class="self-center full-width flex items-center">
                    <q-icon name="o_announcement" size="xs"/>
                    <span class="q-ml-xs">{{ feedback.comment }}</span>
                </div>
            </q-item-label>
        </q-item-section>

        <q-item-section side v-if="feedback.status === 10">
            <q-icon name="o_check"
                    color="positive"
                    :title="$translate('project-feedback.status.'+feedback.status)"/>
        </q-item-section>
        <q-item-section side v-if="feedback.status === 99">
            <q-icon name="o_clear"
                    color="negative"
                    :title="$translate('project-feedback.status.'+feedback.status)"/>
        </q-item-section>
        <q-item-section side>
            <q-btn icon="o_description"
                   :title="$translate('buttons.detail')"
                   flat dense
                   @click="detailDialog = true"/>
            <q-dialog v-model="detailDialog" no-backdrop-dismiss>
                <Card :title="$translate('project.view.feedback-info-detail')"
                      in-modal show-close-button no-card-section header-toolbar>
                    <q-card-section>
                        {{ feedback.response }}
                    </q-card-section>
                    <q-card-section v-if="feedback.file">
                        <q-btn icon="attach_file"
                               :label="feedback.file.name"
                               size="sm"
                               dense flat/>
                    </q-card-section>
                    <q-card-section v-if="feedback.comment" caption>
                        <div class="self-center full-width flex items-center">
                            <q-icon name="o_announcement" size="xs"/>
                            <span class="q-ml-xs">{{ feedback.comment }}</span>
                        </div>
                    </q-card-section>
                    <q-card-section v-if="feedback.ssc" caption>
                        <div class="self-center full-width flex items-center">
                            <q-icon name="o_business" size="xs"/>
                            <span class="q-ml-xs">{{ feedback.ssc.name }}</span>
                        </div>
                    </q-card-section>
                    <template v-slot:actions>
                        <q-btn v-if="feedback.can.approve" icon="o_check"
                               :label="$translate('buttons.approve')"
                               dense
                               color="positive"
                               @click="approveFeedback(feedback)"/>

                        <q-btn v-if="feedback.can.approve" icon="o_clear"
                               :label="$translate('buttons.decline')"
                               dense
                               color="negative"
                               @click="declineFeedback(feedback)"/>

                        <q-btn icon="o_close"
                               :label="$translate('buttons.close')"
                               dense flat
                               color="grey-9"
                               v-close-popup/>
                    </template>
                </Card>
            </q-dialog>
        </q-item-section>
        <q-item-section side v-if="feedback.can.delete">
            <q-btn icon="o_delete"
                   :title="$translate('buttons.delete')"
                   flat dense
                   @click="deleteFeedback(feedback)"/>
        </q-item-section>
    </q-item>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar()

const props = defineProps({
    feedback: {
        type: Object,
    },
})
const detailDialog = ref(false)

/**
 * Удаление отзыва
 *
 * @param feedback
 */
const deleteFeedback = (feedback) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: feedback.response}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('project.feedback.delete', [props.project, feedback]))
    })
}

const approveFeedback = (feedback) => {
    router.patch(route('feedback.approve', [feedback]))
    detailDialog.value = false
}
const declineFeedback = (feedback) => {
    $q.dialog({
        title: 'Подтверждение отклонения',
        message: 'Укажите причину отклонения отзыва',
        prompt: {
            model: '',
            type: 'textarea' // optional
        },
        cancel: true,
        persistent: true
    }).onOk(data => {
        console.debug('>>>> OK, received', data)
        router.post(route('feedback.decline', [feedback]), {comment: data})
        detailDialog.value = false
    }).onCancel(() => {
        // console.log('>>>> Cancel')
    }).onDismiss(() => {
        // console.log('I am triggered on both OK and Cancel')
    })
}
</script>