<template>
    <q-card-section>
        <q-input :label="$translate('report-template.form.fields.name')+':'"
                 stack-label
                 v-model="template.name"
                 :error="template.errors.hasOwnProperty('name')"
                 :error-message="_.get(template.errors,'name')">
        </q-input>
    </q-card-section>
    <q-card-section>
        <q-item-label caption>
            {{ $translate('report-template.form.fields.allow') }}
        </q-item-label>
        <q-option-group v-model="template.allow"
                        :options="allowOptions"
                        size="xs"/>
    </q-card-section>
    <q-card-section>
        <q-item-label caption>
            {{ $translate('report-template.form.fields.usage') }}
        </q-item-label>
        <q-option-group v-model="template.usage"
                        :options="usageOptions"
                        size="xs"/>
    </q-card-section>

    <q-card-section>
        <q-item-label>{{ $translate('report-template.form.fields.data') }}</q-item-label>
        <q-tabs align="left" no-caps v-model="tab">
            <q-tab name="equipment">{{ $translate('report-template.form.label.equipment') }}</q-tab>
            <q-tab name="consumable">{{ $translate('report-template.form.label.consumable') }}</q-tab>
            <q-tab name="worktime">{{ $translate('report-template.form.label.worktime') }}</q-tab>
        </q-tabs>
        <q-tab-panels v-model="tab">
            <q-tab-panel name="equipment" class="q-px-none">
                <Equipment v-model="template.equipment"
                           :additional="(template.usage>0)"/>
            </q-tab-panel>
            <q-tab-panel name="consumable" class="q-px-none">
                <Consumable v-model="template.consumable"
                            :additional="(template.usage>0)"/>
            </q-tab-panel>
            <q-tab-panel name="worktime" class="q-px-none">
                <Worktime v-model="template.worktime"
                          :additional="(template.usage>0)"/>
            </q-tab-panel>
        </q-tab-panels>
    </q-card-section>
    <q-card-section>
        <Alert variant="info" :message="$translate('report-template.form.label.comment_3')"/>
        <Alert variant="info" :message="$translate('report-template.form.label.comment_4')"/>
        <Alert variant="danger" :message="$translate('report-template.form.label.comment_1')"/>
        <Alert variant="danger" :message="$translate('report-template.form.label.comment_2')"/>
    </q-card-section>
    <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary"/>
    </q-inner-loading>
</template>

<script setup>

import {computed, getCurrentInstance, nextTick, onBeforeMount, onMounted, ref} from "vue";
import {useReportDataStore} from '@/store/reportDataStore';
import _ from "lodash";
import {Alert} from "@/Components/Block";
import {Equipment, Consumable, Worktime} from './TemplateList';

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    research: {type: Object, required: true},
})

const reportDataStore = useReportDataStore()

const tab = ref('equipment')
const loading = ref(false)

const template = defineModel({
    type: Object,
    default() {
        return {
            name: null,
            allow: 0,
            usage: 0,
            equipment: {},
            consumable: {},
            worktime: {},
        }
    }
})

const allowOptions = computed(() => {
    return [
        {
            value: props.research.ssc_id,
            label: $translate('report-template.form.fields.allow_all') + ' "' + props.research.ssc.name + '"'
        },
        {
            value: 0,
            label: $translate('report-template.form.fields.allow_me')
        },
    ]
})
const usageOptions = computed(() => {
    return [
        {
            value: props.research.template_id,
            label: $translate('report-template.form.fields.usage_template') + ' "' + props.research.template.name + '"'
        },
        {
            value: 0,
            label: $translate('report-template.form.fields.usage_all')
        },
    ]
})

const prepareEquipment = () => {
    console.log('prepareEquipment', reportDataStore.equipmentList)
    _.forEach(reportDataStore.equipmentList, (item) => {
        if (!(_.get(item, 'deleted_at'))) {
            _.set(template.value.equipment, item.id, {
                checked: false,
                id: item.id,
                equipment_id: item.equipment_id,
                name: item.equipment.display_name,
                time: item.used_time_display,
                assay: item.assay,
                comment: item.comment,
                depend: 0,
                additional: [],
                worktimes: [],
                consumables: [],
                complex_parts: item.complex_parts
            })
        }
    })
}
const prepareConsumables = () => {
    _.forEach(reportDataStore.consumableList, (item) => {
        if (!(_.get(item, 'deleted_at'))) {
            if (item.research_report_equipment_id === 0) {
                _.set(template.value.consumable, item.id, {
                    checked: false,
                    id: item.id,
                    consumable_id: item.consumable.id,
                    name: item.consumable.name,
                    used: item.used_count_formatted + ' ' + item.used_unit_value,
                    unit: item.used_unit,
                    transform: item.transform_id,
                    count: item.used_count,
                    comment: item.comment,
                    depend: 0,
                    additional: [],
                })
            } else {
                if (item.comment.indexOf('[дополнительный расход]') >= 0) {
                    template.value.equipment[item.research_report_equipment_id].consumables.push({
                        checked: false,
                        id: item.id,
                        consumable_id: item.consumable.id,
                        name: item.consumable.name,
                        used: item.used_count_formatted + ' ' + item.used_unit_value,
                        unit: item.used_unit,
                        transform: item.transform_id,
                        count: item.used_count,
                        comment: item.comment,
                        depend: 0,
                        additional: [],
                    })
                }
            }
        }
    })
}
const prepareWorktime = () => {
    _.forEach(reportDataStore.worktimeList, (item) => {
        if (!(_.get(item, 'deleted_at'))) {
            if (item.research_report_equipment_id === 0) {
                _.set(template.value.worktime, item.id, {
                    checked: false,
                    id: item.id,
                    time: item.used_time_display,
                    comment: item.comment,
                    depend: 0,
                    additional: [],
                })
            } else {
                template.value.worktime[item.research_report_equipment_id].consumables.push({
                    checked: false,
                    id: item.id,
                    time: item.used_time_display,
                    comment: item.comment,
                })
            }
        }
    })
}

onBeforeMount(() => {
    loading.value = true
    reportDataStore.setResearch(props.research)
    nextTick(() => {
        reportDataStore.loadEquipments(prepareEquipment)
    })
    nextTick(() => {
        reportDataStore.loadConsumables(prepareConsumables)
    })
    nextTick(() => {
        reportDataStore.loadWorktime(prepareWorktime)
    })
    loading.value = false
})

onMounted(() => {
    loading.value = true
    nextTick(() => {
        prepareEquipment()
    })
    nextTick(() => {
        loading.value = false
    })
})
</script>