<script setup>
import {getCurrentInstance, onMounted, ref} from 'vue'
import {router, useForm} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import {useQuasar} from "quasar";
import AnketaMember from "@/Components/RID/AnketaMember.vue";
import {RIDAnketa, RIDAnketaMember} from "@/entity";
import AnketaData from "@/Components/RID/AnketaData.vue";
import _ from 'lodash'

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();

const anketa = defineModel('anketa', {})
const errors = defineModel('errors', {})


const memberDialog = ref(false)
const editMemberIndex = ref(null)
const member = useForm({
    lastname: null,
    name: null,
    surname: null,

    position: null,
    department: null,

    passport: {},
    address: {},

    contact: {
        phone: null,
        email: null,
    }
})

const openMemberDialog = () => {
    member.reset()
    editMemberIndex.value = null;
    memberDialog.value = true;
}
const addMember = () => {
    console.log(member.value)
    if (editMemberIndex.value !== null) {
        anketa.value.persons[editMemberIndex.value] = _.cloneDeep(member.data());
    } else {
        anketa.value.persons.push(_.cloneDeep(member.data()));
        anketa.value.vklad.push({count: null, content: null});
    }
    memberDialog.value = false;
}
const deleteMember = (index) => {
    anketa.value.persons.splice(index, 1)
    anketa.value.vklad.splice(index, 1)
}
const editMember = (item, index) => {
    member.reset();
    _.forEach(item, (v, k) => {
        _.set(member, k, v)
    })

    editMemberIndex.value = index
    memberDialog.value = true;
}
</script>

<template>
    <q-toolbar class="bg-grey-3">
        <q-toolbar-title>
            {{ $translate('module-rid.fields.authors-title') }}
        </q-toolbar-title>
    </q-toolbar>
    <q-list separator dense>
        <template v-for="(member,index) in anketa.persons">
            <q-item>
                <q-item-section>
                    <q-item-label>
                        {{ member.lastname }}
                        {{ member.name }}
                        {{ member.surname }}
                    </q-item-label>
                </q-item-section>
                <q-item-section>
                    <q-item-label>
                        {{ _.join(_.filter([member.department, member.position]), ', ') || '&mdash;' }}
                    </q-item-label>
                </q-item-section>
                <q-item-section side>
                    <q-btn icon="mdi-pencil-outline"
                           color="dark"
                           @click="editMember(member,index)"
                           dense flat
                    />
                </q-item-section>
                <q-item-section side>
                    <q-btn icon="mdi-delete-outline"
                           color="negative"
                           @click="deleteMember(index)"
                           dense flat
                    />
                </q-item-section>
            </q-item>
        </template>
    </q-list>
    <q-card-actions>
        <q-btn icon="add"
               :label="$translate('buttons.add')"
               @click="openMemberDialog"
               color="blue-grey-13"
               outline
               dense
        />
    </q-card-actions>
    <q-dialog v-model="memberDialog"
              no-backdrop-dismiss no-esc-dismiss>
        <Card :title="$translate('module-rid.title.members-add')"
              in-modal header-toolbar no-card-section>
            <AnketaMember v-model="member"/>

            <template v-slot:actions>
                <q-btn icon="add"
                       :label="$translate('buttons.add')"
                       color="positive"
                       @click="addMember"
                />
                <q-btn icon="close"
                       :label="$translate('buttons.cancel')"
                       color="dark"
                       v-close-popup
                />

            </template>
        </Card>
    </q-dialog>

    <q-toolbar class="bg-grey-3">
        <q-toolbar-title>
            {{ $translate('module-rid.fields.info-title') }}
        </q-toolbar-title>
    </q-toolbar>
    <AnketaData v-model="anketa"/>

</template>