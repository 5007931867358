<template>
    <Card :title="$translate('research-template.title.show',{template: template.name})"
          title-bg="blue-grey-3"
          hide-actions no-card-section
          header-toolbar
    >
        <template v-slot:headerActions>
            <q-btn icon="navigate_before"
                   :label="$translate('research-template.actions.list')"
                   color="blue-grey-6"
                   @click="router.visit($route('research.template'))"
                   no-caps dense no-wrap/>
        </template>
        <q-card-section>
            <q-field :label="$translate('research.form.select-template')"
                     stack-label>
                <template v-slot:control>
                    <div>{{ template.name }}</div>
                </template>
            </q-field>
        </q-card-section>
        <q-card-section v-if="template.assay">
            <q-input v-model="form.assay"
                     :label="$translate('research.form.select-assay')"
                     stack-label
                     input-class="text-center"
                     style="width:500px;"/>
        </q-card-section>
        <FieldsRendering :fields="template.fields"
                         :ssc="template.ssc_id"
                         v-model="form.fields"/>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    template: {type: Object}
})

const form = useForm({
    template_id: null,
    assay: null,
    fields: {}
})
</script>