<template>
    <Card :title="classificator.name" title-bg="deep-purple-1" hide-actions header-toolbar no-card-section>
        <template v-slot:headerActions>
            <q-btn v-if="can.create"
                   dense @click="openAddDialog(0)" color="deep-purple-4"
                   icon="add" :label="$translate('classificator.actions.add')"/>
            <q-btn dense @click="router.visit($route('admin.list.multi.index'))" color="deep-purple-4"
                   icon="navigate_before" :label="$translate('buttons.back')"/>
        </template>
        <q-splitter v-model="splitterModel"
                    :limits="[20,80]"
                    @update:model-value="saveSplitterModel">
            <template v-slot:before>
                <q-input ref="filterRef"
                         v-model="filter" :placeholder="$translate('search.element')"
                         debounce="1000"
                         clear-icon="clear"
                         filled square dense clearable>
                    <template v-slot:prepend>
                        <q-icon name="search"/>
                    </template>
                </q-input>
                <q-scroll-area :style="areaHeight">
                    <q-tree :nodes="items"
                            default-expand-all
                            node-key="id"
                            label-key="name"
                            @lazy-load="onLazyLoad"
                            v-model:selected="selectedItemId"
                            selected-color="teal"
                    >
                        <template v-slot:default-header="prop" class="row">
                            <div :class="{'col':true,'text-grey':!prop.node.checked}">{{ prop.node.name }}</div>
                            <q-btn v-if="can.edit"
                                   icon="add"
                                   color="grey-8"
                                   size="xs"
                                   flat dense
                                   @click="openAddDialog(prop.node.id)"
                                   :title="$translate('buttons.add-child')"
                                   :disable="!prop.node.can.create"/>
                            <q-btn v-if="can.edit"
                                   icon="o_edit"
                                   color="grey-8"
                                   size="xs"
                                   flat dense
                                   @click="loadItem(prop.node.id)"
                                   :title="$translate('buttons.edit')"
                                   :disable="!prop.node.can.edit"/>
                            <q-btn v-if="can.edit"
                                   icon="o_delete"
                                   color="grey-8"
                                   size="xs"
                                   @click="deleteItem(prop.node)"
                                   flat dense
                                   :title="$translate('buttons.delete')"
                                   :disable="!prop.node.can.delete"/>
                        </template>
                    </q-tree>
                </q-scroll-area>
            </template>
            <template v-slot:after>
                <div v-if="selectedItemId && !loading">
                    <q-bar>
                        <div>{{ $translate('classificator.edit.title') }}</div>
                    </q-bar>
                    <q-card-section v-if="selectedItem">
                        <q-item-label overline>{{ $translate('classificator.fields.name') }}</q-item-label>
                        <template v-for="(lang_name,locale) in $translate('fields.locale')">
                            <q-input v-if="selectedItem.can.edit"
                                     v-model="selectedItem.name_i18n[locale]"
                                     :label="lang_name"
                                     dense counter
                                     type="textarea" rows="2"
                                     :error-message="form.errors['name_i18n.'+locale]"
                                     :error="form.errors.hasOwnProperty('name_i18n.'+locale)"/>
                            <q-field v-else
                                     :label="lang_name" stack-label
                                     dense>
                                <template v-slot:control>
                                    <div>{{ selectedItem.name_i18n[locale] }}</div>
                                </template>
                            </q-field>
                        </template>
                    </q-card-section>
                    <q-card-section>
                        <q-item-label overline>{{ $translate('classificator.fields.description') }}</q-item-label>
                        <template v-for="(lang_name,locale) in $translate('fields.locale')">
                            <q-input v-if="selectedItem.can.edit"
                                     v-model="selectedItem.description_i18n[locale]" :label="lang_name" dense counter
                                     type="textarea" rows="3"
                                     :error-message="form.errors['name_i18n.'+locale]"
                                     :error="form.errors.hasOwnProperty('name_i18n.'+locale)"/>
                            <q-field v-else
                                     :label="lang_name" stack-label
                                     dense>
                                <template v-slot:control>
                                    <div>{{ selectedItem.description_i18n[locale] }}</div>
                                </template>
                            </q-field>
                        </template>
                    </q-card-section>
                    <q-card-section v-if="selectedItem.can.edit">
                        <q-checkbox v-model="selectedItem.checked"
                                    size="sm"
                                    :label="$translate('classificator.fields.checked')"/>
                    </q-card-section>
                    <q-card-actions align="center" v-if="selectedItem.can.edit">
                        <q-btn color="positive" icon="o_save" :label="$translate('buttons.save')"
                               @click="updateItem"></q-btn>
                    </q-card-actions>
                </div>
            </template>
        </q-splitter>
    </Card>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('classificator.add.title', {name: props.classificator.name})" title-bg="deep-purple-1"
              in-modal show-close-button no-card-section>
            <q-card-section>
                <q-item-label overline>{{ $translate('classificator.fields.name') }}</q-item-label>
                <template v-for="(lang_name,locale) in $translate('fields.locale')">
                    <q-input v-model="addForm.name_i18n[locale]" :label="lang_name" dense counter
                             :error-message="addForm.errors['name_i18n.'+locale]"
                             :error="addForm.errors.hasOwnProperty('name_i18n.'+locale)"/>
                </template>
            </q-card-section>
            <q-card-section>
                <q-item-label overline>{{ $translate('classificator.fields.description') }}</q-item-label>
                <template v-for="(lang_name,locale) in $translate('fields.locale')">
                    <q-input type="textarea" rows="3" v-model="addForm.description_i18n[locale]" :label="lang_name"
                             dense counter
                             :error-message="addForm.errors['description_i18n.'+locale]"
                             :error="addForm.errors.hasOwnProperty('description_i18n.'+locale)"/>
                </template>
            </q-card-section>
            <template v-slot:actions>
                <q-btn color="positive" icon="o_save" :label="$translate('buttons.add')" @click="submitForm"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {router, useForm} from "@inertiajs/vue3";
import {computed, getCurrentInstance, onMounted, ref, watch} from "vue";
import axios from "axios";

import {useQuasar} from "quasar";
import _ from "lodash";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()
const $q = useQuasar();
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    classificator: {
        type: Object
    },
    can: {
        type: Object
    }
})

const items = ref([])

const selectedItemId = ref(null)

const splitterModel = ref(60)

const saveSplitterModel = (value) => {
    $q.cookies.set('multi_list_split', value, {expires: 365})
}
const getSplitterModel = () => {
    return Number($q.cookies.get('multi_list_split'));
}
const initSplitModel = () => {
    let split = getSplitterModel()
    if (!split || split === 'NaN') {
        split = 50;
    }
    splitterModel.value = split;
}

const areaHeight = computed(() => {
    return {
        height: 'calc(100vh - 180px)'
    }
})
const onLazyLoad = ({node, key, done, fail}) => {
    let params = {
        m: 'multi',
        class: props.classificator.id,
        parent: node.id
    }
    axios.get(route('ajax.classificator'), {
        params: params
    })
            .then((response) => {
                done(response.data.results);
            })
}

const initItems = (filter) => {
    axios.get(route('ajax.classificator'), {
        params: {
            m: 'multi',
            class: props.classificator.id,
            parent: 0,
            search: filter
        }
    })
            .then((response) => {
                // console.log(response.data.results.data)
                items.value = response.data.results;
            })
}
onMounted(() => {
    initSplitModel()
    initItems()
})
const selectedItem = ref({})
const loading = ref(false)
const filter = ref('')
const filterRef = ref(null)
const loadItem = (id) => {
    loading.value = true
    axios.get(route('ajax.classificator'), {
        params: {
            m: 'multiItem',
            class: props.classificator.id,
            id: id
        }
    }).then((response) => {
        let res = response.data.results;
        if (!res.description_i18n) {
            _.set(res, 'description_i18n', {ru: '', en: ''});
        }
        selectedItem.value = res;
    }).finally(() => {
        loading.value = false
    })
}

watch(selectedItemId, () => {
    loading.value = true
    loadItem(selectedItemId.value)
})
watch(filter, () => {
    initItems(filter.value)
})
const addDialog = ref(false)
const addForm = useForm({
    parent_id: 0,
    name_i18n: {
        ru: null,
        en: null
    },
    description_i18n: {
        ru: null,
        en: null
    },
    checked: true
})
const openAddDialog = (parent) => {
    addForm.parent_id = parent;
    addDialog.value = true
}

const submitForm = () => {
    addForm.post(route('admin.list.multi.store', {list: props.classificator.id}),
            {
                onSuccess: () => {
                    addDialog.value = false
                    initItems()
                }
            })
}
const form = useForm({})
const updateItem = () => {
    form.transform((data) => ({
                ...data,
                ...selectedItem.value
            })
    )
            .patch(route('admin.list.multi.update', {list: props.classificator.id, id: selectedItem.value.id}), {
                onSuccess: () => {
                    initItems()
                }
            })
}

const deleteItem = (item) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: item.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    })
            .onOk(() => {
                router.delete(route('admin.list.multi.delete', {list: props.classificator.id, id: item.id}),
                        {
                            onSuccess: initItems
                        })
            })
}
</script>