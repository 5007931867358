<script setup>
import {getCurrentInstance, ref} from 'vue'
import {useQuasar} from "quasar";
import _ from 'lodash'
import {RIDAnketaMember} from "@/entity";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();


const props = defineProps({
    person: {
        type: RIDAnketaMember,
        required: true
    },
    vklad: {
        type: Object,
        required: true,
        default() {
            return {
                count: null,
                content: null
            }
        }
    }
})

const toggle = ref(false)
</script>
<template>
    <q-item>
        <q-item-section side>
            <q-btn :icon="'mdi-chevron-'+(toggle?'down':'right')"
                   @click="toggle = !toggle"
                   dense flat/>
        </q-item-section>
        <q-item-section>
            <q-item-label>
                {{ person.lastname }}
                {{ person.name }}
                {{ person.surname }}
            </q-item-label>
            <q-item-label caption>
                {{ person.position }},
                {{ person.department }}
            </q-item-label>
        </q-item-section>
        <q-item-section class="col-4">
            <q-item-label>
                {{ vklad.count }}
                {{ $translate('units.percent') }}
            </q-item-label>
        </q-item-section>
    </q-item>
    <q-slide-transition>
        <div v-show="toggle">
            <q-item>
                <q-item-section>
                    <q-item-label caption>{{ $translate('module-rid.fields.vklad-content') }}:</q-item-label>
                    <q-item>
                        {{ vklad.content }}
                    </q-item>
                </q-item-section>
            </q-item>
            <q-item>
                <q-item-section>
                    <q-item-label caption>{{ $translate('module-rid.fields.members.contact') }}:</q-item-label>
                    <q-item>
                        {{ _.join(_.values(person.contact), " ") }}
                    </q-item>
                </q-item-section>
            </q-item>
            <q-item>
                <q-item-section>
                    <q-item-label caption>{{ $translate('module-rid.fields.members.passport') }}:</q-item-label>
                    <q-item>
                        {{ _.join(_.values(person.passport), " ") }}
                    </q-item>
                </q-item-section>
            </q-item>
            <q-item>
                <q-item-section>
                    <q-item-label caption>{{ $translate('module-rid.fields.members.address') }}:</q-item-label>
                    <q-item>
                        {{ _.join(_.values(person.address), " ") }}
                    </q-item>
                </q-item-section>
            </q-item>
        </div>
    </q-slide-transition>
</template>