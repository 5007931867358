<template>
    <Card :title="$translate('helpdesk.title.form')"
          title-bg="deep-orange-4"
          title-text="white"
          header-toolbar
          show-close-button
          style="width: 450px; max-width: 90vw;"
    >
        <q-select v-model="form.category"
                  :options="getOptions"
                  :label="$translate('helpdesk.label.category')"
                  :hint="$translate('helpdesk.label.category-hint')"
                  options-dense
                  :error-message="form.errors.category"
                  :error="form.errors.hasOwnProperty('category')"
        >
            <template v-slot:option="scope">
                <q-expansion-item v-if="scope.opt.children"
                                  :label="scope.opt.label"
                                  dense>
                    <template v-for="child in scope.opt.children" :key="child.id">
                        <q-item clickable
                                @click="form.category = child.id"
                                v-close-popup
                                dense
                                class="q-ml-md">
                            {{ child.label }}
                        </q-item>
                    </template>
                </q-expansion-item>
                <q-item v-else
                        clickable
                        @click="form.category = scope.opt.id"
                        v-close-popup
                        dense>
                    {{ scope.opt.label }}
                </q-item>
            </template>
        </q-select>

        <Alert variant="info" hide-icon>
            {{ $translate('helpdesk.label.notify') }}
        </Alert>

        <q-input v-model="form.comment"
                 type="textarea"
                 outlined
                 :label="$translate('helpdesk.label.comment')"
                 :error-message="form.errors.comment"
                 :error="form.errors.hasOwnProperty('comment')"/>

        <div class="q-pa-xs q-mt-md border position-relative" v-if="form.screenshot">
            <q-img :src="form.screenshot"/>
        </div>

        <q-btn v-if="form.screenshot" @click="form.screenshot = null"
               icon="delete"
               :label="$translate('helpdesk.label.deleteScreenshot')"
               color="negative"
               size="sm"
               class="full-width"
               flat
               dense/>

        <q-btn v-if="showScreenshotButton"
               icon="screenshot_monitor"
               :label="$translate('helpdesk.label.takeScreenshot')"
               class="q-pa-xs q-my-md full-width"
               no-caps
               @click="takeScreenshot"
        />
        <q-file v-model="form.files"
                :label="$translate('helpdesk.label.addFile')"
                accept="image/*"
                max-files="3"
                @rejected="onRejected"
                outlined
                use-chips
                multiple
                append>
            <template v-slot:prepend>
                <q-icon name="attach_file"/>
            </template>
        </q-file>

        <template v-slot:actions>
            <div class="text-center column items-center q-gutter-y-sm">
                <q-checkbox v-model="form.confirm"
                            :label="$translate('helpdesk.label.confirm')"
                            size="xs"
                />
                <div class="text-negative" v-if="form.errors.confirm">{{ form.errors.confirm }}</div>
                <q-btn color="positive"
                       @click="createIssue"
                       icon-right="keyboard_double_arrow_right"
                       :label="$translate('helpdesk.actions.send')"
                       :loading="form.processing"
                       no-caps>
                    <template v-slot:loading>
                        <q-spinner-facebook/>
                    </template>
                </q-btn>
            </div>
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref, computed} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import Alert from "../Block/Alert.vue";
import Card from "../Block/Card.vue";
import html2canvas from 'html2canvas';
import {Notify} from "quasar";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const emit = defineEmits(['onSuccess'])

const showScreenshotButton = computed(() => {
    return true;
    //($q.platform.is.safari || $q.platform.is.edg || $q.platform.is.firefox)
})

const form = useForm({
    category: null,
    comment: null,
    screenshot: null,
    files: [],
    confirm: false,
    url: window.location.href,
    browserLocale: window.navigator.language,
    screenResolution: [window.screen.width, window.screen.height].join(' x ')
})

const getOptions = computed(() => {
    let options = [];
    _.each($translate('helpdesk.category'), (child, cat) => {
        if (_.isArray(child)) {
            options.push({
                'id': cat,
                'label': cat,
                'children': _.map(child, (item) => ({'id': cat + " | " + item, 'label': item}))
            })
        } else {
            options.push({
                'id': child,
                'label': child,
            })
        }
    });
    return options;
})

const takeScreenshot = () => {
    let element = document.body;
    html2canvas(element, {
        ignoreElements: function (element) {
            /* Remove element with id="MyElementIdHere" */
            if ('helpdesk-dialog' === element.id) {
                return true;
            }
        }
    }).then(function (canvas) {
        form.screenshot = canvas.toDataURL();
    });
}

const onRejected = (rejectedEntries) => {
    Notify.create({
        type: 'negative',
        position: "right",
        message: `${rejectedEntries.length} file(s) did not pass validation constraints`
    })
}

const createIssue = () => {
    form.post(
        route('helpdesk.createIssue'),
        {
            onSuccess: () => {
                emit('onSuccess')
                form.reset()
            }
        }
    )
}
</script>