<template>
    <Card title-bg="blue-grey-3"
          header-toolbar
          hide-actions
          no-card-section>
        <template v-slot:title>
            <div class="flex no-wrap">
                <DataTableActions :actions="actions"/>
                <span class="q-ml-sm ellipsis">{{ department.name }}</span>
            </div>
        </template>
        <template v-slot:headerActions v-if="department.can.index">
            <q-btn icon="navigate_before"
                   :label="$translate('ssc.actions.back')"
                   color="blue-grey-6"
                   dense no-wrap
                   @click="router.get($route('ssc.list'))"/>
        </template>
        <slot/>
    </Card>
</template>

<script setup lang="ts">
import {router} from "@inertiajs/vue3";
import {getCurrentInstance, ref} from "vue";
import {Card} from "@/Components/Block";
import DataTableActions from "@/Components/DataTableActions.vue";
import _ from "lodash";
import {DataTableAction} from "@/entity";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

interface Props {
    department: any
}

type TableAction = { [path: string]: DataTableAction };

const props = defineProps<Props>()

const actions: any = {
    'common': {
        'icon': 'o_info',
        'label': $translate('ssc.title-common'),
        'action': $route('ssc.show', props.department)
    },
    'staff': {
        'icon': 'o_person',
        'label': $translate('ssc.title-staff'),
        'action': $route('ssc.staff', props.department),
        'hidden': !props.department.can.staff.view
    },
    'staff_worktime': {
        // 'icon': 'o_person',
        'label': "&mdash; " + _.lowerCase($translate('cadre-staff.label.worktime')),
        'action': $route('ssc.staff.worktime', props.department),
        'hidden': !props.department.can.staff.worktime
    },
    'staff_vacation': {
        // 'icon': 'o_person',
        'label': "&mdash; " + _.lowerCase($translate('cadre-vacation.title.menu')),
        'action': $route('ssc.staff.vacation', {ssc: props.department}),
        'hidden': !props.department.can.staff.vacation
    },
    'staff_sicklist': {
        // 'icon': 'o_person',
        'label': "&mdash; " + _.lowerCase($translate('cadre-sicklist.title.menu')),
        'action': $route('ssc.staff.sicklist', {ssc: props.department}),
        'hidden': !props.department.can.staff.sicklist
    },
    'staff_dutyjourney': {
        // 'icon': 'o_person',
        'label': "&mdash; " + _.lowerCase($translate('cadre-dutyjourney.title.menu')),
        'action': $route('ssc.staff.dutyjourney', {ssc: props.department}),
        'hidden': !props.department.can.staff.dutyjourney
    },
    'documents': {
        'icon': 'o_file_present',
        'label': $translate('ssc.title-documents'),
        'action': $route('ssc.documents', props.department),
        'hidden': !props.department.can.documents.view
    },
    'address': {
        'icon': 'o_location_on',
        'label': $translate('ssc.title-address'),
        'action': $route('ssc.address', props.department),
        'hidden': !props.department.can.address.view
    },
    'settings': {
        'icon': 'o_settings',
        'label': $translate('ssc.title-settings'),
        'action': $route('ssc.settings', props.department),
        'hidden': !props.department.can.settings.view
    }
};
</script>