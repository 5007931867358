<template>
    <Card title-bg="deep-orange-2" :title="cardTitle" header-toolbar hide-actions no>
        <template v-slot:headerActions>
            <q-btn v-if="_.get(can, 'back')"
                   :label="$translate('module-ethics.application.card.actions.back')"
                   @click="router.get($route('services.ethics.applications'))"
                   icon="o_chevron_left"
                   color="deep-orange-5"
                   dense
            />
        </template>
        <component :is="ethicBlock('Breadcrumbs')" :state="_.get(application, 'state')"/>
        <div class="row q-gutter-x-sm q-mb-md">
            <component :is="ethicBlock('Controls')" :can="can" :application="application"/>
        </div>
        <div class="row q-gutter-x-md">
            <div class="col">
                <component :is="ethicBlock('Main')" :application="application"/>
            </div>
            <div class="col column q-gutter-y-md">
                <component :is="ethicBlock('Author')" :user="_.get(application, 'user')"/>
                <component :is="ethicBlock('Files')" :output-files="_.get(application, 'output_files')"/>
            </div>
        </div>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import * as EthicsBlocks from "@/Components/Ethics/Block";
import {computed, getCurrentInstance} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    application: Object,
    can: Object
});

const ethicBlock = (block) => EthicsBlocks[block]

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const cardTitle = computed(() => $translate('module-ethics.application.card.title', {
    number: _.get(props.application, 'number')
}));
</script>
