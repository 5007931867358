<template>
    <Card :title="$translate('module-syexc.proposal.card.expert_form.title')">
        <template v-slot:default>
            <q-field :label="$translate('module-syexc.proposal.card.expert_form.fields.direction_name')" stack-label>
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        {{ _.get(proposal, 'direction.name') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-syexc.proposal.card.expert_form.fields.direction_expert')" stack-label>
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        {{ _.get(proposal, 'direction.expert.fulled_name') }}
                    </div>
                </template>
            </q-field>
            <ExpertActDataForm @on-act-data-filled="setActData"
                               :proposal="proposal.number"
                               :country="lists.countries"
                               :act="actData"
            >
                <template v-slot:download-link>
                    <a v-if="_.get(proposal, 'hasAct')"
                       class="text-brand cursor-pointer"
                       :href="actUrl"
                       target="_blank"
                    >
                        <q-icon name="o_file_download" size="sm"/>
                        {{ $translate('module-syexc.proposal.card.expert_form.actions.download_act') }}
                    </a>
                </template>
            </ExpertActDataForm>
            <template v-if="!_.isNull(form)">
                <div v-if="form.errors.hasOwnProperty('act_data')" class="q-pl-md text-negative">
                    {{ form.errors.act_data }}
                </div>
            </template>
            <ExpertConslusionDataForm :conclusion-number="proposal.conclusion_number"
                                      @on-conclusion-data-filled="setConclusionData"
                                      :conclusion="conclusionData"
                                      :proposal="proposal.number"
                                      :country="lists.countries"

            >
                <template v-slot:download-link>
                    <a v-if="_.get(proposal, 'hasConclusion')"
                       class="text-brand cursor-pointer q-mt-sm"
                       :href="conclusionUrl"
                       target="_blank"
                    >
                        <q-icon name="o_file_download" size="sm"/>
                        {{ $translate('module-syexc.proposal.card.expert_form.actions.download_conclusion') }}
                    </a>
                </template>
            </ExpertConslusionDataForm>
            <template v-if="!_.isNull(form)">
                <div v-if="form.errors.hasOwnProperty('conclusion_data')" class="q-pl-md text-negative">
                    {{ form.errors.conclusion_data }}
                </div>
            </template>
            <q-file v-model="actFile"
                    :label="$translate('module-syexc.proposal.card.expert_form.fields.act_file')"
                    :hint="$translate('module-syexc.proposal.card.expert_form.fields.act_file_description')"
                    :error="!_.isNull(form) && form.errors.hasOwnProperty('act_file')"
                    :error-message="_.get(form, 'errors.act_file')"
            >
                <template v-slot:prepend>
                    <q-icon name="attach_file"/>
                </template>
            </q-file>
            <div v-if="_.get(proposal, 'act_file')" class="q-my-sm">
                <a :href="actFileUrl" target="_blank" class="text-brand cursor-pointer q-mt-sm">
                    <q-icon name="o_file_download" size="sm"/>
                    {{ $translate('module-syexc.proposal.card.expert_form.actions.download_act_file') }}
                </a>
            </div>
            <q-input v-model="expertComment"
                     :label="$translate('module-syexc.proposal.card.expert_form.fields.expert_comment')"
                     type="textarea"
                     rows="3"
            />
            <AlertInfo class="q-mt-md">{{ alertInfoText }}</AlertInfo>
        </template>
        <template v-slot:actions>
            <q-btn :label="$translate('module-syexc.proposal.card.expert_form.actions.save')"
                   @click="submitForm(null)"
                   color="primary"
            />
            <q-btn :label="$translate('module-syexc.proposal.card.expert_form.actions.confirm_expert')"
                   @click="dialogs.confirm = !dialogs.confirm"
                   color="positive"
            />
            <q-btn :label="$translate('module-syexc.proposal.card.expert_form.actions.decline_expert')"
                   @click="dialogs.decline = !dialogs.decline"
                   color="negative"
                   class="q-mt-sm"
            />
        </template>
    </Card>
    <q-dialog v-model="dialogs.confirm">
        <Card :title="$translate('module-syexc.proposal.card.expert_form.modal.confirm_expert.title')"
              actions-align="right"
              header-toolbar
        >
            <template v-slot:headerActions>
                <q-btn icon="close" flat round dense v-close-popup/>
            </template>
            <template v-slot:default>
                <AlertSuccess>
                    {{ $translate('module-syexc.proposal.card.expert_form.modal.confirm_expert.success') }}
                </AlertSuccess>
                <AlertInfo>
                    {{ $translate('module-syexc.proposal.card.expert_form.modal.confirm_expert.info') }}
                </AlertInfo>
            </template>
            <template v-slot:actions>
                <q-btn :label="$translate('buttons.cancel')" color="negative" v-close-popup/>
                <q-btn :label="$translate('buttons.confirm')"
                       @click="submitConfirm"
                       color="positive"
                />
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="dialogs.decline">
        <Card :title="$translate('module-syexc.proposal.card.expert_form.modal.decline_expert.title')"
              actions-align="right"
              header-toolbar
        >
            <template v-slot:headerActions>
                <q-btn icon="close" flat round dense v-close-popup/>
            </template>
            <template v-slot:default>
                <AlertInfo>
                    {{ $translate('module-syexc.proposal.card.expert_form.modal.decline_expert.info') }}
                </AlertInfo>
                <q-list>
                    <template
                        v-for="(value, i) in $translate('module-syexc.proposal.card.expert_form.modal.decline_expert.reason')"
                        :key="i">
                        <q-item tag="label" v-ripple>
                            <q-item-section avatar>
                                <q-checkbox v-model="reason" :val="value"/>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ value }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-list>
                <q-input v-model="commentDecline"
                         :label="$translate('module-syexc.proposal.card.expert_form.modal.decline_expert.comment_decline')"
                         type="textarea"
                         rows="2"
                />
            </template>
            <template v-slot:actions>
                <q-btn :label="$translate('buttons.cancel')" color="negative" v-close-popup/>
                <q-btn :label="$translate('buttons.send')"
                       @click="submitDecline"
                       color="positive"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import AlertInfo from "@/Components/Block/AlertInfo.vue";
import AlertSuccess from "@/Components/Block/AlertSuccess.vue";
import Card from "@/Components/Block/Card.vue";
import ExpertActDataForm from "@/Components/Syexc/Proposal/Form/ExpertActDataForm.vue";
import ExpertConslusionDataForm from "@/Components/Syexc/Proposal/Form/ExpertConslusionDataForm.vue";
import {computed, getCurrentInstance, ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    proposal: {
        type: Object,
        required: true
    },
    lists: {
        type: Object,
        required: true
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const actUrl = computed(() => route('services.sys-exp-ctrl.proposal.get-file', {
    proposal: _.get(props.proposal, 'number'),
    type: 'act'
}));
const conclusionUrl = computed(() => route('services.sys-exp-ctrl.proposal.get-file', {
    proposal: _.get(props.proposal, 'number'),
    type: 'conclusion'
}));
const actFileUrl = computed(() => route('services.sys-exp-ctrl.proposal.get-file', {
    proposal: _.get(props.proposal, 'number'),
    type: 'act_file'
}));

const alertInfoText = $translate('module-syexc.proposal.card.expert_form.info', {
    save: $translate('module-syexc.proposal.card.expert_form.actions.save')
});

const dialogs = ref({
    confirm: ref(false),
    decline: ref(false)
});

const actData = ref(props.proposal.act_data);
const conclusionData = ref(props.proposal.conclusion_data);
const actFile = ref(props.proposal.act_file);
const expertComment = ref(props.proposal.expert_comment);

const reason = ref([]);
const commentDecline = ref('');

let form = null;
const formData = {};

const submitForm = (dialog = null) => {
    formData['act_data'] = actData.value;
    formData['conclusion_data'] = conclusionData.value;
    formData['act_file'] = actFile.value;
    formData['expert_comment'] = expertComment.value;
    form = useForm(formData);
    form.post(
        route('services.sys-exp-ctrl.proposal.expert', {
            proposal: _.get(props.proposal, 'number')
        }), {
            onFinish: (page) => {
                if (dialog) {
                    dialogs.value[dialog] = false;
                }
            }
        }
    )
};

const submitConfirm = () => {
    formData['status'] = 30;
    submitForm('confirm');
};
const submitDecline = () => {
    formData['status'] = 22;
    formData['reason'] = reason.value;
    formData['comment_decline'] = commentDecline.value;
    submitForm('decline');
};

const setActData = (data) => {
    actData.value = data;
};
const setConclusionData = (data) => {
    conclusionData.value = data;
};
</script>
