<template>
    <div class="flex q-gutter-x-sm full-width q-mt-sm q-mb-xs">
        <q-btn icon="add"
               :label="$translate('research-template.actions.select-field')"
               size="sm"
               color="dark"
               class="q-mt-xs"
               @click="addField"
               outline/>
    </div>
    <template v-for="(action,index) in actions">
        <div class="row border-bottom q-pb-sm q-mb-sm">
            <div class="col-1 flex items-start">
                <q-btn icon="o_delete"
                       size="sm"
                       color="negative"
                       class="q-mt-md "
                       @click="deleteField(index)"
                       dense
                       flat/>
            </div>
            <div class="col-22">
                <q-select v-model="action.field"
                          :options="fieldsOptions"
                          @update:model-value="()=>{action.actions = []}"
                          :label="$translate('fields.label.actions.field')"
                          dense
                          options-dense
                          emit-value
                          map-options/>
            </div>
            <div v-if="action.field" class="col-22 offset-1 flex column items-start justify-end">
                <template v-for="(act,index) in action.actions">
                    <div class="row full-width q-gutter-x-md">
                        <div class="col">
                            <q-select v-model="act.type"
                                      :options="actionTypeOptions(action.field)"
                                      :label="$translate('fields.label.actions.action')"
                                      @update:model-value="()=>{act.value = null}"
                                      options-dense
                                      dense
                                      emit-value
                                      map-options/>
                        </div>
                        <div class="col" v-if="allowAttrSelect(act.type)">
                            <q-select v-model="act.attr"
                                      :options="actionAttrOptions(action.field)"
                                      :label="$translate('fields.label.actions.attr')"
                                      options-dense
                                      dense
                                      emit-value
                                      map-options/>
                        </div>
                        <div class="col" v-if="allowValueSelect(act.type)">
                            <q-select v-model="act.value"
                                      :options="actionValueOptions(action.field)"
                                      :label="$translate('fields.label.actions.value')"
                                      :multiple="(act.type !== 'sel-val')"
                                      options-dense
                                      dense
                                      emit-value
                                      map-options
                                      use-chips/>
                        </div>
                        <div class="col" v-if="allowValueInput(act.type)">
                            <q-input v-model="act.value"
                                     dense
                                     :label="$translate('fields.label.actions.value')"/>
                        </div>
                        <div class="col-auto flex items-start">
                            <q-btn icon="o_delete"
                                   size="sm"
                                   color="negative"
                                   class="q-mt-md"
                                   @click="deleteAction(action,index)"
                                   dense
                                   flat/>
                        </div>
                    </div>
                </template>
                <q-btn icon="add"
                       :label="$translate('research-template.actions.add-action')"
                       size="sm"
                       color="light-green-9"
                       class="q-ml-sm q-mt-xs"
                       @click="addAction(action.actions)"
                       outline
                       dense
                />
            </div>
        </div>
    </template>
</template>

<script setup>
import {useQuasar} from "quasar";
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch} from "vue";
import _ from "lodash";
import {usePage} from "@inertiajs/vue3";
import ClassificationDB from "@/plugins/ClassificationDB";

const $q = useQuasar();
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    field: {type: Object},
    fields: {type: Array},
})

const actions = defineModel({
    type: Array,
    default: []
})
/**
 * Текущая локаль
 *
 * @type {ComputedRef<string>}
 */
const locale = computed(() => usePage().props.locale)
/**
 * Добавление поля
 */
const addField = () => {
    actions.value.push({
        field: null,
        actions: [],
    })
}

/**
 * Удаление поля
 * @param index
 */
const deleteField = (index) => {
    _.pullAt(actions.value, index)
}

/**
 * Добавление действия с полем
 * @param action
 */
const addAction = (action) => {
    action.push({
        type: null,
        attr: null,
        value: []
    })
}

/**
 * Удаление действия с полем
 * @param action
 * @param index
 */
const deleteAction = (action, index) => {
    _.pullAt(action.actions, index)
}

/**
 * Поля доступные для выбора в действии
 * за исключением текущего поля
 *
 * @type {ComputedRef<{disable: boolean, label: string, value: string}[]>}
 */
const fieldsOptions = computed(() => (_.map(props.fields, (item) => ({
                            value: item.name,
                            label: _.get(item.label, usePage().props.locale),
                            disable: item.name === props.field.name
                        })
                )
        )
)

/**
 * Список доступных действий для выбранного поля
 *
 * @param field Выбранное поле
 * @returns {{label: string, value: string}[]}
 */
const actionTypeOptions = (field) => {
    return _.map($translate('fields.types.' + props.fields.find(x => x.name === field).type + '.actions'), (v, k) => ({
        value: k,
        label: v
    }))
}

/**
 * Список доступных аттрибутов для выбранного поля
 *
 * @param field Выбранное поле
 * @returns {{label: string, value: string}[]}
 */
const actionAttrOptions = (field) => {
    return _.map($translate('fields.types.' + props.fields.find(x => x.name === field).type + '.attr'), (v, k) => ({
        value: k,
        label: v
    }))
}
/**
 * Список значений классификатора для выбранного поля
 *
 * @param name
 * @returns {{label: string, value: string, any}[]}
 */
const actionValueOptions = (name) => {
    let field = props.fields.find(x => x.name === name);
    if (_.get(field.variants, 'type') === 'self') {
        return _.get(field.variants, 'values').map((item) => ({
            value: item.value,
            label: _.get(item.label, usePage().props.locale) || item.value
        }));
    }
    return _.get(options.value, _.get(field.variants, _.get(field.variants, 'type')));
}

/**
 * Доступно изменение аттрибутов поля
 * @param type
 * @returns {boolean}
 */
const allowAttrSelect = (type) => {
    if (type === 'attr') {
        return true;
    }
    return false;
}
/**
 * Доступен выбор значений
 * @param type
 * @returns {boolean}
 */
const allowValueSelect = (type) => {
    switch (type) {
        case "sel-val":
        case "hide-val":
        case "show-val":
            return true;
        default:
            return false;
    }
}
/**
 * Доступен ввод значений
 *
 * @param type
 * @returns {boolean}
 */
const allowValueInput = (type) => {
    switch (type) {
        case "attr":
            return true;
        default:
            return false;
    }
}

const options = ref({})

onMounted(() => {
    /** Подгрузка список из классификаторов в базу браузера **/
    _.forEach(props.fields, (field) => {
        if (_.get(field, 'variants.type') === 'classificator') {
            ClassificationDB
                    .getSimpleList(_.get(field, 'variants.classificator'))
                    .then((r) => {
                        _.set(options.value, _.get(field, 'variants.classificator'), r)
                    })
        }
        if (_.get(field, 'variants.type') === 'science_classificator') {
            ClassificationDB
                    .getMultiList(_.get(field, 'variants.science_classificator'))
                    .then((r) => {
                        _.set(options.value, _.get(field, 'variants.science_classificator'), r)
                    })
        }
    });
})
</script>