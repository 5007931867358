<template>
    <Card :title="$translate('module-delivery.title-address-goods') + ' ' +_.get(address,'address')"
          header-toolbar no-card-section hide-actions>
        <template v-slot:headerActions>
            <q-btn icon="o_add"
                   :label="$translate('module-delivery.actions.add-goods')"
                   dense no-caps
                   color="indigo-3"
                   no-wrap
                   @click="addDialogOpen"
            />
        </template>
        <Alert variant="info" v-if="address.goods_count > 0">
            Выбранные товары для доставки
        </Alert>
        <Alert variant="info" v-else>
            Все доступные товары для доставки
        </Alert>
        <q-table ref="tableRef"
                 row-key="id"
                 :rows="items"
                 :columns="columns"
                 :filter="filter"
                 :loading="loading"
                 @request="onRequest"
                 v-model:pagination="pagination"
                 :rows-per-page-options="[10,50,100]"
                 wrap-cells
                 flat
        >
            <template v-slot:top-row>
                <q-tr>
                    <q-td/>
                    <q-td colspan="100%">
                        <q-input v-model="filter" debounce="1000" :placeholder="$translate('search.element')"
                                 borderless dense
                                 clearable clear-icon="clear">
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:body-cell-name="props">
                <q-td :props="props">
                    <div v-html="props.value"/>
                    <div class="text-small">{{ _.get(props.row, 'type') }}</div>
                </q-td>
            </template>
            <template v-slot:body-cell="props">
                <q-td :props="props">
                    <div v-html="props.value"/>
                </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @on-success="onSuccess"
                    />
                </q-td>
            </template>
        </q-table>
    </Card>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('module-delivery.title-goods-consumable-add')"
              in-modal show-close-button>
            <SearchDeliveryGoods @onSelect="selectGoods" :error="_.get(form.errors,'goods_id')"/>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="formSubmit"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import Card from "@/Components/Block/Card.vue";
import Alert from "@/Components/Block/Alert.vue";
import DataTableActions from "@/Components/DataTableActions.vue";
import SearchConsumables from "@/Components/Search/SearchConsumables.vue";
import SearchDeliveryGoods from "@/Components/Search/SearchDeliveryGoods.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    address: {type: Object}
})

const tableRef = ref();
const filter = ref('');
const loading = ref(false);
const items = ref([]);
const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'name', align: 'left', label: $translate('module-delivery.goods.fields.name'), field: 'name'},
    {name: 'balance', align: 'left', label: $translate('module-delivery.goods.fields.balance'), field: 'balance'},
];

const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});

const onRequest = (request) => {
    const {page, rowsPerPage, sortBy, descending} = request.pagination;
    const filter = request.filter;

    loading.value = true;

    let params = {
        m: (props.address.goods_count > 0 ? 'goods' : 'list'),
        ssc_id: props.address.ssc_id,
        address: props.address.id,
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {}
        }
    };
    if (_.size(filter) > 0) {
        params.datatable['search'] = filter;
    }
    axios.get(route('services.delivery.goods.json'), {
        params: params,
    })
        .then((response) => {
            items.value = response.data.results.data;
            pagination.value.page = response.data.results.current_page;
            pagination.value.rowsPerPage = response.data.results.per_page;
            pagination.value.sortBy = sortBy;
            pagination.value.descending = descending;
            pagination.value.rowsNumber = response.data.results.total;
        })
        .finally(() => {
            loading.value = false;
        });
};

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};
const form = useForm({
    goods_id: null,
})
const addDialog = ref(false)
const addDialogOpen = () => {
    form.reset()
    addDialog.value = true
}

const selectGoods = (value) => {
    form.goods_id = value.value
}
const formSubmit = () => {
    form.post(route('services.delivery.address.goods.store', props.address.id), {
        onSuccess: () => {
            form.reset()
            addDialog.value = false;
            onSuccess()
        }
    })
}
onMounted(() => {
    onSuccess()
});
</script>