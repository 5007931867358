<template>
    <Card title-bg="teal-4" title-text="white"
          header-toolbar no-card-section hide-actions>
        <template v-slot:title>
            <span class="q-mr-sm q-pr-sm border-right">{{ project.number }}</span>
            <span :title="project.name">{{ project.name }}</span>
        </template>
        <template v-slot:headerActions>
            <q-btn icon="navigate_before"
                   color="teal-8"
                   dense no-caps no-wrap
                   @click="router.visit($route('project.my'))">
                <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">
                    {{ $translate('project.actions.list') }}
                </q-item-label>
                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">
                    {{ $translate('project.actions.list') }}
                </q-tooltip>
            </q-btn>
        </template>

        <q-tabs align="left" inline-label v-model="tabs" active-color="teal-6">
            <DataTableActions class="q-ml-sm"
                              :title="$translate('Меню проекта')"
                              :actions="actions"/>
            <q-tab name='about' icon="o_pageview"
                   @click="router.visit($route('project.show',[project]))">
                <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">
                    {{ $translate('project.actions.about') }}
                </q-item-label>
                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">
                    {{ $translate('project.actions.about') }}
                </q-tooltip>
            </q-tab>
            <q-tab v-if="_.get(project,'can.research.list')"
                   name='research'
                   icon="o_biotech"
                   flat class="self-stretch">
                <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">
                    {{ $translate('project.actions.research') }}
                    <span class="text-lowercase" v-if="researchState">
                        ({{
                            $translate('project.menu.research.' + researchState)
                        }})
                    </span>
                </q-item-label>
                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">
                    {{ $translate('project.actions.research') }}
                </q-tooltip>
                <q-menu fit>
                    <q-list dense>
                        <q-item @click="router.visit($route('project.research',[props.project]))" clickable
                                v-close-popup>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('project.menu.research.all') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item @click="router.visit($route('project.research',{project: props.project,state:'new'}))"
                                clickable
                                v-close-popup>
                            <q-item-section>
                                <q-item-label class="text-lowercase">
                                    &mdash; {{ $translate('project.menu.research.new') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item
                                @click="router.visit($route('project.research',{project: props.project,state:'progress'}))"
                                clickable
                                v-close-popup>
                            <q-item-section>
                                <q-item-label class="text-lowercase">
                                    &mdash; {{ $translate('project.menu.research.progress') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item
                                @click="router.visit($route('project.research',{project: props.project,state:'completed'}))"
                                clickable
                                v-close-popup>
                            <q-item-section>
                                <q-item-label class="text-lowercase">
                                    &mdash; {{ $translate('project.menu.research.completed') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item
                                @click="router.visit($route('project.research',{project: props.project,state:'claim'}))"
                                clickable
                                v-close-popup>
                            <q-item-section>
                                <q-item-label class="text-lowercase">
                                    &mdash; {{ $translate('project.menu.research.claim') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-separator/>
                        <q-item v-if="_.get(project,'can.research.create')"
                                @click="router.visit($route('project.research.new',[props.project]))" clickable
                                v-close-popup>
                            <q-item-section side>
                                <q-icon name="o_add" size="xs" color="indigo-5"/>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label class="text-lowercase text-indigo-5">
                                    {{ $translate('project.research.actions.new') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-menu>
            </q-tab>
            <q-tab v-if="_.get(project,'can.delivery.list')"
                   name='delivery' icon="o_local_shipping">
                <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">
                    {{ $translate('project.actions.delivery') }}
                </q-item-label>
                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">
                    {{ $translate('project.actions.delivery') }}
                </q-tooltip>
                <q-menu fit>
                    <q-list dense>
                        <q-item @click="router.visit($route('project.delivery.list',project))" clickable
                                v-close-popup>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('project.menu.delivery.all') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-separator/>
                        <q-item v-if="_.get(project,'can.delivery.create')"
                                @click="router.visit($route('project.delivery.create',{project: props.project}))"
                                clickable>
                            <q-item-section side>
                                <q-icon name="o_add" size="xs" color="indigo-5"/>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label class="text-indigo-5">
                                    {{ $translate('project.menu.delivery.new') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-menu>
            </q-tab>
            <q-tab name='report' icon="o_library_books"
                   v-if="_.get(project,'can.publication.list')">
                <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">
                    {{ $translate('project.actions.report-material') }}
                </q-item-label>
                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">
                    {{ $translate('project.actions.report-material') }}
                </q-tooltip>
                <q-menu fit>
                    <q-list dense>
                        <q-item @click="router.visit($route('project.publications',project))"
                                clickable
                                v-close-popup>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('project.menu.publication.list') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-separator/>
                        <q-item v-if="_.get(project,'can.publication.create')"
                                @click="router.visit($route('project.publications.add',{project: props.project}))"
                                clickable>
                            <q-item-section side>
                                <q-icon name="o_add" size="xs" color="indigo-5"/>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label class="text-indigo-5">
                                    {{ $translate('project.menu.publication.new') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="_.get(project,'can.publication.create')"
                                @click="router.visit($route('publication.my',{assign_project: props.project.number}))"
                                clickable>
                            <q-item-section side>
                                <q-icon name="o_link" size="xs" color="indigo-5"/>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label class="text-indigo-5">
                                    {{ $translate('project.menu.publication.assign') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-menu>
            </q-tab>
<!--            <q-tab name='files' icon="o_picture_as_pdf"-->
<!--                   @click="router.visit($route('project.files.index',project))">-->
<!--                <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">-->
<!--                    {{ $translate('project.actions.files') }}-->
<!--                </q-item-label>-->
<!--                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">-->
<!--                    {{ $translate('project.actions.files') }}-->
<!--                </q-tooltip>-->
<!--            </q-tab>-->
            <q-space/>
            <!--            <q-tab name='export' v-if="_.get(project,'can.export')"-->
            <!--                   @click="router.visit($route('project.export',project))" target="_blank"-->
            <!--                   icon="import_export">-->
            <!--                <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">-->
            <!--                    {{ $translate('buttons.export') }}-->
            <!--                </q-item-label>-->
            <!--                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">-->
            <!--                    {{ $translate('buttons.export') }}-->
            <!--                </q-tooltip>-->
            <!--            </q-tab>-->
            <q-btn-dropdown icon="o_print" class="full-height" flat>
                <template v-slot:label v-if="$q.screen.gt.md">
                    <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">
                        {{ $translate('buttons.print') }}
                    </q-item-label>
                </template>
                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">
                    {{ $translate('buttons.print') }}
                </q-tooltip>
                <q-list separator dense>
                    <q-item clickable v-close-popup :href="$route('project.print',project)" target="_blank">
                        <q-item-section side>
                            <q-icon name="o_print" size="xs"/>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ $translate('buttons.print-preview') }}</q-item-label>
                        </q-item-section>
                    </q-item>

                    <ProjectPrintBarcode :project="project"/>


                </q-list>
            </q-btn-dropdown>
        </q-tabs>
        <slot/>
    </Card>
</template>

<script setup>
import {getCurrentInstance, ref, computed} from "vue";
import {router} from "@inertiajs/vue3";
import _ from 'lodash';
import {useQuasar} from 'quasar';
import {Card} from "@/Components/Block/";
import DataTableActions from "@/Components/DataTableActions.vue";
import ResearchPrintBarcode from "@/Components/ProjectResearch/ResearchPrintBarcode.vue";
import ResearchPrintAssayBarcode from "@/Components/ProjectResearch/ResearchPrintAssayBarcode.vue";
import ProjectPrintBarcode from "@/Components/Project/ProjectPrintBarcode.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar();

const props = defineProps({
    project: {type: Object, required: true},
    tab: {type: String, default: 'about'},
    researchState: {type: String, default: null}
})

const tabs = ref(props.tab)

const hideTab = (tab) => {
    if ($q.screen.gt.md) {
        return true;
    }
    if ($q.screen.lt.md && tabs.value === tab) {
        return true;
    }
    return false;
}
const actions = computed(() => ({
    'project': {
        label: $translate('project.actions.about').toUpperCase(),
        action: $route('project.show', [props.project]),
        icon: 'o_pageview',
    },
    'about': {
        label: "&mdash; " + $translate('project.view.base-info').toLowerCase(),
        action: $route('project.show', [props.project]),
        // icon: 'o_bubble_chart',
        // hidden: !props.project.can.chart
    },
    'files': {
        label: "&mdash; " + $translate('project.actions.files').toLowerCase(),
        action: $route('project.files.index', [props.project]),
        // icon: 'o_bubble_chart',
        // hidden: !props.project.can.chart
    },
    'chart': {
        label: "&mdash; " + $translate('project.actions.chart').toLowerCase(),
        action: $route('project.chart', [props.project]),
        // icon: 'o_bubble_chart',
        hidden: !props.project.can.chart
    },
    'export': {
        label: "&mdash; " + $translate('project.actions.export').toLowerCase(),
        action: $route('project.export', [props.project]),
        // icon: 'o_bubble_chart',
        hidden: !props.project.can.export
    },
    'research': {
        label: $translate('project.actions.research').toUpperCase(),
        action: $route('project.research', [props.project]),
        icon: 'o_biotech',
        hidden: !props.project.can.research.list
    },
    'research-list': {
        label: "&mdash; " + $translate('project.menu.research.all').toLowerCase(),
        action: $route('project.research', [props.project]),
        // icon: 'o_biotech',
        hidden: !props.project.can.research.list
    },
    'research-add': {
        label: "&mdash; " + $translate('project.research.actions.new').toLowerCase(),
        action: $route('project.research.new', [props.project]),
        // icon: 'o_biotech',
        hidden: !props.project.can.research.create
    },
    'delivery': {
        label: $translate('project.actions.delivery').toUpperCase(),
        action: $route('project.delivery.list', [props.project]),
        icon: 'o_local_shipping',
        hidden: !props.project.can.delivery.list
    },
    'delivery-list': {
        label: "&mdash; " + $translate('project.menu.delivery.all').toLowerCase(),
        action: $route('project.delivery.list', [props.project]),
        // icon: 'o_biotech',
        hidden: !props.project.can.delivery.list
    },
    'delivery-add': {
        label: "&mdash; " + $translate('project.menu.delivery.new').toLowerCase(),
        action: $route('project.delivery.create', [props.project]),
        // icon: 'o_biotech',
        hidden: !props.project.can.delivery.create
    },
    'report': {
        label: $translate('project.actions.report-material').toUpperCase(),
        action: $route('project.publications', [props.project]),
        icon: 'o_library_books',
        hidden: !props.project.can.publication.list
    },
    'report-list': {
        label: "&mdash; " + $translate('project.menu.publication.list').toLowerCase(),
        action: $route('project.publications', [props.project]),
        // icon: 'o_biotech',
        hidden: !props.project.can.publication.list
    },
    'report-add': {
        label: "&mdash; " + $translate('project.menu.publication.new').toLowerCase(),
        action: $route('project.publications.add', [props.project]),
        // icon: 'o_biotech',
        hidden: !props.project.can.publication.create
    },
}))
</script>