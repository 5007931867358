<template>
    <q-tabs v-model="tabs" align="left">
        <q-tab name="main" :label="$translate('cadre-staff.label.main-info')"/>
        <q-tab name="vacation" :label="$translate('cadre-staff.label.vacation')"/>
        <q-tab name="sicklist" :label="$translate('cadre-staff.label.sicklist')"/>
        <q-tab name="dutyjourney" :label="$translate('cadre-staff.label.dutyjourney')"/>
        <q-tab name="summary-chart" :label="$translate('cadre-staff.label.summary-chart')"/>
        <!--        <q-tab name="training" :label="$translate('cadre-staff.label.training')"/>-->
        <!--        <q-tab name="skill" :label="$translate('cadre-staff.label.skill')"/>-->
        <!--        <q-tab name="competence" :label="$translate('cadre-staff.label.competence')"/>-->
    </q-tabs>
    <q-tab-panels v-model="tabs">
        <q-tab-panel name="main">
            <MainInfo :staff="staff"/>
        </q-tab-panel>
        <q-tab-panel name="vacation">
            <Vacations :staff="staff"/>
        </q-tab-panel>
        <q-tab-panel name="sicklist">
            <Sicklist :staff="staff"/>
        </q-tab-panel>
        <q-tab-panel name="dutyjourney">
            <Dutyjourney :staff="staff"/>
        </q-tab-panel>
        <q-tab-panel name="summary-chart">
            <SummaryChart :staff/>
        </q-tab-panel>
        <q-tab-panel name="training">

        </q-tab-panel>
        <q-tab-panel name="skill">

        </q-tab-panel>
        <q-tab-panel name="competence">

        </q-tab-panel>
    </q-tab-panels>
</template>

<script setup lang="ts">

import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {CadreStaff} from "@/entity"
import MainInfo from "@/Components/Cadre/Staff/Card/MainInfo.vue";
import Vacations from "@/Components/Cadre/Staff/Card/Vacations.vue";
import Sicklist from "@/Components/Cadre/Staff/Card/Sicklist.vue";
import Dutyjourney from "@/Components/Cadre/Staff/Card/Dutyjourney.vue";
import SummaryChart from "@/Components/Cadre/Staff/Card/SummaryChart.vue";

interface Props {
    staff: CadreStaff
}

const app = getCurrentInstance();
const $translate = app?.appContext.config.globalProperties.$translate;
const $route = app?.appContext.config.globalProperties.$route;

const props = defineProps<Props>()

const tabs = ref('main')

</script>