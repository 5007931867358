<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions no-card-section>

        <q-card-section v-if="_.get(fields,'fields.lang.show')">
            <q-select v-model="publication.lang_id"
                      :label="_.get(fields,'fields.lang.label')"
                      :options="optionsLanguages"
                      stack-label
                      options-dense
                      map-options
                      emit-value
                      clearable clear-icon="clear"
                      :error="errors.hasOwnProperty('lang_id')"
                      :error-message="_.get(errors,'lang_id')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.name.show')">
            <q-input v-model="publication.name"
                     :label="_.get(fields,'fields.name.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('name')"
                     :error-message="_.get(errors,'name')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.subname.show')">
            <q-input v-model="publication.subname"
                     :label="_.get(fields,'fields.subname.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('subname')"
                     :error-message="_.get(errors,'subname')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.referat.show')">
            <q-input v-model="publication.referat"
                     :label="_.get(fields,'fields.referat.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('referat')"
                     :error-message="_.get(errors,'referat')"/>
        </q-card-section>

        <FieldsRendering :fields="_.get(fields,'fields_additional')"
                         v-model="publication.common_fields"
                         :errors="errors"
                         error-prefix="common_fields."/>
    </Card>
</template>

<script setup>
/**************************************************
 *
 *  Результаты исследований - основная информация
 *
 ***************************************************/
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import ClassificationDB from "@/plugins/ClassificationDB";
import {Card} from "@/Components/Block";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

/**
 * Поля блока
 */
const props = defineProps({
    fields: {type: Object}
})
const publication = defineModel('publication', {type: Object})
const errors = defineModel('errors', {type: Object})

const optionsLanguages = ref([])
const loadLanguages = () => {
    ClassificationDB
            .getSimpleList('languages')
            .then((r) => {
                optionsLanguages.value = r
            })
}
onMounted(() => {
    loadLanguages()
})
</script>