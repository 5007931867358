<template>
    <Card :title="$translate('module-syexc.proposal.card.committee_result.title')" class="q-mt-md" hide-actions>
        <template v-slot:default>
            <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label class="text-weight-bold">
                            {{
                                $translate('module-syexc.proposal.card.committee_result.fields.committee_leader', {
                                    leader: _.get(committee, 'leader.data.fulled_name')
                                })
                            }}
                        </q-item-label>
                        <q-item-label v-if="_.get(committee, 'resultLeader')">
                            <div>
                                <span v-html="_.get(committee, 'resultLeader.data.result_text')"></span>
                                ({{ formatDateTime(_.get(committee, 'resultLeader.data.updated_at')) }})
                            </div>
                            <div class="q-mt-xs">{{ _.get(committee, 'resultLeader.data.comment') }}</div>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <template v-for="(member, i) in _.get(committee, 'members.data')" :key="i">
                    <q-item v-if="member">
                        <q-item-section>
                            <q-item-label class="text-weight-bold">
                                {{
                                    $translate('module-syexc.proposal.card.committee_result.fields.committee_member', {
                                        member: _.get(member, 'fulled_name')
                                    })
                                }}
                            </q-item-label>
                            <q-item-label v-if="getResultMember(member)">
                                <div>
                                    <span v-html="_.get(getResultMember(member), 'result_text')"></span>
                                    ({{ formatDateTime(_.get(getResultMember(member), 'updated_at')) }})
                                </div>
                                <div class="q-mt-xs">{{ _.get(getResultMember(member), 'comment') }}</div>
                            </q-item-label>
                        </q-item-section>
                    </q-item>
                </template>

                <q-item v-if="_.get(committee, 'lawyer')">
                    <q-item-section>
                        <q-item-label class="text-weight-bold">
                            {{
                                $translate('module-syexc.proposal.card.committee_result.fields.committee_lawyer', {
                                    lawyer: _.get(committee, 'lawyer.data.fulled_name')
                                })
                            }}
                        </q-item-label>
                        <q-item-label v-if="_.get(committee, 'resultLawyer')">
                            <div>
                                <span v-html="_.get(committee, 'resultLawyer.data.result_text')"></span>
                                ({{ formatDateTime(_.get(committee, 'resultLawyer.data.updated_at')) }})
                            </div>
                            <div class="q-mt-xs">{{ _.get(committee, 'resultLawyer.data.comment') }}</div>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item v-if="_.get(committee, 'lawyerIO')">
                    <q-item-section>
                        <q-item-label class="text-weight-bold">
                            {{
                                $translate('module-syexc.proposal.card.committee_result.fields.committee_lawyer_io', {
                                    lawyer_io: _.get(props.committee, 'lawyerIO.data.fulled_name')
                                })
                            }}
                        </q-item-label>
                        <q-item-label v-if="_.get(committee, 'resultLawyerIO')">
                            <div>
                                <span v-html="_.get(committee, 'resultLawyerIO.data.result_text')"></span>
                                ({{ formatDateTime(_.get(committee, 'resultLawyerIO.data.updated_at')) }})
                            </div>
                            <div class="q-mt-xs">{{ _.get(committee, 'resultLawyerIO.data.comment') }}</div>
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";
import moment from "moment";

const props = defineProps({
    committee: {
        type: Object,
        required: true
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const getResultMember = (member) => _.find(
    _.get(props.committee, 'resultMembers.data'),
    o => o.committee_id === member.id
);

const formatDateTime = (datetime) => moment(datetime).format($translate('common.datetime.js'));
</script>
