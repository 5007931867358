<template>
    <Card :title="$translate('project.view.base-info')" hide-actions class="q-mb-md">
        <template v-slot:headerActions v-if="!printable">
            <q-btn v-if="project.can.edit"
                   icon="o_edit"
                   :label="$translate('buttons.edit')"
                   @click="projectEditDialog = true"
                   dense no-caps/>
            <q-btn v-if="project.can.delete"
                   icon="o_delete"
                   :label="$translate('buttons.delete')"
                   color="negative"
                   @click="deleteProject()"
                   dense no-caps/>
        </template>

        <q-field :label="$translate('project.view.base-info-number')"
                 tag="div"
                 stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width">
                    {{ project.number }}
                </div>
            </template>
        </q-field>
        <q-field :label="$translate('project.view.base-info-name')"
                 tag="div"
                 stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width">
                    {{ project.name }}
                </div>
            </template>
        </q-field>
        <q-field :label="$translate('project.view.base-info-type')"
                 tag="div"
                 stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width">
                    {{ project.type.tree_name }}
                </div>
            </template>
        </q-field>
        <q-field :label="$translate('project.view.base-info-state')"
                 tag="div"
                 stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width">
                    {{ $translate('project.status.' + project.status.status) }}
                </div>
            </template>
        </q-field>
        <q-field :label="$translate('project.view.base-info-timeline')"
                 tag="div"
                 stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width items-center flex">
                    <span>
                        {{ moment(project.date_start).format($translate('common.date.js')) }}
                         &mdash;
                        {{ moment(project.date_end).format($translate('common.date.js')) }}
                    </span>
                    <q-chip v-if="project.expireIn" color="red-6" text-color="white" dense sq>
                        {{ project.expireIn }}
                    </q-chip>
                </div>
            </template>
        </q-field>
        <q-btn v-if="project.can.prolongation && !printable"
               :label="$translate('project.actions.prolongate')"
               icon="update"
               @click="prolongationDialog = true"
               class="q-mb-sm"
               dense no-caps/>
        <SourceDetail :project="project"/>
        <q-field :label="$translate('project.view.base-info-description')"
                 tag="div"
                 stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width">
                    <q-scroll-area :style="scrollDescriptionHeight" visible>
                        <div v-html="helpers.nl2br(project.description)" ref="description"></div>
                    </q-scroll-area>
                </div>
            </template>
        </q-field>
        <q-field :label="$translate('project.view.base-info-targets')"
                 tag="div"
                 stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width">
                    <q-scroll-area :style="scrollTargetsHeight" visible>
                        <div v-html="helpers.nl2br(project.targets)" ref="targets"></div>
                    </q-scroll-area>
                </div>
            </template>
        </q-field>

        <FieldsValues :fields="project.type.fields" :values="project.additional"/>

        <q-field :label="$translate('project.view.base-info-keywords')"
                 tag="div"
                 stack-label borderless>
            <template v-slot:control>
                <div>
                    <template v-for="(keyword) in _.uniq(project.keywords)">
                        <q-chip square dense v-if="keyword">{{ keyword }}</q-chip>
                    </template>
                </div>
            </template>
        </q-field>

        <q-field :label="$translate('project.view.base-info-created')"
                 tag="div"
                 stack-label borderless>
            <template v-slot:control>
                <div class="full-width self-center">
                    {{ moment(project.created_at).format($translate('common.datetime.js')) }}
                    /{{ project.author.full_name }}/
                </div>
            </template>
        </q-field>
    </Card>
    <q-dialog v-model="projectEditDialog" v-if="project.can.edit" full-height full-width>
        <Card :title="$translate('project.title.edit')" show-close-button>
            <q-scroll-area :style="areaHeight">
                <q-card-section>
                    <q-item-label overline>{{ $translate('project.form.fields.name') }}*:</q-item-label>
                    <q-input v-model="projectEditForm.name"
                             type="textarea" rows="3"
                             counter maxlength="10000"
                             :error="projectEditForm.errors.hasOwnProperty('name')"
                             :error-message="_.get(projectEditForm.errors,'name')"/>
                </q-card-section>
                <q-card-section>
                    <q-item-label overline>{{ $translate('project.form.fields.description') }}*:</q-item-label>
                    <q-field
                            :error="projectEditForm.errors.hasOwnProperty('description')"
                            :error-message="_.get(projectEditForm.errors,'description')">
                        <template v-slot:control>
                            <div class="full-width">
                                <q-editor ref="descriptionRef" v-model="projectEditForm.description" @paste="onPasteDescription" flat/>
                            </div>
                        </template>
                    </q-field>
                </q-card-section>
                <q-card-section>
                    <q-item-label overline>{{ $translate('project.form.fields.targets') }}*:</q-item-label>
                    <q-field
                            :error="projectEditForm.errors.hasOwnProperty('targets')"
                            :error-message="_.get(projectEditForm.errors,'targets')">
                        <template v-slot:control>
                            <div class="full-width">
                                <q-editor ref="targetsRef" v-model="projectEditForm.targets" @paste="onPasteTargets" flat/>
                            </div>
                        </template>
                    </q-field>
                </q-card-section>
                <FieldsRendering :fields="project.type.fields_inherit"
                                 v-model="projectEditForm.additional"
                                 :errors="additionalErrors"
                                 error-prefix="additional."
                />
                <q-card-section>
                    <q-item-label overline>{{ $translate('project.form.fields.keywords') }}:</q-item-label>
                    <q-select v-model="projectEditForm.keywords"
                              use-chips
                              use-input
                              new-value-mode="add-unique"
                              multiple
                              :error="projectEditForm.errors.hasOwnProperty('keywords')"
                              :error-message="_.get(projectEditForm.errors,'keywords')">

                    </q-select>
                </q-card-section>
            </q-scroll-area>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="projectEditFormSubmit"/>
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="prolongationDialog" v-if="project.can.prolongation" no-backdrop-dismiss>
        <Card :title="$translate('project.title.prolongation')" in-modal show-close-button>
            <q-item-label>{{ $translate('project.form.fields.period') }}:</q-item-label>
            <div class="row q-gutter-x-md">
                <q-input mask="date" v-model="prolongationForm.date_start"
                         :prefix="$translate('project.form.fields.period-from')"
                         input-class="text-center"
                         class="col"
                         :error="prolongationForm.errors.hasOwnProperty('date_start')"
                         :error-message="_.get(prolongationForm.errors,'date_start')">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                <q-date v-model="prolongationForm.date_start"
                                        mask="YYYY/MM/DD"
                                        minimal>
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup :label="$translate('buttons.select')" color="primary"
                                               flat/>
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
                <q-input mask="date" v-model="prolongationForm.date_end"
                         :prefix="$translate('project.form.fields.period-to')"
                         input-class="text-center"
                         class="col"
                         :error="prolongationForm.errors.hasOwnProperty('date_end')"
                         :error-message="_.get(prolongationForm.errors,'date_end')">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                <q-date v-model="prolongationForm.date_end"
                                        today-btn
                                        mask="YYYY/MM/DD"
                                        minimal first-day-of-week="1">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup :label="$translate('buttons.select')" color="primary"
                                               flat/>
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
            </div>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="prolongationFormSubmit"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {useQuasar} from 'quasar';
import _ from 'lodash';
import helpers from "@/helpers";
import moment from "moment";
import {useElementSize} from '@vueuse/core'
import Card from "@/Components/Block/Card.vue";
import FieldsValues from "@/Components/Fields/FieldsValues.vue";
import SourceDetail from "@/Components/Project/Card/SourceDetail.vue";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar()

const props = defineProps({
    project: {
        type: Object,
        required: true
    },
    printable: {
        type: Boolean,
        default: false
    }
})

const description = ref()
const scrollDescriptionHeight = computed(() => {
    let h = useElementSize(description).height;
    return {height: ((h.value > 200 && !props.printable) ? 200 : h.value) + 'px'};
})

const targets = ref()
const scrollTargetsHeight = computed(() => {
    let h = useElementSize(targets).height;
    return {height: ((h.value > 200 && !props.printable) ? 200 : h.value) + 'px'};
})

const projectEditDialog = ref(false)

const projectEditForm = useForm({
    name: _.get(props.project, 'name'),
    description: _.get(props.project, 'description'),
    targets: _.get(props.project, 'targets'),
    keywords: _.get(props.project, 'keywords') || [],
    additional: _.get(props.project, 'additional') || {},
})

const descriptionRef = ref()
const targetsRef = ref()

const projectEditFormSubmit = () => {
    projectEditForm.post(route('project.update', props.project), {
        onSuccess: () => {
            projectEditDialog.value = false;
            router.reload()
        }
    })
}
const updateFields = (fields) => {
    _.set(projectEditForm, 'additional', fields)
}
const additionalErrors = computed(() => {
    let _errors = {}
    _.forEach(projectEditForm.errors, (error, key) => {
        if (_.includes(key, 'additional.')) {
            _.set(_errors, _.replace(key, 'additional.', ''), error)
        }
    })
    return _errors
})
const areaHeight = computed(() => {
    return {
        height: 'calc(100vh - 180px)'
    }
})

const prolongationDialog = ref(false)
const prolongationForm = useForm({
    date_start: moment(_.get(props.project, 'date_start')).format('YYYY/MM/DD'),
    date_end: moment(_.get(props.project, 'date_end')).format('YYYY/MM/DD'),
})
const prolongationFormSubmit = () => {
    prolongationForm.post(route('project.prolongation', props.project), {
        onSuccess: () => {
            prolongationDialog.value = false
            router.reload()
        }
    })
}
const deleteProject = () => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: props.project.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('project.delete', props.project))
    })
}

const onPasteDescription = (evt) => {
    helpers.sanitizeOnPaste(evt, descriptionRef)
}
const onPasteTargets = (evt) => {
    helpers.sanitizeOnPaste(evt, targetsRef)
}
</script>