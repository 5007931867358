<template>
    <Card v-if="_.size(project.type.expected_result_array)>0"
          :title="$translate('project.view.expectedresult-info')"
          class="q-mb-md"
          no-card-section
          :hide-actions="!project.can.edit || printable"
    >
        <q-list separator>
            <template v-for="(item,index) in project.expectedresult">
                <q-item v-if="item.count">
                    <q-item-section class="col">
                        <q-item-label class="ellipsis">{{ expectedResultClassification[index] }}</q-item-label>
                        <q-item-label caption>{{ item.comment }}</q-item-label>
                    </q-item-section>
                    <q-item-section class="col-4">
                        <q-item-label class="text-center text-big">
                            {{ item.count }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
        <template v-slot:actions v-if="project.can.edit">
            <q-btn icon="o_edit"
                   :label="$translate('buttons.edit')"
                   color="teal-1"
                   text-color="teal-9"
                   class="q-ml-none q-mr-auto"
                   @click="openFormDialog"
                   no-caps dense/>
            <q-dialog v-model="formDialog" no-backdrop-dismiss>
                <Card :title="$translate('project.view.expectedresult-info')"
                      in-modal show-close-button no-card-section>
                    <q-list separator>
                        <template v-for="(expectedResult) in project.type.expected_result_array">
                            <q-item>
                                <div class="full-width">
                                    <q-input v-model="formData[expectedResult.slug]['count']"
                                             :prefix="expectedResult.name"
                                             stack-label
                                             input-class="text-center"
                                             mask="#" reverse-fill-mask
                                    ></q-input>
                                    <q-input v-model="formData[expectedResult.slug]['comment']"
                                             :label="$translate('project.form.fields.expected-result-comment')"
                                             stack-label></q-input>
                                </div>
                            </q-item>
                        </template>
                    </q-list>
                    <template v-slot:actions>
                        <q-btn icon="o_save"
                               :label="$translate('buttons.save')"
                               color="positive"
                               @click="submitForm"
                        />
                        <q-btn icon="o_close"
                               :label="$translate('buttons.cancel')"
                               color="dark"
                               v-close-popup
                        />
                    </template>
                </Card>
            </q-dialog>
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import {Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    project: {
        type: Object,
        required: true
    },
    printable: {
        type: Boolean,
        default: false
    }
})

const expectedResultClassification = usePage().props.classificator.expectedResult;

const formDialog = ref(false)
const form = useForm({})
const formData = ref({})
const openFormDialog = () => {
    _.forEach(props.project.type.expected_result_array, (expRes) => {
        _.set(formData.value, expRes.slug, {count: null, comment: null})
        let current_value = _.get(props.project.expectedresult, expRes.slug)
        if (current_value) {
            _.set(formData.value, expRes.slug, {count: current_value.count, comment: current_value.comment})
        }
    })
    formDialog.value = true
}
const submitForm = () => {
    form
        .transform((data) => ({
            ...data,
            'expectedresult': formData.value
        }))
        .post(route('project.updateExpectedResult', props.project), {
            onSuccess: () => {
                formDialog.value = false;
                router.reload()
            }
        })
}
</script>