<template>
    <Card :title="$translate('project.view.chart')" no-card-section hide-actions>
        <div style="height: calc(100vh - 16rem)">
            <q-inner-loading :showing="loading">
                <q-spinner-gears size="50px" color="primary"/>
                <q-item-label class="text-body1 text-primary q-mt-md">
                    {{ $translate('Загрузка данных графика') }}
                </q-item-label>
            </q-inner-loading>
            <Chart v-if="!_.isEmpty(chartData)"
                   chart-type="force-directed-tree"
                   :data="chartData"
                   :config="chartConfig"
                   height="100%"
            />
        </div>
    </Card>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref, Ref} from "vue";
import {AnyValuesObject} from "@/entity/types";
import {IForceDirectedTreeChartConfig} from "@/entity/charts";
import {Card} from "@/Components/Block";
import Chart from "@/Components/Chart.vue";
import {ComponentInternalInstance} from "@vue/runtime-core";
import axios from "axios";
import _ from "lodash";

const { projectId } = defineProps<{projectId: number}>();

const app: ComponentInternalInstance | null = getCurrentInstance();
const $translate: Function = app!.appContext.config.globalProperties.$translate;
const $route: Function = app!.appContext.config.globalProperties.$route;

const chartData: Ref<AnyValuesObject> = ref<AnyValuesObject>({});
const chartConfig: Ref<IForceDirectedTreeChartConfig> = ref<IForceDirectedTreeChartConfig>({
    forceDirectedSeries: {
        singleBranchOnly: false,
        topDepth: 0,
        downDepth: 1,
        upDepth: 1,
        initialDepth: 1,
        valueField: "value",
        categoryField: "short_name",
        idField: "name",
        childDataField: "children",
        linkWithField: "link",
        centerStrength: 1,
        minRadius: 3,
        maxRadius: 10,
        manyBodyStrength: -20,
        cursorOverStyle: "pointer",
        nodePadding: 10
    },
    forceDirectedSeriesLinksTemplateSettings: {
        strokeWidth: 2,
        strokeOpacity: 0.8
    },
    forceDirectedSeriesNodesTemplateTooltipText: "[bold]{name}[/]",
    forceDirectedSeriesCirclesTemplateSettings: {
        templateField: "nodeSettings"
    }
});

const loading: Ref<boolean> = ref<boolean>(false);

const fetchData = () => {
    loading.value = true;
    axios
        .get($route("ajax.project", {m: "chartData", project_id: projectId}))
        .then((response) => {
            chartData.value = response.data.results;
            chartConfig.value.exportingSettings = {
                filePrefix: chartData.value[chartConfig.value.forceDirectedSeries.categoryField]
            };
            let children: AnyValuesObject[] = [];
            _.forEach(chartData.value[chartConfig.value.forceDirectedSeries.childDataField], (item) => {
                children.push(Object.assign({}, item, {
                    [chartConfig.value.forceDirectedSeries.childDataField]: _.toArray(
                        item[chartConfig.value.forceDirectedSeries.childDataField]
                    )
                }));
            });
            chartData.value[chartConfig.value.forceDirectedSeries.childDataField] = children;
        })
        .catch((e) => {
            console.error(e);
        })
        .finally(() => {
            loading.value = false;
        });
};

onMounted(() => {
    fetchData();
});
</script>
