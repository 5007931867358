<script setup lang="ts">
import {getCurrentInstance} from "vue";
import {useQuasar} from "quasar";
import moment from "moment";
import _ from "lodash";
import {Alert, Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route
const $q = useQuasar();

interface Props {
    project: object,
    files: any[]
}

const props = defineProps<Props>()

const getFileName = (name: string) => {

    return moment(_.first(_.split(_.last(_.split(name,'/')),'.')), 'YYYYMMDDHHmmss').format($translate('common.datetime.js'));
}
</script>

<template>
    <Card :title="$translate('project.export.title-exported')"
          header-toolbar no-card-section hide-actions>
        <q-list separator>
            <template v-for="(file) in files">
                <q-item clickable
                        :href="$route('project.export.file',[project, _.first(_.split(_.last(_.split(file,'/')),'.'))])"
                        target="_blank">
                    <q-item-section side>
                        <q-icon name="o_folder_zip"/>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('buttons.ot') }}
                            {{ getFileName(file) }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>
</template>