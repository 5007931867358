<template>
    <q-card v-if="!_.isEmpty(chartData)" class="q-mt-md">
        <Chart chart-type="line-date" :config="chartConfig" :legend="chartLegend" :data="chartData"/>
    </q-card>
</template>

<script setup lang="ts">
import Chart from "@/Components/Chart.vue";
import {getCurrentInstance, onMounted, Ref, ref} from "vue";
import {ComponentInternalInstance} from "@vue/runtime-core";
import {ILineDateChartConfig} from "@/entity/charts";
import _ from "lodash";
import axios from "axios";

const { consumableId } = defineProps<{consumableId: number}>();

const app: ComponentInternalInstance | null = getCurrentInstance();
const $route: Function = app!.appContext.config.globalProperties.$route;

const chartData: Ref<{}> = ref<{}>({});
const chartLegend: Ref<{}> = ref<{}>({});
const chartConfig: Ref<ILineDateChartConfig> = ref<ILineDateChartConfig>({
    chartSettings: {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true
    },
    chartColors: [
        "#FF0000",
        "#0000FF",
        "#FF8C00",
        "#7CFC00",
        "#FF1493",
        "#808000",
        "#800000",
        "#008000",
        "#9932CC",
        "#00FFFF",
        "#D2691E",
        "#FF00FF",
        "#B8860B"
        // "#FF2400", "#FFA500", "#FFC75F", "#66FF00", "#FFC75F", "#337AB7", "#FFC75F", "#845EC2", "#D65DB1", "#F9F871"
    ],
    chartWidgets: {
        cursor: { behavior: "none" },
        scrollbarX: {
            orientation: "horizontal",
            maxHeight: 6
        }
    },
    valueYAxis: {
        numberFormat: "#.00' ₽'",
        extraTooltipPrecision: 1
    },
    dateXAxis: {
        baseInterval: {
            timeUnit: "day",
            count: 1
        },
        maxDeviation: 0
    },
    lineSeries: {
        valueYField: "price",
        valueXField: "date",
        tooltipLabelText: "{price_formatted}"
    }
});

onMounted(() => {
    axios
        .get($route("ajax.consumables", {
            m: "chartSettings",
            consumable_id: consumableId
        }))
        .then(response => {
            const { data, legend } = response.data.results;
            chartData.value = data;
            chartLegend.value = legend;
        });
});
</script>
