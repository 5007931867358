<template>
    <div class="flex justify-end items-center q-gutter-sm">
        <q-btn v-if="reportData.can.reload"
               :label="$translate('module-analytics.actions.renew-report')"
               @click="router.get($route('analytics.report.standard.show', {
                   report: reportData.report,
                   report_data: reportData.id
               }), {
                   'reload': true
               })"
               icon="o_refresh"
               color="positive"
               size="sm"
        />
        <q-btn v-if="reportData.can.save"
               :label="$translate('module-analytics.actions.save-report')"
               @click="modal = !modal"
               icon="o_save"
               color="primary"
               size="sm"
        />
        <q-dialog v-if="reportData.can.save" v-model="modal">
            <q-card style="width: 300px; max-width: 80vw">
<!--                <q-toolbar>
                    <q-toolbar-title>{{ _.toUpper($translate('module-analytics.actions.save-report')) }}</q-toolbar-title>
                </q-toolbar>-->
                <q-card-section>
                    <q-input v-model="comment"
                             :label="$translate('module-analytics.fields.save-report.comment')"
                             type="textarea"
                             rows="3"
                    />
                    <q-select v-model="tags"
                              :label="$translate('module-analytics.fields.save-report.tags')"
                              new-value-mode="add"
                              input-debounce="0"
                              hide-dropdown-icon
                              use-chips
                              use-input
                              multiple
                              dense
                    />
                </q-card-section>
                <q-card-actions align="center">
                    <q-btn :label="$translate('buttons.save')" color="positive" @click="submitForm" />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <q-btn v-if="reportData.can.export"
               :label="$translate('module-analytics.actions.export-report')"
               :href="$route('analytics.report.standard.show', {
                   report: reportData.report,
                   report_data: reportData.id,
                   'export': true
               })"
               target="_blank"
               icon="o_file_download"
               color="primary"
               size="sm"
        />
        <q-btn v-if="reportData.can.back"
               :label="$translate('module-analytics.actions.back-to-report')"
               @click="router.visit($route('analytics.report.standard.list', {report: reportData.report}))"
               icon="o_chevron_left"
               color="dark"
               size="sm"
        />
    </div>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";

const props = defineProps({
    reportData: {
        type: Object,
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const modal = ref(false);
const comment = ref(null);
const tags = ref([]);

let form = null;

const submitForm = () => {
    let forma = {};
    forma['comment'] = comment.value;
    forma['tags'] = tags.value;
    form = useForm(forma);
    form.post($route('analytics.report.standard.save', {
        report: props.reportData.report,
        report_data: props.reportData.id
    }));
    form = null;
    modal.value = !modal.value;
};
</script>
