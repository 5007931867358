<template>
    <Card :title="$translate('publication.title.add')"
          title-bg="purple-1"
          header-toolbar>
        <template v-slot:title v-if="project">
            {{ $translate('publication.title.add-project') }}
            #{{ project.number }}
        </template>

        <Alert variant="info" v-if="pure_enabled">
            Перед добавлением отчетного материала, убедитесь что он не импортирован из Pure.
        </Alert>

        <q-select v-model="reportCategory"
                  :label="$translate('publication.fields.select-type')"
                  :options="optionsReportCategory"
                  @update:modelValue="setReportCategory"
                  :error="publication.errors.hasOwnProperty('report_type_category')"
                  :error-message="_.get(publication.errors,'report_type_category')"/>

        <component :is="reportTypeCategory"
                   v-model="publication"/>

        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   color="positive"
                   @click="submitForm"
            />
        </template>

    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import {Alert, Card} from "@/Components/Block";
import * as ReportForm from "@/Components/ReportMaterialForm";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    categorys: {type: Array},
    project: {type: Object, default: null}
})

const publication = useForm({
    report_type_category: null,
    report_type_id: null,
    name: null
})

const reportCategory = ref(null)

const optionsReportCategory = computed(() => {
    return _.map(props.categorys, (category) => ({value: category, label: $translate('report-type.type.' + category)}))
})

const pure_enabled = computed(()=>usePage().props.pure_enabled)

const setReportCategory = (category) => {
    publication.report_type_category = category.value
}

const reportTypeCategory = computed(() => ReportForm[_.upperFirst(_.get(reportCategory.value, 'value'))])

const submitForm = () => {
    if (props.project) {
        publication.transform((data) => ({
            ...data,
            ...publication
        }))
            .post(route('project.publications.store', props.project))
    } else {
        publication.transform((data) => ({
            ...data,
            ...publication
        }))
            .post(route('publication.save'))
    }

}
</script>