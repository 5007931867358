<template>
    <Card v-if="_.isEmpty(data)" hide-actions>
        <div style="height: calc(100vh - 16rem)">
            <q-inner-loading :showing="loading">
                <q-spinner-gears size="50px" color="primary"/>
                <q-item-label class="text-body1 text-primary q-mt-md">
                    {{ $translate('Загрузка данных графика') }}
                </q-item-label>
            </q-inner-loading>
        </div>
    </Card>
    <template v-if="!_.isEmpty(data)">
        <Card :title="title" title-bg="pink-9" title-text="white" header-toolbar hide-actions>
            <template v-for="(item, i) in data" :key="i">
                <Chart :chart-type="chartTypes[i]" :data="item" :config="config[i]"/>
            </template>
        </Card>
    </template>
</template>

<script setup lang="ts">
import {Card} from "@/Components/Block";
import Chart from "@/Components/Chart.vue";
import {getCurrentInstance, onMounted, Ref, ref} from "vue";
import {ComponentInternalInstance} from "@vue/runtime-core";
import type {ChartTypes as TChartTypes} from "@/entity/charts/types";
import {AnyValuesObject} from "@/entity/types";
import axios from "axios";
import _ from "lodash";

const { method } = defineProps<{method: string}>();

const app: ComponentInternalInstance | null = getCurrentInstance();
const $translate: Function = app!.appContext.config.globalProperties.$translate;
const $route: Function = app!.appContext.config.globalProperties.$route;

const title: Ref<string> = ref<string>('');
const chartTypes: Ref<TChartTypes[] | never[]> = ref<TChartTypes[] | never[]>([]);
const data: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);
const config: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);

const loading: Ref<boolean> = ref<boolean>(false);

const fetchData: Function = (): void => {
    loading.value = true;
    axios
        .get($route("ajax.analytic-chart", {m: method}))
        .then((response) => {
            title.value = response.data.results.title;
            chartTypes.value = response.data.results.chartTypes;
            data.value = response.data.results.data;
            config.value = response.data.results.config;
        })
        .catch((e) => {
            console.error(e);
        })
        .finally(() => {
            loading.value = false;
        });
};

onMounted(() => {
    fetchData();
});
</script>
