<template>
    <Card :title="$translate('equipment.item.tabs.in-complex')"
          v-if="_.size(equipment.in_complex)>0"
          hide-actions no-card-section class="q-mb-md">
        <q-list separator>
            <template v-for="(complex) in equipment.in_complex">
                <q-item>
                    <q-item-section side>
                        <DataTableActions :actions="complex.actions">
                            <q-item clickable v-if="!complex.index"
                                    @click="router.visit($route('equipment.show',complex.id))"
                                    v-close-popup>
                                <q-item-section>
                                    <q-item-label>
                                        {{ $translate('buttons.detail') }}
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section avatar>
                                    <q-icon name="o_pageview" size="xs"/>
                                </q-item-section>
                            </q-item>
                        </DataTableActions>
                    </q-item-section>
                    <q-item-section>
                            <q-item-label>{{ complex.display_name }}</q-item-label>
                            <q-item-label overline>{{ complex.serial }}</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-img :src="complex.photo" v-if="complex.photo" sizes="4rem"/>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import axios from "axios";
import _ from "lodash"
import DataTableActions from "@/Components/DataTableActions.vue";
import Card from "@/Components/Block/Card.vue";
import {useQuasar} from "quasar";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar();

const props = defineProps({
    equipment: {
        type: Object
    }
})


</script>