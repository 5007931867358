<template>
    <p class="text-body1">
        {{ $translate('module-ethics.application.form.intro', {author: _.get(user, 'fulled_name')}) }}
    </p>
    <q-field v-if="application && _.get(application, 'comment')"
             :label="$translate('module-ethics.application.form.fields.comment')" class="q-mb-sm"
             stack-label
             outlined
    >
        <template v-slot:control>
            <div class="self-center full-width no-outline" tabindex="0">
                {{ _.get(application, 'comment') }}
            </div>
        </template>
    </q-field>
    <q-select v-model="form.type"
              :label="$translate('module-ethics.application.form.fields.type')"
              :options="helpers.toOptions($translate('module-ethics.application.types'))"
              :error="form.errors.hasOwnProperty('type')"
              :error-message="_.get(form.errors, 'type')"
              options-dense
              map-options
              emit-value
    />
    <q-input v-model="form.name"
             :label="$translate('module-ethics.application.form.fields.name')"
             :error="form.errors.hasOwnProperty('name')"
             :error-message="_.get(form.errors, 'name')"
             type="textarea"
             rows="4"
    />
    <q-input v-model="form.users"
             :label="$translate('module-ethics.application.form.fields.users')"
             type="textarea"
             rows="4"
    />
    <q-input v-model="form.doc"
             :label="$translate('module-ethics.application.form.fields.doc')"
             type="textarea"
             rows="4"
    />
    <q-input v-model="form.remark"
             :label="$translate('module-ethics.application.form.fields.remark')"
             type="textarea"
             rows="4"
    />
    <q-file v-model="form.files"
            :label="$translate('module-ethics.application.form.fields.files')"
            :error="form.errors.hasOwnProperty('files')"
            :error-message="_.get(form.errors, 'files')"
            bottom-slots
            multiple
    >
        <template v-slot:prepend>
            <q-icon name="attach_file" />
        </template>
        <template v-slot:hint>
            <span v-html="$translate('module-ethics.application.form.fields.files_hint')"></span>
        </template>
    </q-file>
    <Files v-if="application && _.get(application, 'output_files')"
           :output-files="_.get(application, 'output_files')"
           class="q-mt-md"
    />
</template>

<script setup>
import Files from "@/Components/Ethics/Block/Files.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";
import helpers from "@/helpers";

/**
 * @param InertiaForm form
 */
const form = defineModel({type: Object});
const props = defineProps({
    application: Object,
    user: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
