<template>
    <q-item>
        <q-item-section side>
            <UserAvatar :avatar="_.get(staff,'user.avatar')"/>
        </q-item-section>
        <q-item-section>
            <q-item-label>
                {{ staff.name }}
            </q-item-label>
            <q-item-label caption>
                {{ _.get(staff, 'degree') }}
            </q-item-label>
        </q-item-section>
        <q-item-section>
            <q-item-label>
                {{ _.get(staff, 'cadre_position.name') }}
                ({{ _.get(staff, 'rate_formatted') }})
            </q-item-label>
            <q-item-label caption>
                {{ $translate('cadre-staff.fields.personnel_number') }}:
                {{ _.get(staff, 'personnel_number') }}
            </q-item-label>
        </q-item-section>
        <q-item-section side>
            <DataTableActions>
                <q-item v-if="staff.permission.detail"
                        clickable v-close-popup
                        @click="openStaffDialog">
                    <q-item-section>
                        <q-item-label class="text-no-wrap">{{ $translate('buttons.detail') }}</q-item-label>
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="o_account_box" size="xs"/>
                    </q-item-section>
                </q-item>
                <q-item v-if="staff.permission.edit"
                        clickable v-close-popup
                        @click="profileLink(staff.user_id)">
                    <q-item-section>
                        <q-item-label class="text-no-wrap">{{ $translate('buttons.edit') }}</q-item-label>
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="o_edit" size="xs"/>
                    </q-item-section>
                </q-item>
                <q-separator/>
                <q-item v-if="staff.permission.logonas"
                        clickable v-close-popup
                        @click="loginAs(staff.user_id)">
                    <q-item-section>
                        <q-item-label class="text-no-wrap">{{
                                $translate('auth.loginas', {name: staff.user.last_name})
                            }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="o_meeting_room" size="xs"/>
                    </q-item-section>
                </q-item>
                <q-item v-if="staff.permission.delete"
                        clickable v-close-popup
                        @click="deleteUser(staff)">
                    <q-item-section>
                        <q-item-label class="text-no-wrap">{{
                                $translate('cadre-staff.actions.dismiss')
                            }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="o_delete" size="xs"/>
                    </q-item-section>
                </q-item>
            </DataTableActions>
        </q-item-section>
    </q-item>
    <q-dialog v-model="staffDialog"
              full-width full-height no-backdrop-dismiss>
        <Card :title="staff.name"
              header-toolbar
              show-close-button hide-actions no-card-section>
            <StaffCard :staff="staff"/>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import UserAvatar from "@/Components/UserAvatar.vue";
import DataTableActions from "@/Components/DataTableActions.vue";
import {Card} from "@/Components/Block";
import StaffCard from "@/Components/Cadre/Staff/StaffCard.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    staff: {
        type: Object
    }
})

/** Действия с пользователем **/

const staffDialog = ref(false)
const openStaffDialog = () => {
    staffDialog.value = true;
}

const loginAs = (user_id) => {
    // window.alert(user_id);
    router.post(route('admin.logonas'),
            {loginas: user_id},
            {
                preserveState: true,
                preserveScroll: true
            })
}
</script>