<template>
    <Card :title="$translate('module-archive.research.card.main.title')" class="q-mb-md" hide-actions>
        <template v-slot:default>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.research.card.main.fields.number') }}:</div>
                <div class="col-auto"><b>{{ _.get(archiveResearch, 'number') }}</b></div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.research.card.main.fields.rc_name') }}:</div>
                <div class="col">{{ _.get(archiveResearch, 'rc_name') }}</div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.research.card.main.fields.department_name') }}:</div>
                <div class="col">{{ _.get(archiveResearch, 'department_name') }}</div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.research.card.main.fields.research_state') }}:</div>
                <div class="col">{{ _.get(archiveResearch, 'research_state') }}</div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.research.card.main.fields.dcreate') }}:</div>
                <div class="col">{{ handleDate('dcreate', true) }}</div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.research.card.main.fields.period') }}:</div>
                <div class="col">{{ handleDate('bdate') }} — {{ handleDate('edate') }}</div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.research.card.main.fields.dakt') }}:</div>
                <div class="col">{{ handleDate('dakt', true) }}</div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.research.card.main.fields.probs_nofsamples') }}:</div>
                <div class="col">
                    {{ handleInfo('probs') }}/{{ handleInfo('nofsamples') }}
                </div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.research.card.main.fields.date_samples') }}:</div>
                <div class="col">{{ handleDate('date_samples') }}</div>
            </div>
            <div v-if="_.get(archiveResearch, 'afm_info.internal_comment')" class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.research.card.main.fields.internal_comment') }}:</div>
                <div class="col">{{ _.get(archiveResearch, 'afm_info.internal_comment') }}</div>
            </div>
            <div v-if="_.get(archiveResearch, 'afm_info.comment_for_user')" class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.research.card.main.fields.comment_for_user') }}:</div>
                <div class="col">{{ _.get(archiveResearch, 'afm_info.comment_for_user') }}</div>
            </div>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";
import moment from "moment";
import helpers from "@/helpers";

const props = defineProps({
    archiveResearch: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const handleDate = (property, datetime = false) => {
    let value = _.get(props.archiveResearch, 'afm_dates.' + property),
        format = $translate(datetime ? 'common.datetime.js' : 'common.date.js');
    return helpers.filled(value) ? moment(value).format(format) : '?';
};
const handleInfo = (property) => {
    let value = _.get(props.archiveResearch, 'afm_info.' + property);
    return helpers.filled(value) ? value : '?';
};
</script>
