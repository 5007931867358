<template>
    <Card :title="$translate('module-ethics.application.card.author.title')" hide-actions>
        <template v-slot:default>
            <q-field :label="$translate('module-ethics.application.card.author.fields.fio')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width" tabindex="0">
                        {{ _.get(user, 'fulled_name') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-ethics.application.card.author.fields.email')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width" tabindex="0">
                        {{ _.get(user, 'email') }}
                    </div>
                </template>
            </q-field>
            <template v-for="(field, i) in additionalDataFields" :key="i">
                <q-field v-if="!_.isEmpty(getAdditionalData(field))"
                         :label="$translate('module-ethics.application.card.author.fields.' + field)"
                         stack-label
                         borderless
                >
                    <template v-slot:control>
                        <div class="self-center full-width" tabindex="0">
                            {{ getAdditionalData(field) }}
                        </div>
                    </template>
                </q-field>
            </template>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";

const props = defineProps({
    user: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const additionalDataFields = ['position', 'title', 'faculty', 'department', 'company', 'phone', 'intphone', 'mobile'];
const getAdditionalData = (field) => {
    let filtered = _.filter(
        _.get(props.user, 'additional'),
        o => _.get(o, 'slug') === field
    );
    return _.join(_.uniq(_.map(filtered, o => _.get(o, 'value'))), ', ');
};
</script>
