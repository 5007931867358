<template>
    <div class="row q-gutter-md items-start">
        <Card class="col-md-11 col-lg" :title="$translate('equipment.item.stats.project.title')" no-card-section
              hide-actions>
            <q-item dense class="q-py-xs">
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.item.stats.project.description') }}
                    </q-item-label>
                </q-item-section>
            </q-item>
            <q-expansion-item>
                <template v-slot:header>
                    <q-item-section>
                        <q-item-label class="text-h4 q-pa-md text-center">{{ _.size(usageProject) }}</q-item-label>
                    </q-item-section>
                </template>
                <template v-slot:default>
                    <div class="row justify-around">
                        <template v-for="(time,project) in usageProject">
                            <q-chip outline color="dark" size="md" square
                                    clickable
                                    @click="router.visit($route('project.show', project))">
                                {{ project }}
                                <q-separator vertical class="q-mx-sm"/>
                                {{ formatTime(time) }}
                            </q-chip>
                        </template>
                    </div>
                </template>
            </q-expansion-item>
        </Card>
        <Card class="col-md-11 col-lg" :title="$translate('equipment.item.stats.research.title')" no-card-section
              hide-actions>
            <q-item dense class="q-py-xs">
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.item.stats.research.description') }}
                    </q-item-label>
                </q-item-section>
            </q-item>
            <q-expansion-item>
                <template v-slot:header>
                    <q-item-section>
                        <q-item-label class="text-h4 q-pa-md text-center">
                            {{ _.size(usageResearch) }}
                        </q-item-label>
                    </q-item-section>
                </template>
                <template v-slot:default>
                    <div class="row justify-around">
                        <template v-for="(time,research) in usageResearch">
                            <q-chip outline color="dark" size="md" square
                                    clickable
                                    @click="router.visit($route('project.research.show', research))">
                                {{ research }}
                                <q-separator vertical class="q-mx-sm"/>
                                {{ formatTime(time) }}
                            </q-chip>
                        </template>
                    </div>
                </template>
            </q-expansion-item>
        </Card>
        <Card class="col-md-11 col-lg" :title="$translate('equipment.item.stats.time.title')" no-card-section
              hide-actions>
            <q-item dense class="q-py-xs">
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.item.stats.time.description') }}
                    </q-item-label>
                </q-item-section>
            </q-item>
            <q-expansion-item>
                <template v-slot:header>
                    <q-item-section>
                        <q-item-label class="text-h4 q-pa-md text-center text-no-wrap">
                            {{ formatTime(_.sum(_.values(usagePeriod))) }}
                        </q-item-label>
                    </q-item-section>
                </template>
                <template v-slot:default>
                    <div class="row justify-around">
                        <template v-for="(time,year) in usagePeriod">
                            <q-chip outline color="dark" size="md" square>
                                {{ year }}
                                <q-separator vertical class="q-mx-sm"/>
                                {{ formatTime(time) }}
                            </q-chip>
                        </template>
                    </div>
                </template>
            </q-expansion-item>
        </Card>
        <Card class="col-md-11 col-lg" :title="$translate('equipment.item.stats.template.title')" no-card-section
              hide-actions>
            <q-item dense class="q-py-xs">
                <q-item-section>
                    <q-item-label caption>{{ $translate('equipment.item.stats.template.description') }}</q-item-label>
                </q-item-section>
            </q-item>
            <q-expansion-item>
                <template v-slot:header>
                    <q-item-section>
                        <q-item-label class="text-h4 q-pa-md text-center">
                            {{ _.size(templates) }}
                        </q-item-label>
                    </q-item-section>
                </template>
                <template v-slot:default>
                    <div class="column q-px-xs">
                        <template v-for="(template) in templates">
                            <q-chip outline color="dark" size="md"
                                    class="ellipsis full-width"
                                    square
                                    clickable
                                    @click="router.visit($route('research.template.show', template))">
                                <span>#{{ template.id }}</span>
                                <q-separator vertical class="q-mx-sm"/>
                                <span class="ellipsis">{{ template.name }}</span>
                                <q-tooltip class="text-body2">
                                    {{ $translate('research-template.title.show', {template: template.name}) }}
                                </q-tooltip>
                            </q-chip>
                        </template>
                    </div>
                </template>
            </q-expansion-item>
        </Card>
    </div>
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import _ from "lodash"
import {Card} from "@/Components/Block";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    equipment: {
        type: Object
    },
})

const usage = computed(() => usePage().props.usage)
const templates = computed(() => usePage().props.templates)

const usageProject = computed(() => {
    let items = {}
    _.forEach(usage.value, (item) => {
        _.set(items, item.project_number, _.get(items, item.project_number, 0) + item.used_time)
    });
    return items;
})

const usageResearch = computed(() => {
    let items = {}
    _.each(usage.value, (item) => {
        _.set(items, item.project_research_number, _.get(items, item.project_research_number, 0) + item.used_time)
    });
    return items;
})

const usagePeriod = computed(() => {
    let items = {}
    _.each(usage.value, (item) => {
        let t = item.used_date.split("-")
        _.set(items, t[0], _.get(items, t[0], 0) + item.used_time)
    });
    return items;
})
const formatTime = (time) => {
    let min = (time % 60)
    let hours = (time - min) / 60
    return hours + 'ч.' + ' ' + min + 'м.';
}
</script>