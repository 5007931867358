<template>
    <q-card>
        <q-card-section class="flex justify-between items-center">
            <AnalyticReportsHeader :report-data="reportData"></AnalyticReportsHeader>
            <AnalyticReportsActions :report-data="reportData"></AnalyticReportsActions>
        </q-card-section>
        <q-card-section>
            <q-table :columns="columns"
                     :rows="data"
                     :pagination="{rowsPerPage: 0}"
                     :rows-per-page-options="[0]"
                     hide-pagination
                     bordered
                     flat
            >
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-th class="text-left" colspan="100%">{{ props.row.ssc }}</q-th>
                    </q-tr>
                    <q-tr :props="props" v-for="(item, i) in props.row.data" :key="i">
                        <q-td />
                        <q-td>{{ item.type }}</q-td>
                        <q-td class="text-center">{{ item.count }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </q-card-section>
    </q-card>
</template>

<script setup>
import AnalyticReportsActions from "@/Components/Analytic/Reports/AnalyticReportsActions.vue";
import AnalyticReportsHeader from "@/Components/Analytic/Reports/AnalyticReportsHeader.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";

const props = defineProps({
    report: {
        type: String,
    },
    reportData: {
        type: Object,
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const data = ref([]);
_.forEach(props.reportData.data, (types, ssc) => {
    data.value.push({
        ssc: ssc,
        data: _.map(types, (count, type) => ({
            type: type,
            count: count
        }))
    });
});

const columns = [
    {name: 'space', label: '', align: 'left'},
    {
        name: 'type',
        label: $translate('module-analytics.table_columns.' + props.report + '.type'),
        align: 'left',
        field: 'type'
    },
    {
        name: 'count',
        label: $translate('module-analytics.table_columns.' + props.report + '.count'),
        align: 'center',
        field: 'count'
    },
];
</script>
