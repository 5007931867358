<template>
    <section class="row justify-center align-center ">
        <div class=" col-22 col-sm-18 col-md-12 col-xl-7">
            <div class="flex align-center justify-start q-ma-md">
                <q-card style="max-height: 90vh">
                    <q-card-section class="text-uppercase text-center bg-grey-1 text-weight-bold">
                        {{ $translate('module-auth.form.verify.title') }}
                    </q-card-section>

                    <q-separator/>

                    <q-card-section class="text-center">

                        <q-banner class="bg-yellow-2 q-mb-md">
                            <template v-slot:avatar>
                                <q-icon name="warning_amber" color="deep-orange-6"/>
                            </template>
                            <div>{{ $translate('module-auth.form.verify.before') }}</div>
                        </q-banner>

                        <q-banner class="bg-blue-2 q-mt-md">
                            <template v-slot:avatar>
                                <q-icon name="info" color="blue-8"/>
                            </template>
                            <div
                                v-html="$translate('module-auth.form.verify.sendlink', {'link': $route('verification.resend')})"></div>
                        </q-banner>
                    </q-card-section>
                </q-card>
            </div>
        </div>
    </section>
</template>

<script setup>
import {usePage} from "@inertiajs/vue3";
const page = usePage()
</script>