<template>
    <q-card-section>
        <component :is="projectBLock('AboutProject')" :project="project" printable/>
        <component :is="projectBLock('ResearchClaim')" :project="project" printable/>
        <component :is="projectBLock('Feedback')" :project="project" printable/>
        <component :is="projectBLock('Members')" :project="project" printable/>
        <component :is="projectBLock('Departments')" :project="project" printable/>
        <component :is="projectBLock('ExpectedResult')" :project="project" printable/>
        <component :is="projectBLock('Expenses')" :project="project" printable/>
    </q-card-section>
</template>

<script setup>
import Print from "@/Layout/Print.vue";
import {getCurrentInstance} from "vue";
import * as ProjectCard from "@/Components/Project/Card"

const projectBLock = (block) => ProjectCard[block]

defineOptions({layout: Print});

const props = defineProps({
    project: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>