<template>
    <Card :title="$translate('project.view.members-info') + ' ('+(_.size(projectMembers) + 1)+')'"
          no-card-section
          actions-align="stretch"
          class="q-mb-md"
          :hide-actions="!(project.can.leader.manage || project.can.members.add) || printable">
        <template v-slot:headerActions v-if="!printable">
            <q-btn v-if="project.can.members.add"
                   icon="mdi-file-import-outline"
                   :label="$translate('buttons.import')"
                   @click="importDialog = true"
                   dense
                   no-caps
            />
            <q-dialog v-model="importDialog" no-backdrop-dismiss>
                <Card title="Импорт участников проекта из файла" in-modal show-close-button>
                    <Alert variant="info" :message="importInfoText"/>
                    <q-file v-model="importForm.file_members" label="Выберите файл" accept=".txt">
                        <template v-slot:prepend>
                            <q-icon name="attach_file"/>
                        </template>
                    </q-file>
                    <component :is="searchDialog('Roles')"
                               scope="project"
                               :label="$translate('project.actions.select-role-placeholder')"
                               @onSelect="onSelectImportRole"/>
                    <div class="text-negative" v-if="importForm.errors.hasOwnProperty('role_id')">
                        {{ _.get(memberAddForm.errors, 'role_id') }}
                    </div>
                    <template v-slot:actions>
                        <q-btn icon="o_save"
                               :label="$translate('buttons.add')"
                               color="positive"
                               @click="importMembers"
                        />
                        <q-btn icon="o_close"
                               :label="$translate('buttons.cancel')"
                               color="dark"
                               v-close-popup
                        />
                    </template>
                </Card>
            </q-dialog>
        </template>
        <q-scroll-area :style="areaHeight" visible>
            <q-list separator>
                <MemberItem :title="$translate('project.view.leader-info')"
                            :member="project.leader"
                            :can="project.can.leader"
                            :hide-actions="printable"/>

                <q-inner-loading :showing="loadingMembers">
                    <q-spinner-gears size="50px" color="primary"/>
                </q-inner-loading>

                <MemberItem v-for="(member) in projectMembers"
                            :title="member.role.display_name"
                            :member="member.user"
                            :project-researches="member.research"
                            :memberId="member.id"
                            :project="project"
                            :can="member.can"
                            :hide-actions="printable"
                            @onSuccess="loadMembers"/>
            </q-list>
        </q-scroll-area>
        <template v-slot:actions>
            <q-btn v-if="project.can.members.add"
                   icon="add"
                   :label="$translate('project.actions.add-member')"
                   @click="memberAddDialog = true"
                   no-caps dense
                   color="teal-1" text-color="teal-9"
                   class="q-ml-none q-mr-auto"/>
            <q-btn v-if="project.can.leader.manage"
                   icon="autorenew"
                   :label="$translate('project.actions.new-leader')"
                   @click="replaceLeaderDialog = true"
                   no-caps dense
                   color="teal-1" text-color="teal-9"
                   class="q-ml-auto q-mr-none"/>
        </template>
    </Card>
    <q-dialog v-model="memberAddDialog" no-backdrop-dismiss>
        <Card :title="$translate('project.form.title.add-member')"
              in-modal show-close-button>
            <component :is="searchDialog('User')"
                       v-if="project.can.members.search"
                       @onSelect="selectMemberUser"
                       with-ldap
                       allow-create/>
            <q-input v-else v-model="memberAddForm.member_id"
                     :label="$translate('project.actions.select-member-placeholder2')"/>
            <div class="text-negative" v-if="memberAddForm.errors.hasOwnProperty('member_id')">
                {{ _.get(memberAddForm.errors, 'member_id') }}
            </div>

            <component :is="searchDialog('Roles')"
                       scope="project"
                       :label="$translate('project.actions.select-role-placeholder')"
                       @onSelect="selectMemberRole"/>
            <div class="text-negative" v-if="memberAddForm.errors.hasOwnProperty('role_id')">
                {{ _.get(memberAddForm.errors, 'role_id') }}
            </div>

            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.add')"
                       color="positive"
                       @click="memberAddFormSubmit"
                />
                <q-btn icon="o_close"
                       :label="$translate('buttons.cancel')"
                       color="dark"
                       v-close-popup
                />
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="replaceLeaderDialog" no-backdrop-dismiss>
        <Card :title="$translate('project.actions.new-leader')"
              in-modal show-close-button>
            <component :is="searchDialog('User')"
                       @onSelect="selectLeaderUser"
                       with-ldap/>
            <div class="text-negative" v-if="replaceLeaderForm.errors.hasOwnProperty('leader_id')">
                {{ _.get(replaceLeaderForm.errors, 'leader_id') }}
            </div>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.assigndirector')"
                       color="positive"
                       @click="replaceLeaderFormSubmit"
                />
                <q-btn icon="o_close"
                       :label="$translate('buttons.cancel')"
                       color="dark"
                       v-close-popup
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from 'lodash'
import axios from 'axios'
import {getCurrentInstance, ref, onMounted, computed} from "vue";
import MemberItem from "@/Components/Project/Card/MemberItem.vue";
import {Card, Alert} from "@/Components/Block";
import * as SearchForm from "@/Components/Search/";

const searchDialog = (block) => SearchForm[block];

const props = defineProps({
    project: {
        type: Object,
        required: true
    },
    printable: {
        type: Boolean,
        default: false
    },
})

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const ldap_enabled = computed(() => usePage().props.ldap_enabled)

const areaHeight = computed(() => {
    return {
        height: (_.size(projectMembers.value) + 1) * 100 + 'px',
        minHeight: '120px',
        maxHeight: props.printable ? '' : '440px'
    }
})

const memberAddDialog = ref(false)
const memberAddForm = useForm({
    member_id: null,
    role_id: null
})
const selectMemberUser = (member) => {
    memberAddForm.member_id = member.id
}
const selectMemberRole = (role) => {
    memberAddForm.role_id = role
}
const memberAddFormSubmit = () => {
    memberAddForm
            .put(route('project.member', props.project),
                    {
                        onSuccess: () => {
                            memberAddDialog.value = false
                            loadMembers()
                        }
                    })
}

const replaceLeaderDialog = ref(false)
const replaceLeaderForm = useForm({
    leader_id: null
})
const selectLeaderUser = (member) => {
    replaceLeaderForm.leader_id = member.id
}
const replaceLeaderFormSubmit = () => {
    replaceLeaderForm
            .patch(route('project.leader', props.project),
                    {
                        onSuccess: () => {
                            replaceLeaderDialog.value = false
                            loadMembers()
                        }
                    })
}
const loadingMembers = ref(false)
const projectMembers = ref(null)
const loadMembers = () => {
    loadingMembers.value = true;
    axios.get(route('ajax.project', {m: 'members', project: props.project.id}))
            .then((response) => {
                projectMembers.value = response.data.results;
            })
            .then(() => {
                loadingMembers.value = false
            })
}

const importDialog = ref(false);
const importInfoText = computed(() => `Приложите текстовый файл с email-ми ${ldap_enabled.value ? '(или с логинами AD)' : ''} участников по одному на строку`);
const importForm = useForm({
    file_members: null,
    role_id: null
});
const onSelectImportRole = (role) => {
    importForm.role_id = role;
};
const importMembers = () => {
    importForm.post(
            route('project.member', props.project),
            {
                onSuccess: () => {
                    importDialog.value = false;
                    loadMembers();
                    importForm.reset();
                }
            }
    );
};

onMounted(() => {
    loadMembers()
})
</script>