<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          actions-align="left"
          no-card-section>
        <q-list separator>
            <template v-for="(author,index) in members">
                <q-item>
                    <q-item-section>
                        <q-item-label v-if="author.user_id">{{ _.get(author, 'user.fulled_name') }}</q-item-label>
                        <q-item-label v-if="author.company_id">{{ _.get(author, 'company.name') }}</q-item-label>
                        <q-item-label v-if="author.group_id">{{ _.get(author, 'group.name') }}</q-item-label>
                        <q-item-label caption>
                            <span v-if="author.user_id && author.external">внешняя персона</span>
                            <span v-if="author.company_id && !author.external">орг.единица</span>
                            <span v-if="author.company_id && author.external">внешняя организация</span>
                            <span v-if="author.group_id">коллаборация авторов</span>
                        </q-item-label>
                    </q-item-section>

                    <q-item-section v-if="author.user_id && !hidePersonRole">
                        <q-select v-model="members[index].role"
                                  :label="$translate('Роль')"
                                  :options="memberRoleOptions"
                                  options-dense dense
                                  emit-value map-options
                                  :error="errors.hasOwnProperty(errorPrefix+'.'+index+'.role')"
                                  :error-message="_.get(errors,errorPrefix+'.'+index+'.role')"/>
                    </q-item-section>

                    <q-item-section side>
                        <q-btn icon="o_delete"
                               color="negative"
                               flat dense
                               @click="deleteMember(index)"/>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
        <div class="text-negative q-pa-sm"
             v-if="errors.hasOwnProperty(errorPrefix)">
            {{ _.get(errors, errorPrefix) }}
        </div>
        <template v-slot:actions>
            <q-btn-group>
                <q-btn v-if="!hidePerson"
                       icon="o_person_add"
                       :label="$translate('персону')"
                       :title="$translate('Добавить персону')"
                       @click="openPersonDialog"
                       dense no-caps/>
                <q-btn v-if="!hideCompany"
                       icon="o_add_business"
                       :label="$translate('орг.единицу')"
                       :title="$translate('Добавить орг.единицу')"
                       @click="openBusinessDialog"
                       dense no-caps/>
                <q-btn v-if="!hideCompany"
                       icon="o_add_business"
                       :label="$translate('внешнюю организацию')"
                       :title="$translate('Добавить внешнюю организацию')"
                       @click="openBusinessExtDialog"
                       dense no-caps/>
                <q-btn v-if="!hideCol"
                       icon="o_group_add"
                       :label="$translate('коллаборацию')"
                       :title="$translate('Добавить коллаборацию')"
                       @click="openGroupDialog"
                       dense no-caps/>
            </q-btn-group>
        </template>
    </Card>
    <q-dialog v-model="personDialog" no-backdrop-dismiss>
        <component :is="dialogBlock('Person')"
                   @onSelect="addPerson"
                   with-ldap with-external/>
    </q-dialog>
    <q-dialog v-model="businessDialog" no-backdrop-dismiss>
        <component :is="dialogBlock('Department')"
                   @onSelect="addDepartment"/>
    </q-dialog>
    <q-dialog v-model="businessExtDialog" no-backdrop-dismiss>
        <component :is="dialogBlock('Contragent')"
                   @onSelect="addDepartment"/>
    </q-dialog>
    <q-dialog v-model="groupDialog" no-backdrop-dismiss>
        <component :is="dialogBlock('Collaboration')"
                   @onSelect="addGroup"/>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import * as ReportMaterialFormBlock from "@/Components/ReportMaterialForm/Dialog";

const dialogBlock = (block) => ReportMaterialFormBlock[block];

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object},
    errorPrefix: {type: String, default: 'authors'},
    hidePerson: {type: Boolean, default: false},
    hidePersonRole: {type: Boolean, default: false},
    hideCompany: {type: Boolean, default: false},
    hideCol: {type: Boolean, default: false}
})

const members = defineModel('members', {type: Array})
const errors = defineModel('errors', {type: Object})

const deleteMember = (index) => {
    members.value.splice(index, 1)
}

const personDialog = ref(false)
const businessDialog = ref(false)
const businessExtDialog = ref(false)
const groupDialog = ref(false)

const openPersonDialog = () => {
    personDialog.value = true
}
const openBusinessDialog = () => {
    businessDialog.value = true
}
const openBusinessExtDialog = () => {
    businessExtDialog.value = true
}
const openGroupDialog = () => {
    groupDialog.value = true
}
const addPerson = (person) => {
    console.log(person)
    members.value.push({
        user: person.user,
        user_id: person.user.user_id ? person.user.user_id : person.user.id,

        company_id: null,
        company: null,

        group_id: null,
        group: null,

        role: null,
        external: person.user.user_id ? person.external : (person.user.id ? person.external : true),
    })
    personDialog.value = false
}
const addDepartment = (department) => {
    members.value.push({
        user: null,
        user_id: null,

        company_id: department.id,
        company: department,

        group_id: null,
        group: null,

        role: null,
        external: department.external ?? false,
    })
    businessDialog.value = false
    businessExtDialog.value = false
}

const addGroup = (department) => {
    members.value.push({
        user: null,
        user_id: null,

        company_id: null,
        company: null,

        group_id: department.collaboration.id,
        group: department.collaboration,

        role: null,
        external: department.external ?? false,
    })
    groupDialog.value = false
}

const memberRoleOptions = computed(() => {
    return _.map($translate('report-type.author-roles'), (v, k) => ({value: k, label: v}))
})
</script>