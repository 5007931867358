<template>
    <div v-if="services.hasOwnProperty('delivery')">
        <DepartmentSelector :sidebar="sidebar"></DepartmentSelector>
        <q-list>
            <NavigationItem :title="$translate('menu.delivery.request')"
                            :sidebar="sidebar"
                            color="teal-6"
                            icon="o_local_shipping"
            >
                <template #items>
                    <q-item v-if="services.delivery.items.request_all"
                            @click="router.visit($route('services.delivery.order.list-all'))"
                            clickable v-close-popup
                    >
                        <q-item-section class="q-pl-md">
                            {{ $translate("menu.delivery.request-all") }}
                        </q-item-section>
                    </q-item>
                    <q-item v-if="services.delivery.items.request_my"
                            @click="router.visit($route('services.delivery.order.list-my'))"
                            clickable v-close-popup
                    >
                        <q-item-section class="q-pl-md">
                            {{ $translate("menu.delivery.request-my") }}
                        </q-item-section>
                    </q-item>
                    <q-separator v-if="services.delivery.items.request_create"/>
                    <q-item v-if="services.delivery.items.request_create"
                            @click="router.visit($route('services.delivery.order.create'))"
                            clickable v-close-popup
                    >
                        <q-item-section side class="q-pl-md q-pr-xs">
                            <q-icon name="add_box" size="xs" color="teal-6"/>
                        </q-item-section>
                        <q-item-section class="q-pl-none text-teal-6">
                            {{ $translate("menu.delivery.request-add") }}
                        </q-item-section>
                    </q-item>
                </template>
            </NavigationItem>

            <NavigationItem :title="$translate('menu.delivery.settings')"
                            :sidebar="sidebar"
                            color="dark"
                            icon="o_settings"
                            v-if="services.delivery.items.manage"
            >
                <template #items v-if="current_ssc>0">
                    <q-item @click="router.visit($route('ssc.address',current_ssc))"
                            clickable v-close-popup>
                        <q-item-section class="q-pl-md">
                            {{ $translate("menu.delivery.address-ssc") }}
                        </q-item-section>
                    </q-item>
                    <q-item @click="router.visit($route('services.delivery.goods.index'))"
                            clickable v-close-popup>
                        <q-item-section class="q-pl-md">
                            {{ $translate("menu.delivery.goods") }}
                        </q-item-section>
                    </q-item>
                    <q-item @click="router.visit($route('services.delivery.address.index'))"
                            clickable v-close-popup>
                        <q-item-section class="q-pl-md">
                            {{ $translate("menu.delivery.address-delivery") }}
                        </q-item-section>
                    </q-item>
                    <q-item @click="router.visit($route('services.delivery.dates.index'))"
                            clickable v-close-popup>
                        <q-item-section class="q-pl-md">
                            {{ $translate("menu.delivery.dates") }}
                        </q-item-section>
                    </q-item>
                </template>
                <template #items v-else>
                    <div class="text-negative text-center">
                        {{ $translate('module-delivery.select-ssc') }}
                    </div>
                </template>
            </NavigationItem>
        </q-list>
    </div>
</template>

<script setup>
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";
import {computed, getCurrentInstance} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import DepartmentSelector from "@/Components/Navigation/DepartmentSelector.vue";

defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const services = computed(() => usePage().props.services);

const current_ssc = computed(() => usePage().props.auth.current_ssc);
</script>