<template>
    <Card :title="$translate('module-archive.project.card.devices.title', {size: _.size(devices)})"
          class="q-mb-md"
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn :icon="expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                   :title="$translate('buttons.toggle-list')"
                   @click="expanded = !expanded"
                   size="sm"
                   flat
            />
        </template>
        <template v-slot:default>
            <q-slide-transition>
                <div v-show="expanded">
                    <q-list separator>
                        <template v-for="(device, i) in devices" :key="i">
                            <q-item v-if="!_.isNull(_.get(device, 'device.device_name'))">
                                <q-item-section>
                                    <div class="row q-gutter-x-sm">
                                        <div class="col-12">
                                            <a @click="modals[_.get(device, 'device.device_id')] = true"
                                               class="cursor-pointer text-brand"
                                            >
                                                {{ _.get(device, 'device.device_name') }}
                                            </a>
                                        </div>
                                        <div class="col">
                                            <span class="cursor-help">
                                                <q-tooltip class="text-body2">
                                                    {{ $translate('module-archive.project.card.devices.tooltips.total_time') }}
                                                </q-tooltip>
                                                <q-icon name="far fa-clock" color="grey" size="xs" />
                                                {{ _.get(device, 'total_time') }}
                                            </span>
                                        </div>
                                        <div class="col">
                                            <span class="cursor-help">
                                                <q-tooltip class="text-body2">
                                                    {{ $translate('module-archive.project.card.devices.tooltips.total_price') }}
                                                </q-tooltip>
                                                <q-icon name="fas fa-ruble-sign" color="grey" size="xs" />
                                                {{ _.get(device, 'total_price') }}
                                            </span>
                                        </div>
                                    </div>
                                    <q-dialog v-model="modals[_.get(device, 'device.device_id')]">
                                        <Card :title="$translate('module-archive.project.card.devices.modal-title')"
                                              show-close-button
                                              hide-actions
                                              in-modal
                                        >
                                            <template v-slot:default>
                                                <q-list separator dense>
                                                    <template v-for="(value, property) in _.get(device, 'device')">
                                                        <template v-if="allowToShow(property, value)">
                                                            <template v-if="isFileOrUrl(property)">
                                                                <template v-if="isUrl(property)">
                                                                    <q-item>
                                                                        <q-item-section>
                                                                            <div class="small text-grey">
                                                                                {{ getDescription(property) }}
                                                                            </div>
                                                                            <div class="q-ml-xs">
                                                                                <a :href="value"
                                                                                   target="_blank"
                                                                                   rel="noreferrer nofollow"
                                                                                   class="text-brand"
                                                                                >
                                                                                    {{ value }}
                                                                                </a>
                                                                            </div>
                                                                        </q-item-section>
                                                                    </q-item>
                                                                </template>
                                                                <template v-else>
                                                                    <template v-if="_.get(value, 'exists')">
                                                                        <q-item>
                                                                            <q-item-section>
                                                                                <div class="small text-grey">
                                                                                    {{ getDescription(property) }}
                                                                                </div>
                                                                                <div class="q-ml-xs">
                                                                                    <a :href="_.get(value, 'url')"
                                                                                       target="_blank"
                                                                                       rel="noreferrer nofollow"
                                                                                       class="text-brand"
                                                                                    >
                                                                                        <q-icon v-if="_.get(value, 'icon')"
                                                                                                :name="'far fa-' + _.get(value, 'icon')"
                                                                                                size="xs"
                                                                                        />
                                                                                        {{ _.get(value, 'name') }}
                                                                                    </a>
                                                                                </div>
                                                                            </q-item-section>
                                                                        </q-item>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                <q-item>
                                                                    <q-item-section>
                                                                        <div class="small text-grey">
                                                                            {{ getDescription(property) }}
                                                                        </div>
                                                                        <div class="q-ml-xs">
                                                                            {{ handleValue(property, value) }}
                                                                        </div>
                                                                    </q-item-section>
                                                                </q-item>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </q-list>
                                            </template>
                                        </Card>
                                    </q-dialog>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </div>
            </q-slide-transition>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import helpers from "@/helpers";

const props = defineProps({
    devices: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const expanded = ref(true);

const modals = ref({});
_.forEach(props.devices, device => {
    _.set(modals.value, _.get(device, 'device.device_id'), ref(false));
});

const urlFields = ['url', 'ckp_url', 'link'];
const fileFields = [
    'sertificate_file',
    'passport_file',
    'techdoc_file',
    'contract_file',
    'tech_file',
    'fstek_in_file',
    'fstek_out_file'
];
const moneyFields = [
    'depreciation',
    'start_price',
    'cost_am',
    'current_cost',
    'year_wages_fund',
    'training_expenses',
    'raising_finance',
    'exploitation_cost',
    'ckp_price'
];

const isFileOrUrl = (property) => _.includes([...urlFields, ...fileFields], property);
const isUrl = (property) => _.includes(urlFields, property);
const isMoney = (property) => _.includes(moneyFields, property);

const allowToShow = (property, value) => helpers.filled(value) && _.includes(_.keys($translate('module-archive.project.card.devices.fields')), property);

const getDescription = (property) => _.get($translate('module-archive.project.card.devices.fields'), property);
const handleValue = (property, value) => isMoney(property) ? helpers.formatMoney(value) : value;
</script>
