<template>
    <Card hide-title>
        <q-file v-model="importForm.import_file"
                :label="$translate('import.select-file')"
                accept=".xlsx, .csv"
                @change="previewData=null"
                :error="importForm.errors.hasOwnProperty('import_file')"
                :error-message="_.get(importForm,'errors.import_file')"
        >
            <template v-slot:prepend>
                <q-icon name="attach_file"/>
            </template>
        </q-file>
        <Alert variant="info">
            <div class="text-bold">{{ $translate('import.main-fields') }}:</div>
            <div>{{ availableFieldsList }}</div>
        </Alert>
        <Alert variant="info">
            <div class="text-bold">{{ $translate('import.required-fields') }}:</div>
            <div>{{ requiredFieldsList }}</div>
        </Alert>
        <Alert variant="warning">
            <div>{{ $translate('import.attention-1') }}</div>
        </Alert>
        <template v-slot:actions>
            <q-btn icon='o_file_upload'
                   :label="$translate('import.preview-button')"
                   color="secondary"
                   :loading="loadingPreview"
                   @click="loadFilePreview"/>
        </template>
    </Card>

    <Card v-if="previewData"
          title="Сопоставление данных"
          header-toolbar
          no-card-section
          class="q-mt-md">

        <q-table :rows="previewData"
                 :columns="columns"
                 wrap-cells
                 :rows-per-page-options="[0]"
                 hide-pagination
                 flat>
            <template v-slot:header-cell="props">
                <q-th :props="props" style="min-width: 140px;">
                    <q-select v-model="importForm.fields[props.col.name]"
                              :options="modelFields"
                              option-label="label"
                              option-value="value"
                              options-dense
                              :label="props.col.label"
                              dense
                    />
                </q-th>
            </template>
            <template v-slot:body-cell="props">
                <q-td :props="props">
                    {{ props.row[props.col.name] }}
                </q-td>
            </template>
        </q-table>

        <template v-slot:actions>
            <q-btn icon='import_export'
                   :label="$translate('buttons.import')"
                   color="positive"
                   @click="executeImport"/>
        </template>
    </Card>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, ref} from "vue";
import {useQuasar} from "quasar";
import {useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from 'axios';
import {Card, Alert} from "@/Components/Block/";


const app = getCurrentInstance()
const $q = useQuasar();

const $translate = app!.appContext.config.globalProperties.$translate

interface Field {
    value: any,
    label: string,
    searchable: boolean,
    required: boolean,
}

interface Props {
    modelFields: Field[],
    requiredFields: string[],
    previewRoute: string,
    executeRoute: string
}

const props = defineProps<Props>()

const availableFieldsList = computed(() => {
    return _.join(_.filter(_.map(props.modelFields, (o) => o.label)), "; ")
})

const requiredFieldsList = computed(() => {
    return _.join(_.filter(_.map(props.modelFields, (o) => o.required ? o.label : null)), "; ")
})

const importForm = useForm({
    import_file: null,
    fields: []
})

const previewData: any = ref(null);

const columns: any = ref([])

const loadingPreview = ref(false)
const loadFilePreview = () => {
    if (importForm.import_file) {
        loadingPreview.value = true
        let formData = new FormData();
        formData.append('import_file', importForm.import_file);
        _.set(previewData, 'value', null);
        _.set(columns, 'value', []);
        axios.post(props.previewRoute, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
                .then((response) => {
                    _.set(previewData, 'value', response.data);
                    _.forEach(response.data[0], (v, k) => {
                        columns.value.push({name: k, label: v, sortable: false, align: 'center'})
                    })
                })
                .finally(() => {
                    loadingPreview.value = false;
                })
    }
}

const validateRequired = (): boolean => {
    let result = true;
    _.forEach(props.modelFields, (field) => {
        if (field.required) {
            if (!_.includes(importForm.fields, field)) {
                result = false;
                $q.notify({
                    color: 'negative',
                    message: 'Не выбрано обязательно поле «' + field.label + '»',
                    position: "bottom",
                    progress: true,
                })
            }
        }
    });

    return result;
}
const executeImport = () => {
    if (validateRequired()) {
        importForm.post(props.executeRoute, {
            onSuccess: () => {
                importForm.reset()
                previewData.value = null
            }
        })
    }
}
</script>