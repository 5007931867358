<template>
    <ResearchLayout :research="research" tab="result">

        <Card :title="$translate('research.title.result')" header-toolbar no-card-section>
            <AlertDanger v-if="hasBroken">{{ $translate('research.title.has-broken-files') }}</AlertDanger>
            <AlertInfo>{{ $translate('research.title.result-comment') }}</AlertInfo>
            <q-list separator>
                <template v-for="(file) in report">
                    <q-item>
                        <q-item-section side>
                            <q-checkbox size="xs" v-model="form.ids" :val="file.id" name="selected[]"/>
                        </q-item-section>
                        <q-item-section side>
                            <q-icon v-if="file.status === 10"
                                    name="o_hourglass_bottom"
                                    color="primary"
                                    size="sm"/>
                            <q-icon v-if="file.status === 20"
                                    name="o_check_circle"
                                    color="positive"
                                    size="sm"/>
                            <q-icon v-if="file.status === 30"
                                    name="o_cancel"
                                    color="negative"
                                    size="sm"/>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label class="cursor-pointer">
                                <div class="flex q-gutter-x-md items-center">
                                    <span>{{ file.file_data['name'] }}</span>
                                    <span>({{ file.file_size }})</span>
                                </div>
                                <div class="text-italic">{{ file.file_data['comment'] }}</div>
                            </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <div class="q-gutter-x-sm justify-end" v-if="file.status>0">
                                <q-btn v-if="file.status === 10"
                                       icon="o_check"
                                       :label="$translate('buttons.approve')"
                                       color="positive" outline
                                       @click="approve(file.id)"
                                       dense/>
                                <q-btn v-if="file.status !== 30"
                                       icon="o_clear"
                                       :label="$translate('buttons.decline')"
                                       color="negative" outline
                                       @click="openDeclineDialog(file.id)"
                                       dense/>
                                <q-btn v-if="file.status !== 30"
                                       icon="o_download"
                                       :label="$translate('buttons.download')"
                                       :href="downloadFile(file)"
                                       target="_blank"
                                       color="dark"
                                       outline
                                       dense/>
                            </div>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>

            <template v-slot:actions>
                <div class="flex full-width items-center justify-between q-px-sm">
                    <div>
                        <!--                        <q-checkbox size="xs" v-model="checkAll"-->
                        <!--                                :label="$translate('buttons.checkall')"/>-->
                    </div>
                    <div class="flex q-gutter-x-sm items-center">
                        <span>{{ $translate('buttons.with-selected') }}:</span>
                        <q-btn
                            icon="o_check"
                            :label="$translate('buttons.approve')"
                            :disable="_.size(form.ids) === 0"
                            @click="approve"
                            dense/>
                        <q-btn
                            icon="o_clear"
                            :label="$translate('buttons.decline')"
                            :disable="_.size(form.ids) === 0"
                            @click="openDeclineDialog()"
                            dense/>
                        <q-btn type="submit" form="formDownload"
                               icon="o_download"
                               :label="$translate('buttons.download-zip')"
                               :disable="_.size(form.ids) === 0"
                               dense/>
                    </div>
                </div>
            </template>
        </Card>
        <form :action="$route('project.research.report.files-download', props.research)"
              id="formDownload" method="post" target="_blank">
            <input type="hidden" name="_token" :value="token">
            <input type="hidden" name="ids[]" v-for="(id) in form.ids" :value="id">
        </form>
        <q-dialog v-model="declineDialog" no-backdrop-dismiss>
            <Card in-modal show-close-button :title="$translate('research.list.decline-modal-comment')">
                <q-input type="textarea" rows="5" v-model="form.comment"
                         :label="$translate('research.list.decline-modal-comment')"/>
                <template v-slot:actions>
                    <q-btn icon="clear"
                           :label="$translate('buttons.decline')"
                           color="negative"
                           @click="decline"/>
                    <q-btn :label="$translate('buttons.cancel')"
                           color="dark"
                           @click="closeDeclineDialog"/>
                </template>
            </Card>
        </q-dialog>
    </ResearchLayout>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";

import ResearchLayout from "@/Components/ProjectResearch/ResearchLayout.vue";
import Card from "@/Components/Block/Card.vue";
import AlertInfo from "@/Components/Block/AlertInfo.vue";
import AlertDanger from "@/Components/Block/AlertDanger.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    research: {type: Object, required: true},
    report: {type: Object, required: true},
    hasBroken: {type: Boolean, default: false}
})

const form = useForm({
    ids: [],
    file_id: null,
    state: null,
    comment: null
})

const token = computed(() => usePage().props.csrf_token)
const approve = (id) => {
    form.transform((data) => ({
        ...data,
        file_id: id ? id : null,
        state: 20
    }))
        .patch(route('project.research.report.files.update', props.research), {
            onSuccess: () => {
                router.reload()
            }
        })
}

const declineDialog = ref(false)
const openDeclineDialog = (id = null) => {
    if (id) {
        form.file_id = id
    }
    declineDialog.value = true
}
const closeDeclineDialog = () => {
    form.file_id = null;
    declineDialog.value = false
}
const decline = () => {
    form.transform((data) => ({
        ...data,
        state: 30
    }))
        .patch(route('project.research.report.files.update', props.research), {
            onSuccess: () => {
                declineDialog.value = false
                router.reload()
            }
        })
}
const downloadFile = (file) => {
    return route('project.research.report.files.download', {
        research: props.research,
        file: file.id
    })
}

</script>