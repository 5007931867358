<template>
    <HasChanges v-if="form.isDirty"/>
    <q-table :rows="rows" :columns="columns"
             row-key="name"
             table-header-class="text-uppercase bg-grey-3"
             :rows-per-page-options="[0]"
             hide-pagination
             wrap-cells>
        <template v-slot:body="props">
            <q-tr :props="props">
                <template v-if="props.row.type === 'divider'">
                    <q-td key="label" :props="props" colspan="100%" class="text-italic">
                        {{ props.row.label }}
                    </q-td>
                </template>
                <template v-else>
                    <q-td key="label" :props="props" style="width: 20%">
                        {{ props.row.label }}
                    </q-td>
                    <q-td key="value" :props="props" style="width: 40%">
                        <q-input v-if="props.row.type === 'text'"
                                 v-model="form[props.row.block][props.row.name]"
                                 outlined
                                 dense
                        />
                        <q-input v-if="props.row.type === 'textarea'"
                                 v-model="form[props.row.block][props.row.name]"
                                 type="textarea"
                                 rowspan="3"
                                 outlined
                                 dense
                        />
                        <div v-if="props.row.type === 'file'">
                            <q-file v-model="form[props.row.block][props.row.name]"
                                    v-bind="props.row.props"
                                    clearable
                                    clear-icon="clear"
                                    outlined
                            >
                                <template v-slot:prepend>
                                    <q-icon name="attach_file"/>
                                </template>
                                <template v-slot:append v-if="props.row.append">
                                    <q-btn icon="download"
                                           :title="$translate('Скачать загруженный файл')"
                                           flat dense
                                           :href="'/storage/'+props.row.append"
                                           target="_blank"/>
                                </template>
                            </q-file>
                        </div>
                        <div v-if="props.row.type === 'file:image'">
                            <q-file v-model="form[props.row.block][props.row.name]"
                                    v-bind="props.row.props"
                                    clearable
                                    clear-icon="clear"
                                    outlined
                            >
                                <template v-slot:prepend>
                                    <q-icon name="attach_file"/>
                                </template>
                                <template v-slot:append v-if="props.row.append">
                                    <q-avatar>
                                        <img :src="'/storage/'+props.row.append" alt=""/>
                                    </q-avatar>
                                </template>
                            </q-file>
                        </div>
                        <q-toggle v-if="props.row.type === 'checkbox'"
                                  v-model="form[props.row.block][props.row.name]"
                                  true-value="1"
                                  false-value="0"
                        />
                        <q-select v-if="props.row.type === 'select' && props.row.multiple === false"
                                  v-model="form[props.row.block][props.row.name]"
                                  :options="props.row.options"
                                  :emit-value="_.every(props.row.options, _.isObject)"
                                  map-options
                                  options-dense
                                  clearable
                                  clear-icon="clear"
                                  outlined
                                  dense
                        >
                            <!--                            <template v-slot:selected>-->
                            <!--                            <span v-if="!_.isEmpty(form[props.row.block][props.row.name])">-->
                            <!--                                {{ getSelectedLabel(props.row.options, form[props.row.block][props.row.name]) }}-->
                            <!--                            </span>-->
                            <!--                            </template>-->
                        </q-select>
                        <q-select v-if="props.row.type === 'select' && props.row.multiple === true"
                                  v-model="form[props.row.block][props.row.name]"
                                  :options="props.row.options"
                                  map-options
                                  options-html
                                  :emit-value="_.every(props.row.options, _.isObject)"
                                  options-dense
                                  use-chips
                                  clearable
                                  clear-icon="clear"
                                  multiple
                                  outlined
                                  dense
                        >
                            <!--                            <template v-slot:option="scope">-->
                            <!--                                <q-item v-bind="scope.itemProps">-->
                            <!--                                    <q-item-section>-->
                            <!--                                        <q-item-label v-html="scope.opt.label"></q-item-label>-->
                            <!--                                    </q-item-section>-->
                            <!--                                </q-item>-->
                            <!--                            </template>-->
                            <!--                            <template v-slot:selected>-->
                            <!--                            <span v-if="!_.isEmpty(form[props.row.block][props.row.name])">-->
                            <!--                                <q-chip-->
                            <!--                                        v-for="(label, i) in getSelectedLabels(props.row.options, form[props.row.block][props.row.name])"-->
                            <!--                                        :key="i"-->
                            <!--                                        v-html="label"-->
                            <!--                                />-->
                            <!--                            </span>-->
                            <!--                            </template>-->
                        </q-select>
                        <q-select v-if="props.row.type === 'tags'"
                                  v-model="form[props.row.block][props.row.name]"
                                  new-value-mode="add"
                                  input-debounce="0"
                                  hide-dropdown-icon
                                  use-chips
                                  use-input
                                  multiple
                                  outlined
                                  dense
                        />
                        <div v-if="props.row.type === 'password'" class="row">
                            <q-field v-if="!_.isEmpty(props.row.value) && !checkPasswordField(props.row)"
                                     class="col"
                                     outlined
                                     disable
                                     dense
                            >
                                <template v-slot:control>
                                    <div>**********</div>
                                </template>
                            </q-field>
                            <q-input v-else
                                     v-model="form[props.row.block][props.row.name]"
                                     type="text"
                                     class="col"
                                     outlined
                                     dense
                            />
                            <q-btn color="grey-8" icon="o_vpn_key" @click="enablePassword(props.row)" flat>
                                <q-tooltip class="text-body2">
                                    {{ $translate('settings.password-field-enable') }}
                                </q-tooltip>
                            </q-btn>
                            <span style="display: none">{{ passwordWatcherHelper }}</span>
                        </div>
                        <q-input v-if="props.row.type === 'number'"
                                 v-model.number="form[props.row.block][props.row.name]"
                                 :min="props.row.min ? props.row.min : 0"
                                 :step="props.row.step ? props.row.step : 1"
                                 :max="props.row.max ? props.row.max : false"
                                 :rules="props.row.rules ? props.row.rules : false"
                                 :mask="props.row.mask ? props.row.mask : false"
                                 :reverse-fill-mask="!!props.row.reverseFillMask"
                                 type="number"
                                 outlined
                                 dense
                        />
                        <q-option-group v-if="props.row.type === 'checkbox-group'"
                                        v-model="form[props.row.block][props.row.name]"
                                        :options="props.row.options"
                                        type="toggle"
                        />
                        <q-option-group v-if="props.row.type === 'radio'"
                                        v-model="form[props.row.block][props.row.name]"
                                        :options="props.row.options"
                        />
                    </q-td>
                    <q-td key="description" :props="props" style="width: 40%" auto-width>
                    <span
                            v-if="props.row.type === 'password' && !_.isEmpty(props.row.value) && !checkPasswordField(props.row)"
                            class="text-negative">
                        {{ $translate('settings.password-entered') }}
                    </span>
                        <span v-else class="text-italic text-caption" v-html="props.row.description"></span>
                    </q-td>
                </template>
            </q-tr>
        </template>
    </q-table>
    <HasChanges v-if="form.isDirty"/>
    <div class="q-pa-sm text-center">
        <q-btn color="positive"
               icon="o_save"
               :label="$translate('buttons.save')"
               @click="submitForm"
               :loading="form.processing">
            <template v-slot:loading>
                <q-spinner-facebook/>
            </template>
        </q-btn>
    </div>
</template>

<script setup>
import {HasChanges} from "@/Components/Block";
import {getCurrentInstance, onBeforeMount, reactive, ref} from "vue";
import {useForm, router} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    data: {
        type: Array,
        default: [],
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const columns = [
    {
        name: 'label',
        label: $translate('settings.table.label'),
        align: 'left',
    },
    {
        name: 'value',
        label: $translate('settings.table.value'),
        align: 'left',
    },
    {
        name: 'description',
        label: $translate('settings.table.description'),
        align: 'left',
    }
];
const rows = ref(props.data);

let form = null;

const passwords = reactive({});
const passwordWatcherHelper = ref('');

onBeforeMount(() => {
    let forma = {}
    _.forEach(rows.value, (row) => {
        if (!forma.hasOwnProperty(row.block)) {
            forma[row.block] = {}
        }
        if (row.type !== 'password') {
            forma[row.block][row.name] = row.value
        }
    })
    form = useForm(forma);
});

const enablePassword = (field) => {
    _.set(passwords, `${field.block}.${field.name}`, '');
    passwordWatcherHelper.value = 'render template';
};
const checkPasswordField = (field) => _.has(passwords, `${field.block}.${field.name}`);

const getSelectedLabel = (options, value) => _.every(options, _.isObject) ?
        _.get(_.find(options, {'value': value}), 'label') :
        value;
const getSelectedLabels = (options, values) => _.map(values, value => getSelectedLabel(options, value));

const submitForm = () => {
    form.post(
            route('admin.settings.save'),
            {
                onSuccess: () => {
                    router.reload()
                }
            }
    )
};
</script>