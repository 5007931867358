<template>
    <q-tabs v-model="tabs" align="left" no-caps breakpoint="500">
        <q-tab name="common"
               :label="$translate('equipment.item.tabs.common')"
               alert-icon="error"
               :alert="errorsCommon"
        />
        <q-tab v-if="isComplex"
               name="complex"
               :label="$translate('equipment.item.tabs.complex')"
        />
        <q-tab name="buh"
               :label="$translate('equipment.item.tabs.finance')"
        />
        <q-tab name="rashod"
               :label="$translate('equipment.item.tabs.rashod')"
        />
        <q-tab name="dopinfo"
               :label="$translate('equipment.item.tabs.dopinfo')"
               alert-icon="error"
               :alert="errorsDopinfo"/>
    </q-tabs>
    <q-tab-panels v-model="tabs">
        <q-tab-panel name="common">
            <EquipmentMainInformation v-model="equipment"/>
        </q-tab-panel>
        <q-tab-panel name="complex" v-if="isComplex">
            <EquipmentComplex v-model="equipment"/>
        </q-tab-panel>
        <q-tab-panel name="buh">
            <Alert variant="warning" v-if="!equipment.equipment_type"
                   :message="$translate('equipment.validation.no-equipment-type')"/>
            <EquipmentFinancialSingle v-if="isSingle" v-model="equipment"/>
            <EquipmentFinancialComplex v-if="isComplex" v-model="equipment"/>
        </q-tab-panel>
        <q-tab-panel name="rashod">
            <Alert variant="warning" v-if="!equipment.equipment_type"
                   :message="$translate('equipment.validation.no-equipment-type')"/>
            <EquipmentConsumables v-else v-model="equipment"/>
        </q-tab-panel>
        <q-tab-panel name="dopinfo">
            <EquipmentAdditional v-model="equipment"/>
        </q-tab-panel>
    </q-tab-panels>
</template>

<script setup lang="ts">

import {router, usePage} from "@inertiajs/vue3";
import {computed, getCurrentInstance, ref} from "vue";
import _ from "lodash";
import EquipmentMainInformation from "@/Components/Equipment/Tabs/EquipmentMainInformation.vue";
import EquipmentFinancialSingle from "@/Components/Equipment/Tabs/EquipmentFinancialSingle.vue";
import EquipmentAdditional from "@/Components/Equipment/Tabs/EquipmentAdditional.vue";
import EquipmentConsumables from "@/Components/Equipment/Tabs/EquipmentConsumables.vue";
import EquipmentComplex from "@/Components/Equipment/Tabs/EquipmentComplex.vue";
import EquipmentFinancialComplex from "@/Components/Equipment/Tabs/EquipmentFinancialComplex.vue";
import {Alert} from "@/Components/Block";

const tabs = ref('common');
const app = getCurrentInstance()

const $translate = app!.appContext.config.globalProperties.$translate

const equipment: any = defineModel()

const errors = computed(() => usePage().props.errors)

const errorsCommon = computed(() => {
    let f = ['equipment_type', 'equipment_catalogue_id', 'display_name', 'purpose', 'multi_assay', 'public.photo', 'ssc_id']
    let hasErrors = _.intersection(Object.keys(errors.value), f)
    if (!_.isEmpty(hasErrors)) {
        return "negative"
    }
    return false
})

const errorsDopinfo = computed(() => {
    let f = ['public.description', 'public.technical']
    let hasErrors = _.intersection(Object.keys(errors.value), f)
    if (!_.isEmpty(hasErrors)) {
        return "negative"
    }
    return false
})

const isComplex = computed(() => _.get(equipment.value, 'equipment_type') === 'complex')
const isSingle = computed(() => _.get(equipment.value, 'equipment_type') === 'single')
</script>