<template>
    <q-card-section v-if="!field.hidden">
        <q-item-label caption v-if="!hideLabel">
            <span>{{ fieldLabel(field) }}</span>
            <span v-if="isRequired">*</span>
            <span>:</span>
        </q-item-label>

        <template v-if="field.type === 'input:text'">
            <q-input v-model="fieldValue"
                     maxlength="250"
                     counter
                     dense
                     :error="error"
                     :error-message="errorMessage"
            />
        </template>

        <template v-if="field.type === 'input:number'">
            <q-input type="number"
                     v-model="fieldValue"
                     style="width: 300px;max-width: 80vw"
                     dense
                     :min="_.get(field.variants,'min')"
                     :max="_.get(field.variants,'max')"
                     v-bind="field.attr"
                     :error="error"
                     :error-message="errorMessage"
            />
        </template>

        <template v-if="field.type==='input:checkbox'">
            <q-option-group v-model="fieldValue"
                            type="checkbox"
                            :options="options"
                            size="sm"
                            dense
            />
            <div class="text-negative text-small" v-if="error">{{ errorMessage }}</div>
        </template>

        <template v-if="field.type==='input:radio'">
            <q-option-group v-model="fieldValue"
                            type="radio"
                            :options="options"
                            size="sm"
                            dense
            />
            <div class="text-negative text-small" v-if="error">{{ errorMessage }}</div>
        </template>

        <template v-if="field.type==='input:date'">
            <q-input mask="date"
                     v-model="fieldValue"
                     style="width: 200px;max-width: 80vw"
                     input-class="text-center"
                     dense
                     :error="error"
                     :error-message="errorMessage"
            >
                <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                            <q-date v-model="fieldValue"
                                    today-btn minimal>
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup :label="$translate('buttons.select')" color="primary" flat/>
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-input>
        </template>

        <template v-if="field.type==='input:time'">
            <q-input mask="time" :rules="['time']"
                     v-model="fieldValue"
                     style="width: 200px;max-width: 80vw"
                     input-class="text-center"
                     dense
                     :error="error"
                     :error-message="errorMessage"
            >
                <template v-slot:append>
                    <q-icon name="access_time" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                            <q-time v-model="fieldValue">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup :label="$translate('buttons.close')" color="primary" flat/>
                                </div>
                            </q-time>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-input>
        </template>

        <template v-if="field.type==='input:datetime'">
            <q-input style="width: 300px; max-width: 80vw"
                     v-model="fieldValue"
                     input-class="text-center"
                     dense
                     :error="error"
                     :error-message="errorMessage"
            >

                <template v-slot:prepend>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                            <q-date v-model="fieldValue" mask="YYYY-MM-DD HH:mm" minimal>
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup :label="$translate('buttons.select')" color="primary" flat/>
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>

                <template v-slot:append>
                    <q-icon name="access_time" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                            <q-time v-model="fieldValue" mask="YYYY-MM-DD HH:mm" format24h>
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup :label="$translate('buttons.close')" color="primary" flat/>
                                </div>
                            </q-time>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-input>
        </template>

        <template v-if="field.type==='select' || field.type==='select:multi'">
            <!-- Простой список -->
            <q-select v-if="!isMultiLevelOptions"
                      v-model="fieldValue"
                      :options="options"
                      :multiple="isMulti"
                      :use-chips="isMulti"
                      clear-icon="clear"
                      :clearable="!isRequired"
                      options-dense
                      map-options
                      emit-value
                      dense
                      :error="error"
                      :error-message="errorMessage"
            />
            <!-- Многоуровневый список -->
            <q-field v-else
                     :error="error"
                     :error-message="errorMessage"
            >
                <template v-slot:control>
                    <q-item-section v-if="fieldValue">
                        <q-item-label v-if="_.isArray(fieldValue)">
                            <template v-for="(item) in getClassificatorItems(field, fieldValue)">
                                <q-chip>{{ item.label }}</q-chip>
                            </template>
                        </q-item-label>
                        <q-item-label v-else>
                            {{ getClassificatorItem(field, fieldValue).label }}
                        </q-item-label>
                    </q-item-section>
                    <q-popup-edit v-slot="scope"
                                  v-model="fieldValue"
                                  anchor="top left"
                                  buttons
                                  :label-set="$translate('buttons.select')"
                                  :label-cancel="$translate('buttons.cancel')">

                        <q-scroll-area style="height: 400px; max-height: 50vh">
                            <ElementTree :items="options"
                                         :level="0"
                                         :parent="0"
                                         :active-element="_.get(scope,'value')">
                                <template v-slot:name="node">
                                    <q-item-label class="col flex self-center items-start column"
                                                  @click="!node.node.has_children?(isMulti?scope.value.push(node.node.value):scope.value = node.node.value):null"
                                                  :title="node.node.label">
                                        <span>{{ node.node.label }}</span>
                                    </q-item-label>
                                </template>
                            </ElementTree>
                        </q-scroll-area>
                    </q-popup-edit>
                </template>
            </q-field>
        </template>

        <template v-if="field.type==='file' || field.type==='file:multi'">
            <q-file v-model="fieldValue"
                    :multiple="isMulti"
                    :use-chips="isMulti"
                    dense
                    :error="error"
                    :error-message="errorMessage"
            >
                <template v-slot:prepend>
                    <q-icon name="attach_file"/>
                </template>
            </q-file>
        </template>

        <template v-if="field.type==='textarea'">
            <q-input v-model="fieldValue"
                     type="textarea"
                     rows="3"
                     counter
                     dense
                     :error="error"
                     :error-message="errorMessage"
            />
        </template>

        <template v-if="field.type==='alert'">
            <Alert :message="fieldLabel(field)" :variant="field.variants.type"/>
        </template>

        <q-item-label v-if="field.description && !error">
            <small class="text-italic">{{ _.get(field.description, locale) }}</small>
        </q-item-label>
        <q-item-label v-if="field.comment && !error">
            <small class="text-italic">{{ _.get(field, 'comment') }}</small>
        </q-item-label>
    </q-card-section>
</template>

<script setup>
import {useQuasar} from "quasar";
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch} from "vue";
import _ from "lodash";

import ElementTree from "@/Components/ElementTree.vue";
import {usePage} from "@inertiajs/vue3";
import Alert from "@/Components/Block/Alert.vue";

const $q = useQuasar()
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate


const props = defineProps({
    field: {
        type: Object,
        required: true,
        default() {
            return {
                name: '',
                label: {},
                description: {},
                hideLabel: false,
                type: 'input:text',
                required: false,
                rules: [],
                variants: {
                    type: null,
                    model: null,
                    values: null,
                    classificator: null,
                    science_classificator: null
                },
                /** аттрибуты поля **/
                attr: [],
                /** свойства поля **/
                props: [],
                /** скрывать/показывать поле **/
                hidden: false
            }
        }
    },
    options: {type: Array, default: []},
    error: {type: Boolean, required: false, default: false},
    errorMessage: {type: String, required: false, default: null}
})

const fieldValue = defineModel({
    required: true
})

const emit = defineEmits(['update:value'])
/**
 *
 * @type {ComputedRef<string>}
 */
const locale = computed(() => usePage().props.locale)

/**
 * Мультивыбор у поля
 *
 * @returns {boolean}
 */
const isMulti = computed(() => {
    switch (props.field.type) {
        case "select:multi":
        case "input:checkbox":
        case "file:multi":
            return true;
        default:
            return false;
    }
})

/**
 * Скрывать label
 *
 * @type {ComputedRef<boolean>}
 */
const hideLabel = computed(() => {
    if (props.field.hideLabel) {
        return true;
    }
    if (props.field.type === 'alert') {
        return true;
    }
    return false;
})
/**
 * Могут ли у поля быть опции выбора
 *
 * @returns {boolean}
 */
const hasOptions = () => {
    switch (props.field.type) {
        case "select":
        case "select:multi":
        case "input:checkbox":
        case "input:radio":
            return true;
        default:
            return false;
    }
}

/**
 * Поле обязательно для заполнения
 *
 * @returns {undefined}
 */
const isRequired = computed(() => {
    return ['1', true].includes(_.get(props.field, 'required'));
})
/**
 * Поле с многоуровневыми списками
 *
 * @returns {boolean}
 */
const isMultiLevelOptions = computed(() => {
    return _.get(props.field, 'variants.type') === "science_classificator";
})

/**
 * Label для поля
 *
 * @param field
 * @returns {string}
 */
const fieldLabel = (field) => {
    if (field.hasOwnProperty('label')) {
        return _.get(field.label, locale.value);
    }
    return field.name
}

/**
 *
 * @param field
 * @param id
 * @returns {unknown}
 */
const getClassificatorItem = (field, id) => {
    return _.find(props.options, {value: id});
}
/**
 *
 * @param field
 * @param ids
 * @returns {string[]}
 */
const getClassificatorItems = (field, ids) => {
    return _.filter(props.options, (item) => {
        return _.isArray(ids) ? ids.includes(item.id) : ids === item.id
    });
}
</script>