<template>
    <Alert v-if="$translate('module-feedback.description')"
           variant="info"
           :message="$translate('module-feedback.description')"
    />
    <SettingsTable :data="config"/>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const config = [
    {
        name: 'FEEDBACK_ENABLED',
        label: $translate('module-feedback.settings.FEEDBACK_ENABLED'),
        value: props.settings.module.FEEDBACK_ENABLED,
        description: $translate('module-feedback.settings.FEEDBACK_ENABLED_description'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'FEEDBACK_UPLOAD_FILES',
        label: $translate('module-feedback.settings.FEEDBACK_UPLOAD_FILES'),
        value: props.settings.module.FEEDBACK_UPLOAD_FILES,
        description: $translate('module-feedback.settings.FEEDBACK_UPLOAD_FILES_description'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'FEEDBACK_REQUIRED_FILES',
        label: $translate('module-feedback.settings.FEEDBACK_REQUIRED_FILES'),
        value: props.settings.module.FEEDBACK_REQUIRED_FILES,
        description: $translate('module-feedback.settings.FEEDBACK_REQUIRED_FILES_description'),
        block: 'module',
        type: 'checkbox',
    },
];
</script>
