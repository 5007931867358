<template>
    <div v-if="data.module.archive.enabled">
        <Alert v-if="$translate('module-archive.description')"
               variant="info"
               :message="$translate('module-archive.description')"
        />
        <SettingsTable :data="config"/>
    </div>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";

const props = defineProps({
    settings: {
        type: Object,
        // Object or array defaults must be returned from a factory function.
        // The function receives the raw props received by the component as the argument.
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const config = [
    {
        name: 'ARCHIVE_ENABLED',
        label: $translate('module-archive.settings.ARCHIVE_ENABLED'),
        value: props.settings.module.ARCHIVE_ENABLED,
        description: $translate('module-archive.settings.ARCHIVE_ENABLED_description'),
        block: 'module',
        type: 'checkbox',
    },
];
</script>
