<template>
    <Card :title="$translate('equipment.item.tabs.work')" hide-actions>
        <div class="row q-gutter-md" :class="{'column':$q.screen.lt.md}">
            <div class="col">
                <q-field tag="div">
                    <template v-slot:control>
                        <div class="self-center full-width flex items-center justify-between no-wrap">
                            <div class="ellipsis-2-lines">{{ $translate('equipment.item.fields.self_work') }}</div>

                            <q-icon v-if="equipment.worktime_self" name="check" size="sm" color="positive"
                                    :title="$translate('equipment.item.fields.self-work-1')"/>
                            <q-icon v-else name="o_cancel" size="sm" color="negative"
                                    :title="$translate('equipment.item.fields.self-work-0')"/>
                        </div>
                    </template>
                    <template v-slot:append v-if="!equipment.is_expired && equipment.permission.edit">
                        <q-btn flat :label="$translate('buttons.on-off')" @click="selfWorkModal = true" no-caps></q-btn>
                    </template>
                </q-field>
                <q-dialog v-model="selfWorkModal" no-backdrop-dismiss>
                    <Card :title="$translate('equipment.item.fields.self-work')"
                          style="width: 700px;max-width: 90vw" show-close-button>
                        <div class="row column">
                            <q-radio v-model="selfWorkForm.self_work" val="0">
                                {{ $translate('equipment.item.fields.self-work-0') }}
                            </q-radio>
                            <q-radio v-model="selfWorkForm.self_work" val="1">
                                {{ $translate('equipment.item.fields.self-work-1') }}
                            </q-radio>
                        </div>
                        <template v-slot:actions>
                            <q-btn color="positive" icon="save" :label="$translate('buttons.save')"
                                   @click="selfWorkSubmit"/>
                        </template>
                    </Card>
                </q-dialog>
                <q-field tag="div">
                    <template v-slot:control>
                        <div class="self-center full-width flex items-center justify-between no-wrap">
                            <div class="ellipsis-2-lines">
                                {{ $translate('equipment.item.fields.week-work-without-operator') }}
                            </div>
                            <q-icon v-if="equipment.worktime_self_week" name="check" size="sm" color="positive"
                                    :title="$translate('equipment.item.fields.week-work-without-operator-1')"/>
                            <q-icon v-else name="o_cancel" size="sm" color="negative"
                                    :title="$translate('equipment.item.fields.week-work-without-operator-0')"/>
                        </div>
                    </template>
                    <template v-slot:append v-if="!equipment.is_expired && equipment.permission.edit">
                        <q-btn flat :label="$translate('buttons.on-off')" @click="weekWorkModal = true" no-caps></q-btn>
                    </template>
                </q-field>
                <q-dialog v-model="weekWorkModal" no-backdrop-dismiss>
                    <Card :title="$translate('equipment.item.fields.week-work-without-operator')"
                          style="width: 700px;max-width: 90vw" show-close-button>
                        <div class="row column">
                            <q-radio v-model="weekWorkForm.self_work_week" val="0">
                                {{ $translate('equipment.item.fields.week-work-without-operator-0') }}
                            </q-radio>
                            <q-radio v-model="weekWorkForm.self_work_week" val="1">
                                {{ $translate('equipment.item.fields.week-work-without-operator-1') }}
                            </q-radio>
                        </div>
                        <template v-slot:actions>
                            <q-btn color="positive" icon="save" :label="$translate('buttons.save')"
                                   @click="weekWorkSubmit"/>
                        </template>
                    </Card>
                </q-dialog>
            </div>
            <div class="col q-gutter-y-md">
                <Card :title="$translate('equipment.item.title.work-common')" no-card-section>
                    <template v-slot:headerActions>
                        <q-btn icon="help_outline"
                               flat dense
                               href="https://docs.researchims.ru/guide_staff/manager/devices/#devices_operating_mode"
                               target="_blank"/>
                    </template>
                    <q-item dense v-if="equipment.schedule">
                        <q-item-section>
                            <q-item-label>
                                {{ $translate('buttons.from') }}
                                {{ moment(equipment.schedule.start).format($translate('common.date.js')) }}
                                {{
                                    equipment.schedule.end ? $translate('buttons.to') + ' ' + moment(equipment.schedule.end).format($translate('common.date.js')) : $translate('buttons.now')
                                }}
                            </q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-table v-if="equipment.schedule" :columns="$translate('fields.weekdays')"
                             :rows="[equipment.schedule.worktime_day]"
                             hide-pagination hide-no-data flat dense>
                        <template v-slot:header-cell="props">
                            <q-th class="text-center text-uppercase">
                                {{ props.col.label }}
                            </q-th>
                        </template>
                        <template v-slot:body-cell="props">
                            <q-td class="text-center text-uppercase" :props="props">
                                {{ helpers.hour2time(_.get(props.row, props.col.day)) }}
                            </q-td>
                        </template>
                    </q-table>
                    <q-banner v-else class="text-negative" dense>
                        {{ $translate('equipment.item.error.no-common-worktime') }}
                    </q-banner>
                    <q-item v-if="equipment.schedule" class="text-center">
                        <q-item-section>
                            <q-item-label caption class="text-no-wrap">
                                {{ $translate('equipment.item.fields.worktime_year') }}
                            </q-item-label>
                            <q-item-label>{{ equipment.worktime_year }}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption class="text-no-wrap">
                                {{ $translate('equipment.item.fields.work-period') }}
                            </q-item-label>
                            <q-item-label>{{ calcTimeForPeriod(equipment.schedule) }}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption class="text-no-wrap">
                                {{ $translate('Стоимость') }}
                            </q-item-label>
                            <q-item-label>{{ helpers.formatNumber(equipment.schedule.depreciation) }} {{ $translate('units.rub-hour') }}</q-item-label>
                        </q-item-section>
                    </q-item>

                    <template v-slot:actions>
                        <div class="row justify-between full-width items-center">
                            <EquipmentScheduleCommonForm :equipment="equipment"/>
                            <q-btn icon="history" :label="$translate('equipment.item.title.worktime-common-list')"
                                   dense color="grey-3" text-color="dark" no-caps
                                   @click="commonHistory = !commonHistory"/>
                        </div>
                        <div v-if="commonHistory" class="full-width">
                            <q-list separator>
                                <template v-for="(history) in equipment.schedule_history">
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label caption class="flex justify-between items-center">
                                                <div class="flex items-center">
                                                    <div>
                                                        {{ $translate('buttons.from') }}
                                                        {{
                                                            moment(history.start).format($translate('common.date.js'))
                                                        }}
                                                        {{ $translate('buttons.to') }}
                                                        {{
                                                            history.end ? moment(history.end).format($translate('common.date.js')) : $translate('buttons.now')
                                                        }}
                                                    </div>
                                                    <q-btn v-if="moment().startOf('day').isBefore(moment(history.start).startOf('day'))"
                                                           icon="o_delete"
                                                           :title="$translate('buttons.delete')"
                                                           size="sm"
                                                           color="negative"
                                                           flat
                                                           @click="router.delete($route('equipment.schedule.common.delete',{equipment:equipment,schedule:history}))"/>

                                                    <q-btn v-if="history.confirm_document"
                                                           icon="download" size="xs" flat dense
                                                           :href="history.confirm_document.fullpath" target="_blank">
                                                        <q-tooltip size="md">
                                                            {{ $translate('equipment.item.fields.confirm_document') }}
                                                        </q-tooltip>
                                                    </q-btn>
                                                </div>
                                                <div>
                                                    {{ $translate('equipment.item.fields.work-period') }}:
                                                    {{ calcTimeForPeriod(history) }}
                                                </div>
                                            </q-item-label>
                                            <q-table :columns="$translate('fields.weekdays')"
                                                     :rows="[history.worktime_day]"
                                                     hide-pagination hide-header
                                                     hide-no-data flat dense
                                                     separator="vertical">
                                                <template v-slot:body-cell="props">
                                                    <q-td class="text-uppercase" :props="props">
                                                        <div class="flex column full-width items-stretch">
                                                            <span class="text-small text-left">{{
                                                                    props.col.label
                                                                }}</span>
                                                            <span>{{
                                                                    helpers.hour2time(_.get(props.row, props.col.day))
                                                                }}</span>
                                                        </div>
                                                    </q-td>
                                                </template>
                                            </q-table>
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-list>
                        </div>
                    </template>
                </Card>

                <Card :title="$translate('equipment.item.title.work-planned')" no-card-section>
                    <template v-slot:headerActions>
                        <q-btn icon="help_outline" flat dense
                               href="https://docs.researchims.ru/guide_staff/manager/devices/#devices_operating_mode"
                               target="_blank"/>
                    </template>
                    <q-item dense v-if="equipment.loading">
                        <q-item-section>
                            <q-item-label>
                                {{ $translate('buttons.from') }}
                                {{ moment(equipment.loading.start).format($translate('common.date.js')) }}
                                {{ $translate('buttons.to') }}
                                {{ moment(equipment.loading.end).format($translate('common.date.js')) }}
                            </q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-table v-if="equipment.loading" :columns="$translate('fields.weekdays')"
                             :rows="[equipment.loading.worktime_day]"
                             hide-pagination hide-no-data flat dense>
                        <template v-slot:header-cell="props">
                            <q-th class="text-center text-uppercase">
                                {{ props.col.label }}
                            </q-th>
                        </template>
                        <template v-slot:body-cell="props">
                            <q-td class="text-center text-uppercase" :props="props">
                                {{ helpers.hour2time(_.get(props.row, props.col.day)) }}
                            </q-td>
                        </template>
                    </q-table>
                    <q-banner v-else class="text-negative" dense>
                        {{ $translate('equipment.item.error.no-planned-worktime') }}
                    </q-banner>
                    <q-item v-if="equipment.loading" class="text-center">
                        <q-item-section>
                            <q-item-label caption class="text-no-wrap">
                                {{ $translate('equipment.item.fields.worktime_year') }}
                            </q-item-label>
                            <q-item-label>{{ calcTimeFromPeriod(equipment.loading) }}</q-item-label>
                        </q-item-section>

                        <q-item-section>
                            <q-item-label caption class="text-no-wrap">
                                {{ $translate('equipment.item.fields.work-period') }}
                            </q-item-label>
                            <q-item-label>{{ equipment.loading.worktime_period }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <template v-slot:actions>
                        <div class="row justify-between full-width items-center">
                            <EquipmentSchedulePlannedForm :equipment="equipment"/>
                            <q-btn icon="history" :label="$translate('equipment.item.title.worktime-planned-list')"
                                   dense color="grey-3" text-color="dark" no-caps
                                   @click="plannedHistory = !plannedHistory"/>
                        </div>
                        <div v-if="plannedHistory" class="full-width">
                            <q-list separator>
                                <template v-for="(history) in equipment.loading_history">
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label caption class="row justify-between items-center">
                                                <div class="row items-center">
                                                    <div>
                                                        {{ $translate('buttons.from') }}
                                                        {{
                                                            moment(history.start).format($translate('common.date.js'))
                                                        }}
                                                        {{ $translate('buttons.to') }}
                                                        {{
                                                            moment(history.end).format($translate('common.date.js'))
                                                        }}
                                                        <q-btn v-if="moment().startOf('day').isBefore(moment(history.start).startOf('day'))"
                                                               icon="o_delete"
                                                               :title="$translate('buttons.delete')"
                                                               size="sm"
                                                               color="negative"
                                                               flat
                                                               @click="router.delete($route('equipment.schedule.planned.delete',{equipment:equipment,schedule:history}))"/>
                                                    </div>
                                                    <q-btn v-if="history.confirm_document"
                                                           icon="download" size="xs" flat dense
                                                           :href="history.confirm_document.fullpath" target="_blank">
                                                        <q-tooltip size="md">
                                                            {{ $translate('equipment.item.fields.confirm_document') }}
                                                        </q-tooltip>
                                                    </q-btn>
                                                </div>
                                                <div>
                                                    {{ $translate('equipment.item.fields.work-period') }}:
                                                    {{ calcTimeForPeriod(history) }}
                                                </div>
                                            </q-item-label>
                                            <q-table :columns="$translate('fields.weekdays')"
                                                     :rows="[history.worktime_day]"
                                                     hide-pagination hide-header
                                                     hide-no-data flat dense
                                                     separator="vertical">
                                                <template v-slot:body-cell="props">
                                                    <q-td class="text-center text-uppercase" :props="props">
                                                        <div class="flex column full-width items-stretch">
                                                            <span class="text-small text-left">{{
                                                                    props.col.label
                                                                }}</span>
                                                            <span>{{
                                                                    helpers.hour2time(_.get(props.row, props.col.day))
                                                                }}</span>
                                                        </div>
                                                    </q-td>
                                                </template>
                                            </q-table>
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-list>
                        </div>
                    </template>
                </Card>
            </div>
        </div>
    </Card>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash"
import {Card} from "@/Components/Block";
import moment from "moment";
import EquipmentSchedulePlannedForm from "@/Components/Equipment/EquipmentSchedulePlannedForm.vue";
import EquipmentScheduleCommonForm from "@/Components/Equipment/EquipmentScheduleCommonForm.vue";
import helpers from "@/helpers";
import {useQuasar} from 'quasar'

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $q = useQuasar()

const props = defineProps({
    equipment: {
        type: Object
    },
})

const selfWorkModal = ref(false)
const selfWorkForm = useForm({
    self_work: null
})
const selfWorkSubmit = () => {
    selfWorkForm.post(route('equipment.self-work.state', props.equipment), {
        onSuccess: () => {
            router.reload()
            selfWorkModal.value = false;
        }
    })
}

const weekWorkModal = ref(false)
const weekWorkForm = useForm({
    self_work_week: null
})
const weekWorkSubmit = () => {
    weekWorkForm.post(route('equipment.self-work.state', props.equipment), {
        onSuccess: () => {
            router.reload()
            weekWorkModal.value = false;
        }
    })
}

const plannedHistory = ref(false)

const commonHistory = ref(false)

const calcTimeFromPeriod = (data) => {
    return (_.sum(_.map(data.worktime_day, Number)) * 52.142).toFixed(0);
}
const calcTimeForPeriod = (data) => {
    let _start = moment(data.start).startOf('day');
    let _end = moment(data.end ? data.end : moment()).startOf('day');

    let _week = [];
    while (_start <= _end) {
        _week.push(Number(_.get(data.worktime_day, _start.format('e'))))
        _start.add('1', 'day');
    }
    return _.sum(_week).toFixed(0);
}
</script>