<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions no-card-section>

        <q-card-section>
            <FieldTags v-model="publication.tags" :errors="errors"/>
        </q-card-section>

        <FieldsRendering :fields="_.get(fields,'fields')"
                         v-model="publication.rubrics"
                         :errors="errors"
                         error-prefix="rubrics."/>
    </Card>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import FieldTags from "@/Components/Fields/FieldTags.vue";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object},
})
const publication = defineModel('publication', {})
const errors = defineModel('errors', {type: Object})

</script>