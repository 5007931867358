<template>
    <ConsumableForm :consumable="consumable"
                    :title="$translate('consumables.title.edit')"
                    :units="units"
                    :can="can"
    />
</template>

<script setup>
import ConsumableForm from "@/Components/Consumables/ConsumableForm.vue";
import {getCurrentInstance} from "vue";

const props = defineProps({
    consumable: {
        type: Object
    },
    units: {
        type: Array
    },
    can: {
        type: Object
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
