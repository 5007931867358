<template>
    <template v-for="(field) in fields">
        <q-field v-if="field.type !== 'alert'"
                 stack-label
                 borderless
                 :dense="dense">
            <template v-slot:control>
                <q-item-label caption v-if="!hideLabel(field)">{{ fieldLabel(field) }}</q-item-label>
                <div class="full-width self-center" v-html="fieldValue(field)"></div>
            </template>
        </q-field>
    </template>
</template>

<script setup>
import {useQuasar} from "quasar";
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch} from "vue";
import _ from "lodash";
import ClassificationDB from "@/plugins/ClassificationDB";

import ElementTree from "@/Components/ElementTree.vue";
import moment from "moment";

const $q = useQuasar()
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    fields: {type: Object, required: true},
    values: {type: Object, default: {}},
    dense: {type: Boolean, default: false}
})

const fieldsData = ref({})

const isMulti = (field) => {
    switch (field.type) {
        case "select:multi":
        case "input:checkbox":
        case "file:multi":
            return true;
        default:
            return false;
    }
}
const hasOptions = (field) => {
    switch (field.type) {
        case "select":
        case "select:multi":
        case "input:checkbox":
        case "input:radio":
            return true;
        default:
            return false;
    }
}

const options = ref({})

const isRequired = (field) => {
    return _.get(field, 'required');
}
const isMultiLevelOptions = (field) => {
    return _.get(field, 'variants.type') === "science_classificator";
}

const getValuesAsOptions = (field) => {
    switch (_.get(field, 'variants.type')) {
        case 'self':
            return _.map(field.variants.values, (item) => ({
                value: String(item.hasOwnProperty('value') ? item.value : item),
                label: item.hasOwnProperty('label') ? item.label[$q.lang.isoName] : item
            }))
        case "classificator":
            return _.map(_.get(options.value, _.get(field, 'variants.classificator')), (item) => ({
                value: String(item.value),
                label: item.label
            }))
        case "science_classificator":
            let _options = _.get(options.value, _.get(field, 'variants.science_classificator'));
            return _.map(_options, (item) => ({
                id: item.value,
                value: String(item.value),
                label: item.label,
                name: item.label,
                parent_id: item.parent_id,
                has_children: (item.children > 0)
            }))
        default:
            if (_.has(field, 'values')) {
                // костыль для старых шаблонов
                return _.map(_.get(field.values, $q.lang.isoName, '').split(';'), (item, index) => ({
                    value: String(index),
                    label: item
                }))
            }
            return []
    }
}

const getClassificatorItem = (field, id) => {
    return _.find(_.get(options.value, _.get(field, 'variants.science_classificator')), {id: id});
}
const getClassificatorItems = (field, ids) => {
    return _.filter(_.get(options.value, _.get(field, 'variants.science_classificator')), (item) => {
        return _.isArray(ids) ? ids.includes(item.id) : ids === item.id
    });
}

const getFileValue = (file) => {
    return file ? "<a href='/storage/" + _.get(file, 'path') + "' target='_blank' rel='nofollow noopener'>"
        + '<i class="q-icon notranslate material-icons-outlined" aria-hidden="true" role="img">attach_file</i>'
        + _.get(file, 'name')
        + "</a>"
        : ''
}

const fieldLabel = (field) => {
    if (field.hasOwnProperty('label')) {
        return field.label[$q.lang.isoName];
    }
    return field.name
}
const fieldValue = (field) => {
    let _value = _.get(props.values, field.name);
    let _options = getValuesAsOptions(field)

    if (!_value || (_.isArray(_value) && _.size(_value)) === 0) {
        return "-";
    }
    switch (field.type) {
        case "input:text":
        case "input:number":
        case "textarea":
            return _value
        case "input:date":
            return moment(_value).format($translate('common.date.js'))
        case "input:time":
            return moment(_value).format($translate('common.time.js'))
        case "input:datetime":
            return moment(_value).format($translate('common.datetime.js'))
        case "input:radio":
        case "select":
            return _.get(_.find(_options, {value: String(_value)}), 'label')
        case "input:checkbox":
        case "select:multi":
            return _.map(_.filter(_options, (item) => _.includes(_value, item.value)), 'label')
                .join("; ")
        case "file":
            return getFileValue(_value);
        case "file:multi":
            return _.map(_value, (file) => getFileValue(file)).join("<br/>");
        default:
            return _value
    }
}

/**
 * Скрывать label
 *
 * @type
 * @return boolean
 */
const hideLabel = (field) => {
    return field.hideLabel || field.type === 'alert';
}

onBeforeMount(() => {
    _.forEach(props.fields, (field) => {
        if (isMulti(field)) {
            _.set(fieldsData.value, field.name, [])
        } else {
            _.set(fieldsData.value, field.name, null)
        }
        if (hasOptions(field)) {
            if (_.get(field, 'variants.type') === 'classificator') {
                ClassificationDB
                    .getSimpleList(_.get(field, 'variants.classificator'))
                    .then((r) => {
                        _.set(options.value, _.get(field, 'variants.classificator'), r)
                    })
            }
            if (_.get(field, 'variants.type') === 'science_classificator') {
                ClassificationDB
                    .getMultiList(_.get(field, 'variants.science_classificator'))
                    .then((r) => {
                        _.set(options.value, _.get(field, 'variants.science_classificator'), r)
                    })
            }
        }
    })
})

</script>