<template>
    <Card :title="$translate('project.view.claim-info')"
          v-if="_.size(project.research_with_claim)>0"
          hide-actions no-card-section
          class="q-mb-md">
        <q-list separator>
            <template v-for="(research_with_claim) in project.research_with_claim">
                <q-item>
                    <q-item-section>
                        <q-item-label overline>
                            <div class="flex q-gutter-x-sm items-center">
                                <a :href="$route('project.research.show',research_with_claim)"
                                   @click.prevent="router.visit($route('project.research.show',research_with_claim))"
                                >#{{ research_with_claim.number }}</a>
                                <q-separator vertical/>
                                <UserWithDetail :user="research_with_claim.claim.author"/>
                                <q-separator vertical/>
                                <div class="self-center flex items-center">
                                    <q-icon name="o_calendar_month" size="xs"/>
                                    <div class="q-ml-xs">
                                        {{
                                            moment(research_with_claim.claim.created_at).format($translate('common.datetime.js'))
                                        }}
                                    </div>
                                </div>
                            </div>
                        </q-item-label>
                        <q-item-label>
                            {{ research_with_claim.claim.claim_text }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import _ from 'lodash';
import moment from "moment";
import {router} from "@inertiajs/vue3";
import UserWithDetail from "@/Components/User/UserWithDetail.vue";
import {Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    project: {
        type: Object,
        required: true
    },
    printable: {
        type: Boolean,
        default: false
    }
})
</script>