<template>
    <q-item>
        <q-item-section>
            <q-item-label class="text-weight-bold">
                {{ $translate('module-syexc.proposal.card.act_data_form.title') }}
            </q-item-label>
        </q-item-section>
        <q-item-section side top>
            <div class="row justify-between">
                <q-btn :label="$translate('module-syexc.proposal.card.act_data_form.actions.fill_act')"
                       @click="actDataDialog = !actDataDialog"
                       size="sm"
                />
            </div>
            <q-dialog v-model="actDataDialog" @hide="sendFilledData">
                <Card :title="$translate('module-syexc.proposal.card.act_data_form.modal.title')"
                      style="width: 1150px; max-width: 80vw;"
                      header-toolbar
                >
                    <template v-slot:headerActions>
                        <q-btn icon="close" flat round dense v-close-popup />
                    </template>
                    <template v-slot:default>
                        <q-select v-model="actData.p1"
                                  :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p1')"
                                  :options="$translate('module-syexc.act-data.p1')"
                                  options-dense
                                  use-chips
                                  multiple

                        />
                        <q-select v-model="actData.p2"
                                  :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p2')"
                                  :options="helpers.toOptions($translate('module-syexc.act-data.p2'))"
                                  options-dense
                                  map-options
                                  emit-value
                        />
                        <div class="q-field__label q-mt-md">
                            {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p3') }}
                        </div>
                        <q-item>
                            <q-item-section>
                                <q-select v-model="actData.p3_1"
                                          :options="$translate('module-syexc.act-data.p3_1')"
                                          options-dense
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-input v-model="actData.p3_2"
                                         :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p3_2')"
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-input v-model="actData.p3_3"
                                         :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p3_3')"
                                />
                            </q-item-section>
                        </q-item>
                        <div class="q-field__label q-mt-md">
                            {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p4') }}
                        </div>
                        <div v-for="(member, index) in actData.p4" :key="index">
                            <q-item>
                                <q-item-section>
                                    <q-input v-model="actData.p4[index].fio"
                                             :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p4_fio')"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <q-input v-model="actData.p4[index].position"
                                             :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p4_position')"
                                    />
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn :label="$translate('module-syexc.proposal.card.act_data_form.modal.actions.delete')"
                                           @click="deleteMember(index)"
                                           color="secondary"
                                           size="sm"
                                    />
                                </q-item-section>
                            </q-item>
                        </div>
                        <div class="q-mt-sm">
                            <q-btn :label="$translate('module-syexc.proposal.card.act_data_form.modal.actions.add_member')"
                                   @click="addMember"
                                   color="secondary"
                                   size="sm"
                            />
                        </div>
                        <div class="q-field__label q-mt-md">
                            {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p5') }}
                        </div>
                        <q-item>
                            <q-item-section>
                                <q-input v-model="actData.p5_1"
                                         :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p5_1')"
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-input v-model="actData.p5_2"
                                         :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p5_2')"
                                />
                            </q-item-section>
                        </q-item>
                        <div class="q-field__label q-mt-md">
                            {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p6') }}
                        </div>
                        <q-input v-model="actData.p6_1"
                                 :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p6_1')"
                                 type="textarea"
                                 rows="2"
                        />
                        <q-input v-model="actData.p6_2"
                                 :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p6_2')"
                        />
                        <div class="q-field__label">
                            <small>
                                {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p6_description') }}
                            </small>
                        </div>
                        <q-input v-model="actData.p7"
                                 :hint="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p7_description')"
                                 :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p7')"
                                 type="textarea"
                                 rows="2"
                        />
                        <div class="q-field__label q-mt-md">
                            {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p8') }}
                        </div>
                        <q-item>
                            <q-item-section>
                                <div>
                                    <q-radio v-model="actData.p8"
                                             :label="$translate('module-syexc.act-data.p8.1')"
                                             val="1"
                                    />
                                </div>
                                <div>
                                    <q-radio v-model="actData.p8"
                                             :label="$translate('module-syexc.act-data.p8.2')"
                                             val="2"
                                    />
                                    <q-item v-if="'2' === actData.p8">
                                        <q-item-section>
                                            <q-input v-model="actData.p8_2.date"
                                                     :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p8_date')"
                                                     :prefix="$translate('buttons.ot')"
                                                     dense
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-input v-model="actData.p8_2.number"
                                                     :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p8_number')"
                                                     prefix="№"
                                                     dense
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-input v-model="actData.p8_2.text" dense />
                                        </q-item-section>
                                    </q-item>
                                </div>
                                <div>
                                    <q-radio v-model="actData.p8"
                                             :label="$translate('module-syexc.act-data.p8.2')"
                                             val="3"
                                    />
                                    <q-item v-if="'3' === actData.p8">
                                        <q-item-section>
                                            <q-input v-model="actData.p8_3.date"
                                                     :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p8_date')"
                                                     :prefix="$translate('buttons.ot')"
                                                     dense
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-input v-model="actData.p8_3.number"
                                                     :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p8_number')"
                                                     prefix="№"
                                                     dense
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-input v-model="actData.p8_3.text" dense />
                                        </q-item-section>
                                    </q-item>
                                </div>
                                <div>
                                    <q-radio v-model="actData.p8"
                                             :label="$translate('module-syexc.act-data.p8.2')"
                                             val="4"
                                    />
                                    <q-item v-if="'4' === actData.p8">
                                        <q-item-section>
                                            <q-input v-model="actData.p8_4.date"
                                                     :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p8_date')"
                                                     :prefix="$translate('buttons.ot')"
                                                     dense
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-input v-model="actData.p8_4.number"
                                                     :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p8_number')"
                                                     prefix="№"
                                                     dense
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-input v-model="actData.p8_4.text" dense />
                                        </q-item-section>
                                    </q-item>
                                </div>
                                <div>
                                    <q-radio v-model="actData.p8"
                                             :label="$translate('module-syexc.act-data.p8.2')"
                                             val="5"
                                    />
                                    <q-input v-if="'5' === actData.p8"
                                             v-model="actData.p8_5.text"
                                             :hint="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p8_5')"
                                             class="q-mx-md"
                                             type="textarea"
                                             rows="1"
                                    />
                                </div>
                            </q-item-section>
                        </q-item>
                        <q-input v-model="actData.p9"
                                 :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p9')"
                                 type="textarea"
                                 rows="3"
                        />
                        <div class="q-field__label q-mt-md">
                            {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p11-12') }}
                        </div>
                        <div class="q-field__label q-mt-md">
                            {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p11') }}
                        </div>
                        <q-item>
                            <q-item-section>
                                <q-checkbox v-model="actData.p10"
                                            :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p10')"
                                            val="1"
                                />
                            </q-item-section>
                        </q-item>
                        <div class="q-field__label q-mt-md">
                            {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p11_a') }}
                        </div>
                        <q-item>
                            <q-item-section>
                                <div class="q-gutter-sm">
                                    <template v-for="(value, index) in $translate('module-syexc.act-data.p11_a')" :key="index">
                                        <q-checkbox v-model="actData.p11_a" :label="value" :val="index" />
                                    </template>
                                </div>
                            </q-item-section>
                            <q-item-section>
                                <q-input v-if="actData.p11_a.includes('3')"
                                         v-model="actData.p11_a_3"
                                         :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p11_a_3')"
                                         dense
                                />
                            </q-item-section>
                        </q-item>
                        <div class="q-field__label q-mt-md">
                            {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p11_b') }}
                        </div>
                        <q-item>
                            <q-item-section>
                                <q-checkbox v-model="actData.p11_b_1"
                                            :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p11_b_1')"
                                            :false-value="''"
                                            true-value="1"
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-select v-model="actData.p11_b_1_select"
                                          :options="$translate('module-syexc.act-data.p11_b_1')"
                                          options-dense
                                          use-chips
                                          multiple
                                          dense
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-checkbox v-model="actData.p11_b_2"
                                            :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p11_b_2')"
                                            :false-value="''"
                                            true-value="1"
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-select v-model="actData.p11_b_2_select"
                                          :options="$translate('module-syexc.act-data.p11_b_2')"
                                          options-dense
                                          use-chips
                                          multiple
                                          dense
                                />
                            </q-item-section>
                        </q-item>
                        <div class="q-field__label q-mt-md">
                            {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p12') }}
                        </div>
                        <q-item>
                            <q-item-section>
                                <div>
                                    <q-checkbox v-model="actData.p12"
                                                :label="$translate('module-syexc.act-data.p12.1')"
                                                val="1"
                                    />
                                </div>
                                <div>
                                    <q-checkbox v-model="actData.p12"
                                                :label="$translate('module-syexc.act-data.p12.2')"
                                                val="2"
                                    />
                                </div>
                                <div>
                                    <q-checkbox v-model="actData.p12"
                                                :label="$translate('module-syexc.act-data.p12.3')"
                                                val="3"
                                    />
                                    <q-input v-if="actData.p12.includes('3')"
                                             v-model="actData.p12_3"
                                             :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p12_1')"
                                             class="q-mx-md"
                                             dense
                                    />
                                </div>
                                <div>
                                    <q-checkbox v-model="actData.p12"
                                                :label="$translate('module-syexc.act-data.p12.4')"
                                                val="4"
                                    />
                                </div>
                                <div>
                                    <q-checkbox v-model="actData.p12"
                                                :label="$translate('module-syexc.act-data.p12.5')"
                                                val="5"
                                    />
                                    <q-input v-if="actData.p12.includes('5')"
                                             v-model="actData.p12_5"
                                             :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p12_5')"
                                             class="q-mx-md"
                                             dense
                                    />
                                </div>
                                <div>
                                    <q-checkbox v-model="actData.p12"
                                                :label="$translate('module-syexc.act-data.p12.6')"
                                                val="6"
                                    />
                                </div>
                                <div>
                                    <q-checkbox v-model="actData.p12"
                                                :label="$translate('module-syexc.act-data.p12.7')"
                                                val="7"
                                    />
                                </div>
                                <div>
                                    <q-checkbox v-model="actData.p12"
                                                :label="$translate('module-syexc.act-data.p12.8')"
                                                val="8"
                                    />
                                    <q-input v-if="actData.p12.includes('8')"
                                             v-model="actData.p12_8"
                                             :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p12_8')"
                                             class="q-mx-md"
                                             dense
                                    />
                                </div>
                            </q-item-section>
                        </q-item>
                        <div class="text-weight-bold q-mt-md">
                            {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p13') }}
                        </div>
                        <q-input v-model="actData.p13"
                                 :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p13_label')"
                                 :hint="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p13_description')"
                        />
                        <q-item>
                            <q-item-section>
                                <div class="q-gutter-sm">
                                    <template v-for="(value, index) in $translate('module-syexc.act-data.p13_1_1')" :key="index">
                                        <q-radio v-model="actData.p13_1_1" :label="value" :val="index" />
                                    </template>
                                </div>
                            </q-item-section>
                            <q-item-section>
                                <q-select v-model="actData.p13_1_2"
                                          :options="$translate('module-syexc.act-data.p13_1')"
                                          options-dense
                                          dense
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-input v-model="actData.p13_1_3"
                                         :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p13_1_3')"
                                         dense
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-checkbox v-model="actData.p13_2_1"
                                            :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p13_2_1')"
                                            :false-value="''"
                                            true-value="1"
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-select v-model="actData.p13_2_2"
                                          :options="$translate('module-syexc.act-data.p13_1')"
                                          options-dense
                                          dense
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-input v-model="actData.p13_2_3"
                                         :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p13_2_3')"
                                         dense
                                />
                            </q-item-section>
                        </q-item>
                        <div class="q-field__label">
                            <small>
                                {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p13_2_4_before') }}
                            </small>
                            <q-input v-model="actData.p13_2_4" type="date" dense />
                            <small>
                                {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p13_2_4_after') }}
                            </small>
                        </div>
                        <q-item>
                            <q-item-section>
                                <q-checkbox v-model="actData.p13_3_1"
                                            :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p13_3_1')"
                                            :false-value="''"
                                            true-value="1"
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-select v-model="actData.p13_3_2"
                                          :options="$translate('module-syexc.act-data.p13_3')"
                                          options-dense
                                          dense
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-input v-model="actData.p13_3_3"
                                         :label="$translate('module-syexc.proposal.card.act_data_form.modal.fields.p13_3_3')"
                                         dense
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <div class="q-gutter-sm">
                                    <template v-for="(value, index) in $translate('module-syexc.act-data.p13_4_1')" :key="index">
                                        <q-radio v-model="actData.p13_4_1" :label="value" :val="index" />
                                    </template>
                                </div>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('module-syexc.proposal.card.act_data_form.modal.fields.p13_4') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                    <template v-slot:actions>
                        <q-btn :label="$translate('module-syexc.proposal.card.act_data_form.modal.actions.close')"
                               color="positive"
                               v-close-popup
                        />
                    </template>
                </Card>
            </q-dialog>
        </q-item-section>
    </q-item>
    <slot name="download-link"></slot>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, onMounted, reactive, ref} from "vue";
import _ from "lodash";
import helpers from "@/helpers";

const props = defineProps({
    proposal: String,
    act: Object,
    country: Array
});
const emit = defineEmits(['onActDataFilled']);

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const actDataDialog = ref(false);

let actData = null;
const actDataDefault = {
    p1: [],
    p2: '',
    p3_1: '',
    p3_2: '',
    p3_3: '',
    p4: [],
    p5_1: '',
    p5_2: '',
    p6_1: '',
    p6_2: '',
    p7: '',
    p8: '',
    p8_1: {
        date: '',
        number: '',
        text: '',
    },
    p8_2: {
        date: '',
        number: '',
        text: '',
    },
    p8_3: {
        date: '',
        number: '',
        text: '',
    },
    p8_4: {
        date: '',
        number: '',
        text: '',
    },
    p8_5: {
        date: '',
        number: '',
        text: '',
    },
    p9: '',
    p10: [],
    p11_a: [],
    p11_a_3: '',
    p11_b_1: '',
    p11_b_1_select: [],
    p11_b_2: '',
    p11_b_2_select: [],
    p12: [],
    p12_3: '',
    p12_5: '',
    p12_8: '',
    p13: '',
    p13_1_1: '',
    p13_1_2: '',
    p13_1_3: '',
    p13_2_1: '',
    p13_2_2: '',
    p13_2_3: '',
    p13_2_4: '',
    p13_3_1: '',
    p13_3_2: '',
    p13_3_3: '',
    p13_4_1: '',
    p13_4_2: '',
    p13_4_3: '',
};

const addMember = () => {
    actData.p4.push({
        fio: '',
        position: '',
    });
};
const deleteMember = (index) => {
    _.remove(actData.p4, (o, i) => i === index);
};

const sendFilledData = () => {
    emit('onActDataFilled', actData);
};

onMounted(() => {
    if (props.act) {
        actData = reactive(props.act);
    } else {
        actData = reactive(actDataDefault);
    }
});
</script>
