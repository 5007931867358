<template>
    <q-list v-if="services.hasOwnProperty('finance')">
        <NavigationItem v-if="checkPerms('documents')"
                        :title="$translate('menu.finance-documents')"
                        :sidebar="sidebar"
                        color="dark"
                        icon="o_description"
        >
            <template #items>
                <q-item v-if="services.finance.items.documents.contract_view" clickable>
                    <q-item-section @click="router.visit($route('finance.contract'))" class="q-pl-md q-pr-none">
                        {{ $translate("menu.finance-documents-contract") }}
                    </q-item-section>
                    <q-item-section v-if="services.finance.items.documents.contract_add" class="q-pr-md q-pl-xs" side>
                        <q-btn @click="router.visit($route('finance.contract.add'))"
                               color="primary"
                               icon="add_box"
                               size="xs"
                               flat v-close-popup
                        >
                            <q-tooltip class="text-body2">{{ $translate('buttons.add') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                </q-item>
                <q-item v-if="services.finance.items.documents.bill_view" clickable>
                    <q-item-section @click="router.visit($route('finance.bill'))" class="q-pl-md q-pr-none">
                        {{ $translate("menu.finance-documents-bill") }}
                    </q-item-section>
                    <q-item-section v-if="services.finance.items.documents.bill_add" class="q-pr-md q-pl-xs" side>
                        <q-btn @click="router.visit($route('finance.bill.add'))"
                               color="primary"
                               icon="add_box"
                               size="xs"
                               flat v-close-popup
                        >
                            <q-tooltip class="text-body2">{{ $translate('buttons.add') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                </q-item>
                <q-item v-if="services.finance.items.documents.act_view" clickable>
                    <q-item-section @click="router.visit($route('finance.act'))" class="q-pl-md q-pr-none">
                        {{ $translate("menu.finance-documents-act") }}
                    </q-item-section>
                    <q-item-section v-if="services.finance.items.documents.act_add" class="q-pr-md q-pl-xs" side>
                        <q-btn @click="router.visit($route('finance.act.add'))"
                               color="primary"
                               icon="add_box"
                               size="xs"
                               flat
                        >
                            <q-tooltip class="text-body2">{{ $translate('buttons.add') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                </q-item>
                <q-item v-if="services.finance.items.documents.other_view" clickable>
                    <q-item-section @click="router.visit($route('finance.other'))" class="q-pl-md q-pr-none">
                        {{ $translate("menu.finance-documents-other") }}
                    </q-item-section>
                    <q-item-section v-if="services.finance.items.documents.other_add" class="q-pr-md q-pl-xs" side>
                        <q-btn @click="router.visit($route('finance.other.add'))"
                               color="primary"
                               icon="add_box"
                               size="xs"
                               flat
                        >
                            <q-tooltip class="text-body2">{{ $translate('buttons.add') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
        <NavigationItem v-if="checkPerms('contragents')"
                        :title="$translate('menu.finance-contragents')"
                        :sidebar="sidebar"
                        color="dark"
                        icon="o_home_work"
        >
            <template #items>
                <q-item v-if="services.finance.items.contragents.contragents_view"
                        @click="router.visit($route('finance.contragent'))"
                        clickable
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.finance-contragents") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.finance.items.contragents.ourcompany_view"
                        @click="router.visit($route('finance.our-company'))"
                        clickable
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.finance-ourcompany") }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
        <NavigationItem v-if="services.finance.items.service"
                        :title="$translate('menu.service')"
                        :sidebar="sidebar"
                        color="dark"
                        icon="o_price_change"
        >
            <template #items>
                <q-item @click="router.visit($route('finance.service-docs'))" clickable>
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.service-documents") }}
                    </q-item-section>
                </q-item>
                <q-item @click="router.visit($route('finance.service'))" clickable>
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.service-list") }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
    </q-list>
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";

const props = defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const page = usePage();
const services = computed(() => page.props.services);

const checkPerms = (prop) => _.some(
    _.values(usePage().props.services.finance.items[prop]),
    v => v === true
);
</script>
