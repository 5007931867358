<template>
    <Card :title="$translate('equipment.catalogue.title.export')"
          title-bg="deep-purple-1"
          header-toolbar hide-actions no-card-section>
        <template v-slot:headerActions>
            <q-btn @click="router.visit($route('equipment.list'))"
                   color="deep-purple-3"
                   icon="navigate_before"
                   :label="$translate('к списку')"
                   dense/>
            <q-btn v-if="can.export"
                   @click="router.visit($route('equipment.export'))"
                   color="deep-purple-3"
                   icon="import_export"
                   :label="$translate('buttons.export')"
                   dense/>
        </template>

        <DepartmentSelector/>

        <ExportData :modelFields="modelFields"
                    :route="$route('equipment.export.execute')"/>
    </Card>
</template>

<script setup lang="ts">
import {getCurrentInstance} from "vue";
import {router} from "@inertiajs/vue3";
import {ExportData} from "@/Components/Exchange";
import {Card} from "@/Components/Block";
import DepartmentSelector from "@/Components/Navigation/DepartmentSelector.vue";

interface Permission {
    create: boolean,
    import: boolean,
    export: boolean
}

interface Props {
    modelFields: [],
    can: Permission
}

defineProps<Props>()

const app = getCurrentInstance();
const $route = app!.appContext.config.globalProperties.$route;
const $translate = app!.appContext.config.globalProperties.$translate

</script>