<template>
    <q-layout view="hHh lpR fFf" class="bg-grey-1">
        <q-header elevated class="bg-white text-grey-8">
            <q-toolbar class="q-pl-xs">
                <!-- SERVICE SELECTOR -->
                <ServiceSelector/>
                <!-- LOGO -->
                <q-avatar v-if="$q.screen.gt.xs"
                          class="q-ml-xs">
                    <img :src="appLogo" :alt="appName"/>
                    <q-tooltip class="text-body2">{{ serviceName }}</q-tooltip>
                </q-avatar>
                <q-separator vertical inset class="q-mx-sm"/>
                <!-- APP NAME  -->
                <div v-if="$q.screen.gt.sm">
                    <q-toolbar-title>{{ serviceName }}</q-toolbar-title>
                    <div>{{ appName }}</div>
                </div>
                <template v-if="_.size(INFOBANNER)>0 && $q.screen.gt.sm">
                    <q-space/>
                    <Alert variant="danger">
                        <span v-html="INFOBANNER" class="text-small"/>
                    </Alert>
                </template>
                <q-space/>
                <div class="flex self-stretch no-wrap q-ml-none" :class="$q.screen.lt.sm?'scroll-x':''">
                    <NavigationMenu icon="o_tune"
                                    :title="$translate('menu.manage-org')"
                                    :menu="director_menu"/>
                    <NavigationMenu icon="o_settings"
                                    :title="$translate('admin.admin')"
                                    :menu="admin_menu"/>
                    <LogonAsUser v-if="can_logon_as"/>
                    <HelpMenu/>
                    <HelpDesk/>
                    <NotifyCenter/>
                </div>
                <q-separator vertical/>
                <UserMenu/>
            </q-toolbar>
            <template v-if="_.size(INFOBANNER)>0 && $q.screen.lt.md">
                <q-space/>
                <Alert variant="danger">
                    <span v-html="INFOBANNER" class="text-small"/>
                </Alert>
            </template>
        </q-header>

        <q-drawer v-model="SIDEBAR"
                  :mini="SIDEBAR_MINI"
                  show-if-above
                  :width="SIDEBAR_MINI?52:250"
                  bordered>
            <q-scroll-area :horizontal-thumb-style="{ opacity: 0 }" :style="sidebarHeight">
                <component :is="currentNavigation" :sidebar="SIDEBAR_MINI"/>
            </q-scroll-area>
            <div class="absolute-bottom row items-center justify-center bg-white border-top q-mt-sm">
                <div class="text-center col-grow column" v-if="!SIDEBAR_MINI">
                    <a href="https://researchims.ru" target="_blank" class="text-dark">
                        ResearchIMS
                    </a>
                    <a @click="router.get($route('changelog'))" class="text-dark cursor-pointer text-small">
                        {{ appVersion }}
                    </a>
                </div>
                <q-btn v-if="$q.screen.gt.md"
                       :icon="SIDEBAR_MINI?'chevron_right':'chevron_left'"
                       size="lg" dense square flat
                       color="secondary"
                       :title="SIDEBAR_MINI?$translate('menu.show-menu'):$translate('menu.collapse-menu')"
                       @click="toggleSidebarMini"/>
            </div>
        </q-drawer>
        <q-page-container>
            <q-page class="q-pa-md scroll" :style-fn="pageHeight">
                <slot/>
            </q-page>
        </q-page-container>
        <q-page-sticky position="bottom-left" :offset="[8, 8]" v-if="$q.screen.lt.md">
            <q-btn icon="menu"
                   dense rounded
                   size="lg"
                   class="bg-white"
                   @click="SIDEBAR = !SIDEBAR"/>
        </q-page-sticky>
        <q-page-sticky position="bottom-right" class="bg-grey-1 text-xsmaller">
            <div class="q-pa-xs">{{ serverName }} | {{ executionTime }}</div>
        </q-page-sticky>
    </q-layout>
</template>

<script setup>
import {computed, defineAsyncComponent, onMounted, ref} from 'vue'
import {router, usePage} from '@inertiajs/vue3'
import {useQuasar} from "quasar";
import _ from "lodash";
import NotifyCenter from "@/Components/NotifyCenter.vue";
import UserMenu from "@/Components/Navigation/UserMenu.vue";
import ServiceSelector from "@/Components/Navigation/ServiceSelector.vue";
import HelpMenu from "@/Components/Navigation/HelpMenu.vue";
import NavigationMenu from "@/Components/Navigation/NavigationMenu.vue";
import HelpDesk from "@/Components/Navigation/HelpDesk.vue";
import LogonAsUser from "@/Components/Navigation/LogonAsUser.vue";
import * as ServiceNavigation from "@/Components/Navigation/Services"
import {Alert} from "@/Components/Block/index.js";
import helpers from "@/helpers.js";

const $q = useQuasar()
const page = usePage()
const appName = computed(() => page.props.appName)
const appLogo = computed(() => page.props.appLogo)
const appVersion = computed(() => page.props.appVersion)
const service = computed(() => page.props.service)
const serviceName = computed(() => page.props.serviceName)
const user = computed(() => page.props.auth.user)
const serverName = computed(() => page.props.serverName)
const executionTime = computed(() => page.props.executionTime)
const SIDEBAR = ref($q.screen.gt.md)
const SIDEBAR_MINI = ref(false)

const INFOBANNER = computed(() => helpers.nl2br(page.props.INFOBANNER))

const toggleSidebarMini = () => {
    SIDEBAR_MINI.value = !SIDEBAR_MINI.value;
    $q.localStorage.set('SIDEBAR_MINI', SIDEBAR_MINI.value)
}

const sidebarHeight = computed(() => {
    let minus = 40;
    if ($q.screen.gt.md || $q.platform.is.mobile) {
        minus = 100;
    }
    return {maxHeight: `calc(100vh - ${minus}px)`, height: '100%'}
})
const currentNavigation = computed(() => ServiceNavigation[_.upperFirst(service.value)])

const director_menu = computed(() => _.get(usePage().props.auth, 'director_menu'))
const admin_menu = computed(() => _.get(usePage().props.auth, 'admin_menu'))
const can_logon_as = computed(() => _.get(usePage().props.auth, 'can_logon_as'))

const pageHeight = (offset) => {
    return {maxHeight: offset ? `calc(100vh - ${offset}px)` : '100vh', height: '100%'}
}
onMounted(() => {
    SIDEBAR_MINI.value = $q.localStorage.getItem('SIDEBAR_MINI') || false
})
</script>