<template>
    <UserLayout :title="$translate('users.title-import')">
        <ImportData
                :model-fields='fields'
                :required-fields='requiredFields'
                :preview-route="$route('admin.users.import.preview')"
                :execute-route="$route('admin.users.import.execute')"/>
    </UserLayout>
    <pre>{{ modelFields }}</pre>
</template>

<script setup lang="ts">
import {getCurrentInstance, computed, ComputedRef} from "vue";
import {ImportData} from "@/Components/Exchange";
import UserLayout from "@/Pages/Admin/Users/UserLayout.vue";
import _ from 'lodash'

interface Field {
    value: any,
    label: string,
    searchable: boolean,
    required: boolean,
}

interface Props {
    modelFields: Field[],
    requiredFields: [],
}

const props = defineProps<Props>()

const app = getCurrentInstance();
const $route = app!.appContext.config.globalProperties.$route;
const $translate = app!.appContext.config.globalProperties.$translate

const fields: ComputedRef<any> = computed<any>(() => {
    return _.concat(_.get(props.modelFields, 'model'), _.get(props.modelFields, 'additional'))
})
</script>