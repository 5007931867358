<template>
    <q-item-section class="q-ml-xl q-pl-sm border-left">
        <q-item-label>{{ $translate('report-template.form.label.additional') }}</q-item-label>
        <q-list>
            <template v-for="(addon, index) in addons">
                <q-item>
                    <AddonItem v-model="addons[index]"/>
                    <q-item-section side>
                        <q-btn icon="o_delete"
                               color="negative"
                               @click="deleteItem(index)"
                               flat dense/>
                    </q-item-section>
                </q-item>

            </template>
        </q-list>
        <q-item-label class="q-pt-xs">
            <q-btn icon='o_add'
                   :label="$translate('buttons.add')"
                   size="sm"
                   @click="addItem"
                   dense/>
        </q-item-label>
    </q-item-section>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import AddonItem from "@/Components/ResearchReportTemplate/TemplateList/AddonItem.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const addons = defineModel({type: Array})

const addItem = () => {
    addons.value.push({
        field: null,
        value: null,
        time: null,
        depend: 0
    })
}

const deleteItem = (index) => {
    addons.value.splice(index, 1)
}
</script>