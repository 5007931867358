<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          actions-align="left"
          no-card-section>
        <q-list separator dense>
            <template v-for="(item, index) in series">
                <q-item>
                    <q-item-section>
                        <q-item-label :title="$translate('Издание')">
                            {{ _.get(item, 'edition.name') }}
                        </q-item-label>
                        <q-item-label :title="$translate('Издатель')" caption>
                            {{ _.get(item, 'publisher.name') }}
                        </q-item-label>
                        <div v-if="errors.hasOwnProperty('series.'+index+'.edition_id')" class="text-negative">
                            {{ _.get(errors, 'series.' + index + '.edition_id') }}
                        </div>
                        <div v-if="errors.hasOwnProperty('series.'+index+'.publisher_id')" class="text-negative">
                            {{ _.get(errors, 'series.' + index + '.publisher_id') }}
                        </div>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{ $translate('Том') }}: {{ _.get(item, 'number') }}</q-item-label>
                        <q-item-label caption>{{ $translate('Номер') }}: {{ _.get(item, 'book') }}</q-item-label>
                        <div v-if="errors.hasOwnProperty('series.'+index+'.number')" class="text-negative">
                            {{ _.get(errors, 'series.' + index + '.number') }}
                        </div>
                        <div v-if="errors.hasOwnProperty('series.'+index+'.book')" class="text-negative">
                            {{ _.get(errors, 'series.' + index + '.book') }}
                        </div>
                        <div v-if="errors.hasOwnProperty('series.'+index+'.isbn')" class="text-negative">
                            {{ _.get(errors, 'series.' + index + '.isbn') }}
                        </div>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn icon="o_delete"
                               color="negative"
                               size="sm"
                               @click="deleteItem(index)"
                               flat dense
                        />
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
        <div class="text-negative q-px-sm" v-if="errors.hasOwnProperty('series')">
            {{ _.get(errors, 'series') }}
        </div>
        <template v-slot:actions>
            <q-btn icon="o_add"
                   :label="$translate('buttons.add')"
                   @click="openDialog"
                   dense no-caps/>
            <q-dialog v-model="seriesDialog" no-backdrop-dismiss>
                <Card :title="$translate('Добавление серии')"
                      in-modal show-close-button>
                    <Form v-model="serie"/>
                    <template v-slot:actions>
                        <q-btn icon="o_save"
                               :label="$translate('buttons.save')"
                               color="positive"
                               @click="saveItem"
                        />
                    </template>
                </Card>
            </q-dialog>
        </template>
    </Card>
</template>

<script setup>
/**************************************************
 *
 *  Информация о серии
 *
 ***************************************************/
import {getCurrentInstance, ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {Card} from "@/Components/Block";
import Form from "@/Components/ReportMaterialForm/Dialog/Series/Form.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object},
    series: {type: Object},
    // errors: {type: Object}
})

const series = defineModel('series', {type: Array})
const errors = defineModel('errors', {type: Object})

const seriesDialog = ref(false)

const openDialog = () => {
    seriesDialog.value = true;
}

const serie = useForm({
    edition_id: null,
    edition: null,
    publisher_id: null,
    publisher: null,
    number: null,
    book: null,
    isbn: []
})

const saveItem = () => {
    series.value.push(_.cloneDeep(serie))
    serie.reset()
    seriesDialog.value = false;
}

const deleteItem = (index) => {
    series.value.splice(index, 1)
}
</script>