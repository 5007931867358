<template>
    <Card :title="$translate('equipment.item.tabs.finance')" hide-actions>
        <div class="q-gutter-y-md">
            <q-field :label="$translate('equipment.item.fields.document_name')" stack-label borderless>
                <template v-slot:control>
                    <div class="full-width row items-center">
                        <q-icon name="edit" color="grey-5" left v-if="equipment.permission.edit"
                                :title="$translate('buttons.edit')" class="cursor-pointer">
                            <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                          buttons anchor="top left"
                                          :label-set="$translate('buttons.save')"
                                          :label-cancel="$translate('buttons.cancel')"
                                          @save="patchEquipment"
                                          :title="$translate('equipment.item.fields.document_name')">
                                <q-input type="textarea" rows="2" v-model="scope.value.document_name"
                                         counter style="width: 600px;max-width: 70vw"/>
                            </q-popup-edit>
                        </q-icon>
                        <div>{{ equipment.document_name ? equipment.document_name : '-' }}</div>
                    </div>
                </template>
            </q-field>

            <div class="row q-gutter-x-lg">
                <q-field :label="$translate('equipment.item.fields.inventory_number')" stack-label
                         class="col" borderless>
                    <template v-slot:control>
                        <div class="full-width row items-center">
                            <q-icon name="edit" color="grey-5" left v-if="equipment.permission.edit"
                                    :title="$translate('buttons.edit')" class="cursor-pointer">
                                <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                              buttons anchor="top left"
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchEquipment"
                                              :title="$translate('equipment.item.fields.inventory_number-placeholder')">
                                    <q-input v-model="scope.value.inventory_number"
                                             counter style="width: 600px;max-width: 70vw"/>
                                </q-popup-edit>
                            </q-icon>
                            <div>{{ equipment.inventory_number ? equipment.inventory_number : '-' }}</div>
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.item.fields.serial_number')" stack-label
                         class="col" borderless>
                    <template v-slot:control>
                        <div class="full-width row items-center">
                            <q-icon name="edit" color="grey-5" left v-if="equipment.permission.edit"
                                    :title="$translate('buttons.edit')" class="cursor-pointer">
                                <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                              buttons anchor="top left"
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchEquipment"
                                              :title="$translate('equipment.item.fields.serial_number-placeholder')">
                                    <q-input v-model="scope.value.serial_number"
                                             counter style="width: 600px;max-width: 70vw"/>
                                </q-popup-edit>
                            </q-icon>
                            <div>{{ equipment.serial_number ? equipment.serial_number : '-' }}</div>
                        </div>
                    </template>
                </q-field>
            </div>

            <div class="row q-gutter-x-lg">
                <q-field :label="$translate('equipment.item.fields.year_created')" stack-label
                         class="col" borderless>
                    <template v-slot:control>
                        <div class="full-width row items-center">
                            <q-icon name="edit" color="grey-5" left v-if="equipment.permission.edit"
                                    :title="$translate('buttons.edit')" class="cursor-pointer">
                                <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                              buttons anchor="top left"
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchEquipment"
                                              :title="$translate('equipment.item.fields.year_created')">
                                    <q-input type='number' v-model="scope.value.year_created" min="1900"
                                             :max="new Date().getFullYear()"
                                             counter style="width: 600px;max-width: 70vw"/>
                                </q-popup-edit>
                            </q-icon>
                            <div>{{ equipment.year_created ? equipment.year_created : '-' }}</div>
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.item.fields.year_placement')" stack-label
                         class="col" borderless>
                    <template v-slot:control>
                        <div class="full-width row items-center">
                            <q-icon name="edit" color="grey-5" left v-if="equipment.permission.edit"
                                    :title="$translate('buttons.edit')" class="cursor-pointer">
                                <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                              buttons anchor="top left"
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchEquipment"
                                              :title="$translate('equipment.item.fields.year_placement')">
                                    <q-date v-model="scope.value.year_placement" minimal
                                            counter style="width: 300px;max-width: 70vw"/>
                                </q-popup-edit>
                            </q-icon>
                            <div>{{
                                    equipment.year_placement ? moment(equipment.year_placement).format($translate('common.date.js')) : '-'
                                }}
                            </div>
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.item.fields.lifetime')" stack-label
                         class="col" borderless>
                    <template v-slot:control>
                        <div class="full-width row items-center">
                            <q-icon name="edit" color="grey-5" left
                                    v-if="equipment.permission.edit && !equipment.lifetime"
                                    :title="$translate('buttons.edit')" class="cursor-pointer">
                                <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                              buttons anchor="top left"
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchEquipment"
                                              :title="$translate('equipment.item.fields.lifetime')">
                                    <q-input type="number" min="1" v-model="scope.value.lifetime"
                                             :suffix="$translate('units.years')"
                                             input-class="text-center"
                                             style="width: 300px;max-width: 70vw"/>
                                </q-popup-edit>
                            </q-icon>
                            <div>{{
                                    equipment.lifetime ? equipment.lifetime + ' ' + $translate('units.years') : '-'
                                }}
                            </div>
                        </div>
                    </template>
                </q-field>
            </div>

            <div class="row q-gutter-x-lg">
                <q-field :label="$translate('equipment.item.fields.initial_cost')" stack-label
                         class="col" borderless>
                    <template v-slot:control>
                        <div class="full-width row items-center">
                            <q-icon name="edit" color="grey-5" left v-if="equipment.permission.edit"
                                    :title="$translate('buttons.edit')" class="cursor-pointer">
                                <q-popup-edit v-model="equipmentEdit"
                                              v-slot="scope"
                                              buttons anchor="top left"
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchEquipment"
                                              :title="$translate('equipment.item.fields.initial_cost')">

                                    <q-field v-model="scope.value.initial_cost" bottom-slots>
                                        <template v-slot:control="{ id, floatingLabel, modelValue, emitValue }">
                                            <input :id="id" class="q-field__input text-center"
                                                   @change="e => emitValue(e.target.value)"
                                                   v-money="moneyFormatForDirective"
                                                   v-show="floatingLabel">
                                        </template>
                                        <template v-slot:hint>
                                            <div class="text-center">
                                                Текущее значение {{ helpers.formatMoney(equipment.initial_cost) }}
                                            </div>
                                        </template>
                                    </q-field>
                                </q-popup-edit>
                            </q-icon>
                            <div>{{ equipment.lifetime ? helpers.formatMoney(equipment.initial_cost) : '-' }}</div>
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.item.fields.depreciation_real')" stack-label
                         class="col" borderless>
                    <template v-slot:control>
                        <div class="self-center full-width" :title="equipment.depreciation_real_hour">
                            {{ equipment.depreciation_real_hour.toFixed(2) + ' ' + $translate('units.rub-hour') }}
                        </div>
                        <div class="self-center full-width" :title="equipment.depreciation_real_day">
                            {{ equipment.depreciation_real_day.toFixed(2) + ' ' + $translate('units.rub-day') }}
                        </div>
                        <div class="self-center full-width" :title="equipment.depreciation_real_month">
                            {{ equipment.depreciation_real_month.toFixed(2) + ' ' + $translate('units.rub-month') }}
                        </div>
                        <div class="self-center full-width" :title="equipment.depreciation_real_year">
                            {{ equipment.depreciation_real_year.toFixed(2) + ' ' + $translate('units.rub-year') }}
                        </div>
                    </template>
                </q-field>
                <q-field
                        :label="$translate('equipment.item.fields.left_cost',{date: moment().format($translate('common.date.js'))})"
                        stack-label
                        class="col" borderless>
                    <template v-slot:control>
                        <div class="self-center full-width" :title="equipment.left_cost">
                            {{ equipment.left_cost.toFixed(2) + ' ' + $translate('units.rub') }}
                        </div>
                    </template>
                </q-field>
            </div>
        </div>

    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, watch} from "vue";
import {Card} from "@/Components/Block";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment";
import {format, unformat} from 'v-money3';
import helpers from "@/helpers.js";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    equipment: {
        type: Object
    }
})

const can = computed(() => usePage().props.can)

const moneyFormatForDirective = {
    decimal: ',',
    thousands: ' ',
    prefix: '',
    suffix: ' ' + $translate('units.rub'),
    precision: 2,
    allowBlank: true,
    disableNegative: true,
    masked: false /* doesn't work with directive */
}

const form = useForm({})
const equipmentEdit = computed(() => props.equipment)
const patchEquipment = (value, oldValue) => {
    let newData = {}
    _.each(oldValue, (v, k) => {
        if (!_.isEqual(value[k], v)) {
            if (k === 'initial_cost') {
                _.set(newData, 'initial_cost', Number(unformat(value.initial_cost, moneyFormatForDirective)))
            } else {
                newData[k] = value[k];
            }
        }

    })
    form.transform((data) => ({
        ...data,
        ...newData
    }))
            .patch(route('equipment.patch', props.equipment), {
                onSuccess: () => {
                    router.reload()
                }
            })
}
</script>