<template>
    <q-card-section>
        <q-item-label overline>{{ $translate('equipment.catalogue.fields.category') }}</q-item-label>
        <q-select v-model="equipmentCatalogue.equipment_category_id"
                  :options="equipmentCategoryOptions"
                  :loading="equipmentCategoryLoading"
                  dense
                  options-dense
                  map-options
                  emit-value
                  use-input
                  input-debounce="1000"
                  :error-message="equipmentCatalogue.errors['equipment_category_id']"
                  :error="equipmentCatalogue.errors.hasOwnProperty('equipment_category_id')"/>
    </q-card-section>

    <q-card-section>
        <q-item-label overline>{{ $translate('equipment.catalogue.fields.name') }}</q-item-label>
        <template v-for="(lang_name,locale) in $translate('fields.locale')">
            <q-input v-model="equipmentCatalogue.name_i18n[locale]"
                     :label="lang_name"
                     dense counter stack-label
                     :error-message="equipmentCatalogue.errors['name_i18n.'+locale]"
                     :error="equipmentCatalogue.errors.hasOwnProperty('name_i18n.'+locale)"/>
        </template>
    </q-card-section>
    <q-card-section>
        <div class="row q-gutter-x-xl">
            <div class="col">
                <q-item-label overline>{{ $translate('equipment.catalogue.fields.vendor') }}</q-item-label>
                <q-select v-model="equipmentCatalogue.vendor"
                          :options="vendorList"
                          new-value-mode="add-unique"
                          dense
                          use-input
                          options-dense
                          map-options
                          emit-value
                          @filter="equipmentVendorLoader"
                          :error-message="equipmentCatalogue.errors['vendor']"
                          :error="equipmentCatalogue.errors.hasOwnProperty('vendor')"/>
            </div>
            <div class="col">
                <q-item-label overline>
                    {{ $translate('equipment.catalogue.fields.country_origin') }}
                </q-item-label>
                <q-select v-model="equipmentCatalogue.country_origin_id"
                          :options="equipmentCountryOptions"
                          :loading="equipmentCountryLoading"
                          @filter="equipmentCountryLoader"
                          option-value="value"
                          input-debounce="1000"
                          use-input
                          options-dense
                          dense
                          map-options
                          emit-value
                          :error-message="equipmentCatalogue.errors['country_origin_id']"
                          :error="equipmentCatalogue.errors.hasOwnProperty('country_origin_id')"/>
            </div>
        </div>
        <div class="row q-gutter-x-xl">
            <div class="col">
                <q-item-label overline>{{ $translate('equipment.catalogue.fields.brand') }}</q-item-label>
                <q-input v-model="equipmentCatalogue.brand"
                         dense
                         :error-message="equipmentCatalogue.errors['brand']"
                         :error="equipmentCatalogue.errors.hasOwnProperty('brand')"/>
            </div>
            <div class="col">
                <q-item-label overline>{{ $translate('equipment.catalogue.fields.model') }}</q-item-label>
                <q-input v-model="equipmentCatalogue.model"
                         dense
                         :error-message="equipmentCatalogue.errors['model']"
                         :error="equipmentCatalogue.errors.hasOwnProperty('model')"/>
            </div>
        </div>
    </q-card-section>
    <q-card-section>
        <q-item-label overline>{{ $translate('equipment.catalogue.fields.description') }}</q-item-label>
        <template v-for="(lang_name,locale) in $translate('fields.locale')">
            <q-input v-model="equipmentCatalogue.description_i18n[locale]"
                     :label="lang_name"
                     type="textarea" rows="3"
                     dense counter stack-label
                     :error-message="equipmentCatalogue.errors['description_i18n.'+locale]"
                     :error="equipmentCatalogue.errors.hasOwnProperty('description_i18n.'+locale)"/>
        </template>
    </q-card-section>
</template>

<script setup>
import {getCurrentInstance, onBeforeMount, onMounted, ref} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const equipmentCatalogue = defineModel({type: Object})


const equipmentCategoryOptions = ref([])
const equipmentCategoryLoading = ref(false)
const equipmentCategoryLoader = (val, update) => {
    equipmentCategoryLoading.value = true;
    axios.get(route('ajax.equipment.category'), {
        params: {
            m: 'options',
        }
    })
            .then((response) => {
                equipmentCategoryOptions.value = response.data.results
            })
            .finally(() => {
                equipmentCategoryLoading.value = false;
            })
}
const equipmentCountryOptions = ref([])
const equipmentCountryLoading = ref(false)
const equipmentCountryLoader = (val, update) => {
    equipmentCountryLoading.value = true;
    axios.get(route('ajax.classificator'), {
        params: {
            class: 'country',
            m: 'loadOptions',
            search: val
        }
    })
            .then((response) => {
                if(update) {
                    update(() => {
                        equipmentCountryOptions.value = response.data.results
                    })
                }else{
                    equipmentCountryOptions.value = response.data.results
                }
            })
            .finally(() => {
                equipmentCountryLoading.value = false;
            })
}

const vendorList = ref([]);
const equipmentVendorLoader = (val, update) => {
    if (val === '') {
        update(() => {
            vendorList.value = usePage().props.vendors;
        })
    } else {
        vendorList.value = usePage().props.vendors;
        update(() => {
            const needle = val.toLowerCase()
            vendorList.value = usePage().props.vendors.filter(v => v.toLowerCase().indexOf(needle) > -1)
        })
    }
}
onBeforeMount(() => {
    equipmentCategoryLoader()
    equipmentCountryLoader()
})
</script>