<template>
    <q-select v-model="selected"
              :loading="loading"
              clearable
              clear-icon="clear"
              input-debounce="1000"
              :options="fetchedData"
              @filter="searchItems"
              :label="label?label:$translate('search.department.label')"
              :stack-label="stackLabel"
              :hint="$translate('search.department.hint')"
              :hide-hint="hideHint"
              @update:model-value="(value) => emit('onSelect', value)"
              use-input
              :error-message="error"
              :error="_.size(error)>0"
    >
        <template v-slot:option="option">
            <q-item v-bind="option.itemProps">
                <q-item-section>
                    <q-item-label v-html="option.opt.html"></q-item-label>
                </q-item-section>
            </q-item>
        </template>
    </q-select>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import axios from "axios";
import _ from "lodash";

const props = defineProps({
    /**
     * Подпись к полю
     */
    label: {
        type: String,
        default: null
    },
    stackLabel: {
        type: Boolean,
        default: false
    },
    hideHint: {
        type: Boolean,
        default: false
    },
    filters: {
        type: Array,
        required: false,
        default: []
    },
    method: {
        type: String,
        default: 'search'
    },
    error: {
        type: String,
        default: null
    }
});

const emit = defineEmits('onSelect');

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const selected = ref(null);
const fetchedData = ref([]);
const loading = ref(false);

const searchItems = (search, update) => {
    if (search === '') {
        if (_.size(fetchedData.value) > 0) {
            // already loaded
            update()
            return
        }
        update(() => {
            fetchedData.value = [];
        });
        return
    }
    update(() => {
        let params = {
            m: props.method,
            q: search,
        };
        loading.value = true;
        axios.get(route('ajax.cadre.department', params))
                .then((response) => {
                    fetchedData.value = response.data.results;
                })
                .finally(() => {
                    loading.value = false;
                });
    });
};
</script>
