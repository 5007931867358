<template>
    <q-toolbar v-if="!_.isEmpty(department)" class="bg-grey-4 cursor-text">
        <q-toolbar-title>
            <div class="items-center">
                <div class="ellipsis">{{ department.name }}</div>
            </div>
            <q-tooltip class="text-body2">
                {{ department.name }}
            </q-tooltip>
        </q-toolbar-title>
        <q-btn :icon="department.bookmarked?'o_bookmark_remove':'o_bookmark_add'"
               :title="$translate('cadre-department.actions.bookmark.'+(!department.bookmarked?'add':'remove'))"
               color="grey-6" dense
               class="q-mr-sm"
               @click="toggleBookmark"/>
        <q-btn :icon="showDepartmentDetail?'expand_less':'expand_more'"
               color="grey-6" dense
               @click="showDepartmentDetail = !showDepartmentDetail"/>
    </q-toolbar>
    <q-scroll-area style="height: 78vh">
        <q-card-section v-if="showDepartmentDetail">
            <q-field :label="$translate('cadre-department.fields.name')"
                     tag="div"
                     stack-label>
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ _.get(department, 'name') }}
                    </div>
                </template>
                <template v-slot:append v-if="department.permission.edit">
                    <EditDepartmentName v-model="department" @onSuccess="()=>emit('onSuccess')"/>
                </template>
            </q-field>

            <q-field :label="$translate('cadre-department.fields.ssc')"
                     tag="div"
                     stack-label>
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ _.get(department, 'ssc.name') }}
                    </div>
                </template>
                <template v-slot:append>
                    <AssignRelatedDepartment v-model="department" @onSuccess="()=>emit('onSuccess')"/>
                </template>
            </q-field>

            <q-field :label="$translate('cadre-department.fields.source_id')"
                     tag="div"
                     stack-label>
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ _.get(department, 'source_id') }}
                    </div>
                </template>
            </q-field>

        </q-card-section>
        <template v-if="!_.isEmpty(department)">
            <!-- сотрудники -->
            <q-bar class="bg-grey-2 q-py-sm q-mb-sm">
                <div class="text-caption">
                    {{ $translate('cadre-department.title.staff-list') }}
                    ({{ _.size(department.staff) }})
                </div>
                <q-space/>
                <DataTableActions>
                    <WorktimeDepartmentChart :department-id="department.id"/>
                    <VacationDepartmentChart :department-id="department.id"/>
                </DataTableActions>
            </q-bar>
            <q-list separator dense>
                <template v-for="(staff) in _.sortBy(department.staff,'name')">
                    <StaffItem :staff="staff"/>
                </template>
            </q-list>
        </template>
    </q-scroll-area>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import DataTableActions from "@/Components/DataTableActions.vue";
import StaffItem from "@/Components/Cadre/Staff/StaffItem.vue";
import AssignRelatedDepartment from "@/Components/Cadre/Department/AssignRelatedDepartment.vue";
import EditDepartmentName from "@/Components/Cadre/Department/EditDepartmentName.vue";
import WorktimeDepartmentChart from "@/Components/Cadre/Worktime/WorktimeDepartmentChart.vue";
import VacationDepartmentChart from "@/Components/Cadre/Vacation/VacationDepartmentChart.vue";


const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const department = defineModel({
    type: Object
})
const emit = defineEmits(['onSuccess'])
const showDepartmentDetail = ref(false)

const toggleBookmark = () => {
    router.patch($route('cadre.department.bookmark-department', {cadreDepartment: department.value}), {},
            {
                onSuccess: () => emit('onSuccess')
            })
}

</script>