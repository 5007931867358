<template>
    <ConsumableSscForm :title="$translate('consumables.ssc.tab.create')" :units="units" :can="can"/>
</template>

<script setup>
import ConsumableSscForm from "@/Components/Consumables/ConsumableSscForm.vue";
import {getCurrentInstance} from "vue";

const props = defineProps({
    units: {
        type: Array
    },
    can: {
        type: Object
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
