<template>
    <Card :title="issue.number"
          header-toolbar title-bg="deep-orange-4" title-text="white"
          hide-actions no-card-section>
        <template v-slot:headerActions>
            <q-chip outline size="md" color="white" class="no-pointer-events">{{ issue.stateName }}</q-chip>
        </template>

        <q-toolbar dense class="bg-deep-orange-4 text-white">
            {{ issue.title }}
            <q-space/>
            <div class="q-gutter-xs q-py-sm">
                <q-btn :label="$translate('buttons.back')"
                       @click="router.visit($route('helpdesk.issues.list'))"
                       color="blue-grey-1"
                       text-color="dark"
                       icon="o_chevron_left"
                       size="sm"
                />
                <q-btn v-if="issue.can.selfClose"
                       :label="$translate('buttons.close-issue')"
                       @click="router.post($route('helpdesk.issues.close', {issue: issue.id}))"
                       color="blue-grey-1"
                       text-color="dark"
                       icon="close"
                       size="sm"
                />
            </div>
        </q-toolbar>
        <q-card-section class="text-right text-italic">{{ issue.created }}</q-card-section>
        <q-card-section class="q-py-md" v-html="issue.description"/>
        <q-card-section class="text-right text-italic">
            {{ $translate('users.issues.discussion-participants') }}: {{ issue.authorEmail }}
        </q-card-section>
        <q-card-section v-if="issue.attachments.length > 0">
            <q-btn label="вложения"
                   :icon="issue.attachmentsExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                   @click="issue.attachmentsExpanded = !issue.attachmentsExpanded"
                   color="blue-grey-1"
                   text-color="dark"
                   size="sm"
            />
            <q-slide-transition>
                <div v-show="issue.attachmentsExpanded" class="row">
                    <div class="col-8 q-my-sm" v-for="(attachment) in issue.attachments" :key="attachment.id">
                        <a :href="attachment.href" target="_blank" rel="noreferrer nofollow">
                            <q-icon :name="'far fa-' + attachment.icon" size="sm"/>
                            {{ attachment.name }}
                        </a>
                    </div>
                </div>
            </q-slide-transition>
        </q-card-section>
        <q-card-section>
            <q-list separator>
                <template v-for="(comment) in issue.comments" :key="comment.id">
                    <q-item>
                        <q-item-section>
                            <q-item-label v-html="comment.text"/>
                            <div>
                                <a v-for="(attachment) in comment.attachments"
                                   :key="attachment.id"
                                   :href="attachment.href"
                                   target="_blank"
                                   rel="noreferrer nofollow"
                                >
                                    <q-img v-if="attachment.isImage"
                                           :src="attachment.href"
                                           :alt="attachment.name"
                                           height="150px"
                                           width="150px"
                                    />
                                    <div v-else class="q-my-sm">
                                        <q-icon :name="'far fa-' + attachment.icon" size="sm"/>
                                        {{ attachment.name }}
                                    </div>
                                </a>
                            </div>
                        </q-item-section>
                        <q-item-section side top>
                            <q-item-label>
                                <span>{{ comment.author }}, </span>
                                <span>{{ comment.createdAt }}</span>
                            </q-item-label>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </q-card-section>
        <q-separator/>
        <q-card-section v-if="issue.can.addComment">
            <q-input v-model="form.comment" type="textarea" label="Введите текст комментария" outlined/>
            <q-file v-model="form.files"
                    accept="image/*"
                    class="q-mt-sm"
                    label="Приложите файл(-ы)"
                    multiple
                    outlined
            >
                <template v-slot:prepend>
                    <q-icon name="attach_file"/>
                </template>
            </q-file>
            <q-btn :label="$translate('buttons.send')"
                   @click="submitForm"
                   :loading="form.processing"
                   color="positive"
                   class="q-mt-sm"
            >
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
        </q-card-section>

    </Card>
</template>

<script setup>
import {router, useForm} from "@inertiajs/vue3";
import {getCurrentInstance} from "vue";
import Card from "@/Components/Block/Card.vue";

const props = defineProps({
    issue: {
        type: Object,
        default: null
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    comment: null,
    files: []
});

const submitForm = () => {
    form.post(
        route('helpdesk.issues.comment.store', {issue: props.issue.id}),
        {
            preserveScroll: true,
            onSuccess: () => {
                form.reset()
            }
        }
    );
};
</script>
