<template>
    <div>
        <q-checkbox v-model="hideDisabled"
                    @update:modelValue="loadItems"
                    size="xs"
                    :label="$translate('Скрыть недоступные для создания проекта основания')"/>
    </div>
    <q-separator/>
    <Alert variant="info" v-if="!loading && _.size(items)===0"
           :message="$translate('Данные в системе Pure отсутствуют')"/>
    <q-list separator v-if="_.size(items)>0">
        <template v-for="(item) in items">
            <q-item>
                <q-item-section>
                    <div v-html="item.html"></div>
                    <div class="flex q-gutter-x-sm q-mb-xs items-center">
                        <q-btn icon="link" :label="$translate('Перейти в источник')"
                               :href="item.link" target="_blank"
                               dense size="sm" color="teal-4"/>
                        <q-btn icon="sync" :label="$translate('Обновить данные из источника')"
                               dense size="sm" color="teal-4"
                               @click="updateProject(item.id)"/>
                        <div class="text-small">
                            {{ $translate('Обновлено:') }}
                            {{ moment(item.updatedAt).format($translate('common.datetime.js')) }}
                        </div>
                    </div>
                </q-item-section>
            </q-item>
        </template>
    </q-list>
    <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary"/>
    </q-inner-loading>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Alert} from "@/Components/Block";
import moment from "moment";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    url: {type: String}
})

const items = ref(null)

const loading = ref(false)

const hideDisabled = ref(true)
const loadItems = () => {
    loading.value = true
    axios.get(route('ajax.pure', {
                m: "getAwardsFromCacheForProfile",
                hideDisabled: hideDisabled.value
            })
    )
            .catch((e) => console.error(e))
            .then((response) => {
                items.value = response.data.results;
            })
            .finally(() => {
                loading.value = false
            })
}
const updateProject = (uuid) => {
    router.get(route('profile.sync-source', {source: 'pure', id: uuid}), {}, {
        onSuccess: () => {
            loadItems()
        }
    })
}
onMounted(() => {
    loadItems()
})
</script>