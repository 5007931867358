<template>
    <q-card :style="styleValue">
        <component :is="headerComponent" :class="titleClass" v-if="!hideTitle">
            <component :is="headerComponentTitle" :shrink="!noShrink">
                <slot name="title">{{ title }}</slot>
            </component>
            <q-space v-if="!noShrink"/>
            <div v-if="$slots.headerActions" class="q-gutter-x-sm text-no-wrap">
                <slot name="headerActions"/>
            </div>
            <q-btn icon="close" flat dense v-close-popup v-if="showCloseButton"/>
        </component>
        <div :class="{'scroll':inModal}" :style="contentStyle">
            <q-card-section v-if="!noCardSection">
                <slot name="default"/>
            </q-card-section>
            <slot name="default" v-else/>
        </div>
        <q-separator v-if="!hideActions"/>
        <q-card-actions v-if="!hideActions" :align="actionsAlign">
            <slot name="actions"/>
        </q-card-actions>
    </q-card>
</template>

<script setup lang="ts">
import {computed} from "vue";
import {QBar, QToolbar, QToolbarTitle} from "quasar";

interface Props {
    title?: string,
    titleBg?: string,
    titleText?: string,
    titleClasses?: string,

    inModal?: boolean,
    hideTitle?: boolean,
    hideActions?: boolean,
    noCardSection?: boolean,
    showCloseButton?: boolean,
    headerToolbar?: boolean,
    headerToolbarDiv?: boolean,
    noShrink?: boolean,

    actionsAlign?: 'left' | 'center' | 'right' | 'stretch' | 'between' | 'around' | 'evenly'
}

const props = withDefaults(defineProps<Props>(), {
    titleBg: 'grey-3',
    actionsAlign: 'center',
    hideTitle: false,
    hideActions: false,
    noCardSection: false,
    showCloseButton: false,
    inModal: false,
    headerToolbar: false,
    headerToolbarDiv: false,
    noShrink: false,

})
const titleClass = computed(() => {
    let classes = [];

    classes.push('bg-' + props.titleBg)
    if (props.titleText) {
        classes.push('text-' + props.titleText)
    }
    if (props.titleClasses) {
        classes.push(props.titleClasses)
    }
    return classes.join(" ");
})

const styleValue = computed(() => {
    if (props.inModal) {
        return "width:800px; max-width: 90vw";
    }
    return null
})
const contentStyle = computed(() => {
    if (!props.inModal) {
        return null
    }
    let _header = props.headerToolbar ? '50px' : '32px';
    let _footer = props.hideActions ? '0px' : '52px';
    return 'height: calc(100% - ' + _header + ' - ' + _footer + ' - 4px)';
})

const headerComponent = computed(() => {
    if (props.headerToolbar) {
        return QToolbar
    }
    return QBar
})

const headerComponentTitle = computed(() => {
    if (props.headerToolbar && !props.headerToolbarDiv) {
        return QToolbarTitle
    }
    return "div"
})
</script>