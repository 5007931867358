<template>
    <q-table ref="tableRef"
             row-key="id"
             :rows="items"
             :columns="columns"
             :loading="loading"
             @request="onRequest"
             v-model:pagination="pagination"
             :rows-per-page-options="[10,50,100]"
             flat
             wrap-cells
    >
        <template v-slot:body-cell-detail="props">
            <q-td :props="props">
                <span v-html="props.value"></span>
            </q-td>
        </template>
    </q-table>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import axios from "axios";

const props = defineProps({
    consumable: {
        type: Object
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const tableRef = ref();
const loading = ref(false);
const items = ref([]);
const columns = [
    {name: 'date', align: 'left', label: $translate('consumables.ssc.label.data'), field: 'date'},
    {name: 'category', align: 'left', label: $translate('consumables.ssc.label.category'), field: 'category'},
    {name: 'count', align: 'left', label: $translate('consumables.ssc.label.count'), field: 'count'},
    {name: 'detail', align: 'left', label: $translate('consumables.ssc.label.detail'), field: 'detail'},
];
const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});

const onRequest = (request) => {
    const {page, rowsPerPage, sortBy, descending} = request.pagination;

    loading.value = true;

    let params = {
        m: 'movement',
        id: props.consumable.id,
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {}
        }
    };
    axios.get(route('ajax.consumables.ssc'), {
            params: params,
        })
        .then((response) => {
            items.value = response.data.results.data;
            pagination.value.page = response.data.results.current_page;
            pagination.value.rowsPerPage = response.data.results.per_page;
            pagination.value.sortBy = sortBy;
            pagination.value.descending = descending;
            pagination.value.rowsNumber = response.data.results.total;
        })
        .finally(() => {
            loading.value = false;
        });
};

onMounted(() => {
    tableRef.value.requestServerInteraction();
});
</script>
