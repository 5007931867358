<template>
    <Card :title="$translate('research-template.title.edit')"
          title-bg="blue-grey-3" header-toolbar>

        <ResearchTemplateForm :template="form"/>

        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   color="positive"
                   @click="submitForm"/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import ElementTree from "@/Components/ElementTree.vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from 'lodash'
import {useQuasar} from "quasar";
import axios from "axios";
import FieldsConstructor from "@/Components/Fields/FieldsConstructor.vue";
import ResearchTemplateForm from "@/Components/ResearchTemplate/ResearchTemplateForm.vue";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const can = computed(() => usePage().props.can)

const props = defineProps({
    template: {type: Object, required: true}
})
const form = useForm(props.template)

const submitForm = () => {
    form.post(route('research.template.update', props.template), {
        onSuccess: () => {
            // router.visit(route('research.template'))
        }
    })
}
</script>