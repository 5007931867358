<template>
    <div v-if="data.module.finance.enabled">
        <Alert v-if="$translate('module-finance.description')"
               variant="info"
               :message="$translate('module-finance.description')"
        />
        <SettingsTable :data="config"/>
    </div>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const config = [
    {
        name: 'FINANCE_ENABLED',
        label: $translate('module-finance.settings.FINANCE_ENABLED'),
        value: props.settings.module.FINANCE_ENABLED,
        description: $translate('module-finance.settings.FINANCE_ENABLED_description'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'FINANCE_NAKLAD',
        label: $translate('module-finance.settings.FINANCE_NAKLAD'),
        value: props.settings.module.FINANCE_NAKLAD,
        description: $translate('module-finance.settings.FINANCE_NAKLAD_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'FINANCE_NDS',
        label: $translate('module-finance.settings.FINANCE_NDS'),
        value: props.settings.module.FINANCE_NDS,
        description: $translate('module-finance.settings.FINANCE_NDS_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'ROUND_FLOAT',
        label: $translate('module-finance.settings.ROUND_FLOAT'),
        value: props.settings.module.ROUND_FLOAT ? props.settings.module.ROUND_FLOAT : 'HALF_UP',
        description: $translate('module-finance.settings.ROUND_FLOAT_description'),
        block: 'module',
        type: 'radio',
        options: _.map(['HALF_UP', 'HALF_DOWN', 'HALF_FULL'], value => ({
            label: $translate('module-finance.settings.ROUND_FLOAT_' + value),
            value: value
        }))
    },
];
</script>
