<template>
    <Card hide-actions :title="$translate('equipment.item.tabs.public')">
        <q-field :label="$translate('equipment.item.fields.public-description')" stack-label borderless class="q-mb-md">
            <template v-slot:control>
                <div class="full-width row items-start no-wrap">
                    <q-icon name="edit" color="grey-5 q-mt-xs" left v-if="can.edit"
                            :title="$translate('buttons.edit')" class="cursor-pointer">
                        <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                      buttons auto-save anchor="top left"
                                      :label-set="$translate('buttons.save')"
                                      :label-cancel="$translate('buttons.cancel')"
                                      @save="patchEquipment"
                                      :title="$translate('equipment.item.fields.public-description')">
                            <q-editor ref="publicDescriptionRef"
                                      @paste="onPasteDescription"
                                      v-model="scope.value.public.description"
                                      counter style="width: 600px;max-width: 70vw"/>
                        </q-popup-edit>
                    </q-icon>
                    <div class="self-stretch" v-html="_.get(equipment,'public.description')"></div>
                </div>
            </template>
        </q-field>

        <q-field :label="$translate('equipment.item.fields.public-technical')" stack-label borderless class="q-mb-md">
            <template v-slot:control>
                <div class="full-width row items-start no-wrap">
                    <q-icon name="edit" color="grey-5 q-mt-xs" left v-if="can.edit"
                            :title="$translate('buttons.edit')" class="cursor-pointer">
                        <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                      buttons auto-save anchor="top left"
                                      :label-set="$translate('buttons.save')"
                                      :label-cancel="$translate('buttons.cancel')"
                                      @save="patchEquipment"
                                      :title="$translate('equipment.item.fields.public-technical')">
                            <q-editor ref="publicTechnicalRef"
                                      @paste="onPasteTechnical"
                                      v-model="scope.value.public.technical"
                                      counter style="width: 600px;max-width: 70vw"/>
                        </q-popup-edit>
                    </q-icon>
                    <div class="self-stretch" v-html="_.get(equipment,'public.technical')"></div>
                </div>
            </template>
        </q-field>

        <q-field :label="$translate('equipment.item.fields.public-images')" stack-label borderless/>
        <div class="row q-gutter-sm items-center q-mb-md">
            <template v-for="(image) in _.get(equipment,'public.images',[])">
                <div class="col-auto bg-grey-2 q-pa-sm relative-position">
                    <q-btn flat dense icon="o_delete" size="sm" :title="$translate('buttons.delete')"
                           color="negative" class="absolute-top-right" style="z-index: 999"
                           @click="deletePhoto(image)"/>
                    <q-img :src="image.fullpath" loading="lazy" fit="scale-down" width="150px" height="150px"
                           :title="image.name"/>
                </div>
            </template>
        </div>
        <q-uploader ref="uploader" auto-upload v-if="can.edit"
                    :url="$route('equipment.public-images.upload',props.equipment)"
                    style="width: 700px;max-width: 90vw"
                    label="выберите фото для загрузки"
                    accept=".jpg, image/*"
                    color="grey-5"
                    :headers="()=> [{name: 'X-XSRF-TOKEN', value: Cookies.get('XSRF-TOKEN')}]"
                    @uploaded="()=>{ router.reload(); uploader.reset()}"
        />
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash"
import {Card} from "@/Components/Block";
import {Cookies} from 'quasar'
import helpers from "@/helpers";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    equipment: {
        type: Object
    }
})

const can = computed(() => props.equipment.permission)

const uploader = ref('uploader');

const publicDescriptionRef = ref()
const publicTechnicalRef = ref()

const onPasteDescription = (evt) => {
    helpers.sanitizeOnPaste(evt, publicDescriptionRef)
}
const onPasteTechnical = (evt) => {
    helpers.sanitizeOnPaste(evt, publicTechnicalRef)
}

const form = useForm({})
const equipmentEdit = computed(() => props.equipment)
const patchEquipment = (value, oldValue) => {
    let newData = {}
    _.each(oldValue.public, (v, k) => {
        if (!_.isEqual(value.public[k], v)) {
            newData[k] = value.public[k];
        }
    })
    form.transform((data) => ({
        ...data,
        ...newData
    }))
            .patch(route('equipment.patch.public', props.equipment), {
                onSuccess: () => {
                    router.reload()
                }
            })
}
const deletePhoto = (photo) => {
    let newData = {
        images: []
    }
    newData.images.push(photo.path)

    form.transform((data) => ({
        ...data,
        ...newData
    }))
            .delete(route('equipment.public-images.delete', props.equipment), {
                onSuccess: () => {
                    router.reload()
                }
            })
}
</script>