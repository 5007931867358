<template>
    <q-item clickable
            @click="barcodeDialog = true">
        <q-item-section side>
            <q-icon name="o_qr_code" size="xs"/>
        </q-item-section>
        <q-item-section>
            <q-item-label>{{ $translate('buttons.print-barcode-project') }}</q-item-label>
        </q-item-section>
    </q-item>
    <q-dialog v-model="barcodeDialog" no-backdrop-dismiss>
        <Card :title="$translate('barcode.select-type')"
              in-modal show-close-button>
            <form id="barcodeForm" target="_blank" :action="$route('project.print-code', project)"
                  method="post">
                <input type="hidden" name="_token" :value="token">
                <div class="full-width ">
                    <div class="row q-gutter-x-xl q-gutter-y-md">

                        <div class="col column items-center justify-end">
                            <q-img class="q-my-auto"
                                   :src="$route('barcode',{class:'2D',format:'QRCODE',text:project.number,w:2,h:2})"/>
                            <q-radio v-model="form.type" val="QRCODE" name="type"
                                     :label="$translate('barcode.type.QRCODE')"/>
                        </div>

                        <div class="col column items-center justify-end">
                            <q-img class="q-my-auto"
                                   :src="$route('barcode',{class:'2D',format:'DATAMATRIX',text:project.number,w:3,h:3})"/>
                            <q-radio v-model="form.type" val="DATAMATRIX" name="type"
                                     :label="$translate('barcode.type.DATAMATRIX')"/>
                        </div>

                        <div class="col column items-center justify-end">
                            <q-img class="q-my-auto"
                                   :src="$route('barcode',{class:'1D',format:'C128',text:project.number,w:1})"/>
                            <q-radio v-model="form.type" val="C128" name="type"
                                     :label="$translate('barcode.type.C128')"/>
                        </div>
                    </div>
                </div>
            </form>
            <template v-slot:actions>
                <q-btn icon="o_print"
                       :label="$translate('buttons.print-barcode-project')"
                       color="positive"
                       type="submit"
                       form="barcodeForm"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>

import {computed, getCurrentInstance, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import {useForm, usePage} from "@inertiajs/vue3";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    project: {type: Object, required: true},
})

const barcodeDialog = ref(false)

const form = useForm({
    type: null
})

const token = computed(() => usePage().props.csrf_token)

</script>