<template>
    <Card :title="$translate('module-delivery.title-goods')"
          header-toolbar no-card-section hide-actions>

        <template v-slot:headerActions>
            <q-btn icon="o_add"
                   :label="$translate('module-delivery.actions.add-consumable')"
                   dense no-caps
                   color="indigo-3"
                   @click="addConsumableDialogOpen"
            />
        </template>
        <Alert variant="info">
            Выберите товары которые планируете доставлять.<br/>
            Если ничего не выбрано то доставка осуществляться не будет
        </Alert>

        <q-table ref="tableRef"
                 row-key="id"
                 :rows="items"
                 :columns="columns"
                 :filter="filter"
                 :loading="loading"
                 @request="onRequest"
                 v-model:pagination="pagination"
                 :rows-per-page-options="[10,50,100]"
                 wrap-cells
                 flat
        >
            <template v-slot:top-row>
                <q-tr>
                    <q-td/>
                    <q-td colspan="100%">
                        <q-input v-model="filter" debounce="1000" :placeholder="$translate('search.element')"
                                 borderless dense
                                 clearable clear-icon="clear">
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:body-cell-name="props">
                <q-td :props="props">
                    <div v-html="props.value"/>
                    <div class="text-small">{{ _.get(props.row, 'type') }}</div>
                </q-td>
            </template>
            <template v-slot:body-cell="props">
                <q-td :props="props">
                    <div v-html="props.value"/>
                </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @on-success="onSuccess"
                    />
                </q-td>
            </template>
        </q-table>
    </Card>
    <q-dialog v-model="addConsumableDialog" no-backdrop-dismiss>
        <Card :title="$translate('module-delivery.title-goods-consumable-add')"
              in-modal show-close-button>
            <SearchConsumables @onSelect="(v)=>formConsumable.consumable = v.value"/>
            <q-select v-model="formConsumable.default_address"
                      :label="$translate('module-delivery.goods.fields.address')"
                      :options="ssc_address"
                      emit-value map-options
            />
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="formConsumableSubmit"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import Card from "@/Components/Block/Card.vue";
import Alert from "@/Components/Block/Alert.vue";
import DataTableActions from "@/Components/DataTableActions.vue";
import SearchConsumables from "@/Components/Search/SearchConsumables.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    ssc_address: {type: Array}
})

const tableRef = ref();
const filter = ref('');
const loading = ref(false);
const items = ref([]);
const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'name', align: 'left', label: $translate('module-delivery.goods.fields.name'), field: 'name'},
    {name: 'balance', align: 'left', label: $translate('module-delivery.goods.fields.balance'), field: 'balance'},
    {name: 'address', align: 'left', label: $translate('module-delivery.goods.fields.address'), field: 'address'},
];

const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});

const onRequest = (request) => {
    const {page, rowsPerPage, sortBy, descending} = request.pagination;
    const filter = request.filter;

    loading.value = true;

    let params = {
        m: 'list',
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {}
        }
    };
    if (_.size(filter) > 0) {
        params.datatable['search'] = filter;
    }
    axios.get(route('services.delivery.goods.json'), {
        params: params,
    })
        .then((response) => {
            items.value = response.data.results.data;
            pagination.value.page = response.data.results.current_page;
            pagination.value.rowsPerPage = response.data.results.per_page;
            pagination.value.sortBy = sortBy;
            pagination.value.descending = descending;
            pagination.value.rowsNumber = response.data.results.total;
        })
        .finally(() => {
            loading.value = false;
        });
};

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};

const addConsumableDialog = ref(false)
const addConsumableDialogOpen = () => {
    formConsumable.reset()
    addConsumableDialog.value = true
}

const formConsumable = useForm({
    consumable: null,
    default_address: null
})
const formConsumableSubmit = () => {
    formConsumable.post(route('services.delivery.goods.store'), {
        onSuccess: () => {
            formConsumable.reset()
            addConsumableDialog.value = false;
            onSuccess()
        }
    })
}
onMounted(() => {
    onSuccess()
});
</script>