<template>
    <q-btn icon="fas fa-user-secret"
           @click="dialog = !dialog"
           flat
           color="grey-8"
           class="self-stretch" :class="{'q-px-sm':$q.screen.lt.sm}">
        <q-tooltip class="text-body2">{{ $translate('auth.logonas') }}</q-tooltip>
    </q-btn>
    <q-dialog position="top" v-model="dialog" no-backdrop-dismiss>
        <q-card style="width: 900px; max-width: 90vw">
            <q-bar class="bg-cyan-14 text-white">
                <div>{{ $translate('auth.logonas') }}</div>
                <q-space/>
                <q-btn flat icon="close" v-close-popup/>
            </q-bar>
            <q-input v-model="search"
                     debounce="1000"
                     aria-autocomplete="none"
                     autocomplete="none"
                     :loading="searching"
                     :label="$translate('auth.search-as-label')"
                     autofocus
                     filled
                     square
                     @update:model-value="searchUsers">
            </q-input>
            <q-list separator dense v-if="users.length>0">
                <template v-for="(user) in users">
                    <q-item>
                        <q-item-section avatar>
                            <UserAvatar :avatar="user.user.avatar"></UserAvatar>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ user.user.fulled_name }}</q-item-label>
                            <template v-for="(title) in userTitle(user.user)">
                                <span class="q-mr-xs text-italic">{{ title.value }}</span>
                            </template>
                        </q-item-section>
                        <q-item-section class="q-gutter-xs column items-start">
                            <div class="q-gutter-x-xs">
                                <q-chip dense v-if="user.user.ext_login"
                                        :title="$translate('users.fields.extlogin')">
                                    <q-icon name="o_badge"/>
                                    <span class="q-ml-xs">{{ user.user.ext_login }}</span>
                                </q-chip>
                                <q-chip dense>
                                    <q-icon name="o_email"/>
                                    <span class="q-ml-xs">{{ user.user.email }}</span>
                                </q-chip>
                            </div>
                        </q-item-section>
                        <q-item-section side>
                            <q-btn dense icon="login" flat @click="loginAs(user.user.user_id)"
                                   :title="$translate('auth.loginas',{name: user.user.full_name})"/>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
            <Alert variant="warning" v-if="!searching && users.length===0 && search">
                {{ $translate('auth.search-not-found') }}
            </Alert>
        </q-card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import axios from "axios";
import {router} from "@inertiajs/vue3";
import UserAvatar from "@/Components/UserAvatar.vue";
import {Alert} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const dialog = ref(false)
const search = ref(null)
const users = ref([])
const searching = ref(false)

const userTitle = (user) => {
    return _.filter(user.additional, function (item) {
        return item.slug === 'title' || item.slug === 'position'
    })
}
/**
 * Поиск подразделения по краткому/полному наименованию
 * @param search
 */
const searchUsers = (search) => {
    searching.value = true;
    if (search === '') {
        users.value = [];
    } else {
        axios
            .get(route('ajax.users', {
                m: 'search',
                q: search
            }))
            .then((response) => {
                users.value = response.data.results
            })
            .finally(() => {
                searching.value = false;
            })

    }
}
const loginAs = (user_id) => {
    // window.alert(user_id);
    router.post(
        route('admin.logonas'),
        {loginas: user_id},
        {
            onSuccess: () => {
                dialog.value = false
                router.visit('/')
            }
        })
}
</script>