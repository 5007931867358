<template>
    <Card :title="$translate('users.manage.main-data.title')"
          title-bg="cyan-14"
          title-text="white"
          no-card-section
          hide-actions
    >
        <q-list dense separator class="max-height-vh-70 overflow-y-auto">
            <q-item>
                <q-item-section>
                    <q-field :label="$translate('users.manage.main-data.fields.fulled_name')"
                             stack-label
                             borderless
                    >
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">{{ user.fulled_name }}</div>
                        </template>
                    </q-field>
                </q-item-section>
            </q-item>
            <q-item>
                <q-item-section v-if="user.ext_login">
                    <q-field :label="$translate('users.manage.main-data.fields.extlogin')" stack-label borderless>
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">{{ user.ext_login }}</div>
                        </template>
                    </q-field>
                </q-item-section>
            </q-item>
            <q-item>
                <q-item-section>
                    <q-field :label="$translate('users.manage.main-data.fields.email')" stack-label borderless>
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">{{ user.email }}</div>
                        </template>
                    </q-field>
                </q-item-section>
            </q-item>
            <q-item v-for="(item, i) in user.additional" :key="i">
                <q-item-section>
                    <q-field :label="item.description.name" stack-label borderless>
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">{{ item.value }}</div>
                        </template>
                    </q-field>
                </q-item-section>
            </q-item>
        </q-list>
    </Card>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import {Card} from "@/Components/Block";

const props = defineProps({
    user: {
        type: Object,
        required: true,
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
