<template>
    <Card :title="$translate('module-analytics.title.' + report)" title-bg="deep-purple-2"
          header-toolbar no-card-section hide-actions>
        <template v-slot:headerActions>
            <q-btn :label="$translate('module-analytics.actions.create-report')"
                   icon="o_add"
                   color="deep-purple-5"
                   @click="modal = !modal"
                   dense/>
        </template>
        <slot/>
    </Card>
    <q-dialog v-model="modal" no-backdrop-dismiss>
        <Card in-modal
              :title="$translate('module-analytics.actions.create-report')" title-bg="deep-purple-2"
              show-close-button>
            <component :is="selectForm"
                       :ssc="ssc"
                       :from="from"
                       :to="to"
                       :ssc-list="sscList"
                       :tags-list="tagsList"
                       @receive-form="submitForm"
            />
            <template v-slot:actions>
                <q-btn icon="save" :label="$translate('module-analytics.actions.create-report')"
                       @click="store.switchState()"
                       color="positive"
                       no-caps
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import * as AnalyticReportsForms from "@/Components/Analytic/Reports/Forms";
import {computed, getCurrentInstance, ref} from "vue";
import _ from "lodash";
import {useForm} from "@inertiajs/vue3";
import {store} from "@/store/globalstore";
import Card from "@/Components/Block/Card.vue";

const props = defineProps({
    report: {
        type: String,
        required: true,
    },
    ssc: {
        type: Number,
    },
    from: {
        type: String,
    },
    to: {
        type: String,
    },
    tags: {
        type: Array,
        default: [],
    },
    tagsAdditional: {
        type: Array,
        default: [],
    },
    sscList: {
        type: Array,
        required: true,
    },
    tagsList: {
        type: Array,
        required: false,
        default: [],
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const modal = ref(false);

let form = null;

const submitForm = (data) => {
    form = useForm(data);
    form.get(route('analytics.report.standard.list', {report: props.report}));
    modal.value = !modal.value;
    store.switchState();
};

const selectForm = computed(
    () => AnalyticReportsForms[_.upperFirst(_.camelCase(props.report + '_form'))]
);
</script>
