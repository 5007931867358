<template>
    <ProjectLayout :project="project" tab="about">
        <q-card-section>
            <div class="q-gutter-x-md " :class="$q.screen.lt.md?'column':'row'">
                <div class="col q-gutter-y-md">
                    <AboutProject :project="project"/>
                    <ResearchClaim :project="project"/>
                    <Feedback :project="project"/>
                    <Relations :project="project"/>
                </div>
                <div class="col q-gutter-y-md">
                    <Members :project="project" :ldap-enabled="ldapEnabled"/>
                    <Departments :project="project"/>
                    <ExpectedResult :project="project"/>
                    <Expenses :project="project"/>
                </div>
            </div>
        </q-card-section>
    </ProjectLayout>
</template>

<script setup>
import ProjectLayout from "@/Components/Project/ProjectLayout.vue";
import {useQuasar} from "quasar"
import {
    AboutProject,
    ResearchClaim,
    Feedback,
    Members,
    Departments,
    ExpectedResult,
    Expenses,
    Relations
} from "@/Components/Project/Card/";

const $q = useQuasar();

defineProps(['project', 'ldapEnabled'])
</script>