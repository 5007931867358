<template>
    <q-list v-if="helpers.filled(publications)">
        <q-item v-for="(publication, i) in publications" :key="i">
            <q-item-section>
                <PublicationCard :publication="publication"/>
            </q-item-section>
        </q-item>
    </q-list>
    <AlertWarning v-else>{{ $translate('module-archive.publication.warnings.no-publications') }}</AlertWarning>
</template>

<script setup>
import AlertWarning from "@/Components/Block/AlertWarning.vue";
import {Card as PublicationCard} from "@/Components/Archive/Publication";
import helpers from "@/helpers";
import {getCurrentInstance} from "vue";

const props = defineProps({
    publications: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
