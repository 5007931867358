<template>
    <q-btn dense icon="menu" flat :disable="!canAction">
        <q-menu self="center right">
            <q-item v-if="department.can.approve"
                    @click="approveDialog = true" clickable v-close-popup>
                <q-item-section>
                    <q-item-label>{{ $translate('project.view.ssc-request.actions.approve') }}</q-item-label>
                </q-item-section>
                <q-item-section avatar>
                    <q-icon name="o_check" size="xs"/>
                </q-item-section>
            </q-item>

            <q-item v-if="department.can.revision"
                    @click="revisionDialog = true" clickable v-close-popup>
                <q-item-section>
                    <q-item-label>{{ $translate('project.view.ssc-request.actions.revision') }}</q-item-label>
                </q-item-section>
                <q-item-section avatar>
                    <q-icon name="o_restore" size="xs"/>
                </q-item-section>
            </q-item>
            <q-item v-if="department.can.decline"
                    @click="declineDialog = true" clickable v-close-popup>
                <q-item-section>
                    <q-item-label>{{ $translate('project.view.ssc-request.actions.decline') }}</q-item-label>
                </q-item-section>
                <q-item-section avatar>
                    <q-icon name="o_block" size="xs"/>
                </q-item-section>
            </q-item>
            <q-item v-if="department.can.confirm"
                    @click="confirmDialog = true" clickable v-close-popup>
                <q-item-section>
                    <q-item-label>{{ $translate('project.view.ssc-request.actions.confirm') }}</q-item-label>
                </q-item-section>
                <q-item-section avatar>
                    <q-icon name="o_check" size="xs"/>
                </q-item-section>
            </q-item>
            <q-item v-if="department.can.recovery">
                <q-item-section>
                    <q-item-label>{{ $translate('project.view.ssc-request.actions.recovery') }}</q-item-label>
                </q-item-section>
                <q-item-section avatar>
                    <q-icon name="o_account_box" size="xs"/>
                </q-item-section>
            </q-item>
            <q-item v-if="department.can.delete"
                    @click="deleteDepartment(department)" clickable v-close-popup>
                <q-item-section>
                    <q-item-label>{{ $translate('project.view.ssc-request.actions.delete') }}</q-item-label>
                </q-item-section>
                <q-item-section avatar>
                    <q-icon name="o_delete" size="xs"/>
                </q-item-section>
            </q-item>
        </q-menu>
    </q-btn>

    <q-dialog v-model="approveDialog"
              no-backdrop-dismiss>
        <Card :title="$translate('project.view.ssc-request.approve-request.title')"
              show-close-button in-modal>
            <AlertInfo>{{ $translate('project.view.ssc-request.approve-request.alert') }}</AlertInfo>

            <q-field :label="$translate('project.view.ssc-work-description')"
                     stack-label
                     borderless>
                <template v-slot:control>
                    <div v-if="department.description"
                         class="self-stretch full-width"
                         v-html="department.description"></div>
                    <div v-else
                         class="self-stretch full-width text-center">&mdash;
                    </div>
                </template>
            </q-field>

            <q-field v-if="department.research_count !== unlimited"
                     :label="$translate('project.view.ssc-count-description')"
                     stack-label
                     borderless>
                <template v-slot:control>
                    <div class="self-stretch full-width text-h6 text-center"
                         v-html="department.research_count"></div>
                </template>
            </q-field>

            <q-input type="textarea" rows="3"
                     v-model="form.comment"
                     :label="$translate('project.view.ssc-request.approve-request.comment-change')"
                     stack-label/>

            <template v-slot:actions>
                <q-btn icon="check"
                       label="согласовать"
                       color="positive"
                       @click="actionApprove"/>
                <q-btn icon="o_block"
                       label="отклонить"
                       color="negative"/>
            </template>
        </Card>
    </q-dialog>

    <q-dialog v-model="declineDialog"
              no-backdrop-dismiss>
        <Card :title="$translate('project.view.ssc-request.decline-request.title')"
              show-close-button in-modal>
            <AlertInfo>{{ $translate('project.view.ssc-request.decline-request.alert') }}</AlertInfo>
            <q-field :label="$translate('project.view.ssc-work-description')"
                     stack-label
                     borderless>
                <template v-slot:control>
                    <div v-if="department.description"
                         class="self-stretch full-width"
                         v-html="department.description"></div>
                    <div v-else
                         class="self-stretch full-width text-center">&mdash;
                    </div>
                </template>
            </q-field>
            <q-field v-if="department.research_count !== unlimited"
                     :label="$translate('project.view.ssc-count-description')"
                     stack-label
                     borderless>
                <template v-slot:control>
                    <div class="self-stretch full-width text-h6 text-center"
                         v-html="department.research_count"></div>
                </template>
            </q-field>

            <q-input type="textarea" rows="3"
                     v-model="form.comment"
                     :label="$translate('project.view.ssc-request.decline-request.comment')"
                     stack-label
                     :error="form.errors.hasOwnProperty('comment')"
                     :error-message="_.get(form.errors,'comment')"/>
            <template v-slot:actions>
                <q-btn icon="o_block"
                       :label="$translate('buttons.decline')"
                       color="negative"
                       @click="actionCancel"/>
                <q-btn icon="o_close"
                       :label="$translate('buttons.cancel')"
                       color="dark"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>

    <q-dialog v-model="revisionDialog"
              no-backdrop-dismiss>
        <Card :title="$translate('project.view.ssc-request.revision-request.title')"
              show-close-button in-modal>
            <q-field :label="$translate('project.view.ssc-work-description')" stack-label
                     borderless>
                <template v-slot:control>
                    <div v-if="department.description"
                         class="self-stretch full-width"
                         v-html="department.description"></div>
                    <div v-else
                         class="self-stretch full-width text-center">&mdash;
                    </div>
                </template>
            </q-field>
            <q-field v-if="department.research_count !== unlimited"
                     :label="$translate('project.view.ssc-count-description')"
                     stack-label
                     borderless>
                <template v-slot:control>
                    <div class="self-stretch full-width text-h6 text-center"
                         v-html="department.research_count"></div>
                </template>
            </q-field>
            <q-input v-if="department.research_count !== unlimited"
                     v-model="form.new_count"
                     :label="$translate('project.view.ssc-request.revision-request.new-count')"
                     stack-label
                     input-class="text-center text-h6"/>

            <q-input type="textarea" rows="3"
                     v-model="form.comment"
                     label="Укажите комментарий"
                     stack-label
                     :error="form.errors.hasOwnProperty('comment')"
                     :error-message="_.get(form.errors,'comment')"/>

            <template v-slot:actions>
                <q-btn icon="check"
                       label="отправить на корректировку"
                       color="positive"
                       @click="actionRevision"/>
                <q-btn icon="o_close"
                       :label="$translate('buttons.cancel')"
                       color="dark"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>

    <q-dialog v-model="confirmDialog"
              no-backdrop-dismiss>
        <Card :title="$translate('project.view.ssc-request.confirm-request.title')"
              show-close-button in-modal>
            <q-field :label="$translate('project.view.ssc-work-description')" stack-label
                     borderless>
                <template v-slot:control>
                    <div v-if="department.description"
                         class="self-stretch full-width"
                         v-html="department.description"></div>
                    <div v-else
                         class="self-stretch full-width text-center">&mdash;
                    </div>
                </template>
            </q-field>
            <q-field v-if="department.research_count !== unlimited"
                     :label="$translate('project.view.ssc-count-description')"
                     stack-label
                     borderless>
                <template v-slot:control>
                    <div class="self-stretch full-width text-h6 text-center"
                         v-html="department.research_count"></div>
                </template>
            </q-field>
            <q-input v-if="department.research_count !== unlimited"
                     v-model="form.new_count"
                     :label="$translate('project.view.ssc-request.confirm-request.new-count')"
                     stack-label
                     input-class="text-center text-h6"/>

            <q-field :label="$translate('project.view.ssc-request.confirm-request.last-comment')"
                     stack-label
                     borderless>
                <template v-slot:control>
                    <div class="self-stretch full-width text-body2"
                         v-html="helpers.nl2br(_.get(department.comments[0],'comment'))"></div>
                </template>
            </q-field>

            <q-input type="textarea" rows="3"
                     v-model="form.comment"
                     :label="$translate('project.view.ssc-request.confirm-request.comment')"
                     stack-label
                     :error="form.errors.hasOwnProperty('comment')"
                     :error-message="_.get(form.errors,'comment')"/>

            <template v-slot:actions>
                <q-btn icon="check"
                       :label="$translate('buttons.approve')"
                       color="positive"
                       @click="actionConfirm"/>
                <q-btn icon="close"
                       :label="$translate('buttons.decline')"
                       color="negative"
                       @click="actionConfirmDecline"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {router, useForm, usePage} from "@inertiajs/vue3";
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import AlertInfo from "@/Components/Block/AlertInfo.vue";
import _ from 'lodash';
import helpers from "@/helpers";
import {useQuasar} from "quasar";

const app = getCurrentInstance()
const $q = useQuasar();
const $translate = app.appContext.config.globalProperties.$translate;

const props = defineProps({
    department: {type: Object},
    project: {type: Object}
})

const unlimited = computed(() => usePage().props.config.unlimited);

const canAction = computed(() => {
    return _.size(_.filter(props.department.can, (item) => item === true)) > 0
})
const approveDialog = ref(false)
const declineDialog = ref(false)
const revisionDialog = ref(false)
const confirmDialog = ref(false)

const form = useForm({
    ssc_id: null,
    original_count: null,
    new_count: null,
    comment: null,

})
onMounted(() => {
    form.ssc_id = props.department.ssc_id;
    form.original_count = props.department.research_count;
    form.new_count = props.department.research_count;
})
const actionApprove = () => {
    form
        .transform((data) => ({...data, action: 'approve'}))
        .post(route('project.request.process', props.project), {
            onSuccess: () => {
                approveDialog.value = false;
                router.reload()
            }
        })
}
const actionCancel = () => {
    form
        .transform((data) => ({...data, action: 'decline'}))
        .post(route('project.request.process', props.project), {
            onSuccess: () => {
                declineDialog.value = false;
                router.reload()
            }
        })
}
const actionRevision = () => {
    form
        .transform((data) => ({...data, action: 'revision'}))
        .post(route('project.request.process', props.project), {
            onSuccess: () => {
                revisionDialog.value = false;
                router.reload()
            }
        })
}
const actionConfirm = () => {
    form
        .transform((data) => ({...data, action: 'confirm'}))
        .post(route('project.request.confirm', [props.project, props.department]), {
            onSuccess: () => {
                confirmDialog.value = false;
                router.reload()
            }
        })
}
const actionConfirmDecline = () => {
    form
        .transform((data) => ({...data, action: 'cancel'}))
        .post(route('project.request.confirm', [props.project, props.department]), {
            onSuccess: () => {
                confirmDialog.value = false;
                router.reload()
            }
        })
}
const deleteDepartment = (department) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: department.ssc.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('project.request.delete', {
            project: department.project,
            requestResearch: department
        }));
    })
};

</script>