<template>
    <div class="text-right text-small">
        последнее обновление: {{ moment(staff.updated_at).format($translate('common.datetime.js')) }}
    </div>
    <div class="row q-gutter-x-md">
        <div class="col-auto">
            <q-img :src="staff.user.avatar?staff.user.avatar:'/img/avatar.png'"
                   loading="lazy"
                   alt="user avatar"
                   style="width: 150px;height: 150px;object-fit: cover;"/>
        </div>
        <div class="col">
            <q-field :label="$translate('users.fields.last_name')" stack-label tag="div">
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ _.get(staff, 'user.last_name') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('users.fields.first_name')" stack-label tag="div">
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ _.get(staff, 'user.first_name') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('users.fields.middle_name')" stack-label tag="div">
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ _.get(staff, 'user.middle_name') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('users.fields.birthdate')" stack-label tag="div">
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ _.get(staff, 'user.birthdate') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('users.fields.email')" stack-label tag="div">
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ _.get(staff, 'user.email') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('cadre-staff.fields.degree')" stack-label tag="div">
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ _.get(staff, 'degree') || '&mdash;' }}
                    </div>
                </template>
            </q-field>

        </div>
        <div class="col">
            <q-field :label="$translate('cadre-staff.fields.cadre_department')" stack-label tag="div">
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ _.get(staff, 'cadre_department.name') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('cadre-staff.fields.position')" stack-label tag="div">
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ _.get(staff, 'cadre_position.name') }} ({{ _.get(staff, 'cadre_position.group') }})
                        на {{ _.get(staff, 'rate_formatted') }}
                    </div>
                </template>
                <template v-slot:append v-if="_.size(addonStaff)>0">
                    <q-btn icon="expand_more" flat :title="$translate('другие должности пользователя')">
                        <q-menu>
                            <q-item dense>
                                <q-item-section>
                                    <q-item-label caption class="text-center">
                                        {{ $translate('другие должности пользователя') }}
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-for="(ss) in addonStaff" clickable
                                    @click="router.visit($route('cadre.staff.show',ss))">
                                <q-item-section>
                                    <q-item-label class="text-no-wrap">
                                        {{ _.get(ss, 'cadre_position.name') }} ({{ _.get(ss, 'cadre_position.group') }})
                                        на {{ _.get(ss, 'rate_formatted') }}
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-menu>
                    </q-btn>
                </template>
            </q-field>
            <q-field :label="$translate('cadre-staff.fields.date_employment')" stack-label tag="div">
                <template v-slot:control>
                    <div class="items-center full-width">
                        {{ _.get(staff, 'date_employment') }}
                    </div>
                </template>
                <template v-slot:prepend v-if="staff.file_employment">
                    <q-btn icon="attach_file"
                           :title="$translate('cadre-staff.fields.file_employment')"
                           dense flat
                    />
                </template>
            </q-field>
            <q-field
                    :label="$translate('cadre-staff.label.worktime') + ' ('+(_.get(staff, 'worktime.0.status_text') || 'не указан' )+')'"
                    tag="div"
                    stack-label borderless>
                <template v-slot:control>
                    <div class="items-center full-width flex">
                        <WorktimeList :worktime="_.get(staff, 'worktime')"/>
                    </div>
                </template>
            </q-field>
        </div>
    </div>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref, computed} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {usePage} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment";
import {CadreStaff} from "@/entity"
import {List as WorktimeList} from "@/Components/Cadre/Worktime";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

interface Props {
    staff: CadreStaff,
}

const props = defineProps<Props>()

const addonStaff: any = computed(() => usePage().props.addonStaff)

</script>