import {openDB, deleteDB, wrap, unwrap} from 'idb';
import axios from "axios";
import _ from "lodash";

const DB_NAME = 'classification'
export default {

    /**
     *
     * @param table
     * @returns {Promise<IDBPDatabase<unknown>>}
     */
    async initDB(table) {
        return openDB(DB_NAME + '_' + table, 1, {
            upgrade(db) {
                db.createObjectStore(table, {
                    // The 'id' property of the object will be the key.
                    keyPath: 'id',
                    // If it isn't explicitly set, create a value by auto incrementing.
                    autoIncrement: true,
                });
            },
        });
    },
    /**
     *
     * @param storage
     * @param key
     * @returns {Promise<StoreValue<unknown, StoreNames<unknown>> | undefined>}
     */
    async getItem(storage, key) {
        return (await this.initDB(storage)).get(storage, key)
    },
    /**
     *
     * @param storage
     * @param key
     * @param value
     * @returns {Promise<StoreKey<unknown, StoreNames<unknown>>>}
     */
    async putItem(storage, key, value) {
        return (await this.initDB(storage)).put(storage, key, value)
    },

    /**
     * Загрузка элементов простого списка
     * @param classname
     * @returns {Promise<void>}
     */
    async loadSimpleList(classname) {
        const db = await this.initDB(classname);
        const keys = await db.getAllKeys(classname)
        if (_.size(keys) === 0) {
            await axios
                .get('/classificator.json?m=loadOptions&class=' + classname)
                .then(async (response) => {
                    _.forEach(response.data.results, (item) => {
                        db.put(classname, item)
                    })
                })
        }
    },
    /**
     *
     * @param classname
     * @returns {Promise<*>}
     */
    async getSimpleList(classname) {
        const db = await this.initDB(classname);
        const keys = await db.getAllKeys(classname)
        if (_.size(keys) === 0) {
            await this.loadSimpleList(classname)
        }
        return db.getAll(classname)
    },
    /**
     * Загрузка элементов многоуровневого списка
     * @param key
     * @returns {Promise<void>}
     */
    async loadMultiList(key) {
        const db = await this.initDB(key);
        await axios
            .get('/science.classification.json?m=loadOptions&class=' + key)
            .then(async (response) => {
                _.forEach(response.data.results, (item) => {
                    db.put(key, item)
                })
            })

    },
    /**
     *
     * @param classname
     * @returns {Promise<*>}
     */
    async getMultiList(classname) {
        const db = await this.initDB(classname);
        const keys = await db.getAllKeys(classname)
        if (_.size(keys) === 0) {
            await this.loadMultiList(classname)
        }
        return db.getAll(classname)
    },
    /**
     * Загрузка элементов моделей
     * @param key
     * @returns {Promise<void>}
     */
    async loadModelList(key, ssc_id) {
        const db = await this.initDB(key + ssc_id);
        await axios
            .get('/model.json', {
                params: {
                    m: 'loadOptions',
                    class: key,
                    ssc_id: ssc_id
                }
            })
            .then(async (response) => {
                _.forEach(response.data.results, (item) => {
                    db.put(key + ssc_id, item)
                })
            })

    },
    /**
     *
     * @param classname
     * @param ssc_id
     * @returns {Promise<*>}
     */
    async getModelList(classname, ssc_id) {
        const db = await this.initDB(classname + ssc_id);
        const keys = await db.getAllKeys(classname + ssc_id)
        if (_.size(keys) === 0) {
            await this.loadModelList(classname, ssc_id)
        }
        return db.getAll(classname + ssc_id)
    },
// install(app, options) {
//     const dbPromise = openDB('keyval-store', 1, {
//         upgrade(db) {
//             db.createObjectStore('keyval');
//         },
//     });
//
//     async function get(key) {
//         return (await dbPromise).get('keyval', key);
//     }
//
//     async function set(key, val) {
//         return (await dbPromise).put('keyval', val, key);
//     }
//
//     async function del(key) {
//         return (await dbPromise).delete('keyval', key);
//     }
//
//     async function clear() {
//         return (await dbPromise).clear('keyval');
//     }
//
//     async function keys() {
//         return (await dbPromise).getAllKeys('keyval');
//     }
// }
}