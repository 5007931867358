<template>
    <q-bar class="bg-grey-1 q-py-sm q-mb-sm">
        <h6 class="q-my-none">{{ $translate('cadre-staff.label.dutyjourney') }}</h6>
        <q-space/>
        <q-btn v-if="_.get(can,'dutyjourney.create')"
               icon="add"
               :label="$translate('buttons.add')"
               color="white" text-color="black"
               size="md" dense no-caps
               @click="openAddDialog"
        />
        <q-separator vertical class="q-mx-md"/>
        <q-select v-model="currentYear"
                  @update:modelValue="loadItems"
                  :options="optionsYears"
                  :prefix="$translate('на')"
                  :suffix="$translate('units.year')"
                  options-dense
                  dense/>
        <q-btn-toggle v-model="tab"
                      spread no-caps
                      toggle-color="blue-grey-3" color="white" text-color="black"
                      :options="[{value: 'table', slot:'table'},{value: 'chart',slot:'chart'}]"
                      @update:model-value="loadItems"
        >
            <template v-slot:table>
                <q-icon name="sym_o_table" size="sm"/>
                <q-tooltip>{{ $translate('buttons.view-as-table') }}</q-tooltip>
            </template>
            <template v-slot:chart>
                <q-icon name="sym_o_grouped_bar_chart" size="sm"/>
                <q-tooltip>{{ $translate('buttons.view-as-chart') }}</q-tooltip>
            </template>
        </q-btn-toggle>
    </q-bar>

    <template v-if="tab==='table'">
        <q-table :rows="items"
                 :columns="tableColumns"
                 :pagination="{rowsPerPage: 0}"
                 :rows-per-page-options="[0]"
                 :loading="loading"
                 hide-pagination
                 bordered
                 flat
        >
            <template v-slot:body-cell-period="props">
                <q-td :props="props">
                    <div v-html="props.value"></div>
                </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DutyjourneyActions :dutyjourney="props.row" @onSuccess="loadItems"/>
                </q-td>
            </template>
        </q-table>
    </template>
    <template v-if="tab==='chart'">
        <Chart v-if="!_.isEmpty(chartData)"
               chart-type="gantt-date"
               :data="chartData"
               :config="chartConfig"
               :height="chartHeight"
        />
    </template>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('cadre-dutyjourney.title.new')"
              in-modal show-close-button header-toolbar>
            <ProfileDutyjourneyForm v-model="addForm"
                                    :position="_.filter([_.get(staff,'name'), _.get(staff,'cadre_position.name'),_.get(staff,'cadre_department.name')]).join(', ')"/>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       dense
                       @click="addFormSubmit"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, Ref, ref, computed, ComputedRef} from "vue";
import {usePage, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import {CadreStaff, CadreDutyjourney} from "@/entity"
import Chart from "@/Components/Chart.vue";
import DutyjourneyActions from "@/Components/Cadre/Dutyjourney/DutyjourneyActions.vue";
import {AnyValuesObject, StringValuesObject} from "@/entity/types";
import {IGanttDateChartConfig} from "@/entity/charts";
import {toValue} from "@vueuse/core";
import ProfileDutyjourneyForm from "@/Components/Cadre/Dutyjourney/ProfileDutyjourneyForm.vue";
import {Card} from "@/Components/Block";


const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

interface Props {
    staff: CadreStaff
}

const props = defineProps<Props>()

const tab = ref('table')

const loading = ref(false)

const optionsYears: any = computed(() => usePage().props.dutyjourneyYears)

const can: any = computed(() => usePage().props.can)

const currentYear = ref(moment().year())

const items = ref([])

const tableColumns: any = [
    {name: 'actions', label: '', style: 'width:20px'},
    {
        name: 'type',
        field: 'type_text',
        label: $translate('cadre-dutyjourney.label.type'),
        align: 'left'
    },
    {
        name: 'period',
        field: 'period',
        label: $translate('cadre-dutyjourney.label.period'),
        align: 'left'
    },
    {
        name: 'city',
        field: 'city',
        label: $translate('cadre-dutyjourney.label.city'),
        align: 'left'
    },
    {
        name: 'status',
        field: 'status_text',
        label: $translate('cadre-dutyjourney.label.status'),
        align: 'left'
    },
]
const loadItems = () => {
    loading.value = true;
    axios.get($route('ajax.cadre.staff', {m: 'dutyjourney', staff_id: props.staff.id, year: currentYear.value}))
            .then((response) => {
                items.value = response.data.results
            })
            .finally(() => {
                if ("chart" === tab.value) {
                    refillChartData();
                }
                loading.value = false
            })
}


const addDialog = ref(false)

const openAddDialog = () => {
    addForm.reset()
    addDialog.value = true;
}

const addForm = useForm({
    action: null,
    dutyjourney: {
        cadre_staff_id: props.staff.id,
        type: null,
        date_start: null,
        date_end: null,
        days: null,
        city: null,
        target: null,
        file: null,
        comment: null,
        status: null
    },
    comment: null
});

/**/
const addFormSubmit = () => {
    addForm
            .transform((data) => ({
                        ...data.dutyjourney,
                        comment: data.comment
                    })
            )
            .post($route('cadre.dutyjourney.add'), {
                onSuccess: () => {
                    addForm.reset()
                    addDialog.value = false;
                    loadItems()
                }
            });
}

/*******************************************
 * настройки для графика
 ***************************************/

const chartData: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);
const chartConfig: ComputedRef<IGanttDateChartConfig> = computed<IGanttDateChartConfig>(() => ({
    chartSettings: {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
    },
    chartWidgets: {
        cursor: {behavior: "zoomX"},
    },
    categoryYAxis: {
        categoryField: "type_text",
        categories: [],
        rendererSettings: {
            minGridDistance: 30
        },
        rendererGridTemplateLocation: 1
    },
    dateXAxis: {
        baseInterval: {timeUnit: "day", count: 1},
        gridIntervals: [
            {timeUnit: "day", count: 1},
            {timeUnit: "week", count: 1},
            {timeUnit: "month", count: 1},
        ],
        additionalSettings: {
            min: moment(currentYear.value + "-01-01").toDate().getTime(),
            max: moment(currentYear.value + "-12-31").toDate().getTime(),
            strictMinMax: true,
            tooltipDateFormat: "dd.MM.yyyy"
        },
        rendererSettings: {
            strokeOpacity: 0.1
        }
    },
    columnSeries: {
        openValueXField: "date_start",
        valueXField: "date_end",
        categoryYField: "type_text",
        tooltipText: "{type_text} ({status_text}):\n[bold]{openValueX.formatDate('dd.MM.yyyy')}[/] - [bold]{valueX.formatDate('dd.MM.yyyy')}[/] ({days} дней)\n{comment}",
        dataFieldToProcess: "dutyjourneys",
        dateFields: ["date_start", "date_end"],
        dateFormat: "yyyy-MM-dd",
    },
}));
const chartHeight: Ref<string> = ref<string>("700px");

const refillChartData: Function = () => {
    chartData.value = [];
    chartConfig.value.categoryYAxis.categories = [];
    let categories: StringValuesObject[] = [], data: AnyValuesObject[] = [];
    _.forEach(toValue(items), (item: CadreDutyjourney) => {
        categories.push({
            [chartConfig.value.categoryYAxis.categoryField]: item[chartConfig.value.categoryYAxis.categoryField]
        });
    });
    categories = _.uniqBy(categories, chartConfig.value.categoryYAxis.categoryField);
    if (1 === _.size(categories)) {
        chartHeight.value = "250px";
    }
    _.forEach(categories, (category: StringValuesObject) => {
        data.push({
            [chartConfig.value.categoryYAxis.categoryField]: category[chartConfig.value.categoryYAxis.categoryField],
            [chartConfig.value.columnSeries.dataFieldToProcess]: _.filter(
                    toValue(items),
                    (item: CadreDutyjourney) => item[chartConfig.value.categoryYAxis.categoryField] === category[chartConfig.value.categoryYAxis.categoryField]
            )
        });
    });
    chartData.value = data;
    chartConfig.value.categoryYAxis.categories = _.reverse(
            _.sortBy(categories, chartConfig.value.categoryYAxis.categoryField)
    );
};

onMounted(() => {
    loadItems()
})
</script>