<template>
    <Card :title="$translate('cadre-positions.title.list')"
          header-toolbar hide-actions no-card-section>
        <div class="row" :class="{'column':$q.screen.lt.md}">
            <template v-for="(position) in positions">
                <div class="col-12 q-pa-md">
                    <ProfilePositionItem :position="position"/>
                </div>
            </template>
        </div>
    </Card>
</template>

<script setup lang="ts">
import {getCurrentInstance} from "vue";
import {Card} from "@/Components/Block";
import {useQuasar} from "quasar";
import ProfilePositionItem from "@/Components/Cadre/Position/ProfilePositionItem.vue";
import {CadreStaff} from "@/entity/";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

const $q = useQuasar()

interface Props {
    positions: CadreStaff[]
}

defineProps<Props>()

</script>