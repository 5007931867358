<template>
    <q-item>
        <q-item-section>
            <q-item-label>
                {{ moment(vacation.date_start).format($translate('common.date.js')) }}
                &mdash;
                {{ moment(vacation.date_end).format($translate('common.date.js')) }}
                ({{ vacation.vacation_days }})
            </q-item-label>
            <q-item-label caption>
                {{ vacation.status_text }}
            </q-item-label>
        </q-item-section>
        <q-item-section side>
            <q-icon name="o_comment" v-if="vacation.comment" class="cursor-pointer">
                <q-popup-proxy>
                    <q-item>
                        <q-item-section>
                            <q-item-label>
                                {{ vacation.comment }}
                            </q-item-label>
                        </q-item-section>
                    </q-item>
                </q-popup-proxy>
            </q-icon>
        </q-item-section>
        <q-item-section side>
            <VacationActions :vacation="vacation" @onSuccess="router.reload()"/>
        </q-item-section>
    </q-item>
</template>
<script setup lang="ts">

import {router, useForm} from "@inertiajs/vue3";
import moment from "moment";
import {computed, getCurrentInstance, ref} from "vue";
import {CadreVacation} from "@/entity";
import {useQuasar} from "quasar";
import VacationActions from "@/Components/Cadre/Vacation/VacationActions.vue";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route
const $q = useQuasar()

interface Props {
    vacation: CadreVacation
}

defineProps<Props>()

</script>
