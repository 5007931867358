<template>
    <q-list v-if="services.hasOwnProperty('analytics')">
        <NavigationItem v-if="services.analytics.items.standard_reports.all"
                        :title="$translate('menu.analytics.standard')"
                        :sidebar="sidebar"
                        color="dark"
                        icon="o_analytics"
        >
            <template #items>
                <template v-for="(name, report) in $translate('module-analytics.reports')">
                    <q-item v-if="services.analytics.items.standard_reports[report]"
                            @click="router.visit($route('analytics.report.standard.list', {'report': report}))"
                            clickable v-close-popup
                    >
                        <q-item-section class="q-pl-md">
                            {{ name }}
                        </q-item-section>
                    </q-item>
                </template>
            </template>
        </NavigationItem>
        <NavigationItem v-if="services.analytics.items.charts.all"
                        :title="$translate('menu.analytics.charts')"
                        :sidebar="sidebar"
                        color="dark"
                        icon="o_area_chart"
        >
            <template #items>
                <template v-for="(name, report) in $translate('module-analytics.chart_simple')">
                    <q-item v-if="services.analytics.items.charts[report]"
                            @click="router.visit($route('analytics.chart_simple', {'context': report}))"
                            clickable v-close-popup
                    >
                        <q-item-section class="q-pl-md">
                            {{ name }}
                        </q-item-section>
                    </q-item>
                </template>
                <q-item dense><q-item-section><q-separator/></q-item-section></q-item>
                <template v-for="(item, key) in $translate('module-analytics.chart_extended')" :key="key">
                    <q-item v-if="services.analytics.items.charts[key]"
                            @click="router.visit($route('analytics.chart_extended', {'context': key}))"
                            clickable v-close-popup
                    >
                        <q-item-section class="q-pl-md">
                            {{ item.title }}
                        </q-item-section>
                    </q-item>
                </template>
            </template>
        </NavigationItem>
    </q-list>
</template>

<script setup>
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";
import {computed, getCurrentInstance} from "vue";
import {router, usePage} from "@inertiajs/vue3";

const props = defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const page = usePage();
const services = computed(() => page.props.services);
</script>
