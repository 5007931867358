<template>
    <q-input v-model="form.name"
             :label="$translate('module-sensors.sensor-types.form.fields.name')"
             :error="form.errors.hasOwnProperty('name')"
             :error-message="_.get(form.errors, 'name')"
    />
    <q-select v-model="selectedConnection"
              :label="$translate('module-sensors.sensor-types.form.fields.select-connection')"
              :error="form.errors.hasOwnProperty('connection_data')"
              :error-message="_.get(form.errors, 'connection_data')"
              :options="connections"
              map-options
              emit-value
    />
    <SensorTypeConnectionData :connection-id="selectedConnection"
                              :current-data="form.connection_data"
                              @input="setConnectionData"
    />
    <q-select v-model="form.format"
              :label="$translate('module-sensors.sensor-types.form.fields.format')"
              :error="form.errors.hasOwnProperty('format')"
              :error-message="_.get(form.errors, 'format')"
              :options="formats"
              map-options
              emit-value
    />
</template>

<script setup>
import {ConnectionData as SensorTypeConnectionData} from "@/Components/Sensors/SensorType";
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";

/**
 * @param InertiaForm form
 */
const form = defineModel({type: Object});
const props = defineProps({
    connections: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const selectedConnection = ref(null);
const formats = _.map($translate('module-sensors.formats'), (value, key) => ({
    label: value.name,
    value: key
}));

const setConnectionData = (event) => {
    form.value.connection_data = event;
};

onMounted(() => {
    if (form.value.connection_data) {
        selectedConnection.value = _.get(form.value.connection_data, 'id');
    }
});
</script>
