<template>
    <q-banner class="bg-red-6 text-white q-my-xs" dense>
        <q-item dense>
            <q-item-section side>
                <q-icon name="o_report_problem" color="white" size="md"/>
            </q-item-section>
            <q-item-section>
                <slot/>
            </q-item-section>
        </q-item>
    </q-banner>
</template>