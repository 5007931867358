<template>
    <Layout :department="department" tab="staff">
        <q-bar class="bg-grey-2 q-py-sm q-mb-sm">
            <h6 class="q-my-none">{{ $translate('ssc.title-staff') }}</h6>
            <q-space/>
            <q-btn v-if="department.can.staff.add"
                   :label="$translate('buttons.add')"
                   icon="add"
                   size="sm"
                   @click="openStaffModal"
                   color="blue-grey-5"
                   dense/>
        </q-bar>
        <q-list separator>
            <template v-for="(item) in staff">
                <q-item>
                    <StaffItem :staff="item"
                               :user="item"
                               :can="item.can"
                               :ssc="department"
                               with-roles
                               :is-director="item.user_id === department.director_id"/>
                </q-item>
            </template>
        </q-list>
        <q-dialog v-model="staffModal" no-backdrop-dismiss>
            <Card :title="$translate('ssc.new-staff')"
                  title-bg="blue-grey-3"
                  in-modal show-close-button>
                <SearchUser @onSelect="selectStaffUser" with-ldap/>
                <SearchRoles @onSelect="selectStaffRole" :scope="['ssc']"/>
                <template v-slot:actions>
                    <q-btn icon="save"
                           :label="$translate('buttons.add')"
                           color="positive"
                           @click="staffFormSubmit"/>
                </template>
            </Card>
        </q-dialog>
    </Layout>
</template>

<script setup>

import Layout from "@/Pages/SSC/Layout.vue";
import {getCurrentInstance, ref} from "vue";
import StaffItem from "@/Components/SSC/Staff/StaffItem.vue";
import {router, useForm} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import SearchUser from "@/Components/Search/SearchUser.vue";
import SearchRoles from "@/Components/Search/SearchRoles.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const props = defineProps({
    department: {},
    staff: {
        type: Array,
    }
})

const staffModal = ref(false)
const openStaffModal = () => {
    staffForm.reset()
    staffModal.value = true
}
const staffForm = useForm({
    user_id: null,
    role_id: false
})
const selectStaffUser = (user) => {
    staffForm.user_id = user.id
}
const selectStaffRole = (id) => {
    staffForm.role_id = id
}
const staffFormSubmit = () => {
    staffForm
            .patch(route('admin.ssc.staff', props.department), {
                onSuccess: () => {
                    staffModal.value = false
                    staffForm.reset()
                    router.reload()
                }
            })
}
</script>