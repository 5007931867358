<template>
    <Card :title="$translate('users.manage.roles.title')"
          title-bg="cyan-14"
          title-text="white"
          no-card-section
          hide-actions
    >
        <q-list separator class="max-height-vh-70 overflow-y-auto">
            <q-item v-if="show">
                <q-item-section>
                    <q-skeleton width="100%" height="2rem"></q-skeleton>
                    <div class="row justify-between q-my-sm">
                        <q-skeleton width="35%"></q-skeleton>
                        <q-skeleton width="55%"></q-skeleton>
                    </div>
                    <div class="row justify-between q-my-sm">
                        <q-skeleton width="65%"></q-skeleton>
                        <q-skeleton width="30%"></q-skeleton>
                    </div>
                </q-item-section>
            </q-item>
            <q-item v-for="(item, i) in data" :key="i">
                <q-item-section>
                    <div class="row items-center justify-between">
                        <div class="col">
                            {{ item.role }}
                            <q-icon v-if="item.description" name="o_info" color="info" class="cursor-help">
                                <q-tooltip class="text-body2">{{ item.description }}</q-tooltip>
                            </q-icon>
                        </div>
                        <div v-if="!_.isEmpty(item.badges)">
                            <q-badge v-for="(badge, k) in item.badges" :key="k"
                                     color="secondary"
                                     text-color="white"
                                     :label="badge.data"
                                     @click="router.visit(badge.href)"
                                     class="cursor-pointer"
                            />
                        </div>
                    </div>
                    <div v-if="item.permissions">
                        <div v-for="(permissions, group) in item.permissions" :key="group">
                            <div class="q-mt-xs">
                                <small>{{ group }}</small>
                            </div>
                            <q-badge v-for="(permission, j) in permissions" :key="j"
                                     :label="permission.name"
                                     color="grey-8"
                                     class="q-mx-xs"
                                     outline
                            />
                        </div>
                    </div>
                </q-item-section>
            </q-item>
        </q-list>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import axios from "axios";
import _ from "lodash";
import {Card} from "@/Components/Block";

const props = defineProps({
    userId: {
        type: Number,
        required: true
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const show = ref(true);
const data = ref([]);

const getUserRoles = () => {
    show.value = true;
    axios.get(route("ajax.users"), {
        params: {
            "m": "getUserCardRoles",
            "user_id": props.userId
        }
    }).then((response) => {
        _.forEach(response.data.results, (item) => {
            data.value.push(item);
        });
    }).catch(e => {
        console.error(e);
    }).finally(() => {
        show.value = false;
    });
};

onMounted(() => {
    getUserRoles();
});
</script>
