<template>
    <q-input v-model="form.name"
             :label="$translate('module-microcollections.type.form.fields.name')"
             :error="form.errors.hasOwnProperty('name')"
             :error-message="_.get(form.errors, 'name')"
             maxlength="255"
    />
    <q-input v-model="form.description_i18n.ru"
             :label="$translate('module-microcollections.type.form.fields.description_i18n-ru')"
             :error="form.errors.hasOwnProperty('description_i18n.ru')"
             :error-message="_.get(form.errors, 'description_i18n.ru')"
             type="textarea"
             rows="3"
    />
    <q-input v-model="form.description_i18n.en"
             :label="$translate('module-microcollections.type.form.fields.description_i18n-en')"
             :error="form.errors.hasOwnProperty('description_i18n.en')"
             :error-message="_.get(form.errors, 'description_i18n.en')"
             type="textarea"
             rows="3"
    />
    <q-toggle v-model="form.public_state" :label="publicState" :true-value="false" :false-value="true"/>
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import _ from "lodash";

/**
 * @param InertiaForm form
 */
const form = defineModel({type: Object});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const publicState = computed(() => {
    let keys = {true: 'shown', false: 'hidden'};
    return $translate('module-microcollections.type.form.fields.public_state.' + keys[form.value.public_state]);
});
</script>
