<template>
    <template v-if="connectionId">
        <template v-for="(connectionField, key) in connectionData" :key="key">
            <q-select v-if="'select' === connectionField.type"
                      :label="$translate(`module-sensors.connections.${connectionId}.fields.${connectionField.name}`)"
                      v-model="connectionField.value"
                      :multiple="connectionField.multiple"
                      :options="connectionField.options"
                      options-dense
                      map-options
                      emit-value
            />
            <q-input v-else
                     :label="$translate(`module-sensors.connections.${connectionId}.fields.${connectionField.name}`)"
                     v-model="connectionField.value"
                     :type="connectionField.type"
            />
        </template>
    </template>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref, watch} from "vue";
import _ from "lodash";
import axios from "axios";

const props = defineProps({
    connectionId: String,
    currentData: Object
});
const emit = defineEmits(["input"]);

const connectionData = ref({});
const localConnectionId = ref();

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const fetchConnectionData = (id) => {
    axios.get(route('ajax.sensors'), {
        params: {
            m: 'getSensorFields',
            id: id
        }
    }).then((response) => {
        connectionData.value = _.map(response.data.results, (item) => {
            if (props.currentData && props.currentData.hasOwnProperty(item.name)) {
                return { ...item, value: props.currentData[item.name] };
            } else {
                return { ...item, value: [] };
            }
        });
    });
};

watch(props, () => {
    if (props.connectionId) {
        localConnectionId.value = props.connectionId;
    }
});
watch(
    () => localConnectionId.value,
    (newConnectionId, oldConnectionId) => {
        if (newConnectionId && (newConnectionId !== oldConnectionId || _.isEmpty(connectionData.value))) {
            fetchConnectionData(newConnectionId);
        }
    },
    { deep: true }
);
watch(
    () => connectionData.value,
    (newConnectionData) => {
        let cd = {};
        _.forEach(newConnectionData, (connectionField) => {
            cd[connectionField.name] = connectionField.value;
        });
        cd = { ...cd, id: props.connectionId };
        emit('input', cd);
    },
    { deep: true }
);

onMounted(() => {
    if (props.connectionId) {
        localConnectionId.value = props.connectionId;
    }
});
</script>
