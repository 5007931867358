<template>
    <ProjectLayout :project="project" tab="delivery">
        <q-toolbar class="bg-indigo-1 text-black">
            <q-toolbar-title>
                {{ $translate('module-delivery.title-order-create') }}
            </q-toolbar-title>
        </q-toolbar>
        <q-card-section>
            <DeliveryOrderForm v-model="order"
                               :errors="form.errors"
            />
        </q-card-section>
        <q-separator/>
        <q-card-actions align="center">
            <q-btn icon="o_save"
                   :label="$translate('buttons.add')"
                   color="positive"
                   @click="submitForm"/>
        </q-card-actions>

    </ProjectLayout>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import { useForm} from "@inertiajs/vue3";
import DeliveryOrderForm from "@/Components/Delivery/DeliveryOrderForm.vue";
import ProjectLayout from "@/Components/Project/ProjectLayout.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    project: {
        type: Object,
    }
});

const order = ref({
    from: null,
    goods: null,
    count: null,
    unit: null,
    self: null,
    to_address: null,
    room: null,
    self_address: null,
    new_address: null,
    new_address_detail: null,
    date: null,
    comment: null,
    valid: false
})

const form = useForm(order.value)

const submitForm = () => {
    form.transform((data) => ({
        ...data,
        ...order.value,
        project_id: props.project.id
    }))
            .post(route('services.delivery.order.store'), {})
}
</script>