<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions no-card-section>

        <q-card-section v-if="_.get(fields,'fields.name.show')">
            <q-input v-model="publication.name"
                     :label="_.get(fields,'fields.name.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('name')"
                     :error-message="_.get(errors,'name')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.description.show')">
            <q-input v-model="publication.description"
                     :label="_.get(fields,'fields.description.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('description')"
                     :error-message="_.get(errors,'description')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.predmet.show')">
            <q-input v-model="publication.predmet"
                     :label="_.get(fields,'fields.predmet.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('predmet')"
                     :error-message="_.get(errors,'predmet')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.events.show')">
            <q-field v-model="publication.events"
                     :label="_.get(fields,'fields.events.label')"
                     stack-label
                     :error="errors.hasOwnProperty('event_id')"
                     :error-message="_.get(errors,'event_id')">
                <template v-slot:control>
                    <div class="items-center">
                        {{ _.get(publication, 'event.name') }}
                    </div>
                </template>
                <template v-slot:append>
                    <q-btn :label="$translate('buttons.select')"
                           icon="sym_o_trackpad_input"
                           dense size="sm"
                           @click="openDialogEvents"/>
                    <q-dialog v-model="dialogEvents">
                        <EventsDialog @onSelect="setEvents"/>
                    </q-dialog>
                </template>
            </q-field>
        </q-card-section>

        <FieldsRendering :fields="_.get(fields,'fields_additional')"
                         v-model="publication.common_fields"
                         :errors="errors"
                         error-prefix="common_fields."/>
    </Card>
</template>

<script setup>
/**************************************************
 *
 *  Пресса - основная информация
 *
 ***************************************************/

import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";
import {Events as EventsDialog} from "@/Components/ReportMaterialForm/Dialog";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object}
})
const publication = defineModel('publication', {type: Object})
const errors = defineModel('errors', {type: Object})

const dialogEvents = ref(false)

const openDialogEvents = () => {
    dialogEvents.value = true
}
const setEvents = (data) => {
    publication.value.event = data.events
    publication.value.event_id = data.events.id
    dialogEvents.value = false
}
</script>