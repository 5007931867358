<template>
    <Card :title="$translate('project.view.ssc-researches')"
          no-card-section
          class="q-mb-md"
          :hide-actions="!project.can.departments.add || printable">
        <q-list separator>
            <template v-for="(department) in _.sortBy(project.sscresearches,'id')">
                <ProjectDepartmentsItem :project="project"
                                        :department="department"
                                        :printable="printable"/>
            </template>
        </q-list>
        <template v-slot:actions>
            <q-btn icon="add"
                   :label="$translate('project.actions.add-department')"
                   @click="openDeparmentDialog"
                   no-caps dense
                   color="teal-1"
                   text-color="teal-9"
                   class="q-ml-none q-mr-auto"/>
        </template>
        <q-dialog v-model="departmentAddDialog" no-backdrop-dismiss no-esc-dismiss>
            <Card :title="$translate('project.view.ssc-request.add-form.title')"
                  in-modal show-close-button no-card-section header-toolbar>
                <Alert variant="warning"
                       v-if="_.includes(_.map(RIMS_PROJECT_REVALIDATE_DEPARTMENT,(n)=>Number(n)),project.type_id)"
                       :html-message="$translate('Внимание!<br/>Добавление нового подразделения в проект, потребует повторного согласования выполнения работ от руководителей ранее добавленных подразделений.')"/>

                <q-item>
                    <q-item-section>
                        <q-select :label="$translate('project.view.ssc-request.add-form.select-ssc')"
                                  v-model="selectedDepartment"
                                  :options="departmentsOptions"
                                  option-label="name"
                                  behavior="dialog"
                                  options-dense
                                  :loading="loadingDepartments">
                            <template v-slot:option="scope">
                                <q-item v-bind="scope.itemProps" class="border-bottom q-py-sm">
                                    <q-item-section>
                                        <q-item-label>{{ scope.opt.name }}</q-item-label>
                                        <q-item-label caption>{{ scope.opt.description }}</q-item-label>
                                        <q-item-label caption class="text-negative" v-if="scope.opt.disable">{{ $translate('Подразделение уже добавлено в проект') }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-select>
                        <div class="text-negative q-pa-xs"
                             v-if="departmentAddForm.errors.hasOwnProperty('ssc_id')">
                            {{ _.get(departmentAddForm.errors, 'ssc_id') }}
                        </div>
                    </q-item-section>
                </q-item>
                <div v-if="selectedDepartment">
                    <q-item v-if="selectedDepartment.description">
                        <q-item-section>
                            <q-item-label caption>{{ selectedDepartment.description }}</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item v-if="!selectedDepartment.unlimited">
                        <q-item-section>
                            <q-input type="number" min="0"
                                     v-model="departmentAddForm.count"
                                     :prefix="$translate('project.view.ssc-count-description')+':'"
                                     stack-label
                                     input-class="text-center"
                                     :error="departmentAddForm.errors.hasOwnProperty('count')"
                                     :error-message="_.get(departmentAddForm.errors,'count')"></q-input>
                        </q-item-section>
                    </q-item>

                    <q-item v-if="selectedDepartment.ssc_show_work_description">
                        <q-item-section>
                            <q-field :label="$translate('project.view.ssc-work-description')" stack-label>
                                <template v-slot:control>
                                    <div class="self-stretch full-width">
                                        <q-editor flat v-model="departmentAddForm.description"/>
                                    </div>
                                </template>
                            </q-field>
                            <div class="text-negative q-pa-xs"
                                 v-if="departmentAddForm.errors.hasOwnProperty('description')">
                                {{ _.get(departmentAddForm.errors, 'description') }}
                            </div>
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-field
                                    v-if="(selectedDepartment.project_reglament || selectedDepartment.hasOwnProperty('documents') && selectedDepartment.documents.length > 0)"
                                    borderless>
                                <template v-slot:control>
                                    <div class="self-stretch full-width q-gutter-y-md q-py-md">
                                        <div class="text-italic"
                                             v-html="_.get(selectedDepartment, 'project_reglament')"></div>

                                        <template v-for="(document) in selectedDepartment.documents">
                                            <div>
                                                <a :href="document.file_path" target="_blank">
                                                    <q-icon name="attach_file" size="xs" left/>
                                                    <span>{{ document.name }}</span>
                                                    <span class="q-ml-sm">
                                                        [{{ document.file_name }}, {{ document.file_size }}]
                                                    </span>
                                                </a>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </q-field>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-checkbox v-model="departmentAddForm.agreed">
                            <span
                                    v-if="(selectedDepartment.project_reglament || selectedDepartment.project_reglament_root)
                                && selectedDepartment.hasOwnProperty('documents') && selectedDepartment.documents.length > 0">
                                {{
                                    $translate('project-form.confirm-ssc-reglament-docs', {name: selectedDepartment.name})
                                }}
                            </span>
                                <span
                                        v-else-if="(selectedDepartment.project_reglament || selectedDepartment.project_reglament_root)">
                                {{ $translate('project-form.confirm-ssc-reglament', {name: selectedDepartment.name}) }}
                            </span>
                                <span
                                        v-else-if="selectedDepartment.hasOwnProperty('documents') && selectedDepartment.documents.length > 0">
                                {{ $translate('project-form.confirm-ssc-docs', {name: selectedDepartment.name}) }}
                            </span>
                                <span v-else-if="selectedDepartment.project_reglament_root">
                                {{ $translate('project-form.confirm-ssc', {name: selectedDepartment.name}) }}
                            </span>
                                <span
                                        v-if="selectedDepartment.hasOwnProperty('documents') && selectedDepartment.documents.length > 0">
                                 <span v-for="(document) in selectedDepartment.documents">
                                     {{ document.name.length > 0 ? document.name : document.file_name }}&comma;&nbsp;
                                 </span>
                            </span>
                            </q-checkbox>
                            <div class="text-negative q-pa-xs"
                                 v-if="departmentAddForm.errors.hasOwnProperty('agreed')">
                                {{ _.get(departmentAddForm.errors, 'agreed') }}
                            </div>
                        </q-item-section>
                    </q-item>
                </div>
                <template v-slot:actions>
                    <q-btn icon="o_save"
                           :label="$translate('buttons.add')"
                           @click="departmentAddFormSubmit"
                           color="positive"/>
                </template>
            </Card>
        </q-dialog>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from 'lodash';
import axios from "axios";

import ProjectDepartmentsItem from "@/Components/Project/Card/DepartmentsItem.vue";
import {Alert, Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    project: {
        type: Object,
        required: true
    },
    printable: {
        type: Boolean,
        default: false
    }
})

const unlimited = computed(() => usePage().props.config.unlimited);
const locale = computed(() => usePage().props.locale);

const departmentAddDialog = ref(false)
const selectedDepartment = ref(null)

const departmentAddForm = useForm({
    ssc_id: null,
    count: null,
    description: null,
    agreed: false
})

const departmentsOptions = ref([])

const RIMS_PROJECT_REVALIDATE_DEPARTMENT = computed(() => usePage().props.settings.RIMS_PROJECT_REVALIDATE_DEPARTMENT)

const root_reglament = computed(() => {
    return _.join(_.uniq(_.values(_.mapValues(selectedDepartment.value, 'project_reglament_root'))), "<br/>");
})
const loadingDepartments = ref(false)
const loadDepartments = () => {
    if (_.isEmpty(departmentsOptions.value)) {
        loadingDepartments.value = true;
        axios
                .get(route('ajax.ssc', {m: 'rcToProject'}))
                .then((response) => {
                    departmentsOptions.value = _.map(response.data.results, (item) => ({
                        ...item,
                        disable: (_.findIndex(props.project.sscresearches, {ssc_id: item.id}) >= 0)
                    }))
                })
                .finally(() => {
                    loadingDepartments.value = false;
                })
    }
}
const openDeparmentDialog = () => {
    loadDepartments()
    selectedDepartment.value = null;
    departmentAddForm.reset()
    departmentAddDialog.value = true;
}
const departmentAddFormSubmit = () => {
    departmentAddForm
            .transform((data) => ({
                ...data,
                ssc_id: selectedDepartment.value.id
            }))
            .post(route('project.request.add', props.project), {
                onSuccess: () => {
                    departmentAddDialog.value = false
                    departmentAddForm.reset()
                    router.reload()
                }
            })
}
</script>