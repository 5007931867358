<template>
    <q-input v-model="document.name"
             :label="$translate('Наименование документа')"/>

    <q-file v-model="document.file"
            :label="$translate('Файл')">
        <template v-slot:prepend>
            <q-icon name="attach_file"/>
        </template>
    </q-file>

    <q-select v-model="document.type"
              :label="$translate('Тип файла/документа')"
              :options="documentType"
              @update:modelValue="(val)=> {document.type_id=val.id}"
              options-dense/>

    <q-select v-model="document.version"
              :label="$translate('Версия файла/документа')"
              :options="documentVersion"
              map-options
              emit-value
              options-dense/>

    <q-select v-model="document.access"
              :label="$translate('Доступ к электронной версии')"
              :options="documentAccess"
              map-options
              emit-value
              options-dense/>

    <q-input v-model="document.from"
             v-if="document.access ==='open'"
             type="date"
             :label="$translate('Дата доступа')"/>

    <div class="row q-gutter-x-md items-center" v-if="document.access ==='embargo'">
        <div class="col-auto">
            {{ $translate('Период эмбарго') }}
        </div>
        <q-input v-model="document.from"
                 type="date"
                 class="col-auto"
                 input-class="text-center"
                 :prefix="$translate('buttons.from')"/>
        <q-input v-model="document.to"
                 type="date"
                 class="col-auto"
                 input-class="text-center"
                 :prefix="$translate('buttons.to')"/>
    </div>

    <q-select v-model="document.license"
              :label="$translate('Лицензия')"
              :options="documentLicense"
              map-options
              emit-value
              options-dense/>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import ClassificationDB from "@/plugins/ClassificationDB";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const document = defineModel({
    type: Object,
    default() {
        return {
            name: null,
            file: null,
            type_id: null,
            type: null,
            version: null,
            access: null,
            license: null,
            from: null,
            to: null
        }
    }
})

const documentType = ref([])

const documentVersion = computed(() => {
    return _.map($translate('report-type.document-version'), (v, k) => ({value: k, label: v})) || []
})
const documentAccess = computed(() => {
    return _.map($translate('report-type.document-access'), (v, k) => ({value: k, label: v})) || []
})
const documentLicense = computed(() => {
    return _.map($translate('common.license'), (v, k) => ({value: k, label: v})) || []
})
onMounted(() => {
    ClassificationDB
        .getSimpleList('documenttype')
        .then((r) => {
            documentType.value = r
        })
})
</script>