<template>
    <Card title-bg="deep-purple-2"
          :title="$translate('module-sensors.sensors.form.title.create')"
          actions-align="center"
          header-toolbar
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('module-sensors.sensors.form.actions.back')"
                   @click="router.get($route('services.sensors.list'))"
                   icon="o_chevron_left"
                   color="deep-purple-5"
                   dense
            />
        </template>
        <SensorForm v-model="form" :sensor-groups="sensorGroups" :sensor-types="sensorTypes" :ssc="ssc"/>
        <template v-slot:actions>
            <q-btn :label="$translate('module-sensors.sensors.form.actions.add')"
                   @click="submitForm"
                   color="positive"
            />
        </template>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {Form as SensorForm} from "@/Components/Sensors/Sensor";
import {getCurrentInstance} from "vue";
import {router, useForm} from "@inertiajs/vue3";

const props = defineProps({
    sensorGroups: Array,
    sensorTypes: Array,
    ssc: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    name: '',
    period: 1,
    timeout: null,
    sensor_group_id: null,
    sensor_type_id: null,
    connection_data: {
        id: null,
        ip: ''
    },
    enabled: true,
    sensor_location: {
        address: '',
        detail: null,
        floor: '',
        room: '',
    },
    ssc_id: null,
    sensor_users: null
});

const submitForm = () => {
    form.post(route('services.sensors.store'), {
        onSuccess: () => router.visit(route('services.sensors.list'))
    });
};
</script>
