<template>
    <q-btn icon="o_edit"
           :label="$translate('buttons.change')"
           size="sm"
           class="q-ml-md"
           @click="dialog = true"
           dense/>
    <q-dialog v-model="dialog"
              no-backdrop-dismiss>
        <Card :title="$translate('research.form.title.edit-assay')"
              show-close-button>
            <q-input v-model="form.assay"
                     :prefix="$translate('research.form.new-assay-count')+':'"
                     input-class="text-center"
                     v-bind="assayLimit"
                     borderless
                     :error="form.errors.hasOwnProperty('assay')"
                     :error-message="_.get(form.errors,'assay')"
            />
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="submitForm"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, ref, computed} from "vue";
import {Card} from "@/Components/Block";
import {useForm} from "@inertiajs/vue3";
import _ from "lodash";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    research: {type: Object, required: true}
})

const emit = defineEmits(['onSuccess'])

const dialog = ref(false)

const form = useForm({
    assay: props.research.assay
})

const assayLimit = computed(() => {
    let _min = _.get(props.research.template, 'assay_limit.min')
    let _max = _.get(props.research.template, 'assay_limit.max')
    return {min: _min, max: _max}
})
const submitForm = () => {
    form.patch(route('project.research.update-assay', props.research), {
        onSuccess: () => {
            dialog.value = false
            emit('onSuccess')
        }
    })
}
</script>