<template>
    <UserLayout :title="$translate('users.title-export')">
        <ExportData
                :modelFields="modelFields"
                :additionalFields="additionalFields"
                :route="$route('admin.users.export.execute')"/>
    </UserLayout>
</template>

<script setup lang="ts">

import {getCurrentInstance} from "vue";
import UserLayout from "@/Pages/Admin/Users/UserLayout.vue";
import {ExportData} from "@/Components/Exchange";

interface Props {
    modelFields: [],
    additionalFields?: []
}

defineProps<Props>()

const app = getCurrentInstance();
const $route = app!.appContext.config.globalProperties.$route;
const $translate = app!.appContext.config.globalProperties.$translate

</script>