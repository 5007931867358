<template>
    <Card :title="$translate('Видимость объекта')"
          hide-actions>
        <SearchObjectVisibility @selectItem="selectItem"
                                :error="_.get(errors,'visibility_id')"/>
    </Card>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import {ObjectVisibility as SearchObjectVisibility} from "@/Components/Search";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const props = defineProps({
    fields: {type: Object},
    // errors: {type: Object},
})

const visibility = defineModel('visibility', {})
const errors = defineModel('errors', {type: Object})

const selectItem = (selected) => {
    visibility.value = selected.id
}
</script>