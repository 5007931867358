<template>
    <Card :title="$translate('equipment.item.tabs.consumables')" hide-actions no-card-section>
        <q-table :rows="equipment.consumables" :columns="consumablesCols"
                 row-key="consumable.id"
                 flat bordered :rows-per-page-options="[0]"
                 square
                 hide-pagination hide-no-data>
            <template v-slot:top-row v-if="equipment.permission.edit">
                <q-tr>
                    <q-td colspan="100%">
                        <q-btn icon="add" dense :label="$translate('buttons.add')" @click="openConsumableDialog" no-caps/>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props">
                    <DataTableActions :actions="props.row.actions">
<!--                        <q-item clickable v-if="!props.row.index" @click="deleteConsumable(props.rowIndex)">-->
<!--                            <q-item-section>-->
<!--                                <q-item-label>-->
<!--                                    {{ $translate('buttons.delete') }}-->
<!--                                </q-item-label>-->
<!--                            </q-item-section>-->
<!--                            <q-item-section avatar>-->
<!--                                <q-icon name="o_delete" size="xs"/>-->
<!--                            </q-item-section>-->
<!--                        </q-item>-->
                    </DataTableActions>
                </q-td>
            </template>
            <template v-slot:body-cell-material="props">
                <q-td :props="props">
                    {{ props.row.consumable.name }}
                </q-td>
            </template>
            <template v-slot:body-cell-consumption="props">
                <q-td :props="props">
                    {{ props.row.consumption_count }}
                    {{ props.row.consumption_unit_value }}
                    /
                    {{ props.row.time_count }}
                    {{ props.row.time_unit_value }}

                    <q-icon name="replay" v-if="props.row.reusable"
                            :title="$translate('equipment.item.fields.material-reusable')"/>
                </q-td>
            </template>
        </q-table>
    </Card>

    <q-dialog v-model="addConsumableDialog" no-backdrop-dismiss>
        <q-card style="width: 700px;max-width: 80vw">
            <q-bar>
                <div>Добавление</div>
                <q-space/>
                <q-btn flat dense icon="close" v-close-popup/>
            </q-bar>
            <q-card-section>
                <q-item-label>{{ $translate('equipment.item.fields.material-consumable') }}</q-item-label>
                <q-select v-model="consumablesItem.consumable"
                          :options="consumablesList" options-dense option-label="name"
                          dense>
                </q-select>
            </q-card-section>
            <q-card-section v-if="consumablesItem.consumable">
                <q-item-label>{{ $translate('equipment.item.fields.material-consumption') }}</q-item-label>
                <div class="row q-gutter-x-md">
                    <q-input v-model="consumablesItem.count"
                             :label="$translate('equipment.item.fields.material-consumption-count')" stack-label
                             class="col" dense/>
                    <q-select v-model="consumablesItem.unit"
                              :label="$translate('equipment.item.fields.material-consumption-unit')" stack-label
                              :options="consumablesItem.consumable.transform" options-dense option-label="name"
                              class="col" dense>
                    </q-select>
                </div>
            </q-card-section>
            <q-card-section v-if="consumablesItem.consumable">
                <q-item-label>{{ $translate('equipment.item.fields.material-time') }}</q-item-label>
                <div class="row q-gutter-x-md q-mb-sm">
                    <q-input v-model="consumablesItem.time_count"
                             :label="$translate('equipment.item.fields.material-time-count')" stack-label
                             class="col" dense/>
                    <q-select v-model="consumablesItem.time_unit"
                              :label="$translate('equipment.item.fields.material-time-unit')" stack-label
                              :options="timeUnits" options-dense option-label="label"
                              class="col" dense>
                    </q-select>
                </div>
                <q-item-label caption>Период времени за которое расходуется указанное количество материала
                </q-item-label>
            </q-card-section>
            <q-card-section v-if="consumablesItem.consumable">
                <q-checkbox :label="$translate('equipment.item.fields.material-reusable')"
                            v-model="consumablesItem.reusable" dense/>
                <q-item-label caption>Не уменьшать остаток, списывать только потери указанные в РМ</q-item-label>
            </q-card-section>
            <q-separator/>
            <q-card-actions align="center" v-if="consumablesItem.consumable">
                <q-btn color="positive" icon="save" :label="$translate('buttons.add')" @click="addConsumable"/>
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import axios from "axios";
import _ from "lodash"
import DataTableActions from "@/Components/DataTableActions.vue";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    equipment: {
        type: Object
    }
})

const timeUnits = computed(() => usePage().props.options.timeUnits)

const consumablesCols = [
    {name: "actions", label: '', style: "width: 2rem",},
    {name: "material", label: $translate('equipment.item.fields.material-consumable'), align: 'left'},
    {name: "consumption", label: $translate('equipment.item.fields.material-consumption'), align: 'left'},
]

const consumablesItem = ref({
    consumable: null,
    count: 0,
    unit: null,
    time_count: null,
    time_unit: null,
    reusable: false,
})

const consumablesList = ref([])

const form = useForm({})
const loadConsumable = () => {
    if (consumablesList.value.length > 0) {
        return
    }
    axios.get('/consumables.json', {params: {'m': 'selectForEquipment', 'ssc': props.equipment.ssc_id}})
        .then((response) => {
            consumablesList.value = response.data.results
        })
}


const addConsumableDialog = ref(false)
const openConsumableDialog = () => {
    addConsumableDialog.value = true
}
const addConsumable = () => {
    let item = {};

    if (!consumablesItem.value.unit.hasOwnProperty('id')
        && !consumablesItem.value.consumable.hasOwnProperty('id')
        && !consumablesItem.value.time_unit.hasOwnProperty('label')) {
        return;
    }

    _.set(item, 'equipment_id', props.equipment.id);
    _.set(item, 'consumable_id', consumablesItem.value.consumable.id)
    _.set(item, 'consumption_count', consumablesItem.value.count)
    _.set(item, 'consumption_unit', consumablesItem.value.unit.id)
    _.set(item, 'time_count', consumablesItem.value.time_count)
    _.set(item, 'time_unit', consumablesItem.value.time_unit.value)
    _.set(item, 'reusable', consumablesItem.value.reusable)

    form.transform((data) => ({
        ...data,
        ...item
    }))
        .post(route('equipment.consumable.create', props.equipment), {
            onSuccess: () => {
                router.reload()
                addConsumableDialog.value = false;
            }
        })
    // props.equipment.consumables.push(item);
    // addConsumableDialog.value = false
    // consumablesItem.value = {
    //     consumable: null,
    //     count: 0,
    //     unit: null,
    //     time_count: null,
    //     time_unit: null,
    //     reusable: false,
    // }
}

const deleteConsumable = (index) => {
    props.equipment.consumables.splice(index, 1)
}
onMounted(() => {
    loadConsumable()
})
</script>
