<template>
    <q-item clickable @click="openDialog">
        <q-item-section>
            <q-item-label class="text-no-wrap">{{ $translate('cadre-department.actions.vacation-chart') }}</q-item-label>
        </q-item-section>
    </q-item>
    <q-dialog full-height full-width v-model="dialogModel" @show="chartHeight++" @hide="chartHeight--">
        <Card :title="$translate('cadre-department.actions.vacation-chart')"
              show-close-button
              no-card-section
              header-toolbar
              hide-actions
              in-modal
        >
            <Chart v-if="!_.isEmpty(chartData)"
                   chart-type="gantt-date"
                   :data="chartData"
                   :config="chartConfig"
                   :height="chartHeight + 'px'"
            />
        </Card>
    </q-dialog>
</template>

<script setup lang="ts">
import {ComponentInternalInstance} from "@vue/runtime-core";
import {computed, ComputedRef, getCurrentInstance, ref, Ref} from "vue";
import moment from "moment/moment";
import {set, toValue} from "@vueuse/core";
import _ from "lodash";
import Chart from "@/Components/Chart.vue";
import {Card} from "@/Components/Block";
import {IGanttDateChartConfig} from "@/entity/charts";
import axios from "axios";
import {AnyValuesObject, StringValuesObject} from "@/entity/types";

const { departmentId } = defineProps<{departmentId: number}>();

const app: ComponentInternalInstance | null = getCurrentInstance();
const $translate: Function = app!.appContext.config.globalProperties.$translate;
const $route: Function = app!.appContext.config.globalProperties.$route;

const chartData: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);
const chartConfig: ComputedRef<IGanttDateChartConfig> = computed<IGanttDateChartConfig>(() => ({
    chartSettings: {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
    },
    chartWidgets: {
        cursor: { behavior: "zoomX" },
    },
    categoryYAxis: {
        categoryField: "fulledName",
        categories: [],
        rendererSettings: {
            minGridDistance: 30
        },
        rendererGridTemplateLocation: 1
    },
    dateXAxis: {
        baseInterval: { timeUnit: "day", count: 1 },
        gridIntervals: [
            { timeUnit: "day", count: 1 },
            { timeUnit: "week", count: 1 },
            { timeUnit: "month", count: 1 },
        ],
        additionalSettings: {
            min: moment().startOf("year").toDate().getTime(),
            max: moment().endOf("year").toDate().getTime(),
            strictMinMax: true,
            tooltipDateFormat: "dd.MM.yyyy"
        },
        rendererSettings: {
            strokeOpacity: 0.1
        }
    },
    columnSeries: {
        openValueXField: "start",
        valueXField: "end",
        categoryYField: "fulledName",
        tooltipText: "{typeText} ({statusText}):\n[bold]{openValueX.formatDate('dd.MM.yyyy')}[/] - [bold]{valueX.formatDate('dd.MM.yyyy')}[/] ({cDays} дней)\n{comment}",
        dataFieldToProcess: "vacations",
        dateFields: ["start", "end"],
        dateFormat: "yyyy-MM-dd",
    },
    customColorLogic: {
        fieldName: "type",
        fieldValues: ["payment", "own", "other"]
    }
}));

const chartHeight: Ref<number> = ref<number>(0);

interface IStaffVacation {
    id: number,
    staffId: number,
    category: string,
    fulledName: string,
    start: string,
    end: string,
    type: string,
    typeText: string,
    statusText: string,
    comment: string,
    cDays: number,
    [p: string]: any
}

const fetchData: Function = () => {
    chartData.value = [];
    chartConfig.value.categoryYAxis.categories = [];
    axios.get($route("ajax.cadre.vacation", {m: "chartVacations", department: departmentId, year: moment().year()}))
        .then((response) => {
            let categories: StringValuesObject[] = [], data: AnyValuesObject[] = [];
            _.forEach(response.data.results, (staffVacations: IStaffVacation[]) => {
                let firstStaffVacation: IStaffVacation = staffVacations[0],
                    processedData: AnyValuesObject = {
                        [chartConfig.value.categoryYAxis.categoryField]: firstStaffVacation[chartConfig.value.categoryYAxis.categoryField],
                        [chartConfig.value.columnSeries.dataFieldToProcess]: []
                    };
                _.forEach(staffVacations, (staffVacation: IStaffVacation) => {
                    categories.push({
                        [chartConfig.value.categoryYAxis.categoryField]: staffVacation[chartConfig.value.categoryYAxis.categoryField]
                    });
                    if (!_.isNull(staffVacation[chartConfig.value.columnSeries.openValueXField]) && !_.isNull(staffVacation[chartConfig.value.columnSeries.valueXField])) {
                        processedData[chartConfig.value.columnSeries.dataFieldToProcess].push({
                            [chartConfig.value.columnSeries.openValueXField]: staffVacation[chartConfig.value.columnSeries.openValueXField],
                            [chartConfig.value.columnSeries.valueXField]: staffVacation[chartConfig.value.columnSeries.valueXField],
                            [chartConfig.value.columnSeries.categoryYField]: staffVacation[chartConfig.value.columnSeries.categoryYField],
                            type: staffVacation.type,
                            typeText: staffVacation.typeText,
                            statusText: staffVacation.statusText,
                            cDays: staffVacation.cDays,
                            comment: staffVacation.comment
                        });
                    }
                });
                data.push(processedData);
            });
            chartData.value = data;
            chartHeight.value = 32 * _.size(categories);
            if (toValue(chartHeight) < 250) {
                chartHeight.value = 250;
            }
            chartConfig.value.categoryYAxis.categories = _.reverse(
                _.sortBy(
                    _.uniqBy(categories, chartConfig.value.categoryYAxis.categoryField),
                    chartConfig.value.categoryYAxis.categoryField
                )
            );
        })
        .catch((e) => {
            console.error(e);
        });
};

const dialogModel: Ref<boolean> = ref<boolean>(false);
const openDialog = () => {
    set(dialogModel, true);
    fetchData();
};
</script>
