<template>
    <q-btn v-if="_.size(actions)>0 || $slots.default" flat dense no-caps no-wrap icon-right="menu">
        <q-tooltip class="text-body2" v-if="title">{{ title }}</q-tooltip>
        <q-menu>
            <q-list dense>
                <template v-for="(action,p) in actions">
                    <q-item v-if="!action.hidden"
                            :href="action.action"
                            clickable
                            @click.prevent="actionClick(action)"
                            v-close-popup
                            >
                        <q-item-section>
                            <q-item-label v-html="action.label"/>
                        </q-item-section>
                        <q-item-section avatar>
                            <q-icon :name="action.icon" size="xs"/>
                        </q-item-section>
                    </q-item>
                </template>
                <slot name="default"/>
            </q-list>
        </q-menu>
    </q-btn>
</template>

<script setup lang="ts">
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import {useQuasar} from "quasar";
import {getCurrentInstance} from "vue";
import {DataTableAction} from "@/entity";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app!.appContext.config.globalProperties.$translate

type TableAction = { [key: string]: DataTableAction };

interface Props {
    actions?: TableAction,
    name?: string | null,
    title?: string | null,
}

const props = defineProps<Props>()

const emit = defineEmits(['onSuccess'])

const actionClick = (action: DataTableAction) => {
    let data = {}
    if (action.action) {
        if (action.hasOwnProperty('fields')) {
            _.each(action.fields, (field) => {
                _.set(data, field.name, field.value)
            })
        }
        if (action.method === 'delete') {
            $q.dialog({
                title: $translate('delete-dialog.title'),
                message: $translate('delete-dialog.message', {name: props.name}),
                cancel: {
                    color: 'negative',
                    noCaps: true,
                    label: $translate('delete-dialog.action.cancel')
                },
                ok: {
                    color: 'positive',
                    noCaps: true,
                    label: $translate('delete-dialog.action.confirm')
                },
                persistent: true
            }).onOk(() => {
                router.delete(action.action, {
                    onSuccess: () => {
                        emit('onSuccess')
                    }
                })
            })
        } else {
            if (action.action instanceof Function) {
                action.action()
            } else {
                router.visit(action.action,
                        {
                            method: _.get(action, 'method', 'get'),
                            data: data,
                            onFinish: () => {
                                emit('onSuccess')
                            }
                        }
                )
            }
        }
    }
}
</script>
