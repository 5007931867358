<template>
    <div v-if="data.module.syexc.enabled">
        <Alert v-if="$translate('module-syexc.description')"
               variant="info"
               :message="$translate('module-syexc.description')"
        />
        <SettingsTable :data="config"/>
    </div>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const config = [
    {
        name: 'SYEXC_ENABLED',
        label: $translate('module-syexc.settings.SYEXC_ENABLED'),
        value: props.settings.module.SYEXC_ENABLED,
        description: $translate('module-syexc.settings.SYEXC_ENABLED_description'),
        block: 'module',
        type: 'checkbox',
    },
];
</script>
