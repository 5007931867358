<script setup>
import {getCurrentInstance, onMounted, ref, reactive} from 'vue'
import {set, get, useArrayFilter} from '@vueuse/core'
import {useQuasar} from "quasar";
import SearchAddress from "@/Components/Search/SearchAddress.vue";
import SearchUser from "@/Components/Search/SearchUser.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();

const member = defineModel({
    default() {
        return {
            lastname: null,
            name: null,
            surname: null,
            position: null,
            department: null,
            passport: {code: null, date: null, issued: null, number: null, seria: null},
            address: {
                block: null,
                city: null,
                country: null,
                flat: null,
                house: null,
                postal_code: null,
                street: null,
            },
            contact: {
                phone: null,
                email: null
            }
        }
    }
})

const selectUser = (user) => {
    set(member.value, 'user_id', get(user.user, 'user_id') || null)
    set(member.value, 'lastname', get(user.user, 'last_name') || null)
    set(member.value, 'name', get(user.user, 'first_name') || null)
    set(member.value, 'surname', get(user.user, 'middle_name') || null)
    set(member.value, 'birthdate', get(user.user, 'birthdate') || null)
    set(member.value.contact, 'email', get(user.user, 'email') || null)
    set(member.value, 'position', get(get(useArrayFilter(user.user.additional_all, (item) => item.slug === 'title'), 0), 'value') || null)
    set(member.value, 'department', get(get(useArrayFilter(user.user.additional_all, (item) => item.slug === 'department'), 0), 'value') || null)
}
const selectAddress = (address) => {
    set(member.value, 'address', address.data)
}
</script>

<template>
    <div class="q-px-md">
        <SearchUser with-ldap @onSelect="selectUser"/>
    </div>
    <q-card-section>
        <div class="bg-grey-3 q-pa-xs">
            {{ $translate('module-rid.fields.members.fio') }}
        </div>

        <div class="flex column full-width">
            <q-input v-model="member.lastname"
                     :label="$translate('module-rid.fields.members.lastname')"
                     dense/>
            <q-input v-model="member.name"
                     :label="$translate('module-rid.fields.members.name')"
                     dense/>
            <q-input v-model="member.surname"
                     :label="$translate('module-rid.fields.members.surname')"
                     dense/>
        </div>
        <div class="flex row full-width">
            <q-input v-model="member.birthdate"
                     :label="$translate('module-rid.fields.members.birthdate')"
                     dense
                     class="col-6"/>
        </div>
        <div class="flex items-center q-gutter-x-sm q-mt-md">
            <q-input v-model="member.position"
                     :label="$translate('module-rid.fields.members.position')"
                     dense
                     class="col"/>
            <q-input v-model="member.department"
                     :label="$translate('module-rid.fields.members.department')"
                     dense
                     class="col"/>
        </div>
        <div class="flex row q-gutter-x-sm q-mt-md">
            <q-input v-model="member.contact.phone" :label="$translate('module-rid.fields.members.contact-phone')"
                     dense
                     class="col"/>
            <q-input v-model="member.contact.email" :label="$translate('module-rid.fields.members.contact-email')"
                     dense
                     class="col"/>
        </div>
    </q-card-section>
    <q-card-section>
        <div class="bg-grey-3 q-pa-xs">
            {{ $translate('module-rid.fields.members.passport') }}
        </div>
        <div class="flex row q-gutter-x-sm q-mt-md">
            <q-input v-model="member.passport.seria"
                     :label="$translate('module-rid.fields.members.passport-seria')"
                     dense
                     class="col"/>
            <q-input v-model="member.passport.number"
                     :label="$translate('module-rid.fields.members.passport-number')"
                     dense
                     class="col"/>
            <q-input v-model="member.passport.date"
                     :label="$translate('module-rid.fields.members.passport-date')"
                     dense
                     class="col"/>
            <q-input v-model="member.passport.code"
                     :label="$translate('module-rid.fields.members.passport-kod')"
                     dense
                     class="col"/>
        </div>
        <div class="flex row q-gutter-x-sm q-mt-md">
            <q-input v-model="member.passport.issued"
                     :label="$translate('module-rid.fields.members.passport-issued')"
                     dense
                     class="col"/>
        </div>
    </q-card-section>
    <q-card-section>
        <div class="bg-grey-3 q-pa-xs">
            {{ $translate('module-rid.fields.members.address') }}
        </div>
        <SearchAddress @onSelect="selectAddress"/>
        <div class="col-24 text-caption">или введите свой:</div>
        <div class="row q-gutter-x-sm">
            <q-input v-model="member.address.postal_code"
                     :label="$translate('module-rid.fields.members.address-index')"
                     dense
                     class="col-4"/>
            <q-input v-model="member.address.country"
                     :label="$translate('module-rid.fields.members.address-country')"
                     dense
                     class="col-8"/>
            <q-input v-model="member.address.city"
                     :label="$translate('module-rid.fields.members.address-city')"
                     dense
                     class="col"/>
        </div>
        <div class="row q-gutter-x-sm">
            <q-input v-model="member.address.street"
                     :label="$translate('module-rid.fields.members.address-street')"
                     dense
                     class="col"/>
            <q-input v-model="member.address.house"
                     :label="$translate('module-rid.fields.members.address-house')"
                     dense
                     class="col-3"/>
            <q-input v-model="member.address.block"
                     :label="$translate('module-rid.fields.members.address-block')"
                     dense
                     class="col-3"/>
            <q-input v-model="member.address.flat"
                     :label="$translate('module-rid.fields.members.address-flat')"
                     dense
                     class="col-3"/>
        </div>
    </q-card-section>

</template>