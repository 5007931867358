<template>
    <Card title-bg="indigo-1" :title="title" actions-align="center" header-toolbar no-card-section>
        <template v-slot:headerActions>
            <q-btn v-if="_.get(can, 'index') || _.get(can, 'detail')"
                   :label="$translate('buttons.back')"
                   @click="router.get(backRoute)"
                   icon="o_chevron_left"
                   dense no-wrap
                   color="indigo-3"
            />
        </template>
        <template v-slot:default>
            <q-card-section>
                <template v-if="consumable.id">
                    <q-field :label="$translate('consumables.fields.catalogue')" stack-label>
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">{{ consumable.name }}</div>
                        </template>
                    </q-field>
                </template>
                <template v-else>
                    <q-select v-model="consumable.parent_id"
                              :disable="disabledUnit"
                              :options="catalogue"
                              @filter="searchConsumable"
                              :label="$translate('consumables.fields.catalogue')" stack-label
                              :hint="$translate('consumables.fields.catalogue-placeholder')"
                              input-debounce="0"
                              option-label="name"
                              option-value="id"
                              :loading="loading"
                              options-dense
                              map-options
                              emit-value
                              use-input
                    />
                    <div v-if="selectedRootConsumable" class="q-pl-sm">
                        <q-field v-if="selectedRootConsumable.cas"
                                 :prefix="$translate('consumables.fields.cas') + ': '"
                                 borderless
                                 dense
                        >
                            <template v-slot:control>
                                <div class="self-center full-width no-outline" tabindex="0">
                                    {{ selectedRootConsumable.cas }}
                                </div>
                            </template>
                        </q-field>
                        <q-field :prefix="$translate('consumables.fields.category') + ': '" borderless dense>
                            <template v-slot:control>
                                <div class="self-center full-width no-outline" tabindex="0">
                                    {{ $translate('consumables.category.' + selectedRootConsumable.category) }}
                                </div>
                            </template>
                        </q-field>
                        <q-field :prefix="$translate('consumables.fields.units') + ': '" borderless dense>
                            <template v-slot:control>
                                <div class="self-center full-width no-outline" tabindex="0">
                                    {{ _.get(selectedRootConsumable, 'unit_text') }}
                                </div>
                            </template>
                        </q-field>
                    </div>
                </template>

                <div style="width: 500px">
                    <q-input v-model.number="consumable.minimal"
                             :prefix="$translate('consumables.fields.minimal')" stack-label
                             :hint="$translate('consumables.fields.minimal-description')"
                             :step="(consumable.whole ? 1 : 0.01)"
                             :suffix="_.get(unitSelected, 'label')"
                             type="number" input-class="text-center"
                             min="0"
                    />
                </div>

                <div style="width: 500px">
                    <q-input v-model.number="consumable.loss"
                             :prefix="$translate('consumables.fields.loss')" stack-label
                             :hint="$translate('consumables.fields.loss-description')"
                             type="number" step="0.01" suffix="%"
                             max="100" min="0"
                             input-class="text-center"
                    />
                </div>
            </q-card-section>
            <q-card-section>
                <q-toggle v-model="consumable.internal" :label="$translate('consumables.fields.internal')"
                          size="sm"/>
                <q-item-label caption>{{ $translate('consumables.fields.internal-description') }}</q-item-label>
            </q-card-section>
            <q-card-section>
                <q-toggle v-model="consumable.self_usage" :label="$translate('consumables.fields.external')"
                          size="sm"/>
                <q-item-label caption>{{ $translate('consumables.fields.external-description') }}</q-item-label>
            </q-card-section>
            <q-card-section>
                <q-toggle v-model="consumable.whole" :label="$translate('consumables.fields.whole')" size="sm"/>
                <q-item-label caption>{{ $translate('consumables.fields.whole-description') }}</q-item-label>

            </q-card-section>
            <q-card-section>
                <ConsumableTransform v-if="consumable.parent_id"
                                     :consumable="consumable"
                                     :units="units"
                ></ConsumableTransform>
            </q-card-section>
        </template>
        <template v-slot:actions>
            <q-btn icon="save" :label="$translate('buttons.' + (consumable.id ? 'save' : 'add'))"
                   @click="submitForm"
                   color="positive"
            />
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import ConsumableTransform from "@/Components/Consumables/ConsumableTransform.vue";
import {computed, getCurrentInstance, ref, useAttrs} from "vue";
import {useForm, router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";

const attrs = useAttrs();
const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    units: {
        type: Array
    },
    can: {
        type: Object
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const consumable = ref({
    units: null,
    parent_id: null,
    loss: 0,
    minimal: 0,
    internal: false,
    self_usage: false,
    whole: false,
    transform: [],
    to_base: 1
});
if (attrs.hasOwnProperty('consumable')) {
    consumable.value = Object.assign({}, consumable.value, attrs.consumable);
}

const backRoute = computed(() => {
    if (props.can.detail) {
        return route('consumables.ssc.show', {consumable: consumable.value.id});
    }
    if (props.can.index) {
        return route('consumables.ssc.index');
    }
});

const catalogue = ref([]);
const loading = ref(false);
const disabledUnit = ref(false);

const unitSelected = computed(() => consumable.value.units ?
    _.find(props.units, {'value': consumable.value.units}) :
    {}
);
const selectedRootConsumable = computed(() => {
    if (consumable.value.parent_id && !consumable.value.id) {
        let selected = _.find(catalogue.value, {'id': consumable.value.parent_id});
        if (selected) {
            consumable.value.units = selected.units;
            return selected;
        }
    }
});

const searchConsumable = (search, update) => {
    if (search === '') {
        update(() => {
            catalogue.value = [];
        });
    }
    update(() => {
        catalogue.value = [];
        loading.value = true;
        axios.get(route('ajax.consumables'), {
            params: {
                m: 'catalogue',
                q: search
            }
        })
            .then((response) => {
                catalogue.value = _.values(response.data.results);
            })
            .finally(() => {
                loading.value = false;
            });
    });
};

let form = null;

const submitForm = () => {
    form = useForm(consumable.value);
    form.post(
        consumable.value.id ?
            route('consumables.ssc.update', {consumable: consumable.value.id}) :
            route('consumables.ssc.store')
    )
};
</script>
