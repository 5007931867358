<template>
    <Card title-bg="orange-1"
          :title="$translate('module-syexc.title-research-direction')"
          actions-align="left"
          no-card-section
          header-toolbar
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('module-syexc.actions.directions.add')"
                   @click="addDialog = !addDialog"
                   color="orange-4"
                   icon="o_add"
                   dense no-caps
            />
            <q-dialog v-model="addDialog" no-backdrop-dismiss>
                <DirectionForm :action="$route('services.sys-exp-ctrl.directions.store')"
                               :title="$translate('module-syexc.actions.add-direction')"
                               @on-close-modal="addDialog = !addDialog"
                               in-modal
                />
            </q-dialog>
        </template>
        <template v-slot:default>
            <q-list separator>
                <q-item v-for="(direction, i) in directions" :key="i">
                    <q-item-section>
                        <q-item-label>{{ direction.name }}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-field :label="$translate('module-syexc.roles.expert')"
                                 stack-label borderless
                                 class="col">
                            <template v-slot:control>{{ _.get(direction, 'expert.fulled_name') }}</template>
                        </q-field>
                    </q-item-section>
                    <q-item-section>
                        <q-field :label="$translate('module-syexc.roles.secretar')"
                                 stack-label borderless
                                 class="col">
                            <template v-slot:control>{{ _.get(direction, 'secretary.fulled_name') }}</template>
                        </q-field>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn @click="triggerEditDialog(i)" color="secondary" icon="o_edit" dense flat>
                            <q-tooltip class="text-body2">{{ $translate('buttons.edit') }}</q-tooltip>
                        </q-btn>
                        <q-dialog v-model="editDialogs['direction_' + i]">
                            <DirectionForm :action="$route('services.sys-exp-ctrl.directions.update', {
                                              direction: direction.id
                                           })"
                                           :title="$translate('module-syexc.actions.edit-direction')"
                                           @on-close-modal="triggerEditDialog(i)"
                                           :direction="direction"
                                           in-modal
                            ></DirectionForm>
                        </q-dialog>
                    </q-item-section>
                </q-item>
            </q-list>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import DirectionForm from "@/Components/Syexc/Direction/Form/DirectionForm.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";

const props = defineProps({
    directions: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const addDialog = ref(false);
const editDialogs = ref({});
_.forEach(props.directions, (direction, i) => {
    editDialogs.value['direction_' + i] = ref(false);
});

const triggerEditDialog = (index) => {
    editDialogs.value['direction_' + index] = !editDialogs.value['direction_' + index];
};
</script>
