<template>
    <q-list v-if="services.hasOwnProperty('sensors')">
        <NavigationItem :title="services.sensors.name"
                        :sidebar="sidebar"
                        color="deep-purple-4"
                        icon="o_sensors"
        >
            <template #items>
                <q-item @click="router.visit($route('services.sensors.dashboard'))" v-close-popup clickable>
                    <q-item-section class="q-pl-md">
                        {{ $translate('menu.sensors.dashboard') }}
                    </q-item-section>
                </q-item>
                <q-separator/>
                <q-item v-if="services.sensors.manage.sensors"
                        @click="router.visit($route('services.sensors.list'))"
                        v-close-popup
                        clickable
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate('menu.sensors.sensors') }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.sensors.manage.sensor_types"
                        @click="router.visit($route('services.sensors.sensor-types'))"
                        v-close-popup
                        clickable
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate('menu.sensors.sensor-types') }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.sensors.manage.sensor_groups"
                        @click="router.visit($route('services.sensors.sensor-groups'))"
                        v-close-popup
                        clickable
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate('menu.sensors.sensor-groups') }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.sensors.manage.measurement_types"
                        @click="router.visit($route('services.sensors.measurement-types'))"
                        v-close-popup
                        clickable
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate('menu.sensors.measurement-types') }}
                    </q-item-section>
                </q-item>

            </template>
        </NavigationItem>
    </q-list>
</template>

<script setup>
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";
import {computed, getCurrentInstance} from "vue";
import {router, usePage} from "@inertiajs/vue3";

const props = defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const page = usePage();
const services = computed(() => page.props.services);
</script>
