<template>
    <q-input v-model="form.name"
             :label="$translate('module-sensors.measurement-types.form.fields.name')"
             :error="form.errors.hasOwnProperty('name')"
             :error-message="_.get(form.errors, 'name')"
    />
    <q-select v-model="form.data_type"
              :label="$translate('module-sensors.measurement-types.form.fields.data_type')"
              :options="helpers.toOptions($translate('module-sensors.measurement-types.data-types'))"
              :error="form.errors.hasOwnProperty('data_type')"
              :error-message="_.get(form.errors, 'data_type')"
              options-dense
              map-options
              emit-value
    />
</template>

<script setup>
import {getCurrentInstance} from "vue";
import helpers from "@/helpers";
import _ from "lodash";

/**
 * @param InertiaForm form
 */
const form = defineModel({type: Object});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
