import {createApp, h} from 'vue'
import {createInertiaApp, router} from '@inertiajs/vue3'
import {createPinia} from 'pinia'
import {
    Quasar, Notify,
    LocalStorage, SessionStorage,
    Loading, QSpinnerGears,
    LoadingBar, Dialog, Cookies
} from 'quasar'
import _ from "lodash";
import axios from 'axios';

import {ZiggyVue, route} from 'ziggy-js';
//import route from "ziggy-js";

import Translator from './plugins/Translator';
import ClassificationDB from './plugins/ClassificationDB';

import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css';
import '@quasar/extras/mdi-v7/mdi-v7.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import 'quasar/src/css/index.sass';

import Layout from "./Layout/Layout.vue";

const langList = import.meta.glob('../../node_modules/quasar/lang/(en-US|ru).js')

import {Money3Directive} from 'v-money3'

const pinia = createPinia()
const notifySuccess = (message) => {
    Notify.create({
        color: 'positive', message: message, position: "top", progress: true, icon: "task_alt"
    })
}
const notifyError = (message) => {
    Notify.create({
        color: 'negative', message: message, position: "top", progress: false, icon: "error_outline"
    })
}

router.on('start', (event) => {
    Loading.show()
    console.info(`Starting a visit to ${event.detail.visit.url}`)
})
router.on('success', (event) => {
    let success = event.detail.page.props.flash.success ? (_.isArray(event.detail.page.props.flash.success) ? event.detail.page.props.flash.success : [event.detail.page.props.flash.success]) : null
    _.forEach(success, message => notifySuccess(message))
    let error = event.detail.page.props.flash.error ? (_.isArray(event.detail.page.props.flash.error) ? event.detail.page.props.flash.error : [event.detail.page.props.flash.error]) : null
    _.forEach(error, message => notifyError(message))
})
router.on('exception', (event) => {
    console.error('router exception', event)
})
router.on('error', (event) => {
    console.error('router error', event)
    _.forEach(_.get(event.detail, 'page.props.flash.error'), message => notifyError(message))
})
router.on('finish', (event) => {
    console.info(`Ending a visit to ${event.detail.visit.url}`)
    Loading.hide()
})

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            notifyError('Session expired');
            router.reload();
        }
        // Handle other errors here
        return Promise.reject(error);
    }
);

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', {eager: true})
        let page = pages[`./Pages/${name}.vue`]
        page.default.layout = page.default.layout || Layout
        return page
    }, setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})

        /**
         * @todo Надо будет добавить получение из кук/браузера когда сделаем переключение между языками
         * @type {string}
         */
        const langIso = 'ru'

        // app.config.globalProperties.$trans = Translator.install()
        app.config.globalProperties.$route = route
        // console.log(langList)
        langList[`../../node_modules/quasar/lang/${langIso}.js`]().then(lang => {
            Quasar.lang.set(lang.default)
        })

        app.use(plugin)
            .directive('money', Money3Directive)
            .use(Translator)
            .use(ClassificationDB)
            .use(pinia)
            .use(ZiggyVue)
            .use(Quasar, {
                plugins: {
                    Notify,
                    Dialog,
                    LocalStorage,
                    SessionStorage,
                    Loading,
                    LoadingBar,
                    Cookies
                }, // import Quasar plugins and add here
                config: {
                    loadingBar: {
                        color: 'deep-orange-6',
                        size: '1rem',
                        position: 'bottom'
                    },
                    loading: {
                        spinner: QSpinnerGears
                    }
                }
            })
            .mount(el)
    },
})
    .then(r => {
    })