<template>
    <template v-for="(rule,index) in fieldRules">
        <q-expansion-item
            dense
            dense-toggle
            expand-separator
            switch-toggle-side
            header-class="bg-grey-2 text-uppercase"
            :default-opened="_.size(rule.condition.items) === 0"
            :label="$translate('advanced-fields.fields.field-rule-num') + (index + 1 )">
            <div class="border-bottom">
                <div class="row">
                    <div class="col border-right">
                        <div class="bg-grey-2 q-px-md">{{ $translate('fields.label.rule-condition') }}</div>
                        <FieldConstructorRuleConditions
                            v-model="rule.condition"
                            v-bind="props"/>
                    </div>
                    <div class="col">
                        <div class="bg-grey-2 q-px-md">{{ $translate('fields.label.rule-actions') }}</div>
                        <FieldConstructorRuleActions
                            v-model="rule.result"
                            v-bind="props"/>
                    </div>
                </div>
            </div>
            <div class="q-pa-sm">
                <q-btn icon="o_delete"
                       size="sm"
                       color="negative"
                       :title="$translate('buttons.delete')"
                       :label="$translate('fields.actions.delete-rule') + ' #'+(index + 1 )"
                       @click="deleteRule(index)"
                       dense
                />
            </div>
        </q-expansion-item>
    </template>
</template>

<script setup>
import {Notify, useQuasar} from "quasar";
import {getCurrentInstance, ref, toRefs, watch} from "vue";
import _ from "lodash";
import FieldConstructorRuleConditions from "@/Components/Fields/FieldConstructorRuleConditions.vue";
import FieldConstructorRuleActions from "@/Components/Fields/FieldConstructorRuleActions.vue";

const $q = useQuasar();
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    field: {type: Object},
    fields: {type: Array}
})

const fieldRules = ref(props.field.rules)

const deleteRule = (index) => {
    _.pullAt(fieldRules.value, index)
}

</script>