import _ from "lodash";
export default {
    install(app, options) {
        app.translate = function (string, params= null) {
            let text = _.get(window.i18n, string, string);
            if (params) {
                _.each(params, (val, key) => {
                    text = text.replaceAll(':' + key, val);
                });
            }
            return text;
        }
        app.config.globalProperties.$translate = function (string, params = null) {
            let text = _.get(window.i18n, string, string);
            if (params) {
                _.each(params, (val, key) => {
                    text = text.replaceAll(':' + key, val);
                });
            }
            return text;
        }
    }
}