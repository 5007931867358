import { reactive } from 'vue'

export const store = reactive({
    state: false,
    data: null,
    setData(data) {
        this.data = null
        this.data = data
    },
    switchState() {
        this.state = !this.state
    }
})
