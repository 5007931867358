<template>
    <q-layout view="hhh lpR fFf">
        <section class="row justify-center align-center ">
            <div class="col-22 col-sm-18 col-md-10 col-xl-7 absolute-center">
                <div class="flex items-center justify-start q-ma-md">
                    <q-avatar v-if="appLogo">
                        <img :src="appLogo" :alt="appName ?? ''">
                    </q-avatar>
                    <div class="q-ml-md">
                        <q-toolbar-title v-if="serviceName">{{ serviceName }}</q-toolbar-title>
                        <div v-if="appName">{{ appName }}</div>
                    </div>
                </div>
                <q-page-container>
                    <slot/>
                </q-page-container>
            </div>
        </section>
    </q-layout>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import {usePage} from '@inertiajs/vue3';
import _ from 'lodash';

const page = usePage();
const appName = computed<string | null>(() => _.toString(_.get(page.props, 'appName')) || null);
const appLogo = computed<string | null>(() => _.toString(_.get(page.props, 'appLogo')) || null);
const serviceName = computed<string | null>(() => _.toString(_.get(page.props, 'serviceName')) || null);
</script>