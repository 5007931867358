<template>
    <Card title-bg="indigo-1" :title="consumable.name" header-toolbar no-card-section hide-actions no-shrink>
        <template v-slot:headerActions>
            <q-btn v-if="_.get(can, 'index')"
                   :label="$q.screen.lt.md?null:$translate('buttons.back')"
                   :title="$translate('buttons.back')"
                   @click="router.get($route('consumables.ssc.index'))"
                   icon="o_chevron_left"
                   dense
                   color="indigo-3"
            />
            <q-btn v-if="_.get(can, 'edit')"
                   :label="$q.screen.lt.md?null:$translate('buttons.edit')"
                   :title="$translate('buttons.edit')"
                   @click="router.get($route('consumables.ssc.edit', {consumable: consumable.id}))"
                   icon="o_edit"
                   dense
                   color="indigo-3"
            />
            <q-btn v-if="_.get(can, 'writeon')"
                   :label="$q.screen.lt.xl?null:$translate('consumables.ssc.actions.writeon')"
                   :title="$translate('consumables.ssc.actions.writeon')"
                   @click="writeonDialog = !writeonDialog"
                   icon="add"
                   dense
                   color="indigo-3"
            />
            <q-dialog v-model="writeonDialog" no-backdrop-dismiss>
                <ConsumableMovementPlus :consumable="consumable"
                                        @form-received="writeonDialog = !writeonDialog"
                />
            </q-dialog>
            <q-btn v-if="_.get(can, 'writeoff')"
                   :label="$q.screen.lt.xl?null:$translate('consumables.ssc.actions.writeoff')"
                   :title="$translate('consumables.ssc.actions.writeoff')"
                   @click="writeoffDialog = !writeoffDialog"
                   icon="remove"
                   dense
                   color="indigo-3"
            />
            <q-dialog v-model="writeoffDialog">
                <ConsumableMovementMinus :consumable="consumable"
                                         @form-received="writeoffDialog = !writeoffDialog"
                />
            </q-dialog>
        </template>
        <template v-slot:default>
            <q-tabs v-model="tab" align="left" dense>
                <q-tab name="main" :label="$translate('consumables.ssc.tab.main-info')"/>
                <q-tab name="transform" :label="$translate('consumables.ssc.tab.transform')"/>
                <q-tab name="movement" :label="$translate('consumables.ssc.tab.movement')"/>
            </q-tabs>
            <q-tab-panels v-model="tab" animated>
                <q-tab-panel name="main">
                    <q-field :label="$translate('consumables.fields.name')" stack-label>
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">
                                {{ consumable.name }}
                            </div>
                        </template>
                    </q-field>
                    <q-field :label="$translate('consumables.fields.category')" stack-label>
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">
                                {{ _.get(consumable, 'category_name') }}
                            </div>
                        </template>
                    </q-field>
                    <q-field :label="$translate('consumables.fields.cas')" stack-label>
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">
                                {{ consumable.cas }}
                            </div>
                        </template>
                    </q-field>
                    <q-field :label="$translate('consumables.fields.vendor_number')" stack-label>
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">
                                {{ consumable.vendor_number ? consumable.vendor_number : '-' }}
                            </div>
                        </template>
                    </q-field>
                    <q-field :label="$translate('consumables.fields.units')" stack-label>
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">
                                {{ _.get(consumable, 'unit.name') }}
                            </div>
                        </template>
                    </q-field>
                    <q-field :prefix="$translate('consumables.fields.minimal')"
                             :suffix="_.get(consumable, 'unit.name')"
                             style="width: 500px;"
                             stack-label
                    >
                        <template v-slot:control>
                            <div class="self-center full-width no-outline text-right" tabindex="0">
                                {{ _.get(consumable, 'minimal') }}
                            </div>
                        </template>
                    </q-field>
                    <q-field :prefix="$translate('consumables.fields.loss')"
                             suffix="%"
                             style="width: 500px;"
                    >
                        <template v-slot:control>
                            <div class="self-center full-width no-outline text-right" tabindex="0">
                                {{ _.get(consumable, 'loss') }}
                            </div>
                        </template>
                    </q-field>
                    <q-field :prefix="$translate('consumables.fields.internal')"
                             style="width: 500px;"
                    >
                        <template v-slot:append>
                            <q-icon v-if="_.get(consumable, 'internal')" name="check" color="positive"/>
                            <q-icon v-else name="clear" color="negative"/>
                        </template>
                    </q-field>
                    <q-field :prefix="$translate('consumables.fields.external')"
                             :hint="$translate('consumables.fields.external-description')"
                             style="width: 500px;"
                    >
                        <template v-slot:append>
                            <q-icon v-if="_.get(consumable, 'self_usage')" name="check" color="positive"/>
                            <q-icon v-else name="clear" color="negative"/>
                        </template>
                    </q-field>
                    <q-field :prefix="$translate('consumables.fields.whole')"
                             :hint="$translate('consumables.fields.whole-description')"
                             style="width: 500px;"
                    >
                        <template v-slot:append>
                            <q-icon v-if="_.get(consumable, 'whole')" name="check" color="positive"/>
                            <q-icon v-else name="clear" color="negative"/>
                        </template>
                    </q-field>
                    <template v-if="_.get(consumable, 'balance')">
                        <q-field
                            :prefix="$translate('consumables.fields.count') + ' (' + dateFormat(_.get(consumable, 'balance.date', _.get(consumable, 'created_at'))) + ')'"
                            :suffix="_.get(consumable, 'unit.name')"
                            style="width: 500px;"
                        >
                            <template v-slot:control>
                                <div class="self-center full-width no-outline text-right" tabindex="0">
                                    {{ helpers.formatNumber(_.get(consumable, 'balance.count', 0)) }}
                                </div>
                            </template>
                        </q-field>
                        <q-field :prefix="$translate('consumables.fields.price')"
                                 style="width: 500px;"
                        >
                            <template v-slot:control>
                                <div class="self-center full-width no-outline text-right" tabindex="0">
                                    {{ _.get(consumable, 'balance.price_formatted', 0) }}
                                </div>
                            </template>
                        </q-field>
                    </template>
                </q-tab-panel>
                <q-tab-panel name="transform">
                    <q-list>
                        <template v-for="(item, i) in consumable.transform" :key="i">
                            <q-item>
                                <q-item-section>
                                    <q-field>
                                        <template v-slot:prepend>
                                            <div class="cursor-help">
                                                <q-tooltip class="text-body2">
                                                    {{ $translate('consumables.title.transform-name') }}
                                                </q-tooltip>
                                                {{ item.name }}
                                            </div>
                                        </template>
                                        <template v-slot:control>
                                            <div class="self-center full-width  row q-gutter-x-sm no-outline"
                                                 tabindex="0">
                                                <div class="cursor-help">
                                                    {{ item.from_value }}
                                                    ({{
                                                        _.get(_.find(classificatorUnits, {'slug': item.from_unit}), 'name')
                                                    }})
                                                    <q-tooltip class="text-body2">
                                                        {{ $translate('consumables.title.transform-input') }}
                                                    </q-tooltip>
                                                </div>
                                                <div>&rarr;</div>
                                                <div class="cursor-help">
                                                    {{ item.to_value }}
                                                    ({{
                                                        _.get(_.find(classificatorUnits, {'slug': item.to_unit}), 'name')
                                                    }})
                                                    <q-tooltip class="text-body2">
                                                        {{ $translate('consumables.title.transform-base') }}
                                                    </q-tooltip>
                                                </div>
                                            </div>
                                        </template>
                                    </q-field>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </q-tab-panel>
                <q-tab-panel name="movement">
                    <ConsumablesMovementTable :consumable="consumable"/>
                </q-tab-panel>
            </q-tab-panels>
        </template>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block/";
import ConsumableMovementMinus from "@/Components/Consumables/ConsumableMovementMinus.vue";
import ConsumableMovementPlus from "@/Components/Consumables/ConsumableMovementPlus.vue";
import ConsumablesMovementTable from "@/Components/Consumables/ConsumablesMovementTable.vue";
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment";
import helpers from "@/helpers";
import {useQuasar} from 'quasar'

const props = defineProps({
    consumable: {
        type: Object
    },
    can: {
        type: Object
    },
    classificatorUnits: {
        type: Array
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const $q = useQuasar()

const tab = ref('main');

const writeonDialog = ref(false);
const writeoffDialog = ref(false);

const dateFormat = (date) => moment(date).format($translate('common.date.js'));

onMounted(() => {
    const url = new URL(window.location.href);
    if ('#writeon' === url.hash) {
        writeonDialog.value = true;
    }
    if ('#writeoff' === url.hash) {
        writeoffDialog.value = true;
    }
});
</script>
