<template>
    <q-field :label="$translate('cadre-dutyjourney.label.cadre_staff')"
             tag="div"
             stack-label>
        <template v-slot:control>
            <div class="items-center full-width">
                {{ position }}
            </div>
        </template>
    </q-field>

    <q-select v-model="form.dutyjourney.type"
              :label="$translate('cadre-dutyjourney.label.type')"
              stack-label
              :options="typeOptions()"
              emit-value map-options
              :error="form.errors.hasOwnProperty('type')"
              :error-message="_.get(form,'errors.type')"
    />

    <q-field :label="$translate('cadre-dutyjourney.label.period')"
             stack-label
             :error="form.errors.hasOwnProperty('date_start') || form.errors.hasOwnProperty('date_end')"
             :error-message="_.get(form,'errors.date_start') || _.get(form,'errors.date_end')">
        <template v-slot:control>
            <div class="items-center full-width flex q-gutter-x-xs"
                 v-if="form.dutyjourney.date_start!=null && form.dutyjourney.date_end!=null">
                <span>{{ $translate('buttons.from') }}</span>
                <span>{{ moment(form.dutyjourney.date_start).format($translate('common.date.js')) }}</span>
                <span>{{ $translate('buttons.to') }}</span>
                <span>{{ moment(form.dutyjourney.date_end).format($translate('common.date.js')) }}</span>
                <span>({{ $translate('cadre-dutyjourney.fields.days') }} {{ form.dutyjourney.days }})</span>
            </div>
            <q-popup-proxy>
                <q-date v-model="datePeriod"
                        @update:modelValue="datePeriodUpdate"
                        range minimal
                        :bordered="false">
                    <div class="row items-center justify-end">
                        <q-btn :label="$translate('buttons.select')"
                               color="dark"
                               v-close-popup flat/>
                    </div>
                </q-date>
            </q-popup-proxy>
        </template>
    </q-field>

    <q-input v-model="form.dutyjourney.city"
             :label="$translate('cadre-dutyjourney.label.city')"
             stack-label
             :error="form.errors.hasOwnProperty('city')"
             :error-message="_.get(form,'errors.city')"
    />

    <q-input v-model="form.dutyjourney.target"
             :label="$translate('cadre-dutyjourney.label.target')"
             stack-label
             type="textarea"
             rows="3"
             :error="form.errors.hasOwnProperty('target')"
             :error-message="_.get(form,'errors.target')"
    />

    <q-file v-model="form.dutyjourney.file"
            :label="$translate('cadre-dutyjourney.label.file')"
            stack-label
            :error="form.errors.hasOwnProperty('file')"
            :error-message="_.get(form,'errors.file')"
    >
        <template v-slot:prepend>
            <q-icon name="attach_file"/>
        </template>
    </q-file>

    <q-input v-model="form.comment"
             :label="$translate('cadre-dutyjourney.label.comment')"
             stack-label
             type="textarea"
             rows="3"/>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import {CadreDutyjourney} from "@/entity/";
import moment from "moment";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

interface Model {
    action?: null,
    dutyjourney: CadreDutyjourney,
    comment?: null,
    errors: {},
}

const form = defineModel<Model>({
    required: true
});

interface Props {
    position: string
}
defineProps<Props>()

const typeOptions = () => _.map($translate('cadre-dutyjourney.type'), (v, k) => ({label: v, value: k}))

const datePeriod = ref(null)

const datePeriodUpdate = (date: string) => {
    let _dateStart;
    let _dateEnd;
    if (date) {
        if (_.isObject(date)) {
            _dateStart = moment(_.get(date, 'from'))
            _dateEnd = moment(_.get(date, 'to'))
        } else {
            _dateStart = moment(date)
            _dateEnd = moment(date)
        }
        _.set(form.value.dutyjourney, 'date_start', _dateStart.format('YYYY-MM-DD'))
        _.set(form.value.dutyjourney, 'date_end', _dateEnd.format('YYYY-MM-DD'))
        _.set(form.value.dutyjourney, 'days', _dateEnd.diff(_dateStart, 'days') + 1)
    } else {
        _.set(form.value.dutyjourney, 'date_start', null)
        _.set(form.value.dutyjourney, 'date_end', null)
        _.set(form.value.dutyjourney, 'days', 0)
    }
}

</script>