<template>
    <Card :title="$translate('research-report.title.files')" class="q-ma-sm" no-card-section hide-actions>
        <q-uploader
            ref="uploader"
            :url="$route('project.research.report.files.upload', props.research)"
            :headers="()=> [{name: 'X-XSRF-TOKEN', value: Cookies.get('XSRF-TOKEN')}]"
            multiple
            :max-file-size="1024*1024*1024*2"
            color="grey-2"
            text-color="dark"
            class="full-width"
            field-name="file"
            @finish="uploaded"
            @uploaded="uploaded"
            @rejected="onRejected"
        >
            <template v-slot:header="scope">
                <div class="row no-wrap items-center q-pa-sm q-gutter-md">
                    <q-btn v-if="scope.canAddFiles" type="a" icon="o_add" label="выбрать файлы (max 2Gb)"
                           @click="scope.pickFiles"
                           dense no-caps color="primary">
                        <q-uploader-add-trigger/>
                    </q-btn>
                    <q-spinner v-if="scope.isUploading" size="2em"/>
                    <div class="col">
                        <div class="q-uploader__subtitle" v-if="scope.queuedFiles.length>0">
                            {{ scope.uploadSizeLabel }} / {{ scope.uploadProgressLabel }}
                        </div>
                    </div>
                    <q-btn v-if="scope.queuedFiles.length > 0" icon="o_delete" label='очистить очередь'
                           @click="scope.removeQueuedFiles" dense flat no-caps>
                    </q-btn>
                    <q-btn v-if="scope.canUpload" icon="o_cloud_upload" label="загрузить выбранные файлы"
                           @click="scope.upload" dense no-caps color="positive">
                    </q-btn>

                    <q-btn v-if="scope.isUploading" icon="clear" @click="scope.abort" dense no-caps>
                    </q-btn>
                </div>
            </template>
            <template v-slot:list="scope">

                <q-list separator>
                    <q-item v-for="file in scope.files" :key="file.__key">

                        <q-item-section side :title="file.__status">
                            <q-icon name="hourglass_empty" v-if="file.__status === 'idle'"/>
                            <q-icon name="report_problem" v-if="file.__status === 'failed'" color="negative"/>
                            <q-spinner v-if="file.__status === 'uploading'" color="primary"/>
                            <q-icon name="done" v-if="file.__status === 'uploaded'" color="positive"/>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label class="full-width ellipsis">
                                {{ file.name }}
                            </q-item-label>
                            <q-item-label caption>
                                {{ file.__sizeLabel }} / {{ file.__progressLabel }}
                            </q-item-label>
                        </q-item-section>

                        <q-item-section side>
                            <q-btn
                                size="12px"
                                flat
                                dense
                                round
                                icon="o_delete"
                                color="negative"
                                @click="scope.removeFile(file)"
                            />
                        </q-item-section>
                    </q-item>

                </q-list>
            </template>
        </q-uploader>
    </Card>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import {Cookies, useQuasar} from "quasar";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar()

const props = defineProps({
    research: {type: Object, required: true}
})

const emit = defineEmits(['onSuccess'])

const uploader = ref('uploader');

const uploaded = () => {
    uploader.value.removeUploadedFiles()
    emit('onSuccess')
}
const onRejected = (rejectedEntries) => {
    // Notify plugin needs to be installed
    // https://quasar.dev/quasar-plugins/notify#Installation
    $q.notify({
        type: 'negative',
        message: `${rejectedEntries.length} file(s) did not pass validation constraints`
    })
}
</script>