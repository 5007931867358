<template>
    <Card title-bg="indigo-2"
          title-text="indigo-10"
          :title="$translate('module-microcollections.element.form.title.create')"
          :header-toolbar="can.back"
    >
        <template v-if="can.back" v-slot:headerActions>
            <q-btn :label="$translate('buttons.back')"
                   @click="router.get($route('services.microcollections.index'))"
                   icon="o_chevron_left"
                   color="indigo-5"
                   no-caps
                   dense
            />
        </template>
        <MicroCollectionForm v-model="form" :types="types" :parts="parts"/>
        <template v-slot:actions>
            <q-btn :label="$translate('buttons.save')" @click="submitForm" color="positive"/>
        </template>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {Form as MicroCollectionForm} from "@/Components/MicroCollections/Element";
import {getCurrentInstance} from "vue";
import {router, useForm} from "@inertiajs/vue3";

const props = defineProps({
    can: Object,
    types: Array,
    parts: Array,
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    collection_type_id: null,
    collection_part_id: null,
    number: '',
    class: '',
    family: '',
    genus: '',
    species: '',
    strain: '',
    syngen: '',
    coupling: '',
    serotype: '',
    synonym: '',
    host: '',
    from_collection: '',
    country: '',
    city: '',
    ecotype: '',
    person: '',
    year: '',
    symbiont: '',
    media: '',
    condition: '',
    sequence: '',
    genome: '',
    publication: '',
    comment: '',
    add: '',
    file: null,
    public_state: true
});

const submitForm = () => {
    form.post(route('services.microcollections.element.store'));
};
</script>
