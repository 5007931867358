<template>
    <div class="outer-container">
        <div class="inner-container">
            <div class="row justify-center">
                <table border="1" cellpadding="4px" cellspacing="0" width="50%" style="border-collapse: collapse">
                    <tbody>
                    <tr>
                        <td>№ заявки:<br></td>
                        <td>{{ _.get(application, 'number') }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="row justify-center q-pt-sm">
                <table border="1" cellpadding="4px" width="80%" style="border-collapse: collapse">
                    <tbody>
                        <tr>
                            <td width="30%">Ф.И.О<br/><small>(руководителя исследований)</small></td>
                            <td>{{ _.get(application, 'user.fulled_name') }}</td>
                        </tr>
                        <tr>
                            <td>Должность</td>
                            <td>{{ getAdditionalData('position') }}</td>
                        </tr>
                        <tr>
                            <td>Факультет, кафедра</td>
                            <td>{{ getAdditionalData('faculty') }}, {{ getAdditionalData('department') }}</td>
                        </tr>
                        <tr>
                            <td>Контактный телефон</td>
                            <td>{{ getAdditionalData('phone') }}</td>
                        </tr>
                        <tr>
                            <td>Электронная почта</td>
                            <td>{{ _.get(application, 'user.email') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p><b>Наименование работы: </b><br/>{{ _.get(application, 'name') }}</p>
            <p><b>Тип: </b><br/>{{ _.get(application, 'type_text') }}</p>
            <p><b>Дата создания: </b><br/>{{ moment(_.get(application, 'created_at')).format($translate('common.datetime.js')) }}</p>
            <p><b>Список соавторов: </b><br/>{{ _.get(application, 'users') }}</p>
            <p><b>Список приложенных документов: </b><br/>{{ _.get(application, 'doc') }}</p>
            <p><b>Статус: </b><br/>{{ _.get(application, 'state_text') }}</p>
            <p v-if="_.get(application, 'meeting_date')"><b>Рассмотрение назначено на: </b><br/>{{ moment(_.get(application, 'meeting_date')).format($translate('common.date.js')) }}</p>
            <p><b>Комментарий: </b><br/>{{ _.get(application, 'remark') }}</p>
            <p>
                Руководитель работы
                <br/>
                {{ _.get(application, 'user.fulled_name') }}  _______________________________
                "___" _________________202__г.
            </p>
            <br/>
            <p>
                ЗАЯВКА ЗАРЕГИСТРИРОВАНА<br/>
                <br/>
                _______________________________
                "___" _________________202__г.
            </p>
        </div>
    </div>
</template>

<script setup>
import Print from "@/Layout/Print.vue";
import _ from "lodash";
import moment from "moment";
import {getCurrentInstance} from "vue";

defineOptions({layout: Print});

const props = defineProps({
    application: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const getAdditionalData = (field) => {
    let filtered = _.filter(
        _.get(props.application, 'user.additional'),
        o => _.get(o, 'slug') === field
    );
    return _.join(_.uniq(_.map(filtered, o => _.get(o, 'value'))), ', ');
};
</script>

<style scoped>
.outer-container {
    background-color: #DCC7C7; /* общий фон */
}
.inner-container {
    width: 595pt; /* ширина */
    height: 842pt; /* высота */
    padding: 30px 30px 30px 50px; /* внутренние отступы - верх, право, низ, лево */
    margin: 20px auto; /* выравнивание по центру */
    background-color: white; /* цвет фона в блоке */
    font-family:  "Times New Roman", serif; /* нужный шрифт */
    font-size: 18px;
}
</style>