<template>
    <Card :title="$translate('module-archive.project.card.reagents.title', {size: _.size(reagents)})"
          class="q-mb-md"
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn :icon="expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                   :title="$translate('buttons.toggle-list')"
                   @click="expanded = !expanded"
                   size="sm"
                   flat
            />
        </template>
        <template v-slot:default>
            <q-slide-transition>
                <div v-show="expanded">
                    <q-list separator>
                        <template v-for="(reagent, i) in reagents" :key="i">
                            <q-item>
                                <q-item-section>
                                    <div class="row q-gutter-x-sm">
                                        <div class="col-12">{{ _.get(reagent, 'name') }}</div>
                                        <div class="col">
                                        <span class="cursor-help">
                                            <q-tooltip class="text-body2">
                                                {{ $translate('module-archive.project.card.reagents.tooltips.expenses') }}
                                            </q-tooltip>
                                            <q-icon name="fas fa-vials" class="q-mr-xs" color="grey" size="xs" />
                                            {{ _.round(_.get(reagent, 'expenses'), 4) }} {{ _.get(reagent, 'units') }}
                                        </span>
                                        </div>
                                        <div class="col">
                                        <span class="cursor-help">
                                            <q-tooltip class="text-body2">
                                                {{ $translate('module-archive.project.card.reagents.tooltips.total_price') }}
                                            </q-tooltip>
                                            <q-icon name="fas fa-ruble-sign" color="grey" size="xs" />
                                            {{ _.get(reagent, 'total_price') }}
                                        </span>
                                        </div>
                                    </div>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </div>
            </q-slide-transition>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";

const props = defineProps({
    reagents: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const expanded = ref(true);
</script>
