<template>
    <div class="q-mb-md">
        <q-item-label>
            {{ $translate('cadre-sicklist.label.period') }}
            <q-icon name="sym_o_help" class="cursor-pointer" size="xs" color="grey-9">
                <q-popup-proxy>
                    <div v-html="$translate('cadre-sicklist.label.period-help')" class="q-ma-sm"></div>
                </q-popup-proxy>
            </q-icon>
        </q-item-label>
        <div class="row q-gutter-x-md">
            <q-field :label="$translate('cadre-sicklist.fields.date_start')"
                     stack-label class="col"
                     :error="form.errors.hasOwnProperty('date_start')"
                     :error-message="_.get(form,'errors.date_start')"
            >
                <template v-slot:control>
                    <div class="items-center full-width flex q-gutter-x-xs">
                        <span v-if="form.sicklist.date_start">
                            {{ moment(form.sicklist.date_start).format($translate('common.date.js')) }}
                        </span>
                    </div>
                    <q-popup-proxy>
                        <q-date v-model="form.sicklist.date_start"
                                :options="dateOptions"
                                minimal
                                :bordered="false">
                            <div class="row items-center justify-end">
                                <q-btn :label="$translate('buttons.select')"
                                       color="dark"
                                       v-close-popup flat/>
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </template>
            </q-field>
            <q-field :label="$translate('cadre-sicklist.fields.date_end')"
                     stack-label class="col"
                     :error="form.errors.hasOwnProperty('date_end')"
                     :error-message="_.get(form,'errors.date_end')">
                <template v-slot:control>
                    <div class="items-center full-width flex q-gutter-x-xs">
                        <span v-if="form.sicklist.date_end">
                            {{ moment(form.sicklist.date_end).format($translate('common.date.js')) }}
                        </span>
                    </div>
                    <q-popup-proxy>
                        <q-date v-model="form.sicklist.date_end"
                                :options="dateOptions"
                                minimal
                                :bordered="false">
                            <div class="row items-center justify-end">
                                <q-btn :label="$translate('buttons.select')"
                                       color="dark"
                                       v-close-popup flat/>
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </template>
            </q-field>
        </div>
    </div>
    <div class="q-mb-md">
        <q-item-label>
            {{ $translate('cadre-sicklist.label.code') }}
            <q-icon name="sym_o_help" class="cursor-pointer" size="xs" color="grey-9">
                <q-popup-proxy>
                    <div v-html="$translate('cadre-sicklist.label.code-help')" class="q-ma-sm"></div>
                </q-popup-proxy>
            </q-icon>
        </q-item-label>
        <div class="row q-gutter-x-md">
            <q-input v-model="form.sicklist.code"
                     :label="$translate('cadre-sicklist.fields.code')"
                     stack-label
                     class="col"
                     :error="form.errors.hasOwnProperty('code')"
                     :error-message="_.get(form,'errors.code')"/>
            <q-input v-model="form.sicklist.code2"
                     :label="$translate('cadre-sicklist.fields.code2')"
                     stack-label
                     class="col"
                     :error="form.errors.hasOwnProperty('code2')"
                     :error-message="_.get(form,'errors.code2')"/>
        </div>
    </div>
    <div class="q-mb-md">
        <q-item-label>
            {{ $translate('cadre-sicklist.label.enumber') }}
        </q-item-label>
        <q-input v-model="form.sicklist.enumber"
                 mask="#" reverse-fill-mask
                 stack-label
                 class="col"
                 :error="form.errors.hasOwnProperty('enumber')"
                 :error-message="_.get(form,'errors.enumber')"/>
    </div>
    <div class="q-mb-md">
        <q-item-label>
            {{ $translate('cadre-sicklist.label.file') }}
        </q-item-label>
        <q-file v-model="form.sicklist.file"
                stack-label
                class="col"
                :error="form.errors.hasOwnProperty('file')"
                :error-message="_.get(form,'errors.file')"/>
    </div>
    <div class="q-mb-md">
        <q-item-label>
            {{ $translate('cadre-sicklist.label.comment') }}
        </q-item-label>
        <q-input v-model="form.comment"
                 type="textarea" rows="3"
                 stack-label
                 class="col"
                 :error="form.errors.hasOwnProperty('comment')"
                 :error-message="_.get(form,'errors.comment')"/>
    </div>
</template>
<script setup lang="ts">
import {getCurrentInstance} from "vue";
import _ from "lodash";
import {CadreSicklist} from "@/entity/";
import moment from "moment";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

interface Model {
    sicklist: CadreSicklist,
    action?: null,
    comment?: null
    errors: {},
}

const form = defineModel<Model>({
    required: true
});

const dateOptions = (date: string) => {
    return moment(date).startOf('day').isSameOrBefore(moment().startOf('day'))
}

</script>