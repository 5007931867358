<template>
    <q-btn icon="add_link"
           :label="$translate('publication.detail.assign-project')"
           @click="dialog = true"
           no-caps dense/>

    <q-dialog v-model="dialog" no-backdrop-dismiss>
        <Card :title="$translate('publication.detail.assign-project')"
              in-modal show-close-button>

            <q-select v-model="form.project_id"
                      :label="$translate('publication.detail.select-project')"
                      :options="projectsToValidation"
                      map-options
                      emit-value
                      :error="form.errors.hasOwnProperty('project_id')"
                      :error-message="_.get(form.errors,'project_id')"
            />
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.assign')"
                       color="positive"
                       @click="submitForm"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    publication: {type: Object}
})
const dialog = ref(false)

const projectsToValidation = computed(() => usePage().props.projectsToValidation)

const form = useForm({
    project_id: null
})

const submitForm = () => {
    form.post(route('publication.project', {
        category: props.publication.report_type_category,
        publication: props.publication
    }), {
        onSuccess: () => {
            dialog.value = false
        }
    })
}
</script>

<style scoped>

</style>