<template>
    <q-card>
        <q-tabs align="left" v-model="tabs">
            <q-tab name="vacation"
                   :label="$translate('cadre-vacation.title.chart')"
                   @click="router.get($route('cadre.vacation.chart'))"
            />
            <q-tab name="sicklist"
                   :label="$translate('cadre-sicklist.title.chart')"
                   @click="router.get($route('cadre.sicklist.chart'))"
            />
            <q-tab name="dutyjourney"
                   :label="$translate('cadre-dutyjourney.title.chart')"
                   @click="router.get($route('cadre.dutyjourney.chart'))"
            />
        </q-tabs>
        <q-card-section>
            <slot/>
        </q-card-section>
    </q-card>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    tab: 'vacation'
});

const tabs = ref(props.tab);
</script>
