<template>
    <Card v-if="typeFields.show"
          :title="typeFields.name"
          hide-actions>
        <q-list separator>
<!--            <template v-for="(externalid) in publication.object">-->
<!--                <q-item dense>-->
<!--                    <q-item-section>-->
<!--                        <q-item-label>{{ externalid.type }}</q-item-label>-->
<!--                    </q-item-section>-->
<!--                    <q-item-section>-->
<!--                        <q-item-label>{{ externalid.value }}</q-item-label>-->
<!--                    </q-item-section>-->
<!--                </q-item>-->
<!--            </template>-->
        </q-list>

    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import FieldsValues from "@/Components/Fields/FieldsValues.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    publication: {type: Object}
})

const typeFields = computed(() => {
    return props.publication.report_type.fields.object;
})
</script>