<template>
    <Alert variant="info" :message="$translate('module-telegram.description')"/>
    <SettingsTable :data="config"/>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const config = [
    {
        name: 'TELEGRAM_ENABLED',
        label: $translate('module-telegram.settings.TELEGRAM_ENABLED'),
        value: props.settings.module.TELEGRAM_ENABLED,
        description: $translate('module-telegram.settings.TELEGRAM_ENABLED_description'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'TELEGRAM_BOT_NAME',
        label: $translate('module-telegram.settings.TELEGRAM_BOT_NAME'),
        value: props.settings.module.TELEGRAM_BOT_NAME,
        description: $translate('module-telegram.settings.TELEGRAM_BOT_NAME_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'TELEGRAM_API_TOKEN',
        label: $translate('module-telegram.settings.TELEGRAM_API_TOKEN'),
        value: props.settings.module.TELEGRAM_API_TOKEN,
        description: $translate('module-telegram.settings.TELEGRAM_API_TOKEN_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'TELEGRAM_API_HOST',
        label: $translate('module-telegram.settings.TELEGRAM_API_HOST'),
        value: !_.isEmpty(props.settings.module.TELEGRAM_API_HOST) ?
            props.settings.module.TELEGRAM_API_HOST :
            'https://api.telegram.org',
        description: $translate('module-telegram.settings.TELEGRAM_API_HOST_description'),
        block: 'module',
        type: 'text',
    },
];
</script>
