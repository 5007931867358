<template>
    <div v-if="data.module.ldap.enabled">
        <Alert v-if="$translate('module-ldap.description')"
               variant="info"
               :message="$translate('module-ldap.description')"
        />
        <SettingsTable :data="config"/>
    </div>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";
import helper from "@/helpers";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const config = [
    {
        name: 'LDAP_ENABLED',
        label: $translate('settings.module.ldap.name'),
        value: props.settings.module.LDAP_ENABLED,
        description: $translate('settings.module.ldap.disable_help'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'LDAP_SUPPORT',
        label: $translate('module-ldap.settings.LDAP_SUPPORT'),
        value: props.settings.module.LDAP_SUPPORT,
        description: $translate('module-ldap.settings.LDAP_SUPPORT_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'LDAP_PREFIX',
        label: $translate('module-ldap.settings.LDAP_PREFIX'),
        value: props.settings.module.LDAP_PREFIX,
        description: $translate('module-ldap.settings.LDAP_PREFIX_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'LDAP_SUFFIX',
        label: $translate('module-ldap.settings.LDAP_SUFFIX'),
        value: props.settings.module.LDAP_SUFFIX,
        description: $translate('module-ldap.settings.LDAP_SUFFIX_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'LDAP_DC',
        label: $translate('module-ldap.settings.LDAP_DC'),
        value: props.settings.module.LDAP_DC,
        description: $translate('module-ldap.settings.LDAP_DC_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'LDAP_DN',
        label: $translate('module-ldap.settings.LDAP_DN'),
        value: props.settings.module.LDAP_DN,
        description: $translate('module-ldap.settings.LDAP_DN_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'LDAP_BACKUP_REBIND',
        label: $translate('module-ldap.settings.LDAP_BACKUP_REBIND'),
        value: props.settings.module.LDAP_BACKUP_REBIND,
        description: $translate('module-ldap.settings.LDAP_BACKUP_REBIND_description'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'LDAP_ENCRYPT',
        label: $translate('module-ldap.settings.LDAP_ENCRYPT'),
        value: props.settings.module.LDAP_ENCRYPT,
        description: $translate('module-ldap.settings.LDAP_ENCRYPT_description'),
        block: 'module',
        type: 'select',
        multiple: false,
        options: [
            {label: 'No encrypt', value: 'none'},
            {label: 'SSL', value: 'ssl'},
            {label: 'TLS', value: 'tls'},
        ]
    },
    {
        name: 'LDAP_SEARCH_FILTER',
        label: $translate('module-ldap.settings.LDAP_SEARCH_FILTER'),
        value: props.settings.module.LDAP_SEARCH_FILTER,
        description: $translate('module-ldap.settings.LDAP_SEARCH_FILTER_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'LDAP_SEARCH_FIELDS',
        label: $translate('module-ldap.settings.LDAP_SEARCH_FIELDS'),
        value: helper.jsonParse(props.settings.module.LDAP_SEARCH_FIELDS),
        description: $translate('module-ldap.settings.LDAP_SEARCH_FIELDS_description'),
        block: 'module',
        type: 'tags',
    },
    // association
    {
        name: 'LDAP_USERNAME',
        label: $translate('module-ldap.settings.LDAP_USERNAME'),
        value: props.settings.module.LDAP_USERNAME,
        description: $translate('module-ldap.settings.LDAP_USERNAME_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'LDAP_PASSWORD',
        label: $translate('module-ldap.settings.LDAP_PASSWORD'),
        value: props.settings.module.LDAP_PASSWORD,
        description: $translate('module-ldap.settings.LDAP_PASSWORD_description'),
        block: 'module',
        type: 'password',
    },
    {
        name: 'LDAP_STAFF_SEARCH',
        label: $translate('module-ldap.settings.LDAP_STAFF_SEARCH'),
        value: props.settings.module.LDAP_STAFF_SEARCH,
        description: $translate('module-ldap.settings.LDAP_STAFF_SEARCH_description'),
        block: 'module',
        type: 'checkbox',
    },
];
</script>
