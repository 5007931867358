<template>
    <Card :title="template.name"
          title-bg="blue-grey-2"
          header-toolbar hide-actions>
        <template v-slot:headerActions>
            <q-btn icon="navigate_before"
                   :label="$translate('research-template.actions.list')"
                   color="blue-grey-6"
                   @click="router.visit($route('report-template.list'))"
                   no-caps dense no-wrap/>
        </template>
        <q-field tag="div"
                 :label="$translate('report-template.form.fields.name')"
                 stack-label
                 borderless>
            <template v-slot:control>
                <div class="full-width">
                    {{ template.name }}
                </div>
            </template>
        </q-field>
        <q-field tag="div"
                 :label="$translate('report-template.form.fields.usage')"
                 stack-label
                 borderless>
            <template v-slot:control>
                <div class="full-width">
                    {{ template.usage_text }}
                </div>
            </template>
        </q-field>
        <q-field tag="div"
                 :label="$translate('report-template.form.fields.allow')"
                 stack-label
                 borderless>
            <template v-slot:control>
                <div class="full-width">
                    {{ template.allow_text }}
                </div>
            </template>
        </q-field>
        <q-field tag="div"
                 :label="$translate('report-template.form.fields.detail')"
                 stack-label
                 borderless>
            <template v-slot:control>
                <div class="full-width">
                    <div class=" row q-gutter-x-md">
                        <div class="col">
                            <Card :title="$translate('report-template.form.label.equipment')" hide-actions
                                  no-card-section>
                                <q-list dense>
                                    <template v-for="(equipment) in template.equipment">
                                        <q-item>
                                            <q-item-section>
                                                <q-item-label>{{ equipment.name }}</q-item-label>
                                            </q-item-section>
                                            <q-item-section side>
                                                <q-item-label>{{ equipment.time }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item v-if="_.size(equipment.worktimes)>0">
                                            <q-item-section>
                                                <q-item-label overline>
                                                    {{ $translate('report-template.form.label.worktime') }}
                                                </q-item-label>
                                                <q-item-label v-for="(worktime) in equipment.worktimes" caption>
                                                    <div class="flex justify-between row no-wrap">
                                                        <span>&mdash; {{ worktime.comment }}</span>
                                                        <span>{{ worktime.time }}</span>
                                                    </div>
                                                </q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-separator/>
                                    </template>
                                </q-list>
                            </Card>
                        </div>
                        <div class="col">
                            <Card :title="$translate('report-template.form.label.consumable')" hide-actions
                                  no-card-section>
                                <q-list dense separator>
                                    <template v-for="(consumable) in template.consumable">
                                        <q-item>
                                            <q-item-section>
                                                <q-item-label>{{ consumable.name }}</q-item-label>
                                            </q-item-section>
                                            <q-item-section side>
                                                <q-item-label>{{ consumable.used }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-list>
                            </Card>
                        </div>
                        <div class="col">
                            <Card :title="$translate('report-template.form.label.worktime')" hide-actions
                                  no-card-section>
                                <q-list dense separator>
                                    <template v-for="(worktime) in template.worktime">
                                        <q-item>
                                            <q-item-section>
                                                <q-item-label>{{ worktime.comment }}</q-item-label>
                                            </q-item-section>
                                            <q-item-section side>
                                                <q-item-label>{{ worktime.time }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-list>
                            </Card>
                        </div>
                    </div>
                </div>
            </template>
        </q-field>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    template: {type: Object}
})
</script>