<template>
    <Card title-bg="indigo-1"
          :title="$translate('consumables.ssc.title.plus')"
          actions-align="center"
          show-close-button
          in-modal
          header-toolbar
    >

        <q-input v-model="form.dogovor_number"
                 :label="$translate('consumables.ssc.fields.dogovor')" stack-label
                 :placeholder="$translate('consumables.ssc.fields.dogovor_number')"
                 :error-message="form.errors.dogovor_number"
                 :error="form.errors.hasOwnProperty('dogovor_number')"
        />
        <q-input v-model="form.dogovor_date" style="width:300px"
                 :label="$translate('consumables.ssc.fields.dogovor_date')" stack-label
                 :max="minOfDateAndToday"
                 type="date"
                 :error-message="form.errors.dogovor_date"
                 :error="form.errors.hasOwnProperty('dogovor_date')"
        />
        <q-input v-model.number="form.dogovor_price"
                 :label="$translate('consumables.ssc.fields.dogovor_price')" stack-label
                 :suffix="$translate('units.rub')"
                 type="number"
                 step="0.01"
                 min="0"
                 :error-message="form.errors.dogovor_price"
                 :error="form.errors.hasOwnProperty('dogovor_price')"
        />
        <q-file v-model="form.dogovor_file"
                :label="$translate('consumables.ssc.fields.dogovor_file')" stack-label
                accept=".jpg, .png, .gif, .pdf"
                clearable
                bottom-slots
        >
            <template v-slot:prepend>
                <q-icon name="attach_file"/>
            </template>
        </q-file>
        <template v-if="form.errors.hasOwnProperty('dogovor_file')">
            <div class="text-negative">{{ form.errors.dogovor_file }}</div>
        </template>
        <q-input v-model="form.date" style="width:300px"
                 :label="$translate('consumables.ssc.fields.date')" stack-label
                 :min="form.dogovor_date"
                 :max="moment().format('YYYY-MM-DD')"
                 type="date"
                 :error-message="form.errors.date"
                 :error="form.errors.hasOwnProperty('date')"
        />
        <div class="row q-gutter-md">
            <div class="col">
                <q-input v-model.number="form.count"
                         :label="$translate('consumables.ssc.fields.count')" stack-label
                         type="number"
                         step="0.01"
                         min="0"
                         :error-message="form.errors.count"
                         :error="form.errors.hasOwnProperty('count')"
                />
            </div>
            <div class="col">
                <q-select v-model="form.transform_id"
                          :label="$translate('consumables.ssc.fields.units')" stack-label
                          :options="consumable.transform.filter((o)=>(!o.archive))"
                          option-label="name"
                          option-value="id"
                          options-dense
                          map-options
                          emit-value
                          :error-message="form.errors.transform_id"
                          :error="form.errors.hasOwnProperty('transform_id')"
                />
            </div>
        </div>
        <q-input v-model="form.comment"
                 :label="$translate('consumables.ssc.fields.comment')" stack-label
                 type="textarea"
                 rows="3"
                 :error-message="form.errors.comment"
                 :error="form.errors.hasOwnProperty('comment')"
        />
        <template v-slot:actions>
            <q-btn icon="save" :label="$translate('buttons.add')"
                   @click="submitForm"
                   color="positive"
            />
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {computed, getCurrentInstance} from "vue";
import {useForm} from "@inertiajs/vue3";
import moment from "moment";

const emit = defineEmits(['formReceived']);
const props = defineProps({
    consumable: {
        type: Object
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const form = useForm({
    dogovor_number: null,
    dogovor_date: null,
    dogovor_price: null,
    dogovor_file: null,
    date: '',
    count: null,
    transform_id: null,
    comment: null
});

const minOfDateAndToday = computed(() => {
    if (form.date.value === '') {
        return moment().format('YYYY-MM-DD');
    }
    if (moment().isAfter(form.date.value)) {
        return form.date.value;
    } else {
        return moment().format('YYYY-MM-DD');
    }
});

const submitForm = () => {
    form.post(route('consumables.ssc.writeon.post', {consumable: props.consumable.id}), {
        onSuccess: () => {
            emit('formReceived');
        }
    });

};
</script>
