<template>
    <Card :title="$translate('module-delivery.title-order-create')" header-toolbar>
        <DeliveryOrderForm v-model="order"
                           :errors="form.errors"
        />
        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.add')"
                   color="positive"
                   @click="submitForm"/>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import DeliveryOrderForm from "@/Components/Delivery/DeliveryOrderForm.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const order = ref({
    from: null,
    goods: null,
    count: null,
    unit: null,
    self: null,
    to_address: null,
    room: null,
    self_address: null,
    new_address: null,
    new_address_detail: null,
    date: null,
    comment: null,
    valid: false
})

const form = useForm(order.value)

const submitForm = () => {
    form.transform((data) => ({
        ...data,
        ...order.value
    }))
        .post(route('services.delivery.order.store'), {})
}
</script>