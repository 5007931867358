<template>
    <div class="q-gutter-y-md">
        <div class="row q-gutter-x-lg">
            <q-field :label="$translate('equipment.item.fields.year-place')" stack-label
                     hint="минимальная дата составляющих комплекса"
                     class="col">
                <template v-slot:control>
                    {{ equipment.year_placement }}
                </template>
            </q-field>
            <q-field :label="$translate('equipment.item.fields.lifetime')" stack-label
                     hint="среднее арифметическое составляющих комплекса"
                     class="col"
                     :suffix="$translate('units.years')">
                <template v-slot:control>
                    <div class="self-center full-width text-right no-outline">
                        {{ equipment.lifetime }}
                    </div>
                </template>
            </q-field>
        </div>

        <div class="row q-gutter-x-lg">
            <q-field :label="$translate('equipment.item.fields.buh_cost')" stack-label
                     hint="сумма составляющих комплекса"
                     class="col"
                     :suffix="$translate('units.rub')">
                <template v-slot:control>
                    <div class="self-center full-width text-right no-outline">
                        {{ equipment.initial_cost }}
                    </div>
                </template>
            </q-field>
            <q-field type="number"
                     :label="$translate('equipment.item.fields.depreciation_real')" stack-label
                     :hint="$translate('equipment.item.fields.depreciation_real_description')"
                     class="col" :suffix="$translate('units.rub-hour')">
                <template v-slot:control>
                    <div class="self-center full-width text-right no-outline">
                        {{ equipment.depreciation_real }}
                    </div>
                </template>
            </q-field>
        </div>
    </div>
</template>

<script setup lang="ts">
import {getCurrentInstance, watch, watchEffect, watchSyncEffect} from "vue";
import _ from "lodash";
import moment from 'moment';
import {Equipment} from '@/entity'

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

const equipment: Equipment = defineModel()

const depreciationReal = () => {
    let deprecation: number = 0;
    if (equipment.value.lifetime > 0 && equipment.value.initial_cost > 0) {
        deprecation = Number(Number(_.get(equipment.value, 'initial_cost') / _.get(equipment.value, 'lifetime') / 365 / 24).toFixed(2));
    }
    _.set(equipment.value, 'depreciation_real', deprecation);
}


watchEffect(() => {
    let initial_cost:number = 0;
    let year_placement = moment();
    let lifetime: number[] = [];
    _.each(equipment.value.complex, (complex) => {
        initial_cost = initial_cost + complex.initial_cost;
        if (complex.year_placement && moment(complex.year_placement).isBefore(year_placement)) {
            year_placement = moment(complex.year_placement);
        }
        lifetime.push(complex.lifetime);
    });
    _.set(equipment.value, 'initial_cost', initial_cost);
    _.set(equipment.value, 'year_placement', year_placement.format(moment.HTML5_FMT.DATE));
    _.set(equipment.value, 'lifetime', _.sum(lifetime) / _.size(lifetime));
    depreciationReal()
})
</script>