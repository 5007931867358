<template>
    <Card title-bg="indigo-2"
          :title="$translate('module-microcollections.part.index.title')"
          title-text="indigo-10"
          header-toolbar
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn v-if="_.get(can, 'create')"
                   :label="$translate('buttons.add')"
                   @click="router.get($route('services.microcollections.part.create'))"
                   color="indigo-5"
                   icon="o_add"
                   no-caps
                   dense
            />
        </template>
        <q-list separator>
            <template v-for="(part, i) in _.get(parts, 'data')" :key="i">
                <q-item>
                    <q-item-section>
                        <q-item-label>{{ part.name }}</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-item-label class="cursor-help">
                            <q-tooltip class="text-body2">
                                {{ $translate('module-microcollections.part.index.elements_count') }}
                            </q-tooltip>
                            {{ _.get(part, 'elements_count') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <DataTableActions :actions="part.actions" :name="part.name" @on-success="onSuccess"/>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import DataTableActions from "@/Components/DataTableActions.vue";
import {getCurrentInstance} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    parts: Object,
    can: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const onSuccess = () => {
    router.reload();
};
</script>