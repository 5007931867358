<template>
    <q-item-label caption>
        {{ $translate('module-pure.select-project') }}*:
    </q-item-label>

    <q-inner-loading :showing="loadingProjects">
        <div class="flex items-center justify-center">
            <q-spinner-gears size="50px" color="primary"/>
            <q-item-label class="q-ml-xl text-primary">{{ $translate('module-pure.loading-data') }}</q-item-label>
        </div>
    </q-inner-loading>

    <q-select v-model="selectedProject"
              :options="options"
              :loading="loadingProjects"
              emit-value
              map-options
              input-debounce="1000"
              option-label="html"
              options-html
              use-input
              behavior="dialog"
              clearable clear-icon="clear"
              @filter="searchProject"
              @update:model-value="loadProject"
              :error="project.errors.hasOwnProperty('sourceData.award') || project.errors.hasOwnProperty('sourceDetail')  || project.errors.hasOwnProperty('sourceData')"
              :error-message="_.get(project.errors,'sourceData.award') || _.get(project.errors, 'sourceDetail') || _.get(project.errors, 'sourceData')"
    />

    <div v-if="projectData" class="bg-grey-1 q-pa-sm q-mb-md">

        <div v-if="projectData.hasOwnProperty('descriptions') && Array.isArray(projectData.descriptions)">
            <q-field v-for="(description) in projectData.descriptions"
                     :label="description.type" stack-label borderless>
                <template v-slot:control>
                    <div class="self-center full-width" v-html="description.value"/>
                </template>
            </q-field>
        </div>
        <q-field :label="$translate('module-pure.project.timeline')"
                 stack-label borderless>
            <template v-slot:control>
                <div>
                    {{ $translate('module-pure.project.from') }} {{ dateFormat(projectData.actualStartDate) }}
                    {{ $translate('module-pure.project.to') }} {{ dateFormat(projectData.actualEndDate) }}
                </div>
                <div
                    v-if="(projectData.hasOwnProperty('actualStartDate') && projectData.hasOwnProperty('actualEndDate'))">
                    <div class="text-negative" v-if="(!checkActualStartDate() && !checkActualEndDate())">
                        Не заполнен цикл существования (реальная дата начала / реальная дата завершения)
                    </div>
                    <div class="text-negative" v-else-if="!checkActualStartDate()">
                        Не заполнен цикл существования (реальная дата начала)
                    </div>
                    <div class="text-negative" v-else-if="!checkActualEndDate()">
                        Не заполнен цикл существования (реальная дата завершения)
                    </div>
                </div>
            </template>
        </q-field>

        <q-field v-if="projectData.hasOwnProperty('awardholders') && Array.isArray(projectData.awardholders)"
                 :label="$translate('module-pure.project.members')"
                 stack-label borderless>
            <template v-slot:control>
                <div class="self-center full-width">
                    <q-list dense>
                        <q-item v-for="(holder, index) in projectData.awardholders">
                            <q-item-section side>
                                <q-item-label>{{ holder.personRole.value }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ holder.name.lastName }} {{ holder.name.firstName }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </div>
            </template>
        </q-field>
        <Alert variant="info" v-if="validateInfo" :message="validateInfo"/>
        <Alert variant='danger' v-if="validateError" :message="validateError"/>
    </div>
</template>

<script setup>
import {getCurrentInstance, nextTick, onMounted, ref, watch} from "vue";
import {usePage} from "@inertiajs/vue3";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import {Alert} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const project = defineModel({type: Object})

const locale = usePage().props.locale;

const selectedProject = ref(null)
const projectData = ref(null)
const sourceOptions = ref([])
const options = ref([])

const loadingProjects = ref(false)

const validateInfo = ref(null)
const validateError = ref(null)

const loadProjects = () => {
    loadingProjects.value = true;
    axios.get(route('ajax.pure', {m: 'getAwardsFromCache'}))
        .then((response) => {
            sourceOptions.value = response.data.results
        })
        .then(() => {
            options.value = sourceOptions.value
        })
        .finally(() => {
            loadingProjects.value = false
        })
}
const loadProject = () => {
    projectData.value = null
    axios.get(route('ajax.pure', {m: 'getAwardFromCache', award: selectedProject.value.id}))
        .then(response => {
            // console.log(response.data);
            projectData.value = response.data.results;
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            // vm.$root.$data.isLoading = false;
            validateData()
        });
}


const searchProject = (val, update, abort) => {
    if (val.length === 0) {
        update(() => {
            options.value = sourceOptions.value
        })
        return
    }
    if (val.length < 2) {
        abort()
        return
    }
    update(() => {
        const needle = val.toLowerCase()
        options.value = sourceOptions.value.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
    })
}

const checkActualStartDate = () => {
    if (!projectData.value.hasOwnProperty('actualStartDate')) {
        return false;
    }
    let actualStartDate = _.get(projectData.value, 'actualStartDate');
    return $translate('module-pure.no-data') !== actualStartDate;
}

const checkActualEndDate = () => {
    if (!projectData.value.hasOwnProperty('actualEndDate')) {
        return false;
    }
    let actualEndDate = _.get(projectData.value, 'actualEndDate');
    return $translate('module-pure.no-data') !== actualEndDate;
}
const dateFormat = (date) => {
    if ($translate('module-pure.no-data') === date) {
        return date;
    }
    return 0 < date.length ? moment(date).format($translate('common.date.js')) : date;
}
const validateData = () => {
    validateError.value = null
    // проверка на существующий проект
    axios.post('/datasource.json', {
        'm': 'validateProject',
        'source': 'pure',
        'data': projectData.value
    }).then(response => {
        if (response.data.results !== true) {
            validateError.value = response.data.results.error;
        }
    }).catch(error => {
        console.error(error);
        // vm.$root.$data.isLoading = false;
    });
}

const handleKeywords = (keywordGroups) => {
    let keywords = [];
    if (!keywordGroups) {
        return keywords;
    }
    _.forEach(keywordGroups, keywordGroup => {
        if (keywordGroup["logicalName"] === "keywordContainers") {
            let freeKeywords = keywordGroup.keywordContainers[0].freeKeywords.split("|");
            keywords = _.concat(freeKeywords, keywords);
        }
    });
    return keywords;
}
const handleDescription = (descriptions) => {
    if (!descriptions) {
        return null;
    }
    const sanitizer = v => v.split('<br/>').join("\n").replace(/(<([^>]+)>)/gi, "");
    return descriptions.map(description => description.type + ': ' + sanitizer(description.value)).join("\n\n");
}
const handleStartDate = () => {
    let today = moment.now()
    let awardDate = _.get(projectData.value, 'awardDate'),
        dateStartA = _.get(projectData.value, 'actualStartDate'),
        dateStart;

    dateStart = 0 < _.size(dateStartA) ? dateStartA : awardDate;
    return moment(moment(dateStart).isSameOrBefore(today) ? today : dateStart).format('YYYY-MM-DD');

}
const handleEndDate = () => {
    let dateEndA = _.get(projectData.value, 'actualEndDate');
    return 0 < _.size(dateEndA) ? moment(dateEndA).format('YYYY-MM-DD') : null;
}


watch(projectData, () => {
    _.set(project.value, 'sourceData', {award: _.get(selectedProject.value, 'id')});
    _.set(project.value, 'sourceDetail', projectData.value);
    _.set(project.value, 'name', _.get(projectData.value, 'title.text'));
    _.set(project.value, 'description', handleDescription(_.get(projectData.value, 'descriptions')));
    _.set(project.value, 'targets', null);
    _.set(project.value, 'keywords', handleKeywords(_.get(projectData.value, 'keywordGroups')));
    _.set(project.value, 'additional', {});
    _.set(project.value, 'date_start', handleStartDate());
    _.set(project.value, 'date_end', handleEndDate());
    _.set(project.value, 'members', []);
    _.set(project.value, 'ssc', []);
})


onMounted(() => {
    loadProjects()
})
</script>