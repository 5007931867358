<template>
    <Layout :department="department" tab="settings">
        <q-tabs v-model="tabs" dense>
            <q-tab name="common" :label="$translate('ssc.settings.tab.common')"/>
            <q-tab name="module" :label="$translate('ssc.settings.tab.module')"/>
            <q-tab name="contact" :label="$translate('ssc.settings.tab.contact')"/>
            <q-tab name="custom-state" :label="$translate('ssc.settings.tab.custom-state')"/>
        </q-tabs>
        <q-tab-panels v-model="tabs">
            <q-tab-panel name="common">
                <SettingsCommon :department="department"/>
            </q-tab-panel>
            <q-tab-panel name="module" class="q-px-md q-gutter-y-md">
                <SettingsModuleRims :department="department"/>
                <SettingsModuleDelivery :department="department"/>
            </q-tab-panel>
            <q-tab-panel name="contact" class="q-px-md">
                <SettingsContact :department="department"/>
            </q-tab-panel>
            <q-tab-panel name="custom-state" class="q-px-md">
                <CustomState :department="department"/>
            </q-tab-panel>
        </q-tab-panels>
    </Layout>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import Layout from "@/Pages/SSC/Layout.vue";
import {
    Common as SettingsCommon,
    ModuleRims as SettingsModuleRims,
    ModuleDelivery as SettingsModuleDelivery,
    Contact as SettingsContact,
} from "@/Components/SSC/Settings";

import CustomState from "@/Pages/SSC/CustomState.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const props = defineProps({
    department: {},
    staff: {
        type: Array,
    }
})

const tabs = ref('common')
</script>