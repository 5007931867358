<template>
    <q-select v-model="form.measurement_type_id"
              :label="$translate('module-sensors.sensor-rules.form.fields.measurement_type')"
              :error="form.errors.hasOwnProperty('measurement_type_id')"
              :error-message="_.get(form.errors, 'measurement_type_id')"
              :options="measurementTypes"
              map-options
              emit-value
    />
    <q-input v-model="form.rule"
             :label="$translate('module-sensors.sensor-rules.form.fields.rule')"
             :error="form.errors.hasOwnProperty('rule')"
             :error-message="_.get(form.errors, 'rule')"
    />
</template>

<script setup>
import {getCurrentInstance} from "vue";
import _ from "lodash";

/**
 * @param InertiaForm form
 */
const form = defineModel({type: Object});
const props = defineProps({
    measurementTypes: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
