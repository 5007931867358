<template>
    <div class="flex inline items-center q-gutter-x-sm no-wrap self-center q-pr-sm text-grey-8 text-small">
        <q-checkbox v-model="checked"
                    size="xs"
                    :label="$translate('common.page-reload.refresh')"
                    dense
                    @update:modelValue="onChecked"
        />
        <q-input v-model="time"
                 dense
                 class="q-pa-none"
                 style="width: 42px"
                 :suffix="$translate('common.page-reload.sec')"
                 borderless
                 @update:modelValue="onChecked"/>
    </div>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, onMounted, ref, watch} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import {useTimeout, useQuasar} from "quasar";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

const $q = useQuasar()

const checked = ref(false)

const time = ref(30)

const url = computed(() => $route().current())

const {registerTimeout, removeTimeout} = useTimeout()

const onChecked = () => {
    let current_set = JSON.parse($q.sessionStorage.getItem('PageReload') || '{}') || {};
    console.debug(current_set);
    if (checked.value) {
        _.set(current_set, url.value, time.value)
        registerTimeout(() => {
            console.info('router.reload()');
            router.reload({
                onFinish: () => onChecked(),
            })
        }, time.value * 1000) // every `time` seconds
    } else {
        _.unset(current_set, url.value)
        removeTimeout()
    }
    $q.sessionStorage.set('PageReload', JSON.stringify(current_set))
}

onMounted(async () => {
    let current_set = JSON.parse($q.sessionStorage.getItem('PageReload') || '{}') || {};
    if (_.get(current_set, url.value) > 0) {
        checked.value = true;
        time.value = current_set[url.value];
    }
    onChecked()
})
</script>