<template>
    <q-tabs align="left" v-model="tab"
            inline-label no-caps
            active-bg-color="grey-2">
        <q-tab name="equipment"
               v-if="!_.includes(disabledTabs,'equipment')"
               icon="o_plumbing"
               :label="$translate('research-report.tab.equipment')">
            <q-spinner v-if="reportDataStore.loadingEquipment"
                       color="primary"
                       class="q-ml-sm"/>
        </q-tab>
        <q-tab name="consumables"
               v-if="!_.includes(disabledTabs,'consumables')"
               icon="sym_o_labs"
               :label="$translate('research-report.tab.consumables')">
            <q-spinner v-if="reportDataStore.loadingConsumables"
                       color="primary"
                       class="q-ml-sm"/>
        </q-tab>
        <q-tab name="worktime"
               v-if="!_.includes(disabledTabs,'worktime')"
               icon="o_schedule"
               :label="$translate('research-report.tab.worktime')">
            <q-spinner v-if="reportDataStore.loadingWorktime"
                       color="primary"
                       class="q-ml-sm"/>
        </q-tab>
        <q-tab name="files"
               v-if="!_.includes(disabledTabs,'files')"
               icon="o_picture_as_pdf"
               :label="$translate('research-report.tab.files')">
            <q-spinner v-if="reportDataStore.loadingFiles"
                       color="primary"
                       class="q-ml-sm"/>
        </q-tab>
    </q-tabs>
    <q-tab-panels v-model="tab" keep-alive>
        <q-tab-panel name="equipment" class="q-pa-none">
            <ResearchReportEquipmentList
                :items="reportDataStore.equipmentList"
                :loading="reportDataStore.loadingEquipment"
                @onSuccess="reportDataStore.loadEquipments()"/>
            <ResearchReportEquipmentCreate
                v-if='research.can.fillReport'
                :research="research"/>
        </q-tab-panel>
        <q-tab-panel name="consumables" class="q-pa-none">
            <ResearchReportConsumableList
                :items="reportDataStore.consumableList"
                :loading="reportDataStore.loadingConsumables"
                @onSuccess="reportDataStore.loadConsumables()"/>
            <ResearchReportConsumableCreate
                v-if='research.can.fillReport'
                :research="research"/>
        </q-tab-panel>
        <q-tab-panel name="worktime" class="q-pa-none">
            <ResearchReportWorktimeList
                :items="reportDataStore.worktimeList"
                :loading="reportDataStore.loadingWorktime"
                @onSuccess="reportDataStore.loadWorktime()"/>
            <ResearchReportWorktimeCreate
                v-if='research.can.fillReport'
                :research="research"/>
        </q-tab-panel>
        <q-tab-panel name="files" class="q-pa-none">
            <ResearchReportFilesList
                :items="reportDataStore.filesList"
                :loading="reportDataStore.loadingFiles"
                @onSuccess="reportDataStore.loadFiles()"/>
            <ResearchReportFilesCreate
                v-if='research.can.fillReport'
                :research="research"
                @onSuccess="reportDataStore.loadFiles()"/>
        </q-tab-panel>
    </q-tab-panels>
</template>

<script setup>

import {getCurrentInstance, onMounted, ref} from "vue";
import {useReportDataStore} from '@/store/reportDataStore';
import ResearchReportEquipmentList from "@/Components/ProjectResearch/Report/Equipment/ResearchReportEquipmentList.vue";
import ResearchReportConsumableList
    from "@/Components/ProjectResearch/Report/Consumables/ResearchReportConsumableList.vue";
import ResearchReportWorktimeList from "@/Components/ProjectResearch/Report/Worktime/ResearchReportWorktimeList.vue";
import _ from "lodash";
import ResearchReportEquipmentCreate
    from "@/Components/ProjectResearch/Report/Equipment/ResearchReportEquipmentCreate.vue";
import ResearchReportWorktimeCreate
    from "@/Components/ProjectResearch/Report/Worktime/ResearchReportWorktimeCreate.vue";
import ResearchReportConsumableCreate
    from "@/Components/ProjectResearch/Report/Consumables/ResearchReportConsumableCreate.vue";
import ResearchReportFilesCreate from "@/Components/ProjectResearch/Report/Files/ResearchReportFilesCreate.vue";
import axios from "axios";
import ResearchReportFilesList from "@/Components/ProjectResearch/Report/Files/ResearchReportFilesList.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    research: {type: Object, required: true},
    disabledTabs: {type: Array, default: []},
})
const reportDataStore = useReportDataStore()

const tab = ref('equipment')

onMounted(() => {
    reportDataStore.setResearch(props.research)
    reportDataStore.loadEquipments()
    reportDataStore.loadConsumables()
    reportDataStore.loadWorktime()
    reportDataStore.loadFiles()
})
</script>

<style scoped>

</style>