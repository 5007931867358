<template>
    <q-btn flat no-caps class="self-stretch no-wrap" style="max-width:400px">
        <div class="q-mr-sm" v-if="$q.screen.gt.xs && parent_user">
            <div>{{ parent_user.full_name }}</div>
        </div>
        <q-icon name="keyboard_arrow_right"
                v-if="$q.screen.gt.xs && parent_user"
                class="q-mr-sm"/>
        <div class="q-mr-sm self-center" v-if="$q.screen.gt.xs">
            <div>{{ user.full_name }}</div>
            <div class="ellipsis"  style="max-width:180px">
                <template v-for="(title) in userTitle(user)">
                    <small class="text-italic" :title="title.value">{{ title.value }}</small>
                </template>
            </div>
        </div>
        <UserAvatar :avatar="user.avatar" size="40px"/>
        <q-menu fit>
            <q-list separator>
                <q-item v-if="userMenu.profile"
                        v-close-popup clickable
                        @click="router.visit($route('switch-service','profile'))">
                    <q-item-section side>
                        <q-icon name="person"/>
                    </q-item-section>
                    <q-item-section class="text-uppercase">
                        {{ $translate("menu.user.profile") }}
                    </q-item-section>
                </q-item>
                <q-separator v-if="_.size(userMenu.ssc)>0"/>
                <q-item-label header v-if="_.size(userMenu.ssc)>0">{{ $translate("menu.user.ssc") }}</q-item-label>
                <template v-for="(name,id) in userMenu.ssc">
                    <q-item v-close-popup clickable dense
                            @click="router.visit($route('ssc.show',id))">
                        <q-item-section>
                            {{ name }}
                        </q-item-section>
                    </q-item>
                </template>

                <q-item @click="logout"
                        v-close-popup clickable>
                    <q-item-section side>
                        <q-icon name="logout"/>
                    </q-item-section>
                    <q-item-section class="text-uppercase">
                        {{ $translate("menu.user.logout") }}
                    </q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>
</template>

<script setup>
import {computed, getCurrentInstance} from 'vue'
import {router, useForm, usePage} from '@inertiajs/vue3'
import _ from "lodash";
import UserAvatar from "@/Components/UserAvatar.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $p = usePage();

const user = computed(() => $p.props.auth.user)
const parent_user = computed(() => _.get($p.props.auth,'parent_user'))
const title = computed(() => _.get(_.first(_.get($p.props.auth.user, 'additional'), {slug: 'title'}), 'value'))

const userMenu = computed(() => usePage().props.auth.menu)

const logoutForm = useForm({});

const logout = () => {
    if (parent_user.value) {
        router.visit(route('admin.logoutas'))
    } else {
        logoutForm.post(route('logout'));
    }
}
const userTitle = (user) => {
    return _.filter(user.additional, function (item) {
        return item.slug === 'title' || item.slug === 'position'
    })
}
</script>