<template>
    <q-item-label caption>
        {{ $translate('project.fields.source-none-base') }}*:
    </q-item-label>
    <q-select v-model="baseOn"
              :options="sourceOptions"
              emit-value
              map-options
              @update:model-value="selectBase"
              :error="project.errors.hasOwnProperty('sourceData.type')"
              :error-message="_.get(project.errors,'sourceData.type')"
    />

    <q-item-label v-if="baseOn === 'exist'" caption>
        {{ $translate('project.fields.source-none-base-project') }}*:
    </q-item-label>
    <q-select v-if="baseOn === 'exist'"
              v-model="selectedProject"
              :options="existOptions"
              options-dense
              :loading="loadingProjects"
              @update:model-value="loadProject"
              :error="project.errors.hasOwnProperty('sourceData.project')"
              :error-message="_.get(project.errors,'sourceData.project')">
        <template v-slot:no-option>
            <q-item>
                <q-item-section class="text-italic text-negative">
                    нет доступных проектов
                </q-item-section>
            </q-item>
        </template>
    </q-select>

    <div v-if="baseOn === 'exist' && selectedProject" class="bg-grey-1 q-pa-sm q-mb-md">
        <q-item>
            <q-item-section>
                <q-item-label caption>{{ $translate('project.fields.number') }}</q-item-label>
                <q-item-label>{{ selectedProject.number }}</q-item-label>
            </q-item-section>
        </q-item>
        <q-item>
            <q-item-section>
                <q-item-label caption>{{ $translate('project.fields.name') }}</q-item-label>
                <q-item-label>{{ selectedProject.name }}</q-item-label>
            </q-item-section>
        </q-item>
        <q-item>
            <q-item-section>
                <q-item-label caption>{{ $translate('project.fields.description') }}</q-item-label>
                <q-item-label>{{ selectedProject.description }}</q-item-label>
            </q-item-section>
        </q-item>
    </div>

</template>

<script setup>
import {getCurrentInstance, nextTick, ref} from "vue";
import axios from "axios";
import _ from "lodash";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const project = defineModel({type: Object})

const baseOn = ref(null)
const selectedProject = ref(null)
const projectData = ref(null)
const loadingProjects = ref(false)

const sourceOptions = [
    {value: 'empty', label: $translate('project-form.new-project')},
    {value: 'exist', label: $translate('project-form.base-on-project')},
]
const existOptions = ref(null)

/** загрузка списка доступных проектов */
const getProjects = () => {
    if (existOptions.value !== null) {
        return
    }
    loadingProjects.value = true;
    axios
        .get(route('ajax.project', {m: "baseon"}))
        .then((response) => {
            existOptions.value = response.data.results
        })
        .finally(() => {
            loadingProjects.value = false;
        })
}

/** загрузка выбранного проекта для предустановленных данных */
const loadProject = () => {
    if (!_.get(selectedProject.value, 'value')) {
        return null
    }
    axios
        .get(route('ajax.project', {m: "single", id: selectedProject.value.value}))
        .then((response) => {
            projectData.value = response.data.results
            _.set(project.value, 'sourceData', {type: 'exist', project: _.get(selectedProject.value, 'value')});
            _.set(project.value, 'sourceDetail', projectData.value);
            _.set(project.value, 'name', _.get(projectData.value, 'name'));
            _.set(project.value, 'description', _.get(projectData.value, 'description'));
        })
}

/**
 * Выбор основания
 */
const selectBase = () => {
    if (baseOn.value === 'empty') {
        _.set(project.value, 'sourceData', {type: 'empty'});
        _.set(project.value, 'sourceDetail', {});
    }
    if (baseOn.value === 'exist') {
        getProjects()
        _.set(project.value, 'sourceData', {type: 'exist'});
    }
    _.set(project.value, 'name', null);
    _.set(project.value, 'description', null);
    _.set(project.value, 'targets', null);
    _.set(project.value, 'keywords', null);
    _.set(project.value, 'additional', {});
    _.set(project.value, 'date_start', null);
    _.set(project.value, 'date_end', null);
    _.set(project.value, 'members', []);
    _.set(project.value, 'ssc', []);
}
</script>
