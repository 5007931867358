<template>
    <Card :title="consumable.name"
          title-bg="indigo-1"
          header-toolbar no-card-section hide-actions>
        <template v-slot:headerActions>
            <q-btn v-if="_.get(can, 'index')"
                   :label="$translate('buttons.back')"
                   @click="router.get($route('consumables.index'))"
                   icon="o_chevron_left"
                   color="indigo-3"
                   dense no-wrap
            />
        </template>
        <template v-slot:default>
            <q-splitter v-model="splitterModel" unit="px"
                        :horizontal="$q.screen.lt.md">
                <template v-slot:before>
                    <q-tabs v-model="tab" vertical no-caps>
                        <q-tab name="common" :label="$translate('consumables.title.base-info')"/>
                        <q-tab name="transform" :label="$translate('consumables.title.transform')"/>
                        <q-tab name="usage-in-equipment" :label="$translate('consumables.title.usage-in-equipment')"/>
                        <q-tab name="usage-in-project" :label="$translate('consumables.title.usage-in-project')"/>
                    </q-tabs>
                </template>
                <template v-slot:after>
                    <q-tab-panels v-model="tab" animated swipeable vertical>
                        <q-tab-panel name="common">
                            <component :is="showBlock('Common')" :consumable="consumable"/>
                            <component :is="showBlock('Chart')" :consumable-id="consumable.id"/>
                        </q-tab-panel>
                        <q-tab-panel name="transform">
                            <component :is="showBlock('Transform')" :consumable-id="consumable.id"/>
                        </q-tab-panel>
                        <q-tab-panel name="usage-in-equipment">
                            <component :is="showBlock('UsageInEquipment')" :consumable-id="consumable.id"/>
                        </q-tab-panel>
                        <q-tab-panel name="usage-in-project">
                            <component :is="showBlock('UsageInResearch')" :consumable-id="consumable.id"/>
                        </q-tab-panel>
                    </q-tab-panels>
                </template>
            </q-splitter>
        </template>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import * as ConsumablesBlocks from "@/Components/Consumables/Block";
import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import {useQuasar} from 'quasar'

const $q = useQuasar()

const props = defineProps({
    consumable: Object,
    can: Object
});

const showBlock = (block) => ConsumablesBlocks[block]

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const splitterModel = ref(250);
const tab = ref('common');
</script>
