<template>
    <q-card style="max-height: 90vh">
        <q-card-section class="text-uppercase text-center bg-grey-1 text-weight-bold">
            {{ $translate('module-auth.form.registr.title') }}
        </q-card-section>
        <q-separator/>
        <div style="max-height: 80vh" class="scroll">
            <q-banner class="bg-yellow-2 q-ma-md" v-if="banner">
                <template v-slot:avatar>
                    <q-icon name="warning_amber" color="deep-orange-6"/>
                </template>
                <div v-html="banner"></div>
            </q-banner>

            <q-input v-model="form.last_name" id="last_name" bottom-slots
                     :label="$translate('users.fields.last_name')+'*'" label-color="blue-grey"
                     class="q-mx-md"
                     :error-message="form.errors.last_name"
                     :error="form.errors.hasOwnProperty('last_name')">
                <template v-slot:append v-if="!form.errors.hasOwnProperty('last_name')">
                    <q-icon name="o_person" color="blue-grey"/>
                </template>
            </q-input>

            <q-input v-model="form.first_name" id="first_name" bottom-slots
                     :label="$translate('users.fields.first_name')+'*'" label-color="blue-grey"
                     class="q-mx-md"
                     :error-message="form.errors.first_name"
                     :error="form.errors.hasOwnProperty('first_name')">
                <template v-slot:append v-if="!form.errors.hasOwnProperty('first_name')">
                    <q-icon name="o_person" color="blue-grey"/>
                </template>
            </q-input>

            <q-input v-model="form.middle_name" id="middle_name" bottom-slots
                     :label="$translate('users.fields.middle_name')" label-color="blue-grey"
                     class="q-mx-md"
                     :error-message="form.errors.middle_name"
                     :error="form.errors.hasOwnProperty('middle_name')">
                <template v-slot:append v-if="!form.errors.hasOwnProperty('middle_name')">
                    <q-icon name="o_person" color="blue-grey"/>
                </template>
            </q-input>

            <q-input v-model="form.email" id="email" bottom-slots
                     :label="$translate('users.fields.email')+'*'" label-color="blue-grey"
                     class="q-mx-md"
                     :error-message="form.errors.email"
                     :error="form.errors.hasOwnProperty('email')">
                <template v-slot:append v-if="!form.errors.hasOwnProperty('email')">
                    <q-icon name="o_email" color="blue-grey"/>
                </template>
            </q-input>

            <q-input v-model="form.password" id="password" bottom-slots type="password"
                     :label="$translate('module-auth.form.registr.password')+'*'" label-color="blue-grey"
                     class="q-mx-md"
                     :error-message="form.errors.password"
                     :error="form.errors.hasOwnProperty('password')">
                <template v-slot:append v-if="!form.errors.hasOwnProperty('password')">
                    <q-icon name="password" color="blue-grey"/>
                </template>
            </q-input>


            <q-input v-model="form.password_confirmation" id="password_confirmation" bottom-slots type="password"
                     :label="$translate('module-auth.form.registr.password2')+'*'" label-color="blue-grey"
                     class="q-mx-md"
                     :error-message="form.errors.password_confirmation"
                     :error="form.errors.hasOwnProperty('password_confirmation')">
                <template v-slot:append v-if="!form.errors.hasOwnProperty('password_confirmation')">
                    <q-icon name="password" color="blue-grey"/>
                </template>
            </q-input>


            <div class="q-mx-md">
                <div class="row inline no-wrap align-center" v-if="PERSONAL_DATA">
                    <q-icon size="sm" :name="form.confirm_pd?'done':'close'"
                            :color="form.confirm_pd?'positive':'blue-grey'"/>
                    <div @click="confirm_pd = true" class="q-ml-sm cursor-pointer">
                        {{ $translate('module-auth.confirm-pd') }}
                    </div>
                </div>
                <q-dialog v-model="confirm_pd" size="md">
                    <q-card>
                        <q-bar class="row items-center q-pb-none">
                            <div class="text-weight-bold text-deep-orange-10">
                                {{ $translate('module-auth.modal-confirm-pd-title') }}
                            </div>
                            <q-space/>
                            <q-btn icon="close" flat round dense v-close-popup/>
                        </q-bar>

                        <q-card-section style="max-height: 50vh" class="scroll">
                            <div v-html="PERSONAL_DATA"></div>
                            <q-checkbox v-model="form.confirm_pd"
                                        :label="$translate('module-auth.modal-confirm-pd-checkbox')"
                                        @click="confirm_pd = !confirm_pd"/>
                        </q-card-section>
                    </q-card>
                </q-dialog>
                <div class="row inline no-wrap align-center" v-if="PRIVACY_POLICY">
                    <q-icon size="sm" :name="form.confirm_pp?'done':'close'"
                            :color="form.confirm_pp?'positive':'blue-grey'"/>
                    <div @click="confirm_pp = true" class="q-ml-sm cursor-pointer">
                        {{ $translate('module-auth.confirm-pp') }}
                    </div>
                </div>
                <q-dialog v-model="confirm_pp" size="md">
                    <q-card>
                        <q-bar class="row items-center q-pb-none">
                            <div class="text-weight-bold text-deep-orange-10">
                                {{ $translate('module-auth.modal-confirm-pp-title') }}
                            </div>
                            <q-space/>
                            <q-btn icon="close" flat round dense v-close-popup/>
                        </q-bar>

                        <q-card-section style="max-height: 50vh" class="scroll">
                            <div v-html="PRIVACY_POLICY"></div>
                            <q-checkbox v-model="form.confirm_pp"
                                        :label="$translate('module-auth.modal-confirm-pd-checkbox')"
                                        @click="confirm_pp = !confirm_pp"/>
                        </q-card-section>
                    </q-card>
                </q-dialog>
            </div>
        </div>
        <q-card-section class="text-center">
            <q-btn color="positive" @click="submit" :loading="form.processing">
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
                <div>{{ $translate('buttons.register') }}</div>
            </q-btn>
        </q-card-section>
        <q-card-section class="flex justify-between align-center">
            <q-btn color="secondary" :href="$route('login')">
                <div class="">{{ $translate('buttons.login') }}</div>
                <q-icon right size="xs" name="login"/>
            </q-btn>

            <q-btn flat href="https://docs.researchims.ru/common/login/" target="_blank" size="md"
                   color="deep-orange-10">
                <div class="text-lowercase">{{ $translate('module-auth.form.problem') }}</div>
            </q-btn>
        </q-card-section>
    </q-card>
</template>

<script setup>
import Guest from "@/Layout/Guest.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {computed, ref} from "vue";

defineOptions({layout: Guest})

const page = usePage()
const options = computed(() => page.props.options)
const banner = computed(() => page.props.banner)
const PERSONAL_DATA = computed(() => page.props.PERSONAL_DATA)
const PRIVACY_POLICY = computed(() => page.props.PRIVACY_POLICY)
const confirm_pd = ref()
const confirm_pp = ref()
const form = useForm({
    last_name: '',
    first_name: '',
    middle_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    confirm_pd: false,
    confirm_pp: false
})

const submit = () => {
    form
        .transform(data => ({
            ...data
        }))
        .post(route('register'), {
            onFinish: () => form.reset('password'),
        })
}
</script>