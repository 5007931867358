<template>
    <Layout :department="department" tab="staff">
        <q-bar class="bg-grey-2 q-py-sm q-mb-sm">
            <h6 class="q-my-none">{{ $translate('ssc.title-staff-sicklist') }}</h6>
            <q-space/>
            <q-select v-model="currentYear"
                      :options="years"
                      @update:modelValue="selectYear"
                      :prefix="$translate('на')"
                      :suffix="$translate('units.year')"
                      dense/>
            <q-btn-toggle v-model="tab"
                          spread no-caps
                          toggle-color="blue-grey-3" color="white" text-color="black"
                          :options="[{value: 'table', slot:'table'},{value: 'chart',slot:'chart'}]"
                          @update:model-value="onUpdateTab"
            >
                <template v-slot:table>
                    <q-icon name="sym_o_table" size="sm"/>
                    <q-tooltip>{{ $translate('buttons.view-as-table') }}</q-tooltip>
                </template>
                <template v-slot:chart>
                    <q-icon name="sym_o_grouped_bar_chart" size="sm"/>
                    <q-tooltip>{{ $translate('buttons.view-as-chart') }}</q-tooltip>
                </template>
            </q-btn-toggle>
        </q-bar>
        <template v-if="tab==='table'">
            <q-list separator>
                <q-item class="text-bold">
                    <q-item-section class="col-4">
                        <q-item-label>{{ $translate('cadre-staff.fields.staff') }}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label class="text-center">{{ $translate('cadre-sicklist.label.period') }}</q-item-label>
                    </q-item-section>
                </q-item>
                <template v-for="(item) in staff">
                    <q-item>
                        <q-item-section class="col-4">
                            <q-item-label>
                                {{ item.name }}
                            </q-item-label>
                            <q-item-label caption>
                                {{ _.get(item, 'cadre_position.name') }} {{ _.get(item, 'rate_formatted') }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-list dense separator>
                                <template v-for="(sicklist) in item.sicklist">
                                    <StaffSicklistItem :sicklist="sicklist"/>
                                </template>
                            </q-list>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </template>
        <template v-if="tab==='chart'">
            <Chart v-if="!_.isEmpty(chartData)"
                   chart-type="gantt-date"
                   :data="chartData"
                   :config="chartConfig"
                   :height="chartHeight"
            />
        </template>
    </Layout>
</template>

<script setup lang="ts">
import Layout from "@/Pages/SSC/Layout.vue";
import {computed, ComputedRef, getCurrentInstance, Ref, ref} from "vue";
import {router} from "@inertiajs/vue3";
import {CadreStaff, CadreSicklist} from "@/entity";
import _ from "lodash";
import moment from "moment/moment";
import {IGanttDateChartConfig} from "@/entity/charts";
import Chart from "@/Components/Chart.vue";
import {AnyValuesObject, StringValuesObject} from "@/entity/types";
import StaffSicklistItem from "@/Components/SSC/Staff/StaffSicklistItem.vue";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

interface Props {
    department: object,
    staff: CadreStaff[],
    years: [],
    year?: number | null,
}

const props = defineProps<Props>()

const tab = ref('table')

const loading = ref(false)


const currentYear = ref(props.year || moment().year())


/**
 * переход на страницу больничных за год
 *
 * @param value
 * @return CadreVacation[]
 */
const selectYear = (value: number) => {
    router.visit($route('ssc.staff.sicklist', {ssc: props.department, year: value}));
}

const chartData: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);
const chartConfig: ComputedRef<IGanttDateChartConfig> = computed<IGanttDateChartConfig>(() => ({
    chartSettings: {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
    },
    chartWidgets: {
        cursor: { behavior: "zoomX" },
    },
    categoryYAxis: {
        categoryField: "name",
        categories: [],
        rendererSettings: {
            minGridDistance: 30
        },
        rendererGridTemplateLocation: 1
    },
    dateXAxis: {
        baseInterval: { timeUnit: "day", count: 1 },
        gridIntervals: [
            { timeUnit: "day", count: 1 },
            { timeUnit: "week", count: 1 },
            { timeUnit: "month", count: 1 },
        ],
        additionalSettings: {
            min: moment(currentYear.value + "-01-01").toDate().getTime(),
            max: moment(currentYear.value + "-12-31").toDate().getTime(),
            strictMinMax: true,
            tooltipDateFormat: "dd.MM.yyyy"
        },
        rendererSettings: {
            strokeOpacity: 0.1
        }
    },
    columnSeries: {
        openValueXField: "date_start",
        valueXField: "date_end",
        categoryYField: "name",
        tooltipText: "Больничный ({status_text}):\n[bold]{openValueX.formatDate('dd.MM.yyyy')}[/] - [bold]{valueX.formatDate('dd.MM.yyyy')}[/]\n{comment}",
        dataFieldToProcess: "sicklist",
        dateFields: ["date_start", "date_end"],
        dateFormat: "yyyy-MM-dd",
    },
    customColorLogic: {
        fieldName: "status",
        fieldValues: [0, 10, 20, 99]
    }
}));
const chartHeight: Ref<string> = ref<string>("80vh");

const refillChartData: Function = () => {
    chartData.value = [];
    chartConfig.value.categoryYAxis.categories = [];
    let categories: StringValuesObject[] = [], data: AnyValuesObject[] = [];
    _.forEach(props.staff, (staff: CadreStaff) => {
        categories.push({
            [chartConfig.value.categoryYAxis.categoryField]: staff[chartConfig.value.categoryYAxis.categoryField]
        });
        let processedData: AnyValuesObject = {
            [chartConfig.value.categoryYAxis.categoryField]: staff[chartConfig.value.categoryYAxis.categoryField],
            [chartConfig.value.columnSeries.dataFieldToProcess]: []
        };
        _.forEach(staff[chartConfig.value.columnSeries.dataFieldToProcess], (sicklist: CadreSicklist) => {
            let valueXField: string | null = sicklist[chartConfig.value.columnSeries.valueXField];
            if (_.isNull(valueXField)) {
                valueXField = moment().format(moment.HTML5_FMT.DATE);
            }
            if (!_.isNull(sicklist[chartConfig.value.columnSeries.openValueXField])) {
                processedData[chartConfig.value.columnSeries.dataFieldToProcess].push({
                    [chartConfig.value.columnSeries.openValueXField]: sicklist[chartConfig.value.columnSeries.openValueXField],
                    [chartConfig.value.columnSeries.valueXField]: valueXField,
                    [chartConfig.value.columnSeries.categoryYField]: staff[chartConfig.value.columnSeries.categoryYField],
                    status: sicklist.status,
                    status_text: sicklist.status_text,
                    comment: sicklist.comment
                });
            }
        });
        data.push(processedData);
    });
    chartData.value = data;
    chartConfig.value.categoryYAxis.categories = _.reverse(
        _.sortBy(categories, chartConfig.value.categoryYAxis.categoryField)
    );
    if (1 === _.size(chartConfig.value.categoryYAxis.categories)) {
        chartHeight.value = "250px";
    }
};
const onUpdateTab = () => {
    if ("chart" === tab.value) {
        refillChartData();
    }
};
</script>
