<template>
    <Card :title="_.get(publication,'name')"
          title-bg="purple-1"
          header-toolbar hide-actions>
        <component :is="component"
                   :publication="publication"/>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import _ from "lodash";
import * as ReportMaterial from '@/Components/ReportMaterial'
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    publication: {type: Object}
})

const component = computed(() => ReportMaterial[_.upperFirst(_.get(props.publication, 'report_type_category'))])
</script>