<template>
    <q-list v-if="nodes" dense>
        <template v-for="(node) in nodes">
            <q-item :style="inset" class="row items-center" :active-class="activeClass" clickable
                    :active="(_.isArray(activeElement)?activeElement.includes(node.id):activeElement === node.id)">
                <div class="q-pl-sm flex items-center">
                    <q-icon :name="iconName(node)"
                            size="xs"
                            class="cursor-pointer self-center q-mr-sm"
                            @click="toggleCollapse(node.id)"
                    />
                </div>
                <slot name="name" :node="node">
                    <q-item-label>{{ node.name }}</q-item-label>
                </slot>
                <q-space/>
                <slot name="addons" :node="node"/>
                <q-space/>
                <slot name="actions" :node="node"/>
            </q-item>

            <template v-if="hasChildren(node.id) && !collapsedItem(node.id)">
                <ElementTree :items="items" :level="(props.level + 1)"
                             :parent="node.id"
                             :active-element="activeElement"
                             :expand-tree="expandTree">
                    <template v-slot:name="node">
                        <slot name="name" :node="node.node"></slot>
                    </template>
                    <template v-slot:addons="node">
                        <slot name="addons" :node="node.node"></slot>
                    </template>
                    <template v-slot:actions="node">
                        <slot name="actions" :node="node.node"></slot>
                    </template>
                </ElementTree>
            </template>
        </template>
    </q-list>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import _ from "lodash";

const props = defineProps({
    items: {type: Object, required: true},
    level: {type: Number, default: 0},
    parent: {type: Number, default: 0},
    activeElement: {default: null},
    activeClass: {type: String, default: 'text-primary bg-blue-grey-1'},
    expandElement: {type: Boolean, default: false},
    expandTree: {type: Boolean, default: false},
})
const inset = computed(() => {
    return 'padding-left: ' + (props.level) * 20 + 'px';
})

const nodes = computed(() => {
    return _.sortBy(_.filter(props.items, {parent_id: props.parent}), 'name');
})

const iconName = (node) => {
    return hasChildren(node.id) ? (collapsedItem(node.id) ? 'o_add_box' : 'o_indeterminate_check_box') : 'subdirectory_arrow_right'
}
const collapsed = ref([])

const toggleCollapse = (id) => {
    if (_.includes(collapsed.value, id)) {
        collapsed.value = _.filter(collapsed.value, (v) => v !== id)
    } else {
        collapsed.value.push(id);
    }
}

const collapsedItem = (id) => !(props.expandElement || _.includes(collapsed.value, id));

const hasChildren = (parent) => {
    return (_.findIndex(props.items, {parent_id: parent}) >= 0);
}

onMounted(() => {
    if (props.expandTree) {
        _.forEach(props.items, (item) => {
            collapsed.value.push(item.id);
        })
    }
})

</script>
