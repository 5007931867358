<template>
    <q-bar class="bg-grey-1 q-py-sm q-mb-sm">
        <h6 class="q-my-none">{{ $translate('cadre-staff.label.summary-chart') }}</h6>
        <q-space/>
        <q-select v-model="currentYear"
                  @update:modelValue="loadItems"
                  :options="optionsYears"
                  :prefix="$translate('на')"
                  :suffix="$translate('units.year')"
                  options-dense
                  dense
        />
    </q-bar>
    <Chart v-if="!_.isEmpty(chartData)"
           chart-type="gantt-date"
           :data="chartData"
           :config="chartConfig"
           height="700px"
    />
</template>

<script setup lang="ts">
import Chart from "@/Components/Chart.vue";
import {ComponentInternalInstance} from "@vue/runtime-core";
import {computed, ComputedRef, getCurrentInstance, onMounted, Ref, ref} from "vue";
import {usePage} from "@inertiajs/vue3";
import {AnyValuesObject} from "@/entity/types";
import {IGanttDateChartConfig} from "@/entity/charts";
import {CadreStaff} from "@/entity";
import moment from "moment/moment";
import axios from "axios";
import _ from "lodash";

const {staff} = defineProps<{ staff: CadreStaff }>();

const app: ComponentInternalInstance | null = getCurrentInstance();
const $translate: Function = app!.appContext.config.globalProperties.$translate;
const $route: Function = app!.appContext.config.globalProperties.$route;

const currentYear: Ref<number> = ref<number>(moment().year());
const optionsYears: ComputedRef<any> = computed(() => usePage().props.summaryYears);

const chartData: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);
const chartConfig: ComputedRef<IGanttDateChartConfig> = computed<IGanttDateChartConfig>(() => ({
    chartSettings: {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
    },
    chartWidgets: {
        cursor: {behavior: "zoomX"},
    },
    categoryYAxis: {
        categoryField: "category",
        categories: [],
        rendererSettings: {
            minGridDistance: 30
        },
        rendererGridTemplateLocation: 1
    },
    dateXAxis: {
        baseInterval: {timeUnit: "day", count: 1},
        gridIntervals: [
            {timeUnit: "day", count: 1},
            {timeUnit: "week", count: 1},
            {timeUnit: "month", count: 1},
        ],
        additionalSettings: {
            min: moment(currentYear.value + "-01-01").toDate().getTime(),
            max: moment(currentYear.value + "-12-31").toDate().getTime(),
            strictMinMax: true,
            tooltipDateFormat: "dd.MM.yyyy"
        },
        rendererSettings: {
            strokeOpacity: 0.1
        }
    },
    columnSeries: {
        openValueXField: "date_start",
        valueXField: "date_end",
        categoryYField: "category",
        tooltipText: "{context}: {type_text}\n({status_text}):\n[bold]{openValueX.formatDate('dd.MM.yyyy')}[/] - [bold]{valueX.formatDate('dd.MM.yyyy')}[/] ({days} дней)\n{comment}",
        dataFieldToProcess: "summary",
        dateFields: ["date_start", "date_end"],
        dateFormat: "yyyy-MM-dd",
    },
}));

const loadItems = () => {
    chartData.value = [];
    chartConfig.value.categoryYAxis.categories = [];
    axios
            .get($route("ajax.cadre.staff", {
                m: "summary",
                staff_id: staff.id,
                user_id: staff.user_id,
                year: currentYear.value
            }))
            .then((response) => {
                _.forEach(response.data.results, (item: AnyValuesObject) => {
                    chartConfig.value.categoryYAxis.categories.push({
                        [chartConfig.value.categoryYAxis.categoryField]: item[chartConfig.value.categoryYAxis.categoryField]
                    })
                });
                chartData.value = response.data.results;
            })
};

onMounted(() => {
    loadItems();
});
</script>
