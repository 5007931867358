<template>
    <Card title-bg="indigo-2"
          :title="$translate('module-microcollections.type.index.title')"
          title-text="indigo-10"
          header-toolbar
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn v-if="_.get(can, 'create')"
                   :label="$translate('buttons.add')"
                   @click="router.get($route('services.microcollections.type.create'))"
                   color="indigo-5"
                   icon="o_add"
                   no-caps
                   dense
            />
        </template>
        <q-list separator>
            <template v-for="(type, i) in _.get(types, 'data')" :key="i">
                <q-item>
                    <q-item-section>
                        <q-item-label>{{ type.name }}</q-item-label>
                        <q-item-label caption lines="1">{{ type.description }}</q-item-label>
                        <q-item-label caption lines="1">
                            {{ $translate('module-microcollections.type.index.elements_count') }}: {{ _.get(type, 'elements_count') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-item-label>
                            <q-chip :label="publicState(type)"
                                    :color="_.get(type, 'public_state') ? 'positive' : 'negative'"
                                    text-color="white"
                                    size="12px"
                            />
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <DataTableActions :actions="type.actions" :name="type.name" @on-success="onSuccess"/>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import DataTableActions from "@/Components/DataTableActions.vue";
import {getCurrentInstance} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    types: Object,
    can: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const publicState = (type) => {
    let keys = {true: 'shown', false: 'hidden'};
    return $translate('module-microcollections.type.index.public_state.' + keys[_.get(type, 'public_state')]);
};

const onSuccess = () => {
    router.reload();
};
</script>
