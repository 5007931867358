<template>
    <q-select :label="$translate('ssc.documents.fields.type')"
              v-model="form.document_type_id"
              :options="documentType" options-dense
              emit-value map-options
              :error-message="_.get(form,'errors.document_type_id')"
              :error="form.errors.hasOwnProperty('document_type_id')">

    </q-select>
    <q-field :label="$translate('ssc.documents.fields.name')" borderless>
        <template v-slot:control>
            <template v-for="(lang_name, locale) in $translate('fields.locale')">
                <q-input class="full-width" v-model="form.name_i18n[locale]" required
                         :hint="lang_name" dense
                         :error-message="_.get(form,'errors.name_i18n.'+locale)"
                         :error="form.errors.hasOwnProperty('name_i18n.'+locale)"></q-input>
            </template>
        </template>
    </q-field>
    <q-file :label="$translate('ssc.documents.fields.file')"
            v-model="form.file"
            :error-message="_.get(form,'errors.file')"
            :error="form.errors.hasOwnProperty('file')">
        <template v-slot:prepend>
            <q-icon name="attach_file"/>
        </template>
    </q-file>
    <div>
        <q-checkbox v-model="form.external"
                    :label="$translate('ssc.documents.fields.external')"/>
    </div>
    <div>
        <q-checkbox v-model="form.confirm"
                    :label="$translate('ssc.documents.fields.confirm-help')"/>
    </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = defineModel({type: Object});

defineProps({
    documentType: {}
})
</script>