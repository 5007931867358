<template>
    <div class="sensor-measurements-chart" ref="chartRef"></div>
</template>

<script setup>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_ru_RU from "@amcharts/amcharts5/locales/ru_RU";
import {onBeforeUnmount, onMounted, ref, shallowRef, watch} from "vue";
import _ from "lodash";

const props = defineProps({
    sensor: Object,
    data: Array
});

const chartRef = ref(null);

const am5Root = shallowRef({ root: null });
const am5Chart = shallowRef({ chart: null });
const am5xAxis = shallowRef({ xAxis: null });
const am5Legend = shallowRef({ legend: null });

const createChart = () => {
    let root = am5.Root.new(chartRef.value);
    root.setThemes([am5themes_Animated.new(root)]);
    root.locale = am5locales_ru_RU;
    root._logo.dispose();
    am5Root.value = { root: root };

    let chart = am5Root.value.root.container.children.push(
        am5xy.XYChart.new(am5Root.value.root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            layout: am5Root.value.root.verticalLayout
        })
    );
    am5Chart.value = { chart: chart };

    am5Chart.value.chart.get("colors").set("colors", [
        am5.color("#FF0000"),
        am5.color("#0000FF"),
        am5.color("#FF8C00"),
        am5.color("#7CFC00"),
        am5.color("#FF1493"),
        am5.color("#808000"),
        am5.color("#800000"),
        am5.color("#008000"),
        am5.color("#9932CC"),
        am5.color("#00FFFF"),
        am5.color("#D2691E"),
        am5.color("#FF00FF"),
        am5.color("#B8860B"),
    ]);

    let dateAxis = am5Chart.value.chart.xAxes.push(
        am5xy.DateAxis.new(am5Root.value.root, {
            maxDeviation: 0,
            baseInterval: {
                timeUnit: "minute",
                count: 1
            },
            gridIntervals: [
                { timeUnit: "minute", count: 1 },
                { timeUnit: "minute", count: 5 },
                { timeUnit: "minute", count: 10 },
                { timeUnit: "minute", count: 15 },
                { timeUnit: "minute", count: 30 },
                { timeUnit: "hour", count: 1 },
                { timeUnit: "hour", count: 3 },
                { timeUnit: "hour", count: 6 },
                { timeUnit: "hour", count: 12 },
                { timeUnit: "day", count: 1 },
                { timeUnit: "day", count: 2 },
                { timeUnit: "day", count: 3 },
                { timeUnit: "day", count: 4 },
                { timeUnit: "day", count: 5 },
                { timeUnit: "week", count: 1 },
                { timeUnit: "month", count: 1 },
                { timeUnit: "month", count: 2 },
                { timeUnit: "month", count: 3 },
                { timeUnit: "month", count: 6 },
                { timeUnit: "year", count: 1 }
            ],
            renderer: am5xy.AxisRendererX.new(am5Root.value.root, {}),
            tooltip: am5.Tooltip.new(am5Root.value.root, {})
        })
    );
    let xRenderer = dateAxis.get("renderer");
    xRenderer.labels.template.setAll({
        rotation: 47,
        centerY: am5.p50
    });
    am5xAxis.value = { xAxis: dateAxis };

    let cursor = am5Chart.value.chart.set("cursor", am5xy.XYCursor.new(am5Root.value.root, {
        behavior: "none"
    }));
    // cursor.lineY.set("visible", false);

    let scrollbarX = am5Chart.value.chart.set("scrollbarX", am5.Scrollbar.new(am5Root.value.root, {
        orientation: "horizontal",
        maxHeight: 6
    }));
    scrollbarX.startGrip.set("scale", 0.7);
    scrollbarX.endGrip.set("scale", 0.7);

    let legend = am5Chart.value.chart.children.push(am5.Legend.new(am5Root.value.root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        centerY: am5.percent(50),
    }));
    am5Legend.value = { legend: legend };
};

const getYAxisMax = (data) => {
    let max = Number(_.get(_.maxBy(data, 'value'), 'value'));
    return 1 === max ?
        _.add(max, 1) :
        _.ceil(_.add(max, _.multiply(max, 0.2)));
};
const getYAxisMin = (data) => {
    let max = getYAxisMax(data);
    return 2 === max ?
        _.subtract(max, 3) :
        _.multiply(getYAxisMax(data), 0.2);
};
const createRange = (yAxis, value, endValue, label, color, dashed) => {
    let rangeDataItem = yAxis.makeDataItem({
        value: value,
        endValue: endValue
    });
    let range = yAxis.createAxisRange(rangeDataItem);
    if (endValue) {
        range.get("axisFill").setAll({
            fill: color,
            fillOpacity: 0.2,
            visible: true
        });
    } else {
        range.get("grid").setAll({
            stroke: color,
            strokeOpacity: 0.7,
            strokeWidth: 0.8,
            location: 1
        });
        if (dashed) {
            range.get("grid").set("strokeDasharray", [10, 10]);
        }
    }
    if (label) {
        range.get("label").setAll({
            text: label,
            location: 1,
            fontSize: 19,
            inside: true,
            centerX: am5.p0,
            centerY: am5.p100
        });
    }
};
const addLimits = (yAxis, lower, upper, color) => {
    // Add range fill
    //this._createRange(yAxis, lower, upper, undefined, am5.color(0xffce00));
    // Add upper/average/lower lines
    createRange(yAxis, lower, undefined, undefined, color, true);
    createRange(yAxis, upper, undefined, undefined, color, true);
    //createRange(lower + (upper - lower) / 2, undefined, "Process average", am5.color(0x4d00ff), true);
};

const refillData = () => {
    am5Chart.value.chart.yAxes.clear();
    am5Chart.value.chart.series.clear();
    _.forEach(props.data, (measurementGroup, i) => {
        let yRenderer = am5xy.AxisRendererY.new(am5Root.value.root, {
            opposite: Boolean(i),
            strokeWidth: 1.5
        });
        let valueAxis = am5Chart.value.chart.yAxes.push(
            am5xy.ValueAxis.new(am5Root.value.root, {
                extraTooltipPrecision: 1,
                //numberFormat: this.config.valueAxis.numberFormat,
                max: getYAxisMax(measurementGroup.data),
                min: getYAxisMin(measurementGroup.data),
                maxDeviation: 1,
                renderer: yRenderer,
                tooltip: am5.Tooltip.new(am5Root.value.root, {})
            })
        );
        if (am5Chart.value.chart.yAxes.indexOf(valueAxis) > 0) {
            valueAxis.set("syncWithAxis", am5Chart.value.chart.yAxes.getIndex(0));
        }

        let series = am5Chart.value.chart.series.push(
            am5xy.LineSeries.new(am5Root.value.root, {
                name: measurementGroup.info.name,
                xAxis: am5xAxis.value.xAxis,
                yAxis: valueAxis,
                valueYField: "value",
                valueXField: "date",
                tooltip: am5.Tooltip.new(am5Root.value.root, {
                    pointerOrientation: "horizontal",
                    labelText: `${measurementGroup.info.name}: [bold]{valueY}{descr}[/]`
                })
            })
        );
        // series.bullets.push(() => am5.Bullet.new(this.root, {
        //     sprite: am5.Circle.new(this.root, {
        //         radius: 5,
        //         fill: series.get("fill")
        //     })
        // }));
        series.strokes.template.setAll({
            strokeWidth: 1.5
        });
        yRenderer.grid.template.set("strokeOpacity", 0.05);
        yRenderer.labels.template.set("fill", series.get("fill"));
        yRenderer.setAll({
            stroke: series.get("fill"),
            strokeOpacity: 1,
            opacity: 1,
        });
        series.data.setAll(measurementGroup.data);
        series.appear(1000);
        // series.events.on("datavalidated", () => this.yAxis.setAll({
        //     min: this.yAxis.getPrivate("min"),
        //     max: this.yAxis.getPrivate("max"),
        //     start: 0,
        //     end: 1
        // }));
        addLimits(
            valueAxis,
            _.get(props.sensor, 'sensor_group.allowable_measurements')[i].min_value,
            _.get(props.sensor, 'sensor_group.allowable_measurements')[i].max_value,
            series.get("fill")
        );
    });
    am5Chart.value.chart.appear(1000, 100);
    am5Legend.value.legend.data.setAll(am5Chart.value.chart.series.values);
};

watch(props, () => {
    refillData();
});

onMounted(() => {
    createChart();
    refillData();
});
onBeforeUnmount(() => {
    if (am5Chart.value.chart) {
        am5Chart.value.chart.dispose();
    }
});
</script>

<style scoped>
.sensor-measurements-chart {
    width: 100%;
    height: 500px;
}
</style>