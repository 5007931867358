<template>
    <div v-if="data.module.cadre.enabled">
        <Alert v-if="$translate('module-cadre.description')"
               variant="info"
               :message="$translate('module-cadre.description')"
        />
        <SettingsTable :data="config"/>
    </div>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";
import helpers  from "@/helpers.js";


const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const config = [
    {
        name: 'CADRE_ENABLED',
        label: $translate('module-cadre.settings.CADRE_ENABLED'),
        value: _.get(props.settings, 'module.CADRE_ENABLED'),
        description: $translate('module-cadre.settings.CADRE_ENABLED_description'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'CADRE_STRUCTURE',
        label: $translate('module-cadre.settings.CADRE_STRUCTURE'),
        value: _.get(props.settings, 'module.CADRE_STRUCTURE'),
        description: $translate('module-cadre.settings.CADRE_STRUCTURE_description'),
        block: 'module',
        type: 'select',
        multiple: false,
        options: _.get(props.data.module, 'cadre.CADRE_STRUCTURE_options')
    },
    {
        name: 'CADRE_VACATION_TEMPLATE',
        label: $translate('module-cadre.settings.CADRE_VACATION_TEMPLATE'),
        value: helpers.jsonParse(_.get(props.settings, 'module.CADRE_VACATION_TEMPLATE')),
        append: _.get(helpers.jsonParse(_.get(props.settings, 'module.CADRE_VACATION_TEMPLATE', '[]')), 'path', null),
        description: $translate('module-cadre.settings.CADRE_VACATION_TEMPLATE_description'),
        block: 'module',
        type: 'file',
        props: {
            accept: '.docx'
        }
    },
    {
        name: 'CADRE_DEPARTMENT_LEADER',
        label: $translate('module-cadre.settings.CADRE_DEPARTMENT_LEADER'),
        value: _.get(props.settings, 'module.CADRE_DEPARTMENT_LEADER'),
        description: $translate('module-cadre.settings.CADRE_DEPARTMENT_LEADER_description'),
        block: 'module',
        type: 'checkbox',
        multiple: false,
    },
];
</script>
