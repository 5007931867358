<template>
    <Layout :department="department" tab="address">
        <q-bar class="bg-grey-2 q-py-sm">
            <h6 class="q-my-none">{{ $translate('ssc.title-address') }}</h6>
            <q-space/>
            <SSCAddressForm :department="department"/>
        </q-bar>
        <q-list separator>
            <template v-for="(address) in department.address">
                <q-item>
                    <q-item-section side>
                        <q-item-label>
                            <q-btn icon="o_local_shipping"
                                   :color="address.delivery?'positive':null"
                                   size="sm"
                                   :title="$translate('ssc.address.fields.delivery')"
                                   @click="toggleBool(address,'delivery')"
                                   flat/>
                            <q-btn icon="transfer_within_a_station"
                                   :color="address.self?'info':null"
                                   size="sm"
                                   :title="$translate('ssc.address.fields.self')"
                                   @click="toggleBool(address,'self')"
                                   flat/>
                        </q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>
                            {{ address.address }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn icon="o_delete" color="negative" flat @click="deleteAddress(address)"></q-btn>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Layout>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import {router} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import Layout from "@/Pages/SSC/Layout.vue";
import SSCAddressForm from "@/Components/SSC/SSCAddressForm.vue";

const $q = useQuasar();
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    department: {},
})

const toggleBool = (row, field) => {
    router.patch(route('ssc.address.' + field, [props.department, row]), {}, {
        onSuccess: () => {
            router.reload()
        }
    })
}
const deleteAddress = (row) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: row.address}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('ssc.address.delete', [props.department, row]), {
            onSuccess: () => {
                router.reload()
            }
        })
    })
}
</script>