<template>
    <div v-if="data.module.delivery.enabled">
        <Alert v-if="$translate('module-delivery.description')"
               variant="info"
               :message="$translate('module-delivery.description')"
        />
        <Alert v-if="$translate('module-delivery.description2')"
               variant="warning"
               :message="$translate('module-delivery.description2')"
        />
        <q-tabs v-model="tab" align="left" dense no-caps>
            <q-tab name="base" label="Базовые настройки"/>
            <q-tab name="contacts" :label="$translate('module-delivery.settings.DELIVERY_CONTACT_INFO')"/>
        </q-tabs>
        <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="base">
                <SettingsTable :data="config.base"/>
            </q-tab-panel>
            <q-tab-panel name="contacts">
                <SettingsTable :data="config.contacts"/>
            </q-tab-panel>
        </q-tab-panels>
    </div>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import helper from "@/helpers";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const tab = ref('base');

const config = {
    base: [
        {
            name: 'DELIVERY_ENABLED',
            label: $translate('module-delivery.settings.DELIVERY_ENABLED'),
            value: props.settings.module.DELIVERY_ENABLED,
            description: $translate('module-delivery.settings.DELIVERY_ENABLED_description'),
            block: 'module',
            type: 'checkbox',
        },
        {
            name: 'DELIVERY_SOURCE',
            label: $translate('module-delivery.settings.DELIVERY_SOURCE'),
            value: helper.jsonParse(props.settings.module.DELIVERY_SOURCE),
            description: $translate('module-delivery.settings.DELIVERY_SOURCE_description'),
            block: 'module',
            type: 'checkbox-group',
            options: _.map($translate('module-delivery.sources'), (value, key) => ({
                label: value,
                value: key
            }))
        },
    ],
    contacts: [
        {
            name: 'DELIVERY_ORDER_MEMBERS_ADDITIONAL_FIELDS',
            label: $translate('module-delivery.settings.DELIVERY_CONTACT_INFO_ORDER_MEMBER_INFO'),
            value: _.reject(helper.jsonParse(props.settings.module.DELIVERY_ORDER_MEMBERS_ADDITIONAL_FIELDS), _.isNull),
            description: $translate('module-delivery.settings.DELIVERY_CONTACT_INFO_ORDER_MEMBER_INFO_description'),
            block: 'module',
            type: 'select',
            multiple: true,
            options: props.data.module.delivery.classificatorUserData
        }
    ]
};
</script>
