<template>
    <Card :title="$translate('equipment.item.tabs.complex')" hide-actions no-card-section>
        <q-table :rows="equipment.complex" :columns="complexCols"
                 row-key="consumable.id"
                 flat bordered :rows-per-page-options="[0]" hide-pagination
                 wrap-cells>
            <template v-slot:top-row v-if="equipment.permission.edit">
                <q-tr>
                    <q-td colspan="100%">
                        <q-btn icon="add" :label="$translate('buttons.add')" @click="addDialog = true" no-caps/>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props">
                    <DataTableActions :actions="props.row.actions">
                        <q-item clickable v-if="!props.row.index"
                                @click="router.visit($route('equipment.show',props.row.id))"
                                v-close-popup>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('buttons.detail') }}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-icon name="o_pageview" size="xs"/>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-if="!props.row.index" @click="deleteItemComplex(props.row.id)"
                                v-close-popup>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('buttons.delete') }}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-icon name="o_delete" size="xs"/>
                            </q-item-section>
                        </q-item>
                    </DataTableActions>
                </q-td>
            </template>
            <template v-slot:body-cell-photo="props">
                <q-td :props="props">
                    <q-img :src="props.row.photo" v-if="props.row.photo" sizes="4rem"/>
                </q-td>
            </template>
            <template v-slot:body-cell-name="props">
                <q-td :props="props">
                    <q-item-label>{{ props.row.display_name }}</q-item-label>
                    <q-item-label overline>{{ props.row.serial }}</q-item-label>
                </q-td>
            </template>
            <template v-slot:body-cell-initial_cost="props">
                <q-td :props="props">
                    <q-item-label v-if="props.row.initial_cost">
                        {{ props.row.initial_cost }} {{ $translate('units.rub') }}
                    </q-item-label>
                    <q-item-label v-else class="text-negative">не указана первоначальная стоимость</q-item-label>
                </q-td>
            </template>
            <template v-slot:body-cell-year_placement="props">
                <q-td :props="props">
                    <q-item-label v-if="props.row.year_placement">{{ props.row.year_placement }}</q-item-label>
                    <q-item-label v-else class="text-negative">не указана дата постановки на учет</q-item-label>
                </q-td>
            </template>
            <template v-slot:body-cell-lifetime="props">
                <q-td :props="props">
                    <q-item-label v-if="props.row.initial_cost">
                        {{ props.row.lifetime }} {{ $translate('units.years') }}
                    </q-item-label>
                    <q-item-label v-else class="text-negative">не указан срок полезного использования</q-item-label>
                </q-td>
            </template>
            <template v-slot:body-cell-depreciation="props">
                <q-td :props="props">
                    <q-item-label>
                        {{ props.row.depreciation.toFixed(2) }} /
                        {{ props.row.depreciation_real.toFixed(2) }}
                    </q-item-label>
                </q-td>
            </template>
        </q-table>
    </Card>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <q-card style="width: 700px;max-width: 80vw">
            <q-bar>
                <div>Добавление</div>
                <q-space/>
                <q-btn flat dense icon="close" v-close-popup/>
            </q-bar>
            <q-card-section>
                <q-input filled square aria-autocomplete="none" autocomplete="none" :loading="loadingState"
                         v-model="search" debounce="1000" @update:model-value="searchEquipment"
                         :label="$translate('equipment.item.fields.complex-search')">
                </q-input>
            </q-card-section>
            <q-card-section v-if="search">
                <q-list separator dense v-if="searchedElements.length > 0">
                    <template v-for="(element) in searchedElements">
                        <q-item>
                            <q-item-section avatar height="3rem" width="3rem">
                                <q-img :src="element.photo" v-if="element.photo"
                                       height="2.5rem" width="2.5rem"
                                       fit="scale-down"/>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ element.display_name }}</q-item-label>
                                <q-item-label overline>{{ element.serial }}</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-btn dense icon="add_to_queue" flat @click="addItemComplex(element)"
                                       :title="$translate('buttons.add')"/>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-list>
            </q-card-section>
        </q-card>
    </q-dialog>

</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import axios from "axios";
import _ from "lodash"
import DataTableActions from "@/Components/DataTableActions.vue";
import {Card} from "@/Components/Block";
import {useQuasar} from "quasar";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar();

const props = defineProps({
    equipment: {
        type: Object
    }
})

/**
 *
 * @type {array}
 */
const complexCols = [
    {name: "actions", label: '', style: "width: 2rem",},
    {name: "photo", label: '', style: "width: 3rem",},
    {name: "name", label: $translate('equipment.item.title.complex-item'), align: 'left'},
    {name: "initial_cost", label: 'Начальная стоимость', align: 'right'},
    {name: "year_placement", label: 'Дата постановки', align: 'center'},
    {name: "lifetime", label: 'Срок использования', align: 'center'},
    {name: "depreciation", label: 'Амортизация/Стоимость приборочаса', align: 'center'},
]

const addDialog = ref(false)

const form = useForm({})

const search = ref(null)
const searchedElements = ref([])
const loadingState = ref(false)
const searchEquipment = (val) => {
    loadingState.value = true;
    axios.get('/equipment.json', {
        params: {
            m: 'complex',
            ssc_id: props.equipment.ssc_id,
            q: val
        }
    })
        .then((response) => {
            searchedElements.value = response.data.results;
        })
        .finally(() => loadingState.value = false)
}


const addItemComplex = (element) => {
    let newData = {
        complex: []
    }
    newData.complex.push({id: element.id})
    form
        .transform((data) => ({
            ...data,
            ...newData
        }))
        .patch(route('equipment.patch.complex', props.equipment), {
            onSuccess: () => {
                router.reload()
            }
        })
}

const deleteItemComplex = (index) => {
    let item = _.find(props.equipment.complex, {id: index})

    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: item.display_name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('equipment.patch.complex.delete', [props.equipment, index]),
            {
                onSuccess: () => {
                    router.reload()
                }
            })
    })
};
</script>