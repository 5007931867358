<template>
    <q-item dense v-if="itemCondition.hasOwnProperty('items')">
        <q-item-section v-if="itemCondition.items.length > 1"
                        class="border-right q-mr-md"
                        side>
            <q-btn @click="toggleOperator"
                   :label="labelOperator"
                   square/>
        </q-item-section>
        <q-item-section>
            <template v-for="(r, i) in itemCondition.items">
                <div class="row q-gutter-x-sm">
                    <div v-if="r.type === 'single'" class="row q-gutter-x-sm col">
                        <q-select v-model="itemCondition.items[i].condition"
                                  :options="conditionOptions"
                                  :label="$translate('fields.label.condition.rule')"
                                  class="col-6"
                                  @update:model-value="()=>{r.value = null}"
                                  emit-value
                                  map-options
                                  options-dense
                                  stack-label
                                  dense/>

                        <div v-if="!hasSelector" class="col">
                            <q-input v-if="field.type === 'input:date'"
                                     v-model="r.value"
                                     type="date"
                                     dense/>
                            <q-input v-else
                                     v-model="itemCondition.items[i].value"
                                     :label="$translate('fields.label.condition.value')"
                                     class="col"
                                     stack-label
                                     dense/>
                        </div>

                        <div v-if="hasSelector" class="col">
                            <q-select v-model="itemCondition.items[i].value"
                                      :options="options"
                                      :label="$translate('fields.label.condition.value')"
                                      :multiple="isMulti(r.condition)"
                                      stack-label
                                      emit-value
                                      map-options
                                      options-dense
                                      dense
                                      use-chips/>
                        </div>
                    </div>
                    <div v-else class="col border rounded-borders q-my-xs">
                        <q-item-label caption class="q-ml-xs">
                            <small>{{ $translate('fields.label.rule-group') }}</small>
                        </q-item-label>
                        <FieldConstructorRuleConditions v-model="itemCondition.items[i]" :field="field"/>
                    </div>
                    <q-btn icon="o_delete"
                           color="negative"
                           size="sm"
                           @click="deleteRule(i)"
                           flat
                           dense/>
                </div>
            </template>
        </q-item-section>
    </q-item>
    <div class="flex q-gutter-x-sm full-width q-mt-sm q-mb-xs">
        <q-btn size="sm" icon="add"
               :label="$translate('fields.actions.add-single-rule')"
               color="dark"
               @click="addRule('single')"
               outline
               dense/>
        <q-btn size="sm" icon="add"
               :label="$translate('fields.actions.add-group-rule')"
               color="dark"
               @click="addRule('group')"
               outline
               dense/>
    </div>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {useQuasar} from "quasar";
import _ from "lodash";
import {usePage} from "@inertiajs/vue3";
import ClassificationDB from "@/plugins/ClassificationDB";

const props = defineProps({
    item: {
        type: Object,
        default: {
            type: '',
            items: [],
            operator: 'and',
            condition: null,
            value: null,
        }
    },
    field: {
        type: Object
    }
})

const $q = useQuasar();
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

/**
 *
 * @type {ModelRef<Object, string>}
 */
const itemCondition = defineModel({
    type: Object,
    default() {
        return {
            type: '',
            items: [],
            operator: 'and'
        }
    }
})

/**
 * Переключатель оператора
 */
const toggleOperator = () => {
    if (itemCondition.value.operator === 'or') {
        itemCondition.value.operator = 'and'
    } else {
        itemCondition.value.operator = 'or'
    }
}
/**
 * Отображение оператора
 *
 * @type {ComputedRef<string>}
 */
const labelOperator = computed(() => {
    if (itemCondition.value.operator === 'or') {
        return $translate('buttons.or')
    }
    return $translate('buttons.and')
})

/**
 * Условия зависящие от типа поля
 * @type {ComputedRef<{label: string, value: string}[]>}
 */
const conditionOptions = computed(() => {
    return _.map(_.get($translate('fields.types'), _.get(props.field, 'type') + '.condition'), (v, k) => ({
        value: k,
        label: v
    }))
})

/**
 * Добавление правила
 *
 * @param type
 */
const addRule = (type) => {
    itemCondition.value.items.push({
        type: type,
        operator: 'and',
        items: [],
        condition: null,
        value: null,
    })
}

/**
 * Удаление правила
 * @param index
 */
const deleteRule = (index) => {
    _.pullAt(itemCondition.value.items, index)
}

/**
 * Есть список у типа поля?
 *
 * @type {ComputedRef<boolean>}
 */
const hasSelector = computed(() => {
    switch (props.field.type) {
        case "select":
        case "select:multi":
        case "input:checkbox":
        case "input:radio":
            return true;
        default:
            return false;
    }
})

/**
 * Может быть мультивыбором
 *
 * @param condition
 * @returns {boolean}
 */
const isMulti = (condition) => {
    if (
        ['input:checkbox', 'select:multi'].includes(props.field.type)
        &&
        ['eq', 'ne', 'contains', 'notcontains'].includes(condition)
    ) {
        return true;
    }
    return false;
}

const options = ref([])

onMounted(() => {
    switch (_.get(props.field, 'variants.type')) {
        case "self":
            options.value = _.map(props.field.variants.values, (v) => ({
                value: v.value,
                label: v.label[usePage().props.locale]
            }))
            break;
        case "classificator":
            ClassificationDB
                .getSimpleList(_.get(props.field, 'variants.classificator'))
                .then((r) => {
                    options.value = r;
                })
            break;
        case "science_classificator":
            ClassificationDB
                .getMultiList(_.get(props.field, 'variants.science_classificator'))
                .then((r) => {
                    options.value = r;
                })
            break;
        default:
            if (_.has(props.field, 'values')) {
                // костыль для старых шаблонов
                options.value = _.map(props.field.values[usePage().props.locale].split(';'), (item, index) => ({
                    value: String(index),
                    label: item
                }))
            }
    }
})
</script>