<template>
    <Card :title="$translate('publication.title.edit')"
          title-bg="purple-1"
          header-toolbar>

        <component :is="reportTypeCategory"
                   v-model="publication"/>

        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   color="positive"
                   @click="submitForm"
            />
        </template>

    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import {Card} from "@/Components/Block";
import * as ReportForm from "@/Components/ReportMaterialForm";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    categorys: {type: Array},
})

const publication = useForm(() => usePage().props.publication)

const reportTypeCategory = computed(() => ReportForm[_.upperFirst(_.get(publication, 'report_type_category'))])

const submitForm = () => {
    publication.transform((data) => ({
        ...data,
        ...publication
    }))
        .post(route('publication.update', {category: publication.report_type_category, publication: publication.id}))
}
</script>