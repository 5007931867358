<script setup>
import {getCurrentInstance, onMounted, ref, computed} from 'vue'
import {router, useForm} from "@inertiajs/vue3";
import _ from 'lodash';
import Card from "@/Components/Block/Card.vue";
import DataTable from "@/Components/DataTable.vue";
import {useQuasar} from "quasar";
import Anketa from "@/Components/RID/Anketa.vue";
import {RIDAnketa, RIDAnketaMember} from "@/entity";
import AnketaReferat from "@/Components/RID/Object/AnketaReferat.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();

const props = defineProps({
    anketa: {}
})

const documents = computed(() => {
    return _.groupBy(props.anketa.documents, 'type')
})
const uploadDialog = ref(false)
const form = useForm({
    type: null,
    file: null,
    link: null
})
const uploadForm = ref({
    type: null,
    file: null,
    link: null
})
const openUploadDialog = (type) => {
    _.set(uploadForm.value, 'type', type)
    uploadDialog.value = true;
}
const uploadFormSubmit = () => {
    form
            .transform((data) => ({
                ...data,
                ...uploadForm.value
            }))
            .post($route('services.rid.docs.upload', props.anketa), {
                onSuccess: () => {
                    uploadDialog.value = false;
                }
            })
}
</script>

<template>
    <q-list separator>
        <template v-for="(document) in documents['result']">
            <q-item v-if="document.file">
                <q-item-section side>
                    <q-icon name="mdi-paperclip"
                            size="sm"/>
                </q-item-section>
                <q-item-section>
                    <q-item-label> {{ document.file.name }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                    <q-btn icon-right="mdi-download-outline"
                           size="md"
                           :href="$route('services.rid.docs.download',[props.anketa, document.id])"
                           target="_blank"
                           no-caps dense flat no-wrap>
                    </q-btn>
                </q-item-section>
            </q-item>
            <q-item v-if="document.link">
                <q-item-section side>
                    <q-icon name="mdi-link"
                            size="sm"/>
                </q-item-section>
                <q-item-section>
                    <q-item-label class="text-no-wrap ellipsis"> {{ document.link }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                    <q-btn icon-right="mdi-open-in-new"
                           size="md"
                           :href="document.link"
                           target="_blank"
                           no-caps dense flat no-wrap>
                    </q-btn>
                </q-item-section>
            </q-item>
        </template>
    </q-list>
    <q-separator/>
    <q-card-actions align="right" v-if="anketa.can.uploadResult">
        <q-btn icon="mdi-upload-outline"
               :label="$translate('buttons.upload')"
               @click="openUploadDialog('result')"
               flat dense/>
    </q-card-actions>
    <q-dialog v-model="uploadDialog" no-esc-dismiss no-backdrop-dismiss>
        <Card in-modal show-close-button title="Загрузка результирующих документов">
            <q-file v-model='uploadForm.file'
                    :label="$translate('файл')"
                    clearable
                    clear-icon="clear">
                <template v-slot:prepend>
                    <q-icon name="mdi-paperclip"/>
                </template>
            </q-file>
            <q-input v-model='uploadForm.link'
                     :label="$translate('или ссылка')"
                     type="url"
                     clearable
                     clear-icon="clear">
                <template v-slot:prepend>
                    <q-icon name="mdi-link"/>
                </template>
            </q-input>
            <template v-slot:actions>
                <q-btn icon="mdi-upload"
                       :label="$translate('buttons.upload')"
                       color="positive"
                       @click="uploadFormSubmit"
                />
            </template>
        </Card>
    </q-dialog>
</template>