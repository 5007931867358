<template>
    <div class="q-gutter-y-md">
        <q-input v-model="equipment.document_name"
                 :label="$translate('equipment.item.fields.document_name')"
                 stack-label
                 :hint="$translate('equipment.item.fields.document_name-description')"/>

        <div class="row q-gutter-x-lg">
            <q-input v-model="equipment.inventory_number"
                     :label="$translate('equipment.item.fields.inventory_number-placeholder')"
                     stack-label
                     class="col"/>
            <q-input v-model="equipment.serial_number"
                     :label="$translate('equipment.item.fields.serial_number-placeholder')"
                     stack-label
                     class="col"/>
        </div>

        <div class="row q-gutter-x-lg">
            <q-input type="number"
                     v-model="equipment.year_created"
                     @update:modelValue="depreciationReal"
                     :label="$translate('equipment.item.fields.year_created')"
                     stack-label
                     class="col" input-class="text-center"
                     min="1900" :max="new Date().getFullYear()"/>
            <q-input type="date" v-model="equipment.year_placement"
                     :label="$translate('equipment.item.fields.year_placement')"
                     stack-label
                     class="col" input-class="text-center"
                     min="1900-01-01"/>
            <q-input type="number"
                     v-model="equipment.lifetime"
                     @update:modelValue="depreciationReal"
                     :label="$translate('equipment.item.fields.lifetime')"
                     stack-label
                     class="col" input-class="text-right"
                     :suffix="$translate('units.years')">
            </q-input>
        </div>

        <div class="row q-gutter-x-lg">
            <q-input v-model="equipment.initial_cost"
                     @update:modelValue="depreciationReal"
                     :label="$translate('equipment.item.fields.initial_cost')"
                     stack-label
                     class="col" input-class="text-right"
                     :suffix="$translate('units.rub')"
                     mask="#.##" reverse-fill-mask fill-mask="0">
            </q-input>
            <q-field type="number"
                     :label="$translate('equipment.item.fields.depreciation_real')"
                     stack-label
                     :hint="$translate('equipment.item.fields.depreciation_real_description')"
                     class="col" :suffix="$translate('units.rub-hour')">
                <template v-slot:control>
                    <div class="self-center full-width text-right no-outline">
                        {{ equipment.depreciation_real }}
                    </div>
                </template>
            </q-field>
        </div>
    </div>
</template>

<script setup lang="ts">
import {getCurrentInstance, watch} from "vue";
import _ from "lodash"
import {Equipment} from '@/entity'

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

const equipment: Equipment = defineModel()

const depreciationReal = () => {
    let deprecation: number = 0;
    if (equipment.value.lifetime > 0 && equipment.value.initial_cost > 0) {
        deprecation = Number(Number(_.get(equipment.value, 'initial_cost') / _.get(equipment.value, 'lifetime') / 365 / 24).toFixed(2));
    }
    _.set(equipment.value, 'depreciation_real', deprecation);
}

</script>