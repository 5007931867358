<template>
    <Card :title="$translate('project.form.title.departments')"
          no-card-section class="q-mb-md">
        <Alert v-if="project.errors.hasOwnProperty('ssc')" variant="danger">
            {{ _.get(project.errors, 'ssc') }}
        </Alert>
        <q-list>
            <template v-for="(department,index) in project.ssc">
                <DepartmentsDescription v-model="project.ssc[index]"
                                        :project="project">
                    <template v-slot:deleteItem>
                        <q-btn icon="o_delete" flat dense color="negative"
                               :title="$translate('buttons.delete')"
                               @click="deleteDepartment(index)"/>
                    </template>
                </DepartmentsDescription>
                <q-separator/>
            </template>
        </q-list>

        <template v-slot:actions>
            <q-btn :label="$translate('buttons.add')"
                   @click="addDepartmentDialog = true"
                   icon="add"
                   color="positive"></q-btn>
            <q-dialog v-model="addDepartmentDialog" no-backdrop-dismiss>
                <Card :title="$translate('project.form.title.add-ssc')" in-modal show-close-button>
                    <q-select :label="$translate('project.form.fields.ssc')"
                              v-model="selectedDepartment"
                              :options="departmentsOptions"
                              options-dense
                              option-label="name"/>
                    <template v-slot:actions>
                        <q-btn icon="add" :label="$translate('buttons.add')" color="positive"
                               @click="addDepartment"/>
                    </template>
                </Card>
            </q-dialog>
        </template>
    </Card>


    <Card :title="$translate('project.view.ssc-reglament-description')"
          v-if="_.size(project.ssc)>0"
          no-card-section hide-actions>
        <q-card-section class="text-italic">
            <div v-html="root_reglament"></div>
        </q-card-section>
        <q-list separator>
            <template v-for="(department,index) in project.ssc">
                <q-item>
                    <q-item-section>
                        <q-item-label class="text-body1 flex items-center q-gutter-x-sm">
                            <q-icon name="o_business" size="sm"/>
                            <span>{{ department.ssc.name }}</span>
                        </q-item-label>

                        <q-field v-if="department.ssc.show_description"
                                 :label="$translate('project.view.ssc-work-description')" stack-label>
                            <template v-slot:control>
                                <div class="self-stretch full-width">
                                    <q-editor flat v-model="project.ssc[index].description"/>
                                </div>
                            </template>
                        </q-field>
                        <q-field
                                v-if="(department.ssc.project_reglament ||  _.size(department.ssc.documents) > 0)"
                                borderless>
                            <template v-slot:control>
                                <div class="self-stretch full-width q-gutter-y-md q-py-md q-pl-sm">
                                    <div class="text-italic" v-html="_.get(department.ssc, 'project_reglament')"></div>
                                    <template v-for="(sscdoc) in department.ssc.documents">
                                        <div>
                                            <a :href="sscdoc.file_path" target="_blank">
                                                <q-icon name="attach_file" size="xs" left/>
                                                <span>{{ sscdoc.name }}</span>
                                                <span class="q-ml-sm">[{{ sscdoc.file_name }}, </span>
                                                <span>{{ sscdoc.file_size }}]</span>
                                            </a>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </q-field>

                        <q-checkbox v-model="department.agreed">
                            <span
                                    v-if="(department.ssc.project_reglament || department.ssc.project_reglament_root) && _.size(department.ssc.documents) > 0">
                                {{ $translate('project-form.confirm-ssc-reglament-docs', {name: department.ssc.name}) }}
                            </span>
                            <span v-else-if="(department.ssc.project_reglament || department.project_reglament_root)">
                                {{ $translate('project-form.confirm-ssc-reglament', {name: department.ssc.name}) }}
                            </span>
                            <span v-else-if="_.size(department.ssc.documents) > 0">
                                {{ $translate('project-form.confirm-ssc-docs', {name: department.ssc.name}) }}
                            </span>
                            <span v-else-if="department.ssc.project_reglament_root">
                                {{ $translate('project-form.confirm-ssc', {name: department.ssc.name}) }}
                            </span>
                            <span v-if="_.size(department.ssc.documents) > 0">
                                 <span v-for="(document) in department.ssc.documents">
                                     {{ document.name.length > 0 ? document.name : document.file_name }}&comma;&nbsp;
                                 </span>
                            </span>
                        </q-checkbox>
                        <div class="text-negative q-pa-xs"
                             v-if="project.errors.hasOwnProperty('ssc.'+index+'.agreed')">
                            {{ _.get(project.errors, 'ssc.' + index + '.agreed') }}
                        </div>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount, ref} from "vue";
import _ from "lodash";
import axios from "axios"
import {Alert, Card} from "@/Components/Block";
import DepartmentsDescription from "@/Components/Project/CreateProjectSteps/DepartmentsDescription.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const project = defineModel({type: Object})

const emit = defineEmits(['update:project'])

const departments = ref([])

const addDepartmentDialog = ref(false)

const selectedDepartment = ref(null)
const addDepartment = () => {
    if (selectedDepartment.value) {
        if (!_.find(project.value.ssc, {id: selectedDepartment.value.id})) {
            // departments.value.push(selectedDepartment.value)
            project.value.ssc.push({
                id: selectedDepartment.value.id,
                count: null,
                agreed: false,
                description: null,
                ssc: selectedDepartment.value
            })
        }
        selectedDepartment.value = null;
        addDepartmentDialog.value = false;
    }
}
const deleteDepartment = (index, sscId) => {
    // departments.value.splice(index, 1);
    // _.unset(editorRefs.value, 'editorRef' + sscId)
    project.value.ssc.splice(index, 1);
}
const departmentsOptions = ref([])

const root_reglament = computed(() => {
    return _.join(_.uniq(_.values(_.mapValues(project.value.ssc, 'ssc.project_reglament_root'))), "<br/>");
})
const loadDepartments = () => {
    axios
            .get(route('ajax.ssc', {m: 'rcToProject'}))
            .then((response) => {
                departmentsOptions.value = response.data.results
            })
}

onBeforeMount(() => {
    loadDepartments()
})
</script>