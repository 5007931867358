<template>
    <ResearchLayout :research="research" tab="report">

        <Card :title="$translate('report-template.title.add',{'number':research.number})"
              header-toolbar
              no-card-section>

            <ResearchReportTemplateForm :research="research"
                                        v-model="template"/>

            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="submitForm"
                />
            </template>
        </Card>
    </ResearchLayout>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref, watch} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {Card} from "@/Components/Block";
import ResearchLayout from "@/Components/ProjectResearch/ResearchLayout.vue";
import ResearchReportTemplateForm from "@/Components/ResearchReportTemplate/ResearchReportTemplateForm.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    research: {type: Object},
})

const template = useForm({
    name: null,
    allow: 0,
    usage: 0,
    equipment: {},
    consumable: {},
    worktime: {},
})

const submitForm = () => {
    template
        .transform((data) => ({
            name: data.name,
            allow: data.allow,
            usage: data.usage,
            equipment: _.filter(data.equipment, 'checked'),
            consumable: _.filter(data.consumable, 'checked'),
            worktime: _.filter(data.worktime, 'checked'),
        }))
        .post(route('project.research.report-template.save', props.research), {
            onSuccess: () => {
                router.visit(route('project.research.report', props.research))
            }
        })
}

</script>