<template>
    <Card :title="$translate('project-type.title-copy')+': '+cloneProjectType.name"
          title-bg="blue-grey-3"
          header-toolbar>
        <template v-slot:headerActions>
            <q-btn icon="navigate_before"
                   :label="$translate('buttons.back')"
                   @click="router.get($route('admin.project-type.index'))"
                   color="blue-grey-5"
                   dense no-caps/>
        </template>

        <ProjectTypeForm v-model="projectType"
                         :errors="projectTypeForm.errors"/>

        <template v-slot:actions>
            <q-btn icon="save"
                   :label="$translate('buttons.add')"
                   color="positive"
                   @click="addProjectType"/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from 'lodash'
import {useQuasar} from "quasar";
import ProjectTypeForm from "@/Components/ProjectType/ProjectTypeForm.vue";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const projectTypeForm = useForm({
    id: null,
    parent_id: null,
    name_i18n: {},
    description_i18n: {},
    unlimited: true,
    members: 1,
    character: {
        base: [],
        additional: []
    },
    expected_result: [],
    fields: []
})

const projectType = ref({
    id: null,
    parent_id: null,
    name_i18n: {},
    description_i18n: {},
    unlimited: true,
    members: 1,
    character: {
        base: [],
        additional: []
    },
    expected_result: [],
    fields: []
})

const cloneProjectType = computed(() => usePage().props.projectType)

const addProjectType = () => {
    projectTypeForm
        .transform((data) => ({
            ...data,
            ...projectType.value,
            id: null
        }))
        .post(route('admin.project-type.store'), {
            onSuccess: () => {
                router.get(route('admin.project-type.index'))
            }
        })
}

onMounted(() => {
    _.set(projectType.value, 'parent_id', _.get(cloneProjectType.value, 'parent_id'));
    _.set(projectType.value, 'name_i18n', _.get(cloneProjectType.value, 'name_i18n'));
    _.set(projectType.value, '.description_i18n ', _.get(cloneProjectType.value, 'description_i18n'));
    _.set(projectType.value, 'members', _.get(cloneProjectType.value, 'members'));
    _.set(projectType.value, 'character.base', _.map(_.get(cloneProjectType.value, 'character.base'), Number));
    _.set(projectType.value, 'character.additional', _.map(_.get(cloneProjectType.value, 'character.additional'), Number));
    _.set(projectType.value, 'expected_result', _.get(cloneProjectType.value, 'expected_result'));
    _.set(projectType.value, 'fields', _.get(cloneProjectType.value, 'fields'));
})
</script>