<template>
    <q-select v-model="selected"
              option-value="id"
              :loading="loading"
              input-debounce="1000"
              :multiple="multiple"
              :options="fetchedData"
              options-dense
              @filter="searchEquipment"
              :label="label?label:$translate('search.equipment-catalogue.label')"
              @update:model-value="onSelect"
              use-input
              :error-message="error"
              :error="_.size(error)>0"
    >
        <q-tooltip>{{ $translate('search.equipment-catalogue.hint') }}</q-tooltip>
    </q-select>
</template>

<script setup lang="ts">
import {getCurrentInstance, ref} from "vue";
import axios from "axios";
import _ from "lodash";

interface Props {
    label?: string;
    multiple?: boolean;
    filters?: object | null;
    error?: string;
}

const props = withDefaults(defineProps<Props>(), {
    multiple: false
});

const emit = defineEmits(['equipmentSelected']);

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

const selected = ref(null);
const fetchedData = ref([]);
const loading = ref(false);

const onSelect = (value: any) => {
    emit('equipmentSelected', value)
}
const searchEquipment = (search: any, update: any) => {
    if (search === '') {
        update(() => {
            fetchedData.value = [];
        });
        return;
    }
    update(() => {
        let params = {
            m: 'search',
            q: search,
            filters: props.filters
        };
        loading.value = true;
        fetchedData.value = [];
        axios.get($route('ajax.equipment.catalogue', params))
                .then((response) => {
                    fetchedData.value = response.data.results;
                })
                .finally(() => {
                    loading.value = false;
                });
    });
};
</script>
