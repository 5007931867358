<template>
    <q-item :class="itemClass" :clickable="sidebar">
        <q-item-section side>
            <q-icon v-if="icon" :name="icon" :color="color"/>
        </q-item-section>
        <q-item-section class="text-uppercase text-weight-bold" v-if="!sidebar">{{ title }}</q-item-section>
        <q-tooltip anchor="center right" class="text-body2">{{ title }}</q-tooltip>
        <q-menu fit anchor="top right" v-if="sidebar">
            <slot name="items"></slot>
        </q-menu>
    </q-item>
    <q-list dense v-if="!sidebar">
        <slot name="items"></slot>
    </q-list>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    sidebar: {
        type: Boolean,
        default: false,
    },
    icon: {
        type: String,
        default: null
    },
    title: {
        type: String,
        required: true
    },
    color: {
        type: String,
        default: null
    }
})
const textColor = computed(() => 'text-' + props.color);
const itemClass = computed(() => [textColor.value, props.sidebar ? 'cursor-help' : null])
</script>
