<template>
    <q-btn icon="o_sync"
           :label="$translate('cadre-department.actions.copy-internal')"
           color="blue-grey-6"
           no-caps
           dense
           @click="dialog = true"/>
    <q-dialog v-model="dialog" no-backdrop-dismiss>
        <Card :title="$translate('cadre-department.title.copy-internal')"
              header-toolbar
              in-modal show-close-button no-card-section>

            <q-card-section>
                <SearchSSC :label="$translate('cadre-department.label.copy-internal.select-source')"
                           @on-select="selectSource"/>
            </q-card-section>

            <q-card-section>
                <q-checkbox v-model="syncData.leader"
                            :label="$translate('cadre-department.label.copy-internal.sync-leader')"/>
            </q-card-section>

            <q-card-section>
                <q-checkbox v-model="syncData.remove_data"
                            :label="$translate('cadre-department.label.copy-internal.remove-data')"/>
                <Alert variant="danger"
                       v-if="syncData.remove_data"
                       :message="$translate('cadre-department.info.copy-internal-warn')"/>
            </q-card-section>

            <template v-slot:actions>
                <q-btn icon="o_sync"
                       :label="$translate('buttons.syncexternal')"
                       color="positive"
                       @click="syncExternal"/>
                <q-btn icon="o_close"
                       :label="$translate('buttons.close')"
                       color="dark"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {Card, Alert} from "@/Components/Block";
import {SSC as SearchSSC} from "@/Components/Search/";
import _ from 'lodash';

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

const dialog = ref(false)

const syncData = useForm({
    source_id: null,
    leader: false,
    remove_data: false
})

const selectSource = (val: any) => {
    syncData.source_id = _.get(val, 'id')
}
const syncExternal = () => {
    syncData.post($route('cadre.department.sync.internal'), {
        onSuccess: () => {
            syncData.reset()
            dialog.value = false
        }
    })
}
</script>