<script setup>
import {getCurrentInstance, onMounted, ref} from 'vue'
import {router, useForm} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import DataTable from "@/Components/DataTable.vue";
import {useQuasar} from "quasar";
import Anketa from "@/Components/RID/Anketa.vue";
import {RIDAnketa, RIDAnketaMember} from "@/entity";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();

const form = useForm({
    name: null,
    annotation: null,
    characters: null,
    scope: null,
    short_description: null,
    disclose_information: null,
    product: null,
    disadvantages: null,
    benefits: null,
    limitations: null,
    prospects: null,
    form: null, form_other: null,
    usage: null, usage_other: null,
    persons: [],
    vklad: [],
})

const submitForm = () => {
    form.post($route('services.rid.store'))
}
</script>

<template>
    <Card :title="$translate('module-rid.title.create')"
          title-bg="blue-grey-13" title-text="white"
          header-toolbar no-card-section>
        <template v-slot:headerActions>
            <q-btn icon="mdi-chevron-left"
                   :label="$translate('buttons.back')"
                   @click="router.visit($route('services.rid.list'))"
                   color="white"
                   outline
                   dense
                   no-caps
            />
        </template>

        <Anketa v-model:anketa="form"
                v-model:errors="form.errors"/>

        <template v-slot:actions>
            <q-btn icon="mdi-content-save-outline"
                   :label="$translate('buttons.save')"
                   color="positive"
                   @click="submitForm"
            />
        </template>
    </Card>
</template>