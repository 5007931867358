<template>
    <Card :title="$translate('publication.detail.project')"
          no-card-section
          :hide-actions="!publication.can.assignWithProject"
          actions-align="left">
        <q-list>
            <template v-for="(project) in publication.projects">
                <q-item>
                    <q-item-section clickable
                                    @click="router.visit($route('project.show',project))"
                                    class="cursor-pointer">
                        <q-item-label class="text-bold">
                            {{ project.number }}
                        </q-item-label>
                        <q-item-label>
                            {{ project.name }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side top class="q-gutter-y-xs">
                        <q-btn icon="mdi-open-in-new"
                               color="dark"
                               @click.prevent="router.visit($route('project.show',project))"
                               :href="$route('project.show',project)"
                               title="к карточке проекта"
                               size="md"
                               flat
                               dense
                        />
                        <q-btn v-if="project.permission.unAssignProject"
                               icon="o_delete"
                               color="negative"
                               @click="deleteItem(project)"
                               flat
                               dense
                        />
                    </q-item-section>
                </q-item>
                <SendApproveDialog v-if="project.permission.toApprove"
                                   :project="project"
                                   :publication_id="publication.id"
                                   :publication_type="publication.report_type_category"/>

                <ApprovingStatus :project="project"
                                 :publication="publication"/>

                <q-separator/>
            </template>
        </q-list>
        <template v-slot:actions>
            <AssignProjectDialog :publication="publication"/>
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import {Card} from "@/Components/Block";
import AssignProjectDialog from "@/Components/ReportMaterial/Project/AssignProjectDialog.vue";
import SendApproveDialog from "@/Components/ReportMaterial/Project/SendApproveDialog.vue";
import ApprovingStatus from "@/Components/ReportMaterial/Project/ApprovingStatus.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const $q = useQuasar()


const props = defineProps({
    publication: {type: Object}
})
const deleteItem = (item) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: item.number}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.get(route('publication.unassign-project', {
            category: props.publication.report_type_category,
            publication: props.publication,
            project: item,
        }));
    })
}
</script>