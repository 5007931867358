<template>
    <Card :title="_.get(publication, 'publication_name')" hide-actions>
        <template v-slot:headerActions>
            <q-btn :icon="expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                   :title="$translate('buttons.toggle-list')"
                   @click="expanded = !expanded"
                   size="sm"
                   flat
            />
        </template>
        <template v-slot:default>
            <q-slide-transition>
                <div v-show="expanded">
                    <q-list separator dense>
                        <template v-for="(value, property) in publication" :key="property">
                            <q-item v-if="allowToShow(property, value)">
                                <q-item-section>
                                    <div class="row q-py-xs">
                                        <div class="col-7">{{ getDescription(property) }}:</div>
                                        <div v-if="'filename' === property" class="col">
                                            <template v-if="_.get(value, 'exists')">
                                                <a :href="_.get(value, 'url')"
                                                   target="_blank"
                                                   rel="noreferrer nofollow"
                                                   class="text-brand"
                                                >
                                                    <q-icon v-if="_.get(value, 'icon')"
                                                            :name="'far fa-' + _.get(value, 'icon')"
                                                            size="xs"
                                                    />
                                                    {{ _.get(value, 'name') }}
                                                </a>
                                            </template>
                                            <template v-else>{{ _.get(value, 'name') }}</template>
                                        </div>
                                        <div v-else-if="isCatalogue(property)" class="col">
                                            <q-icon v-if="value > 0" name="o_check_circle" color="positive" />
                                        </div>
                                        <div v-else class="col">{{ value }}</div>
                                    </div>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </div>
            </q-slide-transition>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import helpers from "@/helpers";

const props = defineProps({
    publication: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const expanded = ref(true);

const allowToShow = (property, value) => {
    if ('filename' === property) {
        return helpers.filled(_.get(value, 'name'));
    }
    return helpers.filled(value) && _.includes(_.keys($translate('module-archive.publication.fields')), property);
};

const getDescription = (property) => _.get($translate('module-archive.publication.fields'), property);

const isCatalogue = (property) => _.includes(['wak', 'wos', 'scopus', 'rinc', 'elib'], property);
</script>
