<template>
    <ProjectLayout :project="project" tab="research">
        <q-toolbar class="bg-indigo-3 text-white">
            <q-toolbar-title>
                {{ $translate('research.form.title.add') }}
            </q-toolbar-title>
        </q-toolbar>

        <template v-if="loading">
            <q-card-section>
                <q-linear-progress
                        stripe
                        size="30px"
                        :value="loadingProgress"
                        color="info"
                        class="q-mt-sm"/>
            </q-card-section>
        </template>
        <template v-else-if="_.size(sscList) === 0">
            <q-card-section>
                <Alert variant="info">
                    Для подачи заявок:
                    <ol>
                        <li>Вы должны быть участником проекта с правом подачи заявок;</li>
                        <li>Руководителями всех выбранных подразделений согласовано выполнение работ.</li>
                    </ol>
                </Alert>
            </q-card-section>
        </template>
        <template v-else>
            <q-card-section>
                <q-select v-model="form.ssc_id"
                          :label="$translate('research.form.select-rc')"
                          :options="sscListOptions"
                          @update:model-value="selectSsc"
                          map-options
                          emit-value
                          :error="form.errors.hasOwnProperty('ssc_id')"
                          :error-message="form.errors.ssc_id"/>
            </q-card-section>

            <q-card-section v-if='form.ssc_id'>
                <q-select v-model="selectedTemplate"
                          :label="$translate('research.form.select-template')"
                          :options="templateListOptions"
                          @update:model-value="selectTemplate"
                          :error="form.errors.hasOwnProperty('template_id')"
                          :error-message="form.errors.template_id"
                />
            </q-card-section>

            <q-card-section v-if="selectedTemplate && _.get(selectedTemplate,'assay')">
                <q-input v-model="form.assay"
                         type="number"
                         :label="$translate('research.form.select-assay')"
                         mask="#" reverse-fill-mask
                         :min="assayMin"
                         :max="assayMax"
                         stack-label
                         input-class="text-center"
                         style="width:500px;"
                         :error="form.errors.hasOwnProperty('assay')"
                         :error-message="_.get(form.errors,'assay')"/>
            </q-card-section>

            <FieldsRendering v-if="selectedTemplate"
                             :fields="templateFields"
                             :ssc="form.ssc_id"
                             v-model="form.fields"
                             :errors="form.errors"
                             error-prefix="fields."/>
        </template>

        <q-separator/>

        <q-card-actions align="center">
            <q-btn icon="o_save"
                   :disable="!selectedTemplate"
                   :label="$translate('buttons.create')"
                   color="positive"
                   @click="submitForm"/>
        </q-card-actions>
    </ProjectLayout>
</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount, ref} from "vue";
import axios from "axios";
import _ from "lodash";

import ProjectLayout from "@/Components/Project/ProjectLayout.vue";
import {Card, Alert} from "@/Components/Block/";
import AlertInfo from "@/Components/Block/AlertInfo.vue";
import {router, useForm} from "@inertiajs/vue3";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    project: {type: Object, required: true},
})

const loading = ref(true);
const loadingProgress = ref(0)

const sscList = ref([]);

const sscListOptions = computed(() => {
    return _.map(_.values(sscList.value), (item) => ({
        value: item.id,
        label: item.name
    }))
})

const selectSsc = () => {
    form.template_id = null;
    form.assay = null;
    form.fields = {};
    selectedTemplate.value = null
}

const selectedTemplate = ref(null)

const selectTemplate = () => {
    console.debug(selectedTemplate)
    form.template_id = selectedTemplate.value.value;
    form.assay = null;
    form.fields = {};
}

const loadSsc = () => {
    loading.value = true
    loadingProgress.value = 0.3;
    axios.get(route('ajax.ssc', {
        m: 'rcWithTemplateApproved',
        project: props.project.id
    }))
            .then((response) => {
                loadingProgress.value = 0.6;
                // console.log(response.data);
                sscList.value = response.data.results;
            })
            .then(() => {
                loadingProgress.value = 0.9;
            })
            .finally(() => {
                setTimeout(() => {
                    loading.value = false
                }, 1500)
            })

}

const templateListOptions = computed(() => {
    return _.map(sscList.value[form.ssc_id].template, (item) => ({
        value: item.id,
        label: item.name,
        assay: item.assay,
        assay_limit: item.assay_limit,
        fields: item.fields
    }))
})

const assayMin = computed(() => {
    return _.get(selectedTemplate.value, 'assay_limit.min') || 1
});

const assayMax = computed(() => {
    return _.get(selectedTemplate.value, 'assay_limit.max') || null
})

const templateFields = computed(() => {
    if (selectedTemplate) {
        return selectedTemplate.value.fields
    }
    return {}
})

const form = useForm({
    ssc_id: null,
    template_id: null,
    assay: null,
    fields: {}
})

const submitForm = () => {
    form.post(route('project.research.store', props.project), {
        onSuccess: () => {
            router.visit(route('project.research', props.project))
        }
    })
}

const updateFields = (data) => {
    form.fields = data
}

onBeforeMount(() => {
    loadSsc()
})
</script>