<template>
    <q-input v-model="link.name"
             :label="$translate('Наименование ссылки')"/>

    <q-input v-model="link.url"
             type="url"
             :label="$translate('Адрес ссылки')"/>

    <q-select v-model="link.type"
              :label="$translate('Тип ссылки')"
              :options="options"
              @update:modelValue="(val)=> {link.type_id=val.id}"
              options-dense/>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import ClassificationDB from "@/plugins/ClassificationDB";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;


const link = defineModel({
    type: Object,
    default() {
        return {
            name: null,
            url: null,
            type_id: null,
            type: null
        }
    }
})

const options = ref([])

onMounted(() => {
    ClassificationDB
            .getSimpleList('documenttype')
            .then((r) => {
                options.value = r
            })
})
</script>