<template>
    <Card :title="$translate('equipment.item.fields.documents')" hide-actions no-card-section>
        <template v-slot:headerActions v-if="equipment.permission.edit">
            <q-btn icon="add" :label="$translate('buttons.add')"
                   dense
                   size="sm" color="grey-2" text-color="body"
                   @click="addDocumentModal = true"/>
        </template>
        <q-list separator>
            <template v-for="(document,index) in equipment.documents">
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ _.get(document, 'doctype.name') }}</q-item-label>
                        <q-item-label>
                            <a :href="document.fullpath" target="_blank">{{ document.name }}</a>
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side top v-if="equipment.permission.edit">
                        <q-btn icon="o_delete" flat dense size="sm" color="negative"
                               @click="deleteDocument(document.id)"/>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>
    <q-dialog v-model="addDocumentModal">
        <Card style="width: 700px;max-width: 90vw"
              title="Добавление документа"
              show-close-button>
            <q-select v-model="addDocumentForm.type"
                      :label="$translate('equipment.item.fields.documents-type')"
                      :options="documentType"
                      options-dense emit-value map-options/>
            <q-file label="выберите файл"
                    v-model="addDocumentForm.file">
                <template v-slot:prepend>
                    <q-icon name="attach_file"/>
                </template>
            </q-file>
            <template v-slot:actions>
                <q-btn color="positive" icon="save" :label="$translate('buttons.save')" @click="submitAddDocumentForm"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import _ from "lodash";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    equipment: {
        type: Object
    }
})
const documentType = computed(() => usePage().props.options.documentType)

const addDocumentModal = ref(false)
const addDocumentForm = useForm({
    type: null,
    file: null
})
const submitAddDocumentForm = () => {
    addDocumentForm
        .post(route('equipment.document.create', props.equipment), {
            onSuccess: () => {
                router.reload()
                addDocumentModal.value = false
            }
        })
}
const deleteDocument = (index) => {
    addDocumentForm
        .delete(route('equipment.document.delete', [props.equipment, index]), {
            onSuccess: () => {
                router.reload()
            }
        })
}
</script>