<template>
    <h6 class="text-center q-my-xs">{{ $translate('project-expenses.title') }} #{{ project.number }}</h6>
    <q-field :label="$translate('project-expenses.project.number')" tag="section" stack-label borderless>
        <template v-slot:control>
            <div class="self-center full-width q-pl-md">
                #{{ project.number }}
            </div>
        </template>
    </q-field>
    <q-field :label="$translate('project-expenses.project.name')" tag="section" stack-label borderless>
        <template v-slot:control>
            <div class="self-center full-width q-pl-md">
                {{ project.name }}
            </div>
        </template>
    </q-field>
    <q-field :label="$translate('project-expenses.project.leader')" tag="section" stack-label borderless>
        <template v-slot:control>
            <div class="self-center full-width q-pl-md">
                {{ project.leader.fulled_name }}
            </div>
        </template>
    </q-field>
    <q-field :label="$translate('project-expenses.total-cost')" tag="section" stack-label borderless>
        <template v-slot:control>
            <div class="self-center full-width q-pl-md">
                {{ project.current_cost_formatted }}
            </div>
        </template>
    </q-field>


    <template v-for="(research) in project.expenses_report.report">
        <hr/>
        <div>{{ $translate('project-expenses.research.name') }} #{{ research.number }}</div>
        <div>{{ $translate('project-expenses.ssc') }} &laquo;{{ research.ssc }}&raquo;</div>

        <template v-for="(expenses,date) in _.get(research,'expenses')">
            <table class="full-width q-table q-table--dense q-table--bordered page-break">
                <caption class="text-left">{{ $translate('project-expenses.date') }}
                    {{ moment(date).format($translate('common.date.js')) }}
                </caption>
                <tbody>
                <tr class="bg-grey-2">
                    <th>{{ $translate('research-report.expenses.table.equipment') }}</th>
                    <th>{{ $translate('research-report.expenses.table.time') }}</th>
                    <th>{{ $translate('research-report.expenses.table.cost') }}</th>
                </tr>
                <template v-for="(item) in _.get(expenses,'equipment.items')">
                    <tr>
                        <td>
                            <div>{{ item.name }}</div>
                            <div class="text-italic">{{ item.comment }}</div>
                            <small>Добавлено: {{ item.author }}</small>
                        </td>
                        <td class="text-center">
                            {{
                                moment.utc(moment.duration(item.used_time, "minutes").asMilliseconds()).format("HH:mm")
                            }}
                        </td>
                        <td class="text-right q-pr-lg">
                            {{ helper.formatMoney(item.cost) }}
                        </td>
                    </tr>
                </template>
                <tr class="bg-grey-2">
                    <th>{{ $translate('research-report.expenses.table.consumable') }}</th>
                    <th>{{ $translate('research-report.expenses.table.count') }}</th>
                    <th>{{ $translate('research-report.expenses.table.cost') }}</th>
                </tr>
                <template v-for="(item) in _.get(expenses,'consumables.items')">
                    <tr>
                        <td>
                            <div>{{ item.name }}</div>
                            <div class="text-italic">{{ item.comment }}</div>
                            <small>Добавлено: {{ item.author }}</small>
                        </td>
                        <td class="text-center">
                            {{ helper.formatNumber(item.used_count) }}
                            {{ item.used_unit }}
                        </td>
                        <td class="text-right q-pr-lg">
                            {{ helper.formatMoney(item.cost) }}
                        </td>
                    </tr>
                </template>

                <tr class="bg-grey-2">
                    <th>{{ $translate('research-report.expenses.table.user') }}</th>
                    <th>{{ $translate('research-report.expenses.table.time') }}</th>
                    <th>{{ $translate('research-report.expenses.table.cost') }}</th>
                </tr>
                <template v-for="(item) in _.get(expenses,'worktime.items')">
                    <tr>
                        <td>
                            <div>{{ item.author }}</div>
                            <div class="text-italic">{{ item.comment }}</div>
                        </td>
                        <td class="text-center">
                            {{
                                moment.utc(moment.duration(item.used_time, "minutes").asMilliseconds()).format("HH:mm")
                            }}
                        </td>
                        <td class="text-right q-pr-lg">
                            {{ helper.formatMoney(item.cost) }}
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </template>
    </template>
    <div class="small q-py-lg">
        Дата формирования отчета: {{
            moment(project.expenses_report.updated_at).format($translate('common.datetime.js'))
        }}
    </div>
</template>

<script setup>
import Print from "@/Layout/Print.vue";
import _ from "lodash";
import helper from "@/helpers";
import {getCurrentInstance} from "vue";
import moment from "moment";


defineOptions({layout: Print});

const props = defineProps({
    project: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const calculateCost = (data, key) => {
    data = _.values(data)
    if (_.size(data) > 0) {
        let items = [];
        _.forEach(data, (item) => {
            if (_.size(_.get(item, key)) > 0) {
                items.push(_.get(item, key))
            }
        })
        items = _.filter(items);
        if (_.size(items) > 0) {
            return _.sumBy(items, 'cost');
        }
    }
    return 0;
}
</script>