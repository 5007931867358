<template>
    <Card :title="cadreStaff.name"
          header-toolbar
          no-card-section
          hide-actions>
        <template v-slot:headerActions>
            <q-btn :label="$translate('buttons.back')"
                   @click="router.get($route('cadre.staff.index'))"
                   icon="o_chevron_left"
                   color="grey-6"
                   dense
            />
        </template>
        <StaffCard :staff="cadreStaff"/>
    </Card>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import {Card} from "@/Components/Block";
import StaffCard from "@/Components/Cadre/Staff/StaffCard.vue";
import {CadreStaff as Staff} from "@/entity"

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

interface Props {
    cadreStaff: Staff
}

const props = defineProps<Props>()
</script>