<template>
    <Card :title="$translate('users.manage.external-data.title')"
          header-toolbar hide-actions no-card-section>

        <q-tabs v-model="tab" align="left">
            <template v-for="(item) in sources">
                <q-tab :name="item.source" :label="item.name"/>
            </template>
        </q-tabs>
        <q-tab-panels v-model="tab">
            <template v-for="(item) in sources">
                <q-tab-panel :name="item.source" class="q-px-none">
                    <component :is="Sources[_.upperFirst(item.source)]"
                               :url="item.url"/>
                </q-tab-panel>
            </template>
        </q-tab-panels>
    </Card>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import Card from "@/Components/Block/Card.vue";
import {useQuasar} from "quasar";
import * as Sources from '@/Components/User/Source'


const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar()

const props = defineProps({
    user: {
        type: Object
    },
    sources: {
        type: Array
    }
})
const tab = ref(_.get(_.first(props.sources), 'source'))
</script>