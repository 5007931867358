<template>
    <ResearchLayout :research="research" tab="report">

        <q-toolbar class="bg-grey-3">
            <q-toolbar-title>
                {{ $translate('research.title.report') }}
            </q-toolbar-title>
            <q-space/>
            <ResearchActions :research="research"/>
        </q-toolbar>
        <ResearchReportForm :research="research"
                            :disabled-tabs="disabledTabs"/>
    </ResearchLayout>
</template>

<script setup>
import {getCurrentInstance, onMounted} from "vue";
import {useReportDataStore} from '@/store/reportDataStore';

import ResearchLayout from "@/Components/ProjectResearch/ResearchLayout.vue";
import ResearchReportForm from "@/Components/ProjectResearch/Report/ResearchReportForm.vue";
import ResearchActions from "@/Components/ProjectResearch/Card/ResearchActions.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    research: {type: Object, required: true},
    disabledTabs: {type: Object, required: true},
})

const reportDataStore = useReportDataStore()

onMounted(() => {
    reportDataStore.setResearch(props.research)
})

</script>