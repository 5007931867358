<template>
    <q-item>
        <q-item-section side>
            <q-icon v-bind="departmentStatusIcon(department.approved)" flat dense>
                <q-tooltip class="text-body2">
                    {{ department.approved_text }}
                </q-tooltip>
            </q-icon>
        </q-item-section>
        <q-item-section clickable @click="toggleContact = true" class="cursor-pointer">
            <q-item-label>
                {{ department.ssc.name }}
            </q-item-label>
        </q-item-section>
        <q-item-section side>
            <q-item-label>
                <q-tooltip class="text-body2">
                    {{ $translate('project.view.ssc-request.count-title') }}
                </q-tooltip>
                <q-btn v-if="department.can.edit && department.approved !== 1 && department.research_count !== unlimited"
                       icon="edit"
                       size="sm"
                       dense square flat
                       @click="toggleCount = true">
                    <q-tooltip class="text-body2 bg-primary"
                               anchor="bottom middle"
                               self="bottom middle">
                        {{ $translate('project.view.ssc-request.change-count') }}
                    </q-tooltip>
                </q-btn>
                <q-chip text-color="primary" dense square outline v-if="department.approved !== 1">
                    <span v-if="department.research_count === unlimited" class="text-h6">&#8734;</span>
                    <span v-else>{{ department.research_count }}</span>
                </q-chip>
                <q-chip text-color="positive" dense square outline v-if="department.approved === 1">
                    <span v-if="department.research_count_approved === unlimited" class="text-h6">&#8734;</span>
                    <span v-else>{{ department.research_count_approved }}</span>
                </q-chip>
                <q-chip text-color="negative" dense square outline>
                    {{ department.research_used }}
                </q-chip>
            </q-item-label>
        </q-item-section>
        <q-btn flat icon="o_contact_page" color="grey-7"
               dense
               v-if="!printable"
               @click="toggleContact = true">
            <q-tooltip class="text-body2">{{ $translate('project.view.ssc-contact-title') }}</q-tooltip>
        </q-btn>
        <q-btn flat icon="o_description" color="grey-7"
               dense
               v-if="!printable"
               @click="toggleDescription = !toggleDescription">
            <q-tooltip class="text-body2">
                {{ $translate('project.view.ssc-work-description') }}
            </q-tooltip>
        </q-btn>
        <q-btn flat icon="o_question_answer" color="grey-7"
               dense
               v-if="!printable"
               @click="openCommentDialog">
            <q-tooltip class="text-body2">
                {{ $translate('project.view.ssc-request.comment-title') }}
            </q-tooltip>
        </q-btn>
        <q-item-section side v-if="!printable">
            <DepartmentActions :department="department" :project="project"/>
        </q-item-section>
        <!-- Изменение количества-->
        <q-dialog v-if="department.approved !== 1"
                  v-model="toggleCount"
                  no-backdrop-dismiss>
            <Card :title="$translate('project.view.ssc-request.count-change.title')"
                  show-close-button in-modal>
                <q-input type="number"
                         :label="$translate('project.view.ssc-request.count-change.count-label')"
                         v-model="departmentCountForm.count"
                         stack-label
                         input-class="text-center"/>
                <q-input type="textarea" rows="3"
                         v-model="departmentCountForm.comment"
                         :label="$translate('project.view.ssc-request.count-change.comment-label')"
                         stack-label/>
                <template v-slot:actions>
                    <q-btn icon="o_save"
                           :label="$translate('buttons.save')"
                           color="positive"
                           @click="departmentCountFormSubmit"/>
                    <q-btn icon="o_close"
                           :label="$translate('buttons.cancel')"
                           color="dark"
                           v-close-popup/>
                </template>
            </Card>
        </q-dialog>
        <!-- Контакты подразделения -->
        <q-dialog v-model="toggleContact">
            <Card :title="$translate('project.view.ssc-contact-title') + ' <' + department.ssc.name+'>'"
                  in-modal show-close-button hide-actions no-card-section he>
                <q-list separator>
                    <q-item v-if="_.get(department.ssc.info_settings,'common.show')">
                        <q-item-section>
                            <q-item-label v-html="helpers.nl2br(department.ssc.info_settings.common.info)"/>
                        </q-item-section>
                    </q-item>
                    <template v-if="_.get(department.ssc.info_settings,'leader.show')">
                        <MemberItem v-if="department.ssc.director"
                                            :title="$translate('project.view.ssc-contact-title-leader')"
                                            :member="department.ssc.director"
                                            hide-actions/>
                    </template>
                    <template v-if="_.get(department.ssc.info_settings,'other_leaders.show')">
                        <template v-for="(head,id) in department.ssc.heads">
                            <MemberItem v-if="head.data.user_id !== department.ssc.director_id"
                                                :title="$translate('project.view.ssc-contact-title-leader-other')"
                                                :member="head.data"
                                                hide-actions/>
                        </template>
                    </template>
                </q-list>
            </Card>
        </q-dialog>
        <!-- Комментарии/чат -->
        <q-dialog v-model="toggleChat" no-backdrop-dismiss>
            <Card :title="$translate('project.view.ssc-request.comment-title-modal') + ' ' + department.ssc.name"
                  in-modal show-close-button hide-actions>
                <q-inner-loading :showing="loadingComment" size="xl">
                    <div class="flex items-center justify-center">
                        <q-item-label class="q-ml-xl text-primary">{{
                                $translate('Загрузка комментариев')
                            }}
                        </q-item-label>
                    </div>
                </q-inner-loading>
                <template v-if="!loadingComment">
                    <template v-for="(comment) in comments">
                        <q-chat-message :name="comment.author.full_name"
                                        :text="[helpers.nl2br(comment.comment)]"
                                        text-html
                                        :stamp="moment(comment.created_at).locale(locale).fromNow()"
                                        size="16"
                                        :sent="commentAuthorProjectMember(comment.author)"
                        />
                    </template>
                    <q-chat-message :name="project.leader.full_name"
                                    :text="[$translate('Подразделение добавлено')]"
                                    text-html
                                    :stamp="moment(department.created_at).locale(locale).fromNow()"
                                    size="16"
                                    sent
                    />
                </template>
            </Card>
        </q-dialog>
    </q-item>

    <q-slide-transition>
        <div v-show="toggleDescription || printable" class="q-mx-sm q-mb-sm">
            <q-item-label caption>{{ $translate('project.view.ssc-work-description') }}</q-item-label>
            <div class="q-p-sm"
                 v-html="helpers.nl2br(department.description??'&mdash;')"></div>
            <q-card-actions v-if="department.approved !== 1 && department.ssc.ssc_show_work_description"
                            align="right"
                            class="print-hide">
                <q-btn icon="o_edit"
                       :label="$translate('buttons.change')"
                       dense no-caps
                       @click="toggleDescriptionEdit = true"/>
                <q-dialog v-model="toggleDescriptionEdit">
                    <Card :title="$translate('project.view.ssc-work-description')"
                          in-modal show-close-button>
                        <q-editor @paste="onPaste"
                                  ref="editorRef"
                                  type="textarea"
                                  v-model="departmentDescriptionForm.description"
                                  :model-value="department.description"/>
                        <div class="text-negative"
                             v-if="departmentDescriptionForm.errors.hasOwnProperty('description')">
                            {{ _.get(departmentDescriptionForm.errors, 'description') }}
                        </div>
                        <template v-slot:actions>
                            <q-btn icon="o_save"
                                   :label="$translate('buttons.save')"
                                   color="positive"
                                   @click="departmentDescriptionFormSubmit"/>
                            <q-btn icon="o_close"
                                   :label="$translate('buttons.cancel')"
                                   color="dark"
                                   v-close-popup/>
                        </template>
                    </Card>
                </q-dialog>
            </q-card-actions>

        </div>
    </q-slide-transition>
    <q-separator/>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import moment from 'moment/min/moment-with-locales';
import helpers from "@/helpers";
import {DepartmentActions,MemberItem} from "@/Components/Project/Card"
import {Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    project: {
        type: Object,
        required: true
    },
    department: {
        type: Object,
        required: true
    },
    printable: {
        type: Boolean,
        default: false
    }
})

const unlimited = computed(() => usePage().props.config.unlimited);
const locale = computed(() => usePage().props.locale);

const toggleDescription = ref(false)
const toggleDescriptionEdit = ref(false)
const toggleContact = ref(false)
const toggleChat = ref(false)
const toggleCount = ref(false)

const departmentDescriptionForm = useForm({
    ssc_id: null,
    description: null,
})
const departmentCountForm = useForm({
    count: null,
    comment: null,
})
const commentAuthorProjectMember = (author) => {
    if (props.project.leader_id === author.user_id) {
        return true
    }
    return _.findIndex(props.project.members, {user_id: author.user_id}) >= 0;
}

const openCommentDialog = () => {
    toggleChat.value = true
    loadComments()
}
const comments = ref(null)
const loadingComment = ref(false)

const editorRef = ref();

const loadComments = () => {
    loadingComment.value = true;
    axios
            .get(route('ajax.project', {m: 'comments', id: props.department.id}))
            .then((response) => {
                comments.value = response.data.results
            })
            .finally(() => {
                loadingComment.value = false;
            })

}
const departmentStatusIcon = (status) => {
    switch (status) {
        case 1:
            return {name: 'check', color: 'positive'}
        case 0:
            return {name: 'hourglass_bottom', color: 'info'}
        case -1:
            return {name: 'hourglass_top', color: 'orange'}
        case -2:
            return {name: 'block', color: 'negative'}
        case -3:
        case -4:
        case -5:
            return {name: 'pause', color: 'accent'}
    }
}


const departmentDescriptionFormSubmit = () => {
    departmentDescriptionForm
            .transform((data) => ({
                ...data,
                ssc_id: props.department.ssc_id
            }))
            .post(route('project.request.description', props.project), {
                onSuccess: () => {
                    toggleDescriptionEdit.value = false
                    departmentDescriptionForm.reset()
                    router.reload()
                }
            })
}
const departmentCountFormSubmit = () => {
    departmentCountForm
            .post(route('project.request.update', [props.project, props.department]), {
                onSuccess: () => {
                    toggleCount.value = false
                    departmentCountForm.reset()
                    router.reload()
                }
            })
}

const onPaste = (evt) => {
    helpers.sanitizeOnPaste(evt, editorRef);
}
</script>