<template>
    <Card title-bg="indigo-2"
          title-text="indigo-10"
          :title="$translate('module-microcollections.type.form.title.create')"
          :header-toolbar="can.back"
    >
        <template v-if="can.back" v-slot:headerActions>
            <q-btn :label="$translate('buttons.back')"
                   @click="router.get($route('services.microcollections.type.index'))"
                   icon="o_chevron_left"
                   color="indigo-5"
                   no-caps
                   dense
            />
        </template>
        <MicroCollectionTypeForm v-model="form"/>
        <template v-slot:actions>
            <q-btn :label="$translate('buttons.save')" @click="submitForm" color="positive"/>
        </template>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {Form as MicroCollectionTypeForm} from "@/Components/MicroCollections/Type";
import {getCurrentInstance} from "vue";
import {router, useForm} from "@inertiajs/vue3";

const props = defineProps({
    can: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    name: '',
    description_i18n: {ru: '', en: ''},
    public_state: true
});

const submitForm = () => {
    form.post(route('services.microcollections.type.store'));
};
</script>
