<template>
    <Card :title="$translate('research-report.title.equipment')" class="q-ma-sm">
        <q-select v-model="selectedEquipment"
                  :options="options"
                  :loading="loading"
                  @filter="filterEquipment"
                  use-input
                  input-debounce="1000"
                  options-dense
                  clearable
                  clear-icon="clear"
                  @update:model-value="onSelectEquipment"
                  :label="$translate('research-report.fields.equipment.equipment')"
                  :hint="$translate('research-report.fields.equipment.equipment-placeholder')"
                  :error="form.errors.hasOwnProperty('equipment_id')"
                  :error-message="_.get(form.errors,'equipment_id')"
        >
            <template v-slot:selected v-if="selectedEquipment">
                <q-item>
                    <q-item-section>
                        <q-item-label>{{ selectedEquipment.display_name }}</q-item-label>
                        <div class="text-small text-italic q-ml-md">
                            {{ $translate('equipment.item.fields.inventory_number-title') }}:
                            {{ selectedEquipment.inventory_number ?? '&mdash;' }} /
                            {{ selectedEquipment.serial_number ?? '&mdash;' }}
                        </div>
                        <div class="text-small text-italic q-ml-md">
                            {{ $translate('equipment.item.fields.placement_address') }}:
                            {{ selectedEquipment.placement_address ?? '&mdash;' }}
                        </div>
                        <div v-if="!selectedEquipment.loading" class="text-negative q-ml-md">
                            {{ $translate('equipment.state.no-planned') }}
                        </div>
                    </q-item-section>
                </q-item>
            </template>
        </q-select>
        <div class="row q-gutter-x-lg" v-if="selectedEquipment">
            <div class="col-24" v-if="_.size(selectedEquipment.complex)>0">
                <q-field :label="$translate('research-report.label.complex-part')+':'"
                         borderless stack-label>
                    <template v-slot:control>
                        <div class="flex column q-gutter-y-xs">
                            <template v-for="(complex) in selectedEquipment.complex">
                                <q-checkbox v-model="form.complex_part"
                                            :val="complex.id"
                                            :label="complex.label"
                                            size="xs"
                                            dense
                                />
                            </template>
                        </div>
                    </template>
                </q-field>
            </div>
            <div class="col-auto">
                <q-input mask="date" v-model="form.date" ref="date"
                         :prefix="$translate('research-report.fields.equipment.date')"
                         @update:model-value="loadEquipmentWorkedTime"
                         bottom-slots
                         input-class="text-center"
                         :error="form.errors.hasOwnProperty('date')"
                         :error-message="_.get(form.errors,'date')">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer" @click="showDateDialog">
                            <q-popup-proxy v-model="dateDialog" cover no-parent-event
                                           transition-show="scale"
                                           transition-hide="scale">
                                <q-date v-model="form.date"
                                        :options="dateOptions"
                                        @update:model-value="loadEquipmentWorkedTime"
                                        minimal>
                                    <div class="row items-center justify-end">
                                        <q-btn @click="hideDateDialog"
                                               :label="$translate('buttons.select')"
                                               color="primary" flat/>
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                    <template v-slot:hint v-if="selectedEquipment && form.date">
                        {{
                            $translate('equipment.state.used-time', {
                                time: equipmentLoading,
                                total: helpers.hour2time(selectedEquipment.worktime_day[moment(form.date).weekday()])
                            })
                        }}
                    </template>
                </q-input>
            </div>
            <div class="col-auto">
                <q-input v-model="form.time"
                         :prefix="$translate('research-report.fields.equipment.time')"
                         :hint="$translate('research-report.fields.equipment.time-placeholder')"
                         input-class="text-center"
                         :error="form.errors.hasOwnProperty('time')"
                         :error-message="_.get(form.errors,'time')"/>
            </div>
            <div class="col" v-if="selectedEquipment.multi_assay && research.assay > 0 && _.size(groupUsage)>0">
                <q-select v-model="selectedGroup"
                          :label="$translate('research-report.fields.equipment.group')"
                          :options="groupUsage"
                          @update:model-value="onSelectGroup"
                          clearable
                          clear-icon="clear"/>
            </div>
            <div class="col-3" v-if="selectedEquipment.multi_assay && research.assay > 0">
                <q-select v-model="form.assay"
                          :label="$translate('research-report.fields.equipment.count-assay')"
                          :options="assayOptions" options-dense/>
            </div>
        </div>

        <q-input type="textarea" rows="2"
                 v-if="selectedEquipment"
                 v-model="form.comment"
                 :label="$translate('research-report.fields.equipment.comment-placeholder')"/>

        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.add')"
                   @click="formSubmit"
                   color="positive"
                   dense/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {useReportDataStore} from '@/store/reportDataStore';
import axios from "axios";
import {useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment";
import Card from "@/Components/Block/Card.vue";
import helpers from "@/helpers.js";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    research: {type: Object, required: true}
})

const reportDataStore = useReportDataStore()

const equipmentList = ref(null);
const loading = ref(false);
const options = ref([]);
const filterEquipment = (val, update, abort) => {
    loading.value = true;
    if (equipmentList.value !== null) {
        if (val === null) {
            update(() => {
                options.value = equipmentList.value
            })
            loading.value = false
            return
        }
        update(() => {
            const needle = val.toLowerCase()
            options.value = _.filter(equipmentList.value, (v => (v.display_name).toLowerCase().indexOf(needle) > -1))
        })
    }
    loading.value = false
}

/**
 * Форма добавления
 * @type {InertiaForm<{date: null, overtimereason_size: null, unit: string, overtimereason: null, assay: null, complex_part: *[], comment: null, time: null, equipment_id: null, group: null}>}
 */
const form = useForm({
    equipment_id: null,
    date: null,
    time: null,
    unit: 'min',
    overtimereason: null,
    overtimereason_size: null,
    comment: null,
    assay: null,
    group: null,
    complex_part: [],
})

const selectedEquipment = ref(null)

const onSelectEquipment = (val) => {
    form.reset()
    form.equipment_id = val ? val.id : null
}

const dateDialog = ref(false)

const showDateDialog = () => {
    dateDialog.value = true
}
const hideDateDialog = () => {
    dateDialog.value = false
}
/**
 * Минимальное количество образцов загружаемых в оборудование
 * зависит от количества образцов полученных в рамках исследования.
 *
 * @type {ComputedRef<number>}
 */
const minAssay = computed(() => {
    return Number(Math.min(
            _.get(selectedEquipment.value, 'multi_assay'),
            _.get(props.research, 'assay_received')
    ))
})
/**
 * Массив количества образцов
 *
 * @type {ComputedRef<number[]>}
 */
const assayOptions = computed(() => {
    return _.range(1, minAssay.value + 1)
})

/**
 * Проверка даты на доступность заполнения
 *
 * @param {Date} date
 * @returns {boolean}
 */
const dateOptions = (date) => {
    if (moment(date, 'YYYY/MM/DD').startOf('day').isAfter()) {
        return false
    }
    if (moment(date, 'YYYY/MM/DD').startOf('day').isBefore(moment(props.research.responsible_assign).startOf('day'))) {
        return false
    }
    if (moment(date, 'YYYY/MM/DD').startOf('day').isAfter(moment(props.research.project.date_end).startOf('day'))) {
        return false
    }
    return _.size(_.pickBy(selectedEquipment.value.worked_days, (work_period) => {
        let start = moment(work_period.from).subtract(1, 'days').startOf('day');
        let end = moment(work_period.to).add(1, 'days').startOf('day');
        return (moment(date, 'YYYY/MM/DD').startOf('day').isBetween(start, end))
    })) > 0
}

/**
 * Группы для совместного использования образцов
 *
 * @type {Ref<UnwrapRef<null>>}
 */
const groupUsage = ref(null)
const selectedGroup = ref(null)
const onSelectGroup = (val) => {
    form.group = selectedGroup.value.value;
    form.time = selectedGroup.value.time;
}

/**
 * Загрузка оборудования на выбранную дату
 *
 * @type {Ref<UnwrapRef<null>>}
 */
const equipmentLoading = ref(null)

/**
 *
 */
const loadEquipmentWorkedTime = () => {
    axios.get(route('ajax.equipment', {
        m: 'getWorkedTime',
        equipment_id: selectedEquipment.value.id,
        research_id: props.research.id,
        date: moment(form.date, 'YYYY/MM/DD').startOf('day').format('YYYY-MM-DD')
    })).then((response) => {
        // console.log(response.data.results);
        // должно вернуть сколько времени израсходовано
        // console.log(vm.equipment_list[vm.equipment.equipment_id]);
        // Если в выбранном оборудовании указано что загрузка нескольких образцов и заявка подразумевает загрузку образцов

        groupUsage.value = _.filter(
                _.map(response.data.results, (item) => {
                    if (
                            !(
                                    item.project_research_id === props.research.id
                                    ||
                                    item.assay === selectedEquipment.value.multi_assay
                            )
                    ) {
                        return {
                            value: item.id,
                            group: item.group,
                            label: '#' + _.get(item, 'project_research_number') + ' / ' + Number(item.assay) + 'обр. / ' + item.used_time_display,
                            time: Number(item.used_time),
                            assay: Number(item.assay),
                        }
                    }
                })
        )
        equipmentLoading.value = helpers.int2time(_.sumBy(response.data.results, 'used_time'))

    }).finally(() => {
        // this.$parent.isLoading = false;
        //this.$root.$data.isLoading = false;
    });
}

const loadEquipment = () => {
    loading.value = true
    axios.get(route('ajax.equipment', {
                m: 'inSSC',
                ssc_id: props.research.ssc_id,
                template_id: props.research.template_id,
            })
    ).then((response) => {
        equipmentList.value = response.data.results;
    }).finally(() => {
        loading.value = false
    });
}

const formSubmit = () => {

    form.post(route('project.research.report.equipment.store', props.research),
            {
                onSuccess: () => {
                    selectedEquipment.value = null;
                    selectedGroup.value = null;
                    form.reset();
                    reportDataStore.loadEquipments();
                }
            })
}
onMounted(() => {
    loadEquipment()
})
</script>
