<template>
    <Card title-bg="deep-purple-2"
          :title="$translate('module-sensors.sensor-groups.form.title.create')"
          actions-align="center"
          header-toolbar
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('module-sensors.sensor-groups.form.actions.back')"
                   @click="router.get($route('services.sensors.sensor-groups'))"
                   icon="o_chevron_left"
                   color="deep-purple-5"
                   dense
            />
        </template>
        <SensorGroupForm v-model="form" :measurement-types="measurementTypes"/>
        <template v-slot:actions>
            <q-btn :label="$translate('module-sensors.sensor-groups.form.actions.add')"
                   @click="submitForm"
                   color="positive"
            />
        </template>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {Form as SensorGroupForm} from "@/Components/Sensors/SensorGroup";
import {getCurrentInstance} from "vue";
import {router, useForm} from "@inertiajs/vue3";

const props = defineProps({
    measurementTypes: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    name: '',
    timeout: 5,
    allowable_measurements: []
});

const submitForm = () => {
    form.post(route('services.sensors.sensor-groups.store'), {
        onSuccess: () => router.visit(route('services.sensors.sensor-groups'))
    });
};
</script>
