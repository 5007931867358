<template>
    <div v-if="allowSelector">
        <q-avatar v-if="sidebar"
                  square class="bg-grey-2 cursor-pointer"
                  style="width: 100%"
                  @click="select.showPopup()">
            {{ _.get(selectedDepartment, 'short') || 'все' }}
            <q-tooltip class="text-body2">{{ _.get(selectedDepartment, 'name') }}</q-tooltip>
        </q-avatar>

        <div :class="sidebar?'hidden':null">
            <q-select v-model="selectedDepartment"
                      ref="select"
                      :label="$translate('selector.selected-ssc')"
                      :options="departmentList"
                      option-value="id"
                      option-label="name"
                      options-dense
                      @update:model-value="switchSSC"
                      lazy-rules
                      filled
            >
                <template v-slot:selected v-if="selectedDepartment">
                    {{ selectedDepartment.text }}
                </template>
            </q-select>
        </div>
    </div>

</template>

<script setup lang="ts">
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import _ from "lodash";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate

const allowSelector: any = computed((): any => _.get(usePage().props, 'auth.ssc_selector'));
const currentSsc: any = computed((): any => _.get(usePage().props, 'auth.current_ssc'));
const departmentList: any = computed((): any => _.get(usePage().props, 'auth.ssc'));
const selectedDepartment: any = computed((): any => _.find(departmentList.value, {id: currentSsc.value}))

const select = ref()

interface Props {
    sidebar?: boolean
}

withDefaults(defineProps<Props>(), {sidebar: false})

const switchSSC = (value: any) => {
    router.get('/switch/ssc/' + value.id)
}

onMounted(() => {
    if (!selectedDepartment.value) {
        switchSSC({id: -1})
    }
})
</script>