<template>
    <q-card-section>
        <Card no-card-section hide-actions>
            <template v-slot:title>
                <q-toggle v-model="data.show"
                          :label="$translate('Стоимость обработки статьи (Article Processing Charge, APC)')"
                          dense/>
            </template>
            <q-card-section v-if="data.show">
                <q-input v-model="data.name"
                         :label="$translate('Наименование блока')"
                         dense/>
                <q-checkbox v-model="data.required"
                            :label="$translate('Обязательный блок')"
                            size="xs"
                            dense/>
            </q-card-section>
        </Card>
    </q-card-section>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const data = defineModel({
    default: {
        show: false,
        name: '',
        required: false
    }
})
</script>