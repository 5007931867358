<template>
    <div class="row">
        <q-btn label="ID/UUID" flat dense no-caps>
            <q-menu>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>
                            ID
                        </q-item-label>
                        <q-item-label>
                            {{ publication.report_type_category }}:{{ publication.id }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item v-if="publication.uuid">
                    <q-item-section>
                        <q-item-label caption>
                            Pure UUID
                        </q-item-label>
                        <q-item-label>
                            {{ publication.uuid }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </q-menu>
        </q-btn>
        <q-separator v-if="publication.can.syncReportMaterial" class="q-mx-sm" vertical/>
        <q-btn v-if="publication.can.syncReportMaterial"
               icon="mdi-cloud-sync-outline"
               :label="$translate('buttons.pure-sync')"
               @click="router.patch($route('publication.update-from-pure', {
                   category: publication.report_type_category,
                   uuid: publication.uuid
               }))"
               no-caps
               dense
               flat
        />
    </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

defineProps({publication: {type: Object}})
</script>