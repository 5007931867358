<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          actions-align="left"
          no-card-section>
        <q-list separator>
            <template v-for="(item, index) in items">
                <q-item>
                    <q-item-section>
                        <q-select v-model="items[index].type"
                                  :options="options"
                                  options-dense
                                  map-options
                                  emit-value
                                  dense
                                  :error="errors.hasOwnProperty('externalid.'+index+'.type')"
                                  :error-message="_.get(errors,'externalid.'+index+'.type')"
                        />
                    </q-item-section>
                    <q-item-section>
                        <q-input v-model="items[index].value"
                                 dense
                                 placeholder="значение"
                                 :error="errors.hasOwnProperty('externalid.'+index+'.value')"
                                 :error-message="_.get(errors,'externalid.'+index+'.value')"
                        />
                    </q-item-section>
                    <q-item-section side>
                        <q-btn icon="o_delete"
                               color="negative"
                               @click="deleteItem(index)"
                               flat dense
                        />
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
        <div v-if="errors.hasOwnProperty('externalid')" class="text-negative q-pa-sm">
            {{ _.get(errors, 'externalid') }}
        </div>
        <template v-slot:actions>
            <q-btn icon="o_add"
                   :label="$translate('buttons.add')"
                   dense
                   no-caps
                   @click="addItem"
            />
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object},
})

const items = defineModel('items', {type: Array})
const errors = defineModel('errors', {type: Object})

const addItem = () => {
    items.value.push({
        type: null,
        value: null
    })
}
const deleteItem = (index) => {
    items.value.splice(index, 1)
}

const options = computed(() => {
    return _.map($translate('report-type.externalid'), (v, k) => ({value: k, label: v}))
})
</script>