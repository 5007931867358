<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions>

        <div class="flex items-center q-gutter-x-md">
            <q-item-label>{{ $translate('report-material.fields.apc.title') }}:</q-item-label>
            <q-radio v-model="apc.pay" val="1" :label="$translate('buttons.yes')" size="xs"/>
            <q-radio v-model="apc.pay" val="0" :label="$translate('buttons.no')" size="xs"/>
        </div>

        <div class="row q-gutter-x-md" v-if="apc.pay==='1'">
            <q-input v-model="apc.cost"
                     :prefix="$translate('report-material.fields.apc.cost')"
                     :hint="$translate('report-material.fields.apc.cost_currency')"
                     mask="#.##"
                     reverse-fill-mask
                     input-class="text-center"
                     class="col"
                     dense>
                <template v-slot:append>
                    <q-select v-model="apc.currency"
                              :options="options"
                              options-dense
                              :label="$translate('валюта')"
                              style="width: 100px"
                              map-options
                              emit-value
                              dense
                              borderless>
                        <template v-slot:selected>
                            {{ apc.currency }}
                        </template>
                    </q-select>
                </template>
            </q-input>

            <q-input v-model="apc.cost_real"
                     :prefix="$translate('report-material.fields.apc.cost_real')"
                     :suffix="$translate('units.rub')"
                     :hint="$translate('report-material.fields.apc.cost_real_currency')"
                     mask="#.##"
                     reverse-fill-mask
                     input-class="text-center"
                     class="col"
                     dense/>
        </div>
        <div class="text-negative" v-if="errors.hasOwnProperty('apc')">
            {{ _.get(errors, 'apc') }}
        </div>
        <div class="text-negative" v-if="errors.hasOwnProperty('apc.pay')">
            {{ _.get(errors, 'apc.pay') }}
        </div>
        <div class="text-negative" v-if="errors.hasOwnProperty('apc.cost')">
            {{ _.get(errors, 'apc.cost') }}
        </div>
        <div class="text-negative" v-if="errors.hasOwnProperty('apc.currency')">
            {{ _.get(errors, 'apc.currency') }}
        </div>
        <div class="text-negative" v-if="errors.hasOwnProperty('apc.cost_real')">
            {{ _.get(errors, 'apc.cost_real') }}
        </div>
    </Card>
</template>

<script setup>
/**************************************************
 *
 *  Article Processing Charge
 *
 ***************************************************/
import {computed, getCurrentInstance} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const props = defineProps({
    fields: {type: Object}
})

const apc = defineModel('apc', {
    type: Object,
    default() {
        return {pay: null, cost: null, currency: null, cost_real: null}
    }
})
const errors = defineModel('errors', {type: Object})

const options = computed(() => {
    return _.sortBy(_.map($translate('currency'), (v, k) => ({value: k, label: v})), 'label')
})
</script>