<template>
    <div class="row q-gutter-md" :class="{'column':$q.screen.lt.md}">
        <div class="col q-gutter-y-md">
            <!-- тип  оборудования -->
            <q-select v-if="allowEdit"
                      v-model="equipment.equipment_type"
                      :label="$translate('equipment.item.fields.equipment_type')"
                      :options="equipmentTypes"
                      stack-label emit-value map-options
                      :error-message="_.get(errors,'equipment_type')"
                      :error="errors.hasOwnProperty('equipment_type')"/>
            <q-field v-else disable
                     :label="$translate('equipment.item.fields.equipment_type')" stack-label>
                <template v-slot:control>
                    {{ _.find(equipmentTypes, {value: equipment.equipment_type}).label }}
                </template>
            </q-field>

            <!-- оборудование в справочнике -->
            <q-select v-if="isSingle && (allowEdit || !equipment.equipment_catalogue)"
                      v-model="equipment.equipment_catalogue"
                      @update:modelValue="selectEquipment"
                      :label="$translate('equipment.item.fields.equipment_catalogue')"
                      :options="equipmentCatalogue"
                      stack-label options-dense use-input
                      option-value="value"
                      @filter="searchEquipment"
                      :error-message="_.get(errors,'equipment_catalogue_id')"
                      :error="errors.hasOwnProperty('equipment_catalogue_id')"/>
            <q-field v-else-if="equipment.equipment_type === 'single'"
                     disable
                     :label="$translate('equipment.item.fields.equipment_catalogue')" stack-label>
                <template v-slot:control>
                    {{ _.get(equipment.equipment_catalogue, 'name') }}
                </template>
            </q-field>

            <!-- наименование оборудования -->
            <q-input v-model="equipment.display_name"
                     :label="$translate('equipment.item.fields.display_name')"
                     stack-label
                     :error-message="_.get(errors,'display_name')"
                     :error="errors.hasOwnProperty('display_name')">
                <template v-slot:append v-if="equipment.equipment_catalogue_id && allowEdit">
                    <q-btn icon="content_copy"
                           :title="$translate('equipment.item.actions.clone-name')"
                           flat
                           @click="copyDisplayNameFromCatalogue"/>
                </template>
            </q-input>

            <!-- назначение оборудования -->
            <q-select v-if="allowEdit"
                      v-model="equipment.purpose"
                      :label="$translate('equipment.item.fields.purpose')" stack-label
                      :options="purposeType" options-dense emit-value map-options
                      :error-message="_.get(errors,'purpose')"
                      :error="errors.hasOwnProperty('purpose')"/>
            <q-field v-else-if="equipment.equipment_type === 'single'"
                     disable
                     :label="$translate('equipment.item.fields.purpose')" stack-label>
                <template v-slot:control>
                    {{ _.get(_.find(purposeType, {value: equipment.purpose}), 'label') }}
                </template>
            </q-field>

            <!-- количество образцов -->
            <div class="row items-center q-gutter-x-sm">
                <q-checkbox v-model="multi_assay"
                            @update:modelValue="toggleMultiAssay"
                            dense size="sm"
                            :label="$translate('equipment.item.fields.multi-assay-title')"/>

                <q-input v-if="multi_assay"
                         v-model="equipment.multi_assay"
                         :hint="$translate('equipment.item.fields.multi-assay-count-title')"
                         :title="$translate('equipment.item.fields.multi-assay-count')"
                         input-class="text-center"
                         min="2"
                         mask="#" reverse-fill-mask dense
                         :error-message="_.get(errors,'multi_assay')"
                         :error="errors.hasOwnProperty('multi_assay')"/>
            </div>
            <!-- уникальное оборудование -->
            <div class="row items-center q-gutter-x-sm">
                <q-checkbox v-model="equipment.unique" dense size="sm"
                            :label="$translate('equipment.item.fields.unique')"/>
            </div>
            <!-- публикация для ЦКП -->
            <div class="row items-center q-gutter-x-sm">
                <q-checkbox v-model="equipment.public.ckp" dense size="sm"
                            :label="$translate('equipment.item.fields.public-ckp')"/>
            </div>
            <!-- публикация для внешних сайтов -->
            <div class="row items-center q-gutter-x-sm">
                <q-checkbox v-model="equipment.public.external" dense size="sm"
                            :label="$translate('equipment.item.fields.public-external')"/>
            </div>
            <!-- данные ФСТЭК -->
            <div class="row column">
                <q-checkbox v-model="equipment.fstec" dense size="sm"
                            :label="$translate('equipment.item.fields.fstec')"/>

                <q-file :label="$translate('equipment.item.fields.fstec-request')"
                        v-model="equipment.fstec_data.request"
                        v-if="equipment.fstec" clearable class="q-ml-lg" stack-label>
                    <template v-slot:append>
                        <q-icon name="attach_file"/>
                    </template>
                </q-file>
                <q-file :label="$translate('equipment.item.fields.fstec-response')"
                        v-model="equipment.fstec_data.response"
                        v-if="equipment.fstec" clearable class="q-ml-lg" stack-label>
                    <template v-slot:append>
                        <q-icon name="attach_file"/>
                    </template>
                </q-file>
                <q-input type="textarea" rows="3" :label="$translate('equipment.item.fields.fstec-recommendation')"
                         v-if="equipment.fstec" v-model="equipment.fstec_data.recommendation" class="q-ml-lg"
                         stack-label>
                </q-input>
            </div>
        </div>
        <div class="col q-gutter-y-md">
            <q-file v-model="equipment.public.photo"
                    :label="$translate('equipment.item.fields.public-photo')" stack-label
                    accept="image/*"
                    @update:model-value="onFileChange" clearable
                    :error-message="_.get(errors,'public.photo')"
                    :error="errors.hasOwnProperty('public.photo')">
                <template v-slot:prepend v-if="photo_preview">
                    <q-img :src="photo_preview" class="q-mx-sm" width="3rem"
                           loading="lazy" fit="cover"/>
                </template>
                <template v-slot:append>
                    <q-icon name="attach_file"/>
                </template>
            </q-file>

            <q-select v-if="allowEdit"
                      v-model="equipment.ssc_id"
                      :label="$translate('equipment.item.fields.ssc')" stack-label
                      :options="sscList" options-dense emit-value map-options
                      :error-message="_.get(errors,'ssc_id')"
                      :error="errors.hasOwnProperty('ssc_id')"/>
            <q-field v-else disable
                     :label="$translate('equipment.item.fields.ssc')" stack-label>
                <template v-slot:control>
                    {{ _.get(equipment.ssc, 'name') }}
                </template>
            </q-field>

            <q-input type="textarea" rows="2"
                     :label="$translate('equipment.item.fields.placement_address')" stack-label
                     v-model="equipment.placement_address"/>

            <q-input v-model="equipment.placement_square"
                     label-slot stack-label
                     min="0.01" step="0.01" type="number">
                <template v-slot:label>
                    <div class="row items-center all-pointer-events"
                         v-html="$translate('equipment.item.fields.placement_square')"/>
                </template>
            </q-input>

            <q-input v-model="equipment.equipment_square"
                     label-slot stack-label
                     min="0.01" step="0.01" type="number">
                <template v-slot:label>
                    <div class="row items-center all-pointer-events"
                         v-html="$translate('equipment.item.fields.equipment_square')"/>
                </template>
            </q-input>

            <q-select v-model="equipment.tags"
                      :label="$translate('equipment.item.fields.tags')" stack-label
                      multiple use-input use-chips new-value-mode="add-unique"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, onBeforeMount, ref} from "vue";
import {usePage} from "@inertiajs/vue3";
import axios from "axios";
import _ from "lodash"
import {useQuasar} from 'quasar'
import {Equipment} from '@/entity'
import {set} from '@vueuse/core'

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $q = useQuasar()

const equipment: Equipment = defineModel()

const errors: object = computed(() => usePage().props.errors)


const equipmentTypes: any = computed(() => _.get(usePage().props, 'options.equipmentTypes'))
const purposeType: any = computed(() => _.get(usePage().props, 'options.purposeType'))
const sscList: any = computed(() => _.get(usePage().props, 'options.sscList'))

const equipmentCatalogue = ref([]);

const multi_assay = ref(false)
const allowEdit = computed(() => !(_.get(equipment.value, 'id') > 0))

const isComplex = computed(() => _.get(equipment.value, 'equipment_type') === 'complex')
const isSingle = computed(() => _.get(equipment.value, 'equipment_type') === 'single')

const searchEquipment = (val: any, update: any) => {
    // console.log(val)
    axios.get('/equipment.catalogue.json', {
        params: {
            m: 'options',
            filter: val
        }
    })
            .then((response) => {
                update(() => {
                    set(equipmentCatalogue, response.data.results);
                })
            });
}
const copyDisplayNameFromCatalogue = () => {
    _.set(equipment.value, 'display_name', _.get(_.find(equipmentCatalogue.value, {value: equipment.value.equipment_catalogue_id}), 'label'));
}

/**
 * адрес фото для предпросмотра
 *
 * @type {string|null}
 */
const photo_preview = ref(null)
const onFileChange = (e: any) => {
    set(photo_preview, null);
    if (e) {
        set(photo_preview, URL.createObjectURL(e));
    }
}

const toggleMultiAssay = () => {
    if (!multi_assay.value) {
        _.set(equipment.value, 'multi_assay', null)
    } else {
        _.set(equipment.value, 'multi_assay', 2)
    }
}

const selectEquipment = () => {
    _.set(equipment.value, 'equipment_catalogue_id', _.get(equipment.value, 'equipment_catalogue.value'))
}


onBeforeMount(() => {
    // _.set(options.value, 'equipmentTypes', () => usePage().props.options.equipmentTypes);
    // _.set(options.value, 'purposeType', () => usePage().props.options.purposeType);
    // _.set(options.value, 'sscList', () => usePage().props.options.sscList);
})
</script>