<template>
    <Card :title="$translate('equipment.category.title.show')+': '+equipmentCategory.name"
          title-bg="deep-purple-1"
          header-toolbar hide-actions>
        <template v-slot:headerActions>
            <q-btn icon="navigate_before"
                   no-wrap
                   :label="$translate('buttons.back')"
                   color="deep-purple-4"
                   @click="router.visit($route('admin.equipment-category.index'))"
                   dense/>
        </template>
        <div class="row q-gutter-md" :class="{'column':$q.screen.lt.md}">
            <Card :title="$translate('equipment.category.title.baseinfo')"
                  hide-actions
                  class="col">
                <q-field :label="$translate('equipment.category.fields.code')" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">{{ equipmentCategory.code }}</div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.category.fields.name')" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">{{ equipmentCategory.name }}</div>
                    </template>
                </q-field>
            </Card>
            <Card :title="$translate('equipment.category.title.catalogue') + ' ('+size(equipmentCategory.equipments)+')'"
                  hide-actions no-card-section
                  class="col">
                <div style="max-height: calc(100vh - 210px)"
                     class="scroll-y">
                    <q-list separator dense>
                        <template v-for="(equipment) in sortBy(equipmentCategory.equipments,'name')">
                            <q-item @click="router.visit($route('admin.equipment-catalogue.show',equipment))"
                                    clickable>
                                <q-item-section>
                                    <q-item-label>{{ equipment.name }}</q-item-label>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label>#{{ equipment.id }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </div>
            </Card>
        </div>
    </Card>
</template>

<script setup lang="ts">
import {getCurrentInstance,} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {Card} from "@/Components/Block/";
import {sortBy, size} from "lodash"
import {useQuasar} from 'quasar'

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route
const $q = useQuasar()

interface Props {
    equipmentCategory: any
}

defineProps<Props>()
</script>