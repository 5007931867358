<template>
    <Alert v-if="$translate('module-auth.description')"
           variant="info"
           :message="$translate('module-auth.description')"
    />
    <SettingsTable :data="config"/>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const config = [
    {
        name: 'SELF_REGISTRATION',
        label: $translate('module-auth.settings.SELF_REGISTRATION'),
        value: props.settings.module.SELF_REGISTRATION,
        description: $translate('module-auth.settings.SELF_REGISTRATION_description'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'PASSWORD_MINLENGTH',
        label: $translate('module-auth.settings.PASSWORD_MINLENGTH'),
        value: props.settings.module.PASSWORD_MINLENGTH,
        description: $translate('module-auth.settings.PASSWORD_MINLENGTH_description'),
        block: 'module',
        type: 'number',
    },
    {
        name: 'SELF_PASSWORD_RECOVERY',
        label: $translate('module-auth.settings.SELF_PASSWORD_RECOVERY'),
        value: props.settings.module.SELF_PASSWORD_RECOVERY,
        description: $translate('module-auth.settings.SELF_PASSWORD_RECOVERY_description'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'VALIDATE_EMAIL',
        label: $translate('module-auth.settings.VALIDATE_EMAIL'),
        value: props.settings.module.VALIDATE_EMAIL,
        description: $translate('module-auth.settings.VALIDATE_EMAIL_description'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'LOGIN_STAFF',
        label: $translate('module-auth.settings.LOGIN_STAFF'),
        value: props.settings.module.LOGIN_STAFF,
        description: $translate('module-auth.settings.LOGIN_STAFF_description'),
        block: 'module',
        type: 'textarea',
    },
    {
        name: 'REGISTER_STAFF',
        label: $translate('module-auth.settings.REGISTER_STAFF'),
        value: props.settings.module.REGISTER_STAFF,
        description: $translate('module-auth.settings.REGISTER_STAFF_description'),
        block: 'module',
        type: 'textarea',
    },
    {
        name: 'RECOVERY_STAFF',
        label: $translate('module-auth.settings.RECOVERY_STAFF'),
        value: props.settings.module.RECOVERY_STAFF,
        description: $translate('module-auth.settings.RECOVERY_STAFF_description'),
        block: 'module',
        type: 'textarea',
    },
];
</script>
