<template>
    <Card :title="$translate('Выбор подразделения')"
          in-modal show-close-button>

        <SearchDepartment @onSelect="selectDepartment"/>

        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   @click="setDepartment"
                   color="positive"
                   dense/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import {Department as SearchDepartment} from "@/Components/Search";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const emit = defineEmits(['onSelect'])

const tab = ref('search')

const department = ref(null)

const selectDepartment = (user) => {
    department.value = user.company
    _.set(department.value, 'external', false)
}
const setDepartment = () => {
    emit('onSelect', department.value)
}
</script>