<template>
    <q-input v-model="form.name_i18n.ru"
             :label="$translate('module-microcollections.part.form.fields.name_i18n-ru')"
             :error="form.errors.hasOwnProperty('name_i18n.ru')"
             :error-message="_.get(form.errors, 'name_i18n.ru')"
             type="textarea"
             rows="3"
    />
    <q-input v-model="form.name_i18n.en"
             :label="$translate('module-microcollections.part.form.fields.name_i18n-en')"
             :error="form.errors.hasOwnProperty('name_i18n.en')"
             :error-message="_.get(form.errors, 'name_i18n.en')"
             type="textarea"
             rows="3"
    />
</template>

<script setup>
import {getCurrentInstance} from "vue";
import _ from "lodash";

/**
 * @param InertiaForm form
 */
const form = defineModel({type: Object});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
