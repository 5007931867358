<template>
    <Card title-bg="orange-1" :title="$translate('module-syexc.proposal.create.title')" header-toolbar>
        <template v-slot:headerActions>
            <q-btn :label="$translate('buttons.back')"
                   @click="router.get($route('services.sys-exp-ctrl.proposal.list'))"
                   icon="o_chevron_left"
                   color="orange-4"
                   dense
            />
        </template>
        <template v-slot:default>
            <ProposalForm :form="form"
                          :send-form="submitForm"
            ></ProposalForm>
        </template>
        <template v-slot:actions>
            <q-btn :label="$translate('module-syexc.actions.proposal.add')"
                   icon="save"
                   @click="submitForm"
                   color="positive"/>
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import ProposalForm from "@/Components/Syexc/Proposal/Form/ProposalForm.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    description: null,
    oldFiles: [],
    files: [],
    comment: null,
});

const submitForm = () => {
    form.post($route('services.sys-exp-ctrl.proposal.store'),{
        onSuccess: () => router.visit($route('services.sys-exp-ctrl.proposal.list'))
    })
};
</script>
