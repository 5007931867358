<script setup>
import {getCurrentInstance, onMounted, ref} from 'vue'
import {useQuasar} from "quasar";
import {RIDAnketa, RIDAnketaMember} from "@/entity";
import AnketaAuthorsItem from "@/Components/RID/Object/AnketaAuthorsItem.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();

const props = defineProps({
    anketa: {}
})
</script>
<template>
    <template v-for="(person,index) in anketa.persons" :key="index">
        <AnketaAuthorsItem :person="person" :vklad="anketa.vklad[index]"/>
    </template>
</template>