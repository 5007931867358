<template>
    <q-item>
        <q-item-section>
            <q-item-label class="text-weight-bold">
                {{ $translate('module-syexc.proposal.card.conclusion_data_form.title') }}
            </q-item-label>
        </q-item-section>
        <q-item-section side top>
            <div class="row justify-between">
                <q-btn :label="$translate('module-syexc.proposal.card.conclusion_data_form.actions.fill_project')"
                       @click="conclusionDataDialog = !conclusionDataDialog"
                       size="sm"
                />
            </div>
            <q-dialog v-model="conclusionDataDialog" @hide="sendFilledData">
                <Card :title="$translate('module-syexc.proposal.card.conclusion_data_form.modal.title', {
                          number: conclusionNumber ? conclusionNumber : ''
                      })"
                      style="width: 1150px; max-width: 80vw;"
                      header-toolbar
                >
                    <template v-slot:headerActions>
                        <q-btn icon="close" flat round dense v-close-popup />
                    </template>
                    <template v-slot:default>
                        <q-input v-model="conclusionData.p1"
                                 :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p1')"
                                 type="textarea"
                                 rows="2"
                        />
                        <q-input v-model="conclusionData.p2"
                                 :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p2')"
                                 type="textarea"
                                 rows="2"
                        />
                        <q-input v-model="conclusionData.p2_1"
                                 :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p2_1')"
                                 type="textarea"
                                 rows="2"
                        />
                        <q-input v-model="conclusionData.p2_2"
                                 :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p2_2')"
                                 rows="2"
                        />
                        <q-input v-model="conclusionData.p2_3"
                                 :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p2_3')"
                                 type="textarea"
                                 rows="2"
                        />
                        <q-input v-model="conclusionData.p2_4"
                                 :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p2_4')"
                                 type="textarea"
                                 rows="2"
                        />
                        <q-input v-model="conclusionData.p2_4_1"
                                 :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p2_4_1')"
                                 type="textarea"
                                 rows="2"
                        />
                        <q-input v-model="conclusionData.p2_4_2"
                                 :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p2_4_2')"
                                 type="textarea"
                                 rows="2"
                        />
                        <q-item class="q-mt-md">
                            <q-item-section>
                                <q-item-label class="text-weight-bold">
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.objects') }}
                                </q-item-label>
                                <q-item-label>
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p3') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-for="(object, index) in conclusionData.objects" :key="'p3_' + index">
                            <q-item-section>
                                <div class="text-italic">
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_index', {index: index + 1}) }}
                                    <q-btn :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.actions.delete_object')"
                                           @click="deleteObject(index)"
                                           color="secondary"
                                           size="xs"
                                    />
                                </div>
                                <div class="q-ml-md">
                                    <q-input v-model="conclusionData.objects[index].name"
                                             :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_name')"
                                    />
                                    <q-input v-model="conclusionData.objects[index].code"
                                             :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_code')"
                                    />
                                    <q-input v-model="conclusionData.objects[index].description"
                                             :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_description')"
                                             type="textarea"
                                             rows="2"
                                    />
                                </div>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <div>
                                    <q-btn :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.actions.add_object')"
                                           @click="addObject"
                                           color="secondary"
                                           icon="o_add_box"
                                           size="sm"
                                    />
                                </div>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p4_1') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-for="(object, index) in conclusionData.objects" :key="'p4_1_' + index">
                            <q-item-section>
                                <div class="text-italic">
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_index', {index: index + 1}) }}
                                </div>
                                <div class="q-ml-md">
                                    <div v-for="(list, li) in object.list" :key="'list_'+li" class="border-left q-pl-md">
                                        <div>
                                            {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_list') }}
                                        </div>
                                        <div class="row q-gutter-x-sm">
                                            <div class="col-4">
                                                <q-select v-model="conclusionData.objects[index].list[li].id"
                                                          :options="options"
                                                          @update:model-value="setObjectValue(index, li)"
                                                          options-dense
                                                          map-options
                                                          emit-value
                                                          outlined
                                                          dense
                                                />
                                            </div>
                                            <div class="col-18">
                                                <q-input v-model="conclusionData.objects[index].list[li].name"
                                                         type="textarea"
                                                         rows="3"
                                                         outlined
                                                         readonly
                                                />
                                            </div>
                                            <div class="col">
                                                <q-btn :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.actions.delete_object')"
                                                       @click="deleteObjectList(index, li)"
                                                       color="secondary"
                                                       size="sm"
                                                />
                                            </div>
                                        </div>
                                        <div v-for="(section, si) in list.sections" :key="'section_'+li" class="q-my-sm">
                                            <div class="row q-gutter-x-sm">
                                                <div class="col-22">
                                                    <q-input v-model="conclusionData.objects[index].list[li].sections[si].number"
                                                             :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_list_section')"
                                                             outlined
                                                    />
                                                </div>
                                                <div class="col">
                                                    <q-btn :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.actions.delete_object')"
                                                           @click="deleteObjectListSection(index, li, si)"
                                                           color="secondary"
                                                           size="sm"
                                                    />
                                                </div>
                                            </div>
                                            <div v-for="(position, pi) in section.positions" :key="'position_'+pi" class="q-my-sm">
                                                <div class="row q-gutter-x-sm">
                                                    <div class="col-22">
                                                        <q-input v-model="conclusionData.objects[index].list[li].sections[si].positions[pi].number"
                                                                 :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_list_section_position')"
                                                                 outlined
                                                        />
                                                    </div>
                                                    <div class="col">
                                                        <q-btn :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.actions.delete_object')"
                                                               @click="deleteObjectListSectionPosition(index, li, si, pi)"
                                                               color="secondary"
                                                               size="sm"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="{'q-mt-sm': _.isEmpty(section.positions)}">
                                                <q-btn :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.actions.add_position_to_section', {
                                                          number: conclusionData.objects[index].list[li].sections[si].number
                                                       })"
                                                       @click="addObjectListSectionPosition(index,li,si)"
                                                       color="secondary"
                                                       icon="o_add_box"
                                                       size="sm"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <q-btn :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.actions.add_section')"
                                                   @click="addObjectListSection(index, li)"
                                                   color="secondary"
                                                   icon="o_add_box"
                                                   size="sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="q-mt-md">
                                    <q-btn :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.actions.add_list', {index: index + 1})"
                                           @click="addObjectList(index)"
                                           color="secondary"
                                           icon="o_add_box"
                                           size="sm"
                                    />
                                </div>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p4_2') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-for="(object, index) in conclusionData.objects" :key="'p4_2_' + index">
                            <q-item-section>
                                <div class="text-italic">
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_index', {index: index + 1}) }}
                                </div>
                                <div class="q-ml-md">
                                    <q-input v-model="conclusionData.objects[index].character"
                                             :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_character')"
                                             type="textarea"
                                             rows="2"
                                    />
                                    <div class="row q-gutter-x-sm items-center q-my-sm">
                                        <div class="col-5">
                                            {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_list_section_position_description') }}
                                        </div>
                                        <div class="col">
                                            <div v-for="(list, li) in object.list" :key="'list_'+li">
                                                <div v-for="(section, si) in list.sections" :key="'section_'+si">
                                                    <div v-for="(position, pi) in section.positions" :key="'position_'+pi">
                                                        <q-input v-model="conclusionData.objects[index].list[li].sections[si].positions[pi].description"
                                                                 :label="'раздел '+section.number+' пункт '+position.number"
                                                                 type="textarea"
                                                                 rows="2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <q-select v-model="conclusionData.objects[index].result"
                                              :options="helpers.toOptions($translate('module-syexc.conslusion-data.p4_2'))"
                                              :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_result')"
                                              options-dense
                                              map-options
                                              emit-value
                                              dense
                                    />
                                </div>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p5') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p5_1') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p5_1_a') }}
                                </q-item-label>
                                <q-list class="q-mt-md" dense>
                                    <q-item>
                                        <q-item-section avatar top>
                                            <q-radio v-model="conclusionData.p5_1_a" val="1" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('module-syexc.conslusion-data.p5_1_a.1') }}
                                            </q-item-label>
                                            <q-input v-model="conclusionData.p5_1_a_1" dense />
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section avatar top>
                                            <q-radio v-model="conclusionData.p5_1_a" val="2" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('module-syexc.conslusion-data.p5_1_a.2') }}
                                            </q-item-label>
                                            <q-input v-model="conclusionData.p5_1_a_2" dense />
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section avatar top>
                                            <q-radio v-model="conclusionData.p5_1_a" val="3" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('module-syexc.conslusion-data.p5_1_a.3') }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p5_1_b') }}
                                </q-item-label>
                                <q-list class="q-mt-md" dense>
                                    <q-item>
                                        <q-item-section avatar top>
                                            <q-radio v-model="conclusionData.p5_1_b" val="1" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('module-syexc.conslusion-data.p5_1_b.1') }}
                                            </q-item-label>
                                            <q-input v-model="conclusionData.p5_1_b_1" dense />
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section avatar top>
                                            <q-radio v-model="conclusionData.p5_1_b" val="2" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('module-syexc.conslusion-data.p5_1_b.2') }}
                                            </q-item-label>
                                            <q-input v-model="conclusionData.p5_1_b_2" dense />
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section avatar top>
                                            <q-radio v-model="conclusionData.p5_1_b" val="3" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('module-syexc.conslusion-data.p5_1_b.3') }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p5_1_c') }}
                                </q-item-label>
                                <template v-for="(object, index) in conclusionData.objects" :key="'p5_1_c_'+index">
                                    <div class="q-mt-md">
                                        <div class="text-italic">
                                            {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.object_index', {index: index + 1}) }}
                                        </div>
                                        <q-list dense>
                                            <q-item>
                                                <q-item-section avatar top>
                                                    <q-radio v-model="conclusionData.objects[index].p5_1_c" val="1" />
                                                </q-item-section>
                                                <q-item-section>
                                                    <q-item-label>
                                                        {{ $translate('module-syexc.conslusion-data.p5_1_c.1') }}
                                                    </q-item-label>
                                                    <q-input v-model="conclusionData.objects[index].p5_1_c_1" dense />
                                                </q-item-section>
                                            </q-item>
                                            <q-item>
                                                <q-item-section avatar top>
                                                    <q-radio v-model="conclusionData.objects[index].p5_1_c" val="2" />
                                                </q-item-section>
                                                <q-item-section>
                                                    <q-item-label>
                                                        {{ $translate('module-syexc.conslusion-data.p5_1_c.2') }}
                                                    </q-item-label>
                                                    <q-input v-model="conclusionData.objects[index].p5_1_c_2" dense />
                                                </q-item-section>
                                            </q-item>
                                            <q-item>
                                                <q-item-section avatar top>
                                                    <q-radio v-model="conclusionData.objects[index].p5_1_c" val="3" />
                                                </q-item-section>
                                                <q-item-section>
                                                    <q-item-label>
                                                        {{ $translate('module-syexc.conslusion-data.p5_1_c.3') }}
                                                    </q-item-label>
                                                </q-item-section>
                                            </q-item>
                                        </q-list>
                                    </div>
                                </template>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p5_2') }}
                                </q-item-label>
                                <q-list class="q-mt-md" dense>
                                    <template v-for="(value, key) in $translate('module-syexc.conslusion-data.p5_2')" :key="'p5_2_'+key">
                                        <q-item>
                                            <q-item-section avatar top>
                                                <q-radio v-model="conclusionData.p5_2" :val="value" />
                                            </q-item-section>
                                            <q-item-section>
                                                <q-item-label>{{ value }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-list>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p6') }}
                                </q-item-label>
                                <q-list class="q-mt-md" dense>
                                    <q-item>
                                        <q-item-section avatar top>
                                            <q-radio v-model="conclusionData.p6" val="1" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('module-syexc.conslusion-data.p6.1') }}
                                            </q-item-label>
                                            <q-input v-if="'1'===conclusionData.p6"
                                                     v-model="conclusionData.p6_comment"
                                                     dense
                                            />
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section avatar top>
                                            <q-radio v-model="conclusionData.p6" val="2" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('module-syexc.conslusion-data.p6.2') }}
                                            </q-item-label>
                                            <q-input v-if="'2'===conclusionData.p6"
                                                     v-model="conclusionData.p6_comment"
                                                     dense
                                            />
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section avatar top>
                                            <q-radio v-model="conclusionData.p6" val="3" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('module-syexc.conslusion-data.p6.3', {comment: conclusionData.p6_comment}) }}
                                            </q-item-label>
                                            <q-input v-if="'3'===conclusionData.p6"
                                                     v-model="conclusionData.p6_comment"
                                                     dense
                                            />
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section avatar top>
                                            <q-radio v-model="conclusionData.p6" val="4" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('module-syexc.conslusion-data.p6.4') }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-item-section>
                        </q-item>
                        <q-input v-model="conclusionData.p7"
                                 :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p7')"
                                 type="textarea"
                                 rows="3"
                        />
                        <q-input v-model="conclusionData.p7_1"
                                 :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p7_1')"
                                 mask="date"
                        >
                            <template v-slot:append>
                                <q-icon name="event" class="cursor-pointer">
                                    <q-popup-proxy transition-show="scale" transition-hide="scale" cover>
                                        <q-date v-model="conclusionData.p7_1" :options="fnDates">
                                            <div class="row items-center justify-end">
                                                <q-btn :label="$translate('buttons.select')"
                                                       color="primary"
                                                       v-close-popup
                                                       flat
                                                />
                                            </div>
                                        </q-date>
                                    </q-popup-proxy>
                                </q-icon>
                            </template>
                        </q-input>
                        <q-input v-model.number="conclusionData.p8"
                                 :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.fields.p8')"
                                 type="number"
                                 prefix="на"
                                 suffix="л."
                                 min="0"
                        />
                    </template>
                    <template v-slot:actions>
                        <q-btn :label="$translate('module-syexc.proposal.card.conclusion_data_form.modal.actions.close')"
                               color="positive"
                               v-close-popup
                        />
                    </template>
                </Card>
            </q-dialog>
        </q-item-section>
    </q-item>
    <slot name="download-link"></slot>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, onMounted, reactive, ref} from "vue";
import _ from "lodash";
import moment from "moment";
import helpers from "@/helpers";

const props = defineProps({
    conclusion: Object,
    conclusionNumber: String,
    proposal: String,
    country: Array
});
const emit = defineEmits(['onConclusionDataFilled']);

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const conclusionDataDialog = ref(false);

let conclusionData = null;
const conclusionDataDefault = {
    p1: 'Федеральное государственное бюджетное образовательное учреждение высшего образования ' +
            '«Санкт-Петербургский государственный университет», 199034, г. Санкт-Петербург, ' +
            'Университетская набережная, д. 7/9, тел. +7(812)328-78-11.',
    p2: '',
    p2_1: '',
    p2_2: '',
    p2_3: 'Федеральное государственное бюджетное образовательное учреждение высшего образования ' +
            '«Санкт-Петербургский государственный университет», 199034, г. Санкт-Петербург, ' +
            'Университетская набережная, д. 7/9, тел. +7(812)328-78-11.',
    p2_4: '',
    p2_4_1: '',
    p2_4_2: '',
    objects: [],
    p5_1_a: '',
    p5_1_a_1: '',
    p5_1_a_2: '',
    p5_1_b: '',
    p5_1_b_1: '',
    p5_1_b_2: '',
    p5_2: '',
    p6: '',
    p6_comment: '',
    p7: '',
    p7_1: '',
    p8: '',
};

const minDate = ref('');
const maxDate = ref('');
const fnDates = (date) => {
    return date >= minDate.value && date <= maxDate.value;
};

const options = ref([]);

const addObject = () => {
    conclusionData.objects.push({
        name: '',
        code: '',
        description: '',
        character: '',
        list: [],
        result: '',
        p5_1_c: '',
        p5_1_c_1: '',
        p5_1_c_2: '',
    });
};
const deleteObject = (index) => {
    _.remove(conclusionData.objects, (o, i) => i === index);
};

const addObjectList = (index) => {
    if (!_.isArray(conclusionData.objects[index].list)) {
        conclusionData.objects[index].list = [];
    }
    conclusionData.objects[index].list.push({
        id: null,
        name: '',
        sections: [],
    });
};
const deleteObjectList = (index, listIndex) => {
    _.remove(conclusionData.objects[index].list, (o, i) => i === listIndex);
};

const addObjectListSection = (index, listIndex) => {
    conclusionData.objects[index].list[listIndex].sections.push({
        number: null,
        positions: [],
    });
};
const deleteObjectListSection = (index, listIndex, sectionIndex) => {
    _.remove(conclusionData.objects[index].list[listIndex].sections, (o, i) => i === sectionIndex);
};

const addObjectListSectionPosition = (index, listIndex, sectionIndex) => {
    conclusionData.objects[index].list[listIndex].sections[sectionIndex].positions.push({
        number: '',
        description: '',
    });
};
const deleteObjectListSectionPosition = (index, listIndex, sectionIndex, positionIndex) => {
    _.remove(
        conclusionData.objects[index].list[listIndex].sections[sectionIndex].positions,
        (o, i) => i === positionIndex
    );
};

const setObjectValue = (index, listIndex) => {
    _.set(
        conclusionData.objects[index].list[listIndex],
        'name',
        $translate('module-syexc.conslusion-data.p4_1.' + conclusionData.objects[index].list[listIndex].id)
    );
};

const sendFilledData = () => {
    emit('onConclusionDataFilled', conclusionData);
};

onMounted(() => {
    if (props.conclusion) {
        conclusionData = reactive(props.conclusion);
    } else {
        conclusionData = reactive(conclusionDataDefault);
        addObject();
    }
    minDate.value = moment().format('YYYY/MM/DD');
    maxDate.value = moment().add(2, 'y').format('YYYY/MM/DD');
    _.forEach($translate('module-syexc.conslusion-data.p4_1'), (item, i) => {
        options.value.push({value: i, label: `Указ №${i}`});
    });
});
</script>
