<template>
    <Card :title="$translate('module-notify.name')" title-bg="green-2" no-card-section header-toolbar>
        <q-tabs v-model="tabs"
                dense inline-label
                align="left" indicator-color="green-2"
                :breakpoint="0">
            <q-tab name="channels" icon="settings_voice" :label="$translate('module-notify.settings.channels')"/>
            <q-tab name="notifications" icon="tune" :label="$translate('module-notify.settings.notifications')"/>
        </q-tabs>

        <q-tab-panels v-model="tabs">
            <q-tab-panel name="channels">
                <q-list separator>

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox color="cyan"
                                        :toggle-indeterminate="false"
                                        name="NOTIFY_CHANNELS[]"
                                        v-model="form.settings.notify['NOTIFY_CHANNELS']"
                                        val="database"
                                        checked-icon="done" unchecked-icon="close"/>
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>{{ $translate('notifications.channels.database') }}</q-item-label>
                            <q-item-label caption>
                                {{ $translate('notifications.channels.database-description') }}
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox color="cyan"
                                        :toggle-indeterminate="false"
                                        name="NOTIFY_CHANNELS[]"
                                        v-model="form.settings.notify['NOTIFY_CHANNELS']"
                                        val="mail"
                                        checked-icon="done" unchecked-icon="close"/>
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>{{ $translate('notifications.channels.mail') }}</q-item-label>
                            <q-item-label caption>
                                {{ $translate('notifications.channels.mail-description', {'email': user.email}) }}
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item tag="label" v-ripple v-if="TELEGRAM_ENABLED">
                        <q-item-section side top>
                            <q-checkbox color="cyan"
                                        :disable="!TELEGRAM_ID"
                                        :toggle-indeterminate="false"
                                        name="NOTIFY_CHANNELS[]"
                                        v-model="form.settings.notify['NOTIFY_CHANNELS']"
                                        val="telegram"
                                        checked-icon="done" unchecked-icon="close"/>
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>{{ $translate('notifications.channels.telegram') }}</q-item-label>
                            <q-item-label caption>
                                {{
                                    $translate('notifications.channels.telegram-description', {'email': user.email})
                                }}
                            </q-item-label>
                            <q-item-label class="text-positive" v-if="TELEGRAM_ID"
                                          v-html="$translate('module-notify.channels.telegram.configured', {'BOTNAME': TELEGRAM_BOT_NAME})">
                            </q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
                <div style="margin-left: 70px" v-if="TELEGRAM_ENABLED && !TELEGRAM_ID">
                    <q-btn no-caps @click="TELEGRAM_SETTINGS = true" color="positive">
                        {{ $translate('module-notify.channels.telegram.configure.button') }}
                    </q-btn>
                    <q-dialog v-model="TELEGRAM_SETTINGS">
                        <Card :title="$translate('module-notify.channels.telegram.configure.title')"
                              title-text="white" title-bg="primary"
                              show-close-button>
                            <q-timeline color="secondary">
                                <q-timeline-entry subtitle="Шаг 1">
                                    <div v-html="$translate('module-notify.channels.telegram.configure.step1',{
                                                'BOT_NAME': TELEGRAM_BOT_NAME,
                                                'TELEGRAM_TOKEN': TELEGRAM_TOKEN,
                                            })"></div>
                                    <q-btn icon-right="fab fa-telegram-plane"
                                           :label="TELEGRAM_BOT_NAME"
                                           color="secondary"
                                           no-caps
                                           :href="'https://t.me/'+TELEGRAM_BOT_NAME+'?start='+TELEGRAM_TOKEN"
                                           target="_blank"/>
                                </q-timeline-entry>
                                <q-timeline-entry subtitle="Шаг 2">
                                    {{ $translate('module-notify.channels.telegram.configure.step2') }}
                                </q-timeline-entry>
                                <q-timeline-entry subtitle="Шаг 3">
                                    {{ $translate('module-notify.channels.telegram.configure.step3') }}
                                </q-timeline-entry>
                                <q-timeline-entry subtitle="Шаг 4">
                                    {{ $translate('module-notify.channels.telegram.configure.step4') }}
                                </q-timeline-entry>
                            </q-timeline>
                            <template v-slot:actions>
                                <q-btn icon="check"
                                       :label="$translate('buttons.confirm')"
                                       color="positive"
                                       v-close-popup
                                       @click="router.reload()"/>
                            </template>
                        </Card>
                    </q-dialog>
                </div>


            </q-tab-panel>
            <q-tab-panel name="notifications">
                <q-splitter v-model="splitterModel" unit="px">
                    <template v-slot:before>
                        <q-tabs v-model="notificationsTab" vertical dense no-caps>
                            <template v-for="(items,module) in notifications">
                                <q-tab :name="module" :label="$translate('module-'+module+'.name')"/>
                            </template>
                        </q-tabs>
                    </template>
                    <template v-slot:after>
                        <q-tab-panels v-model="notificationsTab">
                            <template v-for="(items,module) in notifications">
                                <q-tab-panel :name="module">
                                    <div class="q-pa-xs row q-gutter-md notification-items">
                                        <template v-for="(notification_items,group) in items">
                                            <q-card class="my-card">
                                                <q-bar class="bg-green-1">
                                                    <div>{{ group }}</div>
                                                </q-bar>
                                                <q-list>
                                                    <template v-for="(notify) in notification_items">
                                                        <q-item v-if="form.notifications.hasOwnProperty(notify.notify)">
                                                            <q-item-section>
                                                                <q-item-label>{{ notify.name }}</q-item-label>
                                                                <q-item-label caption>
                                                                    <div class="row">
                                                                        <template
                                                                                v-for="(channel) in form.settings.notify['NOTIFY_CHANNELS']">
                                                                            <q-toggle size="xs" color="green-4"
                                                                                      v-model="form.notifications[notify.notify][channel]"
                                                                                      :label="$translate('notifications.channels.'+channel)"></q-toggle>
                                                                        </template>
                                                                    </div>
                                                                </q-item-label>
                                                            </q-item-section>
                                                        </q-item>
                                                    </template>
                                                </q-list>
                                            </q-card>
                                        </template>
                                    </div>
                                </q-tab-panel>
                            </template>
                        </q-tab-panels>
                    </template>
                </q-splitter>
            </q-tab-panel>
        </q-tab-panels>
        <template v-slot:actions>
            <q-btn color="positive" @click="submitForm" :loading="form.processing"
                   icon="save" :label="$translate('buttons.save')">
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
        </template>
    </Card>

</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    notifications: {type: Object},
    notifications_current: {type: Object},
    NOTIFY_CHANNELS: {type: Array},
    TELEGRAM_ENABLED: {type: Boolean},
    TELEGRAM_ID: {type: String},
    TELEGRAM_BOT_NAME: {type: String},
    TELEGRAM_TOKEN: {type: String},
})
const tabs = ref('channels')
const notificationsTab = ref('rims')
const TELEGRAM_SETTINGS = ref(false)
const splitterModel = ref(180)

const user = computed(() => usePage().props.auth.user)

const notificationsKeys = computed(() => _.flatMapDeep(_.flatMapDeep(props.notifications, (item, key) => _.values(item)), (item) => item.notify));

const defaultNotificationsValues = computed(() => {
    let items = {};
    _.forEach(notificationsKeys.value, (key) => {
        _.set(items, key, {})
        _.forEach(props.NOTIFY_CHANNELS, (channel) => {
            _.set(items[key], channel, false)
        })
    })
    return items;
})
const form = useForm({
    settings: {
        notify: {
            NOTIFY_CHANNELS: props.NOTIFY_CHANNELS
        },
    },
    notifications: _.merge(defaultNotificationsValues.value, props.notifications_current)
})

const submitForm = () => {
    form.post(route('notify.settings-update'))
}
</script>

<style scoped>
.my-card {
    width: 100%;
    max-width: 435px;
}
</style>