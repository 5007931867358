<template>
    <q-list>
        <q-item class="bg-grey-2">
            <q-item-section side>
                <UserAvatar :avatar="user.avatar"/>
            </q-item-section>
            <q-item-section>
                <q-item-label class="text-bold ">
                    {{ user.fulled_name }}
                </q-item-label>
            </q-item-section>
        </q-item>

        <NavigationItem :title="serviceName"
                        :sidebar="sidebar"
                        color="indigo-5"
                        icon="o_person">
            <template #items>
                <q-item @click="router.visit($route('profile'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate('users.manage.main-data.title') }}
                    </q-item-section>
                </q-item>
                <q-item v-if="navigation.ssc"
                        @click="router.visit($route('profile.ssc.index'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate('Мои подразделения') }}
                    </q-item-section>
                </q-item>
                <q-item v-if="navigation.source"
                        @click="router.visit($route('profile.source.index'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate('users.manage.external-data.title') }}
                    </q-item-section>
                </q-item>

                <q-separator/>

                <q-item v-if="navigation.position"
                        @click="router.visit($route('profile.position.index'))"
                        clickable>
                    <q-item-section class="q-pl-md">
                        {{ $translate('Должности/График работы') }}
                    </q-item-section>
                </q-item>
                <q-item v-if="navigation.vacation"
                        @click="router.visit($route('profile.vacation.index'))"
                        clickable>
                    <q-item-section class="q-pl-md">
                        {{ $translate('Мои отпуска') }}
                    </q-item-section>
                </q-item>
                <q-item v-if="navigation.sicklist"
                        @click="router.visit($route('profile.sicklist.index'))"
                        clickable>
                    <q-item-section class="q-pl-md">
                        {{ $translate('Больничные листы') }}
                    </q-item-section>
                </q-item>
                <q-item v-if="navigation.dutyjourney"
                        @click="router.visit($route('profile.dutyjourney.index'))"
                        clickable>
                    <q-item-section class="q-pl-md">
                        {{ $translate('Мои командировки') }}
                    </q-item-section>
                </q-item>
                <q-separator/>
                <!--                <q-item-->
                <!--                        clickable>-->
                <!--                    <q-item-section class="q-pl-md">-->
                <!--                        {{ $translate('Повышение квалификации') }}-->
                <!--                    </q-item-section>-->
                <!--                </q-item>-->
                <q-item v-if="navigation.skill"
                        @click="router.visit($route('profile.skill.index'))"
                        clickable>
                    <q-item-section class="q-pl-md">
                        {{ $translate('Навыки/достижения') }}
                    </q-item-section>
                </q-item>
                <q-item v-if="navigation.competence"
                        @click="router.visit($route('profile.competence.index'))"
                        clickable>
                    <q-item-section class="q-pl-md">
                        {{ $translate('Мои компетенции') }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
    </q-list>
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";
import UserAvatar from "@/Components/UserAvatar.vue";

const props = defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const page = usePage();
const services = computed(() => page.props.services);
const serviceName = computed(() => String(page.props.serviceName));
const user = computed(() => page.props.auth.user)
const navigation = computed(() => page.props.auth.profile_menu)
</script>
