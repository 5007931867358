<template>
    <Alert v-if="$translate('module-analytics.description')"
           variant="info"
           :message="$translate('module-analytics.description')"
    />
    <q-tabs v-model="tab" align="left" dense no-caps>
        <q-tab name="base" label="Формирование и отправка отчетов"/>
    </q-tabs>
    <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="base">
            <SettingsTable :data="config.base"/>
        </q-tab-panel>
    </q-tab-panels>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import helper from "@/helpers";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const tab = ref('base');

const config = {
    base: [
        {
            name: 'ANALYTIC_REPORTING_REPORT',
            label: $translate('module-analytics.settings.ANALYTIC_REPORTING_REPORT'),
            value: helper.jsonParse(props.settings.module.ANALYTIC_REPORTING_REPORT),
            description: $translate('module-analytics.settings.ANALYTIC_REPORTING_REPORT_description'),
            block: 'module',
            type: 'checkbox-group',
            options: _.map($translate('module-analytics.reports'), (value, key) => ({
                label: value,
                value: key
            }))
        },
        {
            name: 'ANALYTIC_REPORTING_DAYS',
            label: $translate('module-analytics.settings.ANALYTIC_REPORTING_DAYS'),
            value: props.settings.module.ANALYTIC_REPORTING_DAYS,
            description: $translate('module-analytics.settings.ANALYTIC_REPORTING_DAYS_description'),
            block: 'module',
            type: 'text',
        },
        {
            name: 'ANALYTIC_REPORTING_PERIOD',
            label: $translate('module-analytics.settings.ANALYTIC_REPORTING_PERIOD'),
            value: props.settings.module.ANALYTIC_REPORTING_PERIOD,
            description: $translate('module-analytics.settings.ANALYTIC_REPORTING_PERIOD_description'),
            block: 'module',
            type: 'text',
        },
        {
            name: 'ANALYTIC_REPORTING_EMAIL',
            label: $translate('module-analytics.settings.ANALYTIC_REPORTING_EMAIL'),
            value: props.settings.module.ANALYTIC_REPORTING_EMAIL,
            description: $translate('module-analytics.settings.ANALYTIC_REPORTING_EMAIL_description'),
            block: 'module',
            type: 'textarea',
        },
    ]
};
</script>
