<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions no-card-section>

        <q-card-section v-if="_.get(fields,'fields.name.show')">
            <q-input v-model="publication.name"
                     :label="_.get(fields,'fields.name.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('name')"
                     :error-message="_.get(errors,'name')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.description.show')">
            <q-input v-model="publication.description"
                     :label="_.get(fields,'fields.description.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('description')"
                     :error-message="_.get(errors,'description')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.result.show')">
            <q-input v-model="publication.result"
                     :label="_.get(fields,'fields.result.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('result')"
                     :error-message="_.get(errors,'result')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.comment.show')">
            <q-input v-model="publication.comment"
                     :label="_.get(fields,'fields.comment.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('comment')"
                     :error-message="_.get(errors,'comment')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.date.show')">
            <q-field :label="_.get(fields,'fields.date.label')"
                     style="width: 100%; max-width: 300px"
                     stack-label
                     :error="errors.hasOwnProperty('date_start') || errors.hasOwnProperty('date_end')"
                     :error-message="_.get(errors,'date_start') + ' | '+ _.get(errors,'date_end')">
                <template v-slot:control>
                    <div class="full-width flex justify-around">
                        <span>
                            {{ $translate('buttons.from') }}
                            {{ _.get(publication, 'date_start', '-') }}
                        </span>
                        <span>
                            {{ $translate('buttons.to') }}
                            {{ _.get(publication, 'date_end', '-') }}
                        </span>
                    </div>
                </template>

                <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                            <q-date v-model="publication.date"
                                    @update:modelValue="setDate"
                                    today-btn
                                    minimal
                                    range
                                    first-day-of-week="1">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup
                                           :label="$translate('buttons.select')"
                                           color="primary"
                                           flat/>
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-field>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.recognition.show')">
            <q-select v-model="publication.recognition_id"
                      :label="_.get(fields,'fields.recognition.label')"
                      :options="optionsRecognition"
                      options-dense
                      emit-value
                      map-options
                      stack-label
                      :error="errors.hasOwnProperty('recognition_id')"
                      :error-message="_.get(errors,'recognition_id')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.indicator.show')">
            <q-select v-model="publication.indicators"
                      :label="_.get(fields,'fields.indicator.label')"
                      :options="optionsIndicator"
                      multiple use-chips
                      options-dense
                      emit-value
                      map-options
                      stack-label
                      :error="errors.hasOwnProperty('indicators')"
                      :error-message="_.get(errors,'indicators')"/>
        </q-card-section>

        <ObjectApplication :field="_.get(fields,'fields.object')"
                           v-model:object_type="publication.object_type"
                           v-model:object_id="publication.object_id"
                           :error="_.get(errors,'object_id')"/>

        <ObjectApplication :field="_.get(fields,'fields.target')"
                           v-model:object_type="publication.target_type"
                           v-model:object_id="publication.target_id"
                           :error="_.get(errors,'target_id')"/>

        <FieldsRendering :fields="_.get(fields,'fields_additional')"
                         v-model="publication.common_fields"
                         :errors="errors"
                         error-prefix="common_fields."/>

    </Card>
</template>

<script setup>
/**************************************************
 *
 *  Деятельность - основная информация
 *
 ***************************************************/

import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";
import {Card} from "@/Components/Block";
import ClassificationDB from "@/plugins/ClassificationDB";
import ObjectApplication from "@/Components/ReportMaterialForm/Block/ObjectApplication.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object}
})
const publication = defineModel('publication', {type: Object})
const errors = defineModel('errors', {type: Object})

const optionsRecognition = ref([])
const optionsIndicator = ref([])
const loadRecognition = () => {
    ClassificationDB
        .getSimpleList('eventstate')
        .then((r) => {
            optionsRecognition.value = r
        })
}
const loadIndicator = () => {
    ClassificationDB
        .getMultiList('activities_indicator')
        .then((r) => {
            optionsIndicator.value = r
        })
}
const setDate = (value) => {
    // console.log(value)
    _.set(publication.value, 'date_start', value.from || value)
    _.set(publication.value, 'date_end', value.to || value)
}

onMounted(() => {
    loadRecognition()
    loadIndicator()
})
</script>