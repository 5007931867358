<template>
    <Card title-bg="deep-purple-2"
          :title="$translate('module-sensors.sensor-types.card.title', {name: _.get(sensorType, 'name')})"
          header-toolbar
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('module-sensors.sensor-types.card.actions.back')"
                   @click="router.get($route('services.sensors.sensor-types'))"
                   icon="o_chevron_left"
                   color="deep-purple-5"
                   dense
            />
        </template>
        <div class="row q-gutter-x-sm justify-center">
            <div class="col">
                <q-field :label="$translate('module-sensors.sensor-types.card.fields.format')" stack-label borderless>
                    <template v-slot:control>
                        <div class="self-center full-width no-outline" tabindex="0">
                            {{ $translate(`module-sensors.formats.${_.get(sensorType, 'format')}.name`) }}
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('module-sensors.sensor-types.card.fields.connection')" stack-label borderless>
                    <template v-slot:control>
                        <div class="self-center full-width no-outline" tabindex="0">
                            {{ $translate(`module-sensors.connections.${_.get(sensorType, 'connection_data.id')}.name`) }}
                        </div>
                    </template>
                </q-field>
                <template v-for="(connectionField, key) in sensorTypeFields" :key="key">
                    <q-field v-if="'select' === connectionField.type"
                             :label="getFieldLabel(connectionField)"
                             stack-label
                             borderless
                    >
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">
                                <template v-for="(value, i) in _.castArray(_.get(sensorType, `connection_data.${connectionField.name}`))">
                                    <q-chip :label="getLabelFromOptions(value, connectionField.options)" />
                                </template>
                            </div>
                        </template>
                    </q-field>
                    <q-field v-else :label="getFieldLabel(connectionField)" stack-label borderless>
                        <template v-slot:control>
                            <div class="self-center full-width no-outline" tabindex="0">
                                {{ _.get(sensorType, `connection_data.${connectionField.name}`) }}
                            </div>
                        </template>
                    </q-field>
                </template>
            </div>
            <div class="col">
                <SensorRulesList :sensor-type-id="_.get(sensorType, 'id')"
                                 :sensor-rules="_.get(sensorType, 'sensor_rules')"
                                 :measurement-types="measurementTypes"
                />
                <q-btn :label="$translate('module-sensors.sensor-rules.list.actions.add')"
                       @click="addRuleDialog = !addRuleDialog"
                       color="secondary"
                       class="q-mt-sm"
                />
                <q-dialog v-model="addRuleDialog" no-backdrop-dismiss>
                    <Card :title="$translate('module-sensors.sensor-rules.form.title')" in-modal>
                        <template v-slot:default>
                            <SensorRuleForm v-model="form" :measurement-types="measurementTypes"/>
                        </template>
                        <template v-slot:actions>
                            <q-btn :label="$translate('buttons.save')" @click="submitForm" color="positive"/>
                            <q-btn :label="$translate('buttons.cancel')" v-close-popup/>
                        </template>
                    </Card>
                </q-dialog>
            </div>
        </div>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {Form as SensorRuleForm, List as SensorRulesList} from "@/Components/Sensors/SensorRule";
import {getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    sensorType: Object,
    sensorTypeFields: Object,
    measurementTypes: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    sensor_type_id: _.get(props.sensorType, 'id'),
    measurement_type_id: null,
    rule: null,
});

const addRuleDialog = ref(false);

const getFieldLabel = (field) => $translate(
    `module-sensors.connections.${_.get(props.sensorType, 'connection_data.id')}.fields.${field.name}`
);
const getLabelFromOptions = (value, options) => _.get(
    _.find(options, o => o.value === value),
    'label'
);

const submitForm = () => {
    form.post(route('services.sensors.sensor-rules.store'), {
        onSuccess: () => {
            form.reset();
            addRuleDialog.value = false;
        }
    });
};
</script>
