<template>
    <ConsumableSscForm :consumable="consumable"
                       :title="$translate('consumables.ssc.tab.edit')"
                       :units="units"
                       :can="can"
    ></ConsumableSscForm>
</template>

<script setup>
import ConsumableSscForm from "@/Components/Consumables/ConsumableSscForm.vue";
import {getCurrentInstance} from "vue";

const props = defineProps({
    consumable: {
        type: Object
    },
    units: {
        type: Array
    },
    can: {
        type: Object
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
