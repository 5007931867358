<template>
    <q-btn icon="help_outline"
           flat
           color="grey-8"
           class="self-stretch" :class="{'q-px-sm':$q.screen.lt.sm}">
        <q-tooltip class="text-body2">{{ $translate('common.help') }}</q-tooltip>
        <q-menu fit>
            <q-list separator>
                <q-item v-close-popup href="https://docs.researchims.ru" target="_blank">
                    <q-item-section side>
                        <q-icon name="o_quiz"/>
                    </q-item-section>
                    <q-item-section>
                        {{ $translate("common.manual_help") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="manual_link"
                        v-close-popup :href="'https://docs.researchims.ru'+manual_link" target="_blank">
                    <q-item-section side>
                        <q-icon name="o_help_center"/>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate("common.manual_menu") }}
                        </q-item-label>
                        <q-item-label caption v-if="manual_name">
                            {{ manual_name }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item href="https://docs.researchims.ru/common/faq/" v-close-popup target="_blank">
                    <q-item-section side>
                        <q-icon name="o_question_answer"/>
                    </q-item-section>
                    <q-item-section>
                        {{ $translate("common.manual_faq") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="user.user_id" clickable
                        v-close-popup @click="router.visit($route('changelog'))">
                    <q-item-section side>
                        <q-icon name="o_history"/>
                    </q-item-section>
                    <q-item-section >
                        {{ $translate("changelog.title") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="user" v-close-popup href="/apidoc/" target="_blank">
                    <q-item-section side>
                        <q-icon name="o_integration_instructions"/>
                    </q-item-section>
                    <q-item-section >
                        {{ $translate("common.api") }}
                    </q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>
</template>

<script setup>
import {computed} from 'vue'
import {router, usePage} from "@inertiajs/vue3";

const user = computed(() => usePage().props.auth.user)

const manual_link = computed(() => usePage().props.manual_link)
const manual_name = computed(() => usePage().props.manual_name)

</script>
<style scoped lang="css">
a:hover {
    text-decoration: none;
}
</style>