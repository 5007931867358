<template>
    <div v-if="props.data.module.sensors.enabled">
        <Alert v-if="$translate('module-sensors.description')"
               variant="info"
               :message="$translate('module-sensors.description')"
        />
        <div v-if="_.get(props.data.module,'sensors.needInstall')" class="q-mb-sm">
            <q-btn
                    :label="$translate('module-sensors.settings.SENSORS_INSTALL')"
                    icon="o_terminal"
                    color="negative"
                    @click="router.visit($route('services.sensors.install-module'))"
            />
            <span class="q-ml-md">{{ $translate('module-sensors.settings.SENSORS_INSTALL_description') }}</span>
        </div>
        <SettingsTable :data="data"/>
    </div>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";
import {router} from "@inertiajs/vue3";

const props = defineProps({
    settings: {
        type: Object,
        // Object or array defaults must be returned from a factory function.
        // The function receives the raw props received by the component as the argument.
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const data = [
    {
        name: 'SENSORS_ENABLED',
        label: $translate('module-sensors.settings.SENSORS_ENABLED'),
        value: props.settings.module.SENSORS_ENABLED,
        description: $translate('module-sensors.settings.SENSORS_ENABLED_description'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'SENSORS_DB_HOST',
        label: $translate('module-sensors.settings.SENSORS_DB_HOST'),
        value: props.settings.module.SENSORS_DB_HOST,
        description: $translate('module-sensors.settings.SENSORS_DB_HOST_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'SENSORS_DB_PORT',
        label: $translate('module-sensors.settings.SENSORS_DB_PORT'),
        value: props.settings.module.SENSORS_DB_PORT,
        description: $translate('module-sensors.settings.SENSORS_DB_PORT_description'),
        block: 'module',
        type: 'number',
    },
    {
        name: 'SENSORS_DB_DATABASE',
        label: $translate('module-sensors.settings.SENSORS_DB_DATABASE'),
        value: props.settings.module.SENSORS_DB_DATABASE,
        description: $translate('module-sensors.settings.SENSORS_DB_DATABASE_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'SENSORS_DB_USERNAME',
        label: $translate('module-sensors.settings.SENSORS_DB_USERNAME'),
        value: props.settings.module.SENSORS_DB_USERNAME,
        description: $translate('module-sensors.settings.SENSORS_DB_USERNAME_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'SENSORS_DB_PASSWORD',
        label: $translate('module-sensors.settings.SENSORS_DB_PASSWORD'),
        value: props.settings.module.SENSORS_DB_PASSWORD,
        description: $translate('module-sensors.settings.SENSORS_DB_PASSWORD_description'),
        block: 'module',
        type: 'password',
    },
    {
        name: 'SENSORS_ZABBIX_URL',
        label: $translate('module-sensors.settings.SENSORS_ZABBIX_URL'),
        value: props.settings.module.SENSORS_ZABBIX_URL,
        description: $translate('module-sensors.settings.SENSORS_ZABBIX_URL_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'SENSORS_ZABBIX_TOKEN',
        label: $translate('module-sensors.settings.SENSORS_ZABBIX_TOKEN'),
        value: props.settings.module.SENSORS_ZABBIX_TOKEN,
        description: $translate('module-sensors.settings.SENSORS_ZABBIX_TOKEN_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'SENSORS_TIMEOUT',
        label: $translate('module-sensors.settings.SENSORS_TIMEOUT'),
        value: props.settings.module.SENSORS_TIMEOUT ?? 15,
        description: $translate('module-sensors.settings.SENSORS_TIMEOUT_description'),
        block: 'module',
        type: 'number',
        min: 1,
        max: 60,
        mask: '#',
        rules: [val => val >= 1 && val <= 60 || 'Минимум минута и не более часа'],
        reverseFillMask: true
    }
];
</script>
