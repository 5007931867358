<template>
    <Card v-if="_.get(typeFields,'show')"
          :title="_.get(typeFields,'name')"
          hide-actions no-card-section>

        <q-list separator>
            <template v-for="(material) in publication.material">
                <q-item>
                    <q-item-section>
                        <q-item-label class="text-bold">{{ material.title }}</q-item-label>
                        <q-item-label v-if="material.description">{{ material.description }}</q-item-label>

                        <q-item-label v-if="material.date">
                            <span class="text-grey-5">Дата:</span>
                            {{ material.date }}
                        </q-item-label>
                        <q-item-label v-if="material.url">
                            <span class="text-grey-5">URL-адрес:</span>
                            {{ material.url }}
                        </q-item-label>
                        <q-item-label v-if="material.publisher">
                            <span class="text-grey-5">Имя средства информации/СМИ:</span>
                            {{ material.publisher.name }}
                        </q-item-label>
                        <q-item-label v-if="material.submission">
                            <span class="text-grey-5">Тип подачи информации:</span>
                            {{ material.submission.name }}
                        </q-item-label>
                        <q-item-label v-if="material.degree">
                            <span class="text-grey-5">Степень признания:</span>
                            {{ material.degree.name }}
                        </q-item-label>
                        <q-item-label v-if="material.country">
                            <span class="text-grey-5">Страна/Tерритория:</span>
                            {{ material.country.name }}
                        </q-item-label>
                        <q-item-label v-if="material.vendor">
                            <span class="text-grey-5">Производитель/автор:</span>
                            {{ material.vendor }}
                        </q-item-label>
                        <q-item-label v-if="material.length">
                            <span class="text-grey-5">Продолжительность/длина/размер: </span>
                            {{ material.length }}
                        </q-item-label>
                        <q-item-label v-if="material.members">
                            <span class="text-grey-5">Лица и учреждения:</span>
                            <Authors :items="material.members"/>
                        </q-item-label>
                    </q-item-section>
                    <q-item-section top side>
                        <q-item-label caption>{{ material.type_text }}</q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>

    </Card>
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import {Authors} from "@/Components/ReportMaterial/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    publication: {type: Object}
})

const typeFields = computed(() => {
    return props.publication.report_type.fields.material;
})
</script>