<template>
    <Card :title="$translate('project.form.title.members')"
          no-card-section>
        <q-list separator>
            <q-item>
                <q-item-section>
                    <q-item-label>
                        {{ leader.last_name }} {{ leader.first_name }} {{ leader.middle_name }}
                    </q-item-label>
                    <q-item-label caption>
                        <div>{{ _.get(leader, 'ext_login') }}</div>
                        <div>{{ _.get(_.find(leader.additional, {slug: 'position'}), 'value') }}</div>
                        <div>{{ _.get(_.find(leader.additional, {slug: 'title'}), 'value') }}</div>
                    </q-item-label>
                </q-item-section>
                <q-item-section>
                    <q-field :label="$translate('project.view.member-role')" stack-label>
                        <template v-slot:control>
                            <div class="self-center full-width">
                                {{ $translate('project.view.leader-info') }}
                            </div>
                        </template>
                    </q-field>
                </q-item-section>
                <q-item-section side>
                    <q-btn icon="o_delete"
                           flat disable/>
                </q-item-section>
            </q-item>
            <template v-for="(member,index) in project.members">
                <q-item>
                    <q-item-section>
                        <q-item-label>
                            {{ member.user.last_name }}
                            {{ member.user.first_name }}
                            {{ member.user.middle_name }}
                        </q-item-label>
                        <q-item-label caption>
                            <div>{{ _.get(member.user, 'ext_login') }}</div>
                            <div>{{ _.get(_.find(member.user.additional, {slug: 'position'}), 'value') }}</div>
                            <div>{{ _.get(_.find(member.user.additional, {slug: 'title'}), 'value') }}</div>
                        </q-item-label>
                        <q-item-label class="text-negative"
                                      v-if="project.errors.hasOwnProperty('members.'+index+'.uuid')">
                            {{ _.get(project.errors, 'members.' + index + '.uuid') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-select :label="$translate('project.view.member-role')"
                                  v-model="member.role_id"
                                  :options="projectRoles"
                                  map-options
                                  emit-value
                                  @update:modelValue="(role_id)=>member.role = _.find(projectRoles,{value:role_id})"
                                  :error="project.errors.hasOwnProperty('members.'+index+'.role_id')"
                                  :error-message="_.get(project.errors,'members.'+index+'.role_id')"
                        />
                    </q-item-section>
                    <q-item-section side>
                        <q-btn icon="o_delete"
                               color="negative"
                               flat
                               @click="deleteMember(index)"/>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
        <Alert variant="danger"
               v-if="project.errors.hasOwnProperty('members')">{{ _.get(project.errors, 'members') }}
        </Alert>
        <template v-slot:actions>
            <q-btn v-if="!needImport"
                   icon="add"
                   :label="$translate('buttons.add')"
                   @click="addMemberDialog = true"
                   color="positive"/>

            <q-btn v-if="needImport"
                   icon="add"
                   :label="$translate('Импорт участников из источника')"
                   @click="importMemberDialog = true"
                   color="positive"/>
        </template>
    </Card>
    <q-dialog v-model="addMemberDialog" no-backdrop-dismiss>
        <Card :title="$translate('project.form.title.add-member')"
              in-modal show-close-button header-toolbar>
            <SearchUser @onSelect="selectMember" with-ldap/>
            <template v-slot:actions>
                <q-btn icon="add"
                       :label="$translate('buttons.add')"
                       color="positive"
                       @click="addMember"/>
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="importMemberDialog" no-backdrop-dismiss v-if="_.get(project,'source') === 'pure'">
        <Card :title="$translate('Импорт участников из гранта')"
              in-modal show-close-button header-toolbar no-card-section>
            <component :is="memberBlock('Pure')" :award="project.sourceDetail" v-model="importedMembers"/>
            <template v-slot:actions>
                <q-btn icon="add"
                       :label="$translate('buttons.import-selected')"
                       color="positive"
                       @click="importMembers"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {
    getCurrentInstance,
    onBeforeMount, onMounted,
    ref, computed,
    watch,
} from "vue";
import _ from "lodash";
import {Alert, Card} from "@/Components/Block";
import {router, usePage} from "@inertiajs/vue3";
import {User as SearchUser} from "@/Components/Search";
import axios from "axios"
import * as ImportMember from "@/Components/Project/CreateProjectSteps/ImportMembers";

const memberBlock = (block) => ImportMember[block];

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const project = defineModel({type: Object})

const emit = defineEmits(['update:project'])

const settings = computed(() => usePage().props)

// const members = ref([])
const leader = ref({})

const addMemberDialog = ref(false)
const importMemberDialog = ref(false)

const projectMember = ref(null)

const needImport = computed(() => {
    return (_.includes(settings.value.ONLY_USERS_FROM_SOURCE, _.get(project.value, 'source')))
})
const selectMember = (member) => {
    projectMember.value = member.id;
}

const addMember = () => {
    axios.get(route('ajax.users', {m: 'full', id: projectMember.value}))
            .then((response) => {
                if (!_.find(project.value.members, {user_id: projectMember.value})) {
                    project.value.members.push({
                        user_id: _.get(response.data.results, 'user_id'),
                        user: response.data.results,
                        uuid: null,
                        role_id: null,
                        role: null
                    })
                }
            })
            .then(() => {
                // members.value = _.uniqBy(members.value, 'user_id')
            })
            .finally(() => {
                projectMember.value = null
                addMemberDialog.value = false
            })
}

const projectRoles = ref([])
const loadProjectRoles = () => {
    axios.get(route('ajax.project', {m: 'projectRoles'}))
            .then((response) => {
                projectRoles.value = _.map(response.data.results, (v, k) => ({
                    value: Number(k),
                    label: v
                }));
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
            });
}

const deleteMember = (index) => {
    project.value.members.splice(index, 1);
};
/**
 * импорт участников проекта из источника
 */
const importedMembers = ref({})
const importMembers = () => {
    _.forEach(importedMembers.value, (member) => {
        if (member.checked) {
            if (leader.value.user_id !== member.user.user_id
                    &&
                    _.findIndex(project.value.members, {user_id: member.user.user_id}) < 0
            ) {
                project.value.members.push({
                    user_id: member.user.user_id,
                    user: member.user,
                    uuid: member.uuid,
                    role_id: member.role_id,
                    role: _.find(projectRoles.value, {value: member.role_id})
                })
            }

        }
    })
    importMemberDialog.value = false;
}

onBeforeMount(() => {
    leader.value = usePage().props.auth.user
    project.value.leader = leader.value
    project.value.leader_id = leader.value.user_id
    loadProjectRoles()
})
</script>