<template>
    <q-avatar v-if="avatar"
              :size="size">
        <img :src="avatar"
             style="object-fit: cover"
             loading="lazy"
             alt="user avatar"
             @error="defaultAvatar"/>
    </q-avatar>
    <q-avatar v-else
              :size="size"
              icon="person"
              color="grey-5"
              text-color="white"
              circle>
    </q-avatar>
</template>

<script setup lang="ts">
interface Props {
    avatar: string | null,
    size?: string
}

withDefaults(defineProps<Props>(), {size: '45px'})

const defaultAvatar = (e: any) => {
    e.target.src = '/img/avatar.png';
}
</script>