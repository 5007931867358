<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          actions-align="left"
          no-card-section>
        <q-list separator dense>
            <q-item class="text-italic" v-if="_.size(stages)>0">
                <q-item-section>
                    <q-item-label>{{ $translate('Этап') }}</q-item-label>
                </q-item-section>
                <q-item-section>
                    <q-item-label>{{ $translate('Дата') }}</q-item-label>
                </q-item-section>
            </q-item>
            <template v-for="(stage, index) in stages">
                <q-item>
                    <q-item-section>
                        <q-select v-model="stages[index].type"
                                  :placeholder="$translate('Этап')"
                                  :options="options"
                                  options-dense
                                  map-options
                                  emit-value
                                  dense
                                  :error="errors.hasOwnProperty('stage.'+index+'.type')"
                                  :error-message="_.get(errors,'stage.'+index+'.type')"/>
                    </q-item-section>
                    <q-item-section>
                        <q-input v-model="stages[index].date"
                                 type="date"
                                 dense
                                 :error="errors.hasOwnProperty('stage.'+index+'.date')"
                                 :error-message="_.get(errors,'stage.'+index+'.date')"
                        />
                    </q-item-section>
                    <q-item-section side top>
                        <q-btn icon="o_delete"
                               color="negative"
                               size="sm"
                               class="q-mt-sm"
                               @click="deleteStage(index)"
                               flat dense
                        />
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
        <div class="text-negative q-px-sm" v-if="errors.hasOwnProperty('stage')">
            {{ _.get(errors, 'stage') }}
        </div>
        <template v-slot:actions>
            <q-btn icon="o_add"
                   :label="$translate('buttons.add')"
                   @click="addStage"
                   dense no-caps/>
        </template>
    </Card>
</template>

<script setup>
/**************************************************
 *
 *  Этапы публикаций
 *
 ***************************************************/
import {computed, getCurrentInstance} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const props = defineProps({
    fields: {type: Object},
})

const stages = defineModel('stages', {type: Array})
const errors = defineModel('errors', {type: Object})

const options = computed(() => _.map($translate('report-type.stage'), (v, k) => ({value: k, label: v})))

const addStage = () => {
    stages.value.push({
        type: null,
        date: null
    })
}

const deleteStage = (index) => {
    stages.value.splice(index, 1)
}
</script>