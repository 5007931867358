<template>
    <q-item>
        <q-item-section>
            <q-btn :label="$translate('отправить на согласование')"
                   icon-right="o_send"
                   dense
                   class="q-my-sm"
                   color="positive"
                   @click="dialog = true"/>

            <q-dialog v-model="dialog" no-backdrop-dismiss>
                <Card :title="$translate('Отправка отчетного материала на валидацию')"
                      in-modal show-close-button header-toolbar>
                    <Alert variant="info">
                        Выберите подразделения аффилированные с данным отчетным материалом
                    </Alert>
                    <q-list>
                        <template v-for="(sscresearches) in project.sscresearches">
                            <!--
                            Показываем только те подразделения
                            в которых есть выполнение работ
                            -->
                            <q-item v-if="sscresearches.has_work">
                                <q-item-section>
                                    <q-item-label>
                                        <q-toggle v-model="form.ssc"
                                                  :val="sscresearches.ssc.id"
                                                  :label="sscresearches.ssc.name"
                                                  size="sm"/>
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-else>
                                <q-item-section>
                                    <q-item-label>
                                        <q-toggle v-model="form.ssc"
                                                  :val="sscresearches.ssc.id"
                                                  :label="sscresearches.ssc.name"
                                                  size="sm"
                                                  disable/>
                                    </q-item-label>
                                    <q-item-label caption class="text-negative q-ml-xl">
                                        {{
                                            $translate('Выполнение работ в подразделении не проводилось')
                                        }}
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                    <template v-slot:actions>
                        <q-btn icon="o_save"
                               :label="$translate('buttons.to-approve')"
                               color="positive"
                               @click="submitForm"/>
                    </template>
                </Card>
            </q-dialog>
        </q-item-section>
    </q-item>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Alert, Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    project: {type: Object},
    publication_type: {type: String},
    publication_id: {type: Number}
})
const dialog = ref(false)

const form = useForm({
    ssc: []
})

const submitForm = () => {
    form.post(route('publication.to-approve', {
        category: props.publication_type,
        publication: props.publication_id,
        project: props.project.id
    }), {
        onSuccess: () => {
            dialog.value = false
        }
    })
}
</script>