<template>
    <q-btn @click="searchInfoDialog = true" color="grey-6" icon="o_help_outline" dense flat>
        <q-tooltip class="text-body2">Справка по поиску</q-tooltip>
    </q-btn>
    <q-dialog v-model="searchInfoDialog">
        <Card title="Справка по поиску" show-close-button header-toolbar hide-actions in-modal>
            <template v-slot:default>
                <p class="q-mb-none">Пробел <span class="search-info-operator">&nbsp;</span> обрабатывается как логическое <b>И</b>.</p>
                <span class="search-info-example">Термоциклер Eppendorf 5332</span> - найдет все проекты содержащие данные слова в любом порядке.
                <hr/>
                <p class="q-mb-none">Запросы заключённые в двойные кавычки <span class="search-info-operator">""</span> ищутся дословно (цитатой).</p>
                <span class="search-info-example">"Термоциклер Eppendorf 5332"</span> - найдет все проекты содержащие данные слова именно в этом порядке.
                <hr/>
                <p class="q-mb-none">Оператор <span class="search-info-operator">or</span> обрабатывается как логическое <b>ИЛИ</b>.</p>
                <span class="search-info-example">"Термоциклер Eppendorf 5332" or "Carl Zeiss Supra 40VP"</span> - найдёт все проекты содержащие либо фразу "Термоциклер Eppendorf 5332", либо фразу "Carl Zeiss Supra 40VP"
                <br/>
                <span class="search-info-example">Термоциклер or Supra</span> - найдёт все проекты содержащие либо слово Термоциклер, либо слово Supra.
                <hr/>
                <p class="q-mb-none">Оператор <span class="search-info-operator">-</span> перед словом или фразой в двойных кавычках обрабатывается как исключение (поиск всех записей не содержащих поисковый запрос).</p>
                <span class="search-info-example">"Термоциклер Eppendorf 5332" -"Carl Zeiss Supra 40VP"</span> - найдёт все проекты содержащие фразу "Термоциклер Eppendorf 5332" и не содержащие фразу "Carl Zeiss Supra 40VP".
                <br/>
                <span class="search-info-example">Термоциклер -Supra</span> - найдёт все проекты содержащие слово Термоциклер и не содержащие слово Supra.
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {Card} from "./Block";
import {getCurrentInstance, ref} from "vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const searchInfoDialog = ref(false);
</script>
