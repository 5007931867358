<template>
    <q-layout view="hHh lpR fFf" class="bg-grey-1">
        <q-toolbar class="bg-white text-grey-8 border-bottom q-py-xs">
            <!-- LOGO -->
            <q-avatar>
                <img :src="appLogo" :alt="appName"/>
            </q-avatar>
            <q-separator vertical inset class="q-ml-sm"/>
            <!-- APP NAME  -->
            <div class="q-ml-sm" v-if="$q.screen.gt.xs">
                <q-toolbar-title>{{ serviceName }}</q-toolbar-title>
                <div>{{ appName }}</div>
            </div>
            <q-space/>
            <div class="row justify-center print-hide">
                <q-btn :label="$translate('buttons.print')"
                       href="javascript:window.print()"
                       icon="o_print"
                       color="dark"
                />
            </div>
            <q-space/>
            <slot name="barcode">
                <div v-html="barcode" class="flex items-center q-my-auto"/>
            </slot>
        </q-toolbar>
        <q-page-container>
            <q-page class="q-py-sm">
                <slot/>
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script setup lang="ts">
import {computed, ComputedRef, getCurrentInstance} from "vue";
import {ComponentInternalInstance} from "@vue/runtime-core";
import {QVueGlobals} from "quasar/dist/types/globals";
import {useQuasar} from "quasar";
import {usePage} from "@inertiajs/vue3";
import {Page, PageProps} from "@inertiajs/core";

const app: ComponentInternalInstance | null = getCurrentInstance();
const $translate: Function = app!.appContext.config.globalProperties.$translate;

const $q: QVueGlobals = useQuasar();
const page: Page = usePage<PageProps>();

const appName: ComputedRef = computed(() => page.props.appName);
const appLogo: ComputedRef = computed(() => page.props.appLogo);
const serviceName: ComputedRef = computed(() => page.props.serviceName);
const barcode: ComputedRef = computed(() => page.props.barcode);
</script>

<style lang="scss">
@page {
    size: auto;   /* auto is the initial value */
    margin: 10mm 10mm 10mm 10mm;  /* this affects the margin in the printer settings */
}
</style>
