<template>
    <q-card-section>
        <div class="row q-gutter-md">
            <div class="col-24 col-md-9 col-xl-auto">
                <Card :title="$translate('export.select-fields')"
                      class="q-mb-md"
                      no-card-section header-toolbar hide-actions>
                    <template v-if="_.size(modelFields)>0" key="modelFields">
                        <q-bar class="bg-grey-1 justify-between">
                            {{ $translate('export.primary-fields') }}
                            <q-checkbox v-model="modelFieldsAll"
                                        @update:modelValue="toggleModelFieldsAll"
                                        :label="$translate('export.select-all')"
                                        dense
                                        size="xs"/>
                        </q-bar>
                        <Alert variant="danger" dense v-if="form.errors.hasOwnProperty('modelFields')">
                            {{ form.errors.modelFields }}
                        </Alert>
                        <q-card-section>
                            <q-option-group :options="modelFields"
                                            type="checkbox"
                                            v-model="form.modelFields"
                                            size="sm"
                                            dense/>
                        </q-card-section>
                    </template>
                    <template v-if="_.size(additionalFields)>0" key="additionalFields">
                        <q-bar class="bg-grey-1 justify-between">
                            {{ $translate('export.additional-fields') }}
                            <q-checkbox v-model="additionalFieldsAll"
                                        @update:modelValue="toggleAdditionalFieldsAll"
                                        :label="$translate('export.select-all')"
                                        dense
                                        size="xs"/>
                        </q-bar>
                        <q-card-section>
                            <q-option-group :options="additionalFields"
                                            type="checkbox"
                                            v-model="form.modelFields"
                                            size="sm"
                                            dense/>
                        </q-card-section>
                    </template>
                </Card>
                <Card :title="$translate('export.file-type')"
                      class="q-mb-md"
                      no-card-section header-toolbar hide-actions>
                    <q-card-section class="column">
                        <q-radio v-model="form.type" size="xs" val="xlsx"
                                 :label="$translate('export.file-xlsx')"/>
                        <q-radio v-model="form.type" size="xs" val="csv"
                                 :label="$translate('export.file-csv')"/>
                    </q-card-section>

                    <Alert variant="danger" v-if="form.errors.hasOwnProperty('type')">
                        {{ form.errors.type }}
                    </Alert>
                </Card>
            </div>
            <div class="col">
                <Card :title="$translate('export.search-conditions')"
                      class="q-mb-md"
                      no-card-section header-toolbar>
                    <q-item v-for="(searchObject, index) in form.search" :key="index">
                        <q-item-section>
                            <q-select v-model="form.search[index].value"
                                      :options="searchable" options-dense
                                      emit-value map-options dense
                                      :label="$translate('export.fields.field')" stack-label
                                      :error="form.errors.hasOwnProperty('search.'+index+'.value')"
                                      :error-message="_.get(form.errors,'search.'+index+'.value')"/>
                        </q-item-section>
                        <q-item-section>
                            <q-select v-model="form.search[index].operator"
                                      :options="operators" options-dense
                                      emit-value map-options dense
                                      :label="$translate('export.fields.operator')" stack-label
                                      :error="form.errors.hasOwnProperty('search.'+index+'.operator')"
                                      :error-message="_.get(form.errors,'search.'+index+'.operator')"/>
                        </q-item-section>
                        <q-item-section>
                            <q-input
                                    :disable="(searchObject.operator === 'empty' || searchObject.operator === 'not-empty')"
                                    dense
                                    v-model="form.search[index].needle"
                                    :label="$translate('export.fields.value')" stack-label
                                    :error="form.errors.hasOwnProperty('search.'+index+'.needle')"
                                    :error-message="_.get(form.errors,'search.'+index+'.needle')"
                            />
                        </q-item-section>
                        <q-item-section side>
                            <q-btn flat dense icon="delete" @click="deleteSearch(index)"/>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label caption>{{ $translate('export.search-hint') }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <template v-slot:actions>
                        <q-btn @click="addSearch()" icon="add" :label="$translate('export.add')" dense/>
                    </template>
                </Card>
            </div>
        </div>
    </q-card-section>
    <q-separator/>
    <q-card-actions align="center">
        <q-btn color="positive" icon="import_export" :label="$translate('buttons.export')" @click="formSubmit"/>
    </q-card-actions>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, ref, watch} from "vue";
import {useQuasar} from "quasar";
import {useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {Alert, Card} from "@/Components/Block/";

const app = getCurrentInstance()
const $q = useQuasar();
const $translate = app!.appContext.config.globalProperties.$translate

interface Field {
    value: any,
    label: string,
    searchable: boolean,
    required: boolean,
}

interface Props {
    modelFields: Field[],
    additionalFields?: Field[],
    route: string,
}

interface searchField {
    value: string | null,
    operator: string | null,
    needle: string | null,
}

const props = defineProps<Props>()

const searchable = computed(() => _.union(_.filter(props.modelFields, {searchable: true}), props.additionalFields))
const operators = computed(() => {
    return _.map($translate('export.operator'), (v, k) => ({value: k, label: v}));
})
const form = useForm({
    modelFields: [] as string[],
    search: [] as searchField[],
    type: null,
})
const modelFieldsAll = ref(false)
const additionalFieldsAll = ref(false)

const toggleModelFieldsAll = () => {
    _.forEach(props.modelFields, (field: Field) => {
        if (_.includes(form.modelFields, _.get(field, 'value')) && !modelFieldsAll.value) {
            _.remove(form.modelFields, _.get(field, 'value'))
        }
        if (!_.includes(form.modelFields, _.get(field, 'value')) && modelFieldsAll.value) {
            form.modelFields.push(_.get(field, 'value'))
        }
    })
}
const toggleAdditionalFieldsAll = () => {
    _.forEach(props.additionalFields, (field: Field) => {
        if (_.includes(form.modelFields, _.get(field, 'value')) && !additionalFieldsAll.value) {
            _.remove(form.modelFields, _.get(field, 'value'))
        }
        if (!_.includes(form.modelFields, field.value) && additionalFieldsAll.value) {
            form.modelFields.push(_.get(field, 'value'))
        }
    })
}
const addSearch = () => {
    form.search.push({
        value: null,
        operator: 'equals',
        needle: null,
    })
}
const deleteSearch = (index: number) => {
    form.search.splice(index, 1);
}

const formSubmit = () => {
    form.post(props.route, {})
}
</script>