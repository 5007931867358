<template>
    <Card :title="$translate('module-syexc.proposal.card.committee_form.title')"
          :hide-actions="!isSecretar"
          actions-align="left"
          class="q-mt-md"
    >
        <q-list separator>
            <q-item>
                <q-item-section>
                    <q-field borderless
                             :label="$translate('module-syexc.proposal.card.committee_form.fields.leader', {leader: _.get(committee, 'leader.data.fulled_name')})"
                             stack-label>
                        <template v-slot:control>
                            <div class="full-width flex column">
                                <template
                                        v-if="Number(authId) === Number(_.get(committee, 'leader.data.user_id')) && isForLeaderState">
                                    <template
                                            v-for="(label, value) in $translate('module-syexc.proposal.committed_status')"
                                            :key="value">
                                        <q-radio v-model="leaderForm.result" :val="value" size="xs">
                                            <span v-html="label"></span>
                                        </q-radio>
                                    </template>
                                    <div v-if="leaderForm.errors.hasOwnProperty('result')" class="text-negative">
                                        {{ leaderForm.errors.result }}
                                    </div>
                                    <q-input v-model="leaderForm.comment"
                                             :label="$translate('module-syexc.proposal.card.committee_form.fields.comment')"
                                             class="q-mb-sm"
                                             type="textarea"
                                             rows="2"
                                    />
                                    <q-btn :label="$translate('module-syexc.proposal.card.committee_form.actions.add_conclusion')"
                                           :loading="leaderForm.processing"
                                           @click="submitLeaderForm"
                                           color="positive"
                                    >
                                        <template v-slot:loading>
                                            <q-spinner-facebook/>
                                        </template>
                                    </q-btn>

                                </template>
                                <template v-else>
                                    <template v-if="_.get(committee, 'resultLeader')">
                                        <div v-html="_.get(committee, 'resultLeader.data.result_text')"></div>
                                        <div>
                                            {{
                                                formatDatetime(_.get(committee, 'resultLeader.data.updated_at'))
                                            }}
                                        </div>
                                        <div>{{ _.get(committee, 'resultLeader.data.comment') }}</div>
                                    </template>
                                    <template v-else>
                                        <div>
                                            {{
                                                $translate('module-syexc.proposal.card.committee_form.no-conclusion')
                                            }}
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </template>
                    </q-field>
                </q-item-section>
            </q-item>
            <template v-for="(member, index) in _.get(props.committee, 'members.data')" :key="index">
                <q-item>
                    <q-item-section>
                        <template v-if="Number(authId) === Number(_.get(member, 'user_id')) && isForMemberState">
                            <div class="q-field__label">
                                <small>
                                    {{
                                        $translate('module-syexc.proposal.card.committee_form.fields.member', {
                                            member: _.get(member, 'fulled_name')
                                        })
                                    }}
                                </small>
                            </div>
                            <template v-for="(label, value) in $translate('module-syexc.proposal.committed_status')"
                                      :key="value">
                                <q-radio v-model="memberForms[member.id].result" :val="Number(value)" size="sm">
                                    <span v-html="label"></span>
                                </q-radio>
                            </template>
                            <div v-if="memberForms[member.id].errors.hasOwnProperty('result')"
                                 class="text-negative">
                                {{ memberForms[member.id].errors.result }}
                            </div>
                            <q-input v-model="memberForms[member.id].comment"
                                     :label="$translate('module-syexc.proposal.card.committee_form.fields.comment')"
                                     class="q-mb-sm"
                                     type="textarea"
                                     rows="2"
                            />
                            <div class="col">
                                <q-btn :label="$translate('module-syexc.proposal.card.committee_form.actions.add_conclusion')"
                                       :loading="memberForms[member.id].processing"
                                       @click="submitMemberForm(member)"
                                       color="positive"
                                >
                                    <template v-slot:loading>
                                        <q-spinner-facebook/>
                                    </template>
                                </q-btn>
                            </div>
                        </template>
                        <template v-else>
                            <q-field :label="$translate('module-syexc.proposal.card.committee_form.fields.member', {
                                            member: _.get(member, 'fulled_name')
                                         })"
                                     stack-label borderless
                            >
                                <template v-slot:control>
                                    <div class="self-center full-width no-outline" tabindex="0">
                                        <template v-if="getResultMember(member)">
                                            <div v-html="_.get(getResultMember(member), 'result_text')"></div>
                                            <div>
                                                {{ formatDatetime(_.get(getResultMember(member), 'updated_at')) }}
                                            </div>
                                            <div>{{ _.get(getResultMember(member), 'comment') }}</div>
                                        </template>
                                        <template v-else>
                                            <div>{{
                                                    $translate('module-syexc.proposal.card.committee_form.no-conclusion')
                                                }}
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </q-field>
                        </template>
                    </q-item-section>
                </q-item>
            </template>
            <q-item>
                <q-item-section>
                    <template
                            v-if="Number(authId) === Number(_.get(committee, 'lawyer.data.user_id')) && isForLawyerState">
                        <template v-for="(label, value) in $translate('module-syexc.proposal.lawyer_status')"
                                  :key="value">
                            <q-radio v-model="lawyerForm.result" :val="value">
                                <span v-html="label"></span>
                            </q-radio>
                        </template>
                        <div v-if="lawyerForm.errors.hasOwnProperty('result')" class="text-negative">
                            {{ lawyerForm.errors.result }}
                        </div>
                        <q-input v-model="lawyerForm.comment"
                                 :label="$translate('module-syexc.proposal.card.committee_form.fields.comment')"
                                 class="q-mb-sm"
                                 type="textarea"
                                 rows="2"
                        />
                        <div class="col">
                            <q-btn :label="$translate('module-syexc.proposal.card.committee_form.actions.add_conclusion')"
                                   :loading="lawyerForm.processing"
                                   @click="submitLawyerForm"
                                   color="positive"
                            >
                                <template v-slot:loading>
                                    <q-spinner-facebook/>
                                </template>
                            </q-btn>
                        </div>
                    </template>
                    <template v-else>
                        <q-field :label="$translate('module-syexc.proposal.card.committee_form.fields.lawyer', {
                                        lawyer: _.get(committee, 'lawyer.data.fulled_name')
                                     })"
                                 stack-label borderless
                        >
                            <template v-slot:control>
                                <div class="self-center full-width no-outline" tabindex="0">
                                    <template v-if="_.get(committee, 'resultLawyer')">
                                        <div v-html="_.get(committee, 'resultLawyer.data.result_text')"></div>
                                        <div>
                                            {{ formatDatetime(_.get(committee, 'resultLawyer.data.updated_at')) }}
                                        </div>
                                        <div>{{ _.get(committee, 'resultLawyer.data.comment') }}</div>
                                    </template>
                                    <template v-else>
                                        <div>{{
                                                $translate('module-syexc.proposal.card.committee_form.no-conclusion')
                                            }}
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </q-field>
                    </template>
                </q-item-section>
            </q-item>
            <q-item v-if="_.get(committee,'lawyerIO')">
                <q-item-section>
                    <template
                            v-if="Number(authId) === Number(_.get(committee, 'lawyerIO.data.user_id')) && isForLawyerState">
                        <template v-for="(label, value) in $translate('module-syexc.proposal.lawyer_status')"
                                  :key="value">
                            <q-radio v-model="lawyerIOForm.result" :val="value">
                                <span v-html="label"></span>
                            </q-radio>
                        </template>
                        <div v-if="lawyerIOForm.errors.hasOwnProperty('result')" class="text-negative">
                            {{ lawyerIOForm.errors.result }}
                        </div>
                        <q-input v-model="lawyerIOForm.comment"
                                 :label="$translate('module-syexc.proposal.card.committee_form.fields.comment')"
                                 class="q-mb-sm"
                                 type="textarea"
                                 rows="2"
                        />
                        <div class="col">
                            <q-btn :label="$translate('module-syexc.proposal.card.committee_form.actions.add_conclusion')"
                                   :loading="lawyerIOForm.processing"
                                   @click="submitLawyerIOForm"
                                   color="positive"
                            >
                                <template v-slot:loading>
                                    <q-spinner-facebook/>
                                </template>
                            </q-btn>
                        </div>
                    </template>
                    <template v-else>
                        <q-field :label="$translate('module-syexc.proposal.card.committee_form.fields.lawyer_io', {
                                        lawyer_io: _.get(committee, 'lawyerIO.data.fulled_name')
                                     })"
                                 stack-label
                        >
                            <template v-slot:control>
                                <div class="self-center full-width no-outline" tabindex="0">
                                    <template v-if="_.get(committee, 'resultLawyerIO')">
                                        <div v-html="_.get(committee, 'resultLawyerIO.data.result_text')"></div>
                                        <div>
                                            {{ formatDatetime(_.get(committee, 'resultLawyerIO.data.updated_at')) }}
                                        </div>
                                        <div>{{ _.get(committee, 'resultLawyerIO.data.comment') }}</div>
                                    </template>
                                    <template v-else>
                                        <div>{{
                                                $translate('module-syexc.proposal.card.committee_form.no-conclusion')
                                            }}
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </q-field>
                    </template>
                </q-item-section>
            </q-item>
        </q-list>

        <template v-slot:actions v-if="isSecretar">
            <template
                    v-if="isForMemberState && _.size(_.get(committee, 'resultMembers.data')) === _.size(_.get(committee, 'members.data'))">
                <q-btn :label="$translate('module-syexc.proposal.card.committee_form.actions.send_to_leader_agreement')"
                       @click="updateStatus(36)"
                       color="positive"
                />
            </template>
            <template v-if="isForLeaderState && _.get(committee, 'resultLeader')">
                <q-btn :label="$translate('module-syexc.proposal.card.committee_form.actions.send_to_lawyer_agreement')"
                       @click="updateStatus(37)"
                       color="positive"
                />
            </template>
            <q-btn :label="$translate('module-syexc.proposal.card.committee_form.actions.send_to_expert_revision')"
                   @click="updateStatus(25)"
                   color="primary"
            />
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {computed, getCurrentInstance} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment";

const props = defineProps({
    proposal: {
        type: Object,
        required: true
    },
    committee: {
        type: Object,
        required: true
    },
    isSecretar: {
        type: Boolean,
        required: true
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const authId = computed(() => usePage().props.auth.user.user_id);

const isForLeaderState = computed(() => 36 === Number(_.get(props.proposal, 'status')));
const isForMemberState = computed(() => 35 === Number(_.get(props.proposal, 'status')));
const isForLawyerState = computed(() => 37 === Number(_.get(props.proposal, 'status')));


const leaderForm = useForm({
    committee_id: _.get(props.committee, 'leader.data.id'),
    result: _.get(props.committee, 'resultLeader') ?
            _.get(props.committee, 'resultLeader.data.result') :
            '',
    comment: _.get(props.committee, 'resultLeader.data') ?
            _.get(props.committee, 'resultLeader.data.comment') :
            '',
});
const memberForms = {};
const getResultMember = (member) => _.find(
        _.get(props.committee, 'resultMembers.data'),
        o => o.committee_id === member.id
);
_.forEach(_.get(props.committee, 'members.data'), (member) => {
    let memberResult = getResultMember(member);
    memberForms[member.id] = useForm({
        committee_id: member.id,
        result: memberResult ? _.get(memberResult, 'result') : '',
        comment: memberResult ? _.get(memberResult, 'comment') : '',
    });
});
const lawyerForm = useForm({
    committee_id: _.get(props.committee, 'lawyer.data.id'),
    result: _.get(props.committee, 'resultLawyer') ?
            _.get(props.committee, 'resultLawyer.data.result') :
            '',
    comment: _.get(props.committee, 'resultLawyer') ?
            _.get(props.committee, 'resultLawyer.data.comment') :
            '',
});
const lawyerIOForm = useForm({
    committee_id: _.get(props.committee, 'lawyerIO.id'),
    result: _.get(props.committee, 'resultLawyerIO') ?
            _.get(props.committee, 'resultLawyerIO.data.result') :
            '',
    comment: _.get(props.committee, 'resultLawyerIO') ?
            _.get(props.committee, 'resultLawyerIO.data.comment') :
            '',
});

const submitLeaderForm = () => {
    leaderForm.post(route('services.sys-exp-ctrl.proposal.committee', {
        proposal: _.get(props.proposal, 'number')
    }));
};
const submitMemberForm = (member) => {
    memberForms[member.id].post(route('services.sys-exp-ctrl.proposal.committee', {
        proposal: _.get(props.proposal, 'number')
    }));
};
const submitLawyerForm = () => {
    lawyerForm.post(route('services.sys-exp-ctrl.proposal.committee', {
        proposal: _.get(props.proposal, 'number')
    }));
};
const submitLawyerIOForm = () => {
    lawyerIOForm.post(route('services.sys-exp-ctrl.proposal.committee', {
        proposal: _.get(props.proposal, 'number')
    }));
};

const updateStatus = (status) => {
    router.post(route('services.sys-exp-ctrl.proposal.update-status', {
        proposal: _.get(props.proposal, 'number'),
        status: status
    }));
};

const formatDatetime = (datetime) => moment(datetime).format($translate('common.datetime.js'));
</script>
