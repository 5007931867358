<template>
    <Card :title="$translate('report.type.title.list')"
          header-toolbar no-card-section hide-actions>
        <template v-slot:headerActions>
            <q-btn icon="add"
                   :label="$translate('buttons.add')"
                   dense no-caps
                   @click="openAddDialog"/>
        </template>
        <q-tabs align="left" v-model="tab">
            <template v-for="(category) in categorys">
                <q-tab :name="category"
                       :label="$translate('report-type.type.'+category)"/>
            </template>
        </q-tabs>

        <q-tab-panels v-model="tab">
            <template v-for="(category) in categorys">
                <q-tab-panel :name="category">
                    <CategoryList :category="category"/>
                </q-tab-panel>
            </template>
        </q-tab-panels>
    </Card>
    <q-dialog v-model="addDialog" no-backdrop-dismiss full-width full-height>
        <Card :title="$translate('report-type.title.add')"
              no-card-section show-close-button header-toolbar>
            <q-card-section>
                <q-select v-model="form.category"
                          :label="$translate('report-type.fields.category')"
                          :options="categoryOptions"
                          emit-value
                          map-options
                />
            </q-card-section>
            <CategoryForm v-model="form"/>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.add')"
                       color="positive"
                       @click="submitForm"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {Card} from "@/Components/Block";
import CategoryList from "@/Components/ReportMaterialType/CategoryList.vue";
import CategoryForm from "@/Components/ReportMaterialType/CategoryForm.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    categorys: {type: Array}
})

const tab = ref(_.first(props.categorys))

const addDialog = ref(false)

const form = useForm({
    category: null,
    name_i18n: {},
    description_i18n: {},
    fields: {}
})

const categoryOptions = computed(() => {
    return _.map(props.categorys, (category) => ({
        value: category,
        label: $translate('report-type.type.' + category)
    }))
})

const openAddDialog = () => {
    form.reset()
    addDialog.value = true;
}
const submitForm = () => {
    let old_tab = form.category
    form.post(route('admin.report-type.store', [form.category]), {
        onSuccess: () => {
            form.reset()
            addDialog.value = false
            tab.value = 'test'
            router.reload()
        },
        onFinish: () => {
            tab.value = old_tab
        }
    })
}
</script>