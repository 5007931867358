<template>
    <q-list v-if="!_.isEmpty(transform)">
        <template v-for="(group, sscName) in transform" :key="sscName">
            <q-item>
                <q-item-section>
                    <q-item-label>{{ sscName }}</q-item-label>
                    <q-slide-transition>
                        <div v-show="expanded[sscName]">
                            <q-list>
                                <template v-for="(item, i) in group" :key="i">
                                    <q-field :label="item.name" stack-label>
                                        <template v-slot:control>
                                            <div class="self-center full-width no-outline" tabindex="0">
                                                {{ item.from_value }} {{ _.get(item, 'from_unit_name') }}
                                                &rarr;
                                                {{ item.to_value }} {{ _.get(item, 'to_unit_name') }}
                                                <span v-if="item.loss > 0"> +{{ item.loss }}%</span>
                                            </div>
                                        </template>
                                    </q-field>
                                </template>
                            </q-list>
                        </div>
                    </q-slide-transition>
                </q-item-section>
                <q-item-section side top>
                    <q-btn :icon="expanded[sscName] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                           :title="$translate('buttons.toggle-list')"
                           @click="expanded[sscName] = !expanded[sscName]"
                           size="sm"
                           flat
                    />
                </q-item-section>
            </q-item>
        </template>
    </q-list>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import axios from "axios";

const props = defineProps({
    consumableId: Number
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const transform = ref({});
const expanded = ref([]);

onMounted(() => {
    axios.get(route('ajax.consumables', {
        m: 'transform',
        consumable_id: props.consumableId
    })).then(response => {
        transform.value = response.data.results;
        _.forEach(transform.value, (group, sscName) => {
            expanded.value[sscName] = 3 >= _.size(group);
        });
    });
});
</script>
