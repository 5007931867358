<template>
    <Card hide-actions no-card-section hide-title class="q-mb-md page-break">
        <q-card-section>
            <div>Проект №<strong>{{ research.project.number }}</strong></div>
            <div class="text-italic">&laquo;{{ research.project.name }}&raquo;</div>
        </q-card-section>
        <q-card-section>
            <div>Заявка №<strong>{{ research.number }}</strong></div>
            <div class="text-italic">{{ research.template.name }}</div>
        </q-card-section>
        <q-separator/>
        <q-card-section>
            <div class="row q-gutter-x-md">
                <q-field :label="$translate('research.show.number')" tag="div" dense
                         stack-label                          prefix="#" class="col-10">
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ research.number }}
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('research.show.status')" tag="div" dense
                         stack-label
                         class="col">
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ research.full_state_text }}
                        </div>
                    </template>
                </q-field>
            </div>
            <div class="row q-gutter-x-md">
                <q-field :label="$translate('research.show.created')" tag="div" dense
                         stack-label
                         class="col-10">
                    <template v-slot:control>
                        <div class="self-center full-width flex items-center">
                            <q-icon name="o_calendar_month" size="xs"/>
                            <span class="q-ml-xs">
                            {{ moment(research.created_at).format($translate('common.datetime.js')) }}
                        </span>
                        </div>
                    </template>
                </q-field>
                <q-field v-if="research.template.assay" tag="div"
                         :label="$translate('research.show.assay')"
                         stack-label  dense
                         class="col">

                    <template v-slot:control>
                        <div class="self-center full-width flex items-center">
                            <q-icon name="o_science" size="xs"/>
                            <div class="q-ml-xs">
                                {{ research.assay_received }}
                                {{ $translate('buttons.in') }}
                                {{ research.assay }}
                            </div>
                        </div>
                    </template>
                </q-field>
            </div>
            <div class="row q-gutter-x-md">
                <q-field v-if="research.responsible_assign" tag="div"
                         :label="$translate('research.show.assigned')"
                         stack-label  dense
                         class="col-10">
                    <template v-slot:control>
                        <div class="self-center full-width flex items-center">
                            <q-icon name="o_calendar_month" size="xs"/>
                            <div class="q-ml-xs">
                                {{ moment(research.responsible_assign).format($translate('common.date.js')) }}
                            </div>
                        </div>
                    </template>
                </q-field>
                <q-field v-if="research.end_date_plan"  tag="div"
                         :label="$translate('research.show.date_plan')"
                         stack-label
                           dense
                         class="col">
                    <template v-slot:control>
                        <div class="self-center full-width flex items-center">
                            <q-icon name="o_calendar_month" size="xs"/>
                            <div class="q-ml-xs">
                                {{ moment(research.end_date_plan).format($translate('common.date.js')) }}
                            </div>
                        </div>
                    </template>
                </q-field>
                <q-field v-if="research.end_date_real"  tag="div"
                         :label="$translate('research.show.date_real')"
                         stack-label
                           dense
                         class="col">
                    <template v-slot:control>
                        <div class="self-center full-width flex items-center">
                            <q-icon name="o_calendar_month" size="xs"/>
                            <div class="q-ml-xs">
                                {{ moment(research.end_date_real).format($translate('common.date.js')) }}
                            </div>
                        </div>
                    </template>
                </q-field>
            </div>
            <q-field :label="$translate('research.show.author')"  tag="div"
                     stack-label borderless dense>
                <template v-slot:control>
                    <div class="self-center full-width">
                        <UserWithDetail :user="research.author" printable/>
                    </div>
                </template>
            </q-field>
        </q-card-section>
        <q-separator/>
        <q-card-section>
            <div class="row q-gutter-x-md">
                <q-field :label="$translate('research.show.project-number')"  tag="div"
                         stack-label  dense
                         prefix="#" class="col-10">
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ research.project.number }}
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('research.show.project-status')"  tag="div"
                         stack-label  dense
                         class="col">
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ $translate('project.status.' + research.project.status.status) }}
                        </div>
                    </template>
                </q-field>
            </div>
            <q-field :label="$translate('research.show.leader')"  tag="div"
                     stack-label borderless dense>
                <template v-slot:control>
                    <div class="self-center full-width flex items-center">
                        <UserWithDetail :user="research.project.leader"  printable/>
                    </div>
                </template>
            </q-field>
        </q-card-section>
        <q-separator/>
        <q-card-section>
            <q-field :label="$translate('research.show.ssc')"  tag="div"
                     stack-label  dense>
                <template v-slot:control>
                    <div class="self-center full-width flex items-center">
                        <q-icon name="o_business" size="xs"/>
                        <span class="q-ml-xs">{{ research.ssc.name }}</span>
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('research.show.ssc-leader')"
                     tag="div"
                     stack-label borderless
                      dense>
                <template v-slot:control>
                    <div class="self-center full-width flex items-center">
                        <UserWithDetail v-if="research.ssc.director" printable
                                        :user="research.ssc.director"/>
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('research.show.responsible')"
                     tag="div"
                     stack-label borderless
                      dense>
                <template v-slot:control>
                    <div class="self-center full-width items-start">
                        <UserWithDetail v-if="research.responsible" printable
                                        :user="research.responsible"/>
                    </div>
                </template>
            </q-field>
        </q-card-section>
    </Card>
    <Card :title="$translate('research.show.detail')" hide-actions class="page-break">
        <FieldsValues :fields="research.template.fields" :values="research.fields" dense/>
    </Card>
</template>

<script setup>
import Print from "@/Layout/Print.vue";
import _ from "lodash";
import moment from "moment";
import {getCurrentInstance} from "vue";
import Card from "@/Components/Block/Card.vue";
import UserWithDetail from "@/Components/User/UserWithDetail.vue";
import FieldsValues from "@/Components/Fields/FieldsValues.vue";

defineOptions({layout: Print});

const props = defineProps({
    research: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

</script>