<template>
    <q-field :label="$translate('cadre-vacation.fields.cadre_staff')"
             tag="div"
             stack-label>
        <template v-slot:control>
            <div class="items-center full-width">
                {{ position }}
            </div>
        </template>
    </q-field>
    <q-select v-model="form.vacation.type"
              :label="$translate('cadre-vacation.fields.type')"
              stack-label
              :options="typeOptions()"
              emit-value map-options/>

    <q-field :label="$translate('cadre-vacation.fields.period')"
             stack-label
             :error="form.errors.hasOwnProperty('date_start') || form.errors.hasOwnProperty('date_end')"
             :error-message="_.get(form.errors,'date_start') || _.get(form.errors,'date_end')">
        <template v-slot:control>
            <div class="items-center full-width flex q-gutter-x-xs"
                 v-if="form.vacation.date_start!=null && form.vacation.date_end!=null">
                <span>{{ $translate('buttons.from') }}</span>
                <span>{{ moment(form.vacation.date_start).format($translate('common.date.js')) }}</span>
                <span>{{ $translate('buttons.to') }}</span>
                <span>{{ moment(form.vacation.date_end).format($translate('common.date.js')) }}</span>
                <span>({{ $translate('cadre-vacation.fields.days') }} {{ form.vacation.vacation_days }})</span>
            </div>
            <q-popup-proxy>
                <q-date v-model="datePeriod"
                        :options="dateOptions"
                        @update:modelValue="datePeriodUpdate"
                        range minimal
                        :bordered="false">
                    <div class="row items-center justify-end">
                        <q-btn :label="$translate('buttons.select')"
                               color="dark"
                               v-close-popup flat/>
                    </div>
                </q-date>
            </q-popup-proxy>
        </template>
    </q-field>
    <q-input v-model="form.comment"
             :label="$translate('cadre-vacation.fields.comment')"
             stack-label
             type="textarea"
             rows="3"/>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import {CadreVacation} from "@/entity/";
import moment from "moment";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

interface Model {
    action?: string | null,
    vacation: CadreVacation,
    comment?: string | null,
    errors: object
}

const form = defineModel<Model>({
    required: true
});

interface Props {
    position: string
}
defineProps<Props>()

const typeOptions = () => _.map($translate('cadre-vacation.type'), (v, k) => ({label: v, value: k}))

const dateOptions = (date: string) => {
    return moment(date).startOf('day').isSameOrAfter(moment().startOf('day').add(1, 'day'))
}

const datePeriod = ref(null)

const datePeriodUpdate = (date: string) => {
    let _dateStart;
    let _dateEnd;
    if (date) {
        if (_.isObject(date)) {
            _dateStart = moment(_.get(date, 'from'))
            _dateEnd = moment(_.get(date, 'to'))
        } else {
            _dateStart = moment(date)
            _dateEnd = moment(date)
        }
        _.set(form.value.vacation, 'date_start', _dateStart.format('YYYY-MM-DD'))
        _.set(form.value.vacation, 'date_end', _dateEnd.format('YYYY-MM-DD'))
        _.set(form.value.vacation, 'vacation_days', _dateEnd.diff(_dateStart, 'days') + 1)
    } else {
        _.set(form.value.vacation, 'date_start', null)
        _.set(form.value.vacation, 'date_end', null)
        _.set(form.value.vacation, 'vacation_days', 0)
    }
}

</script>