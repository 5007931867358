<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          actions-align="left"
          no-card-section>

        <q-list separator dense>
            <template v-for="(item,index) in materials">
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ $translate('report-type.material-type.' + item.type) }}</q-item-label>
                        <q-item-label>{{ item.title }}</q-item-label>
                        <q-item-label caption>{{ item.description }}</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <div>
                            <q-btn icon="o_edit"
                                   @click="editMaterial(index)"
                                   dense flat/>
                            <q-btn icon="o_delete"
                                   color="negative"
                                   @click="deleteMaterial(index)"
                                   dense
                                   flat/>
                        </div>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
        <div class="text-negative q-pa-sm"
             v-if="errors.hasOwnProperty('material')">
            {{ _.get(errors, 'material') }}
        </div>
        <template v-slot:actions>
            <q-btn icon="o_add"
                   :label="$translate('buttons.add')"
                   @click="newMaterial"
                   dense no-caps
            />
        </template>
    </Card>
    <q-dialog v-model="materialDialog" no-backdrop-dismiss>
        <Card :title="$translate('Материал')" header-toolbar in-modal show-close-button>
            <q-select v-model="materialForm.type"
                      :label="$translate('Тип материала')"
                      :options="optionsMaterialType"
                      options-dense
                      map-options
                      emit-value
                      stack-label/>

            <Card :title="$translate('Описание')" class="q-my-md" hide-actions>

                <q-input v-model="materialForm.title"
                         :label="$translate('Заголовок/Наименование материала')"
                         stack-label/>

                <q-input v-model="materialForm.description"
                         :label="$translate('Описание/выдержка')"
                         stack-label
                         type="textarea"
                         rows="3"/>
            </Card>

            <Authors :fields="authorsParam"
                     v-model="materialForm.members"
                     hide-col hide-person-role/>

            <Card :title="$translate('Информация')" class="q-mt-md" hide-actions>

                <SearchPublisher :label="$translate('Наименование СМИ')"
                                 @onSelect="setPublisher"/>

                <q-input v-model="materialForm.date"
                         :label="$translate('Дата')"
                         style="max-width: 300px"
                         type="date"
                         stack-label/>

                <q-input v-model="materialForm.url"
                         :label="$translate('URL-адрес')"
                         stack-label/>

                <q-select v-model="materialForm.submission_id"
                          :label="$translate('Тип подачи информации')"
                          :options="optionsMediaType"
                          options-dense
                          map-options
                          emit-value
                          stack-label/>

                <q-select v-model="materialForm.degree_id"
                          :label="$translate('Степень признания')"
                          :options="optionsAwardsState"
                          options-dense
                          map-options
                          emit-value
                          stack-label/>

                <q-select v-model="materialForm.country_id"
                          :label="$translate('Страна/Tерритория')"
                          :options="optionsCountry"
                          options-dense
                          map-options
                          emit-value
                          stack-label/>

                <q-input v-model="materialForm.vendor"
                         :label="$translate('Производитель/автор')"
                         stack-label/>

                <q-input v-model="materialForm.length"
                         :label="$translate('Продолжительность/длина/размер')"
                         stack-label/>
            </Card>

            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="saveMaterial"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {Card} from "@/Components/Block";
import {Authors} from "@/Components/ReportMaterialForm/Block";
import SearchPublisher from "@/Components/Search/SearchPublisher.vue";
import ClassificationDB from "@/plugins/ClassificationDB";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object},
    // errors: {type: Object},
})

const materials = defineModel('materials', {type: Object})
const errors = defineModel('errors', {type: Object})

const optionsMaterialType = computed(() => {
    return _.map($translate('report-type.material-type'), (v, k) => {
        return {value: k, label: v}
    })
})
const optionsCountry = ref(null)
const optionsMediaType = ref(null)
const optionsAwardsState = ref(null)

const materialDialog = ref(false)

const materialForm = useForm({
    type: null,
    title: null,
    description: null,
    members: [],
    date: null,
    url: null,
    publisher_id: null,
    submission_id: null,
    degree_id: null,
    country_id: null,
    vendor: null,
    length: null
})
const newMaterial = () => {
    materialForm.reset()
    materialDialog.value = true
}
const authorsParam = {
    show: true,
    name: 'Лица и организации',
    required: false,
}
const setPublisher = (publisher) => {
    _.set(materialForm, 'publisher_id', publisher.value)
}
const saveMaterial = () => {
    if (_.get(materialForm, 'index') >= 0) {
        materials.value[_.get(materialForm, 'index')] = _.cloneDeep(materialForm);
    } else {
        materials.value.push(_.cloneDeep(materialForm))
    }
    materialForm.reset()
    materialDialog.value = false
}
const editMaterial = (index) => {
    let material = materials.value[index]
    _.set(materialForm, 'index', index)
    _.forEach(material, (v, k) => {
        _.set(materialForm, k, v)
    })
    materialDialog.value = true
}
const deleteMaterial = (index) => {
    materials.value.splice(index, 1)
}
onMounted(() => {
    ClassificationDB
            .getMultiList('country')
            .then((r) => {
                optionsCountry.value = r
            })
    ClassificationDB
            .getMultiList('mediatype')
            .then((r) => {
                optionsMediaType.value = r
            })
    ClassificationDB
            .getMultiList('awardstate')
            .then((r) => {
                optionsAwardsState.value = r
            })
})
</script>