<template>
    <Card :title="$translate('module-archive.research.report.worktime.title')" class="q-mb-md" hide-actions>
        <template v-slot:default>
            <template v-if="helpers.filled(researchReportWorktime)">
                <template v-if="helpers.filled(_.get(researchReportWorktime, 'load_info'))">
                    <q-list separator>
                        <template v-for="(worktimeData, i) in _.get(researchReportWorktime, 'load_info')"
                                  :key="i"
                        >
                            <template v-if="helpers.filled(_.get(worktimeData, 'user_info'))">
                                <div class="flex items-center justify-between">
                                    <div class="text-h5">{{ handleUserInfo(_.get(worktimeData, 'user_info')) }}</div>
                                    <q-btn :icon="expanded[_.get(worktimeData, 'user_info.user_id')] ?
                                                'keyboard_arrow_up' :
                                                'keyboard_arrow_down'"
                                           :title="$translate('buttons.detail')"
                                           @click="switchExpanded(_.get(worktimeData, 'user_info.user_id'))"
                                           size="sm"
                                           flat
                                    />
                                </div>
                                <small>{{ _.get(worktimeData, 'user_info.email') }}</small>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12">
                                                {{
                                                    $translate('module-archive.research.report.worktime.fields.total_time')
                                                }}:
                                            </div>
                                            <div class="col-12">
                                                {{ _.get(worktimeData, 'total_time') }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                {{
                                                    $translate('module-archive.research.report.worktime.fields.total_price')
                                                }}:
                                            </div>
                                            <div class="col-12">
                                                <span v-if="helpers.filled(_.get(worktimeData, 'total_price'))">
                                                    {{ helpers.formatMoney(_.get(worktimeData, 'total_price')) }}
                                                </span>
                                                <span v-else class="text-negative">
                                                    {{ $translate('common.not_available') }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <q-slide-transition>
                                    <div v-show="expanded[_.get(worktimeData, 'user_info.user_id')]">
                                        <q-table v-if="helpers.filled(_.get(worktimeData, 'used_info'))"
                                                 :columns="columns"
                                                 :rows="_.get(worktimeData, 'used_info')"
                                                 :pagination="{rowsPerPage: 0}"
                                                 :rows-per-page-options="[0]"
                                                 hide-pagination
                                                 wrap-cells
                                                 bordered
                                                 flat
                                        >
                                            <template v-slot:body-cell-date="props">
                                                <q-td :props="props">
                                                    <span v-if="helpers.filled(props.row.date)">
                                                        {{ moment(props.row.date).format($translate('common.date.js')) }}
                                                    </span>
                                                    <span v-else class="text-negative">
                                                        {{ $translate('common.not_available') }}
                                                    </span>
                                                </q-td>
                                            </template>
                                            <template v-slot:body-cell-user="props">
                                                <q-td :props="props">
                                                    <span v-if="helpers.filled(_.get(worktimeData, 'user_info'))">
                                                        {{ handleUserInfo(_.get(worktimeData, 'user_info')) }}
                                                    </span>
                                                    <span v-else class="text-negative">
                                                        {{ $translate('common.not_available') }}
                                                    </span>
                                                </q-td>
                                            </template>
                                        </q-table>
                                        <AlertWarning v-else>
                                            {{
                                                $translate('module-archive.research.report.worktime.warnings.no-data')
                                            }}
                                        </AlertWarning>
                                    </div>
                                </q-slide-transition>
                            </template>
                        </template>
                    </q-list>
                </template>
                <template v-else>
                    <AlertWarning>
                        {{ $translate('module-archive.research.report.worktime.warnings.no-data') }}
                    </AlertWarning>
                </template>
            </template>
            <template v-else>
                <AlertWarning>
                    {{ $translate('module-archive.research.report.worktime.warnings.no-data') }}
                </AlertWarning>
            </template>
        </template>
    </Card>
</template>

<script setup>
import AlertWarning from "@/Components/Block/AlertWarning.vue";
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import moment from "moment";
import helpers from "@/helpers";

const props = defineProps({
    researchReportWorktime: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const expanded = ref({});
if (helpers.filled(props.researchReportWorktime)) {
    if (helpers.filled(_.get(props.researchReportWorktime, 'load_info'))) {
        _.forEach(_.get(props.researchReportWorktime, 'load_info'), worktimeData => {
            expanded.value[_.get(worktimeData, 'user_info.user_id')] = ref(false);
        });
    }
}

const switchExpanded = (id) => {
    expanded.value[id] = !expanded.value[id];
};

const columns = [
    {
        name: 'date',
        label: $translate('module-archive.research.report.worktime.columns.date'),
        align: 'left',
        field: 'date'
    },
    {
        name: 'time',
        label: $translate('module-archive.research.report.worktime.columns.time'),
        align: 'left',
        field: 'time'
    },
    {
        name: 'user',
        label: $translate('module-archive.research.report.worktime.columns.user'),
        align: 'left',
        field: 'user'
    },
    {
        name: 'comment',
        label: $translate('module-archive.research.report.worktime.columns.comment'),
        align: 'left',
        field: 'comment'
    },
];

const handleUserInfo = (userInfo) => {
    if (_.has(userInfo, 'fullname')) {
        return _.get(userInfo, 'fullname');
    }
    return [
        _.get(userInfo, 'lastname'),
        _.get(userInfo, 'firstname'),
        _.get(userInfo, 'midname')
    ].join(' ');
};
</script>
