<template>
    <Card v-if="_.size(project.type.expected_result_array)>0"
          :title="$translate('project.form.fields.expected-result')"
          hide-actions no-card-section>
        <div class="text-negative q-pa-sm q-px-md"
             v-if="project.errors.hasOwnProperty('expectedresult')">
            {{ _.get(project.errors, 'expectedresult') }}
        </div>
        <template v-for="(expectedResult) in project.type.expected_result_array">
            <div class="self-stretch full-width row items-center q-gutter-x-md q-pb-sm border-bottom">
                <div class="col-10">{{ expectedResult.name }}</div>
                <q-input v-model="project.expectedresult[expectedResult.slug]['count']"
                         class="col-4"
                         :title="expectedResult.name"
                         input-class="text-center q-pr-md"
                         mask="#" reverse-fill-mask
                ></q-input>
                <q-input v-model="project.expectedresult[expectedResult.slug]['comment']"
                         :label="$translate('project.form.fields.expected-result-comment')"
                         class="col"
                         stack-label></q-input>
            </div>
        </template>
    </Card>
</template>

<script setup>
import {
    getCurrentInstance,
    onBeforeMount,
    ref,
    watch,
} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const project = defineModel({type: Object})

const emit = defineEmits(['update:project'])

const expected_result = ref({})

onBeforeMount(() => {
    _.forEach(_.get(project.value, 'type.expected_result_array'), (expRes) => {
        _.set(project.value.expectedresult, expRes.slug, {count: null, comment: null})
    })
})
</script>