<template>
    <Card :title="$translate('module-archive.project.card.leader.title')" class="q-mb-md" hide-actions>
        <template v-slot:default>
            <template v-if="leader">
                <div class="row q-py-xs">
                    <div class="col-6">{{ $translate('module-archive.project.card.leader.fields.fullname') }}:</div>
                    <div class="col-16">{{ _.get(leader, 'fullname') }}</div>
                </div>
                <div class="row q-py-xs">
                    <div class="col-6">{{ $translate('module-archive.project.card.leader.fields.email') }}:</div>
                    <div class="col-16">
                        <span :class="isNotAvailable(_.get(leader, 'email')) ? 'text-negative' : ''">
                            {{ _.get(leader, 'email') }}
                        </span>
                    </div>
                </div>
                <div class="row q-py-xs">
                    <div class="col-6">{{ $translate('module-archive.project.card.leader.fields.ad') }}:</div>
                    <div class="col-16">
                        <span :class="isNotAvailable(_.get(leader, 'ad')) ? 'text-negative' : ''">
                            {{ _.get(leader, 'ad') }}
                        </span>
                    </div>
                </div>
                <template v-for="(description, property) in $translate('module-archive.project.person.additional-data')"
                          :key="property">
                    <div v-if="_.get(leader, property)" class="row q-py-xs">
                        <div class="col-6">{{ description }}</div>
                        <div class="col-16">{{ _.get(leader, property) }}</div>
                    </div>
                </template>
            </template>
            <template v-else>
                <AlertWarning>{{ $translate('module-archive.project.warnings.no-leader') }}</AlertWarning>
            </template>
        </template>
    </Card>
</template>

<script setup>
import AlertWarning from "@/Components/Block/AlertWarning.vue";
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";

const props = defineProps({
    leader: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const isNotAvailable = (value) => $translate('common.not_available') === value;
</script>
