<template>
    <Card :title="$translate('research-template.title.add')"
          title-bg="blue-grey-3" header-toolbar>
        <q-select v-model="form.ssc_id"
                  :label="$translate('research-template.fields.ssc')"
                  :options="sscList"
                  option-label="name"
                  option-value="id"
                  options-dense emit-value map-options stack-label
                  :error="form.errors.hasOwnProperty('ssc_id')"
                  :error-message="_.get(form.errors,'ssc_id')"
        />

        <ResearchTemplateForm :template="form"/>

        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.add')"
                   color="positive"
                   @click="submitForm"/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from 'lodash'
import {useQuasar} from "quasar";
import ResearchTemplateForm from "@/Components/ResearchTemplate/ResearchTemplateForm.vue";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const sscList = computed(() => usePage().props.auth.ssc)


const form = useForm({
    name: null,
    ssc_id: null,
    internal: false,
    external: false,
    allow: {
        allow_equipment: false,
        allow_consumables: false
    },
    assay: false,
    assay_limit: {
        min: null,
        max: null,
    },
    fields: []
})

const submitForm = () => {
    form.post(route('research.template.store'), {
        onSuccess: () => {
            //router.visit(route('research.template'))
        }
    })
}
</script>