<template>
    <q-btn icon="add"
           :label="$translate('cadre-department.actions.add')"
           color="blue-grey-6"
           @click="openAddDepartment(0)"
           no-caps
           dense/>

    <q-dialog v-model="department_form_add" no-esc-dismiss no-backdrop-dismiss>
        <Card :title="$translate('cadre-department.title.create')"
              in-modal header-toolbar show-close-button>
            <CadreDepartmentForm v-model="department"/>
            <template v-slot:actions>
                <q-btn color="positive"
                       icon="add"
                       :label="$translate('buttons.add')"
                       @click="addDepartment"/>
                <q-btn color="grey-7"
                       icon="close"
                       :label="$translate('buttons.close')"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {Card} from "@/Components/Block/";
import CadreDepartmentForm from "@/Components/Cadre/Department/CadreDepartmentForm.vue";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

const emit = defineEmits(['onSuccess'])

const department = useForm({
    id: null,
    parent_id: 0,
    name_i18n: {
        ru: null,
        en: null
    },
    ssc_id: null,
    permission: {}
})

const department_form_add = ref(false)
const openAddDepartment = (parent_id: number = 0) => {
    _.set(department, 'parent_id', parent_id)
    department_form_add.value = true
}
const addDepartment = () => {
    department.post($route('cadre.department.store'), {
        onSuccess: () => {
            department.reset();
            department_form_add.value = false
            emit('onSuccess')
        }
    })
}
</script>