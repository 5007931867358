<template>
    <Card :title="$translate('module-ethics.application.card.files.title')" hide-actions no-card-section>
        <template v-slot:default>
            <q-list v-if="outputFiles && !_.isEmpty(outputFiles)" separator>
                <q-item>
                    <q-item-section class="col-14">
                        <q-item-label>
                            {{ $translate('module-ethics.application.card.files.fields.name') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('module-ethics.application.card.files.fields.size') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('module-ethics.application.card.files.fields.uploaded') }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item v-for="(file, i) in outputFiles" :key="i">
                    <q-item-section class="col-14">
                        <q-item-label>
                            <a :href="file.url" target="_blank" class="text-brand">
                                <q-icon :name="'far fa-' + file.icon" />
                                {{ file.name }}
                            </a>
                        </q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ _.get(file, 'size') }}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ _.get(file, 'uploaded') }}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";

const props = defineProps({
    outputFiles: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
