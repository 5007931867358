<template>

    <Alert variant="info">Выберите блоки требующиеся для данного ОМ</Alert>

    <component :is="showBlock('CommonActivities')" v-model="fields.common"/>
    <component :is="showBlock('Authors')" v-model="fields.authors"/>
    <component :is="showBlock('Responsible')" v-model="fields.responsible"/>
    <component :is="showBlock('Files')" v-model="fields.files"/>
    <component :is="showBlock('Rubrics')" v-model="fields.rubrics"/>
    <component :is="showBlock('ExternalID')" v-model="fields.externalid"/>

</template>

<script setup>
import {getCurrentInstance, onMounted} from "vue";
import _ from "lodash";
import {Alert} from "@/Components/Block";
import * as FormBlock from './Block'
const showBlock = (block) => FormBlock[block]

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const fields = defineModel({
    type: Object,
})
onMounted(() => {
    if (!fields.value.hasOwnProperty('common')) {
        _.set(fields.value, 'common', {
            name: 'Информация о деятельности',
            show: false,
            required: false,
            fields: {
                name: {
                    label: 'Наименование деятельности',
                    show: true,
                    required: true,
                },
                description: {
                    label: 'Описание ',
                    show: true,
                    required: false,
                },
                result: {
                    label: 'Результат',
                    show: true,
                    required: false,
                },
                comment: {
                    label: 'Реферат',
                    show: true,
                    required: false,
                },
                object: {
                    label: 'Объект применения',
                    value: [],
                    show: true,
                    required: true,
                },
                target: {
                    label: 'Область применения',
                    value: [],
                    show: true,
                    required: true,
                },
                date: {
                    label: 'Дата/период',
                    show: true,
                    required: false,
                },
                recognition: {
                    label: 'Степень признания',
                    show: true,
                    required: false,
                },
                indicator: {
                    label: 'Индикатор',
                    show: true,
                    required: false,
                }
            },
            fields_additional: {}
        })
    }
    if (!fields.value.hasOwnProperty('authors')) {
        _.set(fields.value, 'authors', {
            name: 'Лица/организации, осуществляющие деятельность',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('responsible')) {
        _.set(fields.value, 'responsible', {
            name: 'Ответственные за деятельность',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('files')) {
        _.set(fields.value, 'files', {
            name: 'Документы и ссылки',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('rubrics')) {
        _.set(fields.value, 'rubrics', {
            name: 'Ключевые слова, рубрикаторы и классификаторы',
            show: false,
            required: false,
            keywords: {
                label: 'Ключевые слова',
                show: true,
                required: false,
            },
            fields: []
        })
    }
    if (!fields.value.hasOwnProperty('externalid')) {
        _.set(fields.value, 'externalid', {
            name: 'Внешние ID',
            show: false,
            required: false,
        })
    }
})
</script>