<template>
    <Layout :department="department" tab="staff">
        <q-bar class="bg-grey-2 q-py-sm q-mb-sm">
            <h6 class="q-my-none">{{ $translate('ssc.title-staff-dutyjourney') }}</h6>
            <q-space/>
            <q-btn
                    icon="add"
                    :label="$translate('buttons.add')"
                    color="white" text-color="black"
                    size="md" dense no-caps
                    @click="openAddDialog"
            />
            <q-separator vertical class="q-mx-md"/>
            <q-select v-model="currentYear"
                      :options="years"
                      @update:modelValue="selectYear"
                      :prefix="$translate('на')"
                      :suffix="$translate('units.year')"
                      dense/>
            <q-btn-toggle v-model="tab"
                          spread no-caps
                          toggle-color="blue-grey-3" color="white" text-color="black"
                          :options="[{value: 'table', slot:'table'},{value: 'chart',slot:'chart'}]"
                          @update:model-value="onUpdateTab"
            >
                <template v-slot:table>
                    <q-icon name="sym_o_table" size="sm"/>
                    <q-tooltip>{{ $translate('buttons.view-as-table') }}</q-tooltip>
                </template>
                <template v-slot:chart>
                    <q-icon name="sym_o_grouped_bar_chart" size="sm"/>
                    <q-tooltip>{{ $translate('buttons.view-as-chart') }}</q-tooltip>
                </template>
            </q-btn-toggle>
        </q-bar>
        <template v-if="tab==='table'">
            <q-list separator>
                <q-item class="text-bold">
                    <q-item-section class="col-4">
                        <q-item-label>{{ $translate('cadre-staff.fields.staff') }}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label class="text-left">{{ $translate('cadre-dutyjourney.label.period') }}</q-item-label>
                    </q-item-section>
                </q-item>
                <template v-for="(item) in staff">
                    <q-item>
                        <q-item-section class="col-4">
                            <q-item-label>
                                {{ item.name }}
                            </q-item-label>
                            <q-item-label caption>
                                {{ _.get(item, 'cadre_position.name') }} {{ _.get(item, 'rate_formatted') }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-list dense separator>
                                <template v-for="(dutyjourney) in item.dutyjourney">
                                    <StaffDutyjourneyItem :dutyjourney="dutyjourney"/>
                                </template>
                            </q-list>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </template>
        <template v-if="tab==='chart'">
            <Chart v-if="!_.isEmpty(chartData)"
                   chart-type="gantt-date"
                   :data="chartData"
                   :config="chartConfig"
                   :height="chartHeight"
            />
        </template>
        <q-dialog v-model="addDialog" no-backdrop-dismiss>
            <Card :title="$translate('cadre-dutyjourney.title.new')"
                  in-modal show-close-button header-toolbar>
                <q-select v-model="addForm.staff"
                          :label="$translate('Сотрудник')"
                          :options="staff"
                          option-label="name"
                          dense
                />

                <ProfileDutyjourneyForm v-if="addForm.staff"
                                        v-model="addForm"
                                        :position="[_.get(addForm.staff,'cadre_position.name')].join(', ')"/>

                <template v-slot:actions>
                    <q-btn icon="o_save"
                           :label="$translate('buttons.save')"
                           color="positive"
                           dense
                           @click="addFormSubmit"
                    />
                </template>
            </Card>
        </q-dialog>
    </Layout>
</template>

<script setup lang="ts">
import Layout from "@/Pages/SSC/Layout.vue";
import {computed, ComputedRef, getCurrentInstance, Ref, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {CadreStaff, CadreDutyjourney} from "@/entity";
import _ from "lodash";
import moment from "moment/moment";
import {IGanttDateChartConfig} from "@/entity/charts";
import Chart from "@/Components/Chart.vue";
import {AnyValuesObject, StringValuesObject} from "@/entity/types";
import StaffDutyjourneyItem from "@/Components/SSC/Staff/StaffDutyjourneyItem.vue";
import ProfileDutyjourneyForm from "@/Components/Cadre/Dutyjourney/ProfileDutyjourneyForm.vue";
import {Card} from "@/Components/Block";
import ProfileVacationForm from "@/Components/Cadre/Vacation/ProfileVacationForm.vue";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

interface Props {
    department: object,
    staff: CadreStaff[],
    years: [],
    year?: number | null,
}

const props = defineProps<Props>()

const tab = ref('table')

const loading = ref(false)


const currentYear = ref(props.year || moment().year())


/**
 * переход на страницу больничных за год
 *
 * @param value
 * @return CadreVacation[]
 */
const selectYear = (value: number) => {
    router.visit($route('ssc.staff.dutyjourney', {ssc: props.department, year: value}));
}


const addDialog = ref(false)

const openAddDialog = () => {
    addForm.reset()
    addDialog.value = true;
}

const addForm = useForm({
    staff: null,
    action: null,
    dutyjourney: {
        cadre_staff_id: null,
        type: null,
        date_start: null,
        date_end: null,
        days: null,
        city: null,
        target: null,
        file: null,
        comment: null,
        status: null
    },
    comment: null
});

/**/
const addFormSubmit = () => {
    addForm
            .transform((data) => ({
                        ...data.dutyjourney,
                        cadre_staff_id: _.get(data, 'staff.id'),
                        comment: data.comment
                    })
            )
            .post($route('cadre.dutyjourney.add'), {
                onSuccess: () => {
                    addForm.reset()
                    addDialog.value = false;
                }
            });
}


const chartData: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);
const chartConfig: ComputedRef<IGanttDateChartConfig> = computed<IGanttDateChartConfig>(() => ({
    chartSettings: {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
    },
    chartWidgets: {
        cursor: {behavior: "zoomX"},
    },
    categoryYAxis: {
        categoryField: "name",
        categories: [],
        rendererSettings: {
            minGridDistance: 30
        },
        rendererGridTemplateLocation: 1
    },
    dateXAxis: {
        baseInterval: {timeUnit: "day", count: 1},
        gridIntervals: [
            {timeUnit: "day", count: 1},
            {timeUnit: "week", count: 1},
            {timeUnit: "month", count: 1},
        ],
        additionalSettings: {
            min: moment(currentYear.value + "-01-01").toDate().getTime(),
            max: moment(currentYear.value + "-12-31").toDate().getTime(),
            strictMinMax: true,
            tooltipDateFormat: "dd.MM.yyyy"
        },
        rendererSettings: {
            strokeOpacity: 0.1
        }
    },
    columnSeries: {
        openValueXField: "date_start",
        valueXField: "date_end",
        categoryYField: "name",
        tooltipText: "{type_text} ({status_text}):\n[bold]{openValueX.formatDate('dd.MM.yyyy')}[/] - [bold]{valueX.formatDate('dd.MM.yyyy')}[/]\n{comment}",
        dataFieldToProcess: "dutyjourney",
        dateFields: ["date_start", "date_end"],
        dateFormat: "yyyy-MM-dd",
    },
    // customColorLogic: {
    //     fieldName: "status",
    //     fieldValues: [0, 10, 15, 20, 90, 99]
    // }
}));
const chartHeight: Ref<string> = ref<string>("80vh");

const refillChartData: Function = () => {
    chartData.value = [];
    chartConfig.value.categoryYAxis.categories = [];
    let categories: StringValuesObject[] = [], data: AnyValuesObject[] = [];
    _.forEach(props.staff, (staff: CadreStaff) => {
        categories.push({
            [chartConfig.value.categoryYAxis.categoryField]: staff[chartConfig.value.categoryYAxis.categoryField]
        });
        let processedData: AnyValuesObject = {
            [chartConfig.value.categoryYAxis.categoryField]: staff[chartConfig.value.categoryYAxis.categoryField],
            [chartConfig.value.columnSeries.dataFieldToProcess]: []
        };
        _.forEach(staff[chartConfig.value.columnSeries.dataFieldToProcess], (dutyjourney: CadreDutyjourney) => {
            let valueXField: string | null = dutyjourney[chartConfig.value.columnSeries.valueXField];
            if (_.isNull(valueXField)) {
                valueXField = moment().format(moment.HTML5_FMT.DATE);
            }
            if (!_.isNull(dutyjourney[chartConfig.value.columnSeries.openValueXField])) {
                processedData[chartConfig.value.columnSeries.dataFieldToProcess].push({
                    [chartConfig.value.columnSeries.openValueXField]: dutyjourney[chartConfig.value.columnSeries.openValueXField],
                    [chartConfig.value.columnSeries.valueXField]: valueXField,
                    [chartConfig.value.columnSeries.categoryYField]: staff[chartConfig.value.columnSeries.categoryYField],
                    status: dutyjourney.status,
                    status_text: dutyjourney.status_text,
                    type_text: dutyjourney.type_text,
                    comment: dutyjourney.comment
                });
            }
        });
        data.push(processedData);
    });
    chartData.value = data;
    chartConfig.value.categoryYAxis.categories = _.reverse(
        _.sortBy(categories, chartConfig.value.categoryYAxis.categoryField)
    );
    if (1 === _.size(chartConfig.value.categoryYAxis.categories)) {
        chartHeight.value = "250px";
    }
};
const onUpdateTab = () => {
    if ("chart" === tab.value) {
        refillChartData();
    }
};
</script>
