<template>
    <div class="gantt-date-chart" ref="chartRef"></div>
</template>

<script setup lang="ts">
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {onBeforeUnmount, onMounted, Ref, ref, ShallowRef, shallowRef, watch} from "vue";
import {set, toValue} from "@vueuse/core";
import _ from "lodash";
import {AnyValuesObject} from "@/entity/types";
import {IGanttDateChartConfig, ConfigHandler} from "@/entity/charts";
import helpers from "@/helpers";
import moment from "moment";

interface Props {
    data: AnyValuesObject[],
    config: IGanttDateChartConfig,
    locale: AnyValuesObject,
    columnsHeight?: number
}

const props = withDefaults(defineProps<Props>(), {
    columnsHeight: 25
});

const configHandler: ConfigHandler = ConfigHandler.new(props.config);

const chartRef: Ref<any> = ref<any>();

const am5Root: ShallowRef = shallowRef<any>();
const am5Chart: ShallowRef = shallowRef<any>();
const am5YAxis: ShallowRef = shallowRef<any>();
const am5XAxis: ShallowRef = shallowRef<any>();
const am5Colors: ShallowRef = shallowRef<any>();

const createChart: Function = (): void => {
    clearRefs();

    let root: am5.Root = am5.Root.new(toValue(chartRef));
    root.setThemes([am5themes_Animated.new(root)]);
    root.locale = props.locale;
    root._logo!.dispose();
    set(am5Root, { root: root });

    set(am5Chart, {
        chart: am5Root.value.root.container.children.push(
            am5xy.XYChart.new(am5Root.value.root, Object.assign({}, configHandler.get("chartSettings"), {
                layout: am5Root.value.root.verticalLayout
            }))
        )
    });

    set(am5Colors, {
        colors: am5Chart.value.chart.get("colors")
    });

    set(am5YAxis, {
        yAxis: am5Chart.value.chart.yAxes.push(
            am5xy.CategoryAxis.new(am5Root.value.root, getYAxisSettings())
        )
    });

    set(am5XAxis, {
        xAxis: am5Chart.value.chart.xAxes.push(
            am5xy.DateAxis.new(am5Root.value.root, getXAxisSettings())
        )
    });
    if (configHandler.has("dateXAxis.dateFormats")) {
        helpers.setMany(configHandler.get("dateXAxis.dateFormats"), am5XAxis.value.xAxis.get("dateFormats"));
    }
    if (configHandler.has("dateXAxis.rendererLabelsTemplateSettings")) {
        let xRenderer = am5XAxis.value.xAxis.get("renderer");
        xRenderer.labels.template.setAll(configHandler.get("dateXAxis.rendererLabelsTemplateSettings"));
    }

    am5YAxis.value.yAxis.data.setAll(configHandler.get("categoryYAxis.categories"));

    let series = am5Chart.value.chart.series.push(
        am5xy.ColumnSeries.new(am5Root.value.root, getSeriesSettings())
    );
    series.columns.template.setAll(getSeriesColumnsTemplateSettings());
    if (configHandler.has("columnSeries.dateFields") && configHandler.has("columnSeries.dateFormat")) {
        series.data.processor = am5.DataProcessor.new(am5Root.value.root, {
            dateFields: configHandler.get("columnSeries.dateFields"),
            dateFormat: configHandler.get("columnSeries.dateFormat")
        });
    }
    series.data.setAll(getProcessedData());
    series.appear(1000, 100);

    setChartWidgets();

    am5Chart.value.chart.appear(1000, 100);
};

const clearRefs: Function = (): void => {
    set(am5Root, null);
    set(am5Chart, null);
    set(am5YAxis, null);
    set(am5XAxis, null);
    set(am5Colors, null);
};

const getYAxisSettings: Function = (): AnyValuesObject => {
    let yRenderer: am5xy.AxisRendererY = am5xy.AxisRendererY.new(
        am5Root.value.root,
        configHandler.get("categoryYAxis.rendererSettings", {})
    );
    if (configHandler.has("categoryYAxis.rendererGridTemplateLocation")) {
        yRenderer.grid.template.set("location", configHandler.get("categoryYAxis.rendererGridTemplateLocation"));
    }
    return {
        categoryField: configHandler.get("categoryYAxis.categoryField"),
        tooltip: am5.Tooltip.new(am5Root.value.root, configHandler.get("categoryYAxis.tooltipSettings", {})),
        renderer: yRenderer
    };
};

const getXAxisSettings: Function = (): AnyValuesObject => {
    let xAxisSettings: AnyValuesObject = {
        baseInterval: configHandler.get("dateXAxis.baseInterval"),
        gridIntervals: configHandler.get("dateXAxis.gridIntervals"),
        renderer: am5xy.AxisRendererX.new(am5Root.value.root, configHandler.get("dateXAxis.rendererSettings", {})),
        tooltip: am5.Tooltip.new(am5Root.value.root, {})
    };
    if (configHandler.has("dateXAxis.additionalSettings")) {
        helpers.setMany(configHandler.get("dateXAxis.additionalSettings"), xAxisSettings);
    }
    return xAxisSettings;
};

const getProcessedData: Function = (): AnyValuesObject[] => {
    let processedData: AnyValuesObject[] = [], i: number = 0;
    _.forEach(props.data, (item: AnyValuesObject) => {
        _.forEach(item[configHandler.get("columnSeries.dataFieldToProcess")], (dataToProcess: AnyValuesObject) => {
            if (configHandler.has("customColorLogic")) {
                if (configHandler.has("customColorLogic.fieldValues") && configHandler.has("customColorLogic.fieldName")) {
                    let colors: AnyValuesObject = {}, index: number = 0;
                    _.forEach(configHandler.get("customColorLogic.fieldValues"), (value) => {
                        colors[value] = am5Colors.value.colors.getIndex(index);
                        index += 2;
                    });
                    processedData.push(Object.assign({}, dataToProcess, {
                        columnSettings: {
                            fill: colors[dataToProcess[configHandler.get("customColorLogic.fieldName")]]
                        }
                    }));
                }
            } else {
                processedData.push(Object.assign({}, dataToProcess, {
                    columnSettings: {
                        fill: am5Colors.value.colors.getIndex(i)
                    }
                }));
            }
        });
        i += 2;
    });
    return processedData;
};

const getSeriesSettings: Function = (): AnyValuesObject => ({
    xAxis: am5XAxis.value.xAxis,
    yAxis: am5YAxis.value.yAxis,
    openValueXField: configHandler.get("columnSeries.openValueXField"),
    valueXField: configHandler.get("columnSeries.valueXField"),
    categoryYField: configHandler.get("columnSeries.categoryYField"),
    sequencedInterpolation: true,
});
const getSeriesColumnsTemplateSettings: Function = (): AnyValuesObject => ({
    templateField: "columnSettings",
    strokeOpacity: 0,
    height: am5.percent(props.columnsHeight),
    cursorOverStyle: "help", // "pointer"
    tooltipText: configHandler.get("columnSeries.tooltipText", undefined)
});

const setChartWidgets: Function = (): void => {
    if (configHandler.has("chartWidgets")) {
        if (configHandler.has("chartWidgets.cursor")) {
            am5Chart.value.chart.set(
                "cursor",
                am5xy.XYCursor.new(am5Root.value.root, configHandler.get("chartWidgets.cursor"))
            );
        }
        if (configHandler.has("chartWidgets.scrollbarX")) {
            let scrollbarX = am5Chart.value.chart.set(
                "scrollbarX",
                am5.Scrollbar.new(am5Root.value.root, configHandler.get("chartWidgets.scrollbarX"))
            );
            scrollbarX.startGrip.set("scale", 0.7);
            scrollbarX.endGrip.set("scale", 0.7);
        }
    }
};

onMounted(() => {
    createChart();
});
onBeforeUnmount(() => {
    if (am5Root.value.root) {
        am5Root.value.root.dispose();
    }
});
</script>

<style scoped>
.gantt-date-chart {
    width: 100%;
    height: 100%;
}
</style>
