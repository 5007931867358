<template>
    <Card title-bg="deep-purple-2"
          :title="$translate('module-sensors.sensors.form.title.update')"
          actions-align="center"
          header-toolbar
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('module-sensors.sensors.form.actions.back')"
                   @click="router.get($route('services.sensors.list'))"
                   icon="o_chevron_left"
                   color="deep-purple-5"
                   dense
            />
        </template>
        <SensorForm v-model="form" :sensor-groups="sensorGroups" :sensor-types="sensorTypes" :ssc="ssc"/>
        <template v-slot:actions>
            <q-btn :label="$translate('module-sensors.sensors.form.actions.save')"
                   @click="submitForm"
                   color="positive"
            />
        </template>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {Form as SensorForm} from "@/Components/Sensors/Sensor";
import {getCurrentInstance} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    sensor: {
        type: Object,
        default(rawProps) {
            return {
                name: '',
                period: 1,
                timeout: null,
                sensor_group_id: null,
                sensor_type_id: null,
                connection_data: null,
                enabled: true,
                sensor_location: {
                    address: '',
                    detail: null,
                    floor: '',
                    room: '',
                },
                ssc_id: null,
                sensor_users: null
            };
        }
    },
    sensorGroups: Array,
    sensorTypes: Array,
    ssc: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    name: _.get(props.sensor, 'name'),
    period: _.get(props.sensor, 'period'),
    timeout: _.get(props.sensor, 'timeout'),
    sensor_group_id: _.get(props.sensor, 'sensor_group_id'),
    sensor_type_id: _.get(props.sensor, 'sensor_type_id'),
    connection_data: _.get(props.sensor, 'connection_data'),
    enabled: _.get(props.sensor, 'enabled'),
    sensor_location: _.get(props.sensor, 'sensor_location'),
    ssc_id: _.get(props.sensor, 'ssc_id'),
    sensor_users: _.get(props.sensor, 'sensor_users')
});

const submitForm = () => {
    form.post(route('services.sensors.update', {id: props.sensor.id}), {
        onSuccess: () => router.visit(route('services.sensors.list'))
    });
};
</script>
