<template>
    <Layout :department="department" tab="staff">
        <q-bar class="bg-grey-2 q-py-sm q-mb-sm">
            <h6 class="q-my-none">{{ $translate('ssc.title-staff-vacation') }}</h6>
            <q-space/>
            <q-btn
                    icon="add"
                    :label="$translate('buttons.add')"
                    color="white" text-color="black"
                    size="md" dense no-caps
                    @click="openAddDialog"
            />
            <q-separator vertical class="q-mx-md"/>
            <q-select v-model="currentYear"
                      :options="years"
                      @update:modelValue="selectYear"
                      :prefix="$translate('на')"
                      :suffix="$translate('units.year')"
                      dense/>
            <q-btn-toggle v-model="tab"
                          spread no-caps
                          toggle-color="blue-grey-3" color="white" text-color="black"
                          :options="[{value: 'table', slot:'table'},{value: 'chart',slot:'chart'}]"
                          @update:model-value="onUpdateTab"
            >
                <template v-slot:table>
                    <q-icon name="sym_o_table" size="sm"/>
                    <q-tooltip>{{ $translate('buttons.view-as-table') }}</q-tooltip>
                </template>
                <template v-slot:chart>
                    <q-icon name="sym_o_grouped_bar_chart" size="sm"/>
                    <q-tooltip>{{ $translate('buttons.view-as-chart') }}</q-tooltip>
                </template>
            </q-btn-toggle>
        </q-bar>
        <template v-if="tab==='table'">
            <q-list separator>
                <q-item class="text-bold">
                    <q-item-section>
                        <q-item-label>{{ $translate('cadre-staff.fields.staff') }}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label class="text-center">{{ $translate('cadre-vacation.type.payment') }}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label class="text-center">{{ $translate('cadre-vacation.type.own') }}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label class="text-center">{{ $translate('cadre-vacation.type.other') }}</q-item-label>
                    </q-item-section>
                </q-item>
                <template v-for="(item) in staff">
                    <q-item>
                        <q-item-section>
                            <q-item-label>
                                {{ item.name }}
                            </q-item-label>
                            <q-item-label caption>
                                {{ _.get(item, 'cadre_position.name') }} {{ _.get(item, 'rate_formatted') }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-list dense separator>
                                <template v-for="(vacation) in vacationByType(item.vacations, 'payment')">
                                    <StaffVacationItem :vacation="vacation"/>
                                </template>
                            </q-list>
                        </q-item-section>
                        <q-separator vertical/>
                        <q-item-section>
                            <q-list dense separator>
                                <template v-for="(vacation) in vacationByType(item.vacations, 'own')">
                                    <StaffVacationItem :vacation="vacation"/>
                                </template>
                            </q-list>
                        </q-item-section>
                        <q-separator vertical/>
                        <q-item-section>
                            <q-list dense separator>
                                <template v-for="(vacation) in vacationByType(item.vacations, 'other')">
                                    <StaffVacationItem :vacation="vacation"/>
                                </template>
                            </q-list>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </template>
        <template v-if="tab==='chart'">
            <Chart v-if="!_.isEmpty(chartData)"
                   chart-type="gantt-date"
                   :data="chartData"
                   :config="chartConfig"
                   :height="chartHeight"
            />
        </template>
    </Layout>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('cadre-vacation.title.create')"
              in-modal show-close-button header-toolbar>
            <q-select v-model="addForm.staff"
                      :label="$translate('Сотрудник')"
                      :options="staff"
                      option-label="name"
                      dense
            />

            <ProfileVacationForm v-if="addForm.staff"
                                 v-model="addForm"
                                 :position="[_.get(addForm.staff,'cadre_position.name')].join(', ')"/>

            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="addFormSubmit"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup lang="ts">
import Layout from "@/Pages/SSC/Layout.vue";
import {computed, ComputedRef, getCurrentInstance, Ref, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {CadreStaff, CadreVacation} from "@/entity";
import _ from "lodash";
import moment from "moment/moment";
import StaffVacationItem from "@/Components/SSC/Staff/StaffVacationItem.vue";
import {IGanttDateChartConfig} from "@/entity/charts";
import Chart from "@/Components/Chart.vue";
import {AnyValuesObject, StringValuesObject} from "@/entity/types";
import ProfileVacationForm from "@/Components/Cadre/Vacation/ProfileVacationForm.vue";
import {Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

interface Props {
    department: object,
    staff: CadreStaff[],
    years: [],
    year?: number | null,
}

const props = defineProps<Props>()

const tab = ref('table')

const loading = ref(false)

type vacationType = 'payment' | 'own' | 'other';

const currentYear = ref(props.year || moment().year())
/**
 * Отфильтрованные отпуска по типу
 *
 * @param vacations CadreVacation[]
 * @param type 'payment' | 'own' | 'other'
 * @return CadreVacation[]
 */
const vacationByType: Function = (vacations: CadreVacation[], type: vacationType): CadreVacation[] => {
    return _.filter(vacations, (vacation: CadreVacation) => vacation.type === type)
}
/**
 * переход на страницу отпусков за год
 *
 * @param value
 * @return CadreVacation[]
 */
const selectYear = (value: number) => {
    router.visit($route('ssc.staff.vacation', {ssc: props.department, year: value}));
}

const chartData: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);
const chartConfig: ComputedRef<IGanttDateChartConfig> = computed<IGanttDateChartConfig>(() => ({
    chartSettings: {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
    },
    chartWidgets: {
        cursor: {behavior: "zoomX"},
    },
    categoryYAxis: {
        categoryField: "name",
        categories: [],
        rendererSettings: {
            minGridDistance: 30
        },
        rendererGridTemplateLocation: 1
    },
    dateXAxis: {
        baseInterval: {timeUnit: "day", count: 1},
        gridIntervals: [
            {timeUnit: "day", count: 1},
            {timeUnit: "week", count: 1},
            {timeUnit: "month", count: 1},
        ],
        additionalSettings: {
            min: moment(currentYear.value + "-01-01").toDate().getTime(),
            max: moment(currentYear.value + "-12-31").toDate().getTime(),
            strictMinMax: true,
            tooltipDateFormat: "dd.MM.yyyy"
        },
        rendererSettings: {
            strokeOpacity: 0.1
        }
    },
    columnSeries: {
        openValueXField: "date_start",
        valueXField: "date_end",
        categoryYField: "name",
        tooltipText: "{type_text} ({status_text}):\n[bold]{openValueX.formatDate('dd.MM.yyyy')}[/] - [bold]{valueX.formatDate('dd.MM.yyyy')}[/] ({vacation_days} дней)\n{comment}",
        dataFieldToProcess: "vacations",
        dateFields: ["date_start", "date_end"],
        dateFormat: "yyyy-MM-dd",
    },
    customColorLogic: {
        fieldName: "type",
        fieldValues: ["payment", "own", "other"]
    }
}));
const chartHeight: Ref<string> = ref<string>("80vh");

const addDialog = ref(false)

const openAddDialog = () => {
    addDialog.value = true;
}

const addForm = useForm({
    staff: null,
    action: null,
    vacation: {
        cadre_staff_id: null,
        type: null,
        date_start: null,
        date_end: null,
        vacation_days: 0,
    },
    comment: null
})

const addFormSubmit = () => {
    addForm
            .transform((data) => ({
                        ...data.vacation,
                        cadre_staff_id: _.get(data, 'staff.id'),
                        comment: data.comment,
                    })
            )
            .post($route('cadre.vacation.add'), {
                onSuccess: () => {
                    addForm.reset()
                    addDialog.value = false;
                }
            });
}


const refillChartData: Function = () => {
    chartData.value = [];
    chartConfig.value.categoryYAxis.categories = [];
    let categories: StringValuesObject[] = [], data: AnyValuesObject[] = [];
    _.forEach(props.staff, (staff: CadreStaff) => {
        categories.push({
            [chartConfig.value.categoryYAxis.categoryField]: staff[chartConfig.value.categoryYAxis.categoryField]
        });
        let processedData: AnyValuesObject = {
            [chartConfig.value.categoryYAxis.categoryField]: staff[chartConfig.value.categoryYAxis.categoryField],
            [chartConfig.value.columnSeries.dataFieldToProcess]: []
        };
        _.forEach(staff[chartConfig.value.columnSeries.dataFieldToProcess], (vacation: CadreVacation) => {
            processedData[chartConfig.value.columnSeries.dataFieldToProcess].push({
                [chartConfig.value.columnSeries.openValueXField]: vacation[chartConfig.value.columnSeries.openValueXField],
                [chartConfig.value.columnSeries.valueXField]: vacation[chartConfig.value.columnSeries.valueXField],
                [chartConfig.value.columnSeries.categoryYField]: staff[chartConfig.value.columnSeries.categoryYField],
                type: vacation.type,
                type_text: vacation.type_text,
                status_text: vacation.status_text,
                vacation_days: vacation.vacation_days,
                comment: vacation.comment
            });
        });
        data.push(processedData);
    });
    chartData.value = data;
    chartConfig.value.categoryYAxis.categories = _.reverse(
            _.sortBy(categories, chartConfig.value.categoryYAxis.categoryField)
    );
    if (1 === _.size(chartConfig.value.categoryYAxis.categories)) {
        chartHeight.value = "250px";
    }
};
const onUpdateTab = () => {
    if ("chart" === tab.value) {
        refillChartData();
    }
};
</script>
