<template>
    <div>
        <q-checkbox v-model="department.display"
                    :label="$translate('ssc.fields.display')"/>
    </div>
    <div v-if="department.display">
        <q-option-group v-model="department.level"
                        :options="levelOptions"/>
    </div>
    <q-separator/>
    <q-card-actions align="center">
        <q-btn icon="save" :label="$translate('buttons.save')" color="positive"
               @click="patchDepartmentResearchPermission"></q-btn>
    </q-card-actions>
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import {router} from "@inertiajs/vue3";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    department: {}
})
const levelOptions = computed(() => {
    return [
        {value: 1, label: $translate('ssc.fields.level_1')},
        {value: 2, label: $translate('ssc.fields.level_2')},
        {value: 3, label: $translate('ssc.fields.level_3')}
    ]
})
const patchDepartmentResearchPermission = () => {
    router.post(route('ssc.update', props.department.id), {
        display: props.department.display,
        level: props.department.level
    }, {
        onSuccess: () => {
            router.reload()
        }
    })
}
</script>