<template>
    <q-list v-if="services.hasOwnProperty('archive')">
        <NavigationItem :title="$translate('module-archive.name')"
                        :sidebar="sidebar"
                        color="dark"
                        icon="o_inventory_2"
        >
            <template #items>
                <q-item @click="router.visit($route('services.archive.dashboard'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">{{ $translate('menu.archive.dashboard') }}</q-item-section>
                </q-item>
            </template>
        </NavigationItem>
    </q-list>
</template>

<script setup>
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";
import {computed, getCurrentInstance} from "vue";
import {router, usePage} from "@inertiajs/vue3";

const props = defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const page = usePage();
const services = computed(() => page.props.services);
</script>
