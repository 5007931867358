<template>
    <q-table :rows="items"
             :columns="columns"
             :rows-per-page-options="[10,50,100]"
             v-model:pagination="pagination"
             :loading="loading"
             table-header-class="bg-grey-1"
             flat dense wrap-cells>

        <template v-slot:body-cell-name="props">
            <q-td :props="props">
                {{ props.row.file_name }}
            </q-td>
        </template>
        <template v-slot:body-cell-size="props">
            <q-td :props="props">
                {{ props.row.file_size }}
            </q-td>
        </template>
        <template v-slot:body-cell-status="props">
            <q-td :props="props">
                {{ $translate('research-report.list.files.status_' + props.row.status) }}
            </q-td>
        </template>
        <template v-slot:body-cell-comment="props">
            <q-td :props="props">
                {{ _.get(props.row, 'file_data.comment') }}
            </q-td>
        </template>
        <template v-slot:body-cell-actions="props">
            <q-td :props="props">
                <q-btn icon="o_download"
                       :label="$translate('buttons.download')"
                       size="sm"
                       color="primary"
                       :href="downloadFile(props.row)"
                       target="_blank"
                       flat dense/>
                <q-btn v-if="(props.row.status===10 || props.row.status===99)"
                       icon="o_delete"
                       :label="$translate('buttons.delete')"
                       size="sm"
                       color="negative"
                       @click="deleteItem(props.row)"
                       flat dense/>
            </q-td>
        </template>
    </q-table>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import _ from "lodash";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $q = useQuasar()

const props = defineProps({
    items: {type: Array, default: []},
    loading: {type: Boolean, default: false}
})

const emit = defineEmits(['onSuccess'])

const columns = [

    {name: 'checked', label: '', style: 'width:30px', align: 'center'},
    {name: 'name', label: $translate('research-report.list.files.name'), align: 'left'},
    {name: 'size', label: $translate('research-report.list.files.size'), style: 'width:200px', align: 'right'},
    {name: 'status', label: $translate('research-report.list.files.status'), align: 'left'},
    {name: 'comment', label: $translate('research-report.list.files.comment'), align: 'left'},
    {name: 'actions', label: '',},
]

const pagination = ref({
    page: 1,
    rowsPerPage: 10
})

const deleteItem = (item) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: item.file_name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('project.research.report.files.delete', {
            research: item.project_research,
            file: item.id
        }), {
            onSuccess: () => {
                emit('onSuccess')
            }
        })
    })
}
const downloadFile = (file) => {
    return route('project.research.report.files.download', {
        research: file.project_research,
        file: file.id,
        nolog: 1
    })
}
</script>