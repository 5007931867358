<script setup>
import {getCurrentInstance} from 'vue'
import {useQuasar} from "quasar";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();

const props = defineProps({
    anketa: {}
})
</script>

<template>
    <div class="row q-gutter-x-xl">
        <q-field :label="$translate('module-rid.fields.number')" stack-label borderless class="col-4">
            <template v-slot:control>
                <div class="full-width self-center">
                    {{ anketa.number }}
                </div>
            </template>
        </q-field>
        <q-field :label="$translate('module-rid.fields.status')" stack-label borderless class="col-4">
            <template v-slot:control>
                <div class="full-width self-center text-no-wrap">
                    {{ anketa.status_text }}
                </div>
            </template>
        </q-field>
    </div>
    <q-field :label="$translate('module-rid.fields.name')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.name }}
            </div>
        </template>
    </q-field>
    <q-field :label="$translate('module-rid.fields.annotation')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.annotation }}
            </div>
        </template>
    </q-field>
    <q-field :label="$translate('module-rid.fields.characters')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.characters }}
            </div>
        </template>
    </q-field>
</template>