<script setup>
import {getCurrentInstance, onMounted, ref} from 'vue'
import {router, useForm} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import DataTable from "@/Components/DataTable.vue";
import {useQuasar} from "quasar";
import Anketa from "@/Components/RID/Anketa.vue";
import {RIDAnketa, RIDAnketaMember} from "@/entity";
import AnketaReferat from "@/Components/RID/Object/AnketaReferat.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();

const props = defineProps({
    anketa: {}
})
</script>

<template>
    <q-field :label="$translate('module-rid.fields.scope')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.scope }}
            </div>
        </template>
    </q-field>

    <q-field :label="$translate('module-rid.fields.short_description')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.short_description }}
            </div>
        </template>
    </q-field>

    <q-field :label="$translate('module-rid.fields.disclose_information')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.disclose_information }}
            </div>
        </template>
    </q-field>


    <q-field :label="$translate('module-rid.fields.product')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.product }}
            </div>
        </template>
    </q-field>

    <q-field :label="$translate('module-rid.fields.disadvantages')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.disadvantages }}
            </div>
        </template>
    </q-field>

    <q-field :label="$translate('module-rid.fields.benefits')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.benefits }}
            </div>
        </template>
    </q-field>

    <q-field :label="$translate('module-rid.fields.limitations')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.limitations }}
            </div>
        </template>
    </q-field>

    <q-field :label="$translate('module-rid.fields.prospects')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.prospects }}
            </div>
        </template>
    </q-field>

    <q-field :label="$translate('module-rid.fields.form')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.form_value }}
            </div>
        </template>
    </q-field>

    <q-field :label="$translate('module-rid.fields.usage')" stack-label borderless>
        <template v-slot:control>
            <div class="full-width self-center">
                {{ anketa.usage_value }}
            </div>
        </template>
    </q-field>

</template>

<style scoped>

</style>