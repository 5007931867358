<template>
    <StandartLayout :report="report" :ssc="ssc" :from="from" :to="to" :ssc-list="sscList" :tags-list="tagsList">
        <AnalyticReportsTable v-if="!_.isEmpty(reportsList.data)"
                              :reports-list="reportsList.data"
        />
        <AlertDanger v-else>{{ $translate('module-analytics.errors.no-report') }}</AlertDanger>
    </StandartLayout>
</template>


<script setup>
import {getCurrentInstance} from "vue";
import _ from "lodash";
import AnalyticReportsTable from "@/Components/Analytic/Reports/AnalyticReportsTable.vue";
import StandartLayout from "@/Pages/Analytic/Reports/StandartLayout.vue";
import AlertDanger from "@/Components/Block/AlertDanger.vue";

const props = defineProps({
    report: {
        type: String,
        required: true,
    },
    reportsList: {
        type: Object,
        default: {
            data: []
        },
    },
    ssc: {
        type: Number,
    },
    from: {
        type: String,
    },
    to: {
        type: String,
    },
    tags: {
        type: Array,
        default: [],
    },
    tagsAdditional: {
        type: Array,
        default: [],
    },
    sscList: {
        type: Array,
        required: true,
    },
    tagsList: {
        type: Array,
        required: false,
        default: [],
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
