<template>
    <q-btn icon="o_edit"
           :title="$translate('buttons.edit')"
           dense
           @click="dialog = true"
    />
    <q-dialog v-model="dialog" no-backdrop-dismiss>
        <Card :title="$translate('cadre-department.title.edit')"
              in-modal show-close-button header-toolbar>
            <template v-for="(lang_name,lang) in $translate('fields.locale')">
                <q-input v-model="department.name_i18n[lang]"
                         :placeholder="lang_name"
                         :label="$translate('cadre-department.fields.name_i18n')+' ' +lang_name"/>
            </template>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="submitForm"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref, computed} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app?.appContext.config.globalProperties.$translate;
const $route = app?.appContext.config.globalProperties.$route;

const department = defineModel()

const emit = defineEmits(['onSuccess'])

const dialog = ref(false)

const form = useForm({
    name_i18n: {}
})

const $p = usePage();
const sscList = computed(() => _.get($p, 'props.auth.ssc'))
const submitForm = () => {
    form.transform((data) => ({
        ...data,
        name_i18n: department.value.name_i18n
    }))
            .patch($route('cadre.department.update', department.value), {
                onSuccess: () => {
                    form.reset();
                    dialog.value = false;
                    emit('onSuccess')
                }
            })
}
</script>