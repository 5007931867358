<template>
    <Card title-bg="deep-orange-2" :title="$translate('module-ethics.documents-contacts')" hide-actions>
        <div class="row q-gutter-x-md">
            <div class="col">
                <Card :title="$translate('module-ethics.documents.title')" no-card-section hide-actions>
                    <template v-slot:headerActions>
                        <q-btn v-if="_.get(can, 'manageDocuments')"
                               :label="$translate('module-ethics.documents.add')"
                               @click="documentDialog = !documentDialog"
                               icon="o_add_box"
                               flat
                               dense
                        />
                    </template>
                    <template v-slot:default>
                        <q-list padding>
                            <q-item v-for="(doc, i) in documents" :key="i" dense>
                                <q-item-section>
                                    <q-item-label lines="1">
                                        <a :href="doc.url" target="_blank" class="text-brand">
                                            <q-icon :name="'far fa-' + doc.icon" size="xs"/>
                                            {{ doc.name }}
                                        </a>
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn v-if="_.get(can, 'manageDocuments')"
                                           @click="router.delete($route('services.ethics.settings.delete-document', doc.id))"
                                           color="negative"
                                           icon="o_delete"
                                           size="sm"
                                           dense
                                           flat
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                        <q-dialog v-if="_.get(can, 'manageDocuments')" v-model="documentDialog">
                            <Card :title="$translate('module-ethics.documents.add-title')"
                                  style="width: 500px"
                                  no-card-section
                            >
                                <template v-slot:default>
                                    <q-list padding>
                                        <q-item>
                                            <q-item-section>
                                                <q-input v-model="documentsForm.name"
                                                         :label="$translate('module-ethics.documents.name')"
                                                         dense
                                                />
                                            </q-item-section>
                                        </q-item>
                                        <q-item>
                                            <q-item-section>
                                                <q-file v-model="documentsForm.file"
                                                        :label="$translate('module-ethics.documents.file')"
                                                        dense
                                                >
                                                    <template v-slot:prepend>
                                                        <q-icon name="attach_file"/>
                                                    </template>
                                                </q-file>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </template>
                                <template v-slot:actions>
                                    <q-btn :label="$translate('buttons.save')"
                                           @click="postDocuments"
                                           color="positive"
                                    />
                                </template>
                            </Card>
                        </q-dialog>
                    </template>
                </Card>
            </div>
            <div class="col">
                <Card :title="$translate('module-ethics.contacts.title')" no-card-section hide-actions>
                    <template v-slot:default>
                        <q-list padding>
                            <q-item-label header>{{ $translate('module-ethics.committee.animals') }}</q-item-label>
                            <q-item>
                                <q-item-section>
                                    <q-field :label="$translate('module-ethics.settings.ETHICS_ANIMALS_PHONE')"
                                             stack-label
                                             dense
                                    >
                                        <template v-slot:control>
                                            <div class="self-center full-width no-outline" tabindex="0">
                                                {{ contacts.ETHICS_ANIMALS_PHONE }}
                                            </div>
                                        </template>
                                    </q-field>
                                </q-item-section>
                                <q-item-section side>
                                    <q-icon v-if="_.get(can, 'editContacts')"
                                            class="cursor-pointer"
                                            color="grey-5"
                                            name="edit"
                                    >
                                        <q-tooltip class="text-body2">{{ $translate('buttons.edit') }}</q-tooltip>
                                        <q-popup-edit v-model="contactsForm"
                                                      v-slot="scope"
                                                      :title="$translate('module-ethics.settings.ETHICS_ANIMALS_PHONE')"
                                                      :label-set="$translate('buttons.save')"
                                                      :label-cancel="$translate('buttons.cancel')"
                                                      @save="patchContacts"
                                                      auto-save
                                                      buttons
                                        >
                                            <q-input v-model="scope.value.settings.ETHICS_ANIMALS_PHONE"
                                                     autofocus
                                                     counter
                                                     dense
                                            />
                                        </q-popup-edit>
                                    </q-icon>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-field :label="$translate('module-ethics.settings.ETHICS_ANIMALS_EMAIL')"
                                             stack-label
                                             dense
                                    >
                                        <template v-slot:control>
                                            <div class="self-center full-width no-outline" tabindex="0">
                                                {{ contacts.ETHICS_ANIMALS_EMAIL }}
                                            </div>
                                        </template>
                                    </q-field>
                                </q-item-section>
                                <q-item-section side>
                                    <q-icon v-if="_.get(can, 'editContacts')"
                                            class="cursor-pointer"
                                            color="grey-5"
                                            name="edit"
                                    >
                                        <q-tooltip class="text-body2">{{ $translate('buttons.edit') }}</q-tooltip>
                                        <q-popup-edit v-model="contactsForm"
                                                      v-slot="scope"
                                                      :title="$translate('module-ethics.settings.ETHICS_ANIMALS_EMAIL')"
                                                      :label-set="$translate('buttons.save')"
                                                      :label-cancel="$translate('buttons.cancel')"
                                                      @save="patchContacts"
                                                      auto-save
                                                      buttons
                                        >
                                            <q-input v-model="scope.value.settings.ETHICS_ANIMALS_EMAIL"
                                                     autofocus
                                                     counter
                                                     dense
                                            />
                                        </q-popup-edit>
                                    </q-icon>
                                </q-item-section>
                            </q-item>
                            <q-separator spaced />
                            <q-item-label header>{{ $translate('module-ethics.committee.people') }}</q-item-label>
                            <q-item>
                                <q-item-section>
                                    <q-field :label="$translate('module-ethics.settings.ETHICS_PEOPLE_PHONE')"
                                             stack-label
                                             dense
                                    >
                                        <template v-slot:control>
                                            <div class="self-center full-width no-outline" tabindex="0">
                                                {{ contacts.ETHICS_PEOPLE_PHONE }}
                                            </div>
                                        </template>
                                    </q-field>
                                </q-item-section>
                                <q-item-section side>
                                    <q-icon v-if="_.get(can, 'editContacts')"
                                            class="cursor-pointer"
                                            color="grey-5"
                                            name="edit"
                                    >
                                        <q-tooltip class="text-body2">{{ $translate('buttons.edit') }}</q-tooltip>
                                        <q-popup-edit v-model="contactsForm"
                                                      v-slot="scope"
                                                      :title="$translate('module-ethics.settings.ETHICS_PEOPLE_PHONE')"
                                                      :label-set="$translate('buttons.save')"
                                                      :label-cancel="$translate('buttons.cancel')"
                                                      @save="patchContacts"
                                                      auto-save
                                                      buttons
                                        >
                                            <q-input v-model="scope.value.settings.ETHICS_PEOPLE_PHONE"
                                                     autofocus
                                                     counter
                                                     dense
                                            />
                                        </q-popup-edit>
                                    </q-icon>
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <q-field :label="$translate('module-ethics.settings.ETHICS_PEOPLE_EMAIL')"
                                             stack-label
                                             dense
                                    >
                                        <template v-slot:control>
                                            <div class="self-center full-width no-outline" tabindex="0">
                                                {{ contacts.ETHICS_PEOPLE_EMAIL }}
                                            </div>
                                        </template>
                                    </q-field>
                                </q-item-section>
                                <q-item-section side>
                                    <q-icon v-if="_.get(can, 'editContacts')"
                                            class="cursor-pointer"
                                            color="grey-5"
                                            name="edit"
                                    >
                                        <q-tooltip class="text-body2">{{ $translate('buttons.edit') }}</q-tooltip>
                                        <q-popup-edit v-model="contactsForm"
                                                      v-slot="scope"
                                                      :title="$translate('module-ethics.settings.ETHICS_PEOPLE_EMAIL')"
                                                      :label-set="$translate('buttons.save')"
                                                      :label-cancel="$translate('buttons.cancel')"
                                                      @save="patchContacts"
                                                      auto-save
                                                      buttons
                                        >
                                            <q-input v-model="scope.value.settings.ETHICS_PEOPLE_EMAIL"
                                                     autofocus
                                                     counter
                                                     dense
                                            />
                                        </q-popup-edit>
                                    </q-icon>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </template>
                </Card>
            </div>
        </div>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    contacts: Object,
    documents: Array,
    can: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const documentDialog = ref(false);
const documentsForm = useForm({
    name: '',
    file: null
});
const contactsForm = useForm({
    settings: {
        ETHICS_ANIMALS_PHONE: _.get(props.contacts, 'ETHICS_ANIMALS_PHONE'),
        ETHICS_ANIMALS_EMAIL: _.get(props.contacts, 'ETHICS_ANIMALS_EMAIL'),
        ETHICS_PEOPLE_PHONE: _.get(props.contacts, 'ETHICS_PEOPLE_PHONE'),
        ETHICS_PEOPLE_EMAIL: _.get(props.contacts, 'ETHICS_PEOPLE_EMAIL')
    }
});

const postDocuments = () => {
    documentsForm.post(route('services.ethics.settings.save-documents'), {
        onSuccess: (page) => {
            documentDialog.value = !documentDialog.value;
            documentsForm.reset();
            router.reload();
        }
    });
};
const patchContacts = (value) => {
    contactsForm
        .transform((data) => ({...data, ...value}))
        .patch(route('services.ethics.settings.save-contacts'), {
            onSuccess: (page) => {
                router.reload();
            }
        });
};
</script>
