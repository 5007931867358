<template>
    <Card :title="$translate('module-auth.form.login.title')"
          title-bg="grey-1"
          title-classes="text-uppercase text-center"
          header-toolbar
          no-shrink
          no-card-section>
        <q-inner-loading :showing="loading">
            <q-spinner-gears size="50px" color="primary"/>
        </q-inner-loading>
        <q-banner class="bg-yellow-2 q-ma-md" v-if="banner">
            <template v-slot:avatar>
                <q-icon name="warning_amber" color="deep-orange-6"/>
            </template>
            <div v-html="banner"></div>
        </q-banner>
        <form id="login" method="post" :action="$route('login')" @submit.prevent="submit">
            <input type="hidden" name="_token" :value="token">
            <q-input v-model="form.email"
                     type="text"
                     id="email"
                     name="email"
                     :label="$translate('module-auth.form.login.login')"
                     label-color="blue-grey"
                     class="q-ma-md"
                     :error="errors.hasOwnProperty('email')"
                     :error-message="_.get(errors,'email')">
                <template v-slot:prepend v-if="!errors.hasOwnProperty('email')">
                    <q-icon name="o_person" color="blue-grey"/>
                </template>
            </q-input>

            <q-input v-model="form.password"
                     type="password"
                     id="password"
                     name="password"
                     :label="$translate('module-auth.form.login.password')"
                     label-color="blue-grey"
                     class="q-ma-md"
                     bottom-slots
                     :error="errors.hasOwnProperty('password')"
                     :error-message="_.get(errors,'password')">
                <template v-slot:prepend v-if="!errors.hasOwnProperty('password')">
                    <q-icon name="o_password" color="blue-grey"/>
                </template>
            </q-input>
        </form>
        <template v-slot:actions>
            <div class="full-width">
                <div class="text-center">
                    <q-btn color="positive"
                           type="submit"
                           form="login"
                           :label="$translate('buttons.login')"
                           icon-right="login"
                           :loading="form.processing">
                        <template v-slot:loading>
                            <q-spinner-facebook/>
                        </template>
                    </q-btn>
                </div>
                <q-card-section class="flex justify-between align-center full-width">
                    <q-btn v-if="options['register']"
                           :href="$route('register')"
                           :label="$translate('buttons.register') "
                           color="dark"
                           icon="o_person_add_alt"
                           outline no-caps/>
                    <q-btn v-if="options['reset']"
                           :href="$route('password.request')"
                           :label="$translate('buttons.forgotpassword')"
                           icon="lock_reset"
                           color="dark"
                           outline no-caps/>
                    <q-btn href="https://docs.researchims.ru/common/login/"
                           target="_blank"
                           :label="$translate('module-auth.form.problem')"
                           icon="o_report_problem"
                           color="dark"
                           outline
                           no-caps/>
                </q-card-section>
            </div>
        </template>

    </Card>

</template>

<script setup>
import Guest from "@/Layout/Guest.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {computed, ref, getCurrentInstance} from "vue";
import {Card} from "@/Components/Block";
import {useQuasar} from "quasar"
import _ from "lodash";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const $q = useQuasar();
defineOptions({layout: Guest})

const options = computed(() => usePage().props.options)
const errors = computed(() => usePage().props.errors)
const token = computed(() => usePage().props.csrf_token)
const banner = computed(() => usePage().props.banner)

const loading = ref(false)

const form = useForm({
    email: null,
    password: null,
    remember: true
})

const submit = () => {
    $q.loading.show();
    $q.loadingBar.start()
    form.post($route('login'), {
        onSuccess: () => {
            $q.loading.hide();
            $q.loadingBar.stop()
        },
        onFinish: () => {
            $q.loading.hide();
            $q.loadingBar.stop()
        }
    })
}
</script>