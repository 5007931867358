<template>
    <Card :title="$translate('users.title-add')" title-bg="cyan-14" title-text="white"
          in-modal no-card-section show-close-button>
        <q-scroll-area style="height: 75vh;">
            <q-card-section>
                <q-input v-model="form.last_name" :label="$translate('users.fields.last_name')" dense
                         :error-message="form.errors['last_name']"
                         :error="form.errors.hasOwnProperty('last_name')"/>
                <q-input v-model="form.first_name" :label="$translate('users.fields.first_name')" dense
                         :error-message="form.errors['first_name']"
                         :error="form.errors.hasOwnProperty('first_name')"/>
                <q-input v-model="form.middle_name" :label="$translate('users.fields.middle_name')" dense
                         :error-message="form.errors['middle_name']"
                         :error="form.errors.hasOwnProperty('middle_name')"/>

                <q-input v-model="form.email" :label="$translate('users.fields.email')" dense
                         :error-message="form.errors['email']"
                         :error="form.errors.hasOwnProperty('email')"/>
            </q-card-section>
            <q-separator/>
            <q-card-section>
                <q-toolbar-title>{{ $translate('users.details.roles') }}</q-toolbar-title>
                <div class="row q-gutter-xs">
                    <template v-for="(role) in roles">
                        <q-checkbox v-model="selectedRoles" :val="role.id" class="col-11" dense>
                            {{ role.display_name }}
                        </q-checkbox>
                    </template>
                </div>
            </q-card-section>
        </q-scroll-area>

        <template v-slot:actions>
            <q-btn color="positive" icon="save" :label="$translate('buttons.add')" @click="submitForm"/>
            <q-btn color="dark" icon="close" :label="$translate('buttons.cancel')" v-close-popup/>
        </template>
    </Card>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, ref} from "vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {Card} from "@/Components/Block";

const app = getCurrentInstance()

const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

defineProps({
    dialog: {
        type: Boolean,
    }
})
const emit = defineEmits(['onSuccess'])

const roles:any = computed(() => usePage().props.systemRoles)

const selectedRoles = ref([])

const form = useForm({
    first_name: null,
    middle_name: null,
    last_name: null,
    email: null,
    roles: []
})

const submitForm = () => {
    form.transform((data) => ({
        ...data,
        roles: selectedRoles.value,
    }))
            .post($route('admin.users.store'), {
                onSuccess: () => {
                    emit('onSuccess')
                }
            })
}
</script>