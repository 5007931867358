<template>
    <Card title-bg="deep-purple-2"
          :title="$translate('module-sensors.sensor-types.form.title.create')"
          actions-align="center"
          header-toolbar
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('module-sensors.sensor-types.form.actions.back')"
                   @click="router.get($route('services.sensors.sensor-types'))"
                   icon="o_chevron_left"
                   color="deep-purple-5"
                   dense
            />
        </template>
        <SensorTypeForm v-model="form" :connections="connections"/>
        <template v-slot:actions>
            <q-btn :label="$translate('module-sensors.sensor-types.form.actions.add')"
                   @click="submitForm"
                   color="positive"
            />
        </template>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {Form as SensorTypeForm} from "@/Components/Sensors/SensorType";
import {getCurrentInstance} from "vue";
import {router, useForm} from "@inertiajs/vue3";

const props = defineProps({
    connections: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    name: '',
    format: null,
    connection_data: null
});

const submitForm = () => {
    form.post(route('services.sensors.sensor-types.store'), {
        onSuccess: () => router.visit(route('services.sensors.sensor-types'))
    });
};
</script>
