<template>
    <q-btn icon="o_bookmarks"
           :title="$translate('cadre-department.actions.bookmark.'+(!CADRE_DEPARTMENT_BOOKMARKED?'show':'hide'))"
           color="blue-grey-3"
           b
           no-caps
           dense
           :outline="!CADRE_DEPARTMENT_BOOKMARKED"
           @click="toggleBookmark"
           v-bind="$props"/>
</template>

<script setup lang='ts'>
import {getCurrentInstance, onMounted, ref, computed} from "vue";
import {router, usePage} from "@inertiajs/vue3";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

const emit = defineEmits(['onSuccess'])

const CADRE_DEPARTMENT_BOOKMARKED: any = computed(() => usePage().props.CADRE_DEPARTMENT_BOOKMARKED)

const toggleBookmark = () => {
    router.patch($route('cadre.department.bookmark'), {},
            {
                onSuccess: () => emit('onSuccess')
            })
}
</script>