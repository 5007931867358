<template>
    <div v-if="services.hasOwnProperty('rims')">
        <DepartmentSelector :sidebar="sidebar"/>
        <q-list>
            <NavigationItem :title="$translate('menu.project')"
                            :sidebar="sidebar"
                            color="teal-6"
                            icon="storage">
                <template #items>
                    <q-item v-if="services.rims.items.project_all"
                            @click="router.visit($route('project.list'))"
                            clickable v-close-popup>
                        <q-item-section class="q-pl-md">
                            {{ $translate("menu.project-all") }}
                        </q-item-section>
                    </q-item>
                    <q-item v-if="services.rims.items.project_approving"
                            @click="router.visit($route('project.approving'))"
                            clickable v-close-popup>
                        <q-item-section class="q-pl-md">
                            {{ $translate("menu.project-approving") }}
                        </q-item-section>
                    </q-item>
                    <q-item v-if="services.rims.items.project_my"
                            @click="router.visit($route('project.my'))"
                            clickable v-close-popup>
                        <q-item-section class="q-pl-md">
                            {{ $translate("menu.project-my") }}
                        </q-item-section>
                    </q-item>
                    <template v-if="services.rims.items.feedback_all">
                        <q-separator/>
                        <q-item @click="router.visit($route('feedback.all'))"
                                clickable v-close-popup>
                            <q-item-section class="q-pl-md">
                                {{ $translate("menu.project-feedback") }}
                            </q-item-section>
                        </q-item>
                    </template>
                    <template v-if="services.rims.items.project_new">
                        <q-separator/>
                        <q-item @click="router.visit($route('project.new'))"
                                clickable v-close-popup>
                            <q-item-section side class="q-pr-xs">
                                <q-icon name="add" size="xs" color="teal-6"/>
                            </q-item-section>
                            <q-item-section class="q-pl-none text-teal-6">
                                {{ $translate("menu.project-new") }}
                            </q-item-section>
                        </q-item>
                    </template>
                </template>
            </NavigationItem>

            <NavigationItem :title="$translate('menu.research')"
                            :sidebar="sidebar"
                            color="indigo-5"
                            icon="o_biotech">
                <template #items>
                    <template v-if="services.rims.items.research_all">
                        <q-item @click="router.visit($route('researches'))"
                                clickable v-close-popup>
                            <q-item-section class="q-pl-md">
                                {{ $translate("menu.research-all") }}
                            </q-item-section>
                        </q-item>
                        <q-list class="q-pl-md" dense>
                            <!--                        <q-item @click="router.visit($route('researches',{'state':'draft'}))" clickable>-->
                            <!--                            <q-item-section>&mdash; {{ $translate('menu.research-draft') }}</q-item-section>-->
                            <!--                        </q-item>-->
                            <q-item @click="router.visit($route('researches',{'state':'new'}))"
                                    clickable v-close-popup>
                                <q-item-section>&mdash; {{ $translate('menu.research-new') }}</q-item-section>
                            </q-item>
                            <q-item @click="router.visit($route('researches',{'state':'approved'}))"
                                    clickable v-close-popup>
                                <q-item-section>&mdash; {{ $translate('menu.research-approved') }}</q-item-section>
                            </q-item>
                            <q-item @click="router.visit($route('researches',{'state':'progress'}))"
                                    clickable v-close-popup>
                                <q-item-section>&mdash; {{ $translate('menu.research-progress') }}</q-item-section>
                            </q-item>
                            <q-item @click="router.visit($route('researches',{'state':'completed'}))" clickable>
                                <q-item-section>&mdash; {{ $translate('menu.research-completed') }}</q-item-section>
                            </q-item>
                            <q-item @click="router.visit($route('researches',{'state':'claim'}))"
                                    clickable v-close-popup>
                                <q-item-section>&mdash; {{ $translate('menu.research-claim') }}</q-item-section>
                            </q-item>
                        </q-list>
                        <q-separator/>
                    </template>
                    <template v-if="services.rims.items.research_assigned">
                        <q-item @click="router.visit($route('researches.assigned'))"
                                clickable v-close-popup>
                            <q-item-section class="q-pl-md">
                                {{ $translate("menu.research-assigned") }}
                            </q-item-section>
                        </q-item>
                        <q-separator/>
                    </template>
                    <q-item @click="router.visit($route('researches.my'))"
                            clickable v-close-popup>
                        <q-item-section class="q-pl-md">
                            {{ $translate("menu.research-my") }}
                        </q-item-section>
                    </q-item>
                    <q-separator/>
                    <q-item>
                        <q-input v-model="researchNumber"
                                 :label="$translate('menu.research-go-placeholder')"
                                 borderless
                                 dense
                                 class="full-width"
                        >
                            <q-tooltip class="text-body2">{{ $translate('menu.research-go-title') }}</q-tooltip>
                            <template v-slot:append>
                                <q-btn icon="o_double_arrow" size="sm" @click="researchGo" flat dense/>
                            </template>
                        </q-input>
                    </q-item>
                </template>
            </NavigationItem>

            <NavigationItem v-if="services.reportmaterial"
                            :title="$translate('menu.publication.publications')"
                            :sidebar="sidebar"
                            color="purple-5"
                            icon="library_books"
            >
                <template #items>
                    <q-item v-if="services.reportmaterial.items.publication_all"
                            @click="router.visit($route('publication.all'))"
                            clickable v-close-popup>
                        <q-item-section class="q-pl-md">
                            {{ $translate("menu.publication.all") }}
                        </q-item-section>
                    </q-item>
                    <q-item @click="router.visit($route('publication.my'))"
                            clickable v-close-popup>
                        <q-item-section class="q-pl-md">
                            {{ $translate("menu.publication.my") }}
                        </q-item-section>
                    </q-item>
                    <template v-if="services.reportmaterial.items.publication_validate">
                        <q-separator/>
                        <q-item @click="router.visit($route('publication.validating'))"
                                clickable v-close-popup>
                            <q-item-section class="q-pl-md">
                                {{ $translate("menu.publication.validate") }}
                            </q-item-section>
                        </q-item>
                    </template>
                    <template v-if="services.reportmaterial.items.publication_ssc">
                        <q-separator/>
                        <q-item @click="router.visit($route('publication.ssc'))"
                                clickable v-close-popup>
                            <q-item-section class="q-pl-md">
                                {{ $translate("menu.publication.ssc") }}
                            </q-item-section>
                        </q-item>
                    </template>
                    <template v-if="services.reportmaterial.items.publication_add">
                        <q-separator/>
                        <q-item @click="router.visit($route('publication.add'))"
                                clickable v-close-popup>
                            <q-item-section side class="q-pr-xs">
                                <q-icon name="o_add" size="xs" color="purple-5"/>
                            </q-item-section>
                            <q-item-section class="q-pl-none text-purple-5" no-wrap>
                                {{ $translate("menu.publication.add") }}
                            </q-item-section>
                        </q-item>
                    </template>
                </template>
            </NavigationItem>
        </q-list>
    </div>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import DepartmentSelector from "@/Components/Navigation/DepartmentSelector.vue";
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";

defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const services = computed(() => usePage().props.services);

const researchNumber = ref(null)
const researchGo = () => {
    router.post($route("researches.go"), {
        number: researchNumber.value
    }, {
        onSuccess: () => {
            researchNumber.value = null
        }
    });
}
</script>