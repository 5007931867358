<template>
    <h5 class="q-my-none">
        {{ $translate('research-report.expenses.title') }}
    </h5>
    <table class="q-table page-break q-table--horizontal-separator">
        <tbody>
        <tr class="text-bold">
            <td class="w-30">{{ $translate('research-report.expenses.project.number') }}:</td>
            <td>#{{ research.project.number }}</td>
        </tr>
        <tr>
            <td class="w-30">{{ $translate('research-report.expenses.project.name') }}:</td>
            <td>{{ research.project.name }}</td>
        </tr>
        <tr>
            <td class="w-30">{{ $translate('research-report.expenses.project.leader') }}:</td>
            <td>{{ research.project.leader.fulled_name }}</td>
        </tr>
        <tr class="text-bold">
            <td class="w-30">{{ $translate('research-report.expenses.research.number') }}:</td>
            <td>#{{ research.number }}</td>
        </tr>
        <tr>
            <td class="w-30">{{ $translate('research-report.expenses.research.name') }}:</td>
            <td>{{ research.template.name }}</td>
        </tr>
        <tr>
            <td class="w-30">{{ $translate('research-report.expenses.research.responsible') }}:</td>
            <td>{{ research.responsible.fulled_name }}</td>
        </tr>
        <tr class="text-bold">
            <td class="w-30">{{ $translate('research-report.expenses.total-cost') }}:</td>
            <td>{{ helpers.formatMoney(research.cost) }}</td>
        </tr>
        </tbody>
    </table>
    <h6 class="q-mb-none">{{ $translate('research-report.expenses.expenses-equipment') }}</h6>
    <table class="q-table table-bordered page-break q-table--horizontal-separator">
        <thead>
        <tr>
            <th class="text-left">{{ $translate('research-report.expenses.table.equipment') }}</th>
            <th class="w-20 text-center">{{ $translate('research-report.expenses.table.time') }}</th>
            <th class="w-20 text-center">{{ $translate('research-report.expenses.table.cost') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(items, id) in expenses_equipment">
            <td class="text-left">{{ _.get(items[0], 'equipment.display_name') }}</td>
            <td class="w-20 text-center">{{ helpers.int2time(_.sumBy(items, 'used_time')) }}</td>
            <td class="w-20 text-right">{{ helpers.formatMoney(_.sumBy(items, 'cost')) }}</td>
        </tr>
        </tbody>
    </table>

    <h6 class="q-mb-none">{{ $translate('research-report.expenses.expenses-consumables') }}</h6>
    <table class="q-table table-bordered page-break q-table--horizontal-separator">
        <thead>
        <tr>
            <th class="text-left">{{ $translate('research-report.expenses.table.consumable') }}</th>
            <th class="w-20 text-center">{{ $translate('research-report.expenses.table.count') }}</th>
            <th class="w-20 text-center">{{ $translate('research-report.expenses.table.cost') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(items, id) in expenses_consumables">
            <td class="">{{ _.get(items[0], 'consumable.name') }}</td>
            <td class="w-20 text-center">{{ helpers.formatNumber(_.sumBy(items, 'used_count')) }}
                {{ _.get(items[0], 'consumable.unit.name') }}
            </td>
            <td class="w-20 text-right">{{ helpers.formatMoney(_.sumBy(items, 'cost')) }}</td>
        </tr>
        </tbody>
    </table>

    <h6 class="q-mb-none">{{ $translate('research-report.expenses.expenses-worktime') }}</h6>
    <table class="q-table table-bordered page-break q-table--horizontal-separator">
        <thead>
        <tr>
            <th class="text-left">{{ $translate('research-report.expenses.table.user') }}</th>
            <th class="w-20 text-center">{{ $translate('research-report.expenses.table.time') }}</th>
            <th class="w-20 text-center">{{ $translate('research-report.expenses.table.cost') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(items,id) in expenses_worktime">
            <td class="">{{ _.get(items[0], 'user.fulled_name') }}</td>
            <td class="w-20 text-center">{{ helpers.int2time(_.sumBy(items, 'used_time')) }}</td>
            <td class="w-20 text-right">{{ helpers.formatMoney(_.sumBy(items, 'cost')) }}</td>
        </tr>
        </tbody>
    </table>

</template>

<script setup>
import Print from "@/Layout/Print.vue";
import {getCurrentInstance} from "vue";
import helpers from "@/helpers";
import _ from "lodash";

defineOptions({layout: Print});

const props = defineProps({
    research: Object,
    expenses_equipment: Array,
    expenses_consumables: Array,
    expenses_worktime: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

</script>