<template>
    <q-select v-model="form.collection_type_id"
              :label="fieldDescription('collection_type_id')"
              :error="form.errors.hasOwnProperty('collection_type_id')"
              :error-message="_.get(form.errors, 'collection_type_id')"
              :options="types"
              options-dense
              map-options
              emit-value
    />
    <q-select v-model="form.collection_part_id"
              :label="fieldDescription('collection_part_id')"
              :error="form.errors.hasOwnProperty('collection_part_id')"
              :error-message="_.get(form.errors, 'collection_part_id')"
              :options="parts"
              options-dense
              map-options
              emit-value
    />
    <q-input v-model="form.number"
             :label="fieldDescription('number')"
             :error="form.errors.hasOwnProperty('number')"
             :error-message="_.get(form.errors, 'number')"
    />
    <q-input v-model="form.class" :label="fieldDescription('class')" />
    <q-input v-model="form.family" :label="fieldDescription('family')" />
    <q-input v-model="form.genus" :label="fieldDescription('genus')" />
    <q-input v-model="form.species" :label="fieldDescription('species')" />
    <q-input v-model="form.strain" :label="fieldDescription('strain')" />
    <q-input v-model="form.syngen" :label="fieldDescription('syngen')" />
    <q-input v-model="form.coupling" :label="fieldDescription('coupling')" />
    <q-input v-model="form.serotype" :label="fieldDescription('serotype')" />
    <q-input v-model="form.synonym" :label="fieldDescription('synonym')" />
    <q-input v-model="form.host" :label="fieldDescription('host')" />
    <q-input v-model="form.from_collection" :label="fieldDescription('from_collection')" />
    <q-input v-model="form.country" :label="fieldDescription('country')" />
    <q-input v-model="form.city" :label="fieldDescription('city')" />
    <q-input v-model="form.ecotype" :label="fieldDescription('ecotype')" />
    <q-input v-model="form.person" :label="fieldDescription('person')" />
    <q-input v-model.number="form.year" :label="fieldDescription('year')" type="number" />
    <q-input v-model="form.symbiont" :label="fieldDescription('symbiont')" />
    <q-input v-model="form.media" :label="fieldDescription('media')" />
    <q-input v-model="form.condition" :label="fieldDescription('condition')" />
    <q-input v-model="form.sequence" :label="fieldDescription('sequence')" />
    <q-input v-model="form.genome" :label="fieldDescription('genome')" />
    <q-input v-model="form.publication"
             :label="fieldDescription('publication')"
             type="textarea"
             rows="2"
    />
    <q-input v-model="form.comment"
             :label="fieldDescription('comment')"
             type="textarea"
             rows="2"
    />
    <q-input v-model="form.add" :label="fieldDescription('add')" />
    <q-file v-model="form.file" :label="fieldDescription('file')">
        <template v-slot:prepend>
            <q-icon name="attach_file" />
        </template>
    </q-file>
    <div v-if="element && element.hasOwnProperty('oldFile')" class="q-my-md">
        <img :src="element.oldFile.url" :alt="element.oldFile.name" />
    </div>
    <q-toggle v-model="form.public_state" :label="publicState" :true-value="false" :false-value="true" />
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import _ from "lodash";

/**
 * @param InertiaForm form
 */
const form = defineModel({type: Object});
const props = defineProps({
    types: Array,
    parts: Array,
    element: Object,
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const publicState = computed(() => {
    let keys = {true: 'shown', false: 'hidden'};
    return $translate('module-microcollections.element.form.fields.public_state.' + keys[form.value.public_state]);
});

const fieldDescription = (field) => $translate('module-microcollections.element.form.fields.' + field);
</script>
