<template>
    <q-item>
        <q-item-section side>
            <DataTableActions>
                <q-item clickable
                        @click="detailDialog = true"
                        v-close-popup>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('buttons.detail') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="o_pageview" size="xs"/>
                    </q-item-section>
                </q-item>
                <q-item clickable v-if="competence.permission.edit"
                        @click="editDialog = true"
                        v-close-popup>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('buttons.edit') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="o_edit" size="xs"/>
                    </q-item-section>
                </q-item>
                <q-item clickable v-if="competence.permission.apply"
                        @click="editDialog = true"
                        v-close-popup>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('buttons.to-approve') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="o_check" size="xs"/>
                    </q-item-section>
                </q-item>
                <q-item clickable v-if="competence.permission.approve"
                        @click="editDialog = true"
                        v-close-popup>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('buttons.approve') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="o_check" size="xs"/>
                    </q-item-section>
                </q-item>
                <q-item clickable v-if="competence.permission.delete"
                        @click="deleteItem()"
                        v-close-popup>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('buttons.delete') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="o_delete" size="xs"/>
                    </q-item-section>
                </q-item>
            </DataTableActions>
        </q-item-section>
        <q-item-section>
            <q-item-label>
                {{ competence.name }}
            </q-item-label>
        </q-item-section>
        <q-item-section :title="$translate('user-competence.label.equipment')">
            <q-item-label class="flex items-center">
                <q-img :src="_.get(competence, 'equipment.photo')" height="1rem" width="1rem"/>
                <span>{{ _.get(competence, 'equipment.name') }}</span>
            </q-item-label>
        </q-item-section>
        <q-item-section :title="$translate('user-competence.label.user_skill')">
            <q-item-label class="flex items-center">
                <span>{{ _.get(competence, 'user_skill.name') }}</span>
            </q-item-label>
        </q-item-section>
    </q-item>

    <q-dialog v-model="editDialog" no-backdrop-dismiss>
        <Card :title="$translate('user-skill.title.edit')"
              in-modal show-close-button header-toolbar>
            <UserCompetenceForm v-model="editForm"/>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="submitEditForm"
                />
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="detailDialog" no-backdrop-dismiss>
        <Card :title="competence.name"
              in-modal show-close-button header-toolbar hide-actions>
            <q-field :label="$translate('user-competence.label.name')" tag="div" stack-label borderless>
                <template v-slot:control>
                    <div class="full-width">
                        {{ competence.name }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('user-competence.label.description')" tag="div" stack-label borderless>
                <template v-slot:control>
                    <div class="full-width" v-html="competence.description"></div>
                </template>
            </q-field>
            <q-field :label="$translate('user-competence.label.equipment')" tag="div" stack-label borderless>
                <template v-slot:control>
                    <div class="full-width">
                        {{ _.get(competence,'equipment.name') }}
                    </div>
                </template>
            </q-field>
        </Card>
    </q-dialog>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {useQuasar} from 'quasar'
import _ from "lodash";
import DataTableActions from "@/Components/DataTableActions.vue";
import UserCompetenceForm from "@/Components/User/Competence/UserCompetenceForm.vue";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;
const $q = useQuasar()

interface Props {
    competence: UserCompetence
}

const props = defineProps<Props>()

const editDialog = ref(false)
const detailDialog = ref(false)

const editForm = useForm({...props.competence})

const submitEditForm = () => {
    editForm.post($route('profile.competence.update', props.competence), {
        onSuccess: () => {
            editDialog.value = false
            router.reload()
        }
    })
}

const deleteItem = () => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: props.competence.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete($route('profile.competence.delete', props.competence), {
            onSuccess: () => {
                router.reload()
            }
        })
    })
}
</script>