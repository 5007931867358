<template>
    <q-card-section>
        <q-input v-model="form.code"
                 :label="$translate('equipment.category.fields.code')"
                 dense counter maxlength="60"
                 :mask="'a'.repeat(60)"
                 :error-message="form.errors['code']"
                 :error="form.errors.hasOwnProperty('code')"/>
    </q-card-section>
    <q-card-section>
        <template v-for="(lang_name,locale) in $translate('fields.locale')">
            <q-input v-model="form.name_i18n[locale]"
                     :label="$translate('equipment.category.fields.name') +' '+ lang_name"
                     dense counter
                     :error-message="form.errors['name_i18n.'+locale]"
                     :error="form.errors.hasOwnProperty('name_i18n.'+locale)"/>
        </template>
    </q-card-section>
</template>

<script setup>
import {getCurrentInstance} from "vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const form = defineModel({
    code: null,
    name_i18n: {}
})
</script>