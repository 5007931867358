<template>
    <Card :title="$translate('equipment.item.title.add')"
          title-bg="deep-purple-1"
          header-toolbar no-card-section>

        <template v-slot:headerActions>
            <q-btn :label="$translate('buttons.back')"
                   icon="navigate_before"
                   color="deep-purple-3"
                   dense
                   @click="router.get($route('equipment.list'))"/>
        </template>

        <EquipmentForm v-model="equipment"/>

        <template v-slot:actions>
            <q-btn color="positive"
                   icon="save"
                   :label="$translate('buttons.add')"
                   @click="submitForm"/>
        </template>
    </Card>
</template>

<script setup>
import {router, useForm} from "@inertiajs/vue3";
import {getCurrentInstance, ref} from "vue";
import EquipmentForm from "@/Components/Equipment/EquipmentForm.vue";
import {Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const tabs = ref('common');

const equipment = useForm({
    equipment_type: null,
    equipment_catalogue_id: null,
    display_name: null,
    photo: null,
    purpose: null,
    multi_assay: null,
    unique: false,
    public: {
        photo: null,
        ckp: false,
        external: false,
        description: null,
        technical: null,
        images: []
    },
    fstec: false,
    fstec_data: {
        request: null,
        response: null,
        recommendation: null
    },
    ssc_id: null,
    placement_address: null,
    placement_square: null,
    equipment_square: null,
    tags: [],
    document_name: null,
    inventory_number: null,
    serial_number: null,
    year_created: null,
    year_placement: null,
    lifetime: null,
    initial_cost: null,
    depreciation_real: null,

    dopinfo: null,

    documents: [],
    identifications: [],
    classifications: [],
    consumables: [],

    complex: []
})

const submitForm = () => {
    equipment
        .transform((data) => ({
                ...data,
            })
        )
        .post(route('equipment.store'))
}
</script>