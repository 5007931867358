<template>
    <q-select v-model="reportType"
              :options="optionsReportType"
              @update:modelValue="selectReportType"
              dense
              :error="publication.errors.hasOwnProperty('report_type_id')"
              :error-message="_.get(publication.errors,'report_type_id')"/>

    <section v-if="reportType" class="q-mt-md">
        <div class="row q-gutter-x-md">
            <div class="col q-gutter-y-md">
                <BlockCommon :fields="_.get(reportTypeDataFields,'common')"
                             v-model:publication="publication"
                             v-model:errors="publication.errors"/>
                <component :is="reportBlock('ObjectVisibility')"
                           :fields="_.get(reportTypeDataFields,'visibility')"
                           v-model:visibility="publication.visibility"
                           v-model:errors="publication.errors"/>
            </div>
            <div class="col q-gutter-y-md">
                <component :is="reportBlock('Authors')"
                           :fields="_.get(reportTypeDataFields,'authors')"
                           v-model:members="publication.authors"
                           v-model:errors="publication.errors"/>

                <component :is="reportBlock('Authors')"
                           :fields="_.get(reportTypeDataFields,'responsible')"
                           v-model:members="publication.responsible"
                           v-model:errors="publication.errors"/>

                <component :is="reportBlock('Files')"
                           :fields="_.get(reportTypeDataFields,'files')"
                           v-model:documents="publication.documents"
                           v-model:links="publication.links"
                           v-model:errors="publication.errors"/>

                <component :is="reportBlock('ExternalID')"
                           :fields="_.get(reportTypeDataFields,'externalid')"
                           v-model:items="publication.externalid"
                           v-model:errors="publication.errors"/>

                <component :is="reportBlock('Related')"
                           :fields="_.get(reportTypeDataFields,'related')"
                           v-model:related="publication.related"
                           v-model:errors="publication.errors"/>

                <component :is="reportBlock('Rubrics')"
                           :fields="_.get(reportTypeDataFields,'rubrics')"
                           v-model:publication="publication"
                           v-model:errors="publication.errors"/>
            </div>
        </div>
    </section>
    <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary"/>
    </q-inner-loading>
</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref} from "vue";
import _ from "lodash";
import axios from "axios";
import {Qualifications as BlockCommon} from "@/Components/ReportMaterialForm/Block/Common";
import * as ReportMaterialBlock from "@/Components/ReportMaterialForm/Block";

const reportBlock = (block) => ReportMaterialBlock[block];

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const defaultPublication = {
    lang_id: null,
    name: null,
    description: null,
    date: null,
    common_fields: {},
    contragent_id: null,
    sponsors: [],
    externalid: [],
    authors: [],
    responsible: [],
    tags: [],
    rubrics: [],
    documents: [],
    links: [],
    visibility: null,
    related: {}
}

const publication = defineModel({
    type: Object,
})

const loading = ref(false)
const optionsReportType = ref([])
const reportType = ref(null)
const reportTypeData = ref(null)

const reportTypeDataFields = computed(() => {
    return _.get(reportTypeData.value, 'fields')
})
const loadReportType = () => {
    loading.value = true;
    axios
        .get(route('ajax.report.type', {
                m: 'loadOptions',
                category: 'qualifications'
            })
        )
        .then((response) => {
            optionsReportType.value = response.data.results;
        })
        .finally(() => {
            if (publication.value.report_type_id) {
                reportType.value = _.find(optionsReportType.value, {value: publication.value.report_type_id})
                selectReportType(reportType.value)
            } else {
                loading.value = false;
            }
        })
}

const selectReportType = (value) => {
    axios
        .get(route('ajax.report.type', {
                m: 'record',
                id: value.value
            })
        )
        .then((response) => {
            reportTypeData.value = response.data.results;
            _.set(publication.value, 'report_type_id', value.value)
        })
        .then(() => {
            _.forEach(defaultPublication, (v, k) => {
                _.set(publication.value, k, publication.value[k] ?? v)
            })
            _.forEach(_.get(reportTypeDataFields.value, 'related.entity', []), (entity) => {
                _.set(publication.value.related, entity, [])
            })
        })
        .finally(() => {
            loading.value = false;
        })
}

onBeforeMount(() => {
    loadReportType()
})
</script>