<template>

    <Alert variant="info">Выберите блоки требующиеся для данного ОМ</Alert>

    <component :is="reportBlock('CommonEdition')" v-model="fields.common"/>
    <component :is="reportBlock('Authors')" v-model="fields.authors"/>
    <component :is="reportBlock('Responsible')" v-model="fields.responsible"/>
    <component :is="reportBlock('Files')" v-model="fields.files"/>
    <component :is="reportBlock('Rubrics')" v-model="fields.rubrics"/>
    <component :is="reportBlock('ExternalID')" v-model="fields.externalid"/>

</template>

<script setup>
import {getCurrentInstance, onMounted} from "vue";

import {Alert} from "@/Components/Block";
import * as Block from './Block';
import _ from "lodash";

const reportBlock = (block) => Block[block]

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const fields = defineModel({
    type: Object,
})

onMounted(() => {
    if (!fields.value.hasOwnProperty('common')) {
        _.set(fields.value, 'common', {
            name: 'Общая информация',
            show: false,
            required: false,
            fields: {
                name: {
                    label: 'Наименование',
                    show: true,
                    required: true,
                },
                description: {
                    label: 'Описание',
                    show: true,
                    required: false,
                },
                issn: {
                    label: 'ISSN',
                    show: true,
                    required: false,
                },
                publisher: {
                    label: 'Издатель',
                    show: true,
                    required: false,
                },
                countrys: {
                    label: 'Страна(ы)',
                    show: true,
                    required: false,
                },
            },
            fields_additional: []
        })
    }
    if (!fields.value.hasOwnProperty('authors')) {
        _.set(fields.value, 'authors', {
            name: 'Авторы',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('responsible')) {
        _.set(fields.value, 'responsible', {
            name: 'Ответственные за издание',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('files')) {
        _.set(fields.value, 'files', {
            name: 'Документы и ссылки',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('rubrics')) {
        _.set(fields.value, 'rubrics', {
            name: 'Ключевые слова, рубрикаторы и классификаторы',
            show: false,
            required: false,
            keywords: {
                label: 'Ключевые слова',
                show: true,
                required: false,
            },
            fields: []
        })
    }
    if (!fields.value.hasOwnProperty('externalid')) {
        _.set(fields.value, 'externalid', {
            name: 'Внешние ID',
            show: false,
            required: false,
        })
    }
})
</script>