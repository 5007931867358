<template>
    <div>
        <div class="q-pb-sm">
            За период
            {{ $translate('buttons.from') }} <b>{{ reportData.from }}</b>
            {{ $translate('buttons.to') }} <b>{{ reportData.to }}</b>
            <span v-if="moment(reportData.to).isAfter(moment(reportData.created))">
                ({{ reportData.created }})
            </span>
        </div>
        <div class="q-pb-sm">
            Подразделение: <b>{{ reportData.ssc }}</b>
        </div>
        <div>
            <small>{{ $translate('module-analytics.fields.created') }} {{ reportData.created }}</small>
        </div>
    </div>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import moment from "moment";

const props = defineProps({
    reportData: {
        type: Object,
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
