<template>
    <Card :title="$translate('users.manage.cadre-departments.title')"
          title-bg="cyan-14"
          title-text="white"
          no-card-section
          hide-actions
    >

    </Card>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import {Card} from "@/Components/Block";

const props = defineProps({
    userId: {
        type: Number,
        required: true
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
