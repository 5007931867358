<template>
    <div>
        <q-select v-if="withSsc"
                  v-model="sscModel"
                  :label="$translate('module-analytics.fields.ssc')"
                  :options="getOptions()" options-dense
        />
        <div class="row q-gutter-x-md">
            <q-input class='col' v-if="withDates" v-model="dateFromModel" type="date"
                     :prefix="$translate('buttons.from')"/>

            <q-input class='col' v-if="withDates" v-model="dateToModel" type="date"
                     :prefix="$translate('buttons.to')"/>
        </div>

        <q-select v-if="withTags"
                  v-model="tagsModel"
                  :label="$translate('module-analytics.fields.tags-primary')"
                  :options="tagsList"
                  new-value-mode="add"
                  input-debounce="0"
                  hide-dropdown-icon
                  use-chips
                  use-input
                  multiple
                          />
        <q-select v-if="withAdditionalTags"
                  v-model="tagsAdditionalModel"
                  :label="$translate('module-analytics.fields.tags-additional')"
                  :options="tagsList"
                  new-value-mode="add"
                  input-debounce="0"
                  hide-dropdown-icon
                  use-chips
                  use-input
                  multiple

        />
    </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref, watch} from "vue";
import _ from "lodash";
import {store} from "@/store/globalstore";

const props = defineProps({
    ssc: {
        type: Number,
        required: false,
    },
    from: {
        type: String,
        required: false,
    },
    to: {
        type: String,
        required: false,
    },
    sscList: {
        type: Array,
        required: false,
    },
    tagsList: {
        type: Array,
        required: false,
        default: [],
    },
    withSsc: {
        type: Boolean,
        required: false,
        default: false,
    },
    withDates: {
        type: Boolean,
        required: false,
        default: false,
    },
    withTags: {
        type: Boolean,
        required: false,
        default: false,
    },
    withAdditionalTags: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const emit = defineEmits(['receiveForm']);

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const sscModel = ref(null);
const dateFromModel = ref(null);
const dateToModel = ref(null);
const tagsModel = ref([]);
const tagsAdditionalModel = ref([]);

const getOptions = () => {
    let options = [{
        label: $translate('ssc.allselector'),
        value: ''
    }];
    _.forEach(props.sscList, (value) => {
        options.push({
            label: value.name,
            value: value.id
        });
    });
    return options;
};

const receiveFormData = () => {
    let form = {};
    if (props.withSsc) {
        form['ssc'] = sscModel.value.value;
    }
    if (props.withDates) {
        form['from'] = dateFromModel.value;
        form['to'] = dateToModel.value;
    }
    if (props.withTags) {
        form['tags'] = tagsModel.value;
    }
    if (props.withAdditionalTags) {
        form['tags_additional'] = tagsModel.value;
    }
    emit('receiveForm', form);
};

watch(store, () => {
    if (true === store.state) {
        receiveFormData();
    }
});

onMounted(() => {
    if (props.ssc) {
        sscModel.value = props.ssc;
    } else {
        sscModel.value = {
            label: $translate('ssc.allselector'),
            value: ''
        };
    }
    if (props.from) {
        dateFromModel.value = props.from;
    }
    if (props.to) {
        dateToModel.value = props.to;
    }
});
</script>
