<template>
    <Layout>
        <Card title-bg="cyan-14"
              header-toolbar no-card-section hide-actions>
            <template v-slot:title>
                <q-toolbar-title class="text-white">
                    <UserAvatar :avatar="user.avatar" size="40px"/>
                    <span class="q-ml-md">{{ user.fulled_name }}</span>
                </q-toolbar-title>
            </template>
            <q-tabs v-model="tab"
                    align="left"
                    indicator-color="cyan-14"
                    narrow-indicator
                    dense
            >
                <q-tab name="main" v-if="tabs.main"
                       :label="$translate('users.manage.tabs.main-data')"/>
                <q-tab name="service" v-if="tabs.service"
                       :label="$translate('users.manage.tabs.service', {'service': serviceRimsName})"/>
                <q-tab name="departments" v-if="tabs.departments"
                       :label="$translate('users.manage.tabs.ssc')"/>
                <q-space/>
                <q-tab name="system" v-if="tabs.system"
                       :label="$translate('users.manage.tabs.system')"/>
            </q-tabs>
            <q-tab-panels v-model="tab" animated>
                <q-tab-panel name="main" v-if="tabs.main">
                    <div class="row items-start q-gutter-md">
                        <component :is="userCard('MainData')" v-if="tabs.main_info"
                                   :user="user"
                                   class="col"/>
                        <component :is="userCard('Roles')" v-if="tabs.main_roles"
                                   :user-id="user.user_id"
                                   class="col"/>
                    </div>
                </q-tab-panel>
                <q-tab-panel name="service" v-if="tabs.service">
                    <div class="row items-start q-gutter-md">
                        <component :is="userCard('Projects')"
                                   :user-id="user.user_id"
                                   class="col"/>
                        <component :is="userCard('Researches')"
                                   :user-id="user.user_id"
                                   class="col"/>
                        <component :is="userCard('ReportMaterials')"
                                   :user-id="user.user_id"
                                   class="col"/>
                    </div>
                </q-tab-panel>
                <q-tab-panel name="departments" v-if="tabs.departments">
                    <div class="row items-start q-gutter-md">
                        <component :is="userCard('Ssc')"
                                   :user-id="user.user_id"
                                   class="col"/>
                        <component :is="userCard('CadreDepartments')"
                                   :user-id="user.user_id"
                                   class="col"/>
                    </div>
                </q-tab-panel>
                <q-tab-panel name="system" v-if="tabs.system">
                    <div class="row justify-between q-gutter-y-md q-gutter-md-x-md"
                         :class="{'column': $q.screen.lt.md}">
                        <component :is="userCard('System')"
                                   :user-id="user.user_id"
                                   class="col col-md-12"/>
                        <component :is="userCard('LastLogs')"
                                   :user-id="user.user_id"
                                   class="col col-md-6"/>
                    </div>
                </q-tab-panel>
            </q-tab-panels>
        </Card>
    </Layout>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {usePage} from "@inertiajs/vue3";
import Layout from "@/Pages/Profile/Layout.vue";
import {Card} from "@/Components/Block";
import UserAvatar from "@/Components/UserAvatar.vue";
import * as UserCards from "@/Components/User/Card";
import {useQuasar} from "quasar";

const userCard = (block) => UserCards[block]

const props = defineProps({
    user: Object,
    tabs: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const $q = useQuasar();

const page = usePage();
const serviceRimsName = computed(() => page.props.services.rims.name);

const tab = ref("main");
</script>