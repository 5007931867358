<template>
    <q-btn icon="add"
           :label="$translate('buttons.add')"
           dense
           color="grey-3"
           text-color="dark"
           no-caps
           v-if="equipment.permission.edit"
           @click="ScheduleModal = true"/>
    <span v-else></span>
    <q-dialog v-model="ScheduleModal" no-backdrop-dismiss>
        <Card style="width: 700px;max-width: 90vw" show-close-button header-toolbar
              :title="$translate('equipment.item.title.work-common')">
            <q-banner class="bg-blue-4 text-white">
                Укажите планируемую загрузку оборудования на календарный год с указанной даты
            </q-banner>
            <q-item>
                <q-item-section>
                    <div class="row q-gutter-x-md">
                        <q-input type="date" class="col" :prefix="$translate('equipment.item.fields.work-start')"
                                 v-model="ScheduleForm.start"
                                 input-class="text-center"
                                 :error-message="ScheduleForm.errors['start']"
                                 :error="ScheduleForm.errors.hasOwnProperty('start')"/>
                    </div>
                </q-item-section>
            </q-item>

            <q-item>
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.schedule.planned.rejim') }}
                    </q-item-label>
                    <div class="row column">
                        <q-radio size="sm" v-model="ScheduleForm.rejim" val="preset"
                                 :label="$translate('equipment.item.fields.worktime_preset')"/>
                        <q-radio size="sm" v-model="ScheduleForm.rejim" val="week"
                                 :label="$translate('equipment.item.fields.worktime_day')"/>
                        <q-radio size="sm" v-model="ScheduleForm.rejim" val="period"
                                 :label="$translate('equipment.item.fields.worktime_year')"/>
                    </div>
                    <div v-if="ScheduleForm.errors.hasOwnProperty('rejim')" class="text-negative">
                        {{ ScheduleForm.errors['rejim'] }}
                    </div>
                </q-item-section>
            </q-item>
            <q-item v-show="ScheduleForm.rejim === 'preset'">
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.item.fields.worktime_preset') }}
                    </q-item-label>
                    <q-select v-model="ScheduleForm.preset" :options="presetOptions"
                              emit-value map-options options-dense
                              @update:model-value="distributePresetToWeek"
                              :error-message="ScheduleForm.errors['preset']"
                              :error="ScheduleForm.errors.hasOwnProperty('preset')">
                        <template v-slot:option="scope">
                            <q-item v-bind="scope.itemProps">
                                <q-item-section>
                                    <q-item-label v-html="scope.opt.label"></q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                        <template v-slot:selected-item="scope">
                            <q-item v-bind="scope.itemProps">
                                <q-item-section>
                                    <q-item-label v-html="scope.opt.label"></q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-select>
                </q-item-section>
            </q-item>
            <q-item v-show="ScheduleForm.rejim === 'week'">
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.item.fields.worktime_day') }}
                    </q-item-label>
                    <div class="row q-gutter-x-sm">
                        <template v-for="(col) in $translate('fields.weekdays')">
                            <q-input v-model="ScheduleForm.worktime_day[col.day]"
                                     :label="col.label" class="col"
                                     @update:model-value="calculateWorktimePeriod"
                                     input-class="text-center"
                                     type="number" min="0" max="24"/>
                        </template>
                    </div>
                    <div v-if="ScheduleForm.errors.hasOwnProperty('worktime_day')" class="text-negative">
                        {{ ScheduleForm.errors['worktime_day'] }}
                    </div>
                </q-item-section>
            </q-item>
            <q-item v-show="ScheduleForm.rejim === 'period'">
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.item.fields.worktime_year') }}
                    </q-item-label>
                    <q-input v-model="ScheduleForm.worktime_year"
                             input-class="text-center" @update:model-value="distributePeriodToWeek"
                             :hint="'плановый режим работы' + ' (~'+ScheduleForm.perDay+' в день)'"
                             type="number" min="0" max="8760"

                             :error-message="ScheduleForm.errors['worktime_period']"
                             :error="ScheduleForm.errors.hasOwnProperty('worktime_period')"/>
                </q-item-section>
            </q-item>
            <q-item>
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.item.fields.cost-usage') }}
                    </q-item-label>
                    <div class="row column">
                        <q-radio size="sm" v-model="ScheduleForm.cost_usage" :val="false">
                            <span>{{ $translate('equipment.item.fields.depreciation_real') }}</span>
                            <span>
                                - {{ Number(equipment.depreciation_real).toFixed(2) }} {{
                                    $translate('units.rub-hour')
                                }}
                            </span>
                            <span v-if="equipment.equipment_type==='complex'" class="text-grey">
                                (сумма амортизации составляющих комплекса)
                            </span>

                        </q-radio>
                        <q-radio size="sm" v-model="ScheduleForm.cost_usage" :val="true" :disable="depreciationCost">
                            <span>{{ $translate('equipment.item.fields.depreciation') }}</span>
                            <span>
                                - {{ Number(ScheduleForm.depreciation).toFixed(2) }} {{ $translate('units.rub-hour') }}
                            </span>
                            <span v-if="equipment.equipment_type==='complex'" class="text-grey">
                                (сумма стоимости приборочаса составляющих комплекса)
                            </span>
                        </q-radio>
                        <div v-if="depreciationCost" class="text-negative">
                            {{ $translate('equipment.item.error.no_worktime_year') }}
                        </div>
                    </div>
                    <div v-if="ScheduleForm.errors.hasOwnProperty('cost_usage')" class="text-negative">
                        {{ ScheduleForm.errors['cost_usage'] }}
                    </div>
                </q-item-section>
            </q-item>
            <q-item>
                <q-item-section>
                    <q-checkbox v-model="ScheduleForm.worktime_self">
                        {{ $translate('equipment.item.fields.self_work') }}
                    </q-checkbox>
                    <q-checkbox v-model="ScheduleForm.worktime_self_week">
                        {{ $translate('equipment.item.fields.week-work-without-operator') }}
                    </q-checkbox>
                </q-item-section>
            </q-item>
            <q-item>
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.item.fields.confirm_document') }}
                    </q-item-label>
                    <q-file v-model="ScheduleForm.confirm_document"
                            :error-message="ScheduleForm.errors['confirm_document']"
                            :error="ScheduleForm.errors.hasOwnProperty('confirm_document')">
                        <template v-slot:append>
                            <q-icon name="attach_file"/>
                        </template>
                    </q-file>
                </q-item-section>
            </q-item>
            <template v-slot:actions>
                <q-btn color="positive"
                       icon="save"
                       :label="$translate('buttons.add')"
                       @click="ScheduleFormSubmit"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount, reactive, ref, watch} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import axios from "axios";
import _, {ceil} from "lodash"
import Card from "@/Components/Block/Card.vue";
import {Cookies, Notify, useQuasar} from 'quasar'
import moment from "moment";
import EquipmentSchedulePlannedForm from "@/Components/Equipment/EquipmentSchedulePlannedForm.vue";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    equipment: {
        type: Object
    },
})

const ScheduleModal = ref(false)
const ScheduleForm = useForm({
    start: null,
    rejim: null,
    preset: null,
    confirm_document: null,
    worktime_day: {
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
    },
    worktime_year: null,
    perDay: 0,
    cost_usage: false,
    depreciation: 0,
    worktime_self: false,
    worktime_self_week: false,
})
const ScheduleFormSubmit = () => {
    ScheduleForm
            .post(route('equipment.schedule.common.update', props.equipment), {
                onSuccess: () => {
                    router.reload()
                    ScheduleModal.value = false
                }
            })
}
const presetOptions = computed(() => {
    return _.map($translate('fields.work-time-select'), (label, value) => {
        return {
            value: value,
            label: label
        }
    });
})
const distributePresetToWeek = (val) => {
    let v = val.split("x");
    let hours = parseInt(v[0]), days = parseInt(v[1]);
    let i;
    for (i = 0; i < 7; i++) {
        _.set(ScheduleForm.worktime_day, i, null);
    }
    if (days === 7) {
        for (i = 0; i < 7; i++) {
            _.set(ScheduleForm.worktime_day, i, hours);
        }
    } else {
        for (i = 1; i <= days; i++) {
            _.set(ScheduleForm.worktime_day, i, hours);
        }
    }
    calculateWorktimePeriod()
}
const distributePeriodToWeek = (onPeriod) => {
    if (ScheduleForm.rejim === 'period') {
        let val = (onPeriod / 365).toFixed(0);
        _.set(ScheduleForm, 'perDay', (val > 24 ? 24 : val));
        _.forEach(ScheduleForm.worktime_day, (h, d) => {
            _.set(ScheduleForm.worktime_day, d, ScheduleForm.perDay);
        })
    }
}
const calculateWorktimePeriod = () => {
    let sum = _.reduce(ScheduleForm.worktime_day, function (a, b) {
        return (a) + Number(b);
    }, 0);
    _.set(ScheduleForm, 'worktime_year', (sum * 52.142).toFixed(0));
}
const depreciationRealComplex = () => {
    if (props.equipment.equipment_type === 'complex') {
        ScheduleForm.depreciation_real = _.sumBy(props.equipment.complex, 'depreciation_real');
    }
}
const depreciationComplex = () => {
    if (props.equipment.equipment_type === 'complex') {
        ScheduleForm.depreciation = _.sumBy(props.equipment.complex, 'depreciation');
    }
}

const depreciationCost = computed(() => {
    if (props.equipment.equipment_type === 'complex') {
        depreciationRealComplex();
        depreciationComplex();
        return false;
    } else {
        if (ScheduleForm.worktime_year > 0 && props.equipment.lifetime > 0 && props.equipment.initial_cost > 0) {
            ScheduleForm.depreciation = (props.equipment.initial_cost / props.equipment.lifetime / ScheduleForm.worktime_year).toFixed(2);
            return false;
        }
    }
    ScheduleForm.depreciation = 0;
    return true;
})
</script>