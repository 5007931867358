<script setup>
import {getCurrentInstance, onMounted, ref, computed} from 'vue'
import _ from 'lodash'
import {useQuasar} from "quasar";
import {RIDAnketa, RIDAnketaMember} from "@/entity";
import {Alert} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();

const anketa = defineModel({})

const vkladValidate = computed(() => {
    let res = null;
    let total = _.sumBy(_.map(anketa.value.vklad, (item) => ({count: Number(item.count)})), 'count');
    if (Number(total) < 100) {
        res = 'Сумма вклада всех участников < 100%'
    }
    if (Number(total) > 100) {
        res = 'Сумма вклада всех участников > 100%'
    }
    return res;
})

const formOptions = computed(() => _.map($translate('module-rid.form-type'), (v, k) => ({value: k, label: v})));
const usageOptions = computed(() => _.map($translate('module-rid.usage-type'), (v, k) => ({value: k, label: v})));
</script>

<template>
    <q-card-section>
        <div class="bg-grey-3 q-pa-xs">
            {{ $translate('module-rid.fields.referat-title') }}
        </div>
        <q-input v-model="anketa.name"
                 :label="$translate('module-rid.fields.name')"
                 type="textarea" rows="3"
                 :error="anketa.errors.hasOwnProperty('name')"
                 :error-message="_.get(anketa.errors,'name')"
        />
        <q-input v-model="anketa.annotation"
                 :label="$translate('module-rid.fields.annotation')"
                 type="textarea" rows="3"
                 :error="anketa.errors.hasOwnProperty('annotation')"
                 :error-message="_.get(anketa.errors,'annotation')"
        />
        <q-input v-model="anketa.characters"
                 :label="$translate('module-rid.fields.characters')"
                 type="textarea" rows="3"
                 :error="anketa.errors.hasOwnProperty('characters')"
                 :error-message="_.get(anketa.errors,'characters')"
        />
    </q-card-section>
    <q-card-section>
        <div class="bg-grey-3 q-pa-xs">
            {{ $translate('module-rid.fields.vklad-title') }}
        </div>
        <template v-if="anketa.persons.length===0">
            <Alert variant="danger">{{ $translate('module-rid.errors.empty-members') }}</Alert>
        </template>
        <template v-else>
            <q-list>
                <template v-for="(member, index) in anketa.persons">
                    <q-item>
                        <q-item-section>
                            <q-item-label>
                                {{ member.lastname }}
                                {{ member.name }}
                                {{ member.surname }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>
                                {{ _.join(_.filter([member.department, member.position]), ', ') || '&mdash;' }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section class="col-6">
                            <q-input v-model="anketa.vklad[index].count"
                                     :prefix="$translate('module-rid.fields.vklad-count')"
                                     :suffix="$translate('units.percent')"
                                     input-class="text-center"
                                     dense
                                     :error="anketa.errors.hasOwnProperty('vklad.'+index+'.count')"
                                     :error-message="$translate('Обязательно для заполнения')"
                            />
                        </q-item-section>
                    </q-item>
                    <q-input v-model="anketa.vklad[index].content"
                             :label="$translate('module-rid.fields.vklad-content')"
                             type="textarea" rows="3"
                             dense outlined
                             :error="anketa.errors.hasOwnProperty('vklad.'+index+'.content')"
                             :error-message="$translate('Обязательно для заполнения')"
                    />
                </template>
            </q-list>
            <Alert variant="warning" v-if="vkladValidate">{{ vkladValidate }}</Alert>
        </template>
    </q-card-section>
    <q-card-section>
        <div class="bg-grey-3 q-pa-xs">
            {{ $translate('module-rid.fields.commerce-title') }}
        </div>
        <q-input v-model="anketa.scope"
                 :label="$translate('module-rid.fields.scope')"
                 type="textarea" rows="3"
                 :error="anketa.errors.hasOwnProperty('scope')"
                 :error-message="_.get(anketa.errors,'scope')"
        />
        <q-input v-model="anketa.short_description"
                 :label="$translate('module-rid.fields.short_description')"
                 :hint="$translate('module-rid.fields.short_description-hint')"
                 type="textarea" rows="3"
                 :error="anketa.errors.hasOwnProperty('short_description')"
                 :error-message="_.get(anketa.errors,'short_description')"
        />
        <q-input v-model="anketa.disclose_information"
                 :label="$translate('module-rid.fields.disclose_information')"
                 :hint="$translate('module-rid.fields.disclose_information-hint')"
                 type="textarea" rows="3"
                 :error="anketa.errors.hasOwnProperty('disclose_information')"
                 :error-message="_.get(anketa.errors,'disclose_information')"
        />
        <q-input v-model="anketa.product"
                 :label="$translate('module-rid.fields.product')"
                 type="textarea" rows="3"
                 :error="anketa.errors.hasOwnProperty('product')"
                 :error-message="_.get(anketa.errors,'product')"
        />
        <q-input v-model="anketa.disadvantages"
                 :label="$translate('module-rid.fields.disadvantages')"
                 :hint="$translate('module-rid.fields.disadvantages-hint')"
                 type="textarea" rows="3"
                 :error="anketa.errors.hasOwnProperty('disadvantages')"
                 :error-message="_.get(anketa.errors,'disadvantages')"
        />
        <q-input v-model="anketa.benefits"
                 :label="$translate('module-rid.fields.benefits')"
                 :hint="$translate('module-rid.fields.benefits-hint')"
                 type="textarea" rows="3"
                 :error="anketa.errors.hasOwnProperty('benefits')"
                 :error-message="_.get(anketa.errors,'benefits')"
        />
        <q-input v-model="anketa.limitations"
                 :label="$translate('module-rid.fields.limitations')"
                 :hint="$translate('module-rid.fields.limitations-hint')"
                 type="textarea" rows="3"
                 :error="anketa.errors.hasOwnProperty('limitations')"
                 :error-message="_.get(anketa.errors,'limitations')"
        />
        <q-input v-model="anketa.prospects"
                 :label="$translate('module-rid.fields.prospects')"
                 :hint="$translate('module-rid.fields.prospects-hint')"
                 type="textarea" rows="3"
                 :error="anketa.errors.hasOwnProperty('prospects')"
                 :error-message="_.get(anketa.errors,'prospects')"
        />
        <q-field borderless class="q-mt-md"
                 :error="anketa.errors.hasOwnProperty('form')"
                 :error-message="_.get(anketa.errors,'form')">
            <template v-slot:control>
                <div class="full-width column">
                    <div>{{ $translate('module-rid.fields.form') }}</div>
                    <q-option-group v-model="anketa.form"
                                    :options="formOptions"
                                    size="xs"/>
                    <q-input v-if="anketa.form==='other'" v-model="anketa.form_other" dense placeholder="Иное"/>
                </div>
            </template>
        </q-field>
        <q-field borderless class="q-mt-md"
                 :error="anketa.errors.hasOwnProperty('usage')"
                 :error-message="_.get(anketa.errors,'usage')">
            <template v-slot:control>
                <div class="full-width column">
                    <div>{{ $translate('module-rid.fields.usage') }}</div>
                    <q-option-group v-model="anketa.usage"
                                    :options="usageOptions"
                                    size="xs"/>
                    <q-input v-if="anketa.usage==='other'" v-model="anketa.usage_other" dense placeholder="Иное"/>
                </div>
            </template>
        </q-field>
    </q-card-section>
</template>