<template>
    <Card title-bg="indigo-1"
          :title="currentSsc>0?$translate('consumables.ssc.title.list', {name: ssc ? _.get(ssc, 'name_short') : ''}):$translate('consumables.ssc.title.all')"
          header-toolbar
          no-card-section
          hide-actions
    >
        <template v-slot:headerActions v-if="currentSsc>0">
            <q-btn v-if="_.get(can, 'addSSC')"
                   :label="$q.screen.lt.md?null:$translate('consumables.ssc.actions.add')"
                   :title="$translate('consumables.ssc.actions.add')"
                   @click="router.get($route('consumables.ssc.add'))"
                   icon="o_add"
                   dense no-caps no-wrap
                   color="indigo-3"
            />
            <q-btn v-if="_.get(can, 'access')"
                   :label="$q.screen.lt.md?null:$translate('menu.сonsumables-access')"
                   :title="$translate('menu.сonsumables-access')"
                   @click="router.get($route('consumables.ssc.access'))"
                   icon="o_admin_panel_settings"
                   dense no-caps no-wrap
                   color="indigo-3"
            />
        </template>
        <DepartmentSelector/>

        <q-table ref="tableRef"
                 row-key="id"
                 :rows="items"
                 :columns="columns"
                 :visible-columns="visibleColumns"
                 :filter="filter"
                 :loading="loading"
                 @request="onRequest"
                 v-model:pagination="pagination"
                 :rows-per-page-options="[10,50,100]"
                 wrap-cells
                 flat
        >
            <template v-slot:top-row>
                <q-tr>
                    <q-td/>
                    <q-td colspan="100%">
                        <q-input v-model="filter" debounce="1000" :placeholder="$translate('search.element')"
                                 borderless dense
                                 clearable clear-icon="clear">
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:header-cell-internal="props">
                <q-th class="text-center">
                    <q-icon name="o_manage_accounts" size="sm" class="cursor-pointer">
                        <q-menu cover anchor="top middle">
                            <q-list>
                                <q-item dense>
                                    <q-item-section>
                                        <q-item-label>
                                            {{ props.col.label }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-btn-group spread>
                                    <q-btn icon="highlight_off" :label="$translate('buttons.no')"
                                           @click="filterBy('internal',false)"
                                           v-close-popup flat/>
                                    <q-btn icon="o_check_circle" :label="$translate('buttons.yes')"
                                           @click="filterBy('internal',true)"
                                           v-close-popup flat/>
                                </q-btn-group>
                            </q-list>
                        </q-menu>
                    </q-icon>
                </q-th>
            </template>
            <template v-slot:header-cell-self_usage="props">
                <q-th class="text-center">
                    <q-icon name="manage_accounts" size="sm" class="cursor-pointer">
                        <q-menu cover anchor="top middle">
                            <q-list>
                                <q-item dense>
                                    <q-item-section>
                                        <q-item-label>
                                            {{ props.col.label }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-btn-group spread>
                                    <q-btn icon="highlight_off" :label="$translate('buttons.no')"
                                           @click="filterBy('self_usage',false)"
                                           v-close-popup flat/>
                                    <q-btn icon="o_check_circle" :label="$translate('buttons.yes')"
                                           @click="filterBy('self_usage',true)"
                                           v-close-popup flat/>
                                </q-btn-group>
                            </q-list>
                        </q-menu>
                    </q-icon>
                </q-th>
            </template>

            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @on-success="onSuccess"
                    ></DataTableActions>
                </q-td>
            </template>
            <template v-slot:body-cell-count="props">
                <q-td :props="props">
                    <div class="row full-width q-gutter-x-sm">
                        <span class="col text-right">{{ props.row.count.toFixed(2) }}</span>
                        <span class="col text-left">{{ props.row.unit }}</span>
                    </div>
                </q-td>
            </template>
            <template v-slot:body-cell-internal="props">
                <q-td :props="props">
                    <q-icon v-if="props.value > 0" name="o_check_circle"/>
                </q-td>
            </template>
            <template v-slot:body-cell-self_usage="props">
                <q-td :props="props">
                    <q-icon v-if="props.value > 0" name="o_check_circle"/>
                </q-td>
            </template>
            <template v-slot:body-cell-name="props">
                <q-td :props="props">
                    <div>{{ props.value }}</div>
                    <q-item-label v-if="currentSsc < 0" caption class="full-width flex no-wrap items-center">
                        <q-icon name="o_business" size="xs"/>
                        <span class="q-ml-xs">{{ props.row.ssc }}</span>
                    </q-item-label>
                </q-td>
            </template>
        </q-table>

    </Card>
</template>

<script setup>

import {getCurrentInstance, onMounted, ref, computed} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Card} from "@/Components/Block";
import DataTableActions from "@/Components/DataTableActions.vue";
import DepartmentSelector from "@/Components/Navigation/DepartmentSelector.vue";
import {useQuasar} from 'quasar'

const props = defineProps({
    ssc: {
        type: Object
    },
    can: {
        type: Object
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const $q = useQuasar()

const currentSsc = computed(() => usePage().props.auth.current_ssc);

const tableRef = ref();
const filter = ref('');
const loading = ref(false);
const items = ref([]);
const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'name', align: 'left', label: $translate('consumables.fields.name'), field: 'name', sortable: true},
    {
        name: 'internal',
        align: 'left',
        label: $translate('consumables.fields.internal'),
        field: 'internal',
        style: 'width: 3rem'
    },
    {
        name: 'self_usage',
        align: 'left',
        label: $translate('consumables.fields.external'),
        field: 'self_usage',
        style: 'width: 3rem'
    },
    {name: 'count', align: 'center', label: $translate('consumables.fields.count'), field: 'count'},
    {name: 'price', align: 'right', label: $translate('consumables.fields.price'), field: 'price'},
];

const visibleColumns = computed(() => [
    'actions',
    'name',
    !$q.screen.lt.md ? 'internal' : null,
    !$q.screen.lt.md ? 'self_usage' : null,
    !$q.screen.lt.md ? 'count' : null,
    !$q.screen.lt.md ? 'price' : null,
]);

const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});

const onRequest = (request) => {
    const {page, rowsPerPage, sortBy, descending} = request.pagination;
    const filter = request.filter;

    loading.value = true;

    let params = {
        m: 'list',
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {},
            search: filter
        }
    };
    if (sortBy) {
        _.set(params.datatable.sort, sortBy, {'field': sortBy, 'direction': descending ? 'asc' : 'desc'});
    }
    axios.get(route('ajax.consumables.ssc'), {
        params: params,
    })
            .then((response) => {
                items.value = response.data.results.data;
                pagination.value.page = response.data.results.current_page;
                pagination.value.rowsPerPage = response.data.results.per_page;
                pagination.value.sortBy = sortBy;
                pagination.value.descending = descending;
                pagination.value.rowsNumber = response.data.results.total;
            })
            .finally(() => {
                loading.value = false;
            });
};

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};

const filterBy = (field, value) => {
    filter.value = '' + field + ':"' + value + '"';
    onSuccess()
}

onMounted(() => {
    onSuccess()
});
</script>
