<template>
    <div>
        <div class="flex items-center cursor-pointer" @click="dialog = true">
            <q-icon name="o_person" size="xs"/>
            <span class="q-ml-xs">{{ user.fulled_name }}</span>
        </div>
        <q-dialog v-model="dialog" no-backdrop-dismiss>
            <Card title="Контактная информация"
                  in-modal show-close-button hide-actions>
                <div v-if="user.avatar" class="float-right q-ml-md q-mb-md">
                    <q-avatar :src="'/storage/'+user.avatar"/>
                </div>
                <q-field :label="$translate('users.fields.fulled_name')" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ user.fulled_name }}
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('users.fields.email')" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ user.email }}
                        </div>
                    </template>
                </q-field>
                <template v-for="(additional) in user.additional">
                    <q-field v-if="additional.public_value"
                             :label="additional.description.name" stack-label>
                        <template v-slot:control>
                            <div class="self-center full-width">
                                {{ additional.value }}
                            </div>
                        </template>
                    </q-field>
                </template>
            </Card>
        </q-dialog>
        <div v-if="printable" class="row">
            <q-field
                :label="$translate('users.fields.email')" stack-label class="q-ml-lg" borderless dense>
                <template v-slot:control>
                    <div class="self-center full-width">
                        {{ user.email }}
                    </div>
                </template>
            </q-field>
            <template
                v-for="(additional) in user.additional">
                <q-field v-if="additional.public_value"
                         :label="additional.description.name" stack-label class="q-ml-lg" borderless dense>
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ additional.value }}
                        </div>
                    </template>
                </q-field>
            </template>
        </div>
    </div>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import {useQuasar} from "quasar";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const $q = useQuasar()

const props = defineProps({
    user: {type: Object, required: true},
    printable: {type: Boolean, default: false}
})
const dialog = ref(false)
</script>