<template>
    <q-toolbar class="bg-grey-2 text-black">
        <q-toolbar-title>Сводная информация</q-toolbar-title>
        <q-space/>
        <BookmarkSelector @onSuccess="onSuccess"/>
    </q-toolbar>
    <section class="q-gutter-y-md">
        <div class="row q-gutter-md wrap">
            <WidgetSimple :count="staff.vacations"
                          :title="$translate('Отпуска') + ' на '+moment().format($translate('common.date.js'))"
                          :description="$translate('сотрудников в отпуске')"
                          :link="$route('cadre.vacation.index')"
            />
            <WidgetSimple :count="staff.sicklists"
                          :title="$translate('Больничные') + ' на '+moment().format($translate('common.date.js'))"
                          :description="$translate('сотрудников на больничном')"
                          :link="$route('cadre.sicklist.index')"
            />
            <WidgetSimple :count="staff.dutyjourney"
                          :title="$translate('Командировки') + ' на '+moment().format($translate('common.date.js'))"
                          :description="$translate('сотрудников в командировке')"
                          :link="$route('cadre.dutyjourney.index')"
            />
        </div>
        <div class="row q-gutter-md wrap">
            <WidgetSimple :count="departments.total"
                          :title="$translate('Структура')"
                          :description="$translate('количество подразделений')"
                          :link="$route('cadre.department.index')"
            />
            <WidgetSimple :count="staff.total"
                          :title="$translate('Сотрудники')"
                          :description="$translate('всего сотрудников')"
                          :link="$route('cadre.staff.index')"
            />
            <WidgetSimple :count="staff.positions"
                          :title="$translate('Должности')"
                          :description="$translate('должностей сотрудников')"
                          :link="$route('cadre.positions')"
            />
        </div>
    </section>
</template>

<script setup lang="ts">
import {getCurrentInstance} from "vue";
import moment from "moment";
import {Simple as WidgetSimple} from "@/Components/Widget";
import BookmarkSelector from "@/Components/Cadre/Department/BookmarkSelector.vue";
import {Simple} from "@/Components/Widget";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

interface Department {
    total: number
}

interface Staff {
    total: number,
    vacations: number,
    sicklists: number,
    dutyjourney: number,
    positions: number,
}

interface Props {
    departments: Department,
    staff: Staff
}

defineProps<Props>()

const onSuccess = () => {
    //router.reload();
}
</script>