<template>
    <div v-if="data.module.rid.enabled">
        <Alert v-if="$translate('module-rid.description')"
               variant="info"
               :message="$translate('module-rid.description')"
        />
        <SettingsTable :data="config"/>
    </div>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const config = [
    {
        name: 'RID_ENABLED',
        label: $translate('module-rid.settings.RID_ENABLED'),
        value: props.settings.module.RID_ENABLED,
        description: $translate('module-rid.settings.RID_ENABLED_description'),
        block: 'module',
        type: 'checkbox',
    },
];
</script>
