<template>
    <q-item clickable @click="openDialog">
        <q-item-section>
            <q-item-label class="text-no-wrap">{{ $translate('cadre-department.actions.worktime-chart') }}</q-item-label>
        </q-item-section>
    </q-item>
    <q-dialog full-height full-width v-model="dialogModel" @show="chartHeight++" @hide="chartHeight--">
        <Card :title="$translate('cadre-department.actions.worktime-chart')"
              show-close-button
              no-card-section
              header-toolbar
              hide-actions
              in-modal
        >
            <Chart v-if="!_.isEmpty(chartData)"
                   chart-type="gantt-date"
                   :data="chartData"
                   :config="chartConfig"
                   :height="chartHeight + 'px'"
            />
        </Card>
    </q-dialog>
</template>

<script setup lang="ts">
import {getCurrentInstance, Ref, ref} from "vue";
import {ComponentInternalInstance} from "@vue/runtime-core";
import Chart from "@/Components/Chart.vue";
import {Card} from "@/Components/Block";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import {set, toValue} from "@vueuse/core";
import {AnyValuesObject, StringValuesObject} from "@/entity/types";
import {IGanttDateChartConfig} from "@/entity/charts";

const { departmentId } = defineProps<{departmentId: number}>();

const app: ComponentInternalInstance | null = getCurrentInstance();
const $translate: Function = app!.appContext.config.globalProperties.$translate;
const $route: Function = app!.appContext.config.globalProperties.$route;

const chartData: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);
const chartConfig: Ref<IGanttDateChartConfig> = ref<IGanttDateChartConfig>({
    chartSettings: {
        panX: false,
        panY: false,
        wheelX: "panX"
    },
    chartWidgets: {
        cursor: { behavior: "zoomX" }
    },
    categoryYAxis: {
        categoryField: "name",
        categories: [],
        rendererSettings: {
            minGridDistance: 30
        },
        rendererGridTemplateLocation: 1
    },
    dateXAxis: {
        baseInterval: { timeUnit: "hour", count: 1 },
        gridIntervals: [
            { timeUnit: "hour", count: 1 },
            { timeUnit: "day", count: 1 },
            { timeUnit: "week", count: 1 },
        ],
        dateFormats: { day: "EEEE" },
        rendererSettings: {
            strokeOpacity: 0.1
        }
    },
    columnSeries: {
        openValueXField: "start",
        valueXField: "end",
        categoryYField: "name",
        tooltipText: "{openValueX.formatDate('HH:mm')} - {valueX.formatDate('HH:mm')}",
        dataFieldToProcess: "worktime",
        dateFields: ["start", "end"],
        dateFormat: "yyyy-MM-dd HH:mm",
    }
});

const chartHeight: Ref<number> = ref<number>(0);

const dateFormat: Function = (value: string): string => moment(value, $translate("common.datetime.js")).format(
    moment.HTML5_FMT.DATE + " " + moment.HTML5_FMT.TIME // or moment.HTML5_FMT.DATETIME_LOCAL.replace("T", " ")
);

const fetchData: Function = () => {
    axios.get($route("ajax.cadre.worktime", {m: "getStaffWorktimeDataForChart", department_id: departmentId}))
        .then((response) => {
            let data: AnyValuesObject[] = [];
            let categories: StringValuesObject[] = _.map(response.data.results, (staff: AnyValuesObject) => ({
                [chartConfig.value.categoryYAxis.categoryField]: staff[chartConfig.value.categoryYAxis.categoryField]
            }));
            chartConfig.value.categoryYAxis.categories = _.reverse(
                _.sortBy(
                    _.uniqBy(categories, chartConfig.value.categoryYAxis.categoryField),
                    chartConfig.value.categoryYAxis.categoryField
                )
            );
            _.forEach(response.data.results, (staff: AnyValuesObject) => {
                let staffWorktime: AnyValuesObject[] = [];
                _.forEach(staff.worktime, (worktime: AnyValuesObject) => {
                    let worktimeWeekday: number = worktime.day;
                    if (0 === worktimeWeekday) {
                        worktimeWeekday = 7;
                    }
                    let dateOfTimeRange: string = moment().day(worktimeWeekday).format($translate("common.date.js"));
                    staffWorktime.push(Object.assign({}, worktime, {
                        [chartConfig.value.columnSeries.categoryYField]: staff[chartConfig.value.columnSeries.categoryYField],
                        [chartConfig.value.columnSeries.openValueXField]: dateFormat(dateOfTimeRange + " " + worktime[chartConfig.value.columnSeries.openValueXField]),
                        [chartConfig.value.columnSeries.valueXField]: dateFormat(dateOfTimeRange + " " + worktime[chartConfig.value.columnSeries.valueXField]),
                    }));
                });
                data.push({
                    name: staff.name,
                    worktime: staffWorktime
                });
            });
            chartData.value = data;
            chartHeight.value = 32 * _.size(categories);
            if (toValue(chartHeight) < 250) {
                chartHeight.value = 250;
            }
        })
        .catch((e) => {
            console.error(e);
        });
};

const dialogModel: Ref<boolean> = ref<boolean>(false);
const openDialog = () => {
    set(dialogModel, true);
    fetchData();
};
</script>
