<template>
    <q-select v-model="tags"
              :label="$translate('Ключевые слова')"
              stack-label
              multiple
              use-chips
              use-input
              @filter="searchTags"
              :loading="loading"
              :options="optionsTags"
              options-dense
              new-value-mode="add"
              @virtual-scroll="onScroll"
              :error-message="_.get(errors,'tags')"
              :error="errors.hasOwnProperty('tags')"
    />
</template>

<script setup>
import {computed, getCurrentInstance, nextTick, onMounted, ref} from "vue";
import _ from "lodash";
import axios from "axios";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    errors: {type: Object, default: {}},
})
const tags = defineModel({
    type: Array
})

const loading = ref(false)
const allTags = ref([])
const nextPage = ref(2)
const pageSize = 20
const lastPage = computed(() => Math.ceil(allTags.value.length / pageSize))

const optionsTags = computed(() => _.slice(allTags.value, 0, pageSize * (nextPage.value - 1)))

const searchTags = (search, update) => {
    if (search === '') {
        if (_.size(allTags.value) > 0) {
            update()
            return
        }
        update(() => {
            allTags.value = [];
        });
        return
    }
    update(() => {
        let params = {
            m: 'list',
            q: search,
        };
        loading.value = true;
        axios.get(route('ajax.tags', params))
                .then((response) => {
                    allTags.value = response.data.results;
                })
                .finally(() => {
                    loading.value = false;
                });
    });
}

const onScroll = ({to, ref}) => {
    const lastIndex = optionsTags.value.length - 1

    if (loading.value !== true && nextPage.value < lastPage.value && to === lastIndex) {
        loading.value = true

        setTimeout(() => {
            nextPage.value++
            nextTick(() => {
                ref.refresh()
                loading.value = false
            })
        }, 500)
    }
}

onMounted(() => {
    tags.value = _.map(tags.value, (tag) => {
        if (_.isObject(tag)) {
            return _.get(tag, 'name')
        }
        return tag;
    })
})

</script>