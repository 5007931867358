<template>
    <Card hide-actions no-card-section hide-title>
        <q-card-section>
            <div class="text-indigo-5 text-bold">{{ research.template.name }}</div>
        </q-card-section>
        <ResearchActions :research="research"/>
        <q-card-section>
            <div class="row">
                <q-field :label="$translate('research.show.number')"
                         stack-label borderless
                         prefix="#" class="col-10">
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ research.number }}
                        </div>
                    </template>
                </q-field>
                <q-field stack-label borderless label-slot
                         tag="div"
                         class="col">
                    <template v-slot:label>
                        <div class="flex items-center all-pointer-events">
                            <span>{{ $translate('research.show.status') }}</span>
                            <q-btn icon="o_timeline"
                                   flat dense
                                   class="q-ml-sm cursor-pointer"
                                   @click="openHistoryDialog"
                                   :title="$translate('research.actions.history')"/>
                        </div>
                    </template>
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ research.full_state_text }}
                        </div>
                    </template>
                </q-field>
            </div>
            <div class="row">
                <q-field :label="$translate('research.show.created')"
                         stack-label borderless
                         class="col-10">
                    <template v-slot:control>
                        <div class="self-center full-width flex items-center">
                            <q-icon name="o_calendar_month" size="xs"/>
                            <div class="q-ml-xs">
                                {{ moment(research.created_at).format($translate('common.datetime.js')) }}
                            </div>
                        </div>
                    </template>
                </q-field>
                <q-field v-if="research.template.assay" tag="div"
                         :label="$translate('research.show.assay')"
                         stack-label borderless
                         class="col">

                    <template v-slot:control>
                        <div class="self-center full-width flex items-center">
                            <q-icon name="o_science" size="xs"/>
                            <div class="q-ml-xs">
                                {{ research.assay_received }}
                                {{ $translate('buttons.in') }}
                                {{ research.assay }}
                            </div>
                            <ResearchAssayChange v-if="research.can.edit"
                                                 :research="research"
                                                 @onSuccess="onSuccess"/>
                            <ResearchAssayRecieve v-if="research.can.assay"
                                                  :research="research"
                                                  @onSuccess="onSuccess"/>
                            <ResearchAssayHistory :research="research"/>
                        </div>
                    </template>
                </q-field>
            </div>
            <div class="row">
                <q-field v-if="research.responsible_assign"
                         :label="$translate('research.show.assigned')" stack-label borderless class="col-10">
                    <template v-slot:control>
                        <div class="self-center full-width flex items-center">
                            <q-icon name="o_calendar_month" size="xs"/>
                            <div class="q-ml-xs">
                                {{ moment(research.responsible_assign).format($translate('common.date.js')) }}
                            </div>
                        </div>
                    </template>
                </q-field>
                <q-field v-if="research.end_date_plan" :label="$translate('research.show.date_plan')" stack-label
                         borderless class="col">
                    <template v-slot:control>
                        <div class="self-center full-width flex items-center">
                            <q-icon name="o_calendar_month" size="xs"/>
                            <div class="q-ml-xs">
                                {{ moment(research.end_date_plan).format($translate('common.date.js')) }}
                            </div>
                        </div>
                    </template>
                </q-field>
                <q-field v-if="research.end_date_real" :label="$translate('research.show.date_real')" stack-label
                         borderless class="col">
                    <template v-slot:control>
                        <div class="self-center full-width flex items-center">
                            <q-icon name="o_calendar_month" size="xs"/>
                            <div class="q-ml-xs">
                                {{ moment(research.end_date_real).format($translate('common.date.js')) }}
                            </div>
                        </div>
                    </template>
                </q-field>
            </div>
            <q-field :label="$translate('research.show.author')" stack-label borderless>
                <template v-slot:control>
                    <div class="self-center full-width">
                        <UserWithDetail :user="research.author"/>
                    </div>
                </template>
            </q-field>
        </q-card-section>
        <q-separator/>
        <q-card-section>
            <div :class="{'row q-gutter-x-md':$q.screen.gt.md}">
                <q-field :label="$translate('research.show.project-number')" stack-label borderless
                         prefix="#" class="col-4 col-xl">
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ research.project.number }}
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('research.show.project-status')" stack-label borderless
                         class="col">
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ $translate('project.status.' + research.project.status.status) }}
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('project.view.base-info-timeline')"
                         tag="div"
                         stack-label borderless class="col">
                    <template v-slot:control>
                        <div class="self-center full-width items-center flex">
                                <span>
                                    {{ moment(research.project.date_start).format($translate('common.date.js')) }}
                                     &mdash;
                                    {{ moment(research.project.date_end).format($translate('common.date.js')) }}
                                </span>
                        </div>
                    </template>
                </q-field>
            </div>
            <q-field :label="$translate('project.view.base-info-name')"
                     tag="div"
                     stack-label borderless>
                <template v-slot:control>
                    <div class="self-center full-width">
                        {{ research.project.name }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('project.view.base-info-description')"
                     tag="div"
                     stack-label borderless bottom-slots>
                <template v-slot:control>
                    <q-slide-transition>
                        <div class="self-center full-width"
                             v-html="research.project.description"
                             :style="projectDescriptionContainerStyle"></div>
                    </q-slide-transition>
                </template>
                <template v-slot:hint>
                    <q-btn :icon="expandedProjectDescription ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                           :label="$translate(expandedProjectDescription ? 'buttons.hide' : 'buttons.show')"
                           @click="expandedProjectDescription = !expandedProjectDescription"
                           class="float-right"
                           size="sm"
                           flat
                    />
                </template>
            </q-field>
            <q-field :label="$translate('project.view.base-info-targets')"
                     tag="div"
                     stack-label borderless bottom-slots>
                <template v-slot:control>
                    <q-slide-transition>
                        <div class="self-center full-width"
                             v-html="research.project.targets"
                             :style="projectTargetsContainerStyle"></div>
                    </q-slide-transition>
                </template>
                <template v-slot:hint>
                    <q-btn :icon="expandedProjectTargets ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                           :label="$translate(expandedProjectTargets ? 'buttons.hide' : 'buttons.show')"
                           @click="expandedProjectTargets = !expandedProjectTargets"
                           class="float-right"
                           size="sm"
                           flat
                    />
                </template>
            </q-field>
            <q-field :label="$translate('research.show.leader')" stack-label borderless class="col">
                <template v-slot:control>
                    <div class="self-center full-width flex items-center">
                        <UserWithDetail :user="research.project.leader"/>
                    </div>
                </template>
            </q-field>
            <q-field v-if="0 < research.project.members_count" :label="$translate('research.show.members')" stack-label borderless class="col">
                <template v-slot:control>
                    <div class="self-center full-width flex items-center">
                        <UserWithDetail v-if="1 === research.project.members_count" :user="_.head(research.project.members).user"/>
                        <template v-else>
                            <q-btn icon="mdi-account-multiple-outline"
                                   :label="$translate('Список участников') + ' (' + research.project.members_count + ')'"
                                   @click="fetchProjectMembers"
                                   dense
                                   no-caps
                                   flat
                            />
                            <q-dialog v-model="projectMembersDialog">
                                <Card :title="$translate('research.show.members') + ' #' + research.project.number"
                                      show-close-button
                                      no-card-section
                                      hide-actions
                                      in-modal
                                >
                                    <q-inner-loading :showing="projectMembersLoading">
                                        <q-spinner-gears size="50px" color="primary"/>
                                    </q-inner-loading>
                                    <q-list separator>
                                        <MemberItem v-for="(member) in projectMembers"
                                                    :title="member.role.display_name"
                                                    :member="member.user"
                                                    :project-researches="member.research"
                                                    :memberId="member.id"
                                                    :project="research.project"
                                                    :can="member.can"
                                                    :hide-actions="true"/>
                                    </q-list>
                                </Card>
                            </q-dialog>
                        </template>
                    </div>
                </template>
            </q-field>
        </q-card-section>
        <q-separator/>
        <q-card-section>
            <q-field :label="$translate('research.show.ssc')" stack-label borderless>
                <template v-slot:control>
                    <div class="self-center full-width flex no-wrap items-start cursor-pointer"
                         @click="toggleContact = !toggleContact">
                        <q-icon name="o_business" size="xs"/>
                        <span class="q-ml-xs">{{ research.ssc.name }}</span>
                    </div>
                </template>
            </q-field>

            <div class="row">
                <q-field :label="$translate('research.show.ssc-leader')"
                         stack-label
                         borderless class="col-10">
                    <template v-slot:control>
                        <div class="self-center full-width flex items-center">
                            <UserWithDetail v-if="research.ssc.director"
                                            :user="research.ssc.director"/>
                            <div v-else>&mdash;</div>
                        </div>
                    </template>
                </q-field>
                <div class="col">
                    <q-field :label="$translate('research.show.responsible')"
                             v-if="research.state >0"
                             stack-label
                             borderless>
                        <template v-slot:control>
                            <div class="self-center full-width items-start">
                                <UserWithDetail v-if="research.responsible"
                                                :user="research.responsible"/>
                                <div v-else>&mdash;</div>
                            </div>
                        </template>
                    </q-field>
                    <ResearchResponsibleAssign v-if='research.can.responsible'
                                               :research="research"
                                               @onSuccess="onSuccess"/>
                </div>
            </div>
            <q-field :label="$translate('project.view.ssc-work-description')"
                     tag="div"
                     stack-label borderless
                     :bottom-slots="!!research.sscresearch.description">
                <template v-slot:control>
                    <q-slide-transition>
                        <div class="self-center full-width"
                             v-html="research.sscresearch.description??'&mdash;'"
                             :style="sscResearchDescriptionContainerStyle"></div>
                    </q-slide-transition>
                </template>
                <template v-slot:hint>
                    <q-btn :icon="expandedSscResearchDescription ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                           :label="$translate(expandedSscResearchDescription ? 'buttons.hide' : 'buttons.show')"
                           @click="expandedSscResearchDescription = !expandedSscResearchDescription"
                           class="float-right"
                           size="sm"
                           flat
                    />
                </template>
            </q-field>
        </q-card-section>
    </Card>
    <!-- Контакты подразделения -->
    <q-dialog v-model="toggleContact">
        <Card :title="$translate('project.view.ssc-contact-title') + ' ' + research.ssc.name"
              in-modal show-close-button hide-actions no-card-section>
            <q-list separator>
                <q-item v-if="_.get(research.ssc.info_settings,'common.show')">
                    <q-item-section>
                        <q-item-label v-html="helpers.nl2br(research.ssc.info_settings.common.info)"/>
                    </q-item-section>
                </q-item>
                <template v-if="_.get(research.ssc.info_settings,'leader.show')">
                    <MemberItem v-if="research.ssc.director"
                                :title="$translate('project.view.ssc-contact-title-leader')"
                                :member="research.ssc.director"
                                hide-actions/>
                </template>
                <template v-if="_.get(research.ssc.info_settings,'other_leaders.show')">
                    <template v-for="(head,id) in research.ssc.heads">
                        <MemberItem v-if="head.data.user_id !== research.ssc.director_id"
                                    :title="$translate('project.view.ssc-contact-title-leader-other')"
                                    :member="head.data"
                                    hide-actions/>
                    </template>
                </template>
            </q-list>
        </Card>
    </q-dialog>
    <q-dialog v-model="researchHistoryDialog" full-height no-backdrop-dismiss>
        <Card :title="$translate('research.title.history',{number:props.research.number})"
              hide-actions in-modal show-close-button no-card-section header-toolbar>
            <q-inner-loading :showing="loadingHistory">
                <div class="flex items-center justify-center">
                    <q-spinner-gears size="50px" color="primary"/>
                    <q-item-label class="q-ml-xl text-primary">
                        {{ $translate('Загрузка истории изменений') }}
                    </q-item-label>
                </div>
            </q-inner-loading>
            <q-list separator>
                <template v-for="(history) in researchHistory">
                    <q-item>
                        <q-item-section>
                            <small>
                                {{ moment(history.created_at).format($translate('common.datetime.js')) }} |
                                {{ history.user.fulled_name }} | {{ history.content }}
                            </small>
                            <div class="text-wrap text-break" v-html="history.comment"></div>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import axios from "axios";
import moment from "moment/moment";
import {useQuasar} from "quasar";
import _ from "lodash";
import helpers from "@/helpers";
import UserWithDetail from "@/Components/User/UserWithDetail.vue";
import ResearchActions from "@/Components/ProjectResearch/Card/ResearchActions.vue";
import ResearchAssayChange from "@/Components/ProjectResearch/Card/ResearchAssayChange.vue";
import ResearchResponsibleAssign from "@/Components/ProjectResearch/Card/ResearchResponsibleAssign.vue";
import ResearchAssayRecieve from "@/Components/ProjectResearch/Card/ResearchAssayRecieve.vue";
import ResearchAssayHistory from "@/Components/ProjectResearch/Card/ResearchAssayHistory.vue";
import {MemberItem} from "@/Components/Project/Card";
import {Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar();

const props = defineProps({
    research: {type: Object, required: true}
})

const onSuccess = () => {
    router.reload()
}
const toggleContact = ref(false)

const researchHistoryDialog = ref(false)
const researchHistory = ref(null)
const loadingHistory = ref(false)
const openHistoryDialog = () => {
    loadingHistory.value = true;
    axios.get($route('ajax.research', {m: 'history', project_research_id: props.research.id}))
            .then(response => {
                researchHistory.value = response.data.results
            })
            .finally(() => loadingHistory.value = false)

    researchHistoryDialog.value = true;
}

const expandedProjectDescription = ref(false);
const projectDescriptionContainerStyle = computed(() => {
    return expandedProjectDescription.value ? 'height: 100%' : 'height: 2rem; overflow: hidden';
});
const expandedProjectTargets = ref(false);
const projectTargetsContainerStyle = computed(() => {
    return expandedProjectTargets.value ? 'height: 100%' : 'height: 2rem; overflow: hidden';
});
const expandedSscResearchDescription = ref(false);
const sscResearchDescriptionContainerStyle = computed(() => {
    return expandedSscResearchDescription.value ? 'height: 100%' : 'height: 2rem; overflow: hidden';
});

const projectMembers = ref([]);
const projectMembersLoading = ref(false);
const projectMembersDialog = ref(false);
const fetchProjectMembers = () => {
    projectMembersLoading.value = true;
    projectMembersDialog.value = true;
    axios.get(route('ajax.project', {m: 'members', project: props.research.project.id}))
        .then((response) => {
            projectMembers.value = response.data.results;
        })
        .finally(() => {
            projectMembersLoading.value = false;
        })
};

// const getSscResearchDescription = (research) => {
//     let description = research.sscresearch.description;
//     return description.length > 0 ? description : '-';
// };
</script>