<template>
    <Card :title="$translate('project-type.title')"
          title-bg="blue-grey-3"
          header-toolbar hide-actions no-card-section>
        <template v-slot:headerActions>
            <q-btn icon="o_add" v-if="can.create"
                   :label="$translate('project-type.actions.add')"
                   @click="router.get($route('admin.project-type.create'))"
                   color="blue-grey-5"
                   dense no-caps/>
            <q-btn :icon="showingArchive?'inventory_2':'o_inventory_2'"
                   color="dark"
                   @click="toggleShowArchive"
                   outline
                   dense>
                <q-tooltip class="text-body2">
                    {{
                        showingArchive ? $translate('project-type.actions.hide-archive') : $translate('project-type.actions.show-archive')
                    }}
                </q-tooltip>
            </q-btn>
        </template>
        <ElementTree :items="projectTypes" :level="0" :parent="0"
                     expand-element>
            <template v-slot:name="node">
                <q-item-label class="cursor-pointer col flex self-center items-start column"
                              :title="node.node.name">
                    <div :class="node.node.archive?'text-grey':null">{{ node.node.name }}</div>
                    <q-item-label caption v-if="node.node.description">{{ node.node.description }}</q-item-label>
                </q-item-label>
            </template>
            <template v-slot:actions="node">
                <q-btn size="sm" icon="menu"
                       :label="$translate('project-type.actions.title')"
                       flat
                       v-if="_.filter(node.node.can,(v) => v).length>0">
                    <q-menu>
                        <q-list dense separator>
                            <q-item v-if="node.node.can.view" clickable v-close-popup
                                    @click="openPreviewModal(node.node)">
                                <q-item-section side>
                                    <q-icon name="o_info" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label class="text-no-wrap">{{ $translate('buttons.preview') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="node.node.can.add" clickable v-close-popup
                                    @click="router.get($route('admin.project-type.child',node.node))">
                                <q-item-section side>
                                    <q-icon name="add" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label class="text-no-wrap">{{ $translate('project-type.actions.add-child') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="node.node.can.edit" clickable v-close-popup
                                    @click="router.get($route('admin.project-type.edit',node.node))">
                                <q-item-section side>
                                    <q-icon name="o_edit" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label class="text-no-wrap">{{ $translate('buttons.edit') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="node.node.can.copy" clickable v-close-popup
                                    @click="router.get($route('admin.project-type.copy',node.node))">
                                <q-item-section side>
                                    <q-icon name="content_copy" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label class="text-no-wrap">{{ $translate('buttons.clone') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="node.node.can.archive" clickable v-close-popup
                                    @click="router.patch($route('admin.project-type.archive',node.node))">
                                <q-item-section side>
                                    <q-icon name="o_archive" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label class="text-no-wrap">{{ $translate('buttons.archive') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="node.node.can.delete" clickable v-close-popup
                                    @click="deleteItem(node.node)">
                                <q-item-section side>
                                    <q-icon name="o_delete" size="xs"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label class="text-no-wrap">{{ $translate('buttons.delete') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-btn>
            </template>
        </ElementTree>
    </Card>
    <q-dialog v-model="previewModal" full-width full-height no-backdrop-dismiss>
        <Card :title="$translate('project-type.title-preview')"
              hide-actions show-close-button header-toolbar in-modal>
            <ProjectStepMainInfo  v-model="projectPreview"/>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import axios from "axios";
import _ from 'lodash'
import {useQuasar} from "quasar";
import ElementTree from "@/Components/ElementTree.vue";
import ProjectStepMainInfo from "@/Components/Project/CreateProjectSteps/MainInfo.vue";
import {Card} from "@/Components/Block";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    withArchive: false,
    can: {}
})

const projectTypes = computed(() => usePage().props.projectTypes.data)


const showingArchive = ref(props.withArchive)

const toggleShowArchive = () => {
    let val = !showingArchive.value
    router.visit($route('admin.project-type.index', {archive: val}))
}

const deleteItem = (item) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: item.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete($route('admin.project-type.delete', item),
            {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    router.reload()
                }
            }
        )
    })
}

const previewModal = ref(false)

const projectPreview = useForm({
    source: null,
    sourceData: null,
    sourceDetail: null,
    sourceInfo: null,
    type_id: null,
    type: {},
    name: null,
    description: '',
    additional: {},
    targets: '',
    keywords: [],
    date_start: null,
    date_end: null,
    expectedresult: {},
    members: [],
    ssc: []
})

const openPreviewModal = (project) => {
    axios
        .get($route('ajax.project.type', {m: 'record', id: project.id}))
        .then((response) => {
            _.set(projectPreview, 'type', response.data.results);
        })
        .finally(() => {
            previewModal.value = true;
        })
}
</script>