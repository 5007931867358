<template>
    <Card :title="$translate('cadre-department.title.list')"
          title-bg="blue-grey-3"
          no-card-section
          hide-actions
          header-toolbar>

        <template v-slot:headerActions>
            <CreateDepartment v-if="can.create" @onSuccess="onSuccess"/>
            <SyncExternalSource v-if="can.sync"/>
            <SyncInternalSource v-if="can.import"/>
            <BookmarkSelector @onSuccess="onSuccess"
                              color="blue-grey-6"/>
        </template>

        <q-input ref="filterRef"
                 v-model="filter"
                 :placeholder="$translate('ssc.title-search')"
                 debounce="1000"
                 clear-icon="clear"
                 @update:model-value="filterItems"
                 dense
                 filled
                 square
                 clearable>
            <template v-slot:prepend>
                <q-icon name="search"/>
            </template>
        </q-input>
        <q-splitter v-model="splitterModel"
                    :limits="[20,80]"
                    @update:model-value="saveSplitterModel">
            <template v-slot:before>
                <q-inner-loading :showing="loadingDepartments">
                    <q-spinner-gears size="50px" color="primary"/>
                    <q-item-label class="text-body1 text-primary q-mt-md">
                        {{ $translate('Загрузка структуры отделов') }}
                    </q-item-label>
                </q-inner-loading>
                <q-scroll-area :style="areaHeight">
                    <ElementTree :items="allDepartments"
                                 :level="0"
                                 :parent="0"
                                 :active-element="selectedDepartmentId"
                                 :expand-tree="expandTree"
                                 expand-element
                    >
                        <template v-slot:name="node">
                            <q-item-label class="cursor-pointer col flex self-stretch items-center"
                                          :title="node.node.name_short"
                                          @click="selectedDepartmentId = node.node.id">
                                <span v-html="node.node.name"/>
                            </q-item-label>
                        </template>
                    </ElementTree>
                </q-scroll-area>
            </template>

            <template v-slot:after>
                <q-inner-loading :showing="loadingDepartment">
                    <q-spinner-gears size="50px" color="primary"/>
                    <q-item-label class="text-body1 text-primary q-mt-md">
                        {{ $translate('Загрузка данных отдела') }}
                    </q-item-label>
                </q-inner-loading>
                <q-scroll-area :style="areaHeight" content-style="max-width:100%">
                    <DepartmentCard v-model="selectedDepartment"
                                    @onSuccess="onSuccess"/>
                </q-scroll-area>
            </template>

        </q-splitter>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch} from "vue";
import {useQuasar} from "quasar";
import axios from "axios";
import _ from "lodash";

import ElementTree from "@/Components/ElementTree.vue";
import {Card} from "@/Components/Block";
import DepartmentCard from "@/Components/Cadre/Department/DepartmentCard.vue";
import SyncExternalSource from "@/Components/Cadre/Department/SyncExternalSource.vue";
import SyncInternalSource from "@/Components/Cadre/Department/SyncInternalSource.vue";
import CreateDepartment from "@/Components/Cadre/Department/CreateDepartment.vue";
import BookmarkSelector from "@/Components/Cadre/Department/BookmarkSelector.vue";

const $q = useQuasar();
const app = getCurrentInstance()

const props = defineProps({
    can: {type: Object},
})
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const filter = ref('')
const splitterModel = ref(50)
const saveSplitterModel = (value) => {
    $q.cookies.set('cadre_department_index_split', value, {expires: 365})
}
const getSplitterModel = () => {
    return Number($q.cookies.get('cadre_department_index_split'));
}
const initSplitModel = () => {
    let split = getSplitterModel()
    if (!split || split === 'NaN') {
        split = 50;
    }
    splitterModel.value = split;
}
const areaHeight = computed(() => {
    return {
        height: 'calc(100vh - 180px)'
    }
})
const departments = ref({});
const allDepartments = ref({});

const loadingDepartments = ref(false)

const initDepartments = () => {
    loadingDepartments.value = true
    // allDepartments.value = usePage().props.departments.data
    // departments.value = usePage().props.departments.data
    // loadingDepartments.value = false
    axios.get(route('ajax.cadre.department', {m: 'list'}))
            .then((response) => {
                allDepartments.value = response.data.results;
                departments.value = response.data.results;
            })
            .finally(() => {
                loadingDepartments.value = false
            })
}

const selectedDepartmentId = ref(0)
const selectedDepartment = ref({})
const loadingDepartment = ref(false)

watch(selectedDepartmentId, () => {
    loadDepartment(selectedDepartmentId.value)
})

const loadDepartment = (target) => {
    if (target > 0) {
        loadingDepartment.value = true;
        $q.loadingBar.start()
        axios.get(route('ajax.cadre.department', {id: target, m: 'record'}))
                .then((response) => {
                    $q.loadingBar.increment()
                    selectedDepartment.value = response.data.results
                })
                .finally(() => {
                    loadingDepartment.value = false;
                    $q.loadingBar.stop()
                })
    }
}
const expandTree = ref(false)
/**
 * Поиск подразделения по краткому/полному наименованию
 * @param search
 */
const filterItems = (search) => {
    if (search === '') {
        expandTree.value = false;
        allDepartments.value = departments.value;
    } else {
        // ищем нужные элементы
        let filtered = _.filter(departments.value, (item, i) => {
            return _.lowerCase(item.name).indexOf(_.lowerCase(search)) >= 0;
        });
        expandTree.value = _.size(filtered) > 1 && _.size(filtered) < 10;
        // собираем родителей для дерева
        const parented = new Map();

        function getParent(items, parent_id) {
            let item = _.find(items, function (object) {
                return object.id === parent_id
            });
            if (item) {
                parented.set(item.id, item)
                getParent(items, item.parent_id)
            }
        }

        _.each(filtered, (item) => {
            parented.set(item.id, item)
            getParent(departments.value, item.parent_id)
        })
        allDepartments.value = Array.from(parented.values())
    }
}


const onSuccess = () => {
    initDepartments()
    loadDepartment(selectedDepartmentId.value)
}

onBeforeMount(() => {
    initSplitModel()
});

onMounted(() => {
    initDepartments()
})

</script>