<template>
    <div class="force-directed-tree" ref="chartRef"></div>
</template>

<script setup lang="ts">
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import {onBeforeUnmount, onMounted, ref, Ref, shallowRef, ShallowRef} from "vue";
import {AnyValuesObject} from "@/entity/types";
import {ConfigHandler, IForceDirectedTreeChartConfig} from "@/entity/charts";
import {set, toValue} from "@vueuse/core";

interface Props {
    data: AnyValuesObject,
    config: IForceDirectedTreeChartConfig,
    locale: AnyValuesObject,
}

const props = defineProps<Props>();

const configHandler: ConfigHandler = ConfigHandler.new(props.config);

const chartRef: Ref<any> = ref<any>();

const am5Root: ShallowRef = shallowRef<any>();
const am5Container: ShallowRef = shallowRef<any>();
const am5Series: ShallowRef = shallowRef<any>();

const createChart: Function = (): void => {
    clearRefs();

    let root: am5.Root = am5.Root.new(toValue(chartRef));
    root.setThemes([am5themes_Animated.new(root)]);
    root.locale = props.locale;
    root._logo!.dispose();
    set(am5Root, { root: root });

    set(am5Container, {
        container: am5Root.value.root.container.children.push(
            am5.Container.new(am5Root.value.root, {
                width: am5.p100,
                height: am5.p100,
                layout: am5Root.value.root.verticalLayout
            })
        )
    });

    set(am5Series, {
        series: am5Container.value.container.children.push(
            am5hierarchy.ForceDirected.new(am5Root.value.root, Object.assign({}, configHandler.get("forceDirectedSeries"), {
                minRadius: am5.percent(configHandler.get("forceDirectedSeries.minRadius")),
                maxRadius: am5.percent(configHandler.get("forceDirectedSeries.maxRadius")),
            }))
        )
    });

    am5Series.value.series.links.template.setAll(configHandler.get("forceDirectedSeriesLinksTemplateSettings"));
    am5Series.value.series.nodes.template.set(
        "tooltipText",
        configHandler.get("forceDirectedSeriesNodesTemplateTooltipText")
    );
    am5Series.value.series.circles.template.setAll(configHandler.get("forceDirectedSeriesCirclesTemplateSettings"));

    am5Series.value.series.data.setAll([props.data]);
    am5Series.value.series.set("selectedDataItem", am5Series.value.series.dataItems[0]);

    // Make stuff animate on load
    am5Series.value.series.appear(1000, 100);

    if (configHandler.has("exportingSettings")) {
        let exporting: am5plugins_exporting.Exporting = am5plugins_exporting.Exporting.new(am5Root.value.root, {
            menu: am5plugins_exporting.ExportingMenu.new(am5Root.value.root, {}),
            dataSource: props.data,
            filePrefix: configHandler.get("exportingSettings.filePrefix", "force_directed_tree_chart")
        });
    }
};

const clearRefs: Function = (): void => {
    set(am5Root, null);
    set(am5Container, null);
    set(am5Series, null);
};

onMounted(() => {
    createChart();
});

onBeforeUnmount(() => {
    if (am5Root.value.root) {
        am5Root.value.root.dispose();
    }
});
</script>

<style scoped>
.force-directed-tree {
    width: 100%;
    height: 100%;
}
</style>