<template>
    <Alert variant="info">Выберите блоки требующиеся для данного ОМ</Alert>

    <component :is="showBlock('Reviewing')" v-model="fields.reviewing"/>
    <component :is="showBlock('Category')" v-model="fields.category"/>
    <component :is="showBlock('Stage')" v-model="fields.stage"/>
    <component :is="showBlock('CommonPublication')" v-model="fields.common"/>
    <component :is="showBlock('Authors')" v-model="fields.authors"/>
    <component :is="showBlock('Responsible')" v-model="fields.responsible"/>
    <component :is="showBlock('Print')" v-model="fields.print"/>
    <component :is="showBlock('Series')" v-model="fields.series"/>
    <component :is="showBlock('Files')" v-model="fields.files"/>
    <component :is="showBlock('APC')" v-model="fields.apc"/>
    <component :is="showBlock('Rubrics')" v-model="fields.rubrics"/>
    <component :is="showBlock('ExternalID')" v-model="fields.externalid"/>

</template>

<script setup>
import {getCurrentInstance, onMounted} from "vue";
import _ from "lodash";
import {Alert} from "@/Components/Block";
import * as FormBlock from './Block'
const showBlock = (block) => FormBlock[block]

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const fields = defineModel({
    type: Object,
})


onMounted(() => {
    if (!fields.value.hasOwnProperty('common')) {
        _.set(fields.value, 'common', {
            name: 'Информация о публикации',
            show: false,
            required: false,
            fields: {
                lang: {
                    label: 'Язык оригинала',
                    show: true,
                    required: false,
                },
                name: {
                    label: 'Название на языке оригинала',
                    show: true,
                    required: false,
                },
                subname: {
                    label: 'Подзаголовок на языке оригинала',
                    show: true,
                    required: false,
                },
                referat: {
                    label: 'Реферат',
                    show: true,
                    required: false,
                }
            },
            fields_additional: []
        })
    }
    if (!fields.value.hasOwnProperty('material')) {
        _.set(fields.value, 'material', {
            name: 'Информация о материалах средств информации и освещение в средствах информации',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('authors')) {
        _.set(fields.value, 'authors', {
            name: 'Получатели награды',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('responsible')) {
        _.set(fields.value, 'responsible', {
            name: 'Ответственные за награду',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('files')) {
        _.set(fields.value, 'files', {
            name: 'Документы и ссылки',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('rubrics')) {
        _.set(fields.value, 'rubrics', {
            name: 'Ключевые слова, рубрикаторы и классификаторы',
            show: false,
            required: false,
            keywords: {
                label: 'Ключевые слова',
                show: true,
                required: false,
            },
            fields: []
        })
    }
    if (!fields.value.hasOwnProperty('externalid')) {
        _.set(fields.value, 'externalid', {
            name: 'Внешние ID',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('apc')) {
        _.set(fields.value, 'apc', {
            name: 'Стоимость обработки статьи (Article Processing Charge, APC)',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('journal')) {
        _.set(fields.value, 'journal', {
            name: 'Журналы',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('series')) {
        _.set(fields.value, 'series', {
            name: 'Информация о серии',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('print')) {
        _.set(fields.value, 'print', {
            name: 'Основная публикация',
            show: false,
            required: false,
            fields: [],
        })
    }
    if (!fields.value.hasOwnProperty('stage')) {
        _.set(fields.value, 'stage', {
            name: 'Этапы публикации',
            show: false,
            required: false,
        })
    }
    if (!fields.value.hasOwnProperty('category')) {
        _.set(fields.value, 'category', {
            name: 'Категория публикации',
            show: false,
            required: false,
            values: []
        })
    }
    if (!fields.value.hasOwnProperty('reviewing')) {
        _.set(fields.value, 'reviewing', {
            name: 'Рецензирование',
            show: false,
            required: false,
        })
    }
})

</script>