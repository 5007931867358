<template>
    <Card :title="$translate('ssc.title')"
          title-bg="blue-grey-3"
          no-card-section
          hide-actions
          header-toolbar>

        <template v-slot:headerActions>
            <q-btn icon="add"
                   :label="$translate('ssc.actions.add')"
                   color="blue-grey-6"
                   @click="openAddDepartment(0)"
                   no-caps
                   dense/>
        </template>

        <q-input ref="filterRef"
                 v-model="filter"
                 :placeholder="$translate('ssc.title-search')"
                 debounce="1000"
                 clear-icon="clear"
                 @update:model-value="filterItems"
                 dense
                 filled
                 square
                 clearable>
            <template v-slot:prepend>
                <q-icon name="search"/>
            </template>
        </q-input>
        <q-splitter v-model="splitterModel"
                    :limits="[20,80]"
                    @update:model-value="saveSplitterModel">
            <template v-slot:before>
                <q-scroll-area :style="areaHeight">
                    <ElementTree :items="allDepartments"
                                 :level="0"
                                 :parent="0"
                                 :active-element="selectedDepartmentId">
                        <template v-slot:name="node">
                            <q-item-label class="cursor-pointer col flex self-stretch items-center"
                                          :title="node.node.name_short"
                                          @click="selectedDepartmentId = node.node.id">
                                <span>{{ node.node.name }}</span>
                            </q-item-label>
                        </template>
                        <template v-slot:actions="node">
                            <DataTableActions :name="node.node.name"
                                              @on-success="onSuccess">
                                <template v-slot:default>
                                    <q-item v-if="node.node.can.view"
                                            clickable v-close-popup
                                            @click="router.visit($route('ssc.show',node.node))">
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('ssc.actions.card') }}
                                            </q-item-label>
                                        </q-item-section>
                                        <q-item-section avatar>
                                            <q-icon name="o_pageview" size="xs"/>
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="node.node.can.add"
                                            clickable v-close-popup
                                            @click="openAddDepartment(node.node.id)">
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('ssc.actions.add') }}
                                            </q-item-label>
                                        </q-item-section>
                                        <q-item-section avatar>
                                            <q-icon name="o_add" size="xs"/>
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="node.node.can.move"
                                            clickable v-close-popup
                                            @click="openMoveDepartment(node.node)">
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('ssc.actions.move') }}
                                            </q-item-label>
                                        </q-item-section>
                                        <q-item-section avatar>
                                            <q-icon name="sym_o_jump_to_element" size="xs"/>
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="node.node.can.delete"
                                            clickable v-close-popup
                                            @click="openDeleteDepartment(node.node.id,node.node.name)">
                                        <q-item-section>
                                            <q-item-label>
                                                {{ $translate('ssc.actions.delete') }}
                                            </q-item-label>
                                        </q-item-section>
                                        <q-item-section avatar>
                                            <q-icon name="o_delete" size="xs"/>
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </DataTableActions>
                        </template>
                    </ElementTree>
                </q-scroll-area>
            </template>

            <template v-slot:after>
                <q-inner-loading :showing="loadingDepartment">
                    <q-spinner-gears size="50px" color="primary"/>
                </q-inner-loading>
                <SSCCard v-if="selectedDepartmentId"
                         :selected-department.sync="selectedDepartment"
                         :loading="loadingDepartment"
                         @on-success="onSuccess"></SSCCard>
            </template>

        </q-splitter>
    </Card>

    <q-dialog v-model="department_form_add" no-esc-dismiss no-backdrop-dismiss>
        <Card :title="$translate('ssc.title-add')"
              in-modal no-card-section header-toolbar show-close-button>
            <Form v-model="department"/>
            <template v-slot:actions>
                <q-btn color="positive"
                       icon="add"
                       :label="$translate('buttons.add')"
                       @click="addDepartment"/>
                <q-btn color="grey-7"
                       icon="close"
                       :label="$translate('buttons.close')"
                       @click="department_form_add = false"/>
            </template>
        </Card>
    </q-dialog>

    <q-dialog v-model="department_form_move" no-esc-dismiss no-backdrop-dismiss>
        <Card :title="$translate('ssc.title-move',{ssc: movedDepartment.name})"
              title-bg="blue-grey-3"
              show-close-button
              in-modal>
            <q-select v-model="moveForm.new_id"
                      :label="$translate('ssc.title-move-field')"
                      :options="allDepartments" option-label="name" option-value="id"
                      emit-value map-options options-dense
                      :error="moveForm.errors.hasOwnProperty('new_id')"
                      :error-message="moveForm.errors['new_id']"></q-select>
            <template v-slot:actions>
                <q-btn color="positive"
                       icon="sym_o_jump_to_element"
                       :label="$translate('buttons.move')"
                       @click="moveDepartment()"/>
                <q-btn color="grey-7"
                       icon="close"
                       :label="$translate('buttons.close')"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>

</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount, ref, watch} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import axios from "axios";
import _ from "lodash";

import Form from "@/Components/SSC/Form.vue";
import SSCCard from "@/Components/SSC/SSCCard.vue";
import ElementTree from "@/Components/ElementTree.vue";
import DataTableActions from "@/Components/DataTableActions.vue";
import {Card} from "@/Components/Block";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const filter = ref('')
const splitterModel = ref(50)
const saveSplitterModel = (value) => {
    $q.cookies.set('ssc_index_split', value, {expires: 365})
}
const getSplitterModel = () => {
    return Number($q.cookies.get('ssc_index_split'));
}
const initSplitModel = () => {
    let split = getSplitterModel()
    if (!split || split === 'NaN') {
        split = 50;
    }
    splitterModel.value = split;
}
const areaHeight = computed(() => {
    return {
        height: 'calc(100vh - 180px)'
    }
})
const departments = ref({});
const allDepartments = ref({});

const initDepartments = () => {
    departments.value = usePage().props.departments.data;
    allDepartments.value = usePage().props.departments.data;
}


const selectedDepartmentId = ref(0)
const selectedDepartment = ref({})
const loadingDepartment = ref(false)

watch(selectedDepartmentId, () => {
    loadDepartment(selectedDepartmentId.value)
})


const loadDepartment = (target) => {
    loadingDepartment.value = true;
    $q.loadingBar.start()
    axios.get(route('admin.ssc.show', target))
        .then((response) => {
            $q.loadingBar.increment()
            selectedDepartment.value = response.data
        })
        .finally(() => {
            loadingDepartment.value = false;
            $q.loadingBar.stop()
        })
}

/**
 * Поиск подразделения по краткому/полному наименованию
 * @param search
 */
const filterItems = (search) => {
    if (search === '') {
        allDepartments.value = $page.props.departments.data;
    } else {
        // ищем нужные элементы
        let filtered = _.filter(departments.value, (item, i) => {
            return (_.lowerCase(item.name).indexOf(_.lowerCase(search)) >= 0
                || _.lowerCase(item.name_short).indexOf(_.lowerCase(search)) >= 0);
        });
        // собираем родителей для дерева
        const parented = new Map();

        function getParent(items, parent_id) {
            let item = _.find(items, function (object) {
                return object.id === parent_id
            });
            if (item) {
                parented.set(item.id, item)
                getParent(items, item.parent_id)
            }
        }

        _.each(filtered, (item) => {
            parented.set(item.id, item)
            getParent(departments.value, item.parent_id)
        })
        allDepartments.value = Array.from(parented.values())
    }
}

const department = useForm({
    id: null,
    parent_id: 0,
    name_i18n: {
        ru: null,
        en: null
    },
    name_short_i18n: {
        ru: null,
        en: null
    },
    description_i18n: {
        ru: null,
        en: null
    },
    display: false,
    level: 0,
})

const department_form_add = ref(false)
const department_form_move = ref(false)
const movedDepartment = ref(null)
const openAddDepartment = (id) => {
    _.set(department, 'parent_id', id)
    department_form_add.value = true
}
const openMoveDepartment = (department) => {
    movedDepartment.value = department;
    department_form_move.value = true
}
const openDeleteDepartment = (id, name) => {
    $q.dialog({
        title: $translate('ssc.title-delete'),
        message: $translate('ssc.message-delete', {ssc: name}),
        cancel: {
            color: 'negative',
            label: $translate('buttons.cancel')
        },
        ok: {
            color: 'positive',
            label: $translate('buttons.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('admin.ssc.delete', id), {
            onSuccess: () => initDepartments()
        })
    })
}
const addDepartment = () => {
    department.post(route('admin.ssc.store'), {
        onSuccess: () => {
            department_form_add.value = false
            initDepartments()
        }
    })
}
const moveForm = useForm({
    new_id: null
})
const moveDepartment = () => {
    moveForm.post(route('admin.ssc.move', {ssc: movedDepartment.value.id}), {
        onSuccess: () => {
            department_form_move.value = false
            initDepartments()
        }
    })
}

const onSuccess = () => {
    initDepartments()
    loadDepartment(selectedDepartmentId.value)
}

onBeforeMount(() => {
    initSplitModel()
    initDepartments()
});

</script>

<style scoped>
.q-btn.disabled {
    opacity: 0.20 !important;
}
</style>