<template>
    <q-input v-model="templateData.name"
             :label="$translate('research-template.fields.name')"
             :error="templateData.errors.hasOwnProperty('name')"
             :error-message="_.get(templateData.errors,'name')"
    />
    <div>
        <q-toggle v-model="templateData.internal"
                  size="xs"
                  :label="$translate('research-template.fields.internal')"/>
    </div>
    <div class="">
        <q-toggle v-model="templateData.external"
                  size="xs"
                  :label="$translate('research-template.fields.external')"/>
        <div class="q-ml-xl column" v-if="templateData.external">
            <q-checkbox v-model="templateData.allow.allow_equipment"
                        size="xs"
                        :label="$translate('research-template.fields.external-equipment')"/>
            <q-checkbox v-model="templateData.allow.allow_consumables"
                        size="xs"
                        :label="$translate('research-template.fields.external-consumables')"/>
            <div v-if="templateData.errors.hasOwnProperty('allow')" class="text-negative">
                {{ _.get(templateData.errors, 'allow') }}
            </div>
        </div>
    </div>

    <div class="q-mb-md">
        <q-toggle v-model="templateData.assay"
                  size="xs"
                  :label="$translate('research-template.fields.assay')"/>
        <div class="q-ml-lg flex q-gutter-x-md" v-if="templateData.assay">
            <q-input v-model="templateData.assay_limit.min"
                     :prefix="$translate('research-template.fields.assay_limit_min')"
                     input-class="text-center"
                     dense/>
            <q-input v-model="templateData.assay_limit.max"
                     :prefix="$translate('research-template.fields.assay_limit_max')"
                     input-class="text-center"
                     dense/>
        </div>
    </div>

    <FieldsConstructor v-model="templateData.fields"
                       :title="$translate('research-template.fields.fields')"
                       :ssc="template.ssc_id"/>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref} from "vue";
import _ from 'lodash'
import {useQuasar} from "quasar";
import FieldsConstructor from "@/Components/Fields/FieldsConstructor.vue";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    template: {
        type: Object,
        default: () => {
            return {
                name: null,
                internal: false,
                external: false,
                ssc_id: null,
                allow: {
                    allow_equipment: false,
                    allow_consumables: false
                },
                assay: false,
                assay_limit: {
                    min: null,
                    max: null,
                },
                fields: []
            }
        }
    }
})

const templateData = ref(props.template)

onMounted(() => {
    // template.value = _.get(attrs, 'template')
    if (templateData.value.assay_limit === null) {
        templateData.value.assay_limit = {
            min: null,
            max: null,
        }
    }
})
</script>