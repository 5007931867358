<template>
    <q-card>
        <q-card-section class="flex justify-between items-center">
            <AnalyticReportsHeader :report-data="reportData"></AnalyticReportsHeader>
            <AnalyticReportsActions :report-data="reportData"></AnalyticReportsActions>
        </q-card-section>
        <q-card-section>
            <q-table :columns="columns"
                     :rows="data"
                     :pagination="{rowsPerPage: 0}"
                     :rows-per-page-options="[0]"
                     hide-pagination
                     wrap-cells
                     bordered
                     flat
            >
                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th rowspan="2" />
                        <q-th rowspan="2">
                            {{ $translate('module-analytics.table_columns.' + report + '.project_number') }}
                        </q-th>
                        <q-th rowspan="2">
                            {{ $translate('module-analytics.table_columns.' + report + '.project_name') }}
                        </q-th>
                        <q-th colspan="3" class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.for_period') }}<br/>
                            {{ $translate('buttons.from') }} {{ reportData.from }}
                            {{ $translate('buttons.to') }} {{ reportData.to }}
                        </q-th>
                    </q-tr>
                    <q-tr :props="props">
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.updated') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.date_diff') }}
                        </q-th>
                    </q-tr>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td colspan="5"><b>{{ props.row.ssc_name }}</b></q-td>
                    </q-tr>
                    <q-tr :props="props" v-for="(item, i) in props.row.project_ssc_data" :key="i">
                        <q-td />
                        <q-td>
                            <Link :href="$route('project.show', {project: item.project_number})">
                                {{ item.project_number }}
                            </Link>
                        </q-td>
                        <q-td class="ellipsis">
                            {{ item.project_name }}
                        </q-td>
                        <q-td class="text-center">{{ item.updated_at }}</q-td>
                        <q-td class="text-center">{{ item.date_diff }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </q-card-section>
    </q-card>
</template>

<script setup>
import AnalyticReportsActions from "@/Components/Analytic/Reports/AnalyticReportsActions.vue";
import AnalyticReportsHeader from "@/Components/Analytic/Reports/AnalyticReportsHeader.vue";
import {getCurrentInstance, ref} from "vue";
import {Link} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment";

const props = defineProps({
    report: {
        type: String,
    },
    reportData: {
        type: Object,
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const data = ref([]);
_.forEach(_.groupBy(props.reportData.data, 'ssc_id'), (projectSSCData, sscId) => {
    data.value.push({
        ssc_id: sscId,
        ssc_name: _.head(projectSSCData).ssc.name,
        project_ssc_data: _.map(projectSSCData, (item) => ({
            project_number: item.project.number,
            project_name: _.truncate(item.project.name, {'length': 130}),
            updated_at: moment(item.updated_at).format($translate('common.date.js')),
            date_diff: item.datediff
        }))
    });
});

const columns = [
    {name: 'space', label: '', align: 'left'},
    {
        name: 'project_number',
        label: $translate('module-analytics.table_columns.' + props.report + '.project_number'),
        align: 'left'
    },
    {
        name: 'project_name',
        label: $translate('module-analytics.table_columns.' + props.report + '.project_name'),
        align: 'left'
    },
    {name: 'updated', label: $translate('module-analytics.table_columns.' + props.report + '.updated'), align: 'left'},
    {
        name: 'date_diff',
        label: $translate('module-analytics.table_columns.' + props.report + '.date_diff'),
        align: 'left'
    },
];
</script>
