<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions no-card-section>

        <q-card-section v-if="_.get(fields,'fields.name.show')">
            <q-input v-model="publication.name"
                     :label="_.get(fields,'fields.name.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('name')"
                     :error-message="_.get(errors,'name')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.subname.show')">
            <q-input v-model="publication.subname"
                     :label="_.get(fields,'fields.subname.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('subname')"
                     :error-message="_.get(errors,'subname')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.short_name.show')">
            <q-input v-model="publication.short_name"
                     :label="_.get(fields,'fields.short_name.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('short_name')"
                     :error-message="_.get(errors,'short_name')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.conf_number.show')">
            <q-input v-model="publication.conf_number"
                     :label="_.get(fields,'fields.conf_number.label')"
                     stack-label
                     :error="errors.hasOwnProperty('conf_number')"
                     :error-message="_.get(errors,'conf_number')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.description.show')">
            <q-input v-model="publication.description"
                     :label="_.get(fields,'fields.description.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('description')"
                     :error-message="_.get(errors,'description')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.date_start.show') || _.get(fields,'fields.date_end.show')">
            <div class="row q-gutter-x-md">
                <div class="col">
                    <q-input v-if="_.get(fields,'fields.date_start.show')"
                             v-model="publication.date_start"
                             :label="_.get(fields,'fields.date_start.label')"
                             stack-label
                             type="date"
                             :error="errors.hasOwnProperty('date_start')"
                             :error-message="_.get(errors,'date_start')"/>
                </div>
                <div class="col">
                    <q-input v-if="_.get(fields,'fields.date_end.show')"
                             v-model="publication.date_end"
                             :label="_.get(fields,'fields.date_end.label')"
                             stack-label
                             type="date"
                             :error="errors.hasOwnProperty('date_end')"
                             :error-message="_.get(errors,'date_end')"/>
                </div>
            </div>
        </q-card-section>
        <q-card-section v-if="_.get(fields,'fields.placement.show')">
            <q-input v-model="publication.placement"
                     :label="_.get(fields,'fields.placement.label')"
                     stack-label
                     type="textarea"
                     rows="2"
                     :error="errors.hasOwnProperty('placement')"
                     :error-message="_.get(errors,'placement')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.country.show')">
            <q-select v-model="publication.country_id"
                      :label="_.get(fields,'fields.country.label')"
                      :options="optionsCountry"
                      map-options
                      options-dense
                      emit-value
                      stack-label
                      :error="errors.hasOwnProperty('country_id')"
                      :error-message="_.get(errors,'country_id')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.city.show')">
            <q-input v-model="publication.city"
                     :label="_.get(fields,'fields.city.label')"
                     stack-label
                     type="textarea"
                     rows="1"
                     :error="errors.hasOwnProperty('city')"
                     :error-message="_.get(errors,'city')"/>
        </q-card-section>


        <q-card-section v-if="_.get(fields,'fields.state.show')">
            <q-select v-model="publication.state_id"
                      :label="_.get(fields,'fields.state.label')"
                      :options="optionsAwardState"
                      options-dense
                      map-options
                      emit-value
                      stack-label
                      :error="errors.hasOwnProperty('state_id')"
                      :error-message="_.get(errors,'state_id')"/>
        </q-card-section>
    </Card>
</template>

<script setup>
/**************************************************
 *
 *  События - основная информация
 *
 ***************************************************/

import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import ClassificationDB from "@/plugins/ClassificationDB";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object}
})
const publication = defineModel('publication', {type: Object})
const errors = defineModel('errors', {type: Object})

const optionsCountry = ref(null)
const optionsAwardState = ref(null)

onMounted(() => {
    ClassificationDB
        .getSimpleList('country')
        .then((r) => {
            optionsCountry.value = r
        })
    ClassificationDB
        .getSimpleList('awardstate')
        .then((r) => {
            optionsAwardState.value = r
        })
})

</script>