<template>
    <q-card>
        <q-card-section class="flex justify-between items-center">
            <AnalyticReportsHeader :report-data="reportData"></AnalyticReportsHeader>
            <AnalyticReportsActions :report-data="reportData"></AnalyticReportsActions>
        </q-card-section>
        <q-card-section>
            <q-table :columns="columns"
                     :rows="data"
                     :pagination="{rowsPerPage: 0}"
                     :rows-per-page-options="[0]"
                     hide-pagination
                     wrap-cells
                     bordered
                     flat
            >
                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th colspan="2" class="text-left">
                            {{ $translate('module-analytics.table_columns.' + report + '.ssc') }}
                        </q-th>
                        <q-th colspan="5" class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.for_period') }}<br/>
                            {{ $translate('buttons.from') }} {{ reportData.from }}
                            {{ $translate('buttons.to') }} {{ reportData.to }}
                        </q-th>
                    </q-tr>
                    <q-tr :props="props">
                        <q-th />
                        <q-th>{{ $translate('module-analytics.table_columns.' + report + '.name') }}</q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.planned_time') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.max_time') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.work_time') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.percent') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.target') }}
                        </q-th>
                    </q-tr>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td colspan="7"><b>{{ props.row.ssc }}</b></q-td>
                    </q-tr>
                    <template v-for="(equipment, i) in props.row.equipments" :key="i">
                        <q-tr :props="props" :class="getRowClass(equipment)">
                            <q-td></q-td>
                            <q-td>
                                <Link :href="$route('equipment.show', {equipment: equipment.id})">
                                    {{ equipment.name }}
                                </Link>
                            </q-td>
                            <q-td class="text-right">{{ equipment.planned_time }}</q-td>
                            <q-td class="text-right">{{ equipment.max_time }}</q-td>
                            <q-td class="text-right">{{ equipment.work_time }}</q-td>
                            <q-td class="text-right" :class="getPercentClass(equipment)">
                                {{ equipment.percent }}%
                            </q-td>
                            <q-td class="text-right">{{ equipment.target }}%</q-td>
                        </q-tr>
                    </template>
                </template>
            </q-table>
        </q-card-section>
    </q-card>
</template>

<script setup>
import AnalyticReportsActions from "@/Components/Analytic/Reports/AnalyticReportsActions.vue";
import AnalyticReportsHeader from "@/Components/Analytic/Reports/AnalyticReportsHeader.vue";
import {getCurrentInstance, ref} from "vue";
import {usePage, Link} from "@inertiajs/vue3";
import _ from "lodash";
import helpers from "@/helpers";

const props = defineProps({
    report: {
        type: String,
    },
    reportData: {
        type: Object,
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const data = ref([]);
_.forEach(props.reportData.data, (equipments, ssc) => {
    data.value.push({
        ssc: ssc,
        equipments: _.map(equipments, (equipment) => ({
            id: equipment.id,
            name: equipment.name,
            planned_time: helpers.formatNumber(Number(_.get(equipment, 'planned_time', 0)), 2),
            max_time: helpers.formatNumber(Number(_.get(equipment, 'max_time', 0)), 2),
            work_time: helpers.formatNumber(Number(_.get(equipment, 'work_time', 0)), 2),
            percent: helpers.formatNumber(Number(_.get(equipment, 'percent', 0)), 2),
            target: usePage().props.EQUIPMENT_REPORT_TARGET
        }))
    });
});

const getRowClass = (equipment) => 0 === Number(equipment.planned_time) && 0 < Number(equipment.work_time) ?
        'bg-warning' :
        '';
const getPercentClass = (equipment) => {
    if (Number(equipment.target) < Number(equipment.percent) && 0 === Number(equipment.max_time)) {
        return 'bg-red-6 text-white';
    }
    if (100 < Number(equipment.percent)) {
        return 'bg-warning text-white';
    }
    if (Number(equipment.percent) > Number(equipment.target)) {
        return 'bg-positive text-white';
    }
};

const columns = [
    {name: 'space', label: '', align: 'left'},
    {name: 'name', label: $translate('module-analytics.table_columns.' + props.report + '.name'), align: 'left'},
    {
        name: 'planned_time',
        label: $translate('module-analytics.table_columns.' + props.report + '.planned_time'),
        align: 'left'
    },
    {
        name: 'max_time',
        label: $translate('module-analytics.table_columns.' + props.report + '.max_time'),
        align: 'left'
    },
    {
        name: 'work_time',
        label: $translate('module-analytics.table_columns.' + props.report + '.work_time'),
        align: 'left'
    },
    {name: 'percent', label: $translate('module-analytics.table_columns.' + props.report + '.percent'), align: 'left'},
    {name: 'target', label: $translate('module-analytics.table_columns.' + props.report + '.target'), align: 'left'},
];
</script>
