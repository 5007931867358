<template>
    <q-card-section>
        <div class="row q-gutter-x-md">
            <div class="col-6">
                <q-select v-model="field.type"
                          :label="$translate('fields.label.type')"
                          :options="fieldTypes"
                          options-dense
                          map-options
                          emit-value
                          dense
                          @update:model-value="setType"/>
            </div>
            <div class="col-6">
                <q-input v-model="field.name"
                         :label="$translate('fields.label.name')"
                         :hint="$translate('fields.label.name-hint')"
                         mask="x"
                         :maxlength="50"
                         reverse-fill-mask
                         dense/>
            </div>
        </div>
        <div class="row q-gutter-x-md">
            <div class="col">
                <template v-for="(name,lang) in $translate('fields.locale')">
                    <q-input :label="$translate('fields.label.label') + ' ' + name" stack-label
                             :maxlength="50"
                             v-model="field.label[lang]"/>
                </template>
            </div>
            <div class="col" v-if="field.type!=='alert'">
                <template v-for="(name,lang) in $translate('fields.locale')">
                    <q-input :label="$translate('fields.label.description') + ' ' + name" stack-label
                             v-model="field.description[lang]"/>
                </template>
            </div>
        </div>
    </q-card-section>
    <q-card-section v-if="field.type!=='alert'">
        <div>
            <q-checkbox v-model="field.hideLabel"
                        :label="$translate('fields.label.hideLabel')"
                        size="xs"/>
        </div>
        <div v-if="!field.hideLabel">
            <q-checkbox v-model="field.required"
                        :label="$translate('fields.label.required')"
                        size="xs"/>
        </div>
        <div v-if="!field.hideLabel">
            <q-checkbox v-if="uniqueSource" v-model="field.unique"
                        :label="$translate('fields.label.unique')"
                        size="xs"/>
        </div>
    </q-card-section>

    <q-card-section v-if="field.type === 'input:number'">
        <div class="row q-gutter-x-md">
            <q-input type="number"
                     v-model="field.variants.min"
                     :label="$translate('fields.label.min')"
                     stack-label/>
            <q-input type="number"
                     v-model="field.variants.max"
                     :label="$translate('fields.label.max')"
                     stack-label/>
        </div>
    </q-card-section>

    <q-card-section v-if="field.type === 'alert'">
        <div class="row q-gutter-x-md">
            <q-select type="number"
                      v-model="field.variants.type"
                      class="col-8"
                      :options="fieldOptionsType"
                      :label="$translate('fields.label.variants.alert')"
                      stack-label
                      options-dense
                      map-options
                      emit-value/>
            <div class="col">
                <Alert :message="field.label[locale]" :variant="field.variants.type"/>
            </div>
        </div>
    </q-card-section>

    <q-card-section v-if="hasOptions">
        <div class="row q-gutter-x-md">
            <q-select type="number"
                      v-model="field.variants.type"
                      class="col-8"
                      :options="fieldOptionsType"
                      :label="$translate('fields.label.variants.type')"
                      stack-label
                      options-dense
                      map-options
                      emit-value/>
            <div class="col">
                <q-field v-if="field.variants.type==='self'">
                    <template v-slot:control>
                        <template v-for="(value) in field.variants.values">
                            <q-chip dense square :title="_.get(value.label,'ru')">{{ value.value }}</q-chip>
                        </template>
                    </template>
                    <template v-slot:prepend>
                        <q-btn icon="list"
                               :title="$translate('fields.label.variants.list')"
                               color="grey-6"
                               @click="selfListModal = true"
                               dense/>
                    </template>
                    <q-dialog v-model="selfListModal">
                        <Card in-modal :title="$translate('собственный список')" show-close-button no-card-section>
                            <q-table :rows-per-page-options="[0]"
                                     :columns="selfListColumns"
                                     :rows="field.variants.values"
                                     separator="none"
                                     dense>
                                <template v-slot:body-cell-value="props">
                                    <q-td :props="props">
                                        <q-input v-model="props.row.value" size="sm"
                                                 label="значение"
                                                 dense stack-label/>
                                    </q-td>
                                </template>
                                <template v-slot:body-cell-label="props">
                                    <template v-for="(locale,lang) in $translate('fields.locale')">
                                        <q-td :props="props">
                                            <q-input v-model="props.row.label[lang]"
                                                     class="col"
                                                     :label="locale"
                                                     dense
                                                     stack-label/>
                                        </q-td>
                                    </template>
                                </template>
                                <template v-slot:body-cell-action="props">
                                    <q-td style="width:1%">
                                        <q-btn icon="o_delete" color="grey-8"
                                               :title="$translate('buttons.delete')"
                                               @click="field.variants.values.splice(props.rowIndex, 1)"
                                               flat
                                               dense/>
                                    </q-td>
                                </template>
                            </q-table>
                            <template v-slot:actions>
                                <q-btn icon="add" :label="$translate('buttons.add')"
                                       color="info"
                                       @click="field.variants.values.push({value:null,label:{}})"
                                       dense/>
                                <q-btn icon="save" :label="$translate('buttons.close')"
                                       color="positive"
                                       @click="selfListModal = false"
                                       dense/>
                            </template>
                        </Card>
                    </q-dialog>
                </q-field>
                <q-select v-else-if="field.variants.type!=null"
                          v-model="field.variants[field.variants.type]"
                          :options="fieldOptionsVariants"
                          :label="$translate('fields.label.variants.source')"
                          stack-label
                          options-dense
                          emit-value
                          map-options></q-select>
            </div>
        </div>
    </q-card-section>
</template>

<script setup>
import {useQuasar} from "quasar";
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch} from "vue";
import _ from "lodash";
import axios from "axios";
import {usePage} from "@inertiajs/vue3";
import Alert from "@/Components/Block/Alert.vue";
import Card from "@/Components/Block/Card.vue";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    uniqueSource: {
        type: Boolean,
        default: false
    }
})

const field = defineModel({
    type: Object,
    default: {
        type: null,
        name: null,
        label: {},
        hideLabel: false,
        description: {},
        required: false,
        unique: false,
        variants: {},
        rules: []
    }
});

/**
 *
 * @type {ComputedRef<string>}
 */
const locale = computed(() => usePage().props.locale)

/**
 *
 * @type {Ref<UnwrapRef<{classificator: *[], model: *[], scienceClassificator: *[]}>>}
 */
const options = ref({
    model: [],
    classificator: [],
    scienceClassificator: [],
})

/**
 * Типы полей
 *
 * @type {ComputedRef<{label: string, value: string}[]>}
 */
const fieldTypes = computed(() => {
    return _.map($translate('fields.types'), (item, type) => ({
        value: type,
        label: item.label
    }))
})

/**
 * Проверка типа поля на доступность списка
 * @type {ComputedRef<boolean>}
 */
const hasOptions = computed(() => {
    switch (field.value.type) {
        case "select":
        case "select:multi":
        case "input:checkbox":
        case "input:radio":
            return true;
        default:
            return false;
    }
})

/**
 * Тип списка доступного для данного типа поля
 *
 * @type {ComputedRef<{label: string, value: string}[]>}
 */
const fieldOptionsType = computed(() => {
    let _options = []
    _.forEach($translate('fields.variants'), (v, k) => {
        if ($translate('fields.types.' + field.value.type + '.variants').includes(k)) {
            _options.push({
                value: k,
                label: v
            })
        }
    })
    return _options
})

/**
 * Варианты типа списка
 *
 * @type {ComputedRef<{label: string, value: string}[]>}
 */
const fieldOptionsVariants = computed(() => {
    let _options = _.get(options.value, _.get(field.value.variants, 'type'))
    if (_options) {
        return _.sortBy(
            _.map(_options, (item) => ({
                value: item.id,
                label: item.text
            })),
            'label');
    }
    return [];
})

const selfListModal = ref(false)
const selfListColumns = [
    {name: 'value', label: $translate('fields.label.self-list.value'), align: 'center'},
    {name: 'label', label: $translate('fields.label.self-list.label'), align: 'center'},
    {name: 'action', label: '', align: 'center'},
]

const setType = () => {
    switch (field.value.type) {
        case "alert":
            _.set(field.value, 'variants', {
                type: null,
            })
            break;
        case "input:number":
            if (!_.has(field.value, 'variants.min')) {
                _.set(field.value, 'variants', {
                    min: null, max: null
                })
            }
            break;
        case "input:checkbox":
        case "input:radio":
            if (!_.has(field.value, 'variants.values')) {
                _.set(field.value, 'variants', {
                    type: null,
                    values: [],
                    classificator: null,
                })
            }
            break;
        case "select":
        case "select:multi":
            if (!_.has(field.value, 'variants.values')) {
                _.set(field.value, 'variants', {
                    type: null,
                    values: [],
                    model: null,
                    classificator: null,
                    science_classificator: null
                })
            }
            break;
    }
}
onMounted(() => {
    axios.get('/classificator.json?m=classificator').then((response) => {
        _.set(options.value, 'classificator', response.data.results)
    })
    axios.get('/classificator.json?m=science_classificator').then((response) => {
        _.set(options.value, 'science_classificator', response.data.results)
    })
    axios.get('/classificator.json?m=model').then((response) => {
        _.set(options.value, 'model', response.data.results)
    })
})

watch(field, () => {
    setType()
})
</script>