<template>
    <Alert variant="info">
        Проверьте данные по проекту, при необходимости вернитесь на нужный шаг и исправьте данные
    </Alert>
    <div class="column q-gutter-y-md">
        <Card :title="$translate('project.form.step.main-info.title')" class="col"
              hide-actions>
            <q-list separator>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ $translate('project.form.fields.source') }}</q-item-label>
                        <q-item-label>{{ project.sourceInfo.label }}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ $translate('project.form.fields.type') }}</q-item-label>
                        <q-item-label>{{ project.type.name }}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ $translate('project.form.fields.name') }}</q-item-label>
                        <q-item-label>{{ project.name }}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ $translate('project.form.fields.description') }}</q-item-label>
                        <q-item-label>
                            <div v-html="project.description"></div>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ $translate('project.form.fields.targets') }}</q-item-label>
                        <q-item-label>
                            <div v-html="project.targets"></div>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <div class="q-ml-md">
                    <FieldsValues :fields="project.type.fields" :values="project.additional"/>
                </div>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ $translate('project.form.fields.keywords') }}</q-item-label>
                        <q-item-label>
                            <template v-for="(keyword) in project.keywords">
                                <q-chip>{{ keyword }}</q-chip>
                            </template>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ $translate('project.form.fields.period') }}</q-item-label>
                        <q-item-label>
                            <div>
                                {{ moment(project.date_start).format($translate('common.date.js')) }}
                                &mdash;
                                {{ moment(project.date_end).format($translate('common.date.js')) }}
                            </div>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ $translate('project.form.fields.expected-result') }}</q-item-label>
                        <template v-for="(expResult) in project.type.expected_result_array">
                            <div class="q-my-sm">
                                <q-item-label>
                                    <span>{{ expResult.name }}: </span>
                                    <b>{{ _.get(project.expectedresult, expResult.slug + '.count') || "&mdash;" }}</b>
                                </q-item-label>
                                <q-item-label caption class="q-ml-md">
                                    {{ _.get(project.expectedresult, expResult.slug + '.comment') }}
                                </q-item-label>
                            </div>
                        </template>
                    </q-item-section>
                </q-item>

            </q-list>
        </Card>
        <Card :title="$translate('project.form.title.members')" class="col"
              hide-actions no-card-section>
            <q-list separator>
                <q-item>
                    <q-item-section>
                        <q-item-label>
                            {{ project.leader.first_name }}
                            {{ project.leader.middle_name }}
                            {{ project.leader.last_name }}
                        </q-item-label>
                        <q-item-label caption>
                            {{ _.get(_.find(project.leader.additional, {slug: 'position'}), 'value') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ $translate('project.view.leader-info') }}</q-item-label>
                    </q-item-section>
                </q-item>
                <template v-for="(member) in project.members">
                    <q-item>
                        <q-item-section>
                            <q-item-label>
                                {{ member.user.first_name }}
                                {{ member.user.middle_name }}
                                {{ member.user.last_name }}
                            </q-item-label>
                            <q-item-label caption>
                                {{ _.get(_.find(member.user.additional, {slug: 'position'}), 'value') }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ _.get(member, 'role.label') }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </Card>
        <Card :title="$translate('project.form.title.departments')" class="col"
              hide-actions no-card-section>
            <q-list separator>
                <template v-for="(ssc) in project.ssc">
                    <q-item>
                        <q-item-section>
                            <q-item-label class="text-weight-bold">{{ ssc.ssc.name }}</q-item-label>
                            <q-item-label v-if="!ssc.ssc.unlimited">
                                {{ $translate('project.view.ssc-count-description') + ':' }} <b>{{ ssc.count }}</b>
                            </q-item-label>
                            <q-item-label v-if="ssc.ssc.ssc_show_work_description" caption>
                                {{ $translate('project.view.ssc-work-description') }}
                            </q-item-label>
                            <q-item-label>{{ ssc.description }}</q-item-label>
                        </q-item-section>

                    </q-item>
                </template>
            </q-list>
        </Card>
    </div>
</template>

<script setup>
import {getCurrentInstance, ref,} from "vue";
import _ from "lodash";
import {useQuasar} from "quasar";
import moment from "moment";
import {Card, Alert} from "@/Components/Block";
import FieldsValues from "@/Components/Fields/FieldsValues.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $q = useQuasar()

const props = defineProps(['project'])

</script>