<template>
    <q-btn :label="$translate('module-ethics.application.card.actions.print')"
           @click="printApplication"
           icon="o_print"
           dense
    />
    <q-btn v-if="_.get(can, 'edit')"
           :label="$translate('module-ethics.application.card.actions.edit')"
           @click="router.get($route('services.ethics.application.edit', [application]))"
           color="secondary"
           icon="o_edit"
           dense
    />
    <q-btn v-if="_.get(can, 'approve')"
           :label="$translate('module-ethics.application.card.change-state.approve')"
           @click="router.post(getChangeStateRoute(1))"
           color="positive"
           icon="o_check"
           dense
    />
    <template v-if="_.get(can, 'decline')">
        <q-btn :label="$translate('module-ethics.application.card.change-state.decline')"
               @click="declineDialog = !declineDialog"
               color="negative"
               icon="o_close"
               dense
        />
        <q-dialog v-model="declineDialog">
            <Card :title="$translate('module-ethics.application.card.change-state.decline_title', {
                      number: _.get(application, 'number')
                  })"
                  style="width: 500px"
            >
                <template v-slot:headerActions>
                    <q-btn icon="close" v-close-popup round dense flat />
                </template>
                <template v-slot:default>
                    <q-input v-model="declineForm.comment"
                             :label="$translate('module-ethics.application.card.change-state.decline_comment')"
                             type="textarea"
                             rows="3"
                    />
                </template>
                <template v-slot:actions>
                    <q-btn :label="$translate('module-ethics.application.card.change-state.decline')"
                           @click="submitDeclineForm"
                           color="negative"
                           dense
                    />
                </template>
            </Card>
        </q-dialog>
    </template>
    <q-btn v-if="_.get(can, 'confirmDocs')"
           :label="$translate('module-ethics.application.card.change-state.confirm_docs')"
           @click="router.post(getChangeStateRoute(2))"
           icon="o_check"
           dense
    />
    <template v-if="_.get(can, 'saveMeetingDate')">
        <q-btn :label="$translate('module-ethics.application.card.change-state.save_meeting_date')"
               @click="meetingDateDialog = !meetingDateDialog"
               icon="o_close"
               dense
        />
        <q-dialog v-model="meetingDateDialog">
            <Card :title="$translate('module-ethics.application.card.change-state.save_meeting_date')"
                  style="width: 500px"
            >
                <template v-slot:headerActions>
                    <q-btn icon="close" v-close-popup round dense flat />
                </template>
                <template v-slot:default>
                    <q-input v-model="meetingDateForm.meeting_date"
                             :label="$translate('module-ethics.application.card.change-state.meeting_date')"
                             type="date"
                    />
                </template>
                <template v-slot:actions>
                    <q-btn :label="$translate('buttons.save')"
                           @click="submitMeetingDateForm"
                           color="positive"
                           dense
                    />
                </template>
            </Card>
        </q-dialog>
    </template>
    <q-btn v-if="_.get(can, 'finallyApprove')"
           :label="$translate('module-ethics.application.card.change-state.finally_approve')"
           @click="router.post(getChangeStateRoute(4))"
           icon="o_check"
           dense
    />
    <q-btn v-if="_.get(can, 'finallyDecline')"
           :label="$translate('module-ethics.application.card.change-state.finally_decline')"
           @click="router.post(getChangeStateRoute(5))"
           icon="o_close"
           dense
    />
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    application: Object,
    can: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const declineDialog = ref(false);
const declineForm = useForm({
    comment: ''
});

const meetingDateDialog = ref(false);
const meetingDateForm = useForm({
    meeting_date: null
});

const getChangeStateRoute = (state) => route(
    'services.ethics.application.change-state',
    [props.application, state]
);

const submitDeclineForm = () => {
    declineForm.post(getChangeStateRoute(6));
};
const submitMeetingDateForm = () => {
    meetingDateForm.post(getChangeStateRoute(3));
};

const printApplication = () => {
    window.open(
        route('services.ethics.application.print', props.application),
        'printwindow',
        'scrollbars=1,width=1000,height=600'
    );
};
</script>
