<template>
    <h5 class="q-my-none">
        {{ $translate('research-report.expenses.title') }}
    </h5>
    <table class="q-table page-break q-table--horizontal-separator">
        <tbody>
        <tr class="text-bold">
            <td class="w-30">{{ $translate('research-report.expenses.project.number') }}:</td>
            <td>#{{ research.project.number }}</td>
        </tr>
        <tr>
            <td class="w-30">{{ $translate('research-report.expenses.project.name') }}:</td>
            <td>{{ research.project.name }}</td>
        </tr>
        <tr>
            <td class="w-30">{{ $translate('research-report.expenses.project.leader') }}:</td>
            <td>{{ research.project.leader.fulled_name }}</td>
        </tr>
        <tr class="text-bold">
            <td class="w-30">{{ $translate('research-report.expenses.research.number') }}:</td>
            <td>#{{ research.number }}</td>
        </tr>
        <tr>
            <td class="w-30">{{ $translate('research-report.expenses.research.name') }}:</td>
            <td>{{ research.template.name }}</td>
        </tr>
        <tr>
            <td class="w-30">{{ $translate('research-report.expenses.research.responsible') }}:</td>
            <td>{{ research.responsible.fulled_name }}</td>
        </tr>
        <tr class="text-bold">
            <td class="w-30">{{ $translate('research-report.expenses.total-cost') }}:</td>
            <td>{{ helpers.formatMoney(_.sumBy(research.expenses, 'cost')) }}</td>
        </tr>
        </tbody>
    </table>


    <template v-for="(expenses) in research.expenses">
        <h6 class="q-mb-none">{{ $translate('research-report.expenses.expenses') }}
            {{ moment(expenses.date).format($translate('common.date.js')) }}</h6>

        <template v-if="expenses.equipment">
            <h6 class="q-my-none">{{ $translate('research-report.expenses.expenses-equipment') }}</h6>
            <table class="q-table table-bordered page-break q-table--horizontal-separator">
                <thead>
                <tr>
                    <th class="">{{ $translate('research-report.expenses.table.equipment') }}</th>
                    <th class="w-20 text-center">{{ $translate('research-report.expenses.table.time') }}</th>
                    <th class="w-20 text-center">{{ $translate('research-report.expenses.table.cost') }}</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(items, id) in expenses.equipment['items']">
                    <tr v-for="(item) in items">
                        <td class="">
                            <div>{{ _.get(item, 'equipment.display_name') }}</div>
                            <div>{{ _.get(item, 'comment') }}</div>
                            <small>Добавлено: {{ _.get(item, 'user.full_name') }}</small>
                        </td>
                        <td class="w-20 text-center">{{ helpers.int2time(_.get(item, 'used_time')) }}</td>
                        <td class="w-20 text-right">{{ helpers.formatMoney(_.get(item, 'cost')) }}</td>
                    </tr>
                </template>
                </tbody>
            </table>
        </template>
        <template v-if="expenses.consumables">
            <h6 class="q-my-none">{{ $translate('research-report.expenses.expenses-consumables') }}</h6>
            <table class="q-table table-bordered page-break q-table--horizontal-separator">
                <thead>
                <tr>
                    <th class="">{{ $translate('research-report.expenses.table.consumable') }}</th>
                    <th class="w-20 text-center">{{ $translate('research-report.expenses.table.count') }}</th>
                    <th class="w-20 text-center">{{ $translate('research-report.expenses.table.cost') }}</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(items, id) in expenses.consumables['items']">
                    <tr v-for="(item, id) in items">
                        <td class="">{{ _.get(item, 'consumable.name') }}</td>
                        <td class="w-20 text-center">{{ helpers.formatNumber(_.get(item, 'used_count')) }}
                            {{ _.get(items[0], 'consumable.unit.name') }}
                        </td>
                        <td class="w-20 text-right">{{ helpers.formatMoney(_.get(item, 'cost')) }}</td>
                    </tr>
                </template>
                </tbody>
            </table>
        </template>
        <template v-if="expenses.worktime">
            <h6 class="q-my-none">{{ $translate('research-report.expenses.expenses-worktime') }}</h6>
            <table class="q-table table-bordered page-break q-table--horizontal-separator">
                <thead>
                <tr>
                    <th class="">{{ $translate('research-report.expenses.table.user') }}</th>
                    <th class="w-20 text-center">{{ $translate('research-report.expenses.table.time') }}</th>
                    <th class="w-20 text-center">{{ $translate('research-report.expenses.table.cost') }}</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(items, id) in expenses.worktime['items']">
                    <tr v-for="(item,id) in items">
                        <td class="">{{ _.get(item, 'user.fulled_name') }}</td>
                        <td class="w-20 text-center">{{ helpers.int2time(_.get(item, 'used_time')) }}</td>
                        <td class="w-20 text-right">{{ helpers.formatMoney(_.get(item, 'cost')) }}</td>
                    </tr>
                </template>
                </tbody>
            </table>
        </template>
    </template>
</template>

<script setup>
import Print from "@/Layout/Print.vue";
import {getCurrentInstance} from "vue";
import helpers from "@/helpers";
import _ from "lodash";
import moment from "moment";

defineOptions({layout: Print});

const props = defineProps({
    research: Object,
    expenses_equipment: Array,
    expenses_consumables: Array,
    expenses_worktime: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

</script>