<template>
    <Card :title="$translate('report-template.title.list')"
          title-bg="blue-grey-3"
          header-toolbar
          hide-actions
          no-card-section>

        <q-table ref="tableRef"
                 row-key="id"
                 :rows="items"
                 :columns="columns"
                 :filter="filter"
                 :loading="loading"
                 @request="onRequest"
                 v-model:pagination="pagination"
                 :rows-per-page-options="[10,50,100]"
                 wrap-cells
                 flat
        >
            <template v-slot:body-cell-name="props">
                <q-td :props="props">{{ props.row.name }}</q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @onSuccess="onSuccess">
                    </DataTableActions>
                </q-td>
            </template>
        </q-table>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import _ from 'lodash'
import {useQuasar} from "quasar";
import axios from "axios";
import {Card} from "@/Components/Block";
import DataTableActions from "@/Components/DataTableActions.vue";


const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const tableRef = ref();
const filter = ref(null);
const loading = ref(false);
const items = ref([]);


const columns = [
    {name: 'actions', style: 'width:40px'},
    {
        name: 'name',
        label: $translate('report-template.form.fields.name'),
        field: 'name',
        align: 'left',
    },
    {
        name: 'allow',
        label: $translate('report-template.form.fields.allow'),
        field: 'allow_text',
        classes: 'text-no-wrap',
        align: 'center',
    },
    {
        name: 'usage',
        label: $translate('report-template.form.fields.usage'),
        field: 'usage_text',
        align: 'center',
    },
    {
        name: 'user',
        label: $translate('report-template.form.fields.author'),
        field: 'user',
        align: 'left',
    },
]

const pagination = ref({
    sortBy: null,
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});


const onRequest = (request) => {
    const {page, rowsNumber, rowsPerPage, sortBy, descending} = request.pagination;
    const filter = request.filter;

    let params = {
        m: 'template_list',
        page: 1,
        per_page: 10,
        datatable: {
            sort: {},
            search: null
        }
    };

    //console.debug(request)
    loading.value = true;

    params.page = page;
    params.per_page = rowsPerPage

    params.datatable['search'] = _.size(filter) > 0 ? filter : null;

    if (sortBy) {
        params.datatable['sort'][sortBy] = {'field': sortBy, 'direction': descending ? 'desc' : 'asc'};
    }

    axios.get(route('ajax.report'), {
        params: params,
    })
        .then((response) => {
            items.value = response.data.results.data;
            pagination.value.page = response.data.results.current_page;
            pagination.value.rowsPerPage = response.data.results.per_page;
            pagination.value.sortBy = sortBy;
            pagination.value.descending = descending;
            pagination.value.rowsNumber = response.data.results.total;
        })
        .finally(() => {
            loading.value = false;
        });
};

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};

onMounted(() => {
    onSuccess()
});
</script>