<template>
    <q-table :columns="cols"
             :rows="$translate('fields.weekdays')"
             hide-pagination hide-no-data flat dense
             :pagination="{rowsPerPage: 0}"
             :rows-per-page-options="[0]"
    >
        <template v-slot:header-cell="props">
            <q-th class="text-center text-uppercase">
                {{ props.col.label }}
            </q-th>
        </template>
        <template v-slot:body-cell-day="props">
            <q-td class="text-center text-uppercase text-bold" :props="props">
                {{ props.row.label }}
            </q-td>
        </template>
        <template v-slot:body-cell-time_start="props">
            <q-td :props="props">
                <q-input v-model="worktime.time_start[props.row.day]"
                         type="time" input-class="text-center"
                         clearable clear-icon="clear"
                         :error="worktime.errors.hasOwnProperty('time_start.'+props.row.day)"
                         :error-message="_.get(worktime.errors,'time_start.'+props.row.day)"/>
            </q-td>
        </template>
        <template v-slot:body-cell-time_end="props">
            <q-td :props="props">
                <q-input v-model="worktime.time_end[props.row.day]"
                         type="time" input-class="text-center"
                         clearable clear-icon="clear"
                         :error="worktime.errors.hasOwnProperty('time_end.'+props.row.day)"
                         :error-message="_.get(worktime.errors,'time_end.'+props.row.day)"/>
            </q-td>
        </template>
    </q-table>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

const worktime = defineModel({type: Object})

const cols = [
    {name: 'day', label: '', field: 'label', style: 'width:3rem', align: "center" as "center"},
    {name: 'time_start', field: 'time_start', label: $translate('cadre-worktime.label.time_start'), align: "center" as "center"},
    {name: 'time_end', field: 'time_end',  label: $translate('cadre-worktime.label.time_end'), align: "center" as "center"},
]
</script>