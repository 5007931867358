<template>
    <Card :title="_.get(commonFields,'name')??'Основная информация'"
          hide-actions>

        <template v-slot:headerActions>
            <ReportMaterialID :publication="publication"/>
        </template>

        <q-field tag="div"
                 :label="$translate('publication.detail.type')"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width">
                    {{ $translate('report-type.type.' + publication.report_type_category) }}
                    &mdash;
                    {{ publication.report_type.name }}
                </div>
            </template>
        </q-field>

        <q-field v-if="_.get(publication.report_type.fields,'category.show') && !publication.category_id"
                 tag="div"
                 :label="publication.report_type.fields.category.name"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width">
                    {{ $translate('report-type.category.' + publication.category) }}
                </div>
            </template>
        </q-field>

        <q-field v-if="_.get(publication.report_type.fields,'category.show') && publication.category_id"
                 tag="div"
                 :label="publication.report_type.fields.category.name"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width">
                    {{ publication.category.name }}
                </div>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.name.show')"
                 tag="div"
                 :label="commonFields.fields.name.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width" v-html="publication.name ?? '-'"/>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.description.show')"
                 tag="div"
                 :label="commonFields.fields.description.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width" v-html="publication.description ?? '-'"/>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.result.show')"
                 tag="div"
                 :label="commonFields.fields.result.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width" v-html="publication.result ?? '-'"/>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.comment.show')"
                 tag="div"
                 :label="commonFields.fields.comment.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width" v-html="publication.comment ?? '-'"/>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.lang.show')"
                 tag="div"
                 :label="commonFields.fields.lang.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width">
                    {{ _.get(publication, 'lang.name', '-') }}
                </div>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.predmet.show')"
                 tag="div"
                 :label="commonFields.fields.predmet.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width" v-html="publication.predmet ?? '-'"/>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.subname.show')"
                 tag="div"
                 :label="commonFields.fields.subname.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width" v-html="publication.subname ?? '-'"/>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.referat.show')"
                 tag="div"
                 :label="commonFields.fields.referat.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width" v-html="publication.referat ?? '-'"/>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.date.show')"
                 tag="div"
                 :label="commonFields.fields.date.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width"
                     v-html="publication.date ? moment(publication.date).format($translate('common.date.js')) : '-'"/>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.state.show')"
                 tag="div"
                 :label="commonFields.fields.state.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width"
                     v-html="publication.state ? publication.state.name : '-'"/>
            </template>
        </q-field>

        <q-field v-if="_.get(publication.report_type.fields,'stage.show')"
                 tag="div"
                 :label="publication.report_type.fields.stage.name"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <template v-for="(stage) in publication.stage">
                    <div class="items-center row q-gutter-x-md">
                        <span>{{ $translate('report-type.stage.' + stage.type) }}</span>
                        <span>{{ moment(stage.date).format($translate('common.date.js')) }}</span>
                    </div>
                </template>
            </template>
        </q-field>

        <q-field v-if="_.get(publication.report_type.fields,'apc.show')"
                 tag="div"
                 :label="publication.report_type.fields.apc.name"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center" v-if="_.get(publication,'apc.pay') === '1'">
                    {{ publication.apc.pay }} {{ $translate('units.rub') }}
                </div>
                <div v-else>
                    {{ $translate('buttons.no') }}
                </div>
            </template>
        </q-field>

        <q-field v-if="_.get(publication.report_type.fields,'reviewing.show')"
                 tag="div"
                 :label="publication.report_type.fields.reviewing.name"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width">
                    {{ $translate('report-type.reviewing.' + publication.reviewing) }}
                </div>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.object.show')"
                 tag="div"
                 :label="commonFields.fields.object.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div v-if="publication.object_type">{{ publication.object.name }}</div>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.target.show')"
                 tag="div"
                 :label="commonFields.fields.target.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width">
                    <div v-if="publication.target_type">{{ publication.target.name }}</div>
                </div>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.contragent.show')"
                 tag="div"
                 :label="commonFields.fields.contragent.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width">
                    <div v-if="publication.contragent_id">{{ publication.contragent.name }}</div>
                </div>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.events.show')"
                 tag="div"
                 :label="commonFields.fields.events.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width">
                    <div v-if="publication.event_id">{{ publication.event.name }}</div>
                </div>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.issn.show')"
                 tag="div"
                 :label="commonFields.fields.issn.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width">
                    <div v-if="publication.issn">{{ _.map(publication.issn, 'value').join("; ") }}</div>
                </div>
            </template>
        </q-field>

        <q-field v-if="_.get(commonFields,'fields.publisher.show')"
                 tag="div"
                 :label="commonFields.fields.publisher.label"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width">
                    <div v-if="publication.publisher">{{ _.get(publication, 'publisher.name') }}</div>
                </div>
            </template>
        </q-field>

        <q-field v-if="_.size(publication.countrys)>0"
                 tag="div"
                 :label="$translate('Страна(ы)')"
                 stack-label
                 dense
                 borderless>
            <template v-slot:control>
                <div class="items-center full-width">
                    {{ _.map(publication.countrys, 'name').join("; ") }}
                </div>
            </template>
        </q-field>

        <FieldsValues :fields="_.get(commonFields,'fields_additional')"
                      :values="publication.common_fields"
                      dense/>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import moment from "moment";
import _ from "lodash";
import {Card} from "@/Components/Block";
import FieldsValues from "@/Components/Fields/FieldsValues.vue";
import ReportMaterialID from "@/Components/ReportMaterial/Block/ID.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    publication: {type: Object}
})

const commonFields = computed(() => {
    return props.publication.report_type.fields.common;
})
</script>