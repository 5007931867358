<template>
    <q-list separator>
        <q-item class="text-bold">
            <q-item-section>
                <q-item-label>Участник гранта</q-item-label>
            </q-item-section>
            <q-item-section>
                <q-item-label>Пользователь в системе</q-item-label>
            </q-item-section>
            <q-item-section>
                <q-item-label>Роль в проекте</q-item-label>
            </q-item-section>
            <q-item-section side>
                &nbsp;
            </q-item-section>
        </q-item>

        <template v-for="(member,index) in members">
            <q-item>
                <q-item-section>
                    <q-item-label>
                        {{ member.name }}
                    </q-item-label>
                    <q-item-label caption>
                        <div>{{ _.get(member, 'role.value') }}</div>
                    </q-item-label>
                </q-item-section>
                <q-item-section v-if="_.size(member.users)===0">
                    <q-item-label class="text-negative">
                        {{ $translate('module-pure.project.errors.person-not-found') }}
                    </q-item-label>
                </q-item-section>
                <q-item-section v-if="_.size(member.users)===1">
                    <q-item-label>
                        {{ member.users[0].first_name }}
                        {{ member.users[0].middle_name }}
                        {{ member.users[0].last_name }}
                    </q-item-label>
                    <q-item-label caption>
                        <div>{{ _.get(member.users[0], 'ext_login') }}</div>
                        <div>{{ _.get(_.find(member.users[0].additional, {slug: 'position'}), 'value') }}</div>
                        <div>{{ _.get(_.find(member.users[0].additional, {slug: 'title'}), 'value') }}</div>
                    </q-item-label>
                </q-item-section>
                <q-item-section v-if="_.size(member.users)>1">
                    <q-item-label>

                    </q-item-label>
                </q-item-section>
                <q-item-section v-if="_.size(member.users)>0">
                    <q-item-label>
                        {{ projectRoles[_.head(roleSettings[_.get(member, 'role.pureId')])] }}
                    </q-item-label>
                </q-item-section>
                <q-item-section side>
                                        <q-checkbox v-model="selectedMembers[index].checked"
                                                    dense/>
                </q-item-section>
            </q-item>
        </template>
    </q-list>
    <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary"/>
    </q-inner-loading>
</template>

<script setup>
import {getCurrentInstance, nextTick, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    award: {type: Object}
})

const selectedMembers = defineModel({type: Object, default: {}})

const members = ref([])

const loading = ref(false)
const projectRoles = ref([])
const loadProjectRoles = () => {
    axios.get(route('ajax.project', {m: 'projectRoles'}))
        .then(response => {
            projectRoles.value = response.data.results;
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
        });
}

const roleSettings = ref([])
const loadRoleSettings = () => {
    axios.get(route('ajax.pure', {m: 'getAwardsRolesSettings'}))
        .then(response => {
            roleSettings.value = response.data.results;
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
        });
}
const loadAwardMembers = () => {
    loading.value = true;
    loadProjectRoles()
    loadRoleSettings()
    axios.get('/pure.json', {
        params: {
            'm': 'getAwardUsers',
            'award': props.award.uuid
        }
    }).then(response => {
        members.value = response.data.results;
        _.forEach(members.value, (member, k) => {
            _.set(selectedMembers.value, k, {
                user_id: _.get(_.head(member.users), 'user_id'),
                user: _.head(member.users),
                uuid: k,
                role_id: _.head(roleSettings.value[_.get(member, 'role.pureId')]),
                checked: true
            })
        });
    }).then(() => {

    }).catch(error => {
        console.log(error);
    }).finally(() => {
        // setTimeout(function () {
        //     vm.$set(vm, 'loading', false);
        // }, vm.projectUsers.length * 10000)
        loading.value = false;
    });
}

onMounted(() => {
    loadAwardMembers()
})
</script>