<template>
    <Card :title="$translate('module-archive.project.card.acts.title', {size: _.size(acts)})"
          class="q-mb-md"
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn :icon="expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                   :title="$translate('buttons.toggle-list')"
                   @click="expanded = !expanded"
                   size="sm"
                   flat
            />
        </template>
        <template v-slot:default>
            <q-slide-transition>
                <div v-show="expanded">
                    <q-list separator>
                        <q-item v-for="(act, i) in acts" :key="i">
                            <q-item-section>
                                <div v-html="getActString(act)" style="white-space: nowrap;"></div>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </div>
            </q-slide-transition>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import moment from "moment";
import helpers from "@/helpers";

const props = defineProps({
    acts: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const expanded = ref(true);

const getActString = (act) => `
    ${_.get(act, 'name')} от ${moment(_.get(act, 'date')).format($translate('common.date.js'))}:
    <b>${helpers.formatMoney(_.get(act, 'sum'))}</b> (${_.trim(_.get(act, 'user.fullname'))})
`;
</script>
