<template>
    <Card :title="$translate('research-template.title.users',{template: template.name})"
          title-bg="blue-grey-3"
          no-card-section
          header-toolbar
    >
        <template v-slot:headerActions>
            <q-btn icon="navigate_before"
                   :label="$translate('research-template.actions.list')"
                   color="blue-grey-6"
                   @click="router.visit($route('research.template'))"
                   no-caps dense no-wrap/>
        </template>
        <q-list separator>
            <template v-for="(user,index) in staff">
                <q-item>
                    <q-item-section side>
                        <q-toggle v-model="form.users[index]"
                                  :true-value="user.user_id"
                                  :false-value="null"
                                  color="positive"
                        />
                    </q-item-section>
                    <StaffItem :user="user" hide-actions/>
                </q-item>
            </template>
        </q-list>
        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   color="positive"
                   @click="submitForm"
            />
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from 'lodash'
import {useQuasar} from "quasar";
import StaffItem from "@/Components/SSC/Staff/StaffItem.vue";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    template: {type: Object},
    staff: {type: Array}
})

const form = useForm({
    users: []
})

const submitForm = () => {
    form.post(route('research.template.users.update', props.template), {
        onSuccess: () => {
            router.visit(route('research.template'))
        }
    })
}

onMounted(() => {
    _.forEach(props.staff, (user) => {
        if (_.findIndex(props.template.users, {user_id: user.user_id}) >= 0) {
            form.users.push(user.user_id)
        } else {
            form.users.push(null)
        }
    })
})
</script>