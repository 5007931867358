<template>
    <Card v-if="_.get(typeFields,'show')"
          :title="typeFields.name"
          hide-actions>

        <FieldsValues :fields="typeFields.fields"
                      :values="publication.print_fields"
                      dense/>

    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import FieldsValues from "@/Components/Fields/FieldsValues.vue";
import _ from "lodash";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    publication: {type: Object}
})

const typeFields = computed(() => {
    return props.publication.report_type.fields.print;
})
</script>