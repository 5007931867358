<template>
    <q-list>
        <NavigationItem :title="serviceName" :sidebar="sidebar" color="indigo-5" icon="o_biotech">
            <template #items>
                <template v-for="(descr, part) in services.microcollections.items" :key="part">
                    <q-item @click="router.visit($route('services.microcollections.part', part))"
                            clickable v-close-popup>
                        <q-item-section class="q-pl-md">
                            {{ descr }}
                        </q-item-section>
                    </q-item>
                </template>
                <q-separator/>
                <q-item @click="router.visit($route('services.microcollections.index'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate('menu.microcollections.index') }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
        <NavigationItem :title="$translate('menu.microcollections.manage')"
                        :sidebar="sidebar"
                        color="dark"
                        icon="o_construction"
                        v-if="services.microcollections.manage.types || services.microcollections.manage.parts"
        >
            <template #items>
                <q-item v-if="services.microcollections.manage.types"
                        @click="router.visit($route('services.microcollections.type.index'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate('menu.microcollections.types') }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.microcollections.manage.parts"
                        @click="router.visit($route('services.microcollections.part.index'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate('menu.microcollections.parts') }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
    </q-list>
</template>

<script setup>
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";
import {computed, getCurrentInstance} from "vue";
import {router, usePage} from "@inertiajs/vue3";

const props = defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const page = usePage();
const services = computed(() => page.props.services);
const serviceName = computed(() => String(page.props.serviceName));
</script>
