<template>
    <Card :title="$translate('users.manage.ssc.title-2')"
          title-bg="cyan-14"
          title-text="white"
          no-card-section
          hide-actions
    >
        <q-list separator class="max-height-vh-70 overflow-y-auto">
            <q-item v-if="show">
                <q-item-section>
                    <q-skeleton width="100%" height="2rem"></q-skeleton>
                    <div class="row justify-between q-my-sm">
                        <q-skeleton width="35%"></q-skeleton>
                        <q-skeleton width="55%"></q-skeleton>
                    </div>
                    <div class="row justify-between q-my-sm">
                        <q-skeleton width="65%"></q-skeleton>
                        <q-skeleton width="30%"></q-skeleton>
                    </div>
                </q-item-section>
            </q-item>
            <q-item v-for="(item, i) in data" :key="i">
                <q-item-section>
                    <q-item-label>{{ item.ssc }}</q-item-label>
                    <q-item-label caption>{{ item.role }}</q-item-label>
                </q-item-section>
            </q-item>
        </q-list>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import axios from "axios";
import _ from "lodash";
import {Card} from "@/Components/Block";

const props = defineProps({
    userId: {
        type: Number,
        required: true
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const show = ref(true);
const data = ref([]);

const getUserSsc = () => {
    show.value = true;
    axios.get(route("ajax.users"), {
        params: {
            "m": "getUserCardSsc",
            "user_id": props.userId
        }
    }).then((response) => {
        _.forEach(response.data.results, (item) => {
            data.value.push(item);
        });
    }).catch(e => {
        console.error(e);
    }).finally(() => {
        show.value = false;
    });
};

onMounted(() => {
    getUserSsc();
});
</script>
