<template>
    <Card :title="classificator.name"
          title-bg="deep-purple-1"
          hide-actions
          header-toolbar
          no-card-section>
        <template v-slot:headerActions>
            <q-btn v-if="can.create"
                   icon="add"
                   :label="$translate('classificator.actions.add')"
                   color="deep-purple-4"
                   @click="addDialog = true"
                   dense/>
            <q-btn icon="navigate_before"
                   :label="$translate('buttons.back')"
                   color="deep-purple-4"
                   @click="router.visit($route('admin.list.simple.index'))"
                   dense/>
        </template>
        <q-table ref="tableRef"
                 :rows="items"
                 :columns="columns"
                 v-model:pagination="pagination"
                 :filter="filter" :loading="loading"
                 :rows-per-page-options="[10,50,100]"
                 row-key="id"
                 @request="onRequest"
                 wrap-cells
                 flat
        >
            <template v-slot:top-row>
                <q-tr>
                    <q-td></q-td>
                    <q-td></q-td>
                    <q-td colspan="100%">
                        <q-input v-model="filter"
                                 :placeholder="$translate('classificator.actions.search')"
                                 debounce="1000"
                                 clear-icon="clear"
                                 clearable borderless dense>
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:body-cell-checked="props">
                <q-td :props="props">
                    <q-icon v-if="can.edit"
                            :name="props.row.checked?'check':'close'"
                            :color="props.row.checked?'positive':'negative'"
                            class="cursor-pointer"
                            @click="toggleItem(props.row)"/>
                </q-td>
            </template>
            <template v-slot:body-cell-name="props">
                <q-td :props="props">
                    <div class="flex items-center no-wrap">
                        <q-icon v-if="can.edit"
                                name="edit"
                                color="grey-5"
                                :title="$translate('buttons.edit') + ' ' + $translate('classificator.fields.name')"
                                class="cursor-pointer">
                            <q-popup-edit v-model="props.row"
                                          style="width: 600px"
                                          v-slot="scope"
                                          buttons auto-save
                                          :label-set="$translate('buttons.save')"
                                          :label-cancel="$translate('buttons.cancel')"
                                          :title="$translate('classificator.fields.name')"
                                          @save="patchItem">
                                <template v-for="(lang_name,locale) in $translate('fields.locale')">
                                    <q-input v-model="scope.value.name_i18n[locale]"
                                             :label="lang_name"
                                             dense counter/>
                                </template>
                            </q-popup-edit>
                        </q-icon>
                        <div class="q-ml-sm">{{ props.row.name }}</div>
                    </div>
                </q-td>
            </template>
            <template v-slot:body-cell-description="props">
                <q-td :props="props">
                    <div class="row items-center no-wrap">
                        <q-icon v-if="can.edit"
                                name="edit"
                                color="grey-5"
                                :title="$translate('buttons.edit') + ' ' + $translate('classificator.fields.description')"
                                class="cursor-pointer">
                            <q-popup-edit v-model="props.row"
                                          style="width: 600px"
                                          v-slot="scope" buttons auto-save
                                          :label-set="$translate('buttons.save')"
                                          :label-cancel="$translate('buttons.cancel')"
                                          :title="$translate('classificator.fields.description')"
                                          @save="patchItem">
                                <template v-for="(lang_name,locale) in $translate('fields.locale')">
                                    <q-input type="textarea" rows="3"
                                             v-model="scope.value.description_i18n[locale]"
                                             :label="lang_name"
                                             dense counter/>
                                </template>
                            </q-popup-edit>
                        </q-icon>
                        <div class="q-ml-sm">{{ props.row.description }}</div>
                    </div>
                </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @on-success="onSuccess"/>
                </q-td>
            </template>
        </q-table>
    </Card>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('classificator.add.title', {name: props.classificator.name})"
              title-bg="deep-purple-1"
              in-modal show-close-button no-card-section>
            <q-card-section>
                <q-item-label overline>{{ $translate('classificator.fields.slug') }}</q-item-label>
                <q-input v-model="addForm.slug" dense counter maxlength="60"
                         :mask="'a'.repeat(60)"
                         :hint="$translate('classificator.fields.slug-hint')"
                         :error-message="addForm.errors['slug']"
                         :error="addForm.errors.hasOwnProperty('slug')"/>
            </q-card-section>
            <q-card-section>
                <q-item-label overline>{{ $translate('classificator.fields.name') }}</q-item-label>
                <template v-for="(lang_name,locale) in $translate('fields.locale')">
                    <q-input v-model="addForm.name_i18n[locale]"
                             :label="lang_name"
                             dense counter
                             :error-message="addForm.errors['name_i18n.'+locale]"
                             :error="addForm.errors.hasOwnProperty('name_i18n.'+locale)"/>
                </template>
            </q-card-section>
            <q-card-section>
                <q-item-label overline>{{ $translate('classificator.fields.description') }}</q-item-label>
                <template v-for="(lang_name,locale) in $translate('fields.locale')">
                    <q-input type="textarea" rows="3"
                             v-model="addForm.description_i18n[locale]"
                             :label="lang_name"
                             dense counter
                             :error-message="addForm.errors['description_i18n.'+locale]"
                             :error="addForm.errors.hasOwnProperty('description_i18n.'+locale)"/>
                </template>
            </q-card-section>
            <template v-slot:actions>
                <q-btn color="positive"
                       icon="o_save"
                       :label="$translate('buttons.add')"
                       @click="submitForm"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {router, useForm} from "@inertiajs/vue3";
import {getCurrentInstance, onMounted, ref} from "vue";
import axios from "axios";
import DataTableActions from "@/Components/DataTableActions.vue";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    classificator: {
        type: Object
    },
    can: {
        type: Object
    }
})

const tableRef = ref()
const filter = ref('')
const loading = ref(false)
const items = ref([])
const columns = [
    {
        name: 'actions', align: 'center', label: '',
        style: "width: 2rem",
    },
    {
        name: 'checked', align: 'center', label: '',
        style: "width: 2rem",
    },
    {
        name: 'slug', align: 'center', label: $translate('classificator.fields.slug'),
        style: "width: 2rem",
        field: 'slug',
    },
    {
        name: 'name', align: 'left', label: $translate('classificator.fields.name'),
        field: 'name',
        style: "width: 40%",
    },
    {
        name: 'description', align: 'left', label: $translate('classificator.fields.description'),
        field: 'description',
        style: "width: 40%",
    },
]

const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
})

function onRequest(request) {
    const {page, rowsPerPage, sortBy, descending} = request.pagination
    const filter = request.filter

    loading.value = true

    let params = {
        m: 'simple',
        class: props.classificator.id,
        page: page,
        per_page: rowsPerPage,
    }
    params.datatable = {
        sort: {}
    }
    if (filter !== '') {
        params.datatable['search'] = filter;
    }
    axios.get(route('ajax.classificator'), {
        params: params
    })
            .then((response) => {
                // console.log(response.data.results.data)
                items.value = response.data.results.data;

                pagination.value.page = response.data.results.current_page
                pagination.value.rowsPerPage = response.data.results.per_page
                pagination.value.sortBy = sortBy
                pagination.value.descending = descending
                pagination.value.rowsNumber = response.data.results.total
            })
            .finally(() => {
                loading.value = false
            })
}

const addDialog = ref(false)
const addForm = useForm({
    slug: null,
    name_i18n: {
        ru: null,
        en: null
    },
    description_i18n: {
        ru: null,
        en: null
    },
})
const onSuccess = () => {
    addDialog.value = false
    tableRef.value.requestServerInteraction()
}
const submitForm = () => {
    addForm.post(route('admin.list.simple.store', {list: props.classificator.id}), {
        onSuccess: () => {
            onSuccess()
        }
    })
}
const patchItem = (item) => {
    router.patch(route('admin.list.simple.update', {list: props.classificator.id, id: item.id}), {
        name_i18n: item.name_i18n,
        description_i18n: item.description_i18n
    }, {
        onSuccess: () => {
            onSuccess()
        }
    })
}
const toggleItem = (item) => {
    router.patch(route('admin.list.simple.update', {list: props.classificator.id, id: item.id}), {
        checked: !item.checked,
    }, {
        onSuccess: () => {
            onSuccess()
        }
    })
}
onMounted(() => {
    onSuccess()
})
</script>