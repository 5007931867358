<template>
    <Card :title="title" :header-toolbar="inModal" in-modal show-close-button>
        <SearchUser :error="form.errors.hasOwnProperty('user')"
                    :error-message="form.errors.user"
                    @onSelect="setUser"
                    with-ldap
        />

        <template v-slot:actions>
            <q-btn :label="$translate('buttons.save')"
                   @click="submitForm"
                   color="positive"/>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import SearchUser from "@/Components/Search/SearchUser.vue";
import {useForm} from "@inertiajs/vue3";
import {getCurrentInstance} from "vue";

const props = defineProps({
    title: String,
    action: String,
    inModal: Boolean
});
const emit = defineEmits(['onCloseModal']);

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const form = useForm({
    user: null
});

const setUser = (user) => {
    form.user = user.id;
};

const submitForm = () => {
    form.post(props.action, {
        onSuccess: (page) => {
            if (props.inModal) {
                emit('onCloseModal');
            }
        }
    });
};
</script>
