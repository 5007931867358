<template>
    <q-table ref="tableRef"
             row-key="id"
             :rows="items"
             :columns="buildColumns"
             :filter="filter"
             :loading="loading"
             @request="onRequest"
             v-model:pagination="pagination"
             :rows-per-page-options="[10,25,50,100]"
             wrap-cells
             flat
    >
        <template v-slot:top-row>
            <q-tr class="filter">
                <q-td/>
                <q-td colspan="100%">
                    <q-input v-model="filter" debounce="1000"
                             :placeholder="$translate('поиск заявки по номеру, наименованию, подразделению, части ФИО автора')"
                             borderless
                             dense
                             clearable
                             clear-icon="clear">
                        <template v-slot:prepend>
                            <q-icon name="search"/>
                        </template>
                    </q-input>
                </q-td>
            </q-tr>
        </template>
        <template v-slot:header-cell-state="props">
            <q-th :props="props">
                <div class="flex items-center justify-center no-wrap">
                    <span v-html="props.col.label"></span>
                    <q-icon name="o_filter_alt"
                            size="xs"
                            color="grey-7"
                            :title="$translate('фильтр по статусу')"
                            class="q-ml-sm cursor-pointer">
                        <q-menu anchor="bottom middle" self="top middle">
                            <q-list dense>
                                <template v-for="(name,state) in $translate('research.status')">
                                    <q-item dense clickable v-close-popup @click="filterBy('state',name)">
                                        <q-item-section>
                                            <q-item-label>{{ name }}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-list>
                        </q-menu>
                    </q-icon>
                </div>
            </q-th>
        </template>
        <template v-slot:body-cell-number="props">
            <q-td :props="props" class="no-wrap">
                <a :href="$route('project.research.show',props.row.number)"
                   @click.prevent="router.get($route('project.research.show',props.row.number))"
                   v-html="props.row.number"></a>
            </q-td>
        </template>
        <template v-slot:body-cell="props">
            <q-td :props="props">
                <div v-html="props.value"></div>
            </q-td>
        </template>

        <template v-slot:header-cell-actions="props">
            <q-th :props="props" class="no-wrap">
                <ResearchCustomSettings :currentView="customView"
                                        @onViewSelect="onViewSelect"/>
            </q-th>
        </template>
        <template v-slot:body-cell-actions="props">
            <q-td :props="props" class="no-wrap">
                <DataTableActions :actions="props.row.actions"
                                  :name="props.row.name"
                                  @onSuccess="onSuccess"/>
            </q-td>
        </template>
    </q-table>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {useQuasar} from "quasar";

import DataTableActions from "@/Components/DataTableActions.vue";
import ResearchCustomSettings from "@/Components/CustomSettings/ResearchCustomSettings.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const $q = useQuasar()

const props = defineProps({
    project: {
        type: Object,
        default: {}
    },
    state: {
        type: String,
        default: null
    },
    method: {
        type: String,
        default: 'list'
    }
})

const customView = ref(null)
const getCustomView = () => {
    customView.value = (JSON.parse($q.localStorage.getItem('ResearchCustomView')) || null)
}

const tableRef = ref();
const filter = ref('');
const loading = ref(false);
const items = ref([]);
const columns = [
    {name: 'actions', style: 'width:50px', headerStyle: 'width:50px'},
    {
        name: 'number',
        label: $translate('research.list.number'),
        field: 'number',
        classes: 'text-no-wrap',
        align: 'left',
        style: 'width:10rem',
    },
    {
        name: 'state',
        label: $translate('research.list.state'),
        field: 'state',
        classes: 'text-no-wrap',
        align: 'center'
    },
    {
        name: 'template',
        label: $translate('research.list.name'),
        field: 'template',
        align: 'left'
    },
    {
        name: 'assay',
        label: $translate('research.list.assay'),
        field: 'assay',
        align: 'center'
    },
    {
        name: 'ssc',
        label: $translate('research.list.ssc'),
        field: 'ssc',
        align: 'left'
    },
    {
        name: 'author',
        label: $translate('research.list.author'),
        field: 'author',
        align: 'left'
    },
]

const filterBy = (field, value) => {
    filter.value = '' + field + ':"' + value + '"';
    onSuccess();
}

const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});

const onRequest = (request) => {
    const {page, rowsPerPage, sortBy, descending} = request.pagination;
    const filter = request.filter;

    loading.value = true;

    let params = {
        m: props.method,
        project_id: _.get(props.project, 'id'),
        state: props.state,
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {}
        }
    };
    if (_.size(filter) > 0) {
        params.datatable['search'] = filter;
    }
    axios.get(route('ajax.research'), {
        params: params,
    })
            .then((response) => {
                items.value = response.data.results.data;
                pagination.value.page = response.data.results.current_page;
                pagination.value.rowsPerPage = response.data.results.per_page;
                pagination.value.sortBy = sortBy;
                pagination.value.descending = descending;
                pagination.value.rowsNumber = response.data.results.total;
            })
            .finally(() => {
                loading.value = false;
            });
};

const getCustomColumnValue = (val, row, index) => {
    let field = _.get(customView.value.fields, index)
    //return JSON.stringify(field)
    let result = [];
    _.forEach(field.data, (data) => {
        result.push(_.get(row, data.name, null))
    })
    result = _.filter(result);
    return "<div>" + result.join("</div><div>") + "<div>"
}

const buildColumns = computed(() => {
    if (customView.value) {
        let _columns = [
            {name: 'actions', style: 'width:50px', headerStyle: 'width:50px'},
            {
                name: 'number',
                label: $translate('research.list.number'),
                field: 'number',
                classes: 'text-no-wrap',
                align: 'left',
                style: 'width:8rem',
            },
        ];
        _.forEach(_.get(customView.value, 'fields'), (field, index) => {
            _columns.push(
                    {
                        name: 'field_' + index,
                        label: field.name,
                        format: (val, row) => getCustomColumnValue(val, row, index),
                        align: 'left'
                    }
            )
        })
        return _columns
    }
    return columns
})

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
}

const onViewSelect = () => {
    getCustomView()
}

onMounted(() => {
    getCustomView()
    onSuccess()
});
</script>