<template>
    <Card :title="$translate('users.manage.ssc.title')"
          header-toolbar hide-actions no-card-section>

        <q-list separator>
            <template v-for="(department) in departments">
                <q-item>
                    <q-item-section>
                        <q-item-label class="text-bold">
                            <a :href="$route('ssc.show',department.ssc)"
                               @click.prevent="router.visit($route('ssc.show',department.ssc))">
                                {{ department.ssc.name }}
                            </a>
                        </q-item-label>
                        <q-item-label>
                            <span>{{ $translate('users.manage.ssc.director') }}:</span>
                            {{ _.get(department.ssc.director, 'fulled_name') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>{{ $translate('users.manage.ssc.role') }}:</q-item-label>
                        <q-item-label>
                            {{ department.role.display_name }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <div class="column">
                            <q-toggle size="sm"
                                      :model-value="department.show_select"
                                      :label="$translate('users.manage.ssc.show-in-selector')"
                                      @click="toggleShowSelect(department)"/>

                            <q-toggle size="sm"
                                      :model-value="department.show_menu"
                                      :label="$translate('users.manage.ssc.show-in-menu')"
                                      @click="toggleShowMenu(department)"/>
                        </div>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import {Card} from "@/Components/Block/";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    user: {
        type: Object
    },
    departments: {
        type: Array
    }
})

const toggleShowSelect = (item) => {
    router.post(route('profile.ssc.update', {ssc: item.id}), {
        show_select: !item.show_select,
        show_menu: item.show_menu
    }, {
        onSuccess: () => {
            router.reload()
        }
    })
}
const toggleShowMenu = (item) => {
    router.post(route('profile.ssc.update', {ssc: item.id}), {
        show_select: item.show_select,
        show_menu: !item.show_menu
    }, {
        onSuccess: () => {
            router.reload()
        }
    })
}
</script>