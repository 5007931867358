<template>
    <div class="column-date-chart" ref="chartRef"></div>
</template>

<script setup lang="ts">
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import {onBeforeUnmount, onMounted, Ref, ref, ShallowRef, shallowRef} from "vue";
import {AnyValuesObject, StringValuesObject} from "@/entity/types";
import {ConfigHandler, IColumnDateChartConfig} from "@/entity/charts";
import {set, toValue} from "@vueuse/core";

interface Props {
    data: AnyValuesObject[],
    config: IColumnDateChartConfig,
    locale: AnyValuesObject,
    legend: StringValuesObject
}

const props = defineProps<Props>();

const configHandler: ConfigHandler = ConfigHandler.new(props.config);

const chartRef: Ref<any> = ref<any>();

const am5Root: ShallowRef = shallowRef<any>();
const am5Chart: ShallowRef = shallowRef<any>();
const am5YAxis: ShallowRef = shallowRef<any>();
const am5XAxis: ShallowRef = shallowRef<any>();

const createChart: Function = (): void => {
    clearRefs();

    let root: am5.Root = am5.Root.new(toValue(chartRef));
    root.setThemes([am5themes_Animated.new(root)]);
    root.locale = props.locale;
    root._logo!.dispose();
    set(am5Root, { root: root });

    set(am5Chart, {
        chart: am5Root.value.root.container.children.push(
            am5xy.XYChart.new(am5Root.value.root, Object.assign({}, configHandler.get("chartSettings", {}), {
                layout: am5Root.value.root.verticalLayout
            }))
        )
    });
    if (configHandler.has("zoomOnWheelCntrl") && true === configHandler.get("zoomOnWheelCntrl")) {
        // Enable zoom/pan only when CTRL key is pressed
        am5Chart.value.chart.plotContainer.events.on("wheel", function (ev: any) {
            if (ev.originalEvent.ctrlKey) {
                ev.originalEvent.preventDefault();
                am5Chart.value.chart.set("wheelX", "panX");
                am5Chart.value.chart.set("wheelY", "zoomX");
            } else {
                am5Chart.value.chart.set("wheelX", "none");
                am5Chart.value.chart.set("wheelY", "none");
            }
        });
    }

    set(am5XAxis, {
        xAxis: am5Chart.value.chart.xAxes.push(
            am5xy.DateAxis.new(am5Root.value.root, getXAxisSettings())
        )
    });
    if (configHandler.has("dateXAxis.rotateLabels") && true === configHandler.get("dateXAxis.rotateLabels")) {
        let xRenderer = am5XAxis.value.xAxis.get("renderer");
        xRenderer.labels.template.setAll({
            rotation: 47,
            centerY: am5.p50
        });
    }

    set(am5YAxis, {
        yAxis: am5Chart.value.chart.yAxes.push(
            am5xy.ValueAxis.new(am5Root.value.root, getYAxisSettings())
        )
    });

    // Set chart widgets (cursor, scrollbar, ...)
    setChartWidgets();

    let series = am5Chart.value.chart.series.push(
        am5xy.ColumnSeries.new(am5Root.value.root, getSeriesSettings())
    );
    if (configHandler.has("columnSeries.dateFields") && configHandler.has("columnSeries.dateFormat")) {
        series.data.processor = am5.DataProcessor.new(am5Root.value.root, {
            dateFields: configHandler.get("columnSeries.dateFields"),
            dateFormat: configHandler.get("columnSeries.dateFormat")
        });
    }
    series.data.setAll(props.data);
    series.appear(1000); // Make stuff animate on load

    am5Chart.value.chart.appear(1000, 100); // Make stuff animate on load

    createLegend();

    if (configHandler.has("exportingSettings")) {
        let exporting: am5plugins_exporting.Exporting = am5plugins_exporting.Exporting.new(
            am5Root.value.root,
            Object.assign({}, configHandler.get("exportingSettings"), {
                menu: am5plugins_exporting.ExportingMenu.new(am5Root.value.root, {}),
                dataSource: props.data,
            })
        );
    }
};

const clearRefs: Function = (): void => {
    set(am5Root, null);
    set(am5Chart, null);
    set(am5YAxis, null);
    set(am5XAxis, null);
};

const getXAxisSettings: Function = (): AnyValuesObject => ({
    baseInterval: configHandler.get("dateXAxis.baseInterval"),
    maxDeviation: configHandler.get("dateXAxis.maxDeviation"),
    renderer: am5xy.AxisRendererX.new(am5Root.value.root, configHandler.get("dateXAxis.rendererSettings", {})),
    tooltip: am5.Tooltip.new(am5Root.value.root, {})
});

const getYAxisSettings: Function = (): AnyValuesObject => ({
    extraTooltipPrecision: configHandler.get("valueYAxis.extraTooltipPrecision"),
    numberFormat: configHandler.get("valueYAxis.numberFormat"),
    renderer: am5xy.AxisRendererY.new(am5Root.value.root, {}),
    tooltip: am5.Tooltip.new(am5Root.value.root, {})
});

const setChartWidgets: Function = (): void => {
    if (configHandler.has("chartWidgets")) {
        if (configHandler.has("chartWidgets.cursor")) {
            am5Chart.value.chart.set(
                "cursor",
                am5xy.XYCursor.new(am5Root.value.root, configHandler.get("chartWidgets.cursor"))
            );
        }
        if (configHandler.has("chartWidgets.scrollbarX")) {
            let scrollbarX = am5Chart.value.chart.set(
                "scrollbarX",
                am5.Scrollbar.new(am5Root.value.root, configHandler.get("chartWidgets.scrollbarX"))
            );
            scrollbarX.startGrip.set("scale", 0.7);
            scrollbarX.endGrip.set("scale", 0.7);
        }
    }
};

const getSeriesSettings: Function = (): AnyValuesObject => ({
    name: configHandler.get("columnSeries.name"),
    xAxis: am5XAxis.value.xAxis,
    yAxis: am5YAxis.value.yAxis,
    valueYField: configHandler.get("columnSeries.valueYField"),
    valueXField: configHandler.get("columnSeries.valueXField"),
    tooltip: am5.Tooltip.new(am5Root.value.root, {
        labelText: configHandler.get("columnSeries.tooltipLabelText")
    }),
    fill: am5.color(configHandler.get("columnSeries.fill"))
});

const createLegend: Function = (): void => {
    let legend = am5Chart.value.chart.children.push(
        am5.Legend.new(am5Root.value.root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            centerY: am5.percent(50),
        })
    );
    legend.data.setAll(am5Chart.value.chart.series.values);
};

onMounted(() => {
    createChart();
});

onBeforeUnmount(() => {
    if (am5Root.value.root) {
        am5Root.value.root.dispose();
    }
});
</script>

<style scoped>
.column-date-chart {
    width: 100%;
    height: 100%;
}
</style>