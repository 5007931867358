<template>
    <Card :title="title" :header-toolbar="inModal" in-modal show-close-button>

        <q-input v-model="form.name"
                 :label="$translate('module-syexc.directions.fields.name')"
                 :error="form.errors.hasOwnProperty('name')"
                 :error-message="_.get(form.errors, 'name')"
        />

        <template v-if="direction && direction.expert">
            <q-field :label="$translate('module-syexc.directions.fields.expert')" stack-label dense>
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        {{ direction.expert.full_name }}
                    </div>
                </template>
            </q-field>
        </template>

        <SearchUser :label="$translate('module-syexc.directions.fields.expert')"
                    :error="form.errors.hasOwnProperty('expert')"
                    :error-message="_.get(form.errors, 'expert')"
                    @onSelect="setExpert"
                    with-ldap
        />

        <template v-if="direction && direction.secretary">
            <q-field :label="$translate('module-syexc.directions.fields.secretary')" stack-label dense>
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">
                        {{ direction.secretary.full_name }}
                    </div>
                </template>
            </q-field>
        </template>

        <SearchUser :label="$translate('module-syexc.directions.fields.secretary')"
                    @on-select="setSecretary"
                    with-ldap
        />

        <template v-slot:actions>
            <q-btn :label="$translate(direction ? 'buttons.save' : 'buttons.add')"
                   @click="submitForm"
                   color="positive"
            />
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import SearchUser from "@/Components/Search/SearchUser.vue";
import {getCurrentInstance, onMounted} from "vue";
import {useForm} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    title: String,
    action: String,
    direction: Object,
    inModal: Boolean
});
const emit = defineEmits(['onCloseModal']);

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const form = useForm({
    name: null,
    expert: null,
    secretary: null,
    optionsExpert: [],
    optionsSecretary: []
});

const setExpert = (expert) => {
    form.expert = expert.id;
};
const setSecretary = (secretary) => {
    form.secretary = secretary;
};

const submitForm = () => {
    form.post(props.action, {
        onSuccess: (page) => {
            if (props.inModal) {
                emit('onCloseModal');
            }
        }
    });
};

onMounted(() => {
    if (props.direction) {
        form.name = props.direction.name;
        form.expert = props.direction.expert;
        form.secretary = props.direction.secretary;
    }
});
</script>
