<template>
    <q-field :label="$translate('module-delivery.order.reestr.date')" stack-label
             bottom-slots
             :error="form.errors.hasOwnProperty('dates') || form.errors.hasOwnProperty('dates.0')"
             :error-message="_.get(form.errors,'dates') || _.get(form.errors,'dates.0')">
        <template v-slot:default>
            <div class="items-center full-width" v-if="_.isObject(form.dates)">
                {{ form.dates.from }} &mdash; {{ form.dates.to }}
            </div>
            <div class="items-center full-width" v-else>
                {{ form.dates }}
            </div>
            <q-popup-proxy>
                <q-date v-model="form.dates" range minimal>
                    <div class="row items-center justify-end">
                        <q-btn v-close-popup :label="$translate('buttons.select')"
                               color="dark"
                               flat/>
                    </div>
                </q-date>
            </q-popup-proxy>
        </template>
        <template v-slot:prepend>
            <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy>
                    <q-date v-model="form.dates" range minimal>
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup :label="$translate('buttons.select')"
                                   color="dark"
                                   flat/>
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-icon>
        </template>
    </q-field>

    <q-select v-model="form.state"
              :label="$translate('module-delivery.order.reestr.state')"
              :options="optionsStatus"
              emit-value map-options
              multiple options-dense use-chips
              :error="form.errors.hasOwnProperty('state')"
              :error-message="_.get(form.errors,'state')"/>

    <q-select v-model="form.address"
              :label="$translate('module-delivery.order.reestr.address')"
              :hint="$translate('module-delivery.order.reestr.address-hint')"
              :options="optionsAddress"
              :loading="loadingAddress"
              emit-value map-options
              multiple options-dense use-chips
              :error="form.errors.hasOwnProperty('address')"
              :error-message="_.get(form.errors,'address')"/>

    <q-select v-model="form.goods"
              :label="$translate('module-delivery.order.reestr.goods')"
              :hint="$translate('module-delivery.order.reestr.goods-hint')"
              :options="optionsGoods"
              :loading="loadingGoods"
              emit-value map-options
              multiple options-dense use-chips
              :error="form.errors.hasOwnProperty('goods')"
              :error-message="_.get(form.errors,'goods')"/>

</template>
<script setup>
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref} from "vue";
import _ from "lodash";
import axios from "axios";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = defineModel({type: Object})


const optionsStatus = computed(() => {
    return _.map($translate('module-delivery.order.status'), (v, k) => ({value: k, label: v}))
})

const optionsAddress = ref([])
const loadingAddress = ref(false)
const loadAddress = () => {
    loadingAddress.value = true;
    axios.get('/delivery/order/ssc_address.json')
            .then((response) => {
                optionsAddress.value = response.data.data
            })
            .finally(() => {
                loadingAddress.value = false
            })
}

const optionsGoods = ref([])
const loadingGoods = ref(false)
const loadGoods = () => {
    loadingGoods.value = true;
    axios.get('/delivery/order/goods.json')
            .then((response) => {
                optionsGoods.value = response.data.data
            })
            .finally(() => {
                loadingGoods.value = false
            })
}

onBeforeMount(() => {
    loadAddress()
    loadGoods()
})
</script>
