<template>
    <Card :title="title" no-card-section hide-actions>
        <q-list separator>
            <template v-for="(document) in documents">
                <q-item>
                    <q-item-section side>
                        <q-icon name="attach_file"/>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label v-if="document.has_access">
                            <div v-if="document.document_link">
                                <a :href="document.document_link" target="_blank" rel="nofollow noopener">
                                    {{ document.name }}
                                </a>
                            </div>
                            <div v-else>
                                <div>{{ document.name }}</div>
                                <q-badge variant="warning">Файл недоступен в источнике данных</q-badge>
                            </div>
                        </q-item-label>
                        <q-item-label v-else>
                            <div>{{ document.name }}</div>
                            <q-badge variant="danger">Доступ ограничен</q-badge>
                        </q-item-label>
                        <q-item-label caption v-if="document.type">
                            {{ document.type.name }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </template>
            <template v-for="(link) in links">
                <q-item>
                    <q-item-section side>
                        <q-icon name="link"/>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>
                            <a :href="link.url" target="_blank" rel="nofollow noopener">
                                {{ link.name }}
                            </a>
                        </q-item-label>
                        <q-item-label caption v-if="link.type">
                            {{ link.type.name }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    title: {type: String},
    documents: {type: Array},
    links: {type: Array},
})
</script>