<template>
    <div v-if="!equipment.ssc_id">
        <q-banner class="bg-warning text-white">{{ $translate('equipment.validation.no-ssc') }}</q-banner>
    </div>
    <div v-else>
        <q-banner class="bg-light-blue-2 text-indigo-10">
            Для работы комплекса все составляющие комплекса должны иметь заполненными поля: первоначальная стоимость,
            дата постановки на учет, срок полезного использования.
        </q-banner>
        <q-table :rows="equipment.complex" :columns="complexCols"
                 row-key="consumable.id"
                 flat bordered :rows-per-page-options="[0]" hide-pagination>
            <template v-slot:top-row>
                <q-tr>
                    <q-td colspan="100%">
                        <q-btn icon="add" :label="$translate('buttons.add')" @click="addDialog = true" no-caps/>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props">
                    <q-btn icon="o_delete"
                           :title="$translate('buttons.delete')"
                           color="negative"
                           clickable flat dense
                           @click="deleteItemComplex(props.row.id)"/>
                </q-td>
            </template>
            <template v-slot:body-cell-photo="props">
                <q-td :props="props">
                    <q-img :src="props.row.photo" v-if="props.row.photo" sizes="4rem"/>
                </q-td>
            </template>
            <template v-slot:body-cell-name="props">
                <q-td :props="props">
                    <q-item-label>{{ props.row.display_name }}</q-item-label>
                    <q-item-label overline>{{ props.row.serial }}</q-item-label>
                </q-td>
            </template>
            <template v-slot:body-cell-initial_cost="props">
                <q-td :props="props">
                    <q-item-label v-if="props.row.initial_cost">
                        {{ props.row.initial_cost }} {{ $translate('units.rub') }}
                    </q-item-label>
                    <q-item-label v-else class="text-negative">не указана первоначальная стоимость</q-item-label>
                </q-td>
            </template>
            <template v-slot:body-cell-year_placement="props">
                <q-td :props="props">
                    <q-item-label v-if="props.row.year_placement">{{ props.row.year_placement }}</q-item-label>
                    <q-item-label v-else class="text-negative">не указана дата постановки на учет</q-item-label>
                </q-td>
            </template>
            <template v-slot:body-cell-lifetime="props">
                <q-td :props="props">
                    <q-item-label v-if="props.row.initial_cost">
                        {{ props.row.lifetime }} {{ $translate('units.years') }}
                    </q-item-label>
                    <q-item-label v-else class="text-negative">не указан срок полезного использования</q-item-label>
                </q-td>
            </template>
            <template v-slot:body-cell-depreciation="props">
                <q-td :props="props">
                    <q-item-label>
                        {{ props.row.depreciation.toFixed(2) }} /
                        {{ props.row.depreciation_real.toFixed(2) }}
                    </q-item-label>
                </q-td>
            </template>
        </q-table>
        <q-dialog v-model="addDialog" no-backdrop-dismiss>
            <Card :title="$translate('Добавление оборудования в комплекс')"
                  in-modal show-close-button no-card-section hide-actions header-toolbar>
                <q-card-section>
                    <q-input v-model="search"
                             @update:model-value="searchEquipment"
                             debounce="1000"
                             aria-autocomplete="none" autocomplete="none"
                             filled square autofocus
                             :label="$translate('equipment.item.fields.complex-search')"/>
                </q-card-section>
                <q-card-section v-if="search">
                    <q-list separator dense v-if="searchedElements.length > 0">
                        <template v-for="(element) in searchedElements">
                            <q-item>
                                <q-item-section avatar height="3rem" width="3rem">
                                    <q-img v-if="_.has(element,'photo')"
                                           :src="_.get(element,'photo')"
                                           height="2.5rem" width="2.5rem"
                                           fit="scale-down"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ _.get(element, 'display_name') }}</q-item-label>
                                    <q-item-label caption>{{ _.get(element, 'serial') }}</q-item-label>
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn dense icon="add_to_queue" flat @click="addItemComplex(element)"
                                           :title="$translate('buttons.add')"/>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </q-card-section>
            </Card>
        </q-dialog>
    </div>
</template>

<script setup lang="ts">
import {getCurrentInstance, ref} from "vue";
import axios from "axios";
import _ from "lodash"
import {Equipment} from '@/entity'
import {Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

const equipment: Equipment = defineModel()

/**
 *
 * @type {array}
 */
const complexCols: any[] = [
    {name: "actions", label: '', style: "width: 1rem",},
    {name: "photo", label: '', style: "width: 3rem",},
    {name: "name", label: $translate('equipment.item.title.complex-item'), align: 'left'},
    {name: "initial_cost", label: 'Начальная стоимость', align: 'right'},
    {name: "year_placement", label: 'Дата постановки', align: 'center'},
    {name: "lifetime", label: 'Срок использования', align: 'center'},
    {name: "depreciation", label: 'Амортизация/Стоимость приборочаса', align: 'center'},
]

const addDialog = ref(false)

const addItemComplex = (element: any) => {
    equipment.value.complex.push(element)
    equipment.value.complex = _.uniq(equipment.value.complex)
}
const deleteItemComplex = (index: number) => {
    equipment.value.complex = _.filter(equipment.value.complex, (item, i) => item.id !== index);
};

const search = ref(null)

const searchedElements = ref([])

const searchEquipment = (val: string | number | null) => {
    axios.get($route('ajax.equipment', {
        m: 'complex',
        ssc_id: equipment.value.ssc_id,
        q: val
    }))
            .then((response) => {
                searchedElements.value = response.data.results;
            })
}
</script>