<template>
    <Card :title="$translate('users.title-edit')+': '+user.fulled_name"
          title-bg="cyan-14" title-text="white" no-card-section header-toolbar>
        <template v-slot:headerActions>
            <q-btn icon="navigate_before" :label="$translate('buttons.back')" outline @click="router.visit($route('admin.users.index'))"/>
        </template>
        <q-splitter v-model="splitter">
            <template v-slot:before>
                <q-card-section>
                    <q-toolbar-title>{{ $translate('users.details.main') }}</q-toolbar-title>
                    <q-input v-model="form.last_name" :label="$translate('users.fields.last_name')" dense
                             :error-message="form.errors['last_name']"
                             :error="form.errors.hasOwnProperty('last_name')"/>
                    <q-input v-model="form.first_name" :label="$translate('users.fields.first_name')" dense
                             :error-message="form.errors['first_name']"
                             :error="form.errors.hasOwnProperty('first_name')"/>
                    <q-input v-model="form.middle_name" :label="$translate('users.fields.middle_name')" dense
                             :error-message="form.errors['middle_name']"
                             :error="form.errors.hasOwnProperty('middle_name')"/>
                    <div class="row q-gutter-x-md">
                        <q-input v-model="form.birthdate" :label="$translate('users.fields.birthdate')" dense
                                 type="date" class="col"
                                 :error-message="form.errors['birthdate']"
                                 :error="form.errors.hasOwnProperty('birthdate')"/>
                        <q-input v-model="form.ext_login" :label="$translate('users.fields.ext_login')" dense
                                 class="col"
                                 :error-message="form.errors['ext_login']"
                                 :error="form.errors.hasOwnProperty('ext_login')"/>
                    </div>
                </q-card-section>
            </template>
            <template v-slot:after>
                <q-card-section>
                    <q-toolbar-title>{{ $translate('users.fields.roles') }}</q-toolbar-title>
                    <div class="row q-gutter-xs">
                        <template v-for="(role) in systemRoles">
                            <q-checkbox v-model="selectedRoles" :val="role.id" class="col-11" dense>
                                {{ role.display_name }}
                            </q-checkbox>
                        </template>
                    </div>
                </q-card-section>
            </template>
        </q-splitter>
        <template v-slot:actions>
            <q-btn color="positive" icon="check" :label="$translate('buttons.save')" @click="submitForm"/>
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    user: {
        type: Object,
    },
    systemRoles: {
        type: Array,
        default: []
    }
})

const splitter = ref(40)

const selectedRoles = ref([])

const form = useForm({
    first_name: null,
    middle_name: null,
    last_name: null,
    birthdate: null,
    ext_login: null,
    roles: []
})

onMounted(() => {
    form.first_name = props.user.first_name
    form.middle_name = props.user.middle_name
    form.last_name = props.user.last_name
    form.birthdate = props.user.birthdate
    form.ext_login = props.user.ext_login

    selectedRoles.value = _.map(props.user.roles, (role) => role.id)
})
const submitForm = () => {
    form.transform((data) => ({
        ...data,
        roles: selectedRoles.value,
    }))
        .post(route('admin.users.update', props.user))
}
</script>