<template>
    <div>
        <q-splitter v-model="splitterModel">
            <template v-slot:before>
                <q-tabs v-model="tab" active-bg-color="grey-2" vertical>
                    <template v-for="(name, i) in tabNames" :key="i">
                        <q-tab :name="name"
                               v-if="_.get(data,'module.'+name+'.enabled')"
                               :label="$translate('module-' + name + '.name')"
                               content-class="justify-start"
                               no-caps
                        />
                    </template>
                </q-tabs>
            </template>
            <template v-slot:after>
                <q-tab-panels v-model="tab"
                              transition-prev="jump-up"
                              transition-next="jump-up"
                              animated
                              swipeable
                              vertical
                >
                    <template v-for="(name, i) in tabNames" :key="i">
                        <q-tab-panel :name="name" class="q-py-none">
                            <component :is="Integrations[_.upperFirst(name)]" :settings="settings" :data="data"/>
                        </q-tab-panel>
                    </template>
                </q-tab-panels>
            </template>
        </q-splitter>
    </div>
</template>

<script setup>
import * as Integrations from "@/Pages/Admin/Settings/Blocks/Integration";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const splitterModel = ref();
const tab = ref('ldap');
const tabNames = ref(['ldap', 'delo', 'pure', 'dadata', 'srvbus', 'youtrack', 'telegram']);
</script>
