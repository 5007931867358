<template>
    <q-input v-model="form.description"
             :label="$translate('module-syexc.proposal.form.fields.description')"
             :error="form.errors.hasOwnProperty('description')"
             :error-message="_.get(form.errors, 'description')"
             type="textarea"
             rows="5"
    />
    <div class="q-my-sm">
        <div class="q-field__label">{{ $translate('module-syexc.proposal.form.fields.files') }}</div>
        <q-field v-for="(file, i) in form.oldFiles" class="q-mb-sm">
            <template v-slot:control>
                <div class="full-width self-center">
                    <a :href="filePath(file.path)" class="text-brand cursor-pointer" target="_blank">{{ file.name }}</a>
                </div>
            </template>
            <template v-slot:prepend>
                <q-icon name="attach_file"/>
            </template>
            <template v-slot:append>
                <q-btn @click="deleteUploadedFile(i)" color="negative" icon="o_delete" flat dense>
                    <q-tooltip class="text-body2">{{ $translate('buttons.delete') }}</q-tooltip>
                </q-btn>
            </template>
        </q-field>
        <q-file v-model="form.files[i]" v-for="(file, i) in form.files"
                :error="form.errors.hasOwnProperty('files')"
                :error-message="_.get(form.errors, 'files')"
                dense
        >
            <template v-slot:prepend>
                <q-icon name="attach_file"/>
            </template>
            <template v-slot:append>
                <q-btn @click="deleteFile(i)" color="negative" icon="o_delete" flat dense>
                    <q-tooltip class="text-body2">{{ $translate('buttons.delete') }}</q-tooltip>
                </q-btn>
            </template>
        </q-file>
        <q-btn :label="$translate('buttons.add')"
               @click="addFile"
               icon="o_add"
               dense
        />
        <div class="text-negative"
             v-if="form.errors.hasOwnProperty('files')" v-html="_.get(form.errors, 'files')">
        </div>
    </div>
    <q-input v-model="form.comment"
             :label="$translate('module-syexc.proposal.form.fields.comment')"
             type="textarea"
             rows="4"
    />
</template>

<script setup>
import {getCurrentInstance, onMounted} from "vue";
import _ from "lodash";

const props = defineProps({
    form: Object,
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const addFile = () => {
    props.form.files.push([]);
};
const deleteFile = (index) => {
    _.remove(props.form.files, (o, i) => i === index);
};
const deleteUploadedFile = (index) => {
    _.remove(props.form.oldFiles, (o, i) => i === index);
};

const filePath = (path) => '/storage/' + path;

// watch(props, () => {
//     if (props.sendForm) {
//         props.form.post(props.action);
//     }
// });

onMounted(() => {
    // if (props.proposal) {
    //     form.description = props.proposal.description;
    //     form.oldFiles = props.proposal.files;
    //     form.comment = props.proposal.comment;
    // } else {
    //     addFile();
    // }
});
</script>
