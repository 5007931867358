<template>
    <Card :title="$translate('module-syexc.proposal.card.history.title')"
          class="q-mt-md"
          hide-actions
          no-card-section>
        <template v-slot:default>
            <q-list separator class="text-small">
                <q-item v-for="(item, i) in history" :key="i">
                    <q-item-section>
                        <q-item-label caption class="flex justify-between">
                            <span>{{ moment(_.get(item, 'created_at')).format($translate('common.datetime.js')) }}</span>
                            <span>{{ _.get(item, 'user.fulled_name') }}</span>
                        </q-item-label>
                        <q-item-label>
                            <div v-html="handleOldHistoryContent(_.get(item, 'content'))"></div>
                            <div v-if="_.get(item, 'comment')" class="text-italic">
                                {{ _.get(item, 'comment') }}
                            </div>
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";
import moment from "moment";

const props = defineProps({
    history: {
        type: Array,
        required: true
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const handleOldHistoryContent = (content) => {
    if (content.includes('text-success')) {
        return content.replace('text-success', 'text-positive');
    }
    if (content.includes('text-danger')) {
        return content.replace('text-danger', 'text-negative');
    }
    return content;
};
</script>
