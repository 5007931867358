<template>
    <Card :title="$translate('equipment.catalogue.title.show')+': '+equipmentCatalogue.name"
          title-bg="deep-purple-1"
          header-toolbar hide-actions>
        <template v-slot:headerActions>
            <q-btn icon="navigate_before"
                   :label="$translate('buttons.back')"
                   color="deep-purple-4"
                   @click="router.visit($route('admin.equipment-catalogue.index'))"
                   dense no-caps no-wrap/>
        </template>
        <div class="row q-gutter-md" :class="{'column':$q.screen.lt.md}">
            <Card hide-actions class="col" :title="$translate('equipment.catalogue.title.baseinfo')">

                <q-field :label="$translate('equipment.catalogue.fields.name')" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">{{ _.get(equipmentCatalogue, 'name') }}</div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.catalogue.fields.description')" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">{{ _.get(equipmentCatalogue, 'description') }}</div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.catalogue.fields.category')" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ _.get(equipmentCatalogue, 'equipment_category.name') }}
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.catalogue.fields.brand')" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">{{ _.get(equipmentCatalogue, 'brand') }}</div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.catalogue.fields.model')" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">{{ _.get(equipmentCatalogue, 'model') }}</div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.catalogue.fields.vendor')" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">{{ _.get(equipmentCatalogue, 'vendor') }}</div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.catalogue.fields.country')" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">{{ _.get(equipmentCatalogue, 'country_origin.name') }}</div>
                    </template>
                </q-field>
            </Card>
            <Card :title="$translate('equipment.catalogue.title.equipments')"
                  hide-actions class="col" no-card-section>
                <div style="max-height: 70vh" class="scroll-y">
                    <q-list separator dense>
                        <template v-for="(equipment) in equipmentCatalogue.equipments">
                            <q-item @click="router.visit($route('equipment.show',equipment))"
                                    clickable>
                                <q-item-section side>
                                    <q-item-label>
                                        {{ equipment.id }}
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>
                                        {{ equipment.display_name }}
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label class="flex items-center"
                                                  :title="$translate('equipment.item.fields.ssc')">
                                        <q-icon name="business"/>
                                        <span class="q-ml-xs">{{ equipment.ssc.name_short }}</span>
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </div>
            </Card>
        </div>
    </Card>
</template>

<script setup lang="ts">
import {getCurrentInstance,} from "vue";
import {router} from "@inertiajs/vue3";
import {Card} from "@/Components/Block/";
import {useQuasar} from 'quasar'
import _ from 'lodash';

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route
const $q = useQuasar()

interface Props {
    equipmentCatalogue: any
}

defineProps<Props>()
</script>