<template>
    <Card title-bg="pink-1"
          :title="$translate('module-archive.project.list')"
          no-card-section
          hide-actions
          header-toolbar
    >
        <DataTable allow-search :columns="columns" :request-route="$route('ajax.archive', {m: 'projects'})">
            <template v-slot:search-info>
                <SearchInfo/>
            </template>
            <template v-slot:body-cell-number="props">
                <q-td :props="props" class="no-wrap">
                    <span v-html="props.value"></span>
                </q-td>
            </template>
            <template v-slot:body-cell-leader_fio="props">
                <q-td :props="props" class="no-wrap">
                    <span v-html="props.value"></span>
                </q-td>
            </template>
            <template v-slot:body-cell-bdate="props">
                <q-td :props="props" class="no-wrap">
                    <span v-html="props.value"></span>
                </q-td>
            </template>
            <template v-slot:body-cell-edate="props">
                <q-td :props="props" class="no-wrap">
                    <span v-html="props.value"></span>
                </q-td>
            </template>
            <template v-slot:body-cell-project_name="props">
                <q-td :props="props" class="no-wrap">
                    <span v-html="props.value"></span>
                </q-td>
            </template>
            <template v-slot:header-cell-project_type="props">
                <q-th :props="props">
                    <span v-html="props.col.label"></span>
                </q-th>
            </template>
            <template v-slot:body-cell-project_type="props">
                <q-td :props="props" class="no-wrap">
                    <span v-html="props.value"></span>
                </q-td>
            </template>
            <template v-slot:body-cell-project_state="props">
                <q-td :props="props" class="no-wrap">
                    <span v-html="props.value"></span>
                </q-td>
            </template>
            <template v-slot:header-cell-counts="props">
                <q-th :props="props">
                    {{ props.col.label }}
                </q-th>
            </template>
            <template v-slot:body-cell-counts="props">
                <q-td :props="props" class="no-wrap">
                    <div class="row column items-center" v-html="props.value"></div>
                </q-td>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {getCurrentInstance} from "vue";
import SearchInfo from "@/Components/SearchInfo.vue";
import DataTable from "@/Components/DataTable.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'number', align: 'left', label: $translate('module-archive.project.fields.number'), field: 'number'},
    {
        name: 'leader_fio',
        align: 'left',
        label: $translate('module-archive.project.fields.leader_fio'),
        field: 'leader_fio'
    },
    {name: 'bdate', align: 'left', label: $translate('module-archive.project.fields.bdate'), field: 'bdate'},
    {name: 'edate', align: 'left', label: $translate('module-archive.project.fields.edate'), field: 'edate'},
    {
        name: 'project_name',
        align: 'left',
        label: $translate('module-archive.project.fields.project_name'),
        field: 'project_name'
    },
    {name: 'rc', align: 'left', label: $translate('module-archive.project.fields.rc'), field: 'rc'},
    {
        name: 'project_type',
        align: 'left',
        label: $translate('module-archive.project.fields.project_type'),
        field: 'project_type'
    },
    {
        name: 'project_state',
        align: 'left',
        label: $translate('module-archive.project.fields.project_state'),
        field: 'project_state'
    },
    {
        name: 'counts',
        align: 'left',
        label: $translate('module-archive.project.fields.counts'),
        field: 'counts',
        headerClasses: 'text-wrap',
        style: 'width: 5rem',
    },
];
</script>
