<template>
    <Card :title="$translate('equipment.item.tabs.finance')" hide-actions>
        <div class="q-gutter-y-md">
            <div class="row q-gutter-x-lg">
                <q-field :label="$translate('equipment.item.fields.year_placement')" stack-label
                         class="col" borderless>
                    <template v-slot:control>
                        {{ moment(equipment.year_placement).format($translate('common.date.js')) }}
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.item.fields.lifetime')" stack-label
                         class="col" borderless>
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ equipment.lifetime }} {{ $translate('units.years') }}
                        </div>
                    </template>
                </q-field>
            </div>

            <div class="row q-gutter-x-lg">
                <q-field :label="$translate('equipment.item.fields.initial_cost')" stack-label
                         class="col" borderless>
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ equipment.initial_cost }} {{ $translate('units.rub') }}
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.item.fields.depreciation_real')" stack-label
                         class="col" borderless>
                    <template v-slot:control>
                        <div class="self-center full-width">
                            {{ equipment.depreciation_real }} {{ $translate('units.rub-hour') }}
                        </div>
                    </template>
                </q-field>
            </div>
        </div>
    </Card>
</template>

<script setup>
import {getCurrentInstance, watch, watchEffect, watchSyncEffect} from "vue";
import _ from "lodash";
import moment from 'moment';
import {Card} from "@/Components/Block";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    equipment: {
        type: Object
    }
})

const depreciationReal = () => {
    let deprecation = 0;
    if (props.equipment.lifetime > 0 && props.equipment.initial_cost > 0) {
        deprecation = Number(props.equipment.initial_cost / props.equipment.lifetime / 365 / 24).toFixed(2);
    }
    props.equipment.depreciation_real = deprecation
}

watchEffect(() => {
    let initial_cost = 0;
    let year_placement = moment();
    let lifetime = [];
    _.each(props.equipment.complex, (complex) => {
        initial_cost = initial_cost + complex.initial_cost;
        if (complex.year_placement && moment(complex.year_placement).isBefore(year_placement)) {
            year_placement = moment(complex.year_placement);
        }
        lifetime.push(complex.lifetime);
    });
    _.set(props.equipment, 'initial_cost', initial_cost);
    _.set(props.equipment, 'year_placement', year_placement.format(moment.HTML5_FMT.DATE));
    _.set(props.equipment, 'lifetime', Number(_.sum(lifetime) / lifetime.length).toFixed(0));
    depreciationReal()
})
</script>