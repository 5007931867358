<template>
    <Card :title="$translate('role.title')" title-bg="blue-grey-3"
          header-toolbar hide-actions no-card-section>
        <template v-slot:headerActions>
            <q-btn icon="add"
                   :label="$translate('role.actions.add')"
                   @click="addDialog = true"
                   dense no-caps
                   color="blue-grey-6"/>
        </template>
        <q-splitter v-model="splitterModel"
                    :limits="[20,80]"
                    @update:model-value="saveSplitterModel">
            <template v-slot:before>
                <q-input ref="filterRef" v-model="filter"
                         :label="$translate('role.title-search')"
                         debounce="1000"
                         clear-icon="clear"
                         @update:model-value="filterItems"
                         clearable dense filled square>
                    <template v-slot:prepend>
                        <q-icon name="search"/>
                    </template>
                </q-input>
                <q-scroll-area :style="areaHeight">
                    <q-list separator>
                        <template v-for="(role) in roles">
                            <q-item clickable
                                    @click="loadRole(role.id)"
                                    :active="currentRoleId === role.id">
                                <q-item-section>
                                    <q-item-label class="flex items-center">
                                        <span>{{ role.name }}</span>
                                        <q-icon name="o_lock" v-if="role.system"
                                                :title="$translate('role.fields.system')"/>
                                    </q-item-label>
                                    <q-item-label caption lines="2">{{ role.description }}</q-item-label>
                                </q-item-section>
                                <q-item-section side top>
                                    <div class="text-right">
                                        <template v-for="(scope) in role.scopes">
                                            <q-chip size="sm" dense square>{{ scope.label }}</q-chip>
                                        </template>
                                    </div>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </q-scroll-area>
            </template>

            <template v-slot:after>
                <RoleCard v-if="currentRoleId"
                          :current-role="currentRole"
                          @on-success="onSuccess"/>
            </template>

        </q-splitter>
    </Card>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('role.title-add')"
              title-bg="blue-grey-3"
              in-modal show-close-button >
            <q-item-label overline>{{ $translate('role.fields.display_name') }}</q-item-label>
            <template v-for="(lang_name,locale) in $translate('fields.locale')">
                <q-input v-model="addForm.display_name_i18n[locale]" :label="lang_name" dense counter
                         :error-message="addForm.errors['display_name_i18n.'+locale]"
                         :error="addForm.errors.hasOwnProperty('display_name_i18n.'+locale)"/>
            </template>

            <q-item-label overline>{{ $translate('role.fields.description') }}</q-item-label>
            <template v-for="(lang_name,locale) in $translate('fields.locale')">
                <q-input type="textarea" rows="3" v-model="addForm.description_i18n[locale]" :label="lang_name"
                         dense counter
                         :error-message="addForm.errors['description_i18n.'+locale]"
                         :error="addForm.errors.hasOwnProperty('description_i18n.'+locale)"/>
            </template>

            <template v-slot:actions>
                <q-btn color="positive" icon="o_save" :label="$translate('buttons.add')" @click="submitForm"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import _ from "lodash";
import axios from "axios";
import RoleCard from "@/Components/Roles/RoleCard.vue";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()

const $q = useQuasar();
const $translate = app.appContext.config.globalProperties.$translate

const splitterModel = ref(35)

const saveSplitterModel = (value) => {
    $q.cookies.set('roles_index_split', value, {expires: 365})
}
const getSplitterModel = () => {
    return Number($q.cookies.get('roles_index_split'))
}
const initSplitModel = () => {
    let split = getSplitterModel()
    if (!split || split === 'NaN') {
        split = 50;
    }
    splitterModel.value = split;
}

const areaHeight = computed(() => {
    return {
        height: 'calc(100vh - 180px)'
    }
})

const roles = ref([])
const filter = ref('');

const currentRoleId = ref(null)
const currentRole = ref({})

const addDialog = ref(false)

const addForm = useForm({
    display_name_i18n: {},
    description_i18n: {},
})

onMounted(() => {
    initSplitModel()
    roles.value = usePage().props.roles;
})

const onSuccess = () => {
    roles.value = usePage().props.roles;
    loadRole(currentRoleId.value)
}

const filterItems = (search) => {
    if (search === '') {
        roles.value = usePage().props.roles;
    } else {
        roles.value = _.filter(usePage().props.roles, (role) => {
            return (_.lowerCase(role.name).indexOf(_.lowerCase(search)) >= 0);
        })
    }
}

const loadRole = (id) => {
    currentRoleId.value = id
    axios.get(route('admin.roles.show', id))
        .then((response) => {
            currentRole.value = response.data
        })
        .catch(() => {
            currentRole.value = null
        })
}

const submitForm = () => {
    addForm.post(route('admin.roles.store'), {
        onSuccess: () => {
            addDialog.value = false
            onSuccess()
        }
    })
}
</script>