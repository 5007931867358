<template>
    <q-field :label="$translate('equipment.item.title.dopinfo')" stack-label borderless
             :error-message="errors['dopinfo']"
             :error="errors.hasOwnProperty('dopinfo')">
        <template v-slot:control>
            <div class="self-stretch full-width">
                <q-editor ref="dopinfoRef" @paste="onPasteDopinfo" v-model="equipment.dopinfo" min-height="7rem"/>
            </div>
        </template>
    </q-field>

    <q-field :label="$translate('equipment.item.fields.public-description')" stack-label borderless
             :error-message="errors['public.description']"
             :error="errors.hasOwnProperty('public.description')">
        <template v-slot:control>
            <div class="self-stretch full-width">
                <q-editor ref="publicDescriptionRef"
                          @paste="onPasteDescription"
                          v-model="equipment.public.description"
                          min-height="7rem"
                />
            </div>
        </template>
    </q-field>

    <q-field :label="$translate('equipment.item.fields.public-technical')" stack-label borderless
             :error-message="errors['public.technical']"
             :error="errors.hasOwnProperty('public.technical')">
        <template v-slot:control>
            <div class="self-stretch full-width">
                <q-editor  ref="publicTechnicalRef"
                           @paste="onPasteTechnical"
                           v-model="equipment.public.technical"
                           min-height="7rem"
                />
            </div>
        </template>
    </q-field>

    <q-field :label="$translate('equipment.item.fields.public-images')" stack-label borderless>
        <template v-slot:control>
            <div class="self-stretch full-width">
                <q-file v-model="public_images" multiple append use-chips accept="image/*">
                    <template v-slot:prepend>
                        <q-icon name="attach_file"/>
                    </template>
                </q-file>
                <div class="row q-gutter-y-md q-gutter-x-xs justify-between">
                    <template v-for="(image,index) in public_images">
                        <div class="col-4 q-pa-sm bg-grey-1">
                            <q-img :src="previewUrl(image)" fit="cover"/>
                            <q-item-label class="text-center">{{ _.get(image,'name') }}</q-item-label>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </q-field>

</template>

<script setup lang="ts">
import {computed, getCurrentInstance, ref, watch} from "vue";
import {usePage} from "@inertiajs/vue3";
import {Equipment} from '@/entity'
import _ from 'lodash'
import helpers from "@/helpers";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

const equipment: Equipment = defineModel()

const errors = computed(() => usePage().props.errors)

const public_images = ref([])

const dopinfoRef = ref()
const publicDescriptionRef = ref()
const publicTechnicalRef = ref()

const onPasteDopinfo = (evt: any) => {
    helpers.sanitizeOnPaste(evt, dopinfoRef)
}
const onPasteDescription = (evt: any) => {
    helpers.sanitizeOnPaste(evt, publicDescriptionRef)
}
const onPasteTechnical = (evt: any) => {
    helpers.sanitizeOnPaste(evt, publicTechnicalRef)
}

watch(public_images, () => {
    equipment.value.public.images = public_images;
})

const previewUrl = (image: any) => {
    return URL.createObjectURL(image);
}
</script>