<template>
    <q-list v-if="services.hasOwnProperty('cadre')">
        <NavigationItem v-if="checkPerms()"
                        :title="$translate('menu.cadre')"
                        :sidebar="sidebar"
                        color="dark"
                        icon="o_manage_accounts"
        >
            <template #items>
                <q-item v-if="services.cadre.items.main"
                        @click="router.visit($route('cadre.dashboard'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.cadre-main") }}
                    </q-item-section>
                </q-item>
                <q-separator />
                <q-item v-if="services.cadre.items.main"
                        @click="router.visit($route('cadre.department.index'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.cadre-department") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.cadre.items.main"
                        @click="router.visit($route('cadre.staff.index'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.cadre-staff") }}
                    </q-item-section>
                </q-item>
                <q-separator />
                <q-item v-if="services.cadre.items.positions"
                        @click="router.visit($route('cadre.positions'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.cadre-positions") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.cadre.items.allowance"
                        @click="router.visit($route('cadre.allowance'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.cadre-allowance") }}
                    </q-item-section>
                </q-item>
                <q-separator />
                <q-item v-if="services.cadre.items.vacation"
                        @click="router.visit($route('cadre.vacation.index'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.cadre-vacation") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.cadre.items.sicklist"
                        @click="router.visit($route('cadre.sicklist.index'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.cadre-sicklist") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.cadre.items.dutyjourney"
                        @click="router.visit($route('cadre.dutyjourney.index'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.cadre-dutyjourney") }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
    </q-list>
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";

const props = defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const page = usePage();
const services = computed(() => page.props.services);

const checkPerms = () => _.some(
    _.values(usePage().props.services.cadre.items),
    v => v === true
);
</script>
