<template>
    <div class="line-date-chart" ref="chartRef"></div>
</template>

<script setup lang="ts">
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {onBeforeUnmount, onMounted, Ref, ref, ShallowRef, shallowRef} from "vue";
import {AnyValuesObject, StringValuesObject} from "@/entity/types";
import {ConfigHandler, ILineDateChartConfig} from "@/entity/charts";
import _ from "lodash";
import moment from "moment";
import {set, toValue} from "@vueuse/core";

interface Props {
    data: AnyValuesObject[],
    config: ILineDateChartConfig,
    locale: AnyValuesObject,
    legend: StringValuesObject
}

const props = defineProps<Props>();

const configHandler: ConfigHandler = ConfigHandler.new(props.config);

const chartRef: Ref<any> = ref<any>();

const am5Root: ShallowRef = shallowRef<any>();
const am5Chart: ShallowRef = shallowRef<any>();
const am5YAxis: ShallowRef = shallowRef<any>();
const am5XAxis: ShallowRef = shallowRef<any>();

const createChart: Function = (): void => {
    clearRefs();

    let root: am5.Root = am5.Root.new(toValue(chartRef));
    root.setThemes([am5themes_Animated.new(root)]);
    root.locale = props.locale;
    root._logo!.dispose();
    set(am5Root, { root: root });

    set(am5Chart, {
        chart: am5Root.value.root.container.children.push(
            am5xy.XYChart.new(am5Root.value.root, Object.assign({}, configHandler.get("chartSettings"), {
                layout: am5Root.value.root.verticalLayout
            }))
        )
    });
    am5Chart.value.chart.get("colors").set("colors", getChartColors());

    set(am5XAxis, {
        xAxis: am5Chart.value.chart.xAxes.push(
            am5xy.DateAxis.new(am5Root.value.root, getXAxisSettings())
        )
    });

    set(am5YAxis, {
        yAxis: am5Chart.value.chart.yAxes.push(
            am5xy.ValueAxis.new(am5Root.value.root, getYAxisSettings())
        )
    });

    // Set chart widgets (cursor, scrollbar, ...)
    setChartWidgets();

    createSeries();

    am5Chart.value.chart.appear(1000, 100); // Make stuff animate on load

    createLegend();
};

const clearRefs: Function = (): void => {
    set(am5Root, null);
    set(am5Chart, null);
    set(am5YAxis, null);
    set(am5XAxis, null);
};

const getChartColors: Function = (): am5.Color[] => _.map(
    configHandler.get("chartColors"),
    (color) => am5.color(color)
);

const getXAxisSettings: Function = (): AnyValuesObject => ({
    baseInterval: configHandler.get("dateXAxis.baseInterval"),
    maxDeviation: configHandler.get("dateXAxis.maxDeviation"),
    renderer: am5xy.AxisRendererX.new(am5Root.value.root, {}),
    tooltip: am5.Tooltip.new(am5Root.value.root, {})
});

const getYAxisSettings: Function = (): AnyValuesObject => ({
    extraTooltipPrecision: configHandler.get("valueYAxis.extraTooltipPrecision"),
    numberFormat: configHandler.get("valueYAxis.numberFormat"),
    renderer: am5xy.AxisRendererY.new(am5Root.value.root, {}),
    tooltip: am5.Tooltip.new(am5Root.value.root, {})
});

const setChartWidgets: Function = (): void => {
    if (configHandler.has("chartWidgets")) {
        if (configHandler.has("chartWidgets.cursor")) {
            am5Chart.value.chart.set(
                "cursor",
                am5xy.XYCursor.new(am5Root.value.root, configHandler.get("chartWidgets.cursor"))
            );
        }
        if (configHandler.has("chartWidgets.scrollbarX")) {
            let scrollbarX = am5Chart.value.chart.set(
                "scrollbarX",
                am5.Scrollbar.new(am5Root.value.root, configHandler.get("chartWidgets.scrollbarX"))
            );
            scrollbarX.startGrip.set("scale", 0.7);
            scrollbarX.endGrip.set("scale", 0.7);
            // make thumb semi-transparent red, while disabling both grips
            // scrollbarX.thumb.setAll({fill: am5.color(0x550000), fillOpacity: 0.1});
            // scrollbarX.startGrip.setAll({visible: false});
            // scrollbarX.endGrip.setAll({visible: false});
        }
    }
};

const getLineSeriesSettings: Function = (name: string): AnyValuesObject => ({
    name: name,
    xAxis: am5XAxis.value.xAxis,
    yAxis: am5YAxis.value.yAxis,
    valueYField: configHandler.get("lineSeries.valueYField"),
    valueXField: configHandler.get("lineSeries.valueXField"),
    tooltip: am5.Tooltip.new(am5Root.value.root, {
        labelText: configHandler.get("lineSeries.tooltipLabelText")
    })
});

const createSeries: Function = (): void => {
    _.forEach(props.data, (value, key) => {
        let series = am5Chart.value.chart.series.push(
            am5xy.LineSeries.new(am5Root.value.root, getLineSeriesSettings(props.legend[key]))
        );
        let data: AnyValuesObject[] = [];
        _.forEach(value, (item: AnyValuesObject) => {
            let key: string = configHandler.get("lineSeries.valueXField");
            data.push(Object.assign({}, item, {
                [key]: moment(item[key]).toDate().getTime()
            }));
        });
        series.bullets.push(() => am5.Bullet.new(am5Root.value.root, {
            sprite: am5.Circle.new(am5Root.value.root, {
                radius: 5,
                fill: series.get("fill")
            })
        }));
        series.strokes.template.setAll({
            strokeWidth: 2
        });
        series.data.setAll(data);
        series.appear(1000); // Make stuff animate on load
    });
};

const createLegend: Function = (): void => {
    let legend = am5Chart.value.chart.children.push(
        am5.Legend.new(am5Root.value.root, {
            centerX: am5.p50,
            x: am5.p50,
            centerY: am5.p50,
            // layout: am5.GridLayout.new(am5Root.value.root, {
            //     maxColumns: 3,
            //     fixedWidthGrid: true
            // }),
            // Scrollable legend
            // height: am5.percent(100),
            // verticalScrollbar: am5.Scrollbar.new(am5Root.value.root, {
            //     orientation: "vertical"
            // })
        })
    );
    legend.data.setAll(am5Chart.value.chart.series.values);
};

onMounted(() => {
    createChart();
});

onBeforeUnmount(() => {
    if (am5Root.value.root) {
        am5Root.value.root.dispose();
    }
});
</script>

<style scoped>
.line-date-chart {
    width: 100%;
    height: 100%;
}
</style>