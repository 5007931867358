<template>
    <table class="q-table">
        <tbody>
        <tr class="border-bottom">
            <td>{{ $translate('settings.about.app-version') }}</td>
            <td>{{ usePage().props.appVersion }}</td>
        </tr>
        <tr>
            <td>{{ $translate('settings.about.build-date') }}</td>
            <td>{{ usePage().props.appBuild }}</td>
        </tr>
        <tr>
            <td>{{ $translate('settings.about.php-version') }}</td>
            <td><a @click="router.get($route('admin.phpinfo'))" target="_blank">
                {{ usePage().props.appPhpVersion }}</a>
            </td>
        </tr>
        <tr>
            <td>{{ $translate('settings.about.server-name') }}</td>
            <td>{{ usePage().props.appServerName }} ({{ usePage().props.appServerIP }})</td>
        </tr>
        <tr>
            <td>{{ $translate('settings.about.user-ip') }}</td>
            <td>{{ usePage().props.appUserIP }}</td>
        </tr>
        </tbody>
    </table>
</template>

<script setup>
import {router, usePage} from "@inertiajs/vue3";
import {getCurrentInstance} from "vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
</script>
