import _ from "lodash"

export default {
    jsonParse(json) {
        return JSON.parse(_.isEmpty(json) ? "[]" : json)
    },
    toOptions(data, optValue = 'value', optLabel = 'label', reversed = false) {
        return reversed ?
            _.map(data, (value, key) => ({[optLabel]: key, [optValue]: value})) :
            _.map(data, (value, key) => ({[optLabel]: value, [optValue]: key}))
    },
    formatNumber(number, digits = 2) {
        // Use undefined in place of the first argument to use the system locale
        // (the user locale in case the code is running in a browser)
        return Number(number).toLocaleString(undefined, {minimumFractionDigits: digits})
    },
    formatMoney(number) {
        // Use undefined in place of the first argument to use the system locale
        // (the user locale in case the code is running in a browser)
        return Number(number).toLocaleString(undefined, {style: 'currency', currency: 'RUB'})
    },
    hour2time(time) {
        time = time * 60;
        return this.int2time(time)
    },
    int2time(number) {
        if (number === null || number === 0) {
            return '00:00'
        }
        if (number < 1) {
            return '00:01';
        }
        let _hours = _.floor(number / 60);
        let _minutes = _.toInteger((number % 60));
        return (_hours < 10 ? '0' : '') + _hours + ':' + (_minutes < 10 ? '0' : '') + _minutes
    },
    filled(value) {
        if (_.isNull(value)) {
            return false
        }
        if (_.isString(value) && ('' === _.trim(value) || '0000-00-00' === _.trim(value))) {
            return false
        }
        if (_.isArray(value) || _.isObject(value)) {
            if (_.isArray(value) && 0 === value.length) {
                return false
            }
            if (_.isObject(value) && 0 === _.keys(value).length) {
                return false
            }
        }
        if (!_.isNumber(value) && _.isEmpty(value)) {
            return false
        }
        return true
    },
    twoDecimals(n) {
        // Округление до N ненулевых знаков после запятой
        let log10 = n ? Math.floor(Math.log10(n)) : 0, div = log10 < 0 ? Math.pow(10, 1 - log10) : 100
        return Math.round(n * div) / div
    },
    nl2br(text) {
        if (_.isNull(text)) {
            return null
        }
        return text.replace(/([^>])\n/g, '$1<br/>');
    },
    toggleCheckboxes: function (target, value) {
        let checkboxes = document.getElementsByName(target);
        for (let i = 0, n = checkboxes.length; i < n; i++) {
            checkboxes[i].checked = value;
        }
    },
    errorImg: function (e) {
        e.target.src = '/img/no-image.png'
    },
    setMany(from, to) {
        _.forEach(from, (value, property) => {
            _.set(to, property, value);
        });
    },
    sanitizeOnPaste(evt, reference) {
        // Let inputs do their thing, so we don't break pasting of links.
        if (evt.target.nodeName === 'INPUT') return
        let text, onPasteStripFormattingIEPaste
        evt.preventDefault()
        evt.stopPropagation()
        if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
            text = evt.originalEvent.clipboardData.getData('text/plain')
            reference.value.runCmd('insertText', text)
        }
        else if (evt.clipboardData && evt.clipboardData.getData) {
            text = evt.clipboardData.getData('text/plain')
            reference.value.runCmd('insertText', text)
        }
        else if (window.clipboardData && window.clipboardData.getData) {
            if (!onPasteStripFormattingIEPaste) {
                onPasteStripFormattingIEPaste = true
                text = window.clipboardData.getData('text/plain')
                reference.value.runCmd('ms-pasteTextOnly', text)
            }
            onPasteStripFormattingIEPaste = false
        }
    }
}
