<template>
    <Card :title="$translate('project.title.list-'+list)"
          title-bg="teal-4" title-text="white"
          header-toolbar hide-actions no-card-section>
        <template v-slot:headerActions>
            <q-btn :icon="showingArchive?'inventory_2':'o_inventory_2'"
                   color="dark"
                   @click="toggleShowArchive"
                   outline
                   dense>
                <q-tooltip class="text-body2">
                    {{
                        showingArchive ? $translate('project-type.actions.hide-archive') : $translate('project-type.actions.show-archive')
                    }}
                </q-tooltip>
            </q-btn>
        </template>
        <DataTable allow-search
                   :columns="columns"
                   :request-route="$route('ajax.project', {m: 'list', state: list})"
                   :search="filter"
        >
            <template v-slot:body-cell-number="props">
                <q-td :props="props">
                    <a :href="$route('project.show',props.row.number)"
                       @click.prevent="router.get($route('project.show',props.row.number))"
                       :title="$translate('project.actions.about')"
                       v-html="props.row.number"></a>
                </q-td>
            </template>
            <template v-slot:header-cell-status="props">
                <q-th :props="props">
                    <div class="flex items-center justify-center">
                        <span v-html="props.col.label"></span>
                        <q-icon name="o_filter_alt"
                                size="xs"
                                color="grey-7"
                                :title="$translate('фильтр по статусу')"
                                class="q-ml-sm cursor-pointer">
                            <q-menu anchor="bottom middle" self="top middle">
                                <q-list dense>
                                    <template v-for="(name,state) in $translate('project.status')">
                                        <q-item dense clickable v-close-popup @click="filterBy('status',name)">
                                            <q-item-section>
                                                <q-item-label>{{ name }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-list>
                            </q-menu>
                        </q-icon>
                    </div>
                </q-th>
            </template>
            <template v-slot:body-cell-status="props">
                <q-td :props="props">
                    <div v-html="props.row.status_text" @dblclick="filterBy('status',props.row.status_text)"></div>
                    <q-menu touch-position context-menu>
                        <q-list dense style="min-width: 100px">
                            <q-item clickable v-close-popup @click="filterBy('status',props.row.status_text)">
                                <q-item-section side>
                                    <q-icon name="o_filter_alt" size="sm"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>
                                        по статусу "<span v-html="props.row.status_text"></span>"
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-td>
            </template>
            <template v-slot:body-cell-name="props">
                <q-td :props="props">
                    <div class="flex items-start no-wrap q-gutter-x-xs">
                        <a :href="$route('project.show',props.row.number)"
                           @click.prevent="router.get($route('project.show',props.row.number))"
                           v-html="props.row.name" class="col"></a>
                        <div v-html="props.row.source_text" class="col-auto cursor-pointer"
                             @click="filterBy('source',props.row.source)"/>
                    </div>
                    <div class="flex items-start">
                        <template v-for="(state) in props.row.ssc_state">
                            <div v-html="state"/>
                        </template>
                    </div>
                </q-td>
            </template>
            <template v-slot:body-cell-type_id="props">
                <q-td :props="props">
                    <div v-html="props.row.type" @dblclick="filterBy('type',props.row.type)"></div>
                    <q-menu touch-position context-menu>
                        <q-list dense style="min-width: 100px">
                            <q-item clickable v-close-popup @click="filterBy('type',props.row.type)">
                                <q-item-section side>
                                    <q-icon name="o_filter_alt" size="sm"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>
                                        по типу "<span v-html="props.row.type"></span>"
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-td>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from 'vue'
import {router} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import DataTable from "@/Components/DataTable.vue";
import {useQuasar} from "quasar";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();

const props = defineProps({
    'list': {
        type: String,
        default: 'all'
    }
});

const filter = ref(null);
const columns = [
    {name: 'actions', style: 'width:40px'},
    {
        name: 'number',
        label: $translate('project.fields.number'),
        field: 'number',
        classes: 'text-no-wrap',
        align: 'left',
        sortable: true,
    },
    {
        name: 'status',
        label: $translate('project.fields.status'),
        field: 'status',
        classes: 'text-no-wrap',
        align: 'center',

    },
    {
        name: 'name',
        label: $translate('project.fields.name'),
        field: 'name',
        classes: 'w-50',
        align: 'left',
        sortable: true,
    },
    {

        name: 'type_id',
        label: $translate('project.fields.type'),
        field: 'type',
        align: 'left',
    },
    {
        name: 'leader_id',
        label: $translate('project.fields.leader'),
        field: 'leader',
        align: 'left'
    },
    {
        name: 'date_start',
        label: $translate('project.fields.date_start'),
        field: 'date_start',
        align: 'center',
        sortable: true,
    },
    {
        name: 'date_end',
        label: $translate('project.fields.date_end'),
        field: 'date_end',
        align: 'center',
        sortable: true,
    },
];

const showingArchive = ref(false);
const toggleShowArchive = () => {
    let val = !showingArchive.value;
    $q.cookies.set('projectCompleted', val, {expires: 365, path: '/'});
    showingArchive.value = val;
    router.reload();
};

const filterBy = (field, value) => {
    filter.value = '' + field + ':"' + value + '"';
};

onMounted(() => {
    showingArchive.value = ($q.cookies.get('projectCompleted') === 'true');
});
</script>
<style scoped lang="css">
.filter > * {
    padding: 0;
}
</style>