<template>
    <q-table :columns="columns" :rows="reportsList" wrap-cells>
        <template v-slot:body-cell-comment="props">
            <q-td :props="props">
                <div>{{ props.value }}</div>
                <div>
                    <q-chip v-for="(tag, i) in _.filter(props.row.tags)" :key="i" :label="tag" size="sm"/>
                </div>
            </q-td>
        </template>
        <template v-slot:body-cell-period="props">
            <q-td :props="props">
                <div v-html="props.value"></div>
            </q-td>
        </template>
        <template v-slot:body-cell-status="props">
            <q-td :props="props">
                <q-icon :class="{'cursor-help': !_.isEmpty(props.value.title)}"
                        :color="props.value.color"
                        :name="props.value.icon"
                        size="sm"
                >
                    <q-tooltip v-if="!_.isEmpty(props.value.title)" class="text-body2">
                        {{ props.value.title }}
                    </q-tooltip>
                </q-icon>
            </q-td>
        </template>
        <template v-slot:body-cell-actions="props">
            <q-td :props="props">
                <div class="row no-wrap q-gutter-sm">
                    <q-btn v-if="props.row.can.detail"
                           @click="router.visit($route('analytics.report.standard.show', {
                               report: props.row.report,
                               report_data: props.row.id
                           }))"
                           icon="o_visibility"
                           color="primary"
                           size="md"
                           dense
                    >
                        <q-tooltip class="text-body2">
                            {{ $translate('module-analytics.actions.show-report') }}
                        </q-tooltip>
                    </q-btn>
                    <q-btn v-if="props.row.can.save"
                           @click="modals[props.row.id] = !modals[props.row.id]"
                           icon="o_save"
                           color="positive"
                           size="md"
                           dense
                    >
                        <q-tooltip class="text-body2">
                            {{ $translate('module-analytics.actions.save-report') }}
                        </q-tooltip>
                    </q-btn>
                    <q-dialog v-if="props.row.can.save" v-model="modals[props.row.id]">
                        <q-card style="min-width: 350px">
                            <q-card-section>
                                <q-input v-model="comments[props.row.id]"
                                         :label="$translate('module-analytics.fields.save-report.comment')"
                                         type="textarea"
                                         rows="3"
                                />
                                <q-select v-model="tags[props.row.id]"
                                          :label="$translate('module-analytics.fields.save-report.tags')"
                                          new-value-mode="add"
                                          input-debounce="0"
                                          hide-dropdown-icon
                                          use-chips
                                          use-input
                                          multiple
                                          dense
                                />
                            </q-card-section>
                            <q-card-actions align="center">
                                <q-btn :label="$translate('buttons.save')"
                                       color="positive"
                                       @click="submitForm(props.row.report, props.row.id)"
                                />
                            </q-card-actions>
                        </q-card>
                    </q-dialog>
                    <q-btn v-if="props.row.can.delete"
                           @click="deleteReport(props.row.report, props.row.id)"
                           icon="o_delete"
                           color="negative"
                           size="md"
                           dense
                    >
                        <q-tooltip class="text-body2">
                            {{ $translate('module-analytics.actions.delete-report') }}
                        </q-tooltip>
                    </q-btn>
                </div>
            </q-td>
        </template>
    </q-table>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {useQuasar} from "quasar";

const props = defineProps({
    reportsList: {
        type: Array,
        default: [],
    },
});

const $q = useQuasar();
const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const modals = ref([]);
const comments = ref([]);
const tags = ref([]);

_.forEach(props.reportsList, (report, i) => {
    modals.value[report.id] = false;
    comments.value[report.id] = null;
    tags.value[report.id] = null;
});

let form = null;

const submitForm = (report, id) => {
    let forma = {};
    forma['comment'] = comments.value[id];
    forma['tags'] = tags.value[id];
    form = useForm(forma);
    form.post($route('analytics.report.standard.save', {report: report, report_data: id}));
    form = null;
};

const deleteReport = (report, id) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: 'отчёта'}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete($route('analytics.report.standard.delete', {
            report: report,
            report_data: id
        }));
    })
};

const columns = [
    {
        name: 'ssc',
        required: true,
        label: $translate('module-analytics.fields.ssc'),
        align: 'left',
        field: 'ssc',
        sortable: true
    },
    {name: 'comment', label: '', align: 'left', field: 'comment'},
    {name: 'period', label: $translate('module-analytics.fields.period'), align: 'left', field: 'period'},
    {name: 'created', label: $translate('module-analytics.fields.created'), align: 'center', field: 'created'},
    {name: 'expired', label: $translate('module-analytics.fields.expired'), align: 'center', field: 'expired'},
    {name: 'status', label: $translate('module-analytics.fields.status'), align: 'center', field: 'status'},
    {name: 'actions', label: '', align: 'left'}
];

const reloadList = () => {
    let needReload = false;
    _.forEach(props.reportsList, (report) => {
        if (report.created === "") {
            console.debug(report.created)
            needReload = true;
        }
    });
    let timer = null;
    if (needReload) {
        timer = setTimeout(() => {
                    router.reload({
                        onFinish: () => reloadList()
                    })
                }, 30000
        )
    } else {
        clearTimeout(timer);
    }
}

onMounted(() => {
    reloadList()
})
</script>
