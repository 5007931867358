<template>

        <q-card-section>
            <q-field :label="$translate('module-delivery.order.fields.number')"
                     stack-label
                     borderless
                     tag="div">
                <template v-slot:control>
                    <div class="full-width items-center">
                        {{ order.number }}
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.date')"
                     stack-label
                     borderless
                     tag="div">
                <template v-slot:control>
                    <div class="full-width flex items-center">
                        <span>{{ moment(order.date).format($translate('common.date.js')) }}</span>
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.status')"
                     stack-label
                     borderless
                     tag="div">
                <template v-slot:control>
                    <div class="full-width items-center">
                        {{ $translate('module-delivery.order.status.' + order.status) }}
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.project')"
                     stack-label
                     borderless
                     tag="div"
                     v-if="order.project">
                <template v-slot:control>
                    <div class="full-width items-center">
                        {{ order.project.number }}
                        {{ order.project.name }}
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.goods')"
                     stack-label
                     borderless
                     tag="div">
                <template v-slot:control>
                    <div class="full-width items-center q-gutter-y-xs">
                        <div class="row q-ml-md">
                            <span class="col col-md-3">{{ $translate('module-delivery.order.fields.goods_type') }}:</span>
                            <span>{{ $translate('module-delivery.goods.type.' + order.goods.goods_type) }}</span>
                        </div>
                        <div class="row q-ml-md">
                            <span class="col col-md-3">{{ $translate('module-delivery.order.fields.goods_name') }}:</span>
                            <span>{{ order.goods.goods.name }}</span>
                        </div>
                        <div class="row q-ml-md">
                            <span class="col col-md-3">{{ $translate('module-delivery.order.fields.goods_count') }}:</span>
                            <span>{{ order.count }} {{ order.units }}</span>
                        </div>
                    </div>
                </template>
            </q-field>

            <q-field
                    :label="$translate('module-delivery.address.delivery.'+(order.self_delivery?'self':'from'))"
                    stack-label
                    borderless
                    tag="div">
                <template v-slot:control>
                    <div class="full-width items-center q-mb-xs">
                        <div>{{ _.get(order.from_address, 'address') }}</div>
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.delivery_to')"
                     stack-label
                     borderless
                     tag="div"
                     v-if="!order.self_delivery">
                <template v-slot:control>
                    <div class="full-width items-center">
                        <div>{{ _.get(order.to_ssc, 'name') }}</div>
                        <div>{{ _.get(order.to_address, 'address') }}</div>
                        <div>{{ _.get(order, 'room') }}</div>
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.comment')"
                     stack-label
                     borderless
                     tag="div">
                <template v-slot:control>
                    <div class="full-width items-center" v-html="helpers.nl2br(order.comment)"/>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.user')"
                     stack-label
                     borderless>
                <template v-slot:control>
                    <StaffItem :user="order.user"
                               :staff="order.user"
                               hide-avatar
                               hide-actions/>
                </template>
            </q-field>
        </q-card-section>

</template>

<script setup>
import Print from "@/Layout/Print.vue";
import {getCurrentInstance} from "vue";
import StaffItem from "@/Components/SSC/Staff/StaffItem.vue";
import helpers from "@/helpers";
import moment from "moment";
import _ from "lodash";

defineOptions({layout: Print});

const props = defineProps({
    order: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>