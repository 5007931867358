<template>
    <q-card-section v-if="_.get(field,'show')">
        <q-field :label="_.get(field,'label')"
                 stack-label
                 bottom-slots
                 :error-message="error"
                 :error="!_.isNull(error)">
            <template v-slot:control>
                <div class="full-width">
                    <q-item-label>{{ _.get(object, 'label') }}</q-item-label>
                </div>
            </template>
            <template v-slot:append>
                <q-btn :label="$translate('buttons.select')"
                       icon="sym_o_trackpad_input"
                       dense>
                    <q-menu>
                        <q-list separator dense>
                            <q-item v-if="field.value.includes('user')"
                                    clickable v-close-popup
                                    @click="userDialog = !userDialog">
                                <q-item-section>
                                    <q-item-label>{{ $translate('Пользователи') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="field.value.includes('external')"
                                    clickable v-close-popup
                                    @click="externalDialog = !externalDialog">
                                <q-item-section>
                                    <q-item-label>{{ $translate('Внешние персоны') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="field.value.includes('department')"
                                    clickable v-close-popup
                                    @click="departmentDialog = !departmentDialog">
                                <q-item-section>
                                    <q-item-label>{{ $translate('Подразделение') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="field.value.includes('contragent')"
                                    clickable v-close-popup
                                    @click="contragentDialog = !contragentDialog">
                                <q-item-section>
                                    <q-item-label>{{ $translate('Организация') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="field.value.includes('edition')"
                                    clickable v-close-popup
                                    @click="editionDialog = !editionDialog">
                                <q-item-section>
                                    <q-item-label>{{ $translate('Журналы') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="field.value.includes('publisher')"
                                    clickable v-close-popup
                                    @click="publisherDialog = !publisherDialog">
                                <q-item-section>
                                    <q-item-label>{{ $translate('Издатели') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item v-if="field.value.includes('events')"
                                    clickable v-close-popup
                                    @click="eventsDialog = !eventsDialog">
                                <q-item-section>
                                    <q-item-label>{{ $translate('События') }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-btn>
            </template>
        </q-field>
        <q-dialog v-model="userDialog" no-backdrop-dismiss>
            <component :is="formDialog('Person')" @onSelect="selectUser" with-ldap disable-create/>
        </q-dialog>
        <q-dialog v-model="externalDialog" no-backdrop-dismiss>
            <component :is="formDialog('Person')" @onSelect="selectExternal" hide-user disable-create with-external/>
        </q-dialog>
        <q-dialog v-model="editionDialog" no-backdrop-dismiss>
            <component :is="formDialog('Edition')" @onSelect="selectEdition"/>
        </q-dialog>
        <q-dialog v-model="publisherDialog" no-backdrop-dismiss>
            <component :is="formDialog('Publisher')" @onSelect="selectPublisher"/>
        </q-dialog>
        <q-dialog v-model="eventsDialog" no-backdrop-dismiss>
            <component :is="formDialog('Events')" @onSelect="selectEvents"/>
        </q-dialog>
        <q-dialog v-model="departmentDialog" no-backdrop-dismiss>
            <component :is="formDialog('Department')" @onSelect="selectDepartment"/>
        </q-dialog>
        <q-dialog v-model="contragentDialog" no-backdrop-dismiss>
            <component :is="formDialog('Contragent')" @onSelect="selectContragent"/>
        </q-dialog>
    </q-card-section>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import * as Dialog from "@/Components/ReportMaterialForm/Dialog";

const formDialog = (block) => Dialog[block]

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    field: {type: Object},
    error: {type: String, default: null}
})

const object_type = defineModel('object_type')
const object_id = defineModel('object_id')

const object = ref(null)

const userDialog = ref(false)
const externalDialog = ref(false)
const editionDialog = ref(false)
const publisherDialog = ref(false)
const eventsDialog = ref(false)
const departmentDialog = ref(false)
const contragentDialog = ref(false)

const selectUser = (data) => {
    object_type.value = 'user'
    object.value = data;
    userDialog.value = false
    object_id.value = data.id
}
const selectExternal = (data) => {
    object_type.value = 'external'
    object.value = data;
    externalDialog.value = false
    object_id.value = data.id
}
const selectEdition = (data) => {
    object_type.value = 'edition'
    object.value = data;
    editionDialog.value = false
    object_id.value = data.id
}
const selectPublisher = (data) => {
    object_type.value = 'publisher'
    object.value = data;
    publisherDialog.value = false
    object_id.value = data.id
}
const selectEvents = (data) => {
    object_type.value = 'events'
    object.value = data;
    eventsDialog.value = false
    object_id.value = data.id
}
const selectDepartment = (data) => {
    object_type.value = 'department'
    object.value = data;
    departmentDialog.value = false
    object_id.value = data.id
}
const selectContragent = (data) => {
    object_type.value = 'contragent'
    object.value = data;
    contragentDialog.value = false
    object_id.value = data.id
}
</script>