<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions no-card-section>

        <q-card-section v-if="_.get(fields,'fields.name.show')">
            <q-input v-model="publication.name"
                     :label="_.get(fields,'fields.name.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('name')"
                     :error-message="_.get(errors,'name')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.description.show')">
            <q-input v-model="publication.description"
                     :label="_.get(fields,'fields.description.label')"
                     stack-label
                     type="textarea"
                     rows="3"
                     :error="errors.hasOwnProperty('description')"
                     :error-message="_.get(errors,'description')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.lang.show')">
            <q-select v-model="publication.lang_id"
                      :label="_.get(fields,'fields.lang.label')"
                      :options="optionsLanguages"
                      stack-label
                      options-dense
                      map-options
                      emit-value
                      :error="errors.hasOwnProperty('lang_id')"
                      :error-message="_.get(errors,'lang_id')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.date.show')">
            <q-field :label="_.get(fields,'fields.date.label')"
                     style="width: 100%; max-width: 300px"
                     stack-label
                     :error="errors.hasOwnProperty('date')"
                     :error-message="_.get(errors,'date')">
                <template v-slot:control>
                    <div class="full-width text-center">
                        {{ publication.date }}
                    </div>
                </template>
                <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                            <q-date v-model="publication.date"
                                    today-btn
                                    minimal
                                    first-day-of-week="1">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup
                                           :label="$translate('buttons.select')"
                                           color="primary" flat/>
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-field>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.contragent.show')">
            <q-field :label="_.get(fields,'fields.contragent.label')"
                     stack-label
                     :error="errors.hasOwnProperty('contragent_id')"
                     :error-message="_.get(errors,'contragent_id')">
                <template v-slot:control>
                    <div class="items-center">
                        {{ _.get(publication, 'contragent.name') }}
                    </div>
                </template>
                <template v-slot:append>
                    <q-btn :label="$translate('buttons.select')"
                           icon="sym_o_trackpad_input"
                           dense size="sm"
                           @click="openDialogContragent"/>
                </template>
            </q-field>
            <q-dialog v-model="dialogContragent">
                <ContragentDialog @onSelect="setContragent"/>
            </q-dialog>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.sponsor.show')">
            <q-field :label="_.get(fields,'fields.sponsor.label')"
                     stack-label
                     :error="errors.hasOwnProperty('sponsors')"
                     :error-message="_.get(errors,'sponsors')">
                <template v-slot:control>
                    <div class="items-center">
                        <template v-for="(sponsor,index) in _.get(publication, 'sponsors')">
                            <q-chip>
                                <span class='q-mr-sm'>{{ _.get(sponsor, 'name') }}</span>
                                <q-btn icon='o_delete'
                                       color="negative"
                                       flat dense size="sm"
                                       @click="deleteSponsor(index)"/>
                            </q-chip>
                        </template>
                    </div>
                </template>
                <template v-slot:append>
                    <q-btn :label="$translate('buttons.select')"
                           icon="sym_o_trackpad_input"
                           dense size="sm"
                           @click="openDialogSponsor"/>
                </template>
            </q-field>
            <q-dialog v-model="dialogSponsor">
                <ContragentDialog @onSelect="setSponsor"/>
            </q-dialog>
        </q-card-section>

        <FieldsRendering :fields="_.get(fields,'fields_additional')"
                         v-model="publication.common_fields"
                         :errors="errors"
                         error-prefix="common_fields."/>
    </Card>
</template>

<script setup>
/**************************************************
 *
 *  Образовательная деятельность - основная информация
 *
 ***************************************************/

import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";
import ClassificationDB from "@/plugins/ClassificationDB";
import {Contragent as ContragentDialog} from "@/Components/ReportMaterialForm/Dialog";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object}
})
const publication = defineModel('publication', {type: Object})
const errors = defineModel('errors', {type: Object})

const optionsLanguages = ref([])
const loadLanguages = () => {
    ClassificationDB
        .getSimpleList('languages')
        .then((r) => {
            optionsLanguages.value = r
        })
}

const dialogContragent = ref(false)

const openDialogContragent = () => {
    dialogContragent.value = true
}
const setContragent = (data) => {
    publication.value.contragent = data
    publication.value.contragent_id = data.id
    dialogContragent.value = false
}
const dialogSponsor = ref(false)

const openDialogSponsor = () => {
    dialogSponsor.value = true
}
const setSponsor = (data) => {
    publication.value.sponsors.push(data)
    dialogSponsor.value = false
}
const deleteSponsor = (index) => {
    publication.value.sponsors.splice(index, 1)
}
onMounted(() => {
    loadLanguages()
})
</script>