<template>
    <div :style="wrapperStyle">
        <component :is="selectedChart"
                   :columns-height="columnsHeight"
                   :data="data"
                   :legend="legend"
                   :locale="am5locales_ru_RU"
                   :config="config"
                   :max-date="maxDate"
                   :min-date="minDate"
                   :zoom-step="zoomStep"
                   :with-modal="withModal"
        />
    </div>
</template>

<script setup lang="ts">
import am5locales_ru_RU from "@amcharts/amcharts5/locales/ru_RU";
import type {ChartTypes as TChartTypes} from "@/entity/charts/types";
import * as ChartTypes from "@/Components/Charts";
import {AnyValuesObject} from "@/entity/types";
import {computed, ComputedRef} from "vue";
import _ from "lodash";

interface Props {
    chartType: TChartTypes,
    data?: AnyValuesObject | AnyValuesObject[] | null,
    legend?: object,
    config?: object,
    height?: string,
    width?: string,
    columnsHeight?: number,
    maxDate?: number,
    minDate?: number,
    zoomStep?: number,
    withModal?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    data: null,
    height: "500px",
    width: "100%",
    columnsHeight: 35,
    zoomStep: 0.025,
    withModal: false
});

const selectedChart: ComputedRef<any> = computed<any>(function () {
    if (props.chartType) {
        return _.get(ChartTypes, _.upperFirst(_.camelCase(props.chartType)));
    }
});
const wrapperStyle: ComputedRef<{height: string, width: string}> = computed<{height: string, width: string}>(
    () => ({
        height: props.height,
        width: props.width
    })
);
</script>
