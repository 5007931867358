<template>
    <Card title-bg="indigo-1"
          :title="$translate('consumables.ssc.title.minus')"
          actions-align="center"
          show-close-button
          in-modal
    >
        <template v-slot:default>
            <q-field :prefix="$translate('consumables.ssc.fields.consumable')+': '" bottom-slots stack-label>
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">{{ consumable.name }}</div>
                </template>
            </q-field>
            <AlertInfo v-if="_.get(consumable, 'balance')">
                <div class="text-negative">
                    <div>{{ $translate('consumables.ssc.fields.current_count') }}:
                        {{ _.get(consumable, 'balance.count') }} {{ _.get(consumable, 'unit_text') }}
                    </div>
                    <div>{{ $translate('consumables.ssc.fields.last_date') }}:
                        {{ _.get(consumable, 'balance.date_formatted') }}
                    </div>
                </div>
            </AlertInfo>
            <div class="row q-gutter-md">
                <div class="col">
                    <q-input v-model.number="form.count"
                             :label="$translate('consumables.ssc.fields.count')" stack-label
                             type="number"
                             step="0.01"
                             min="0"
                    />
                </div>
                <div class="col">
                    <q-select v-model="form.transform_id"
                              :label="$translate('consumables.ssc.fields.units')" stack-label
                              :options="consumable.transform.filter((o)=>(!o.archive))"
                              option-label="name"
                              option-value="id"
                              options-dense
                              map-options
                              emit-value
                    />
                </div>
            </div>
            <q-input v-model="form.dogovor_number"
                     :label="$translate('consumables.ssc.fields.act')" stack-label
                     :placeholder="$translate('consumables.ssc.fields.act_number')"
            />
            <q-input v-model="form.dogovor_date"
                     :label="$translate('consumables.ssc.fields.act_date')" stack-label
                     :max="minOfDateAndToday"
                     type="date"
            />
            <q-file v-model="form.dogovor_file"
                    :label="$translate('consumables.ssc.fields.act_file')"
                    accept=".jpg, .png, .gif, .pdf"
                    clearable
            >
                <template v-slot:prepend>
                    <q-icon name="attach_file"/>
                </template>
            </q-file>
            <q-input v-model="form.comment"
                     :label="$translate('consumables.ssc.fields.comment-writeoff')" stack-label
                     type="textarea"
                     rows="3"
            />
        </template>
        <template v-slot:actions>
            <q-btn icon="save" :label="$translate('buttons.add')"
                   @click="submitForm"
                   color="positive"
            />
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {computed, getCurrentInstance, ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment/moment";
import AlertInfo from "@/Components/Block/AlertInfo.vue";

const emit = defineEmits(['formReceived']);
const props = defineProps({
    consumable: {
        type: Object
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const date = ref('');

const form = useForm({
    count: null,
    transform_id: null,
    dogovor_number: null,
    dogovor_date: null,
    dogovor_file: null,
    comment: null
});

const minOfDateAndToday = computed(() => {
    if (date.value === '') {
        return moment().format('YYYY-MM-DD');
    }
    if (moment().isAfter(date.value)) {
        return date.value;
    } else {
        return moment().format('YYYY-MM-DD');
    }
});

const submitForm = () => {
    form.post(route('consumables.ssc.writeoff.post', {consumable: props.consumable.id}), {
        onSuccess: () => {
            emit('formReceived');
        }
    });

};
</script>
