<template>
    <Card :title="$translate('feedback.title.list')"
          title-bg="blue-grey-3"
          header-toolbar
          hide-actions
          no-card-section>

        <q-table ref="tableRef"
                 row-key="id"
                 :rows="items"
                 :columns="columns"
                 :filter="filter"
                 :loading="loading"
                 @request="onRequest"
                 v-model:pagination="pagination"
                 :rows-per-page-options="[10,50,100]"
                 wrap-cells
                 flat
        >
            <template v-slot:body-cell-status="props">
                <q-td :props="props" class="no-wrap">
                    <q-icon v-bind="statusIcon(props.row.status)"/>
                </q-td>
            </template>
            <template v-slot:body-cell-file="props">
                <q-td :props="props" class="no-wrap">
                    <q-btn v-if="props.row.file" v-bind="buttonFile(props.row.file)"/>
                </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @onSuccess="onSuccess">
                        <q-item v-if="props.row.can.approve"
                                clickable v-close-popup @click="approveFeedback(props.row)">
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('buttons.approve') }}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-icon name="o_check" size="xs"/>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="props.row.can.decline"
                                clickable @click.prevent="declineFeedback(props.row)" v-close-popup>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('buttons.decline') }}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-icon name="o_clear" size="xs"/>
                            </q-item-section>
                        </q-item>
                    </DataTableActions>
                </q-td>
            </template>
        </q-table>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import ElementTree from "@/Components/ElementTree.vue";
import {router, usePage} from "@inertiajs/vue3";
import _ from 'lodash'
import {useQuasar} from "quasar";
import axios from "axios";
import DataTableActions from "@/Components/DataTableActions.vue";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const tableRef = ref();
const filter = ref(null);
const loading = ref(false);
const items = ref([]);

const columns = [
    {name: 'actions', style: 'width:40px'},
    {
        name: 'status',
        label: $translate('feedback.fields.status'),
        field: 'status',
        classes: 'text-no-wrap',
        align: 'center',
        style: 'width:40px'
    },
    {
        name: 'response',
        label: $translate('feedback.fields.response'),
        field: 'response',
        align: 'left',
    },
    {
        name: 'project',
        label: $translate('feedback.fields.project'),
        field: 'project',
        align: 'left',
        style: 'width:100px'
    },
    {
        name: 'ssc',
        label: $translate('feedback.fields.ssc'),
        field: 'ssc',
        align: 'left',
    },
    {
        name: 'file',
        label: $translate('feedback.fields.file'),
        field: 'file',
        classes: 'text-no-wrap',
        align: 'center',
        style: 'width:40px'
    },
    {
        name: 'author',
        label: $translate('feedback.fields.author'),
        field: 'user',
        align: 'left',
    },
]

const pagination = ref({
    sortBy: null,
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});


const onRequest = (request) => {
    const {page, rowsNumber, rowsPerPage, sortBy, descending} = request.pagination;
    const filter = request.filter;

    let params = {
        m: 'all',
        page: 1,
        per_page: 10,
        datatable: {
            sort: {},
            search: null
        }
    };

    console.debug(request)
    loading.value = true;

    params.page = page;
    params.per_page = rowsPerPage

    params.datatable['search'] = _.size(filter) > 0 ? filter : null;

    if (sortBy) {
        params.datatable['sort'][sortBy] = {'field': sortBy, 'direction': descending ? 'desc' : 'asc'};
    }

    axios.get(route('ajax.feedback'), {
        params: params,
    })
        .then((response) => {
            items.value = response.data.results.data;
            pagination.value.page = response.data.results.current_page;
            pagination.value.rowsPerPage = response.data.results.per_page;
            pagination.value.sortBy = sortBy;
            pagination.value.descending = descending;
            pagination.value.rowsNumber = response.data.results.total;
        })
        .finally(() => {
            loading.value = false;
        });
};

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};

const statusIcon = (status) => {
    let opt = {
        size: 'sm',
        color: 'primary',
        name: 'o_hourglass_bottom',
        title: $translate('feedback.status.' + status)
    }
    if (status === 10) {
        opt.color = 'positive';
        opt.name = 'o_check';
    }
    if (status === 99) {
        opt.color = 'negative';
        opt.name = 'o_clear';
    }
    return opt;
}

const buttonFile = (file) => {
    return {
        icon: 'o_attach_file',
        flat: true,
        dense: true,
        href: file.url,
        target: '_blank'
    }
}


const approveFeedback = (feedback) => {
    router.patch(route('feedback.approve', [feedback]))
    onSuccess()
}
const declineFeedback = (feedback) => {
    $q.dialog({
        title: 'Подтверждение отклонения',
        message: 'Укажите причину отклонения отзыва',
        prompt: {
            model: '',
            type: 'textarea' // optional
        },
        cancel: true,
        persistent: true
    }).onOk(data => {
        console.debug('>>>> OK, received', data)
        router.post(route('feedback.decline', [feedback]), {comment: data})
        onSuccess()
    }).onCancel(() => {
        // console.log('>>>> Cancel')
    }).onDismiss(() => {
        // console.log('I am triggered on both OK and Cancel')
    })
}

onMounted(() => {
    onSuccess()
});
</script>