<template>
    <q-card>
        <q-card-section class="flex justify-between items-center">
            <AnalyticReportsHeader :report-data="reportData"></AnalyticReportsHeader>
            <AnalyticReportsActions :report-data="reportData"></AnalyticReportsActions>
        </q-card-section>
        <q-card-section>
            <q-table :columns="columns"
                     :rows="data"
                     :pagination="{rowsPerPage: 0}"
                     :rows-per-page-options="[0]"
                     hide-pagination
                     wrap-cells
                     bordered
                     flat
            >
                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th class="text-left" style="vertical-align: middle">
                            <div>{{ $translate('module-analytics.table_columns.' + report + '.project_name') }}</div>
                            <div>{{ $translate('module-analytics.table_columns.' + report + '.project_period') }}</div>
                        </q-th>
                        <q-th colspan="3" class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.for_period') }}<br/>
                            {{ $translate('buttons.from') }} {{ reportData.from }}
                            {{ $translate('buttons.to') }} {{ reportData.to }}
                        </q-th>
                    </q-tr>
                    <q-tr :props="props">
                        <q-th class="text-right">
                            {{ $translate('module-analytics.table_columns.' + report + '.ssc') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.total_cost') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.ssc_fin') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.total_fin') }}
                        </q-th>
                    </q-tr>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td class="text-left" colspan="5">
                            <div>
                                <Link :href="$route('project.show', {project: props.row.project_data[0].project_number})">
                                    {{ props.row.project_data[0].project_number }}
                                </Link>
                                <span class="q-ml-sm ellipsis">
                                    {{ props.row.project_data[0].project_name }}
                                </span>
                            </div>
                            <div>
                                {{ $translate('buttons.from') }}
                                {{ moment(props.row.project_data[0].bdate).format($translate('common.date.js')) }}
                                {{ $translate('buttons.to') }}
                                {{ moment(props.row.project_data[0].edate).format($translate('common.date.js')) }}
                            </div>
                        </q-td>
                    </q-tr>
                    <q-tr :props="props" v-for="(item, i) in props.row.project_data" :key="i">
                        <q-td class="text-right">{{ item.ssc }}</q-td>
                        <q-td class="text-center">{{ item.total_cost }} ₽</q-td>
                        <q-td class="text-center">{{ item.ssc_fin }} ₽</q-td>
                        <q-td class="text-center">{{ item.total_fin ? item.total_fin : 0 }} ₽</q-td>
                    </q-tr>
                </template>
            </q-table>
        </q-card-section>
    </q-card>
</template>

<script setup>
import AnalyticReportsActions from "@/Components/Analytic/Reports/AnalyticReportsActions.vue";
import AnalyticReportsHeader from "@/Components/Analytic/Reports/AnalyticReportsHeader.vue";
import {getCurrentInstance, ref} from "vue";
import {Link} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment";
import helpers from "@/helpers";

const props = defineProps({
    report: {
        type: String,
    },
    reportData: {
        type: Object,
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const data = ref([]);
_.forEach(_.groupBy(props.reportData.data, 'project_number'), (projectData, projectNumber) => {
    data.value.push({
        project_number: projectNumber,
        project_data: _.map(projectData, (item) => ({
            project_number: item.project_number,
            project_name: _.truncate(item.project_name, {length: 130}),
            ssc: item.ssc,
            total_cost: helpers.formatNumber(Number(item.total_cost), 2),
            ssc_fin: helpers.formatNumber(Number(item.ssc_fin), 2),
            total_fin: helpers.formatNumber(Number(item.total_fin), 2),
        }))
    });
});

const columns = [
    {
        name: 'ssc',
        label: $translate('module-analytics.table_columns.' + props.report + '.ssc'),
        align: 'right',
        field: 'ssc'
    },
    {
        name: 'total_cost',
        label: $translate('module-analytics.table_columns.' + props.report + '.total_cost'),
        align: 'center',
        field: 'total_cost'
    },
    {
        name: 'ssc_fin',
        label: $translate('module-analytics.table_columns.' + props.report + '.ssc_fin'),
        align: 'center',
        field: 'ssc_fin'
    },
    {
        name: 'total_fin',
        label: $translate('module-analytics.table_columns.' + props.report + '.total_fin'),
        align: 'center',
        field: 'total_fin'
    },
];
</script>
