<template>
    <Card title-bg="orange-1" :title="$translate('module-syexc.title-committee-members')"
          hide-actions header-toolbar no-card-section>
        <template v-slot:headerActions>
            <q-btn :label="$translate('module-syexc.actions.committee.add-member')"
                   @click="addMemberDialog = !addMemberDialog"
                   color="orange-4"
                   text-color="dark"
                   icon="o_add"
                   dense
                   no-caps
            />
            <q-dialog v-model="addMemberDialog"
                      transition-show="scale"
                      transition-hide="scale"
                      persistent
            >
                <MemberForm :title="$translate('module-syexc.actions.add-committee-member')"
                            :action="$route('services.sys-exp-ctrl.committee.member')"
                            @on-close-modal="addMemberDialog = !addMemberDialog"
                            in-modal
                ></MemberForm>
            </q-dialog>
        </template>

        <div class="row q-gutter-x-md">
            <div class="col">
                <q-list>
                    <q-item>
                        <q-item-section>
                            <q-item-label>{{ $translate('module-syexc.roles.committee-leader') }}:</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>
                                {{ _.get(leader, 'user.fulled_name', ' - ') }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-btn @click="editLeaderDialog = !editLeaderDialog"
                                   class="q-mr-none"
                                   color="secondary"
                                   icon="o_edit"
                                   dense flat
                            >
                                <q-tooltip class="text-body2">
                                    {{ $translate('module-syexc.actions.committee.add-leader') }}
                                </q-tooltip>
                            </q-btn>
                            <q-dialog v-model="editLeaderDialog"
                                      transition-show="scale"
                                      transition-hide="scale"
                                      persistent
                            >
                                <MemberForm :title="$translate('module-syexc.actions.select-committee-leader')"
                                            :action="$route('services.sys-exp-ctrl.committee.leader')"
                                            @on-close-modal="editLeaderDialog = !editLeaderDialog"
                                            in-modal
                                ></MemberForm>
                            </q-dialog>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>{{ $translate('module-syexc.roles.committee-secretar') }}:</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ _.get(secretar, 'user.fulled_name', ' - ') }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-btn @click="editSecretarDialog = !editSecretarDialog"
                                   color="secondary"
                                   icon="o_edit"
                                   dense flat
                            >
                                <q-tooltip class="text-body2">
                                    {{ $translate('module-syexc.actions.committee.add-secretar') }}
                                </q-tooltip>
                            </q-btn>
                            <q-dialog v-model="editSecretarDialog"
                                      transition-show="scale"
                                      transition-hide="scale"
                                      persistent
                            >
                                <MemberForm :title="$translate('module-syexc.actions.select-committee-secretar')"
                                            :action="$route('services.sys-exp-ctrl.committee.secretar')"
                                            @on-close-modal="editSecretarDialog = !editSecretarDialog"
                                            in-modal
                                ></MemberForm>
                            </q-dialog>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>{{ $translate('module-syexc.roles.committee-lawyer') }}:</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ _.get(lawyer, 'user.fulled_name', ' - ') }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-btn @click="editLawyerDialog = !editLawyerDialog"
                                   color="secondary"
                                   icon="o_edit"
                                   dense flat
                            >
                                <q-tooltip class="text-body2">
                                    {{ $translate('module-syexc.actions.committee.add-lawyer') }}
                                </q-tooltip>
                            </q-btn>
                            <q-dialog v-model="editLawyerDialog"
                                      transition-show="scale"
                                      transition-hide="scale"
                                      persistent
                            >
                                <MemberForm :title="$translate('module-syexc.actions.select-committee-lawyer')"
                                            :action="$route('services.sys-exp-ctrl.committee.lawyer')"
                                            @on-close-modal="editLawyerDialog = !editLawyerDialog"
                                            in-modal
                                ></MemberForm>
                            </q-dialog>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>{{ $translate('module-syexc.roles.committee-lawyer-io') }}:</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ _.get(lawyerIO, 'user.fulled_name', ' - ') }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <div class="q-gutter-xs">
                                <q-btn v-if="lawyerIO"
                                       @click="deleteLawyerIO"
                                       color="negative"
                                       icon="o_delete"
                                       dense flat
                                >
                                    <q-tooltip class="text-body2">
                                        {{ $translate('module-syexc.actions.committee.delete-lawyer-io') }}
                                    </q-tooltip>
                                </q-btn>
                                <q-btn @click="editLawyerIODialog = !editLawyerIODialog"
                                       color="secondary"
                                       icon="o_edit"
                                       dense flat
                                >
                                    <q-tooltip class="text-body2">
                                        {{ $translate('module-syexc.actions.committee.add-lawyer-io') }}
                                    </q-tooltip>
                                </q-btn>
                                <q-dialog v-model="editLawyerIODialog"
                                          transition-show="scale"
                                          transition-hide="scale"
                                          persistent
                                >
                                    <MemberForm :title="$translate('module-syexc.actions.select-committee-lawyer-io')"
                                                :action="$route('services.sys-exp-ctrl.committee.lawyer-io')"
                                                @on-close-modal="editLawyerIODialog = !editLawyerIODialog"
                                                in-modal
                                    ></MemberForm>
                                </q-dialog>
                            </div>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>
            <q-separator vertical/>
            <div class="col">
                <q-list>
                    <q-item v-for="(member, i) in members" :key="i">
                        <q-item-section >
                            <q-item-label>
                                {{ $translate('module-syexc.roles.committee-member') }} #{{ i + 1 }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ _.get(member, 'user.fulled_name', ' - ') }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-btn @click="deleteMember(member)"
                                   color="negative"
                                   icon="o_delete"
                                   dense flat
                            >
                                <q-tooltip class="text-body2">{{ $translate('buttons.delete') }}</q-tooltip>
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>
        </div>

    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import MemberForm from "@/Components/Syexc/Committee/Form/MemberForm.vue";
import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import _ from "lodash";

const props = defineProps({
    leader: Object,
    secretar: Object,
    lawyer: Object,
    lawyerIO: Object,
    members: Array
});

const $q = useQuasar();
const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const editLeaderDialog = ref(false);
const editSecretarDialog = ref(false);
const editLawyerDialog = ref(false);
const editLawyerIODialog = ref(false);
const addMemberDialog = ref(false);

const deleteLawyerIO = () => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('module-syexc.actions.delete-committee-lawyer-io-confirm'),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('services.sys-exp-ctrl.committee.lawyer-io-delete'));
    });
};

const deleteMember = (member) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('module-syexc.actions.confirm-delete-member'),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('services.sys-exp-ctrl.committee.member.delete', {
            member: member.id
        }));
    });
};
</script>
