<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions>

        <q-field :loading="loading"
                 :error-message="errors"
                 :error="!_.isNull(errors)">
            <template v-slot:control>
                <q-item-section>
                    <q-item-label>{{ _.get(awardCategory, 'label') }}</q-item-label>
                </q-item-section>

                <q-popup-edit v-slot="scope" :title="_.get(fields,'name')"
                              v-model="awardCategory"
                              anchor="top left"
                              buttons
                              :label-set="$translate('buttons.select')"
                              :label-cancel="$translate('buttons.cancel')">
                    <q-scroll-area style="height: 400px; max-height: 50vh">
                        <ElementTree :items="options"
                                     :active-element="_.get(scope.value,'id')"
                                     :expand-element="awardCategory.hasOwnProperty('id')">
                            <template v-slot:name="node">
                                <q-item-label class="col flex self-center items-start column"
                                              @click="!node.node.hasOwnProperty('children')?(scope.value = node.node):null"
                                              :title="node.node.label">
                                    <span>{{ node.node.label }}</span>
                                    <q-item-label caption v-if="node.node.description!==''">
                                        {{ node.node.description }}
                                    </q-item-label>
                                </q-item-label>
                            </template>
                        </ElementTree>
                    </q-scroll-area>
                </q-popup-edit>
            </template>
        </q-field>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import {ObjectVisibility as SearchObjectVisibility} from "@/Components/Search";
import ElementTree from "@/Components/ElementTree.vue";
import ClassificationDB from "@/plugins/ClassificationDB";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object},
})

const awardCategory = defineModel('category')
const errors = defineModel('errors', {type: String, default: null})

const selectItem = (selected) => {
    awardCategory.value = selected.id
}

const loading = ref(false)
const options = ref([])

onMounted(() => {
    ClassificationDB
            .getMultiList('awards_type')
            .then((r) => {
                options.value = r
            })
})
</script>