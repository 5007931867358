<script setup>
import {getCurrentInstance, onMounted, ref, computed} from 'vue'
import {router, useForm} from "@inertiajs/vue3";
import _ from 'lodash';
import Card from "@/Components/Block/Card.vue";
import {useQuasar} from "quasar";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();

const props = defineProps({
    anketa: {}
})

const documents = computed(() => {
    return _.groupBy(props.anketa.documents, 'type')
})
const uploadDialog = ref(false)
const form = useForm({
    type: null,
    file: null
})
const uploadForm = ref({
    type: null,
    file: null
})
const openUploadDialog = (type) => {
    _.set(uploadForm.value, 'type', type)
    uploadDialog.value = true;
}
const uploadFormSubmit = () => {
    form
            .transform((data) => ({
                ...data,
                ...uploadForm.value
            }))
            .post($route('services.rid.docs.upload', props.anketa), {
                onSuccess: () => {
                    uploadDialog.value = false;
                }
            })
}
</script>

<template>
    <q-list separator>
        <q-item>
            <q-item-section class="col-auto">
                <q-item-label>Приложение №1</q-item-label>
                <q-item-label><a href="/files/patent/2018_uvedomlenie_izobretenie.docx" target="_blank">скачать шаблон</a></q-item-label>
            </q-item-section>
            <q-item-section>
                <template v-for="(document) in documents['prilojenie1']">
                    <q-btn icon-right="mdi-download-outline"
                           size="md"
                           :href="$route('services.rid.docs.download',[props.anketa, document.id])"
                           target="_blank"
                           no-caps dense flat no-wrap>
                        {{ document.file.name }}
                    </q-btn>
                </template>
            </q-item-section>
            <q-item-section side v-if="anketa.can.uploadDocument">
                <q-btn icon="mdi-upload-outline"
                       @click="openUploadDialog('prilojenie1')"
                       flat dense/>
            </q-item-section>
        </q-item>
        <q-item>
            <q-item-section>
                <q-item-label>Приложение №2</q-item-label>
                <q-item-label><a href="/files/patent/2018_objekt_avtorskoe_pravo.doc" target="_blank">скачать шаблон</a></q-item-label>
            </q-item-section>
            <q-item-section>
                <template v-for="(document) in documents['prilojenie2']">
                    <q-btn icon-right="mdi-download-outline"
                           size="md"
                           :href="$route('services.rid.docs.download',[props.anketa, document.id])"
                           target="_blank"
                           no-caps dense flat no-wrap>
                        {{ document.file.name }}
                    </q-btn>
                </template>
            </q-item-section>
            <q-item-section side v-if="anketa.can.uploadDocument">
                <q-btn icon="mdi-upload-outline"
                       @click="openUploadDialog('prilojenie2')"
                       flat dense/>
            </q-item-section>
        </q-item>
        <q-item>
            <q-item-section>
                <q-item-label>Приложение №3</q-item-label>
                <q-item-label><a href="/files/patent/2018_uvedomlenie_programma.docx" target="_blank">скачать шаблон</a></q-item-label>
            </q-item-section>
            <q-item-section>
                <template v-for="(document) in documents['prilojenie3']">
                    <q-btn icon-right="mdi-download-outline"
                           size="md"
                           :href="$route('services.rid.docs.download',[props.anketa, document.id])"
                           target="_blank"
                           no-caps dense flat no-wrap>
                        {{ document.file.name }}
                    </q-btn>
                </template>
            </q-item-section>
            <q-item-section side v-if="anketa.can.uploadDocument">
                <q-btn icon="mdi-upload-outline"
                       @click="openUploadDialog('prilojenie3')"
                       flat dense/>
            </q-item-section>
        </q-item>
        <q-item>
            <q-item-section>
                <q-item-label>Приложение №4</q-item-label>
                <q-item-label><a href="/files/patent/2021_uvedomlenie_pechatnoe_izdanie.doc" target="_blank">скачать шаблон</a></q-item-label>
            </q-item-section>
            <q-item-section>
                <template v-for="(document) in documents['prilojenie4']">
                    <q-btn icon-right="mdi-download-outline"
                           size="md"
                           :href="$route('services.rid.docs.download',[props.anketa, document.id])"
                           target="_blank"
                           no-caps dense flat no-wrap>
                        {{ document.file.name }}
                    </q-btn>
                </template>
            </q-item-section>
            <q-item-section side v-if="anketa.can.uploadDocument">
                <q-btn icon="mdi-upload-outline"
                       @click="openUploadDialog('prilojenie4')"
                       flat dense/>
            </q-item-section>
        </q-item>
    </q-list>
    <q-dialog v-model="uploadDialog" no-esc-dismiss no-backdrop-dismiss>
        <Card in-modal show-close-button title="Загрузка документа по заявке">
            <q-file v-model='uploadForm.file'
                    clearable
                    clear-icon="clear">
                <template v-slot:prepend>
                    <q-icon name="attach_file"/>
                </template>
            </q-file>
            <template v-slot:actions>
                <q-btn icon="mdi-upload"
                       :label="$translate('buttons.upload')"
                       color="positive"
                       @click="uploadFormSubmit"
                />
            </template>
        </Card>
    </q-dialog>
</template>