<template>
    <div v-if="data.module.ethics.enabled">
        <Alert v-if="$translate('module-ethics.description')"
               :message="$translate('module-ethics.description')"
               variant="info"
        />
        <div v-if="_.get(data.module, 'ethics.needInstall')" class="q-mb-sm">
            <q-btn :label="$translate('module-ethics.settings.ETHICS_INSTALL')"
                   @click="router.visit($route('services.ethics.install-module'))"
                   icon="o_terminal"
                   color="negative"
            />
            <span class="q-ml-md">{{ $translate('module-ethics.settings.ETHICS_INSTALL_description') }}</span>
        </div>
        <div v-if="_.get(data.module, 'ethics.needImport')" class="q-mb-sm">
            <q-btn :label="$translate('module-ethics.settings.ETHICS_IMPORT')"
                   @click="importDataDialog = !importDataDialog"
                   icon="o_import_export"
                   color="secondary"
            />
            <q-dialog v-model="importDataDialog">
                <Card :title="$translate('module-ethics.import-data-config.title')"
                      show-close-button
                      header-toolbar
                      in-modal
                >
                    <template v-slot:default>
                        <template v-for="(description, field) in $translate('module-ethics.import-data-config.fields')" :key="field">
                            <q-input v-model="form.config[field]" :label="description" dense/>
                        </template>
                        <Alert :message="$translate('module-ethics.import-data-config.warning')"
                               variant="danger"
                        />
                    </template>
                    <template v-slot:actions>
                        <q-btn :label="$translate('module-ethics.settings.ETHICS_IMPORT')"
                               @click="importData"
                               color="positive"
                        />
                    </template>
                </Card>
            </q-dialog>
            <span class="q-ml-md">
                {{ $translate('module-ethics.settings.ETHICS_IMPORT_description') }}
            </span>
        </div>
        <template v-if="!_.get(data.module, 'ethics.needInstall')">
            <SettingsTable :data="config"/>
        </template>
    </div>
</template>

<script setup>
import {Alert, Card} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    settings: {
        type: Object,
        // Object or array defaults must be returned from a factory function.
        // The function receives the raw props received by the component as the argument.
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const config = [
    {
        name: 'ETHICS_ENABLED',
        label: $translate('module-ethics.settings.ETHICS_ENABLED'),
        value: props.settings.module.ETHICS_ENABLED,
        description: $translate('module-ethics.settings.ETHICS_ENABLED_description'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'ETHICS_NAME',
        label: $translate('module-ethics.settings.ETHICS_NAME'),
        value: props.settings.module.ETHICS_NAME,
        description: $translate('module-ethics.settings.ETHICS_NAME_description'),
        block: 'module',
        type: 'text',
    },
];

const importDataDialog = ref(false);

const form = useForm({
    config: {
        host: '',
        port: '',
        database: '',
        username: '',
        password: ''
    }
});

const importData = () => {
    form.post(route('services.ethics.import-data'), {
        onSuccess: () => {
            importDataDialog.value = false;
        }
    });
};
</script>
