<template>
    <q-select v-model="selected"
              option-value="id"
              :loading="loading"
              input-debounce="1000"
              :multiple="multiple"
              :use-chips="multiple"
              :options="fetchedData"
              @filter="searchElement"
              :label="$translate('search.consumable.label')"
              :hint="$translate('search.consumable.hint')"
              @update:model-value="(value) => emit('onSelect', value)"
              use-input
              options-dense
              :error-message="error"
              :error="_.size(error)>0"
    />
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import axios from "axios";
import _ from "lodash";

const props = defineProps({
    label: {
        type: String,
        default: null
    },
    multiple: {
        type: Boolean,
        required: false,
        default: false
    },
    filters: {
        type: Array,
        required: false,
        default: []
    },
    error: {
        type: String,
        default: null
    }
});

const emit = defineEmits(['onSelect'])

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const selected = ref(null);
const fetchedData = ref([]);
const loading = ref(false);

const searchElement = (search, update) => {
    if (search === '') {
        update(() => {
            fetchedData.value = [];
        });
        return;
    }
    update(() => {
        let params = {m: 'search', q: search};
        if (!_.isEmpty(props.filters)) {
            params['filters'] = {};
        }
        _.forEach(props.filters, (v, k) => {
            params['filters'][k] = v;
        });
        loading.value = true;
        axios.get(route('ajax.consumables.ssc',params))
            .then((response) => {
                fetchedData.value = response.data.results;
            })
            .finally(() => {
                loading.value = false;
            });
    });
};
</script>
