<template>
    <q-input v-model="newElement.name"
             :label="$translate('contragents.fields.name')"
             :error="newElement.errors.hasOwnProperty('name')"
             :error-message="_.get(newElement.errors,'name')"
             dense/>

    <q-input v-model="newElement.full_name"
             :label="$translate('contragents.fields.full_name-jur')"
             :error="newElement.errors.hasOwnProperty('full_name')"
             :error-message="_.get(newElement.errors,'full_name')"/>

    <Alert variant="info" hide-icon>
        Для поиска организации введите ИНН/ОГРН(ИП) и нажмите кнопку поиска
    </Alert>

    <div class="row q-gutter-x-md">
        <q-input v-model="newElement.inn"
                 :label="$translate('contragents.fields.inn')"
                 class="col"
                 :error="newElement.errors.hasOwnProperty('inn')"
                 :error-message="_.get(newElement.errors,'inn')"
                 :loading="findingCompany">
            <template v-slot:append>
                <q-btn icon="search"
                       flat dense
                       @click="findCompany(newElement.inn)"/>
            </template>
        </q-input>

        <q-input v-model="newElement.ogrn"
                 :label="$translate('contragents.fields.ogrn')"
                 class="col"
                 :error="newElement.errors.hasOwnProperty('ogrn')"
                 :error-message="_.get(newElement.errors,'ogrn')"
                 :loading="findingCompany">
            <template v-slot:append>
                <q-btn icon="search"
                       flat dense
                       @click="findCompany(newElement.ogrn)"/>
            </template>
        </q-input>
    </div>
    <div class="row q-gutter-x-md">
        <q-input v-model="newElement.kpp"
                 :label="$translate('contragents.fields.kpp')"
                 class="col"
                 :error="newElement.errors.hasOwnProperty('kpp')"
                 :error-message="_.get(newElement.errors,'kpp')"/>

        <q-input v-model="newElement.okpo"
                 :label="$translate('contragents.fields.okpo')"
                 class="col"
                 :error="newElement.errors.hasOwnProperty('okpo')"
                 :error-message="_.get(newElement.errors,'okpo')"/>
    </div>
    <q-select v-model="newElement.tags"
              :label="$translate('contragents.fields.tags-placeholder')"
              options-dense
              emit-value
              map-options
              use-input
              use-chips
              multiple
              clearable
              new-value-mode="add"
              clear-icon="clear"
              :error="newElement.errors.hasOwnProperty('tags')"
              :error-message="_.get(newElement.errors,'tags')"
    />
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import axios from "axios";
import {Alert} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const newElement = defineModel({type:Object})

const findingCompany = ref(false)

const findCompany = (search) => {
    findingCompany.value = true
    axios.get(route("ajax.contragent", {
            m: 'find',
            q: search,
            kpp: newElement.kpp
        })
    )
        .then((response) => {
            newElement.reset()
            newElement.name = _.get(response.data.results, 'name', null)
            newElement.full_name = _.get(response.data.results, 'full_name', null)
            newElement.inn = _.get(response.data.results, 'inn', null)
            newElement.ogrn = _.get(response.data.results, 'ogrn', null)
            newElement.okpo = _.get(response.data.results, 'okpo', null)
            newElement.kpp = _.get(response.data.results, 'kpp', null)
            newElement.errors = _.get(response.data.results, 'errors', {})
        })
        .finally(() => {
            findingCompany.value = false;
        });
}
</script>