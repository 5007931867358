<template>
    <Card :title="$translate('publication.title.list-validating')"
          title-bg="purple-1"
          header-toolbar no-card-section hide-actions>

        <q-tabs align="left" v-model="tab">
            <template v-for="(category) in categorys">
                <q-tab :name="category"
                       :label="$translate('report-type.type.'+category)"/>
            </template>
        </q-tabs>

        <q-tab-panels v-model="tab">
            <template v-for="(category) in categorys">
                <q-tab-panel :name="category" class="q-pa-none">
                    <ReportMaterialList :category="category" show-validating/>
                </q-tab-panel>
            </template>
        </q-tab-panels>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import ReportMaterialList from "@/Components/ReportMaterial/ReportMaterialList.vue";

const props = defineProps({
    categorys: {
        type: Array,
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const tab = ref(_.first(props.categorys))
</script>
