<template>
    <q-btn icon="o_settings_suggest"
           :title="$translate('datatable.menu.title')"
           dense
           flat>
        <q-menu>
            <q-list dense separator>
                <q-item v-if="currentView">
                    <q-item-section side>
                        <q-icon name="wb_sunny"/>
                    </q-item-section>
                    <q-item-section :title="$translate('datatable.menu.current')">
                        <q-item-label class="text-bold">{{ _.get(currentView, 'name') }}</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn icon="close"
                               :title="$translate('datatable.menu.reset')"
                               dense flat v-close-popup
                               @click="resetView"/>
                    </q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="openCustomSettingsSelectorDialog">
                    <q-item-section side>
                        <q-icon name="playlist_add_check"/>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ $translate('datatable.menu.select') }}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="openCustomSettingsEditorDialog">
                    <q-item-section side>
                        <q-icon name="o_add"/>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ $translate('datatable.menu.create') }}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>

    <q-dialog v-model="customSettingsEditorDialog"
              no-backdrop-dismiss full-width full-height>
        <ResearchCustomSettingsEditor :template="customView"
                                      @onSuccess="onSuccess"/>
    </q-dialog>

    <q-dialog v-model="customSettingsSelectorDialog" no-backdrop-dismiss>
        <Card :title="$translate('datatable.selector.title')"
              in-modal show-close-button hide-actions no-card-section header-toolbar>
            <q-list separator>
                <template v-for="(item) in customViewsList">
                    <q-item v-if="_.isArray(item.fields)">
                        <q-item-section>
                            <q-item-label>{{ item.name }}</q-item-label>
                        </q-item-section>

                        <q-item-section side>
                            <q-btn :label="$translate('buttons.apply')"
                                   color="positive"
                                   v-close-popup
                                   flat
                                   @click="applyView(item)"></q-btn>
                        </q-item-section>
                        <q-item-section side>
                            <q-btn icon="o_edit"
                                   :title="$translate('buttons.edit')"
                                   color="primary"
                                   dense flat
                                   @click="editView(item)"></q-btn>
                        </q-item-section>
                        <q-item-section side>
                            <q-btn icon="o_delete"
                                   :title="$translate('buttons.delete')"
                                   color="negative"
                                   dense flat
                                   @click="deleteView(item)"></q-btn>
                        </q-item-section>
                    </q-item>
                    <q-item v-else>
                        <q-item-section>
                            <q-item-label>{{ item.name }}</q-item-label>
                            <q-item-label overline>{{ $translate('datatable.info-deprecated') }}</q-item-label>
                        </q-item-section>

                        <q-item-section side>
                            <q-btn icon="o_delete"
                                   :title="$translate('buttons.delete')"
                                   color="negative"
                                   dense flat
                                   @click="deleteView(item)"></q-btn>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
            <Alert v-if='!loadingCustomViewsList && _.size(customViewsList) === 0'
                   variant="danger"
                   :message="$translate('datatable.no-items')"/>
            <div v-if='!loadingCustomViewsList && _.size(customViewsList) === 0' class="text-center q-ma-md">
                <q-btn icon="o_add"
                       :label="$translate('datatable.menu.create')"
                       color="positive"
                       @click="openCustomSettingsEditorDialog"/>
            </div>
            <Alert v-if='loadingCustomViewsList'
                   variant="info"
                   :message="$translate('datatable.loading-items')"/>
            <q-inner-loading :showing="loadingCustomViewsList">
                <q-spinner-gears size="50px" color="primary"/>
            </q-inner-loading>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {useQuasar} from "quasar";
import axios from "axios";
import _ from "lodash";
import {router} from "@inertiajs/vue3";
import ResearchCustomSettingsEditor from "@/Components/CustomSettings/ResearchCustomSettingsEditor.vue";
import {Alert, Card} from "@/Components/Block";


const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const $q = useQuasar()

const props = defineProps({
    currentView: {type: Object}
})

const emit = defineEmits(['onViewSelect'])

const customViewsList = ref([])

const customView = ref(null)
const loadingCustomViewsList = ref(false)
const loadItems = () => {
    loadingCustomViewsList.value = true
    axios.get(route('ajax.datatable.custom', {m: 'research'}))
        .then((response) => {
            customViewsList.value = response.data.results;
        })
        .finally(() => {
            loadingCustomViewsList.value = false
        })
}

const customSettingsEditorDialog = ref(false)
const openCustomSettingsEditorDialog = () => {
    customView.value = null;
    customSettingsEditorDialog.value = true;
}

const customSettingsSelectorDialog = ref(false)
const openCustomSettingsSelectorDialog = () => {
    customSettingsSelectorDialog.value = true;
    loadItems()
}

const onSuccess = () => {
    customView.value = null;
    customSettingsEditorDialog.value = false;
    loadItems()
}
const resetView = () => {
    $q.localStorage.removeItem('ResearchCustomView')
    emit('onViewSelect')
}
const applyView = (view) => {
    $q.localStorage.set('ResearchCustomView', JSON.stringify(view))
    emit('onViewSelect')
}
const editView = (view) => {
    customView.value = view;
    customSettingsEditorDialog.value = true;
}
const deleteView = (view) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: view.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('datatable.settings.research.delete', view), {
            onSuccess: () => {
                loadItems()
            }
        })
    })
}
</script>