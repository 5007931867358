<template>
    <Card title-bg="deep-purple-1"
          header-toolbar hide-actions no-card-section>
        <template v-slot:title>
            <div v-html="pageTitle"></div>
        </template>
        <template v-slot:headerActions>
            <q-btn v-if="can.add"
                   color="deep-purple-3"
                   icon="add"
                   :label="$translate('buttons.add')"
                   dense
                   @click="router.get($route('equipment.create'))"
            />
            <q-btn v-if="can.export"
                   color="deep-purple-3"
                   icon="import_export"
                   :label="$translate('buttons.export')"
                   dense
                   @click="router.get($route('equipment.export'))"
            />
        </template>
        <DepartmentSelector/>
        <q-table ref="tableRef" wrap-cells
                 :rows="items"
                 :columns="columns"
                 :visible-columns="visibleColumns"
                 row-key="id"
                 v-model:pagination="pagination"
                 :filter="filter"
                 :loading="loading"
                 @request="onRequest"
                 :rows-per-page-options="[10,50,100]"
                 flat
        >
            <template v-slot:top-row>
                <q-tr>
                    <q-td></q-td>
                    <q-td></q-td>
                    <q-td colspan="100%">
                        <q-input borderless dense debounce="1000" v-model="filter"
                                 :placeholder="$translate('search.element')"
                                 clear-icon="clear" clearable>
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:header-cell-state="props">
            <q-th :props="props">
                <q-icon name="power_settings_new" :title="props.col.label" size="sm" class="cursor-pointer">
                    <q-menu cover anchor="top middle">
                            <q-btn-group spread >
                                <q-btn icon="power_settings_new" :label="$translate('buttons.off')"
                                       color="negative"
                                       @click="filterBy('state',false)"
                                       v-close-popup flat no-wrap/>
                                <q-btn icon="power_settings_new" :label="$translate('buttons.on')"
                                       color="positive"
                                       @click="filterBy('state',true)"
                                       v-close-popup flat no-wrap/>
                            </q-btn-group>
                    </q-menu>
                </q-icon>
            </q-th>
        </template>

            <template v-slot:body-cell-state="props">
                <q-td :props="props">
                    <q-icon name="power_settings_new" :title="props.row.state_text"
                            :color="props.row.state?'positive':'negative'" size="xs"/>
                </q-td>
            </template>

            <template v-slot:body-cell-display_name="props">
                <q-td :props="props">
                    <div class="row">
                        <div class="col">
                            <div>
                                <a v-if="_.has(props.row.actions,'detail')" class="cursor-pointer"
                                   @click="router.visit(props.row.actions.detail.action)"
                                   v-html="props.row.name"></a>
                                <span v-else v-html="props.row.name"></span>
                            </div>
                            <div class="text-grey-6">
                                <span>ID: {{ props.row.id }}</span>
                                <span :title="$translate('equipment.item.fields.inventory_number_placeholder')"
                                      v-if="props.row.inventory_number"> | Инв.№: {{
                                        props.row.inventory_number
                                    }}</span>
                                <span :title="$translate('equipment.item.fields.serial_number_placeholder')"
                                      v-if="props.row.serial_number"> | Ser.№: {{ props.row.serial_number }}</span>
                            </div>
                            <div class="text-grey-6 row items-center" v-if="props.row.ssc"
                                 :title="$translate('equipment.item.fields.ssc')">
                                <q-icon name="business" class="q-mr-xs"/>
                                <span>{{ props.row.ssc }}</span>
                            </div>
                        </div>
                        <q-img v-if="props.row.photo" :src="props.row.photo" height="3rem" width="3rem"
                               fit="scale-down"
                               fetchpriority="low"/>
                    </div>
                </q-td>
            </template>

            <template v-slot:header-cell-multi_assay="props">
                <q-th :props="props">
                    <q-icon name="sym_o_lab_panel" :title="props.col.label" size="sm" class="cursor-pointer">
                        <q-menu cover anchor="top middle">
                            <q-list>
                                <q-item dense>
                                    <q-item-section>
                                        <q-item-label>
                                            {{ props.col.label }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item dense clickable @click="filterBy('assay',false)" v-close-popup>
                                    <q-item-section side>
                                        <q-icon name="highlight_off"/>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label>{{ $translate('без использования образцов') }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item dense clickable @click="filterBy('assay',true)" v-close-popup>
                                    <q-item-section side>
                                        <q-icon name="sym_o_lab_panel"/>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label>{{ $translate('в работе используются образцы') }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </q-menu>
                    </q-icon>
                </q-th>
            </template>
            <template v-slot:body-cell-multi_assay="props">
                <q-td :props="props">
                    <q-chip :title="$translate('equipment.item.fields.multi-assay-count')" size="sm"
                            v-if="props.row.multi_assay>0" outline>
                        {{ props.row.multi_assay }}
                    </q-chip>
                </q-td>
            </template>

            <template v-slot:header-cell-worktime_self="props">
                <q-th :props="props">
                    <q-icon name="o_person" :title="props.col.label" size="sm" class="cursor-pointer">
                        <q-menu cover anchor="top middle">
                            <q-list>
                                <q-item dense>
                                    <q-item-section>
                                        <q-item-label>
                                            {{ props.col.label }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-btn-group spread>
                                    <q-btn icon="highlight_off" :label="$translate('buttons.no')"
                                           @click="filterBy('worktime_self',false)"
                                           v-close-popup flat/>
                                    <q-btn icon="o_check_circle" :label="$translate('buttons.yes')"
                                           @click="filterBy('worktime_self',true)"
                                           v-close-popup flat/>
                                </q-btn-group>
                            </q-list>
                        </q-menu>
                    </q-icon>
                </q-th>
            </template>
            <template v-slot:body-cell-worktime_self="props">
                <q-td :props="props">
                    <q-icon name="o_check_circle" size="xs" v-if="props.row.worktime_self"
                            :title="$translate('equipment.item.fields.week-work-without-operator-1')"/>
                </q-td>
            </template>

            <template v-slot:header-cell-worktime_self_week="props">
                <q-th :props="props">
                    <q-icon name="o_person_off" :title="props.col.label" size="sm" class="cursor-pointer">
                        <q-menu cover anchor="top middle">
                            <q-list>
                                <q-item dense>
                                    <q-item-section>
                                        <q-item-label>
                                            {{ props.col.label }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-btn-group spread>
                                    <q-btn icon="highlight_off" :label="$translate('buttons.no')"
                                           @click="filterBy('worktime_self_week',false)"
                                           v-close-popup flat/>
                                    <q-btn icon="o_check_circle" :label="$translate('buttons.yes')"
                                           @click="filterBy('worktime_self_week',true)"
                                           v-close-popup flat/>
                                </q-btn-group>
                            </q-list>
                        </q-menu>
                    </q-icon>
                </q-th>
            </template>
            <template v-slot:body-cell-worktime_self_week="props">
                <q-td :props="props">
                    <q-icon name="o_check_circle" size="xs" v-if="props.row.worktime_self_week"
                            :title="$translate('equipment.item.fields.week-work-without-operator-1')"/>
                </q-td>
            </template>

            <template v-slot:body-cell-schedule_loading="props">
                <q-td :props="props">
                    <div v-html="props.row.schedule_loading"></div>
                </q-td>
            </template>
            <template v-slot:header-cell-equipment_type="props">
                <q-th :props="props">
                    <div class="flex items-center no-wrap">
                        <span>{{ props.col.label }}</span>
                        <q-icon name="o_filter_alt"
                                size="xs"
                                color="grey-7"
                                :title="$translate('фильтр по полю')"
                                class="q-ml-sm cursor-pointer">
                            <q-menu anchor="bottom middle" self="top middle">
                                <q-list dense>
                                    <template v-for="(name,state) in $translate('equipment.item.types')">
                                        <q-item dense clickable v-close-popup @click="filterBy('equipment_type',state)">
                                            <q-item-section>
                                                <q-item-label>{{ name }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-list>
                            </q-menu>
                        </q-icon>
                    </div>
                </q-th>
            </template>
            <template v-slot:header-cell-purpose="props">
                <q-th :props="props">
                    <div class="flex items-center no-wrap">
                        <span>{{ props.col.label }}</span>
                        <q-icon name="o_filter_alt"
                                size="xs"
                                color="grey-7"
                                :title="$translate('фильтр по полю')"
                                class="q-ml-sm cursor-pointer">
                            <q-menu anchor="bottom middle" self="top middle">
                                <q-list dense>
                                    <template v-for="(name,state) in $translate('equipment.item.purpose')">
                                        <q-item dense clickable v-close-popup @click="filterBy('purpose',state)">
                                            <q-item-section>
                                                <q-item-label>{{ name }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-list>
                            </q-menu>
                        </q-icon>
                    </div>
                </q-th>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions" :name="props.row.name"
                                      @on-success="onSuccess">
                    </DataTableActions>
                </q-td>
            </template>
        </q-table>
    </Card>
</template>

<script setup>
import {router, useForm, usePage} from "@inertiajs/vue3";
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import axios from "axios";
import DataTableActions from "@/Components/DataTableActions.vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import {useQuasar} from 'quasar'
import DepartmentSelector from "@/Components/Navigation/DepartmentSelector.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar()

const currentSSC = _.find(usePage().props.auth.ssc, {id: usePage().props.auth.current_ssc})

defineProps({
    can: {
        type: Object
    }
})

const pageTitle = computed(() => {
    return (currentSSC.id > 0) ? $translate('equipment.item.title.list-ssc') + ' &laquo;' + currentSSC.name + '&raquo;' : $translate('equipment.item.title.list-all');
})

const tableRef = ref()
const filter = ref('')
const loading = ref(false)
const items = ref([])
const columns = [
    {
        name: 'actions', align: 'center', label: '',
        style: "width: 2rem",
    },
    {
        name: 'state', align: 'center', label: '',
        style: "width: 1rem",
        field: 'state',
    },
    {
        name: 'display_name', align: 'left', label: $translate('equipment.item.fields.name'),
        field: 'name',
        sortable: true
    },
    {
        name: 'multi_assay', align: 'center', label: $translate('equipment.item.fields.multi-assay-title'),
        style: "width: 2rem",
        field: 'multi_assay',
    },
    {
        name: 'worktime_self', align: 'center', label: $translate('equipment.item.fields.self_work'),
        style: "width: 2rem",
        field: 'worktime_self',
    },
    {
        name: 'worktime_self_week',
        align: 'center',
        label: $translate('equipment.item.fields.week-work-without-operator'),
        style: "width: 2rem",
        field: 'worktime_self_week',
    },
    {
        name: 'schedule_loading', align: 'left', label: $translate('equipment.item.fields.schedule-loading'),
        field: 'schedule_loading',
    },
    {
        name: 'equipment_type', align: 'left', label: $translate('equipment.item.fields.equipment_type'),
        field: 'equipment_type_text',
    },
    {
        name: 'purpose', align: 'left', label: $translate('equipment.item.fields.purpose'),
        field: 'purpose_text',
    },
]
const visibleColumns = computed(() => [
    'actions',
    'state',
    'display_name',
    !$q.screen.lt.md ? 'schedule_loading' : null,
    !$q.screen.lt.md ? 'equipment_type' : null,
    !$q.screen.lt.md ? 'purpose' : null,
    !$q.screen.lt.md ? 'worktime_self_week' : null,
    !$q.screen.lt.md ? 'worktime_self' : null,
    !$q.screen.lt.md ? 'multi_assay' : null,
]);

const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
})

function onRequest(request) {
    const {page, rowsPerPage, sortBy, descending} = request.pagination
    const filter = request.filter

    loading.value = true

    let params = {
        m: 'list',
        datatable: {
            sort: {},
            search: filter
        },
        page: page,
        per_page: rowsPerPage,
    }
    if (sortBy) {
        _.set(params.datatable.sort, sortBy, {'field': sortBy, 'direction': descending ? 'asc' : 'desc'});
    }
    axios.get(route('ajax.equipment', params))
            .then((response) => {
                items.value = response.data.results.data;
                pagination.value.page = response.data.results.current_page
                pagination.value.rowsPerPage = response.data.results.per_page
                pagination.value.sortBy = sortBy
                pagination.value.descending = descending
                pagination.value.rowsNumber = response.data.results.total
            })
            .finally(() => {
                loading.value = false
            })
}

const onSuccess = () => {
    tableRef.value.requestServerInteraction()
}

const filterBy = (field, value) => {
    filter.value = '' + field + ':"' + value + '"';
    onSuccess()
}
onMounted(() => {
    onSuccess()
})
</script>