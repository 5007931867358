<template>
    <q-card>
        <q-card-section class="flex justify-between items-center">
            <div>
                <small>{{ $translate('module-analytics.fields.created') }} {{ reportData.created }}</small>
            </div>
            <AnalyticReportsActions :report-data="reportData"></AnalyticReportsActions>
        </q-card-section>
        <q-card-section>
            <q-table :columns="columns"
                     :rows="data"
                     :pagination="{rowsPerPage: 0}"
                     :rows-per-page-options="[0]"
                     hide-pagination
                     bordered
                     flat
            >
                <template v-slot:body-cell-count="props">
                    <q-td :props="props" :class="getCountClass(props.value)">
                        {{ props.value }}%
                    </q-td>
                </template>
                <template v-slot:body-cell-target="props">
                    <q-td :props="props">{{ props.value }}%</q-td>
                </template>
            </q-table>
        </q-card-section>
    </q-card>
</template>

<script setup>
import AnalyticReportsActions from "@/Components/Analytic/Reports/AnalyticReportsActions.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import helpers from "@/helpers";

const props = defineProps({
    report: {
        type: String,
    },
    reportData: {
        type: Object,
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const data = ref([]);
_.forEach(props.reportData.data, (count, ssc) => {
    data.value.push({
        ssc: ssc,
        count: helpers.formatNumber(Number(count), 2),
        target: helpers.formatNumber(5, 2)
    });
});

const getCountClass = (count) => count >= 5 ? 'bg-positive text-white' : '';

const columns = [
    {name: 'ssc', label: '', align: 'left', field: 'ssc'},
    {
        name: 'count',
        label: $translate('module-analytics.table_columns.' + props.report + '.count'),
        align: 'center',
        field: 'count'
    },
    {
        name: 'target',
        label: $translate('module-analytics.table_columns.' + props.report + '.target'),
        align: 'center',
        field: 'target'
    },
];
</script>
