<template>
    <Card :title="title" hide-actions no-card-section>
        <template v-slot:headerActions>
            <q-btn icon="add" :label="$translate('buttons.add')"
                   @click="openFieldSettingsAddModal"
                   v-if="!readonly"
                   dense/>
        </template>
        <q-list separator dense>
            <template v-for="(field,index) in fields">
                <q-item class="self-center full-width">
                    <!-- MOVER -->
                    <q-item-section side>
                        <q-btn dense flat size="sm" icon="arrow_drop_up"
                               :title="$translate('fields.actions.move-up')"
                               @click="changeFieldPosition(index, index-1)"
                               :disable="!(index>0) && !readonly"/>
                        <q-btn dense flat size="sm" icon="arrow_drop_down"
                               :title="$translate('fields.actions.move-down')"
                               @click="changeFieldPosition(index, index+1)"
                               :disable="!(index<(fields.length-1)) && !readonly"/>
                    </q-item-section>
                    <!-- Label + field name -->
                    <q-item-section>
                        <q-item-label>
                            <span :title="$translate('fields.label.label')">{{ getFieldLabel(field) }}</span>
                            <span v-if="field.required" class="text-negative"
                                  :title="$translate('fields.label.required')"> * </span>
                        </q-item-label>
                        <q-item-label caption :title="$translate('fields.label.name')">
                            {{ getFieldName(field) }}
                        </q-item-label>
                    </q-item-section>
                    <!-- field type  -->
                    <q-item-section>
                        <q-item-label :title="$translate('fields.label.type')">
                            {{ getFieldType(field) }}
                        </q-item-label>
                        <q-item-label caption>
                            {{ getFieldTypeVariant(field) }}
                        </q-item-label>
                    </q-item-section>
                    <!-- field type  -->
                    <q-item-section class="col-2">
                        <q-item-label :title="$translate('fields.label.rules-count')">
                            {{ _.size(_.get(field,'rules')) }}
                        </q-item-label>
                    </q-item-section>
                    <!-- actions  -->
                    <q-item-section side>
                        <q-btn-group>
                            <q-btn icon="o_edit" :title="$translate('fields.label.settings')"
                                   @click="openFieldSettingsEditModal(field,index)"
                                   :disable="readonly"
                                   dense/>
                            <q-separator vertical/>
                            <q-btn icon="tune" :title="$translate('fields.label.rules')"
                                   @click="openFieldRulesModal(field)"
                                   :disable="readonly"
                                   dense/>
                            <q-separator vertical/>
                            <q-btn icon="o_delete" :title="$translate('buttons.delete')"
                                   @click="deleteField(index)"
                                   :disable="readonly"
                                   dense/>
                        </q-btn-group>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>

    <q-dialog v-model="fieldSettingsAddModal" no-backdrop-dismiss full-width>
        <Card :title="$translate('fields.label.settings')"
              in-modal no-card-section header-toolbar show-close-button>
            <FieldConstructorSettings v-model="fieldSettingData"
                                      :unique-source="uniqueSource"/>
            <template v-slot:actions>
                <q-btn icon="save"
                       :label="$translate('buttons.add')"
                       color="positive"
                       @click="addField"/>
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="fieldSettingsEditModal" no-backdrop-dismiss full-width>
        <Card :title="$translate('fields.label.settings')"
              in-modal no-card-section header-toolbar show-close-button>
            <FieldConstructorSettings v-model="fieldSettingData"
                                      :unique-source="uniqueSource"/>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="saveSettingsField"/>
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="fieldRulesModal" no-backdrop-dismiss full-width full-height>
        <Card :title="$translate('advanced-fields.title.rules') + ' <' +getFieldLabel(fieldRulesData) +'>' "
              no-card-section in-modal header-toolbar>
            <q-scroll-area :style="fieldRulesAreaHeight">
                <FieldConstructorRule :field="fieldRulesData"
                                      :fields="fields"/>
            </q-scroll-area>
            <template v-slot:actions>
                <q-btn icon="add"
                       :label="$translate('fields.actions.add-rule')"
                       color="primary"
                       class="q-ml-none q-mr-auto"
                       @click="addRule(fieldRulesData)"/>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       class="q-ml-auto"
                       @click="closeFieldRulesModal"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
/********************************************************************************************************************/
/*
/* Конструктор полей для различных форм
/*
/********************************************************************************************************************/

import {usePage} from "@inertiajs/vue3";
import {Notify, useQuasar} from "quasar";
import {computed, getCurrentInstance, onBeforeMount, ref, toRefs, watch} from "vue";
import _ from "lodash";
import axios from "axios";
import ClassificationDB from "@/plugins/ClassificationDB";
import Card from "@/Components/Block/Card.vue";
import FieldConstructorRule from "@/Components/Fields/FieldConstructorRule.vue";
import FieldConstructorSettings from "@/Components/Fields/FieldConstructorSettings.vue";

const $q = useQuasar();
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    /** Заголовок блока */
    title: String,
    /** вывод поля "включено в группу уникальных полей при одинаковом основании" **/
    uniqueSource: {
        type: Boolean,
        default: false
    },
    /** Только для просмотра */
    readonly: {
        type: Boolean,
        default: false
    },
    ssc: {
        type: Number,
        default: null
    }
})

const emit = defineEmits(['update:items'])

const fields = defineModel({
    type: Array,
    default: []
})

const options = ref([]);

/**
 * Отображаемое имя поля в списке
 * @param field
 * @returns String
 */
const getFieldName = (field) => {
    return _.get(field, 'name')
}
/**
 * Отображаемое наименование поля в списке
 * @param field
 * @returns String
 */
const getFieldLabel = (field) => {
    if (field.hasOwnProperty('label')) {
        return _.get(field.label, usePage().props.locale)
    }
    return '!! Отображаемое наименование поля не указано !!'
}
/**
 * Тип поля в списке
 * @param field
 * @returns String
 */
const getFieldType = (field) => {
    return $translate('fields.types.' + field.type + '.label')
}

/**
 * Тип списка и его название
 *
 * @param field
 * @returns {string}
 */
const getFieldTypeVariant = (field) => {
    let type = _.get($translate('fields.variants'), _.get(field, 'variants.type')) || '';
    let listName = null;
    if (_.get(field, 'variants.type') !== 'self') {
        let list = _.get(options.value, _.get(field, 'variants.type'));
        listName = _.find(list, (item) => item.id === _.get(field.variants, _.get(field, 'variants.type')));
    }
    return type + (listName ? ' "' + listName.text + '"' : '');
}

/**
 * Изменение позиции поля
 *
 * @param index
 * @param newIndex
 */
const changeFieldPosition = (index, newIndex) => {
    let tempArray = [];

    for (let i = 0; i < _.size(fields.value); i++) {
        if (i === index) { // если элемент = текущему
            tempArray[newIndex] = _.cloneDeep(fields.value[i]);
        } else if (i === newIndex) {
            tempArray[index] = _.cloneDeep(fields.value[i]);
        } else {
            tempArray[i] = _.cloneDeep(fields.value[i]);
        }
    }
    _.set(fields, 'value', tempArray)
}
/**
 * Уведомление
 * @param message
 */
const notifyError = (message) => {
    Notify.create({
        color: 'negative',
        message: message,
        position: "top",
        progress: false,
        icon: "error_outline"
    })
}
/***************************
 *  Базовые настройки поля
 ***************************/
const fieldSettingIndex = ref(null)
const fieldSettingData = ref({})
const fieldSettingsAddModal = ref(false)
const fieldSettingsEditModal = ref(false)
const openFieldSettingsAddModal = () => {
    fieldSettingData.value = {
        type: null,
        name: null,
        label: {},
        hideLabel: false,
        description: {},
        required: false,
        unique: false,
        rules: []
    }
    fieldSettingsAddModal.value = true
}
const openFieldSettingsEditModal = (field, index) => {
    fieldSettingIndex.value = index
    fieldSettingData.value = Object.assign({},
        {
            type: null,
            name: null,
            label: {},
            hideLabel: false,
            description: {},
            required: false,
            unique: false,
            rules: []
        },
        _.cloneDeep(field)
    );
    fieldSettingsEditModal.value = true
}
const validateSettings = () => {
    if (_.isEmpty(fieldSettingData.value.name)) {
        notifyError('Укажите имя поля')
        return false;
    }
    if (_.isEmpty(fieldSettingData.value.type)) {
        notifyError('Укажите тип поля')
        return false;
    }
    if (_.isEmpty(fieldSettingData.value.label)) {
        notifyError('Укажите наименование поля')
        return false;
    }
    return true;
}

/**
 * Добавление нового поля
 */
const addField = () => {
    if (validateSettings()) {
        if (_.findIndex(fields.value, {name: fieldSettingData.value.name}) >= 0) {
            notifyError('Duplicate field name "' + fieldSettingData.value.name + '"')
        } else {
            fields.value.push(fieldSettingData.value)
            fieldSettingsAddModal.value = false
        }
    }
}

/**
 * Сохранение поля
 */
const saveSettingsField = () => {
    if (validateSettings()) {
        let index = _.findIndex(fields.value, {name: fieldSettingData.value.name});
        if (index >= 0 && index !== fieldSettingIndex.value) {
            notifyError('Duplicate field name "' + fieldSettingData.value.name + '"')
        } else {
            fields.value[fieldSettingIndex.value] = fieldSettingData.value
            fieldSettingsEditModal.value = false
            fieldSettingIndex.value = null
        }
    }
}
/**
 * Удаление поля
 *
 * @param index
 */
const deleteField = (index) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: fields.value[index].label.ru}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        fields.value.splice(index, 1)
    })
}

/***************************
 *  Правила обработки поля
 ***************************/
const fieldRulesData = ref({})
const fieldRulesModal = ref(false)
const openFieldRulesModal = (field) => {
    fieldRulesData.value = field
    fieldRulesModal.value = true
}
const fieldRulesAreaHeight = computed(() => {
    return {
        height: 'calc(100vh - 160px)'
    }
})

const addRule = (field) => {
    if (!_.isArray(_.get(field, 'rules'))) {
        _.set(field, 'rules', []);
    }
    field.rules.push({
        condition: {
            type: '',
            operator: 'and',
            items: []
        },
        result: []
    });
}

const closeFieldRulesModal = () => {
    fieldRulesData.value = {}
    fieldRulesModal.value = false
}

watch(fields, () => {
    _.forEach(fields, (field) => {
        loadClassificator(field)
    })
    emit('update:items', fields)
})

const loadClassificator = (field) => {
    if (_.get(field, 'variants.type') === 'classificator') {
        ClassificationDB
            .getSimpleList(_.get(field, 'variants.classificator'))
            .then((r) => {
                _.set(options.value, _.get(field, 'variants.classificator'), r)
            })
    }
    if (_.get(field, 'variants.type') === 'science_classificator') {
        ClassificationDB
            .getMultiList(_.get(field, 'variants.science_classificator'))
            .then((r) => {
                _.set(options.value, _.get(field, 'variants.science_classificator'), r)
            })
    }
    if (_.get(field, 'variants.type') === 'model') {
        ClassificationDB
            .getModelList(_.get(field, 'variants.model'), props.ssc)
            .then((r) => {
                _.set(options.value, _.get(field, 'variants.model'), r)
            })
    }
}

onBeforeMount(() => {
    _.forEach(fields.value, (field) => {
        loadClassificator(field)
    })
    axios.get('/classificator.json?m=classificator').then((response) => {
        _.set(options.value, 'classificator', response.data.results)
    })
    axios.get('/classificator.json?m=science_classificator').then((response) => {
        _.set(options.value, 'science_classificator', response.data.results)
    })
    axios.get('/classificator.json?m=model').then((response) => {
        _.set(options.value, 'model', response.data.results)
    })
})

</script>