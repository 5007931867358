<template>
    <ProjectLayout :project="project" tab="export">

        <div class="row q-gutter-x-md q-pa-md" :class="{'column':$q.screen.lt.md}">
            <ExportForm :project="project" class="col"/>
            <ExportList :project="project"  :files="files" class="col"/>
        </div>

    </ProjectLayout>
</template>

<script setup>
import ProjectLayout from "@/Components/Project/ProjectLayout.vue";
import {getCurrentInstance} from "vue";
import {useQuasar} from "quasar";
import ExportForm from "@/Components/Project/Export/ExportForm.vue";
import ExportList from "@/Components/Project/Export/ExportList.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar();

const props = defineProps({
    project: {type: Object, required: true},
    files: {type: [], default: null}
})

</script>