<template>
    <Layout :department="department" tab="common">
        <q-bar class="bg-grey-2 q-py-sm q-mb-sm">
            <h6 class="q-my-none">{{ $translate('ssc.title-common') }}</h6>
            <q-space/>
        </q-bar>
        <q-card-section>
            <q-field :label="$translate('ssc.fields.name')"
                     stack-label class="q-my-md" label-color="dark" borderless>
                <template v-slot:control>
                    <q-icon v-if="department.can.edit"
                            name="edit" color="grey-5" class="q-mr-sm cursor-pointer"
                            :title="$translate('buttons.edit')"/>
                    <div class="cursor-pointer">{{ department.name }}</div>
                    <q-popup-edit v-if="department.can.edit" v-model="form"
                                  v-slot="scope" buttons auto-save
                                  :label-set="$translate('buttons.save')"
                                  :label-cancel="$translate('buttons.cancel')"
                                  @save="patchItem">
                        <div>{{ $translate('ssc.fields.name') }}</div>
                        <template v-for="(lang_name,locale) in $translate('fields.locale')">
                            <q-input v-model="scope.value.name_i18n[locale]" :label="lang_name"
                                     dense counter/>
                        </template>

                    </q-popup-edit>
                </template>
            </q-field>
            <q-separator/>
            <q-field :label="$translate('ssc.fields.name_short')"
                     stack-label class="q-my-md" label-color="dark" borderless>
                <template v-slot:control>
                    <q-icon v-if="department.can.edit"
                            name="edit" color="grey-5" class="q-mr-sm cursor-pointer"
                            :title="$translate('buttons.edit')"/>
                    <div class="cursor-pointer">{{ department.name_short }}</div>
                    <q-popup-edit v-if="department.can.edit" v-model="form"
                                  v-slot="scope" buttons auto-save
                                  :label-set="$translate('buttons.save')"
                                  :label-cancel="$translate('buttons.cancel')"
                                  @save="patchItem">
                        <div>{{ $translate('ssc.fields.name_short') }}</div>
                        <template v-for="(lang_name,locale) in $translate('fields.locale')">
                            <q-input v-model="scope.value.name_short_i18n[locale]" :label="lang_name"
                                     dense counter/>
                        </template>

                    </q-popup-edit>
                </template>
            </q-field>
            <q-separator/>
            <q-field :label="$translate('ssc.fields.description')"
                     stack-label class="q-my-md" label-color="dark" borderless>
                <template v-slot:control>
                    <q-icon v-if="department.can.edit"
                            name="edit" color="grey-5" class="q-mr-sm cursor-pointer"
                            :title="$translate('buttons.edit')"/>
                    <div class="cursor-pointer">{{ department.description }}</div>
                    <q-popup-edit v-if="department.can.edit" v-model="form"
                                  v-slot="scope" buttons auto-save
                                  :label-set="$translate('buttons.save')"
                                  :label-cancel="$translate('buttons.cancel')"
                                  @save="patchItem">
                        <div>{{ $translate('ssc.fields.description') }}</div>
                        <template v-for="(lang_name,locale) in $translate('fields.locale')">
                            <q-input type="textarea" rows="3" v-model="scope.value.description_i18n[locale]"
                                     :label="lang_name"
                                     dense counter/>
                        </template>

                    </q-popup-edit>
                </template>
            </q-field>
            <q-separator/>
            <q-field :label="$translate('ssc.fields.director')"
                     stack-label class="q-my-md" label-color="dark" borderless>
                <template v-slot:control>
                    <q-item class="q-py-sm col row self-stretch" v-if="department.director_id">
                        <StaffItem :staff="department.director" :user="department.director"
                                   hide-actions></StaffItem>
                    </q-item>
                </template>
            </q-field>
        </q-card-section>
    </Layout>
</template>

<script setup>
import {router, useForm} from "@inertiajs/vue3";
import {getCurrentInstance} from "vue";
import StaffItem from "@/Components/SSC/Staff/StaffItem.vue";
import Layout from "@/Pages/SSC/Layout.vue";
import _ from "lodash";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const props = defineProps({
    department: {}
})

const form = useForm({
    name_i18n: {
        ru: _.get(props.department, 'name_i18n.ru'),
        en: _.get(props.department, 'name_i18n.en')
    },
    name_short_i18n: {
        ru: _.get(props.department, 'name_short_i18n.ru'),
        en: _.get(props.department, 'name_short_i18n.en')
    },
    description_i18n: {
        ru: _.get(props.department, 'description_i18n.ru'),
        en: _.get(props.department, 'description_i18n.en')
    }
})

const patchItem = (value) => {
    form.transform((data) => ({
        ...data,
        ...value
    }))
            .post(route('ssc.update', props.department), {
                onSuccess: () => {
                    router.reload()
                }
            })
}
</script>