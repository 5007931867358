<template>
    <Card :title="$translate('research.title.apply-template',{number:props.research.number})"
          in-modal show-close-button header-toolbar>
        <q-select v-model="selectedTemplate"
                  :label="$translate('report-template.form.label.select-template')"
                  :options="optionsTemplate"
                  option-value="id"
                  option-label="name"
                  options-dense
                  @update:modelValue="onSelectTemplate"
                  :error="form.errors.hasOwnProperty('report_template_id')"
                  :error-message="_.get(form.errors,'report_template_id')"
        />
        <div class="row q-gutter-x-md q-mb-md">
            <div class="col">
                <q-input v-model="form.report_date"
                         :label="$translate('report-template.form.label.select-date')"
                         type="date"
                         :min="research.responsible_assign"
                         :max="moment.min(moment(),moment(research.project.date_end)).format('YYYY-MM-DD')"
                         :error="form.errors.hasOwnProperty('report_date')"
                         :error-message="_.get(form.errors,'report_date')"
                />
            </div>
            <div class="col">
                <q-input v-model="form.report_assay"
                         v-if="research.template.assay>0"
                         :label="$translate('report-template.form.label.select-assay')"
                         min="1"
                         :max="research.assay_received"
                         :error="form.errors.hasOwnProperty('report_assay')"
                         :error-message="_.get(form.errors,'report_assay')"
                />
            </div>
        </div>
        <div class="text-negative" v-if="form.errors.hasOwnProperty('equipment')">
            {{ _.get(form.errors,'equipment') }}
        </div>
        <Card v-if="selectedTemplate" hide-title hide-actions no-card-section>
            <q-list separator dense>
                <q-item class="bg-grey-2">
                    <q-item-section>
                        <q-item-label>{{ $translate('report-template.form.label.equipment') }}</q-item-label>
                    </q-item-section>
                </q-item>
                <template v-for="(equipment) in selectedTemplate.equipment">
                    <q-item dense>
                        <q-item-section class="q-pl-sm">
                            <q-item-label>{{ equipment.name }}</q-item-label>
                            <q-item-label v-for="(complex) in equipment.complex_parts" class="text-small">
                                - {{ complex.equipment.display_name }}
                            </q-item-label>
                            <q-item-label v-if="research.template.assay>0"
                                          class="text-small text-info">
                                {{ $translate('report-template.form.info.equipment') }}
                            </q-item-label>

                            <q-item-label v-if="_.size(equipment.consumables)>0"
                                          class="text-small text-italic">
                                {{ $translate('report-template.form.label.consumable') }}
                            </q-item-label>
                            <q-item-label v-for="(consumable) in equipment.consumables" class="text-small">
                                {{ consumable.name }} &mdash; {{ consumable.used }}
                            </q-item-label>
                            <q-item-label v-if="_.size(equipment.consumables)>0 && research.template.assay>0"
                                          class="text-small text-info">
                                {{ $translate('report-template.form.info.consumable') }}
                            </q-item-label>

                            <q-item-label v-if="_.size(equipment.worktimes)>0"
                                          class="text-small text-italic">
                                {{ $translate('report-template.form.label.worktime') }}
                            </q-item-label>
                            <q-item-label v-for="(worktime) in equipment.worktimes" class="text-small">
                                {{ worktime.comment }} &mdash; {{ worktime.time }}
                            </q-item-label>
                            <q-item-label v-if="_.size(equipment.worktimes)>0 && research.template.assay>0"
                                          class="text-small text-info">
                                {{ $translate('report-template.form.info.worktime') }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section side top>
                            <q-item-label>{{ equipment.time }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
            <q-list separator dense>
                <q-item class="bg-grey-2">
                    <q-item-section>
                        <q-item-label>{{ $translate('report-template.form.label.consumable') }}</q-item-label>
                    </q-item-section>
                </q-item>
                <template v-for="(consumable) in selectedTemplate.consumable">
                    <q-item dense>
                        <q-item-section class="q-pl-sm">
                            <q-item-label>{{ consumable.name }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-item-label>{{ consumable.used }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
            <q-list separator dense>
                <q-item class="bg-grey-2">
                    <q-item-section>
                        <q-item-label>{{ $translate('report-template.form.label.worktime') }}</q-item-label>
                    </q-item-section>
                </q-item>
                <template v-for="(worktime) in selectedTemplate.worktime">
                    <q-item dense>
                        <q-item-section class="q-pl-sm">
                            <q-item-label>{{ worktime.comment }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-item-label>{{ worktime.time }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </Card>

        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.apply-template')"
                   color="positive"
                   @click="applyTemplate"
            />
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Card} from "@/Components/Block";
import moment from "moment";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    research: {type: Object}
})
const emit = defineEmits(['onSuccess'])

const form = useForm({
    research_id: null,
    report_template_id: null,
    report_date: null,
    report_assay: null
})
const optionsTemplate = ref(null)

const loadTemplate = () => {
    axios.get(route('ajax.report'), {
        params: {
            'm': 'template',
            'research_template': props.research.template_id,
            'ssc_id': props.research.ssc_id
        }
    }).then((response) => {
        optionsTemplate.value = response.data.results;
    });
}

const selectedTemplate = ref(null)
const onSelectTemplate = (template) => {
    form.report_template_id = template.id
}
const applyTemplate = () => {
    form.transform((data) => ({
        ...data,
        research_id: props.research.id
    }))
        .post(route('project.research.report.template', props.research.number), {
            onSuccess: () => {
                emit('onSuccess')
            }
        })
}
onMounted(() => {
    loadTemplate()
})
</script>