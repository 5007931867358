<template>
    <q-table ref="tableRef"
             :columns="columns"
             :rows="sensorRules"
             :pagination="{rowsPerPage: 0}"
             :rows-per-page-options="[0]"
             :title="$translate('module-sensors.sensor-rules.list.title')"
             hide-pagination
             wrap-cells
             bordered
             flat
    >
        <template v-slot:body-cell-actions="props">
            <q-td :props="props" class="no-wrap">
                <DataTableActions :actions="props.row.actions" :name="props.row.rule" @on-success="onSuccess"/>
            </q-td>
        </template>
    </q-table>
</template>

<script setup>
import DataTableActions from "@/Components/DataTableActions.vue";
import {getCurrentInstance, ref} from "vue";

const props = defineProps({
    sensorTypeId: Number,
    sensorRules: Array,
    measurementTypes: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const tableRef = ref();
const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'id', label: $translate('module-sensors.sensor-rules.list.fields.id'), field: 'id'},
    {
        name: 'measurement_type_name',
        label: $translate('module-sensors.sensor-rules.list.fields.measurement_type'),
        field: 'measurement_type_name'
    },
    {name: 'rule', label: $translate('module-sensors.sensor-rules.list.fields.rule'), field: 'rule'},
];

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};
</script>
