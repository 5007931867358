<template>
    <StandartLayout :report="report" :ssc="ssc" :from="from" :to="to" :ssc-list="sscList" :tags-list="tagsList">
        <component :is="selectView"
                   :report="report"
                   :report-data="reportData"
        />
    </StandartLayout>
</template>

<script setup>
import * as AnalyticReportsViews from "@/Components/Analytic/Reports/Views";
import StandartLayout from "@/Pages/Analytic/Reports/StandartLayout.vue";
import {computed} from "vue";
import _ from "lodash";

const props = defineProps({
    report: {
        type: String,
        required: true,
    },
    reportData: {
        type: Object,
    },
    ssc: {
        type: Number,
    },
    from: {
        type: String,
    },
    to: {
        type: String,
    },
    tags: {
        type: Array,
        default: [],
    },
    tagsAdditional: {
        type: Array,
        default: [],
    },
    sscList: {
        type: Array,
        required: true,
    },
    tagsList: {
        type: Array,
        required: false,
        default: [],
    },
});

const selectView = computed(
    () => AnalyticReportsViews[_.upperFirst(_.camelCase(props.report + '_view'))]
);
</script>
