<template>
    <ProjectLayout :project="project" tab="files" :research-state="state">
        <Card :title="$translate('project.files.title')"
              :hide-actions="!project.can.filesUpload"
              no-card-section>
            <q-list separator>
                <template v-for="(file) in files">
                    <q-item>
                        <q-item-section>
                            <q-item-label>{{ file.file.name }}</q-item-label>
                            <q-item-label caption>
                                {{ $translate('buttons.ot') }}
                                {{ moment(file.created_at).format($translate('common.datetime.js')) }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-btn icon="o_download"
                                   :label="$translate('buttons.download')"
                                   color="positive"
                                   :href="$route('project.files.get',[project,file])"
                                   target="_blank"
                                   flat
                            />
                        </q-item-section>
                        <q-item-section side v-if="file.can.delete">
                            <q-btn icon="o_delete"
                                   :label="$translate('buttons.delete')"
                                   color="negative"
                                   flat
                                   @click="deleteFile(file)"/>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
            <template v-slot:actions>
                <div class="full-width">
                    <Alert variant="info">
                        Отчетные материалы (публикации и т.д.) по проекту прикладываются в соответствующем
                        <a :href="$route('project.publications',project)"
                           @click.prevent="router.visit($route('project.publications',project))">разделе <q-icon name="link"/></a>
                    </Alert>
                </div>
                <div class="row full-width q-gutter-x-md items-center">
                    <div class="col">
                        <q-file v-model="form.file"
                                :label="$translate('project.files.select-file')"
                                :hint="$translate('project.files.select-file-hint')"
                                max-file-size="20971520"
                                @rejected="onRejected"/>
                    </div>
                    <div class="col">
                        <q-field tag="div"
                                 borderless>
                            <template v-slot:control>
                                <q-checkbox v-model="form.status" :label="$translate('project.files.access-members')"/>
                            </template>
                        </q-field>
                        <div class="text-small">{{ $translate('project.files.acesss-tooltip') }}</div>
                    </div>
                    <div class="col-auto">
                        <q-btn icon="o_upload"
                               :label="$translate('buttons.upload')"
                               color="positive"
                               @click="uploadFile"/>
                    </div>
                </div>
            </template>
        </Card>
    </ProjectLayout>
</template>

<script setup>
import ProjectLayout from "@/Components/Project/ProjectLayout.vue";
import {getCurrentInstance} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import moment from "moment";
import {Alert, Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar();

const props = defineProps({
    project: {type: Object, required: true},
    files: {type: String, default: null}
})

const form = useForm({
    file: null,
    status: false
})

const uploadFile = () => {
    form.post(route('project.files.upload', props.project))
}
const onRejected = (rejectedEntries) => {
    $q.notify({
        type: 'negative',
        message: `Выбранный файл превышает допустимый размер`
    })
}

const deleteFile = (file) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: file.file.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('project.files.delete', [props.project, file]));
    })

}
</script>