<template>
    <Card title-bg="deep-orange-2"
          :title="$translate('module-ethics.application.list.title')"
          no-card-section
          hide-actions
          header-toolbar
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('menu.ethics.add-application')"
                   @click="router.get($route('services.ethics.application.create'))"
                   color="deep-orange-5"
                   icon="o_add"
            />
        </template>
        <DataTable allow-search :columns="columns" :request-route="$route('ajax.ethics', {m: 'applications', state: state})">
            <template v-slot:body-cell-number="props">
                <q-td :props="props">
                    <a :href="$route('services.ethics.application.show',props.row)"
                       @click.prevent="router.visit($route('services.ethics.application.show',props.row))"
                    >
                        <span v-html="props.value"></span>
                    </a>
                </q-td>
            </template>
            <template v-slot:body-cell-org="props">
                <q-td :props="props">
                    <span v-html="props.value"></span>
                </q-td>
            </template>
            <template v-slot:body-cell-name="props">
                <q-td :props="props">
                    <span v-html="props.value"></span>
                </q-td>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import DataTable from "@/Components/DataTable.vue";
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance} from "vue";
import {router} from "@inertiajs/vue3";

const props = defineProps({
    state: {
        type: Number,
        default: null
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {
        name: 'number',
        align: 'left',
        label: $translate('module-ethics.application.list.fields.number'),
        field: 'number',
        classes: 'text-no-wrap'
    },
    {name: 'author', align: 'left', label: $translate('module-ethics.application.list.fields.author'), field: 'author'},
    {name: 'org', align: 'left', label: $translate('module-ethics.application.list.fields.org'), field: 'org'},
    {name: 'name', align: 'left', label: $translate('module-ethics.application.list.fields.name'), field: 'name'},
    {
        name: 'dcreate',
        align: 'left',
        label: $translate('module-ethics.application.list.fields.dcreate'),
        field: 'dcreate'
    },
    {name: 'state', align: 'left', label: $translate('module-ethics.application.list.fields.state'), field: 'state'},
    {
        name: 'meeting_date',
        align: 'left',
        label: $translate('module-ethics.application.list.fields.meeting_date'),
        field: 'meeting_date'
    },
];
</script>
