<template>
    <SearchDepartment :label="$translate('cadre-department.fields.parent')"
                      @on-select="selectParent"/>

    <template v-for="(lang_name,lang) in $translate('fields.locale')">
        <q-input v-model="department.name_i18n[lang]"
                 :placeholder="lang_name"
                 :label="$translate('cadre-department.fields.name_i18n')+' ' +lang_name"/>
    </template>

    <SearchSSC :label="$translate('cadre-department.fields.ssc')"
               @on-select="selectSSC"/>

</template>

<script setup lang="ts">
import {getCurrentInstance} from "vue";
import {CadreDepartment} from '@/entity'
import _ from 'lodash';
import {
    Department as SearchDepartment,
    SSC as SearchSSC
} from "@/Components/Search/";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;

const department = defineModel<CadreDepartment>({})

const selectParent = (item: any) => {
    _.set(department.value, 'parent_id', item.id);
}
const selectSSC = (item: any) => {
    _.set(department.value, 'ssc_id', item.id);
}
</script>