<template>
    <q-card>
        <q-card-section class="flex justify-between items-center">
            <AnalyticReportsHeader :report-data="reportData"></AnalyticReportsHeader>
            <AnalyticReportsActions :report-data="reportData"></AnalyticReportsActions>
        </q-card-section>
        <q-card-section>
            <q-table :columns="columns"
                     :rows="data"
                     :pagination="{rowsPerPage: 0}"
                     :rows-per-page-options="[0]"
                     hide-pagination
                     wrap-cells
                     bordered
                     flat
            >
                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th rowspan="2" />
                        <q-th rowspan="2">{{ $translate('module-analytics.table_columns.' + report + '.name') }}</q-th>
                        <q-th colspan="3" class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.for_period') }}<br/>
                            {{ $translate('buttons.from') }} {{ reportData.from }}
                            {{ $translate('buttons.to') }} {{ reportData.to }}
                        </q-th>
                        <q-th rowspan="2" class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.target') }}
                        </q-th>
                    </q-tr>
                    <q-tr :props="props">
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.max_time') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.work_time') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.percent') }}
                        </q-th>
                    </q-tr>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td colspan="6"><b>{{ props.row.ssc }}</b></q-td>
                    </q-tr>
                    <template v-for="(equipment, i) in props.row.equipments" :key="i">
                        <q-tr :props="props">
                            <q-td />
                            <q-td>{{ equipment.name }}</q-td>
                            <q-td class="text-right">{{ equipment.max_time }}</q-td>
                            <q-td class="text-right">{{ equipment.work_time }}</q-td>
                            <q-td class="text-right" :class="getPercentClass(equipment)">
                                {{ equipment.percent }}%
                            </q-td>
                            <q-td class="text-center">{{ equipment.target }}%</q-td>
                        </q-tr>
                        <template v-if="!_.isNull(equipment.projects)">
                            <template v-for="(companies, source) in equipment.projects" :key="source">
                                <q-tr :props="props" v-for="(data, company) in companies" :key="company">
                                    <q-td />
                                    <q-td class="text-right">{{ company }}</q-td>
                                    <q-td class="text-right">{{ helpers.formatNumber(Number(data.price), 2) }}</q-td>
                                    <q-td class="text-right">{{ helpers.formatNumber(Number(data.time) / 60, 2) }}</q-td>
                                    <q-td class="text-right">
                                        {{
                                            helpers.formatNumber(((Number(data.time) / 60) / Number(equipment.work_time)) * 100, 2)
                                        }}%
                                    </q-td>
                                    <q-td />
                                </q-tr>
                            </template>
                        </template>
                    </template>
                </template>
            </q-table>
        </q-card-section>
    </q-card>
</template>

<script setup>
import AnalyticReportsActions from "@/Components/Analytic/Reports/AnalyticReportsActions.vue";
import AnalyticReportsHeader from "@/Components/Analytic/Reports/AnalyticReportsHeader.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import helpers from "@/helpers";

const props = defineProps({
    report: {
        type: String,
    },
    reportData: {
        type: Object,
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const data = ref([]);
_.forEach(props.reportData.data, (equipments, ssc) => {
    data.value.push({
        ssc: ssc,
        equipments: _.map(equipments, (equipment) => ({
            name: equipment.name,
            max_time: helpers.formatNumber(Number(equipment.max_time), 2),
            work_time: helpers.formatNumber(Number(equipment.work_time), 2),
            percent: helpers.formatNumber(Number(equipment.percent), 2),
            target: helpers.formatNumber(20, 2),
            projects: equipment.hasOwnProperty('projects') ? equipment.projects : null
        }))
    });
});

const getPercentClass = (equipment) => equipment.percent > 20 ? 'bg-positive text-white' : '';

const columns = [
    {name: 'space', label: '', align: 'left'},
    {name: 'name', label: $translate('module-analytics.table_columns.' + props.report + '.name'), align: 'left'},
    {
        name: 'max_time',
        label: $translate('module-analytics.table_columns.' + props.report + '.max_time'),
        align: 'left'
    },
    {
        name: 'work_time',
        label: $translate('module-analytics.table_columns.' + props.report + '.work_time'),
        align: 'left'
    },
    {name: 'percent', label: $translate('module-analytics.table_columns.' + props.report + '.percent'), align: 'left'},
    {name: 'target', label: $translate('module-analytics.table_columns.' + props.report + '.target'), align: 'left'},
];
</script>
