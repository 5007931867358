<template>
    <Card title-bg="indigo-2"
          title-text="indigo-10"
          :title="$translate('module-microcollections.element.form.title.edit')"
          :header-toolbar="can.back"
    >
        <template v-if="can.back" v-slot:headerActions>
            <q-btn :label="$translate('buttons.back')"
                   @click="router.get($route('services.microcollections.index'))"
                   icon="o_chevron_left"
                   color="indigo-5"
                   no-caps
                   dense
            />
        </template>
        <MicroCollectionForm v-model="form" :element="element" :types="types" :parts="parts"/>
        <template v-slot:actions>
            <q-btn :label="$translate('buttons.save')" @click="submitForm" color="positive"/>
        </template>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {Form as MicroCollectionForm} from "@/Components/MicroCollections/Element";
import {getCurrentInstance} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    element: {
        type: Object,
        default(rawProps) {
            return {
                collection_type_id: null,
                collection_part_id: null,
                number: '',
                class: '',
                family: '',
                genus: '',
                species: '',
                strain: '',
                syngen: '',
                coupling: '',
                serotype: '',
                synonym: '',
                host: '',
                from_collection: '',
                country: '',
                city: '',
                ecotype: '',
                person: '',
                year: '',
                symbiont: '',
                media: '',
                condition: '',
                sequence: '',
                genome: '',
                publication: '',
                comment: '',
                add: '',
                file: null,
                public_state: true
            };
        }
    },
    can: Object,
    types: Array,
    parts: Array,
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    collection_type_id: _.get(props.element, 'collection_type_id'),
    collection_part_id: _.get(props.element, 'collection_part_id'),
    number: _.get(props.element, 'number'),
    class: _.get(props.element, 'class'),
    family: _.get(props.element, 'family'),
    genus: _.get(props.element, 'genus'),
    species: _.get(props.element, 'species'),
    strain: _.get(props.element, 'strain'),
    syngen: _.get(props.element, 'syngen'),
    coupling: _.get(props.element, 'coupling'),
    serotype: _.get(props.element, 'serotype'),
    synonym: _.get(props.element, 'synonym'),
    host: _.get(props.element, 'host'),
    from_collection: _.get(props.element, 'from_collection'),
    country: _.get(props.element, 'country'),
    city: _.get(props.element, 'city'),
    ecotype: _.get(props.element, 'ecotype'),
    person: _.get(props.element, 'person'),
    year: _.get(props.element, 'year'),
    symbiont: _.get(props.element, 'symbiont'),
    media: _.get(props.element, 'media'),
    condition: _.get(props.element, 'condition'),
    sequence: _.get(props.element, 'sequence'),
    genome: _.get(props.element, 'genome'),
    publication: _.get(props.element, 'publication'),
    comment: _.get(props.element, 'comment'),
    add: _.get(props.element, 'add'),
    file: null,
    public_state: _.get(props.element, 'public_state')
});

const submitForm = () => {
    form.post(route('services.microcollections.element.update', props.element));
};
</script>
