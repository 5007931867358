<template>
    <ChartsLayout tab="vacation">
        <Card title-bg="green-9" title-text="white" :title="$translate('cadre-vacation.title.chart')">
            <div class="row">
                <q-item class="col-4">
                    <q-item-section>
                        <q-select v-model="year"
                                  :options="years" options-dense
                                  :label="$translate('cadre-vacation.chart.select-year')"
                        />
                    </q-item-section>
                </q-item>
                <q-item class="col-4">
                    <q-item-section>
                        <q-select v-model="month"
                                  :options="months" options-dense
                                  :label="$translate('cadre-vacation.chart.select-month')"
                        />
                    </q-item-section>
                </q-item>
                <q-item class="col">
                    <q-item-section>
                        <q-select v-model="department"
                                  :options="filteredDepartments" options-dense
                                  :label="$translate('cadre-vacation.chart.select-department')"
                                  @filter="searchDepartment"
                                  input-debounce="0"
                                  use-input
                        />
                    </q-item-section>
                </q-item>
                <q-item class="col-auto">
                    <q-item-section>
                        <q-btn icon="visibility" :label="$translate('buttons.show')" @click="loadVacations"/>
                    </q-item-section>
                </q-item>
            </div>
            <q-dialog v-model="store.state">
                <q-card v-if="!_.isEmpty(store.data)" style="width: 35vw">
                    <q-toolbar class="bg-green-9 text-white">
                        <q-toolbar-title shrink>{{ store.data.typeText }}</q-toolbar-title>
                        <q-space/>
                        <q-btn icon="close" flat round dense v-close-popup/>
                    </q-toolbar>
                    <q-card-section>
                        <q-item>
                            <q-item-section>
                                <q-field label="ФИО" stack-label>
                                    <template v-slot:control>
                                        <div class="self-center full-width no-outline" tabindex="0">
                                            {{ store.data.fulledName }}
                                        </div>
                                    </template>
                                </q-field>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-field :label="$translate('cadre-vacation.fields.cadre_staff')" stack-label>
                                    <template v-slot:control>
                                        <div class="self-center full-width no-outline" tabindex="0">
                                            {{ store.data.position }}
                                        </div>
                                    </template>
                                </q-field>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-field :label="$translate('cadre-vacation.fields.period')" stack-label>
                                    <template v-slot:control>
                                        <div class="self-center full-width no-outline" tabindex="0">
                                            {{ $translate('buttons.from') }} <b>{{
                                                moment(store.data.start).format($translate('common.date.js'))
                                            }}</b>
                                            {{ $translate('buttons.to') }}
                                            <b>{{ moment(store.data.end).format($translate('common.date.js')) }}</b>
                                            ({{ store.data.cDays }} {{ $translate('units.days_short') }})
                                        </div>
                                    </template>
                                </q-field>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-field label="Статус" stack-label>
                                    <template v-slot:control>
                                        <div class="self-center full-width no-outline" tabindex="0">
                                            {{ store.data.statusText }}
                                        </div>
                                    </template>
                                </q-field>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="store.data.comment && !_.isEmpty(store.data.comment)">
                            <q-item-section>
                                <q-field :label="$translate('cadre-vacation.fields.comment')" stack-label>
                                    <template v-slot:control>
                                        <div class="self-center full-width no-outline"
                                             tabindex="0"
                                             v-html="store.data.comment.replace('\n', '<br/>')"
                                        ></div>
                                    </template>
                                </q-field>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="hasActions(store.data)">
                            <q-item-section>
                                <q-input v-model="comment"
                                         :label="$translate('cadre-vacation.placeholders.comment')"
                                         type="textarea"
                                         rows="3"
                                         outlined
                                />
                            </q-item-section>
                        </q-item>
                    </q-card-section>
                    <q-card-actions
                            v-if="hasActions(store.data) && (store.data.permission.approve || store.data.permission.decline)"
                            align="right">
                        <q-btn v-if="hasActions(store.data) && store.data.permission.approve"
                               @click="approveVacation(store.data, comment)"
                               :label="$translate('buttons.approve')"
                               color="positive"
                               icon="done"
                        />
                        <q-btn v-if="hasActions(store.data) && store.data.permission.decline"
                               @click="declineVacation(store.data, comment)"
                               :label="$translate('buttons.decline')"
                               color="negative"
                               icon="not_interested"
                        />
                    </q-card-actions>
                </q-card>
            </q-dialog>
            <q-separator/>
            <Chart chart-type="gantt-date"
                   :columns-height="25"
                   :config="config"
                   :data="vacations"
                   :legend="vacationsLegend"
                   :height="getHeight()"
                   :max-date="getMaxDate"
                   :min-date="getMinDate"
                   :zoom-step="0.025"
                   :with-modal="true"
            ></Chart>
        </Card>
    </ChartsLayout>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import Chart from "@/Components/Chart.vue";
import ChartsLayout from "@/Pages/Cadre/Charts/ChartsLayout.vue";
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {useForm, usePage} from "@inertiajs/vue3";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import {store} from "@/store/globalstore";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const props = defineProps({
    positions: null,
    years: {
        type: Array
    },
    departments: {
        type: Array
    }
});

const currentSsc = computed(() => usePage().props.auth.current_ssc);

const legend = ref({});
const vacations = ref({});
const department = ref(null);
const year = ref(null);
const month = ref({
    label: $translate('common.month-all'),
    value: null
});
const config = ref({
    root: {
        dateFormatter: {
            dateFormat: "dd.MM.yyyy"
        }
    },
    categoryAxis: {
        categoryField: "category"
    },
    columnSeries: {
        openValueXField: "start",
        valueXField: "end",
        categoryYField: "category",
        tooltipText: "{typeText} ({statusText}):\n[bold]{openValueX}[/] - [bold]{valueX}[/] ({cDays} дней)\n{comment}"
    }
});

const months = computed(() => {
    let months = [];
    months.push({
        label: $translate('common.month-all'),
        value: null
    });
    _.forEach($translate("common.month"), (value, key) => {
        months.push({
            label: value,
            value: key,
        });
    });
    return months;
});

const vacationsLegend = computed(() => getLegend(vacations.value));
const getLegend = (data) => {
    let legend = {};
    _.forEach(data, (d, i) => {
        legend[i] = _.result(d[0], 'category', '---');
    });
    return legend;
};

const getHeight = () => ((_.size(vacations.value) * 50) + 300) + 'px';

const getMinDate = computed(() => {
    let d = moment();
    d.set({'year': year.value, 'month': 0});
    if (month.value && month.value.value) {
        d.month(month.value.value - 1);
    }
    return Number.parseInt(d.startOf('month').format('x'));
});
const getMaxDate = computed(() => {
    let d = moment();
    d.set({'year': year.value, 'month': 11});
    if (month.value && month.value.value) {
        d.month(month.value.value - 1);
    }
    return Number.parseInt(d.endOf('month').format('x'));
});

const loadVacations = () => {
    axios.get(route('ajax.cadre.vacation'), {
        params: {
            'm': 'chartVacations',
            'year': year.value,
            'month': month.value ? month.value.value : month.value,
            'department': department.value ? department.value.value : department.value
        }
    })
        .then((response) => {
            if (!_.isEmpty(response.data.results)) {
                vacations.value = response.data.results;
            }
        });
};

const comment = ref(null);

const hasActions = (vacation) => {
    if (vacation.permission.expired) {
        return false;
    }
    return (vacation.permission.approve || vacation.permission.decline);
};

let formApprove = null;
let formDecline = null;

const approveVacation = (vacation, comment) => {
    let forma = {};
    forma['action'] = 'approveVacation';
    forma['vacation'] = {};
    _.forEach(vacation, (v, k) => {
        forma['vacation'][k] = v;
    });
    forma['comment'] = comment;
    formApprove = useForm(forma);
    formApprove.post(route('cadre.vacation.actions'));
    store.switchState();
    formApprove = null;
    loadVacations();
};
const declineVacation = (vacation, comment) => {
    let forma = {};
    forma['action'] = 'declineVacation';
    forma['vacation'] = {};
    _.forEach(vacation, (v, k) => {
        forma['vacation'][k] = v;
    });
    forma['comment'] = comment;
    formDecline = useForm(forma);
    formDecline.post(route('cadre.vacation.actions'));
    store.switchState();
    formDecline = null;
    loadVacations();
};

const filteredDepartments = ref([]);
const searchDepartment = (search, update) => {
    if (search === '') {
        update(() => {
            filteredDepartments.value = props.departments;
        });
    }
    update(() => {
        filteredDepartments.value = _.filter(
            props.departments,
            o => _.includes(
                _.toLower(_.get(Object(o), 'label')),
                _.toLower(search)
            )
        );
    });
};

onMounted(() => {
    if (!year.value) {
        year.value = props.years[_.findIndex(props.years, y => Number(y) === moment().year())];
    }
    filteredDepartments.value = props.departments;
    if (!department.value) {
        if (currentSsc.value && Number(currentSsc.value) > 0) {
            department.value = _.find(props.departments, {'ssc_id': Number(currentSsc.value)});
            loadVacations()
        }
    }
});
</script>
