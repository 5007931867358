<template>
    <Card :title="$translate('equipment.category.title.edit')" title-bg="deep-purple-1"
          header-toolbar no-card-section>
        <template v-slot:headerActions>
            <q-btn @click="router.visit($route('admin.equipment-category.index'))"
                   color="deep-purple-3"
                   icon="navigate_before"
                   :label="$translate('buttons.back')"
                   dense/>
        </template>

        <EquipmentCategoryForm v-model="form"/>

        <template v-slot:actions>
            <q-btn color="positive"
                   icon="o_save"
                   :label="$translate('buttons.save')"
                   @click="submitForm"/>
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import Card from "@/Components/Block/Card.vue";
import EquipmentCategoryForm from "@/Components/EquipmentCategory/EquipmentCategoryForm.vue";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    equipmentCategory: {
        type: Object
    }
})
const form = useForm({
    code: _.get(props.equipmentCategory, 'code'),
    name_i18n: _.get(props.equipmentCategory, 'name_i18n'),
})
const submitForm = () => {
    form.patch(route('admin.equipment-category.update', props.equipmentCategory.code))
}
</script>