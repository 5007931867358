<template>
    <q-btn icon="add" :label="$translate('buttons.add')" dense color="grey-3"
           text-color="dark" no-caps
           v-if="equipment.permission.edit"
           @click="plannedScheduleModal = true"/>
    <span v-else></span>
    <q-dialog v-model="plannedScheduleModal" no-backdrop-dismiss>
        <Card style="width: 700px;max-width: 90vw" show-close-button header-toolbar
              :title="$translate('equipment.item.title.work-planned')">
            <q-item>
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.schedule.planned.period') }}
                    </q-item-label>
                    <div class="row q-gutter-x-md">
                        <q-input type="date" class="col" :prefix="$translate('buttons.from')"
                                 v-model="plannedScheduleForm.start"
                                 input-class="text-center"
                                 :error-message="plannedScheduleForm.errors['start']"
                                 :error="plannedScheduleForm.errors.hasOwnProperty('start')"/>
                        <q-input type="date" class="col" :prefix="$translate('buttons.to')"
                                 v-model="plannedScheduleForm.end"
                                 input-class="text-center"
                                 :error-message="plannedScheduleForm.errors['end']"
                                 :error="plannedScheduleForm.errors.hasOwnProperty('end')"/>
                    </div>
                </q-item-section>
            </q-item>

            <q-item>
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.schedule.planned.rejim') }}
                    </q-item-label>
                    <div class="row column">
                        <q-radio size="sm" v-model="plannedScheduleForm.rejim" val="preset"
                                 :label="$translate('equipment.item.fields.worktime_preset')"/>
                        <q-radio size="sm" v-model="plannedScheduleForm.rejim" val="week"
                                 :label="$translate('equipment.item.fields.worktime_day')"/>
                        <q-radio size="sm" v-model="plannedScheduleForm.rejim" val="period"
                                 :label="$translate('equipment.item.fields.worktime_period')"/>
                    </div>
                    <div v-if="plannedScheduleForm.errors.hasOwnProperty('rejim')" class="text-negative">
                        {{ plannedScheduleForm.errors['rejim'] }}
                    </div>
                </q-item-section>
            </q-item>
            <q-item v-show="plannedScheduleForm.rejim === 'preset'">
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.item.fields.worktime_preset') }}
                    </q-item-label>
                    <q-select v-model="plannedScheduleForm.preset" :options="presetOptions"
                              emit-value map-options options-dense
                              @update:model-value="distributePresetToWeek"
                              :error-message="plannedScheduleForm.errors['preset']"
                              :error="plannedScheduleForm.errors.hasOwnProperty('preset')">
                        <template v-slot:option="scope">
                            <q-item v-bind="scope.itemProps">
                                <q-item-section>
                                    <q-item-label v-html="scope.opt.label"></q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                        <template v-slot:selected-item="scope">
                            <q-item v-bind="scope.itemProps">
                                <q-item-section>
                                    <q-item-label v-html="scope.opt.label"></q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-select>
                </q-item-section>
            </q-item>
            <q-item v-show="plannedScheduleForm.rejim === 'week'">
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.item.fields.worktime_day') }}
                    </q-item-label>
                    <div class="row q-gutter-x-sm">
                        <template v-for="(col) in $translate('fields.weekdays')">
                            <q-input v-model="plannedScheduleForm.worktime_day[col.day]"
                                     :label="col.label" class="col"
                                     @update:model-value="calculateWorktimePeriod"
                                     input-class="text-center"
                                     type="number" min="0" max="24"/>
                        </template>
                    </div>
                    <div v-if="plannedScheduleForm.errors.hasOwnProperty('worktime_day')" class="text-negative">
                        {{ plannedScheduleForm.errors['worktime_day'] }}
                    </div>
                </q-item-section>
            </q-item>
            <q-item v-show="plannedScheduleForm.rejim === 'period'">
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.item.fields.worktime_period') }}
                    </q-item-label>
                    <q-input v-model="plannedScheduleForm.worktime_period"
                             input-class="text-center" @update:model-value="distributePeriodToWeek"
                             :hint="'плановое количество часов за указанный период' + ' (~'+plannedScheduleForm.perDay+' в день)'"
                             type="number" min="0" max="24"

                             :error-message="plannedScheduleForm.errors['worktime_period']"
                             :error="plannedScheduleForm.errors.hasOwnProperty('worktime_period')"/>
                </q-item-section>
            </q-item>
            <q-item>
                <q-item-section>
                    <q-item-label caption>
                        {{ $translate('equipment.item.fields.confirm_document') }}
                    </q-item-label>
                    <q-file v-model="plannedScheduleForm.confirm_document"
                            :error-message="plannedScheduleForm.errors['confirm_document']"
                            :error="plannedScheduleForm.errors.hasOwnProperty('confirm_document')">
                        <template v-slot:append>
                            <q-icon name="attach_file"/>
                        </template>
                    </q-file>
                </q-item-section>
            </q-item>
            <template v-slot:actions>
                <q-btn color="positive"
                       icon="save"
                       :label="$translate('buttons.add')"
                       @click="plannedScheduleFormSubmit"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount, reactive, ref, watch} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import axios from "axios";
import _, {ceil} from "lodash"
import Card from "@/Components/Block/Card.vue";
import {Cookies, Notify, useQuasar} from 'quasar'
import moment from "moment";
import EquipmentSchedulePlannedForm from "@/Components/Equipment/EquipmentSchedulePlannedForm.vue";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    equipment: {
        type: Object
    },
})

const plannedHistory = ref(false)
const plannedScheduleModal = ref(false)
const plannedScheduleForm = useForm({
    start: null,
    end: null,
    rejim: null,
    preset: null,
    confirm_document: null,
    worktime_day: {
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
    },
    worktime_period: null,
    perDay: 0
})
const plannedScheduleFormSubmit = () => {
    plannedScheduleForm
            .post(route('equipment.schedule.planned.update', props.equipment), {
                onSuccess: () => {
                    router.reload()
                    plannedScheduleModal.value = false
                }
            })
}
const presetOptions = computed(() => {
    return _.map($translate('fields.work-time-select'), (label, value) => {
        return {
            value: value,
            label: label
        }
    });
})
const distributePresetToWeek = (val) => {
    let v = val.split("x");
    let hours = parseInt(v[0]), days = parseInt(v[1]);
    let i;
    for (i = 0; i < 7; i++) {
        _.set(plannedScheduleForm.worktime_day, i, null);
    }
    if (days === 7) {
        for (i = 0; i < 7; i++) {
            _.set(plannedScheduleForm.worktime_day, i, hours);
        }
    } else {
        for (i = 1; i <= days; i++) {
            _.set(plannedScheduleForm.worktime_day, i, hours);
        }
    }
    calculateWorktimePeriod()
}
const distributePeriodToWeek = (onPeriod) => {
    // console.log(plannedScheduleForm)
    if (plannedScheduleForm.rejim === 'period') {
        if (plannedScheduleForm.start && plannedScheduleForm.end) {
            let start = moment(plannedScheduleForm.start).startOf('day');
            let end = moment(plannedScheduleForm.end).startOf('day')
            let days = end.diff(start, 'days') + 1;
            // console.log(days)
            let val = (onPeriod / days).toFixed(0);
            _.set(plannedScheduleForm, 'perDay', (val > 24 ? 24 : val));
            if (onPeriod > days * plannedScheduleForm.perDay) {
                Notify.create({
                    color: 'warning',
                    message: 'Количество часов превышает допустимое значение за указанный период. Значение будет изменено',
                    position: "top",
                    progress: true,
                    icon: "report_problem"
                })
                _.set(plannedScheduleForm, 'worktime_period', days * plannedScheduleForm.perDay)
            }
            _.forEach(plannedScheduleForm.worktime_day, (h, d) => {
                _.set(plannedScheduleForm.worktime_day, d, plannedScheduleForm.perDay);
            })

        }
    }
}
const calculateWorktimePeriod = () => {
    let sum = 0;
    if (plannedScheduleForm.start && plannedScheduleForm.end) {
        let start = moment(plannedScheduleForm.start);
        let end = moment(plannedScheduleForm.end)
        while (start.isSameOrBefore(end)) {
            sum = sum + Number(plannedScheduleForm.worktime_day[start.day()]);
            start.add(1, 'day');
        }
    }
    _.set(plannedScheduleForm, 'worktime_period', sum);
}


</script>