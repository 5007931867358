<template>
    <Card :title="$translate('module-archive.research.card.resultfiles.title', {size: _.size(files)})"
          class="q-mb-md"
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn :icon="expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                   :title="$translate('buttons.toggle-list')"
                   @click="expanded = !expanded"
                   size="sm"
                   flat
            />
        </template>
        <template v-slot:default>
            <q-slide-transition>
                <div v-show="expanded">
                    <q-list separator>
                        <q-item v-for="(file, i) in files" :key="i">
                            <q-item-section>
                                <a :href="_.get(file, 'link')"
                                   target="_blank"
                                   rel="noreferrer nofollow"
                                   class="text-brand"
                                >
                                    <q-icon :name="'far fa-' + _.get(file, 'icon')" size="sm" />
                                    {{ _.get(file, 'name') }}
                                </a>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </div>
            </q-slide-transition>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";

const props = defineProps({
    files: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const expanded = ref(true);
</script>
