<template>
    <Layout :department="department" tab="documents">
        <q-bar class="bg-grey-2 q-py-sm">
            <h6 class="q-my-none">{{ $translate('ssc.title-documents') }}</h6>
            <q-space/>
            <q-btn v-if="department.can.staff.add"
                   :label="$translate('buttons.add')"
                   icon="add"
                   size="sm"
                   @click="addDocumentDialog = true"
                   color="blue-grey-5"
                   dense/>
        </q-bar>
        <q-table :rows="department.documents"
                 :columns="columns"
                 :rows-per-page-options="[0]"
                 hide-pagination flat>
            <template v-slot:body-cell-document_type="props">
                <q-td :props="props">
                    {{ _.get(props.row.document_type, 'name', '--') }}
                </q-td>
            </template>
            <template v-slot:body-cell-name="props">
                <q-td :props="props">
                    {{ props.row.name }}
                </q-td>
            </template>
            <template v-slot:body-cell-file="props">
                <q-td :props="props">
                    <div class="flex items-center">
                        <a :href="$route('ssc.documents.get',[department,props.row])"
                           target="_blank">{{ props.row.file.name }}</a>
                        <q-btn v-if="props.row.external"
                               :title="$translate('копировать код ссылки')"
                               icon="o_integration_instructions"
                               class="q-ml-sm"
                               size="sm"
                               square dense flat
                               @click="copyToBufer(props.row)"
                        />
                    </div>
                </q-td>
            </template>
            <template v-slot:body-cell-external="props">
                <q-td :props="props">
                    <q-icon flat
                            :name="props.row.external?'check':'clear'"
                            :color="props.row.external?'positive':'grey-5'"
                            size="sm"
                    />
                </q-td>
            </template>
            <template v-slot:body-cell-confirm="props">
                <q-td :props="props">
                    <q-icon flat
                            :name="props.row.confirm?'check':'clear'"
                            :color="props.row.confirm?'positive':'grey-5'"
                            size="sm"/>
                </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props">
                    <DataTableActions>
                        <q-item v-if="props.row.can.edit"
                                clickable
                                @click="openEditDialog(props.row)">
                            <q-item-section>
                                <q-item-label>{{ $translate('buttons.edit') }}</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-icon name="o_edit"/>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="props.row.can.delete"
                                clickable
                                @click="deleteDocument(props.row)">
                            <q-item-section>
                                <q-item-label>{{ $translate('buttons.delete') }}</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-icon name="o_delete"/>
                            </q-item-section>
                        </q-item>
                    </DataTableActions>
                </q-td>
            </template>
        </q-table>
        <q-dialog v-model="addDocumentDialog" no-backdrop-dismiss>
            <Card :title="$translate('ssc.documents.title.add')" title-bg="blue-grey-3"
                  in-modal show-close-button header-toolbar>
                <SSCDocumentForm v-model="documentForm" :document-type="documentType"/>
                <template v-slot:actions>
                    <q-btn icon="save" :label="$translate('buttons.add')" color="positive"
                           @click="addDocumentFormSubmit"/>
                </template>
            </Card>
        </q-dialog>
        <q-dialog v-model="editDocumentDialog" no-backdrop-dismiss>
            <Card :title="$translate('ssc.documents.title.edit')" title-bg="blue-grey-3"
                  in-modal show-close-button header-toolbar>
                <SSCDocumentForm v-model="documentForm" :document-type="documentType"/>
                <template v-slot:actions>
                    <q-btn icon="save" :label="$translate('buttons.save')" color="positive"
                           @click="editDocumentFormSubmit"/>
                </template>
            </Card>
        </q-dialog>
    </Layout>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import {useQuasar, copyToClipboard} from "quasar";
import _ from "lodash";
import Layout from "@/Pages/SSC/Layout.vue";
import {Card} from "@/Components/Block/";
import SSCDocumentForm from "@/Components/SSC/Document/SSCDocumentForm.vue";
import DataTableActions from "@/Components/DataTableActions.vue";

const $q = useQuasar();
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    department: {},
})

const documentType = computed(() => usePage().props.documentType)

const columns = [
    {name: 'actions', label: '', align: 'center', style: 'width: 2rem'},
    {name: 'document_type', label: $translate('ssc.documents.fields.type'), align: 'left'},
    {name: 'name', label: $translate('ssc.documents.fields.name'), align: 'left'},
    {name: 'file', label: $translate('ssc.documents.fields.file'), align: 'left'},
    {name: 'external', label: $translate('ssc.documents.fields.external'), align: 'center'},
    {name: 'confirm', label: $translate('ssc.documents.fields.confirm'), align: 'center'},
];
const addDocumentDialog = ref(false)

const documentForm = useForm({
    document_type_id: null,
    name_i18n: {
        ru: '',
        en: '',
    },
    file: null,
    external: false,
    confirm: false,
})
const addDocumentFormSubmit = () => {
    documentForm.post(route('ssc.documents.upload', props.department), {
        onSuccess: () => {
            addDocumentDialog.value = false
            documentForm.reset()
            router.reload()
        }
    })
}

const editDocumentDialog = ref(false)
const openEditDialog = (document) => {
    _.forEach(document, (v, k) => {
        _.set(documentForm, k, v)
    })
    editDocumentDialog.value = true;
}

const editDocumentFormSubmit = () => {
    documentForm.post(route('ssc.documents.update', [props.department, documentForm.id]), {
        onSuccess: () => {
            editDocumentDialog.value = false
            documentForm.reset()
            router.reload()
        }
    })
}

const deleteDocument = (document) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: document.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('ssc.documents.delete', [document.ssc_id, document.id]), {
            onSuccess: () => {
                router.reload()
            }
        })
    })
}

const copyToBufer = (document) => {
    let link = '<a href="' + $route('ssc.documents.get', [document.ssc_id, document]) + '" target="_blank">' + document.name + '</a>';
    copyToClipboard(link)
            .then(() => {
                $q.notify({
                    color: 'positive',
                    message: 'Текст ссылки скопирован в буфер',
                    position: "bottom",
                    progress: true,
                })
            })
            .catch(() => {
                $q.notify({
                    color: 'negative',
                    message: 'Ошибка копирования',
                    position: "bottom",
                    progress: true,
                })
            })
}
</script>