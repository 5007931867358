<template>
    <q-input v-model="form.name"
             :label="$translate('module-sensors.sensors.form.fields.name')"
             :error="form.errors.hasOwnProperty('name')"
             :error-message="_.get(form.errors, 'name')"
    />
    <q-input v-model.number="form.period"
             :label="$translate('module-sensors.sensors.form.fields.period')"
             :suffix="$translate('module-sensors.sensors.form.fields.period_suffix')"
             :error="form.errors.hasOwnProperty('period')"
             :error-message="_.get(form.errors, 'period')"
             :rules="[val => val >= 1 && val <= 60 || 'Минимум минута и не более часа']"
             reverse-fill-mask
             type="number"
             :max="60"
             :min="1"
             mask="#"
    />
    <q-select v-model="form.timeout"
              :label="$translate('module-sensors.sensors.form.fields.timeout')"
              :hint="$translate('module-sensors.sensors.form.fields.timeout_hint')"
              :suffix="$translate('module-sensors.sensors.form.fields.timeout_suffix')"
              :options="timeouts"
              options-dense
    />
    <q-toggle v-model="form.enabled"
              :label="$translate(`module-sensors.sensors.form.fields.${form.enabled ? 'enabled' : 'disabled'}`)"
              class="q-mt-sm"
    />
    <q-select v-model="form.sensor_group_id"
              :label="$translate('module-sensors.sensors.form.fields.sensor_group_id')"
              :error="form.errors.hasOwnProperty('sensor_group_id')"
              :error-message="_.get(form.errors, 'sensor_group_id')"
              :options="sensorGroups"
              map-options
              emit-value
    />
    <q-select v-model="form.sensor_type_id"
              :label="$translate('module-sensors.sensors.form.fields.sensor_type_id')"
              :error="form.errors.hasOwnProperty('sensor_type_id')"
              :error-message="_.get(form.errors, 'sensor_type_id')"
              @update:model-value="onSelectedSensorType"
              :options="sensorTypes"
              map-options
              emit-value
    />
    <SensorConnectionData :connection-id="selectedConnection"
                          :current-data="form.connection_data"
                          @input="setConnectionData"
    />
    <div v-if="form.errors.hasOwnProperty('connection_data')" class="text-negative">
        {{ _.get(form.errors, 'connection_data') }}
    </div>
    <q-btn v-if="selectedConnection && (form.connection_data && form.connection_data.hasOwnProperty('ip') && !_.isEmpty(form.connection_data.ip))"
           :label="$translate('module-sensors.sensors.form.actions.check-connection')"
           @click="checkConnection"
           class="q-mt-sm"
           color="secondary"
           size="sm"
           dense
    />
    <template v-if="!_.isNull(checkConnectionResult)">
        <q-field :label="$translate('module-sensors.sensors.form.fields.connection-result')"
                 stack-label
                 borderless
        >
            <template v-slot:control>
                        <pre v-if="isJSON(checkConnectionResult)"
                             class="self-center full-width no-outline pre-scrollable"
                             tabindex="0"
                        >{{ handleResponse(_.get(JSON.parse(checkConnectionResult), 'result')) }}</pre>
                <div v-else class="self-center full-width no-outline" tabindex="0">
                    {{ checkConnectionResult }}
                </div>
            </template>
        </q-field>
    </template>
    <SearchAddress :label="$translate('module-sensors.sensors.form.fields.location-address')"
                   :error="_.get(form.errors, 'sensor_location.address', '')"
                   @on-select="setAddress"
    />
    <div v-if="!_.isEmpty(form.sensor_location.address)">
        {{ $translate('module-sensors.sensors.form.fields.location-current-address') }}: {{ form.sensor_location.address }}
    </div>
    <div class="row q-gutter-sm">
        <div class="col">
            <q-select v-model="form.sensor_location.floor"
                      :label="$translate('module-sensors.sensors.form.fields.location-floor')"
                      :error="_.has(form.errors, 'sensor_location.floor')"
                      :error-message="_.get(form.errors, 'sensor_location.floor')"
                      :options="helpers.toOptions($translate('module-sensors.floors'))"
                      options-dense
                      map-options
                      emit-value
            />
        </div>
        <div class="col">
            <q-input v-model="form.sensor_location.room"
                     :label="$translate('module-sensors.sensors.form.fields.location-room')"
                     :error="_.has(form.errors, 'sensor_location.room')"
                     :error-message="_.get(form.errors, 'sensor_location.room')"
            />
        </div>
    </div>
    <q-select v-model="form.ssc_id"
              :label="$translate('module-sensors.sensors.form.fields.ssc_id')"
              @update:model-value="fetchSscUsers"
              :error="form.errors.hasOwnProperty('ssc_id')"
              :error-message="_.get(form.errors, 'ssc_id')"
              :options="ssc"
              map-options
              emit-value
    />
    <q-select v-if="form.ssc_id"
              v-model="form.sensor_users"
              :label="$translate('module-sensors.sensors.form.fields.sensor_users')"
              :loading="sscUsersLoading"
              :options="sscUsers"
              options-dense
              map-options
              emit-value
              use-chips
              multiple
    />
</template>

<script setup>
import {Address as SearchAddress} from "@/Components/Search";
import {ConnectionData as SensorConnectionData} from "@/Components/Sensors/Sensor";
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import axios from "axios";
import helpers from "@/helpers";

/**
 * @param InertiaForm form
 */
const form = defineModel({type: Object});
const props = defineProps({
    sensorGroups: Array,
    sensorTypes: Array,
    ssc: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const timeouts = [1, 2, 5, 10, 15, 30, 60];

const selectedConnection = ref(_.get(form.value.connection_data, 'id'));
const checkConnectionResult = ref(null);

const sscUsers = ref([]);
const sscUsersLoading = ref(true);
const fetchSscUsers = () => {
    axios.get(route('ajax.sensors'), {
        params: {
            m: 'getSscUsers',
            ssc_id: form.value.ssc_id
        }
    }).then((response) => {
        sscUsers.value = response.data.results;
    }).finally(() => {
        sscUsersLoading.value = false;
    });
};

const isJSON = (str) => (str.startsWith('{') || str.startsWith('[')) && (str.endsWith('}') || str.endsWith(']'));

const handleResponse = (responseArray) => {
    let result = [];
    _.forEach(responseArray, item => {
        let resultItem = {};
        resultItem.name = _.get(item, 'name');
        if (!_.isEmpty(_.get(item, 'error'))) {
            resultItem.error = _.get(item, 'error');
        } else {
            resultItem.lastvalue = _.get(item, 'lastvalue');
        }
        result.push(resultItem);
    });
    return result;
};

const onSelectedSensorType = () => {
    let selectedSensorType = _.find(props.sensorTypes, { value: form.value.sensor_type_id });
    if (_.isUndefined(selectedSensorType)) {
        selectedConnection.value = null;
    } else {
        selectedConnection.value = _.get(selectedSensorType, 'connection_data.id');
    }
};

const setConnectionData = (event) => {
    form.value.connection_data = event;
};
const setAddress = (event) => {
    form.value.sensor_location.address = event.value;
    form.value.sensor_location.detail = event.data;
};

const checkConnection = () => {
    axios.get(route('ajax.sensors'), {
        params: {
            m: 'checkConnection',
            sensor_type_id: form.value.sensor_type_id,
            connection_data: form.value.connection_data
        }
    }).then((response) => {
        checkConnectionResult.value = response.data.results;
    });
};

onMounted(() => {
    if (form.value.ssc_id) {
        fetchSscUsers();
    }
});
</script>
