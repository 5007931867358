<template>
    <Card v-if="isForApproving"
          :title="$translate('module-syexc.proposal.card.secretar_form.title.approving')"
          class="q-mt-md">
        <template v-slot:default>
            <q-select v-model="form.direction_id"
                      :label="$translate('module-syexc.proposal.card.secretar_form.fields.approving.direction_id')"
                      :error="form.errors.hasOwnProperty('direction_id')"
                      :error-message="_.get(form.errors, 'direction_id')"
                      :options="lists.directions"
                      options-dense
                      map-options
                      emit-value
            />
            <q-input v-model="form.conclusion_number"
                     :label="$translate('module-syexc.proposal.card.secretar_form.fields.approving.conclusion_number')"
                     :error="form.errors.hasOwnProperty('conclusion_number')"
                     :error-message="_.get(form.errors, 'conclusion_number')"
            />
            <q-input v-model="form.reg_number"
                     :label="$translate('module-syexc.proposal.card.secretar_form.fields.approving.reg_number')"
                     :error="form.errors.hasOwnProperty('reg_number')"
                     :error-message="_.get(form.errors, 'reg_number')"
            />
            <q-input v-model="form.secretar_comment"
                     :label="$translate('module-syexc.proposal.card.secretar_form.fields.approving.secretar_comment')"
                     type="textarea"
                     rows="3"
                     :error="form.errors.hasOwnProperty('secretar_comment')"
                     :error-message="_.get(form.errors, 'secretar_comment')"
            />
        </template>
        <template v-slot:actions>
            <q-btn :label="$translate('module-syexc.proposal.card.secretar_form.actions.approving.approve')"
                   @click="updateStatus(20)"
                   :loading="form.processing"
                   color="positive"
                   no-caps
            >
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
            <q-btn :label="$translate('module-syexc.proposal.card.secretar_form.actions.approving.decline')"
                   @click="updateStatus(29)"
                   :loading="form.processing"
                   color="negative"
                   no-caps
            >
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
        </template>
    </Card>
    <Card v-if="isForExpertApproving"
          :title="$translate('module-syexc.proposal.card.secretar_form.title.expert_approving')"
          class="q-mt-md"
    >
        <template v-slot:default>
            <q-input v-model="form.conclusion_number"
                     :label="$translate('module-syexc.proposal.card.secretar_form.fields.approving.conclusion_number')"
                     :error="form.errors.hasOwnProperty('conclusion_number')"
                     :error-message="form.errors.conclusion_number"
            />
            <ExpertConslusionDataForm :conclusion-number="form.conclusion_number"
                                      :conclusion="form.conclusion_data"
                                      :proposal="form.number"
                                      :country="lists.countries"
            >
                <template v-slot:download-link>
                    <a v-if="_.get(proposal, 'hasConclusion')"
                       class="text-brand cursor-pointer q-mt-sm"
                       :href="conclusionUrl"
                       target="_blank"
                    >
                        <q-icon name="o_file_download" size="sm"/>
                        {{
                            $translate('module-syexc.proposal.card.secretar_form.actions.identification_result_prepare.download')
                        }}
                    </a>
                </template>
            </ExpertConslusionDataForm>
            <div v-if="form.errors.hasOwnProperty('conclusion_data')" class="q-pl-md text-negative">
                {{ form.errors.conclusion_data }}
            </div>
            <q-input v-model="form.reg_number"
                     :label="$translate('module-syexc.proposal.card.secretar_form.fields.approving.reg_number')"
            />
            <q-input v-model="form.secretar_comment"
                     :label="$translate('module-syexc.proposal.card.secretar_form.fields.approving.secretar_comment')"
                     :error="form.errors.hasOwnProperty('secretar_comment')"
                     :error-message="form.errors.secretar_comment"
                     type="textarea"
                     rows="3"
            />
        </template>
        <template v-slot:actions>
            <q-btn :label="$translate('module-syexc.proposal.card.secretar_form.actions.expert_approving.save')"
                   :loading="form.processing"
                   @click="updateSecretar"
                   color="primary"
            >
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
            <q-btn :label="$translate('module-syexc.proposal.card.secretar_form.actions.expert_approving.send')"
                   @click="updateStatus(35)"
                   :loading="form.processing"
                   color="positive"
            >
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
            <q-btn :label="$translate('module-syexc.proposal.card.secretar_form.actions.expert_approving.return_to_expert')"
                   @click="updateStatus(25)"
                   :loading="form.processing"
                   color="negative"
            >
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
            <q-btn :label="$translate('module-syexc.proposal.card.secretar_form.actions.expert_approving.return_to_author')"
                   @click="updateStatus(29)"
                   :loading="form.processing"
                   color="negative"
                   class="q-mt-sm"
            >
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
        </template>
    </Card>
    <Card v-if="isForExpertResultAdjustment"
          :title="$translate('module-syexc.proposal.card.secretar_form.title.expert_result_adjustment')"
          class="q-mt-md"
    >
        <template v-slot:default>
            <q-input v-model="form.conclusion_number"
                     :label="$translate('module-syexc.proposal.card.secretar_form.fields.expert_result_adjustment.conclusion_number')"
                     :error="form.errors.hasOwnProperty('conclusion_number')"
                     :error-message="form.errors.conclusion_number"
            />
            <ExpertConslusionDataForm :conclusion-number="form.conclusion_number"
                                      :conclusion="form.conclusion_data"
                                      :proposal="form.number"
                                      :country="lists.countries"
            >
                <template v-slot:download-link>
                    <a v-if="_.get(proposal, 'hasConclusion')"
                       class="text-brand cursor-pointer q-mt-sm"
                       :href="conclusionUrl"
                       target="_blank"
                    >
                        <q-icon name="o_file_download" size="sm"/>
                        {{
                            $translate('module-syexc.proposal.card.secretar_form.actions.identification_result_prepare.download')
                        }}
                    </a>
                </template>
            </ExpertConslusionDataForm>
            <div v-if="form.errors.hasOwnProperty('conclusion_data')" class="q-pl-md text-negative">
                {{ form.errors.conclusion_data }}
            </div>
            <q-input v-model="form.secretar_comment"
                     :label="$translate('module-syexc.proposal.card.secretar_form.fields.expert_result_adjustment.secretar_comment')"
                     :error="form.errors.hasOwnProperty('secretar_comment')"
                     :error-message="form.errors.secretar_comment"
                     type="textarea"
                     rows="3"
            />
        </template>
        <template v-slot:actions>
            <q-btn :label="$translate('module-syexc.proposal.card.secretar_form.actions.expert_result_adjustment.save')"
                   :loading="form.processing"
                   @click="updateSecretar"
                   color="primary"
            >
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
            <q-btn :label="$translate('module-syexc.proposal.card.secretar_form.actions.expert_result_adjustment.send_to_repeated_agreement')"
                   @click="updateStatus(37)"
                   :loading="form.processing"
                   color="positive"
            >
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
        </template>
    </Card>
    <Card v-if="isForIdentificationResultPrepare"
          :title="$translate('module-syexc.proposal.card.secretar_form.title.identification_result_prepare')"
          class="q-mt-md"
    >
        <template v-slot:default>
            <q-input v-model="form.conclusion_number"
                     :label="$translate('module-syexc.proposal.card.secretar_form.fields.identification_result_prepare.conclusion_number')"
                     :error="form.errors.hasOwnProperty('conclusion_number')"
                     :error-message="form.errors.conclusion_number"
            />
            <ExpertConslusionDataForm :conclusion-number="form.conclusion_number"
                                      :conclusion="form.conclusion_data"
                                      :proposal="form.number"
                                      :country="lists.countries"
            >
                <template v-slot:download-link>
                    <a v-if="_.get(proposal, 'hasConclusion')"
                       class="text-brand cursor-pointer"
                       :href="conclusionUrl"
                       target="_blank"
                    >
                        <q-icon name="o_file_download" size="sm"/>
                        {{
                            $translate('module-syexc.proposal.card.secretar_form.actions.identification_result_prepare.download')
                        }}
                    </a>
                </template>
            </ExpertConslusionDataForm>
            <div v-if="form.errors.hasOwnProperty('conclusion_data')" class="q-pl-md text-negative">
                {{ form.errors.conclusion_data }}
            </div>
            <q-file v-model="form.conclusion_file"
                    :label="$translate('module-syexc.proposal.card.secretar_form.fields.identification_result_prepare.conclusion_file.title')"
                    :error="form.errors.hasOwnProperty('conclusion_file')"
                    :error-message="form.errors.conclusion_file"
            >
                <template v-slot:prepend>
                    <q-icon name="attach_file"/>
                </template>
            </q-file>
            <div v-if="_.get(proposal, 'conclusion_file')">
                <a :href="conclusionFileUrl" class="text-brand cursor-pointer" target="_blank">
                    <q-icon name="o_file_download" size="sm"/>
                    {{
                        $translate('module-syexc.proposal.card.expert_result.fields.conclusion.download_conclusion_file')
                    }}
                </a>
            </div>
            <q-input v-model="form.secretar_comment"
                     :label="$translate('module-syexc.proposal.card.secretar_form.fields.identification_result_prepare.secretar_comment')"
                     :error="form.errors.hasOwnProperty('secretar_comment')"
                     :error-message="form.errors.secretar_comment"
                     type="textarea"
                     rows="3"
            />
        </template>
        <template v-slot:actions>
            <q-btn :label="$translate('module-syexc.proposal.card.secretar_form.actions.identification_result_prepare.save')"
                   @click="updateStatus(39)"
                   :loading="form.processing"
                   color="primary"
            >
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
            <q-btn :label="$translate('module-syexc.proposal.card.secretar_form.actions.identification_result_prepare.success')"
                   @click="confirmConclusionDialog = !confirmConclusionDialog"
                   color="positive"
            />
            <q-dialog v-model="confirmConclusionDialog">
                <Card :title="$translate('module-syexc.proposal.card.secretar_form.modal.confirm_conclusion.title')">
                    <template v-slot:default>
                        <q-banner class="bg-positive text-white">
                            {{
                                $translate('module-syexc.proposal.card.secretar_form.modal.confirm_conclusion.success')
                            }}
                        </q-banner>
                    </template>
                    <template v-slot:actions>
                        <q-btn :label="$translate('module-syexc.proposal.card.secretar_form.modal.confirm_conclusion.cancel')"
                               color="negative"
                               v-close-popup
                        />
                        <q-btn :label="$translate('module-syexc.proposal.card.secretar_form.modal.confirm_conclusion.approve')"
                               @click="updateStatus(40)"
                               color="positive"
                        />
                    </template>
                </Card>
            </q-dialog>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import ExpertConslusionDataForm from "@/Components/Syexc/Proposal/Form/ExpertConslusionDataForm.vue";
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    proposal: {
        type: Object,
        required: true
    },
    lists: {
        type: Object,
        required: true
    },
    hasCommitteeResultLawyer: Boolean
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const conclusionUrl = computed(() => route('services.sys-exp-ctrl.proposal.get-file', {
    proposal: _.get(props.proposal, 'number'),
    type: 'conclusion'
}));
const conclusionFileUrl = computed(() => route('services.sys-exp-ctrl.proposal.get-file', {
    proposal: _.get(props.proposal, 'number'),
    type: 'conclusion_file'
}));

const isForApproving = computed(
        () => _.includes([10, 15, 22], Number(_.get(props.proposal, 'status')))
);
const isForExpertApproving = computed(
        () => 30 === Number(_.get(props.proposal, 'status'))
);
const isForExpertResultAdjustment = computed(
        () => 37 === Number(_.get(props.proposal, 'status')) && props.hasCommitteeResultLawyer
);
const isForIdentificationResultPrepare = computed(
        () => _.includes([38, 39], Number(_.get(props.proposal, 'status')))
);

const confirmConclusionDialog = ref(false);

const form = useForm({
    ...props.proposal,
    secretar_comment: null,
    comment_decline: null,
});

const updateStatus = (status) => {
    form.post(route('services.sys-exp-ctrl.proposal.update-status', {
        proposal: _.get(props.proposal, 'number'),
        status: status
    }));
};
const updateSecretar = () => {
    form.post(route('services.sys-exp-ctrl.proposal.update-secretar', {
        proposal: props.proposal
    }));
};
</script>
