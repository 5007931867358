<template>
    <q-select v-model="selected"
              option-value="id"
              :loading="loading"
              clearable
              clear-icon="clear"
              input-debounce="1000"
              :options="fetchedData"
              @filter="searchUser"
              :label="label ? label : $translate('search.user.label')"
              :hint="$translate('search.user.hint')"
              @update:model-value="(value) => $emit('onSelect', value)"
              use-input
              :error-message="error"
              :error="_.size(error)>0"
              :new-value-mode="allowCreate?'add':'toggle'"
              @new-value="createValue"
    >
        <template v-slot:option="option">
            <q-item v-bind="option.itemProps">
                <q-item-section>
                    <q-item-label v-html="option.opt.html"></q-item-label>
                </q-item-section>
                <q-item-section side v-if="option.opt.user.avatar">
                    <q-avatar :src="'/storage/'+option.opt.user.avatar"></q-avatar>
                </q-item-section>
            </q-item>
        </template>
    </q-select>
</template>

<script setup lang="ts">
import {getCurrentInstance, ref} from "vue";
import axios from "axios";
import _ from "lodash";

const props = defineProps({
    /**
     * Подпись к полю
     */
    label: {
        type: String,
        default: null
    },
    /**
     * Скрыть из результатов локальных пользователей
     */
    hideUser: {
        type: Boolean,
        default: false,
    },
    /**
     * Показывать в результатах пользователей из LDAP
     */
    withLdap: {
        type: Boolean,
        default: false,
    },
    /**
     * Показывать в результатах выключенных пользователей
     */
    withUnchecked: {
        type: Boolean,
        default: false,
    },
    /**
     * Показывать в результатах внешних пользователей
     */
    withExternal: {
        type: Boolean,
        default: false,
    },
    /**
     * Разрешить создание
     */
    allowCreate: {
        type: Boolean,
        default: false,
    },
    filters: {
        type: Array,
        required: false,
        default: []
    },
    error: {
        type: String,
        default: null
    }
});
const emit = defineEmits(['onSelect']);

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

const selected = ref(null);
const fetchedData = ref([]);
const loading = ref(false);

const searchUser = (search: string | null, update: any) => {
    if (search === '') {
        if (_.size(fetchedData.value) > 0) {
            // already loaded
            update()
            return
        }
        update(() => {
            fetchedData.value = [];
        });
        return
    }
    update(() => {
        let params = {
            m: 'search',
            q: search,
            hideuser: props.hideUser,
            withldap: props.withLdap,
            withScienceExternal: props.withExternal,
            unchecked: props.withUnchecked,
        };
        loading.value = true;
        axios.get($route('ajax.users', params))
                .then((response) => {
                    fetchedData.value = response.data.results;
                })
                .finally(() => {
                    loading.value = false;
                });
    });
};

const createValue = (val: string, done: any) => {
    done({
        id: val,
        label: val
    })
};
</script>
