<template>
    <Card :title="$translate('module-archive.project.card.expectedresult.title')" class="q-mb-md" hide-actions>
        <template v-slot:default>
            <template v-for="(description, property) in $translate('module-archive.project.expected-results')"
                      :key="property"
            >
                <div v-if="!_.isNull(_.get(expectedResults, property))" class="row q-py-xs">
                    <div class="col-10">{{ description }}</div>
                    <div class="col-14">{{ _.get(expectedResults, property) }}</div>
                </div>
            </template>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";

const props = defineProps({
    expectedResults: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
