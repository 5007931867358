<template>
    <q-field :label="$translate('project.view.source')" stack-label borderless>
        <template v-slot:control>
            <div class="row self-center full-width items-center">
                <span>{{ _.get(project.source, 'name') }}</span>
                <q-icon v-if="!_.isEmpty(project.source.source_details_for_project_card) || !_.isEmpty(project.source.portal_url_from_source)"
                        name="o_quiz"
                        size="xs"
                        class="q-ml-sm cursor-pointer"
                        @click="sourceDetail = true"/>
            </div>
        </template>
    </q-field>
    <q-dialog v-model="sourceDetail">
        <Card :title="$translate('project.view.source-detail') + ' ' + _.get(project.source, 'name')"
              show-close-button actions-align="stretch">
            <template v-for="(v,k) in project.source.source_details_for_project_card">
                <q-field :label="k" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">{{ v }}</div>
                    </template>
                </q-field>
            </template>
            <template v-slot:actions>
                <q-btn v-if="project.source.portal_url_from_source"
                       @click="updateDataFromSource"
                       icon="refresh"
                       :label="$translate('project.actions.source-reload')"
                       no-caps class="q-mr-auto"/>

                <q-btn v-if="project.source.portal_url_from_source"
                       :href="project.source.portal_url_from_source" target="_blank"
                       icon="open_in_new"
                       :label="$translate('project.actions.source-goto')"
                       no-caps/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import _ from 'lodash'
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps(['project'])

const sourceDetail = ref(false)

const updateDataFromSource = () => {
    router.patch(route('project.sync-source', props.project), {}, {
        onSuccess: () => {
            sourceDetail.value = false
            router.reload()
        }
    })
}
</script>