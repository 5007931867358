<template>
    <Card title-bg="indigo-2"
          title-text="indigo-10"
          :title="$translate('module-microcollections.type.form.title.edit')"
          :header-toolbar="can.back"
    >
        <template v-if="can.back" v-slot:headerActions>
            <q-btn :label="$translate('buttons.back')"
                   @click="router.get($route('services.microcollections.type.index'))"
                   icon="o_chevron_left"
                   color="indigo-5"
                   no-caps
                   dense
            />
        </template>
        <MicroCollectionTypeForm v-model="form"/>
        <template v-slot:actions>
            <q-btn :label="$translate('buttons.save')" @click="submitForm" color="positive"/>
        </template>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {Form as MicroCollectionTypeForm} from "@/Components/MicroCollections/Type";
import {getCurrentInstance} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    type: {
        type: Object,
        default(rawProps) {
            return {
                name: '',
                description_i18n: {ru: '', en: ''},
                public_state: true
            };
        }
    },
    can: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const form = useForm({
    name: _.get(props.type, 'name'),
    description_i18n: _.get(props.type, 'description_i18n'),
    public_state: _.get(props.type, 'public_state')
});

const submitForm = () => {
    form.post(route('services.microcollections.type.update', props.type));
};
</script>
