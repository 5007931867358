<template>
    <HasChanges v-if="projectType.isDirty"/>
    <div class="row q-gutter-md">
        <Card :title="$translate('project-type.label.common-fields')" class="col" no-card-section hide-actions>
            <q-card-section>
                <q-item-label overline class="text-black">
                    {{ $translate('project-type.fields.name') }}
                </q-item-label>
                <template v-for="(name,lang) in $translate('fields.locale')">
                    <q-input :label="name" stack-label v-model="projectType.name_i18n[lang]"
                             :error="errors.hasOwnProperty('name_i18n.'+lang)"
                             :error-message="_.get(errors,'name_i18n.'+lang)"/>
                </template>
            </q-card-section>
            <q-card-section>
                <q-item-label overline class="text-black">
                    {{ $translate('project-type.fields.description') }}
                </q-item-label>
                <template v-for="(name,lang) in $translate('fields.locale')">
                    <q-input type="textarea" rows='3' :label="name" stack-label
                             v-model="projectType.description_i18n[lang]"
                             :error="errors.hasOwnProperty('description_i18n.'+lang)"
                             :error-message="_.get(errors,'description_i18n.'+lang)"/>
                </template>
            </q-card-section>
            <q-card-section>
                <q-item-label overline class="text-black">
                    {{ $translate('project-type.fields.members') }}
                </q-item-label>
                <q-toggle v-model="projectType.unlimited"
                          @update:model-value="triggerUnlim"
                          :label="$translate('project-type.fields.members-unlimited')"
                          size="sm"/>
                <q-input type="number" min="1" style="width: 300px"
                         v-if="!projectType.unlimited"
                         v-model="projectType.members"
                         input-class="text-center"
                         :prefix="$translate('project-type.fields.members-limit')">

                </q-input>
            </q-card-section>
        </Card>
        <div class="col">
            <FieldsConstructor v-if="parent"
                               :title="$translate('project-type.label.parent-fields')"
                               v-model="parent.fields_inherit"
                               readonly
            />

            <div class="q-py-xs" v-if="parent"/>

            <FieldsConstructor :title="$translate('project-type.label.addon-fields')"
                               v-model="projectType.fields"
                               @update:items="updateFields"
                               unique-source
            />
        </div>
    </div>
    <div class="row q-gutter-md q-mt-sm">
        <Card :title="$translate('project-type.label.characters')" class="col" hide-actions>
            <q-select v-model="projectType.character.base"
                      :label="$translate('project-type.fields.character_base')"
                      :options="options.projecttype"
                      stack-label options-dense map-options emit-value multiple use-chips
                      :error="errors.hasOwnProperty('character_base')"
                      :error-message="_.get(errors,'character_base')"/>

            <q-select v-model="projectType.character.additional"
                      :label="$translate('project-type.fields.character_additional')"
                      :options="_.filter(options.projecttype,(item)=>(!projectType.character.base.includes(item.id)))"
                      stack-label options-dense map-options emit-value multiple use-chips
                      :error="errors.hasOwnProperty('character_additional')"
                      :error-message="_.get(errors,'character_base')"/>
        </Card>
        <Card :title="$translate('project-type.label.expected-result')" class="col" hide-actions>
            <template v-for="(expectedResult) in options.expectedresult">
                <div>
                    <q-checkbox v-model="projectType.expected_result"
                                :val="expectedResult.slug"
                                :label="expectedResult.label"
                                size="sm"
                    />
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import {useQuasar} from "quasar";
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch} from "vue";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

import Card from "@/Components/Block/Card.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import FieldsConstructor from "@/Components/Fields/FieldsConstructor.vue";
import HasChanges from "@/Components/Block/HasChanges.vue";

defineProps(['errors'])

const projectType = defineModel({
    type: Object,
    default() {
        return {
            id: null,
            name_i18n: {},
            description_i18n: {},
            unlimited: true,
            members: 1,
            character: {
                base: [],
                additional: []
            },
            expected_result: [],
            fields: []
        }
    }
})
const emit = defineEmits(['update:projectType'])

// const projectType = ref({
//     id: null,
//     name_i18n: {},
//     description_i18n: {},
//     unlimited: true,
//     members: 1,
//     character: {
//         base: [],
//         additional: []
//     },
//     expected_result: [],
//     fields: []
// })

const options = ref({
    projecttype: [],
    expectedresult: [],
})
const loadProjectTypeOptions = () => {
    axios.get('/classificator.json', {
        params: {
            m: 'loadOptions',
            class: 'projecttype',
        }
    })
        .then((response) => {
            _.set(options.value, 'projecttype', response.data.results);
        })
        .finally(() => {
        })
}

const loadExpectedResultOption = () => {
    axios.get('/classificator.json', {
        params: {
            m: 'loadOptions',
            class: 'expectedresult',
        }
    })
        .then((response) => {
            _.set(options.value, 'expectedresult', response.data.results);
        })
        .finally(() => {
        })
}

// const oldProjectType = computed(() => usePage().props.projectType)
const parent = computed(() => usePage().props.parent)

const triggerUnlim = (val) => {
    if (!val) {
        projectType.members = 1
    } else {
        projectType.members = -1
    }
}
onBeforeMount(() => {

    loadProjectTypeOptions()
    loadExpectedResultOption()

    // if (oldProjectType.value) {
    //     projectType.value.id = _.get(oldProjectType.value, 'id');
    //     projectType.value.parent_id = _.get(oldProjectType.value, 'parent_id');
    //     projectType.value.name_i18n = _.get(oldProjectType.value, 'name_i18n');
    //     projectType.value.description_i18n = _.get(oldProjectType.value, 'description_i18n');
    //     projectType.value.members = _.get(oldProjectType.value, 'members');
    //     projectType.value.character.base = _.map(_.get(oldProjectType.value, 'character.base'), Number);
    //     projectType.value.character.additional = _.map(_.get(oldProjectType.value, 'character.additional'), Number);
    //     projectType.value.expected_result = _.get(oldProjectType.value, 'expected_result');
    //     projectType.value.fields = _.get(oldProjectType.value, 'fields');
    // }
    // projectType = useForm(projectType.value)
})

onMounted(() => {
    emit('update:projectType', projectType.value)
})

watch(projectType, () => {
    emit('update:projectType', projectType.value)
})
const updateFields = (fields) => {
    emit('update:projectType', projectType.value)
}
</script>