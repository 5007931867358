<template>
    <Card :title="$translate('module-archive.project.card.expenses.title')" class="q-mb-md" hide-actions>
        <template v-slot:default>
            <div class="row q-py-xs">
                <div class="col-10">{{ $translate('module-archive.project.card.expenses.fields.fin_amount') }}</div>
                <div class="col-14">
                    <span v-if="!_.isEmpty(_.get(projectFin, 'pure_fin'))">
                        {{
                            helpers.formatMoney(_.get(projectFin, 'pure_fin.fin_amount', 0))
                        }} ({{
                            _.get(projectFin, 'pure_fin.fin_name')
                        }})
                    </span>
                    <span v-else class="text-negative">{{ $translate('common.not_available') }}</span>
                </div>
            </div>
            <div class="row q-py-xs">
                <div class="col-10">{{ $translate('module-archive.project.card.expenses.fields.max_cost') }}</div>
                <div class="col-14">
                    {{ helpers.formatMoney(_.get(projectFin, 'project_max_cost', 0)) }}
                </div>
            </div>
            <div class="row q-py-xs">
                <div class="col-10">{{ $translate('module-archive.project.card.expenses.fields.current_cost') }}</div>
                <div class="col-14">
                    {{ helpers.formatMoney(_.get(projectFin, 'project_current_cost', 0)) }}
                </div>
            </div>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";
import helpers from "@/helpers";

const props = defineProps({
    projectFin: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
