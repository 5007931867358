<template>
    <Card :title="title" title-text="white" title-bg="cyan-14"
          header-toolbar no-card-section hide-actions>
        <template v-slot:headerActions>
            <div class="q-gutter-x-sm">
                <q-btn v-if='can.index'
                       :label="$translate('users.actions.index')"
                       @click="router.get($route('admin.users.index'))"
                       color="cyan-9"
                       icon="format_list_bulleted"
                       dense no-caps/>
                <q-btn v-if='can.create'
                       :label="$translate('users.actions.add')"
                       @click="createDialog = true"
                       color="cyan-9"
                       icon="add"
                       dense no-caps/>
                <q-btn v-if='can.export'
                       :label="$translate('users.actions.export')"
                       @click="router.get($route('admin.users.export'))"
                       color="cyan-9"
                       icon="import_export"
                       dense no-caps/>
                <q-btn v-if='can.import'
                       :label="$translate('users.actions.import')"
                       @click="router.get($route('admin.users.import'))"
                       color="cyan-9"
                       icon="import_export"
                       dense no-caps/>
                <q-btn v-if='can.merge'
                       :label="$translate('users.actions.merge')"
                       @click="router.get($route('admin.users.merge'))"
                       color="cyan-9"
                       icon="merge_type"
                       dense no-caps/>
            </div>
        </template>
        <slot/>
    </Card>
    <q-dialog v-model="createDialog" no-backdrop-dismiss>
        <UserCreateForm @on-success="onSuccess"/>
    </q-dialog>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, ref, ComputedRef} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import UserCreateForm from "@/Components/User/UserCreateForm.vue";
import Card from "@/Components/Block/Card.vue";

interface Permission {
    index: boolean,
    create: boolean,
    import: boolean,
    export: boolean,
    merge: boolean
}

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

const can: any = computed(() => usePage().props.can)

const tableRef = ref()

const createDialog = ref(false)

defineProps({
    title: null
})
const onSuccess = () => {
    createDialog.value = false
}
</script>