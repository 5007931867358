<template>
    <q-btn icon="o_sync"
           :label="$translate('cadre-department.actions.sync-external')"
           color="blue-grey-6"
           no-caps
           dense
           @click="dialog = true"/>
    <q-dialog v-model="dialog" no-backdrop-dismiss>
        <Card :title="$translate('cadre-department.title.sync-external')"
              header-toolbar
              in-modal show-close-button no-card-section>
            <Alert variant="info"
                   :message="$translate('cadre-department.info.sync-external')"/>
            <template v-slot:actions>
                <q-btn icon="o_sync"
                       :label="$translate('buttons.syncexternal')"
                       color="positive"
                       @click="syncExternal"/>
                <q-btn icon="o_close"
                       :label="$translate('buttons.close')"
                       color="dark"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import {Card, Alert} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

const dialog = ref(false)
const syncExternal = () => {
    router.get($route('cadre.department.sync.external'), {}, {
        onSuccess: () => {
            dialog.value = false
        }
    })
}
</script>