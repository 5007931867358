<template>
    <q-table ref="tableRef"
             :columns="columns"
             :rows="rows"
             :loading="loading"
             :pagination="{rowsPerPage: 0}"
             :rows-per-page-options="[0]"
             hide-pagination
             wrap-cells
             bordered
             flat
    >
        <template v-slot:body-cell-type="props">
            <q-td :props="props">
                <div v-html="props.value"></div>
            </q-td>
        </template>
        <template v-slot:body-cell-current_value="props">
            <q-td :props="props">
                <div v-html="props.value"></div>
            </q-td>
        </template>
        <template v-slot:bottom-row="props">
            <q-tr :props="props">
                <q-td colspan="100%">
                    <span class="text-grey">Значение является средним арифметическим за единицу времени (минута за сутки, час за 3/7 дней, день за год)</span>
                </q-td>
            </q-tr>
        </template>
    </q-table>
    <div class="row items-center q-mt-sm">
        <div class="col-4">
            <q-select v-model="filterFrom"
                      :options="presets"
                      @update:model-value="selectPreset"
                      options-dense
                      map-options
                      emit-value
                      outlined
                      dense
            />
        </div>
        <div class="col-20">
            <div class="row justify-end items-center q-gutter-sm">
                <div class="th-like-label">
                    {{ _.upperFirst($translate('common.page-reload.refresh')) }}
                </div>
                <q-select v-model="autoRefresh"
                          :options="autoRefreshOptions"
                          options-dense
                          map-options
                          emit-value
                          outlined
                          dense
                />
                <q-btn @click="fetchMeasurements" color="secondary" icon="refresh" dense>
                    <q-tooltip class="text-body2">
                        {{ $translate('module-sensors.sensors.card.actions.refresh') }}
                    </q-tooltip>
                </q-btn>
            </div>
        </div>
    </div>
    <SensorMeasurementsChart :sensor="sensor" :data="formattedData"/>
</template>

<script setup>
import {MeasurementsChart as SensorMeasurementsChart} from "@/Components/Sensors/Sensor";
import {computed, getCurrentInstance, onBeforeMount, onMounted, onUnmounted, ref, watch} from "vue";
import _ from "lodash";
import axios from "axios";
import moment from "moment";

const props = defineProps({
    sensor: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const tableRef = ref();
const loading = ref(true);
const rows = ref([]);
const columns = ref([
    {name: 'type', label: $translate('module-sensors.sensors.card.measurements.fields.type'), field: 'type'},
    {name: 'min_max', label: $translate('module-sensors.sensors.card.measurements.fields.min_max'), field: 'min_max'},
    {
        name: 'allowable_min_max',
        label: $translate('module-sensors.sensors.card.measurements.fields.allowable_min_max'),
        field: 'allowable_min_max'
    },
    {
        name: 'current_value',
        label: $translate('module-sensors.sensors.card.measurements.fields.current_value'),
        field: 'current_value'
    },
]);

const autoRefreshTimer = ref();
const autoRefresh = ref(30);
const autoRefreshOptions = [{label: $translate('common.page-reload.disabled'), value: 0}];
_.forEach([15, 30, 60, 120, 180, 300], item => {
    autoRefreshOptions.push({
        label: item >= 60 ?
            `${(item / 60)} ${$translate('common.page-reload.min')}` :
            `${item} ${$translate('common.page-reload.sec')}`,
        value: item
    });
});

const f = (m) => m.format($translate('common.datetime.js'));

const presets = ref([
    {label: 'За последние 3 часа', value: f(moment().startOf('hour').subtract(3, 'hours'))},
    {label: 'За последние 6 часов', value: f(moment().startOf('hour').subtract(6, 'hours'))},
    {label: 'За последние 12 часов', value: f(moment().startOf('hour').subtract(12, 'hours'))},
    {label: 'За текущий день', value: f(moment().startOf('day'))},
    {label: 'За последние 3 дня', value: f(moment().startOf('day').subtract(3, 'days'))},
    {label: 'За последние 7 дней', value: f(moment().startOf('day').subtract(7, 'days'))},
    {label: 'За год', value: f(moment().startOf('day').subtract(1, 'years'))}
]);
const filterFrom = ref(f(moment().startOf('hour').subtract(3, 'hours')));

const measurements = ref([]);

const fetchMeasurements = () => {
    measurements.value = [];
    rows.value = [];
    loading.value = true;
    axios.get(route('ajax.sensors'), {
        params: {
            m: 'getMeasurements',
            sensor_id: props.sensor.id,
            from: filterFrom.value,
            to: f(moment())
        }
    }).then(response => {
        measurements.value = response.data.results;
        console.log(`Measurements fetched (${_.size(measurements.value)})`);
        fillTableRows();
    }).catch(error => {
        console.log(error);
    });
};

const selectPreset = () => {
    fetchMeasurements();
    clearInterval(autoRefreshTimer.value);
    if (autoRefresh.value > 0) {
        autoRefreshTimer.value = setInterval(fetchMeasurements, autoRefresh.value * 1000);
    }
};

const getMinData = (allowableMeasurement) => {
    let grouped = _.groupBy(measurements.value, 'measurement_type_id'),
        finded = _.filter(
            grouped,
            item => _.isEqual(
                Number(_.head(item).measurement_type_id),
                Number(allowableMeasurement.measurement_type_id)
            )
        );
    return _.minBy(_.head(finded), 'value');
};
const getMaxData = (allowableMeasurement) => {
    let grouped = _.groupBy(measurements.value, 'measurement_type_id'),
        finded = _.filter(
            grouped,
            item => _.isEqual(
                Number(_.head(item).measurement_type_id),
                Number(allowableMeasurement.measurement_type_id)
            )
        );
    return _.maxBy(_.head(finded), 'value');
};
const getCurrentData = (allowableMeasurement) => {
    let grouped = _.groupBy(measurements.value, 'measurement_type_id'),
        finded = _.filter(
            grouped,
            item => _.isEqual(
                Number(_.head(item).measurement_type_id),
                Number(allowableMeasurement.measurement_type_id)
            )
        );
    return _.last(_.orderBy(_.head(finded), 'created_at'));
};
const fillTableRows = () => {
    if (!_.isEmpty(measurements.value)) {
        if (props.sensor.hasOwnProperty('sensor_group') && props.sensor.sensor_group.hasOwnProperty('allowable_measurements')) {
            _.forEach(props.sensor.sensor_group.allowable_measurements, (allowableMeasurement) => {
                let min = getMinData(allowableMeasurement),
                    max = getMaxData(allowableMeasurement),
                    current = getCurrentData(allowableMeasurement),
                    currentValue = current.value;
                if (Number(current.value) >= Number(allowableMeasurement.max_value)) {
                    currentValue = `<span class="q-badge bg-negative text-white">${current.value}</span>`;
                }
                if (Number(current.value) <= Number(allowableMeasurement.min_value)) {
                    currentValue = `<span class="q-badge bg-orange-14">${current.value}</span>`;
                }
                rows.value.push({
                    type: `<span class="text-no-wrap">${_.get(allowableMeasurement, 'measurement_type_name')}</span>`,
                    min_max: `${min.value} / ${max.value}`,
                    allowable_min_max: `${allowableMeasurement.min_value} / ${allowableMeasurement.max_value}`,
                    current_value: currentValue
                });
            });
        }
    }
    loading.value = false;
};

const handleLeakValue = (measurementValue) => {
    let leakValuesMapping = {0: 'Есть протечка', 1: 'Нет протечки'};
    return [0, 1].includes(Number(measurementValue)) ?
        ` (${_.get(leakValuesMapping, Number(measurementValue))})` :
        ''
};

const formattedData = computed(() => {
    let data = [], groupedMeasurements = _.groupBy(measurements.value, 'measurement_type_id');
    _.forEach(groupedMeasurements, (group) => {
        let info = {}, formattedGroupData = _.map(group, (measurement) => ({
            date: moment(_.get(measurement, 'created_at')).toDate().getTime(),
            value: Number(measurement.value),
            descr: handleLeakValue(measurement.value)
        }));
        info.name = _.get(_.head(group), 'measurement_type_name');
        data.push({
            data: formattedGroupData,
            info: info
        });
    });
    return data;
});

watch(autoRefresh, () => {
    clearInterval(autoRefreshTimer.value);
    if (autoRefresh.value > 0) {
        autoRefreshTimer.value = setInterval(fetchMeasurements, autoRefresh.value * 1000);
    }
});

onBeforeMount(() => {
    fetchMeasurements();
});
onMounted(() => {
    autoRefreshTimer.value = setInterval(fetchMeasurements, autoRefresh.value * 1000);
});
onUnmounted(() => {
    clearInterval(autoRefreshTimer.value);
});
</script>

<style scoped>
.th-like-label {
    font-size: 12px;
    font-weight: 700
}
</style>
