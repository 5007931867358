<template>
    <Card :title="$translate('project-type.title-edit')+': '+_.get(projectTypeForm,'name')"
          title-bg="blue-grey-3"
          header-toolbar>
        <template v-slot:headerActions>
            <q-btn icon="navigate_before"
                   :label="$translate('buttons.back')"
                   @click="router.get($route('admin.project-type.index'))"
                   color="blue-grey-5"
                   dense no-caps/>
        </template>

        <ProjectTypeForm v-model="projectTypeForm"
                         :errors="projectTypeForm.errors"/>

        <template v-slot:actions>
            <q-btn icon="preview"
                   :label="$translate('buttons.preview')"
                   color="info"
                   @click="openPreviewModal"/>

            <q-btn icon="save"
                   :label="$translate('buttons.save')"
                   color="positive"
                   @click="saveProjectType"/>

        </template>
    </Card>
    <q-dialog v-model="previewModal" full-width full-height>
        <Card :title="$translate('project-type.title-preview')"
              hide-actions show-close-button>
            <ProjectStepMainInfo :project="projectPreview"/>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from 'lodash'
import {useQuasar} from "quasar";
import ProjectTypeForm from "@/Components/ProjectType/ProjectTypeForm.vue";
import ProjectStepMainInfo from "@/Components/Project/CreateProjectSteps/MainInfo.vue";
import {Card} from "@/Components/Block";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const projectTypeForm = useForm(() => usePage().props.projectType)

const previewModal = ref(false)

const projectPreview = ref({})

const saveProjectType = () => {
    projectTypeForm
            .transform((data)=>({
                ...data,
                unlimited: data.unlimited_members
            }))
        .post(route('admin.project-type.update', projectTypeForm.id), {
            onSuccess: () => {
                router.get(route('admin.project-type.index'))
            }
        })
}

const openPreviewModal = () => {
    _.set(projectPreview.value, 'type', projectTypeForm)
    _.set(projectPreview.value.type, 'fields_inherit', _.assign({}, _.get(projectTypeForm.parent, 'fields'), projectTypeForm.fields));
    _.set(projectPreview.value, 'errors', {})
    previewModal.value = true;
}

onMounted(() => {
    _.set(projectTypeForm, 'unlimited', projectTypeForm.unlimited_members)
})
</script>