<template>
    <Card :title="$translate('project-type.title-create')" title-bg="blue-grey-3" header-toolbar>
        <template v-slot:headerActions>
            <q-btn icon="navigate_before"
                   :label="$translate('buttons.back')"
                   @click="router.get($route('admin.project-type.index'))"
                   color="blue-grey-5"
                   dense no-caps/>
        </template>

        <ProjectTypeForm v-model="projectTypeForm"
                         :errors="projectTypeForm.errors"/>

        <template v-slot:actions>
            <q-btn icon="preview"
                   :label="$translate('buttons.preview')"
                   color="info"
                   @click="openPreviewModal"/>

            <q-btn icon="save"
                   :label="$translate('buttons.add')"
                   color="positive"
                   @click="addProjectType"/>
        </template>
    </Card>

    <q-dialog v-model="previewModal" full-width full-height>
        <Card :title="$translate('project-type.title-preview')"
              hide-actions show-close-button>
            <ProjectStepMainInfo :project="projectPreview"/>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from 'lodash'
import {useQuasar} from "quasar";
import ProjectTypeForm from "@/Components/ProjectType/ProjectTypeForm.vue";
import ProjectStepMainInfo from "@/Components/Project/CreateProjectSteps/MainInfo.vue";
import {Card} from "@/Components/Block";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const projectTypeForm = useForm({
    id: null,
    name_i18n: {},
    description_i18n: {},
    unlimited: true,
    members: 1,
    character: {
        base: [],
        additional: []
    },
    expected_result: [],
    fields: []
})

const projectType = ref({})

const props = defineProps(['parent_id'])

const previewModal = ref(false)

const projectPreview = ref({})

const updateProjectType = (data) => {
    projectType.value = data
}

const addProjectType = () => {
    projectTypeForm
        .transform((data) => ({
            ...data,
            ...projectType.value,
            parent_id: props.parent_id
        }))
        .post(route('admin.project-type.store'), {
            onSuccess: () => {
                router.get(route('admin.project-type.index'))
            }
        })
}

const openPreviewModal = () => {
    _.set(projectPreview.value, 'type', projectType.value)
    _.set(projectPreview.value.type, 'fields_inherit', _.assign({}, usePage().props.parent.field, projectType.value.fields));
    _.set(projectPreview.value, 'errors', {})
    previewModal.value = true;
}
</script>