<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions>
        <q-select v-model="reviewing"
                  :options="options"
                  options-dense
                  map-options
                  emit-value
                  dense
                  :error="errors.hasOwnProperty('reviewing')"
                  :error-message="_.get(errors,'reviewing')">
        </q-select>
    </Card>
</template>

<script setup>
/**************************************************
 *
 * Рецензирование
 *
 ***************************************************/
import {computed, getCurrentInstance} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object},
})

const reviewing = defineModel('reviewing')
const errors = defineModel('errors', {type: Object})

const options = computed(() => _.sortBy(_.map($translate('report-type.reviewing'), (v, k) => ({
    value: k,
    label: v
})), 'label'))

</script>