<template>
    <div class="row q-gutter-x-md">
        <Card :title="$translate('menu.analytics.standard')" title-bg="deep-purple-2" class="col"
              no-card-section hide-actions header-toolbar>
            <q-list separator>
                <template v-for="(name, report) in $translate('module-analytics.reports')">
                    <q-item v-if="services.analytics.items.standard_reports[report]"
                            @click="router.visit($route('analytics.report.standard.list', {'report': report}))"
                            clickable
                    >
                        <q-item-section class="q-pl-md">
                            {{ name }}
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </Card>
        <Card :title="$translate('menu.analytics.charts')" title-bg="deep-purple-2" class="col"
              no-card-section hide-actions header-toolbar>
            <q-list separator>
                <template v-for="(name, report) in $translate('module-analytics.chart_simple')">
                    <q-item v-if="services.analytics.items.charts[report]"
                            @click="router.visit($route('analytics.chart_simple', {'context': report}))"
                            clickable
                    >
                        <q-item-section class="q-pl-md">
                            {{ name }}
                        </q-item-section>
                    </q-item>
                </template>
                <template v-for="(item, key) in $translate('module-analytics.chart_extended')" :key="key">
                    <q-item v-if="services.analytics.items.charts[key]"
                            @click="router.visit($route('analytics.chart_extended', {'context': key}))"
                            clickable
                    >
                        <q-item-section class="q-pl-md">
                            {{ item.title }}
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </Card>
    </div>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const page = usePage();
const services = computed(() => page.props.services);
</script>
