<template>

</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
</script>

<style scoped>

</style>