<template>
    <Card :title="$translate('user-skill.title.my')"
          header-toolbar hide-actions no-card-section>
        <template v-slot:headerActions>
            <q-btn icon="o_add"
                   :label="$translate('user-skill.actions.add')"
                   @click="addDialog = true"
            />
        </template>
        <q-list separator>
            <template v-for="(skill) in skills">
                <UserSkillItem :skill="skill"/>
            </template>
        </q-list>
    </Card>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('user-skill.title.new')"
              in-modal show-close-button header-toolbar>
            <UserSkillForm v-model="addForm" :type="type"/>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.add')"
                       color="positive"
                       @click="submitAddForm"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {UserSkill} from '@/entity'
import {Card} from "@/Components/Block";
import UserSkillForm from "@/Components/User/Skill/UserSkillForm.vue";
import UserSkillItem from "@/Components/User/Skill/UserSkillItem.vue";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

interface Props {
    skills: UserSkill,
    type: []
}

const props = defineProps<Props>()

const addDialog = ref(false)

const addForm = useForm({
    name: null,
    description: null,
    type_id: null,
    file: null,
})

const submitAddForm = () => {
    addForm.post($route('profile.skill.store'), {
        onSuccess: () => {
            addDialog.value = false;
            addForm.reset()
            router.reload()
        }
    })
}
</script>