<template>
    <div v-if="data.module.pure.enabled">
        <q-tabs v-model="tab" align="left" dense no-caps>
            <q-tab name="base" :label="$translate('module-pure.base-settings.name')"/>
            <q-tab name="db" label="База данных"/>
            <q-tab name="application-types" :label="$translate('module-pure.mapping-application-types.name')"/>
            <q-tab name="report-materials-types" :label="$translate('module-pure.mapping-publication-types.name')"/>
            <q-tab name="award-roles" label="Сопоставления ролей участников грантов"/>
            <q-tab name="fin-orgs" label="Финансирование"/>
        </q-tabs>
        <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="base">
                <Alert v-if="$translate('module-pure.description')"
                       variant="info"
                       :message="$translate('module-pure.description')"
                />
                <q-tabs v-model="baseTab" align="left" dense no-caps>
                    <q-tab name="main" label="Настройки доступа" />
                    <q-tab name="projects" label="Создание проектов" />
                    <q-tab name="filtering"
                           :label="$translate('module-pure.settings.PURE_AWARDS_SEARCH_FILTERING_RULES')"
                    />
                </q-tabs>
                <q-tab-panels v-model="baseTab" animated>
                    <q-tab-panel name="main">
                        <SettingsTable :data="config.base.main"/>
                    </q-tab-panel>
                    <q-tab-panel name="projects">
                        <SettingsTable :data="config.base.projects"/>
                    </q-tab-panel>
                    <q-tab-panel name="filtering">
                        <SettingsTable :data="config.base.filtering"/>
                    </q-tab-panel>
                </q-tab-panels>
            </q-tab-panel>
            <q-tab-panel name="db">
                <Alert variant="info" message="Настройки подключения к БД Pure"/>
                <SettingsTable :data="config.db"/>
            </q-tab-panel>
            <q-tab-panel name="application-types">
                <Alert v-if="$translate('module-pure.mapping-application-types.description')"
                       variant="info"
                       :message="$translate('module-pure.mapping-application-types.description')"
                />
                <SettingsMapping :url="$route('ajax.pure', {'m': 'getApplicationTypes'})"
                                 name="PURE_APPLICATION_TYPES_ASSOCIATION"
                                 block="module"
                                 :data="helper.jsonParse(settings.module.PURE_APPLICATION_TYPES_ASSOCIATION)"
                                 :list="data.module.pure.applicationTypes"
                                 :multiple="true"
                />
            </q-tab-panel>
            <q-tab-panel name="report-materials-types">
                <Alert v-if="$translate('module-pure.mapping-publication-types.description')"
                       variant="info"
                       :message="$translate('module-pure.mapping-publication-types.description')"
                />
                <q-tabs v-model="rmTab" align="left" dense no-caps>
                    <q-tab v-for="(label, type) in rmTabs" :key="type" :name="type" :label="label"/>
                </q-tabs>
                <q-tab-panels v-model="rmTab" animated>
                    <q-tab-panel v-for="(label, type) in rmTabs" :key="type" :name="type">
                        <SettingsMapping :url="$route('ajax.pure', {'m': 'get' + _.upperFirst(type) + 'Types'})"
                                         :name="'PURE_' + _.toUpper(type) + '_TYPES_ASSOCIATION'"
                                         block="module"
                                         :data="helper.jsonParse(settings.module['PURE_' + _.toUpper(type) + '_TYPES_ASSOCIATION'])"
                                         :list="data.module.pure.reportMaterialTypes[rmCategories[type]]"
                                         :multiple="true"
                        />
                    </q-tab-panel>
                </q-tab-panels>
            </q-tab-panel>
            <q-tab-panel name="award-roles">
                <Alert variant="info" message="Сопоставления ролей участников грантов в Pure и ролей в системе"/>
                <SettingsMapping :url="$route('ajax.project', {'m': 'projectRolesOptions'})"
                                 name="PURE_AWARDS_ROLES_ASSOCIATION"
                                 block="module"
                                 :data="helper.jsonParse(settings.module.PURE_AWARDS_ROLES_ASSOCIATION)"
                                 :list="data.module.pure.awardRoles"
                                 :multiple="false"
                />
            </q-tab-panel>
            <q-tab-panel name="fin-orgs">
                <SearchPureOrganisation block="module"
                                        name="PURE_FIN_ORGS_ASSOCIATION"
                                        :data="helper.jsonParse(settings.module.PURE_FIN_ORGS_ASSOCIATION)"
                                        :list="data.module.pure.projectTypes"
                />
            </q-tab-panel>
        </q-tab-panels>
    </div>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SearchPureOrganisation from "@/Components/Search/SearchPureOrganisation.vue";
import SettingsMapping from "@/Components/Settings/SettingsMapping.vue";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import helper from "@/helpers";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const tab = ref('base');
const baseTab = ref('main');

const rmTab = ref('prizes');
const rmTabs = ref({
    'prizes': 'Награды',
    'events': 'События',
    'journals': 'Издания',
    'activities': 'Деятельность',
    'press': 'Пресса/СМИ',
    'qualifications': 'Квалификационные работы',
    'publications': 'Результаты исследований'
});
const rmCategories = ref({
    'prizes': 'awards',
    'events': 'events',
    'journals': 'edition',
    'activities': 'activities',
    'press': 'press',
    'qualifications': 'qualifications',
    'publications': 'publications'
});

const handleValuesFromCheckboxes = (value) => _.isObject(value) ? _.keys(value) : value;

const config = {
    base: {
        main: [
            {
                name: 'PURE_ENABLED',
                label: $translate('settings.module.pure.name'),
                value: props.settings.module.PURE_ENABLED,
                description: $translate('settings.module.pure.disable_help'),
                block: 'module',
                type: 'checkbox',
            },
            {
                name: 'PURE_BASE_URL',
                label: $translate('module-pure.settings.PURE_BASE_URL'),
                value: props.settings.module.PURE_BASE_URL,
                description: $translate('module-pure.settings.PURE_BASE_URL_description'),
                block: 'module',
                type: 'text',
            },
            {
                name: 'PURE_KEY',
                label: $translate('module-pure.settings.PURE_KEY'),
                value: props.settings.module.PURE_KEY,
                description: $translate('module-pure.settings.PURE_KEY_description'),
                block: 'module',
                type: 'text',
            },
            {
                name: 'PURE_VERSION',
                label: $translate('module-pure.settings.PURE_VERSION'),
                value: props.settings.module.PURE_VERSION,
                description: $translate('module-pure.settings.PURE_VERSION_description'),
                block: 'module',
                type: 'select',
                multiple: false,
                options: props.data.module.pure.apiVersions
            },
        ],
        projects: [
            {
                name: 'PURE_PROJECT_INFO',
                label: $translate('module-pure.settings.PURE_PROJECT_INFO'),
                value: props.settings.module.PURE_PROJECT_INFO,
                description: $translate('module-pure.settings.PURE_PROJECT_INFO_description'),
                block: 'module',
                type: 'textarea',
            },
            {
                name: 'PURE_PROJECT_TYPES_RESTRICTIONS',
                label: 'Уникальное основание',
                value: helper.jsonParse(props.settings.module.PURE_PROJECT_TYPES_RESTRICTIONS),
                description: 'Типы проектов по которым нельзя создать проект если уже создан проект по такому основанию',
                block: 'module',
                type: 'select',
                multiple: true,
                options: props.data.module.pure.projectTypesTree
            },
            {
                name: 'PURE_HIDDEN_PROJECT_TYPES',
                label: 'Исключенные типы',
                value: helper.jsonParse(props.settings.module.PURE_HIDDEN_PROJECT_TYPES),
                description: 'Типы проектов которые исключены из создания по данному основанию',
                block: 'module',
                type: 'select',
                multiple: true,
                options: props.data.module.pure.projectTypesTree
            },
            {
                name: 'PURE_FIN_PROJECT_TYPES_DENY_CREATE',
                label: 'Ограничение подачи проекта',
                value: handleValuesFromCheckboxes(
                    helper.jsonParse(props.settings.module.PURE_FIN_PROJECT_TYPES_DENY_CREATE)
                ),
                description: 'Запретить подачу проектов, если финансирование по данному типу отсутствует или равно 0',
                block: 'module',
                type: 'select',
                multiple: true,
                options: props.data.module.pure.projectTypesTree
            },
        ],
        filtering: [
            {
                name: 'PURE_AWARDS_SEARCH_FILTERING_PAST_DATES',
                label: $translate('module-pure.settings.PURE_AWARDS_SEARCH_FILTERING_PAST_DATES'),
                value: props.settings.module.PURE_AWARDS_SEARCH_FILTERING_PAST_DATES,
                description: $translate('module-pure.settings.PURE_AWARDS_SEARCH_FILTERING_PAST_DATES_description'),
                block: 'module',
                type: 'checkbox',
            },
            {
                name: 'PURE_AWARDS_ALLOW_CREATE_PAST_DATES',
                label: $translate('module-pure.settings.PURE_AWARDS_ALLOW_CREATE_PAST_DATES'),
                value: props.settings.module.PURE_AWARDS_ALLOW_CREATE_PAST_DATES,
                description: $translate('module-pure.settings.PURE_AWARDS_ALLOW_CREATE_PAST_DATES_description'),
                block: 'module',
                type: 'checkbox',
            },
            {
                name: 'PURE_AWARDS_SEARCH_FILTERING_FUTURE_DATES',
                label: $translate('module-pure.settings.PURE_AWARDS_SEARCH_FILTERING_FUTURE_DATES'),
                value: props.settings.module.PURE_AWARDS_SEARCH_FILTERING_FUTURE_DATES,
                description: $translate('module-pure.settings.PURE_AWARDS_SEARCH_FILTERING_FUTURE_DATES_description'),
                block: 'module',
                type: 'checkbox',
            },
            {
                name: 'PURE_AWARDS_SEARCH_FILTERING_LEADER_ROLES',
                label: $translate('module-pure.settings.PURE_AWARDS_SEARCH_FILTERING_LEADER_ROLES'),
                value: props.settings.module.PURE_AWARDS_SEARCH_FILTERING_LEADER_ROLES,
                description: $translate('module-pure.settings.PURE_AWARDS_SEARCH_FILTERING_LEADER_ROLES_description'),
                block: 'module',
                type: 'checkbox',
            },
        ]
    },
    db: [
        {
            name: 'PURE_DB_HOST',
            label: 'Адрес сервера',
            value: props.settings.module.PURE_DB_HOST,
            description: '',
            block: 'module',
            type: 'text',
        },
        {
            name: 'PURE_DB_PORT',
            label: 'Порт',
            value: props.settings.module.PURE_DB_PORT,
            description: '',
            block: 'module',
            type: 'text',
        },
        {
            name: 'PURE_DB_DATABASE',
            label: 'База данных',
            value: props.settings.module.PURE_DB_DATABASE,
            description: '',
            block: 'module',
            type: 'text',
        },
        {
            name: 'PURE_DB_USERNAME',
            label: 'Пользователь',
            value: props.settings.module.PURE_DB_USERNAME,
            description: '',
            block: 'module',
            type: 'text',
        },
        {
            name: 'PURE_DB_PASSWORD',
            label: 'Пароль',
            value: props.settings.module.PURE_DB_PASSWORD,
            description: '',
            block: 'module',
            type: 'password',
        },
    ],
};
</script>
