<template>
    <Card title-bg="indigo-2"
          title-text="indigo-10"
          :title="$translate('module-microcollections.element.card.title')"
          header-toolbar
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn v-if="_.get(can, 'back')"
                   :label="$translate('buttons.back')"
                   @click="router.get($route('services.microcollections.index'))"
                   icon="o_chevron_left"
                   color="indigo-5"
                   no-caps
                   dense
            />
            <q-btn v-if="_.get(can, 'edit')"
                   :label="$translate('buttons.edit')"
                   @click="router.get($route('services.microcollections.element.edit', [element]))"
                   color="indigo-5"
                   icon="o_edit"
                   no-caps
                   dense
            />
        </template>
        <template v-for="(descr, field) in $translate('module-microcollections.element.card.fields')" :key="field">
            <template v-if="!_.isEmpty(_.get(element, 'data.' + field))">
                <q-field :label="descr" stack-label borderless>
                    <template v-slot:control>
                        <div class="self-center full-width" tabindex="0">
                            {{ _.get(element, 'data.' + field) }}
                        </div>
                    </template>
                </q-field>
            </template>
        </template>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {getCurrentInstance} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    element: Object,
    can: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
</script>
