<template>
    <q-item>
        <q-item-section>
            <q-item-label>
                {{ moment(dutyjourney.date_start).format($translate('common.date.js')) }}
                &mdash;
                {{ moment(dutyjourney.date_end).format($translate('common.date.js')) }}
                ({{ dutyjourney.days }})
            </q-item-label>
            <q-item-label caption>
                {{ dutyjourney.status_text }}
                <q-icon name="comment" v-if="dutyjourney.comment" class="cursor-pointer">
                    <q-popup-proxy>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    {{ dutyjourney.comment }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-popup-proxy>
                </q-icon>
            </q-item-label>
        </q-item-section>
        <q-item-section>
            <q-item-label>{{ dutyjourney.city }}</q-item-label>
            <q-item-label>{{ dutyjourney.target }}</q-item-label>
        </q-item-section>
        <q-item-section side>
            <DutyjourneyActions :dutyjourney="dutyjourney" @onSuccess="router.reload()"/>
        </q-item-section>
    </q-item>
</template>
<script setup lang="ts">

import {router, useForm} from "@inertiajs/vue3";
import moment from "moment";
import {computed, getCurrentInstance, ref} from "vue";
import {CadreVacation} from "@/entity";
import {useQuasar} from "quasar";
import VacationActions from "@/Components/Cadre/Vacation/VacationActions.vue";
import DutyjourneyActions from "@/Components/Cadre/Dutyjourney/DutyjourneyActions.vue";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route
const $q = useQuasar()

interface Props {
    dutyjourney: CadreDutyjourney
}

defineProps<Props>()

</script>
