import {defineStore} from 'pinia'
import _ from "lodash";
import axios from "axios";

export const useReportDataStore = defineStore('reportData', {
    state: () => ({
        research: {},
        loadingEquipment: false,
        loadingConsumables: false,
        loadingWorktime: false,
        loadingFiles: false,
        equipmentList: [],
        consumableList: [],
        worktimeList: [],
        filesList: [],
    }),
    actions: {
        /**
         * задание текущего исследования
         *
         * @param research
         */
        setResearch(research) {
            this.research = research;
        },
        /**
         * Загрузка использованного оборудования
         * @param {function?} cb callback function
         */
        loadEquipments(cb) {
            this.loadingEquipment = true;
            axios
                .get(route('ajax.report',
                    {m: 'equipment', research_id: _.get(this.research, 'id')}
                ))
                .then((response) => {
                    this.equipmentList = _.filter(response.data.results, (item) => !item.deleted_at)
                })
                .finally(() => {
                    if (cb) {
                        cb()
                    }
                    this.loadingEquipment = false;
                })
        },
        /**
         * Загрузка расходных материалов
         * @param {function?} cb callback function
         */
        loadConsumables(cb) {
            this.loadingConsumables = true;
            axios
                .get(route('ajax.report',
                    {m: 'consumables', research_id: _.get(this.research, 'id')}
                ))
                .then((response) => {
                    this.consumableList = _.filter(response.data.results, (item) => !item.deleted_at)
                })
                .finally(() => {
                    if (cb) {
                        cb()
                    }
                    this.loadingConsumables = false;
                })
        },
        /**
         * Загрузка рабочего времени
         * @param {function?} cb callback function
         */
        loadWorktime(cb) {
            this.loadingWorktime = true;
            axios.get(route('ajax.report', {m: 'worktime', research_id: _.get(this.research, 'id')}))
                .then((response) => {
                    this.worktimeList = _.filter(response.data.results, (item) => !item.deleted_at)
                })
                .finally(() => {
                    if (cb) {
                        cb()
                    }
                    this.loadingWorktime = false;
                });
        },
        /**
         * Загрузка файлов
         * @param {function?} cb callback function
         */
        loadFiles(cb) {
            this.loadingFiles = true;
            axios.get(route('ajax.report', {m: 'files', research_id: _.get(this.research, 'id')}))
                .then((response) => {
                    this.filesList = _.filter(response.data.results, (item) => !item.deleted_at)
                })
                .finally(() => {
                    if (cb) {
                        cb()
                    }
                    this.loadingFiles = false;
                });
        },
    }
})