<template>
    <Card title-bg="indigo-1" :title="$translate('consumables.title.list')" header-toolbar no-card-section hide-actions>
        <template v-slot:headerActions>
            <q-btn v-if="_.get(can, 'add')"
                   @click="router.get($route('consumables.create'))"
                   :label="$q.screen.lt.md?null:$translate('buttons.add')"
                   :title="$translate('buttons.add')"
                   icon="o_add"
                   color="indigo-3"
                   dense
                   no-wrap
            />
            <q-btn v-if="_.get(can, 'export')"
                   @click="router.get($route('consumables.export'))"
                   :label="$q.screen.lt.md?null:$translate('buttons.export')"
                   :title="$translate('buttons.export')"
                   icon="sym_o_download"
                   color="indigo-3"
                   dense
                   no-wrap
            />
            <q-btn v-if="_.get(can, 'import')"
                   @click="router.get($route('consumables.import'))"
                   :label="$q.screen.lt.md?null:$translate('buttons.import')"
                   :title="$translate('buttons.import')"
                   icon="sym_o_upload"
                   color="indigo-3"
                   dense
                   no-wrap
            />
        </template>
        <template v-slot:default>
            <q-table ref="tableRef"
                     row-key="id"
                     :rows="items"
                     :columns="columns"
                     :visible-columns="visibleColumns"
                     :filter="filter"
                     :loading="loading"
                     @request="onRequest"
                     v-model:pagination="pagination"
                     :rows-per-page-options="[10,50,100]"
                     wrap-cells
                     flat
            >
                <template v-slot:top-row>
                    <q-tr>
                        <q-td/>
                        <q-td colspan="100%">
                            <q-input v-model="filter" debounce="1000" :placeholder="$translate('search.element')"
                                     borderless dense
                                     clearable clear-icon="clear">
                                <template v-slot:prepend>
                                    <q-icon name="search"/>
                                </template>
                            </q-input>
                        </q-td>
                    </q-tr>
                </template>
                <template v-slot:body-cell-actions="props">
                    <q-td :props="props" class="no-wrap">
                        <DataTableActions :actions="props.row.actions"
                                          :name="props.row.name"
                                          @on-success="onSuccess"
                        ></DataTableActions>
                    </q-td>
                </template>
            </q-table>
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref, computed} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Card} from "@/Components/Block/";
import DataTableActions from "@/Components/DataTableActions.vue";
import {useQuasar} from 'quasar'

const props = defineProps({
    can: {
        type: Object
    }
});


const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const $q = useQuasar()

const tableRef = ref();
const filter = ref('');
const loading = ref(false);
const items = ref([]);
const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'name', align: 'left', label: $translate('consumables.fields.name'), field: 'name', sortable: true},
    {name: 'cas', align: 'left', label: $translate('consumables.fields.cas'), field: 'cas', sortable: true},
    {
        name: 'vendor_number',
        align: 'left',
        label: $translate('consumables.fields.vendor_number'),
        field: 'vendor_number',
        sortable: true
    },
    {name: 'units', align: 'left', label: $translate('consumables.fields.units'), field: 'unit_text'},
    {name: 'category', align: 'left', label: $translate('consumables.fields.category'), field: 'category'},
];

const visibleColumns = computed(() => [
    'actions',
    'name',
    !$q.screen.lt.md ? 'cas' : null,
    !$q.screen.lt.md ? 'vendor_number' : null,
    !$q.screen.lt.md ? 'units' : null,
    !$q.screen.lt.md ? 'category' : null,
]);

const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});

const onRequest = (request) => {
    const {page, rowsPerPage, sortBy, descending} = request.pagination;
    const filter = request.filter;

    loading.value = true;

    let params = {
        m: 'list',
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {},
            search: filter
        }
    };
    if (sortBy) {
        _.set(params.datatable.sort, sortBy, {'field': sortBy, 'direction': descending ? 'asc' : 'desc'});
    }

    axios.get(route('ajax.consumables'), {
        params: params,
    })
            .then((response) => {
                items.value = response.data.results.data;
                pagination.value.page = response.data.results.current_page;
                pagination.value.rowsPerPage = response.data.results.per_page;
                pagination.value.sortBy = sortBy;
                pagination.value.descending = descending;
                pagination.value.rowsNumber = response.data.results.total;
            })
            .finally(() => {
                loading.value = false;
            });
};

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};

onMounted(() => {
    onSuccess()
});
</script>
