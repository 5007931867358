<template>
    <Card :title="$translate('equipment.catalogue.title.list')"
          title-bg="deep-purple-1"
          header-toolbar hide-actions no-card-section>
        <template v-slot:headerActions>
            <q-btn v-if="can.create"
                   @click="openAddDialog"
                   color="deep-purple-3"
                   icon="add"
                   :label="$translate('buttons.add')"
                   dense no-caps no-wrap/>
            <q-btn v-if="can.import"
                   @click="router.visit($route('admin.equipment-catalogue.import'))"
                   color="deep-purple-3"
                   icon="import_export"
                   :label="$translate('buttons.import')"
                   dense no-caps no-wrap/>
            <q-btn v-if="can.export"
                   @click="router.visit($route('admin.equipment-catalogue.export'))"
                   color="deep-purple-3"
                   icon="import_export"
                   :label="$translate('buttons.export')"
                   dense no-caps no-wrap/>
        </template>
        <q-table ref="tableRef"
                 :rows="items"
                 :columns="columns"
                 :visible-columns="visibleColumns"
                 row-key="id"
                 v-model:pagination="pagination"
                 :filter="filter"
                 :loading="loading"
                 wrap-cells flat
                 @request="onRequest"
                 :rows-per-page-options="[10,50,100]"
        >
            <template v-slot:top-row>
                <q-tr>
                    <q-td></q-td>
                    <q-td colspan="100%">
                        <q-input borderless dense debounce="1000" v-model="filter"
                                 :placeholder="$translate('search.element')"
                                 clearable clear-icon="clear">
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:body-cell-name="props">
                <q-td :props="props">
                    <div class="row items-center no-wrap">
                        <q-icon name="edit" color="grey-5" left v-if="props.row.actions.edit"
                                :title="$translate('buttons.edit')" class="cursor-pointer">
                            <q-popup-edit v-model="props.row" v-if="props.row.actions.edit"
                                          v-slot="scope" buttons auto-save anchor="top left"
                                          :label-set="$translate('buttons.save')"
                                          :label-cancel="$translate('buttons.cancel')"
                                          @save="patchItem" :title="$translate('equipment.catalogue.fields.name')">
                                <template v-for="(lang_name,locale) in $translate('fields.locale')">
                                    <q-input v-model="scope.value.name_i18n[locale]" :label="lang_name"
                                             dense counter style="width: 600px;max-width: 70vw"/>
                                </template>
                            </q-popup-edit>
                        </q-icon>
                        <div>{{ props.row.name }}</div>
                    </div>
                </q-td>
            </template>
            <template v-slot:body-cell-vendor="props">
                <q-td :props="props">
                    <div class="row items-center no-wrap">
                        <q-icon name="edit" color="grey-5" left v-if="props.row.actions.edit"
                                :title="$translate('buttons.edit')" class="cursor-pointer">
                            <q-popup-edit v-model="props.row" v-if="props.row.actions.edit"
                                          v-slot="scope" buttons auto-save anchor="top left"
                                          :label-set="$translate('buttons.save')"
                                          :label-cancel="$translate('buttons.cancel')"
                                          @save="patchItem" :title="$translate('equipment.catalogue.fields.vendor')">
                                <q-input v-model="scope.value.vendor" dense counter/>
                            </q-popup-edit>
                        </q-icon>
                        <div>{{ props.row.vendor }}</div>
                    </div>
                </q-td>
            </template>
            <template v-slot:body-cell-brand="props">
                <q-td :props="props">
                    <div class="row items-center no-wrap">
                        <q-icon name="edit" color="grey-5" left v-if="props.row.actions.edit"
                                :title="$translate('buttons.edit')" class="cursor-pointer">
                            <q-popup-edit v-model="props.row" v-if="props.row.actions.edit"
                                          v-slot="scope" buttons auto-save anchor="top left"
                                          :label-set="$translate('buttons.save')"
                                          :label-cancel="$translate('buttons.cancel')"
                                          @save="patchItem" :title="$translate('equipment.catalogue.fields.brand')">
                                <q-input v-model="scope.value.brand" dense counter/>
                            </q-popup-edit>
                        </q-icon>
                        <div>{{ props.row.brand }}</div>
                    </div>
                </q-td>
            </template>
            <template v-slot:body-cell-model="props">
                <q-td :props="props">
                    <div class="row items-center no-wrap">
                        <q-icon name="edit" color="grey-5" left v-if="props.row.actions.edit"
                                :title="$translate('buttons.edit')" class="cursor-pointer">
                            <q-popup-edit v-model="props.row" v-if="props.row.actions.edit"
                                          v-slot="scope" buttons auto-save anchor="top left"
                                          :label-set="$translate('buttons.save')"
                                          :label-cancel="$translate('buttons.cancel')"
                                          @save="patchItem" :title="$translate('equipment.catalogue.fields.model')">
                                <q-input v-model="scope.value.model" dense counter/>
                            </q-popup-edit>
                        </q-icon>
                        <div>{{ props.row.model }}</div>
                    </div>
                </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions" :name="props.row.name"
                                      @on-success="onSuccess">
                    </DataTableActions>
                </q-td>
            </template>
        </q-table>
    </Card>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('equipment.catalogue.title.add')"
              title-bg="deep-purple-1"
              show-close-button in-modal no-card-section header-toolbar>
            <EquipmentCatalogueForm v-model="addForm"/>
            <template v-slot:actions>
                <q-btn color="positive"
                       icon="o_save"
                       :label="$translate('buttons.add')"
                       @click="submitForm"/>
            </template>
        </Card>
    </q-dialog>

</template>

<script setup>

import {getCurrentInstance, onMounted, ref, computed} from "vue";
import axios from "axios";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import {Card} from "@/Components/Block";
import EquipmentCatalogueForm from "@/Components/EquipmentCatalogue/EquipmentCatalogueForm.vue";
import DataTableActions from "@/Components/DataTableActions.vue";
import {useQuasar} from 'quasar'


const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $q = useQuasar()

const props = defineProps({
    can: {type: Object}
})


const tableRef = ref()
const filter = ref('')
const loading = ref(false)
const items = ref([])

const columns = [
    {
        name: 'actions', align: 'center', label: '',
        style: "width: 2rem",
    },
    {
        name: 'code', align: 'center', label: $translate('equipment.catalogue.fields.category'),
        style: "width: 15%",
        field: 'category',
        sortable: true,
    },
    {
        name: 'name', align: 'left', label: $translate('equipment.catalogue.fields.name'),
        field: 'name',
        style: "min-width: 50%",
        sortable: true,
    },
    {
        name: 'vendor', align: 'left', label: $translate('equipment.catalogue.fields.vendor'),
        field: 'vendor',
        sortable: true,
    },
    {
        name: 'brand', align: 'left', label: $translate('equipment.catalogue.fields.brand'),
        field: 'brand',
        sortable: true,
    },
    {
        name: 'model', align: 'left', label: $translate('equipment.catalogue.fields.model'),
        field: 'model',
        sortable: true,
    },
    {
        name: 'country', align: 'left', label: $translate('equipment.catalogue.fields.country_origin'),
        field: 'country_origin',
    },
]
const visibleColumns = computed(() => [
    'actions',
    'name',
    'vendor',
    !$q.screen.lt.md ? 'code' : null,
    !$q.screen.lt.md ? 'brand' : null,
    !$q.screen.lt.md ? 'model' : null,
    !$q.screen.lt.md ? 'country' : null,
]);

const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
})

function onRequest(request) {
    const {page, rowsPerPage, sortBy, descending} = request.pagination
    const filter = request.filter

    loading.value = true

    let params = {
        m: 'list',
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {},
            search: filter
        }
    }
    if (sortBy) {
        _.set(params.datatable.sort, sortBy, {'field': sortBy, 'direction': descending ? 'asc' : 'desc'});
    }

    axios.get(route('ajax.equipment.catalogue', params))
            .then((response) => {
                items.value = response.data.results.data;
                pagination.value.page = response.data.results.current_page
                pagination.value.rowsPerPage = response.data.results.per_page
                pagination.value.sortBy = sortBy
                pagination.value.descending = descending
                pagination.value.rowsNumber = response.data.results.total
            })
            .finally(() => {
                loading.value = false
            })
}

onMounted(() => {
    tableRef.value.requestServerInteraction()
})

const addDialog = ref(false)
const addForm = useForm({
    equipment_category_id: null,
    name_i18n: {
        ru: null,
        en: null
    },
    description_i18n: {
        ru: null,
        en: null
    },
    vendor: null,
    brand: null,
    model: null,
    country_origin_id: null
})

const openAddDialog = () => {
    addDialog.value = true;
}
const submitForm = () => {
    addForm
            .post(route('admin.equipment-catalogue.store'), {
                onSuccess: () => {
                    addDialog.value = false
                    onSuccess()
                }
            })
}
const onSuccess = () => {
    tableRef.value.requestServerInteraction()
}
const patchItem = (item) => {
    router.patch(route('admin.equipment-catalogue.update', {equipment_catalogue: item.id}), item, {
        onSuccess: () => {
            tableRef.value.requestServerInteraction()
        }
    })
}
</script>