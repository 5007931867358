<template>
    <Card :title="$translate('research-template.title.list')"
          title-bg="blue-grey-3"
          header-toolbar
          hide-actions
          no-card-section>
        <template v-slot:headerActions>
            <q-btn icon="o_add" v-if="can.create"
                   :label="$q.screen.lt.md?null:$translate('research-template.actions.add')"
                   :title="$translate('research-template.actions.add')"
                   @click="router.get($route('research.template.add'))"
                   color="blue-grey-5"
                   dense
                   no-caps/>
            <q-btn :icon="showingArchive?'inventory_2':'o_inventory_2'"
                   color="dark"
                   @click="toggleShowArchive"
                   outline
                   dense>
                <q-tooltip class="text-body2">
                    {{
                        showingArchive ? $translate('research-template.actions.hide-archived') : $translate('research-template.actions.show-archived')
                    }}
                </q-tooltip>
            </q-btn>
        </template>

        <DepartmentSelector/>

        <q-table ref="tableRef"
                 row-key="id"
                 :rows="items"
                 :columns="columns"
                 :visible-columns="visibleColumns"
                 :filter="filter"
                 :loading="loading"
                 @request="onRequest"
                 v-model:pagination="pagination"
                 :rows-per-page-options="[10,50,100]"
                 wrap-cells flat
        >
            <template v-slot:top-row>
                <q-tr>
                    <q-td/>
                    <q-td colspan="100%">
                        <q-input v-model="filter" debounce="1000" :placeholder="$translate('search.element')"
                                 borderless dense
                                 clearable clear-icon="clear">
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:header-cell-internal="props">
                <q-th :props="props">
                    <q-icon name="person" size="1.8em" class="cursor-pointer">
                        <q-menu cover anchor="top middle">
                            <q-list>
                                <q-item dense>
                                    <q-item-section>
                                        <q-item-label>
                                            {{ props.col.label }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-btn-group spread>
                                    <q-btn icon="highlight_off" :label="$translate('buttons.no')"
                                           @click="filterBy('internal',false)"
                                           v-close-popup flat/>
                                    <q-btn icon="o_check_circle" :label="$translate('buttons.yes')"
                                           @click="filterBy('internal',true)"
                                           v-close-popup flat/>
                                </q-btn-group>
                            </q-list>
                        </q-menu>
                    </q-icon>
                </q-th>
            </template>
            <template v-slot:body-cell-internal="props">
                <q-td :props="props">
                    <q-icon v-if='props.row.internal'
                            name="o_done"
                            color="positive"
                            :title="$translate('research-template.fields.internal')"/>
                </q-td>
            </template>
            <template v-slot:header-cell-external="props">
                <q-th :props="props">
                    <q-icon name="person_outline" size="1.8em" class="cursor-pointer">
                        <q-menu cover anchor="top middle">
                            <q-list>
                                <q-item dense>
                                    <q-item-section>
                                        <q-item-label>
                                            {{ props.col.label }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-btn-group spread>
                                    <q-btn icon="highlight_off" :label="$translate('buttons.no')"
                                           @click="filterBy('external',false)"
                                           v-close-popup flat/>
                                    <q-btn icon="o_check_circle" :label="$translate('buttons.yes')"
                                           @click="filterBy('external',true)"
                                           v-close-popup flat/>
                                </q-btn-group>
                            </q-list>
                        </q-menu>
                    </q-icon>
                </q-th>
            </template>
            <template v-slot:body-cell-external="props">
                <q-td :props="props">
                    <q-icon v-if='props.row.external'
                            name="o_done" color="positive"
                            :title="$translate('research-template.fields.external')"/>
                </q-td>
            </template>
            <template v-slot:header-cell-assay="props">
                <q-th :props="props">
                    <q-icon name="sym_o_lab_panel" size="1.8em" class="cursor-pointer">
                        <q-menu cover anchor="top middle">
                            <q-list>
                                <q-item dense>
                                    <q-item-section>
                                        <q-item-label>
                                            {{ props.col.label }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-btn-group spread>
                                    <q-btn icon="highlight_off" :label="$translate('buttons.no')"
                                           @click="filterBy('assay',false)"
                                           v-close-popup flat/>
                                    <q-btn icon="o_check_circle" :label="$translate('buttons.yes')"
                                           @click="filterBy('assay',true)"
                                           v-close-popup flat/>
                                </q-btn-group>
                            </q-list>
                        </q-menu>
                    </q-icon>
                </q-th>
            </template>
            <template v-slot:body-cell-assay="props">
                <q-td :props="props">
                    <q-icon v-if='props.row.assay'
                            name="o_done"
                            color="positive"
                            :title="$translate('research-template.fields.assay')"/>
                </q-td>
            </template>
            <template v-slot:header-cell-fields="props">
                <q-th :props="props">
                    <q-icon name="o_ballot" size="1.8em">
                        <q-tooltip class="text-body2">{{ $translate('research-template.fields.fields') }}</q-tooltip>
                    </q-icon>
                </q-th>
            </template>
            <template v-slot:body-cell-fields="props">
                <q-td :props="props">
                    <q-badge color="grey">{{ props.row.fields_count }}</q-badge>
                </q-td>
            </template>
            <template v-slot:body-cell-name="props">
                <q-td :props="props">{{ props.row.name }}</q-td>
            </template>
            <template v-slot:body-cell-state="props">
                <q-td :props="props">
                    <q-icon v-if="props.row.archive" name="o_inventory_2" size="1.2em" color="grey-5">
                        <q-tooltip class="text-body2">
                            {{
                                $translate('research-template.fields.archive')
                            }}
                        </q-tooltip>
                    </q-icon>
                    <q-icon v-else-if="props.row.enabled" name="o_check_circle" size="1.2em" color="positive">
                        <q-tooltip class="text-body2">
                            {{
                                $translate('research-template.fields.enabled')
                            }}
                        </q-tooltip>
                    </q-icon>
                </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @onSuccess="onSuccess" />
                </q-td>
            </template>
        </q-table>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import _ from 'lodash'
import {useQuasar} from "quasar";
import axios from "axios";
import {Card} from "@/Components/Block";
import DataTableActions from "@/Components/DataTableActions.vue";
import DepartmentSelector from "@/Components/Navigation/DepartmentSelector.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar();

const can = computed(() => usePage().props.can)

const tableRef = ref();
const filter = ref(null);
const loading = ref(false);
const items = ref([]);

const showingArchive = ref(false)

const toggleShowArchive = () => {
    let val = !showingArchive.value
    $q.cookies.set('researchTemplateArchived', val, {expires: 365, path: '/'})
    showingArchive.value = val
    router.reload()
    onSuccess()
}

const columns = [
    {name: 'actions', style: 'width:40px'},
    {
        name: 'name',
        label: $translate('research-template.fields.name'),
        field: 'name',
        align: 'left',
        sortable: true
    },
    {
        name: 'state',
        label: '',
        field: 'state',
        classes: 'text-no-wrap',
        align: 'center',
        style: 'width:40px'
    },
    {
        name: 'internal',
        label: $translate('research-template.fields.internal'),
        field: 'internal',
        classes: 'text-no-wrap',
        align: 'center',
        style: 'width:40px'
    },
    {
        name: 'external',
        label: $translate('research-template.fields.external'),
        field: 'external',
        align: 'center',
        style: 'width:40px'
    },
    {

        name: 'assay',
        label: $translate('research-template.fields.assay'),
        field: 'assay',
        align: 'center',
        style: 'width:40px'
    },
    {

        name: 'fields',
        label: $translate('research-template.fields.fields'),
        field: 'fields',
        align: 'center',
        style: 'width:40px'
    },
    {
        name: 'ssc',
        label: $translate('research-template.fields.ssc'),
        field: 'ssc',
        align: 'left',
    },
    {
        name: 'author',
        label: $translate('research-template.fields.author'),
        field: 'author',
        align: 'left',
    },
]

const visibleColumns = computed(() => [
    'actions',
    'name',
    'ssc',
    !$q.screen.lt.md ? 'state' : null,
    !$q.screen.lt.md ? 'internal' : null,
    !$q.screen.lt.md ? 'external' : null,
    !$q.screen.lt.md ? 'assay' : null,
    !$q.screen.lt.md ? 'fields' : null,
    !$q.screen.lt.md ? 'author' : null,
]);

const pagination = ref({
    sortBy: null,
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});


const onRequest = (request) => {
    const {page, rowsNumber, rowsPerPage, sortBy, descending} = request.pagination;
    const filter = request.filter;

    let params = {
        m: 'list',
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {},
            search: filter
        }
    };
    if (sortBy) {
        _.set(params.datatable.sort, sortBy, {'field': sortBy, 'direction': descending ? 'asc' : 'desc'});
    }

    axios.get(route('ajax.research.template'), {
        params: params,
    })
            .then((response) => {
                items.value = response.data.results.data;
                pagination.value.page = response.data.results.current_page;
                pagination.value.rowsPerPage = response.data.results.per_page;
                pagination.value.sortBy = sortBy;
                pagination.value.descending = descending;
                pagination.value.rowsNumber = response.data.results.total;
            })
            .finally(() => {
                loading.value = false;
            });
};

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};

const filterBy = (field, value) => {
    filter.value = '' + field + ':"' + value + '"';
    onSuccess();
}

onMounted(() => {
    showingArchive.value = $q.cookies.get('researchTemplateArchived');
    onSuccess()
});
</script>