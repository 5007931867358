<template>
    <q-banner class="bg-red-1 text-red-9 text-center q-my-xs" dense>
        {{ $translate('common.has-changes') }}
    </q-banner>
</template>
<script setup lang="ts">
import {getCurrentInstance} from "vue";

const app = getCurrentInstance()
const $translate = app?.appContext.config.globalProperties.$translate
</script>