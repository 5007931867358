<template>
    <q-list separator>
        <template v-for="(item) in items">
            <q-item>
                <q-item-section>
                    <q-item-label>
                        {{ item.name }}
                    </q-item-label>
                </q-item-section>
                <q-item-section side>
                    <DataTableActions>
                        <q-item v-if="item.can.edit"
                                @click="openEditDialog(item)" clickable>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('buttons.edit') }}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-icon name="o_edit" size="xs"/>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="item.can.copy"
                                @click="copyItem(item)" clickable>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('buttons.clone') }}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-icon name="o_copy" size="xs"/>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="item.can.archive"
                                @click="archiveItem(item)" clickable>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('buttons.archive') }}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-icon name="o_inventory" size="xs"/>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="item.can.delete"
                                @click="deleteItem(item)" clickable>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('buttons.delete') }}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-icon name="o_delete" size="xs"/>
                            </q-item-section>
                        </q-item>
                    </DataTableActions>
                </q-item-section>
            </q-item>
        </template>
    </q-list>
    <q-dialog v-model="editDialog" no-backdrop-dismiss full-width full-height>
        <Card :title="$translate('report-type.title.edit')"
              no-card-section show-close-button in-modal header-toolbar>
            <CategoryForm v-model="selectedItem"
                          v-if="selectedItem"/>
            <q-inner-loading :showing="loadingItem">
                <q-spinner-gears size="50px" color="primary"/>
            </q-inner-loading>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="updateItem"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import ElementTree from "@/Components/ElementTree.vue";
import DataTableActions from "@/Components/DataTableActions.vue";
import {useQuasar} from "quasar";
import Card from "@/Components/Block/Card.vue";
import CategoryForm from "@/Components/ReportMaterialType/CategoryForm.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar();

const props = defineProps({
    category: {type: String}
})

const items = ref([])
const loadItems = () => {
    axios.get(route('ajax.report.type', {m: 'tree', category: props.category}))
        .then((response) => {
            items.value = response.data.results;
        })
}

const selectedItem = ref(null)

const editDialog = ref(false)

const loadingItem = ref(false)
const openEditDialog = (item) => {
    selectedItem.value = null;
    editDialog.value = true;
    loadingItem.value = true;
    axios.get(route('ajax.report.type', {m: 'record', id: item.id}))
        .then((response) => {
            selectedItem.value = response.data.results;
        })
        .finally(() => {
            loadingItem.value = false;
        })
}

const form = useForm({});
const updateItem = () => {
    form.transform((data) => ({
        ...data,
        ...selectedItem.value
    }))
        .post(route('admin.report-type.update', [selectedItem.value.category, selectedItem.value.id]), {
            onSuccess: () => {
                selectedItem.value = null
                editDialog.value = false;
            },
            onFinish: () => {
                loadItems()
            }
        })
}
const copyItem = (item) => {
    router.get(route('admin.report-type.copy', [props.category, item]), {}, {
        onSuccess: () => {
            loadItems()
        }
    })
}
const archiveItem = (item) => {
    router.get(route('admin.report-type.archive', [props.category, item]), {}, {
        onSuccess: () => {
            loadItems()
        }
    })
}
const deleteItem = (item) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: item.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('admin.report-type.delete', [props.category, item]), {
            onSuccess: () => {
                loadItems()
            }
        })
    })
}

onMounted(() => {
    loadItems()
})
</script>