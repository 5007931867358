<template>
    <Card :title="$translate('project.title.add')"
          title-bg="teal-4" title-text="white"
          header-toolbar>
        <q-stepper :vertical="$q.screen.lt.md"
                   v-model="step"
                   ref="stepper"
                   color="primary"
                   flat
                   keep-alive
        >
            <q-step name="select-source"
                    :title="$translate('project.form.step.select-source.title')"
                    :caption="$translate('project.form.step.select-source.caption')"
                    icon="settings"
                    done-color="teal-6"
                    :done="isDone('select-source')"
            >
                <component :is="projectBlock('SelectSource')" v-model="project"/>
            </q-step>

            <q-step name="main-info"
                    :title="$translate('project.form.step.main-info.title')"
                    :caption="$translate('project.form.step.main-info.caption')"
                    icon="o_text_snippet"
                    done-color="teal-6"
                    :done="isDone('main-info')"
            >
                <component :is="projectBlock('MainInfo')" v-model="project"/>
                <component :is="projectBlock('ExpectedResult')" v-model="project"/>
            </q-step>

            <q-step name="members"
                    :title="$translate('project.form.step.members.title')"
                    :caption="$translate('project.form.step.members.caption')"
                    icon="people"
                    done-color="teal-6"
                    :done="isDone('members')"
            >
                <component :is="projectBlock('Members')" v-model="project"/>
            </q-step>

            <q-step name="departments"
                    :title="$translate('project.form.step.departments.title')"
                    :caption="$translate('project.form.step.departments.caption')"
                    icon="domain"
                    done-color="teal-6"
                    :done="isDone('departments')"
            >
                <component :is="projectBlock('Departments')" v-model="project"/>
            </q-step>

            <q-step name="verify-data"
                    :title="$translate('project.form.step.verify-data.title')"
                    :caption="$translate('project.form.step.verify-data.caption')"
                    icon="checklist"
                    done-color="teal-6"
                    :done="isDone('verify-data')"
            >
                <component :is="projectBlock('VerifyData')" :project="project" v-if="step==='verify-data'"/>
            </q-step>
        </q-stepper>

        <template v-slot:actions>
            <q-btn v-if="step !== 'select-source'"
                   @click="$refs.stepper.previous()"
                   color="dark"
                   :label="$translate('buttons.back')"
                   icon="navigate_before"/>

            <q-btn v-if="step !== 'verify-data'"
                   @click="nextStep"
                   color="teal-6"
                   icon-right="navigate_next"
                   :label="$translate('buttons.next')"/>

            <q-btn v-if="step === 'verify-data'"
                   @click="createProject"
                   color="teal-6"
                   icon-right="navigate_next"
                   :label="$translate('project.actions.create')"/>
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import _ from "lodash";

import {Card} from "@/Components/Block";
import * as ProjectStep from "@/Components/Project/CreateProjectSteps"

const $q = useQuasar();
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const step = ref('select-source')
const stepper = ref()

const projectBlock = (block) => ProjectStep[block];

const steps = [
    'select-source',
    'main-info',
    'members',
    'departments',
    'verify-data'
]
const isDone = (doneStep) => {
    let current_index = _.findIndex(steps, (o) => (o === step.value))
    let step_index = _.findIndex(steps, (o) => (o === doneStep))
    return step_index < current_index
}

const project = useForm({
    source: null,
    sourceData: null,
    sourceDetail: null,
    sourceInfo: null,
    type_id: null,
    type: {},
    name: null,
    description: '',
    additional: {},
    targets: '',
    keywords: [],
    date_start: null,
    date_end: null,
    expectedresult: {},
    members: [],
    ssc: []
})

const nextStep = () => {
    validateData()
    return null;
}
/**
 * Валидация данных на бекенде
 */
const validateData = () => {
    let _data = _.cloneDeep(project.data());
    project
            .transform((data) => ({
                ...data,
                type: {},
                ssc: _.map(_data.ssc, (ssc) => {
                    delete ssc.ssc;
                    return ssc;
                }),
                step: step.value
            }))
            .post(route('project.validate'), {
                onSuccess: () => {
                    stepper.value.next()
                }
            })
}
/**
 * Отправка данных для создания проекта
 */
const createProject = () => {
    let _data = _.cloneDeep(project.data());
    project
            .transform((data) => ({
                ...data,
                type: {},
                ssc: _.map(_data.ssc, (ssc) => {
                    delete ssc.ssc;
                    return ssc;
                }),
                step: step.value
            }))
            .post(route('project.store'), {})
}

</script>