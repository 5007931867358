<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions>
        <q-select v-model="publication.category"
                  :label="_.get(fields,'name')"
                  :options="options"
                  stack-label
                  options-dense
                  map-options
                  emit-value
                  :error="publication.errors.hasOwnProperty('category')"
                  :error-message="_.get(publication.errors,'category')"
        />
    </Card>
</template>

<script setup>
/**************************************************
 *
 * Результаты исследований - Категория публикации
 *
 ***************************************************/
import {computed, getCurrentInstance} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object},
})

const publication = defineModel('publication', {type: Object})
const errors = defineModel('errors', {type: Object})

const options = computed(() => _.map(_.get(props.fields, 'values'), (cat) => ({
            value: cat,
            label: $translate('report-type.category.' + cat)
        }))
)

</script>