<template>
    <q-list v-if="services.hasOwnProperty('ethics')">
        <NavigationItem :title="services.ethics.name"
                        :sidebar="sidebar"
                        color="brand"
                        icon="o_psychology_alt"
        >
            <template #items>
                <q-item @click="router.visit($route('services.ethics.applications'))" clickable>
                    <q-item-section>
                        {{ $translate('menu.ethics.applications') }}
                    </q-item-section>
                </q-item>
                <template v-for="(descr, state) in $translate('menu.ethics.state')" :key="state">
                    <q-item @click="router.visit($route('services.ethics.applications') + '?state=' + state)"
                            clickable dense v-close-popup>
                        <q-item-section class="q-pl-sm text-lowercase">
                            &mdash; {{ descr }}
                        </q-item-section>
                    </q-item>
                </template>
                <q-item @click="router.visit($route('services.ethics.application.create'))"
                        clickable v-close-popup>
                    <q-item-section side class="q-pr-xs">
                        <q-icon name="o_add" size="xs" color="brand"/>
                    </q-item-section>
                    <q-item-section class="q-pl-none text-brand">
                        {{ $translate('menu.ethics.add-application') }}
                    </q-item-section>
                </q-item>
                <q-separator/>
                <q-item @click="router.visit($route('services.ethics.settings.index'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate('menu.ethics.docs-contacts') }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
    </q-list>
</template>

<script setup>
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";
import {computed, getCurrentInstance} from "vue";
import {router, usePage} from "@inertiajs/vue3";

const props = defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const page = usePage();
const services = computed(() => page.props.services);
</script>
