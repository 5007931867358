<template>
    <q-input v-model="skill.name"
             :label="$translate('user-skill.label.name')"
             :error-message="_.get(errors,'name')"
             :error="errors.hasOwnProperty('name')"/>

    <q-select v-if='!skill.id && type'
              v-model="skill.type_id"
              :label="$translate('user-skill.label.type')"
              :hint="$translate('user-skill.label.type-hint')"
              :options="type"
              map-options
              emit-value
              use-input
              use-chips
              new-value-mode="add-unique"
              :error-message="_.get(errors,'type_id')"
              :error="errors.hasOwnProperty('type_id')"/>

    <q-field :label="$translate('user-skill.label.description')"
             stack-label borderless
             :error-message="_.get(errors,'description')"
             :error="errors.hasOwnProperty('description')">
        <template v-slot:control>
            <div class="full-width">
                <q-editor v-model="skill.description"/>
            </div>
        </template>
    </q-field>

    <q-file v-model="skill.file"
            :label="$translate('user-skill.label.file')"
            :error-message="_.get(errors,'file')"
            :error="errors.hasOwnProperty('file')"
    >
        <template v-slot:prepend>
            <q-icon name="attach_file"/>
        </template>
    </q-file>
</template>

<script setup lang="ts">
import {getCurrentInstance, computed} from "vue";
import _ from "lodash";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

const skill: any = defineModel()

interface Props {
    type?: []
}

const props = defineProps<Props>()

const errors: object = computed(() => _.get(skill, 'errors') || {})
</script>