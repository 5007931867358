<template>

    <table class="w-100">
        <tbody>
        <tr>
            <td class="text-center text-bold">
                Список заявок на доставку
            </td>
            <td class="text-center text-bold">
                <div>_______________________________________</div>
                <br/>
                <div>________________________/_____________/</div>
            </td>
        </tr>
        </tbody>
    </table>
    <div class="q-table__container q-table--horizontal-separator column no-wrap q-table--no-wrap  q-table--dense">
        <table class="w-100 q-table q-my-lg q-table--bordered">
            <thead>
            <tr>
                <th>Адрес</th>
                <th colspan="2" class="text-center">Номер заявки, дата</th>
                <th colspan="2" class="text-center">Наименование, количество</th>
                <th class="text-right">Получено</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(items,group) in orders">
                <tr>
                    <td colspan="100%" class="text-italic">{{ group }}</td>
                </tr>
                <template v-for="(order) in items">
                    <tr>
                        <td width="20%" class="text-center">пом. {{ order.room }}</td>
                        <td class="text-right">{{ order.number }}</td>
                        <td class="text-left"><span
                                class="small">{{ moment(order.date).format($translate('common.date.js')) }}</span>
                        </td>
                        <td class="text-right">{{ order.goods.goods.name }}</td>
                        <td class="text-left" nowrap="">{{ order.count }} {{ order.units }}</td>
                        <td colspan="1" class="align-bottom">
                        </td>
                    </tr>
                </template>
                <tr>
                    <td colspan="100%">&nbsp;</td>
                </tr>
            </template>
            </tbody>
        </table>
    </div>

</template>

<script setup>
import Print from "@/Layout/Print.vue";
import {getCurrentInstance} from "vue";
import StaffItem from "@/Components/SSC/Staff/StaffItem.vue";
import helpers from "@/helpers";
import moment from "moment";
import _ from "lodash";

defineOptions({layout: Print});

const props = defineProps({
    orders: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>