<template>
    <Card :title="$translate('module-archive.research.report.consumables.title')" hide-actions>
        <template v-slot:default>
            <template v-if="helpers.filled(researchReportConsumables)">
                <template v-if="helpers.filled(_.get(researchReportConsumables, 'load_info'))">
                    <q-list separator>
                        <template v-for="(consumablesData, i) in _.get(researchReportConsumables, 'load_info')"
                                  :key="i"
                        >
                            <template v-if="helpers.filled(_.get(consumablesData, 'reagent'))">
                                <div class="flex items-center justify-between">
                                    <div class="text-h6">{{ _.get(consumablesData, 'reagent.name') }}</div>
                                    <q-btn :icon="expanded[_.get(consumablesData, 'reagent.id')] ?
                                                'keyboard_arrow_up' :
                                                'keyboard_arrow_down'"
                                           :title="$translate('buttons.detail')"
                                           @click="switchExpanded(_.get(consumablesData, 'reagent.id'))"
                                           size="sm"
                                           flat
                                    />
                                </div>
                                <q-slide-transition>
                                    <div v-show="expanded[_.get(consumablesData, 'reagent.id')]">
                                        <q-table v-if="helpers.filled(_.get(consumablesData, 'used_info'))"
                                                 :columns="columns"
                                                 :rows="_.get(consumablesData, 'used_info')"
                                                 :pagination="{rowsPerPage: 0}"
                                                 :rows-per-page-options="[0]"
                                                 hide-pagination
                                                 wrap-cells
                                                 bordered
                                                 flat
                                        >
                                            <template v-slot:header-cell-expense="props">
                                                <q-th :props="props">
                                                    {{ props.col.label }} ({{ _.get(consumablesData, 'reagent.units') }})
                                                </q-th>
                                            </template>
                                            <template v-slot:body-cell-timestamp="props">
                                                <q-td :props="props">
                                                    <span v-if="helpers.filled(props.row.timestamp)">
                                                        {{ moment(props.row.timestamp).format($translate('common.datetime.js')) }}
                                                    </span>
                                                    <span v-else class="text-negative">
                                                        {{ $translate('common.not_available') }}
                                                    </span>
                                                </q-td>
                                            </template>
                                            <template v-slot:body-cell-expense="props">
                                                <q-td :props="props">
                                                    <span v-if="helpers.filled(_.get(props.row, 'expense'))">
                                                        {{ helpers.twoDecimals(_.get(props.row, 'expense')) }}
                                                    </span>
                                                    <span v-else class="text-negative">
                                                        {{ $translate('common.not_available') }}
                                                    </span>
                                                </q-td>
                                            </template>
                                            <template v-slot:body-cell-price="props">
                                                <q-td :props="props">
                                                    <span v-if="helpers.filled(props.row.price)">
                                                        {{ helpers.formatMoney(props.row.price) }}
                                                    </span>
                                                    <span v-else class="text-negative">
                                                        {{ $translate('common.not_available') }}
                                                    </span>
                                                </q-td>
                                            </template>
                                            <template v-slot:body-cell-user="props">
                                                <q-td :props="props">
                                                    <span v-if="helpers.filled(_.get(consumablesData, 'user'))">
                                                        {{ handleUserInfo(_.get(consumablesData, 'user')) }}
                                                    </span>
                                                    <span v-else class="text-negative">
                                                        {{ $translate('common.not_available') }}
                                                    </span>
                                                </q-td>
                                            </template>
                                        </q-table>
                                        <AlertWarning v-else>
                                            {{
                                                $translate('module-archive.research.report.consumables.warnings.no-data')
                                            }}
                                        </AlertWarning>
                                    </div>
                                </q-slide-transition>
                            </template>
                        </template>
                    </q-list>
                </template>
                <template v-else>
                    <AlertWarning>
                        {{ $translate('module-archive.research.report.consumables.warnings.no-data') }}
                    </AlertWarning>
                </template>
            </template>
            <template v-else>
                <AlertWarning>
                    {{ $translate('module-archive.research.report.consumables.warnings.no-data') }}
                </AlertWarning>
            </template>
        </template>
    </Card>
</template>

<script setup>
import AlertWarning from "@/Components/Block/AlertWarning.vue";
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import moment from "moment";
import helpers from "@/helpers";

const props = defineProps({
    researchReportConsumables: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const expanded = ref({});
if (helpers.filled(props.researchReportConsumables)) {
    if (helpers.filled(_.get(props.researchReportConsumables, 'load_info'))) {
        _.forEach(_.get(props.researchReportConsumables, 'load_info'), consumablesData => {
            expanded.value[_.get(consumablesData, 'reagent.id')] = ref(false);
        });
    }
}

const switchExpanded = (id) => {
    expanded.value[id] = !expanded.value[id];
};

const columns = [
    {
        name: 'timestamp',
        label: $translate('module-archive.research.report.consumables.columns.timestamp'),
        align: 'left',
        field: 'timestamp'
    },
    {
        name: 'expense',
        label: $translate('module-archive.research.report.consumables.columns.expense'),
        align: 'center',
        field: 'expense'
    },
    {
        name: 'price',
        label: $translate('module-archive.research.report.consumables.columns.price'),
        align: 'center',
        field: 'price'
    },
    {
        name: 'user',
        label: $translate('module-archive.research.report.consumables.columns.user'),
        align: 'left',
        field: 'user'
    },
    {
        name: 'comment',
        label: $translate('module-archive.research.report.consumables.columns.comment'),
        align: 'left',
        field: 'comment'
    },
];

const handleUserInfo = (userInfo) => {
    if (_.has(userInfo, 'fullname')) {
        return _.get(userInfo, 'fullname');
    }
    return [
        _.get(userInfo, 'lastname'),
        _.get(userInfo, 'firstname'),
        _.get(userInfo, 'midname')
    ].join(' ');
};
</script>
