<template>
    <Card :title="$translate('module-archive.project.card.main.title')" class="q-mb-md" hide-actions>
        <template v-slot:default>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.project.card.main.fields.number') }}:</div>
                <div class="col-auto"><b>{{ _.get(archiveProject, 'number') }}</b></div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.project.card.main.fields.name') }}:</div>
                <div class="col">{{ _.get(archiveProject, 'project_name') }}</div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.project.card.main.fields.rc') }}:</div>
                <div class="col">{{ _.get(archiveProject, 'rc_name') }}</div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.project.card.main.fields.type') }}:</div>
                <div class="col">{{ _.get(archiveProject, 'project_type') }}</div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.project.card.main.fields.state') }}:</div>
                <div class="col">{{ _.get(archiveProject, 'project_state') }}</div>
            </div>
            <div v-if="_.get(archiveProject, 'project_dates.dcreate')" class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.project.card.main.fields.created') }}:</div>
                <div class="col">
                    {{ moment(_.get(archiveProject, 'project_dates.dcreate')).format($translate('common.datetime.js')) }}
                </div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.project.card.main.fields.source') }}:</div>
                <div class="col">
                    <template v-if="!_.isEmpty(_.get(archiveProject, 'project_source.data'))">
                        <a @click="modal = !modal" class="cursor-pointer text-brand">
                            {{ _.get(archiveProject, 'project_source.id') }}
                        </a>
                        <q-dialog v-model="modal">
                            <Card :title="$translate('module-archive.project.card.main.fields.source-data')"
                                  actions-align="right"
                                  show-close-button
                                  in-modal
                            >
                                <template v-slot:default>
                                    <div v-if="_.includes(['ias', 'pure'], _.get(archiveProject, 'project_source.source'))"
                                         v-html="_.get(archiveProject, 'project_source.data.shortRendering')"
                                    ></div>
                                    <div v-if="'delo' === _.get(archiveProject, 'project_source.source')">
                                        <q-list>
                                            <q-item>
                                                <q-item-section>
                                                    <q-item-label caption>
                                                        {{ $translate('delo.deloNumber-label') }}
                                                    </q-item-label>
                                                    <q-item-label class="q-ml-xs">
                                                        {{ _.get(archiveProject, 'project_source.data.FREE_NUM') }}
                                                    </q-item-label>
                                                </q-item-section>
                                            </q-item>
                                            <q-item>
                                                <q-item-section>
                                                    <q-item-label caption>
                                                        {{ $translate('delo.deloDate-label') }}
                                                    </q-item-label>
                                                    <q-item-label class="q-ml-xs">
                                                        {{ _.get(archiveProject, 'project_source.data.DOC_DATE') }}
                                                    </q-item-label>
                                                </q-item-section>
                                            </q-item>
                                            <q-item>
                                                <q-item-section>
                                                    <q-item-label caption>
                                                        {{ $translate('delo.rk_classification') }}
                                                    </q-item-label>
                                                    <q-item-label class="q-ml-xs">
                                                        {{ _.get(archiveProject, 'project_source.data.CLASSIF_NAME') }}
                                                    </q-item-label>
                                                </q-item-section>
                                            </q-item>
                                            <q-item>
                                                <q-item-section>
                                                    <q-item-label caption>
                                                        {{ $translate('delo.rk_name') }}
                                                    </q-item-label>
                                                    <q-item-label class="q-ml-xs">
                                                        {{ _.get(archiveProject, 'project_source.data.ANNOTAT') }}
                                                    </q-item-label>
                                                </q-item-section>
                                            </q-item>
                                            <q-item>
                                                <q-item-section>
                                                    <q-item-label caption>
                                                        {{ $translate('delo.rk_notes') }}
                                                    </q-item-label>
                                                    <q-item-label class="q-ml-xs">
                                                        {{ _.get(archiveProject, 'project_source.data.NOTE') }}
                                                    </q-item-label>
                                                </q-item-section>
                                            </q-item>
                                        </q-list>
                                    </div>
                                </template>
                                <template v-slot:actions>
                                    <q-btn :label="$translate('buttons.close')" v-close-popup flat />
                                    <q-btn v-if="_.get(archiveProject, 'project_source.data.info.link')"
                                           :label="$translate('module-archive.project.card.actions.go-to-source')"
                                           :href="_.get(archiveProject, 'project_source.data.info.link')"
                                           icon-right="o_open_in_new"
                                           target="_blank"
                                           flat
                                    />
                                </template>
                            </Card>
                        </q-dialog>
                    </template>
                    <template v-else>
                        <span>{{ _.get(archiveProject, 'project_source.id') }}</span>
                    </template>
                </div>
            </div>
            <div v-if="_.get(archiveProject, 'project_additional_data.additionalData3')" class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.project.card.main.fields.mrmi-methods') }}:</div>
                <div class="col">{{ _.get(archiveProject, 'project_additional_data.additionalData3') }}</div>
            </div>
            <div class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.project.card.main.fields.timeline') }}:</div>
                <div class="col">
                    {{
                        moment(_.get(archiveProject, 'project_dates.bdate')).format($translate('common.date.js'))
                    }} — {{
                        moment(_.get(archiveProject, 'project_dates.edate')).format($translate('common.date.js'))
                    }}
                </div>
            </div>
            <hr v-if="_.get(archiveProject, 'project_info.project_target') || _.get(archiveProject, 'project_info.project_short_description')" />
            <div v-if="_.get(archiveProject, 'project_info.project_target')" class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.project.card.main.fields.targets') }}:</div>
                <div class="col">{{ _.get(archiveProject, 'project_info.project_target') }}</div>
            </div>
            <div v-if="_.get(archiveProject, 'project_info.project_short_description')" class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.project.card.main.fields.description') }}:</div>
                <div class="col">{{ _.get(archiveProject, 'project_info.project_short_description') }}</div>
            </div>
            <div v-if="_.get(archiveProject, 'project_info.keywords')" class="row q-py-xs">
                <div class="col-7">{{ $translate('module-archive.project.card.main.fields.keywords') }}:</div>
                <div class="col">{{ _.get(archiveProject, 'project_info.keywords') }}</div>
            </div>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import moment from "moment";

const props = defineProps({
    archiveProject: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const modal = ref(false);
</script>
