<template>
    <Card title-bg="purple-1"
          :title="$translate('consumables.access.title.list', {name: ssc ? _.get(ssc, 'name_short') : ''})"
          header-toolbar
          no-card-section
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn v-if="_.get(can, 'access')"
                   :label="$translate('consumables.access.actions.add')"
                   @click="addAccessDialog = !addAccessDialog"
                   icon="o_add"
                   color="purple-3"
                   dense no-caps
            />
            <q-btn v-if="_.get(can, 'index')"
                   :label="$translate('buttons.back')"
                   @click="router.get($route('consumables.ssc.index'))"
                   icon="o_chevron_left"
                   color="purple-3"
                   dense no-caps
            />
            <q-dialog v-model="addAccessDialog"
                      no-backdrop-dismiss>
                <Card :title="$translate('consumables.access.title.create')"
                      in-modal show-close-button header-toolbar>
                    <SearchUser @on-select="selectUser"/>
                    <SearchConsumables @on-select="selectConsumable" multiple/>
                    <template v-slot:actions>
                        <q-btn icon="o_save"
                               :label="$translate('buttons.save')"
                               color="positive"
                               @click="accessFormSubmit"/>
                    </template>
                </Card>
            </q-dialog>
        </template>

        <DepartmentSelector/>

        <q-table ref="tableRef"
                 row-key="id"
                 :rows="items"
                 :columns="columns"
                 :filter="filter"
                 :loading="loading"
                 @request="onRequest"
                 v-model:pagination="pagination"
                 :rows-per-page-options="[10,50,100]"
                 wrap-cells
                 flat
        >
            <template v-slot:top-row>
                <q-tr>
                    <q-td/>
                    <q-td colspan="100%">
                        <q-input v-model="filter"
                                 :placeholder="$translate('search.element')"
                                 debounce="1000"
                                 borderless dense
                                 clear-icon="clear"
                                 clearable>
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @on-success="onSuccess"
                    ></DataTableActions>
                </q-td>
            </template>
            <template v-slot:body-cell-user="props">
                <q-td :props="props">
                    <span v-html="props.value"></span>
                </q-td>
            </template>
        </q-table>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import Card from "@/Components/Block/Card.vue";
import SearchUser from "@/Components/Search/SearchUser.vue";
import SearchConsumables from "@/Components/Search/SearchConsumables.vue";
import DataTableActions from "@/Components/DataTableActions.vue";
import DepartmentSelector from "@/Components/Navigation/DepartmentSelector.vue";

const props = defineProps({
    ssc: {
        type: Object
    },
    can: {
        type: Object
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const addAccessDialog = ref(false);

const tableRef = ref();
const filter = ref('');
const loading = ref(false);
const items = ref([]);

const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'user', align: 'left', label: $translate('consumables.access.fields.user'), field: 'user'},
    {
        name: 'consumable',
        align: 'left',
        label: $translate('consumables.access.fields.consumables'),
        field: 'consumable'
    },
];
const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};

const onRequest = (request) => {
    const {page, rowsPerPage, sortBy, descending} = request.pagination;
    const filter = request.filter;

    loading.value = true;

    let params = {
        m: 'list',
        ssc_id: _.get(props.ssc, 'id'),
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {}
        }
    };
    if (_.size(filter) > 0) {
        params.datatable['search'] = filter;
    }
    axios.get(route('ajax.consumables.access'), {
        params: params,
    })
            .then((response) => {
                items.value = response.data.results.data;
                pagination.value.page = response.data.results.current_page;
                pagination.value.rowsPerPage = response.data.results.per_page;
                pagination.value.sortBy = sortBy;
                pagination.value.descending = descending;
                pagination.value.rowsNumber = response.data.results.total;
            })
            .finally(() => {
                loading.value = false;
            });
};

const accessForm = useForm({
    user: null,
    consumables: null
})

const accessFormSubmit = () => {
    accessForm.post(route('consumables.ssc.access.store'), {
        onSuccess: () => {
            addAccessDialog.value = false;
            onSuccess()
        }
    })
}
const selectUser = (value) => {
    accessForm.user = value.id
}
const selectConsumable = (value) => {
    accessForm.consumables = _.map(value, (item) => item.value);
}

onMounted(() => {
    onSuccess();
});
</script>
