<template>
    <Card title-bg="indigo-1"
          :title="$translate('module-delivery.title-order-'+method)"
          header-toolbar no-card-section hide-actions>
        <template v-slot:headerActions v-if="method==='list_all'">
            <q-btn icon="o_print"
                   :label="$translate('buttons.print')"
                   dense
                   color="indigo-4"
                   @click="dialogPrint = true"
            />
        </template>

        <q-table ref="tableRef"
                 row-key="id"
                 :rows="items"
                 :columns="columns"
                 :filter="filter"
                 :loading="loading"
                 @request="onRequest"
                 v-model:pagination="pagination"
                 :rows-per-page-options="[10,50,100]"
                 wrap-cells
                 flat
        >
            <template v-slot:top-row>
                <q-tr>
                    <q-td/>
                    <q-td colspan="100%">
                        <q-input v-model="filter" debounce="1000" :placeholder="$translate('search.element')"
                                 borderless dense
                                 clearable clear-icon="clear">
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:header-cell-status="props">
                <q-th :props="props">
                    <div class="flex items-center justify-center no-wrap">
                        <span v-html="props.col.label"></span>
                        <q-icon name="o_filter_alt"
                                size="xs"
                                color="grey-7"
                                :title="$translate('фильтр по статусу')"
                                class="q-ml-sm cursor-pointer">
                            <q-menu anchor="bottom middle" self="top middle">
                                <q-list dense>
                                    <template v-for="(name,state) in $translate('module-delivery.order.status')">
                                        <q-item dense clickable v-close-popup @click="filterBy('status',state)">
                                            <q-item-section>
                                                <q-item-label>{{ name }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-list>
                            </q-menu>
                        </q-icon>
                    </div>
                </q-th>
            </template>
            <template v-slot:body-cell-number="props">
                <q-td :props="props">
                    <a :href="$route('services.delivery.order.show',props.value)"
                       @click.prevent="router.visit($route('services.delivery.order.show',props.value))">
                        {{ props.value }}
                    </a>
                </q-td>
            </template>
            <template v-slot:body-cell="props">
                <q-td :props="props">
                    <div v-html="props.value"/>
                </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @on-success="onSuccess"
                    />
                </q-td>
            </template>
        </q-table>
    </Card>
    <q-dialog v-model="dialogPrint" no-backdrop-dismiss>
        <Card :title="$translate('module-delivery.title-order-reestr-print')"
              in-modal show-close-button header-toolbar>
            <DeliveryOrderReestrForm v-model="dialogForm"/>
            <template v-slot:actions>
                <q-btn icon="o_print"
                       :label="$translate('buttons.print')"
                       color="positive"
                       @click="printDialogSubmit"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {Card} from "@/Components/Block";
import DataTableActions from "@/Components/DataTableActions.vue";
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import DeliveryOrderReestrForm from "@/Components/Delivery/DeliveryOrderReestrForm.vue";

const props = defineProps({
    method: {
        type: String,
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const tableRef = ref();
const filter = ref('');
const loading = ref(false);
const items = ref([]);

const dialogPrint = ref(false)

const dialogForm = useForm({
    dates: null,
    state: [],
    address: [],
    goods: [],
});


const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {
        name: 'number',
        align: 'left',
        label: $translate('module-delivery.order.fields.number'),
        field: 'number',
        style: 'width: 120px'
    },
    {name: 'status', align: 'left', label: $translate('module-delivery.order.fields.status'), field: 'status'},
    {name: 'date', align: 'left', label: $translate('module-delivery.order.fields.date'), field: 'date'},
    {name: 'goods', align: 'left', label: $translate('module-delivery.order.fields.goods'), field: 'goods'},
    {name: 'address', align: 'left', label: $translate('module-delivery.order.fields.address'), field: 'address'},
];

const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});

const onRequest = (request) => {
    const {page, rowsPerPage, sortBy, descending} = request.pagination;
    const filter = request.filter;

    loading.value = true;

    let params = {
        m: props.method,
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {}
        }
    };
    if (_.size(filter) > 0) {
        params.datatable['search'] = filter;
    }
    axios.get(route('services.delivery.order.json'), {
        params: params,
    })
            .then((response) => {
                items.value = response.data.results.data;
                pagination.value.page = response.data.results.current_page;
                pagination.value.rowsPerPage = response.data.results.per_page;
                pagination.value.sortBy = sortBy;
                pagination.value.descending = descending;
                pagination.value.rowsNumber = response.data.results.total;
            })
            .finally(() => {
                loading.value = false;
            });
};

const filterBy = (field, value) => {
    filter.value = '' + field + ':"' + value + '"';
    onSuccess();
}

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};

const printDialogSubmit = () => {
    dialogForm.transform((data) => ({
        ...data,
        dates: {
            0: _.get(data.dates, 'from') || data.dates,
            1: _.get(data.dates, 'to'),
        }
    }))
            .post(route('services.delivery.order.reestr'))
}
onMounted(() => {
    onSuccess()
});
</script>
