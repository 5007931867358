<template>
    <q-field :label="$translate('consumables.fields.category')" tag="div" stack-label>
        <template v-slot:control>
            <div class="self-center full-width no-outline" tabindex="0">
                {{ _.get(consumable, 'category_name') }}
            </div>
        </template>
    </q-field>
    <q-field :label="$translate('consumables.fields.cas')" tag="div" stack-label>
        <template v-slot:control>
            <div class="self-center full-width no-outline" tabindex="0">
                {{ consumable.cas }}
            </div>
        </template>
    </q-field>
    <q-field :label="$translate('consumables.fields.vendor_number')" tag="div" stack-label>
        <template v-slot:control>
            <div class="self-center full-width no-outline" tabindex="0">
                {{ consumable.vendor_number ? consumable.vendor_number : '-' }}
            </div>
        </template>
    </q-field>
    <q-field :label="$translate('consumables.fields.type')" tag="div" stack-label>
        <template v-slot:control>
            <div class="self-center full-width no-outline" tabindex="0">
                {{ _.get(consumable, 'type_name') }}
            </div>
        </template>
    </q-field>
    <q-field :label="$translate('consumables.fields.units')" tag="div" stack-label>
        <template v-slot:control>
            <div class="self-center full-width no-outline" tabindex="0">
                {{ _.get(consumable, 'unit.name') }}
            </div>
        </template>
    </q-field>
    <q-field :prefix="$translate('consumables.fields.minimal')"
             :suffix="_.get(consumable, 'unit.name')"
             style="width: 270px;"
             tag="div"
             stack-label
    >
        <template v-slot:control>
            <div class="self-center full-width no-outline text-right" tabindex="0">
                {{ _.get(consumable, 'minimal') }}
            </div>
        </template>
    </q-field>
    <q-field :prefix="$translate('consumables.fields.loss')"
             suffix="%"
             style="width: 270px;"
             tag="div"
    >
        <template v-slot:control>
            <div class="self-center full-width no-outline text-right" tabindex="0">
                {{ _.get(consumable, 'loss') }}
            </div>
        </template>
    </q-field>
    <q-field v-if="_.get(consumable, 'internal')" style="width: 270px;" tag="div">
        <template v-slot:prepend>
            <q-icon name="o_check" color="positive"/>
        </template>
        <template v-slot:control>
            <div class="self-center full-width no-outline" tabindex="0">
                {{ $translate('consumables.fields.internal') }}
            </div>
        </template>
    </q-field>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import _ from "lodash";

const props = defineProps({
    consumable: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
</script>
