<template>
    <q-item dense>
        <q-item-section>
            <q-item-label class="text-info">
                {{ $translate('module-reportmaterial.status.' + project.pivot.status) }}
            </q-item-label>
        </q-item-section>
        <q-item-section v-if="project.pivot.status>=10">
            <q-btn :label="$translate('статусы согласования')"
                   icon-right="o_chevron_right"
                   color="secondary"
                   @click="expand = !expand"
                   dense flat no-caps/>
        </q-item-section>
    </q-item>

    <q-list v-if="expand" class="q-mb-sm">
        <template v-for="(sscResearch) in project.sscresearches">
            <q-item v-if="sscResearch.validating">
                <q-item-section class="q-ml-sm">
                    <q-item-label>
                        {{ sscResearch.ssc.name }}
                    </q-item-label>
                    <q-item-label class="text-secondary flex items-center">
                        <q-icon name="o_verified"/>
                        <span class="q-ml-sm">{{ sscResearch.validating.status_text }}</span>
                        <q-tooltip v-if="sscResearch.validating.status > 10" class="text-body2">
                            <div>{{ sscResearch.validating.comment }}</div>
                            <div>{{ sscResearch.validating.user.fulled_name }}</div>
                        </q-tooltip>
                    </q-item-label>
                </q-item-section>
                <q-item-section side top v-if="project.pivot.status < 50">
                    <ValidateDialog v-if="sscResearch.permission.validate"
                            :project="project"
                            :ssc-research="sscResearch"
                            :validation-requirements="publication.report_type.validation_requirements"/>
                </q-item-section>
            </q-item>
        </template>
    </q-list>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import ValidateDialog from "@/Components/ReportMaterial/Project/ValidateDialog.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    project: {type: Object},
    publication: {type: Object}
})

const expand = ref(false)
</script>