<template>
    <div class="clustered-column-chart" ref="chartRef"></div>
</template>

<script setup lang="ts">
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {onBeforeUnmount, onMounted, Ref, ref, ShallowRef, shallowRef, watch} from "vue";
import {ConfigHandler, IClusteredColumnChartConfig} from "@/entity/charts";
import {AnyValuesObject} from "@/entity/types";
import _ from "lodash";
import {set, toValue} from "@vueuse/core";

interface Props {
    data: AnyValuesObject[],
    legend: AnyValuesObject,
    config: IClusteredColumnChartConfig,
    locale: AnyValuesObject
}

const props = defineProps<Props>();

const configHandler: ConfigHandler = ConfigHandler.new(props.config);

const chartRef: Ref<any> = ref<any>();

const am5Root: ShallowRef = shallowRef<any>();
const am5Chart: ShallowRef = shallowRef<any>();
const am5YAxis: ShallowRef = shallowRef<any>();
const am5XAxis: ShallowRef = shallowRef<any>();
const am5Legend: ShallowRef = shallowRef<any>();

const createChart: Function = (): void => {
    clearRefs();

    let root: am5.Root = am5.Root.new(toValue(chartRef));
    root.setThemes([am5themes_Animated.new(root)]);
    root.locale = props.locale;
    root._logo!.dispose();
    set(am5Root, { root: root });

    set(am5Chart, {
        chart: am5Root.value.root.container.children.push(
            am5xy.XYChart.new(am5Root.value.root, Object.assign({}, configHandler.get("chartSettings", {}), {
                layout: am5Root.value.root.verticalLayout
            }))
        )
    });

    am5Chart.value.chart.get("colors").set("colors", getColors());

    set(am5YAxis, {
        yAxis: am5Chart.value.chart.yAxes.push(
            am5xy.ValueAxis.new(am5Root.value.root, getYAxisSettings())
        )
    });
    if (configHandler.has("valueYAxis.range")) {
        let rangeDataItem = am5YAxis.value.yAxis.makeDataItem(configHandler.get("valueYAxis.range.rangeSettings"));
        let range = am5YAxis.value.yAxis.createAxisRange(rangeDataItem);
        range.get("grid").setAll(Object.assign({}, configHandler.get("valueYAxis.range.rangeStyleSettings"), {
            stroke: am5.color(configHandler.get("valueYAxis.range.rangeStyleSettings.stroke"))
        }));
    }

    set(am5Legend, {
        legend: am5Chart.value.chart.children.push(
            am5.Legend.new(am5Root.value.root, {
                centerX: am5.p50,
                x: am5.p50
            })
        )
    })

    set(am5XAxis, {
        xAxis: am5Chart.value.chart.xAxes.push(
            am5xy.DateAxis.new(am5Root.value.root, getXAxisSettings())
        )
    });
    if (configHandler.has("dateXAxis.rotateLabels") && true === configHandler.get("dateXAxis.rotateLabels")) {
        let xRenderer = am5XAxis.value.xAxis.get("renderer");
        xRenderer.labels.template.setAll({
            rotation: 47,
            centerY: am5.p50
        });
    }

    am5XAxis.value.xAxis.data.setAll(props.data);

    _.forEach(props.legend, (item) => {
        let series = am5Chart.value.chart.series.push(
            am5xy.ColumnSeries.new(am5Root.value.root, {
                name: item.name,
                xAxis: am5XAxis.value.xAxis,
                yAxis: am5YAxis.value.yAxis,
                valueYField: item.valueYField,
                valueXField: item.valueXField
            })
        );
        series.columns.template.setAll({
            tooltipText: configHandler.get("columnSeries.tooltipText"),
            width: am5.percent(configHandler.get("columnSeries.width")),
            tooltipY: 0,
            strokeOpacity: 0,
            cursorOverStyle: "help", // "pointer"
        });

        series.data.setAll(props.data);

        series.appear(); // Make stuff animate on load

        series.bullets.push(() => am5.Bullet.new(am5Root.value.root, {
            locationY: 0,
            sprite: am5.Label.new(am5Root.value.root, {
                text: "{valueY}",
                fill: am5Root.value.root.interfaceColors.get("alternativeText"),
                centerY: 0,
                centerX: am5.p50,
                populateText: true
            })
        }));

        am5Legend.value.legend.data.push(series);
    });

    setChartWidgets();

    am5Chart.value.chart.appear(1000, 100);
};

const clearRefs: Function = (): void => {
    set(am5Root, null);
    set(am5Chart, null);
    set(am5YAxis, null);
    set(am5XAxis, null);
    set(am5Legend, null);
};

const getColors: Function = (): am5.Color[] => _.map(
    configHandler.get("chartColors"),
    (color) => am5.color(color)
);

const getYAxisSettings: Function = (): AnyValuesObject => ({
    renderer: am5xy.AxisRendererY.new(am5Root.value.root, configHandler.get("valueYAxis.rendererSettings", {})),
    tooltip: am5.Tooltip.new(am5Root.value.root, {})
});

const getXAxisSettings: Function = (): AnyValuesObject => ({
    baseInterval: configHandler.get("dateXAxis.baseInterval"),
    maxDeviation: configHandler.get("dateXAxis.maxDeviation"),
    renderer: am5xy.AxisRendererX.new(am5Root.value.root, configHandler.get("dateXAxis.rendererSettings", {})),
    tooltip: am5.Tooltip.new(am5Root.value.root, {})
});

const setChartWidgets: Function = (): void => {
    if (configHandler.has("chartWidgets")) {
        if (configHandler.has("chartWidgets.cursor")) {
            am5Chart.value.chart.set(
                "cursor",
                am5xy.XYCursor.new(am5Root.value.root, configHandler.get("chartWidgets.cursor"))
            );
        }
        if (configHandler.has("chartWidgets.scrollbarX")) {
            let scrollbarX = am5Chart.value.chart.set(
                "scrollbarX",
                am5.Scrollbar.new(am5Root.value.root, configHandler.get("chartWidgets.scrollbarX"))
            );
            scrollbarX.startGrip.set("scale", 0.7);
            scrollbarX.endGrip.set("scale", 0.7);
        }
    }
};

onMounted(() => {
    createChart();
});

onBeforeUnmount(() => {
    if (am5Root.value.root) {
        am5Root.value.root.dispose();
    }
});
</script>

<style scoped>
.clustered-column-chart {
    width: 100%;
    height: 100%;
}
</style>