<template>
    <q-card-section>
        <Card no-card-section hide-actions>
            <template v-slot:title>
                <q-toggle v-model="data.show"
                          :label="$translate('Отношения')"
                          dense/>
            </template>
            <q-card-section v-if="data.show">
                <q-input v-model="data.name"
                         :label="$translate('Наименование блока')"
                         dense/>
                <q-checkbox v-model="data.required"
                            :label="$translate('Обязательный блок')"
                            size="xs"
                            dense/>
            </q-card-section>
            <q-card-section v-if="data.show">
                <q-item-label>{{ $translate('Доступные отношения') }}</q-item-label>
                <q-option-group v-model="data.entity"
                                :options="options"
                                type="checkbox"
                                size="xs"/>
            </q-card-section>
        </Card>
    </q-card-section>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted} from "vue";
import _, {isNull} from "lodash";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const data = defineModel({
    default: {
        show: false,
        name: '',
        required: false,
        entity: []
    }
})
const options = computed(() => {
    return _.map(['publications', 'activities', 'awards', 'press', 'qualifications', 'events', 'edition'], (cat) => ({
        value: cat,
        label: $translate('report-type.type.' + cat)
    }))
})
onMounted(() => {
    if (!data.value.hasOwnProperty('entity') || !_.isArray(data.value.entity)) {
        _.set(data.value, 'entity', [])
    }
})
</script>