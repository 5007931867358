<template>
    <SettingsTable :data="config"/>
</template>

<script setup>
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

/**
 * Массив данных для отображения в таблице
 * @type {[{name: string, description: string, block: string, label: *, type: string, value: *}]}
 */
const config = [
    {
        name: 'PROJECT_NAME',
        label: $translate('settings.system.PROJECT_NAME'),
        value: _.get(props.settings, 'system.PROJECT_NAME', null),
        description: '',
        block: 'system',
        type: 'text',
    },
    {
        name: 'APP_NAME',
        label: $translate('settings.org-name'),
        value: _.get(props.settings, 'system.APP_NAME', null),
        description: '',
        block: 'system',
        type: 'text',
    },
    {
        name: 'APP_LOGO',
        label: $translate('settings.org-logo'),
        value: JSON.parse(_.get(props.settings, 'system.APP_LOGO', '[]')),
        append: _.get(JSON.parse(_.get(props.settings, 'system.APP_LOGO', '[]')), 'path', null),
        description: '',
        block: 'system',
        type: 'file:image',
        props: {
            accept: 'image/*'
        }
    },
    {
        name: 'INFOBANNER',
        label: $translate('settings.info-banner'),
        value: _.get(props.settings,'system.INFOBANNER'),
        description: '',
        block: 'system',
        type: 'textarea',
    },
    {
        name: 'PRIVACY_POLICY',
        label: $translate('settings.privacy-policy'),
        value: _.get(props.settings,'system.PRIVACY_POLICY'),
        description: '',
        block: 'system',
        type: 'textarea',
    },
    {
        name: 'PERSONAL_DATA',
        label: $translate('settings.personal-data'),
        value: _.get(props.settings,'system.PERSONAL_DATA'),
        description: '',
        block: 'system',
        type: 'textarea',
    },
    {
        name: 'LICENSE_KEY',
        label: $translate('settings.system.LICENSE_KEY'),
        value: _.get(props.settings,'system.LICENSE_KEY'),
        description: 'no license',
        block: 'system',
        type: 'textarea',
    }
];
</script>
