<template>
    <q-list v-if="services.hasOwnProperty('syexc')">
        <NavigationItem :title="$translate('module-syexc.proposal.title')"
                        :sidebar="sidebar"
                        icon="o_assignment"
                        color="dark">
            <template #items>
                <q-item v-if="services.syexc.items.requests.list"
                        @click="router.visit($route('services.sys-exp-ctrl.proposal.list'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate("module-syexc.proposal.title") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.syexc.items.requests.new"
                        @click="router.visit($route('services.sys-exp-ctrl.proposal.create'))"
                        clickable v-close-popup>
                    <q-item-section side class="q-pr-sm">
                        <q-icon name="o_add" size="xs" color="brand"/>
                    </q-item-section>
                    <q-item-section class="text-brand">
                        {{ $translate("module-syexc.actions.proposal.add") }}
                    </q-item-section>
                </q-item>
                <q-separator/>
                <q-input v-model="proposalNumber"
                         :label="$translate('menu.research-go-placeholder')"
                         class="q-ma-md"
                         outlined
                         dense>
                    <q-tooltip class="text-body2">{{ $translate('menu.research-go-title') }}</q-tooltip>
                    <template v-slot:append>
                        <q-btn icon="o_double_arrow" size="sm" @click="submitForm" flat/>
                    </template>
                </q-input>
            </template>
        </NavigationItem>
        <NavigationItem v-if="services.syexc.items.settings.directions || services.syexc.items.settings.committee"
                        :title="$translate('module-syexc.actions.manage')"
                        :sidebar="sidebar"
                        color="dark"
                        icon="o_construction">
            <template #items>
                <q-item v-if="services.syexc.items.settings.directions"
                        @click="router.visit($route('services.sys-exp-ctrl.directions.list'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate("module-syexc.directions.title") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.syexc.items.settings.committee"
                        @click="router.visit($route('services.sys-exp-ctrl.committee.list'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate("module-syexc.committee.title") }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
    </q-list>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";

const props = defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const page = usePage();
const services = computed(() => page.props.services);

const proposalNumber = ref(null);

const submitForm = () => {
    router.post($route("services.sys-exp-ctrl.proposal.go"), {
        "number": proposalNumber.value
    });
};
</script>
