<template>
    <Card :title="$translate('Добавить персону')"
          no-card-section in-modal show-close-button>
        <Alert variant="info" v-if="!disableCreate">
            Воспользуйтесь поиском для добавления существующего пользователя/сотрудника.<br/>
            Если пользователь не найден, воспользуйтесь добавлением новой персоны
        </Alert>
        <q-tabs v-model="tab" v-if="!disableCreate">
            <q-tab name="search"
                   :label="$translate('Поиск пользователя')"/>
            <q-tab name="new"
                   :label="$translate('Добавление новой персоны')"/>
        </q-tabs>

        <q-tab-panels v-model="tab">
            <q-tab-panel name="search">
                <SearchUser @onSelect="selectUser"
                            v-bind="props"/>
            </q-tab-panel>
            <q-tab-panel name="new">
                <Form v-model="newPerson"/>
            </q-tab-panel>
        </q-tab-panels>
        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   @click="setPerson"
                   color="positive"
                   dense/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Card, Alert} from "@/Components/Block";
import {User as SearchUser} from "@/Components/Search";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    withExternal: {type: Boolean, default: false},
    hideUser: {type: Boolean, default: false},
    withLdap: {type: Boolean, default: false},
    disableCreate: {type: Boolean, default: false},
})
const emit = defineEmits(['onSelect'])

const tab = ref('search')

const person = ref(null)

const selectUser = (user) => {
    person.value = user
    _.set(person.value, 'external', false)
}

const newPerson = useForm({
    first_name: null,
    middle_name: null,
    last_name: null,
    country_id: null,
    gender: null,
    company_id: null
})

const setPerson = () => {
    if (tab.value === 'search') {
        emit('onSelect', person.value)
    }
    if (tab.value === 'new') {
        axios.post(route('services.science.external-person.store'), newPerson)
            .catch((errors) => {
                _.forEach(errors.response.data.errors, (v, k) => {
                    _.set(newPerson.errors, k, v.join(";"))
                })
            })
            .then((response) => {
                person.value = {user: response.data.person}
                _.set(person.value.user, 'user_id', response.data.person.id)
                _.set(person.value, 'external', true)
            })
            .finally(() => {
                emit('onSelect', person.value)
            })
    }
}
</script>