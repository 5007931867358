<template>
    <ProjectLayout :project="project" tab="delivery">
        <q-bar class="bg-grey-3">
            {{ $translate('module-delivery.title-order-list_all') }}
        </q-bar>
        <DataTable :request-route="$route('project.delivery.order.json', {project:project.number, m: 'list_project', project_id: project.id})"
                   :columns="columns" allow-search>
            <template v-slot:body-cell="props">
                <q-td :props="props">
                    <div v-html="props.value"/>
                </q-td>
            </template>
        </DataTable>
    </ProjectLayout>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import ProjectLayout from "@/Components/Project/ProjectLayout.vue";
import DataTable from "@/Components/DataTable.vue";

const props = defineProps({
    project: {
        type: Object,
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {
        name: 'number',
        align: 'left',
        label: $translate('module-delivery.order.fields.number'),
        field: 'number',
        style: 'width: 120px'
    },
    {name: 'status', align: 'left', label: $translate('module-delivery.order.fields.status'), field: 'status'},
    {name: 'date', align: 'left', label: $translate('module-delivery.order.fields.date'), field: 'date'},
    {name: 'goods', align: 'left', label: $translate('module-delivery.order.fields.goods'), field: 'goods'},
    {name: 'address', align: 'left', label: $translate('module-delivery.order.fields.address'), field: 'address'},
];

</script>
