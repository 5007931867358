<template>
    <q-table ref="tableRef"
             row-key="id"
             :rows="items"
             :columns="columns"
             :filter="filter"
             :loading="loading"
             @request="onRequest"
             v-model:pagination="pagination"
             :rows-per-page-options="[10,50,100]"
             wrap-cells
    >
        <template v-slot:top-row>
            <q-tr>
                <q-td/>
                <q-td colspan="100%">
                    <q-input v-model="filter"
                             debounce="1000"
                             :placeholder="$translate('search.element')"
                             borderless dense
                             clearable clear-icon="clear">
                        <template v-slot:prepend>
                            <q-icon name="search"/>
                        </template>
                    </q-input>
                </q-td>
            </q-tr>
        </template>
        <template v-slot:body-cell="props">
            <q-td :props="props">
                <div v-html="props.value"/>
            </q-td>
        </template>
        <template v-slot:body-cell-datetime="props">
            <q-td :props="props">
                <div v-for="(date) in props.value">{{ date }}</div>
            </q-td>
        </template>
        <template v-slot:body-cell-imported="props">
            <q-td :props="props">
                <q-icon v-if="props.value"
                        name="mdi-database-import-outline"
                        class="cursor-help"
                        color="purple-5"
                        size="sm"
                >
                    <q-tooltip class="text-body2">
                        {{ $translate('publication.fields.imported') }}
                    </q-tooltip>
                </q-icon>
            </q-td>
        </template>
        <template v-slot:body-cell-name="props">
            <q-td :props="props">
                <template v-if="props.row.actions.hasOwnProperty('detail')">
                    <a :href="props.row.actions.detail.action"
                       @click.prevent="router.visit(props.row.actions.detail.action)">
                        {{ props.value }}
                    </a>
                </template>
                <template v-else>
                    {{ props.value }}
                </template>
            </q-td>
        </template>
        <template v-slot:body-cell-actions="props">
            <q-td :props="props" class="no-wrap">
                <DataTableActions :actions="props.row.actions"
                                  :name="props.row.name"
                                  @on-success="onSuccess"
                />
            </q-td>
        </template>
    </q-table>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import axios from "axios";
import DataTableActions from "@/Components/DataTableActions.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    category: {type: String},
    showAll: {type: Boolean, default: null},
    showValidating: {type: Boolean, default: null},
    assignSsc: {type: Boolean, default: null},
    assignProject: {type: String, default: null}
})

const tableRef = ref();
const filter = ref('');
const loading = ref(false);
const items = ref([]);
const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'imported', align: 'left', label: '', field: 'imported', style: 'width: 2rem'},
    {name: 'type', align: 'left', label: $translate('publication.fields.type'), field: 'report_type',},
    {name: 'name', align: 'left', label: $translate('publication.fields.name'), field: 'name'},
    {name: 'author', align: 'left', label: $translate('publication.fields.author'), field: 'author'},
    {name: 'datetime', align: 'left', label: $translate('publication.fields.datetime'), field: 'datetime'},
];

const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});

const onRequest = (request) => {
    const {page, rowsPerPage, sortBy, descending} = request.pagination;
    const filter = request.filter;

    loading.value = true;

    let params = {
        m: 'publications',
        all: props.showAll,
        validating: props.showValidating,
        category: props.category,
        assign_project: props.assignProject,
        ssc: props.assignSsc,
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {},
            search: filter
        }
    };
    axios.get(route('ajax.report', params))
            .then((response) => {
                items.value = response.data.results.data;
                pagination.value.page = response.data.results.current_page;
                pagination.value.rowsPerPage = response.data.results.per_page;
                pagination.value.sortBy = sortBy;
                pagination.value.descending = descending;
                pagination.value.rowsNumber = response.data.results.total;
            })
            .finally(() => {
                loading.value = false;
            });
};

const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};

onMounted(() => {
    onSuccess()
});
</script>