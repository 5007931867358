<template>
    <q-input v-model="competence.name"
             :label="$translate('user-competence.label.name')"
             :error-message="_.get(errors,'name')"
             :error="errors.hasOwnProperty('name')"/>

    <q-field :label="$translate('user-competence.label.description')"
             stack-label borderless
             :error-message="_.get(errors,'description')"
             :error="errors.hasOwnProperty('description')">
        <template v-slot:control>
            <div class="full-width">
                <q-editor v-model="competence.description"/>
            </div>
        </template>
    </q-field>

    <SearchEquipmentCatalogue :label="$translate('user-competence.label.equipment')"
                              @equipmentSelected="selectEquipment"/>

    <q-select v-if="skill"
              v-model="competence.user_skill_id"
              :label="$translate('user-competence.label.user_skill')"
              :options="skill"
              map-options
              emit-value
              use-chips
              options-dense
              :error-message="_.get(errors,'user_skill_id')"
              :error="errors.hasOwnProperty('user_skill_id')"/>

</template>

<script setup lang="ts">
import {getCurrentInstance, computed} from "vue";
import {usePage} from "@inertiajs/vue3";
import _ from "lodash";
import {EquipmentCatalogue as SearchEquipmentCatalogue} from "@/Components/Search/";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

const competence: any = defineModel()

const skill: any = computed(() => usePage().props.skill);

const errors: object = computed(() => _.get(competence, 'errors') || {})

const selectEquipment = (item: any) => {
    _.set(competence.value, 'equipment_id', _.get(item, 'id'))
}
</script>