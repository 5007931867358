<template>
    <Card :title="$translate('classificator.title-simple')"
          title-bg="deep-purple-1"
          header-toolbar hide-actions no-card-section>
        <q-list dense separator>
            <template v-for="(list) in classificators">
                <q-item>
                    <q-item-section>
                        <q-item-label @click="router.get($route('admin.list.simple.show',list.id))"
                                      class="cursor-pointer"
                                      clickable>
                            {{ list.name }}
                        </q-item-label>
                    </q-item-section>
                    <q-btn icon="list"
                           :title="$translate('classificator.actions.list')"
                           @click="router.get($route('admin.list.simple.show',list.id))"
                           flat no-caps/>
                </q-item>
            </template>
        </q-list>
    </Card>
</template>

<script setup>
import {router} from "@inertiajs/vue3";
import {Card} from "@/Components/Block/";
import {getCurrentInstance} from "vue";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

defineProps({
    classificators: {
        type: Object
    }
})
</script>