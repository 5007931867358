<template>
    <q-list v-if="services.hasOwnProperty('rid')">
        <NavigationItem :title="$translate('menu.rid.patent')"
                        :sidebar="sidebar"
                        color="blue-grey-13"
                        icon="mdi-scale-balance"
        >
            <template #items>
                <q-item v-if="services.rid.items.my"
                        @click="router.visit($route('services.rid.list'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("module-rid.menu.my") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.rid.items.new"
                        @click="router.visit($route('services.rid.create'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("module-rid.menu.new") }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
    </q-list>
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";

const props = defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const page = usePage();
const services = computed(() => page.props.services);
</script>
