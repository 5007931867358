<template>
    <Card title-bg="indigo-2"
          title-text="indigo-10"
          :title="title"
          :header-toolbar="can.create"
          no-card-section
          hide-actions
    >
        <template v-if="can.create" v-slot:headerActions>
            <q-btn :label="$translate('buttons.add')"
                   @click="router.get($route('services.microcollections.element.create'))"
                   color="indigo-5"
                   icon="o_add"
                   no-caps
                   dense
            />
        </template>
        <DataTable allow-search
                   :columns="columns"
                   :request-route="$route('ajax.microcollections', {m: 'list', part: part})"
        >
            <template v-slot:body-cell-file="props">
                <q-td :props="props">
                    <img v-if="!props.value"
                         src="/img/microcollections_noimg.jpg"
                         title="Нет картинки"
                         alt="noimg"
                         height="40"
                    />
                    <img v-else
                         :src="props.value.url"
                         :alt="props.value.name"
                         @click="imageDialog[props.row.id] = true"
                         class="cursor-zoom-in"
                         height="40"
                    />
                    <q-dialog v-if="props.value" v-model="imageDialog[props.row.id]">
                        <img :src="props.value.url" :alt="props.value.name"/>
                    </q-dialog>
                </q-td>
            </template>
        </DataTable>
    </Card>
</template>

<script setup>
import DataTable from "@/Components/DataTable.vue";
import {Card} from "@/Components/Block";
import {router} from "@inertiajs/vue3";
import {getCurrentInstance, ref} from "vue";

const props = defineProps({
    part: {
        type: Number,
        default: null
    },
    title: String,
    can: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'id', align: 'left', label: $translate('module-microcollections.element.list.fields.id'), field: 'id'},
    {name: 'file', align: 'left', label: $translate('module-microcollections.element.list.fields.file'), field: 'file'},
    {
        name: 'genus',
        align: 'left',
        label: $translate('module-microcollections.element.list.fields.genus'),
        field: 'genus'
    },
    {
        name: 'species',
        align: 'left',
        label: $translate('module-microcollections.element.list.fields.species'),
        field: 'species'
    },
    {
        name: 'strain',
        align: 'left',
        label: $translate('module-microcollections.element.list.fields.strain'),
        field: 'strain'
    },
    {
        name: 'synonym',
        align: 'left',
        label: $translate('module-microcollections.element.list.fields.synonym'),
        field: 'synonym'
    },
    {
        name: 'number',
        align: 'left',
        label: $translate('module-microcollections.element.list.fields.number'),
        field: 'number'
    },
    {
        name: 'shown',
        align: 'left',
        label: $translate('module-microcollections.element.list.fields.shown'),
        field: 'shown'
    },
];

const imageDialog = ref([]);
</script>
