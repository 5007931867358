<template>
    <q-select :label="$translate('Подразделение')"
              :hint="$translate('Выберите подразделение откуда требуется доставка')"
              v-model="order.from"
              :options="optionsSSC"
              options-dense
              emit-value
              map-options
              clearable clear-icon="clear"
              @update:model-value="selectSSC"
              :error="errors.hasOwnProperty('from')"
              :error-message="_.get(errors,'from')"
              class="q-mb-md"
    />

    <q-select v-if="order.from"
              v-model="order.goods"
              :label="$translate('Запрашиваемая позиция')"
              :hint="$translate('Выберите что требуется доставить')"
              :options="optionsGoods"
              options-dense
              emit-value
              map-options
              clearable clear-icon="clear"
              :loading="loadingGoods"
              @update:model-value="selectGoods"
              :error="errors.hasOwnProperty('goods')"
              :error-message="_.get(errors,'goods')"
              class="q-mb-md"
    />

    <div class="row q-mb-md" style="width: 100%; max-width: 400px" v-if="selectedGoods">
        <q-input :label="$translate('Требуемое количество')"
                 :hint="$translate('Доступно для заказа '+ selectedGoods.balance.count + selectedGoods.unit.name)"
                 v-model="order.count"
                 input-class="text-center"
                 class="col"
                 :error="errors.hasOwnProperty('count')"
                 :error-message="_.get(errors,'count')"
                 @update:model-value="loadDeliveryDates"/>

        <q-select v-model="order.unit"
                  :label="$translate('ед.изм.')"
                  :options="selectedGoods.all_units"
                  option-label="text"
                  options-dense
                  emit-value
                  map-options
                  class="col-8"
                  :error="errors.hasOwnProperty('unit')"
                  :error-message="_.get(errors,'unit')"
                  @update:model-value="loadDeliveryDates"/>
    </div>

    <div class="column q-mb-md" v-if="selectedGoods">
        <q-radio v-model="order.self"
                 :val=false
                 :label="$translate('Доставка по указанному адресу')"
                 size="sm"
                 @update:model-value="loadSscAddress"/>

        <div v-if="order.self === false" class="q-ml-xl q-mb-md">
            <q-select v-model="selectedSSCAddress"
                      :label="$translate('выберите адрес подразделения для доставки')"
                      :disable="newAddress"
                      :options="optionsSSCAddress"
                      options-dense
                      @update:model-value="onSelectSSCAddress">
                <template v-slot:no-option>
                    <q-item>
                        <q-item-section>
                            <q-item-label class="text-negative">в вашем подразделении не указаны адреса</q-item-label>
                        </q-item-section>
                    </q-item>
                </template>
            </q-select>

            <q-checkbox v-model="newAddress"
                        :label="$translate('указать новый/другой адрес')"
                        size="xs"/>

            <SearchAddress v-if="newAddress"
                           @onSelect="setNewAddress"/>

            <q-input v-model="order.room"
                     :label="$translate('корпус/номер помещения')"/>
        </div>

        <q-radio v-model="order.self"
                 :val=true
                 :label="$translate('Самовывоз с выбранного адреса')"
                 size="sm"
                 @update:model-value="loadSelfAddress"/>

        <div v-if="order.self === true" class="q-ml-xl q-mb-md">
            <q-select v-model="order.self_address"
                      :label="$translate('выберите адрес самовывоза')"
                      :options="optionsSelfAddress"
                      options-dense
                      emit-value
                      map-options
                      @update:model-value="selectSelfAddress">
                <template v-slot:no-option>
                    <q-item>
                        <q-item-section>
                            <q-item-label class="text-negative">данная позиция не доступна к самовывозу</q-item-label>
                        </q-item-section>
                    </q-item>
                </template>
            </q-select>
        </div>
    </div>

    <div class="flex q-mb-md">
        <q-field v-if="allowSelectDate"
                 :label="order.self?'Выберите дату самовывоза':'Выберите дату доставки'"
                 :stack-label="order.date!=null"
                 style="width: 100%; max-width: 400px"
                 :error="errors.hasOwnProperty('date')"
                 :error-message="_.get(errors,'date')"
        >
            <template v-slot:control>
                <div class="full-width text-center">
                    {{ order.date }}
                </div>
            </template>
            <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                        <q-date v-model="order.date"
                                today-btn
                                minimal
                                :options="dateOptions"
                                first-day-of-week="1">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup
                                       :label="$translate('buttons.select')"
                                       color="primary" flat/>
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-icon>
            </template>
        </q-field>
    </div>
    <q-input v-if="allowSelectDate"
             type="textarea"
             v-model="order.comment"
             label="Комментарий к заявке:">
    </q-input>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref, watch} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import SearchAddress from "@/Components/Search/SearchAddress.vue";
import moment from "moment";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    errors: {type: Object, default: null}
})

const order = defineModel({
    type: Object,
    default() {
        return {
            from: null,
            goods: null,
            count: null,
            unit: null,
            self: null,
            to_address: null,
            room: null,
            self_address: null,
            new_address: null,
            new_address_detail: null,
            date: null,
            comment: null,
        }
    }
})

const optionsSSC = ref([])
const optionsGoods = ref([])
const loadingGoods = ref(false)

/**
 * Загрузка списка подразделений из которых может производиться доставка
 */
const loadSSC = () => {
    axios.get('/delivery/order/ssc.json')
        .then((response) => {
            optionsSSC.value = _.map(response.data.data, (item) => ({
                value: item.value,
                label: item.label
            }))
        })
        .finally(() => {
        })
}

/**
 * Действия при выборе подразделения
 * @param value
 */
const selectSSC = (value) => {
    order.goods = null
    loadGoods()
}
/**
 * Выбранный товар
 *
 * @type {Ref<UnwrapRef<null>>}
 */
const selectedGoods = computed(() => {
    return _.find(optionsGoods.value, {value: order.value.goods})
})

/**
 * Действия при выборе товара
 * @param value
 */
const selectGoods = (value) => {
    order.value.count = null
    order.value.unit = null
    order.value.self = null
}
/**
 * Загрузка товаров для выбранного подразделения
 */
const loadGoods = () => {
    if (order.value.from) {
        loadingGoods.value = true;
        axios.get('/delivery/order/goods.json', {
            params: {
                ssc_id: order.value.from
            }
        })
            .then((response) => {
                optionsGoods.value = _.map(response.data.data, (item) => (_.assign(item, {id: item.value})))
            })
            .finally(() => {
                loadingGoods.value = false
            })
    }
}

const newAddress = ref(false)

const selectedSSCAddress = ref(null)

const onSelectSSCAddress = (address) => {
    order.value.to_address = address.value;
    order.value.room = _.get(address, 'room');
    loadDeliveryDates()
}

const optionsSSCAddress = ref([])

const loadSscAddress = () => {
    order.value.to_address = null
    order.value.room = null
    order.value.new_address = null
    order.value.new_address_detail = null
    order.value.self_address = null

    axios.get('/delivery/order/ssc_address.json', {})
        .then((response) => {
            optionsSSCAddress.value = response.data.data
        })
}
const setNewAddress = (value) => {
    order.value.to_address = null
    order.value.room = null
    order.value.new_address = value.unrestricted_value
    order.value.new_address_detail = value.data
    loadDeliveryDates()
}

const optionsSelfAddress = ref([])
const loadSelfAddress = () => {
    order.value.to_address = null
    order.value.room = null
    order.value.new_address = null
    order.value.new_address_detail = null
    order.value.self_address = null

    axios.get('/delivery/order/self_address.json', {
        params: {
            ssc_id: order.value.from,
            goods_id: order.value.goods
        }
    })
        .then((response) => {
            optionsSelfAddress.value = response.data.data;
        })
}

const selectSelfAddress = (value) => {
    loadDeliveryDates()
}

const allowSelectDate = computed(() => {
    return !!(order.value.to_address || order.value.self_address || order.value.new_address);
})
const allowedDates = ref([])
const loadDeliveryDates = () => {
    if (allowSelectDate) {
        axios.post('/delivery/order/dates.json', order.value)
            .then((response) => {
                allowedDates.value = response.data.enabled
            })
    }
}

/**
 * Проверка даты на доступность заполнения
 *
 * @param {Date} date
 * @returns {boolean}
 */
const dateOptions = (date) => {
    if (moment(date, 'YYYY/MM/DD').startOf('day').isBefore()) {
        return false
    }
    return _.includes(allowedDates.value, moment(date, 'YYYY/MM/DD').startOf('day').format('YYYY-MM-DD'))
}


onMounted(() => {
    loadSSC()
    loadGoods()
})
</script>