<template>
    <div v-if="!hideForm" class="row items-center q-mb-sm" style="max-width: 1000px">
        <div v-if="!hideEquipmentSearch" class="col">
            <SearchEquipment @equipmentSelected="setSelected"/>
        </div>
        <div v-if="!hidePeriod" class="col q-px-md" style="max-width: 300px">
            <q-field :label="$translate('module-analytics.fields.period')" class="cursor-pointer" stack-label>
                <template v-slot:control>
                    <div class="self-center full-width no-outline" tabindex="0">{{ loadingPeriodDisplay }}</div>
                </template>
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="loadingPeriod" range minimal>
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup :label="$translate('buttons.select')" color="primary" flat/>
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-field>
        </div>
        <div v-if="!hideEquipmentSearch || !hidePeriod" class="col-auto">
            <q-btn icon="visibility" :label="$translate('buttons.show')" @click="getEquipmentLoading"/>
        </div>
    </div>
    <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary"/>
        <q-item-label class="text-body1 text-primary q-mt-md">
            {{ $translate('Загрузка данных графика') }}
        </q-item-label>
    </q-inner-loading>
    <Chart v-if="!_.isNull(equipmentId) && !_.isEmpty(chartData)"
           chart-type="clustered-column"
           :data="chartData"
           :config="chartConfig"
           :legend="chartLegend"
    />
    <Alert v-if="showInfo" variant="info"
           :message="$translate('module-analytics.chart_extended.equipment_loading.equipment_required')"
    />
    <Alert v-if="showWarning" variant="warning"
           :message="$translate('module-analytics.chart_extended.equipment_loading.no_data')"
    />
</template>

<script setup lang="ts">
import {Alert} from "@/Components/Block";
import Chart from "@/Components/Chart.vue";
import {Equipment as SearchEquipment} from "@/Components/Search";
import {computed, ComputedRef, getCurrentInstance, onMounted, Ref, ref} from "vue";
import {ComponentInternalInstance} from "@vue/runtime-core";
import {IClusteredColumnChartConfig} from "@/entity/charts";
import {AnyValuesObject, StringValuesObject} from "@/entity/types";
import _ from "lodash";
import axios from "axios";
import moment from "moment";

interface Props {
    hideForm: boolean,
    hideEquipmentSearch: boolean,
    hidePeriod: boolean,
    currentEquipmentId: number,
    cardPeriod: boolean
}

interface ILegendItem {
    name: string,
    valueYField: string,
    valueXField: string
}

const props = withDefaults(defineProps<Props>(), {
    hideForm: false,
    hideEquipmentSearch: false,
    hidePeriod: false,
    cardPeriod: false
});

const app: ComponentInternalInstance | null = getCurrentInstance();
const $translate: Function = app!.appContext.config.globalProperties.$translate;
const $route: Function = app!.appContext.config.globalProperties.$route;

const equipmentId: Ref<number | null> = ref<number | null>(null);
const setSelected = (value: {id: number}) => {
    equipmentId.value = value.id;
};

const defaultLoadingPeriod: Function = (): StringValuesObject => ({
    from: moment().subtract(30, "days").format('YYYY/MM/DD'),
    to: moment().format('YYYY/MM/DD')
});
const cardLoadingPeriod: Function = (): StringValuesObject => ({
    from: moment().subtract(3, "months").format('YYYY/MM/DD'),
    to: moment().format('YYYY/MM/DD')
});

const loadingPeriod: Ref<StringValuesObject> = ref<StringValuesObject>(defaultLoadingPeriod());
const loadingPeriodDisplay: ComputedRef<string> = computed<string>(() => {
    let from = moment(loadingPeriod.value.from).format($translate('common.date.js')),
        to = moment(loadingPeriod.value.to).format($translate('common.date.js'));
    return [from, to].join(' — ');
});

const loading: Ref<boolean> = ref<boolean>(false);

const chartData: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);
const chartConfig: Ref<IClusteredColumnChartConfig> = ref<IClusteredColumnChartConfig>({
    chartSettings: {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true
    },
    chartColors: [
        "#67ce0c",
        "#ef6d10",
        "#FF0000",
        "#0000FF",
        "#7CFC00",
        "#FF1493",
        "#808000",
        "#800000",
        "#008000",
        "#9932CC",
        "#00FFFF",
        "#FF00FF",
        "#B8860B"
    ],
    chartWidgets: {
        cursor: {
            behavior: "none"
        },
        scrollbarX: {
            orientation: "horizontal",
            maxHeight: 6
        }
    },
    valueYAxis: {
        rendererSettings: {
            strokeOpacity: 0.1
        },
        range: {
            rangeSettings: {
                value: 24,
                endValue: undefined
            },
            rangeStyleSettings: {
                stroke: "#000000",
                strokeOpacity: 0.7,
                strokeWidth: 0.7,
                strokeDasharray: [10, 10],
                location: 1
            }
        }
    },
    dateXAxis: {
        maxDeviation: 0,
        baseInterval: {
            timeUnit: "day",
            count: 1
        },
        rendererSettings: {
            minGridDistance: 20
        },
        rotateLabels: true
    },
    columnSeries: {
        tooltipText: "{name} на {valueX.formatDate('dd.MM.yyyy')}: {valueY} часов",
        width: 90
    }
});
const chartLegend: Ref<{[p: string]: ILegendItem}> = ref<{[p: string]: ILegendItem}>({
    "0": {
        name: $translate('equipment.item.label.used-loading'),
        valueYField: "used_time",
        valueXField: "used_date"
    },
    "1": {
        name: $translate('equipment.item.label.planned-loading'),
        valueYField: "planned_time",
        valueXField: "used_date"
    }
});
const isLoaded: Ref<boolean> = ref<boolean>(false);

const showInfo: ComputedRef<boolean> = computed<boolean>(() => (
    _.isNull(equipmentId.value) && _.isEmpty(chartData.value) && !isLoaded.value)
);
const showWarning: ComputedRef<boolean> = computed<boolean>(() => (
    !_.isNull(equipmentId.value) && _.isEmpty(chartData.value) && isLoaded.value)
);

const getEquipmentLoading = (): void => {
    loading.value = true;
    if (equipmentId.value && loadingPeriod.value) {
        chartData.value = [];
        axios
            .get($route("ajax.equipment.stats", {
                m: "getEquipmentLoading",
                equipment_id: equipmentId.value,
                period: loadingPeriodDisplay.value
            }))
            .then((response) => {
                let data: AnyValuesObject[] = [];
                _.forEach(response.data.results, (v, k) => {
                    data.push(Object.assign({}, v, {
                        "planned_time": Number(v.planned_time),
                        "used_time": Number(v.used_time),
                        "used_date": moment(v.used_date).toDate().getTime()
                    }));
                });
                chartData.value = data;
            })
            .finally(() => {
                loading.value = false;
                isLoaded.value = true;
            });
    }
};

onMounted(() => {
    if (props.currentEquipmentId) {
        equipmentId.value = props.currentEquipmentId;
    }
    if (props.cardPeriod) {
        loadingPeriod.value = cardLoadingPeriod();
    }
    if (equipmentId.value && loadingPeriod.value) {
        getEquipmentLoading();
    }
});
</script>
