<template>
    <div v-if="data.module.delo.enabled">
        <Alert v-if="$translate('module-delo.description')"
               variant="info"
               :message="$translate('module-delo.description')"
        />
        <q-tabs v-model="tab" align="left" dense no-caps>
            <q-tab name="main" label="Настройки доступа"/>
            <q-tab name="projects" label="Создание проектов"/>
        </q-tabs>
        <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="main">
                <SettingsTable :data="config.main"/>
            </q-tab-panel>
            <q-tab-panel name="projects">
                <SettingsTable :data="config.projects"/>
            </q-tab-panel>
        </q-tab-panels>
    </div>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import helper from "@/helpers";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const tab = ref('main');

const config = {
    main: [
        {
            name: 'DELO_ENABLED',
            label: $translate('settings.module.delo.name'),
            value: props.settings.module.DELO_ENABLED,
            description: $translate('settings.module.delo.disable_help'),
            block: 'module',
            type: 'checkbox',
        },
        {
            name: 'DELO_HOST',
            label: $translate('module-delo.settings.DELO_HOST'),
            value: props.settings.module.DELO_HOST,
            description: $translate('module-delo.settings.DELO_HOST_description'),
            block: 'module',
            type: 'text',
        },
        {
            name: 'DELO_PORT',
            label: $translate('module-delo.settings.DELO_PORT'),
            value: props.settings.module.DELO_PORT,
            description: $translate('module-delo.settings.DELO_PORT_description'),
            block: 'module',
            type: 'number',
        },
        {
            name: 'DELO_DATABASE',
            label: $translate('module-delo.settings.DELO_DATABASE'),
            value: props.settings.module.DELO_DATABASE,
            description: $translate('module-delo.settings.DELO_DATABASE_description'),
            block: 'module',
            type: 'text',
        },
        {
            name: 'DELO_USERNAME',
            label: $translate('module-delo.settings.DELO_USERNAME'),
            value: props.settings.module.DELO_USERNAME,
            description: $translate('module-delo.settings.DELO_USERNAME_description'),
            block: 'module',
            type: 'text',
        },
        {
            name: 'DELO_PASSWORD',
            label: $translate('module-delo.settings.DELO_PASSWORD'),
            value: props.settings.module.DELO_PASSWORD,
            description: $translate('module-delo.settings.DELO_PASSWORD_description'),
            block: 'module',
            type: 'password',
        },
        {
            name: 'DELO_ENCRYPT',
            label: 'Шифрование',
            value: !_.isEmpty(props.settings.module.DELO_ENCRYPT) ? props.settings.module.DELO_ENCRYPT : 'no',
            description: '',
            block: 'module',
            type: 'select',
            multiple: false,
            options: ['yes', 'no', 'strict']
        },
    ],
    projects: [
        {
            name: 'DELO_PROJECT_INFO',
            label: $translate('module-delo.settings.DELO_PROJECT_INFO'),
            value: props.settings.module.DELO_PROJECT_INFO,
            description: $translate('module-delo.settings.DELO_PROJECT_INFO_description'),
            block: 'module',
            type: 'textarea',
        },
        {
            name: 'DELO_PROJECT_TYPES_RESTRICTIONS',
            label: 'Уникальное основание',
            value: helper.jsonParse(props.settings.module.DELO_PROJECT_TYPES_RESTRICTIONS),
            description: 'Типы проектов по которым нельзя создать проект если уже создан проект по такому основанию',
            block: 'module',
            type: 'select',
            multiple: true,
            options: props.data.module.delo.projectTypesTree
        },
        {
            name: 'DELO_HIDDEN_PROJECT_TYPES',
            label: 'Исключенные типы',
            value: helper.jsonParse(props.settings.module.DELO_HIDDEN_PROJECT_TYPES),
            description: 'Типы проектов которые исключены из создания по данному основанию',
            block: 'module',
            type: 'select',
            multiple: true,
            options: props.data.module.delo.projectTypesTree
        },
    ]
};
</script>
