<template>
    <Layout :department="department" tab="staff">
        <q-bar class="bg-grey-2 q-py-sm q-mb-sm">
            <h6 class="q-my-none">{{ $translate('ssc.title-staff-worktime') }}</h6>
            <q-space/>
            <q-btn-toggle v-model="tab"
                          spread no-caps
                          toggle-color="blue-grey-3" color="white" text-color="black"
                          :options="[{value: 'table', slot:'table'},{value: 'chart',slot:'chart'}]"
            >
                <template v-slot:table>
                    <q-icon name="sym_o_table" size="sm"/>
                    <q-tooltip>{{ $translate('buttons.view-as-table') }}</q-tooltip>
                </template>
                <template v-slot:chart>
                    <q-icon name="sym_o_grouped_bar_chart" size="sm"/>
                    <q-tooltip>{{ $translate('buttons.view-as-chart') }}</q-tooltip>
                </template>
            </q-btn-toggle>
        </q-bar>

        <template v-if="tab==='table'">
            <q-list separator>
                <template v-for="(item) in staff">
                    <q-item>
                        <q-item-section>
                            <q-item-label>
                                {{ item.name }}
                            </q-item-label>
                            <q-item-label caption>
                                {{ _.get(item, 'cadre_position.name') }} {{ _.get(item, 'rate_formatted') }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <WorktimeList :worktime="_.get(item, 'worktime')"/>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </template>
        <template v-if="tab==='chart'">
            <Chart chart-type="gantt-date"
                   :data="chartData"
                   :config="chartConfig"
                   :height="chartHeight"
            />
        </template>
    </Layout>
</template>

<script setup lang="ts">
import Layout from "@/Pages/SSC/Layout.vue";
import {getCurrentInstance, onMounted, Ref, ref} from "vue";
import {List as WorktimeList} from "@/Components/Cadre/Worktime";
import _ from "lodash";
import moment from "moment";
import Chart from "@/Components/Chart.vue";
import {CadreStaff, CadreWorktime} from "@/entity"
import {AnyValuesObject, StringValuesObject} from "@/entity/types";
import {IGanttDateChartConfig} from "@/entity/charts";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

interface Props {
    department: object,
    staff: CadreStaff[]
}

const props = defineProps<Props>()

const tab = ref('table')

const loading = ref(false)

const chartData: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);
const chartConfig: Ref<IGanttDateChartConfig> = ref<IGanttDateChartConfig>({
    chartSettings: {
        panX: false,
        panY: false,
        wheelX: "panX"
    },
    chartWidgets: {
        cursor: { behavior: "zoomX" }
    },
    categoryYAxis: {
        categoryField: "name",
        categories: [],
        rendererSettings: {
            minGridDistance: 30
        },
        rendererGridTemplateLocation: 1
    },
    dateXAxis: {
        baseInterval: { timeUnit: "hour", count: 1 },
        gridIntervals: [
            { timeUnit: "hour", count: 1 },
            { timeUnit: "day", count: 1 },
            { timeUnit: "week", count: 1 },
        ],
        dateFormats: { day: "EEEE" },
        rendererSettings: {
            strokeOpacity: 0.1
        }
    },
    columnSeries: {
        openValueXField: "start",
        valueXField: "end",
        categoryYField: "name",
        tooltipText: "{openValueX.formatDate('HH:mm')} - {valueX.formatDate('HH:mm')}",
        dataFieldToProcess: "worktime",
        dateFields: ["start", "end"],
        dateFormat: "yyyy-MM-dd HH:mm",
    }
});
const chartHeight: Ref<string> = ref<string>("80vh");

const dateFormat: Function = (value: string): string => moment(value, $translate("common.datetime.js")).format(
    moment.HTML5_FMT.DATE + " " + moment.HTML5_FMT.TIME // or moment.HTML5_FMT.DATETIME_LOCAL.replace("T", " ")
);

const worktimeActions: Function = (item: CadreStaff): boolean => _.size(_.find(item.worktime, (worktime) => worktime.status === 0)) > 0;

onMounted(() => {
    let data: AnyValuesObject[] = [];
    _.forEach(props.staff, (staff: CadreStaff) => {
        let staffWorktime: any[] = [];
        _.forEach(staff.worktime, (worktime: CadreWorktime) => {
            let worktimeWeekday: number = worktime.day;
            if (0 === worktimeWeekday) {
                worktimeWeekday = 7;
            }
            let dateOfTimeRange: string = moment().day(worktimeWeekday).format($translate("common.date.js"));
            staffWorktime.push(Object.assign({}, worktime, {
                [chartConfig.value.columnSeries.categoryYField]: _.get(staff, chartConfig.value.columnSeries.categoryYField),
                [chartConfig.value.columnSeries.openValueXField]: dateFormat(dateOfTimeRange + " " + worktime.time_start),
                [chartConfig.value.columnSeries.valueXField]: dateFormat(dateOfTimeRange + " " + worktime.time_end),
            }));
        });
        data.push({
            name: staff.name,
            worktime: staffWorktime
        });
    });
    chartData.value = data;
    let categories: StringValuesObject[] = _.map(chartData.value, (staff) => ({
        [chartConfig.value.categoryYAxis.categoryField]: staff[chartConfig.value.categoryYAxis.categoryField]
    }));
    chartConfig.value.categoryYAxis.categories = _.reverse(
        _.sortBy(
            _.uniqBy(categories, chartConfig.value.columnSeries.categoryYField),
            chartConfig.value.columnSeries.categoryYField
        )
    );
    if (1 === _.size(chartConfig.value.categoryYAxis.categories)) {
        chartHeight.value = "250px";
    }
});
</script>
