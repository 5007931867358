<template>
    <Alert v-if="$translate('module-reportmaterial.description')"
           variant="info"
           :message="$translate('module-reportmaterial.description')"
    />
    <q-tabs v-model="tab" align="left" dense no-caps>
        <q-tab name="base" :label="$translate('module-reportmaterial.tabs.base')"/>
        <q-tab name="types" :label="$translate('module-reportmaterial.tabs.types')"/>
    </q-tabs>
    <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="base">
            <SettingsTable :data="config.base"/>
        </q-tab-panel>
        <q-tab-panel name="types">
            <ReportMaterialSettingsTable :data="config.types"/>
            <!--            <template v-for="(category) in data.module.reportMaterial.category">
                            <q-bar class="bg-grey-1 text-grey-9">{{ $translate('report-type.type.' + category) }}</q-bar>
                            <SettingsTable :data="config.types[category]"/>
                        </template>-->
        </q-tab-panel>
    </q-tab-panels>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import ReportMaterialSettingsTable from "@/Components/Settings/ReportMaterialSettingsTable.vue";
import {getCurrentInstance, nextTick, onMounted, ref} from "vue";
import _ from "lodash";
import helper from "@/helpers";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const tab = ref('base');

const config = {
    base: [
        {
            name: 'REPORTMATERIAL_ENABLED',
            label: $translate('module-reportmaterial.settings.REPORTMATERIAL_ENABLED'),
            value: props.settings.module.REPORTMATERIAL_ENABLED,
            description: $translate('module-reportmaterial.settings.REPORTMATERIAL_ENABLED_description'),
            block: 'module',
            type: 'checkbox',
        },
        {
            name: 'REPORTMATERIAL_ROLE_ASSIGN_PROJECT',
            label: $translate('module-reportmaterial.settings.REPORTMATERIAL_ROLE_ASSIGN_PROJECT'),
            value: _.reject(helper.jsonParse(props.settings.module.REPORTMATERIAL_ROLE_ASSIGN_PROJECT), _.isNull),
            description: $translate('module-reportmaterial.settings.REPORTMATERIAL_ROLE_ASSIGN_PROJECT_description'),
            block: 'module',
            type: 'select',
            multiple: true,
            options: _.map($translate('report-type.author-roles'), (value, key) => ({
                label: value,
                value: key
            }))
        },
    ],
    types: {}
};

onMounted(() => {
    let REPORTMATERIAL_CATEGORYS_ENABLED = helper.jsonParse(_.get(props.settings.module, 'REPORTMATERIAL_CATEGORYS_ENABLED'));
    let REPORTMATERIAL_CATEGORYS_CREATE = helper.jsonParse(_.get(props.settings.module, 'REPORTMATERIAL_CATEGORYS_CREATE'));
    let REPORTMATERIAL_CATEGORYS_VALIDATION = helper.jsonParse(_.get(props.settings.module, 'REPORTMATERIAL_CATEGORYS_VALIDATION'));
    nextTick(() => {
        _.forEach(props.data.module.reportMaterial.category, (cat) => {
            _.set(config.types, cat, []);
            config.types[cat].push({
                name: 'REPORTMATERIAL_CATEGORYS_ENABLED',
                value: String(_.get(REPORTMATERIAL_CATEGORYS_ENABLED, cat)),
                category: cat,
                label: $translate('module-reportmaterial.settings.REPORTMATERIAL_CATEGORYS_ENABLED'),
                description: $translate('module-reportmaterial.settings.REPORTMATERIAL_CATEGORYS_ENABLED_description'),
                block: 'module',
                type: 'checkbox',
            })

            config.types[cat].push({
                name: 'REPORTMATERIAL_CATEGORYS_CREATE',
                label: $translate('module-reportmaterial.settings.REPORTMATERIAL_CATEGORYS_CREATE'),
                value: String(_.get(REPORTMATERIAL_CATEGORYS_CREATE, cat)),
                category: cat,
                description: $translate('module-reportmaterial.settings.REPORTMATERIAL_CATEGORYS_CREATE_description'),
                block: 'module',
                type: 'checkbox',
            })
        })
    })

    nextTick(() => {
        _.forEach(props.data.module.reportMaterial.category, (cat) => {
            let types = _.get(props.data.module.reportMaterial.types, cat);
            config.types[cat].push({
                label: $translate('module-reportmaterial.settings.REPORTMATERIAL_VALIDATION'),
                type: 'divider',
            })
            _.forEach(types, (type) => {
                config.types[cat].push({
                    name: 'REPORTMATERIAL_CATEGORYS_VALIDATION',
                    value: _.filter(_.get(_.get(REPORTMATERIAL_CATEGORYS_VALIDATION, cat), type.id)),
                    category: cat,
                    report_type_id: type.id,
                    label: type.name,
                    description: type.description,
                    block: 'module',
                    type: 'select',
                    multiple: true,
                    options: _.map($translate('module-reportmaterial.validation.blocks'), (value, key) => ({
                        label: value,
                        value: key
                    }))
                })
            })
        })
    })
})

</script>
