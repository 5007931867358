<template>
    <Alert v-if="!equipment.schedule" variant="danger"
           :message="$translate('equipment.item.error.no-common-worktime')"/>

    <Alert v-if="!equipment.loading" variant="danger"
           :message="$translate('equipment.item.error.no-planned-worktime')"/>

    <Card title-bg="deep-purple-1"
          header-toolbar
          no-card-section
          shrink
          hide-actions>
        <template v-slot:title>
            <div class="row items-center no-wrap">
                <q-img v-if="equipment.photo"
                       :src="equipment.photo"
                       loading="lazy"
                       error-src="/img/no-image.png"
                       width="4rem" height="4rem"
                       fit="scale-down"
                       class="q-mr-md"/>
                <div class="col-shrink">
                    <q-toolbar-title :title="equipment.display_name" class="ellipsis">
                        {{ equipment.display_name }}
                    </q-toolbar-title>
                    <q-item-label class="text-small">
                        <span>{{ $translate('equipment.state.title') }}: </span>
                        <span v-if="equipment.is_expired" class="text-negative">
                            {{ $translate('equipment.state.removed') }}
                        </span>
                        <span v-else-if="equipment.state" class="text-positive">
                            {{ $translate('buttons.on') }}
                        </span>
                        <span v-else class="text-negative">
                            {{ $translate('buttons.off') }}
                        </span>
                    </q-item-label>
                    <q-item-label class="text-small">ID: {{ equipment.id }}</q-item-label>
                </div>
            </div>
        </template>
        <template v-slot:headerActions>
            <q-btn icon="navigate_before"
                   :label="$translate('buttons.back')"
                   color="deep-purple-3"
                   dense
                   @click="router.get($route('equipment.list'))"
                   no-wrap
            />
        </template>
        <q-splitter v-model="splitterTab" unit="px">
            <template v-slot:before>
                <q-tabs vertical no-caps v-model="tabs" align="left">
                    <q-tab name="common"
                           :label="$translate('equipment.item.tabs.common')"/>
                    <q-tab v-if="equipment.equipment_type === 'complex'"
                           name="complex"
                           :label="$translate('equipment.item.tabs.complex')"/>
                    <q-tab name="finance"
                           v-if="equipment.permission.edit"
                           :label="$translate('equipment.item.tabs.finance')"/>
                    <q-tab name="work"
                           :label="$translate('equipment.item.tabs.work')"/>
                    <q-tab name="consumables"
                           :label="$translate('equipment.item.tabs.consumables')"/>
                    <q-tab name="loading"
                           v-if="equipment.permission.edit"
                           :label="$translate('equipment.item.tabs.loading')"/>
                    <q-tab name="document-and-identification"
                           :label="$translate('equipment.item.tabs.document-and-identification')"/>
                    <q-tab name="public"
                           :label="$translate('equipment.item.tabs.public')"/>
                </q-tabs>
            </template>
            <template v-slot:after>
                <q-tab-panels v-model="tabs" animated>
                    <q-tab-panel name="common">
                        <component :is="equipmentBlock('MainInformation')" :equipment="equipment"/>
                    </q-tab-panel>
                    <q-tab-panel name="complex" v-if="equipment.equipment_type === 'complex'">
                        <component :is="equipmentBlock('Complex')" :equipment="equipment"/>
                    </q-tab-panel>
                    <q-tab-panel name="finance">
                        <component :is="equipmentBlock('FinancialSingle')" :equipment="equipment"
                                               v-if="equipment.equipment_type === 'single'"/>
                        <component :is="equipmentBlock('FinancialComplex')" :equipment="equipment"
                                                v-if="equipment.equipment_type === 'complex'"/>
                    </q-tab-panel>
                    <q-tab-panel name="public">
                        <component :is="equipmentBlock('Additional')" :equipment="equipment"/>
                    </q-tab-panel>
                    <q-tab-panel name="document-and-identification">
                        <div class="q-gutter-y-md">
                            <component :is="equipmentBlock('Documentation')" :equipment="equipment"/>
                            <component :is="equipmentBlock('Identification')" :equipment="equipment"/>
                            <component :is="equipmentBlock('Classification')" :equipment="equipment"/>
                        </div>
                    </q-tab-panel>
                    <q-tab-panel name="consumables">
                        <component :is="equipmentBlock('Consumables')" :equipment="equipment"/>
                    </q-tab-panel>
                    <q-tab-panel name="loading">
                        <component :is="equipmentBlock('Usage')" :equipment="equipment"/>
                        <Card class="q-mt-md"
                              :title="$translate('equipment.item.tabs.loading-chart')"
                              no-card-section
                              hide-actions
                        >
                            <EquipmentLoadingChart :current-equipment-id="equipment.id"
                                                   card-period
                                                   hide-equipment-search
                            />
                        </Card>
                    </q-tab-panel>
                    <q-tab-panel name="work">
                        <component :is="equipmentBlock('WorkSchedule')" :equipment="equipment"/>
                        <!-- график -->
                    </q-tab-panel>
                </q-tab-panels>
            </template>
        </q-splitter>
    </Card>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import {Alert, Card} from "@/Components/Block";
import * as CardBlock from "@/Components/Equipment/Card";
import EquipmentLoadingChart from "@/Components/Analytic/Charts/EquipmentLoadingChart.vue";

const equipmentBlock = (block) => CardBlock[block]


const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

defineProps({
    equipment: {
        type: Object,
        required: true
    }
})

const splitterTab = ref(200)

const tabs = ref('common')

</script>