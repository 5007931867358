<template>
    <Card :title="moduleName" header-toolbar v-if="deliveryModule">
        <HasChanges v-if="settingsForm.isDirty"/>
        <div class="row q-gutter-x-md">
            <div class="col">
                <Card :title="$translate('ssc.settings.delivery.order.title')" no-card-section hide-actions >
                    <AlertInfo>{{ $translate('ssc.settings.delivery.order.description') }}</AlertInfo>
                    <q-card-section>
                        <div class="q-my-xs">
                            <q-checkbox size="sm" dense
                                        true-value="1" false-value="0"
                                        v-model="settingsForm['DELIVERY_ORDER_SSC_' + department.id + '_VALIDATE_NEW_DATE']"
                                        :label="$translate('module-delivery.settings.DELIVERY_ORDER_SSC_VALIDATE_NEW_DATE')"
                            />
                        </div>
                    </q-card-section>
                </Card>
            </div>
            <div class="col">

            </div>
        </div>
        <HasChanges v-if="settingsForm.isDirty"/>
        <template v-slot:actions>
            <q-btn color="positive" icon="save" :label="$translate('buttons.save')" @click="settingsFormSubmit"/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount} from "vue";
import _ from "lodash";
import {router, useForm, usePage} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import AlertInfo from "@/Components/Block/AlertInfo.vue";
import HasChanges from "@/Components/Block/HasChanges.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const props = defineProps({
    department: {},
})
const deliveryModule = computed(() => _.get(usePage().props.services, 'delivery'));
const moduleName = computed(() => _.get(usePage().props.services, 'delivery.name'));

let settingsForm = null;

onBeforeMount(() => {
    let form = {}
    let settings = usePage().props.settings

    _.set(form, "DELIVERY_ORDER_SSC_" + props.department.id + "_VALIDATE_NEW_DATE",
            _.get(settings, "DELIVERY_ORDER_SSC_" + props.department.id + "_VALIDATE_NEW_DATE", '0'));

    settingsForm = useForm(form);
});


const settingsFormSubmit = () => {
    settingsForm.post(route('ssc.settings.update', props.department), {
        onSuccess: () => {
            router.reload()
        }
    })
}
</script>