<template>
    <q-banner class="bg-light-blue-2 text-dark q-my-xs" dense>
        <q-item dense>
            <q-item-section side>
                <q-icon name="o_info" color="primary" size="md"/>
            </q-item-section>
            <q-item-section>
                <slot/>
            </q-item-section>
        </q-item>
    </q-banner>
</template>