<template>
    <h6 class="text-center q-my-xs">{{ $translate('project-expenses.title') }} #{{ project.number }}</h6>
    <q-field :label="$translate('project-expenses.project.number')" tag="section" stack-label borderless>
        <template v-slot:control>
            <div class="self-center full-width q-pl-md">
                #{{ project.number }}
            </div>
        </template>
    </q-field>
    <q-field :label="$translate('project-expenses.project.name')" tag="section" stack-label borderless>
        <template v-slot:control>
            <div class="self-center full-width q-pl-md">
                {{ project.name }}
            </div>
        </template>
    </q-field>
    <q-field :label="$translate('project-expenses.project.leader')" tag="section" stack-label borderless>
        <template v-slot:control>
            <div class="self-center full-width q-pl-md">
                {{ project.leader.fulled_name }}
            </div>
        </template>
    </q-field>
    <q-field :label="$translate('project-expenses.total-cost')" tag="section" stack-label borderless>
        <template v-slot:control>
            <div class="self-center full-width q-pl-md">
                {{ project.current_cost_formatted }}
            </div>
        </template>
    </q-field>


    <template v-for="(research) in project.expenses_report.report">
        <table class="full-width q-table q-table--dense q-table--bordered page-break">
            <caption>{{ $translate('project-expenses.research.name') }} #{{ research.number }}</caption>
            <tbody>
            <tr>
                <td>{{ $translate('project-expenses.equipment') }}</td>
                <td class="text-right q-pr-lg">{{ helper.formatMoney(calculateCost(_.get(research, 'expenses'), 'equipment')) }}</td>
            </tr>
            <tr>
                <td>{{ $translate('project-expenses.consumables') }}</td>
                <td class="text-right q-pr-lg">{{ helper.formatMoney(calculateCost(_.get(research, 'expenses'), 'consumables')) }}</td>
            </tr>
            <tr>
                <td>{{ $translate('project-expenses.worktime') }}</td>
                <td class="text-right q-pr-lg">{{ helper.formatMoney(calculateCost(_.get(research, 'expenses'), 'worktime')) }}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td>{{ $translate('project-expenses.total-cost-research') }}</td>
                <td class="text-right q-pr-lg">{{ helper.formatMoney(_.sumBy(_.values(_.get(research, 'expenses')), 'cost')) }}</td>
            </tr>
            </tfoot>
        </table>
    </template>
    <div class="small q-py-lg">
        Дата формирования отчета: {{
            moment(project.expenses_report.updated_at).format($translate('common.datetime.js'))
        }}
    </div>
</template>

<script setup>
import Print from "@/Layout/Print.vue";
import _ from "lodash";
import helper from "@/helpers";
import {getCurrentInstance} from "vue";
import moment from "moment/moment";


defineOptions({layout: Print});

const props = defineProps({
    project: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const calculateCost = (data, key) => {
    data = _.values(data)
    if (_.size(data) > 0) {
        let items = [];
        _.forEach(data, (item) => {
            if (_.size(_.get(item, key)) > 0) {
                items.push(_.get(item, key))
            }
        })
        items = _.filter(items);
        if (_.size(items) > 0) {
            return _.sumBy(items, 'cost');
        }
    }
    return 0;
}
</script>