<template>
    <Card title-bg="indigo-5" title-text="white"
          header-toolbar hide-actions no-card-section>
        <template v-slot:title>
            <div v-html="pageTitle"></div>
        </template>
        <ResearchList :state="state" :method="method"/>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import ResearchList from "@/Components/ProjectResearch/ResearchList.vue";
import {Card} from "@/Components/Block";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    can: {
        type: Object
    },
    state: {
        type: String,
        default: null
    },
    method: {
        type: String,
        default: 'list'
    }
})

const pageTitle = computed(() => {
    let title = $translate('research.title.'+props.method);

    if (props.state) {
        title += ' (' + $translate('research.title.research-' + props.state)+') '
    }
    return title
})
</script>