<template>
    <Card title-bg="deep-purple-2"
          :title="$translate('module-sensors.sensor-groups.list.title')"
          no-card-section
          header-toolbar
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn v-if="can.create"
                   :label="$translate('module-sensors.sensor-groups.list.actions.create')"
                   @click="router.get($route('services.sensors.sensor-groups.create'))"
                   icon="o_add"
                   color="deep-purple-5"
                   dense
            />
        </template>
        <q-table ref="tableRef"
                 :columns="columns"
                 :rows="sensorGroups"
                 :pagination="{rowsPerPage: 0}"
                 :rows-per-page-options="[0]"
                 hide-pagination
                 wrap-cells
                 flat
        >
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions" :name="props.row.name"/>
                </q-td>
            </template>
        </q-table>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import DataTableActions from "@/Components/DataTableActions.vue";
import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";

const props = defineProps({
    sensorGroups: Array,
    can: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const tableRef = ref();
const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'id', label: $translate('module-sensors.sensor-groups.list.fields.id'), field: 'id', style: 'width: 2rem'},
    {name: 'name', label: $translate('module-sensors.sensor-groups.list.fields.name'), field: 'name'},
];
</script>
