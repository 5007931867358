<template>
    <q-table :rows="items"
             :columns="columns"
             :rows-per-page-options="[10,50,100]"
             v-model:pagination="pagination"
             :loading="loading"
             table-header-class="bg-grey-1"
             flat dense wrap-cells>
        <template v-slot:body-cell-date="props">
            <q-td :props="props">
                {{ moment(props.row.used_date).format($translate('common.date.js')) }}
            </q-td>
        </template>
        <template v-slot:body-cell-equipment="props">
            <q-td :props="props">
                <a :href="props.row.equipment.full_link"
                   @click.prevent="router.visit(props.row.equipment.full_link)">
                    {{ props.row.equipment.display_name }}
                </a>
                <q-btn push color="primary" icon="mdi-folder-arrow-down-outline" dense square size="sm" flat
                       class="q-ml-sm"
                       v-if="_.size(props.row.complex_parts)>0">
                    <q-popup-proxy>
                        <q-list separator dense>
                            <template v-for="(complex) in props.row.complex_parts">
                                <q-item dense>
                                    <q-item-section>
                                        <q-item-label>{{ complex.equipment.display_name }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-list>
                    </q-popup-proxy>
                </q-btn>
            </q-td>
        </template>
        <template v-slot:body-cell-time="props">
            <q-td :props="props">
                {{ props.row.used_time_display }}
                ({{ props.row.used_time }} {{ $translate('units.min') }})
            </q-td>
        </template>
        <template v-slot:body-cell-author="props">
            <q-td :props="props">
                {{ props.row.user.fulled_name }}
            </q-td>
        </template>
        <template v-slot:body-cell-comment="props">
            <q-td :props="props">
                {{ props.row.comment }}
            </q-td>
        </template>
        <template v-slot:body-cell-actions="props">
            <q-td :props="props">
                <q-btn v-if="props.row.can.delete"
                       icon="o_delete"
                       size="sm"
                       color="negative"
                       @click="deleteItem(props.row)"
                       flat dense/>
            </q-td>
        </template>
    </q-table>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import moment from "moment";
import _ from "lodash";
import {router} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import {useReportDataStore} from "@/store/reportDataStore.js";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $q = useQuasar()

const reportDataStore = useReportDataStore()

const props = defineProps({
    items: {type: Array, default: []},
    loading: {type: Boolean, default: false}
})

const emit = defineEmits(['onSuccess'])

const columns = [
    {name: 'actions', label: '', style: 'width:20px'},
    {name: 'date', label: $translate('research-report.list.equipment.date'), style: 'width:100px', align: 'center'},
    {name: 'equipment', label: $translate('research-report.list.equipment.equipment'), align: 'left'},
    {name: 'time', label: $translate('research-report.list.equipment.time'), style: 'width:200px', align: 'left'},
    {name: 'author', label: $translate('research-report.list.equipment.author'), align: 'left'},
    {name: 'comment', label: $translate('research-report.list.equipment.comment'), align: 'left'},
]

const pagination = ref({
    page: 1,
    rowsPerPage: 10
})

const deleteItem = (item) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: item.equipment.display_name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('project.research.report.equipment.delete', {
            research: reportDataStore.research,
            equipment: item.id
        }), {
            onSuccess: () => {
                emit('onSuccess')
            }
        })
    })
}
</script>