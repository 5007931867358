<template>
    <Card :title="$translate('cadre-dutyjourney.title.all')"
          title-bg="deep-purple-1"
          header-toolbar hide-actions no-card-section>

        <template v-slot:headerActions>
            <BookmarkSelector @onSuccess="onSuccess"/>
        </template>

        <q-bar class="flex bg-grey-2 q-py-sm">
            <q-select v-model="currentDepartment"
                      :options="optionsDepartments"
                      :label="$translate('cadre-dutyjourney.fields.cadre_department')"
                      stack-label
                      option-value="id"
                      options-dense
                      map-options
                      emit-value
                      use-input
                      dense
                      clearable clear-icon="clear"
                      class="w-30 q-mr-md"
                      @filter="filterDepartment"
            />

            <q-select v-model="currentType"
                      :options="optionsType()"
                      :label="$translate('cadre-dutyjourney.fields.type')"
                      stack-label
                      options-dense
                      map-options
                      emit-value
                      dense
                      clearable clear-icon="clear"
                      class="w-30 q-mr-md"
            />

            <q-select v-model="currentYear"
                      :options="years"
                      :prefix="$translate('на')"
                      :suffix="$translate('units.year')"
                      options-dense
                      class="q-mr-md"
                      dense/>

            <q-btn icon="refresh"
                   :label="$translate('buttons.show')"
                   color="blue-grey-3"
                   @click="onSuccess"
            />
            <q-space/>
            <q-btn-toggle v-model="tab"
                          spread no-caps
                          toggle-color="blue-grey-3" color="white" text-color="black"
                          :options="[{value: 'table', slot:'table'},{value: 'chart',slot:'chart'}]"
                          @update:model-value="onSuccess"
            >
                <template v-slot:table>
                    <q-icon name="sym_o_table" size="sm"/>
                    <q-tooltip>{{ $translate('buttons.view-as-table') }}</q-tooltip>
                </template>
                <template v-slot:chart>
                    <q-icon name="sym_o_grouped_bar_chart" size="sm"/>
                    <q-tooltip>{{ $translate('buttons.view-as-chart') }}</q-tooltip>
                </template>
            </q-btn-toggle>
        </q-bar>

        <template v-if="tab === 'table'">
            <q-table ref="tableRef" wrap-cells
                     :rows="items" :columns="columns"
                     row-key="id"
                     v-model:pagination="pagination"
                     :filter="filter"
                     :loading="loading"
                     @request="onRequest"
                     :rows-per-page-options="[10,50,100]"
                     flat
            >
                <template v-slot:top-row>
                    <q-tr>
                        <q-td></q-td>
                        <q-td colspan="100%">
                            <q-input v-model="filter"
                                     :placeholder="$translate('search.element')"
                                     clear-icon="clear" debounce="1000"
                                     clearable borderless dense>
                                <template v-slot:prepend>
                                    <q-icon name="search"/>
                                </template>
                            </q-input>
                        </q-td>
                    </q-tr>
                </template>
                <template v-slot:body-cell-staff="props">
                    <q-td :props="props" class="no-wrap">
                        <div>{{ props.row.cadre_staff.staff }}</div>
                        <div class="text-smaller">Таб.№: {{ props.row.cadre_staff.personnel_number }}</div>
                    </q-td>
                </template>
                <template v-slot:header-cell-department="props">
                    <q-th :props="props" class="no-wrap">
                        <div>{{ $translate('cadre-dutyjourney.fields.cadre_department') }}</div>
                        <div>{{ $translate('cadre-dutyjourney.fields.cadre_staff') }}</div>
                    </q-th>
                </template>
                <template v-slot:body-cell-department="props">
                    <q-td :props="props" class="no-wrap">
                        <div v-html="props.row.cadre_staff.cadre_department"></div>
                        <div v-html="props.row.cadre_staff.cadre_position"></div>
                    </q-td>
                </template>

                <template v-slot:body-cell-period="props">
                    <q-td :props="props" class="no-wrap">
                        <div v-html="props.value"></div>
                    </q-td>
                </template>
                <template v-slot:body-cell-actions="props">
                    <q-td :props="props" class="no-wrap">
                        <DutyjourneyActions :dutyjourney="props.row" @onSuccess="onSuccess"/>
                    </q-td>
                </template>
            </q-table>
        </template>
        <template v-if="tab==='chart'">
            <Alert v-if="!currentDepartment" variant="danger"
                   :message="$translate('Выберите подразделение')"/>
            <template v-else>
                <Chart v-if="!_.isEmpty(chartData)"
                       chart-type="gantt-date"
                       :data="chartData"
                       :config="chartConfig"
                       height="80vh"
                />
            </template>
        </template>
    </Card>
</template>

<script setup lang="ts">
import {computed, ComputedRef, getCurrentInstance, onMounted, Ref, ref} from "vue";
import _ from "lodash";
import moment from "moment/moment";
import {Alert, Card} from "@/Components/Block";
import axios from "axios";
import {useQuasar} from "quasar";
import VacationActions from "@/Components/Cadre/Vacation/VacationActions.vue";
import {IGanttDateChartConfig} from "@/entity/charts";
import Chart from "@/Components/Chart.vue";
import {AnyValuesObject, StringValuesObject} from "@/entity/types";
import {toValue} from "@vueuse/core";
import DutyjourneyActions from "@/Components/Cadre/Dutyjourney/DutyjourneyActions.vue";
import BookmarkSelector from "@/Components/Cadre/Department/BookmarkSelector.vue";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route
const $q = useQuasar()

interface Props {
    departments: [],
    department?: number | null,
    years: [],
    year?: number | null,
}

const props = defineProps<Props>()

const tab = ref('table')

const loading = ref(false)

const currentYear = ref(props.year || moment().year())

const currentDepartment = ref(props.department)

const optionsDepartments = ref([])

const filterDepartment = (val: string, update: any) => {
    if (val === '') {
        update(() => {
            optionsDepartments.value = props.departments
        })
        return
    }

    update(() => {
        const needle = val.toLowerCase()
        optionsDepartments.value = props.departments.filter((v: any) => _.get(v, 'label').toLowerCase().indexOf(needle) > -1)
    })
}

const currentType = ref(null)

const optionsType = () => _.map($translate('cadre-dutyjourney.type'), (v, k) => ({value: k, label: v}))

const tableRef = ref()
const filter = ref('')
const items = ref([])
const columns: any = [
    {
        name: 'actions', align: 'center', label: '',
        style: "width: 2rem",
    },
    {
        name: 'staff', align: 'left', label: $translate('cadre-staff.fields.staff'),
        field: 'staff',
    },
    {
        name: 'department', align: 'left', label: '',
        field: 'department',
    },
    {
        name: 'period', align: 'left', label: $translate('cadre-dutyjourney.label.period'),
        field: 'period',
    },
    {
        name: 'type', align: 'left', label: $translate('cadre-dutyjourney.label.type'),
        field: 'type_text',
    },
    {
        name: 'status', align: 'left', label: $translate('cadre-dutyjourney.label.status'),
        field: 'status_text',
    },
]
const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
})

function onRequest(request: any) {
    const {page, rowsPerPage, sortBy, descending} = request.pagination
    const filter = request.filter

    loading.value = true

    let params = {
        m: 'list',
        department: currentDepartment.value,
        type: currentType.value,
        year: currentYear.value,
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {},
            search: null,
        }
    }
    if (_.size(filter) > 0) {
        params.datatable.search = filter
    }
    axios.get($route('ajax.cadre.dutyjourney', params))
            .then((response) => {
                items.value = response.data.results.data;
                pagination.value.page = response.data.results.current_page
                pagination.value.rowsPerPage = response.data.results.per_page
                pagination.value.sortBy = sortBy
                pagination.value.descending = descending
                pagination.value.rowsNumber = response.data.results.total
            })
            .finally(() => {
                loading.value = false
            })
}

const onSuccess = () => {
    switch (toValue(tab)) {
        case "table":
            if (toValue(tableRef)) {
                tableRef.value.requestServerInteraction();
            }
            break;
        case "chart":
            refillChartData();
            break;
    }
};

const chartData: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);
const chartConfig: ComputedRef<IGanttDateChartConfig> = computed<IGanttDateChartConfig>(() => ({
    chartSettings: {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
    },
    chartWidgets: {
        cursor: {behavior: "zoomX"},
    },
    categoryYAxis: {
        categoryField: "fulledName",
        categories: [],
        rendererSettings: {
            minGridDistance: 30
        },
        rendererGridTemplateLocation: 1
    },
    dateXAxis: {
        baseInterval: {timeUnit: "day", count: 1},
        gridIntervals: [
            {timeUnit: "day", count: 1},
            {timeUnit: "week", count: 1},
            {timeUnit: "month", count: 1},
        ],
        additionalSettings: {
            min: moment(currentYear.value + "-01-01").toDate().getTime(),
            max: moment(currentYear.value + "-12-31").toDate().getTime(),
            strictMinMax: true,
            tooltipDateFormat: "dd.MM.yyyy"
        },
        rendererSettings: {
            strokeOpacity: 0.1
        }
    },
    columnSeries: {
        openValueXField: "start",
        valueXField: "end",
        categoryYField: "fulledName",
        tooltipText: "{typeText} ({statusText}):\n[bold]{openValueX.formatDate('dd.MM.yyyy')}[/] - [bold]{valueX.formatDate('dd.MM.yyyy')}[/] ({cDays} дней)\n{comment}",
        dataFieldToProcess: "dutyjourneys",
        dateFields: ["start", "end"],
        dateFormat: "yyyy-MM-dd",
    },
    customColorLogic: {
        fieldName: "type",
        fieldValues: _.keys($translate("cadre-dutyjourney.type"))
    }
}));

interface IStaffDutyjourney {
    id: number,
    staffId: number,
    category: string,
    fulledName: string,
    start: string,
    end: string,
    type: string,
    typeText: string,
    statusText: string,
    comment: string,
    cDays: number,

    [p: string]: any
}

const refillChartData: Function = () => {
    if (toValue(currentDepartment)) {
        chartData.value = [];
        chartConfig.value.categoryYAxis.categories = [];
        axios
            .get($route("ajax.cadre.dutyjourney", {
                m: "chartDutyjourney",
                department: currentDepartment.value,
                year: currentYear.value,
                type: currentType.value
            }))
            .then((response) => {
                let categories: StringValuesObject[] = [], data: AnyValuesObject[] = [];
                _.forEach(response.data.results, (staffDutyjourneys: IStaffDutyjourney[]) => {
                    let firstStaffDutyjourney: IStaffDutyjourney = staffDutyjourneys[0],
                        processedData: AnyValuesObject = {
                            [chartConfig.value.categoryYAxis.categoryField]: firstStaffDutyjourney[chartConfig.value.categoryYAxis.categoryField],
                            [chartConfig.value.columnSeries.dataFieldToProcess]: []
                        };
                    _.forEach(staffDutyjourneys, (staffDutyjourney: IStaffDutyjourney) => {
                        categories.push({
                            [chartConfig.value.categoryYAxis.categoryField]: staffDutyjourney[chartConfig.value.categoryYAxis.categoryField]
                        });
                        if (!_.isNull(staffDutyjourney[chartConfig.value.columnSeries.openValueXField]) && !_.isNull(staffDutyjourney[chartConfig.value.columnSeries.valueXField])) {
                            processedData[chartConfig.value.columnSeries.dataFieldToProcess].push({
                                [chartConfig.value.columnSeries.openValueXField]: staffDutyjourney[chartConfig.value.columnSeries.openValueXField],
                                [chartConfig.value.columnSeries.valueXField]: staffDutyjourney[chartConfig.value.columnSeries.valueXField],
                                [chartConfig.value.columnSeries.categoryYField]: staffDutyjourney[chartConfig.value.columnSeries.categoryYField],
                                type: staffDutyjourney.type,
                                typeText: staffDutyjourney.typeText,
                                statusText: staffDutyjourney.statusText,
                                cDays: staffDutyjourney.cDays,
                                comment: staffDutyjourney.comment
                            });
                        }
                    });
                    data.push(processedData);
                });
                chartData.value = data;
                chartConfig.value.categoryYAxis.categories = _.reverse(
                    _.sortBy(
                        _.uniqBy(categories, chartConfig.value.categoryYAxis.categoryField),
                        chartConfig.value.categoryYAxis.categoryField
                    )
                );
            })
            .catch((e) => {
                console.error(e);
            });
    }
};

onMounted(() => {
    onSuccess()
})
</script>