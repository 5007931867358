<template>
    <Card :title="$translate('equipment.category.title.list')"
          title-bg="deep-purple-1"
          header-toolbar hide-actions no-card-section>
        <template v-slot:headerActions>
            <q-btn v-if="can.create"
                   color="deep-purple-3"
                   icon="add"
                   :label="$translate('buttons.add')"
                   @click="addDialog = true"
                   dense no-caps no-wrap
            />
        </template>
        <q-table ref="tableRef" wrap-cells
                 :rows="items"
                 :columns="columns"
                 :visible-columns="visibleColumns"
                 row-key="id"
                 v-model:pagination="pagination"
                 :filter="filter"
                 :loading="loading"
                 @request="onRequest"
                 :rows-per-page-options="[10,50,100]"
                 flat
        >
            <template v-slot:top-row>
                <q-tr>
                    <q-td></q-td>
                    <q-td colspan="100%">
                        <q-input v-model="filter"
                                 :placeholder="$translate('search.element')"
                                 debounce="1000"
                                 clear-icon="clear"
                                 borderless dense clearable>
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                    </q-td>
                </q-tr>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @on-success="onSuccess"/>
                </q-td>
            </template>
        </q-table>
    </Card>

    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('equipment.category.title.add')"
              title-bg="deep-purple-1"
              show-close-button in-modal no-card-section header-toolbar
        >
            <EquipmentCategoryForm v-model="addForm"/>
            <template v-slot:actions>
                <q-btn color="positive" icon="o_save"
                       :label="$translate('buttons.add')"
                       @click="submitForm"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref, computed} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import axios from "axios";
import _ from "lodash";
import {useQuasar} from 'quasar'
import {Card} from "@/Components/Block/";
import DataTableActions from "@/Components/DataTableActions.vue";
import EquipmentCategoryForm from "@/Components/EquipmentCategory/EquipmentCategoryForm.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $q = useQuasar()

const props = defineProps({
    can: {type: Object}
})
const tableRef = ref()
const filter = ref('')
const loading = ref(false)
const items = ref([])
const columns = [
    {
        name: 'actions', align: 'center', label: '',
        style: "width: 2rem",
    },
    {
        name: 'code', align: 'center', label: $translate('equipment.category.fields.code'),
        style: "width: 2rem",
        field: 'code',
        sortable: true
    },
    {
        name: 'name', align: 'left', label: $translate('equipment.category.fields.name'),
        field: 'name',
        sortable: true
    },
    {
        name: 'count', align: 'left', label: $translate('equipment.category.fields.count'),
        field: 'count',
    },
]
const visibleColumns = computed(() => [
    'actions',
    'name',
    !$q.screen.lt.md ? 'code' : null,
    !$q.screen.lt.md ? 'count' : null,
]);

const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
})

function onRequest(request) {
    const {page, rowsPerPage, sortBy, descending} = request.pagination
    const filter = request.filter

    loading.value = true

    let params = {
        m: 'list',
        page: page,
        per_page: rowsPerPage,
        datatable: {
            sort: {},
            search: filter
        }
    }
    if (sortBy) {
        _.set(params.datatable.sort, sortBy, {'field': sortBy, 'direction': descending ? 'asc' : 'desc'});
    }

    axios.get(route('ajax.equipment.category', params))
            .then((response) => {
                items.value = response.data.results.data;
                pagination.value.page = response.data.results.current_page
                pagination.value.rowsPerPage = response.data.results.per_page
                pagination.value.sortBy = sortBy
                pagination.value.descending = descending
                pagination.value.rowsNumber = response.data.results.total
            })
            .finally(() => {
                loading.value = false
            })
}


const addDialog = ref(false)
const addForm = useForm({
    code: null,
    name_i18n: _.mapValues($translate('fields.locale'), (lang, key) => null),
})

const onSuccess = () => {
    tableRef.value.requestServerInteraction()
}

const submitForm = () => {
    addForm.post(route('admin.equipment-category.store'), {
        onSuccess: () => {
            addDialog.value = false
            onSuccess()
        }
    })
}

onMounted(() => {
    onSuccess()
})
</script>