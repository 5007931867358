<template>
    <q-separator />
    <div class="flex q-my-sm q-mx-none q-gutter-x-sm items-center">
        <q-btn v-if="research.can.actions.simple"
               :label="$translate('project.research.actions.state_simple')"
               no-caps dense/>
        <q-btn v-if="research.can.actions.new"
               icon="add_task"
               :label="$translate('project.research.actions.state_new')"
               color="positive"
               @click="submitForm('new')"
               no-caps dense/>
        <q-btn v-if="research.can.actions.draft"
               icon="o_sticky_note_2"
               :label="$translate('project.research.actions.state_draft')"
               color="grey-8"
               @click="submitForm('draft')"
               no-caps dense/>
        <q-btn v-if="research.can.actions.approved"
               icon="o_check"
               :label="$translate('project.research.actions.state_approve')"
               color="positive"
               @click="submitForm('approved')"
               no-caps dense/>
        <q-btn v-if="research.can.actions.declined"
               icon="o_cancel"
               :label="$translate('project.research.actions.state_decline')"
               color="negative"
               @click="declineDialog = true"
               no-caps dense/>
        <q-btn v-if="research.can.actions.progress"
               icon="o_flag"
               :label="$translate('project.research.actions.work_start')"
               color="primary"
               @click="submitForm('progress')"
               no-caps dense/>
        <q-btn v-if="research.can.actions.return"
               icon="o_settings_backup_restore"
               :label="$translate('project.research.actions.work_return')"
               color="accent"
               @click="submitForm('return')"
               no-caps dense/>
        <q-btn v-if="research.can.actions.completed"
               icon="o_done_all"
               :label="$translate('project.research.actions.work_end')"
               color="positive"
               @click="submitForm('completed')"
               no-caps dense/>
        <q-btn v-if="research.can.actions.suspend"
               icon="o_pause"
               :label="$translate('buttons.suspend')"
               color="primary"
               @click="suspendDialog = true"
               no-caps dense/>
        <q-btn v-if="research.can.actions.unsuspend"
               icon="o_play_arrow"
               :label="$translate('buttons.unsuspend')"
               color="positive"
               @click="submitForm('unsuspend')"
               no-caps dense/>
        <q-btn v-if="research.can.actions.claim"
               icon="o_feedback"
               :label="$translate('project.research.actions.claim')"
               color="negative"
               @click="claimDialog = true"
               no-caps dense/>
        <q-btn v-if="research.can.delete"
               icon="o_delete"
               :label="$translate('buttons.delete')"
               color="negative"
               class="q-ml-auto q-mr-sm"
               @click="deleteResearch"
               no-caps dense/>

    </div>
    <q-dialog v-if="research.can.actions.declined"
              v-model="declineDialog"
              no-backdrop-dismiss>
        <Card :title="$translate('research.list.decline-modal-title')"
              in-modal show-close-button header-toolbar>
            <q-input v-model="form.comment"
                     type="textarea" rows="3"
                     :error="form.errors.hasOwnProperty('comment')"
                     :error-message="form.errors.comment"
            />
            <template v-slot:actions>
                <q-btn icon="o_cancel"
                       :label="$translate('project.research.actions.state_decline')"
                       color="negative"
                       @click="submitForm('declined')"/>
                <q-btn :label="$translate('buttons.close')"
                       color="dark"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-if="research.can.actions.suspend"
              v-model="suspendDialog"
              no-backdrop-dismiss>
        <Card :title="$translate('research.list.suspend-modal-title')"
              in-modal show-close-button header-toolbar>
            <q-input v-model="form.comment"
                     type="textarea" rows="3"
                     :error="form.errors.hasOwnProperty('comment')"
                     :error-message="form.errors.comment"
            />
            <template v-slot:actions>
                <q-btn icon="o_pause"
                       :label="$translate('project.research.actions.state_suspend')"
                       color="negative"
                       @click="submitForm('suspend')"/>
                <q-btn :label="$translate('buttons.close')"
                       color="dark"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-if="research.can.actions.claim"
              v-model="claimDialog"
              no-backdrop-dismiss>
        <Card :title="$translate('research.list.claim-modal-title')"
              in-modal show-close-button header-toolbar>
            <q-input v-model="form.comment"
                     :label="$translate('research.list.claim-modal-text-placeholder')"
                     type="textarea" rows="3"
                     :error="form.errors.hasOwnProperty('comment')"
                     :error-message="_.get(form.errors,'comment')"
            />
            <q-file v-model="form.file"
                    :label="$translate('research.list.claim-modal-file-placeholder')"
                    :error="form.errors.hasOwnProperty('file')"
                    :error-message="_.get(form.errors,'file')"
            />
            <template v-slot:actions>
                <q-btn icon="o_send"
                       :label="$translate('project.research.actions.claim')"
                       color="negative"
                       @click="submitClaim('claim')"/>
                <q-btn :label="$translate('buttons.close')"
                       color="dark"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>
    <q-separator v-if="hasActions"/>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from 'lodash';
import Card from "@/Components/Block/Card.vue";
import {useQuasar} from "quasar";

const $q = useQuasar();
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    research: {type: Object, required: true}
})

const hasActions = computed(() => !!_.filter(props.research.can.actions))

const form = useForm({
    state: null,
    comment: null,
    file: null
})
const submitForm = (action) => {
    form.transform((data) => ({
        ...data,
        state: action
    })).patch(route('project.research.update-state', [props.research]), {
        onSuccess: () => {
            declineDialog.value = false;
            suspendDialog.value = false;
            form.reset()
            router.reload()
        }
    })
}
const submitClaim = (action) => {
    form.transform((data) => ({
        ...data,
        state: action
    })).post(route('project.research.claim-store', [props.research]), {
        onSuccess: () => {
            claimDialog.value = false
            form.reset()
            router.reload()
        }
    })
}
const declineDialog = ref(false)
const suspendDialog = ref(false)
const claimDialog = ref(false)

const deleteResearch = () => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: props.research.template.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('project.research.delete', props.research))
    })
}
</script>