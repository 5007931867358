<template>
    <div class="row q-gutter-md" :class="{'column':$q.screen.lt.md}">
        <div class="col">
            <!-- основная информация -->
            <Card :title="$translate('equipment.item.tabs.common')" hide-actions>
                <q-field :label="$translate('equipment.item.fields.name')"
                         tag="div"
                         stack-label borderless>
                    <template v-slot:control>
                        <div class="full-width row items-start no-wrap">
                            <q-icon name="edit" color="grey-5" left v-if="can.edit"
                                    :title="$translate('buttons.edit')" class="cursor-pointer">
                                <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                              buttons auto-save anchor="top left"
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchEquipment" :title="$translate('equipment.item.fields.name')">
                                    <q-input v-model="scope.value.display_name"
                                             counter style="width: 600px;max-width: 70vw"/>
                                </q-popup-edit>
                            </q-icon>
                            <div>{{ equipment.display_name }}</div>
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.item.fields.equipment_type')"
                         tag="div"
                         stack-label borderless>
                    <template v-slot:control>
                        <div class="full-width">
                            {{ $translate('equipment.item.types.' + equipment.equipment_type) }}
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.item.fields.purpose')"
                         tag="div"
                         stack-label borderless>
                    <template v-slot:control>
                        <div class="full-width">
                            {{ $translate('equipment.item.purpose.' + equipment.purpose) }}
                        </div>
                    </template>
                </q-field>
                <q-field v-if="isSingle"
                         :label="$translate('equipment.item.fields.equipment_catalogue')"
                         tag="div"
                         stack-label borderless
                >
                    <template v-slot:control>
                        <div class="full-width">
                            <q-expansion-item :label="_.get(equipment,'equipment_catalogue.name')" dense
                                              header-class="q-px-none">
                                <q-field :label="$translate('equipment.item.fields.name')" dense borderless
                                         class="q-mx-md">
                                    <template v-slot:control>
                                        {{ _.get(equipment, 'equipment_catalogue.name') }}
                                    </template>
                                </q-field>
                                <q-field :label="$translate('equipment.item.fields.brand')" dense borderless
                                         class="q-mx-md">
                                    <template v-slot:control>
                                        {{ _.get(equipment, 'equipment_catalogue.brand') }}
                                    </template>
                                </q-field>
                                <q-field :label="$translate('equipment.item.fields.model')" dense borderless
                                         class="q-mx-md">
                                    <template v-slot:control>
                                        {{ _.get(equipment, 'equipment_catalogue.model') }}
                                    </template>
                                </q-field>
                                <q-field :label="$translate('equipment.item.fields.vendor')" dense borderless
                                         class="q-mx-md">
                                    <template v-slot:control>
                                        {{ _.get(equipment, 'equipment_catalogue.vendor') }}
                                    </template>
                                </q-field>
                                <q-field :label="$translate('equipment.item.fields.country')" dense borderless
                                         class="q-mx-md">
                                    <template v-slot:control>
                                        {{ _.get(equipment, 'equipment_catalogue.country_origin.name') }}
                                    </template>
                                </q-field>
                                <q-field :label="$translate('equipment.item.fields.category')" dense borderless
                                         class="q-mx-md">
                                    <template v-slot:control>
                                        {{ _.get(equipment, 'equipment_catalogue.equipment_category.name') }}
                                    </template>
                                </q-field>
                            </q-expansion-item>
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.item.fields.year_created')"
                         stack-label borderless
                         v-if="isSingle">
                    <template v-slot:control>
                        <div class="full-width row items-center">
                            <q-icon v-if="can.edit && !equipment.year_created"
                                    name="edit" color="grey-5" left
                                    :title="$translate('buttons.edit')" class="cursor-pointer">
                                <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                              buttons auto-save anchor="top left"
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchEquipment"
                                              :title="$translate('equipment.item.fields.dopinfo')">
                                    <q-input v-model="scope.value.year_created" type="number" min="1900"
                                             :max="new Date().getFullYear()"
                                             counter style="width: 600px;max-width: 70vw"/>
                                </q-popup-edit>
                            </q-icon>
                            <div>{{ equipment.year_created }}</div>
                        </div>
                    </template>
                </q-field>
                <q-field v-if="equipment.multi_assay>0" borderless
                         :label="$translate('equipment.item.fields.multi-assay-title')" stack-label>
                    <template v-slot:control>
                        <div class="self-center full-width">
                            <q-chip :title="$translate('equipment.item.fields.multi-assay-count')" outline square>
                                {{ equipment.multi_assay }}
                            </q-chip>
                        </div>
                    </template>

                </q-field>
                <q-field :label="$translate('equipment.item.fields.dopinfo')" stack-label borderless>
                    <template v-slot:control>
                        <div class="full-width row items-start no-wrap ">
                            <q-icon name="edit" color="grey-5" left v-if="can.edit"
                                    :title="$translate('buttons.edit')" class="cursor-pointer q-mt-xs">
                                <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                              buttons auto-save anchor="top left"
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchEquipment"
                                              :title="$translate('equipment.item.fields.dopinfo')">
                                    <q-editor ref="dopinfoRef"
                                              @paste="onPasteDopinfo"
                                              v-model="scope.value.dopinfo"
                                              counter style="width: 600px;max-width: 70vw"/>
                                </q-popup-edit>
                            </q-icon>
                            <div v-html="equipment.dopinfo"></div>
                        </div>
                    </template>
                </q-field>
            </Card>
        </div>
        <div class="col">
            <!-- размещение -->
            <Card :title="$translate('equipment.item.tabs.placement')" hide-actions class="q-mb-md">
                <q-field :label="$translate('equipment.item.fields.ssc')" stack-label borderless>
                    <template v-slot:control>
                        <div class="full-width self-center">
                            {{ equipment.ssc.name }}
                        </div>
                    </template>
                </q-field>
                <q-field :label="$translate('equipment.item.fields.placement_address')" stack-label borderless>
                    <template v-slot:control>
                        <div class="full-width items-start row">
                            <q-icon name="edit" color="grey-5" left v-if="can.edit"
                                    :title="$translate('buttons.edit')" class="cursor-pointer  q-mt-xs">
                                <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                              buttons auto-save anchor="top left"
                                              :label-set="$translate('buttons.save')"
                                              :label-cancel="$translate('buttons.cancel')"
                                              @save="patchEquipment"
                                              :title="$translate('equipment.item.fields.placement_address')">
                                    <q-input type="textarea" rows="4" v-model="scope.value.placement_address"
                                             counter style="width: 600px;max-width: 70vw"/>
                                </q-popup-edit>
                            </q-icon>
                            <div v-html="equipment.placement_address"></div>
                        </div>
                    </template>
                </q-field>
                <div class="row q-gutter-x-md">
                    <q-field :label="$translate('equipment.item.fields.placement_square')" stack-label borderless
                             class="col">
                        <template v-slot:control>
                            <div class="full-width row items-center">
                                <q-icon name="edit" color="grey-5" left v-if="can.edit"
                                        :title="$translate('buttons.edit')" class="cursor-pointer">
                                    <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                                  buttons auto-save anchor="top left"
                                                  :label-set="$translate('buttons.save')"
                                                  :label-cancel="$translate('buttons.cancel')"
                                                  @save="patchEquipment"
                                                  :title="$translate('equipment.item.fields.placement_square')">
                                        <q-input type="number" step="0.01" rows="4" min="0"
                                                 v-model="scope.value.placement_square"
                                                 counter/>
                                    </q-popup-edit>
                                </q-icon>
                                <div v-html="equipment.placement_square"></div>
                            </div>
                        </template>
                    </q-field>

                    <q-field :label="$translate('equipment.item.fields.equipment_square')" stack-label borderless
                             class="col">
                        <template v-slot:control>
                            <div class="full-width row items-center">
                                <q-icon name="edit" color="grey-5" left v-if="can.edit"
                                        :title="$translate('buttons.edit')" class="cursor-pointer">
                                    <q-popup-edit v-model="equipmentEdit" v-slot="scope"
                                                  buttons auto-save anchor="top left"
                                                  :label-set="$translate('buttons.save')"
                                                  :label-cancel="$translate('buttons.cancel')"
                                                  @save="patchEquipment"
                                                  :title="$translate('equipment.item.fields.equipment_square')">
                                        <q-input type="number" step="0.01" rows="4" min="0"
                                                 v-model="scope.value.equipment_square"
                                                 counter/>
                                    </q-popup-edit>
                                </q-icon>
                                <div v-html="equipment.equipment_square"></div>
                            </div>
                        </template>
                    </q-field>
                </div>
            </Card>

            <EquipmentInComplex :equipment="equipment"/>

            <!-- история вкл/выкл -->
            <Card hide-actions no-card-section>
                <template v-slot:title>
                    <div class="row items-center">
                        <div>{{ $translate('equipment.state.title') }}:&nbsp;&nbsp;</div>
                        <div class="text-center">
                            <div v-if="equipment.is_expired" class="text-negative">
                                {{ $translate('equipment.state.removed') }}
                                {{ moment(equipment.expired_at).format($translate('common.date.js')) }}
                            </div>
                            <div v-else-if="equipment.state" class="text-positive">
                                {{ $translate('equipment.state.actions.on') }}
                            </div>
                            <div v-else class="text-negative">
                                {{ $translate('equipment.state.actions.off') }}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:headerActions>
                    <q-btn v-if="can.check" @click="modalOnOff = true" dense
                           icon='power_settings_new' :label="$translate('equipment.state.actions.on-off')"
                           no-caps color="grey-9" text-color="white"/>
                    <q-btn v-if="can.offline" @click="modalRemoval = true" dense
                           icon='block' :label="$translate('equipment.item.actions.removal')"
                           no-caps color="deep-orange-9" text-color="white"/>
                </template>
                <q-item>
                    <q-item-section>
                        <q-item-label>{{ $translate('equipment.item.title.state-changes') }}</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn v-if="can.check"
                               @click="modalPeriod = true"
                               icon='add'
                               :label="$translate('equipment.item.actions.add-onoff-period')"
                               no-caps
                               size="sm"
                               color="grey-4"
                               text-color="grey-9"/>
                    </q-item-section>
                </q-item>
                <q-table dense :rows="equipment.state_changes" :columns="stateChangesTable" flat
                         :rows-per-page-options="[0]" hide-pagination hide-no-data>
                    <template v-slot:body-cell-file="props">
                        <q-td :props="props">
                            <q-btn icon="sym_o_file_present"
                                   :title="props.row.file.name"
                                   :href="props.row.file.href" target="_blank"
                                   dense
                                   flat
                            />
                        </q-td>
                    </template>
                </q-table>
            </Card>
        </div>
    </div>
    <q-dialog v-model="modalOnOff" no-backdrop-dismiss>
        <q-card style="width: 700px;max-width: 80vw;">
            <q-toolbar class="bg-grey-3">
                <div>{{ $translate('equipment.state.fields.state') }} &laquo;{{ equipment.display_name }}&raquo;</div>
                <q-space/>
                <q-btn flat icon="close" v-close-popup/>
            </q-toolbar>
            <q-card-section>
                <q-field :prefix="$translate('equipment.state.fields.state')" borderless
                         :error="formOnOff.errors.hasOwnProperty('state_type')"
                         :error-message="formOnOff.errors['state_type']">
                    <template v-slot:control>
                        <div class="row full-width">
                            <q-toggle v-model="formOnOff.state" disable
                                      :label="formOnOff.state ? $translate('equipment.state.actions.on') : $translate('equipment.state.actions.off')"
                                      :color="!formOnOff.state?'negative':'positive'" keep-color
                            />
                            <q-select v-model="formOnOff.state_type"
                                      :options="customState" option-value="id" option-label="name" options-dense
                                      class="col q-ml-sm"
                                      outlined dense emit-value map-options
                            />
                        </div>
                    </template>
                </q-field>
                <q-input type="date" v-model="formOnOff.date" style="width: 50%; margin-left: 150px"
                         :prefix="$translate('equipment.state.fields.date')"
                         input-class="text-center"
                         :min="onOffMinDate" :max="onOffMaxDate"
                         :error="formOnOff.errors.hasOwnProperty('date')"
                         :error-message="formOnOff.errors['date']"/>

                <q-input v-model="formOnOff.act"
                         :label="$translate('equipment.state.fields.act')" stack-label
                         :error="formOnOff.errors.hasOwnProperty('act')"
                         :error-message="formOnOff.errors['act']"/>

                <q-file v-model="formOnOff.file" accept=".pdf, image/*"
                        :label="$translate('equipment.state.fields.file')" stack-label
                        :error="formOnOff.errors.hasOwnProperty('file')"
                        :error-message="formOnOff.errors['file']">
                    <template v-slot:append>
                        <q-icon name="attach_file"/>
                    </template>
                </q-file>

                <q-input v-model="formOnOff.comment"
                         :label="$translate('equipment.state.fields.comment')" stack-label/>

            </q-card-section>
            <q-separator/>
            <q-card-actions align="center">
                <q-btn color="positive" :label="$translate('buttons.save')" icon="save" @click="submitFormOnOff"/>
            </q-card-actions>
        </q-card>
    </q-dialog>
    <q-dialog v-model="modalRemoval" no-backdrop-dismiss>
        <q-card style="width: 700px;max-width: 80vw;">
            <q-toolbar class="bg-grey-3">
                <div>{{ $translate('equipment.item.title.removal') }} &laquo;{{ equipment.display_name }}&raquo;</div>
                <q-space/>
                <q-btn flat icon="close" v-close-popup/>
            </q-toolbar>
            <q-banner class="bg-negative text-white">{{ $translate("equipment.item.title.removal-alert") }}</q-banner>
            <q-card-section>
                <q-input type="date" v-model="formRemoval.date" style="width: 50%;"
                         :label="$translate('equipment.state.fields.date-removal')"
                         input-class="text-center"
                         :min="onOffMinDate" :max="onOffMaxDate"
                         :error="formRemoval.errors.hasOwnProperty('date')"
                         :error-message="formRemoval.errors['date']"/>

                <q-input v-model="formRemoval.act"
                         :label="$translate('equipment.state.fields.act-removal')" stack-label
                         :error="formRemoval.errors.hasOwnProperty('act')"
                         :error-message="formRemoval.errors['act']"/>

                <q-file v-model="formRemoval.file" accept=".pdf, image/*"
                        :label="$translate('equipment.state.fields.file')" stack-label
                        :error="formRemoval.errors.hasOwnProperty('file')"
                        :error-message="formRemoval.errors['file']">
                    <template v-slot:append>
                        <q-icon name="attach_file"/>
                    </template>
                </q-file>

                <q-input v-model="formRemoval.comment"
                         :label="$translate('equipment.state.fields.comment')" stack-label/>
            </q-card-section>
            <q-separator/>
            <q-card-actions align="center">
                <q-btn color="positive" :label="$translate('equipment.item.actions.removal-confirm')" icon="save"
                       @click="submitFormRemoval"/>
            </q-card-actions>
        </q-card>
    </q-dialog>
    <q-dialog v-model="modalPeriod" no-backdrop-dismiss>
        <q-card style="width: 700px;max-width: 80vw;">
            <q-toolbar class="bg-grey-3">
                <div>
                    {{ $translate('equipment.item.title.state-period') }}
                    &laquo;{{ equipment.display_name }}&raquo;
                </div>
                <q-space/>
                <q-btn flat icon="close" v-close-popup/>
            </q-toolbar>
            <q-banner class="bg-light-blue-2">{{ $translate('equipment.item.info.state-period') }}</q-banner>
            <q-card-section>
                <div class="row q-gutter-x-md">
                    <q-input v-model="formPeriod.date[0]" class="col"
                             mask="date" :rules="['date']" readonly
                             :label="$translate('equipment.state.fields.select_date_0')" stack-label
                             input-class="text-center"
                             :error="formPeriod.errors.hasOwnProperty('date.0')"
                             :error-message="formPeriod.errors['date.0']">
                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                    <q-date v-model="formPeriod.date[0]" :options="equipment.workeddays" today-btn
                                            minimal first-day-of-week="1">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup :label="$translate('buttons.select')" color="primary"
                                                   flat/>
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-icon>
                        </template>
                    </q-input>
                    <q-input v-model="formPeriod.date[1]" class="col"
                             mask="date" :rules="['date']" readonly
                             :label="$translate('equipment.state.fields.select_date_1')" stack-label
                             input-class="text-center"
                             :error="formPeriod.errors.hasOwnProperty('date.1')"
                             :error-message="formPeriod.errors['date.1']">
                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                    <q-date v-model="formPeriod.date[1]" :options="equipment.workeddays" today-btn
                                            minimal first-day-of-week="1">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup :label="$translate('buttons.select')" color="primary"
                                                   flat/>
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-icon>
                        </template>
                    </q-input>
                </div>
                <div v-if="formPeriod.errors.hasOwnProperty('date')" class="text-negative">
                    {{ formPeriod.errors['date'] }}
                </div>

                <div class="row q-gutter-x-md">
                    <div class="col">
                        <q-input v-model="formPeriod.act[0]"
                                 :label="$translate('equipment.state.fields.act')" stack-label
                                 :error="formPeriod.errors.hasOwnProperty('act.0')"
                                 :error-message="formPeriod.errors['act.0']"/>

                        <q-file v-model="formPeriod.file[0]" accept=".pdf, image/*"
                                :label="$translate('equipment.state.fields.file')" stack-label
                                :error="formPeriod.errors.hasOwnProperty('file.0')"
                                :error-message="formPeriod.errors['file.0']">
                            <template v-slot:append v-if="!formPeriod.errors.hasOwnProperty('file.1')">
                                <q-icon name="attach_file"/>
                            </template>
                        </q-file>
                    </div>
                    <div class="col">
                        <q-input v-model="formPeriod.act[1]"
                                 :label="$translate('equipment.state.fields.act')" stack-label
                                 :error="formPeriod.errors.hasOwnProperty('act.1')"
                                 :error-message="formPeriod.errors['act.1']"/>

                        <q-file v-model="formPeriod.file[1]" accept=".pdf, image/*"
                                :label="$translate('equipment.state.fields.file')" stack-label
                                :error="formPeriod.errors.hasOwnProperty('file.1')"
                                :error-message="formPeriod.errors['file.1']">
                            <template v-slot:append v-if="!formPeriod.errors.hasOwnProperty('file.1')">
                                <q-icon name="attach_file"/>
                            </template>
                        </q-file>
                    </div>
                </div>
                <q-input v-model="formOnOff.comment"
                         :label="$translate('equipment.state.fields.comment')" stack-label/>

            </q-card-section>
            <q-separator/>
            <q-card-actions align="center">
                <q-btn color="positive" :label="$translate('buttons.save')" icon="save" @click="submitFormPeriod"/>
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash"
import moment from "moment";
import {Card} from "@/Components/Block";
import {EquipmentInComplex} from "@/Components/Equipment/Card";
import {useQuasar} from 'quasar'
import helpers from "@/helpers";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $q = useQuasar()

const props = defineProps({
    equipment: {
        type: Object
    },
})
const isComplex = computed(() => props.equipment.equipment_type === 'complex')
const isSingle = computed(() => props.equipment.equipment_type === 'single')

const can = computed(() => props.equipment.permission)
const customState = computed(() => usePage().props.customState)

const stateChangesTable = [
    {
        name: 'date',
        label: $translate('equipment.state.fields.date'), align: 'left',
        field: row => row.date,
        format: val => moment(val).format($translate('common.date.js')),
    },
    {
        name: 'state',
        label: $translate('equipment.state.fields.state'), align: 'left',
        field: row => row.state,
        format: val => (val ? $translate('equipment.state.actions.on') : $translate('equipment.state.actions.off')),
    },
    {
        name: 'file',
        label: $translate('equipment.state.fields.file'), align: 'center',
        field: row => row.file,
    },
    {
        name: 'created_at',
        label: $translate('equipment.state.fields.author'), align: 'left',
        field: row => row,
        format: val => moment(val.created_at).format($translate('common.date.js')) + " " + val.user.full_name,
    }
]

const modalOnOff = ref(false)

const formOnOff = useForm({
    state: !props.equipment.state,
    state_type: null,
    date: null,
    act: null,
    file: null,
    comment: null
})

const dopinfoRef = ref()

const onPasteDopinfo = (evt) => {
    helpers.sanitizeOnPaste(evt, dopinfoRef)
}

const onOffMinDate = computed(() => {
    let last = _.last(props.equipment.state_changes);
    if (last) {
        return moment(_.get(last, 'date')).format('YYYY-MM-DD')
    }
    return null
})

const onOffMaxDate = computed(() => {
    return moment().format('YYYY-MM-DD')
})

const submitFormOnOff = () => {
    formOnOff.post(route('equipment.state.update', props.equipment), {
        onSuccess: () => {
            modalOnOff.value = false
            router.reload()
        }
    })
}

const modalRemoval = ref(false)
const formRemoval = useForm({
    date: null,
    act: null,
    file: null,
    comment: null
})
const submitFormRemoval = () => {
    formRemoval.post(route('equipment.removal.confirm', props.equipment), {
        onSuccess: () => {
            modalRemoval.value = false
            router.reload()
        }
    })
}
const modalPeriod = ref(false)
const formPeriod = useForm({
    date: {
        0: null,
        1: null,
    },
    act: {
        0: null,
        1: null,
    },
    file: {
        0: null,
        1: null,
    },
    comment: null
})

const submitFormPeriod = () => {
    formPeriod.post(route('equipment.state.period.update', props.equipment), {
        onSuccess: () => {
            modalPeriod.value = false
            router.reload()
        }
    })
}

const form = useForm({})
const equipmentEdit = computed(() => props.equipment)
const patchEquipment = (value, oldValue) => {
    let newData = {}
    _.each(oldValue, (v, k) => {
        if (!_.isEqual(value[k], v)) {
            newData[k] = value[k];
        }
    })
    form.transform((data) => ({
        ...data,
        ...newData
    }))
            .patch(route('equipment.patch', props.equipment), {
                onSuccess: () => {
                    router.reload()
                }
            })
}

</script>