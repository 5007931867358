<template>
    <Card v-if="_.get(typeFields,'show')"
          :title="typeFields.name"
          hide-actions no-card-section>

        <q-list separator>
            <template v-for="(series) in publication.series">
                <q-item dense>
                    <q-item-section>
                        <q-item-label>{{ _.get(series,'edition.name') || '-' }}</q-item-label>
                        <q-item-label caption>
                            <div class="small mr-3">Том: {{ series.book ?? '-' }}</div>
                            <div class="small">Номер: {{ series.number ?? '-' }}</div>
                        </q-item-label>
                        <q-item-label caption>
                            <div class="small">Издатель: {{ _.get(series,'publisher.name') || '-' }}</div>
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>

    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import FieldsValues from "@/Components/Fields/FieldsValues.vue";
import _ from "lodash";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    publication: {type: Object}
})

const typeFields = computed(() => {
    return props.publication.report_type.fields.series;
})
</script>