<template>
    <Card :title="$translate('publication.title.list-my')"
          title-bg="purple-1"
          header-toolbar no-card-section hide-actions>
        <template v-slot:headerActions>
            <q-btn icon="mdi-sync" color="purple-3" label="Обновить данные из Pure" @click="syncWithPureDialog = true"/>
            <q-dialog v-model="syncWithPureDialog" no-backdrop-dismiss>
                <Card in-modal title="Принудительное обновление данных из Pure" show-close-button>
                    <Alert variant="info" message="Если у вас не отображаются какие-либо отчетные материалы, которые были добавлены в Pure более суток назад, вы можете воспользоваться данным функционалом (принудительное обновление данных из Pure)."/>
                    <Alert variant="danger">
                        Внимание!<br/>Данная операция занимает продолжительное время.<br/>По окончании обновления данных вы получите соответствующее уведомление.
                    </Alert>
                    <template v-slot:actions>
                        <q-btn color="positive"
                               :label="$translate('buttons.continue')"
                               @click="router.get($route('publication.sync'))"
                        />
                        <q-btn color="dark" :label="$translate('buttons.cancel')" v-close-popup/>
                    </template>
                </Card>
            </q-dialog>
        </template>
        <q-tabs align="left" v-model="tab">
            <template v-for="(category) in categorys">
                <q-tab :name="category"
                       :label="$translate('report-type.type.'+category)"/>
            </template>
        </q-tabs>

        <q-tab-panels v-model="tab">
            <template v-for="(category) in categorys">
                <q-tab-panel :name="category" class="q-pa-none">
                    <ReportMaterialList :category="category"
                                        :assign-project="assign_project"/>
                </q-tab-panel>
            </template>
        </q-tab-panels>
    </Card>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import ReportMaterialList from "@/Components/ReportMaterial/ReportMaterialList.vue";
import {Alert, Card} from "@/Components/Block";
import {router} from "@inertiajs/vue3";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    categorys: {
        type: Array,
    },
    assign_project: {
        type: String, default: null
    }
});

const tab = ref(_.first(props.categorys))

const syncWithPureDialog = ref(false);
</script>
