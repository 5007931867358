<template>
    <Card :title="$translate('module-syexc.proposal.card.expert_result.title')" hide-actions>
        <template v-slot:default>
            <div class="row q-py-xs">
                <div class="col-8">
                    {{ $translate('module-syexc.proposal.card.expert_result.fields.direction_name') }}
                </div>
                <div class="col">{{ _.get(proposal, 'direction.name') }}</div>
            </div>
            <div class="row q-py-xs border-top">
                <div class="col-8">
                    {{ $translate('module-syexc.proposal.card.expert_result.fields.direction_expert') }}
                </div>
                <div class="col">{{ _.get(proposal, 'direction.expert.fulled_name') }}</div>
            </div>
            <div v-if="_.get(proposal, 'conclusion_number')" class="row q-py-xs border-top">
                <div class="col-8">
                    {{ $translate('module-syexc.proposal.card.expert_result.fields.conclusion_number') }}
                </div>
                <div class="col">{{ _.get(proposal, 'conclusion_number') }}</div>
            </div>
            <div class="row q-py-xs border-top">
                <div class="col-8">
                    {{ $translate('module-syexc.proposal.card.expert_result.fields.conclusion.title') }}
                </div>
                <div class="col">
                    <div>
                        <a :href="conclusionUrl" class="text-brand cursor-pointer" target="_blank">
                            <q-icon name="o_file_download" size="sm" />
                            {{ $translate('module-syexc.proposal.card.expert_result.fields.conclusion.download_conclusion') }}
                        </a>
                    </div>
                    <div v-if="_.get(proposal, 'conclusion_file')">
                        <a :href="conclusionFileUrl" class="text-brand cursor-pointer" target="_blank">
                            <q-icon name="o_file_download" size="sm" />
                            {{ $translate('module-syexc.proposal.card.expert_result.fields.conclusion.download_conclusion_file') }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="row q-py-xs border-top">
                <div class="col-8">
                    {{ $translate('module-syexc.proposal.card.expert_result.fields.act.title') }}
                </div>
                <div class="col">
                    <div>
                        <a :href="actUrl" class="text-brand cursor-pointer" target="_blank">
                            <q-icon name="o_file_download" size="sm" />
                            {{ $translate('module-syexc.proposal.card.expert_result.fields.act.download_act') }}
                        </a>
                    </div>
                    <div v-if="_.get(proposal, 'act_file')">
                        <a :href="actFileUrl" class="text-brand cursor-pointer" target="_blank">
                            <q-icon name="o_file_download" size="sm" />
                            {{ $translate('module-syexc.proposal.card.expert_result.fields.act.download_act_file') }}
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="_.get(proposal, 'reg_number')" class="row q-py-xs border-top">
                <div class="col-8">{{ $translate('module-syexc.proposal.card.expert_result.fields.reg_number') }}</div>
                <div class="col">{{ _.get(proposal, 'reg_number') }}</div>
            </div>
            <div v-if="_.get(proposal, 'expert_comment')" class="row q-py-xs border-top">
                <div class="col-8">
                    {{ $translate('module-syexc.proposal.card.expert_result.fields.expert_comment') }}
                </div>
                <div class="col">{{ _.get(proposal, 'expert_comment') }}</div>
            </div>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {computed, getCurrentInstance} from "vue";
import _ from "lodash";

const props = defineProps({
    proposal: {
        type: Object,
        required: true
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const conclusionUrl = computed(() => route('services.sys-exp-ctrl.proposal.get-file', {
    proposal: _.get(props.proposal, 'number'),
    type: 'conclusion'
}));
const conclusionFileUrl = computed(() => route('services.sys-exp-ctrl.proposal.get-file', {
    proposal: _.get(props.proposal, 'number'),
    type: 'conclusion_file'
}));
const actUrl = computed(() => route('services.sys-exp-ctrl.proposal.get-file', {
    proposal: _.get(props.proposal, 'number'),
    type: 'act'
}));
const actFileUrl = computed(() => route('services.sys-exp-ctrl.proposal.get-file', {
    proposal: _.get(props.proposal, 'number'),
    type: 'act_file'
}));
</script>
