<template>
    <Card :title="$translate('module-syexc.proposal.card.detail.title')" hide-actions>
        <template v-slot:default>
            <q-field :label="$translate('module-syexc.proposal.card.detail.fields.user')" stack-label borderless>
                <template v-slot:control>
                    <StaffItem :user="proposal.user" hide-actions hide-avatar/>
                </template>
            </q-field>
            <q-field :label="$translate('module-syexc.proposal.card.detail.fields.description')" stack-label borderless>
                <template v-slot:control>
                    <div class="full-width self-center">{{ _.get(proposal, 'description') }}</div>
                </template>
            </q-field>
            <q-field :label="$translate('module-syexc.proposal.card.detail.fields.files')" stack-label borderless>
                <template v-slot:control>
                    <div class="full-width self-center" v-for="(file, i) in _.get(proposal, 'files')" :key="i">
                        <a :href="file.fullpath" target="_blank" class="text-brand cursor-pointer">
                            <q-icon name="o_file_download" size="xs"/>
                            {{ file.name }}
                        </a>
                    </div>
                </template>
            </q-field>
            <q-field v-if="_.get(proposal, 'comment')"
                     :label="$translate('module-syexc.proposal.card.detail.fields.comment')" stack-label borderless>
                <template v-slot:control>
                    <div class="full-width self-center">{{ _.get(proposal, 'comment') }}</div>
                </template>
            </q-field>
            <q-field v-if="40 === Number(_.get(proposal, 'status'))"
                     :label="$translate('module-syexc.proposal.card.detail.fields.conclusion_file')" stack-label
                     borderless>
                <template v-slot:control>
                    <div class="full-width self-center">
                        <a :href="conclusionFileUrl" class="text-brand cursor-pointer">
                            <q-icon name="o_file_download" size="xs"/>
                            {{ conclusionNumberText }}
                        </a>
                    </div>
                </template>
            </q-field>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {computed, getCurrentInstance} from "vue";
import _ from "lodash";
import StaffItem from "@/Components/SSC/Staff/StaffItem.vue";

const props = defineProps({
    proposal: {
        type: Object,
        required: true
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const conclusionFileUrl = computed(() => $route('services.sys-exp-ctrl.proposal.get-file', {
    proposal: _.get(props.proposal, 'number'),
    type: 'conclusion_file'
}));
const conclusionNumberText = computed(
    () => $translate('module-syexc.proposal.card.detail.fields.conclusion_number', {
        number: _.get(props.proposal, 'conclusion_number')
    })
);
</script>
