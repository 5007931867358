<template>
    <UserLayout :title="$translate('users.title-merge')">
        <q-card-section>
            <div class="row">
                <div class="col">
                    <SearchUser @onSelect="setSourceUser" with-unchecked
                                :label="$translate('users.merge.user-source')"/>
                    <div class="text-negative" v-if="form.errors.hasOwnProperty('source_user')">
                        {{ _.get(form.errors, 'source_user') }}
                    </div>
                </div>
                <div class="col-2 flex items-center justify-center">
                    <span class="text-body2">=></span>
                </div>
                <div class="col">
                    <SearchUser @onSelect="setTargetUser" with-unchecked
                                :label="$translate('users.merge.user-target')"/>
                    <div class="text-negative" v-if="form.errors.hasOwnProperty('target_user')">
                        {{ _.get(form.errors, 'target_user') }}
                    </div>
                </div>
            </div>
            <div v-if="sourceUser && targetUser">
                <div class="row q-mt-md">
                    <div class="col text-weight-bold">
                        {{ $translate("users.merge.main-info") }}
                    </div>
                    <div class="col-2"></div>
                    <div class="col text-weight-bold">
                        {{ $translate("users.merge.main-info") }}
                    </div>
                    <div class="col-24 text-primary text-center q-py-sm">
                        {{ $translate("users.merge.main-info-comment") }}
                    </div>
                </div>
                <div class="row q-mt-md">
                    <div class="col text-weight-bold">
                        <q-field :label="$translate('users.fields.user_id')" stack-label>
                            <template v-slot:control>{{ sourceUser.user_id }}</template>
                        </q-field>
                    </div>
                    <div class="col-2"></div>
                    <div class="col text-weight-bold">
                        <q-field :label="$translate('users.fields.user_id')" stack-label>
                            <template v-slot:control>{{ targetUser.user_id }}</template>
                        </q-field>
                    </div>
                </div>
                <div class="row q-mt-md">
                    <div class="col text-weight-bold">
                        <q-field :label="$translate('users.fields.email')" stack-label>
                            <template v-slot:control>{{ sourceUser.email }}</template>
                        </q-field>
                    </div>
                    <div class="col-2"></div>
                    <div class="col text-weight-bold">
                        <q-field :label="$translate('users.fields.email')" stack-label>
                            <template v-slot:control>{{ targetUser.email }}</template>
                        </q-field>
                    </div>
                </div>
                <div class="row q-mt-md">
                    <div class="col text-weight-bold">
                        <q-field :label="$translate('users.fields.first_name')" stack-label>
                            <template v-slot:control>{{ sourceUser.first_name }}</template>
                        </q-field>
                    </div>
                    <div class="col-2 text-center">
                        <q-checkbox v-model="form.primary_data.first_name" size="md"
                                    checked-icon="double_arrow" color="positive"
                                    unchecked-icon="clear"
                        />
                    </div>
                    <div class="col text-weight-bold">
                        <q-field :label="$translate('users.fields.first_name')" stack-label>
                            <template v-slot:control>{{ targetUser.first_name }}</template>
                        </q-field>
                    </div>
                </div>
                <div class="row q-mt-md">
                    <div class="col text-weight-bold">
                        <q-field :label="$translate('users.fields.middle_name')" stack-label>
                            <template v-slot:control>{{ sourceUser.middle_name }}</template>
                        </q-field>
                    </div>
                    <div class="col-2 text-center">
                        <q-checkbox v-model="form.primary_data.middle_name"
                                    checked-icon="double_arrow" color="positive"
                                    unchecked-icon="clear"/>
                    </div>
                    <div class="col text-weight-bold">
                        <q-field :label="$translate('users.fields.middle_name')" stack-label>
                            <template v-slot:control>{{ targetUser.middle_name }}</template>
                        </q-field>
                    </div>
                </div>
                <div class="row q-mt-md">
                    <div class="col text-weight-bold">
                        <q-field :label="$translate('users.fields.last_name')" stack-label>
                            <template v-slot:control>{{ sourceUser.last_name }}</template>
                        </q-field>
                    </div>
                    <div class="col-2 text-center">
                        <q-checkbox v-model="form.primary_data.last_name"
                                    checked-icon="double_arrow" color="positive"
                                    unchecked-icon="clear"/>
                    </div>
                    <div class="col text-weight-bold">
                        <q-field :label="$translate('users.fields.last_name')" stack-label>
                            <template v-slot:control>{{ targetUser.last_name }}</template>
                        </q-field>
                    </div>
                </div>
                <div class="row q-mt-md">
                    <div class="col text-weight-bold">
                        <q-field :label="$translate('users.fields.ext_login')" stack-label>
                            <template v-slot:control>{{ sourceUser.ext_login }}</template>
                        </q-field>
                    </div>
                    <div class="col-2 text-center">
                        <q-checkbox v-model="form.primary_data.ext_login"
                                    checked-icon="double_arrow" color="positive"
                                    unchecked-icon="clear"/>
                    </div>
                    <div class="col text-weight-bold">
                        <q-field :label="$translate('users.fields.ext_login')" stack-label>
                            <template v-slot:control>{{ targetUser.ext_login }}</template>
                        </q-field>
                    </div>
                </div>
            </div>
            <div v-if="sourceUser && targetUser">
                <div class="row q-mt-md">
                    <div class="col text-weight-bold">
                        {{ $translate("users.merge.additional-source") }}
                    </div>
                    <div class="col-2"></div>
                    <div class="col text-weight-bold">
                        {{ $translate("users.merge.additional-target") }}
                    </div>
                    <div class="col-24 text-primary text-center q-py-sm">
                        {{ $translate("users.merge.additional-comment") }}
                    </div>
                </div>
                <div class="row q-mt-md">
                    <div class="col text-weight-bold">
                        <template v-for="(additional) in sourceUser.additional_all">
                            <q-field :label="additional.description.name" stack-label>
                                <template v-slot:control>{{ additional.value }}</template>
                                <template v-slot:append>
                                    <q-btn-toggle v-model="form.additional_data[additional.id]"
                                                  toggle-color="positive"
                                                  dense no-caps
                                                  :options="[
                                                        {value: 'skip', slot: 'skip'},
                                                        {value: 'replace', slot: 'replace'},
                                                        {value: 'add', slot: 'add'}
                                                    ]">
                                        <template v-slot:skip>
                                            <div class="row items-center no-wrap">
                                                <q-icon name="clear"/>
                                                <span>{{ $translate('buttons.skip') }}</span>
                                            </div>
                                        </template>
                                        <template v-slot:replace>
                                            <div class="row items-center no-wrap">
                                                <q-icon name="sync"/>
                                                <span>{{ $translate('buttons.replace') }}</span>
                                            </div>
                                        </template>
                                        <template v-slot:add>
                                            <div class="row items-center no-wrap">
                                                <q-icon name="add"/>
                                                <span>{{ $translate('buttons.add') }}</span>
                                            </div>
                                        </template>
                                    </q-btn-toggle>
                                </template>
                            </q-field>
                        </template>
                    </div>
                    <div class="col-2 text-center">
                    </div>
                    <div class="col text-weight-bold">
                        <template v-for="(additional) in targetUser.additional_all">
                            <q-field :label="additional.description.name" stack-label>
                                <template v-slot:control>{{ additional.value }}</template>
                            </q-field>
                        </template>
                    </div>
                </div>
            </div>
        </q-card-section>
        <q-separator/>
        <q-card-actions align="center" v-if="sourceUser && targetUser">
            <q-btn icon="merge_type"
                   :label="$translate('buttons.merge')"
                   color="positive"
                   @click="submitForm"/>
        </q-card-actions>
    </UserLayout>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import axios from "axios";
import _ from "lodash";

import UserLayout from "@/Pages/Admin/Users/UserLayout.vue";
import SearchUser from "@/Components/Search/SearchUser.vue";

const app = getCurrentInstance();

const $route = app.appContext.config.globalProperties.$route;
const $translate = app.appContext.config.globalProperties.$translate;

const form = useForm({
    source_user: null,
    target_user: null,
    primary_data: {
        first_name: false,
        middle_name: false,
        last_name: false,
        ext_login: false,
    },
    additional_data: {}
})

const loadUser = (user_id, target) => {
    axios.get($route('ajax.users', {id: user_id, m: 'full'}))
        .then((response) => {
            target.value = response.data.results
        })
}
const sourceUser = ref(null)
const targetUser = ref(null)
const setSourceUser = (value) => {
    loadUser(value.id, sourceUser)
    form.source_user = value.id;
}
const setTargetUser = (value) => {
    loadUser(value.id, targetUser)
    form.target_user = value.id;
}
const submitForm = () => {
    form.post($route('admin.users.merge.process'), {
        onSuccess: () => {
            router.visit($route('admin.users.index'))
        }
    })
}
</script>