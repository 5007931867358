<template>
    <Alert variant="danger" v-if="!_.isEmpty(_.get(project,'sourceInfo.info'))">
        {{ _.get(project, 'sourceInfo.info') }}
    </Alert>
    <Card :title="$translate('project.form.title.project-info')"
          class="q-mb-md"
          no-card-section hide-actions>
        <q-card-section>
            <q-item-label caption>{{ $translate('project.form.fields.name') }}*:</q-item-label>
            <q-input v-model="project.name"
                     type="textarea" rows="3"
                     counter maxlength="10000"
                     :error="project.errors.hasOwnProperty('name')"
                     :error-message="_.get(project.errors,'name')"/>
        </q-card-section>
        <q-card-section>
            <q-item-label caption>{{ $translate('project.form.fields.description') }}*:</q-item-label>
            <q-field
                :error="project.errors.hasOwnProperty('description')"
                :error-message="_.get(project.errors,'description')">
                <template v-slot:control>
                    <div class="full-width">
                        <q-editor ref="descriptionRef" v-model="project.description" @paste="onPasteDescription" flat/>
                    </div>
                </template>
            </q-field>
        </q-card-section>
        <q-card-section>
            <q-item-label caption>{{ $translate('project.form.fields.targets') }}*:</q-item-label>
            <q-field
                :error="project.errors.hasOwnProperty('targets')"
                :error-message="_.get(project.errors,'targets')">
                <template v-slot:control>
                    <div class="full-width">
                        <q-editor ref="targetsRef" v-model="project.targets" @paste="onPasteTargets" flat/>
                    </div>
                </template>
            </q-field>
        </q-card-section>

        <FieldsRendering :fields="_.get(project,'type.fields_inherit') || {}"
                         v-model="project.additional"
                         :errors="_.get(project,'errors')"
                         error-prefix="additional."/>

        <q-card-section>
            <q-item-label caption>{{ $translate('project.form.fields.keywords') }}:</q-item-label>
            <q-select v-model="project.keywords"
                      use-chips
                      use-input
                      new-value-mode="add-unique"
                      multiple
                      :error="project.errors.hasOwnProperty('keywords')"
                      :error-message="_.get(project.errors,'keywords')">

            </q-select>
        </q-card-section>

        <q-card-section>
            <q-item-label caption>{{ $translate('project.form.fields.period') }}*:</q-item-label>
            <div class="row q-gutter-x-md">
                <q-input mask="date" v-model="project.date_start"
                         :prefix="$translate('project.form.fields.period-from')"
                         input-class="text-center"
                         style="width: 300px;"
                         :error="project.errors.hasOwnProperty('date_start')"
                         :error-message="_.get(project.errors,'date_start')">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                <q-date v-model="project.date_start" :options="startDateOptions"
                                        minimal>
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup :label="$translate('buttons.select')" color="primary" flat/>
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
                <q-input mask="date" v-model="project.date_end"
                         :prefix="$translate('project.form.fields.period-to')"
                         input-class="text-center"
                         style="width: 300px;"
                         :error="project.errors.hasOwnProperty('date_end')"
                         :error-message="_.get(project.errors,'date_end')">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                <q-date v-model="project.date_end"
                                        :options="endDateOptions"
                                        today-btn
                                        minimal
                                        first-day-of-week="1">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup :label="$translate('buttons.select')" color="primary" flat/>
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
            </div>
        </q-card-section>
    </Card>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import moment from "moment";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";
import {Alert, Card} from "@/Components/Block";
import helpers from "@/helpers";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const project = defineModel({type: Object})

const emit = defineEmits(['update:project'])

const source = ref(null)

const descriptionRef = ref()
const targetsRef = ref()

const updateFields = (fields) => {
    _.set(project.value, 'additional', fields)
}
const startDateOptions = (date) => {
    return moment(date).startOf('day').isSameOrAfter(moment().startOf('day'))
}
const endDateOptions = (date) => {
    return moment(date).startOf('day').isSameOrAfter(moment().startOf('day'))
        && moment(date).startOf('day').isAfter(moment(project.value.date_start).startOf('day'))
}

const onPasteDescription = (evt) => {
    helpers.sanitizeOnPaste(evt, descriptionRef)
}
const onPasteTargets = (evt) => {
    helpers.sanitizeOnPaste(evt, targetsRef)
}
</script>
