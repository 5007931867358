<template>
    <DataTableActions v-if="hasActions">
        <q-item v-if="sicklist.permission.edit"
                @click="openEditDialog"
                clickable
                v-close-popup>
            <q-item-section>
                <q-item-label class="text-lowercase">
                    {{ $translate('buttons.edit') }}
                </q-item-label>
            </q-item-section>
            <q-item-section avatar>
                <q-icon name="o_edit" size="xs"/>
            </q-item-section>
        </q-item>
        <q-item clickable v-close-popup
                v-if="sicklist.permission.approve"
                @click="actionApprove">
            <q-item-section>
                <q-item-label class="text-lowercase">
                    {{ $translate('buttons.approve') }}
                </q-item-label>
            </q-item-section>
            <q-item-section avatar>
                <q-icon name="o_check" size="xs"/>
            </q-item-section>
        </q-item>
        <q-item clickable
                v-if="sicklist.permission.decline"
                @click="declineDialog = true">
            <q-item-section>
                <q-item-label class="text-lowercase">
                    {{ $translate('buttons.decline') }}
                </q-item-label>
            </q-item-section>
            <q-item-section avatar>
                <q-icon name="o_clear" size="xs"/>
            </q-item-section>
        </q-item>
        <q-item v-if="sicklist.permission.delete"
                @click="actionDelete"
                clickable v-close-popup>
            <q-item-section>
                <q-item-label class="text-lowercase">
                    {{ $translate('buttons.delete') }}
                </q-item-label>
            </q-item-section>
            <q-item-section avatar>
                <q-icon name="o_delete" size="xs"/>
            </q-item-section>
        </q-item>
    </DataTableActions>

    <q-dialog v-model="declineDialog" no-backdrop-dismiss>
        <Card :title="$translate('cadre-sicklist.title.decline')"
              in-modal show-close-button header-toolbar>
            <q-input v-model="form.comment"
                     :label="$translate('cadre-sicklist.fields.comment-decline')"
                     type="textarea" rows="3"
                     :error="form.errors.hasOwnProperty('comment')"
                     :error-message="_.get(form.errors,'comment')"
            />
            <template v-slot:actions>
                <q-btn icon="o_cancel"
                       :label="$translate('buttons.decline')"
                       color="negative"
                       @click="actionDecline"/>
                <q-btn :label="$translate('buttons.close')"
                       color="dark"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="editDialog" no-backdrop-dismiss>
        <Card :title="$translate('cadre-sicklist.title.edit')"
              in-modal show-close-button header-toolbar>
            <ProfileSicklistForm v-model="form"/>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="editFormSubmit"
                />
            </template>
        </Card>
    </q-dialog>
</template>
<script setup lang="ts">
import {router, useForm} from "@inertiajs/vue3";
import {computed, getCurrentInstance, ref} from "vue";
import _ from "lodash";
import {useQuasar} from "quasar";

import DataTableActions from "@/Components/DataTableActions.vue";
import {CadreSicklist} from "@/entity";
import {Card} from "@/Components/Block";
import ProfileSicklistForm from "@/Components/Cadre/Sicklist/ProfileSicklistForm.vue";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route
const $q = useQuasar()

interface Props {
    sicklist: CadreSicklist
}

const props = defineProps<Props>()

const emit = defineEmits(['onSuccess'])

/**
 * Проверка доступности действий
 *
 * @return boolean
 */
const hasActions = computed((): boolean => {
    return (_.size(_.filter(props.sicklist.permission, (v) => v)) > 0);
})

const form = useForm({
    action: null,
    sicklist: props.sicklist,
    comment: null
})

const editDialog = ref(false)
const declineDialog = ref(false)

/**
 * Редактирование отпуска (показ формы)
 */
const openEditDialog = () => {
    editDialog.value = true;
}
/**
 * Редактирование отпуска (отправка данных)
 */
const editFormSubmit = (): void => {
    form.transform((data) => ({
        ...data.sicklist,
        comment: data.comment
    }))
            .post($route('profile.sicklist.update', form.sicklist.id), {
                onSuccess: () => {
                    emit('onSuccess')
                    editDialog.value = false;
                }
            })
}
/**
 * Отправка на согласование
 */
const actionApply = (): void => {
    form.transform((data) => ({
        ...data,
        action: 'apply'
    }))
            .post($route('cadre.sicklist.actions', form.sicklist.id), {
                onSuccess: () => {
                    emit('onSuccess')
                }
            })
}
/**
 * Согласование отпуска
 * (руководителем подразделения/кадровым сотрудником)
 */
const actionApprove = (): void => {
    form.transform((data) => ({
        ...data,
        action: 'approve'
    }))
            .post($route('cadre.sicklist.actions', form.sicklist.id), {
                onSuccess: () => {
                    declineDialog.value = false
                    emit('onSuccess')
                }
            })
}
/**
 * Отклонение отпуска
 * (руководителем подразделения/кадровым сотрудником)
 */
const actionDecline = (): void => {
    form.transform((data) => ({
        ...data,
        action: 'decline',
    }))
            .post($route('cadre.sicklist.actions', form.sicklist.id), {
                onSuccess: () => {
                    declineDialog.value = false
                    emit('onSuccess')
                }
            })
}

/**
 * Удаление отпуска
 */
const actionDelete = (): void => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: props.sicklist.date_start + ' &mdash; ' + (props.sicklist.date_end || '...')}),
        html: true,
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete($route('cadre.sicklist.delete', {cadreSicklist: props.sicklist.id,}),
                {
                    onSuccess: () => {
                        emit('onSuccess')
                    }
                })
    })
}

</script>