<template>
    <Card :title="$translate('Выбор организации')"
          no-card-section in-modal show-close-button>
        <Alert variant="info">
            Воспользуйтесь поиском для выбора организации.<br/>
            Если организация не найдена, воспользуйтесь добавлением новой
        </Alert>
        <q-tabs v-model="tab">
            <q-tab name="search" :label="$translate('Поиск организации')"/>
            <q-tab name="new" :label="$translate('Добавление новой организации')"/>
        </q-tabs>

        <q-tab-panels v-model="tab">
            <q-tab-panel name="search">
                <SearchContragent @onSelect="selectContragent"/>
            </q-tab-panel>
            <q-tab-panel name="new">
                <Form v-model="newElement"/>
            </q-tab-panel>
        </q-tab-panels>

        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   @click="setContragent"
                   color="positive"
                   dense/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Card, Alert} from "@/Components/Block";
import {Contragent as SearchContragent} from "@/Components/Search";
import Form from "@/Components/ReportMaterialForm/Dialog/Contragent/Form.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const emit = defineEmits(['onSelect'])

const tab = ref('search')

const contragent = ref(null)

const selectContragent = (user) => {
    contragent.value = user.company
    _.set(contragent.value, 'external', true)
}

const newElement = useForm({
    name: null,
    full_name: null,
    inn: null,
    ogrn: null,
    kpp: null,
    okpo: null,
    tags: []
})

const setContragent = () => {
    if (tab.value === 'search') {
        emit('onSelect', contragent.value)
    }
    if (tab.value === 'new') {
        newElement.errors = {}
        axios.post(route('finance.contragent.store'), newElement)
            .catch((errors) => {
                _.forEach(errors.response.data.errors, (v, k) => {
                    _.set(newElement.errors, k, v.join(";"))
                })
            })
            .then((response) => {
                contragent.value = {user: response.data.contragent}
                _.set(contragent.value.company, 'user_id', response.data.contragent.id)
                _.set(contragent.value, 'external', true)
            })
            .finally(() => {
                emit('onSelect', contragent.value)
            })
    }
}
</script>