<template>
    <Card title-bg="deep-purple-2"
          :title="$translate('module-sensors.sensors.list.title')"
          no-card-section
          header-toolbar
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn v-if="can.create"
                   :label="$translate('module-sensors.sensors.list.actions.create')"
                   @click="router.get($route('services.sensors.create'))"
                   icon="o_add"
                   color="deep-purple-5"
                   dense
            />
        </template>
        <q-table ref="tableRef"
                 row-key="id"
                 :rows="items"
                 :columns="columns"
                 :filter="filter"
                 :loading="loading"
                 @request="onRequest"
                 v-model:pagination="pagination"
                 :rows-per-page-options="[10,25,50,100]"
                 wrap-cells
                 flat
        >
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @on-success="onSuccess"
                    />
                </q-td>
            </template>
            <template v-slot:body-cell-name="props">
                <q-td :props="props">
                    <span v-html="props.value"></span>
                </q-td>
            </template>
        </q-table>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import DataTableActions from "@/Components/DataTableActions.vue";
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import axios from "axios";

const props = defineProps({
    can: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const tableRef = ref();
const filter = ref('');
const loading = ref(false);
const items = ref([]);
const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'id', label: $translate('module-sensors.sensors.list.fields.id'), field: 'id', style: 'width: 2rem'},
    {name: 'name', label: $translate('module-sensors.sensors.list.fields.name'), field: 'name'},
    {name: 'ip', label: $translate('module-sensors.sensors.list.fields.ip'), field: 'ip'},
    {name: 'period', label: $translate('module-sensors.sensors.list.fields.period'), field: 'period'},
    {name: 'sensor_type', label: $translate('module-sensors.sensors.list.fields.sensor_type'), field: 'sensor_type'},
    {name: 'sensor_group', label: $translate('module-sensors.sensors.list.fields.sensor_group'), field: 'sensor_group'},
    {name: 'ssc', label: $translate('module-sensors.sensors.list.fields.ssc'), field: 'ssc'},
    {name: 'enabled_text', label: $translate('module-sensors.sensors.list.fields.enabled'), field: 'enabled_text'},
];
const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
});

const onRequest = (request) => {
    const {page, rowsPerPage, sortBy, descending} = request.pagination;

    loading.value = true;

    let params = {
        m: 'getSensors',
        page: page,
        per_page: rowsPerPage,
    };
    axios.get(route('ajax.sensors'), {
        params: params,
    })
    .then((response) => {
        items.value = response.data.results.data;
        pagination.value.page = response.data.results.current_page;
        pagination.value.rowsPerPage = response.data.results.per_page;
        pagination.value.sortBy = sortBy;
        pagination.value.descending = descending;
        pagination.value.rowsNumber = response.data.results.total;
    })
    .finally(() => {
        loading.value = false;
    });
};
const onSuccess = () => {
    tableRef.value.requestServerInteraction();
};

onMounted(() => {
    onSuccess();
});
</script>