<template>
    <Card v-if="project.can.expensesView"
          :title="$translate('project.view.expenses.title')"
          no-card-section hide-actions
          class="q-mb-md">

        <q-linear-progress :value="progress" size="2.7rem"
                           :color="progressColor"
                           v-if="!printable">
            <div class="absolute-full flex flex-center">
                <q-badge color="white" class="text-body2"
                         :text-color="progressColor"
                         :label="progressLabel"/>
            </div>
        </q-linear-progress>

        <q-list separator>
            <q-item class="text-bold text-grey-7">
                <q-item-section>
                    <q-item-label>
                        {{ $translate('project.view.expenses.ssc.name') }}
                    </q-item-label>
                </q-item-section>
                <q-item-section>
                    <q-item-label class="text-right">
                        {{ $translate('project.view.expenses.ssc.current') }}
                    </q-item-label>
                </q-item-section>
                <q-item-section>
                    <q-item-label class="text-right">
                        {{ $translate('project.view.expenses.ssc.expected') }}
                    </q-item-label>
                </q-item-section>
            </q-item>
            <template v-for="(item) in project.sscresearches">
                <q-item>
                    <q-item-section>
                        <q-item-label>{{ item.ssc.name }}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label class="row items-center">
                            <span class="q-mr-none q-ml-auto">
                                {{
                                    _.get(item, 'can.expenses.current') ?
                                            _.get(_.find(project.ssccost, {ssc_id: item.ssc_id}), 'current_cost_formatted', helpers.formatMoney(0))
                                            : '******'
                                }}
                            </span>
                        </q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label class="text-right">
                            <q-btn v-if="_.get(item,'can.expenses.edit')"
                                   icon="o_edit"
                                   :title="$translate('project.actions.type-cost')"
                                   @click="openExpensesForm(item.ssc_id)"
                                   dense
                                   flat>
                                <template v-slot:default>
                                    <span class="q-ml-sm">{{
                                            _.get(item, 'can.expenses.expected') ?
                                                    _.get(_.find(project.ssccost, {ssc_id: item.ssc_id}), 'expected_cost_formatted', helpers.formatMoney(0))
                                                    : '******'
                                        }}</span>
                                </template>
                            </q-btn>
                            <template v-else>
                                    <span>{{
                                            _.get(item, 'can.expenses.expected') ?
                                                    _.get(_.find(project.ssccost, {ssc_id: item.ssc_id}), 'expected_cost_formatted', helpers.formatMoney(0))
                                                    : '******'
                                        }}</span>
                            </template>
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </template>
            <q-separator/>
            <q-separator/>
            <q-item class="text-bold text-grey-7">
                <q-item-section>
                    <q-item-label class="text-italic">{{ $translate('project.view.expenses.funding') }}</q-item-label>
                </q-item-section>
                <q-item-section>
                    <q-item-label class="row items-center justify-end">
                        <q-btn v-if="_.size(fundings)>0"
                               icon="o_quiz"
                               title="данные из источника"
                               class="q-mx-xs cursor-pointer"
                               @click="projectFundings = true"
                               dense no-caps flat/>
                        {{ helpers.formatMoney(_.get(project, 'source.fundings_from_source.total', 0)) }}
                    </q-item-label>
                </q-item-section>
            </q-item>
            <q-item class="text-bold text-grey-7">
                <q-item-section>
                    <q-item-label class="text-italic">{{ $translate('project.view.expenses.expected') }}</q-item-label>
                </q-item-section>
                <q-item-section>
                    <q-item-label class="text-right">
                        {{ _.get(project, 'expected_cost_formatted', helpers.formatMoney(0)) }}
                    </q-item-label>
                </q-item-section>
            </q-item>
            <q-item class="text-bold text-grey-7">
                <q-item-section>
                    <q-item-label class="text-italic">{{ $translate('project.view.expenses.current') }}</q-item-label>
                </q-item-section>
                <q-item-section>
                    <q-item-label class="text-right">
                        {{ _.get(project, 'current_cost_formatted', helpers.formatMoney(0)) }}
                    </q-item-label>
                </q-item-section>
            </q-item>
        </q-list>
        <q-dialog v-model="projectFundings" v-if="_.size(fundings)>0">
            <Card :title="$translate('Данные по финансированию проекта из источника')"
                  in-modal show-close-button hide-actions>
                <q-list>
                    <template v-for="(funds,name) in fundings">
                        <q-item>
                            <q-item-section>
                                <q-item-label>{{ name }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label class="text-right">
                                    {{ helpers.formatMoney(_.sumBy(funds, 'cost')) }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-list>
            </Card>
        </q-dialog>
        <template v-slot:headerActions v-if="!printable">
            <div class="flex items-center q-gutter-x-sm no-wrap " :class="$q.screen.lt.xl?'column reverse':''">
                <PageReload v-if="project.expenses_report && !_.get(project,'expenses_report.complete')"/>
                <q-btn-dropdown icon="fas fa-coins"
                                :label="$translate('project.actions.expenses-print')"
                                dense flat>
                    <q-list dense>
                        <q-item clickable v-close-popup @click="createExpensesReport">
                            <q-item-section>
                                <q-item-label>{{ $translate('project.actions.expenses-create') }}</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-icon name="restart_alt" size="xs"/>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="_.get(project,'expenses_report.complete')"
                                :href="$route('project.expenses',{project:project, size:'short'})"
                                target="_blank"
                                clickable v-close-popup>
                            <q-item-section>
                                <q-item-label>
                                    {{
                                        $translate("project.actions.expenses-print-short-completed",
                                                {
                                                    "date": moment(_.get(project.expenses_report, 'updated_at')).format($translate('common.datetime.js'))
                                                }
                                        )
                                    }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="project.can.expensesViewDetail"
                                :href="$route('project.expenses',{project:project, size:'full'})"
                                target="_blank"
                                clickable v-close-popup>
                            <q-item-section>
                                <q-item-label>
                                    {{
                                        $translate("project.actions.expenses-print-full-completed",
                                                {
                                                    "date": moment(_.get(project.expenses_report, 'updated_at')).format($translate('common.datetime.js'))
                                                }
                                        )
                                    }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="project.expenses_report && !_.get(project,'expenses_report.complete')"
                                disable>
                            <q-item-section>
                                <q-item-label>{{ $translate('project.actions.expenses-print-short') }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
            </div>
        </template>
    </Card>
    <q-dialog v-model="projectExpenses" no-backdrop-dismiss>
        <Card :title="$translate('project.view.expenses.type-cost.title')"
              in-modal show-close-button header-toolbar>
            <q-field :prefix="$translate('project.view.expenses.type-cost.cost')+':'"
                     v-model="projectExpensesForm.expected_cost"
                     bottom-slots
            >
                <template v-slot:control="{ id, floatingLabel, modelValue, emitValue }">
                    <input :id="id" class="q-field__input text-right"
                           @change="e => emitValue(e.target.value)"
                           v-money="moneyFormatForDirective"
                           v-show="floatingLabel">
                </template>
                <template v-slot:hint>
                    <div class="text-negative" v-if="projectExpensesForm.errors.hasOwnProperty('expected_cost')">
                        {{ projectExpensesForm.errors.expected_cost }}
                    </div>
                    <div class="text-center">
                        {{
                            $translate('Текущая сумма планируемых затрат: :cost', {cost: _.get(_.find(project.ssccost, {ssc_id: projectExpensesForm.ssc_id}), 'expected_cost_formatted', helpers.formatMoney(0))})
                        }}
                    </div>
                </template>
            </q-field>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="submitExpensesForm"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import helpers from "@/helpers";
import _ from "lodash";
import PageReload from "@/Components/PageReload.vue";
import moment from "moment";
import {Card} from "@/Components/Block";
import {useQuasar} from "quasar";
import {format, unformat} from 'v-money3';


const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar();

const moneyFormatForDirective = {
    decimal: ',',
    thousands: ' ',
    prefix: '',
    suffix: ' ' + $translate('units.rub'),
    precision: 2,
    allowBlank: true,
    disableNegative: true,
    masked: false /* doesn't work with directive */
}

const props = defineProps({
    project: {
        type: Object,
        required: true
    },
    printable: {
        type: Boolean,
        default: false
    }
})

const progress = ref(0)
const progressLabel = ref(null)
const progressColor = ref('positive')

const fundings = computed(() => {
    return _.groupBy(_.get(props.project.source, 'fundings_from_source.fundings'), 'funder.name')
})


const projectFundings = ref(false)

const projectExpenses = ref(false)

const projectExpensesForm = useForm({
    ssc_id: null,
    expected_cost: null
})

const openExpensesForm = (ssc_id) => {
    projectExpensesForm.reset();
    projectExpensesForm.ssc_id = ssc_id
    projectExpenses.value = true
}

const submitExpensesForm = () => {
    projectExpensesForm.transform((data) => ({
        ...data,
        expected_cost: unformat(projectExpensesForm.expected_cost, moneyFormatForDirective)
    }))
            .post(
                    route('project.cost.add', props.project),
                    {
                        onSuccess: () => {
                            projectExpenses.value = false
                            projectExpensesForm.reset()
                            router.reload()
                        }
                    })
}

const createExpensesReport = () => {
    router.get(route('project.expenses-create', props.project), {}, {
        onSuccess: () => {
            router.reload()
        }
    })
}

onMounted(() => {
    progress.value = (props.project.current_cost / props.project.expected_cost)
    let _progress = (progress.value * 100).toFixed(2);
    if (props.project.expected_cost > 0) {
        progressLabel.value = _progress + "%" + " (" + props.project.current_cost_formatted + " из " + props.project.expected_cost_formatted + ")";
        if (_progress > 75) {
            progressColor.value = 'orange-9'
        }
        if (_progress > 90) {
            progressColor.value = 'negative'
        }
    } else {
        progressColor.value = 'negative'
        progressLabel.value = 'Планируемые затраты по проекту не указаны';
    }
})
</script>