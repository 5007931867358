<template>
    <Card :title="$translate('module-rims.settings.RIMS_CONTACT_INFO')" no-card-section header-toolbar>
        <HasChanges v-if="settingsForm.isDirty"/>
        <q-card-section>
            <q-input type="textarea" rows="3"
                     v-model="settingsForm['RIMS_CONTACT_INFO_COMMON_SSC_' + department.id + '']"
                     :label="$translate('module-rims.settings.RIMS_CONTACT_INFO_COMMON_SSC')" stack-label
                     label-color="black">
            </q-input>
        </q-card-section>
        <q-separator/>
        <q-card-section>
            <div class="q-mt-md">
                <q-checkbox size="sm" dense true-value="1" false-value="0"
                            v-model="settingsForm['RIMS_SHOW_CONTACT_INFO_COMMON_SSC_' + department.id + '']"
                            :label="$translate('module-rims.settings.RIMS_SHOW_CONTACT_INFO_COMMON_SSC')"
                />
            </div>
            <div class="q-mt-md">
                <q-checkbox size="sm" dense true-value="1" false-value="0"
                            v-model="settingsForm['RIMS_SHOW_CONTACT_INFO_LEADER_SSC_' + department.id + '']"
                            :label="$translate('module-rims.settings.RIMS_SHOW_CONTACT_INFO_LEADER_SSC')"
                />
            </div>
            <div class="q-mt-md">
                <q-checkbox size="sm" dense true-value="1" false-value="0"
                            v-model="settingsForm['RIMS_SHOW_CONTACT_INFO_OTHER_LEADERS_SSC_' + department.id + '']"
                            :label="$translate('module-rims.settings.RIMS_SHOW_CONTACT_INFO_OTHER_LEADERS_SSC')"
                />
            </div>
        </q-card-section>
        <HasChanges v-if="settingsForm.isDirty"/>
        <template v-slot:actions>
            <q-btn color="positive" icon="save" :label="$translate('buttons.save')" @click="settingsFormSubmit"/>
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onBeforeMount} from "vue";
import _ from "lodash";
import {router, useForm, usePage} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import HasChanges from "@/Components/Block/HasChanges.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const props = defineProps({
    department: {},
})

let settingsForm = null;
onBeforeMount(() => {
    let form = {}
    let settings = usePage().props.settings
    _.set(form, "RIMS_CONTACT_INFO_COMMON_SSC_" + props.department.id + "",
            _.get(settings, "RIMS_CONTACT_INFO_COMMON_SSC_" + props.department.id + "", null));
    _.set(form, "RIMS_SHOW_CONTACT_INFO_COMMON_SSC_" + props.department.id + "",
            _.get(settings, "RIMS_SHOW_CONTACT_INFO_COMMON_SSC_" + props.department.id + "", '0'));
    _.set(form, "RIMS_SHOW_CONTACT_INFO_LEADER_SSC_" + props.department.id + "",
            _.get(settings, "RIMS_SHOW_CONTACT_INFO_LEADER_SSC_" + props.department.id + "", '0'))
    _.set(form, "RIMS_SHOW_CONTACT_INFO_OTHER_LEADERS_SSC_" + props.department.id + "",
            _.get(settings, "RIMS_SHOW_CONTACT_INFO_OTHER_LEADERS_SSC_" + props.department.id + "", '0'))

    settingsForm = useForm(form);
});

const settingsFormSubmit = () => {
    settingsForm.post(route('ssc.settings.update', props.department), {
        onSuccess: () => {
            router.reload()
        }
    })
}
</script>