<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          actions-align="left"
          no-card-section>
        <q-list separator>
            <template v-for="(item,index) in documents">
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ _.get(item, 'type.label') }}</q-item-label>
                        <q-item-label>{{ _.get(item, 'name') }}</q-item-label>
                        <q-item-label>
                            <q-icon name="attach_file"/>
                            {{ _.get(item, 'file.name') }}
                        </q-item-label>
                        <div class="text-negative q-px-xs"
                             v-if="errors.hasOwnProperty('documents.'+index+'.name')">
                            {{ _.get(errors, 'documents.' + index + '.name') }}
                        </div>
                        <div class="text-negative q-px-xs"
                             v-if="errors.hasOwnProperty('documents.'+index+'.file')">
                            {{ _.get(errors, 'documents.' + index + '.file') }}
                        </div>
                        <div class="text-negative q-px-xs"
                             v-if="errors.hasOwnProperty('documents.'+index+'.type_id')">
                            {{ _.get(errors, 'documents.' + index + '.type_id') }}
                        </div>
                        <div class="text-negative q-px-xs"
                             v-if="errors.hasOwnProperty('documents.'+index+'.version')">
                            {{ _.get(errors, 'documents.' + index + '.version') }}
                        </div>
                        <div class="text-negative q-px-xs"
                             v-if="errors.hasOwnProperty('documents.'+index+'.access')">
                            {{ _.get(errors, 'documents.' + index + '.access') }}
                        </div>
                        <div class="text-negative q-px-xs"
                             v-if="errors.hasOwnProperty('documents.'+index+'.license')">
                            {{ _.get(errors, 'documents.' + index + '.license') }}
                        </div>
                    </q-item-section>
                    <q-item-section side top>
                        <div>
                            <q-btn icon="o_edit"
                                   color="dark"
                                   @click="editDocumentDialog(item,index)"
                                   flat dense/>
                            <q-btn icon="o_delete"
                                   color="negative"
                                   @click="deleteDocument(index)"
                                   flat dense/>
                        </div>
                    </q-item-section>
                </q-item>
            </template>

            <template v-for="(item,index) in links">
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ _.get(item, 'type.label') }}</q-item-label>
                        <q-item-label>{{ _.get(item, 'name') }}</q-item-label>
                        <q-item-label>
                            <q-icon name="link"/>
                            {{ _.get(item, 'url') }}
                        </q-item-label>
                        <div class="text-negative q-px-xs"
                             v-if="errors.hasOwnProperty('links.'+index+'.name')">
                            {{ _.get(errors, 'links.' + index + '.name') }}
                        </div>
                        <div class="text-negative q-px-xs"
                             v-if="errors.hasOwnProperty('links.'+index+'.url')">
                            {{ _.get(errors, 'links.' + index + '.url') }}
                        </div>
                        <div class="text-negative q-px-xs"
                             v-if="errors.hasOwnProperty('links.'+index+'.type_id')">
                            {{ _.get(errors, 'links.' + index + '.type_id') }}
                        </div>
                    </q-item-section>
                    <q-item-section side>
                        <div>
                            <q-btn icon="o_edit"
                                   color="dark"
                                   @click="editLinkDialog(item,index)"
                                   flat dense/>
                            <q-btn icon="o_delete"
                                   color="negative"
                                   @click="deleteLink(index)"
                                   flat dense/>
                        </div>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
        <div v-if="errors.hasOwnProperty('documents')" class="text-negative q-pa-sm">
            {{ _.get(errors, 'documents') }}
        </div>
        <div v-if="errors.hasOwnProperty('links')" class="text-negative q-pa-sm">
            {{ _.get(errors, 'links') }}
        </div>
        <template v-slot:actions>
            <q-btn icon="o_add" :label="$translate('документ')"
                   no-caps dense
                   @click="openDocumentDialog"/>
            <q-dialog v-model="documentDialog" no-backdrop-dismiss>
                <Card :title="$translate('Добавление/редактирование документа')"
                      in-modal show-close-button>
                    <FilesDocumentForm v-model="document"/>
                    <template v-slot:actions>
                        <q-btn icon="o_save"
                               :label="$translate('buttons.save')"
                               color="positive"
                               @click="saveDocument"
                        />
                    </template>
                </Card>
            </q-dialog>
            <q-btn icon="o_add" :label="$translate('ссылку')"
                   no-caps dense
                   @click="openLinkDialog"/>
            <q-dialog v-model="linkDialog" no-backdrop-dismiss>
                <Card :title="$translate('Добавление/редактирование ссылки')"
                      in-modal show-close-button>
                    <FilesLinkForm v-model="link"/>
                    <template v-slot:actions>
                        <q-btn icon="o_save"
                               :label="$translate('buttons.save')"
                               color="positive"
                               @click="saveLink"
                        />
                    </template>
                </Card>
            </q-dialog>
        </template>
    </Card>

</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {Card} from "@/Components/Block";
import FilesDocumentForm from "@/Components/ReportMaterialForm/Block/Files/FilesDocumentForm.vue";
import FilesLinkForm from "@/Components/ReportMaterialForm/Block/Files/FilesLinkForm.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object}
})

const documents = defineModel('documents', {type: Array})
const links = defineModel('links', {type: Array})
const errors = defineModel('errors', {type: Object})

const documentDialog = ref(false)
const linkDialog = ref(false)

const document = useForm({
    name: null,
    file: null,
    type_id: null,
    type: null,
    version: null,
    access: null,
    license: null,
    index: null
})

const link = useForm({
    name: null,
    url: null,
    type_id: null,
    type: null,
    index: null
})

const openDocumentDialog = () => {
    document.reset()
    documentDialog.value = true
}
const editDocumentDialog = (doc, index) => {
    _.forEach(doc, (v, k) => {
        _.set(document, k, v)
    })
    _.set(document, 'index', index)
    documentDialog.value = true
}
const saveDocument = () => {
    if (document.index != null) {
        documents.value.splice(document.index, 1)
    }
    documents.value.push(_.cloneDeep(document))
    documentDialog.value = false
}
const deleteDocument = (index) => {
    documents.value.splice(index, 1)
}

const openLinkDialog = () => {
    link.reset()
    linkDialog.value = true
}
const editLinkDialog = (doc, index) => {
    _.forEach(doc, (v, k) => {
        _.set(link, k, v)
    })
    _.set(link, 'index', index)
    linkDialog.value = true
}
const saveLink = () => {
    if (link.index != null) {
        links.value.splice(link.index, 1)
    }
    links.value.push(_.cloneDeep(link))
    linkDialog.value = false
}
const deleteLink = (index) => {
    links.value.splice(index, 1)
}
</script>