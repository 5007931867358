<template>
    <Card :title="$translate('calendar.title')"
          header-toolbar hide-actions ref="calendarCard">
        <FullCalendar ref="calendar" :options="calendarOptions"/>
    </Card>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card in-modal show-close-button :title="$translate('Добавление нерабочего периода для подразделения')">
            <q-select v-model="addForm.ssc_id"
                      :label="$translate('calendar.fields.ssc')"
                      :options="sscList"
                      option-label="name"
                      option-value="id"
                      options-dense emit-value map-options stack-label
                      :error="addForm.errors.hasOwnProperty('ssc_id')"
                      :error-message="_.get(addForm.errors,'ssc_id')"
            />

            <q-field :label="$translate('calendar.fields.date')" stack-label
                     bottom-slots
                     :error="addForm.errors.hasOwnProperty('dates') || addForm.errors.hasOwnProperty('dates.0')"
                     :error-message="_.get(addForm.errors,'dates') || _.get(addForm.errors,'dates.0')">
                <template v-slot:control>
                    <div class="items-center full-width" v-if="_.isObject(addForm.dates)">
                        {{ addForm.dates.from }} &mdash; {{ addForm.dates.to }}
                    </div>
                    <div class="items-center full-width" v-else>
                        {{ addForm.dates }}
                    </div>
                    <q-popup-proxy>
                        <q-date v-model="addForm.dates" range minimal>
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup :label="$translate('buttons.select')"
                                       color="dark"
                                       flat/>
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </template>

                <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy>
                            <q-date v-model="addForm.dates" range minimal>
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup
                                           :label="$translate('buttons.select')"
                                           color="dark"
                                           flat/>
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-field>

            <q-input v-model="addForm.comment"
                     type="textarea" rows="3"
                     :label="$translate('calendar.fields.comment')"
            />

            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.add')"
                       color="positive"
                       @click="addFormSubmit"
                />
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="detailEventDialog">
        <Card :title="$translate('детальная информация')"
              show-close-button in-modal>
            <q-field tag="div" prefix="Дата:" stack-label borderless>
                <template v-slot:control>
                    <div>{{ moment(_.get(selectedEvent.event, 'start')).format($translate('common.date.js')) }}</div>
                </template>
            </q-field>
            <q-field tag="div" prefix="Подразделение:" stack-label borderless
                     v-if="selectedEvent.event.extendedProps.hasOwnProperty('ssc')">
                <template v-slot:control>
                    <div>{{ _.get(selectedEvent.event.extendedProps, 'ssc') }}</div>
                </template>
            </q-field>
            <q-field tag="div" stack-label borderless>
                <template v-slot:control>
                    <div>{{ _.get(selectedEvent.event.extendedProps, 'comment') }}</div>
                </template>
            </q-field>
            <template v-slot:actions>
                <q-btn v-if="_.get(selectedEvent.event.extendedProps, 'can.delete')"
                       icon="o_delete"
                       :label="$translate('buttons.delete')"
                       color="negative"
                       @click="deleteEvent(selectedEvent)"/>
                <q-btn v-close-popup
                       icon="clear"
                       :label="$translate('buttons.close')"
                       color="dark"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref, computed} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment";
import {useQuasar, dom} from 'quasar';
import {Card} from "@/Components/Block";
import FullCalendar from '@fullcalendar/vue3'
import locale from "@fullcalendar/core/locales/ru";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const $q = useQuasar();

const calendarCard = ref()
const sscList = computed(() => usePage().props.auth.ssc)

const calendar = ref()

const reloadData = () => {
    let calendarApi = calendar.value.getApi()
    calendarApi.refetchEvents()
}

const addDialog = ref(false)
const addDialogOpen = () => {
    addDialog.value = true;
}

const addForm = useForm({
    ssc_id: null,
    dates: null,
    comment: null,
})

const addFormSubmit = () => {
    addForm.post($route('admin.calendar.add'), {
        onSuccess: () => {
            addDialog.value = false;
            addForm.reset()
            reloadData()
        }
    })
}

const selectedEvent = ref(null)

const detailEventDialog = ref(false)

const deleteEvent = (event) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: moment(_.get(event.event, 'start')).format($translate('common.date.js'))}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete($route('admin.calendar.delete', event.event.id),
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        detailEventDialog.value = false
                        selectedEvent.value = null;
                        reloadData()
                    }
                })
    })
}
const calendarHeight = computed(() => {
    return 'calc(100vh - 180px)'
})

const calendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    headerToolbar: {
        left: 'addAddress,reload',
        center: 'title',
        right: 'prev,today,next',
    },
    customButtons: {
        addAddress: {
            text: "добавить дату",
            click: addDialogOpen
        },
        reload: {
            text: "обновить",
            click: reloadData
        }
    },
    height: calendarHeight.value,
    locales: [locale],
    initialView: 'dayGridMonth',
    editable: true,
    selectable: false,
    weekends: true,
    //select: this.handleDateSelect,
    // eventHover: handleEventClick,
    // eventClick: handleEventClick,
    // eventsSet: handleEvents,
    eventSources: [
        $route('admin.calendar.base-days'), // базовые выходные дни
        $route('admin.calendar.ssc-days'), // выходные дни подразделений
    ],
    eventClick: function (info) {
        selectedEvent.value = info
        detailEventDialog.value = true
    },
}
</script>