<template>
    <q-select v-model="selected"
              :loading="loading"
              clearable
              clear-icon="clear"
              input-debounce="1000"
              :multiple="multiple"
              :options="options"
              options-dense
              @filter="searchData"
              :label="$translate('search.delivery.goods.label')"
              :hint="$translate('search.delivery.goods.hint')"
              @update:model-value="(value) => emit('onSelect', value)"
              :error-message="error"
              :error="_.size(error)>0"
              use-input
    >
    </q-select>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import axios from "axios";
import _ from "lodash";

const props = defineProps({
    label: {
        type: String,
        default: null
    },
    multiple: {
        type: Boolean,
        required: false,
        default: false
    },
    filters: {
        type: Array,
        required: false,
        default: []
    },
    error: {
        type: String,
        default: null
    }
});

const emit = defineEmits(['onSelect'])

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const selected = ref(null);
const options = ref([]);
const loading = ref(false);

const searchData = (search, update) => {
    if (search === '') {
        if (_.size(options.value) > 0) {
            // already loaded
            update()
            return
        }
        update(() => {
            options.value = [];
        });
        return
    }
    if (selected.value) {
        search = selected.value.value + ' ' + search
    }
    update(() => {
        let params = {
            m: 'search',
            q: search
        };
        if (!_.isEmpty(props.filters)) {
            params['filters'] = {};
        }
        _.forEach(props.filters, (v, k) => {
            params['filters'][k] = v;
        });
        loading.value = true;
        axios.get(route('services.delivery.goods.json', params))
                .then((response) => {
                    options.value = response.data.results;
                })
                .finally(() => {
                    loading.value = false;
                });
    });
};
</script>