<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from 'vue'
import {router} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import DataTable from "@/Components/DataTable.vue";
import {useQuasar} from "quasar";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

const $q = useQuasar();

const columns: any = [
    {name: 'actions', style: 'width:40px'},
    {
        name: 'number',
        label: $translate('module-rid.fields.number'),
        field: 'number',
        align: 'left',
        sortable: true
    },
    {
        name: 'status',
        label: $translate('module-rid.fields.status'),
        field: 'status',
        align: 'left',
        sortable: true
    },
    {
        name: 'name',
        label: $translate('module-rid.fields.name'),
        field: 'name',
        align: 'left',
        sortable: true
    },
    {
        name: 'user',
        label: $translate('module-rid.fields.user'),
        field: 'user',
        align: 'left',
        sortable: true
    },
]
</script>

<template>
    <Card :title="$translate('module-rid.title.list')"
          title-bg="blue-grey-13" title-text="white"
          header-toolbar hide-actions no-card-section>
        <template v-slot:headerActions>
            <q-btn icon="add"
                   :label="$translate('module-rid.actions.create')"
                   @click="router.visit($route('services.rid.create'))"
                   color="white"
                   outline
                   dense
                   no-caps
            />
        </template>
        <DataTable allow-search
                   :columns="columns"
                   :request-route="$route('ajax.rid', {m: 'list'})"
        >
        </DataTable>
    </Card>
</template>

<style scoped>

</style>