<template>
    <q-item>
        <q-item-section>
            <q-item-label class="text-body1 flex items-center q-gutter-x-sm">
                <q-icon name="o_business" size="sm"/>
                <span>{{ department.ssc.name }}</span>
            </q-item-label>
            <q-item-label caption v-if="department.ssc.description">
                {{ department.ssc.description }}
            </q-item-label>
        </q-item-section>
        <q-item-section side>
            <q-input v-if="!department.ssc.unlimited"
                     type="number" min="0"
                     v-model="department.count"
                     :prefix="$translate('project.view.ssc-count-description')+':'" stack-label
                     input-class="text-center"
                     :error="project.errors.hasOwnProperty('ssc.'+index+'.count')"
                     :error-message="_.get(project.errors,'ssc.'+index+'.description')"></q-input>
        </q-item-section>
        <q-item-section side>
            <div class="text-right">
                <slot name="deleteItem"/>
            </div>
        </q-item-section>
    </q-item>
    <q-item v-if="department.ssc.ssc_show_work_description">
        <q-item-section>
            <q-item-label caption>{{ $translate('project.view.ssc-work-description') }}*</q-item-label>
            <q-editor @paste="onPaste"
                      ref="editorRef"
                      :id="department.ssc.id"
                      flat
                      v-model="department.description"
                      class="full-width"
            />
            <div class="text-negative q-pa-xs"
                 v-if="project.errors.hasOwnProperty('ssc.'+index+'.description')">
                {{ _.get(project.errors, 'ssc.' + index + '.description') }}
            </div>
        </q-item-section>
    </q-item>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import helpers from "@/helpers";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const department = defineModel({type: Object})

const props = defineProps({
    project: {type: Object}
})

const emit = defineEmits(['onDelete'])

const editorRef = ref()

const onPaste = (evt) => {
    helpers.sanitizeOnPaste(evt, editorRef);
}

const deleteDepartment = (sscId) => {
    console.log('onDelete', [sscId])
    emit('onDelete')
}
</script>

<style scoped>

</style>