<template>
    <Card :title="$translate('Добавить коллаборацию авторов')"
          no-card-section in-modal show-close-button>
        <Alert variant="info">
            Воспользуйтесь поиском для добавления существующего коллаборации авторов.<br/>
            Если коллаборация не найдена, воспользуйтесь добавлением новой коллаборации
        </Alert>
        <q-tabs v-model="tab">
            <q-tab name="search" :label="$translate('Поиск коллаборации')"/>
            <q-tab name="new" :label="$translate('Добавление новой коллаборации')"/>
        </q-tabs>

        <q-tab-panels v-model="tab">
            <q-tab-panel name="search">
                <SearchCollaboration @onSelect="selectUser"/>
            </q-tab-panel>
            <q-tab-panel name="new">
                <q-input v-model="newPerson.name"
                         :label="$translate('Наименование коллаборации')"
                         class="col"
                         :error="newPerson.errors.hasOwnProperty('name')"
                         :error-message="_.get(newPerson.errors,'name')"/>
            </q-tab-panel>
        </q-tab-panels>
        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   @click="setPerson"
                   color="positive"
                   dense
            />
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Card, Alert} from "@/Components/Block";
import {Collaboration as SearchCollaboration} from "@/Components/Search";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const emit = defineEmits(['onSelect'])

const tab = ref('search')

const person = ref(null)

const selectUser = (user) => {
    person.value = user
    _.set(person.value, 'external', false)
}

const newPerson = useForm({
    name: null,
})

const setPerson = () => {
    if (tab.value === 'search') {
        emit('onSelect', person.value)
    }
    if (tab.value === 'new') {
        axios.post(route('services.science.collaboration.store'), newPerson)
            .catch((errors) => {
                _.forEach(errors.response.data.errors, (v, k) => {
                    _.set(newPerson.errors, k, v.join(";"))
                })
            })
            .then((response) => {
                person.value = {collaboration: response.data.collaboration}
                _.set(person.value, 'external', false)
            })
            .finally(() => {
                emit('onSelect', person.value)
            })
    }
}
</script>