<template>
    <Card title-bg="pink-1"
          :title="$translate('module-archive.research.card.title', {
              research: _.get(archiveResearch, 'data.number')
          })"
          no-card-section
          header-toolbar
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('module-archive.research.card.actions.back-to-project', {
                       project: archiveProjectNumber
                   })"
                   @click="router.get($route('services.archive.project', {
                       archiveProject: archiveProjectId
                   }))"
                   icon="o_chevron_left"
                   dense
                   flat
            />
        </template>
        <q-tabs v-model="tab" align="left" no-caps>
            <q-tab name="research" :label="$translate('module-archive.research.tabs.card')"/>
            <q-tab name="report" :label="$translate('module-archive.research.tabs.report')"/>
        </q-tabs>
        <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="research">
                <div class="row q-gutter-x-md">
                    <div class="col-md col-24">
                        <component :is="showBlock('Main')"
                                   :archive-research="_.get(archiveResearch, 'data')"/>
                        <component :is="showBlock('Additional')"
                                   :afm-data="_.get(archiveResearch, 'data.afm_info.afm_data')"/>
                        <component :is="showBlock('IncludeFiles')"
                                   v-if="!_.isEmpty(_.get(archiveResearch, 'data.research_include_files'))"
                                   :files="_.get(archiveResearch, 'data.research_include_files')"
                        />
                        <component :is="showBlock('ResultFiles')"
                                   v-if="!_.isEmpty(_.get(archiveResearch, 'data.research_result_files'))"
                                   :files="_.get(archiveResearch, 'data.research_result_files')"
                        />
                    </div>
                    <div class="col-md col-24">
                        <component :is="showBlock('Leader')"
                                   :leader="_.get(archiveResearch, 'data.leader')"/>
                        <component :is="showBlock('Initiator')"
                                   :initiator="_.get(archiveResearch, 'data.initiator')"/>
                        <component :is="showBlock('Employee')"
                                   :employee="_.get(archiveResearch, 'data.employee')"/>
                    </div>
                </div>
            </q-tab-panel>
            <q-tab-panel name="report">
                <ResearchReport :archive-research="_.get(archiveResearch, 'data')"/>
            </q-tab-panel>
        </q-tab-panels>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import * as ResearchBlocks from "@/Components/Archive/Research/Block";
import ResearchReport from "@/Components/Archive/Research/Report/ResearchReport.vue";
import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";

const showBlock = (block) => ResearchBlocks[block]

const props = defineProps({
    archiveProjectId: Number,
    archiveProjectNumber: String,
    archiveResearch: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const tab = ref('research');
</script>
