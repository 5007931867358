<template>
    <Card v-if="_.get(fields,'show') && _.size(_.get(fields,'entity'))>0"
          :title="_.get(fields,'name')"
          no-card-section
          actions-align="left">

        <template v-for="(items, entity) in related">
            <q-list separator v-if="_.size(items)>0">
                <q-bar class="bg-grey-2 text-body2 text-italic">
                    {{ $translate('report-type.type.' + entity) }}
                </q-bar>
                <template v-for="(item,index) in items">
                    <q-item>
                        <q-item-section>
                            <q-item-label>{{ item.label }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-btn icon="o_delete"
                                   color="negative"
                                   @click="deleteEntity(entity,index)"
                                   flat dense/>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </template>
        <div class="text-negative q-pa-sm"
             v-if="errors.hasOwnProperty('related')">
            {{ _.get(errors, 'related') }}
        </div>
        <template v-slot:actions>
            <q-btn :label="$translate('buttons.add')"
                   icon="o_add"
                   dense no-caps>
                <q-menu>
                    <q-list separator dense>
                        <template v-for="(entity) in _.get(fields,'entity')">
                            <q-item clickable v-close-popup @click="openDialog(entity)">
                                <q-item-section>
                                    <q-item-label>{{ $translate('report-type.type.' + entity) }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </q-menu>
            </q-btn>
        </template>
    </Card>

    <template v-for="(entity) in _.get(fields,'entity')">
        <q-dialog v-model="dialog[entity]" no-backdrop-dismiss>
            <Card :title="$translate('report-type.type.' + entity)"
                  in-modal show-close-button>
                <component :is="Search[_.upperFirst(entity)]"
                           @onSelect="(data)=>selectEntity(entity,data)"/>
                <template v-slot:actions>
                    <q-btn icon="o_save"
                           :label="$translate('buttons.save')"
                           color="positive"
                           @click="addEntity"/>
                </template>
            </Card>
        </q-dialog>
    </template>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import {Card} from "@/Components/Block";
import * as Search from '@/Components/Search'

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object},
})

const related = defineModel('related')
const errors = defineModel('errors', {type: Object})

const dialog = ref([])
const data = ref({})

const openDialog = (entity) => {
    _.set(dialog.value, entity, true)
}

const currentEntity = ref({type: null, data: null})

const selectEntity = (entity, data) => {
    currentEntity.value = {
        type: entity,
        data: data
    }
}

const addEntity = () => {
    related.value[currentEntity.value.type].push(currentEntity.value.data)
    _.set(dialog.value, currentEntity.value.type, false)
}

const deleteEntity = (entity, index) => {
    related.value[entity].splice(index, 1)
}
</script>