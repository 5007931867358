<template>
    <Alert v-if="$translate('module-youtrack.description')"
           variant="info"
           :message="$translate('module-youtrack.description')"
    />
    <Alert v-if="_.isEmpty(props.settings.module.YOUTRACK_TOKEN)"
           variant="danger"
           message="Токен доступа не установлен"
    />
    <SettingsTable :data="config"/>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";
import _ from "lodash";
import helper from "@/helpers";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const config = [
    {
        name: 'YOUTRACK_ENABLED',
        label: $translate('module-youtrack.settings.YOUTRACK_ENABLED'),
        value: props.settings.module.YOUTRACK_ENABLED,
        description: $translate('module-youtrack.settings.YOUTRACK_ENABLED_description'),
        block: 'module',
        type: 'checkbox',
    },
    {
        name: 'YOUTRACK_HOST',
        label: $translate('module-youtrack.settings.YOUTRACK_HOST'),
        value: props.settings.module.YOUTRACK_HOST,
        description: $translate('module-youtrack.settings.YOUTRACK_HOST_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'YOUTRACK_TOKEN',
        label: $translate('module-youtrack.settings.YOUTRACK_TOKEN'),
        value: props.settings.module.YOUTRACK_TOKEN,
        description: $translate('module-youtrack.settings.YOUTRACK_TOKEN_description'),
        block: 'module',
        type: 'text',
    }
];
if (!_.isEmpty(props.settings.module.YOUTRACK_TOKEN)) {
    config.push({
        name: 'YOUTRACK_PROJECT',
        label: $translate('module-youtrack.settings.YOUTRACK_PROJECT'),
        value: props.settings.module.YOUTRACK_PROJECT,
        description: $translate('module-youtrack.settings.YOUTRACK_PROJECT_description'),
        block: 'module',
        type: 'select',
        multiple: false,
        options: props.data.module.youtrack.projects
    })
    if (!_.isEmpty(props.settings.module.YOUTRACK_PROJECT)) {
        config.push({
            name: 'YOUTRACK_STATES_DENY_COMMENT',
            label: $translate('module-youtrack.settings.YOUTRACK_STATES_DENY_COMMENT'),
            value: helper.jsonParse(props.settings.module.YOUTRACK_STATES_DENY_COMMENT),
            description: $translate('module-youtrack.settings.YOUTRACK_STATES_DENY_COMMENT_description'),
            block: 'module',
            type: 'select',
            multiple: true,
            options: props.data.module.youtrack.issueStates
        });
    }
}
</script>
