<template>
    <Card :title="$translate('project.view.feedback-info')"
          v-if="project.can.feedback.list"
          :hide-actions="!project.can.feedback.add || printable"
          no-card-section
          actions-align="left"
          class="q-mb-md">

        <FeedbackList :items="project.feedback"/>

        <template v-slot:actions>
            <q-btn icon="o_add"
                   :label="$translate('project.actions.add-feedback')"
                   dense no-caps
                   @click="showFeedbackDialog"/>
            <q-dialog v-model="feedbackDialog" no-backdrop-dismiss>
                <Card :title="$translate('project-feedback.title.feedback-add')" in-modal show-close-button>

                    <Alert :message="$translate('project-feedback.alert-1')" variant="warning"/>

                    <q-input v-model="feedbackForm.response"
                             :label="$translate('project-feedback.label.response')"
                             type="textarea"
                             :error="feedbackForm.errors.hasOwnProperty('response')"
                             :error-message="_.get(feedbackForm.errors,'response')"
                    />

                    <q-file v-model="feedbackForm.file"
                            v-if="project.can.feedback.file"
                            :label="$translate('project-feedback.label.file')"
                            :error="feedbackForm.errors.hasOwnProperty('file')"
                            :error-message="_.get(feedbackForm.errors,'file')"
                    >
                        <template v-slot:prepend>
                            <q-icon name="attach_file"/>
                        </template>
                    </q-file>

                    <q-select v-model="feedbackForm.ssc"
                              :label="$translate('project-feedback.label.ssc')"
                              :hint="$translate('project-feedback.label.ssc-hint')"
                              :options="sscOptions"
                              map-options
                              emit-value
                              multiple
                              use-chips
                              :error="feedbackForm.errors.hasOwnProperty('ssc')"
                              :error-message="_.get(feedbackForm.errors,'ssc')"
                    />

                    <template v-slot:actions>
                        <q-btn icon="o_save"
                               :label="$translate('buttons.save')"
                               color="positive"
                               @click="feedbackFormSubmit"
                        />
                    </template>
                </Card>
            </q-dialog>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import _ from 'lodash';
import FeedbackList from "@/Components/Feedback/Project/FeedbackList.vue";
import {Alert, Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar()

const props = defineProps({
    project: {
        type: Object,
        required: true
    },
    printable: {
        type: Boolean,
        default: false
    }
})

const feedbackDialog = ref(false)

const showFeedbackDialog = () => {
    feedbackDialog.value = true
}

const feedbackForm = useForm({
    ssc: [],
    response: null,
    file: null
})

const sscOptions = computed(() => {
    return _.map(props.project.sscresearches, (sscresearche) => ({
            value: sscresearche.ssc_id,
            label: sscresearche.ssc.name
        })
    )
})

const feedbackFormSubmit = () => {
    feedbackForm.post(route('project.feedback.store', props.project), {
        onSuccess: () => {
            feedbackDialog.value = false;
            router.reload()
        }
    })
}

</script>