<template>
    <Card title-bg="orange-1"
          :title="$translate('module-syexc.title-proposal', {
              'number': _.get(proposal, 'number'),
              'date': moment(_.get(proposal, 'created_at')).format($translate('common.datetime.js'))
          })"
          header-toolbar
          hide-actions
    >
        <template v-slot:headerActions>

            <q-btn v-if="_.get(can, 'edit')"
                   :label="$translate('buttons.edit')"
                   @click="router.get($route('services.sys-exp-ctrl.proposal.edit', proposal))"
                   color="primary"
                   icon="o_edit"
                   dense
            />
            <q-btn v-if="_.get(can, 'toApprove')"
                   :label="$translate('module-syexc.actions.proposal.to-approve')"
                   @click="router.get($route('services.sys-exp-ctrl.proposal.update-status', {
                       proposal,
                       status: 29 === Number(_.get(proposal, 'status')) ? 15 : 10
                   }))"
                   color="positive"
                   icon="done"
                   dense
            />
            <q-btn :label="$translate('module-syexc.actions.proposal.list')"
                   @click="router.get($route('services.sys-exp-ctrl.proposal.list'))"
                   icon="o_chevron_left"
                   color="orange-4"
                   dense
            />
        </template>
        <template v-slot:default>
            <div class="q-mb-md">
                <span class="q-mr-none">{{ $translate('module-syexc.proposal.fields.status') }}:</span>
                &nbsp;<span v-html="_.get(proposal, 'status_text')"></span>
            </div>
            <div class="row q-gutter-x-md">
                <div class="col-md col-24">
                    <Detail :proposal="proposal" />
                    <History :history="_.get(proposal, 'history')" />
                </div>
                <div class="col-md col-24">
                    <ExpertResult v-if="30 <= Number(_.get(proposal, 'status'))"
                                  :proposal="proposal"/>
                    <CommitteeResult v-if="38 <= Number(_.get(proposal, 'status'))"
                                     :committee="committee"/>
                    <SecretarForm v-if="_.get(can, 'secretar')"
                                  :has-committee-result-lawyer="!_.isNull(_.get(committee, 'resultLawyer'))"
                                  :proposal="proposal"
                                  :lists="lists"/>
                    <ExpertForm v-if="_.get(can, 'expert')"
                                :proposal="proposal"
                                :lists="lists"/>
                    <CommitteeForm v-if="_.get(can, 'committee')"
                                   :is-secretar="isSecretar"
                                   :committee="committee"
                                   :proposal="proposal"/>

                </div>
            </div>
        </template>

    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import Detail from "@/Components/Syexc/Proposal/Block/Detail.vue";
import History from "@/Components/Syexc/Proposal/Block/History.vue";
import ExpertResult from "@/Components/Syexc/Proposal/Block/ExpertResult.vue";
import CommitteeResult from "@/Components/Syexc/Proposal/Block/CommitteeResult.vue";
import SecretarForm from "@/Components/Syexc/Proposal/Form/SecretarForm.vue";
import ExpertForm from "@/Components/Syexc/Proposal/Form/ExpertForm.vue";
import CommitteeForm from "@/Components/Syexc/Proposal/Form/CommitteeForm.vue";
import {getCurrentInstance} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment";

const props = defineProps({
    proposal: {
        type: Object,
        required: true
    },
    committee: {
        type: Object,
        required: true
    },
    can: {
        type: Object,
        required: true
    },
    lists: {
        type: Object,
        required: true
    },
    isSecretar: {
        type: Boolean,
        required: true
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
</script>