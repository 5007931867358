<template>
    <div class="row q-gutter-x-md">
        <div class="col q-gutter-y-md">
            <component :is="reportBlock('Main')" :publication="publication"/>
            <component :is="reportBlock('RelatedProject')" :publication="publication"/>
        </div>
        <div class="col q-gutter-y-md">
            <component :is="reportBlock('Authors')" v-if="_.get(publication,'report_type.fields.authors.show')"
                       :title="_.get(publication,'report_type.fields.authors.name')"
                       :items="publication.authors"/>
            <component :is="reportBlock('Authors')" v-if="_.get(publication,'report_type.fields.responsible.show')"
                       :title="_.get(publication,'report_type.fields.responsible.name')"
                       :items="publication.responsible"/>
            <component :is="reportBlock('Files')" v-if="_.get(publication,'report_type.fields.files.show')"
                       :title="_.get(publication,'report_type.fields.files.name')"
                       :documents="publication.documents"
                       :links="publication.links"/>
            <component :is="reportBlock('Rubrics')" :publication="publication"/>
            <component :is="reportBlock('ExternalID')" :publication="publication"/>
        </div>
    </div>
</template>

<script setup>
import _ from "lodash";
import * as ReportMaterialBlock from "@/Components/ReportMaterial/Block/";

const reportBlock = (block) => ReportMaterialBlock[block];

defineProps({
    publication: {type: Object}
})
</script>