<template>
    <q-item-section avatar v-if="!hideAvatar">
        <UserAvatar :avatar="user.avatar"></UserAvatar>
    </q-item-section>
    <q-item-section>
        <q-item-label>{{ user.fulled_name }}</q-item-label>
        <template v-if="_.size(user.positions)>0">
            <div class="column items-start">
                <template v-for="(position) in user.positions">
                    <div class="flex items-start">
                        <q-chip dense color="grey-1" :title="$translate('cadre-staff.fields.personnel_number')"
                                class="text-small">
                            <q-icon name="o_numbers"/>
                            <span class="q-ml-xs">{{ position.personnel_number }}</span>
                        </q-chip>
                        <q-chip dense color="grey-1" :title="$translate('cadre-staff.fields.position')"
                                class="text-italic text-small">
                            {{ position.cadre_position }} {{ position.rate_formatted }}
                        </q-chip>
                    </div>
                </template>
                <q-chip dense color="grey-1" :title="$translate('users.fields.birthdate')" class="text-small"
                        v-if="user.birthdate">
                    <q-icon name="o_insert_invitation"/>
                    <span class="q-ml-xs">{{ moment(user.birthdate).format($translate('common.date.js')) }}</span>
                </q-chip>
            </div>
        </template>
        <template v-else v-for="(title) in userTitle(user)">
            <span class="q-mr-xs text-italic">{{ title.value }}</span>
        </template>
    </q-item-section>
    <q-item-section class="q-gutter-xs column items-start">
        <div class="q-gutter-x-xs">
            <q-chip dense v-if="user.ext_login" :title="$translate('users.fields.extlogin')" color="grey-2">
                <q-icon name="o_badge"/>
                <span class="q-ml-xs">{{ user.ext_login }}</span>
            </q-chip>
            <q-chip dense color="grey-2">
                <q-icon name="o_email"/>
                <span class="q-ml-xs">{{ user.email }}</span>
            </q-chip>
        </div>
        <div class="q-gutter-x-xs">
            <template v-for="(phone) in userPhones(user)">
                <q-chip dense color="grey-2">
                    <q-icon name="o_phone"/>
                    <span class="q-ml-xs">{{ phone.value }}</span>
                </q-chip>
            </template>
        </div>
    </q-item-section>
    <q-item-section v-if="withRoles">
        <q-item-label overline>Назначенные роли в подразделении</q-item-label>
        <q-item-label>
            <template v-for="(role) in staff.ssc_roles">
                <q-chip size="md" dense square color="grey-2">
                    <span>{{ role.display_name }}</span>
                    <q-btn flat dense icon="o_cancel" size="sm" text-color="red-3"
                           v-if="can.delete"
                           @click="deleteRole(role.user_id, role)"
                           :title="$translate('users.actions.delete_role')"/>
                </q-chip>
            </template>
        </q-item-label>
    </q-item-section>
    <q-item-section side>
        <q-btn dense icon="menu" flat v-if="!hideActions">
            <q-menu self="center right">
                <q-list dense>
                    <q-item v-if="can.detail"
                            clickable v-close-popup
                            @click="profileLink(staff.user_id)">
                        <q-item-section>
                            <q-item-label>{{ $translate('buttons.detail') }}</q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <q-icon name="o_account_box" size="xs"/>
                        </q-item-section>
                    </q-item>
                    <q-item v-if="can.template"
                            clickable v-close-popup
                            @click="openDialogTemplate">
                        <q-item-section>
                            <q-item-label>{{ $translate('ssc.actions.add-template-to-user') }}</q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <q-icon name="mdi-file-document-check-outline" size="xs"/>
                        </q-item-section>
                    </q-item>
                    <q-item v-if="can.logonas"
                            clickable v-close-popup
                            @click="loginAs(staff.user_id)">
                        <q-item-section>
                            <q-item-label>{{ $translate('auth.loginas', {name: staff.last_name}) }}</q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <q-icon name="o_meeting_room" size="xs"/>
                        </q-item-section>
                    </q-item>
                    <q-item v-if="!isDirector && can.delete"
                            clickable v-close-popup
                            @click="deleteUser(staff)">
                        <q-item-section>
                            <q-item-label>{{ $translate('buttons.delete') }}</q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <q-icon name="o_delete" size="xs"/>
                        </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup v-if="isDirector && can.delete"
                            @click="deleteDirector(staff)">
                        <q-item-section>
                            <q-item-label class="text-lowercase">
                                {{ $translate('ssc.delete-main-director') }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <q-icon name="o_delete" size="xs"/>
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-menu>
        </q-btn>
    </q-item-section>
    <q-dialog v-model="dialogTemplate">
        <Card title="Выберите шаблоны, заявки на основе которых будут доступны пользователю"
              in-modal show-close-button no-card-section>
            <q-inner-loading :showing="loadingTemplate">
                <q-spinner-gears size="50px" color="primary"/>
            </q-inner-loading>
            <q-list separator>
                <template v-for="(template,index) in sscTemplate" :key="template.id">
                    <q-item>
                        <q-item-section side>
                            <q-toggle v-model="formTemplate.template[index]"
                                      :true-value="template.id"
                                      :false-value="null"
                                      color="positive"
                            />
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>
                                {{ template.name }}
                            </q-item-label>
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="formTemplateSubmit"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import _ from "lodash";
import axios from 'axios';
import {router, useForm} from "@inertiajs/vue3";
import UserAvatar from '@/Components/UserAvatar.vue'
import {useQuasar} from "quasar";
import {getCurrentInstance, ref} from "vue";
import moment from 'moment';
import {Card} from "@/Components/Block";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    staff: {
        type: Object,
    },
    user: {
        type: Object,
    },
    ssc: {
        type: Object,
    },
    can: {
        type: Object,
        default: {
            delete: false,
        }
    },
    isDirector: {
        type: Boolean,
        default: false
    },
    hideAvatar: {
        type: Boolean,
        default: false
    },
    hideActions: {
        type: Boolean,
        default: false
    },
    withRoles: {
        type: Boolean,
        default: false
    },
})

const emit = defineEmits(['onSuccess'])

const userPhones = (user) => {
    return _.filter(user.additional, function (item) {
        return item.slug === 'phone' || item.slug === 'mobile'
    })
}

const userTitle = (user) => {
    return _.filter(user.additional, function (item) {
        return item.slug === 'title' || item.slug === 'position'
    })
}


/** Действия с пользователем **/

const profileLink = (user_id) => {
    // window.alert(user_id);
    router.visit(route('admin.users.show', user_id),
            {
                preserveState: true,
                preserveScroll: true
            })
}

const loginAs = (user_id) => {
    // window.alert(user_id);
    router.post(route('admin.logonas'),
            {loginas: user_id},
            {
                preserveState: true,
                preserveScroll: true
            })
}
const deleteUser = (user) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: user.fulled_name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('admin.ssc.staff.drop', {'ssc': props.ssc.id, 'user': user.user_id}),
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        emit('onSuccess')
                    }
                })
    })
}
const deleteDirector = (user) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: user.fulled_name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        let params = {ssc: props.ssc.id};
        if (_.get(props.ssc, 'director_id', null) === user.user_id) {
            _.set(params, 'main_director_id', user.user_id);
        } else {
            _.set(params, 'director_id', user.user_id);
        }
        router.delete(route('admin.ssc.director.delete', params),
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        emit('onSuccess')
                    }
                })
    })
}
const deleteRole = (user_id, role) => {

    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: role.display_name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('admin.ssc.staff.role.drop', [role.ssc_id, role.id]), {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                emit('onSuccess')
            }
        })
    })
}

const dialogTemplate = ref(false)
const openDialogTemplate = () => {
    dialogTemplate.value = true;
    loadTemplates()
}
const loadingTemplate = ref(false)
const sscTemplate = ref([])
const loadTemplates = () => {
    sscTemplate.value = [];
    loadingTemplate.value = true;
    axios.get(route('ajax.ssc', {m: 'template', ssc_id: props.ssc.id}))
            .then((response) => {
                sscTemplate.value = response.data.results
                _.forEach(sscTemplate.value, (v, i) => {
                    if (v.users.includes(props.staff.user_id)) {
                        formTemplate.template[i] = v.id
                    }
                })
            })
            .finally(() => {
                loadingTemplate.value = false;
            })
}
const formTemplate = useForm({
    template: []
})

const formTemplateSubmit = () => {
    formTemplate.transform((data) => ({
        template: _.filter(data.template)
    }))
            .post(route('admin.ssc.template', {ssc: props.ssc.id, user: props.staff.user_id}), {
                onSuccess: () => {
                    router.reload();
                    formTemplate.reset();
                    dialogTemplate.value = false;
                }
            })
}
</script>