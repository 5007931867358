<template>
    <Alert variant="info" :message="$translate('module-dadata.description')"/>
    <Alert variant="info" :message="$translate('module-dadata.description2')"/>
    <SettingsTable :data="config"/>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance} from "vue";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const config = [
    {
        name: 'DADATA_TOKEN',
        label: $translate('module-dadata.settings.DADATA_TOKEN'),
        value: props.settings.module.DADATA_TOKEN,
        description: $translate('module-dadata.settings.DADATA_TOKEN_description'),
        block: 'module',
        type: 'text',
    },
    {
        name: 'DADATA_SECRET',
        label: $translate('module-dadata.settings.DADATA_SECRET'),
        value: props.settings.module.DADATA_SECRET,
        description: $translate('module-dadata.settings.DADATA_SECRET_description'),
        block: 'module',
        type: 'text',
    },
];
</script>
