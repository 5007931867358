<template>
    <Card :title="title??$translate('Выбор издателя')"
          no-card-section in-modal show-close-button header-toolbar>
        <Alert variant="info">
            Воспользуйтесь поиском для выбора существующего издателя.<br/>
            Если издатель не найден, воспользуйтесь добавлением нового
        </Alert>
        <q-tabs v-model="tab">
            <q-tab name="search" :label="titleSearch??$translate('Поиск издателя')"/>
            <q-tab name="new" :label="titleCreate??$translate('Добавление нового издателя')"/>
        </q-tabs>
        <q-tab-panels v-model="tab">
            <q-tab-panel name="search">
                <SearchPublisher @onSelect="selectItem"/>
            </q-tab-panel>
            <q-tab-panel name="new">
                <q-input v-model="newItem.name"
                         :label="$translate('Наименование')"
                         class="col"
                         :error="newItem.errors.hasOwnProperty('name')"
                         :error-message="_.get(newItem.errors,'name')"/>
            </q-tab-panel>
        </q-tab-panels>
        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   @click="setItem"
                   color="positive"
                   dense
            />
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Card, Alert} from "@/Components/Block";
import {Publisher as SearchPublisher} from "@/Components/Search";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    title: {
        type: String,
        default: null
    },
    titleSearch: {
        type: String,
        default: null
    },
    titleCreate: {
        type: String,
        default: null
    }
})

const emit = defineEmits(['onSelect'])

const tab = ref('search')

const item = ref(null)

const selectItem = (data) => {
    item.value = data
}

const newItem = useForm({
    name: null,
})

const setItem = () => {
    if (tab.value === 'search') {
        emit('onSelect', item.value)
    }
    if (tab.value === 'new') {
        axios.post(route('services.science.publisher.store'), newItem)
            .catch((errors) => {
                _.forEach(errors.response.data.errors, (v, k) => {
                    _.set(newItem.errors, k, v.join(";"))
                })
            })
            .then((response) => {
                item.value = {publisher: response.data.publisher}
            })
            .finally(() => {
                emit('onSelect', item.value)
            })
    }
}
</script>