<template>
    <q-skeleton v-if="loading" height="3rem"/>
    <q-toolbar v-else class="bg-grey-4 cursor-text">
        <q-toolbar-title>
            <div class="row items-center">
                <div>{{ department.name_short }}</div>
            </div>
        </q-toolbar-title>
        <q-btn :icon="showDepartmentDetail?'expand_less':'expand_more'"
               color="grey-6" dense
               @click="showDepartmentDetail = !showDepartmentDetail"/>
    </q-toolbar>
    <q-scroll-area style="height: 75vh">
        <q-card-section v-if="showDepartmentDetail && !loading">
            <q-field :label="$translate('ssc.fields.name_short')" stack-label borderless>
                <template v-slot:control>
                    <div class="self-center full-width no-outline row items-center" tabindex="0">
                        <q-icon name="edit"
                                color="grey-5"
                                :title="$translate('buttons.edit')"
                                class="cursor-pointer"
                                left
                                v-if="department.can.edit">
                            <q-popup-edit v-model="department.name_short_i18n"
                                          v-slot="scope" buttons auto-save
                                          :title="$translate('ssc.fields.name_short')"
                                          :label-set="$translate('buttons.save')"
                                          :label-cancel="$translate('buttons.cancel')"
                                          @save="patchDepartmentShortName">
                                <template v-for="(lang_name,locale) in $translate('fields.locale')">
                                    <q-input v-model="scope.value[locale]" :label="lang_name"
                                             dense autofocus counter/>
                                </template>
                            </q-popup-edit>
                        </q-icon>
                        <div>{{ department.name_short }}</div>
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('ssc.fields.name')" stack-label borderless>
                <template v-slot:control>
                    <div class="self-center full-width no-outline row items-center" tabindex="0">
                        <q-icon name="edit"
                                :title="$translate('buttons.edit')"
                                color="grey-5"
                                class="cursor-pointer"
                                left
                                v-if="department.can.edit">
                            <q-popup-edit v-model="department.name_i18n"
                                          v-slot="scope" buttons auto-save
                                          :title="$translate('ssc.fields.name')"
                                          :label-set="$translate('buttons.save')"
                                          :label-cancel="$translate('buttons.cancel')"
                                          @save="patchDepartmentName">
                                <template v-for="(lang_name,locale) in $translate('fields.locale')">
                                    <q-input v-model="scope.value[locale]" :label="lang_name"
                                             dense autofocus counter/>
                                </template>
                            </q-popup-edit>
                        </q-icon>
                        <div>{{ department.name }}</div>
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('ssc.fields.description')" stack-label borderless>
                <template v-slot:control>
                    <div class="self-center full-width no-outline cursor-text row items-center" tabindex="0">
                        <q-icon name="edit"
                                :title="$translate('buttons.edit')"
                                color="grey-5"
                                class="cursor-pointer"
                                left
                                v-if="department.can.edit">
                            <q-popup-edit v-model="department.description_i18n"
                                          v-slot="scope" buttons auto-save
                                          :title="$translate('ssc.fields.description')"
                                          :label-set="$translate('buttons.save')"
                                          :label-cancel="$translate('buttons.cancel')"
                                          @save="patchDepartmentDescription">
                                <template v-for="(lang_name,locale) in $translate('fields.locale')">
                                    <q-input v-model="scope.value[locale]" :label="lang_name"
                                             type="textarea" rows="2"
                                             dense autofocus counter/>
                                </template>
                            </q-popup-edit>
                        </q-icon>
                        <div>{{ department.description }}</div>
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('ssc.fields.display')" stack-label borderless>
                <template v-slot:control>
                    <div class="self-center full-width no-outline cursor-text row items-center" tabindex="0">
                        <q-icon name="edit"
                                :title="$translate('buttons.edit')"
                                color="grey-5"
                                class="cursor-pointer"
                                left
                                v-if="department.can.edit">
                            <q-popup-edit v-model="department"
                                          v-slot="scope" buttons auto-save
                                          :title="$translate('ssc.fields.research')"
                                          :label-set="$translate('buttons.save')"
                                          :label-cancel="$translate('buttons.cancel')"
                                          @save="patchDepartmentResearchPermission">
                                <q-checkbox v-model="scope.value.display"
                                            :label="$translate('ssc.fields.display')"/>

                                <q-option-group v-model="scope.value.level" v-if="scope.value.display"
                                                :options="levelOptions"/>
                            </q-popup-edit>
                        </q-icon>
                        <div v-if="department.display">
                            {{ $translate('ssc.fields.level_' + department.level) }}
                        </div>
                        <div v-else class="text-lowercase">
                            {{ $translate('buttons.no') }}
                        </div>
                    </div>
                </template>
            </q-field>
        </q-card-section>
        <q-tabs v-model="tabStaff" dense align="left" v-if="department">
            <q-tab name='head' :label="$translate('ssc.title-director')"/>
            <q-tab name='staff' :label="$translate('ssc.title-staff')"
                   v-if="_.get(department.can,'staff.view')"/>
        </q-tabs>
        <q-tab-panels v-model="tabStaff" class="bg-transparent">
            <q-tab-panel name='head' class="q-pa-xs">
                <q-list separator dense v-if="department && !loading">
                    <!-- руководитель -->
                    <q-bar class="bg-grey-2 q-py-sm q-mb-sm">
                        <div class="text-overline">{{ $translate('ssc.title-director') }}</div>
                        <q-space/>
                        <div v-if="department.can.director.add">
                            <q-btn v-if="department.director"
                                   size="sm" dense icon="sync"
                                   @click="leaderModal = true"
                                   :label="$translate('ssc.actions.replacedirector')"/>
                            <q-btn v-else
                                   size="sm" dense icon="add"
                                   @click="leaderModal = true"
                                   :label="$translate('ssc.actions.adddirector')"/>
                        </div>
                    </q-bar>
                    <q-item v-if="loading">
                        <q-item-section avatar>
                            <q-skeleton type="QAvatar"/>
                        </q-item-section>
                        <q-item-section>
                            <q-skeleton type="text" height="3rem" width="100%"/>
                        </q-item-section>
                    </q-item>
                    <q-item v-else-if="department.director">
                        <StaffItem :staff="department.director"
                                   :user="department.director"
                                   :ssc="department"
                                   :can="_.merge(department.director.can,department.can.director)"
                                   @on-success="emit('onSuccess')"
                                   is-director/>
                    </q-item>
                    <q-dialog v-model="leaderModal" no-backdrop-dismiss>
                        <Card :title="$translate('ssc.new-main-director')"
                              title-bg="blue-grey-3"
                              in-modal
                              show-close-button>
                            <SearchUser @onSelect="selectLeader"/>
                            <template v-slot:actions>
                                <q-btn icon="save" :label="$translate('buttons.save')" color="positive"
                                       @click="leaderFormSubmit"/>
                            </template>
                        </Card>
                    </q-dialog>

                    <!-- заместители -->
                    <q-bar class="bg-grey-2 q-py-sm q-mb-sm">
                        <div class="text-overline">{{ $translate('ssc.title-heads') }}</div>
                        <q-space/>
                        <q-btn v-if="department.can.heads.add"
                               size="sm" dense icon="add" @click="leaderHeadModal = true"
                               :label="$translate('buttons.add')"/>
                    </q-bar>
                    <template v-for="(head) in department.heads">
                        <q-item v-if="head.user_id !== department.director_id">
                            <StaffItem :staff="head"
                                       :user="head"
                                       :ssc="department"
                                       :can="_.merge(head.can,department.can.heads)"
                                       @on-success="emit('onSuccess')"
                                       is-director/>
                        </q-item>
                    </template>
                    <q-dialog v-model="leaderHeadModal" no-backdrop-dismiss>
                        <Card :title="$translate('ssc.new-additional-director-select-modal-title')" in-modal
                              show-close-button
                              title-bg="blue-grey-3">
                            <SearchUser @onSelect="selectLeader"/>
                            <template v-slot:actions>
                                <q-btn icon="save" :label="$translate('buttons.save')" color="positive"
                                       @click="leaderHeadFormSubmit"/>
                            </template>
                        </Card>
                    </q-dialog>
                </q-list>
            </q-tab-panel>
            <q-tab-panel name='staff' class="q-pa-xs">
                <q-list separator v-if="department && !loading">
                    <!-- сотрудники -->
                    <q-bar class="bg-grey-2 q-py-sm q-mb-sm">
                        <div class="text-overline">{{ $translate('ssc.title-staff') }}</div>
                        <q-space/>
                        <q-btn v-if="department.can.staff.add"
                               size="sm" dense icon="add" @click="staffModal=true"
                               :label="$translate('buttons.add')"/>
                    </q-bar>
                    <template v-for="(staff) in department.staff">
                        <q-item v-if="staff.user_id !== department.director_id">
                            <StaffItem :staff="staff"
                                       :user="staff"
                                       :ssc="department"
                                       :can="_.merge(staff.can,department.can.staff)"
                                       @on-success="emit('onSuccess')"/>
                        </q-item>
                    </template>
                    <q-dialog v-model="staffModal" no-backdrop-dismiss>
                        <Card :title="$translate('ssc.new-staff')" in-modal show-close-button
                              title-bg="blue-grey-3">
                            <SearchUser @onSelect="selectStaffUser"/>
                            <SearchRoles @onSelect="selectStaffRole" :scope="['ssc']"/>
                            <template v-slot:actions>
                                <q-btn icon="save" :label="$translate('buttons.save')" color="positive"
                                       @click="staffFormSubmit"/>
                            </template>
                        </Card>
                    </q-dialog>
                </q-list>
            </q-tab-panel>
        </q-tab-panels>
    </q-scroll-area>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from 'lodash';
import {
    User as SearchUser,
    Roles as SearchRoles
} from "@/Components/Search/";
import StaffItem from "@/Components/SSC/Staff/StaffItem.vue";
import {Card} from "@/Components/Block";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    selectedDepartment: {
        type: Object,
        default: {},
    },
    loading: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['onSuccess'])

const department = computed(() => props.selectedDepartment)

const showDepartmentDetail = ref(false)
const tabStaff = ref('head')

const patchDepartmentName = (value, initValue) => {
    router.patch(route('admin.ssc.update', department), {
        name_i18n: value
    }, {
        onSuccess: () => {
            emit('onSuccess')
        }
    })
}
const patchDepartmentShortName = (value, initValue) => {
    router.patch(route('admin.ssc.update', department.value), {
        name_short_i18n: value
    }, {
        onSuccess: () => {
            emit('onSuccess')
        }
    })
}
const patchDepartmentDescription = (value, initValue) => {
    router.patch(route('admin.ssc.update', department.value), {
        description_i18n: value
    }, {
        onSuccess: () => {
            emit('onSuccess')
        }
    })
}
const patchDepartmentResearchPermission = (value, initValue) => {
    router.patch(route('admin.ssc.update', department.value), {
        display: value.display,
        level: value.level
    }, {
        onSuccess: () => {
            emit('onSuccess')
        }
    })
}
const levelOptions = computed(() => {
    return [
        {value: 1, label: $translate('ssc.fields.level_1')},
        {value: 2, label: $translate('ssc.fields.level_2')},
        {value: 3, label: $translate('ssc.fields.level_3')}
    ]
})
const leaderModal = ref(false)
const leaderHeadModal = ref(false)
const leaderForm = useForm({
    user_id: null,
    replace: false
})
const selectLeader = (user) => {
    leaderForm.user_id = user.id
}
const leaderFormSubmit = () => {
    leaderForm
            .transform((data) => ({
                ...data,
                replace: true
            }))
            .patch(route('admin.ssc.director.update', props.selectedDepartment), {
                onSuccess: () => {
                    leaderModal.value = false
                    emit('onSuccess')
                }
            })
}
const leaderHeadFormSubmit = () => {
    leaderForm
            .transform((data) => ({
                ...data,
                replace: false
            }))
            .patch(route('admin.ssc.director.update', props.selectedDepartment), {
                onSuccess: () => {
                    leaderHeadModal.value = false
                    emit('onSuccess')
                }
            })
}
const staffModal = ref(false)
const staffForm = useForm({
    user_id: null,
    role_id: false
})
const selectStaffUser = (user) => {
    staffForm.user_id = user.id
}
const selectStaffRole = (id) => {
    staffForm.role_id = id
}
const staffFormSubmit = () => {
    staffForm
            .patch(route('admin.ssc.staff', props.selectedDepartment), {
                onSuccess: () => {
                    staffModal.value = false
                    emit('onSuccess')
                }
            })
}
</script>