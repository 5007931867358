<template>
    <q-btn icon="o_headset_mic"
           flat
           color="grey-8"
           class="self-stretch"
           :class="{'q-px-sm':$q.screen.lt.sm}">
        <q-tooltip class="text-body2">{{ $translate('helpdesk.title.menu') }}</q-tooltip>
        <q-menu fit>
            <q-list separator>
                <q-item v-close-popup clickable @click="gotoIssues">
                    <q-item-section side>
                        <q-icon name="headset_mic"/>
                    </q-item-section>
                    <q-item-section class="text-uppercase">
                        {{ $translate('menu.user.issues') }}
                    </q-item-section>
                </q-item>
                <q-item v-close-popup clickable @click="openDialog">
                    <q-item-section side>
                        <q-icon name="support"/>
                    </q-item-section>
                    <q-item-section>
                        {{ $translate('helpdesk.title.button') }}
                    </q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>
    <q-dialog v-model="dialog" position="right"
              no-backdrop-dismiss
              no-esc-dismiss
              id="helpdesk-dialog">
        <HelpDeskDialog @onSuccess="closeDialog"/>
    </q-dialog>
</template>

<script setup>
import { getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import HelpDeskDialog from "@/Components/HelpDesk/HelpDeskDialog.vue";


const $q = useQuasar()
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const dialog = ref(false)
const expanded = ref()

const openDialog = () => dialog.value = true;
const gotoIssues = () => router.visit(route('helpdesk.issues.list'))

const closeDialog = () => dialog.value = false;
</script>