<template>
    <Card :title="$translate('module-delivery.title-order-show',{number: order.number})"
          header-toolbar no-card-section hide-actions>
        <div class="q-pa-sm q-gutter-x-sm flex justify-start">
            <q-btn icon="navigate_before"
                   :label="$translate('module-delivery.actions.list-orders-all')"
                   color='primary'
                   @click="router.visit($route('services.delivery.order.list-all'))"
                   dense no-caps>
            </q-btn>
            <q-btn v-if="order.project"
                   icon="navigate_before"
                   :label="$translate('module-delivery.actions.list-orders-project',{project:order.project.number})"
                   color='primary'
                   @click="router.visit($route('project.delivery.list',order.project))"
                   dense no-caps>
            </q-btn>
            <q-btn v-if="order.can.confirm"
                   icon="o_check"
                   :label="$translate('buttons.approve')"
                   color='positive'
                   @click="approveOrder"
                   dense no-caps>
            </q-btn>
            <q-btn v-if="order.can.decline"
                   icon="o_clear"
                   :label="$translate('buttons.decline')"
                   color='negative'
                   @click="declineOrder"
                   dense no-caps>
            </q-btn>
            <q-btn v-if="order.can.delivery"
                   :label="$translate('buttons.to-delivery')"
                   color='info'
                   @click="deliveryOrder"
                   dense no-caps>
            </q-btn>
            <q-btn v-if="order.can.selfDelivery"
                   :label="$translate('buttons.to-self-delivery')"
                   color='info'
                   @click="selfDeliveryOrder"
                   dense no-caps>
            </q-btn>
            <q-btn v-if="order.can.complete"
                   icon="o_check"
                   :label="$translate('buttons.delivered')"
                   color='positive'
                   @click="completeDialog = true"
                   dense no-caps>
            </q-btn>
            <q-btn v-if="order.can.cancel"
                   icon="o_clear"
                   :label="$translate('buttons.undelivered')"
                   color='negative'
                   @click="cancelOrder"
                   dense no-caps>
            </q-btn>
            <q-btn icon="o_print"
                   :label="$translate('buttons.print')"
                   :href="$route('services.delivery.order.print',order)"
                   color='dark'
                   target="_blank"
                   class="q-ml-auto q-mr-none"
                   dense no-caps>
            </q-btn>
        </div>
        <q-separator/>
        <q-card-section>
            <q-field :label="$translate('module-delivery.order.fields.number')"
                     stack-label
                     borderless
                     tag="div">
                <template v-slot:control>
                    <div class="full-width items-center">
                        {{ order.number }}
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.date')"
                     stack-label
                     borderless
                     tag="div">
                <template v-slot:control>
                    <div class="full-width flex items-center">
                        <span>{{ moment(order.date).format($translate('common.date.js')) }}</span>
                        <q-btn v-if="order.can.changeDate"
                               icon="o_edit"
                               size="sm"
                               dense flat
                               @click="changeDateDialog = true"/>
                        <q-dialog v-model="changeDateDialog">
                            <Card :title="$translate('module-delivery.order.actions.change-delivery-date')"
                                  show-close-button no-card-section>
                                <q-date v-model="changeDateForm.date"
                                        today-btn
                                        minimal
                                        flat
                                        :options="dateOptions"
                                        first-day-of-week="1">
                                </q-date>
                                <template v-slot:actions>
                                    <q-btn icon="o_save"
                                           :label="$translate('buttons.save')"
                                           color="positive"
                                           @click="changeDateSubmit"/>
                                </template>
                            </Card>
                        </q-dialog>

                        <q-btn v-if="order.can.changeDateApprove"
                               icon="o_check"
                               :label="$translate('buttons.approve')"

                               dense flat no-caps
                               color="positive"
                               @click="changeDateApprove"/>

                        <q-btn v-if="order.can.changeDateDecline"
                               icon="o_clear"
                               :label="$translate('buttons.decline')"

                               dense flat no-caps
                               color="negative"
                               @click="changeDateDecline"/>
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.status')"
                     stack-label
                     borderless
                     tag="div">
                <template v-slot:control>
                    <div class="full-width items-center">
                        {{ $translate('module-delivery.order.status.' + order.status) }}
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.project')"
                     stack-label
                     borderless
                     tag="div"
                     v-if="order.project">
                <template v-slot:control>
                    <div class="full-width items-center">
                        {{ order.project.number }}
                        {{ order.project.name }}
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.goods')"
                     stack-label
                     borderless
                     tag="div">
                <template v-slot:control>
                    <div class="full-width items-center q-gutter-y-xs">
                        <div class="row q-ml-md">
                            <span class="col-3">{{ $translate('module-delivery.order.fields.goods_type') }}:</span>
                            <span>{{ $translate('module-delivery.goods.type.' + order.goods.goods_type) }}</span>
                        </div>
                        <div class="row q-ml-md">
                            <span class="col-3">{{ $translate('module-delivery.order.fields.goods_name') }}:</span>
                            <span>{{ order.goods.goods.name }}</span>
                        </div>
                        <div class="row q-ml-md">
                            <span class="col-3">{{ $translate('module-delivery.order.fields.goods_count') }}:</span>
                            <span>{{ order.count }} {{ order.units }}</span>
                        </div>
                    </div>
                </template>
            </q-field>

            <q-field
                    :label="$translate('module-delivery.address.delivery.'+(order.self_delivery?'self':'from'))"
                    stack-label
                    borderless
                    tag="div">
                <template v-slot:control>
                    <div class="full-width items-center q-mb-xs">
                        <div>{{ _.get(order.from_address, 'address') }}</div>
                    </div>
                    <q-btn v-if="order.can.address"
                           icon="o_edit"
                           :label="$translate('module-delivery.order.actions.change-delivery-address')"
                           dense no-caps
                           @click="formAddressDialog = true"/>
                    <q-dialog v-model="formAddressDialog" no-backdrop-dismiss>
                        <Card :title="$translate('module-delivery.order.actions.change-delivery-address-select')"
                              in-modal show-close-button>
                            <q-select v-model="formAddress.address"
                                      :options="order.from_ssc.address"
                                      option-label="address"
                                      option-value="id"
                                      map-options
                                      emit-value/>
                            <template v-slot:actions>
                                <q-btn icon="o_save"
                                       :label="$translate('buttons.save')"
                                       color="positive"
                                       @click="formAddressSubmit"
                                />
                            </template>
                        </Card>
                    </q-dialog>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.delivery_to')"
                     stack-label
                     borderless
                     tag="div"
                     v-if="!order.self_delivery">
                <template v-slot:control>
                    <div class="full-width items-center">
                        <div>{{ _.get(order.to_ssc, 'name') }}</div>
                        <div>{{ _.get(order.to_address, 'address') }}</div>
                        <div>{{ _.get(order, 'room') }}</div>
                    </div>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.comment')"
                     stack-label
                     borderless
                     tag="div">
                <template v-slot:control>
                    <div class="full-width items-center" v-html="helpers.nl2br(order.comment)"/>
                </template>
            </q-field>

            <q-field :label="$translate('module-delivery.order.fields.user')"
                     stack-label
                     borderless>
                <template v-slot:control>
                    <StaffItem :user="order.user"
                               :staff="order.user"
                               hide-actions/>
                </template>
            </q-field>
        </q-card-section>
    </Card>
    <q-dialog v-model="completeDialog" no-backdrop-dismiss>
        <Card in-modal show-close-button header-toolbar
              :title="$translate('module-delivery.title-complete-order',{order:order.number})">
            <q-input v-model="completeForm.date"
                     type="date"
                     :prefix="$translate('module-delivery.order.complete.fields.date')"
                     input-class="text-center"
                     style="max-width:320px"
                     :error="completeForm.errors.hasOwnProperty('date')"
                     :error-message="_.get(completeForm.errors,'date')"
            />

            <q-file v-model="completeForm.file"
                    :label="$translate('module-delivery.order.complete.fields.file')"
                    :hint="$translate('module-delivery.order.complete.fields.file-hint')"
                    :error="completeForm.errors.hasOwnProperty('file')"
                    :error-message="_.get(completeForm.errors,'file')"
            />

            <div class="q-gutter-y-md q-my-md">
                <q-radio v-model="completeForm.method" val="checkout" size="xs">
                    <strong>Списать &laquo;{{ _.get(order.goods, 'goods.name') }}&raquo;</strong>
                    с баланса &laquo;{{ _.get(order, 'from_ssc.name') }}&raquo;
                    в количестве: {{ order.count }} {{ order.units }}
                </q-radio>
                <q-radio v-model="completeForm.method" val="transfer" size="xs"
                         v-if="order.can.transfer">
                    <strong>Передать &laquo;{{ _.get(order.goods, 'goods.name') }}&raquo; на баланс</strong>
                    получателя: &laquo;{{ _.get(order, 'to_ssc.name') }}&raquo; в
                    количестве: {{ order.count }} {{ order.units }}
                </q-radio>
                <div class="text-negative">
                    {{ _.get(completeForm.errors, 'method') }}
                </div>
            </div>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.delivered')"
                       color="positive"
                       @click="completeOrder"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {Card, Alert} from "@/Components/Block";
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import moment from "moment";
import StaffItem from "@/Components/SSC/Staff/StaffItem.vue";
import {Notify, useQuasar} from "quasar";
import helpers from "@/helpers";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const $q = useQuasar()

const props = defineProps({
    order: {
        type: Object,
    },
    deliveryOrderDate: {
        type: Array
    }
});

const approveOrder = () => {
    router.patch(route('services.delivery.order.action', [props.order, 'confirm']), {}, {
        onSuccess: () => {
            router.reload()
        }
    })
}

const declineOrder = () => {
    $q.dialog({
        title: 'Подтверждение отклонения',
        message: 'Укажите причину отклонения заявки',
        prompt: {
            model: '',
            type: 'textarea' // optional
        },
        cancel: {
            color: 'dark',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'negative',
            noCaps: true,
            label: $translate('buttons.decline')
        },
        persistent: true
    }).onOk(data => {
        router.patch(route('services.delivery.order.action', [props.order, 'decline']), {comment: data})
    })
}

const deliveryOrder = () => {
    if (props.order.from_address_id) {
        router.patch(route('services.delivery.order.action', [props.order, 'delivery']), {}, {
            onSuccess: () => {
                router.reload()
            }
        })
    } else {
        $q.notify({
            color: 'negative',
            message: 'Укажите адрес отправления',
            position: "top",
            progress: false,
            icon: "error_outline"
        })
    }
}

const selfDeliveryOrder = () => {
    router.patch(route('services.delivery.order.action', [props.order, 'selfDelivery']), {}, {
        onSuccess: () => {
            router.reload()
        }
    })
}


const formAddressDialog = ref(false)

const formAddress = useForm({
    address: null
})

const formAddressSubmit = () => {
    formAddress.post(route('services.delivery.order.address', props.order), {
        onSuccess: () => {
            formAddressDialog.value = false
            router.reload()
        }
    })
}

const changeDateDialog = ref(false)
const changeDateForm = useForm({
    date: null
})
const changeDateSubmit = () => {
    changeDateForm.post(route('services.delivery.order.action', [props.order, 'changeDate']), {
        onSuccess: () => {
            changeDateDialog.value = false
            router.reload()
        }
    })
}
const changeDateApprove = () => {
    router.patch(route('services.delivery.order.action', [props.order, 'changeDateApprove']), {}, {
        onSuccess: () => {
            router.reload()
        }
    })
}
const changeDateDecline = () => {
    router.patch(route('services.delivery.order.action', [props.order, 'changeDateDecline']), {}, {
        onSuccess: () => {
            router.reload()
        }
    })
}
/**
 * Проверка даты на доступность заполнения
 *
 * @param {Date} date
 * @returns {boolean}
 */
const dateOptions = (date) => {
    if (moment(date, 'YYYY/MM/DD').startOf('day').isBefore()) {
        return false
    }
    return _.includes(props.deliveryOrderDate, moment(date, 'YYYY/MM/DD').startOf('day').format('YYYY-MM-DD'))
}

const completeDialog = ref(false)
const completeForm = useForm({
    date: props.order.date,
    file: null,
    method: 'checkout'
})
const completeOrder = () => {
    completeForm.post(route('services.delivery.order.action', [props.order, 'complete']),
            {
                onSuccess: () => {
                    completeDialog.value = false;
                    router.reload()
                }
            })
}
const cancelOrder = () => {
    $q.dialog({
        title: 'Заявка не выполнена',
        message: 'Укажите причину недоставки',
        prompt: {
            model: '',
            type: 'textarea' // optional
        },
        cancel: {
            color: 'dark',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'negative',
            noCaps: true,
            label: $translate('buttons.send')
        },
        persistent: true
    }).onOk(data => {
        router.patch(route('services.delivery.order.action', [props.order, 'cancel']), {comment: data})
    })
}
</script>