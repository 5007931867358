<template>
    <Card title-bg="pink-1"
          :title="$translate('module-archive.project.card.title', {
              project: _.get(archiveProject, 'data.number')
          })"
          no-card-section
          header-toolbar
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('buttons.back')"
                   @click="router.get($route('services.archive.projects'))"
                   icon="o_chevron_left"
                   dense
                   flat
            />
        </template>
        <q-tabs v-model="tab" align="left" no-caps>
            <q-tab name="project" :label="$translate('module-archive.project.tabs.card')"/>
            <q-tab name="researches" :label="$translate('module-archive.project.tabs.researches')">
                <q-badge color="primary" text-color="white" floating>
                    {{ _.get(archiveProject, 'data.project_info.afm_count') }}
                </q-badge>
            </q-tab>
            <q-tab name="publications" :label="$translate('module-archive.project.tabs.publications')">
                <q-badge color="primary" text-color="white" floating>
                    {{ _.get(archiveProject, 'data.project_info.publications_count') }}
                </q-badge>
            </q-tab>
        </q-tabs>
        <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="project">
                <div class="row q-gutter-x-md">
                    <div class="col-md col-24">
                        <component :is="showProjectBlock('Main')" :archive-project="_.get(archiveProject, 'data')"/>
                        <component :is="showProjectBlock('Additional')" v-if="hasAdditional"
                                                  :edu-info="_.get(archiveProject, 'data.edu_info')"
                        />
                        <component :is="showProjectBlock('Students')" v-if="hasStudents"
                                                :students="_.get(archiveProject, 'data.project_students')"
                        />
                        <component :is="showProjectBlock('Comments')" v-if="hasComments"
                                                :comments="_.get(archiveProject, 'data.comment')"
                        />
                        <component :is="showProjectBlock('Files')" v-if="hasFiles"
                                             :files="_.get(archiveProject, 'data.project_files')"
                        />
                        <component :is="showProjectBlock('Devices')" v-if="hasDevices"
                                               :devices="_.get(archiveProject, 'data.project_devices')"
                        />
                        <component :is="showProjectBlock('Employees')" v-if="hasEmployees"
                                                 :employees="_.get(archiveProject, 'data.project_employees')"
                        />
                        <component :is="showProjectBlock('Reagents')" v-if="hasReagents"
                                                :reagents="_.get(archiveProject, 'data.project_reagents')"
                        />
                    </div>
                    <div class="col-md col-24">
                        <component :is="showProjectBlock('Leader')" :leader="_.get(archiveProject, 'data.leader')"/>
                        <component :is="showProjectBlock('Members')" :project-users="_.get(archiveProject, 'data.project_users')"/>
                        <component :is="showProjectBlock('ExpectedResults')" :expected-results="_.get(archiveProject, 'data.expected_results')"/>
                        <component :is="showProjectBlock('Expenses')" :project-fin="_.get(archiveProject, 'data.project_fin')"/>
                        <component :is="showProjectBlock('Acts')" v-if="hasActs" :acts="_.get(archiveProject, 'data.project_acts')"/>
                        <component :is="showProjectBlock('Reviews')" v-if="hasReviews" :reviews="_.get(archiveProject, 'data.review')"/>
                    </div>
                </div>
            </q-tab-panel>
            <q-tab-panel name="researches">
                <DataTable allow-search
                           :columns="researchesColumns"
                           :request-route="$route('ajax.archive', {
                               m: 'researches',
                               project_id: _.get(archiveProject, 'data.id')
                           })"
                >
                    <template v-slot:header-cell-samples="props">
                        <q-th :props="props">
                            <span v-html="props.col.label"></span>
                        </q-th>
                    </template>
                    <template v-slot:body-cell-number="props">
                        <q-td :props="props" class="no-wrap">
                            <span v-html="props.value"></span>
                        </q-td>
                    </template>
                    <template v-slot:body-cell-leader_fio="props">
                        <q-td :props="props" class="no-wrap">
                            <span v-html="props.value"></span>
                        </q-td>
                    </template>
                    <template v-slot:body-cell-initiator_fio="props">
                        <q-td :props="props" class="no-wrap">
                            <span v-html="props.value"></span>
                        </q-td>
                    </template>
                    <template v-slot:body-cell-department="props">
                        <q-td :props="props" class="no-wrap">
                            <span v-html="props.value"></span>
                        </q-td>
                    </template>
                    <template v-slot:body-cell-task="props">
                        <q-td :props="props" class="no-wrap">
                            <span v-html="props.value"></span>
                        </q-td>
                    </template>
                    <template v-slot:body-cell-samples="props">
                        <q-td :props="props" class="no-wrap">
                            <span v-html="props.value"></span>
                        </q-td>
                    </template>
                    <template v-slot:body-cell-research_state="props">
                        <q-td :props="props" class="no-wrap">
                            <span v-html="props.value"></span>
                        </q-td>
                    </template>
                    <template v-slot:body-cell-employee_fio="props">
                        <q-td :props="props" class="no-wrap">
                            <span v-html="props.value"></span>
                        </q-td>
                    </template>
                </DataTable>
            </q-tab-panel>
            <q-tab-panel name="publications">
                <PublicationsList :publications="_.values(_.get(archiveProject, 'data.publications'))"/>
            </q-tab-panel>
        </q-tab-panels>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import * as ProjectBlocks from "@/Components/Archive/Project/Block";
import {List as PublicationsList} from "@/Components/Archive/Publication";
import {computed, getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import DataTable from "@/Components/DataTable.vue";

const showProjectBlock = (block) => ProjectBlocks[block]

const props = defineProps({
    archiveProject: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const tab = ref('project');

const hasAdditional = computed(() => _.some(
    _.values(_.get(props.archiveProject.data, 'edu_info')),
    o => !_.isEmpty(o)
));
const hasStudents = computed(() => !_.isEmpty(_.get(props.archiveProject.data, 'project_students')));
const hasComments = computed(() => !_.isEmpty(_.get(props.archiveProject.data, 'comment')));
const hasFiles = computed(() => !_.isEmpty(_.get(props.archiveProject.data, 'project_files')));
const hasActs = computed(() => !_.isEmpty(_.get(props.archiveProject.data, 'project_acts')));
const hasReviews = computed(() => !_.isEmpty(_.get(props.archiveProject.data, 'review')));
const hasDevices = computed(() => !_.isEmpty(_.get(props.archiveProject.data, 'project_devices')));
const hasEmployees = computed(() => !_.isEmpty(_.get(props.archiveProject.data, 'project_employees')));
const hasReagents = computed(() => !_.isEmpty(_.get(props.archiveProject.data, 'project_reagents')));

const researchesColumns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'number', align: 'left', label: $translate('module-archive.research.fields.number'), field: 'number'},
    {
        name: 'leader_fio',
        align: 'left',
        label: $translate('module-archive.research.fields.leader_fio'),
        field: 'leader_fio'
    },
    {
        name: 'initiator_fio',
        align: 'left',
        label: $translate('module-archive.research.fields.initiator_fio'),
        field: 'initiator_fio'
    },
    {
        name: 'department',
        align: 'left',
        label: $translate('module-archive.research.fields.department'),
        field: 'department'
    },
    {name: 'task', align: 'left', label: $translate('module-archive.research.fields.task'), field: 'task'},
    {name: 'samples', align: 'center', label: $translate('module-archive.research.fields.samples'), field: 'samples'},
    {
        name: 'research_state',
        align: 'left',
        label: $translate('module-archive.research.fields.research_state'),
        field: 'research_state'
    },
    {
        name: 'employee_fio',
        align: 'left',
        label: $translate('module-archive.research.fields.employee_fio'),
        field: 'employee_fio'
    },
];
</script>
