<template>
    <q-btn :icon="notificationsCount>0?'notifications':'o_notifications'"
           @click="toggleNotificationSide"
           flat
           color="grey-8"
           class="self-stretch"
           :class="{'q-px-sm':$q.screen.lt.sm}">
        <q-badge v-if="notificationsCount>0"
                 color="red"
                 text-color="white"
                 class="q-mt-sm q-mr-sm"
                 floating>
            {{ notificationsCount }}
        </q-badge>
        <q-tooltip class="text-body2">{{ $translate('module-notify.name') }}</q-tooltip>
    </q-btn>
    <q-drawer side="right"
              v-model="notificationSide"
              :width="350"
              :breakpoint="500"
              class="bg-grey-2 q-pb-lg"
              overlay
              elevated>
        <q-tabs v-model="tabs"
                @update:modelValue="toggleTab"
                breakpoint="500"
                shrink
                class="text-grey"
                active-color="dark"
                align="justify"
                indicator-color="green-2">
            <q-btn icon="close"
                   size="sm"
                   color="dark"
                   class="q-px-sm full-height"
                   @click="notificationSide = false"
                   square
                   flat/>
            <q-tab name="notify"
                   :label="$translate('module-notify.tabs.notify')"
                   class="q-px-xs">
                <q-badge v-if="notifyCount>0"
                         color="red"
                         text-color="white"
                         floating
                         rounded/>
            </q-tab>
            <q-tab name="news"
                   :label="$translate('module-notify.tabs.news')"
                   class="q-px-xs">
                <q-badge v-if="newsCount>0"
                         color="red"
                         text-color="white"
                         floating
                         rounded/>
            </q-tab>
            <q-btn icon="construction"
                   size="sm"
                   @click="router.get($route('notify.settings')); notificationSide = false"
                   class="q-px-md full-height"
                   flat>
                <q-tooltip class="text-body2">{{ $translate('module-notify.tabs.settings') }}</q-tooltip>
            </q-btn>
        </q-tabs>
        <q-separator/>
        <div class="fixed-full full-height max-height-vh-100 z-top" v-if="loading">
            <q-inner-loading :showing="loading">
                <div class="flex items-center justify-center">
                    <q-spinner-gears size="50px" color="primary"/>
                </div>
            </q-inner-loading>
        </div>
        <q-tab-panels animated v-model="tabs">
            <q-tab-panel name="notify" class="q-pa-none">
                <q-list separator v-if="notifyCount>0">
                    <q-item v-for="(note) in notify">
                        <q-item-section>
                            <q-item-label class="text-weight-bold text-italic ">
                                {{ note.data.title }}
                            </q-item-label>
                            <q-item-label v-html="note.data.message" class="q-my-sm"></q-item-label>
                            <div class="row inline items-center justify-between no-wrap">
                                <div class="row items-center text-blue-grey">
                                    <q-icon name="watch_later" left/>
                                    <div>{{ formatDate(note.created_at, $translate('common.datetime.js')) }}</div>
                                </div>
                                <div class="row align-center text-blue-grey">
                                    <q-btn icon="done" size="sm"
                                           :label="$translate('module-notify.actions.mark-as-read')"
                                           @click="markRead(note.id)"></q-btn>
                                </div>
                            </div>
                        </q-item-section>
                    </q-item>
                </q-list>
                <q-banner v-else>{{ $translate('module-notify.actions.no-items') }}</q-banner>
            </q-tab-panel>
            <q-tab-panel name="news" class="q-pa-none">
                <q-list separator>
                    <q-item v-for="(note) in news">
                        <q-item-section :class="note.read_at?'text-grey-6':null">
                            <q-item-label class="text-weight-bold text-italic ">
                                {{ note.data.title }}
                            </q-item-label>
                            <q-item-label v-html="note.data.message" class="q-my-sm"/>
                            <div class="row inline items-center justify-between no-wrap">
                                <div class="row items-center"
                                     :class="note.read_at?'text-grey-6':'text-blue-grey'">
                                    <q-icon name="watch_later" left/>
                                    <div>{{ formatDate(note.created_at, $translate('common.datetime.js')) }}</div>
                                </div>
                                <div class="row align-center text-blue-grey" v-if="!note.read_at">
                                    <q-btn size="sm"
                                           icon="done"
                                           @click="markRead(note.id)"
                                           :label="$translate('module-notify.actions.mark-as-read')"></q-btn>
                                </div>
                            </div>
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-tab-panel>
        </q-tab-panels>
        <q-bar v-if="notificationsCount > 1" class="fixed-bottom bg-white">
            <q-btn variant="outline-secondary"
                   size="sm"
                   icon="done_all"
                   @click="markRead('all')"
                   :label="$translate('module-notify.actions.mark-all-read')"/>
        </q-bar>
    </q-drawer>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, ref} from "vue";
import {usePage, router} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import moment from "moment";
import _ from "lodash";
import axios from "axios";

const app = getCurrentInstance()
const $q = useQuasar()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

const loading: any = ref(false)

const notify: any = ref([]);
const notifyCount: any = computed(() => _.get(usePage().props.auth, 'notifications.notify'));

const news: any = ref([]);
const newsCount: any = computed(() => _.get(usePage().props.auth, 'notifications.news'));

const notificationsCount: any = computed(() => notifyCount.value + newsCount.value)

const tabs: any = ref('notify')

const notificationSide: any = ref(false)

const toggleNotificationSide = () => {
    if (!notificationSide.value) {
        loadNotification()
    }
    notificationSide.value = !notificationSide.value;
}
const loadNotification = () => {
    loading.value = true;
    axios.get($route('notify.last'))
            .then((response) => {
                notify.value = response.data;
            })
            .finally(() => {
                loading.value = false;
            })
}
const loadNews = () => {
    loading.value = true;
    axios.get($route('notify.news'))
            .then((response) => {
                news.value = response.data;
            })
            .finally(() => {
                loading.value = false;
            })
}

const toggleTab = (force: boolean) => {
    switch (tabs.value) {
        case 'notify':
            if (_.size(notify.value) === 0 || force === true) {
                loadNotification();
            }
            break;
        case 'news':
            if (_.size(news.value) === 0 || force === true) {
                loadNews();
            }
            break;
    }
}
const formatDate = (date: string, format: string) => {
    return moment(date).format(format)
}

const markRead = (id: string) => {
    loading.value = true;
    axios.patch($route('notify.mark-as-read', {notifyId: id}))
            .then(() => {
                toggleTab(true)
            })
            .finally(() => {
                router.reload({only: ['auth']});
                loading.value = false;
            })

}

</script>