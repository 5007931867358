<template>
    <Card :title="$translate('project.form.title.select-source')"
          hide-actions>

        <!-- выбор источника данных-->
        <q-item-label caption>{{ $translate('project.fields.source') }}*:</q-item-label>
        <q-select v-model="project.sourceInfo"
                  :options="sourceList"
                  :loading="loadingSource"
                  @update:modelValue="selectSource"
                  :error="project.errors.hasOwnProperty('source')"
                  :error-message="_.get(project.errors,'source')"
        />

        <!-- данные из источника -->
        <component :is="source" v-model="project"/>

        <!-- тип проекта -->
        <q-item-label v-if="project.source" caption>{{ $translate('project.form.type.label') }}*:</q-item-label>

        <q-field v-if="project.source"
                 :loading="loadingProjectType"
                 :error="project.errors.hasOwnProperty('type_id')"
                 :error-message="_.get(project.errors,'type_id')">
            <template v-slot:control>
                <q-item-section>
                    <q-item-label>{{ _.get(projectType, 'name') }}</q-item-label>
                    <q-item-label caption>{{ _.get(projectType, 'description') }}</q-item-label>
                </q-item-section>
                <q-popup-edit v-slot="scope" :title="$translate('project.form.type.label')"
                              v-model="projectType"
                              anchor="top left"
                              buttons
                              :label-set="$translate('buttons.select')"
                              :label-cancel="$translate('buttons.cancel')">
                    <q-scroll-area style="height: 400px; max-height: 50vh">
                        <ElementTree :items="projectTypeOptions"
                                     :active-element="_.get(scope.value,'id')"
                                     expand-element>
                            <template v-slot:name="node">
                                <q-item-label class="col flex self-center items-start column"
                                              @click="node.node.childrens_count === 0?(scope.value = node.node):null"
                                              :title="node.node.name">
                                    <span>{{ node.node.name }}</span>
                                    <q-item-label caption v-if="node.node.description!==''">
                                        {{ node.node.description }}
                                    </q-item-label>
                                </q-item-label>
                            </template>
                        </ElementTree>
                    </q-scroll-area>
                </q-popup-edit>
            </template>
        </q-field>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref, watch} from "vue";
import axios from "axios";
import _ from "lodash";
import * as DataSource from "@/Components/DataSource";
import ElementTree from "@/Components/ElementTree.vue";
import ClassificationDB from "@/plugins/ClassificationDB";
import {Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const project = defineModel({type: Object})

const sourceList = ref([])
const projectTypeOptions = ref([])
const projectType = ref(null)

const selectSource = (source) => {
    _.set(project.value, 'source', source.value);
}
const source = computed(() => DataSource['DataSource' + _.upperFirst(project.value.source)])

watch(projectType, () => {
    _.set(project.value, 'type_id', _.get(projectType.value, 'id'))
    _.set(project.value, 'type', projectType.value);

    _.forEach(projectType.value.fields_inherit, (field) => {
        if (_.has(field, 'variants')) {
            if (_.get(field, 'variants.classificator')) {
                ClassificationDB.loadSimpleList(_.get(field, 'variants.classificator'))
            }
            if (_.get(field, 'variants.science_classificator')) {
                ClassificationDB.loadMultiList(_.get(field, 'variants.science_classificator'))
            }
            if (_.get(field, 'variants.model')) {
                ClassificationDB.loadModelList(_.get(field, 'variants.model'))
            }
        }
    })
})

const loadingSource = ref(false)
const loadingProjectType = ref(false)

onMounted(() => {
    loadingSource.value = true;
    loadingProjectType.value = true;
    axios.get(route('ajax.datasource', {m: 'list'}))
            .then((response) => {
                sourceList.value = _.values(response.data.results)
            })
            .finally(() => {
                loadingSource.value = false;
            })
    axios.get(route('ajax.project.type', {m: 'options'}))
            .then((response) => {
                projectTypeOptions.value = _.values(response.data.results)
            })
            .finally(() => {
                loadingProjectType.value = false;
            })
    //source.value = props.project.source
})
</script>
