<template>
        <q-card class="">
            <q-card-section class="text-uppercase text-center bg-grey-1 text-weight-bold">
                {{ $translate('module-auth.form.changepassword.title') }}
            </q-card-section>

            <q-separator/>

            <input type="hidden" name="token" v-model="form.token">

            <q-input v-model="form.email" id="email" bottom-slots
                     :label="$translate('module-auth.form.changepassword.email')" label-color="blue-grey"
                     class="q-mx-md"
                     :error-message="form.errors.email"
                     :error="form.errors.hasOwnProperty('email')">
                <template v-slot:append v-if="!form.errors.hasOwnProperty('email')">
                    <q-icon name="email" color="blue-grey"/>
                </template>
            </q-input>


            <q-input v-model="form.password" id="password" bottom-slots type="password"
                     :label="$translate('module-auth.form.changepassword.password')" label-color="blue-grey"
                     class="q-mx-md"
                     :error-message="form.errors.password"
                     :error="form.errors.hasOwnProperty('password')">
                <template v-slot:append v-if="!form.errors.hasOwnProperty('password')">
                    <q-icon name="password" color="blue-grey"/>
                </template>
            </q-input>


            <q-input v-model="form.password_confirmation" id="password_confirmation" bottom-slots type="password"
                     :label="$translate('module-auth.form.changepassword.password2')" label-color="blue-grey"
                     class="q-mx-md"
                     :error-message="form.errors.password_confirmation"
                     :error="form.errors.hasOwnProperty('password_confirmation')">
                <template v-slot:append v-if="!form.errors.hasOwnProperty('password_confirmation')">
                    <q-icon name="password" color="blue-grey"/>
                </template>
            </q-input>


            <q-card-section class="text-center">
                <q-btn color="primary" @click="submit" :loading="form.processing">
                    <template v-slot:loading>
                        <q-spinner-facebook/>
                    </template>
                    <div>{{ $translate('module-auth.form.changepassword.submit') }}</div>
                </q-btn>
            </q-card-section>

            <q-card-section class="flex justify-between align-center">
                <q-btn color="secondary" :href="$route('login')">
                    <div class="">{{ $translate('buttons.login') }}</div>
                    <q-icon right size="xs" name="login"/>
                </q-btn>

                <q-btn flat href="https://docs.researchims.ru/common/login/" target="_blank" size="md"
                       color="deep-orange-10">
                    <div class="text-lowercase">{{ $translate('module-auth.form.problem') }}</div>
                </q-btn>
            </q-card-section>
        </q-card>
</template>

<script setup>
import Guest from "@/Layout/Guest.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {computed} from "vue";
import {Notify} from 'quasar'
defineOptions({layout: Guest})
const page = usePage()
const message = computed(() => page.props.message)

const form = useForm({
    token: page.props.token,
    email: '',
    password: '',
    password_confirmation: ''
})

const notifySuccess = () => {
    Notify.create({
        color: 'positive',
        message: message,
        position: "top",
        progress: true,
        icon: "task_alt"
    })
    setTimeout(() => {
        window.location.href = '/';
    }, 2000)
}

const submit = () => {
    form
        .transform(data => ({
            ...data
        }))
        .post(route('password.update'), {
            onSuccess: () => notifySuccess()
        })
}
</script>