<template>
    <div>
        <q-splitter v-model="splitterModel">
            <template v-slot:before>
                <q-tabs v-model="tab" active-bg-color="grey-2" vertical>
                    <template v-for="(name, i) in tabNames" :key="name">
                        <q-tab :name="name"
                               v-if="moduleEnabled(name)"
                               :label="$translate('module-' + name + '.name')"
                               content-class="full-width justify-end"
                               no-caps
                        />
                    </template>
                </q-tabs>
            </template>
            <template v-slot:after>
                <q-tab-panels v-model="tab"
                              transition-prev="jump-up"
                              transition-next="jump-up"
                              animated
                              swipeable
                              vertical
                >
                    <template v-for="(name, i) in tabNames" :key="name">
                        <q-tab-panel :name="name" class="q-py-none">
                            <component :is="Modules[_.upperFirst(_.camelCase(name))]"
                                       :settings="settings"
                                       :data="data"
                            />
                        </q-tab-panel>
                    </template>
                </q-tab-panels>
            </template>
        </q-splitter>
    </div>
</template>

<script setup>
import * as Modules from "@/Pages/Admin/Settings/Blocks/Modules";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";

const props = defineProps({
    settings: {
        type: Object,
        // Object or array defaults must be returned from a factory function.
        // The function receives the raw props received by the component as the argument.
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const splitterModel = ref(20);
const tab = ref('auth');
const tabNames = ref([
    'auth',
    'rims',
    'analytics',
    'finance',
    'cadre',
    'syexc',
    'science',
    'rid',
    'feedback',
    'reportmaterial',
    'delivery',
    'archive',
    'ethics',
    'microcollections',
    'sensors'
]);
const moduleEnabled = (moduleName) => {
    return _.get(props.data, 'module.' + moduleName + '.enabled');
};
</script>
