<script setup lang="ts">

import {getCurrentInstance, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import moment from "moment";
import _ from 'lodash'
import {Alert, Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route
const $q = useQuasar();

interface Props {
    project: any
}

interface Research {
    id: bigint,
}

const props = defineProps<Props>()

interface Form {
    what: string[],
    research: bigint[]
}

const form = useForm<Form>({
    what: [],
    research: []
})

const toggleAll = ref(false)
const selectAll = () => {
    form.research = [];
    if (toggleAll.value) {
        _.forEach(props.project.research, (research: Research) => {
            form.research.push(research.id)
        });
    }
}
const submitForm = () => {
    form.post($route('project.export.process', props.project), {
        onSuccess: () => {
            form.reset();
        }
    })
}
</script>

<template>
    <Card :title="$translate('project.export.title')"
          header-toolbar no-card-section>
        <q-scroll-area style="height: calc(100vh - 330px)">
            <Alert variant="info" :message="$translate('project.export.info')"/>

            <q-bar class="bg-white">
                {{ $translate('project.export.project') }}
            </q-bar>
            <div class="column q-gutter-y-xs q-ml-md">
                <q-checkbox v-model="form.what" val="project"
                            :label="$translate('project.export.checkbox.project')"/>
                <q-checkbox v-model="form.what" val="project-expenses-short"
                            :label="$translate('project.export.checkbox.project-expenses-short')"/>
                <q-checkbox v-model="form.what" val="project-expenses-full"
                            :label="$translate('project.export.checkbox.project-expenses-full')"/>
            </div>

            <q-bar class="bg-white q-mt-md">
                {{ $translate('project.export.research') }}
            </q-bar>
            <div class="column q-gutter-y-xs q-ml-md">
                <q-checkbox v-model="form.what" val="research"
                            :label="$translate('project.export.checkbox.research')"/>
                <q-checkbox v-model="form.what" val="research-expenses-short"
                            :label="$translate('project.export.checkbox.research-expenses-short')"/>
                <q-checkbox v-model="form.what" val="research-expenses-full"
                            :label="$translate('project.export.checkbox.research-expenses-full')"/>
            </div>

            <Alert variant="danger" v-if="form.errors.hasOwnProperty('what')" :message="form.errors.what"/>
            <template
                    v-if="_.size(_.intersection(form.what,['research','research-expenses-short','research-expenses-full']))>0">
                <q-bar class="bg-white q-mt-md flex justify-between">
                    <span>{{ $translate('project.export.research-list') }}</span>
                    <q-checkbox v-model="toggleAll" @update:modelValue="selectAll"
                                :label="$translate('export.select-all')"/>
                </q-bar>

                <q-list dense>
                    <template v-for="(research) in project.research" :key="research.id">
                        <q-item>
                            <q-item-section>
                                <q-checkbox v-model="form.research"
                                            :val="research.id">
                                    <q-item-label>
                                        #{{ research.number }}
                                        &quot;{{ research.template.name }}&quot;
                                    </q-item-label>
                                </q-checkbox>
                                <q-item-label class="q-ml-xl" caption>
                                    <div class="self-center full-width flex items-center">
                                        <q-icon name="o_business" size="xs"/>
                                        <span class="q-ml-xs">{{ research.ssc.name }}</span>
                                    </div>
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-list>
            </template>

            <Alert variant="danger" v-if="form.errors.hasOwnProperty('research')" :message="form.errors.research"/>
        </q-scroll-area>
        <template v-slot:actions>
            <q-btn icon="o_import_export"
                   :label="$translate('buttons.export')"
                   color="positive"
                   @click="submitForm"
            />
        </template>
    </Card>

</template>