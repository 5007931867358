<template>
    <q-item>
        <q-item-section avatar>
            <UserAvatar :avatar="_.get(member,'avatar')"></UserAvatar>
        </q-item-section>
        <q-item-section>
            <q-item-label caption :title="$translate('project.view.member-role')">{{ title }}</q-item-label>
            <q-item-label>{{ _.get(member, 'fulled_name') }}</q-item-label>
            <q-item-label caption class="text-italic text-smaller">
                <div v-if="userTitle(member)">{{ userTitle(member).value }}</div>
                <div v-if="userDepartment(member)">{{ userDepartment(member).value }}</div>
            </q-item-label>
        </q-item-section>
        <q-item-section class="q-gutter-xs column items-start">
            <div class="q-gutter-x-xs column items-start full-width">
                <q-chip dense color="grey-2" clickable @click="copyToBufer(member.email)">
                    <q-icon name="o_email"/>
                    <span class="q-ml-xs ellipsis">{{ member.email }}</span>
                    <q-tooltip class="text-body2">{{ member.email }}</q-tooltip>
                </q-chip>
            </div>
            <div class="q-gutter-x-xs full-width">
                <template v-for="(phone) in userPhones(member)">
                    <q-chip dense color="grey-2" clickable @click="copyToBufer(phone.value)">
                        <q-icon name="o_phone"/>
                        <span class="q-ml-xs ellipsis">{{ phone.value }}</span>
                        <q-tooltip class="text-body2">{{ phone.value }}</q-tooltip>
                    </q-chip>
                </template>
            </div>
        </q-item-section>
        <q-item-section side>
            <q-btn dense icon="menu" flat v-if="canManage">
                <q-menu self="center right">
                    <q-list dense>
                        <q-item v-if="can.view"
                                clickable v-close-popup
                                @click="profileLink(member.user_id)">
                            <q-item-section>
                                <q-item-label>{{ $translate('buttons.detail') }}</q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-icon name="o_account_box" size="xs"/>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="can.logonas"
                                clickable v-close-popup
                                @click="loginAs(member.user_id)">
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('auth.loginas', {name: member.last_name || member.email}) }}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-icon name="o_meeting_room" size="xs"/>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="can.role"
                                clickable v-close-popup
                                @click="memberRoleDialog = true">
                            <q-item-section>
                                <q-item-label>{{ $translate('изменить роль') }}</q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-icon name="sym_o_passkey" size="xs"/>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="can.delete"
                                clickable v-close-popup
                                @click="deleteMember(member)">
                            <q-item-section>
                                <q-item-label>{{ $translate('buttons.delete') }}</q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-icon name="o_delete" size="xs"/>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="can.replace"
                                clickable v-close-popup
                                @click="replaceMemberDialog = true">
                            <q-item-section>
                                <q-item-label>{{ $translate('buttons.replace') }}</q-item-label>
                            </q-item-section>
                            <q-item-section avatar>
                                <q-icon name="o_sync" size="xs"/>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-menu>
            </q-btn>
        </q-item-section>
    </q-item>
    <q-dialog v-model="replaceMemberDialog" v-if="can.replace">
        <Card :title="$translate('project.view.members.confirm-delete-title')"
              in-modal show-close-button>
            <Alert variant="danger">
                {{
                    $translate('project.view.members.confirm-delete-author-research', {
                                'user': member.fulled_name,
                                'number': _.values(projectResearches)
                            }
                    )
                }}
            </Alert>
            <Alert variant="info">
                {{ $translate('project.view.members.confirm-delete-select-new') }}
            </Alert>
            <q-select v-model="replaceMemberId"
                      :options="memberOptions">
            </q-select>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('project.view.members.confirm-delete-replace-action')"
                       color="positive"
                       @click="replaceMember"/>
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="memberRoleDialog" no-backdrop-dismiss>
        <Card :title="$translate('project.form.title.change-role')"
              in-modal show-close-button>

            <SearchRole scope="project"
                        :label="$translate('project.actions.select-role-placeholder')"
                        @onSelect="selectMemberRole"/>
            <div class="text-negative" v-if="memberRoleForm.errors.hasOwnProperty('role_id')">
                {{ _.get(memberRoleForm.errors, 'role_id') }}
            </div>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="memberRoleFormSubmit"
                />
                <q-btn icon="o_close"
                       :label="$translate('buttons.cancel')"
                       color="dark"
                       v-close-popup
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import _ from "lodash";
import {router, useForm} from "@inertiajs/vue3";
import {useQuasar, copyToClipboard} from "quasar";
import {computed, getCurrentInstance, ref} from "vue";
import UserAvatar from '@/Components/UserAvatar.vue'
import {Alert, Card} from "@/Components/Block";
import {Roles as SearchRole} from "@/Components/Search/";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    member: {
        type: Object,
    },
    memberId: {
        type: Number
    },
    project: {
        type: Object
    },
    projectResearches: {
        type: Object,
    },
    title: {
        type: String
    },
    can: {
        type: Object,
        default: {
            view: false,
            logonas: false,
            delete: false,
            replace: false
        }
    },
    hideActions: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits('onSuccess');

const replaceMemberDialog = ref(false)
const replaceMemberId = ref(null)

const memberRoleDialog = ref(false)
const memberRoleForm = useForm({
    member_id: props.member.user_id,
    role_id: null,
})
const selectMemberRole = (role) => {
    memberRoleForm.role_id = role
}

const userPhones = (user) => {
    return _.filter(_.get(user, 'additional'), function (item) {
        return item.slug === 'phone' || item.slug === 'mobile'
    })
}

const userTitle = (user) => {
    return _.find(_.get(user, 'additional'), function (item) {
        return item.slug === 'title' || item.slug === 'position'
    })
}
const userDepartment = (user) => {
    return _.find(_.get(user, 'additional'), function (item) {
        return item.slug === 'department' || item.slug === 'faculty' || item.slug === 'company'
    })
}

const memberOptions = computed(() => {
    let _members = _.get(props.project, 'members');
    _members = _.filter(_members, (item) => {
        return item.user_id !== _.get(props.member, 'user_id');
    });
    _members = _.map(_members, (item) => ({
        value: item.user_id,
        label: item.user.fulled_name
    }))
    _members.push({
        value: props.project.leader.user_id,
        label: props.project.leader.fulled_name
    })
    return _members
})

/** Действия с пользователем **/
const canManage = computed(() => !props.hideActions && _.size(_.filter(props.can, (c) => c)) > 0)

const profileLink = (user_id) => {
    // window.alert(user_id);
    router.visit(route('admin.users.show', user_id),
            {
                preserveState: true,
                preserveScroll: true
            })
}
const loginAs = (user_id) => {
    // window.alert(user_id);
    router.post(route('admin.logonas'),
            {loginas: user_id},
            {
                preserveState: true,
                preserveScroll: true
            })
}
const deleteMember = (user) => {
    if (props.project.leader_id === user.user_id) {
        router.delete(route('project.member', props.project), {
            data: {'member_id': props.memberId},
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                emit('onSuccess')
            }
        })
    } else {
        $q.dialog({
            title: $translate('delete-dialog.title'),
            message: $translate('delete-dialog.message', {name: user.fulled_name}),
            cancel: {
                color: 'negative',
                noCaps: true,
                label: $translate('delete-dialog.action.cancel')
            },
            ok: {
                color: 'positive',
                noCaps: true,
                label: $translate('delete-dialog.action.confirm')
            },
            persistent: true
        }).onOk(() => {
            router.delete(route('project.member', props.project), {
                data: {'member_id': props.memberId},
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    emit('onSuccess')
                }
            })
        })
    }
}
const replaceMember = (user) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: props.member.fulled_name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.patch(route('project.member', props.project),
                {
                    'member_id': props.memberId,
                    'new_author': replaceMemberId
                },
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        replaceMemberDialog.value = false
                        emit('onSuccess')
                    }
                })
    })
}
const copyToBufer = (text) => {
    copyToClipboard(text)
            .then(() => {
                $q.notify({
                    color: 'positive',
                    message: 'Текст скопирован в буфер',
                    position: "bottom",
                    progress: true,
                })
            })
            .catch(() => {
                $q.notify({
                    color: 'negative',
                    message: 'Ошибка копирования текст',
                    position: "bottom",
                    progress: true,
                })
            })
}
const memberRoleFormSubmit = () => {
    memberRoleForm.put(route('project.member.role',props.project), {
        onSuccess: () => {
            memberRoleDialog.value = false
            emit('onSuccess')
        }
    })
}
</script>