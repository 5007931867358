<template>
    <ProjectLayout :project="project" tab="about">
        <q-card-section>
            <div class="row q-gutter-x-md " :class="{'column': $q.screen.lt.md}">
                <div class="col q-gutter-y-md">
                    <ProjectLinksChart :project-id="project.id"/>
                </div>
            </div>
        </q-card-section>
    </ProjectLayout>
</template>

<script setup>
import ProjectLayout from "@/Components/Project/ProjectLayout.vue";
import ProjectLinksChart from "@/Components/Project/Card/ProjectLinksChart.vue";
import {useQuasar} from "quasar";

const $q = useQuasar();

defineProps({project: Object});
</script>
