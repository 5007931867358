<template>
    <q-list v-if="services.hasOwnProperty('science')">
        <NavigationItem v-if="checkPerms('requests')"
                        :title="$translate('science-application.title.request')"
                        :sidebar="sidebar"
                        color="dark"
                        icon="o_assignment"
        >
            <template #items>
                <q-item v-if="services.science.items.requests.application_list"
                        @click="router.visit($route('services.science.application.list'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate("science-application.title.request-all") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.science.items.requests.stage_list"
                        @click="router.visit($route('services.science.stage.list'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate("science-application.title.stage-all") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.science.items.requests.project_list"
                        @click="router.visit($route('services.science.project.list'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate("science-application.title.project-all") }}
                    </q-item-section>
                </q-item>
                <q-separator/>
                <q-item v-if="services.science.items.requests.application_my"
                        @click="router.visit($route('services.science.application.list-my'))"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate("science-application.title.request-my") }}
                    </q-item-section>
                </q-item>
                <q-separator/>
                <q-item v-if="services.science.items.requests.application_new"
                        @click="router.visit($route('services.science.application.create'))"
                        clickable v-close-popup                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("science-application.actions.create-application") }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
        <NavigationItem v-if="services.reportmaterial && checkPerms('publications')"
                        :title="$translate('menu.publication.publications')"
                        :sidebar="sidebar"
                        color="purple-5"
                        icon="library_books"
        >
            <template #items>
                <q-item v-for="(name, type) in $translate('report-type.type')" :key="type"
                        @click="router.visit($route('publication.list', {'category': type}))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ name }}
                    </q-item-section>
                </q-item>
                <q-separator/>
                <q-item v-if="services.science.items.publications.all"
                        @click="router.visit($route('publication.all'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.publication.all") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.science.items.publications.my"
                        @click="router.visit($route('publication.my'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("menu.publication.my") }}
                    </q-item-section>
                </q-item>
                <q-separator/>
                <q-item v-if="services.science.items.publications.add"
                        @click="router.visit($route('publication.add'))"
                        clickable v-close-popup
                >
                    <q-item-section side class="q-pl-md q-pr-xs">
                        <q-icon name="add_box" size="xs" color="purple-5"/>
                    </q-item-section>
                    <q-item-section class="q-pl-none text-purple-5">
                        {{ $translate("menu.publication.add") }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
        <NavigationItem v-if="checkPerms('persons')"
                        :title="$translate('science-application.title.person')"
                        :sidebar="sidebar"
                        color="dark"
                        icon="o_people"
        >
            <template #items>
                <q-item v-if="services.science.items.persons.internal"
                        @click="router.visit($route('services.science.internal-person.list'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("science-application.title.person-user") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.science.items.persons.external"
                        @click="router.visit($route('services.science.external-person.list'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("science-application.title.person-external") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.science.items.persons.collaboration"
                        @click="router.visit($route('services.science.collaboration.list'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("science-application.title.person-collaboration") }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
        <NavigationItem v-if="checkPerms('company')"
                        :title="$translate('science-application.title.company')"
                        :sidebar="sidebar"
                        color="dark"
                        icon="o_home_work"
        >
            <template #items>
                <q-item v-if="services.science.items.company.internal"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate("science-application.title.company-internal") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.science.items.company.external"
                        clickable v-close-popup>
                    <q-item-section class="q-pl-md">
                        {{ $translate("science-application.title.company-external") }}
                    </q-item-section>
                </q-item>
                <q-item v-if="services.science.items.company.publisher"
                        @click="router.visit($route('services.science.publisher.list'))"
                        clickable v-close-popup
                >
                    <q-item-section class="q-pl-md">
                        {{ $translate("science-application.title.publishing-issuer") }}
                    </q-item-section>
                </q-item>
            </template>
        </NavigationItem>
    </q-list>
</template>

<script setup>
import {computed, getCurrentInstance} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import NavigationItem from "@/Components/Navigation/NavigationItem.vue";

const props = defineProps({
    sidebar: {
        type: Boolean,
        default: false
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const page = usePage();
const services = computed(() => page.props.services);

const checkPerms = (prop) => _.some(
    _.values(usePage().props.services.science.items[prop]),
    v => v === true
);
</script>
