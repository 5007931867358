<template>
    <Card :title="$translate('equipment.item.fields.classifications')" hide-actions no-card-section>
        <template v-slot:headerActions v-if="equipment.permission.edit">
            <q-btn icon="add" :label="$translate('buttons.add')" dense
                   size="sm" color="grey-2" text-color="body"
                   @click="openClassificatorDialog"/>
        </template>
        <q-list separator>
            <template v-for="(document,index) in equipment.classifications">
                <q-item>
                    <q-item-section>
                        <q-item-label caption>{{ _.get(document, 'type_classification.name') }}</q-item-label>
                        <q-item-label>{{ document.value.name }}</q-item-label>
                    </q-item-section>
                    <q-item-section side top v-if="equipment.permission.edit">
                        <q-btn icon="o_delete" flat dense size="sm" color="negative"
                               @click="deleteDocument(document.id)"/>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>
    <q-dialog v-model="addClassificatorDialog" no-backdrop-dismiss>
        <Card title="Добавление" style="width: 700px;max-width: 90vw"
              show-close-button no-card-section>
            <q-card-section>
                <q-select v-model="addClassificatorsForm.type"
                          :label="$translate('equipment.item.fields.classifications-type')" stack-label
                          :options="classificatorsTypeOptions" options-cover options-dense
                          emit-value map-options
                          aria-autocomplete="none" autocomplete="none"/>
            </q-card-section>
            <q-card-section v-if="addClassificatorsForm.type">
                <q-select :label="$translate('equipment.item.fields.classifications-value')" stack-label
                          v-model="addClassificatorsForm.value"
                          :options="classificatorsValueOptions" options-dense options-cover
                          emit-value map-options
                          use-input @filter="searchElement"
                          aria-autocomplete="none" autocomplete="none" bottom-slots>
                    <template v-slot:hint v-if="!addClassificatorsForm.type">
                        <span class="text-negative">выберите тип классификатора</span>
                    </template>
                    <template v-slot:hint v-else>
                        <span class="text-info">начните вводить значение для поиска</span>
                    </template>
                </q-select>
            </q-card-section>
            <template v-slot:actions v-if="addClassificatorsForm.value">
                <q-btn color="positive" icon="save" :label="$translate('buttons.add')"
                       @click="submitClassificatorsForm"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>


import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    equipment: {
        type: Object
    }
})
const classificatorsTypeOptions = ref([])
const classificatorsValueOptions = ref([])
const addClassificatorDialog = ref(false)
const classificationsType = ref(null)
const classificationsValue = ref(null)
const openClassificatorDialog = () => {
    addClassificatorDialog.value = true
}
const deleteDocument = (index) => {
    props.equipment.classifications.splice(index, 1);
    addClassificatorsForm.delete(route('equipment.classificator.delete', [props.equipment, index]), {
        onSuccess: () => router.reload()
    })
}
const addClassificatorsForm = useForm({
    type: null,
    value: null
})

const submitClassificatorsForm = () => {
    addClassificatorsForm.post(route('equipment.classificator.create', props.equipment), {
        onSuccess: () => {
            router.reload()
            addClassificatorDialog.value = false
        }
    })

}
const loadClassificatorsOptions = () => {
    axios
        .get('/classificator.json', {params: {m: 'classificator'}})
        .then((response) => {
            _.each(response.data.results, (item) => {
                classificatorsTypeOptions.value.push({
                    value: 'classificator-' + item.id,
                    label: item.text
                })
            })
        })
    axios
        .get('/classificator.json', {params: {m: 'science_classificator'}})
        .then((response) => {
            _.each(response.data.results, (item) => {
                classificatorsTypeOptions.value.push({
                    value: 'science.classification-' + item.id,
                    label: item.text
                })
            })
        })
}

const searchElement = (val, update) => {
    let inee = addClassificatorsForm.type.split('-')
    let url = '/' + inee[0] + '.json';
    axios.get(url, {
        params: {
            m: 'list',
            class: inee[1],
            q: val
        }
    }).then((response) => {
        update(() => {
            classificatorsValueOptions.value = response.data.results
        })
    })


}
onMounted(() => {
    loadClassificatorsOptions()
})

</script>