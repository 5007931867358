<template>
    <div v-if="!equipment.ssc_id">
        <q-banner class="bg-warning text-white">{{ $translate('equipment.validation.no-ssc') }}</q-banner>
    </div>
    <q-table v-else :rows="equipment.consumables" :columns="consumablesCols"
             row-key="consumable.id"
             flat bordered :rows-per-page-options="[0]" hide-pagination>
        <template v-slot:top-row>
            <q-tr>
                <q-td colspan="100%">
                    <q-btn icon="add" :label="$translate('buttons.add')" @click="openConsumableDialog" no-caps/>
                </q-td>
            </q-tr>
        </template>
        <template v-slot:body-cell-actions="props">
            <q-td :props="props">
                <DataTableActions :actions="props.row.actions">
                    <q-item clickable v-if="!props.row.index" @click="deleteConsumable(props.rowIndex)">
                        <q-item-section>
                            <q-item-label>
                                {{ $translate('buttons.delete') }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section avatar>
                            <q-icon name="o_delete" size="xs"/>
                        </q-item-section>
                    </q-item>
                </DataTableActions>
            </q-td>
        </template>
        <template v-slot:body-cell-material="props">
            <q-td :props="props">
                {{ props.row.consumable.name }}
            </q-td>
        </template>
        <template v-slot:body-cell-consumption="props">
            <q-td :props="props">
                {{ props.row.consumption_count }}
                {{ props.row.consumption_unit_value }}
                <q-icon name="replay" v-if="props.row.reusable"
                        :title="$translate('equipment.item.fields.material-reusable')"/>
            </q-td>
        </template>
        <template v-slot:body-cell-time="props">
            <q-td :props="props">
                {{ props.row.time_count }}
                {{ props.row.time_unit_value }}
            </q-td>
        </template>
    </q-table>
    <q-dialog v-model="addConsumableDialog" no-backdrop-dismiss>
        <Card :title="$translate('Добавление расходного материала')"
              in-modal show-close-button header-toolbar :hide-actions="!consumablesItem.consumable" no-card-section>
            <q-card-section>
                <q-item-label>{{ $translate('equipment.item.fields.material-consumable') }}</q-item-label>
                <q-select v-model="consumablesItem.consumable"
                          :options="consumablesList" options-dense option-label="name"
                          dense>
                </q-select>
            </q-card-section>
            <q-card-section v-if="consumablesItem.consumable">
                <q-item-label>{{ $translate('equipment.item.fields.material-consumption') }}</q-item-label>
                <div class="row q-gutter-x-md">
                    <q-input v-model="consumablesItem.count"
                             :label="$translate('equipment.item.fields.material-consumption-count')" stack-label
                             class="col" dense/>
                    <q-select v-model="consumablesItem.unit"
                              :label="$translate('equipment.item.fields.material-consumption-unit')" stack-label
                              :options="_.get(consumablesItem,'consumable.transform')" options-dense option-label="name"
                              class="col" dense>
                    </q-select>
                </div>
            </q-card-section>
            <q-card-section v-if="consumablesItem.consumable">
                <q-item-label>{{ $translate('equipment.item.fields.material-time') }}</q-item-label>
                <div class="row q-gutter-x-md">
                    <q-input v-model="consumablesItem.time_count"
                             :label="$translate('equipment.item.fields.material-time-count')" stack-label
                             class="col" dense/>
                    <q-select v-model="consumablesItem.time_unit"
                              :label="$translate('equipment.item.fields.material-time-unit')" stack-label
                              :options="timeUnits" options-dense option-label="label"
                              class="col" dense>
                    </q-select>
                </div>
            </q-card-section>
            <q-card-section v-if="consumablesItem.consumable">
                <q-checkbox :label="$translate('equipment.item.fields.material-reusable')"
                            v-model="consumablesItem.reusable" dense/>
                <q-item-label caption>Не уменьшать остаток, списывать только потери указанные в РМ</q-item-label>
            </q-card-section>
            <template v-slot:actions>
                <q-btn color="positive" icon="save" :label="$translate('buttons.add')" @click="addConsumable"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch} from "vue";
import {usePage} from "@inertiajs/vue3";
import axios from "axios";
import _ from "lodash"
import DataTableActions from "@/Components/DataTableActions.vue";
import {Equipment} from '@/entity'
import {Card} from "@/Components/Block";
import {set, get} from "@vueuse/core"

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route

const equipment: Equipment = defineModel()

const timeUnits: any = computed(() => _.get(usePage().props, 'options.timeUnits'))

const consumablesCols: any[] = [
    {name: "actions", label: '', style: "width: 2rem",},
    {name: "material", label: $translate('equipment.item.fields.material-consumable'), align: 'left'},
    {name: "consumption", label: $translate('equipment.item.fields.material-consumption'), align: 'left'},
    {name: "time", label: $translate('equipment.item.fields.material-time'), align: 'center'},
]

const defaultConsumablesItem = {
    consumable: {
        id: null,
        name: null,
        transform: []
    },
    count: 0,
    unit: {
        id: null,
        name: null,
    },
    time_count: null,
    time_unit: {
        value: null,
        label: null,
    },
    reusable: false,
}
const consumablesItem = ref(defaultConsumablesItem)

const consumablesList = ref([])
const loadConsumable = () => {
    if (consumablesList.value.length > 0) {
        return
    }
    axios.get('/consumables.json', {params: {'m': 'selectForEquipment', 'ssc': equipment.value.ssc_id}})
            .then((response) => {
                consumablesList.value = response.data.results
            })
}

const addConsumableDialog = ref(false)
const openConsumableDialog = () => {
    if (equipment.value.ssc_id > 0) {
        loadConsumable()
        addConsumableDialog.value = true
    }

}
const addConsumable = () => {
    let item: any = {
        id: null,
        consumable_id: null,
        consumable: {
            id: null,
            name: null,
        },
        consumption_count: null,
        consumption_unit_value: null,
        consumption_unit: null,
        time_count: null,
        time_unit: null,
        time_unit_value: null,
        reusable: false
    };

    if (!consumablesItem.value.unit.hasOwnProperty('id')
            && !consumablesItem.value.consumable.hasOwnProperty('id')
            && !consumablesItem.value.time_unit.hasOwnProperty('label')) {
        return;
    }

    item.id = null
    _.set(item, 'consumable.id', consumablesItem.value.consumable.id)
    _.set(item, 'consumable.name', consumablesItem.value.consumable.name)
    _.set(item, 'consumable_id', consumablesItem.value.consumable.id)
    _.set(item, 'consumption_count', consumablesItem.value.count)
    _.set(item, 'consumption_unit_value', consumablesItem.value.unit.name)
    _.set(item, 'consumption_unit', consumablesItem.value.unit.id)
    _.set(item, 'time_count', consumablesItem.value.time_count)
    _.set(item, 'time_unit', consumablesItem.value.time_unit.value)
    _.set(item, 'time_unit_value', consumablesItem.value.time_unit.label)
    _.set(item, 'reusable', consumablesItem.value.reusable)

    equipment.value.consumables.push(item);
    addConsumableDialog.value = false
    consumablesItem.value = defaultConsumablesItem
}

const deleteConsumable = (index: number) => {
    equipment.value.consumables.splice(index, 1)
}

</script>
