<template>
    <div>
        <q-banner class="bg-light-blue-2">
            <q-icon name="o_info" size="sm"/>
            {{ $translate('search.pure.organisation.info') }}
        </q-banner>
        <q-list>
            <q-item v-for="(item, i) in list" :key="i">
                <q-item-section>
                    <q-select v-model="form[block][name][item.id]"
                              :options="fetchedData"
                              @filter="searchOrg"
                              :label="item.name"
                              input-debounce="0"
                              option-label="text"
                              option-value="id"
                              behavior="dialog"
                              :loading="loading"
                              use-input
                              multiple
                    >
                        <template v-slot:option="scope">
                            <q-item v-bind="scope.itemProps">
                                <q-item-section>
                                    <q-item-label v-html="scope.opt.html"></q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                        <template v-slot:selected>
                            <q-chip v-for="(item, i) in form[block][name][item.id]"
                                    :key="i"
                                    :label="item.text"
                            />
                        </template>
                    </q-select>
                </q-item-section>
            </q-item>
        </q-list>
        <div class="q-pa-sm text-center">
            <q-btn color="positive" :label="$translate('buttons.save')" @click="submitForm" :loading="form.processing">
                <template v-slot:loading>
                    <q-spinner-facebook/>
                </template>
            </q-btn>
        </div>
    </div>
</template>

<script setup>
import {getCurrentInstance, onBeforeMount, ref} from "vue";
import axios from "axios";
import _ from "lodash";
import {useForm} from "@inertiajs/vue3";
import helper from "@/helpers";

const props = defineProps({
    label: {
        type: String,
        default: null
    },
    block: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        default: ''
    },
    data: {
        type: Object,
        default: {},
    },
    list: {
        type: Array,
        default: []
    },
    error: {
        type: String,
        default: null
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const name = ref(props.name);
const block = ref(props.block);
const data = ref(props.data);
const list = ref(props.list);

const fetchedData = ref([]);
const loading = ref(false);

let form = null;

onBeforeMount(() => {
    let forma = {};
    if (!forma.hasOwnProperty(block.value)) {
        forma[block.value] = {};
    }
    if (!forma[block.value].hasOwnProperty(name.value)) {
        forma[block.value][name.value] = {};
    }
    _.forEach(list.value, (item) => {
        _.set(forma, `${block.value}.${name.value}.${item.id}`, helper.jsonParse(_.get(data.value, item.id)));
    });
    form = useForm(forma);
});

const isUUID = (uuid) => {
    let s = "" + uuid;
    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    return s !== null;
};
const searchOrg = (search, update) => {
    if (search === '') {
        update(() => {
            fetchedData.value = [];
        });
        return;
    }
    fetchedData.value = [];
    if (isUUID(search)) {
        update(() => {
            loading.value = true;
            axios.get(route('ajax.pure', {
                m: 'getOrganisation',
                org: search
            }))
                .then((response) => {
                    fetchedData.value = response.data.results;
                })
                .finally(() => {
                    loading.value = false;
                });
        });
    }
};

const submitForm = () => {
    _.forEach(form[block.value][name.value], (value, key) => {
        _.set(form, `${block.value}.${name.value}.${key}`, JSON.stringify(value));
    });
    form.post(route('admin.settings.save'));
};
</script>
