<template>
    <q-banner class="bg-positive text-white q-my-xs" dense>
        <q-item dense>
            <q-item-section side>
                <q-icon name="o_check_circle" color="white" size="md"/>
            </q-item-section>
            <q-item-section>
                <slot/>
            </q-item-section>
        </q-item>
    </q-banner>
</template>
