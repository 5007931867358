<template>
    <Card title-bg="deep-purple-2"
          :title="$translate('module-sensors.sensor-groups.card.title', {name: _.get(sensorGroup, 'name')})"
          header-toolbar
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('module-sensors.sensor-groups.card.actions.back')"
                   @click="router.get($route('services.sensors.sensor-groups'))"
                   icon="o_chevron_left"
                   color="deep-purple-5"
                   dense
            />
        </template>
        <q-item>
            <q-item-section>
                <q-item-label>
                    {{ $translate('module-sensors.sensor-groups.card.fields.timeout') }}: {{
                        _.get(sensorGroup, 'timeout')
                    }}
                </q-item-label>
            </q-item-section>
        </q-item>
        <q-list separator>
            <q-item-label header>
                {{ $translate('module-sensors.sensor-groups.card.fields.allowable_measurements') }}
            </q-item-label>
            <q-item v-for="(allowableMeasurement, index) in sensorGroup.allowable_measurements" :key="index">
                <q-item-section>
                    <q-item-label>{{ allowableMeasurement.measurement_type_name }}</q-item-label>
                </q-item-section>
                <q-item-section>
                    {{ $translate('module-sensors.sensor-groups.card.fields.min_value') }}: {{ allowableMeasurement.min_value }}
                </q-item-section>
                <q-item-section>
                    {{ $translate('module-sensors.sensor-groups.card.fields.max_value') }}: {{ allowableMeasurement.max_value }}
                </q-item-section>
            </q-item>
        </q-list>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {getCurrentInstance} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    sensorGroup: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
</script>
