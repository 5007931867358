<template>
    <Card title-bg="indigo-5"
          title-text="white"
          class="q-mt-xs "
          header-toolbar
          no-card-section
          hide-actions>
        <template v-slot:title>
            <span class="q-mr-sm q-pr-sm border-right">{{ research.number }}</span>
            <span :title="_.get(research,'template.name')">{{ _.get(research, 'template.name') }}</span>
        </template>
        <template v-slot:headerActions>
            <q-btn icon="navigate_before"
                   color="white" text-color="indigo-5"
                   dense no-caps no-wrap
                   @click="router.visit($route('project.research',[research.project]))">
                <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">
                    {{ $translate('research.actions.list') }}
                </q-item-label>
                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">
                    {{ $translate('research.actions.list') }}
                </q-tooltip>
            </q-btn>
        </template>
        <q-tabs align="left"
                v-model="tabs"
                active-color="indigo-5"
                inline-label>
            <DataTableActions class="q-ml-sm"
                              :title="$translate('Меню')"
                              :actions="actions"/>
            <q-tab v-if="research.can.show"
                   name='about' icon="o_visibility"
                   @click="router.visit($route('project.research.show',[research]))">
                <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">
                    {{ $translate('research.actions.about') }}
                </q-item-label>
                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">
                    {{ $translate('research.actions.about') }}
                </q-tooltip>
            </q-tab>

            <q-btn-dropdown v-if="research.can.report"
                            class="full-height" flat
                            name='report' icon="o_history_edu">
                <template v-slot:label v-if="$q.screen.gt.md">
                    <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">
                        {{ $translate('project.research.actions.report') }}
                    </q-item-label>
                </template>
                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">
                    {{ $translate('project.research.actions.report') }}
                </q-tooltip>
                <q-list separator dense>
                    <q-item @click="router.visit($route('project.research.report',[research]))"
                            clickable v-close-popup>
                        <q-item-section>
                            <q-item-label>{{ $translate('project.research.actions.write-report') }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item v-if="research.can.fillReport"
                            @click="reportTemplateDialog = true"
                            clickable v-close-popup>
                        <q-item-section>
                            <q-item-label>{{ $translate('project.research.actions.apply-template') }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item v-if="research.can.fillReport"
                            @click="router.visit($route('project.research.report-template',[research]))"
                            clickable v-close-popup>
                        <q-item-section>
                            <q-item-label>{{ $translate('project.research.actions.save-template') }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-btn-dropdown>

            <q-tab v-if="research.can.result"
                   name='result' icon="o_dataset"
                   @click="router.visit($route('project.research.result',[research]))">
                <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">
                    {{ $translate('project.research.actions.result') }}
                </q-item-label>
                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">
                    {{ $translate('project.research.actions.result') }}
                </q-tooltip>
            </q-tab>

            <q-btn-dropdown v-if="research.can.expenses"
                            class="full-height" flat
                            name='expenses' icon="o_request_quote">
                <template v-slot:label v-if="$q.screen.gt.md">
                    <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">
                        {{ $translate('project.research.actions.expenses') }}
                    </q-item-label>
                </template>
                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">
                    {{ $translate('project.research.actions.expenses') }}
                </q-tooltip>
                <q-list separator dense>
                    <q-item :href="$route('project.research.expenses',[research,'short'])"
                            target="_blank"
                            clickable v-close-popup>
                        <q-item-section>
                            <q-item-label>{{ $translate('project.research.actions.expenses-short') }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item :href="$route('project.research.expenses',[research,'full'])"
                            target="_blank"
                            clickable v-close-popup>
                        <q-item-section>
                            <q-item-label>{{ $translate('project.research.actions.expenses-full') }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-btn-dropdown>

            <q-space/>

            <q-btn-dropdown icon="o_print" class="full-height" flat>
                <template v-slot:label v-if="$q.screen.gt.md">
                    <q-item-label class="q-ml-sm" v-if="$q.screen.gt.md">
                        {{ $translate('buttons.print') }}
                    </q-item-label>
                </template>
                <q-tooltip v-if="$q.screen.lt.md" class="text-body2">
                    {{ $translate('buttons.print') }}
                </q-tooltip>
                <q-list separator dense>
                    <q-item clickable v-close-popup :href="$route('project.research.print',research)" target="_blank">
                        <q-item-section side>
                            <q-icon name="o_print" size="xs"/>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ $translate('buttons.print-preview') }}</q-item-label>
                        </q-item-section>
                    </q-item>

                    <ResearchPrintBarcode v-if="research.can.printBarcodeResearch"
                                          :research="research"/>

                    <ResearchPrintAssayBarcode v-if="research.can.printBarcodeAssay"
                                               :research="research"/>

                </q-list>
            </q-btn-dropdown>
        </q-tabs>
        <slot/>
    </Card>

    <q-dialog v-model="reportTemplateDialog">
        <ResearchReportTemplateApply :research="research" @onSuccess="() => reportTemplateDialog=false"/>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import {useQuasar} from "quasar";
import {Card} from "@/Components/Block/";
import ResearchPrintBarcode from "@/Components/ProjectResearch/ResearchPrintBarcode.vue";
import ResearchPrintAssayBarcode from "@/Components/ProjectResearch/ResearchPrintAssayBarcode.vue";
import ResearchReportTemplateApply from "@/Components/ResearchReportTemplate/ResearchReportTemplateApply.vue";
import DataTableActions from "@/Components/DataTableActions.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar();

const props = defineProps({
    research: {type: Object, required: true},
    tab: {type: String, default: 'about'}
})

const tabs = ref(props.tab)

const researchHistoryDialog = ref(false)
const reportTemplateDialog = ref(false)

const actions = computed(() => ({
    'research': {
        label: $translate('research.actions.research', {number: props.research.number}).toUpperCase(),
        action: $route('project.research.show', [props.research]),
        icon: 'o_pageview',
        // hidden: !research.can.show
    },
    'about': {
        label: "&mdash; " + $translate('research.actions.base-info').toLowerCase(),
        action: $route('project.research.show', [props.research]),
        // icon: 'o_bubble_chart',
        // hidden: !research.can.show
    },
    'project': {
        label: "&mdash; " + $translate('research.actions.project', {number: props.research.project.number}).toLowerCase(),
        action: $route('project.show', [props.research.project]),
        // icon: 'o_bubble_chart',
        // hidden: !research.can.show
    },
    'result': {
        label: "&mdash; " + $translate('project.research.actions.result').toLowerCase(),
        action: $route('project.research.result', [props.research]),
        // icon: 'o_bubble_chart',
        hidden: !props.research.can.result
    },
    'copy': {
        label: "&mdash; " + $translate('research.actions.copy').toLowerCase(),
        action: $route('project.research.copy', [props.research]),
        // icon: 'o_bubble_chart',
        hidden: !props.research.can.copy
    },
    // 'history': {
    //     label: "&mdash; " + $translate('research.actions.history').toLowerCase(),
    //     action: openHistoryDialog,
    //     // icon: 'o_bubble_chart',
    //     hidden: !props.research.can.copy
    // },
    'report': {
        label: $translate('project.research.actions.report').toUpperCase(),
        action: $route('project.research.show', [props.research]),
        icon: 'o_history_edu',
        hidden: !props.research.can.report
    },
    'write-report': {
        label: "&mdash; " + $translate('project.research.actions.write-report').toLowerCase(),
        action: $route('project.research.report', [props.research]),
        // icon: 'o_bubble_chart',
        hidden: !props.research.can.report || !props.research.can.fillReport
    },
    'apply-template': {
        label: "&mdash; " + $translate('project.research.actions.apply-template').toLowerCase(),
        action: () => reportTemplateDialog.value = true,
        // icon: 'o_bubble_chart',
        hidden: !props.research.can.report || !props.research.can.fillReport
    },
    'save-template': {
        label: "&mdash; " + $translate('project.research.actions.save-template').toLowerCase(),
        action: $route('project.research.report-template', [props.research]),
        // icon: 'o_bubble_chart',
        hidden: !props.research.can.report || !props.research.can.fillReport
    },
    'expenses': {
        label: $translate('project.research.actions.expenses').toUpperCase(),
        // action: $route('project.research.show', [props.research]),
        icon: 'o_request_quote',
        hidden: !props.research.can.expenses
    },
    'expenses-short': {
        label: "&mdash; " + $translate('project.research.actions.expenses-short').toLowerCase(),
        action: $route('project.research.expenses', [props.research, 'short']),
        // icon: 'o_bubble_chart',
        hidden: !props.research.can.expenses
    },
    'expenses-full': {
        label: "&mdash; " + $translate('project.research.actions.expenses-full').toLowerCase(),
        action: $route('project.research.expenses', [props.research, 'full']),
        // icon: 'o_bubble_chart',
        hidden: !props.research.can.expenses
    },
}))
</script>>