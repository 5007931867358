<template>
    <Card :title="title"
          title-bg="indigo-1"
          header-toolbar>
        <template v-slot:headerActions>
            <q-btn v-if="_.get(can, 'index')"
                   :label="$translate('buttons.back')"
                   @click="router.get($route('consumables.index'))"
                   icon="o_chevron_left"
                   dense
                   color="indigo-3"
            />
        </template>


        <q-select v-model="consumable.category"
                  :label="$translate('consumables.fields.category')" stack-label
                  :options="categories"
                  options-dense
                  map-options
                  emit-value
                  :error="form.errors.hasOwnProperty('category')"
                  :error-message="_.get(form.errors,'category')"
        />


        <q-input v-model="consumable.name"
                 :label="$translate('consumables.fields.name')" stack-label
                 :hint="$translate('consumables.fields.name-placeholder')"
                 type="textarea"
                 rows="2"
                 :error="form.errors.hasOwnProperty('name')"
                 :error-message="_.get(form.errors,'name')"
        />


        <q-input v-if="consumable.category === 'reagent'"
                 v-model="consumable.cas"
                 :label="$translate('consumables.fields.cas')"
                 stack-label
                 :error="form.errors.hasOwnProperty('cas')"
                 :error-message="_.get(form.errors,'cas')"
        />


        <q-input v-model="consumable.vendor_number"
                 :label="$translate('consumables.fields.vendor_number')"
                 stack-label
                 :error="form.errors.hasOwnProperty('vendor_number')"
                 :error-message="_.get(form.errors,'vendor_number')"
        />


        <q-select v-model="consumable.type"
                  :label="$translate('consumables.fields.type')" stack-label
                  :options="types"
                  options-dense
                  map-options
                  emit-value
                  :error="form.errors.hasOwnProperty('type')"
                  :error-message="_.get(form.errors,'type')"
        />

        <q-select v-model="consumable.units"
                  :label="$translate('consumables.fields.units')" stack-label
                  :options="unitsOptions"
                  options-dense
                  map-options
                  emit-value
                  use-input
                  @filter="searchUnit"
                  :error="form.errors.hasOwnProperty('units')"
                  :error-message="_.get(form.errors,'units')"
        />

        <template v-slot:actions>
            <q-btn icon='save'
                   :label="$translate('buttons.' + (consumable.id ? 'save' : 'add'))"
                   @click="submitForm"
                   color="positive"
            />
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, nextTick, onMounted, ref, useAttrs} from "vue";
import {useForm, router} from "@inertiajs/vue3";
import _ from "lodash";
import helpers from "@/helpers";
import {Card} from "@/Components/Block";

const attrs = useAttrs();
const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    units: {
        type: Array
    },
    can: {
        type: Object
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const consumable = ref({
    category: null,
    name: null,
    cas: null,
    vendor_number: null,
    type: null,
    units: null,
    transform_input: [],
    transform_output: []
});

if (attrs.hasOwnProperty('consumable')) {
    consumable.value = Object.assign({}, consumable.value, attrs.consumable);
}

const categories = helpers.toOptions($translate('consumables.category'));
const types = helpers.toOptions($translate('consumables.type'));

const selectedUnit = ref(null);
const disabledUnit = ref(false);

const form = useForm({
    category: null,
    name: null,
    cas: null,
    vendor_number: null,
    type: null,
    units: null,
    transform_input: [],
    transform_output: []
});

const unitsOptions = ref([])

const searchUnit = (search, update) => {
    if (search === '') {
        update(() => {
            unitsOptions.value = props.units;
        });
        return
    }
    update(() => {
        unitsOptions.value = _.filter(
                props.units,
                o => _.includes(
                        _.toLower(_.get(Object(o), 'label')),
                        _.toLower(search)
                )
        );
    });
};

const submitForm = () => {
    form.transform((data) => ({
        ...data,
        ...consumable.value
    }))
            .post(
                    consumable.value.id ?
                            route('consumables.update', {consumable: consumable.value.id}) :
                            route('consumables.store')
                    ,
                    {},
                    {
                        onSuccess: () => {
                            router.visit(route('consumables.index'))
                        }
                    })
};

onMounted(() => {
    nextTick(() => {
        if (consumable.value.units && consumable.value.id) {
            disabledUnit.value = true;
        }
    });
});
</script>
