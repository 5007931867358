<template>
    <Card :title="$translate('module-syexc.title')"
          title-bg="orange-1"
          header-toolbar no-card-section hide-actions>
        <template v-slot:headerActions>
            <q-btn v-if="services.syexc.items.requests.new"
                   @click="router.visit($route('services.sys-exp-ctrl.proposal.create'))"
                   :label="$translate('module-syexc.actions.proposal.add')"
                   color="orange-4"
                   icon="o_add"
            />
        </template>
        <template v-slot:default>
            <DataTable allow-search :columns="columns" :request-route="$route('ajax.syexc.proposal', {m: 'list'})">
                <template v-slot:body-cell-number="props">
                    <q-td :props="props">
                        <Link v-if="props.row.actions.hasOwnProperty('detail')"
                              :href="props.row.actions.detail.action"
                        >
                            <span class="text-no-wrap">{{ props.value }}</span>
                        </Link>
                        <span v-else  class="text-no-wrap">{{ props.value }}</span>
                        <div class="text-no-wrap text-small">
                            {{ $translate('buttons.ot')}} {{ moment(props.row.created_at).format($translate('common.date.js')) }}
                        </div>
                    </q-td>
                </template>
                <template v-slot:body-cell-status="props">
                    <q-td :props="props">
                        <span v-html="props.value"></span>
                    </q-td>
                </template>
                <template v-slot:body-cell-expert="props">
                    <q-td :props="props">
                        <span v-html="props.value"></span>
                    </q-td>
                </template>
            </DataTable>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import DataTable from "@/Components/DataTable.vue";
import {computed, getCurrentInstance} from "vue";
import {Link, router, usePage} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import moment from "moment";

const $q = useQuasar();
const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const columns = [
    {name: 'actions', align: 'center', label: '', style: 'width: 2rem'},
    {name: 'number', align: 'left', label: $translate('module-syexc.proposal.fields.number'), field: 'number',style: 'width: 4rem'},
    {name: 'status', align: 'left', label: $translate('module-syexc.proposal.fields.status'), field: 'status'},
    {name: 'description', align: 'left', label: $translate('module-syexc.proposal.fields.description'), field: 'description'},
    {name: 'user', align: 'left', label: $translate('module-syexc.proposal.fields.user'), field: 'user'},
    {name: 'expert', align: 'left', label: $translate('module-syexc.proposal.fields.expert'), field: 'expert'},
];

const services = computed(() => usePage().props.services);
</script>
