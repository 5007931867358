<template>
    <Card title-bg="deep-orange-2" :title="$translate('module-ethics.application.form.title.create')">
        <ApplicationForm v-model="form" :user="user"/>
        <template v-slot:actions>
            <q-btn :label="$translate('module-ethics.application.form.actions.save')"
                   @click="submitFormSave"
                   color="positive"
            />
            <q-btn :label="$translate('module-ethics.application.form.actions.save-as-draft')"
                   @click="submitFormSaveAsDraft"
                   color="secondary"
            />
        </template>
    </Card>
</template>

<script setup>
import {Form as ApplicationForm} from "@/Components/Ethics";
import {Card} from "@/Components/Block";
import {getCurrentInstance} from "vue";
import {useForm} from "@inertiajs/vue3";

const props = defineProps({
    user: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const form = useForm({
    type: null,
    name: '',
    users: '',
    doc: '',
    remark: '',
    files: []
});

const submitFormSave = () => {
    form.post(route('services.ethics.application.store') + '?state=0');
};
const submitFormSaveAsDraft = () => {
    form.post(route('services.ethics.application.store') + '?state=6');
};
</script>
