<template>
    <q-tabs v-model="tab" align="left" dense no-caps>
        <q-tab v-for="(visibility, index) in visibilities"
               :key="index"
               :name="index"
               :label="visibility.name"
        />
    </q-tabs>
    <q-tab-panels v-model="tab" animated>
        <q-tab-panel v-for="(visibility, index) in visibilities" :key="index" :name="index">
            <q-item>
                <q-item-section>
                    <q-input v-model="visibility.name" label="Наименование области видимости" outlined dense />
                </q-item-section>
            </q-item>
            <q-item>
                <q-item-section>
                    <q-input v-model="visibility.description"
                             label="Описание области"
                             type="textarea"
                             rows="2"
                             outlined
                             dense
                    />
                </q-item-section>
            </q-item>
            <q-item>
                <q-item-section>
                    <div class="q-gutter-sm">
                        <q-item-label>Ограничение доступа</q-item-label>
                        <q-radio v-model="visibility.access" :val="true" label="Без ограничений" />
                        <q-radio v-model="visibility.access" :val="false" label="Доступ по условию" />
                    </div>
                </q-item-section>
            </q-item>
            <q-item v-if="!visibility.access">
                <q-item-section>
                    <div class="q-px-md row items-start q-gutter-md">
                        <q-card class="col">
                            <q-toolbar class="bg-grey-3">
                                <q-toolbar-title>по IP адресу</q-toolbar-title>
                            </q-toolbar>
                            <q-card-section>
                                <q-list>
                                    <q-item v-for="(ip, i) in visibility.rules.ip" :key="i">
                                        <q-item-section>
                                            <span>{{ ip }}</span>
                                        </q-item-section>
                                        <q-item-section side top>
                                            <q-icon name="o_delete"
                                                    color="negative"
                                                    size="sm"
                                                    @click="deleteIP(visibility.rules.ip, i)"
                                                    class="cursor-pointer"
                                            />
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-card-section>
                            <q-card-actions>
                                <q-btn :label="$translate('buttons.add')" size="sm" @click="vsAddIp = true" />
                                <q-dialog v-model="vsAddIp">
                                    <q-card>
                                        <q-card-section>
                                            <div class="text-h6">Введите IP или подсеть</div>
                                        </q-card-section>
                                        <q-card-section>
                                            <q-input v-model="newIp" outlined dense />
                                        </q-card-section>
                                        <q-card-actions align="right">
                                            <q-btn :label="$translate('buttons.add')"
                                                   color="positive"
                                                   size="sm"
                                                   @click="addIp(visibility.rules.ip)"
                                            />
                                            <q-btn :label="_.toLower($translate('buttons.cancel'))"
                                                   color="negative"
                                                   size="sm"
                                                   v-close-popup
                                            />
                                        </q-card-actions>
                                    </q-card>
                                </q-dialog>
                            </q-card-actions>
                        </q-card>
                        <q-card class="col">
                            <q-toolbar class="bg-grey-3">
                                <q-toolbar-title>Выбранные пользователи и группы</q-toolbar-title>
                            </q-toolbar>
                            <q-card-section>
                                <q-list separator>
                                    <q-item tag="label" v-ripple>
                                        <q-item-section avatar top>
                                            <q-checkbox v-model="visibility.rules.user_all" :val="true" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>Все пользователи</q-item-label>
                                            <q-item-label caption>все авторизованные пользователи</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="!visibility.rules.user_all" tag="label" v-ripple>
                                        <q-item-section avatar top>
                                            <q-checkbox v-model="visibility.rules.user_internal" :val="true" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>Внутренние пользователи</q-item-label>
                                            <q-item-label caption>
                                                пользователи имеющие внешний логин, LDAP, или являются сотрудником подразделения
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="!visibility.rules.user_all" tag="label" v-ripple>
                                        <q-item-section avatar top>
                                            <q-checkbox v-model="visibility.rules.user_external" :val="true" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>Внешние пользователи</q-item-label>
                                            <q-item-label caption>
                                                пользователи имеющие не имеющие внешнего логина, LDAP, и не являются сотрудником подразделения
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="!visibility.rules.user_all">
                                        <q-item-section>
                                            <q-item-label>Выбранные пользователи:</q-item-label>
                                            <q-item-label caption>Выберите пользователей имеющих доступ</q-item-label>
                                        </q-item-section>
                                        <q-item-section side top>
                                            <q-btn :label="$translate('buttons.add')"
                                                   size="sm"
                                                   @click="vsAddUser = true"
                                            />
                                        </q-item-section>
                                        <template v-for="(user, i) in visibility.rules.user" :key="i">
                                            <q-item-section>
                                                <q-item-label>{{ user.full_name }}</q-item-label>
                                            </q-item-section>
                                            <q-item-section side top>
                                                <q-icon name="o_delete"
                                                        color="negative"
                                                        size="sm"
                                                        @click="deleteUser(visibility.rules.user, i)"
                                                        class="cursor-pointer"
                                                />
                                            </q-item-section>
                                        </template>
                                        <q-dialog v-model="vsAddUser">
                                            <q-card>
                                                <q-card-section>
                                                    <div class="text-h6">Выберите пользователя</div>
                                                </q-card-section>
                                                <q-card-section>
                                                    <!-- user-search -->
                                                </q-card-section>
                                                <q-card-actions align="right">
                                                    <q-btn :label="_.toLower($translate('buttons.cancel'))"
                                                           color="negative"
                                                           size="sm"
                                                           v-close-popup
                                                    />
                                                </q-card-actions>
                                            </q-card>
                                        </q-dialog>
                                    </q-item>
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label>Группы прав:</q-item-label>
                                            <q-item-label caption>Выберите группы прав в которых пользователь состоит</q-item-label>
                                        </q-item-section>
                                        <q-item-section side top>
                                            <q-btn :label="$translate('buttons.add')"
                                                   size="sm"
                                                   @click="vsAddGroup = true"
                                            />
                                        </q-item-section>
                                        <template v-for="(group, i) in visibility.rules.group" :key="i">
                                            <q-item-section>
                                                <q-item-label>{{ group.display_name }}</q-item-label>
                                            </q-item-section>
                                            <q-item-section side top>
                                                <q-icon name="o_delete"
                                                        color="negative"
                                                        size="sm"
                                                        @click="deleteGroup(visibility.rules.group, i)"
                                                        class="cursor-pointer"
                                                />
                                            </q-item-section>
                                        </template>
                                        <q-dialog v-model="vsAddGroup">
                                            <q-card>
                                                <q-card-section>
                                                    <div class="text-h6">Выберите группу</div>
                                                </q-card-section>
                                                <q-card-section>
                                                    <!-- role-group-search -->
                                                </q-card-section>
                                                <q-card-actions align="right">
                                                    <q-btn :label="_.toLower($translate('buttons.cancel'))"
                                                           color="negative"
                                                           size="sm"
                                                           v-close-popup
                                                    />
                                                </q-card-actions>
                                            </q-card>
                                        </q-dialog>
                                    </q-item>
                                    <q-item tag="label" v-ripple>
                                        <q-item-section avatar top>
                                            <q-checkbox v-model="visibility.rules.related" :val="true" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>Связанные пользователи</q-item-label>
                                            <q-item-label caption>
                                                Пользователи указанные в каждой сущности (соискатели, участники, авторы)
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-card-section>
                        </q-card>
                    </div>
                </q-item-section>
            </q-item>
        </q-tab-panel>
    </q-tab-panels>
    <div class="q-pa-sm text-center">
        <q-btn color="positive" :label="$translate('buttons.save')" @click="submitForm" :loading="form.processing">
            <template v-slot:loading>
                <q-spinner-facebook />
            </template>
        </q-btn>
    </div>
</template>

<script setup>
import {getCurrentInstance, onBeforeMount, ref} from "vue";
import _ from "lodash";
import {useForm} from "@inertiajs/vue3";

const props = defineProps({
    settings: {
        type: Array
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const visibilities = ref([]);

const tab = ref('');

const vsAddIp = ref(false);
const newIp = ref('');

const vsAddUser = ref(false);

const vsAddGroup = ref(false);

const addIp = (ips) => {
    if (!_.isEmpty(newIp.value)) {
        ips.push(newIp.value);
        vsAddIp.value = false;
        newIp.value = '';
    }
};
const deleteIP = (ips, index) => {
    _.remove(ips, (value, i) => i === index);
};

const deleteUser = (users, index) => {
    _.remove(users, (value, i) => i === index);
};

const deleteGroup = (groups, index) => {
    _.remove(groups, (value, i) => i === index);
};

const addVisibility = (data) => {
    visibilities.value.push(
        _.assign(
            {},
            {
                name: 'Название видимости',
                description: '',
                access: true,
                rules: {
                    ip: [],
                    user_all: false,
                    user_internal: false,
                    user_external: false,
                    user: [],
                    group: [],
                    related: false,
                },
            },
            data
        )
    );
};
const deleteVisibility = (index) => {
    _.remove(visibilities.value, (value, i) => i === index);
};

let form = null;

onBeforeMount(() => {
    if (!_.isEmpty(props.settings)) {
        _.forEach(props.settings, (settings) => {
            addVisibility(settings);
        });
    }
    let forma = {};
    _.forEach(visibilities.value, (visibility, index) => {
        if (!forma.hasOwnProperty('SCIENCE_VISIBILITY')) {
            forma['SCIENCE_VISIBILITY'] = {};
        }
        if (!forma['SCIENCE_VISIBILITY'].hasOwnProperty(index)) {
            forma['SCIENCE_VISIBILITY'][index] = {};
        }

        forma['SCIENCE_VISIBILITY'][index]['id'] = visibility.id;
        forma['SCIENCE_VISIBILITY'][index]['name'] = visibility.name;
        forma['SCIENCE_VISIBILITY'][index]['description'] = visibility.description;
        forma['SCIENCE_VISIBILITY'][index]['access'] = visibility.access ? '1' : '0';

        if (!forma['SCIENCE_VISIBILITY'][index].hasOwnProperty('rules')) {
            forma['SCIENCE_VISIBILITY'][index]['rules'] = {};
        }

        if (!forma['SCIENCE_VISIBILITY'][index]['rules'].hasOwnProperty('ip')) {
            forma['SCIENCE_VISIBILITY'][index]['rules']['ip'] = [];
        }
        _.forEach(visibility.rules.ip, (ip) => {
            forma['SCIENCE_VISIBILITY'][index]['rules']['ip'].push(ip);
        });

        if (!forma['SCIENCE_VISIBILITY'][index]['rules'].hasOwnProperty('user')) {
            forma['SCIENCE_VISIBILITY'][index]['rules']['user'] = [];
        }
        _.forEach(visibility.rules.user, (user) => {
            forma['SCIENCE_VISIBILITY'][index]['rules']['user'].push(user.user_id);
        });

        if (!forma['SCIENCE_VISIBILITY'][index]['rules'].hasOwnProperty('group')) {
            forma['SCIENCE_VISIBILITY'][index]['rules']['group'] = [];
        }
        _.forEach(visibility.rules.group, (group) => {
            forma['SCIENCE_VISIBILITY'][index]['rules']['group'].push(group.name);
        });

        forma['SCIENCE_VISIBILITY'][index]['rules']['relation'] = visibility.rules.relation;
    });
    form = useForm(forma);
});

const submitForm = () => {
    form.post(route('services.science.visibility'));
};
</script>
