<template>
    <q-tabs v-model="tab" align="left" dense no-caps>
        <q-tab name="base" label="Базовые настройки"/>
        <q-tab name="projects" :label="$translate('module-rims.settings.RIMS_PROJECT')"/>
        <q-tab name="research" :label="$translate('module-rims.settings.RIMS_PROJECT_RESEARCH')"/>
        <q-tab name="equipment" :label="$translate('module-rims.settings.RIMS_EQUIPMENT')"/>
    </q-tabs>
    <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="base">
            <Alert v-if="$translate('module-rims.description')"
                   variant="info"
                   :message="$translate('module-rims.description')"
            />
            <q-tabs v-model="baseTab" align="left" dense no-caps>
                <q-tab name="main" label="Настройки доступа"/>
                <q-tab name="profile" label="Профиль пользователя"/>
            </q-tabs>
            <q-tab-panels v-model="baseTab" animated>
                <q-tab-panel name="main">
                    <SettingsTable :data="config.base.main"/>
                </q-tab-panel>
                <q-tab-panel name="profile">
                    <SettingsTable :data="config.base.profile"/>
                </q-tab-panel>
            </q-tab-panels>
        </q-tab-panel>
        <q-tab-panel name="projects">
            <q-tabs v-model="projectsTab" align="left" dense no-caps>
                <q-tab name="_main" label="Основные настройки"/>
                <q-tab name="fin" :label="$translate('module-rims.settings.RIMS_PROJECT_FUNDING')"/>
                <q-tab name="validate" :label="$translate('module-rims.settings.RIMS_PROJECT_VALIDATING')"/>
                <q-tab name="contacts" :label="$translate('module-rims.settings.RIMS_CONTACT_INFO')"/>
            </q-tabs>
            <q-tab-panels v-model="projectsTab" animated>
                <q-tab-panel name="_main">
                    <SettingsTable :data="config.projects.main"/>
                </q-tab-panel>
                <q-tab-panel name="fin">
                    <q-list>
                        <q-expansion-item group="fin" label="Основные настройки">
                            <q-item>
                                <q-item-section>
                                    <SettingsTable :data="config.projects.fin.main"/>
                                </q-item-section>
                            </q-item>
                        </q-expansion-item>
                        <q-expansion-item group="fin" label="Планируемые затраты">
                            <q-item>
                                <q-item-section>
                                    <Alert variant="info"
                                           message="Блокировать работы в подразделении в случае превышения планируемых затрат"
                                    />
                                    <SettingsTable :data="config.projects.fin.planned"/>
                                </q-item-section>
                            </q-item>
                        </q-expansion-item>
                        <q-expansion-item group="fin" label="Выделенное финансирование">
                            <q-item>
                                <q-item-section>
                                    <Alert variant="info"
                                           message="Блокировать работы в подразделении в случае превышения выделенного финансирования"
                                    />
                                    <SettingsTable :data="config.projects.fin.total"/>
                                </q-item-section>
                            </q-item>
                        </q-expansion-item>
                    </q-list>
                </q-tab-panel>
                <q-tab-panel name="validate">
                    <SettingsTable :data="config.projects.validate"/>
                </q-tab-panel>
                <q-tab-panel name="contacts">
                    <SettingsTable :data="config.projects.contacts"/>
                </q-tab-panel>
            </q-tab-panels>
        </q-tab-panel>
        <q-tab-panel name="research">
            <SettingsTable :data="config.research"/>
        </q-tab-panel>
        <q-tab-panel name="equipment">
            <Alert variant="info" :message="$translate('module-rims.settings.RIMS_EQUIPMENT_description')"/>
            <SettingsTable :data="config.equipment"/>
        </q-tab-panel>
    </q-tab-panels>
</template>

<script setup>
import {Alert} from "@/Components/Block";
import SettingsTable from "@/Components/Settings/SettingsTable.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import helper from "@/helpers";

const props = defineProps({
    settings: {
        type: Object,
        default(rawProps) {
            return {};
        }
    },
    data: {
        type: Object,
        default(rawProps) {
            return {};
        }
    }
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const tab = ref('base');
const baseTab = ref('main');
const projectsTab = ref('_main');

const config = {
    base: {
        main: [
            {
                name: 'RIMS_NAME',
                label: $translate('module-rims.settings.RIMS_NAME'),
                value: props.settings.module.RIMS_NAME,
                description: $translate('module-rims.settings.RIMS_NAME_description'),
                block: 'module',
                type: 'text',
            },
            {
                name: 'RIMS_ALLOW_CREATE_PROJECT_USERS_BY_EMAIL',
                label: $translate('module-rims.settings.RIMS_ALLOW_CREATE_PROJECT_USERS_BY_EMAIL'),
                value: props.settings.module.RIMS_ALLOW_CREATE_PROJECT_USERS_BY_EMAIL,
                description: $translate('module-rims.settings.RIMS_ALLOW_CREATE_PROJECT_USERS_BY_EMAIL_description'),
                block: 'module',
                type: 'checkbox',
            },
        ],
        profile: [
            {
                name: 'SOURCE_DATA_SHOW_IN_PROFILE',
                label: 'Источник получения данных',
                value: helper.jsonParse(props.settings.module.SOURCE_DATA_SHOW_IN_PROFILE),
                description: 'Определяет из каких источников в профиле пользователя показываются данные для создания проектов',
                block: 'module',
                type: 'checkbox-group',
                options: props.data.module.rims.sourceList
            },
        ]
    },
    projects: {
        main: [
            {
                name: 'NONE_PROJECT_INFO',
                label: $translate('module-rims.settings.RIMS_PROJECT_INFO'),
                value: props.settings.module.NONE_PROJECT_INFO,
                description: $translate('module-rims.settings.RIMS_PROJECT_INFO_description'),
                block: 'module',
                type: 'textarea',
            },
            {
                name: 'RIMS_PROJECT_AGREEMENT_PERIOD',
                label: $translate('module-rims.settings.RIMS_PROJECT_AGREEMENT_PERIOD'),
                value: props.settings.module.RIMS_PROJECT_AGREEMENT_PERIOD ?
                    props.settings.module.RIMS_PROJECT_AGREEMENT_PERIOD :
                    3,
                description: $translate('module-rims.settings.RIMS_PROJECT_AGREEMENT_PERIOD_description'),
                block: 'module',
                type: 'number',
            },
            {
                name: 'ONLY_USERS_FROM_SOURCE',
                label: $translate('module-rims.settings.RIMS_PROJECT_ADD_MEMBER'),
                value: helper.jsonParse(props.settings.module.ONLY_USERS_FROM_SOURCE),
                description: $translate('module-rims.settings.RIMS_PROJECT_ADD_MEMBER_description'),
                block: 'module',
                type: 'checkbox-group',
                options: props.data.module.rims.sourceList
            },
            {
                name: 'NONE_ENABLED',
                label: $translate('settings.no-source'),
                value: props.settings.module.NONE_ENABLED,
                description: $translate('settings.no-source_description'),
                block: 'module',
                type: 'checkbox',
            },
            {
                name: 'RIMS_PROJECT_EXPIRED_NOTIFY',
                label: $translate('module-rims.settings.RIMS_PROJECT_EXPIRED_NOTIFY'),
                value: props.settings.module.RIMS_PROJECT_EXPIRED_NOTIFY ?
                    props.settings.module.RIMS_PROJECT_EXPIRED_NOTIFY :
                    5,
                description: $translate('module-rims.settings.RIMS_PROJECT_EXPIRED_NOTIFY_description'),
                block: 'module',
                type: 'number',
            },
        ],
        fin: {
            main: [
                {
                    name: 'PROJECT_COST_VIEW',
                    label: $translate('module-rims.settings.PROJECT_COST_VIEW'),
                    value: props.settings.module.PROJECT_COST_VIEW,
                    description: $translate('module-rims.settings.PROJECT_COST_VIEW_description'),
                    block: 'module',
                    type: 'radio',
                    options: _.map($translate('module-rims.settings.PROJECT_COST_VIEW_values'), (value, key) => ({
                        label: value,
                        value: key
                    }))
                },
                {
                    name: 'RIMS_SOURCES_HIDE_FIN',
                    label: 'Отображение финансирования в карточке проекта',
                    value: helper.jsonParse(props.settings.module.RIMS_SOURCES_HIDE_FIN),
                    description: 'Ограничить отображение финансирования в карточке проекта по типу источника данных',
                    block: 'module',
                    type: 'checkbox-group',
                    options: props.data.module.rims.sourceList
                },
                {
                    name: 'RIMS_SOURCES_HIDE_ZERO_FIN',
                    label: 'Отображение отсутствующего или нулевого финансирования в карточке проекта',
                    value: helper.jsonParse(props.settings.module.RIMS_SOURCES_HIDE_ZERO_FIN),
                    description: 'Ограничить отображение отсутствующего или нулевого финансирования в карточке проекта по типу источника данных. Зависит от настройки «Ограничить отображение в карточке проекта финансирования»',
                    block: 'module',
                    type: 'checkbox-group',
                    options: props.data.module.rims.sourceList
                },
            ],
            planned: [],
            total: []
        },
        validate: [
            {
                name: 'RIMS_PROJECT_REVALIDATE_DEPARTMENT',
                label: $translate('module-rims.settings.RIMS_PROJECT_REVALIDATE_DEPARTMENT'),
                value: _.reject(helper.jsonParse(props.settings.module.RIMS_PROJECT_REVALIDATE_DEPARTMENT), _.isNull),
                description: $translate('module-rims.settings.RIMS_PROJECT_REVALIDATE_DEPARTMENT_description'),
                block: 'module',
                type: 'select',
                multiple: true,
                options: props.data.module.rims.projectTypesTree
            },
            {
                name: 'RIMS_PROJECT_REVALIDATE_DETAIL',
                label: $translate('module-rims.settings.RIMS_PROJECT_REVALIDATE_DETAIL'),
                value: _.reject(helper.jsonParse(props.settings.module.RIMS_PROJECT_REVALIDATE_DETAIL), _.isNull),
                description: $translate('module-rims.settings.RIMS_PROJECT_REVALIDATE_DETAIL_description'),
                block: 'module',
                type: 'select',
                multiple: true,
                options: props.data.module.rims.projectTypesTree
            },
        ],
        contacts: [
            {
                name: 'PROJECT_LEADER_ADDITIONAL_FIELDS',
                label: $translate('module-rims.settings.RIMS_CONTACT_INFO_PROJECT_LEADER'),
                value: _.reject(helper.jsonParse(props.settings.module.PROJECT_LEADER_ADDITIONAL_FIELDS), _.isNull),
                description: $translate('module-rims.settings.RIMS_CONTACT_INFO_PROJECT_LEADER_description'),
                block: 'module',
                type: 'select',
                multiple: true,
                options: props.data.module.rims.classificatorUserData
            },
            {
                name: 'PROJECT_USERS_ADDITIONAL_FIELDS',
                label: $translate('module-rims.settings.RIMS_CONTACT_INFO_PROJECT_MEMBER'),
                value: _.reject(helper.jsonParse(props.settings.module.PROJECT_USERS_ADDITIONAL_FIELDS), _.isNull),
                description: $translate('module-rims.settings.RIMS_CONTACT_INFO_PROJECT_MEMBER_description'),
                block: 'module',
                type: 'select',
                multiple: true,
                options: props.data.module.rims.classificatorUserData
            },
            {
                name: 'RESEARCH_MEMBERS_CONTACT_INFO',
                label: $translate('module-rims.settings.RIMS_CONTACT_INFO_RESEARCH_MEMBER'),
                value: helper.jsonParse(props.settings.module.RESEARCH_MEMBERS_CONTACT_INFO),
                description: $translate('module-rims.settings.RIMS_CONTACT_INFO_RESEARCH_MEMBER_description'),
                block: 'module',
                type: 'checkbox-group',
                options: _.map(['author', 'leader', 'director', 'responsible'], value => ({
                    label: $translate('research.show.' + value),
                    value: value
                }))
            },
            {
                name: 'RESEARCH_MEMBERS_ADDITIONAL_FIELDS',
                label: $translate('module-rims.settings.RIMS_CONTACT_INFO_RESEARCH_MEMBER_INFO'),
                value: _.reject(helper.jsonParse(props.settings.module.RESEARCH_MEMBERS_ADDITIONAL_FIELDS), _.isNull),
                description: $translate('module-rims.settings.RIMS_CONTACT_INFO_RESEARCH_MEMBER_INFO_description'),
                block: 'module',
                type: 'select',
                multiple: true,
                options: props.data.module.rims.classificatorUserData
            },
            {
                name: 'RIMS_SSC_CONTACTS_ADDITIONAL_FIELDS',
                label: $translate('module-rims.settings.RIMS_CONTACT_INFO_SSC'),
                value: _.reject(helper.jsonParse(props.settings.module.RIMS_SSC_CONTACTS_ADDITIONAL_FIELDS), _.isNull),
                description: $translate('module-rims.settings.RIMS_CONTACT_INFO_SSC_description'),
                block: 'module',
                type: 'select',
                multiple: true,
                options: props.data.module.rims.classificatorUserData
            },
        ]
    },
    research:[
        {
            name: 'NOTIFY_RESEARCH_EXPIRED_DAYS',
            label: $translate('module-rims.settings.NOTIFY_RESEARCH_EXPIRED_DAYS'),
            value: props.settings.module.NOTIFY_RESEARCH_EXPIRED_DAYS ?
                    props.settings.module.NOTIFY_RESEARCH_EXPIRED_DAYS :
                    14,
            description: $translate('module-rims.settings.NOTIFY_RESEARCH_EXPIRED_DAYS_description'),
            block: 'module',
            type: 'number',
        },
        {
            name: 'NOTIFY_RESEARCH_CLOSED_DAYS',
            label: $translate('module-rims.settings.NOTIFY_RESEARCH_CLOSED_DAYS'),
            value: props.settings.module.NOTIFY_RESEARCH_CLOSED_DAYS ?
                    props.settings.module.NOTIFY_RESEARCH_CLOSED_DAYS :
                    28,
            description: $translate('module-rims.settings.NOTIFY_RESEARCH_CLOSED_DAYS_description'),
            block: 'module',
            type: 'number',
        },
        {
            name: 'RIMS_PROJECT_RESEARCH_CLOSE',
            label: $translate('module-rims.settings.RIMS_PROJECT_RESEARCH_CLOSE'),
            value: props.settings.module.RIMS_PROJECT_RESEARCH_CLOSE ?
                    props.settings.module.RIMS_PROJECT_RESEARCH_CLOSE :
                    30,
            description: $translate('module-rims.settings.RIMS_PROJECT_RESEARCH_CLOSE_description'),
            block: 'module',
            type: 'number',
        },
    ],
    equipment: [
        {
            name: 'RIMS_EQUIPMENT_SCHEDULE_COMMON_DOCUMENT_REQUIRED',
            label: $translate('module-rims.settings.RIMS_EQUIPMENT_SCHEDULE_COMMON_DOCUMENT_REQUIRED'),
            value: props.settings.module.RIMS_EQUIPMENT_SCHEDULE_COMMON_DOCUMENT_REQUIRED,
            description: $translate('module-rims.settings.RIMS_EQUIPMENT_SCHEDULE_COMMON_DOCUMENT_REQUIRED_description'),
            block: 'module',
            type: 'checkbox',
        },
        {
            name: 'RIMS_EQUIPMENT_SCHEDULE_PLANNED_DOCUMENT_REQUIRED',
            label: $translate('module-rims.settings.RIMS_EQUIPMENT_SCHEDULE_PLANNED_DOCUMENT_REQUIRED'),
            value: props.settings.module.RIMS_EQUIPMENT_SCHEDULE_PLANNED_DOCUMENT_REQUIRED,
            description: $translate('module-rims.settings.RIMS_EQUIPMENT_SCHEDULE_PLANNED_DOCUMENT_REQUIRED_description'),
            block: 'module',
            type: 'checkbox',
        },
        {
            name: 'EQUIPMENT_MULTI_CALCULATE',
            label: $translate('settings.system.EQUIPMENT_MULTI_CALCULATE'),
            value: props.settings.system.EQUIPMENT_MULTI_CALCULATE ? props.settings.system.EQUIPMENT_MULTI_CALCULATE : '-',
            description: $translate('settings.system.EQUIPMENT_MULTI_CALCULATE_description'),
            block: 'system',
            type: 'radio',
            options: _.map(['full', 'load'], (value) => ({
                label: $translate('settings.system.EQUIPMENT_MULTI_CALCULATE_' + value),
                value: value
            }))
        },
        {
            name: 'RIMS_EQUIPMENT_SCHEDULE_PLANNED_DOCUMENT_REQUIRED',
            label: 'Целевой показатель',
            value: props.settings.system.EQUIPMENT_REPORT_TARGET ? props.settings.system.EQUIPMENT_REPORT_TARGET : 80,
            description: 'Размер целевого показателя для загрузки оборудования',
            block: 'system',
            type: 'number',
        },
    ]
};
if (!_.isEmpty(props.settings.module.CADRE_ENABLED) && Number(props.settings.module.CADRE_ENABLED) > 0) {
    config.base.profile.push({
        name: 'PERSON_RATE_CUSTOM',
        label: $translate('settings.system.PERSON_RATE_CUSTOM'),
        value: !_.isEmpty(props.settings.system.PERSON_RATE_CUSTOM) ? props.settings.system.PERSON_RATE_CUSTOM : 'base',
        description: $translate('settings.system.PERSON_RATE_CUSTOM_description'),
        block: 'system',
        type: 'radio',
        options: [
            {label: $translate('settings.system.PERSON_RATE_CUSTOM_base'), value: 'base'},
            {label: $translate('settings.system.PERSON_RATE_CUSTOM_position'), value: 'position'},
            {label: $translate('settings.system.PERSON_RATE_CUSTOM_staff'), value: 'staff'},
        ]
    });
}
config.base.profile.push({
    name: 'PERSON_RATE',
    label: $translate('settings.system.PERSON_RATE'),
    value: props.settings.system.PERSON_RATE,
    description: $translate('settings.system.PERSON_RATE_description'),
    block: 'system',
    type: 'number',
    min: 0,
    step: 0.01
});
_.forEach(props.data.module.rims.sourceList, (source) => {
    config.projects.fin.planned.push({
        name: 'RIMS_' + _.toUpper(source.value) + '_SUSPEND_FOR_PLANNED_FIN',
        label: source.label,
        value: _.reject(
            helper.jsonParse(props.settings.module['RIMS_' + _.toUpper(source.value) + '_SUSPEND_FOR_PLANNED_FIN']),
            _.isNull
        ),
        description: 'Типы проектов, по которым блокируется выполнение работ',
        block: 'module',
        type: 'select',
        multiple: true,
        options: props.data.module.rims.projectTypesTree
    });
    config.projects.fin.total.push({
        name: 'RIMS_' + _.toUpper(source.value) + '_SUSPEND_FOR_TOTAL_FIN',
        label: source.label,
        value: _.reject(
            helper.jsonParse(props.settings.module['RIMS_' + _.toUpper(source.value) + '_SUSPEND_FOR_TOTAL_FIN']),
            _.isNull
        ),
        description: 'Типы проектов, по которым блокируется выполнение работ',
        block: 'module',
        type: 'select',
        multiple: true,
        options: props.data.module.rims.projectTypesTree
    });
});
</script>
