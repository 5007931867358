<template>
    <q-card-section>
        <Card no-card-section hide-actions>
            <template v-slot:title>
                <q-toggle v-model="data.show"
                          :label="$translate('Общая информация')"
                          dense/>
            </template>
            <q-card-section v-if="data.show">
                <q-input v-model="data.name"
                         :label="$translate('Наименование блока')"
                         dense/>
                <q-checkbox v-model="data.required"
                            :label="$translate('Обязательный блок')"
                            size="xs"
                            dense/>
            </q-card-section>
            <q-card-section v-if="data.show">
                <q-item-label class="q-mt-sm">{{ $translate('Основные поля') }}:</q-item-label>
                <template v-for="(name,field) in fields">
                    <q-item dense>
                        <q-item-section side style="width:300px">
                            <q-checkbox v-model="data.fields[field].show"
                                        size="sm"
                                        :label="name"
                                        dense/>
                        </q-item-section>
                        <q-item-section v-if="data.fields[field].show">
                            <q-input v-model="data.fields[field].label"
                                     :label="$translate('Наименование поля')"
                                     dense
                                     borderless/>
                        </q-item-section>
                        <q-item-section v-if="data.fields[field].show">
                            <q-checkbox v-model="data.fields[field].required"
                                        :label="$translate('fields.label.required')"
                                        size="xs"
                                        dense/>
                        </q-item-section>
                    </q-item>
                </template>
            </q-card-section>
            <q-card-section v-if="data.show">
                <FieldsConstructor v-model="data.fields_additional"
                                   :title="$translate('Дополнительные поля')"/>
            </q-card-section>
        </Card>
    </q-card-section>
</template>

<script setup>
import {getCurrentInstance, onBeforeMount, onMounted} from "vue";
import FieldsConstructor from "@/Components/Fields/FieldsConstructor.vue";
import {Card} from "@/Components/Block";
import _ from "lodash";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const fields = {
    name: 'Наименование',
    subname: 'Подзаголовок',
    short_name: 'Сокращенное наименование',
    conf_number: 'Номер события',
    description: 'Описание события',
    date_start: 'Дата начала',
    date_end: 'Дата окончания',
    state: 'Уровень (статус) мероприятия',
    placement: 'Местоположение',
    city: 'Город',
    country: 'Страна/Tерритория',
}
const data = defineModel({
    default: {
        show: false,
        name: '',
        required: false,
        fields: {},
        fields_additional: {}
    }
})
onBeforeMount(() => {
    _.forEach(fields, (name, field) => {
        if (!data.value.fields.hasOwnProperty(field)) {
            _.set(data.value.fields, field, {label: name, show: true, required: false})
        }
    })
})
</script>