<template>
    <q-btn icon="o_add"
           :label="$translate('research.list.input-assay_add')"
           size="sm"
           class="q-ml-md"
           @click="dialog = true"
           dense/>
    <q-dialog v-model="dialog"
              no-backdrop-dismiss>
        <Card :title="$translate('research.list.input-assay_title')"
              show-close-button>
            <q-field :prefix="$translate('research.list.input-assay_total')" tag="div">
                <template v-slot:control>
                    <div class="self-center full-width text-right q-pr-lg">
                        {{ research.assay }}
                    </div>
                </template>
            </q-field>
            <q-field :prefix="$translate('research.list.input-assay_received')" tag="div">
                <template v-slot:control>
                    <div class="self-center full-width text-right q-pr-lg">
                        {{ research.assay_received }}
                    </div>
                </template>
            </q-field>
            <q-input v-model="form.assay"
                     :prefix="$translate('research.list.input-assay_inputed')"
                     input-class="text-right q-pr-lg"
                     mask="##" reverse-fill-mask
                     min="1" :max="maxCount"
                     :error="form.errors.hasOwnProperty('assay')"
                     :error-message="form.errors['assay']"
                     :readonly="research.ssc.allow_one_time_assay_settings"
            >
            </q-input>


            <q-input v-model="form.date"
                     mask="date" fill-mask :rules="['date']"
                     :prefix="$translate('research.list.input-assay_date')"
                     input-class="text-right"
                     :error="form.errors.hasOwnProperty('date')"
                     :error-message="form.errors['date']">
                <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                            <q-date v-model="form.date" :options="allowedPeriod"
                                    today-btn
                                    minimal
                                    first-day-of-week="1">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup
                                           :label="$translate('buttons.select')"
                                           color="primary" flat/>
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-input>

            <q-input v-model="form.comment"
                     :label="$translate('research.list.input-assay_comment')"
                     type="textarea"
                     rows="3"
            />

            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.addassay')"
                       color="positive"
                       @click="submitForm"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import moment from "moment/moment";
import {Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    research: {type: Object, required: true}
})

const emit = defineEmits(['onSuccess'])

const dialog = ref(false)

const form = useForm({
    assay: (props.research.assay - props.research.assay_received),
    date: null,
    comment: null
})

const maxCount = computed(() => (props.research.assay - props.research.assay_received))

const allowedPeriod = (date) => {
    let d = moment(date).startOf('day')
    let p = moment(props.research.created_at).startOf('day')
    let n = moment().startOf('day')
    let pde = moment(props.research.project.date_end).startOf('day')
    let e = pde.isSameOrBefore(n) ? pde : n;

    return d.isSameOrAfter(p) && d.isSameOrBefore(e)
}

const submitForm = () => {
    form.put(route('project.research.assay.receive', props.research), {
        onSuccess: () => {
            dialog.value = false
            emit('onSuccess')
        }
    })
}
</script>