<template>
    <Card :title="$translate('cadre-sicklist.title.my')"
          header-toolbar hide-actions no-card-section>
        <q-list separator>
            <template v-for="(sicklist) in sicklists">
                    <ProfileSicklistItem :sicklist="sicklist" />
            </template>
        </q-list>

        <template v-slot:headerActions>
            <q-btn icon="o_add"
                   :label="$translate('buttons.add')"
                   @click="openAddForm"
            />
            <q-dialog v-model="addDialog" no-backdrop-dismiss>
                <Card :title="$translate('cadre-sicklist.title.new')"
                      in-modal show-close-button>
                    <ProfileSicklistForm v-model="form"/>
                    <template v-slot:actions>
                        <q-btn icon="o_save"
                               :label="$translate('buttons.save')"
                               color="positive"
                               @click="submitAddForm"
                        />
                    </template>
                </Card>
            </q-dialog>
        </template>
    </Card>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {Card} from "@/Components/Block";
import {useForm} from "@inertiajs/vue3";
import ProfileSicklistForm from "@/Components/Cadre/Sicklist/ProfileSicklistForm.vue";
import ProfileSicklistItem from "@/Components/Cadre/Sicklist/ProfileSicklistItem.vue";
import {useQuasar} from "quasar";
import {CadreStaff} from "@/entity/";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;
const $q = useQuasar()

interface Props {
    sicklists: CadreStaff[]
}

defineProps<Props>()

const addDialog = ref(false)

const form = useForm({
    action: null,
    sicklist: {},
    comment: null
})
const openAddForm = () => {
    form.reset()
    addDialog.value = true;
}
const submitAddForm = () => {
    form.transform((data) => ({
        action: 'add',
        ...data.sicklist,
        comment: data.comment
    }))
            .post($route('profile.sicklist.store'), {
                onSuccess: () => {
                    addDialog.value = false;
                }
            })
}
</script>