<template>
    <q-table :columns="columns"
             :rows="usageInEquipment"
             :pagination="{rowsPerPage: 0}"
             :rows-per-page-options="[0]"
             :loading="loading"
             hide-pagination
             wrap-cells
             bordered
             flat
    >
        <template v-slot:body="props">
            <q-tr :props="props">
                <q-th class="text-left" colspan="100%">{{ props.row.sscName }}</q-th>
            </q-tr>
            <q-tr :props="props" v-for="(item, i) in props.row.data" :key="i">
                <q-td>
                    <Link :href="$route('equipment.show', {equipment: item.equipmentId})">
                        {{ item.displayName }}
                    </Link>
                </q-td>
                <q-td>
                    <span>{{ item.consumption }}</span>
                    <span class="q-pl-xs">{{ item.units }}</span>
                </q-td>
                <q-td>
                    <q-icon v-if="item.reusable > 0"
                            name="o_recycling"
                            size="sm"
                            class="cursor-help"
                    >
                        <q-tooltip class="text-body2">
                            {{ $translate('equipment.item.fields.material-reusable') }}
                        </q-tooltip>
                    </q-icon>
                </q-td>
            </q-tr>
        </template>
    </q-table>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {Link} from "@inertiajs/vue3";
import axios from "axios";

const props = defineProps({
    consumableId: Number
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const usageInEquipment = ref([]);

const loading = ref(false);
const columns = [
    {name: 'equipment', label: 'Оборудование', align: 'left', field: 'equipment'},
    {name: 'consumption', label: 'Расход', align: 'left', field: 'consumption'},
    {name: 'space', label: '', align: 'left'},
];

onMounted(() => {
    loading.value = true;
    axios.get(route('ajax.consumables', {
        m: 'usageInEquipment',
        consumable_id: props.consumableId
    })).then(response => {
        usageInEquipment.value = response.data.results;
    }).finally(() => {
        loading.value = false;
    });
});
</script>
