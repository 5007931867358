<template>
    <q-input v-model="form.name"
             :label="$translate('module-sensors.sensor-groups.form.fields.name')"
             :error="form.errors.hasOwnProperty('name')"
             :error-message="_.get(form.errors, 'name')"
    />
    <q-select v-model="form.timeout"
              :label="$translate('module-sensors.sensor-groups.form.fields.timeout')"
              :hint="$translate('module-sensors.sensor-groups.form.fields.timeout_hint')"
              :suffix="$translate('module-sensors.sensor-groups.form.fields.timeout_suffix')"
              :options="timeouts"
              options-dense
    />
    <template v-for="(allowableMeasurement, index) in form.allowable_measurements" :key="index">
        <q-item>
            <q-item-section>
                <q-select v-model="allowableMeasurement.measurement_type_id"
                          :label="$translate('module-sensors.sensor-groups.form.fields.allowable_measurements.measurement_type_id')"
                          :options="measurementTypes"
                          map-options
                          emit-value
                />
            </q-item-section>
            <q-item-section>
                <q-input v-model="allowableMeasurement.min_value"
                         :label="$translate('module-sensors.sensor-groups.form.fields.allowable_measurements.min_value')"
                />
            </q-item-section>
            <q-item-section>
                <q-input v-model="allowableMeasurement.max_value"
                         :label="$translate('module-sensors.sensor-groups.form.fields.allowable_measurements.max_value')"
                />
            </q-item-section>
            <q-item-section side>
                <q-btn @click="deleteItem(index)" color="secondary" icon="o_delete" dense>
                    <q-tooltip class="text-body2">{{ $translate('buttons.delete') }}</q-tooltip>
                </q-btn>
            </q-item-section>
        </q-item>
    </template>
    <q-btn :label="$translate('module-sensors.sensor-groups.form.actions.add-allowable-measurement')"
           @click="addItem"
           color="secondary"
           class="q-mt-sm"
           size="sm"
    />
</template>

<script setup>
import {getCurrentInstance} from "vue";
import _ from "lodash";
import helpers from "@/helpers";

/**
 * @param InertiaForm form
 */
const form = defineModel({type: Object});
const props = defineProps({
    measurementTypes: Array
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const timeouts = [1, 2, 5, 10, 15, 30, 60];

const addItem = () => {
    form.value.allowable_measurements.push({
        measurement_type_id: null,
        min_value: null,
        max_value: null,
    });
};
const deleteItem = (index) => {
    form.value.allowable_measurements.splice(index, 1);
};
</script>
