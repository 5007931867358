<template>
    <q-btn icon="o_link"
           :title="$translate('buttons.assign')"
           dense
           @click="dialog = true"
    />
    <q-dialog v-model="dialog" no-backdrop-dismiss>
        <Card :title="$translate('cadre-department.fields.ssc_assign')"
              in-modal show-close-button>
            <q-select v-model="form.ssc_id"
                      :label="$translate('ssc.label.select-ssc')"
                      :options="sscList"
                      options-dense
                      map-options emit-value
                      option-value="id"
                      option-label="name"/>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.assign')"
                       color="positive"
                       @click="submitForm"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref, computed} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app?.appContext.config.globalProperties.$translate;
const $route = app?.appContext.config.globalProperties.$route;

const department = defineModel()

const emit = defineEmits(['onSuccess'])

const dialog = ref(false)

const form = useForm({
    ssc_id: null
})

const $p = usePage();
const sscList = computed(() => _.get($p, 'props.auth.ssc'))
const submitForm = () => {
    form.patch($route('cadre.department.update', department.value), {
        onSuccess: () => {
            form.reset();
            dialog.value = false;
            emit('onSuccess')
        }
    })
}
</script>