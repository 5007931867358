<template>
    <q-card>
        <q-card-section class="flex justify-between items-center">
            <AnalyticReportsHeader :report-data="reportData"></AnalyticReportsHeader>
            <AnalyticReportsActions :report-data="reportData"></AnalyticReportsActions>
        </q-card-section>
        <q-card-section>
            <q-table :columns="columns"
                     :rows="data"
                     :pagination="{rowsPerPage: 0}"
                     :rows-per-page-options="[0]"
                     hide-pagination
                     wrap-cells
                     bordered
                     flat
            >
                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th rowspan="2" />
                        <q-th rowspan="2">
                            {{ $translate('module-analytics.table_columns.' + report + '.equipment') }}
                        </q-th>
                        <q-th colspan="6" class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.period_loading') }}<br/>
                            {{ $translate('buttons.from') }} {{ reportData.from }}
                            {{ $translate('buttons.to') }} {{ reportData.to }}
                        </q-th>
                        <q-th rowspan="2" class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.target') }}
                        </q-th>
                    </q-tr>
                    <q-tr :props="props">
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.max_time') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.work_time') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.percent') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.ext_time') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.int_time') }}
                        </q-th>
                        <q-th class="text-center">
                            {{ $translate('module-analytics.table_columns.' + report + '.ext_load_percent') }}
                        </q-th>
                    </q-tr>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-th colspan="100%">
                            <div class="text-left">{{ props.row.ssc }}</div>
                        </q-th>
                    </q-tr>
                    <q-tr :props="props" v-for="(item, i) in props.row.data" :key="i">
                        <q-td />
                        <q-td>{{ item.name }}</q-td>
                        <q-td class="text-center">{{ item.max_time }}</q-td>
                        <q-td class="text-center">{{ item.work_time }}</q-td>
                        <q-td class="text-center">{{ item.percent }}</q-td>
                        <q-td class="text-center">{{ item.ext_time }}</q-td>
                        <q-td class="text-center">{{ item.int_time }}</q-td>
                        <q-td class="text-center" :class="getPercentClass(item.ext_load_percent)">
                            {{ item.ext_load_percent }}%
                        </q-td>
                        <q-td class="text-center">{{ item.target }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </q-card-section>
    </q-card>
</template>

<script setup>
import AnalyticReportsActions from "@/Components/Analytic/Reports/AnalyticReportsActions.vue";
import AnalyticReportsHeader from "@/Components/Analytic/Reports/AnalyticReportsHeader.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import helpers from "@/helpers";

const props = defineProps({
    report: {
        type: String,
    },
    reportData: {
        type: Object,
    },
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const data = ref([]);
_.forEach(props.reportData.data, (equipments, ssc) => {
    data.value.push({
        ssc: ssc,
        data: _.map(equipments, (equipment, i) => ({
            name: equipment.name,
            max_time: helpers.formatNumber(Number(equipment.max_time), 2),
            work_time: helpers.formatNumber(Number(equipment.work_time), 2),
            percent: helpers.formatNumber(Number(equipment.percent), 2),
            ext_time: helpers.formatNumber(Number(equipment.ext_time), 2),
            int_time: helpers.formatNumber(Number(equipment.int_time), 2),
            ext_load_percent: helpers.formatNumber(Number(equipment.ext_load_percent), 2),
            target: helpers.formatNumber(20, 2),
        }))
    });
});

const getPercentClass = (percent) => percent > 20 ? 'bg-positive text-white' : '';

const columns = [
    {name: 'space', label: '', align: 'left'},
    {
        name: 'max_time',
        label: $translate('module-analytics.table_columns.' + props.report + '.max_time'),
        align: 'center',
        field: 'max_time'
    },
    {
        name: 'work_time',
        label: $translate('module-analytics.table_columns.' + props.report + '.work_time'),
        align: 'center',
        field: 'work_time'
    },
    {
        name: 'percent',
        label: $translate('module-analytics.table_columns.' + props.report + '.percent'),
        align: 'center',
        field: 'percent'
    },
    {
        name: 'ext_time',
        label: $translate('module-analytics.table_columns.' + props.report + '.ext_time'),
        align: 'center',
        field: 'ext_time'
    },
    {
        name: 'int_time',
        label: $translate('module-analytics.table_columns.' + props.report + '.int_time'),
        align: 'center',
        field: 'int_time'
    },
    {
        name: 'ext_load_percent',
        label: $translate('module-analytics.table_columns.' + props.report + '.ext_load_percent'),
        align: 'center',
        field: 'ext_load_percent'
    },
    {
        name: 'target',
        label: $translate('module-analytics.table_columns.' + props.report + '.target'),
        align: 'center',
        field: 'target'
    },
];
</script>
