<template>
    <Card title-bg="deep-purple-1"
          header-toolbar hide-actions no-card-section>
        <template v-slot:title>
            {{ $translate('cadre-positions.title.list') }}
        </template>
        <template v-slot:headerActions>
            <q-btn v-if="can.add"
                   color="deep-purple-3"
                   icon="add"
                   :label="$translate('buttons.add')"
                   dense/>
            <q-btn v-if="can.import"
                   color="deep-purple-3"
                   icon="import_export"
                   :label="$translate('buttons.import')"
                   dense/>
            <q-btn v-if="can.export"
                   color="deep-purple-3"
                   icon="import_export"
                   :label="$translate('buttons.export')"
                   dense/>
        </template>

        <q-table ref="tableRef" wrap-cells
                 :rows="items" :columns="columns"
                 row-key="id"
                 v-model:pagination="pagination"
                 :filter="filter" :loading="loading"
                 flat
                 @request="onRequest"
                 :rows-per-page-options="[10,50,100]"
        >
            <template v-slot:top-row>
                <q-tr>
                    <q-td></q-td>
                    <q-td colspan="100%">
                        <q-input v-model="filter"
                                 :placeholder="$translate('search.element')"
                                 clear-icon="clear" debounce="1000"
                                 clearable borderless dense>
                            <template v-slot:prepend>
                                <q-icon name="search"/>
                            </template>
                        </q-input>
                    </q-td>
                </q-tr>
            </template>

            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.name"
                                      @on-success="onSuccess">
                    </DataTableActions>
                </q-td>
            </template>
        </q-table>
    </Card>
</template>

<script setup>
import {router, useForm, usePage} from "@inertiajs/vue3";
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import axios from "axios";
import DataTableActions from "@/Components/DataTableActions.vue";
import _ from "lodash";
import {Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

defineProps({
    can: {
        type: Object
    }
})

const tableRef = ref()
const filter = ref('')
const loading = ref(false)
const items = ref([])
const columns = [
    {
        name: 'actions', align: 'center', label: '',
        style: "width: 2rem",
    },
    {
        name: 'name', align: 'left', label: $translate('cadre-positions.fields.name'),
        field: 'name',
    },
    {
        name: 'group', align: 'left', label: $translate('cadre-positions.fields.group'),
        field: 'group',
    },
    {
        name: 'salary', align: 'right', label: $translate('cadre-positions.fields.salary'),
        field: 'salary_formatted',
    },
]
const pagination = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
})

function onRequest(request) {
    const {page, rowsPerPage, sortBy, descending} = request.pagination
    const filter = request.filter

    loading.value = true

    let params = {
        m: 'list',
        page: page,
        per_page: rowsPerPage,
    }
    params.datatable = {
        sort: {}
    }
    if (_.size(filter) > 0) {
        params.datatable['search'] = filter
    }
    axios.get(route('ajax.cadre.positions'), {
        params: params,
    })
            .then((response) => {
                items.value = response.data.results.data;
                pagination.value.page = response.data.results.current_page
                pagination.value.rowsPerPage = response.data.results.per_page
                pagination.value.sortBy = sortBy
                pagination.value.descending = descending
                pagination.value.rowsNumber = response.data.results.total
            })
            .finally(() => {
                loading.value = false
            })
}

const onSuccess = () => {
    tableRef.value.requestServerInteraction()
}

onMounted(() => {
    onSuccess()
})
</script>