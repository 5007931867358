<template>
    <Card :title="$translate('equipment.catalogue.title.merge')" title-bg="deep-purple-1" header-toolbar
          no-card-section>
        <template v-slot:headerActions>
            <q-btn color="deep-purple-3"
                   icon="navigate_before"
                   :label="$translate('buttons.back')"
                   @click="router.visit($route('admin.equipment-catalogue.index'))"
                   dense no-caps no-wrap/>
        </template>
        <q-splitter v-model="splitterModel">
            <template v-slot:before>
                <Card :title="$translate('equipment.catalogue.title.merge-source')"
                      class="q-ma-md" hide-actions no-card-section>
                    <q-card-section>
                        <q-select dense options-dense label="выберите записи"
                                  hint="максимум 3 элемента"
                                  v-model="selectedItems" multiple use-chips
                                  use-input input-debounce="1000" clearable clear-icon="close"
                                  :options="options" max-values="3"
                                  :loading="loading"
                                  @filter="onSearch" @virtual-scroll="onScroll">
                            <template v-slot:option="scope">
                                <q-item v-bind="scope.itemProps">
                                    <q-item-section>
                                        <q-item-label>{{ scope.opt.name }}</q-item-label>
                                        <q-item-label caption>{{ scope.opt.description }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </template>
                            <template v-slot:selected-item="scope">
                                <q-chip removable dense
                                        @remove="scope.removeAtIndex(scope.index)"
                                        :tabindex="scope.tabindex"
                                        color="grey-1"
                                        text-color="grey-9"
                                        class="q-ma-xs">
                                    {{ scope.opt.name }}
                                </q-chip>
                            </template>
                        </q-select>
                    </q-card-section>
                    <q-separator/>
                    <q-card-section>
                        <div class="row no-wrap">
                            <template v-for="(item) in selectedItems">
                                <q-list separator class="col">
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label overline>
                                                #
                                            </q-item-label>
                                            <q-item-label>
                                                {{ item.id }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label overline>
                                                {{ $translate('equipment.catalogue.fields.category') }}
                                            </q-item-label>
                                            <q-item-label>
                                                {{ _.get(item, 'equipment_category.name') }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label overline>
                                                {{ $translate('equipment.catalogue.fields.name') }}
                                            </q-item-label>
                                            <q-item-label class="item-text" :title="item.name">
                                                {{ item.name }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label overline>
                                                {{ $translate('equipment.catalogue.fields.vendor') }}
                                            </q-item-label>
                                            <q-item-label class="item-text" :title="item.vendor">
                                                {{ item.vendor }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label overline>
                                                {{ $translate('equipment.catalogue.fields.brand') }}
                                            </q-item-label>
                                            <q-item-label class="item-text" :title="item.brand">
                                                {{ item.brand }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label overline>
                                                {{ $translate('equipment.catalogue.fields.model') }}
                                            </q-item-label>
                                            <q-item-label class="item-text" :title="item.model">
                                                {{ item.model }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label overline>
                                                {{ $translate('equipment.catalogue.fields.country') }}
                                            </q-item-label>
                                            <q-item-label class="item-text"
                                                          :title="_.get(item, 'country_origin.name')">
                                                {{ _.get(item, 'country_origin.name') }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <q-item>
                                        <q-item-section>
                                            <q-item-label overline>
                                                {{ $translate('equipment.catalogue.fields.description') }}
                                            </q-item-label>
                                            <q-item-label class="item-text" :title="item.description">
                                                {{ item.description }}
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </template>
                        </div>
                    </q-card-section>
                </Card>
            </template>

            <template v-slot:after>
                <Card :title="$translate('equipment.catalogue.title.merge-target')"
                      class="q-ma-md"
                      hide-actions>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label overline>
                                    #
                                </q-item-label>
                                <q-item-label>
                                    {{ equipmentCatalogue.id }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label overline>
                                    {{ $translate('equipment.catalogue.fields.category') }}
                                </q-item-label>
                                <q-item-label>
                                    {{ _.get(equipmentCatalogue.equipment_category, 'name') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label overline>
                                    {{ $translate('equipment.catalogue.fields.name') }}
                                </q-item-label>
                                <q-item-label>
                                    {{ equipmentCatalogue.name }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label overline>
                                    {{ $translate('equipment.catalogue.fields.vendor') }}
                                </q-item-label>
                                <q-item-label>
                                    {{ equipmentCatalogue.vendor }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label overline>
                                    {{ $translate('equipment.catalogue.fields.brand') }}
                                </q-item-label>
                                <q-item-label>
                                    {{ equipmentCatalogue.brand }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label overline>
                                    {{ $translate('equipment.catalogue.fields.model') }}
                                </q-item-label>
                                <q-item-label>
                                    {{ equipmentCatalogue.model }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label overline>
                                    {{ $translate('equipment.catalogue.fields.country') }}
                                </q-item-label>
                                <q-item-label>
                                    {{ _.get(equipmentCatalogue, 'country_origin.name') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label overline>
                                    {{ $translate('equipment.catalogue.fields.description') }}
                                </q-item-label>
                                <q-item-label>
                                    {{ equipmentCatalogue.description }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </Card>
            </template>
        </q-splitter>

        <template v-slot:actions>
            <q-btn color="positive"
                   icon="save"
                   :label="$translate('buttons.save')"
                   @click="submitForm"/>
        </template>
    </Card>
</template>

<script setup>

import {getCurrentInstance, computed, nextTick, onBeforeMount, onMounted, ref} from "vue";
import _ from "lodash";
import {router} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    equipmentCatalogue: {
        type: Object,
        required: true,
    },
    catalogueList: {
        type: Array,
        required: true,
    }
})

const selectedItems = ref([])

const splitterModel = ref(70);

const pageSize = 10

const loading = ref(false)
const nextPage = ref(2)
const realList = ref(null)
onBeforeMount(() => {
    realList.value = _.cloneDeep(props.catalogueList)
})
const lastPage = computed(() => Math.ceil(realList.value.length / pageSize))
const options = computed(() => realList.value.slice(0, pageSize * (nextPage.value - 1)))

const onScroll = ({to, ref}) => {
    const lastIndex = options.value.length - 1

    if (loading.value !== true && nextPage.value < lastPage.value && to === lastIndex) {
        loading.value = true

        setTimeout(() => {
            nextPage.value++
            nextTick(() => {
                ref.refresh()
                loading.value = false
            })
        }, 500)
    }
}
const onSearch = (val, update) => {
    if (val === '') {
        update(() => {
            realList.value = _.cloneDeep(props.catalogueList)
        })
        return
    }

    update(() => {
        const needle = val.toLowerCase()
        realList.value = _.cloneDeep(props.catalogueList).filter(v => v.name.toLowerCase().indexOf(needle) > -1)
    })
}

const submitForm = () => {
    router.post($route('admin.equipment-catalogue.merge.process', {equipment_catalogue: props.equipmentCatalogue.id}),
            {source: selectedItems.value.map((item) => (item.id))}
    )
}
</script>

<style scoped>
.item-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>