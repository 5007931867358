<template>
    <Card :title="$translate('research-template.title.equipment',{template: template.name})"
          title-bg="blue-grey-3"
          no-card-section
          header-toolbar
    >
        <template v-slot:headerActions>
            <q-btn icon="navigate_before"
                   :label="$translate('research-template.actions.list')"
                   color="blue-grey-6"
                   @click="router.visit($route('research.template'))"
                   no-caps dense no-wrap/>
        </template>
        <q-list separator>
            <template v-for="(equipment,index) in equipments">
                <q-item>
                    <q-item-section side>
                        <q-toggle v-model="form.equipment[index]"
                                  :true-value="equipment.id"
                                  :false-value="null"
                                  color="positive"
                        />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ equipment.display_name }}</q-item-label>
                        <q-item-label class="text-small">Адрес: {{ equipment.placement_address }}</q-item-label>
                        <q-item-label class="text-small">Инв.№: {{ equipment.inventory_number }}</q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
        <template v-slot:actions>
            <q-btn icon="o_save"
                   :label="$translate('buttons.save')"
                   color="positive"
                   @click="submitForm"
            />
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import Card from "@/Components/Block/Card.vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from 'lodash'
import {useQuasar} from "quasar";

const $q = useQuasar();
const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    template: {type: Object},
    equipments: {type: Array}
})

const form = useForm({
    equipment: []
})

const submitForm = () => {
    form.post(route('research.template.equipment.update', props.template), {
        onSuccess: () => {
            router.visit(route('research.template'))
        }
    })
}

onMounted(() => {
    _.forEach(props.equipments, (item) => {
        if (_.findIndex(props.template.equipment, {id: item.id}) >= 0) {
            form.equipment.push(item.id)
        } else {
            form.equipment.push(null)
        }
    })
})
</script>