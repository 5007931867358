<template>
    <Card :title="$translate('module-ethics.application.card.main.title')" hide-actions>
        <template v-slot:default>
            <q-field :label="$translate('module-ethics.application.card.main.fields.number')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width" tabindex="0">
                        {{ _.get(application, 'number') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-ethics.application.card.main.fields.dcreate')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width" tabindex="0">
                        {{ moment(_.get(application, 'created_at')).format($translate('common.datetime.js')) }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-ethics.application.card.main.fields.type')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width" tabindex="0">
                        {{ _.get(application, 'type_text') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-ethics.application.card.main.fields.state')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width" tabindex="0">
                        {{ _.get(application, 'state_text') }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-ethics.application.card.main.fields.name')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width" tabindex="0">
                        <q-scroll-area :style="scrollNameHeight" visible>
                            <div v-html="helpers.nl2br(_.get(application, 'name'))" ref="name"></div>
                        </q-scroll-area>
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-ethics.application.card.main.fields.users')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width" tabindex="0">
                        <q-scroll-area :style="scrollUsersHeight" visible>
                            <div v-html="helpers.nl2br(_.get(application, 'users'))" ref="users"></div>
                        </q-scroll-area>
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-ethics.application.card.main.fields.doc')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width" tabindex="0">
                        <q-scroll-area :style="scrollDocHeight" visible>
                            <div v-html="helpers.nl2br(_.get(application, 'doc'))" ref="doc"></div>
                        </q-scroll-area>
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-ethics.application.card.main.fields.meeting_date')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width" tabindex="0">
                        {{ meetingDate }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('module-ethics.application.card.main.fields.remark')"
                     stack-label
                     borderless
            >
                <template v-slot:control>
                    <div class="self-center full-width" tabindex="0">
                        <q-scroll-area :style="scrollRemarkHeight" visible>
                            <div v-html="helpers.nl2br(_.get(application, 'remark'))" ref="remark"></div>
                        </q-scroll-area>
                    </div>
                </template>
            </q-field>
        </template>
    </Card>
</template>

<script setup>
import Card from "@/Components/Block/Card.vue";
import {computed, getCurrentInstance, ref} from "vue";
import {useElementSize} from "@vueuse/core";
import _ from "lodash";
import moment from "moment";
import helpers from "@/helpers";

const props = defineProps({
    application: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const name = ref();
const scrollNameHeight = computed(() => {
    let h = useElementSize(name).height;
    return {height: ((h.value > 200) ? 200 : h.value) + 'px'};
});

const users = ref();
const scrollUsersHeight = computed(() => {
    let h = useElementSize(users).height;
    return {height: ((h.value > 200) ? 200 : h.value) + 'px'};
});

const doc = ref();
const scrollDocHeight = computed(() => {
    let h = useElementSize(doc).height;
    return {height: ((h.value > 200) ? 200 : h.value) + 'px'};
});

const remark = ref();
const scrollRemarkHeight = computed(() => {
    let h = useElementSize(remark).height;
    return {height: ((h.value > 200) ? 200 : h.value) + 'px'};
});

const meetingDate = computed(() => {
    let meetingDate = _.get(props.application, 'meeting_date');
    return meetingDate ?
        moment(meetingDate).format($translate('common.date.js')) :
        $translate('module-ethics.not-assigned');
});
</script>
