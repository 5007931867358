<template>
    <Card :title="$translate('Календарь доставки под адресам')"
          header-toolbar hide-actions>
        <FullCalendar ref="calendar" :options="calendarOptions"/>
    </Card>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('Добавление адресов и дат доставки')"
              in-modal show-close-button>
            <q-select v-model="addForm.address"
                      :label="$translate('Зона доставки')"
                      :hint="$translate('Выберите одну или несколько зон доставки')"
                      :options="addressOptions"
                      options-dense
                      map-options
                      emit-value
                      multiple
                      use-chips
                      class="q-mb-md"
            />

            <q-field :label="$translate('Период доставки')" stack-label class="q-mb-md">
                <template v-slot:control>
                    <div class="items-center flex q-gutter-x-sm">
                        <div>{{ $translate('buttons.from') }}</div>
                        <div>{{ _.get(addForm.date, 'from') }}</div>
                        <div>{{ $translate('buttons.to') }}</div>
                        <div>{{ _.get(addForm.date, 'to') }}</div>
                    </div>
                </template>
                <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                            <q-date v-model="addForm.date" range>
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup
                                           :label="$translate('buttons.select')"
                                           color="dark" flat/>
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-field>

            <q-field tag="div"
                     :label="$translate('Дни доставки')"
                     stack-label
                     class="q-mb-md"
                     borderless
            >
                <template v-slot:control>
                    <div class="full-width">
                        <div>
                            <q-radio v-model="addForm.days"
                                     val="week"
                                     :label="$translate('по дням недели')"
                                     size="xs"/>
                        </div>
                        <div v-if="addForm.days === 'week'">
                            <q-option-group v-model="addForm.week_days"
                                            type="checkbox"
                                            size="sm"
                                            inline
                                            :options="_.map($translate('fields.weekdays'),(d)=>({value:d.day,label:d.label}))"/>
                        </div>
                        <div>
                            <q-radio v-model="addForm.days"
                                     val="month"
                                     :label="$translate('по числам месяца')"
                                     size="xs"/>
                        </div>
                        <div v-if="addForm.days === 'month'">
                            <q-select v-model="addForm.month_days"
                                      multiple use-chips use-input
                                      hide-dropdown-icon
                                      new-value-mode="add"
                                      :hint="$translate('введите даты разделенные пробелом или запятой')"/>
                        </div>
                    </div>
                </template>
            </q-field>

            <q-field tag="div" borderless :label="$translate('корректировка дат')" stack-label>
                <template v-slot:control>
                    <div class="full-width">
                        <q-item-label>в случае попадания даты на выходной/праздничный день</q-item-label>
                    </div>
                    <div class="column">
                        <q-radio v-model="addForm.sdvig" val="none" name="sdvig" size="xs">
                            оставить дату
                        </q-radio>
                        <q-radio v-model="addForm.sdvig" val="skip" name="sdvig" size="xs">
                            исключить дату
                        </q-radio>
                        <q-radio v-model="addForm.sdvig" val="workday" name="sdvig" size="xs">
                            сдвинуть на ближайший рабочий день
                        </q-radio>
                        <q-radio v-model="addForm.sdvig" val="workweek" name="sdvig" size="xs">
                            сдвинуть на ближайший рабочий день недели
                        </q-radio>
                        <div class="ml-4" v-if="addForm.sdvig==='workweek'">
                            <q-option-group v-model="addForm.sdvig_workweek_days"
                                            type="checkbox"
                                            size="sm"
                                            inline
                                            :options="_.map($translate('fields.weekdays'),(d)=>({value:d.day,label:d.label}))"/>
                        </div>
                    </div>
                </template>
            </q-field>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.add')"
                       color="positive"
                       @click="addFormSubmit"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import Card from "@/Components/Block/Card.vue";
import FullCalendar from '@fullcalendar/vue3'
import locale from "@fullcalendar/core/locales/ru";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'
import {useQuasar} from "quasar";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const $q = useQuasar();

const calendar = ref()

const reloadData = () => {
    let calendarApi = calendar.value.getApi()
    calendarApi.refetchEvents()
}

const addDialog = ref(false)

const addDialogOpen = () => {
    loadAddress()
    addDialog.value = true
}

const addForm = useForm({
    address: [],
    date: {},
    date_start: null,
    date_end: null,
    days: null,
    week_days: [],
    month_days: [],
    sdvig: null,
    sdvig_workweek_days: []
})

const addFormSubmit = () => {
    addForm.post(route('services.delivery.dates.store'), {
        onSuccess: () => {
            addForm.reset()
            addDialog.value = false
            reloadData()
        }
    })
}
const addressOptions = ref([])
const loadAddress = () => {
    axios.get('/delivery/address.json', {params: {m: 'options'}})
        .then((response) => {
            addressOptions.value = response.data.results;
        })
}

const handleEventClick = (clickInfo) => {
    if (!clickInfo.event.id) {
        return;
    }
    $q.dialog({
        title: $translate('Адрес доставки'),
        message: $translate(clickInfo.event.title),
        cancel: {
            color: 'dark',
            noCaps: true,
            label: $translate('закрыть')
        },
        ok: {
            color: 'negative',
            noCaps: true,
            label: $translate('удалить адрес')
        },
        persistent: true
    }).onOk(() => {
        $q.dialog({
            title: $translate('delete-dialog.title'),
            message: $translate('delete-dialog.message', {name: clickInfo.event.title}),
            cancel: {
                color: 'negative',
                noCaps: true,
                label: $translate('delete-dialog.action.cancel')
            },
            ok: {
                color: 'positive',
                noCaps: true,
                label: $translate('delete-dialog.action.confirm')
            },
            persistent: true
        }).onOk(() => {
            router.delete(route('services.delivery.dates.delete', [clickInfo.event.id]), {
                onSuccess: () => {
                    router.reload()
                    reloadData()
                }
            })
        })
    })
}

const handleEvents = (events) => {
    // this.currentEvents = events
}

const calendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    height: 'calc(100vh - 170px)',
    headerToolbar: {
        left: 'addAddress,reload',
        center: 'title',
        right: 'prev,today,next',
    },
    customButtons: {
        addAddress: {
            text: "добавить адрес/дату",
            click: addDialogOpen
        },
        reload: {
            text: "обновить",
            click: reloadData
        }
    },
    locales: [locale],
    initialView: 'dayGridMonth',
    editable: true,
    selectable: false,
    weekends: true,
    //select: this.handleDateSelect,
    // eventHover: handleEventClick,
    eventClick: handleEventClick,
    eventsSet: handleEvents,
    eventSources: [
        '/delivery/dayoff.json',
        '/delivery/dates.json'
    ],
}
</script>