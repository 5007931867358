<template>
    <Card :title="$translate('users.manage.system.identificators.title')"
          title-bg="cyan-14"
          title-text="white"
          actions-align="left"
    >
        <template v-if="show">
            <div class="row justify-between q-my-sm">
                <q-skeleton width="65%"/>
                <q-skeleton width="25%"/>
            </div>
            <div class="q-mt-sm">
                <q-skeleton width="100%" height="1rem"/>
            </div>
            <div class="row justify-between q-my-sm">
                <q-skeleton width="85%"/>
                <q-skeleton width="14%"/>
            </div>
            <div class="row justify-between q-my-sm">
                <q-skeleton width="35%"/>
                <q-skeleton width="55%"/>
            </div>
        </template>
        <template v-else>
            <template v-if="systemInfo.hasOwnProperty('identificators') && !_.isEmpty(systemInfo.identificators)">
                <div v-if="systemInfo.identificators.hasOwnProperty('sap')">
                    <div class="text-weight-bold q-pl-sm q-my-sm text-grey">
                        <span v-if="!_.isEmpty(systemInfo.identificators.sap.employee)">SAP ID (SAP)</span>
                        <span v-else-if="!_.isEmpty(systemInfo.identificators.sap.students)">StudentCard</span>
                    </div>
                    <q-list v-if="!_.isEmpty(systemInfo.identificators.sap.employee)">
                        <q-item v-for="(personSapData, i) in systemInfo.identificators.sap.employee" :key="i">
                            <q-item-section>
                                <q-item-label>
                                    <q-tooltip class="text-body2">Табельный номер</q-tooltip>
                                    {{ personSapData.Tabnum }}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ personSapData.Persnum }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    {{ personSapData.Lastname }} {{ personSapData.Name }} {{ personSapData.Surname }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <q-list v-if="!_.isEmpty(systemInfo.identificators.sap.students)">
                        <q-item v-for="(studentSapData, i) in systemInfo.identificators.sap.students" :key="i">
                            <q-item-section>
                                <q-item-label>{{ studentSapData.DegreeName }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ studentSapData.person_OID }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    {{ studentSapData.Surname }} {{ studentSapData.FirstName }} {{ studentSapData.SecondName }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </div>
                <div v-if="systemInfo.identificators.hasOwnProperty('pure')">
                    <div class="text-weight-bold q-pl-sm q-my-sm text-grey">ExternalId (PURE)</div>
                    <q-list>
                        <q-item v-for="(personPureData, uuid) in systemInfo.identificators.pure" :key="uuid">
                            <q-item-section>
                                <q-item-label>{{ uuid }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    <span class="q-px-sm" :class="{
                                        'bg-positive': isEqual(personPureData),
                                        'bg-negative text-white': !isEqual(personPureData)
                                    }">
                                        {{ getPersonPureExternalId(personPureData) }}
                                    </span>
                                </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    {{ personPureData.name.lastName }} {{ personPureData.name.firstName }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </div>
            </template>
        </template>
        <template v-slot:actions>
            <template v-if="show">
                <q-skeleton width="90%" height="1rem"/>
            </template>
            <template v-else>
                <div v-html="$translate('users.manage.system.identificators.hint')"></div>
            </template>
        </template>
    </Card>
</template>

<script setup lang="ts">
import {Card} from "@/Components/Block";
import {getCurrentInstance, onMounted, Ref, ref} from "vue";
import {ComponentInternalInstance} from "@vue/runtime-core";
import _ from "lodash";
import axios from "axios";

const { userId } = defineProps<{userId: number}>();

const app: ComponentInternalInstance | null = getCurrentInstance();
const $translate: Function = app!.appContext.config.globalProperties.$translate;
const $route: Function = app!.appContext.config.globalProperties.$route;

const show: Ref<boolean> = ref<boolean>(true);

interface IStudentSapData {
    DegreeName: string,
    person_OID: string,
    Surname: string,
    FirstName: string,
    SecondName: string
}
interface IEmployeeSapData {
    Tabnum: number,
    Persnum: number,
    Lastname: string,
    Name: string,
    Surname: string
}
interface IPersonSapData {
    students: IStudentSapData[],
    employee: IEmployeeSapData[]
}
interface IPersonPureData {
    externalId: string,
    name: {
        firstName: string,
        lastName: string
    }
}
interface IIdentificators {
    sap: IPersonSapData,
    pure: {[p: string]: IPersonPureData}
}
interface ISystemInfo {
    identificators: IIdentificators | null
}

const systemInfo: Ref<ISystemInfo> = ref<ISystemInfo>({
    identificators: {
        sap: {
            students: [],
            employee: []
        },
        pure: {}
    }
});

const isEqual = (personPureData: IPersonPureData) => {
    let externalId: string | number = String(_.get(personPureData, "externalId")),
        ids: IIdentificators | null = systemInfo.value.identificators;
    if (ids) {
        if (externalId.startsWith("S-")) {
            return ids.hasOwnProperty("sap") &&
                !_.isEmpty(ids.sap.students) &&
                _.includes(_.map(ids.sap.students, object => object.person_OID), externalId.slice(2));
        } else {
            return ids.hasOwnProperty("sap") &&
                !_.isEmpty(ids.sap.employee) &&
                _.includes(_.map(ids.sap.employee, object => object.Persnum), Number(externalId));
        }
    }
};

const getPersonPureExternalId = (personPureData: IPersonPureData) => personPureData.externalId.startsWith("S-") ?
    personPureData.externalId.slice(2) :
    personPureData.externalId;

const getUserSystemInfo = () => {
    show.value = true;
    axios.get($route("ajax.users", {m: "getUserCardSystemInfo", user_id: userId}))
        .then((response) => {
            systemInfo.value = response.data.results;
        })
        .catch(e => {
            console.error(e);
        })
        .finally(() => {
            show.value = false;
        });
};

onMounted(() => {
    getUserSystemInfo();
});
</script>
