<template>
    <div class="simple-tree-map" ref="chartRef"></div>
</template>

<script setup lang="ts">
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {onBeforeUnmount, onMounted, Ref, ref, ShallowRef, shallowRef} from "vue";
import {AnyValuesObject} from "@/entity/types";
import {ISimpleTreemapChartConfig} from "@/entity/charts";
import {ConfigHandler} from "@/entity/charts";
import {set, toValue} from "@vueuse/core";
import _ from "lodash";

interface Props {
    data: AnyValuesObject[],
    config: ISimpleTreemapChartConfig,
    locale: AnyValuesObject
}

const props = defineProps<Props>();

const configHandler: ConfigHandler = ConfigHandler.new(props.config);

const chartRef: Ref<any> = ref<any>();

const am5Root: ShallowRef = shallowRef<any>();
const am5Container: ShallowRef = shallowRef<any>();
const am5Series: ShallowRef = shallowRef<any>();

const createChart = () => {
    clearRefs();

    let root: am5.Root = am5.Root.new(toValue(chartRef));
    root.setThemes([am5themes_Animated.new(root)]);
    root.locale = props.locale;
    root._logo!.dispose();
    set(am5Root, { root: root });

    set(am5Container, {
        container: am5Root.value.root.container.children.push(
            am5.Container.new(am5Root.value.root, {
                width: am5.p100,
                height: am5.p100,
                layout: am5Root.value.root.verticalLayout
            })
        )
    });

    set(am5Series, {
        series: am5Container.value.container.children.push(
            am5hierarchy.Treemap.new(am5Root.value.root, configHandler.get("treemapSeries"))
        )
    });

    am5Container.value.container.children.unshift(
        am5.Label.new(root, configHandler.get("label"))
    );

    am5Series.value.series.rectangles.template.setAll(
        configHandler.get("treemapSeriesRectanglesTemplateSettings")
    );
    am5Series.value.series.nodes.template.set(
        "tooltipText",
        configHandler.get("treemapSeriesNodesTemplateTooltipText")
    );
    am5Series.value.series.get("colors").set("colors", getColors().reverse());
    am5Series.value.series.data.setAll([props.data]);
    am5Series.value.series.set("selectedDataItem", am5Series.value.series.dataItems[0]);
    // Make stuff animate on load
    am5Series.value.series.appear(1000, 100);
};

const clearRefs: Function = (): void => {
    set(am5Root, null);
    set(am5Container, null);
    set(am5Series, null);
};

const getColors: Function = (): am5.Color[] => _.map(
    configHandler.get("treemapSeriesColors"),
    (color) => am5.color(color)
);

onMounted(() => {
    createChart();
});

onBeforeUnmount(() => {
    if (am5Root.value.root) {
        am5Root.value.root.dispose();
    }
});
</script>

<style scoped>
.simple-tree-map {
    width: 100%;
    height: 100%;
}
</style>