<template>
    <Card :title="$translate('consumables.title.import')"
          title-bg="indigo-1"
          header-toolbar hide-actions no-card-section>
        <template v-slot:headerActions>
            <q-btn @click="router.visit($route('consumables.index'))"
                   color="indigo-3"
                   icon="navigate_before"
                   :label="$translate('к списку')"
                   dense/>
            <q-btn v-if="can.export"
                   @click="router.visit($route('consumables.export'))"
                   color="indigo-3"
                   icon="import_export"
                   :label="$translate('buttons.export')"
                   dense/>
            <q-btn v-if="can.import"
                   @click="router.visit($route('consumables.import'))"
                   color="indigo-3"
                   icon="import_export"
                   :label="$translate('buttons.import')"
                   dense/>
        </template>
        <ImportData :model-fields="modelFields"
                    :required-fields="requiredFields"
                    :execute-route="$route('consumables.import.execute')"
                    :preview-route="$route('consumables.import.preview')"/>
    </Card>
</template>

<script setup lang="ts">
import {getCurrentInstance} from "vue";
import {router} from "@inertiajs/vue3";
import {Card} from "@/Components/Block";
import {ImportData} from "@/Components/Exchange";

interface Permission {
    create: boolean,
    import: boolean,
    export: boolean
}

interface Props {
    modelFields: [],
    requiredFields: [],
    can: Permission
}

defineProps<Props>()

const app = getCurrentInstance();
const $route = app!.appContext.config.globalProperties.$route;
const $translate = app!.appContext.config.globalProperties.$translate

</script>