<template>
    <Card :title="$translate('equipment.catalogue.title.edit')" title-bg="deep-purple-1"
          no-card-section header-toolbar>
        <template v-slot:headerActions>
            <q-btn color="deep-purple-3"
                   icon="navigate_before"
                   :label="$translate('buttons.back')"
                   dense no-caps no-wrap
                   @click="router.visit($route('admin.equipment-catalogue.index'))"/>
        </template>

        <EquipmentCatalogueForm v-model="form"/>

        <template v-slot:actions>
            <q-btn color="positive"
                   icon="o_save"
                   :label="$translate('buttons.save')"
                   @click="submitForm"/>
        </template>
    </Card>
</template>

<script setup>
import {getCurrentInstance, onBeforeMount, ref} from "vue";
import axios from "axios";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {Card} from "@/Components/Block/";
import EquipmentCatalogueForm from "@/Components/EquipmentCatalogue/EquipmentCatalogueForm.vue";

const app = getCurrentInstance()

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const props = defineProps({
    equipmentCatalogue: {
        type: Object
    }
})

const equipmentCategoryOptions = ref(null)
const equipmentCategoryLoader = (val, update) => {
    axios.get($route('ajax.equipment.category'), {
        params: {
            m: 'options',
            search: val
        }
    })
        .then((response) => {
            equipmentCategoryOptions.value = response.data.results
        })
}
const equipmentCountryOptions = ref(null)
const equipmentCountryLoader = (val, update) => {
    axios.get(route('ajax.classificator'), {
        params: {
            class: 'country',
            m: 'loadOptions',
            search: val
        }
    })
        .then((response) => {
            equipmentCountryOptions.value = response.data.results
        })
}
const form = useForm({
    equipment_category_id: _.get(props.equipmentCatalogue, 'equipment_category_id'),
    name_i18n: _.get(props.equipmentCatalogue, 'name_i18n'),
    description_i18n: _.get(props.equipmentCatalogue, 'description_i18n'),
    vendor: _.get(props.equipmentCatalogue, 'vendor'),
    brand: _.get(props.equipmentCatalogue, 'brand'),
    model: _.get(props.equipmentCatalogue, 'model'),
    country_origin_id: _.get(props.equipmentCatalogue, 'country_origin_id')
})

onBeforeMount(() => {
    equipmentCountryLoader()
    equipmentCategoryLoader()
})

const submitForm = () => {
    form
        .patch(route('admin.equipment-catalogue.update', props.equipmentCatalogue.id), {
            onSuccess: () => {
                router.get(route('admin.equipment-catalogue.index'))
            }
        })
}
</script>