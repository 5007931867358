<template>
    <q-select v-model="selected"
              option-value="id" emit-value map-options
              clearable clear-icon="clear"
              :options="fetchedData"
              @filter="searchData"
              :label="label?label:$translate('search.role.label')"
              @update:model-value="(value) => emit('onSelect', value)"
              :error-message="error"
              :error="_.size(error)>0"
    >
        <template v-slot:option="option">
            <q-item v-bind="option.itemProps">
                <q-item-section>
                    <q-item-label v-html="option.opt.html"></q-item-label>
                </q-item-section>
            </q-item>
        </template>
    </q-select>
</template>

<script setup>
import {getCurrentInstance, onBeforeMount, onMounted, ref} from "vue";
import axios from "axios";
import _ from "lodash";

const props = defineProps({
    /**
     * Подпись к полю
     */
    label: {
        type: String,
        default: null
    },
    /**
     * Области видимости ролей
     */
    scope: {
        type: Array,
        default: null,
    },
    filters: {
        type: Array,
        required: false,
        default: []
    },
    error: {
        type: String,
        default: null
    }
});
const emit = defineEmits(['onSelect'])

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const selected = ref(null);
const fetchedData = ref([]);
const loading = ref(false);

onBeforeMount(() => {
    loading.value = true;
    axios.get(route('ajax.roles'), {
        params: {
            m: 'search',
            scope: props.scope
            // allowCreateOption: vm.taggable,
        }
    })
        .then((response) => {
            fetchedData.value = response.data.results;
        })
        .finally(() => {
            loading.value = false;
        });
})
const searchData = (search, update) => {
    if (_.size(fetchedData.value) > 0) {
        // already loaded
        update()
        return
    }
    update(() => {
        let params = {
            m: 'search',
            q: search,
            scope: props.scope
            // allowCreateOption: vm.taggable,
        };
        loading.value = true;
        axios.get(route('ajax.roles',params))
            .then((response) => {
                fetchedData.value = response.data.results;
            })
            .finally(() => {
                loading.value = false;
            });
    });
};
</script>
