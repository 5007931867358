<template>
    <Card :title="$translate('module-archive.research.report.equipment.title')" class="q-mb-md" hide-actions>
        <template v-slot:default>
            <template v-if="helpers.filled(researchReportEquipment)">
                <template v-if="helpers.filled(_.get(researchReportEquipment, 'load_info'))">
                    <q-list separator>
                        <template v-for="(equipmentData, i) in _.get(researchReportEquipment, 'load_info')"
                                  :key="i"
                        >
                            <template v-if="helpers.filled(_.get(equipmentData, 'device_info'))">
                                <div class="flex items-center justify-between">
                                    <div class="text-h5 cursor-help">
                                        <q-tooltip class="text-body2">
                                            {{ _.get(equipmentData, 'device_info.device_name') }}
                                        </q-tooltip>
                                        {{ _.truncate(_.get(equipmentData, 'device_info.device_name'), {length: 95}) }}
                                    </div>
                                    <q-btn :icon="expanded[_.get(equipmentData, 'device_info.device_id')] ?
                                                'keyboard_arrow_up' :
                                                'keyboard_arrow_down'"
                                           :title="$translate('buttons.detail')"
                                           @click="switchExpanded(_.get(equipmentData, 'device_info.device_id'))"
                                           size="sm"
                                           flat
                                    />
                                </div>
                                <small>{{ _.get(equipmentData, 'device_info.off_name') }}</small>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12">
                                                {{
                                                    $translate('module-archive.research.report.equipment.fields.total_time')
                                                }}:
                                            </div>
                                            <div class="col-12">
                                                {{ _.get(equipmentData, 'total_time') }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                {{
                                                    $translate('module-archive.research.report.equipment.fields.hour_cost')
                                                }}:
                                            </div>
                                            <div class="col-12">
                                                <span v-if="helpers.filled(_.get(equipmentData, 'hour_cost'))">
                                                    {{ helpers.formatMoney(_.get(equipmentData, 'hour_cost')) }}
                                                </span>
                                                <span v-else class="text-negative">
                                                    {{ $translate('common.not_available') }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                {{
                                                    $translate('module-archive.research.report.equipment.fields.total_price')
                                                }}:
                                            </div>
                                            <div class="col-12">
                                                <span v-if="helpers.filled(_.get(equipmentData, 'total_price'))">
                                                    {{ helpers.formatMoney(_.get(equipmentData, 'total_price')) }}
                                                </span>
                                                <span v-else class="text-negative">
                                                    {{ $translate('common.not_available') }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <q-slide-transition>
                                    <div v-show="expanded[_.get(equipmentData, 'device_info.device_id')]">
                                        <q-table v-if="helpers.filled(_.get(equipmentData, 'used_info'))"
                                                 :columns="columns"
                                                 :rows="_.get(equipmentData, 'used_info')"
                                                 :pagination="{rowsPerPage: 0}"
                                                 :rows-per-page-options="[0]"
                                                 hide-pagination
                                                 wrap-cells
                                                 bordered
                                                 flat
                                        >
                                            <template v-slot:body-cell-used_at="props">
                                                <q-td :props="props">
                                                    <span v-if="helpers.filled(_.get(props.row, 'used_at'))">
                                                        {{ moment(_.get(props.row, 'used_at')).format($translate('common.date.js')) }}
                                                    </span>
                                                    <span v-else class="text-negative">
                                                        {{ $translate('common.not_available') }}
                                                    </span>
                                                </q-td>
                                            </template>
                                            <template v-slot:body-cell-user="props">
                                                <q-td :props="props">
                                                    <span v-if="helpers.filled(_.get(props.row, 'user_info'))">
                                                        {{ handleUserInfo(_.get(props.row, 'user_info')) }}
                                                    </span>
                                                    <span v-else class="text-negative">
                                                        {{ $translate('common.not_available') }}
                                                    </span>
                                                </q-td>
                                            </template>
                                        </q-table>
                                        <AlertWarning v-else>
                                            {{
                                                $translate('module-archive.research.report.equipment.warnings.no-data')
                                            }}
                                        </AlertWarning>
                                    </div>
                                </q-slide-transition>
                            </template>
                        </template>
                    </q-list>
                </template>
                <template v-else>
                    <AlertWarning>
                        {{ $translate('module-archive.research.report.equipment.warnings.no-data') }}
                    </AlertWarning>
                </template>
            </template>
            <template v-else>
                <AlertWarning>
                    {{ $translate('module-archive.research.report.equipment.warnings.no-data') }}
                </AlertWarning>
            </template>
        </template>
    </Card>
</template>

<script setup>
import AlertWarning from "@/Components/Block/AlertWarning.vue";
import Card from "@/Components/Block/Card.vue";
import {getCurrentInstance, ref} from "vue";
import _ from "lodash";
import moment from "moment";
import helpers from "@/helpers";

const props = defineProps({
    researchReportEquipment: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const expanded = ref({});
if (helpers.filled(props.researchReportEquipment)) {
    if (helpers.filled(_.get(props.researchReportEquipment, 'load_info'))) {
        _.forEach(_.get(props.researchReportEquipment, 'load_info'), equipmentData => {
            expanded.value[_.get(equipmentData, 'device_info.device_id')] = ref(false);
        });
    }
}

const switchExpanded = (id) => {
    expanded.value[id] = !expanded.value[id];
};

const columns = [
    {
        name: 'used_at',
        label: $translate('module-archive.research.report.equipment.columns.used_at'),
        align: 'left',
        field: 'used_at'
    },
    {
        name: 'time',
        label: $translate('module-archive.research.report.equipment.columns.time'),
        align: 'left',
        field: 'time'
    },
    {
        name: 'user',
        label: $translate('module-archive.research.report.equipment.columns.user'),
        align: 'left',
        field: 'user'
    },
    {
        name: 'comment',
        label: $translate('module-archive.research.report.equipment.columns.comment'),
        align: 'left',
        field: 'comment'
    },
];

const handleUserInfo = (userInfo) => {
    if (_.has(userInfo, 'fullname')) {
        return _.get(userInfo, 'fullname');
    }
    return [
        _.get(userInfo, 'lastname'),
        _.get(userInfo, 'firstname'),
        _.get(userInfo, 'midname')
    ].join(' ');
};
</script>
