<template>
    <DataTableActions v-if="hasActions">
        <q-item v-if="vacation.permission.edit"
                @click="openEditDialog"
                clickable
                v-close-popup>
            <q-item-section>
                <q-item-label class="text-lowercase">
                    {{ $translate('buttons.edit') }}
                </q-item-label>
            </q-item-section>
            <q-item-section avatar>
                <q-icon name="o_edit" size="xs"/>
            </q-item-section>
        </q-item>
        <q-item v-if="vacation.permission.apply"
                @click="actionApply"
                clickable
                v-close-popup>
            <q-item-section>
                <q-item-label class="text-lowercase">
                    {{ $translate('buttons.to-approve') }}
                </q-item-label>
            </q-item-section>
            <q-item-section avatar>
                <q-icon name="o_check" size="xs"/>
            </q-item-section>
        </q-item>
        <q-item clickable v-close-popup
                v-if="vacation.permission.approve"
                @click="actionApprove">
            <q-item-section>
                <q-item-label class="text-lowercase">
                    {{ $translate('buttons.approve') }}
                </q-item-label>
            </q-item-section>
            <q-item-section avatar>
                <q-icon name="o_check" size="xs"/>
            </q-item-section>
        </q-item>
        <q-item clickable
                v-if="vacation.permission.decline"
                @click="declineDialog = true">
            <q-item-section>
                <q-item-label class="text-lowercase">
                    {{ $translate('buttons.decline') }}
                </q-item-label>
            </q-item-section>
            <q-item-section avatar>
                <q-icon name="o_clear" size="xs"/>
            </q-item-section>
        </q-item>
        <q-item v-if="vacation.permission.delete"
                @click="actionDelete"
                clickable v-close-popup>
            <q-item-section>
                <q-item-label class="text-lowercase">
                    {{ $translate('buttons.delete') }}
                </q-item-label>
            </q-item-section>
            <q-item-section avatar>
                <q-icon name="o_delete" size="xs"/>
            </q-item-section>
        </q-item>
    </DataTableActions>
    <q-dialog v-model="declineDialog" no-backdrop-dismiss>
        <Card :title="$translate('cadre-vacation.title.decline')"
              in-modal show-close-button header-toolbar>
            <q-input v-model="form.comment"
                     :label="$translate('cadre-vacation.fields.comment-decline')"
                     type="textarea" rows="3"
                     :error="form.errors.hasOwnProperty('comment')"
                     :error-message="_.get(form.errors,'comment')"
            />
            <template v-slot:actions>
                <q-btn icon="o_cancel"
                       :label="$translate('buttons.decline')"
                       color="negative"
                       @click="actionDecline"/>
                <q-btn :label="$translate('buttons.close')"
                       color="dark"
                       v-close-popup/>
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="editDialog" no-backdrop-dismiss>
        <Card :title="$translate('cadre-vacation.title.edit')"
              in-modal show-close-button header-toolbar>
            <ProfileVacationForm v-model="form"
                                 :position="_.get(vacation,'cadre_staff.cadre_position.name','')"/>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       dense
                       @click="editFormSubmit"
                />
            </template>
        </Card>
    </q-dialog>
</template>
<script setup lang="ts">
import {router, useForm} from "@inertiajs/vue3";
import {computed, getCurrentInstance, ref} from "vue";
import _ from "lodash";
import {useQuasar} from "quasar";

import DataTableActions from "@/Components/DataTableActions.vue";
import {CadreVacation} from "@/entity";
import {Card} from "@/Components/Block";
import ProfileVacationForm from "@/Components/Cadre/Vacation/ProfileVacationForm.vue";

const app = getCurrentInstance()
const $translate = app!.appContext.config.globalProperties.$translate
const $route = app!.appContext.config.globalProperties.$route
const $q = useQuasar()

interface Props {
    vacation: CadreVacation
}

const props = defineProps<Props>()

const emit = defineEmits(['onSuccess'])

/**
 * Проверка доступности действий
 *
 * @return boolean
 */
const hasActions = computed((): boolean => {
    return (_.size(_.filter(props.vacation.permission, (v) => v)) > 0);
})

const form = useForm({
    action: null,
    vacation: props.vacation,
    comment: null
})

const editDialog = ref(false)
const declineDialog = ref(false)

/**
 * Редактирование отпуска (показ формы)
 */
const openEditDialog = () => {
    editDialog.value = true;
}
/**
 * Редактирование отпуска (отправка данных)
 */
const editFormSubmit = (): void => {
    form.transform((data) => ({
        date_start: data.vacation.date_start,
        date_end: data.vacation.date_end,
        vacation_days: data.vacation.vacation_days,
        type: data.vacation.type,
        comment: data.comment
    }))
            .post($route('profile.vacation.update', form.vacation.id), {
                onSuccess: () => {
                    emit('onSuccess')
                    editDialog.value = false;
                }
            })
}
/**
 * Отправка на согласование
 */
const actionApply = (): void => {
    form.transform((data) => ({
        ...data,
        action: 'apply'
    }))
            .post($route('cadre.vacation.actions'), {
                onSuccess: () => {
                    emit('onSuccess')
                }
            })
}
/**
 * Согласование отпуска
 * (руководителем подразделения/кадровым сотрудником)
 */
const actionApprove = (): void => {
    form.transform((data) => ({
        ...data,
        action: 'approve'
    }))
            .post($route('cadre.vacation.actions'), {
                onSuccess: () => {
                    emit('onSuccess')
                }
            })
}
/**
 * Отклонение отпуска
 * (руководителем подразделения/кадровым сотрудником)
 */
const actionDecline = (): void => {
    form.transform((data) => ({
        ...data,
        action: 'decline'
    }))
            .post($route('cadre.vacation.actions'), {
                onSuccess: () => {
                    emit('onSuccess')
                }
            })
}

/**
 * Удаление отпуска
 */
const actionDelete = (): void => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: props.vacation.date_start + ' &mdash; ' + props.vacation.date_end}),
        html: true,
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete($route('cadre.vacation.delete', {cadreVacation: props.vacation.id,}),
                {
                    onSuccess: () => {
                        emit('onSuccess')
                    }
                })
    })
}

</script>