<template>
    <Card title-bg="deep-purple-2"
          :title="$translate('module-sensors.sensors.card.title', {name: _.get(props.sensor, 'name')})"
          no-card-section
          header-toolbar
          hide-actions
    >
        <template v-slot:headerActions>
            <q-btn :label="$translate('module-sensors.sensors.card.actions.back')"
                   @click="router.get($route('services.sensors.list'))"
                   icon="o_chevron_left"
                   color="deep-purple-5"
                   dense
            />
        </template>
        <q-tabs v-model="tab" align="left" dense no-caps>
            <q-tab name="info" :label="$translate('module-sensors.sensors.card.tabs.info')"/>
            <q-tab name="chart" :label="$translate('module-sensors.sensors.card.tabs.chart')"/>
        </q-tabs>
        <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="info">
                <SensorInfo :sensor="sensor"/>
            </q-tab-panel>
            <q-tab-panel name="chart">
                <SensorMeasurements :sensor="sensor"/>
            </q-tab-panel>
        </q-tab-panels>
    </Card>
</template>

<script setup>
import {Card} from "@/Components/Block";
import {Info as SensorInfo, Measurements as SensorMeasurements} from "@/Components/Sensors/Sensor";
import {getCurrentInstance, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";

const props = defineProps({
    sensor: Object
});

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const tab = ref('info');
</script>
