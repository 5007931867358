<template>
    <Card :title="title" title-bg="indigo-6" title-text="white" header-toolbar hide-actions>
        <component :is="chartType"/>
    </Card>
</template>

<script setup lang="ts">
import {Card} from "@/Components/Block";
import * as ChartTypes from "@/Components/Analytic/Charts";
import {computed} from "vue";
import _ from "lodash";

interface Props {
    title: string,
    component: string
}

const props = defineProps<Props>();

const chartType = computed(() => _.get(ChartTypes, props.component));
</script>
