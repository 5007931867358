<template>
    <Card v-if="_.get(fields,'show')"
          :title="_.get(fields,'name')"
          hide-actions no-card-section>

        <q-card-section v-if="_.get(fields,'fields.name.show')">
            <q-input v-model="publication.name"
                     :label="_.get(fields,'fields.name.label')"
                     type="textarea"
                     rows="3"
                     stack-label
                     :error="errors.hasOwnProperty('name')"
                     :error-message="_.get(errors,'name')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.description.show')">
            <q-input v-model="publication.description"
                     :label="_.get(fields,'fields.description.label')"
                     type="textarea"
                     rows="3"
                     stack-label
                     :error="errors.hasOwnProperty('description')"
                     :error-message="_.get(errors,'description')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.issn.show')">
            <q-select v-model="publication.issn"
                      :label="_.get(fields,'fields.issn.label')"
                      :hint="$translate('введите значение и нажмите Enter')"
                      clear-icon="clear"
                      new-value-mode="add-unique"
                      multiple
                      stack-label
                      use-chips
                      use-input
                      clearable
                      hide-dropdown-icon
                      :error="errors.hasOwnProperty('issn')"
                      :error-message="_.get(errors,'issn')"/>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.publisher.show')">
            <q-field v-model="publication.publisher"
                     :label="_.get(fields,'fields.publisher.label')"
                     stack-label
                     :error="errors.hasOwnProperty('publishers_id')"
                     :error-message="_.get(errors,'publishers_id')">
                <template v-slot:control>
                    <div class="full-width items-center">
                        {{ _.get(publication, 'publisher.name') }}
                    </div>
                </template>
                <template v-slot:append>
                    <q-btn :label="$translate('buttons.select')"
                           icon="sym_o_trackpad_input"
                           size="sm"
                           dense
                           @click="openDialogPublisher"/>
                    <q-dialog v-model="dialogPublisher">
                        <PublisherDialog @onSelect="setPublisher"/>
                    </q-dialog>
                </template>
            </q-field>
        </q-card-section>

        <q-card-section v-if="_.get(fields,'fields.countrys.show')">
            <q-select v-model="publication.countrys"
                      :label="_.get(fields,'fields.countrys.label')"
                      :options="optionsCountry"
                      stack-label
                      multiple
                      use-chips
                      options-dense
                      map-options
                      emit-value
                      :error="errors.hasOwnProperty('countrys')"
                      :error-message="_.get(errors,'countrys')"/>
        </q-card-section>

        <FieldsRendering :fields="_.get(fields,'fields_additional')"
                         v-model="publication.fields_additional"
                         :errors="errors"
                         error-prefix="fields_additional."
        />
    </Card>
</template>

<script setup>
/**************************************************
 *
 *  Издания - основная информация
 *
 ***************************************************/
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import FieldsRendering from "@/Components/Fields/FieldsRendering.vue";
import {Card} from "@/Components/Block";
import ClassificationDB from "@/plugins/ClassificationDB";
import {Publisher as PublisherDialog} from "@/Components/ReportMaterialForm/Dialog";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const props = defineProps({
    fields: {type: Object}
})
const publication = defineModel('publication', {type: Object})
const errors = defineModel('errors', {type: Object})

const optionsCountry = ref([])

const loadCountry = () => {
    ClassificationDB
            .getSimpleList('country')
            .then((r) => {
                optionsCountry.value = r
            })
}

const dialogPublisher = ref(false)

const openDialogPublisher = () => {
    dialogPublisher.value = true
}

const setPublisher = (data) => {
    publication.value.publisher = data.publisher
    publication.value.publishers_id = data.publisher.id
    dialogPublisher.value = false
}

onMounted(() => {
    loadCountry()
    publication.value.countrys = _.map(publication.value.countrys, (tag) => {
        if (_.isObject(tag)) {
            return _.get(tag, 'id')
        }
        return tag;
    })
    publication.value.issn = _.map(publication.value.issn, (tag) => {
        if (_.isObject(tag)) {
            return _.get(tag, 'value')
        }
        return tag;
    })
})
</script>