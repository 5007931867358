<template>
    <q-item>
        <q-item-section side>
            <DataTableActions>
                <q-item clickable
                        @click="detailDialog = true"
                        v-close-popup>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('buttons.detail') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="o_pageview" size="xs"/>
                    </q-item-section>
                </q-item>
                <q-item clickable v-if="skill.permission.edit"
                        @click="editDialog = true"
                        v-close-popup>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('buttons.edit') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="o_edit" size="xs"/>
                    </q-item-section>
                </q-item>
                <q-item clickable v-if="skill.permission.delete"
                        @click="deleteItem()"
                        v-close-popup>
                    <q-item-section>
                        <q-item-label>
                            {{ $translate('buttons.delete') }}
                        </q-item-label>
                    </q-item-section>
                    <q-item-section avatar>
                        <q-icon name="o_delete" size="xs"/>
                    </q-item-section>
                </q-item>
            </DataTableActions>
        </q-item-section>
        <q-item-section>
            <q-item-label caption>
                {{ skill.type.name }}
            </q-item-label>
            <q-item-label>
                {{ skill.name }}
            </q-item-label>
        </q-item-section>
    </q-item>

    <q-dialog v-model="editDialog" no-backdrop-dismiss>
        <Card :title="$translate('user-skill.title.edit')"
              in-modal show-close-button header-toolbar>
            <UserSkillForm v-model="editForm"/>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="submitEditForm"
                />
            </template>
        </Card>
    </q-dialog>
    <q-dialog v-model="detailDialog" no-backdrop-dismiss>
        <Card :title="skill.name"
              in-modal show-close-button header-toolbar hide-actions>
            <q-field :label="$translate('user-skill.label.name')" tag="div" stack-label borderless>
                <template v-slot:control>
                    <div class="full-width">
                        {{ skill.name }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('user-skill.label.type')" tag="div" stack-label borderless>
                <template v-slot:control>
                    <div class="full-width">
                        {{ skill.type.name }}
                    </div>
                </template>
            </q-field>
            <q-field :label="$translate('user-skill.label.description')" tag="div" stack-label borderless>
                <template v-slot:control>
                    <div class="full-width" v-html="skill.description"></div>
                </template>
            </q-field>
            <q-field :label="$translate('user-skill.label.file')" tag="div" stack-label borderless v-if="skill.file">
                <template v-slot:prepend>
                    <q-icon name="attach_file"/>
                </template>
                <template v-slot:control>
                    <div class="full-width">
                        <a :href="_.get(skill.file,'fullpath')" target="_blank"
                           class="cursor-pointer">{{ _.get(skill.file,'name') }}</a>
                    </div>
                </template>
            </q-field>
        </Card>
    </q-dialog>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {useQuasar} from 'quasar'
import _ from "lodash";
import DataTableActions from "@/Components/DataTableActions.vue";
import UserSkillForm from "@/Components/User/Skill/UserSkillForm.vue";
import {Card} from "@/Components/Block";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;
const $q = useQuasar()

interface Props {
    skill: UserSkill
}

const props = defineProps<Props>()

const editDialog = ref(false)
const detailDialog = ref(false)

const editForm = useForm({...props.skill})

const submitEditForm = () => {
    editForm.post($route('profile.skill.update', props.skill), {
        onSuccess: () => {
            editDialog.value = false
            router.reload()
        }
    })
}

const deleteItem = () => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: props.skill.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete($route('profile.skill.delete', props.skill), {
            onSuccess: () => {
                router.reload()
            }
        })
    })
}
</script>