<template>
    <q-card-section>
        <q-item-label caption>{{ $translate('report-type.fields.name') + ':' }}</q-item-label>
        <div class="row q-gutter-x-lg">
            <q-input v-for="(lang_name,lang) in $translate('fields.locale')"
                     v-model="reportType.name_i18n[lang]"
                     dense
                     required
                     :hint="lang_name"
                     class="col"
            />
        </div>
    </q-card-section>

    <q-card-section>
        <q-item-label caption>{{ $translate('report-type.fields.description') + ':' }}</q-item-label>
        <div class="row q-gutter-x-lg">
            <q-input v-for="(lang_name,lang) in $translate('fields.locale')"
                     v-model="reportType.description_i18n[lang]"
                     type="textarea"
                     rows="2"
                     dense
                     required
                     :hint="lang_name"
                     class="col"
            />
        </div>
    </q-card-section>

    <component :is="component" v-model="reportType.fields"/>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref, watch} from "vue";
import _ from "lodash";

import * as ReportForm from './Form';

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const reportType = defineModel({
    type: Object,
    default: {
        category: null,
        name_i18n: {},
        description_i18n: {},
        fields: {},
    }
})

watch(() => reportType.value.category, () => {
    _.set(reportType.value, 'fields', {});
})

const component = computed(() => {
    return ReportForm['ReportType' + _.upperFirst(_.get(reportType.value, 'category'))];
})

</script>