<template>
    <div class="flex items-center">
        <q-table :columns="$translate('fields.weekdays')"
                 :rows="[worktime]"
                 hide-pagination hide-no-data flat dense
                 class="col-grow">
            <template v-slot:header-cell="props">
                <q-th class="text-center text-uppercase">
                    <span class="text-weight-light">{{ props.col.label }}</span>
                </q-th>
            </template>
            <template v-slot:body-cell="props">
                <q-td :props="props" style="width: calc(100% / 7)">
                    <div class="text-center">
                        {{ _.get(_.find(props.row, (d) => d.day === props.col.day), 'time_start') || '-' }}
                    </div>
                    <div class="text-center">
                        {{ _.get(_.find(props.row, (d) => d.day === props.col.day), 'time_end') || '' }}
                    </div>
                </q-td>
            </template>
        </q-table>
        <div class="col-auto">
            <q-btn v-if="_.get(worktime, '0.permission.edit')"
                   icon="o_edit"
                   :title="$translate('cadre-staff.actions.worktime-edit')"
                   @click="worktimeDialog = true"
                   dense flat
            />
            <q-dialog v-model="worktimeDialog" no-backdrop-dismiss>
                <Card :title="$translate('cadre-worktime.title.edit')"
                      in-modal show-close-button header-toolbar>
                    <WorktimeForm v-model="worktimeForm"/>
                    <template v-slot:actions>
                        <q-btn icon="o_save"
                               :label="$translate('buttons.save')"
                               color="positive"
                               @click="worktimeFormSubmit"
                        />
                    </template>
                </Card>
            </q-dialog>
            <q-btn icon="o_check"
                   v-if="_.get(worktime, '0.permission.approve')"
                   :title="$translate('buttons.approve')"
                   @click="confirmWorktime"
                   dense flat
            />
            <q-btn icon="o_cancel"
                   v-if="_.get(worktime, '0.permission.decline')"
                   :title="$translate('buttons.decline')"
                   @click="declineWorktime"
                   dense flat
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import {CadreWorktime} from "@/entity";
import {Card} from "@/Components/Block";
import {Form as WorktimeForm} from "@/Components/Cadre/Worktime/index";

const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

interface Props {
    worktime: CadreWorktime[]
}

const props = defineProps<Props>()

const worktimeDialog = ref(false)

const worktimeForm = useForm({
    time_start: {
        0: _.get(_.find(props.worktime, (d) => d.day === 0), 'time_start'),
        1: _.get(_.find(props.worktime, (d) => d.day === 1), 'time_start'),
        2: _.get(_.find(props.worktime, (d) => d.day === 2), 'time_start'),
        3: _.get(_.find(props.worktime, (d) => d.day === 3), 'time_start'),
        4: _.get(_.find(props.worktime, (d) => d.day === 4), 'time_start'),
        5: _.get(_.find(props.worktime, (d) => d.day === 5), 'time_start'),
        6: _.get(_.find(props.worktime, (d) => d.day === 6), 'time_start'),
    },
    time_end: {
        0: _.get(_.find(props.worktime, (d) => d.day === 0), 'time_end'),
        1: _.get(_.find(props.worktime, (d) => d.day === 1), 'time_end'),
        2: _.get(_.find(props.worktime, (d) => d.day === 2), 'time_end'),
        3: _.get(_.find(props.worktime, (d) => d.day === 3), 'time_end'),
        4: _.get(_.find(props.worktime, (d) => d.day === 4), 'time_end'),
        5: _.get(_.find(props.worktime, (d) => d.day === 5), 'time_end'),
        6: _.get(_.find(props.worktime, (d) => d.day === 6), 'time_end'),
    },
})


const worktimeFormSubmit = () => {
    worktimeForm.post($route('cadre.worktime.update', {cadreStaff: _.get(props.worktime, '0.cadre_staff_id')}),
            {
                onSuccess: () => {
                    worktimeDialog.value = false
                }
            })
}

const confirmWorktime = () => {
    router.post($route('cadre.worktime.actions', {
        cadreStaff: _.get(props.worktime, '0.cadre_staff_id'),
        action: 'confirmWorktime'
    }))
}
const declineWorktime = () => {
    router.post($route('cadre.worktime.actions', {
        cadreStaff: _.get(props.worktime, '0.cadre_staff_id'),
        action: 'declineWorktime'
    }))
}
</script>