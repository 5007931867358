<template>
    <q-btn v-if="department.can.address.add"
           size="sm" dense icon="add" @click="addressModal = true"
           color="blue-grey-5"
           :label="$translate('buttons.add')"/>

    <q-dialog v-model="addressModal" no-backdrop-dismiss>
        <Card in-modal :title="$translate('ssc.address.title.add')" title-bg="blue-grey-3" show-close-button
              no-card-section>
            <q-card-section>
                <SearchAddress @onSelect="selectAddress"/>
            </q-card-section>
            <q-card-section v-if="addressForm.address" >
                <q-field readonly
                         :label="$translate('ssc.address.fields.address')" stack-label
                         :hint="$translate('ssc.address.fields.address-hint')">
                    <template v-slot:control>
                        {{ addressForm.address }}
                    </template>
                </q-field>
            </q-card-section>
            <q-card-section v-if="addressForm.address" >
                <q-input v-model="addressForm.room"
                         :label="$translate('ssc.address.fields.room')" stack-label>
                </q-input>
            </q-card-section>
            <q-card-section v-if="addressForm.address" >
                <div>
                    <q-checkbox v-model="addressForm.delivery" :label="$translate('ssc.address.fields.delivery')"
                                size="sm"/>
                </div>
                <div>
                    <q-checkbox v-model="addressForm.self" :label="$translate('ssc.address.fields.self')"
                                size="sm"/>
                </div>
            </q-card-section>
            <template v-slot:actions v-if="addressForm.address">
                <q-btn icon="save" :label="$translate('buttons.add')" color="positive"
                       @click="formSubmit"/>
            </template>

        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import {useQuasar} from "quasar";
import Card from "@/Components/Block/Card.vue";
import SearchAddress from "@/Components/Search/SearchAddress.vue";
import {router, useForm} from "@inertiajs/vue3";
import _ from "lodash";

const $q = useQuasar();
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    department: {},
})

const addressModal = ref(false)

const addressForm = useForm({
    address: null,
    detail: null,
    group: null,
    room: null,
    delivery: false,
    self: false,
})

const formSubmit = () => {
    addressForm.post(route('ssc.address.store', props.department), {
        onSuccess: () => {
            addressModal.value = false
            router.reload()
        }
    })
}

const selectAddress = (value) => {
    _.set(addressForm, 'address', value.unrestricted_value);
    _.set(addressForm, 'detail', JSON.stringify(value.data));
}
</script>