<template>
    <q-list separator>
        <template v-for="(state) in stateList">
            <q-item>
                <q-item-section>
                    <q-item-label>
                        {{ state.name }}
                    </q-item-label>
                </q-item-section>
                <q-item-section side>
                    <q-btn icon="o_delete"
                           :label="$translate('buttons.delete')"
                           color="negative"
                           flat dense
                           @click="deleteItem(state)"/>
                </q-item-section>
            </q-item>
        </template>
    </q-list>
    <q-btn icon="o_add"
           :label="$translate('buttons.add')"
           color="primary"
           dense no-caps
           @click="addDialogOpen"/>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('ssc.custom-state.dialog.add')"
              in-modal show-close-button>
            <q-item-label>{{ $translate('ssc.custom-state.fields.name') }}</q-item-label>
            <template v-for="(val,locale) in form.name_i18n">
                <q-input v-model="form.name_i18n[locale]"
                         :label="$translate('fields.locale.'+locale)"
                         stack-label/>
            </template>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.add')"
                       color="positive"
                       @click="submitForm"/>
            </template>
        </Card>
    </q-dialog>
</template>

<script setup>
import {getCurrentInstance, onBeforeMount, onMounted, ref} from "vue";
import _ from "lodash";
import {router, useForm, usePage} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import HasChanges from "@/Components/Block/HasChanges.vue";
import axios from "axios";
import {useQuasar} from "quasar";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $q = useQuasar();

const props = defineProps({
    department: {},
})

const stateList = ref([])
const loadState = () => {
    axios.get(route('ajax.ssc', {m: 'customState', ssc_id: props.department.id}))
        .then((response) => {
            stateList.value = response.data.results;
        })
}

const addDialog = ref(false)
const addDialogOpen = () => {
    _.forEach($translate('fields.locale'), (name, locale) => {
        _.set(form.name_i18n, locale, null)
    })
    addDialog.value = true
}

const form = useForm({
    name_i18n: {}
})

const submitForm = () => {
    form.post(route('ssc.custom-state.store', props.department), {
        onSuccess: () => {
            loadState()
            addDialog.value = false
        }
    })
}

const deleteItem = (item) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: item.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    })
        .onOk(() => {
            router.delete(route('ssc.custom-state.delete', [props.department, item]),
                {
                    onSuccess: () => {
                        loadState()
                    }
                })
        })
}

onMounted(() => {
    loadState()
})
</script>

<style scoped>

</style>