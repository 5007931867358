<template>
    <Card :title="$translate('project.view.related-info')"
          :hide-actions="!project.can.related.add || printable"
          no-card-section
          actions-align="left"
          class="q-mb-md">

        <q-list separator>
            <template v-if="projectRelations.hasOwnProperty('parent') && projectRelations.parent">
                <q-item-label caption class="q-mx-sm q-my-xs">
                    {{ $translate('project.view.base-info-parent') }}
                </q-item-label>
                <q-item>
                    <q-item-section>
                        <q-item-label>
                            <q-item-label>
                                <a :href="$route('project.show', _.get(projectRelations.parent, 'number'))"
                                   @click.prevent="router.visit($route('project.show', _.get(projectRelations.parent, 'number')))">
                                    {{ _.get(projectRelations.parent, 'number') }} {{
                                        _.get(projectRelations.parent, 'name')
                                    }}
                                </a>
                            </q-item-label>
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side v-if="project.can.related.delete">
                        <q-btn icon="mdi-delete-outline"
                               color="negative"
                               size="sm"
                               @click="deleteRelation('parent',_.get(projectRelations.parent, 'id'))"
                               flat dense/>
                    </q-item-section>
                </q-item>
            </template>
            <template v-if="projectRelations.hasOwnProperty('children') && _.size(projectRelations.children)>0">
                <q-item-label caption class="q-mx-sm q-my-xs">
                    {{ $translate('project.view.base-info-children') }}
                </q-item-label>
                <template v-for="(children) in projectRelations.children">
                    <q-item>
                        <q-item-section>
                            <q-item-label>
                                <a :href="$route('project.show', _.get(children, 'number'))"
                                   @click.prevent="router.visit($route('project.show', _.get(children, 'number')))">
                                    {{ _.get(children, 'number') }} {{ _.get(children, 'name') }}
                                </a>
                            </q-item-label>
                        </q-item-section>
                        <q-item-section side v-if="project.can.related.delete">
                            <q-btn icon="mdi-delete-outline"
                                   color="negative"
                                   size="sm"
                                   @click="deleteRelation('children',_.get(children, 'id'))"
                                   flat dense/>
                        </q-item-section>
                    </q-item>
                </template>
            </template>
            <template v-if="projectRelations.hasOwnProperty('related') && _.size(projectRelations.related)>0">
                <q-item-label caption class="q-mx-sm q-my-xs">
                    {{ $translate('project.view.base-info-related') }}
                </q-item-label>
                <template v-for="(related) in projectRelations.related">
                    <q-item>
                        <q-item-section>
                            <q-item-label>
                                <a :href="$route('project.show', _.get(related, 'number'))"
                                   @click.prevent="router.visit($route('project.show', _.get(related, 'number')))">
                                    {{ _.get(related, 'number') }} {{ _.get(related, 'name') }}
                                </a>
                            </q-item-label>
                        </q-item-section>
                        <q-item-section side v-if="project.can.related.delete">
                            <q-btn icon="mdi-delete-outline"
                                   color="negative"
                                   size="sm"
                                   @click="deleteRelation('related',_.get(related, 'id'))"
                                   flat dense/>
                        </q-item-section>
                    </q-item>
                </template>
            </template>
        </q-list>

        <template v-slot:actions>
            <q-btn icon="o_add"
                   :label="$translate('project.view.related-project.select-project-title')"
                   dense no-caps
                   @click="showRelatedDialog"/>
            <q-dialog v-model="relatedDialog" no-backdrop-dismiss>
                <Card :title="$translate('project.view.related-project.select-project-title')" in-modal
                      show-close-button>

                    <q-select :label="$translate('project.view.related-project.select-project')"
                              v-model="relatedForm.project"
                              :options="relatedOptions"
                              @filter="loadRelated"
                              use-input
                              map-options
                              emit-value
                    />

                    <q-field :label="$translate('project.view.related-project.select-type')"
                             borderless stack-label>
                        <template v-slot:control>
                            <div class="full-width no-outline flex column q-gutter-y-sm q-mt-sm">
                                <q-radio v-model="relatedForm.type"
                                         v-if="!project.parent_id"
                                         val="parent" dense
                                         size="sm"
                                         :label="$translate('project.view.related-project.select-type-parent')"/>
                                <q-radio v-model="relatedForm.type"
                                         val="children" dense
                                         size="sm"
                                         :label="$translate('project.view.related-project.select-type-children')"/>
                                <q-radio v-model="relatedForm.type"
                                         val="related" dense
                                         size="sm"
                                         :label="$translate('project.view.related-project.select-type-related')"/>
                            </div>
                        </template>
                    </q-field>

                    <template v-slot:actions>
                        <q-btn icon="o_save"
                               :label="$translate('buttons.save')"
                               color="positive"
                               @click="relatedFormSubmit"
                        />
                    </template>
                </Card>
            </q-dialog>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, ref, onMounted} from "vue";
import {router, useForm} from "@inertiajs/vue3";
import {useQuasar} from "quasar";
import _ from 'lodash'
import axios from 'axios'
import {Alert, Card} from "@/Components/Block";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route
const $q = useQuasar()

const props = defineProps({
    project: {
        type: Object,
        required: true
    },
    printable: {
        type: Boolean,
        default: false
    }
})


const projectRelations = ref({})
const loadRelations = () => {
    axios.get($route('ajax.project.relations', {m: 'list', project: props.project.id}))
            .then((response) => {
                projectRelations.value = response.data.results;
            })
}

const relatedDialog = ref(false)
const relatedForm = useForm({
    project: null,
    type: null
})
const relatedOptions = ref(null)
const loadRelated = (q, update) => {
    let excluded = [];
    excluded.push(_.get(projectRelations.value.parent, 'id'));
    excluded.push(_.map(projectRelations.value.children, 'id'));
    excluded.push(_.map(projectRelations.value.related, 'id'));
    excluded = _.flatten(excluded);
    axios.get($route('ajax.project', {m: 'related', exclude: excluded, q: q}))
            .then((response) => {
                update(() => {
                    relatedOptions.value = response.data.results
                })
            })
}
const relatedFormSubmit = () => {
    if (relatedForm.project && relatedForm.type) {
        relatedForm.patch($route('project.related.add', {project: props.project}), {
            onSuccess: () => {
                relatedDialog.value = false;
                relatedForm.reset()
                loadRelations()
            }
        })
    }
}
const showRelatedDialog = () => {
    relatedForm.reset();
    relatedDialog.value = true;
}

const deleteForm = useForm({
    relate: null,
    id: null
})
const deleteRelation = (type, id) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: 'связанного проекта'}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        deleteForm
                .transform((data) => ({
                    ...data,
                    relate: type,
                    id: id
                }))
                .delete($route('project.related.remove', props.project), {
                    onSuccess: () => {
                        deleteForm.reset();
                        loadRelations()
                    }
                })
    })
}

onMounted(() => {
    loadRelations()
})

</script>