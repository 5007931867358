<template>
    <div class="q-pa-md flex items-center q-gutter-md">
        <WidgetSimple v-if="can.sensors"
                :title="$translate('module-sensors.dashboard.items.sensors')"
                :link="$route('services.sensors.list')"
                :count="sensorsCount.enabled + '/' + sensorsCount.disabled"
                :description="$translate('module-sensors.dashboard.count.sensors.on_off')"
        />
        <WidgetSimple v-if="can.sensor_types"
                :title="$translate('module-sensors.dashboard.items.sensor-types')"
                :link="$route('services.sensors.sensor-types')"
                :count="String(sensorTypesCount.all)"
        />
        <WidgetSimple v-if="can.sensor_groups"
                :title="$translate('module-sensors.dashboard.items.sensor-groups')"
                :link="$route('services.sensors.sensor-groups')"
                :count="String(sensorGroupsCount.all)"
        />
        <WidgetSimple v-if="can.measurement_types"
                :title="$translate('module-sensors.dashboard.items.measurement-types')"
                :link="$route('services.sensors.measurement-types')"
                :count="String(measurementTypesCount.all)"
        />
    </div>
</template>

<script lang="ts" setup>
import {Simple as WidgetSimple} from "@/Components/Widget";
import {getCurrentInstance} from "vue";
import {ComponentInternalInstance} from "@vue/runtime-core";

interface Count {
    enabled: number,
    disabled: number
}
interface CountAll {
    all: number
}
interface Can {
    sensors: boolean,
    sensor_types: boolean,
    sensor_groups: boolean,
    measurement_types: boolean
}
interface Props {
    sensorsCount: Count,
    sensorTypesCount: CountAll,
    sensorGroupsCount: CountAll,
    measurementTypesCount: CountAll,
    can: Can
}

defineProps<Props>();

const app: ComponentInternalInstance | null = getCurrentInstance();
const $translate: Function = app!.appContext.config.globalProperties.$translate;
const $route: Function = app!.appContext.config.globalProperties.$route;
</script>
