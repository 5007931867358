<template>
    <q-select v-model="selected"
              :label="label ? label : $translate('search.publisher.label')"
              :hint="$translate('search.publisher.hint')"
              :loading="loading"
              :options="fetchedData"
              @filter="searchElement"
              @update:model-value="(value) => emit('onSelect', value)"
              clearable
              clear-icon="clear"
              input-debounce="1000"
              options-dense
              use-input
              :error-message="error"
              :error="_.size(error)>0"
    >
        <template v-slot:option="option">
            <q-item v-bind="option.itemProps">
                <q-item-section>
                    <q-item-label v-html="option.opt.html"></q-item-label>
                </q-item-section>
            </q-item>
        </template>
    </q-select>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import axios from "axios";
import _ from "lodash";

const props = defineProps({
    label: {
        type: String,
        default: null
    },
    filters: {
        type: Array,
        required: false,
        default: []
    },
    error: {
        type: String,
        default: null
    }
});
const emit = defineEmits(['onSelect']);

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;

const selected = ref(null);
const fetchedData = ref([]);
const loading = ref(false);

const searchElement = (search, update) => {
    if (search === '') {
        if (_.size(fetchedData.value) > 0) {
            // already loaded
            update()
            return
        }
        update(() => {
            fetchedData.value = [];
        });
        return
    }
    update(() => {
        let params = {
            m: 'search',
            q: search,
        };
        loading.value = true;
        axios.get(route('ajax.publisher', params))
                .then((response) => {
                    fetchedData.value = response.data.results;
                })
                .finally(() => {
                    loading.value = false;
                });
    });
};
</script>
