<template>
    <template v-for="(field) in fieldsItems">
        <FieldsRenderingItem
                :field="field"
                :options="fieldOptions[fieldName(field)]"
                v-model="fieldValues[field.name]"
                :error="hasError(field)"
                :error-message="getError(field)"
                @update:model-value="checkRules(field)"/>
    </template>
</template>

<script setup>
import {useQuasar} from "quasar";
import {computed, getCurrentInstance, nextTick, onBeforeMount, onMounted, ref, watch} from "vue";
import _ from "lodash";

import FieldsRenderingItem from "@/Components/Fields/FieldsRenderingItem.vue";
import ClassificationDB from "@/plugins/ClassificationDB";
import {usePage} from "@inertiajs/vue3";

const $q = useQuasar()
const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate

const props = defineProps({
    fields: {
        type: Object,
        required: true,
    },
    ssc: {
        type: Number,
        default: null
    },
    // values: {
    //     type: Object,
    //     required: false,
    //     default: {}
    // },
    errors: {
        type: Object,
        required: false,
        default: {}
    },
    errorPrefix: {
        type: String,
        required: false,
        default: ''
    }
})

const emit = defineEmits(['update:fields'])

const fieldValues = defineModel({type: Object, default: {}})
/**
 * Текущая локаль
 *
 * @type {ComputedRef<string>}
 */
const locale = computed(() => usePage().props.locale)

/**
 *  Список полей
 * @type {Ref<UnwrapRef<*[]>>}
 */
const fieldsItems = ref([])

/**
 * Значения полей
 * @type {Ref<UnwrapRef<{}>>}
 */
const fieldsData = ref({})

/**
 * списки элементов
 *
 * @type {Ref<UnwrapRef<{}>>}
 */
const options = ref({})

/**
 * списки для каждого поля с учетом правил обработки
 *
 * @type {Ref<UnwrapRef<{}>>}
 */
const fieldOptions = ref({})

/**
 * Мультивыбор у поля
 *
 * @param field
 * @returns {boolean}
 */
const isMulti = (field) => {
    switch (field.type) {
        case "select:multi":
        case "input:checkbox":
        case "file:multi":
            return true;
        default:
            return false;
    }
}

/**
 * Name для поля
 *
 * @param field
 * @returns {string}
 */
const fieldName = (field) => {
    return field.name
}

/**
 * Получение ошибки для поля
 *
 * @param field
 * @returns {string}
 */
const getError = (field) => {
    let label = props.errorPrefix + fieldName(field);

    return _.get(props.errors, label)
}

/**
 * Проверка наличия ошибки для поля
 *
 * @param field
 * @returns {boolean}
 */
const hasError = (field) => {
    let label = props.errorPrefix + fieldName(field);

    return props.errors.hasOwnProperty(label);
}

/**
 * Могут ли у поля быть опции выбора
 *
 * @param field
 * @returns {boolean}
 */
const hasOptions = (field) => {
    switch (field.type) {
        case "select":
        case "select:multi":
        case "input:checkbox":
        case "input:radio":
            return true;
        default:
            return false;
    }
}

/**
 * Проверка правил для поля
 *
 * @param field
 */
const checkRules = (field) => {
    let fName = field.name;
    let fValue = _.get(fieldValues.value, fName)

    console.debug('checkRules => ', fName)
    _.forEach(field.rules, (rule, index) => {
        console.debug('start rule => ' + (index + 1))
        if (checkCondition(rule.condition, fValue)) {
            //fillOptions(fName)
            console.debug('result rule => ' + (index + 1), true)
            applyResult(rule.result)
        }
    })
}

/**
 * Проверка условия
 *
 * @param ruleCondition
 * @param value
 * @returns {boolean}
 */
const checkCondition = (ruleCondition, value) => {
    /** Если применяем я одному элементу **/
    if (ruleCondition.type === 'single') {
        return switchCondition(value, ruleCondition.condition, ruleCondition.value);
    } else {
        if (ruleCondition.items.length > 0) {
            let groupResult = ruleCondition.items.map((item, index) => {
                return checkCondition(item, value);
            });
            if (ruleCondition.operator === 'or') {
                return _.some(groupResult, Boolean);
            }
            if (ruleCondition.operator === 'and') {
                return _.every(groupResult, Boolean);
            }
        }
    }
    return false;
}

/**
 * Проверка значения на соответствие условия
 *
 * @param value проверяемое значение
 * @param op оператор
 * @param check  необходимое значение
 * @returns {boolean}
 */
const switchCondition = (value, op, check) => {
    console.debug('switchCondition => ', value, op, check);
    switch (op) {
        case "empty": //пусто
            if (_.isArray(value)) {
                return (value === null || 0 === _.size(value))
            }
            //console.log("empty => ", (value === null || value === '' || 0 === value.length || !value.trim()));
            return (value === null || value === '' || 0 === _.size(value) || !value.trim())
        case "notempty": //не пусто
            if (_.isArray(value)) {
                return !(value === null || 0 === _.size(value))
            }
            return !(value === null || value === '' || 0 === _.size(value) || !value.trim())
        case "eq": //равно
            if (_.isArray(value) && _.isArray(check)) {
                return _.isEmpty(_.xor(check, value))
            }
            return (String(value).toString().toLowerCase() === String(check).toString().toLowerCase());
        case "ne": //не равно
            if (_.isArray(value) && _.isArray(check)) {
                return !_.isEmpty(_.xor(check, value))
            }
            return (String(value).toString().toLowerCase() !== String(check).toString().toLowerCase());
        case "contains": //содержит
            if (_.isArray(value) && _.isArray(check)) {
                let _inc = false;
                _.each(value, (val) => {
                    if (_.includes(val, check)) {
                        _inc = true;
                    }
                })
                return _inc;
            }
            return _.includes(value, check);
        case "notcontains": //не содержит
            if (_.isArray(value) && _.isArray(check)) {
                let _inc = false;
                _.each(value, (val) => {
                    if (_.includes(check, val)) {
                        _inc = true;
                    }
                })
                return !_inc;
            }
            return !_.includes(value, check);
            //return !String(value).includes(check);
        case "begins": //начинается с
            return String(value).includes(check, 0);
        case "ge": // не менее
            return (Number(value) >= Number(check));
        case "gt": // более
            return (Number(value) > Number(check));
        case "le": // не более
            return (Number(value) <= Number(check));
        case "lt" : // менее"
            return (Number(value) < Number(check));
    }
    return false;
}

/**
 * Применение действий активного правила
 *
 * @param result
 */
const applyResult = (result) => {
    if (result.hasOwnProperty('actions')) {
        _.forEach(result.actions, (action, index) => {
            applyAction(action, result.field)
        });
    } else {
        _.forEach(result, (item) => applyResult(item))
    }
}

/**
 * Применение действия с полем
 *
 * @param action
 * @param fieldName
 */
const applyAction = (action, fieldName) => {
    let index = _.findIndex(fieldsItems.value, {'name': fieldName})
    switch (action.type) {
        case "hide": //скрыть поле
            console.debug('apply action (' + action.type + ') to ', fieldName)
            _.set(fieldsItems.value[index], 'hidden', true)
            _.set(fieldValues.value, fieldName, (isMulti(fieldsItems.value[index]) ? [] : null));
            break;
        case "show": //показать (если скрыто)
            console.debug('apply action (' + action.type + ') to ', fieldName)
            _.set(fieldsItems.value[index], 'hidden', false)
            break;
        case "attr": //'attr' => 'задать аттрибут поля',
            console.debug('apply action (' + action.type + ') to ', fieldName)
            if (!fieldsItems.value[index].hasOwnProperty('attr')) {
                _.set(fieldsItems.value[index], 'attr', {});
            }
            _.set(fieldsItems.value[index].attr, action.attr, action.value);
            break;
        case "sel-val": // выбрать нужные значения
            console.debug('apply action (' + action.type + ') to ', fieldName)
            _.set(fieldValues.value, fieldName, _.size(action.value) > 0 ? action.value : null)
            _.set(fieldValues.value, fieldName, action.value)
            checkRules(fieldsItems.value[index])
            break;
        case "hide-val": // скрыть значения
            console.debug('apply action (' + action.type + ') to ', fieldName)
            _.set(fieldValues.value, fieldName, _.isArray(fieldValues.value[fieldName]) ? [] : null)
            _.set(fieldOptions.value, fieldName, _.map(fieldOptions.value[fieldName], (item) => ({
                        ...item,
                        disable: action.value.includes(item.value)
                    }))
            );
            break;
        case "show-val":  // показать значения
            console.debug('apply action (' + action.type + ') to ', fieldName)
            _.set(fieldValues.value, fieldName, _.isArray(fieldValues.value[fieldName]) ? [] : null)
            _.set(fieldOptions.value, fieldName, _.map(fieldOptions.value[fieldName], (item) => ({
                        ...item,
                        disable: !action.value.includes(item.value)
                    }))
            );
            break;
        case "empty": // пустое
            console.debug('apply action (' + action.type + ') to ', fieldName)
            _.set(fieldValues.value, fieldName, (isMulti(fieldsItems.value[index]) ? [] : null));
            checkRules(fieldsItems.value[index])
            break;
        case "reset": // сброс в начальное состояние
            console.debug('apply action (' + action.type + ') to ', fieldName)
            fieldsItems.value[index] = _.cloneDeep(_.get(props.fields, index))
            _.set(fieldValues.value, fieldName, (isMulti(fieldsItems.value[index]) ? [] : null));
            fillOptions(fieldsItems.value[index])
            checkRules(fieldsItems.value[index])
            break;
    }
}

/**
 * Заполнение списка опциями для всех полей которые этого требуют
 * Для дальнейшей манипуляции с этими списками
 * @param field
 * @returns {*[]}
 */
const fillOptions = (field) => {
    console.debug('fillOptions => ' + fieldName(field))
    let _options = [];
    // заполняем список исходными значениями
    switch (_.get(field, 'variants.type')) {
        case 'self':
            _options = _.map(field.variants.values, (item) => ({
                value: String(item.hasOwnProperty('value') ? item.value : item),
                label: item.hasOwnProperty('label') ? item.label[locale.value] : item
            }))
            break;
        case 'model':
            _options = _.map(_.get(options.value, _.get(field, 'variants.model')), (item) => ({
                value: String(item.hasOwnProperty('value') ? item.value : item.id),
                label: item.hasOwnProperty('label') ? item.label : item
            }));
            break;
        case "classificator":
            _options = _.map(_.get(options.value, _.get(field, 'variants.classificator')), (item) => ({
                value: String(item.hasOwnProperty('value') ? item.value : item),
                label: item.hasOwnProperty('label') ? item.label : item
            }))
            break;
        case "science_classificator":
            _options = _.map(_.get(options.value, _.get(field, 'variants.science_classificator')), (item) => ({
                id: Number(item.hasOwnProperty('value') ? item.value : item),
                value: Number(item.hasOwnProperty('value') ? item.value : item),
                label: item.hasOwnProperty('label') ? item.label : item,
                name: item.hasOwnProperty('label') ? item.label : item,
                parent_id: item.parent_id,
                has_children: (item.children > 0)
            }))
            break;
        default:
            if (_.has(field, 'values')) {
                // костыль для старых шаблонов
                _options = _.map(field.values[locale.value].split(';'), (item, index) => ({
                    value: String(index),
                    label: item
                }))
                break;
            }
    }
    _.set(fieldOptions.value, fieldName(field), _options);
}

/**
 * Подгрузка внешних списков
 * @param field
 */
const loadOptions = (field) => {
    switch (_.get(field, 'variants.type')) {
        case 'classificator':
            ClassificationDB
                    .getSimpleList(_.get(field, 'variants.classificator'))
                    .then((r) => {
                        _.set(options.value, _.get(field, 'variants.classificator'), r)
                        fillOptions(field)
                    })
            break;
        case 'science_classificator':
            ClassificationDB
                    .getMultiList(_.get(field, 'variants.science_classificator'))
                    .then((r) => {
                        _.set(options.value, _.get(field, 'variants.science_classificator'), r)
                        fillOptions(field)
                    })
            break
        case 'model':
            ClassificationDB
                    .getModelList(_.get(field, 'variants.model'), props.ssc)
                    .then((r) => {
                        _.set(options.value, _.get(field, 'variants.model'), r)
                        fillOptions(field)
                    })
            break;
        default:
            fillOptions(field)

    }
}

const initFields = () => {
    fieldsItems.value = [];
    // values.value = props.values;
    _.forEach(props.fields, (field, index) => {
        if (hasOptions(field)) {
            loadOptions(field)
        }
        nextTick(() => {
            fieldsItems.value.push(_.cloneDeep(field));
            // console.log(fieldName(field), "=", _.get(props.values, fieldName(field)))
            // if (props.values.hasOwnProperty(fieldName(field))) {
            _.set(fieldValues.value, fieldName(field), _.get(fieldValues.value, fieldName(field)) || (isMulti(field) ? [] : null))
            // } else {
            //     _.set(values.value, fieldName(field), isMulti(field) ? [] : null)
            // }
        });
    })
    console.debug(fieldValues.value)
}
onBeforeMount(() => {
    initFields()
})

onMounted(() => {
    nextTick(() => {
        _.forEach(props.fields, (field, index) => {
            checkRules(field)
        })
    });
})

// watch(() => values.value, () => {
//     emit('update:fields', values.value)
// })

watch(() => props.fields, () => {
    initFields()
    nextTick(() => {
        _.forEach(props.fields, (field, index) => {
            checkRules(field)
        })
    });
})

</script>